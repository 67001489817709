import { styled } from 'styled-components';

export const Content = styled.form`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 0 2.4rem 1.6rem;
	gap: 1.6rem;
	white-space: pre-wrap;

	& > * {
		user-select: auto;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;
