import React, { FC } from 'react';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRIcon from 'components/base/CRIcon';
import { CRText } from 'components/base/CRText';

import EDocClientPanelBase from '../../EDocClientPanelBase';
import * as S from './styles';

enum EDocResult {
	FAIL = 'FAIL',
	EXPIRED = 'EXPIRED',
	SUCCESS = 'SUCCESS',
	WRITE_SUCCESS = 'WRITE_SUCCESS',
	ALREADY_CONFIRMED = 'ALREADY_CONFIRMED',
}

type EDocResultType = keyof typeof EDocResult;

interface ResultFormatsFullProps {
	documentName: string;
	onClickBack: () => void;
}

const EDocResultFormats: Record<EDocResultType, FC<ResultFormatsFullProps>> = {
	[EDocResult.FAIL]: ({ documentName, onClickBack }: ResultFormatsFullProps) => (
		<FlexContainer align='center' direction='column'>
			<CRIcon src={Assets.icon.report} width='3.6rem' height='3.6rem' />
			<CRText typography='h4' color='primary60' text='서명 실패' margin='1rem 0' />
			<FlexContainer align='center' direction='column' margin='0 0 2rem 0'>
				<div>
					<CRText typography='bodyB' text={`${documentName}`} />
					<CRText text=' 서명에 실패했습니다.' />
				</div>
				<CRText text='고객센터에' display='block' />
				<CRText text='문의해 주시길 바랍니다.' display='block' />
			</FlexContainer>
			<CRButton.Default onClick={onClickBack} style={{ width: '100%' }}>
				전자문서로 이동
			</CRButton.Default>
		</FlexContainer>
	),

	[EDocResult.EXPIRED]: ({ documentName, onClickBack }: ResultFormatsFullProps) => (
		<FlexContainer align='center' direction='column'>
			<CRIcon src={Assets.icon.report} width='3.6rem' height='3.6rem' />
			<CRText typography='h4' color='primary60' text='서명 기한 만료' margin='1rem 0' />
			<FlexContainer align='center' direction='column' margin='0 0 2rem 0'>
				<div>
					<CRText typography='bodyB' text={`${documentName}`} />
					<CRText text='의 서명기한이 만료되었습니다.' />
				</div>
				<CRText text='고객센터에' display='block' />
				<CRText text='문의해 주시길 바랍니다.' display='block' />
			</FlexContainer>
			<CRButton.Default onClick={onClickBack} style={{ width: '100%' }}>
				전자문서로 이동
			</CRButton.Default>
		</FlexContainer>
	),

	[EDocResult.SUCCESS]: ({ documentName }: Pick<ResultFormatsFullProps, 'documentName'>) => (
		<FlexContainer align='center' direction='column'>
			<CRIcon src={Assets.icon.checkCircle} width='3.6rem' height='3.6rem' />
			<CRText typography='h4' color='primary60' text='제출 완료' margin='1rem 0' />
			<FlexContainer align='center' direction='column'>
				<div>
					<CRText typography='bodyB' text={`${documentName}의 검수`} />
					<CRText text='가 완료되었습니다.' />
				</div>
				<CRText text='완료된 문서는 수정이 불가합니다.' display='block' />
			</FlexContainer>
		</FlexContainer>
	),

	[EDocResult.ALREADY_CONFIRMED]: ({
		documentName,
	}: Pick<ResultFormatsFullProps, 'documentName'>) => (
		<FlexContainer align='center' direction='column'>
			<CRIcon src={Assets.icon.checkCircle} width='3.6rem' height='3.6rem' />
			<CRText typography='h4' color='primary60' text='검수 완료' margin='1rem 0' />
			<FlexContainer align='center' direction='column'>
				<div>
					<CRText typography='bodyB' text={`${documentName}의 검수`} />
					<CRText text='가 완료되었습니다.' />
				</div>
				<CRText text='완료된 문서는 수정이 불가합니다.' display='block' />
			</FlexContainer>
		</FlexContainer>
	),
};

interface Props {
	result: EDocResultType;
	documentName: string;
	onClickBack: () => void;
}

function EDocConfirmResult({ documentName, result, onClickBack }: Props) {
	const EDocResultComponent = EDocResultFormats[EDocResult[result]];
	return (
		<EDocClientPanelBase>
			<S.FormContainer>
				<EDocResultComponent documentName={documentName} onClickBack={onClickBack} />
			</S.FormContainer>
		</EDocClientPanelBase>
	);
}

export default EDocConfirmResult;
