import React, { useCallback } from 'react';

import dayjs from 'dayjs';

import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import SalaryDetailDialog from 'components/domain/dialog/SalaryDetailDialog';
import { displayMinuteToHourMinute } from 'lib';
import useDialog from 'lib/hook/util/useDialog';
import { EmployeeSalaryDTO } from 'types/api/employee';

import {
	EMPLOYEE_SALARY_COMPLETE_STATUS_CONFIG,
	EMPLOYEE_SALARY_SEND_STATUS_CONFIG,
	EMPLOYEE_SALARY_TABLE_HEADER_CONFIG,
} from './constant';
import * as S from './styles';

interface Props {
	items?: EmployeeSalaryDTO[] | null;
	refetch?: () => void;
}

function EmployeeSalaryTable({ items = [], refetch }: Props): React.ReactElement {
	const { showDialog } = useDialog();
	const renderSendStatus = useCallback(
		(status: string) => <CRStatus options={EMPLOYEE_SALARY_SEND_STATUS_CONFIG}>{status}</CRStatus>,
		[],
	);

	const renderServiceChangeNm = useCallback(
		(serviceChangeNm: string) =>
			serviceChangeNm?.split(',').length > 0 ? (
				<S.ServiceTypeBadgeContainer>
					{serviceChangeNm
						?.split(',')
						.map((item) => <S.ServiceTypeBadge>{item}</S.ServiceTypeBadge>)}
				</S.ServiceTypeBadgeContainer>
			) : (
				'-'
			),
		[],
	);

	const renderTotalWorDayCount = useCallback(
		(totalWorkDayCnt: number) => (
			<S.TextBox>{totalWorkDayCnt > 0 ? `${totalWorkDayCnt.toLocaleString()}일` : '-'}</S.TextBox>
		),
		[],
	);

	const renderTotalWorkHourCount = useCallback(
		(totalWorkHourCnt: number) => (
			<S.TextBox>
				{totalWorkHourCnt > 0 ? displayMinuteToHourMinute(totalWorkHourCnt) : '-'}
			</S.TextBox>
		),
		[],
	);

	const renderMoney = useCallback(
		(value: number) => <S.TextBox>{value > 0 ? `${value.toLocaleString()}원` : '-'}</S.TextBox>,
		[],
	);

	const renderSalaryYm = useCallback((value: string) => dayjs(value).format('YYYY.MM'), []);

	const onClickRow = (item: EmployeeSalaryDTO) => {
		showDialog(({ hideDialog }) => (
			<SalaryDetailDialog
				employeeSalaryId={item.employeeSalaryId}
				onClose={hideDialog}
				refetchList={refetch}
			/>
		));
	};

	const renderCompleteStatus = useCallback(
		(status: boolean) => (
			<CRStatus options={EMPLOYEE_SALARY_COMPLETE_STATUS_CONFIG}>
				{status ? '완료' : '대기'}
			</CRStatus>
		),
		[],
	);

	return (
		<S.Container>
			<CRTable.Root>
				<CRTable.Head heads={EMPLOYEE_SALARY_TABLE_HEADER_CONFIG} />
				<CRTable.Body>
					{items?.map((item) => (
						<CRTable.Row
							key={item.employeeSalaryId}
							onClick={onClickRow}
							item={item}
							customRender={{
								salaryYm: renderSalaryYm,
								totalWorkDayCnt: renderTotalWorDayCount,
								totalWorkHourCnt: renderTotalWorkHourCount,
								serviceChangeNm: renderServiceChangeNm,
								salarySendStateNm: renderSendStatus,
								salaryCompleteYn: renderCompleteStatus,
								paySumAmt: renderMoney,
								deductSumAmt: renderMoney,
								realPayAmt: renderMoney,
							}}
							renderKeys={[
								'salaryYm',
								'recipientNm',
								'serviceChangeNm',
								'totalWorkDayCnt',
								'totalWorkHourCnt',
								'paySumAmt',
								'deductSumAmt',
								'realPayAmt',
								'salaryCompleteYn',
								'salarySendStateNm',
								'managerNm',
							]}
							customStyle={{
								totalWorkDayCnt: {
									textAlign: 'right',
								},
								totalWorkHourCnt: {
									textAlign: 'right',
								},
								paySumAmt: {
									textAlign: 'right',
								},
								deductSumAmt: {
									textAlign: 'right',
								},
								realPayAmt: {
									textAlign: 'right',
								},
							}}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default EmployeeSalaryTable;
