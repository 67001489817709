import React from 'react';

import CRRsdNumber from 'components/base/CRRsdNumber';
import { EmployeeBasicWorkInfoViewType } from 'types/view/workSchedule';

import * as S from './styles';

type Props = EmployeeBasicWorkInfoViewType;

function EmployeeBasicInformationTable({
	korMemberNm,
	dutyNm,
	mobilePhoneNo,
	workExecuteActingEmployeeNm,
	joinDate,
	employState,
	data,
}: Props): React.ReactElement {
	return (
		<S.Table>
			<S.TableRow>
				<S.TableLabelColumn style={{ width: '12rem' }}>이름</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>{korMemberNm}</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '12rem' }}>직무</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>{dutyNm}</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '12rem' }}>전화번호</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>{mobilePhoneNo}</S.TableValueColumn>
			</S.TableRow>
			<S.TableRow>
				<S.TableLabelColumn style={{ width: '10.4rem' }}>업무대행자</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>
					{workExecuteActingEmployeeNm}
				</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '12rem' }}>입사일</S.TableLabelColumn>
				<S.TableValueColumn
					style={{
						width: '24rem',
					}}>
					{joinDate}
				</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '12rem' }}>상태</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>{employState}</S.TableValueColumn>
			</S.TableRow>
		</S.Table>
	);
}

export default EmployeeBasicInformationTable;
