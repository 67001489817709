import React from 'react';

import CRDialog from 'components/base/CRDialog';
import EDocViewer from 'components/base/CRFileViewer/viewer/EDocViewer';
import CRSpinner from 'components/base/CRSpinner';
import { useMyAccountInfo } from 'lib/hook/react-query';
import { useEDocPreviewInfo } from 'lib/hook/react-query/query/edoc';
import useDialog from 'lib/hook/util/useDialog';
import { EDocParamValue } from 'types/api/eDoc';

import * as S from './styles';

interface Props {
	viewerType?: 'eform' | 'report';
	title?: string;
	dialogType?: 'L' | 'M' | 'S';
	eDocParamValue?: EDocParamValue;
	edocNo?: string;
}

export default function EDocNoDialog({
	viewerType = 'report',
	title,
	dialogType = 'M',
	eDocParamValue,
	edocNo,
}: Props) {
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: eDocPreviewInfo } = useEDocPreviewInfo({
		edocNo,
		centerId: myAccountInfo?.centerId,
	});
	const dialog = useDialog();

	const paramValue: EDocParamValue = eDocPreviewInfo?.data?.edocParam?.edocParamValue
		? JSON.parse(eDocPreviewInfo?.data?.edocParam?.edocParamValue)
		: eDocParamValue;

	return (
		<CRDialog
			type={dialogType}
			onClickClose={dialog.hideDialog}
			title={title}
			body={
				<S.Container>
					{paramValue?.crfeNm && paramValue?.params ? (
						<EDocViewer
							viewerMode='fullPage'
							viewerType={viewerType}
							dataType='json'
							crfeName={paramValue.crfeNm}
							params={paramValue.params}
							showMenu
						/>
					) : (
						<CRSpinner />
					)}
				</S.Container>
			}
		/>
	);
}
