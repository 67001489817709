import styled from 'styled-components';

import { FlexContainer } from 'components/base/CRFlexLayout/styles';

export const Container = styled.div`
	padding: 0px 24px 16px 24px;
`;

export const ButtonContainer = styled(FlexContainer)`
	width: 100%;
`;
