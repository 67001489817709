import dayjs from 'dayjs';
import * as XLSX from 'sheetjs-style';

import { EmployeeSalarysPayrollData } from 'types/api/salary';

export const exportExcelSalaryPayroll = (fileName: string, rows: EmployeeSalarysPayrollData[]) => {
	const title = fileName.replace(/_/g, ' ');

	const moneyStartColumn = 7;

	const displayMoney = (value?: string | number) => {
		if (!value) return 0;
		return value;
	};
	const isMoneyColumn = (c: number) => (c > moneyStartColumn && c < 56) || (c > 58 && c < 62); // 서비스별 근무시간 제외구간

	const date = dayjs(
		`${fileName
			.replace(/[년월]/g, '')
			.split('_')
			.slice(0, 2)
			.join('-')}-01`,
	);

	const subTitle = `근무기간(${date.startOf('month').format('YYYY.MM.DD')}~${date
		.endOf('month')
		.format('YYYY.MM.DD')})`;

	const descriptions = [
		['', title],
		['', subTitle],
	];

	const headers = [
		/// 헤더 1번째
		[
			'',
			'순번',
			'직원ID',
			'직원명',
			'주민번호',
			'입사일',
			'직책',
			'근무시간',
			'근무일수',
			'급여',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'공제',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'실수령액',
			'기관부담금',
			'',
			'',
			'',
			'',
			'',
			'',
			'서비스별 직원급여',
			'',
			'',
			'서비스별 근무시간',
			'',
			'',
			'서비스별 본인부담금',
			'',
			'',
			'은행명',
			'계좌번호',
			'담당 사회복지사',
		],
		/// 헤더 2번째
		[
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'기본급',
			'주휴수당',
			'식대',
			'연차수당',
			'고정야간수당',
			'고정휴일수당',
			'휴일수당',
			'야간근로수당',
			'연장근로수당',
			'복지수당',
			'종일방문요양임금',
			'특별추가수당',
			'특별추가수당(비과세)',
			'요양보호사 권익증진장려금',
			'간호특별수당',
			'중증 수급자 가산금',
			'장기근속장려금',
			'방문간호간호사가산금',
			'원거리교통비가산금',
			'방문간호 인지 가산금',
			'소득합계',
			'국민연금',
			'건강보험',
			'장기요양보험',
			'고용보험실업급여',
			'소득세',
			'지방소득세',
			'본인부담금',
			'국민연금정산',
			'건강보험정산',
			'장기요양보험정산',
			'고용보험정산',
			'기타공제액',
			'연말정산 소득세',
			'연말정산 지방소득세',
			'공제합계',
			'',
			'국민연금',
			'건강보험',
			'장기요양보험',
			'고용보험 실업급여',
			'고용보험 고용안정',
			'산재보험',
			'퇴직적립금',
			'방문요양',
			'방문목욕',
			'방문간호',
			'방문요양',
			'방문목욕',
			'방문간호',
			'방문요양',
			'방문목욕',
			'방문간호',
			'',
			'',
			'',
		],
		[
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
		],
	];

	const sortedRows = rows.slice().sort((a, b) => a.rowNumber - b.rowNumber);
	const excelRows = sortedRows.map((row) => [
		'', // 공백
		row.rowNumber || '-', // 순번
		row.employeeId || '-', // 직원ID
		row.employeeNm || '-', // 직원명
		row?.rsdnNo || '-', // 주민번호
		row.accidentInsuranceAcqDate ? dayjs(row.accidentInsuranceAcqDate).format('YYYY.MM.DD') : '-', // 입사일
		row.dutyNm || '-', // 직책
		row.totalWorkConvertTime || '-', // 근무시간
		row.totalWorkDayCnt || '-', // 근무일수

		// 지급
		displayMoney(row.salaryItemAmt2010), // 기본급
		displayMoney(row.salaryItemAmt2020), // 주휴수당
		displayMoney(row.salaryItemAmt2015), // 식대
		displayMoney(row.salaryItemAmt2030), // 연차수당
		displayMoney(row.salaryItemAmt2051), // 고정야간수당
		displayMoney(row.salaryItemAmt2052), // 고정휴일수당
		displayMoney(row.salaryItemAmt2060), // 휴일수당
		displayMoney(row.salaryItemAmt2070), // 야간근로수당
		displayMoney(row.salaryItemAmt2080), // 연장근로수당
		displayMoney(row.salaryItemAmt2090), // 복지수당
		displayMoney(row.salaryItemAmt2050), // 종일방문요양임금
		displayMoney(row.salaryItemAmt2110), // 특별추가수당
		displayMoney(row.salaryItemAmt2100), // 특별추가수당(비과세)
		displayMoney(row.salaryItemAmt2180), // 요양보호사 권익증진장려금
		displayMoney(row.salaryItemAmt2120), // 간호특별수당
		displayMoney(row.salaryItemAmt2170), // 중증수급자 가산금
		displayMoney(row.salaryItemAmt2130), // 장기근속장려금
		displayMoney(row.salaryItemAmt2140), // 방문간호사 가산금
		displayMoney(row.salaryItemAmt2150), // 원거리교통비 가산금
		displayMoney(row.salaryItemAmt2160), // 방문간호 인지 가산금
		displayMoney(row.paySumAmt), // 소득합계

		// 공제
		displayMoney(row.salaryItemAmt3050), // 국민연금
		displayMoney(row.salaryItemAmt3060), // 건강보험
		displayMoney(row.salaryItemAmt3070), // 장기요양보험
		displayMoney(row.salaryItemAmt3080), // 고용보험 실업급여
		displayMoney(row.salaryItemAmt3030), // 소득세
		displayMoney(row.salaryItemAmt3040), // 지방소득세
		displayMoney(row.salaryItemAmt3010), // 본인부담금
		displayMoney(row.salaryItemAmt3051), // 국민연금정산
		displayMoney(row.salaryItemAmt3061), // 건강보험정산
		displayMoney(row.salaryItemAmt3071), // 장기요양보험정산
		displayMoney(row.salaryItemAmt3081), // 고용보험정산
		displayMoney(row.salaryItemAmt3090), // 기타공제액
		displayMoney(row.salaryItemAmt3020), // 연말정산 소득세
		displayMoney(row.salaryItemAmt3025), // 연말정산 지방소득세
		displayMoney(row.deductSumAmt), // 공제합계
		displayMoney(row.realPayAmt), // 실지급액

		// 기관부담금
		displayMoney(row.salaryItemAmt5010), // 국민연금
		displayMoney(row.salaryItemAmt5020), // 건강보험
		displayMoney(row.salaryItemAmt5030), // 장기요양보험
		displayMoney(row.salaryItemAmt5040), // 고용보험 실업급여
		displayMoney(row.salaryItemAmt5050), // 고용보험 고용안정
		displayMoney(row.salaryItemAmt5060), // 산재보험
		displayMoney(row.salaryItemAmt5070), // 퇴직적립금

		// 서비스별 직원급여
		displayMoney(row.visitCareSalaryAmt), // 방문요양임금
		displayMoney(row.visitBathSalaryAmt), // 방문목욕임금
		displayMoney(row.visitNursingSalaryAmt), // 방문간호임금

		// 서비스별 근무시간
		row.visitCareConvertTime || '-', // 방문요양시간
		row.visitBathConvertTime || '-', // 방문목욕시간
		row.visitNursingConvertTime || '-', // 방문간호시간

		// 서비스별 본인부담금
		displayMoney(row.visitCareBurdenAmt), // 방문요양 본인부담금
		displayMoney(row.visitBathBurdenAmt), // 방문목욕 본인부담금
		displayMoney(row.visitNursingBurdenAmt), // 방문간호 본인부담금

		row.salaryAccountBankNm || '-',
		row.salaryAccountNo || '-',
		row.managerNm || '-',
	]);

	const summary = excelRows[0].map<any>((v, index) => {
		if (index === 0) {
			return '';
		}
		if (index === 1) {
			return '합계';
		}
		if (isMoneyColumn(index)) {
			return 0;
		}
		return '';
	});
	for (let i = 0; i < excelRows.length; i += 1) {
		for (let j = 0; j < excelRows[i].length; j += 1) {
			if (isMoneyColumn(j) && typeof excelRows[i][j] === 'number') {
				summary[j] += excelRows[i][j];
			}
		}
	}

	// 엑셀 워크북 및 워크시트 생성
	const workbook = XLSX.utils.book_new();
	const workSheet = XLSX.utils.aoa_to_sheet([...descriptions, ...headers, ...excelRows, summary]);

	// 설명영역에 대한 스타일 설정
	workSheet.B1.s = {
		font: {
			bold: true,
			sz: '20',
		},
		alignment: { vertical: 'center', horizontal: 'center' },
	};
	workSheet.B2.s = {
		alignment: { vertical: 'center', horizontal: 'center' },
	};

	// 헤더영역에 대한 스타일 설정
	const headerBoundary = {
		start: XLSX.utils.decode_cell('B3'),
		end: { c: headers[0].length - 1, r: 4 },
	};

	for (let { c } = headerBoundary.start; c <= headerBoundary.end.c; c += 1) {
		for (let { r } = headerBoundary.start; r <= headerBoundary.end.r; r += 1) {
			const isLastRow = r === headerBoundary.end.r;
			const cellAddress = XLSX.utils.encode_cell({ r, c });

			workSheet[cellAddress].s = {
				fill: {
					fgColor: { rgb: 'DDDDDD' },
				},
				font: {
					bold: true,
				},
				alignment: { vertical: 'center', horizontal: 'center' },
				border: {
					top: { style: 'thin', color: '#000000' },
					bottom: isLastRow
						? { style: 'medium', color: '#000000' }
						: { style: 'thin', color: '#000000' },
					left: { style: 'thin', color: '#000000' },
					right: { style: 'thin', color: '#000000' },
				},
			};
		}
	}

	// 본몬 내용 스타일 설정
	const bodyBoundary = {
		start: XLSX.utils.decode_cell('B6'),
		end: { c: excelRows[0].length - 1, r: excelRows.length + 5 },
	};

	for (let { c } = bodyBoundary.start; c <= bodyBoundary.end.c; c += 1) {
		for (let { r } = bodyBoundary.start; r <= bodyBoundary.end.r; r += 1) {
			const cellAddress = XLSX.utils.encode_cell({ r, c });
			workSheet[cellAddress].s = {
				border: {
					top: { style: 'thin', color: '#000000' },
					bottom: { style: 'thin', color: '#000000' },
					left: { style: 'thin', color: '#000000' },
					right: { style: 'thin', color: '#000000' },
				},
				...(isMoneyColumn(c)
					? {
							numFmt: '#,##0',
						}
					: {}),
				...(isMoneyColumn(c)
					? { alignment: { vertical: 'center', horizontal: 'right' } }
					: { alignment: { vertical: 'center', horizontal: 'center' } }),
			};
		}
	}

	// 요약영역 스타일 설정
	const summaryBoundary = {
		start: { c: 1, r: excelRows.length + 5 },
		end: { c: summary.length - 1, r: excelRows.length + 5 },
	};

	for (let { c } = summaryBoundary.start; c <= summaryBoundary.end.c; c += 1) {
		for (let { r } = summaryBoundary.start; r <= summaryBoundary.end.r; r += 1) {
			const cellAddress = XLSX.utils.encode_cell({ r, c });
			workSheet[cellAddress].s = {
				border: {
					top: { style: 'thin', color: '#000000' },
					bottom: { style: 'thin', color: '#000000' },
					left: { style: 'thin', color: '#000000' },
					right: { style: 'thin', color: '#000000' },
				},
				numFmt: '#,##0',
				fill: {
					fgColor: { rgb: 'DDDDDD' },
				},
				...(isMoneyColumn(c)
					? { alignment: { vertical: 'center', horizontal: 'right' } }
					: { alignment: { vertical: 'center', horizontal: 'center' } }),
			};
		}
	}

	// 외각선 스타일 설정
	const tableBoundary = {
		start: XLSX.utils.decode_cell('B3'),
		end: { c: excelRows[0].length - 1, r: excelRows.length + 5 },
	};

	for (let { c } = tableBoundary.start; c <= tableBoundary.end.c; c += 1) {
		for (let { r } = tableBoundary.start; r <= tableBoundary.end.r; r += 1) {
			const cellAddress = XLSX.utils.encode_cell({ r, c });
			if (c === tableBoundary.start.c) {
				workSheet[cellAddress].s = {
					...workSheet[cellAddress].s,
					border: {
						...workSheet[cellAddress].s.border,
						left: { style: 'medium', color: '#000000' },
					},
				};
			}
			if (r === tableBoundary.start.r) {
				workSheet[cellAddress].s = {
					...workSheet[cellAddress].s,
					border: {
						...workSheet[cellAddress].s.border,
						top: { style: 'medium', color: '#000000' },
					},
				};
			}
			if (c === tableBoundary.end.c) {
				workSheet[cellAddress].s = {
					...workSheet[cellAddress].s,
					border: {
						...workSheet[cellAddress].s.border,
						right: { style: 'medium', color: '#000000' },
					},
				};
			}
			if (r === tableBoundary.end.r) {
				workSheet[cellAddress].s = {
					...workSheet[cellAddress].s,
					border: {
						...workSheet[cellAddress].s.border,
						bottom: { style: 'medium', color: '#000000' },
					},
				};
			}
		}
	}

	// 셀 너비 설정

	const columnStyles = headers[0].map((_, idx) => {
		if (idx === 0) {
			return { width: 2 };
		}
		if (idx === 62) {
			return { width: 18 };
		}
		if (idx === 63) {
			return { width: 18 };
		}
		const getMaxLength = Math.max(headers[1][idx].length, headers[2][idx].length);
		const minWidth = 14;

		return { width: Math.max(minWidth, getMaxLength * 2.2) };
	});
	workSheet['!cols'] = columnStyles;

	// 셀 병합
	workSheet['!merges'] = [
		{ s: XLSX.utils.decode_cell('B1'), e: XLSX.utils.decode_cell('BM1') },
		{ s: XLSX.utils.decode_cell('B2'), e: XLSX.utils.decode_cell('BM2') },

		{ s: XLSX.utils.decode_cell('B3'), e: XLSX.utils.decode_cell('B5') },
		{ s: XLSX.utils.decode_cell('C3'), e: XLSX.utils.decode_cell('C5') },
		{ s: XLSX.utils.decode_cell('D3'), e: XLSX.utils.decode_cell('D5') },
		{ s: XLSX.utils.decode_cell('E3'), e: XLSX.utils.decode_cell('E5') },
		{ s: XLSX.utils.decode_cell('F3'), e: XLSX.utils.decode_cell('F5') },
		{ s: XLSX.utils.decode_cell('G3'), e: XLSX.utils.decode_cell('G5') },
		{ s: XLSX.utils.decode_cell('H3'), e: XLSX.utils.decode_cell('H5') },
		{ s: XLSX.utils.decode_cell('I3'), e: XLSX.utils.decode_cell('I5') },

		{ s: XLSX.utils.decode_cell('J3'), e: XLSX.utils.decode_cell('AD3') },

		{ s: XLSX.utils.decode_cell('J4'), e: XLSX.utils.decode_cell('J5') },
		{ s: XLSX.utils.decode_cell('K4'), e: XLSX.utils.decode_cell('K5') },
		{ s: XLSX.utils.decode_cell('L4'), e: XLSX.utils.decode_cell('L5') },
		{ s: XLSX.utils.decode_cell('M4'), e: XLSX.utils.decode_cell('M5') },
		{ s: XLSX.utils.decode_cell('N4'), e: XLSX.utils.decode_cell('N5') },
		{ s: XLSX.utils.decode_cell('O4'), e: XLSX.utils.decode_cell('O5') },
		{ s: XLSX.utils.decode_cell('P4'), e: XLSX.utils.decode_cell('P5') },
		{ s: XLSX.utils.decode_cell('Q4'), e: XLSX.utils.decode_cell('Q5') },
		{ s: XLSX.utils.decode_cell('R4'), e: XLSX.utils.decode_cell('R5') },
		{ s: XLSX.utils.decode_cell('S4'), e: XLSX.utils.decode_cell('S5') },
		{ s: XLSX.utils.decode_cell('T4'), e: XLSX.utils.decode_cell('T5') },
		{ s: XLSX.utils.decode_cell('U4'), e: XLSX.utils.decode_cell('U5') },
		{ s: XLSX.utils.decode_cell('V4'), e: XLSX.utils.decode_cell('V5') },
		{ s: XLSX.utils.decode_cell('W4'), e: XLSX.utils.decode_cell('W5') },
		{ s: XLSX.utils.decode_cell('X4'), e: XLSX.utils.decode_cell('X5') },
		{ s: XLSX.utils.decode_cell('Y4'), e: XLSX.utils.decode_cell('Y5') },
		{ s: XLSX.utils.decode_cell('Z4'), e: XLSX.utils.decode_cell('Z5') },
		{ s: XLSX.utils.decode_cell('AA4'), e: XLSX.utils.decode_cell('AA5') },
		{ s: XLSX.utils.decode_cell('AB4'), e: XLSX.utils.decode_cell('AB5') },
		{ s: XLSX.utils.decode_cell('AC4'), e: XLSX.utils.decode_cell('AC5') },
		{ s: XLSX.utils.decode_cell('AD4'), e: XLSX.utils.decode_cell('AD5') },

		{ s: XLSX.utils.decode_cell('AE3'), e: XLSX.utils.decode_cell('AS3') },
		{ s: XLSX.utils.decode_cell('AE4'), e: XLSX.utils.decode_cell('AE5') },
		{ s: XLSX.utils.decode_cell('AF4'), e: XLSX.utils.decode_cell('AF5') },
		{ s: XLSX.utils.decode_cell('AG4'), e: XLSX.utils.decode_cell('AG5') },
		{ s: XLSX.utils.decode_cell('AH4'), e: XLSX.utils.decode_cell('AH5') },
		{ s: XLSX.utils.decode_cell('AI4'), e: XLSX.utils.decode_cell('AI5') },
		{ s: XLSX.utils.decode_cell('AJ4'), e: XLSX.utils.decode_cell('AJ5') },
		{ s: XLSX.utils.decode_cell('AK4'), e: XLSX.utils.decode_cell('AK5') },
		{ s: XLSX.utils.decode_cell('AL4'), e: XLSX.utils.decode_cell('AL5') },
		{ s: XLSX.utils.decode_cell('AM4'), e: XLSX.utils.decode_cell('AM5') },
		{ s: XLSX.utils.decode_cell('AN4'), e: XLSX.utils.decode_cell('AN5') },
		{ s: XLSX.utils.decode_cell('AO4'), e: XLSX.utils.decode_cell('AO5') },
		{ s: XLSX.utils.decode_cell('AP4'), e: XLSX.utils.decode_cell('AP5') },
		{ s: XLSX.utils.decode_cell('AQ4'), e: XLSX.utils.decode_cell('AQ5') },
		{ s: XLSX.utils.decode_cell('AR4'), e: XLSX.utils.decode_cell('AR5') },
		{ s: XLSX.utils.decode_cell('AS4'), e: XLSX.utils.decode_cell('AS5') },

		{ s: XLSX.utils.decode_cell('AT3'), e: XLSX.utils.decode_cell('AT5') },

		{ s: XLSX.utils.decode_cell('AU3'), e: XLSX.utils.decode_cell('BA3') },
		{ s: XLSX.utils.decode_cell('AU4'), e: XLSX.utils.decode_cell('AU5') },
		{ s: XLSX.utils.decode_cell('AV4'), e: XLSX.utils.decode_cell('AV5') },
		{ s: XLSX.utils.decode_cell('AW4'), e: XLSX.utils.decode_cell('AW5') },
		{ s: XLSX.utils.decode_cell('AX4'), e: XLSX.utils.decode_cell('AX5') },
		{ s: XLSX.utils.decode_cell('AY4'), e: XLSX.utils.decode_cell('AY5') },
		{ s: XLSX.utils.decode_cell('AZ4'), e: XLSX.utils.decode_cell('AZ5') },
		{ s: XLSX.utils.decode_cell('BA4'), e: XLSX.utils.decode_cell('BA5') },

		{ s: XLSX.utils.decode_cell('BB3'), e: XLSX.utils.decode_cell('BD3') },
		{ s: XLSX.utils.decode_cell('BB4'), e: XLSX.utils.decode_cell('BB5') },
		{ s: XLSX.utils.decode_cell('BC4'), e: XLSX.utils.decode_cell('BC5') },
		{ s: XLSX.utils.decode_cell('BD4'), e: XLSX.utils.decode_cell('BD5') },

		{ s: XLSX.utils.decode_cell('BE3'), e: XLSX.utils.decode_cell('BG3') },
		{ s: XLSX.utils.decode_cell('BE4'), e: XLSX.utils.decode_cell('BE5') },
		{ s: XLSX.utils.decode_cell('BF4'), e: XLSX.utils.decode_cell('BF5') },
		{ s: XLSX.utils.decode_cell('BG4'), e: XLSX.utils.decode_cell('BG5') },

		{ s: XLSX.utils.decode_cell('BH3'), e: XLSX.utils.decode_cell('BJ3') },
		{ s: XLSX.utils.decode_cell('BH4'), e: XLSX.utils.decode_cell('BH5') },
		{ s: XLSX.utils.decode_cell('BI4'), e: XLSX.utils.decode_cell('BI5') },
		{ s: XLSX.utils.decode_cell('BJ4'), e: XLSX.utils.decode_cell('BJ5') },

		{ s: XLSX.utils.decode_cell('BK3'), e: XLSX.utils.decode_cell('BK5') },
		{ s: XLSX.utils.decode_cell('BL3'), e: XLSX.utils.decode_cell('BL5') },
		{ s: XLSX.utils.decode_cell('BM3'), e: XLSX.utils.decode_cell('BM5') },
		{
			s: { c: 1, r: excelRows.length + 5 },
			e: { c: moneyStartColumn, r: excelRows.length + 5 },
		},
	];

	// 엑셀 파일에 워크시트 추가
	XLSX.utils.book_append_sheet(workbook, workSheet, '입력정보');

	// 파일 저장
	XLSX.writeFile(workbook, `${fileName}.xlsx`);
	return true;
};
