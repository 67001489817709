import React, { useEffect, useMemo, useState } from 'react';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRSpinner from 'components/base/CRSpinner';
import CRTable from 'components/base/CRTable';
import CRTableHeader from 'components/base/CRTableHeader';
import AddDocumentDialog from 'components/domain/dialog/AddDocumentDialog';
import CenterDocumentTable from 'components/domain/table/CenterDocumentTable';
import { useMyAccountInfo } from 'lib/hook/react-query';
import { useMyCenterDocs } from 'lib/hook/react-query/query/document';
import useDialog from 'lib/hook/util/useDialog';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import usePageFilter from 'lib/hook/util/usePageFilter';
import { PageInfo } from 'types/view/base';

import * as S from './styles';

export function CenterDocumentTab() {
	const isCenterChief = useHasPermission('센터장');
	const {
		filters: currentFilter,
		setFilters: setCurrentFilter,
		pageInfo,
		setPageInfo,
	} = usePageFilter({});
	const [keyword, setKeyword] = useState('');
	const { data: myAccountInfo } = useMyAccountInfo();
	const [searchValue, setSearchValue] = useState('');

	const { showDialog } = useDialog();

	const {
		data: centerDocuments,
		isLoading: centerDocumentsLoading,
		refetch,
	} = useMyCenterDocs({
		page: pageInfo.page,
		size: pageInfo.size,
		centerId: myAccountInfo?.centerId,
		keyword,
	});

	const handleRefetch = () => {
		refetch();
	};

	const handleSearchValue = (value: string) => setSearchValue(value);
	const handleSearch = () => {
		setPageInfo((prev) => ({
			...prev,
			page: 1,
		}));
		setKeyword(searchValue);
	};

	const handleChangePageInfo = (pageInfo: PageInfo) => {
		setPageInfo(pageInfo);
	};

	const handleClickAddDocument = () => {
		showDialog(({ hideDialog }) => (
			<AddDocumentDialog onRefresh={handleRefetch} hideDialog={hideDialog} />
		));
	};

	const renderEmpty = useMemo(
		() => (
			<S.EmptyContainer>
				<S.EmptyTitle>등록된 문서 정보가 없습니다.</S.EmptyTitle>
			</S.EmptyContainer>
		),
		[],
	);

	useEffect(() => {
		setPageInfo((prev) => ({
			...prev,
			totalPages: centerDocuments?.totalPages || 0,
		}));
	}, [centerDocuments?.totalPages]);

	return (
		<CRTable.BackBlind
			contentStyle={{
				padding: 0,
			}}>
			<S.TableContainer>
				<S.TableHeaderContainer>
					<CRTableHeader
						onChangePageInfo={handleChangePageInfo}
						pageInfo={pageInfo}
						currentFilter={currentFilter}
						stickyMode
						searchValue={searchValue}
						placeholder='문서 이름으로 검색'
						onChangeSearchValue={handleSearchValue}
						setCurrentFilter={setCurrentFilter}
						onSearch={handleSearch}
						showRefresh
						showViewCount
						onRefresh={handleRefetch}
						renderRightButton={
							isCenterChief ? (
								<CRButton.IconButton
									size='xSmall'
									type='filled'
									iconLeft={Assets.icon.add}
									onClick={handleClickAddDocument}>
									문서 추가
								</CRButton.IconButton>
							) : undefined
						}
					/>
				</S.TableHeaderContainer>
				{centerDocumentsLoading ? (
					<CRSpinner />
				) : centerDocuments && centerDocuments?.content?.length > 0 ? (
					<S.TableScrollContainer>
						<CenterDocumentTable
							isCenterChief={isCenterChief}
							onRefresh={handleRefetch}
							items={centerDocuments?.content || []}
						/>
					</S.TableScrollContainer>
				) : (
					renderEmpty
				)}
			</S.TableContainer>
		</CRTable.BackBlind>
	);
}
