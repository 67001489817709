import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import dayjs, { Dayjs } from 'dayjs';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes, useSaveEmployeeVacation } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { FtimeEmployeeAnnualStateResponseDTO } from 'types/dto';
import { EmployeeVacationFormType, EmployeeWorkInfoViewType } from 'types/view/employee';
import { EmployeeDailyWorkHistoryViewType } from 'types/view/workSchedule';

import * as S from './styles';

interface Props {
	title: string;
	targetDate: Dayjs;
	annualStateInfo: FtimeEmployeeAnnualStateResponseDTO;
	currentEmployee?: EmployeeWorkInfoViewType;
	workhistoryOfTargetMonth?: EmployeeDailyWorkHistoryViewType[];
}

function VacationRegistryDialog({
	title,
	targetDate,
	annualStateInfo,
	currentEmployee,
	workhistoryOfTargetMonth,
}: Props): React.ReactElement {
	const { hideDialog } = useDialog();

	const { data: commonCodes = { CMN195: [] } } = useCommonCodes(
		{ comCdGroupNms: ['CMN195'] },
		commonCodeAdapter,
	);

	const { mutate: createVacation } = useSaveEmployeeVacation((client, data) => {
		if (!data?.centerId) {
			Toast.error('휴가 등록에 실패하였습니다. 잠시 후에 다시 시도해주세요.');
		} else {
			Toast.success('휴가 등록이 완료되었습니다.');
			client.invalidateQueries([
				endpoint.getEmployeeWorkHistory.key,
				{
					employeeId: currentEmployee?.employeeId,
					yearMonth: targetDate.format('YYYYMM'),
				},
			]);
			hideDialog();
		}
	});

	const { control, getValues, watch, handleSubmit } = useForm<EmployeeVacationFormType>({
		mode: 'onChange',
		defaultValues: {},
	});

	const vacationCategoryList = commonCodes.CMN195.filter(
		(item) =>
			item.value === 'CMN195.100' || item.value === 'CMN195.120' || item.value === 'CMN195.999',
	);

	const isRequiredRemark = useMemo(() => {
		if (!getValues('vacationCategory')) return false;
		if (getValues('vacationCategory').value === 'CMN195.999') return true;
		return false;
	}, [watch('vacationCategory')]);

	const disabled = useMemo(
		() =>
			!getValues('vacationCategory') ||
			!getValues('vacationDate') ||
			(getValues('vacationCategory').value === 'CMN195.999' && !getValues('vacationRemark')),
		[watch()],
	);

	const onSubmit = (data: EmployeeVacationFormType) => {
		if (!currentEmployee) {
			Toast.error('휴가 등록에 실패하였습니다. 잠시 후에 다시 시도해주세요.');
			return;
		}
		const vacationStartTime = data.halfDayOfSelection?.[0]?.label === '오후' ? '1330' : '0900';
		const vacationEndTime = data.halfDayOfSelection?.[0]?.label === '오전' ? '1330' : '1800';
		const vacaUseDayCnt =
			data.vacationCategory?.value === 'CMN195.120'
				? 0.5
				: data.vacationCategory?.value === 'CMN195.999'
					? 0
					: 1;
		const workHourCnt = data.vacationCategory?.value === 'CMN195.120' ? 240 : 480;
		const restHourCnt = data.vacationCategory?.value === 'CMN195.120' ? 30 : 60;
		const vacationStartDate = dayjs(data.vacationDate.start);
		const vacationEndDate = dayjs(data.vacationDate.end);

		const daysInVacationDuration = Array.from(
			{ length: vacationEndDate.diff(vacationStartDate, 'day') + 1 },
			(_, i) => vacationStartDate.add(i, 'days').format('YYYYMMDD'),
		).filter((date) => dayjs(date).day() !== 0 && dayjs(date).day() !== 6);

		const totalVacationUseDayCnt = daysInVacationDuration.length * vacaUseDayCnt;
		const hasDuplicatedVacation =
			daysInVacationDuration.filter(
				(date) =>
					workhistoryOfTargetMonth?.find(
						(item) => item.date.isSame(dayjs(date), 'M') && item.date.isSame(dayjs(date), 'D'),
					)?.vacationInfo.length,
			).length > 0;

		const currentRequestBodyArray = daysInVacationDuration.map((date) => ({
			centerId: currentEmployee.centerId,
			yearMonth: targetDate.format('YYYYMM'),
			employeeId: currentEmployee.employeeId,
			workScheduleDivCd: 'CMN193.20',
			vacaKindCd: data.vacationCategory.value,
			workScheduleDt: date,
			startTime: vacationStartTime,
			endTime: vacationEndTime,
			workHourCnt,
			restHourCnt,
			overWorkTimeCnt: 0,
			vacaUseDayCnt,
			remark: data.vacationRemark,
		}));

		if (hasDuplicatedVacation) {
			Toast.error('휴가 기간에 이미 등록된 휴가가 존재합니다.');
			return;
		}
		if ((annualStateInfo.annualRemainDayCnt ?? 0) - totalVacationUseDayCnt < 0) {
			Toast.error('잔여 연차가 부족하여 휴가를 등록할 수 없습니다.');
			return;
		}
		if (!daysInVacationDuration.length) {
			Toast.error('휴가 기간을 다시 선택해주세요.');
			return;
		}
		currentRequestBodyArray.map((requestBody) => createVacation({ body: requestBody }));
	};

	return (
		<CRDialog
			title={title}
			onClickClose={hideDialog}
			type='S'
			body={
				<S.Container>
					<S.RemainVacaDayContainer>
						<S.RemainVacaDay>
							<CRText text='잔여연차' typography='label' color='gray60' />
							<CRText
								text={`${annualStateInfo.annualRemainDayCnt || 0}일`}
								typography='bodyB'
								color='gray10'
							/>
						</S.RemainVacaDay>
						<S.InfoContainer>
							<S.InfoIcon src={Assets.icon.help} />
							<CRText
								text={`${targetDate.format('YYYY년 M월')} ${targetDate
									.endOf('month')
									.format('D')}일 기준으로 산정된 잔여연차입니다.`}
								typography='label'
								color='gray60'
							/>
						</S.InfoContainer>
					</S.RemainVacaDayContainer>

					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInputLabel label='휴가 종류' isRequired>
								<CRInput.Selector
									currentValue={value}
									onChangeValue={onChange}
									placeholder='휴가 종류 선택'
									items={vacationCategoryList}
								/>
							</CRInputLabel>
						)}
						name='vacationCategory'
						control={control}
					/>
					<CRInputLabel label='일시' isRequired>
						{getValues('vacationCategory')?.value === 'CMN195.120' ? (
							<S.HolidayTimeContainer>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInput.DatePicker
											value={value?.start}
											onChangeValue={(date) => onChange({ start: date, end: date })}
											minDate={targetDate.startOf('month').toDate()}
											maxDate={targetDate.endOf('month').toDate()}
											placeholder='일자'
										/>
									)}
									name='vacationDate'
									control={control}
								/>
								<Controller
									render={({ field: { value, onChange } }) => (
										<CRCheckBoxGroup
											checkType='single'
											type='radio'
											gap={0.8}
											onChange={onChange}
											value={value}
											options={[
												{ label: '오전', value: '오전' },
												{ label: '오후', value: '오후' },
											]}
											appearanceType='button'
										/>
									)}
									name='halfDayOfSelection'
									control={control}
								/>
							</S.HolidayTimeContainer>
						) : (
							<Controller
								render={({ field: { onChange, value } }) => (
									<CRInput.DateRangePicker
										value={value}
										onChangeValue={onChange}
										focusMonthDate={targetDate.toDate()}
										minDate={targetDate.startOf('month').toDate()}
										maxDate={targetDate.endOf('month').toDate()}
										selected={dayjs().subtract(5, 'month').toDate()}
										placeholder='시작일 ~ 종료일'
										scrollToNowMonth={false}
									/>
								)}
								name='vacationDate'
								control={control}
							/>
						)}
					</CRInputLabel>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInputLabel label='비고' isRequired={isRequiredRemark}>
								<CRInput.Default
									value={value}
									onChange={onChange}
									placeholder='비고 입력'
									addOnBottom={isRequiredRemark ? '기타 휴가 시 사유 필수' : ''}
								/>
							</CRInputLabel>
						)}
						name='vacationRemark'
						control={control}
					/>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						palette='primary'
						size='default'
						disabled={disabled}
						onClick={handleSubmit(onSubmit)}>
						등록
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}

export default React.memo(VacationRegistryDialog);
