import React, { useMemo } from 'react';

import RouterPath from 'common/router';
import CRPageFooter from 'components/base/CRPageFooter';
import CRTab from 'components/base/CRTab';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import useCenterInfo from 'lib/hook/view/centerInformation/useCenterInfo';

import CenterInformationTab from './CenterInformationTab';
import * as S from './styles';

function CenterInformationPage(): React.ReactElement {
	const isCenterChief = useHasPermission('센터장');
	const informationTab = useMemo(() => RouterPath.myCenterOperate().information, []);
	const { isEdit, toggleEdit, saveCenterInformation } = useCenterInfo();

	return (
		<S.MainComponentContainer>
			<CRTab.Default
				defaultActiveKey={informationTab.key}
				items={[
					{
						label: informationTab.label,
						key: informationTab.key,
						children: <CenterInformationTab />,
					},
				]}
				breadCrumb='센터 정보'
			/>
			{isCenterChief && (
				<S.FooterContainer>
					<S.FooterContentContainer>
						<CRPageFooter
							rightButtons={
								isEdit
									? [
											{
												palette: 'gray',
												buttonType: 'button',
												type: 'text',
												onClick: toggleEdit,
												children: '취소',
											},
											{
												palette: 'primary',
												buttonType: 'button',
												type: 'filled',
												onClick: saveCenterInformation,
												children: '저장',
											},
										]
									: [
											{
												palette: 'primary',
												buttonType: 'button',
												type: 'filled',
												onClick: toggleEdit,
												children: '수정',
											},
										]
							}
						/>
					</S.FooterContentContainer>
				</S.FooterContainer>
			)}
		</S.MainComponentContainer>
	);
}

export default CenterInformationPage;
