import React from 'react';

import CRInputLabel from 'components/base/CRInputLabel';
import { CRText } from 'components/base/CRText';

import * as S from './styles';

export default function ChangeChargeHelp() {
	return (
		<S.FormItemContainer>
			<CRInputLabel label='도움말' type='left-sub' showOverflow betweenGap='2.4rem'>
				<S.HelpText>
					<S.HelpTextTitle>신고 금액 변경 안내</S.HelpTextTitle>
					<S.FormItemContainer>
						<S.HelpTextLabel>
							<CRText typography='bodyB'>1.건강보험 / 고용보험 / 산재보험</CRText>
						</S.HelpTextLabel>
						<S.HelpTextDesc>
							a. 근로자가 신고 기준 금액 변경 요청 시 (단, 허위 신고는 불가)
						</S.HelpTextDesc>
						<S.HelpTextDesc>b. 공단에서 신고 기준 금액 확인 요청하여 변경 필요 시</S.HelpTextDesc>
						<S.HelpTextLabel>
							<CRText typography='bodyB'>2. 국민연금</CRText>
						</S.HelpTextLabel>
						<S.HelpTextDesc>
							a. 착오 등으로 인해 신고 기준 금액을 50만원 이상 높거나 낮게 신고한 경우
							<S.HelpTextDesc>
								i. [요청 사항]란에 ‘취득(내용)정정신고 요청’이라고 기입해야 함
							</S.HelpTextDesc>
							<S.HelpTextDesc>
								ii. 실무 담당자 또는 세무법인에서 요청 시 증빙서류(ex. 근로계약서 등) 첨부 필요
							</S.HelpTextDesc>
						</S.HelpTextDesc>
						<S.HelpTextDesc>
							b. 근로조건 변동(ex. 추가계약으로 인한 근로시간 증가 등)으로 인해 기존 신고 기준 금액
							대비 실제 세전 월 임금이 20%이상 변동(감액 or 증액)되는 경우
							<S.HelpTextDesc>i. 근로자의 동의가 없으면 진행하지 않음</S.HelpTextDesc>
							<S.HelpTextDesc>
								ii. [요청 사항]란에 ‘금액변경신고(기준소득월액 특례) 요청’이라고 기입해야 함
							</S.HelpTextDesc>
							<S.HelpTextDesc>
								iii. 실무 담당자 또는 세무법인에서 요청 시 증빙서류(ex. 근로계약서 등) 첨부 필요
							</S.HelpTextDesc>
						</S.HelpTextDesc>
						<S.HelpTextDesc>c. 공단에서 신고 기준 금액 확인 요청하여 변경 필요 시</S.HelpTextDesc>
					</S.FormItemContainer>
				</S.HelpText>
			</CRInputLabel>
		</S.FormItemContainer>
	);
}
