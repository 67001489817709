import React, { useCallback } from 'react';

import dayjs from 'dayjs';

import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRSpinner from 'components/base/CRSpinner';
import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import CRTableHeader from 'components/base/CRTableHeader';
import { defaultPageInfo } from 'components/base/CRTableHeader/constant';
import { CheckOption } from 'components/base/Selections/type';
import EmptyDescription from 'components/ui/EmptyDescription';
import { PageInfo } from 'types/view/base';
import { Filter } from 'types/view/filter';
import { OwnExpenseMonthlyPay } from 'types/view/ownExpense';

import { EMPLOYEE_SALARY_SEND_STATUS_CONFIG, OWN_EXPENSE_MONTHLY_HEADER_CONFIG } from './constant';
import * as S from './styles';

interface Props<T extends { label: string; value: any }> {
	items?: OwnExpenseMonthlyPay[];
	searchValue?: string;
	filters?: Array<Filter<CheckOption<any>>>;
	pageInfo?: PageInfo;
	isLoading?: boolean;
	currentValue: {
		[key in string]: T[];
	};
	setCurrentFilter?: React.Dispatch<
		React.SetStateAction<{
			[key: string]: CheckOption[];
		}>
	>;
	onSearch?: () => void;
	onChangeSearchValue?: (searchValue: string) => void;
	onClickItem?: (ownExpense: OwnExpenseMonthlyPay) => void;
	onChangePageInfo?: (pageInfo: PageInfo) => void;
	onRefresh?: () => void;
}

function OwnExpenseMonthlyTable<T extends { label: string; value: any }>({
	items = [],
	currentValue,
	searchValue,
	filters,
	pageInfo = defaultPageInfo,
	isLoading,
	setCurrentFilter,
	onChangeSearchValue,
	onClickItem,
	onSearch,
	onChangePageInfo,
	onRefresh,
}: Props<T>): React.ReactElement {
	const isEmpty = items?.length === 0 && !isLoading;
	const OwnExpenseBadge = useCallback(
		(children: string) => (
			<CRStatus options={EMPLOYEE_SALARY_SEND_STATUS_CONFIG}>{children}</CRStatus>
		),
		[],
	);

	const renderBirthDt = useCallback((value: string) => dayjs(value).format('YYYY.MM.DD'), []);

	const renderSalaryYm = useCallback((value: string) => dayjs(value).format('YYYY.MM'), []);

	const renderMainGuardianNm = useCallback((value: string) => value || '-', []);

	return (
		<S.Container>
			<CRTableHeader
				currentFilter={currentValue}
				filters={filters}
				searchValue={searchValue}
				placeholder='수급자명 검색'
				onChangeSearchValue={onChangeSearchValue}
				setCurrentFilter={setCurrentFilter}
				pageInfo={pageInfo}
				onChangePageInfo={onChangePageInfo}
				onSearch={onSearch}
				onRefresh={onRefresh}
				showViewCount
				stickyMode
				showRefresh
			/>
			<S.TableContainer>
				{!isEmpty ? (
					// eslint-disable-next-line react/jsx-no-useless-fragment
					<>
						{isLoading ? (
							<FlexContainer width='100%' height='50rem' justify='center' align='center'>
								<CRSpinner floatMode={false} />
							</FlexContainer>
						) : (
							<CRTable.Root>
								<CRTable.Head heads={OWN_EXPENSE_MONTHLY_HEADER_CONFIG} offset={-47} />
								<CRTable.Body>
									{items.map((item) => (
										<CRTable.Row
											key={item.id}
											item={item}
											renderKeys={[
												'date',
												'recipient',
												'birth',
												'mainGuardian',
												'services',
												'feeAmount',
												'ownExpenseAmount',
												'claimAmount',
												'totalAmountAfterAddition',
												'sendStatus',
												'manager',
											]}
											customRender={{
												sendStatus: OwnExpenseBadge,
												date: renderSalaryYm,
												birth: renderBirthDt,
												mainGuardian: renderMainGuardianNm,
											}}
											customStyle={{
												feeAmount: {
													textAlign: 'right',
												},
												ownExpenseAmount: {
													textAlign: 'right',
												},
												claimAmount: {
													textAlign: 'right',
												},
												totalAmountAfterAddition: {
													textAlign: 'right',
												},
											}}
											onClick={onClickItem}
										/>
									))}
								</CRTable.Body>
							</CRTable.Root>
						)}
					</>
				) : (
					<div style={{ height: '40rem', width: '100%' }}>
						<EmptyDescription
							title='본인부담금 정보가 없습니다.'
							description='검색조건을 수정해주세요.'
						/>
					</div>
				)}
			</S.TableContainer>
		</S.Container>
	);
}

export default OwnExpenseMonthlyTable;
