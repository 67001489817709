import React, { useState } from 'react';

import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import { EditableRecipientFormTypes } from 'lib/provider/recipient';

import RecipientOtherServiceForm from './RecipientOtherServiceForm';
import RecipientOtherServiceView from './RecipientOtherServiceView';
import * as S from './styles';

function RecipientOtherServiceFormView(): React.ReactElement {
	const {
		currentRecipient,
		currentRecipientOtherServices,
		isFormEditable,
		startChangeEditSection,
		cancelChangeEditSection,
		isEditSectionChanging,
	} = useRecipientPage();

	const onStartChangeSection = () => {
		startChangeEditSection(EditableRecipientFormTypes.타급여);
	};

	const onCancelChangeSection = () => {
		cancelChangeEditSection();
	};

	return (
		<S.Container>
			{isFormEditable(EditableRecipientFormTypes.타급여) ? (
				<RecipientOtherServiceForm
					currentRecipient={currentRecipient}
					items={currentRecipientOtherServices}
					onStartChangeSection={onStartChangeSection}
					onCancelChangeSection={onCancelChangeSection}
					isEditSectionChanging={isEditSectionChanging}
				/>
			) : (
				<RecipientOtherServiceView
					items={currentRecipientOtherServices}
					onChangeMode={onStartChangeSection}
				/>
			)}
		</S.Container>
	);
}

export default RecipientOtherServiceFormView;
