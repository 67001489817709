import React from 'react';
import DatePicker from 'react-datepicker';

import { ko } from 'date-fns/esm/locale';
import dayjs, { Dayjs } from 'dayjs';

import { CRText } from 'components/base/CRText';
import { HolidayDTO } from 'types/dto/employee';

import * as S from './styles';

export interface Props {
	currentDate: Dayjs;
	height?: string;
	holidayList?: HolidayDTO[];
	hoverBackgroundColor?: string;
	onChange: (date: Date) => void;
	renderCustomDayHeader?: (date: Dayjs) => React.ReactNode | React.ReactNode[];
	renderCustomDayContent?: (date: Dayjs) => React.ReactNode | React.ReactNode[];
	renderCustomDayFooter?: (date: Dayjs) => React.ReactNode | React.ReactNode[];
	renderCustomCalendarHeader?: () => React.ReactNode | React.ReactNode[];
	renderDayHoverContent?: (date: Dayjs) => React.ReactNode | React.ReactNode[];
}

export default function CRMonthCalendar({
	currentDate,
	height,
	holidayList,
	hoverBackgroundColor = 'none',
	onChange,
	renderCustomDayHeader,
	renderCustomDayContent,
	renderCustomDayFooter,
	renderCustomCalendarHeader,
	renderDayHoverContent,
}: Props) {
	const renderCustomDay = (e: number, date: Date) => {
		const renderItemDate = dayjs(date);

		const firstDayOfMonth = renderItemDate.date() === 1;
		const isToday = dayjs().isSame(renderItemDate, 'day');
		const holiday = holidayList?.filter(
			(item) => dayjs(item.holidayDt).format('YYMMDD') === renderItemDate.format('YYMMDD'),
		);

		const handleDayTextColor = () => {
			if (renderItemDate.month() !== currentDate.month()) return 'gray60';
			if (renderItemDate.day() === 0 || (holiday && holiday.length > 0)) return 'primary60';
			return 'gray00';
		};

		const handleDayText = () => {
			if (firstDayOfMonth) return renderItemDate.format('M월 D일');
			return renderItemDate.format('D');
		};

		const renderDayDate = () => {
			if (isToday)
				return firstDayOfMonth ? (
					<S.DayDateContainer>
						<CRText
							typography='bodyB'
							color={handleDayTextColor()}
							text={renderItemDate.format('M월 ')}
						/>
						<S.Today>{renderItemDate.format('D')}</S.Today>
						<CRText typography='bodyB' color={handleDayTextColor()} text='일' />
					</S.DayDateContainer>
				) : (
					<S.Today>{renderItemDate.format('D')}</S.Today>
				);

			return (
				<CRText typography='bodyB' color={handleDayTextColor()}>
					{handleDayText()}
				</CRText>
			);
		};

		return (
			<S.DayContainer $hoverBackgroundColor={hoverBackgroundColor}>
				<S.DayHeaderContainer>
					<S.DayHeaderContent>
						{renderCustomDayHeader && renderCustomDayHeader(renderItemDate)}
					</S.DayHeaderContent>
					{renderDayDate()}
				</S.DayHeaderContainer>
				<S.DayContentContainer>
					{holiday && holiday.map((item) => <S.Holiday>{item.holidayNm}</S.Holiday>)}
				</S.DayContentContainer>
				{renderCustomDayFooter ? (
					<S.DayFooterContainer>
						<S.HoverContentContainer>
							{renderDayHoverContent && renderDayHoverContent(renderItemDate)}
						</S.HoverContentContainer>
						{renderCustomDayFooter(renderItemDate)}
					</S.DayFooterContainer>
				) : null}
				{renderCustomDayContent && renderCustomDayContent(renderItemDate)}
			</S.DayContainer>
		);
	};

	return (
		<S.Container $height={height}>
			<DatePicker
				locale={ko}
				minDate={currentDate.startOf('month').toDate()}
				maxDate={currentDate.endOf('month').toDate()}
				selected={currentDate.toDate()}
				renderCustomHeader={renderCustomCalendarHeader}
				renderDayContents={renderCustomDay}
				onChange={onChange}
				inline
			/>
		</S.Container>
	);
}
