import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import dayjs from 'dayjs';

import { Toast } from 'components/base/CRToast';
import EDocClientBottomFloating from 'components/ui/EDoc/EDocClientBottomFloating';
import EDocClientButton from 'components/ui/EDoc/EDocClientButton';
import EDocClientHeader, { EDocClientHeaderProps } from 'components/ui/EDoc/EDocClientHeader';
import { displayDayOfWeekInKorean, removeFalsyValues } from 'lib';
import useEDocClientPage from 'lib/hook/eDoc/useESignInfoPage';
import { VisitBathScheduleRecordingInputs } from 'types/api/eDoc';

import {
	EDocRecordSheetsFormProps,
	VisitBathRecordFormFields,
	VisitBathRecordSheetParam,
} from '../types';
import { VisitBathRecordForm } from './VisitBathRecordForm';

const makeDefaultFormData = (defaultValue: VisitBathRecordFormFields) => {
	const emptyStringFiltered = removeFalsyValues(defaultValue);

	const initValue = {
		carNumber: '',
		carChk1: '0',
		carChk2: '0',
		noCarChk1: '0',
		noCarChk2: '0',
		noCarChk3: '0',
		provideChk1: '0',
		provideChk2: '0',
		provideChk3: '0',
		beforeBathChk1: '0',
		beforeBathChk2: '0',
		beforeBathChk3: '0',
		afterBathChk1: '0',
		afterBathChk2: '0',
		afterBathChk3: '0',
		specialContent: '',
	};

	const newObj = {
		...initValue,
		...emptyStringFiltered,
	};
	return newObj;
};

function EDocVisitBathRecordForm({
	onSave,
	onSubmit,
	params,
	isSubmittable,
	disabled,
}: EDocRecordSheetsFormProps<VisitBathRecordSheetParam, VisitBathScheduleRecordingInputs>) {
	const [sheetNumber, setSheetNumber] = useState(0);
	const currentSheetValue = useMemo(
		() => makeDefaultFormData(params[sheetNumber]),
		[sheetNumber],
	) as VisitBathRecordFormFields;
	const methods = useForm<VisitBathRecordFormFields>({ defaultValues: currentSheetValue });
	const isLastPage = sheetNumber >= params.length - 1;
	const { isErp } = useEDocClientPage();
	const [isProcessing, setIsProcessing] = useState(false);

	const clientHeaderProps: EDocClientHeaderProps = useMemo(() => {
		const param = params[sheetNumber];
		const date = dayjs(`${param.year}-${param.month}-${param.day}`);
		const getDate = `${displayDayOfWeekInKorean(date.day())}요일`;

		return {
			recipientNm: param.recipientNm,
			currentDate: date.format('MM월 DD일 ') + getDate,
			endTime: param.endTime,
			startTime: param.startTime,
			totalFormCount: params.length,
			totalMinute: Number(param.totalMinute),
			writtenFormCount: sheetNumber,
		};
	}, [params, sheetNumber]);

	const convertValue = (
		formValues: VisitBathRecordFormFields,
	): VisitBathScheduleRecordingInputs => {
		const isY = (value: string) => value === '1';

		const date = `${currentSheetValue.year}${currentSheetValue.month}${currentSheetValue.day}`;
		return {
			serviceDt: date,
			inputIndex: String(sheetNumber),
			startTime: formValues.startTime.replace(':', ''),
			untagDesc: '',
			inhomeTubBathYn: isY(formValues.noCarChk1),
			longTermInstitutionBathYn: isY(formValues.noCarChk2),
			pbathBathYn: isY(formValues.noCarChk3),
			bathCarNo: formValues.carNumber,
			incarBathYn: isY(formValues.carChk1),
			inhomeBathYn: isY(formValues.carChk2),
			wholebodyBathOfferYn: isY(formValues.provideChk1),
			bathBedOfferYn: isY(formValues.provideChk2),
			bathChairOfferYn: isY(formValues.provideChk3),
			beforebathUridefStateConfirmYn: isY(formValues.beforeBathChk1),
			beforebathBedsoreStateConfirmYn: isY(formValues.beforeBathChk2),
			beforebathSkinStateConfirmYn: isY(formValues.beforeBathChk3),
			afterbathSkinStateConfirmYn: isY(formValues.afterBathChk1),
			afterbathGroomingStateConfirmYn: isY(formValues.afterBathChk2),
			afterbathTidyStateConfirmYn: isY(formValues.afterBathChk3),
			specialDesc: formValues.specialContent,
		};
	};

	const onMoveNextPage = async (formValues: VisitBathRecordFormFields) => {
		if (isProcessing) return;
		setIsProcessing(true);
		try {
			await onSave(convertValue(formValues));
			setSheetNumber((prev) => prev + 1);
			methods.reset(makeDefaultFormData(params[sheetNumber + 1]));
		} catch {
			Toast.error('기록지 저장을 실패했습니다.');
			return;
		} finally {
			setIsProcessing(false);
		}
	};

	const onMovePrevPage = async (formValues: VisitBathRecordFormFields) => {
		if (isProcessing) return;
		setIsProcessing(true);
		try {
			await onSave(convertValue(formValues));
			setSheetNumber((prev) => prev - 1);
			methods.reset(makeDefaultFormData(params[sheetNumber - 1]));
		} catch {
			Toast.error('기록지 저장을 실패했습니다.');
			return;
		} finally {
			setIsProcessing(false);
		}
	};

	const onSubmitHandler = async (formValues: VisitBathRecordFormFields) => {
		if (isProcessing) return;
		setIsProcessing(true);
		try {
			await onSubmit(convertValue(formValues));
			// await onSaveHandler(formValues);
			// console.log(formValues);
		} catch {
			Toast.error('기록지 제출을 실패했습니다.');
			return;
		} finally {
			setIsProcessing(false);
		}
	};

	return (
		<FormProvider {...methods}>
			<EDocClientHeader {...clientHeaderProps} />
			<VisitBathRecordForm disabled={disabled} key={sheetNumber} />
			<EDocClientBottomFloating>
				<EDocClientButton.Prev
					disabled={sheetNumber === 0}
					onClick={methods.handleSubmit(onMovePrevPage)}
				/>
				{isLastPage && isSubmittable ? (
					// eslint-disable-next-line react/jsx-no-useless-fragment
					<EDocClientButton.Submit
						title={isErp ? '검수하기' : '제출하기'}
						onClick={methods.handleSubmit(onSubmitHandler)}
					/>
				) : (
					<EDocClientButton.Next onClick={methods.handleSubmit(onMoveNextPage)} />
				)}
			</EDocClientBottomFloating>
		</FormProvider>
	);
}

export default EDocVisitBathRecordForm;
