import React, { useCallback } from 'react';

import dayjs from 'dayjs';

import CRTable from 'components/base/CRTable';
import { RecipientClaim } from 'types/view/recipient';

import { RECIPIENT_CLAIM_TABLE_HEADER_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	items: RecipientClaim[];
}

function RecipientClaimTable({ items = [] }: Props): React.ReactElement {
	const renderDate = useCallback((value: string) => dayjs(value).format('YYYY.MM'), []);
	return (
		<S.Container>
			<CRTable.Root>
				<CRTable.Head heads={RECIPIENT_CLAIM_TABLE_HEADER_CONFIG} />
				<CRTable.Body>
					{items.map((item, index) => (
						<CRTable.Row
							key={item.id}
							item={{ ...item, index: index + 1 }}
							renderKeys={['date', 'service', 'amount', '']}
							customRender={{ date: renderDate }}
							customStyle={{
								amount: {
									textAlign: 'right',
								},
							}}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default RecipientClaimTable;
