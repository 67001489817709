import styled from 'styled-components';

import Colors from 'common/colors';

export const NormalBadge = styled.div<{
	$bg: keyof typeof Colors;
	$textColor: keyof typeof Colors;
	$width?: string;
	$padding?: string;
}>`
	padding: ${(p) => p.$padding};
	border-radius: 0.4rem;
	font-size: 1.4rem;
	width: ${(p) => p.$width};
	text-align: center;
	background-color: ${(p) => p.theme.colors[p.$bg]};
	color: ${(p) => p.theme.colors[p.$textColor]};
`;

export const EmptyBadge = styled.div`
	font-size: 1.4rem;
	width: 100%;
	text-align: center;
	height: 2.8rem;
`;
