import useCRQuery from 'lib/hook/react-query/base/useCRQuery';
import { endpoint } from 'lib/service/Api/endpoint';

export const useRecipientIntegratedAssessment = useCRQuery(
	endpoint.getRecipientIntegratedAssessment.key,
	'getRecipientIntegratedAssessment',
	{ enabledKey: ['recipientId', 'centerId'] },
);

export const useRecipientIntegratedAssessmentUpdateForm = useCRQuery(
	endpoint.getRecipientIntegratedAssessmentUpdateForm.key,
	'getRecipientIntegratedAssessmentUpdateForm',
);

export const useRecipientIntegratedAssessmentPreviousData = useCRQuery(
	endpoint.getRecipientIntegratedAssessmentPreviousData.key,
	'getRecipientIntegratedAssessmentPreviousData',
	{ enabledKey: ['recipientId', 'recipientIaId'] },
);

export const useRecipientIntegratedAssessmentPreviousList = useCRQuery(
	endpoint.getRecipientIntegratedAssessmentPreviousList.key,
	'getRecipientIntegratedAssessmentPreviousList',
);

export const useRecipientIntegratedAssessmentScore = useCRQuery(
	endpoint.getRecipientIntegratedAssessmentScore.key,
	'getRecipientIntegratedAssessmentScore',
	{ enabledKey: ['targetYear', 'targetSeq'] },
);

export const useRecipientIntegratedAssessmentSalaryOfferSendPreview = useCRQuery(
	endpoint.getRecipientIntegratedAssessmentSalaryOfferSendPreview.key,
	'getRecipientIntegratedAssessmentSalaryOfferSendPreview',
);

export const useRecipientIntegratedAssessmentSalaryOfferChangePlans = useCRQuery(
	endpoint.getRecipientIntegratedAssessmentSalaryOfferChangePlans.key,
	'getRecipientIntegratedAssessmentSalaryOfferChangePlans',
);

export const useRecipientIntegratedAssessmentSalaryOfferChangePlan = useCRQuery(
	endpoint.getRecipientIntegratedAssessmentSalaryOfferChangePlan.key,
	'getRecipientIntegratedAssessmentSalaryOfferChangePlan',
	{
		enabledKey: ['salaryOfferPlanId', 'salaryOfferChangePlanId'],
	},
);
