import React from 'react';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import { CRText } from 'components/base/CRText';

import * as S from './styles';

interface Props {
	onClick: () => void;
	disabled?: boolean;
}

function EDocClientButtonPrev({ onClick, disabled = false }: Props) {
	return (
		<CRButton.Default
			disabled={disabled}
			palette={disabled ? 'gray' : 'primary'}
			type='tonal'
			onClick={onClick}
			style={{ width: '100%', height: '5.6rem' }}>
			<S.Icon src={Assets.icon.keyboardArrowLeft} $disabled={disabled} />
			<CRText typography='h4' color={disabled ? 'gray60' : 'gray10'}>
				이전
			</CRText>
		</CRButton.Default>
	);
}

export default EDocClientButtonPrev;
