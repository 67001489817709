import React, { ChangeEvent, useCallback } from 'react';

import dayjs from 'dayjs';

import Colors from 'common/colors';
import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import { InsuranceState } from 'pages/dashboard/InsuranceDetailPage';
import { EmployeeInsuranceDTO, InsuranceDTO } from 'types/api/insurance';
import { StatusColor } from 'types/view/base';

import { INSURANCE_HEADER_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	items?: InsuranceDTO[];
	checkIds: string[];
	hasPermission: boolean;
	currentRequestState: InsuranceState;
	onClickRow?: (task: InsuranceDTO) => void;
	handleToggleCheckAll: (e: ChangeEvent<HTMLInputElement>) => void;
	handleChangCheckBox: (e: ChangeEvent<HTMLInputElement>) => void;
}

function InsuranceTable({
	items = [],
	checkIds,
	onClickRow,
	handleToggleCheckAll,
	handleChangCheckBox,
	hasPermission = false,
	currentRequestState,
}: Props): React.ReactElement {
	const renderCheckBox = useCallback(
		(insuranceRequestId: number, item?: InsuranceDTO) => (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					height: '3.3rem',
				}}>
				{item &&
					[InsuranceState.대기, InsuranceState.진행중].includes(currentRequestState) &&
					[InsuranceState.대기, InsuranceState.진행중].includes(
						item.insuranceRequestState.id as InsuranceState,
					) &&
					hasPermission && (
						<CRCheckBox
							palette='gray'
							id={String(insuranceRequestId)}
							checked={checkIds.includes(String(insuranceRequestId))}
							onChange={handleChangCheckBox}
						/>
					)}
			</div>
		),
		[checkIds, currentRequestState],
	);

	const renderInsuranceRequestType = useCallback(
		(insuranceRequestType?: string) => (
			<S.ServiceTypeBadgeContainer>
				<S.TextBox>{insuranceRequestType || '-'}</S.TextBox>
			</S.ServiceTypeBadgeContainer>
		),
		[checkIds],
	);

	const renderDate = useCallback(
		(
			approvedKey?: keyof Pick<
				EmployeeInsuranceDTO,
				| 'accidentInsuranceApproveYn'
				| 'employInsuranceApproveYn'
				| 'healthInsuranceApproveYn'
				| 'pensionInsuranceApproveYn'
			>,
		) =>
			function renderPrice(date: string, item?: InsuranceDTO) {
				let isDenied = false;
				if (
					approvedKey &&
					item?.insuranceRequestState?.id === InsuranceState.부분완료 &&
					item?.[approvedKey] === false
				) {
					isDenied = true;
				}
				return (
					<S.TextBox
						style={{
							textAlign: 'right',
							color: isDenied ? Colors.gray60 : Colors.gray10,
							textDecoration: isDenied ? 'line-through' : 'auto',
						}}>
						{date && dayjs(date).isValid() ? dayjs(date).format('YYYY.MM.DD') : '-'}
					</S.TextBox>
				);
			},
		[],
	);

	const renderFullDate = useCallback(
		(date: string) => (
			<S.TextBox>
				{date && dayjs(date).isValid() ? dayjs(date).format('YYYY.MM.DD HH:mm') : '-'}
			</S.TextBox>
		),
		[],
	);

	const renderInsuranceRequestState = useCallback(
		(value: { id: string; text: string; color: StatusColor }) => {
			const options = [{ key: value?.id, label: value?.text, color: value?.color }];
			return <CRStatus options={options}>{value?.id}</CRStatus>;
		},
		[],
	);

	const renderPriceNumber = useCallback(
		(
			approvedKey?: keyof Pick<
				EmployeeInsuranceDTO,
				| 'accidentInsuranceApproveYn'
				| 'employInsuranceApproveYn'
				| 'healthInsuranceApproveYn'
				| 'pensionInsuranceApproveYn'
			>,
		) =>
			function renderPrice(value: number, item?: InsuranceDTO) {
				let isDenied = false;
				if (
					approvedKey &&
					item?.insuranceRequestState?.id === InsuranceState.부분완료 &&
					item?.[approvedKey] === false
				) {
					isDenied = true;
				}
				return (
					<S.TextBox
						style={{
							textAlign: 'right',
							color: isDenied ? Colors.gray60 : Colors.gray10,
							textDecoration: isDenied ? 'line-through' : 'auto',
						}}>
						{value && typeof value === 'number' ? value?.toLocaleString() : '-'}
					</S.TextBox>
				);
			},
		[],
	);

	return (
		<S.Container>
			<CRTable.Root
				style={{
					borderRadius: '0.4rem',
					borderLeft: `0.1rem solid ${Colors.gray90}`,
					borderRight: `0.1rem solid ${Colors.gray90}`,
				}}>
				<CRTable.Head
					checkIds={checkIds}
					onToggleCheck={handleToggleCheckAll}
					heads={hasPermission ? INSURANCE_HEADER_CONFIG : INSURANCE_HEADER_CONFIG.slice(1)}
				/>
				<CRTable.Body>
					{items?.map((item) => (
						<CRTable.Row
							key={item.insuranceRequestId}
							item={{
								...item,
								check: item.insuranceRequestId,
							}}
							stopPropagationKeys={['check']}
							renderKeys={
								hasPermission
									? [
											'check',
											'insuranceRequestState',
											'employeeNm',
											'employeeBirth',
											'insuranceRequestType',
											'pensionInsuranceRequestAmt',
											'pensionInsuranceRequestDate',
											'healthInsuranceRequestAmt',
											'healthInsuranceRequestDate',
											'employInsuranceRequestAmt',
											'employInsuranceRequestDate',
											'accidentInsuranceRequestAmt',
											'accidentInsuranceRequestDate',
											'requestDate',
											'requesterNm',
										]
									: [
											'insuranceRequestState',
											'employeeNm',
											'employeeBirth',
											'insuranceRequestType',
											'pensionInsuranceRequestAmt',
											'pensionInsuranceRequestDate',
											'healthInsuranceRequestAmt',
											'healthInsuranceRequestDate',
											'employInsuranceRequestAmt',
											'employInsuranceRequestDate',
											'accidentInsuranceRequestAmt',
											'accidentInsuranceRequestDate',
											'requestDate',
											'requesterNm',
										]
							}
							customRender={{
								check: renderCheckBox,
								insuranceRequestState: renderInsuranceRequestState,
								employeeBirth: renderDate(),
								insuranceRequestType: renderInsuranceRequestType,
								pensionInsuranceRequestAmt: renderPriceNumber('pensionInsuranceApproveYn'),
								pensionInsuranceRequestDate: renderDate('pensionInsuranceApproveYn'),
								healthInsuranceRequestAmt: renderPriceNumber('healthInsuranceApproveYn'),
								healthInsuranceRequestDate: renderDate('healthInsuranceApproveYn'),
								employInsuranceRequestAmt: renderPriceNumber('employInsuranceApproveYn'),
								employInsuranceRequestDate: renderDate('employInsuranceApproveYn'),
								accidentInsuranceRequestAmt: renderPriceNumber('accidentInsuranceApproveYn'),
								accidentInsuranceRequestDate: renderDate('accidentInsuranceApproveYn'),
								requestDate: renderFullDate,
							}}
							customStyle={{
								check: {
									display: 'flex',
									textAlign: 'center',
									justifyContent: 'center',
								},
							}}
							onClick={onClickRow}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default InsuranceTable;
