import React from 'react';

import dayjs from 'dayjs';

import CRDialog from 'components/base/CRDialog';
import CRInputLabel from 'components/base/CRInputLabel';
import CRStatus from 'components/base/CRStatus';
import InformationTable from 'components/ui/InformationTable';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import { useFullTimeEmployeeLegalEducations } from 'lib/hook/react-query';
import { FullTimeEmployeeDTO } from 'types/dto/full-time-employee';

import * as S from './styles';

interface Props {
	fullTimeEmployee: FullTimeEmployeeDTO;
	hideDialog?: () => void;
}

export default function FullTimeEmployeeStatutoryDetailDialog({
	fullTimeEmployee,
	hideDialog,
}: Props): React.ReactElement {
	const { data: legalEducations } = useFullTimeEmployeeLegalEducations({
		centerId: fullTimeEmployee?.centerId,
		employeeId: fullTimeEmployee?.employeeId,
		memberId: fullTimeEmployee?.memberId,
	});

	if (!legalEducations) return <div />;

	return (
		<CRDialog
			title='법정 의무 교육 이수 확인'
			type='S'
			onClickClose={hideDialog}
			body={
				<S.Container>
					<CRInputLabel label='유효 기간 정보'>
						<InformationTable
							items={[
								[
									{
										label: '교육 이수 기간',
										value: legalEducations
											? `${dayjs(legalEducations?.eduCompleteStartDate).format(
													'YYYY.MM.DD',
												)} ~ ${dayjs(legalEducations?.eduCompleteEndDate).format('YYYY.MM.DD')}`
											: '-',
										labelStyle: {
											width: '12rem',
										},
									},
								],
								[
									{
										label: '유효 기간',
										value: legalEducations
											? `${dayjs(legalEducations?.eduValidStartDate).format(
													'YYYY.MM.DD',
												)} ~ ${dayjs(legalEducations?.eduValidEndDate).format('YYYY.MM.DD')}`
											: '-',
										labelStyle: {
											width: '12rem',
										},
									},
								],
							]}
						/>
					</CRInputLabel>
					<CRInputLabel label='이수 정보'>
						<InformationTable
							items={[
								[
									{
										type: 'label',
										label: '상태',
										labelStyle: {
											width: '9.6rem',
										},
									},
									{
										type: 'label',
										label: '종류',
									},
									{
										type: 'label',
										label: '교육일정',
										labelStyle: {
											width: '11.2rem',
										},
									},
								],
								...(legalEducations?.legalEdus ?? []).map(
									(item) =>
										[
											{
												type: 'value',
												value: (
													<CRStatus
														options={[
															{
																key: '이수 완료',
																color: 'green',
															},
															{
																key: '미이수',
																color: 'red',
															},
														]}>
														{item.completeYn ? '이수 완료' : '미이수'}
													</CRStatus>
												),
											},
											{
												type: 'value',
												value: item.eduTypeNm,
											},
											{
												type: 'value',
												value: item.eduCompleteDate
													? dayjs(item.eduCompleteDate).format('YYYY.MM.DD')
													: '-',
											},
										] as InformationTableItemType[],
								),
							]}
						/>
					</CRInputLabel>
				</S.Container>
			}
		/>
	);
}
