import React, { useMemo, useState } from 'react';

import Assets from 'assets';
import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRTab from 'components/base/CRTab';
import { Toast } from 'components/base/CRToast';
import { useMyAccountInfo } from 'lib/hook/react-query';
import { useDownloadCenterAccountRecordsExcel } from 'lib/hook/react-query/mutation/centerAccount';
import { centerAccountTransfer } from 'lib/util/xlsx/centerAccountTransfer';
import { ResponseCode } from 'types/api';
import { GetCenterAccountRecordsDownloadRequest } from 'types/api/centerAccount';

import CenterAccountTab from './CenterAccountTab';

function CenterAccountPage(): React.ReactElement {
	const centerAccountTab = useMemo(() => RouterPath.myCenterOperate().accountManagement, []);

	const { data: myAccountInfo } = useMyAccountInfo();
	const [isShowExcelDownload, setIsShowExcelDownload] = useState<boolean>(false);
	const [excelDownloadParam, setExcelDownloadParam] = useState<
		GetCenterAccountRecordsDownloadRequest | undefined
	>();

	const { mutate: excelDownload } = useDownloadCenterAccountRecordsExcel((client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			const fileName = `${myAccountInfo?.centerNm}_계좌내역`.replace(/\s/g, '_');
			try {
				centerAccountTransfer(fileName, returnData.data ?? []);
				Toast.success(`[${fileName}] 다운로드에 성공했습니다.`);
			} catch {
				Toast.error(`[${fileName}] 다운로드에 실패했습니다.`);
			}
		} else {
			Toast.error('데이터가 존재하지 않습니다. 관리자에게 문의해주세요.');
			return;
		}
	});
	const handleShowExcelDownload = (show: boolean) => {
		setIsShowExcelDownload(show);
	};

	const handleExcelDownloadParam = (param: GetCenterAccountRecordsDownloadRequest) => {
		setExcelDownloadParam(param);
	};

	const handleClickDownloadExcel = () => {
		if (
			excelDownloadParam?.recordEndDt &&
			excelDownloadParam?.recordStartDt &&
			excelDownloadParam?.centerAccountIds?.length
		) {
			excelDownload(excelDownloadParam);
		}
	};

	return (
		<CRTab.Default
			defaultActiveKey={centerAccountTab.key}
			items={[
				{
					label: centerAccountTab.label,
					key: centerAccountTab.key,
					children: (
						<CenterAccountTab
							onShowExcelDownload={handleShowExcelDownload}
							onChangeExcelDownloadParam={handleExcelDownloadParam}
						/>
					),
				},
			]}
			breadCrumb='센터 계좌'
			renderRightButton={
				isShowExcelDownload ? (
					<CRButton.IconButton
						// disabled={disableControllers}
						palette='gray'
						type='outlined'
						iconLeft={Assets.icon.fileDownload}
						onClick={handleClickDownloadExcel}>
						엑셀 다운로드
					</CRButton.IconButton>
				) : undefined
			}
		/>
	);
}

export default CenterAccountPage;
