import { css } from 'styled-components';

import Colors from './colors';
import Elevation from './elevation';
import Filters from './filter';
import Radius from './radius';
import Typography from './typography';

const Theme = {
	radius: Radius,
	elevation: Elevation,
	colors: Colors,
	typography: Typography,
	filters: Filters,
	// noneSelection: css`
	// 	-webkit-user-select: none; /* Safari */
	// 	-ms-user-select: none; /* IE 10 and IE 11 */
	// 	user-select: none; /* Standard syntax */
	// `,
};

export default Theme;
