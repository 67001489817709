import React, { CSSProperties, ReactElement, useEffect, useRef, useState } from 'react';

import Assets from 'assets';

import * as S from './styles';
import { TabProps } from './type';

interface Props {
	items?: TabProps[];
	menuContainerStyle?: CSSProperties;
	contentContainerStyle?: CSSProperties;
	defaultActiveKey?: string;
	gapBetweenTab?: number;
	breadCrumb?: string;
	renderRightButton?: ReactElement;
	onChange?: (item: TabProps) => void;
}

function MainTab({
	items = [],
	menuContainerStyle,
	contentContainerStyle,
	defaultActiveKey,
	gapBetweenTab = 2.4,
	breadCrumb,
	renderRightButton,
	onChange,
}: Props): React.ReactElement {
	const containerRef = useRef<HTMLUListElement>(null);
	const tabItemRef = useRef<Array<HTMLLIElement | null>>([]);
	const [currentIndex, setCurrentIndex] = useState<number>(() =>
		items?.findIndex((item) => item.key === defaultActiveKey) > -1
			? items?.findIndex((item) => item.key === defaultActiveKey)
			: 0,
	);
	const [activeStyle, setActiveStyle] = useState<CSSProperties>();

	const animateUnderLine = (targetIndex: number) => {
		const containerRect = containerRef.current?.getBoundingClientRect();
		const rect = tabItemRef.current[targetIndex]?.getBoundingClientRect();

		const underLineStyle: CSSProperties = {
			bottom: '-0.1rem',
			left: (rect?.left ?? 0) - (containerRect?.left ?? 0),
			width: rect?.width,
		};

		setActiveStyle(underLineStyle);
	};

	const onClickTab = (selectedItem: TabProps) => {
		const targetIndex = items.findIndex((item) => item.key === selectedItem.key);

		setCurrentIndex(targetIndex);
		animateUnderLine(targetIndex);
		onChange?.(selectedItem);
	};

	useEffect(() => {
		if (items.length > 1) {
			animateUnderLine(currentIndex);
		}
	}, [currentIndex, items]);

	useEffect(() => {
		const index =
			items?.findIndex((item) => item.key === defaultActiveKey) > -1
				? items?.findIndex((item) => item.key === defaultActiveKey)
				: 0;
		setCurrentIndex(index);
	}, [defaultActiveKey, items]);

	return (
		<S.Container>
			<S.MenuContainer>
				<S.MenuLeftContainer
					ref={containerRef}
					style={{ ...(menuContainerStyle ?? {}), gap: `${gapBetweenTab}rem` }}>
					{breadCrumb && (
						<S.BreadCrumb>
							{breadCrumb}
							{items.length > 1 && (
								<img src={Assets.icon.keyboardArrowRight} alt='keyboardArrowRight' />
							)}
						</S.BreadCrumb>
					)}
					{items?.length > 1 &&
						items?.map((item, index) => (
							<S.TabItem
								ref={(el) => {
									tabItemRef.current[index] = el;
								}}
								$isActive={items[currentIndex]?.key === item.key}
								key={item.key}
								onClick={() => onClickTab(item)}>
								{item.label}
							</S.TabItem>
						))}
					{activeStyle && <S.TabUnderLine style={activeStyle} />}
				</S.MenuLeftContainer>
				{renderRightButton && <S.MenuRightContainer>{renderRightButton}</S.MenuRightContainer>}
			</S.MenuContainer>
			<S.TabContent style={contentContainerStyle ?? {}}>
				{items[currentIndex]?.children}
			</S.TabContent>
		</S.Container>
	);
}

export default MainTab;
