import React from 'react';

import CRButton from 'components/base/CRButton';
import { CheckOption } from 'components/base/Selections/type';
import { EmployeeDetailInfoDTO } from 'types/api/employee';

import * as S from './styles';

interface Props {
	item?: EmployeeDetailInfoDTO | null;
	options: {
		payType: CheckOption[];
		bank: CheckOption[];
	};
	onChangeMode: () => void;
}

export default function EmployeePayInfoView({
	item,
	options,
	onChangeMode,
}: Props): React.ReactElement {
	const payTypeKorNm =
		options.payType.find((pay) => pay.value === item?.salaryPayTypeCd)?.label || '-';
	const bankNm = options.bank.find((pay) => pay.value === item?.salaryAccountBankCd)?.label || '-';

	return (
		<S.Container>
			<S.Label>
				임금
				<S.ButtonContainer>
					<CRButton.Default size='xSmall' type='outlined' palette='gray' onClick={onChangeMode}>
						편집
					</CRButton.Default>
				</S.ButtonContainer>
			</S.Label>
			<S.TableContainer>
				<S.Table>
					<S.TableRow>
						<S.TableLabelColumn>수령방식</S.TableLabelColumn>
						<S.TableValueColumn>{payTypeKorNm}</S.TableValueColumn>
						<S.TableLabelColumn>계좌번호</S.TableLabelColumn>
						<S.TableValueColumn>{item?.salaryAccountNo || '-'}</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>은행</S.TableLabelColumn>
						<S.TableValueColumn>{bankNm}</S.TableValueColumn>
						<S.TableLabelColumn>계좌주</S.TableLabelColumn>
						<S.TableValueColumn>{item?.salaryAccountDepositorNm || '-'}</S.TableValueColumn>
					</S.TableRow>
				</S.Table>
			</S.TableContainer>
		</S.Container>
	);
}
