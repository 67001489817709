import React, { ForwardedRef, forwardRef, useImperativeHandle, useState } from 'react';

import dayjs from 'dayjs';

import CRTable from 'components/base/CRTable';
import { CheckOption } from 'components/base/Selections/type';
import EDocNoDialog from 'components/domain/dialog/EdocNoDialog';
import AnnualTOUsageTable from 'components/domain/table/AnnualTOUsageTable';
import { useAnnualTOUsageHistoriesDownload, useMyAccountInfo } from 'lib/hook/react-query';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';

export interface AnnualTOUsageTabForwardRef {
	downloadAnnualTOUsageHistories: () => void;
}

function AnnualTOUsageTab(_, ref: ForwardedRef<AnnualTOUsageTabForwardRef>): React.ReactElement {
	const { showDialog } = useGlobalLayout();
	const [filter, setFilter] = useState<{
		keyword: string;
		dutyFilter: CheckOption[];
		annualTOFilter: CheckOption[];
		rangeFilter: CheckOption[];
		managerFilter: CheckOption[];
	}>({
		keyword: '',
		dutyFilter: [],
		annualTOFilter: [],
		rangeFilter: [
			{
				label: 'rangeFilter',
				value: {
					start: dayjs(new Date()).startOf('y').toDate(),
					end: dayjs(new Date()).endOf('y').toDate(),
				},
			},
		],
		managerFilter: [],
	});
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: annualTOUsageHistoriesDownload = [] } = useAnnualTOUsageHistoriesDownload({
		keyword: filter.keyword,
		centerId: myAccountInfo?.centerId ?? 0,
		vacaStartDt: filter.rangeFilter[0]?.value?.start
			? dayjs(filter.rangeFilter[0]?.value.start).format('YYYYMMDD')
			: undefined,
		vacaEndDt: filter.rangeFilter[0]?.value?.end
			? dayjs(filter.rangeFilter[0]?.value.end).format('YYYYMMDD')
			: undefined,
		employeeId: filter.managerFilter.map((item) => item.value),
		vacaKindCds: filter.annualTOFilter.map((item) => item.value),
		dutyCds: filter.dutyFilter.map((item) => item.value),
	});

	const downloadAnnualTOUsageHistories = () => {
		showDialog(() => (
			<EDocNoDialog
				viewerType='report'
				eDocParamValue={{
					crfeNm: 'template/CMN119_0084_0001.crfe',
					params: (annualTOUsageHistoriesDownload ?? []).map((item, index) => ({
						startEndDate: `${
							filter.rangeFilter[0]?.value.start
								? dayjs(filter.rangeFilter[0]?.value.start).format('YYYY.MM.DD')
								: ''
						}~${
							filter.rangeFilter[0]?.value.end
								? dayjs(filter.rangeFilter[0]?.value.end).format('YYYY.MM.DD')
								: ''
						}`,
						centerNm: myAccountInfo?.centerNm,
						num: `${index + 1}`,
						date: `${dayjs(item.workScheduleDt).format('YYYY.MM.DD')}`,
						workScheduleDivNm: item.vacaKindNm,
						managerNm: item.employeeNm,
						assignTaskNm: item.dutyNm,
						joinDate: `${dayjs(item.joinDate).format('YYYY.MM.DD')}`,
						genderNm: item.genderNm,
						vacaConfirmText: item.vacaConfirmYn ? '확인' : '미확인',
						signNm: item.vacaConfirmDate ? item.employeeNm : '',
					})),
				}}
			/>
		));
	};

	useImperativeHandle(ref, () => ({ downloadAnnualTOUsageHistories }));

	return (
		<CRTable.BackBlind>
			<AnnualTOUsageTable onChangeFilter={setFilter} />
		</CRTable.BackBlind>
	);
}

export default forwardRef<AnnualTOUsageTabForwardRef>(AnnualTOUsageTab);
