import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { Toast } from 'components/base/CRToast';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes, useSaveFullTimeEmployeeDuty } from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import {
	FullTimeEmployeeDTO,
	FullTimeEmployeeDutyDTO,
	FullTimeEmployeeDutyFormDTO,
} from 'types/dto/full-time-employee';

import { FullTimeEmployeeDuty } from '../../../../lib';
import * as S from './styles';

interface Props {
	hideDialog: () => void;
	currentFullTimeEmployee?: FullTimeEmployeeDTO;
	targetCurrentDuty?: FullTimeEmployeeDutyDTO;
}

function AddFullTimeEmployeeHistoryOfDutyDialog({
	currentFullTimeEmployee,
	hideDialog,
	targetCurrentDuty,
}: Props): React.ReactElement {
	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<FullTimeEmployeeDutyFormDTO>({
		mode: 'onChange',
		defaultValues: targetCurrentDuty,
		resolver: yupResolver(FullTimeEmployeeDuty),
	});
	const { data: commonCodes = { CMN070: [] } } = useCommonCodes(
		{ comCdGroupNms: ['CMN070'] },
		commonCodeAdapter,
	);
	const { mutate: saveFullTimeEmployeeDuty } = useSaveFullTimeEmployeeDuty(
		(client, returnData, variables) => {
			if (returnData?.employeeId) {
				client.invalidateQueries([
					endpoint.getFullTimeEmployees.key,
					{
						centerIds: [currentFullTimeEmployee?.centerId],
					},
				]);
				client.invalidateQueries([
					endpoint.getFullTimeEmployeeDuties.key,
					{
						employeeId: currentFullTimeEmployee?.employeeId,
						memberId: currentFullTimeEmployee?.memberId,
						centerId: currentFullTimeEmployee?.centerId,
					},
				]);
				client.invalidateQueries([
					endpoint.getFullTimeEmployeeBaseInfo.key,
					{
						centerId: currentFullTimeEmployee?.centerId,
						employeeId: currentFullTimeEmployee?.employeeId,
					},
				]);
				client.invalidateQueries([
					endpoint.getFullTimeEmployeeDetailInfo.key,
					{
						centerId: currentFullTimeEmployee?.centerId,
						employeeId: currentFullTimeEmployee?.employeeId,
						memberId: currentFullTimeEmployee?.memberId,
					},
				]);
				client.invalidateQueries([
					endpoint.getFullTimeEmployeeWorkStates.key,
					{
						centerId: currentFullTimeEmployee?.centerId,
						employeeId: currentFullTimeEmployee?.employeeId,
						memberId: currentFullTimeEmployee?.memberId,
					},
				]);
				hideDialog();
				Toast.success('정상적으로 직무를 변경하였습니다.');
			}
		},
	);

	const onSubmit = async (data: FullTimeEmployeeDutyFormDTO) => {
		if (!currentFullTimeEmployee?.centerId || !currentFullTimeEmployee?.employeeId) return;

		const param: FullTimeEmployeeDutyFormDTO = {
			ftimeEmployeeDutyHistId: targetCurrentDuty?.ftimeEmployeeDutyHistId,
			employeeId: currentFullTimeEmployee.employeeId,
			dutyStartDate: dayjs(data.dutyStartDate).format('YYYY-MM-DDT09:00:00'),
			dutyCd: data.dutyCd,
			remark: data.remark,
		};

		saveFullTimeEmployeeDuty(param);
	};

	return (
		<CRDialog
			onClickClose={hideDialog}
			type='S'
			title='직무 변경'
			height={800}
			body={
				<S.Form onSubmit={handleSubmit(onSubmit)}>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel isRequired label='변경일'>
								<CRInput.DatePicker
									placeholder='날짜 선택'
									onChangeValue={onChange}
									value={value ? dayjs(value).toDate() : undefined}
								/>
							</CRInputLabel>
						)}
						name='dutyStartDate'
						control={control}
					/>
					<CRInputLabel label='담당 직무' isRequired>
						<Controller
							render={({ field: { onChange, value, ref }, formState: { errors } }) => (
								<CRInput.Selector
									ref={ref}
									items={commonCodes.CMN070.filter((option) => option.data?.etcDesc1 === '1')}
									onChangeValue={(item) => onChange(item.value)}
									currentValue={value ? { label: '', value } : undefined}
									placeholder='담당 직무 선택'
								/>
							)}
							name='dutyCd'
							control={control}
						/>
					</CRInputLabel>
					<CRInputLabel label='비고'>
						<Controller
							render={({ field: { onChange, value, ref }, formState: { errors } }) => (
								<CRInput.TextArea
									placeholder='비고 입력'
									numberOfLines={1}
									onChange={onChange}
									value={value}
								/>
							)}
							name='remark'
							control={control}
						/>
					</CRInputLabel>
				</S.Form>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						disabled={!isValid}
						palette='primary'
						size='default'
						onClick={handleSubmit(onSubmit)}>
						저장
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}

export default AddFullTimeEmployeeHistoryOfDutyDialog;
