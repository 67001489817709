import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import NewMyTaskForm from 'components/domain/form/NewMyTaskForm';
import { TaskTypeCode } from 'components/domain/form/NewMyTaskForm/constant';
import { useCreateTask, useNewSubTask, useNewTask } from 'lib/hook/react-query';
import { ResponseCode } from 'types/api/base';
import { RecipientDTO } from 'types/api/common';

interface Props {
	onClickClose: () => void;
}

export type NewTaskInput = {
	newTaskType?: { value: string; label: string };
	serviceType?: { label: string; value: string; checked: boolean }[];
	documentType?: { value: string; label: string }[];
	resign?: { value: string; label: string; checked: boolean }[];
	recipient?: { value: RecipientDTO; label: string };
	dateRange: { start: Date; end: Date };
};

export default function NewTaskDialog({ onClickClose }: Props): React.ReactElement {
	const { data: newTaskTypesData } = useNewTask();
	const { data: subTaskData, mutate: getSubTask } = useNewSubTask();
	const { control, handleSubmit, watch, setValue } = useForm<NewTaskInput>();
	const { data: createTaskResponse, mutate: createTask } = useCreateTask();

	const newTaskType = watch('newTaskType');

	const onSubmit = (data: NewTaskInput) => {
		if (data.newTaskType?.value === TaskTypeCode.CONTRACT) {
			const a = createTask({
				taskTypeCd: 'CMN120.10',
				taskId: 'SELF_CONTRACT',
				serviceList: ['CMN006.10', 'CMN006.30'],
				recipientId: 3,
				employeeId: 3,
				startDate: '2023-06-27',
				endDate: '2023-06-30',
				serviceYm: '202307',
				serviceWeekNo: '1',
			});
		}
	};

	useEffect(() => {
		if (newTaskType?.value) {
			setValue('serviceType', undefined);
			setValue('documentType', undefined);
			setValue('resign', undefined);
			setValue('recipient', undefined);

			getSubTask({
				taskTypeCd: newTaskType.value,
			});
		}
	}, [newTaskType]);

	useEffect(() => {
		if (createTaskResponse?.code === ResponseCode.SUCCESS) {
			onClickClose?.();
		}
	}, [createTaskResponse]);

	return (
		<NewMyTaskForm
			control={control}
			onSubmit={handleSubmit(onSubmit)}
			newTaskItems={newTaskTypesData?.data}
			subTaskItems={subTaskData?.data}
			onClickClose={onClickClose}
		/>
	);
}
