import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Dayjs } from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import { useSaveEmployeeMonthMaxOverworkTime } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { EmployeeMonthOverworkFormType, EmployeeWorkInfoViewType } from 'types/view/employee';

import * as S from './styles';

interface Props {
	currentEmployee?: EmployeeWorkInfoViewType;
	date: Dayjs;
}

function EmployeeMonthOverworkDialog({
	currentEmployee = {} as EmployeeWorkInfoViewType,
	date,
}: Props): React.ReactElement {
	const { hideDialog } = useDialog();
	const { control, handleSubmit, watch, getValues } = useForm<EmployeeMonthOverworkFormType>({
		mode: 'onChange',
		defaultValues: {
			overworkHour: Math.floor(((currentEmployee.overWorkMaxTimeCnt || 0) * 60) / 60),
			overworkMinute: ((currentEmployee.overWorkMaxTimeCnt || 0) * 60) % 60,
		},
	});

	const { mutate: saveEmployeeMonthMaxOverworkTime } = useSaveEmployeeMonthMaxOverworkTime(
		(client, returnData) => {
			if (returnData?.overWorkMaxTimeCnt) {
				client.invalidateQueries([endpoint.getEmployeeWorkInfoList.key]);
				Toast.success('월 최대 연장근무시간이 성공적으로 설정되었습니다.');
			} else Toast.error('월 최대 연장근무시간 설정에 실패하였습니다. 다시 시도해주세요.');
		},
	);

	const handleOverworkMinuteChange = (
		currentValue: number,
		changeValue: (...event: any[]) => void,
	) => {
		if (currentValue < 30) return changeValue(0);
		return changeValue(30);
	};

	const validateOverworkTime = useMemo(() => {
		const overworkTimeCnt = getValues('overworkHour') * 60 + getValues('overworkMinute');
		if (overworkTimeCnt >= 600) return true;
		return false;
	}, [watch()]);

	const handlehelperTextView = useMemo(
		() =>
			validateOverworkTime ? (
				<CRText typography='label' text='30분 단위 설정 가능' color='gray60' />
			) : (
				<CRText typography='label' text='10시간 이상만 선택할 수 있습니다.' color='primary60' />
			),
		[validateOverworkTime],
	);

	const onSubmit = (data: EmployeeMonthOverworkFormType) => {
		const overworkTimeCnt = data.overworkHour * 60 + data.overworkMinute;
		const overworkRequestData = overworkTimeCnt;

		if (!currentEmployee.centerId || !currentEmployee.employeeId)
			Toast.error('월 최대 연장근무시간 설정에 실패하였습니다. 다시 시도해주세요.');
		else
			saveEmployeeMonthMaxOverworkTime({
				centerId: currentEmployee.centerId,
				employeeId: currentEmployee.employeeId,
				overWorkMaxTimeCnt: overworkRequestData,
				yearMonth: date.format('YYYYMM'),
			});

		hideDialog();
	};

	return (
		<CRDialog
			title={`${
				currentEmployee.korMemberNm ? `${currentEmployee.korMemberNm}님 ` : ''
			}${date.format('MM')}월 연장근무 설정`}
			onClickClose={hideDialog}
			type='S'
			body={
				<S.Container>
					<CRInputLabel label='월 최대 연장근무시간' isRequired>
						<S.WorkTimeContainer>
							<S.InputContainer>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInput.Default
											type='number'
											value={value}
											suffix={
												<CRText text='시간' color='gray60' style={{ whiteSpace: 'nowrap' }} />
											}
											status={validateOverworkTime ? 'default' : 'error'}
											placeholder='0'
											onChange={onChange}
										/>
									)}
									name='overworkHour'
									control={control}
								/>
							</S.InputContainer>
							<S.InputContainer>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInput.Default
											type='number'
											value={value}
											placeholder='0'
											suffix={<CRText text='분' color='gray60' />}
											onChange={onChange}
											status={validateOverworkTime ? 'default' : 'error'}
											onBlur={() => handleOverworkMinuteChange(value, onChange)}
											onPressEnter={() => handleOverworkMinuteChange(value, onChange)}
										/>
									)}
									name='overworkMinute'
									control={control}
								/>
							</S.InputContainer>
						</S.WorkTimeContainer>
						{handlehelperTextView}
					</CRInputLabel>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<S.RightButtonContainer>
						<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
							취소
						</CRButton.Default>
						<CRButton.Default
							palette='primary'
							size='default'
							onClick={handleSubmit(onSubmit)}
							disabled={!validateOverworkTime}>
							저장
						</CRButton.Default>
					</S.RightButtonContainer>
				</S.ButtonContainer>
			}
		/>
	);
}

export default React.memo(EmployeeMonthOverworkDialog);
