import React, { useMemo, useState } from 'react';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInputLabel from 'components/base/CRInputLabel';
import CRTab from 'components/base/CRTab';
import InformationTable from 'components/ui/InformationTable';
import {
	useFullTimeEmployeeAnnualTOHistories,
	useMyAccountInfo,
	useUpdateFullTimeEmployeeAnnualTOHistories,
} from 'lib/hook/react-query';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import {
	FullTimeEmployeeAnnualTOGenerateAdjustDTO,
	FullTimeEmployeeAnnualTORemainAdjustDTO,
	FullTimeEmployeeAnnualTOWorkYearAdjustDTO,
	FullTimeEmployeeDTO,
} from 'types/dto/full-time-employee';

import useDialog from '../../../../lib/hook/util/useDialog';
import { endpoint } from '../../../../lib/service/Api/endpoint';
import { Toast } from '../../../base/CRToast';
import AnnualTOAdjustmentDialog from '../AnnualTOAdjustmentDialog';
import DeleteDialog from '../DeleteDialog';
import FullTimeEmployeeAnnualTODeductionTab from './FullTimeEmployeeAnnualTODeductionTab';
import FullTimeEmployeeAnnualTOOccurrenceTab from './FullTimeEmployeeAnnualTOOccurrenceTab';
import FullTimeEmployeeAnnualTOUsageTab from './FullTimeEmployeeAnnualTOUsageTab';
import FullTimeEmployeeYearsOfServiceTab from './FullTimeEmployeeYearsOfServiceTab';
import * as S from './styles';

interface Props {
	fullTimeEmployee: FullTimeEmployeeDTO;
	hideDialog: () => void;
}

type FullTimeEmployeeAnnualTOAdjustDTO =
	| FullTimeEmployeeAnnualTOGenerateAdjustDTO
	| FullTimeEmployeeAnnualTOWorkYearAdjustDTO
	| FullTimeEmployeeAnnualTORemainAdjustDTO;

export default function FullTimeEmployeeAnnualTODetailDialog({
	fullTimeEmployee,
	hideDialog,
}: Props): React.ReactElement {
	const { showDialog } = useDialog();
	const hasPermission = useHasPermission('센터장');
	const { data: myAccountInfo } = useMyAccountInfo();
	const [command, setCommand] = useState<Array<FullTimeEmployeeAnnualTOAdjustDTO>>([]);

	const isFullTimeEmployeeAnnualTOGenerateAdjustDTO = (
		data: FullTimeEmployeeAnnualTOAdjustDTO,
	): data is FullTimeEmployeeAnnualTOGenerateAdjustDTO => 'annualAdjustDate' in data;

	const isFullTimeEmployeeAnnualTORemainAdjustDTO = (
		data: FullTimeEmployeeAnnualTOAdjustDTO,
	): data is FullTimeEmployeeAnnualTORemainAdjustDTO => 'adjustAnnualCnt' in data;

	const isFullTimeEmployeeAnnualTOWorkYearAdjustDTO = (
		data: FullTimeEmployeeAnnualTOAdjustDTO,
	): data is FullTimeEmployeeAnnualTOWorkYearAdjustDTO => 'workYearCntAdjustDate' in data;

	const executeUpdateCommand = (data: FullTimeEmployeeAnnualTOAdjustDTO) => {
		const newCommand = command.filter(
			(item) =>
				'annualAdjustHistId' in item &&
				'annualAdjustHistId' in data &&
				item.annualAdjustHistId !== data.annualAdjustHistId &&
				'workYearCntAdjustHistId' in item &&
				'workYearCntAdjustHistId' in data &&
				item.workYearCntAdjustHistId !== data.workYearCntAdjustHistId,
		);
		setCommand((prev) => [...newCommand, { ...data, rowType: 'UPDATE' }]);
		hideDialog?.();
	};

	const executeDeleteCommand = (data: FullTimeEmployeeAnnualTOAdjustDTO) => {
		setCommand((prev) => [...prev, { ...data, rowType: 'DELETE' }]);
		hideDialog?.();
	};

	const { data: fullTimeEmployeeAnnualTOHistories } = useFullTimeEmployeeAnnualTOHistories({
		centerId: myAccountInfo?.centerId,
		employeeId: fullTimeEmployee?.employeeId,
		memberId: fullTimeEmployee?.memberId,
	});
	const updateFullTimeEmployeeAnnualTOHistories = useUpdateFullTimeEmployeeAnnualTOHistories(
		(client) => {
			client.invalidateQueries([
				endpoint.getFullTimeEmployeeDetailInfo.key,
				{
					centerId: myAccountInfo?.centerId,
					employeeId: fullTimeEmployee?.employeeId,
					memberId: fullTimeEmployee?.memberId,
				},
			]);
			client.invalidateQueries([
				endpoint.getFullTimeEmployeeAnnualTOHistories.key,
				{
					centerId: myAccountInfo?.centerId,
					employeeId: fullTimeEmployee?.employeeId,
					memberId: fullTimeEmployee?.memberId,
				},
			]);
			Toast.success('연차 이력이 저장되었습니다.');
			hideDialog?.();
		},
		() => {
			Toast.error('연차 이력 저장에 실패하였습니다.');
			hideDialog?.();
		},
	);

	const form = useMemo(() => {
		if (!fullTimeEmployeeAnnualTOHistories) return null;
		const { annualGenerateAdjusts, remainAnnualAdjusts, workYearCntAdjusts } =
			fullTimeEmployeeAnnualTOHistories;

		const newAnnualGenerateAdjusts = annualGenerateAdjusts.map((item) => {
			const exist = command.find(
				(target) =>
					isFullTimeEmployeeAnnualTOGenerateAdjustDTO(target) &&
					target?.annualAdjustHistId === item.annualAdjustHistId,
			);
			if (exist) {
				return exist as FullTimeEmployeeAnnualTOGenerateAdjustDTO;
			}
			return item;
		});

		const newRemainAnnualAdjusts = remainAnnualAdjusts.map((item) => {
			const exist = command.find(
				(target) =>
					isFullTimeEmployeeAnnualTORemainAdjustDTO(target) &&
					target?.annualAdjustHistId === item.annualAdjustHistId,
			);
			if (exist) {
				return exist as FullTimeEmployeeAnnualTORemainAdjustDTO;
			}
			return item;
		});

		const newWorkYearCntAdjusts = workYearCntAdjusts.map((item) => {
			const exist = command.find(
				(target) =>
					isFullTimeEmployeeAnnualTOWorkYearAdjustDTO(target) &&
					target?.workYearCntAdjustHistId === item.workYearCntAdjustHistId,
			);

			if (exist) {
				return exist as FullTimeEmployeeAnnualTOWorkYearAdjustDTO;
			}
			return item;
		});

		return {
			...fullTimeEmployeeAnnualTOHistories,
			annualGenerateAdjusts: newAnnualGenerateAdjusts,
			remainAnnualAdjusts: newRemainAnnualAdjusts,
			workYearCntAdjusts: newWorkYearCntAdjusts,
		};
	}, [command, fullTimeEmployeeAnnualTOHistories]);

	const handleClickUpdateAnnualTODeduction = (data: FullTimeEmployeeAnnualTORemainAdjustDTO) => {
		showDialog(({ hideDialog }) => (
			<AnnualTOAdjustmentDialog
				fullTimeEmployee={fullTimeEmployee}
				hideDialog={hideDialog}
				editItem={data}
				adjustType='CMN191.30'
				onSubmit={executeUpdateCommand}
			/>
		));
	};

	const handleClickDeleteAnnualTODeduction = (data: FullTimeEmployeeAnnualTORemainAdjustDTO) => {
		showDialog(() => (
			<DeleteDialog
				title='연차 조정 이력을 삭제하시겠습니까?'
				content={`${dayjs(data.annualAdjustDate).format(
					'YYYY.MM.DD',
				)} 연차 차감 조정 이력을 삭제합니다.`}
				hideDialog={hideDialog}
				cancelOption={{ text: '취소' }}
				successOption={{
					text: '삭제',
					successCallback: () => executeDeleteCommand(data),
				}}
			/>
		));
	};

	const handleClickUpdateAnnualTOOccurrence = (data: FullTimeEmployeeAnnualTOGenerateAdjustDTO) => {
		showDialog(({ hideDialog }) => (
			<AnnualTOAdjustmentDialog
				fullTimeEmployee={fullTimeEmployee}
				hideDialog={hideDialog}
				editItem={data}
				adjustType='CMN191.20'
				onSubmit={executeUpdateCommand}
			/>
		));
	};

	const handleClickDeleteAnnualTOOccurrence = (data: FullTimeEmployeeAnnualTOGenerateAdjustDTO) => {
		showDialog(() => (
			<DeleteDialog
				title='연차 조정 이력을 삭제하시겠습니까?'
				content={`${dayjs(data.annualAdjustDate).format(
					'YYYY.MM.DD',
				)} 발생 연차 조정 이력을 삭제합니다.`}
				hideDialog={hideDialog}
				cancelOption={{ text: '취소' }}
				successOption={{
					text: '삭제',
					successCallback: () => executeDeleteCommand(data),
				}}
			/>
		));
	};

	const handleClickUpdateWorkYearOfService = (data: FullTimeEmployeeAnnualTOWorkYearAdjustDTO) => {
		showDialog(({ hideDialog }) => (
			<AnnualTOAdjustmentDialog
				fullTimeEmployee={fullTimeEmployee}
				hideDialog={hideDialog}
				editItem={data}
				adjustType='CMN191.10'
				onSubmit={executeUpdateCommand}
			/>
		));
	};

	const handleClickDeleteWorkYearOfService = (data: FullTimeEmployeeAnnualTOWorkYearAdjustDTO) => {
		showDialog(() => (
			<DeleteDialog
				title='연차 조정 이력을 삭제하시겠습니까?'
				content={`${dayjs(data.workYearCntAdjustDate).format(
					'YYYY.MM.DD',
				)} 연차 차감 조정 이력을 삭제합니다.`}
				hideDialog={hideDialog}
				cancelOption={{ text: '취소' }}
				successOption={{
					text: '삭제',
					successCallback: () => executeDeleteCommand(data),
				}}
			/>
		));
	};

	const handleSave = () => {
		if (!form) return;
		updateFullTimeEmployeeAnnualTOHistories.mutate(form);
	};

	if (!form) return <div />;

	return (
		<CRDialog
			onClickClose={hideDialog}
			type='M'
			title='연차 이력'
			body={
				<S.Container>
					<CRInputLabel label='기본 정보'>
						<InformationTable
							items={[
								[
									{
										label: '이름',
										value: fullTimeEmployeeAnnualTOHistories?.employeeNm,
									},
									{
										label: '직무',
										value: fullTimeEmployeeAnnualTOHistories?.dutyNm,
									},
									{
										label: '입사일',
										value: dayjs(fullTimeEmployeeAnnualTOHistories?.joinDate).format('YYYY.MM.DD'),
									},
								],
								[
									{
										label: '근무년수',
										value: fullTimeEmployeeAnnualTOHistories?.workYearCntNm,
									},
									{
										label: '연차 발생일',
										value: dayjs(fullTimeEmployeeAnnualTOHistories?.annualCreationDate).format(
											'YYYY.MM.DD',
										),
									},
									{
										label: '소멸 예정일',
										value: dayjs(
											fullTimeEmployeeAnnualTOHistories?.annualExpirationExpectDate,
										).format('YYYY.MM.DD'),
									},
								],
								[
									{
										label: '사용 연차',
										value: fullTimeEmployeeAnnualTOHistories?.annualUseDayCntNm,
									},
									{
										label: '잔여 연차',
										value: fullTimeEmployeeAnnualTOHistories?.annualRemainDayCntNm,
									},
									{
										type: 'labelValueNull',
									},
								],
							]}
						/>
					</CRInputLabel>
					<CRTab.Sub
						defaultActiveKey='연차 사용'
						items={[
							{
								label: '연차 사용',
								key: '연차 사용',
								children: <FullTimeEmployeeAnnualTOUsageTab items={form.annualUses} />,
							},
							{
								label: '연차 차감',
								key: '연차 차감',
								children: (
									<FullTimeEmployeeAnnualTODeductionTab
										items={form.remainAnnualAdjusts.filter((item) => item.rowType !== 'DELETE')}
										onClickUpdate={handleClickUpdateAnnualTODeduction}
										onClickDelete={handleClickDeleteAnnualTODeduction}
									/>
								),
							},
							{
								label: '발생 연차',
								key: '발생 연차',
								children: (
									<FullTimeEmployeeAnnualTOOccurrenceTab
										items={form.annualGenerateAdjusts.filter((item) => item.rowType !== 'DELETE')}
										onClickUpdate={handleClickUpdateAnnualTOOccurrence}
										onClickDelete={handleClickDeleteAnnualTOOccurrence}
									/>
								),
							},
							{
								label: '근무년수',
								key: '근무년수',
								children: (
									<FullTimeEmployeeYearsOfServiceTab
										items={form.workYearCntAdjusts.filter((item) => item.rowType !== 'DELETE')}
										onClickUpdate={handleClickUpdateWorkYearOfService}
										onClickDelete={handleClickDeleteWorkYearOfService}
									/>
								),
							},
						]}
					/>
				</S.Container>
			}
			footer={
				hasPermission && (
					<S.ButtonContainer>
						<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
							취소
						</CRButton.Default>
						<CRButton.Default palette='primary' size='default' onClick={handleSave}>
							저장
						</CRButton.Default>
					</S.ButtonContainer>
				)
			}
		/>
	);
}
