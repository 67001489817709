import React, { CSSProperties, PropsWithChildren, ReactNode, useState } from 'react';

import Assets from 'assets';

import * as S from './styles';
import { ToolTipBoxBgColor, ToolTipPlacement } from './type';

interface IProps {
	title?: ReactNode;
	arrow?: boolean;
	placement?: ToolTipPlacement;
	containerStyle?: CSSProperties;
	innerStyle?: CSSProperties;
	tooltipBgColor?: ToolTipBoxBgColor;
}

function CRToolTip({
	title = 'Tool Tip',
	arrow = true,
	placement = 'up',
	tooltipBgColor = 'black',
	containerStyle = {},
	children,
	innerStyle = {},
}: PropsWithChildren<IProps>): React.ReactElement {
	const [visible, setVisible] = useState(false);

	return (
		<S.Container
			style={containerStyle}
			onMouseOver={() => setVisible(true)}
			onMouseLeave={() => setVisible(false)}
			placement={placement}>
			{children}
			{title && (
				<S.TooltipBody
					placement={placement}
					visible={visible}
					style={{ ...innerStyle, background: tooltipBgColor }}>
					{arrow && (
						<S.ArrowContainer placement={placement} tooltipBgColor={tooltipBgColor}>
							<img src={Assets.icon.tooltipArrow} alt='arrow' />
						</S.ArrowContainer>
					)}
					{title}
				</S.TooltipBody>
			)}
		</S.Container>
	);
}

export default CRToolTip;
