import { useContext } from 'react';

import { EDocClientPageContext } from 'lib/provider/eDoc/EDocClient';

export default function useEDocClientPage() {
	const context = useContext(EDocClientPageContext);
	if (!context) {
		throw new Error('useEmployeePage must be used within a EmployeePageContext');
	}
	return context;
}
