import React, { useState } from 'react';

import * as Accordion from '@radix-ui/react-accordion';
import dayjs, { Dayjs } from 'dayjs';

import Assets from 'assets';
import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import WorkScheduleCalendar from 'components/domain/calendar/WorkScheduleCalendar';
import EmployeeDailyWorkLogSettingDialog from 'components/domain/dialog/EmployeeDailyWorkLogSettingDialog';
import EmployeeMonthOverworkDialog from 'components/domain/dialog/EmployeeMonthOverworkDialog';
import VacationRegistryDialog from 'components/domain/dialog/VacationRegistryDialog';
import VacationUpdateDialog from 'components/domain/dialog/VacationUpdateDialog';
import VacationViewDialog from 'components/domain/dialog/VacationViewDialog';
import WorkLogDetailDialog from 'components/domain/dialog/WorkLogDetailDialog';
import EmployeeBasicInformationTable from 'components/domain/table/EmployeeBasicInformationTable';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import RdPopover from 'components/ui/radix/popover/RdPopover';
import { employeeWorkHistoryDataAdapter } from 'lib/adapter/workSchedule';
import { useEmployeeWorkHistory, useUpdateWorkHistoryConfirm } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { FtimeEmployeeAnnualStateResponseDTO } from 'types/dto';
import { FtimeEmployeeInfoDTO } from 'types/dto/employee';
import { EmployeeWorkInfoViewType } from 'types/view/employee';
import {
	EmployeeDailyWorkHistoryViewType,
	EmployeeVacationInfoViewType,
} from 'types/view/workSchedule';

import * as S from './styles';

interface Props {
	date: Dayjs;
	currentEmployee?: EmployeeWorkInfoViewType;
}

function RightLayoutContent({ date, currentEmployee }: Props) {
	const [isOpen, setOpen] = useState(false);
	const { showDialog } = useDialog();

	const { data: employeeWorkScheduleData } = useEmployeeWorkHistory(
		{
			centerId: currentEmployee?.centerId as number,
			yearMonth: date.format('YYYYMM') as string,
			employeeId: currentEmployee?.employeeId as number,
		},
		employeeWorkHistoryDataAdapter(date),
	);

	const { mutate: updateWorkHistoryConfirm } = useUpdateWorkHistoryConfirm(
		(client, data, params) => {
			if (!data?.centerId) {
				Toast.error('근무이력 확정에 실패하였습니다. 잠시 후에 다시 시도해주세요.');
			} else {
				const toastText = params.workHistCompleteYn ? '완료' : '취소';
				Toast.success(`근무이력 확정이 ${toastText}되었습니다.`);
				client.invalidateQueries([
					endpoint.getEmployeeWorkHistory.key,
					{
						employeeId: currentEmployee?.employeeId,
						yearMonth: date.format('YYYYMM'),
					},
				]);
			}
		},
	);

	const handleClickConfirmButton = (confirmed: boolean) => () => {
		if (!currentEmployee) return;

		updateWorkHistoryConfirm({
			centerId: currentEmployee?.centerId,
			employeeId: currentEmployee?.employeeId,
			yearMonth: date.format('YYYYMM'),
			workHistCompleteYn: confirmed,
		});
	};

	const handleClickWorkHistoryLabel = (
		date: Dayjs,
		workhistoryOfTargetDate?: EmployeeDailyWorkHistoryViewType,
	) =>
		workhistoryOfTargetDate?.confirmed
			? showDialog(() => <WorkLogDetailDialog targetDate={date} data={workhistoryOfTargetDate} />)
			: showDialog(() => (
					<EmployeeDailyWorkLogSettingDialog
						targetDate={date}
						workhistoryOfTargetDate={workhistoryOfTargetDate}
						currentEmployee={currentEmployee}
					/>
				));

	const handleClickVacationLabel = (
		data: EmployeeVacationInfoViewType,
		confirmed: boolean,
		targetDate: Dayjs,
	) =>
		confirmed
			? showDialog(() => (
					<VacationViewDialog targetDate={targetDate} vacationDataOfTargetDate={data} />
				))
			: showDialog(() => (
					<VacationUpdateDialog
						targetDate={targetDate}
						vacationDataOfTargetDate={data}
						annualStateInfo={
							employeeWorkScheduleData?.annualStateInfo ??
							({} as FtimeEmployeeAnnualStateResponseDTO)
						}
						currentEmployee={currentEmployee}
					/>
				));

	const showEmployeeMonthOverworkDialog = () =>
		showDialog(() => <EmployeeMonthOverworkDialog currentEmployee={currentEmployee} date={date} />);

	const handleClickVacationRegistryButton = () =>
		showDialog(() => (
			<VacationRegistryDialog
				title={date ? `${date.format('YYYY년 M월 휴가 등록')}` : '휴가 등록'}
				targetDate={date}
				annualStateInfo={
					employeeWorkScheduleData?.annualStateInfo ?? ({} as FtimeEmployeeAnnualStateResponseDTO)
				}
				currentEmployee={currentEmployee}
				workhistoryOfTargetMonth={employeeWorkScheduleData?.dailyWorkHistory}
			/>
		));

	const employeeInfo = employeeWorkScheduleData?.employeeBasicInfo ?? {
		korMemberNm: '-',
		dutyNm: '-',
		mobilePhoneNo: '-',
		workExecuteActingEmployeeNm: '-',
		joinDate: '-',
		employState: '-',
		data: {} as FtimeEmployeeInfoDTO,
	};

	const renderDayHoverContent = (targetDate: Dayjs) => {
		const currentDateWorkHistory = employeeWorkScheduleData?.dailyWorkHistory.find(
			(item) => item.date.isSame(targetDate, 'D') && item.date.isSame(targetDate, 'M'),
		);
		const hasWorkHistory =
			currentDateWorkHistory?.workHistoryInfo || currentDateWorkHistory?.shouldRenderNoHistoryLabel;
		const isBeforeToday = targetDate.isBefore(dayjs(), 'D');
		const isSameMonth = targetDate.isSame(date, 'M');

		if (hasWorkHistory || !isSameMonth || !isBeforeToday || currentDateWorkHistory?.confirmed)
			return null;
		return (
			<S.AddWorkHistoryButtonContainer>
				<CRButton.IconButton
					buttonType='button'
					type='filled'
					size='small'
					palette='gray'
					onClick={() => handleClickWorkHistoryLabel(targetDate, currentDateWorkHistory)}
					style={{ gap: '0.8rem', backgroundColor: Colors.gray95 }}
					iconLeft={Assets.icon.whiteAdd}>
					<CRText text='추가' color='gray10' />
				</CRButton.IconButton>
			</S.AddWorkHistoryButtonContainer>
		);
	};

	const renderCustomCalendarHeader = () => {
		const buttonList = [
			{
				label: '연장근로 설정',
				icon: Assets.icon.accessTimeDot,
				onClick: showEmployeeMonthOverworkDialog,
			},
			{
				label: '휴가 등록',
				icon: Assets.icon.add,
				onClick: handleClickVacationRegistryButton,
			},
		];

		return (
			<S.CustomHeaderContainer>
				<CRText typography='h4' text={date.format('YYYY년 M월 일정')} />
				<S.CalendarCustomHeaderContainer>
					{buttonList.map((item) => (
						<CRButton.IconButton
							key={item.label}
							buttonType='button'
							type='outlined'
							size='small'
							palette='gray'
							onClick={item.onClick}
							style={{ padding: '0.6rem 2.4rem 0.6rem 1.6rem', gap: '0.8rem' }}
							iconLeft={item.icon}>
							<CRText typography='body' text={item.label} color='gray10' />
						</CRButton.IconButton>
					))}
					<RdPopover
						showDivider={false}
						side='right'
						align='start'
						borderShape='small'
						sideOffset={-130}
						alignOffset={100}
						isOpen={isOpen}
						setOpen={setOpen}
						content={
							<>
								<S.DropdownItem onClick={handleClickConfirmButton(true)}>확정</S.DropdownItem>
								<S.DropdownItem onClick={handleClickConfirmButton(false)}>취소</S.DropdownItem>
							</>
						}>
						<CRButton.IconButton
							iconRight={isOpen ? Assets.icon.keyboardArrowTop : Assets.icon.keyboardArrowBottom}
							style={{
								display: 'flex',
								gap: '0.8rem',
								padding: '0.6rem 1.6rem 0.6rem 2.4rem',
							}}>
							<CRText typography='body' color='gray100' text='전체 확정' />
						</CRButton.IconButton>
					</RdPopover>
				</S.CalendarCustomHeaderContainer>
			</S.CustomHeaderContainer>
		);
	};

	return (
		<S.Container>
			<S.RightTableContainer>
				<Accordion.Root
					type='multiple'
					style={{
						width: '100%',
						marginBottom: 0,
					}}>
					<Accordion.Item value='직원 정보' asChild>
						<TaskAccordion.Item
							style={{
								marginBottom: 0,
							}}>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger
										style={{
											padding: 0,
										}}>
										직원 정보
									</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild>
								<TaskAccordion.Content
									style={{
										borderTop: 'none',
										padding: '0.8rem 0',
									}}>
									<EmployeeBasicInformationTable {...employeeInfo} />
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
				</Accordion.Root>
			</S.RightTableContainer>
			<S.RightTabContainer>
				<WorkScheduleCalendar
					date={date.toDate()}
					workCalendarData={employeeWorkScheduleData ?? undefined}
					onChange={() => {}}
					handleClickVacationLabel={handleClickVacationLabel}
					handleClickWorkHistoryLabel={handleClickWorkHistoryLabel}
					renderCustomCalendarHeader={renderCustomCalendarHeader}
					renderDayHoverContent={renderDayHoverContent}
				/>
			</S.RightTabContainer>
		</S.Container>
	);
}

export default RightLayoutContent;
