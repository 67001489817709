import React from 'react';

import dayjs from 'dayjs';

import Assets from 'assets';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRIcon from 'components/base/CRIcon';
import { CRText } from 'components/base/CRText';

import DatePickerButton from './DatePickerButton';

interface Props {
	onChange: (date: Date) => void;
	value: Date;
}
function CRTableDatePicker({ onChange, value }: Props): React.ReactElement {
	const date = dayjs(value);

	const onClickPrevMonth = () => {
		onChange(dayjs(value).subtract(1, 'day').toDate());
	};

	const onClickNextPrevMonth = () => {
		onChange(dayjs(value).add(1, 'day').toDate());
	};

	const onClickMonth = (date: Date) => {
		onChange(dayjs(date).toDate());
	};

	return (
		<FlexContainer align='center' gap='0.8rem'>
			<CRIcon
				onClick={onClickPrevMonth}
				src={Assets.icon.keyboardArrowLeft}
				style={{ cursor: 'pointer' }}
				alt='keyboardArrowLeft'
			/>
			<FlexContainer gap='0.8rem' align='center'>
				<CRText typography='h4' text={date.format('YYYY년 M월 DD일')} />
				<DatePickerButton onChangeValue={onClickMonth} value={value} />
			</FlexContainer>
			<CRIcon
				onClick={onClickNextPrevMonth}
				src={Assets.icon.keyboardArrowRight}
				style={{ cursor: 'pointer' }}
				alt='keyboardArrowRight'
			/>
		</FlexContainer>
	);
}

export default CRTableDatePicker;
