import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CheckOption } from 'components/base/Selections/type';
import { ESignSendHistoryDetailDialog } from 'components/domain/dialog/ESignSendHistoryDetailDialog';
import ESignHistoryTable from 'components/domain/table/ESignHistoryTable';
import {
	useCenterManagers,
	useESignHistory,
	useESignHistoryFilter,
	useMyAccountInfo,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import usePageFilter from 'lib/hook/util/usePageFilter';
import { ESignHistoryContentDTO } from 'types/api/send';
import { PageInfo } from 'types/view/base';
import { Filter } from 'types/view/filter';

export default function ESignHistoryTab() {
	const navigate = useNavigate();
	const [keyword, setKeyword] = useState('');
	const {
		filters: currentFilter,
		setFilters: setCurrentFilter,
		pageInfo,
		setPageInfo,
	} = usePageFilter({ paperTypeFilter: [], managerFilter: [], signStatusFilter: [] });

	const { data: myAccountInfo } = useMyAccountInfo();

	const { data: eSignHistoryFilter } = useESignHistoryFilter();
	const {
		data: eSignHistory,
		isLoading: eSigHistoryLoading,
		refetch: eSignHistoryRefetch,
	} = useESignHistory({
		page: pageInfo.page,
		size: pageInfo.size,
		centerId: myAccountInfo?.centerId,
		paperTypeCds: currentFilter?.paperTypeFilter?.map((item) => item.value),
		edocSenderIds: currentFilter?.managerFilter?.map((item) => item.value),
		lastEsignStateCds: currentFilter?.signStatusFilter?.map((item) => item.value),
		edocTitle: keyword,
	});

	const { data: centerManagers } = useCenterManagers({
		centerId: myAccountInfo?.centerId,
	});

	const { showDialog } = useDialog();

	const [searchValue, setSearchValue] = useState('');

	const handleSearchKeyword = (value: string) => setSearchValue(value);

	const handleSearch = () => {
		setPageInfo((prev) => ({
			...prev,
			page: 1,
		}));
		setKeyword(searchValue);
	};

	const handleChangePageInfo = (pageInfo: PageInfo) => {
		setPageInfo(pageInfo);
	};

	const paperTypeFilter = useMemo(
		() =>
			({
				key: 'paperTypeFilter',
				type: 'multi',
				options: (eSignHistoryFilter?.paperTypeCds ?? []).map((item) => ({
					label: item.korComCdAliasNm,
					value: item.comCdId,
				})),
				placeholder: '문서유형',
			}) as Filter<CheckOption>,
		[eSignHistoryFilter?.paperTypeCds],
	);

	const managerFilter = useMemo(
		() =>
			({
				key: 'managerFilter',
				type: 'multi',
				options:
					centerManagers?.map((item) => ({
						label: item.userNm,
						value: item.memberAccountId,
					})) ?? [],
				placeholder: '발송자',
			}) as Filter<CheckOption>,
		[centerManagers],
	);

	const signStatusFilter = useMemo(
		() =>
			({
				key: 'signStatusFilter',
				type: 'multi',
				options: (eSignHistoryFilter?.lastEsignStateCds ?? [])
					?.filter((item) => item.comCdGroupNm === 'CMN171')
					.map((item) => ({
						label: item.korComCdAliasNm,
						value: item.comCdId,
					})),
				placeholder: '상태',
			}) as Filter<CheckOption>,
		[eSignHistoryFilter?.lastEsignStateCds],
	);

	const handleClickRow = (item: ESignHistoryContentDTO) => {
		showDialog(() => (
			<ESignSendHistoryDetailDialog
				centerId={myAccountInfo?.centerId}
				edocSendId={item.edocSendId}
				navigate={navigate}
			/>
		));
	};

	useEffect(() => {
		setPageInfo((prev) => ({
			...prev,
			totalPages: eSignHistory?.totalPages || 0,
		}));
	}, [eSignHistory?.totalPages]);

	// 사회복지사 기본필터를 자신으로 설정
	useLayoutEffect(() => {
		if (myAccountInfo && myAccountInfo?.positionCd === 'CMN103.10') {
			setCurrentFilter((prev) => {
				const alreadyManagerExist = Boolean(
					prev?.managerFilter?.find((manager) => manager.value === myAccountInfo.memberAccountId),
				);
				if (alreadyManagerExist) {
					return prev;
				}
				return {
					...prev,
					managerFilter: [
						...(prev?.managerFilter || []),
						{ value: myAccountInfo.memberAccountId, label: myAccountInfo.userNm },
					],
				};
			});
		}
	}, [myAccountInfo?.memberAccountId]);

	return (
		<ESignHistoryTable
			onRefresh={() => eSignHistoryRefetch()}
			isLoading={eSigHistoryLoading}
			filters={[signStatusFilter, paperTypeFilter, managerFilter]}
			setCurrentFilter={setCurrentFilter}
			currentFilter={currentFilter}
			items={eSignHistory?.content}
			pageInfo={pageInfo}
			onChangePageInfo={handleChangePageInfo}
			searchValue={searchValue}
			onChangeSearchValue={handleSearchKeyword}
			onSearch={handleSearch}
			onClickRow={handleClickRow}
		/>
	);
}
