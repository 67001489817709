import {
	QueryClient,
	UseMutationOptions,
	UseMutationResult,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';

import { useApi } from 'lib/provider/service/Api';
import IApi from 'lib/service/Api/IApi';
import { MethodParametersAndReturnType } from 'types/view/util';

function useCRMutation<T extends keyof IApi>(
	queryKey: string,
	action: T,
	option: Omit<
		UseMutationOptions<
			MethodParametersAndReturnType<IApi, T>[1],
			unknown,
			MethodParametersAndReturnType<IApi, T>[0],
			unknown
		>,
		'mutationFn'
	> = {},
) {
	return (
		onSuccess?: (
			client: QueryClient,
			data: MethodParametersAndReturnType<IApi, T>[1],
			variables: MethodParametersAndReturnType<IApi, T>[0],
		) => void,
		onError?: (
			client: QueryClient,
			error: unknown,
			variables: MethodParametersAndReturnType<IApi, T>[0],
		) => void,
	): UseMutationResult<
		MethodParametersAndReturnType<IApi, T>[1],
		unknown,
		MethodParametersAndReturnType<IApi, T>[0],
		unknown
	> => {
		const api = useApi();
		const client = useQueryClient();

		return useMutation(
			(
				api[action] as (
					param: MethodParametersAndReturnType<IApi, T>[0],
				) => Promise<MethodParametersAndReturnType<IApi, T>[1]>
			).bind(api),
			{
				mutationKey: [queryKey],
				onError: (error: unknown, variables: MethodParametersAndReturnType<IApi, T>[0]) => {
					onError?.(client, error, variables);
				},
				onSuccess: (
					data: MethodParametersAndReturnType<IApi, T>[1],
					variables: MethodParametersAndReturnType<IApi, T>[0],
				) => {
					onSuccess?.(client, data, variables);
				},
				...option,
			},
		);
	};
}

export default useCRMutation;
