import React, { ForwardedRef, forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import * as Pop from '@radix-ui/react-popover';

import Assets from 'assets';
import { CRText } from 'components/base/CRText';
import RdPopover from 'components/ui/radix/popover/RdPopover';

import * as S from './styles';

type Props = {
	value?: string;
	onChange?: (value: string) => void;
	placeholder?: string;
	disable?: boolean;
	hourStepNumber?: number;
	minuteStepNumber?: number;
	helperText?: string;
	error?: boolean;
	errorMessage?: string;
	seperated?: boolean;
};

function CRTimeSelector(
	{
		value = '',
		onChange,
		placeholder,
		disable = false,
		hourStepNumber = 1,
		minuteStepNumber = 1,
		helperText,
		error,
		errorMessage,
		seperated = true,
	}: Props,
	ref?: ForwardedRef<HTMLDivElement>,
): React.ReactElement {
	const containerRef = useRef<HTMLInputElement>(null);
	const itemWrapperRef = useRef<HTMLDivElement>(null);
	const itemListRef = useRef<HTMLUListElement>(null);
	const itemRef = useRef<HTMLButtonElement>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);

	const [currentHour, currentMinute] = value.split(':');
	const [focus, setFocus] = useState(false);

	const minuteOptions = Array.from({ length: Math.floor(60 / minuteStepNumber) }, (_, i) =>
		(i * minuteStepNumber).toString().padStart(2, '0'),
	);

	const hourOptions = Array.from({ length: Math.floor(24 / hourStepNumber) }, (_, i) =>
		(i * hourStepNumber).toString().padStart(2, '0'),
	);

	const totalTimeOptions = Array.from({ length: Math.floor(24 / hourStepNumber) }, (_, i) =>
		minuteOptions.map((minute) => `${(i * hourStepNumber).toString().padStart(2, '0')}:${minute}`),
	).flat();

	const [position, setPosition] = useState({
		top: 0,
		left: 0,
		height: 0,
		width: 0,
	});

	const handleHourChange = (hour: string) =>
		onChange?.(`${hour}:${currentMinute || minuteOptions[0]}`);
	const handleMinuteChange = (minute: string) =>
		onChange?.(`${currentHour || hourOptions[0]}:${minute}`);

	const handleResizeEvent = useCallback(() => {
		const boundingClientRect = containerRef.current?.getBoundingClientRect();
		if (!boundingClientRect) return;

		const { height, width } = boundingClientRect;
		setPosition({ top: height - 1, left: 0, height, width });
	}, []);

	const renderOptionPopUp = (
		options: string[],
		currentValue: string,
		onClickOption: (value: string) => void,
		rightBorder?: boolean,
	) => {
		const optionContainerStyle = {
			width: Math.floor(((wrapperRef.current?.clientWidth ?? 0) - 10) / 2),
			top: wrapperRef.current?.clientHeight ? wrapperRef.current.clientHeight / 2 : position.top,
			left: position.left,
		};
		const optionStype = {
			background: 'transparent',
			border: 0,
			padding: 0,
			margin: 0,
			cursor: 'pointer',
			outline: 'none',
		};

		return (
			<S.ItemListContainer
				$isEmpty={options.length === 0}
				$rightBorder={rightBorder}
				ref={itemListRef}
				style={optionContainerStyle}>
				{options.map((item) => (
					<Pop.Close ref={itemRef} style={optionStype}>
						<S.TimeOption
							key={`${item}`}
							onClick={() => onClickOption(item)}
							$selected={item === currentValue}>
							{item}
						</S.TimeOption>
					</Pop.Close>
				))}
			</S.ItemListContainer>
		);
	};
	useEffect(() => {
		handleResizeEvent();
		document.addEventListener('resize', handleResizeEvent);

		return () => {
			document.removeEventListener('resize', handleResizeEvent);
		};
	}, [handleResizeEvent]);

	return (
		<div ref={ref} style={{ display: 'flex', flexDirection: 'column' }}>
			<RdPopover
				openCallback={() => !disable && setFocus(true)}
				closeCallback={() => !disable && setFocus(false)}
				sideOffset={0}
				showDivider={false}
				borderShape='none'
				content={
					!disable && (
						<S.SelectorContainer
							ref={itemWrapperRef}
							style={{
								width: wrapperRef.current?.clientWidth,
								top: wrapperRef.current?.clientHeight
									? wrapperRef.current.clientHeight / 2
									: position.top,
								left: position.left,
							}}>
							{!seperated ? (
								renderOptionPopUp(totalTimeOptions, value, onChange ?? (() => {}))
							) : (
								<>
									{renderOptionPopUp(hourOptions, currentHour, handleHourChange, true)}
									{renderOptionPopUp(minuteOptions, currentMinute, handleMinuteChange)}
								</>
							)}
						</S.SelectorContainer>
					)
				}>
				<S.Container ref={wrapperRef} $isDisabled={disable} $focus={focus} $error={error}>
					<S.ContentContainer
						$hasValue={!!value}
						ref={containerRef}
						$isDisabled={disable}
						aria-disabled
						value={value}
						placeholder={placeholder}
						$error={error}
						onClick={() => !disable && setFocus((prev) => !prev)}
					/>
					<S.Icon src={Assets.icon.accessTimeBlack} alt='accessTime' />
				</S.Container>
			</RdPopover>
			{(helperText || (error && errorMessage)) && (
				<CRText
					text={error ? errorMessage : helperText}
					color={error ? 'primary60' : 'gray60'}
					typography='label'
				/>
			)}
		</div>
	);
}

export default forwardRef(CRTimeSelector);
