import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import { Toast } from 'components/base/CRToast';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { LinkAccount } from 'lib';
import { useLinkAccounts } from 'lib/hook/react-query/mutation/userAccount';
import useDialog from 'lib/hook/util/useDialog';
import { ResponseCode } from 'types/api';
import { GetLinkAccountsDTO } from 'types/api/userAccount';
import { LinkAccountForm } from 'types/view/userAccount';

import AccountInfoCard from './AccountInfoCard';
import * as S from './styles';

interface Props {
	memberAccountId: number;
	data: GetLinkAccountsDTO[];
	refetch: () => void;
}

export function LinkAccountDialog({ memberAccountId, data, refetch }: Props) {
	const { hideDialog } = useDialog();
	const linkAccountsMutation = useLinkAccounts((client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			refetch();
			Toast.success('직원 정보가 연동되었습니다.');
		} else {
			Toast.error('직원 정보 연동에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
		hideDialog();
	});

	const {
		control,
		handleSubmit,
		trigger,
		formState: { isValid },
	} = useForm<LinkAccountForm>({
		resolver: yupResolver(LinkAccount),
	});

	const onSubmit = (formData: LinkAccountForm) => {
		linkAccountsMutation.mutate({
			memberAccountId,
			memberId: formData.account[0].value,
		});
	};

	const submitForm = () => {
		trigger().then((isValid) => {
			if (isValid) {
				handleSubmit(onSubmit)();
			}
		});
	};

	const accounts = useMemo(
		() =>
			(data || [])?.map((item) => ({
				label: <AccountInfoCard item={item} />,
				value: item.memberId,
				data: item,
			})),
		[data],
	);

	return (
		<CRDialog
			height='80rem'
			showDivider
			onClickClose={hideDialog}
			type='S'
			title='직원 정보 연동'
			body={
				<S.Container>
					<S.SubTitle>생성된 직원 정보</S.SubTitle>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRCheckBoxGroup
								direction='col'
								onChange={onChange}
								appearanceType='button'
								checkType='single'
								type='radio'
								gap={0.8}
								value={value}
								options={accounts}
							/>
						)}
						name='account'
						control={control}
					/>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						palette='primary'
						size='default'
						disabled={!isValid}
						onClick={submitForm}>
						연동
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
