import React, { ChangeEvent, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import * as Accordion from '@radix-ui/react-accordion';

import Assets from 'assets';
import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import CRFilterGroup from 'components/base/CRInput/CRFilterGroup';
import CRTab from 'components/base/CRTab';
import { TaskTabProps } from 'components/base/CRTab/TaskTab/type';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import RecipientEnrollDialog from 'components/domain/dialog/RecipientEnrollDialog';
import InformationLayout from 'components/domain/layout/InformationLayout';
import RecipientListTable from 'components/domain/table/RecipientListTable';
import RecipientInformationTable from 'components/ui/RecipientDetailBasicInformationTable';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import { useMyAccountInfo, useRecipientExcelInfo } from 'lib/hook/react-query';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import { exportRecipientsInfoTransfer } from 'lib/util/xlsx/RecipientsInfoTransfer';
import { Recipient } from 'types/view/recipient';

import { TAB_CONFIG } from './config';
import * as S from './styles';

function RecipientPage(): React.ReactElement {
	const { showDialog, breadCrumbs } = useGlobalLayout();
	const navigate = useNavigate();

	const {
		isLoading,
		searchText,
		setSearchText,
		currentTab,
		recipients,
		currentRecipient,
		setCurrentRecipient,
		currentRecipientInfoSummary,
		currentFilter,
		setCurrentFilter,
		stateFilter,
		serviceFilter,
		managerFilter,
		expiredFilter,
	} = useRecipientPage();

	const { mutateAsync: getRecipientsExcelInfo, isLoading: excelDownloadLoading } =
		useRecipientExcelInfo();
	const { data: myAccountInfo } = useMyAccountInfo();

	const handleClickRecipientEnroll = () => {
		const onSuccess = (recipientId: number) => {
			navigate(`/${RouterPath.recipient().tab.uniqueKey}/${recipientId}/basic?isNew=true`);
		};
		showDialog(({ hideDialog }) => (
			<RecipientEnrollDialog onClickClose={hideDialog} onSuccess={onSuccess} />
		));
	};

	const handleClickTab = (tab: TaskTabProps) => {
		navigate(
			`/${RouterPath.recipient().tab.uniqueKey}/${currentRecipient?.recipientId}/${tab.key}`,
		);
	};

	const handleClickDetailItem = (item: Recipient) => {
		setCurrentRecipient(item);
		navigate(`/${RouterPath.recipient().tab.uniqueKey}/${item?.recipientId}/${currentTab}`);
	};

	const handleChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchText(event.target.value);
	};

	const downloadRecipientsExcelInfoTransferHandler = async () => {
		if (!myAccountInfo?.centerId) return;
		const res = await getRecipientsExcelInfo({
			centerId: String(myAccountInfo.centerId),
		});

		if (!res || !res.length) {
			Toast.error('다운로드 할 자료가 존재하지 않습니다. 관리자에게 문의해주세요.');
			return;
		}

		const fileName = `수급자목록_${myAccountInfo.centerNm}`.replace(/\s/g, '_');

		try {
			exportRecipientsInfoTransfer(fileName, res);
			Toast.success(`[${fileName}] 다운로드에 성공했습니다.`);
		} catch {
			Toast.error(`[${fileName}] 다운로드에 실패했습니다.`);
		}
	};

	const LeftContentComponent = useMemo(
		() => (
			<S.Container>
				<S.Header>
					<S.Title>수급자 목록</S.Title>
					<CRButton.IconButton
						iconLeft={Assets.icon.add}
						type='filled'
						palette='primary'
						size='xSmall'
						onClick={handleClickRecipientEnroll}>
						수급자 추가
					</CRButton.IconButton>
				</S.Header>
				<CRInput.Search
					placeholder='수급자명, 생년월일, 전화번호 검색'
					value={searchText}
					onChange={handleChangeSearchText}
				/>
				<CRFilterGroup
					filters={[stateFilter, serviceFilter, managerFilter, expiredFilter]}
					currentFilter={currentFilter}
					setCurrentFilter={setCurrentFilter}
				/>
				<S.TableContainer>
					<RecipientListTable
						items={recipients}
						currentItem={currentRecipient}
						onClickRecipient={handleClickDetailItem}
						searchValue={searchText}
					/>
				</S.TableContainer>
				<S.LeftLayoutBottomContainer>
					<CRText text={`총 ${recipients?.length}명`} />
					<CRButton.IconButton
						isLoading={excelDownloadLoading}
						size='small'
						palette='gray'
						type='outlined'
						iconLeft={Assets.icon.download}
						onClick={downloadRecipientsExcelInfoTransferHandler}>
						엑셀 다운로드
					</CRButton.IconButton>
				</S.LeftLayoutBottomContainer>
			</S.Container>
		),
		[
			currentFilter,
			currentRecipient,
			searchText,
			serviceFilter,
			stateFilter,
			managerFilter,
			recipients,
			currentTab,
			expiredFilter,
			excelDownloadLoading,
		],
	);

	const RightContentComponent = useMemo(
		() => (
			<S.Container>
				<S.RightTableContainer>
					<Accordion.Root
						type='multiple'
						style={{
							width: '100%',
							marginBottom: 0,
						}}
						defaultValue={['기본 정보']}>
						<Accordion.Item value='기본 정보' asChild>
							<TaskAccordion.Item
								style={{
									marginBottom: 0,
								}}>
								<Accordion.Header asChild>
									<Accordion.Trigger asChild>
										<TaskAccordion.Trigger
											style={{
												padding: 0,
											}}>
											수급자 정보
										</TaskAccordion.Trigger>
									</Accordion.Trigger>
								</Accordion.Header>
								<Accordion.Content asChild>
									<TaskAccordion.Content
										style={{
											borderTop: 'none',
											padding: '0.8rem 0',
										}}>
										<RecipientInformationTable item={currentRecipientInfoSummary} />
									</TaskAccordion.Content>
								</Accordion.Content>
							</TaskAccordion.Item>
						</Accordion.Item>
					</Accordion.Root>
				</S.RightTableContainer>
				<S.RightTabContainer>
					<CRTab.Task items={TAB_CONFIG} defaultActiveKey={currentTab} onChange={handleClickTab} />
					<S.RightTabViewContainer>
						<S.TabView>
							<Outlet />
						</S.TabView>
					</S.RightTabViewContainer>
				</S.RightTabContainer>
			</S.Container>
		),
		[currentRecipientInfoSummary, currentTab, handleClickTab],
	);

	if (isLoading) {
		return <div />;
	}

	if (breadCrumbs.length > 1) {
		return <Outlet />;
	}

	return (
		<InformationLayout
			LeftContentComponent={LeftContentComponent}
			RightContentComponent={RightContentComponent}
		/>
	);
}

export default React.memo(RecipientPage);
