import React, { useEffect, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import * as Accordion from '@radix-ui/react-accordion';
import dayjs from 'dayjs';

import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { CRText } from 'components/base/CRText';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import InformationTable from 'components/ui/InformationTable';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import { useMyAccountInfo } from 'lib/hook/react-query';
import { useInsurancePriorInfo } from 'lib/hook/react-query/query/insurance';
import { InsurancePriorInfoDTO } from 'types/api/insurance';
import { InsuranceCancelFormType } from 'types/view/insurance';

import { InsuranceType } from '..';
import * as S from './styles';

interface Props {
	context: UseFormReturn<InsuranceCancelFormType, any, undefined>;
	requestTypeCd?: string;
}

export default function InsuranceAcquirementCancelForm({ context, requestTypeCd }: Props) {
	const { data: myAccountInfo } = useMyAccountInfo();
	const params = useParams<{ id: string }>();
	const { data: insurancePriorInfo } = useInsurancePriorInfo({
		employeeId: Number(params.id),
		centerId: myAccountInfo?.centerId,
		insuranceTypeCd: requestTypeCd,
	});

	const formData = context.watch();
	const { insuranceType } = formData;

	const displayAcqDate = (acqDate?: string) => (
		<CRText
			text={acqDate ? dayjs(acqDate).format('YYYY.MM.DD') : '-'}
			typography='label'
			color='gray10'
		/>
	);

	const informationTableItems = useMemo(() => {
		const items: InformationTableItemType[][] = [
			[
				{
					type: 'label',
					label: '구분',
					labelStyle: {
						height: '4.1rem',
						verticalAlign: 'middle',
					},
				},
				{
					type: 'label',
					label: '취득 일자',
					labelStyle: {
						height: '4.1rem',
						verticalAlign: 'middle',
					},
				},
			],
		];

		if (insurancePriorInfo?.employInsurancePosbYn) {
			items.push([
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRCheckBoxGroup
									type='checkbox'
									value={value}
									options={[
										{
											label: InsuranceType.EMPLOY,
											value: InsuranceType.EMPLOY,
										},
									]}
									onChange={onChange}
								/>
							)}
							name='insuranceType'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: displayAcqDate(insurancePriorInfo?.employInsuranceDate),
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
			]);
		}

		if (insurancePriorInfo?.accidentInsurancePosbYn) {
			items.push([
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRCheckBoxGroup
									type='checkbox'
									value={value}
									options={[
										{
											label: InsuranceType.ACCIDENT,
											value: InsuranceType.ACCIDENT,
										},
									]}
									onChange={onChange}
								/>
							)}
							name='insuranceType'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: displayAcqDate(insurancePriorInfo?.accidentInsuranceDate),
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
			]);
		}

		if (insurancePriorInfo?.pensionInsurancePosbYn) {
			items.push([
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRCheckBoxGroup
									type='checkbox'
									value={value}
									options={[
										{
											label: InsuranceType.PENSION,
											value: InsuranceType.PENSION,
										},
									]}
									onChange={onChange}
								/>
							)}
							name='insuranceType'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: displayAcqDate(insurancePriorInfo?.pensionInsuranceDate),
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
			]);
		}

		if (insurancePriorInfo?.healthInsurancePosbYn) {
			items.push([
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRCheckBoxGroup
									type='checkbox'
									value={value}
									options={[
										{
											label: InsuranceType.HEALTH,
											value: InsuranceType.HEALTH,
										},
									]}
									onChange={onChange}
								/>
							)}
							name='insuranceType'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: displayAcqDate(insurancePriorInfo?.healthInsuranceDate),
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
			]);
		}

		if (items.length === 1) {
			items.push([
				{
					type: 'value',
					value: '-',
					valueStyle: {
						verticalAlign: 'middle',
						height: '3.3rem',
					},
				},
			]);
		}

		return items;
	}, [insurancePriorInfo, insuranceType, context]);

	const isInsuranceInfoEmpty = insurancePriorInfo
		? (
				[
					'accidentInsurancePosbYn',
					'employInsurancePosbYn',
					'healthInsurancePosbYn',
					'pensionInsurancePosbYn',
				] as (keyof InsurancePriorInfoDTO)[]
			).every((key) => insurancePriorInfo[key] === false)
		: false;

	useEffect(() => {
		if (insurancePriorInfo) {
			context.setValue('data', insurancePriorInfo);
		}
	}, [insurancePriorInfo]);

	return (
		<Accordion.Item value='신고 내용' asChild>
			<TaskAccordion.Item>
				<Accordion.Header asChild>
					<Accordion.Trigger asChild>
						<TaskAccordion.Trigger>신고 내용</TaskAccordion.Trigger>
					</Accordion.Trigger>
				</Accordion.Header>
				<Accordion.Content asChild>
					<TaskAccordion.Content>
						<S.FormContainer>
							<CRInputLabel
								label='신고 선택'
								type='left-sub'
								isRequired
								showOverflow
								betweenGap='2.4rem'>
								{isInsuranceInfoEmpty ? (
									<S.EmptyContainer>
										<CRText
											typography='labelB'
											color='gray60'
											text='신고 가능한 보험이 없습니다.'
										/>
									</S.EmptyContainer>
								) : (
									<InformationTable roundBorder items={informationTableItems} />
								)}
							</CRInputLabel>

							<Controller
								render={({ field: { onChange, value } }) => (
									<CRInputLabel label='요청 사항' type='left-sub' showOverflow betweenGap='2.4rem'>
										<CRInput.TextArea
											disabled={isInsuranceInfoEmpty}
											numberOfLines={4}
											onChange={onChange}
											value={value}
											placeholder='요청 사항 입력'
											fixedHeight
										/>
									</CRInputLabel>
								)}
								name='requestDesc'
								control={context.control}
							/>
							<Controller
								render={({ field: { onChange, value } }) => (
									<div
										style={{
											width: '57.2rem',
										}}>
										<CRInputLabel
											label='파일 첨부'
											type='left-sub'
											showOverflow
											betweenGap='2.4rem'>
											<CRInput.FileUploader
												disabled={isInsuranceInfoEmpty}
												onChange={onChange}
												files={value}
												placeholder='파일을 끌어오거나 업로드'
											/>
										</CRInputLabel>
									</div>
								)}
								name='attachFile'
								control={context.control}
							/>
						</S.FormContainer>
					</TaskAccordion.Content>
				</Accordion.Content>
			</TaskAccordion.Item>
		</Accordion.Item>
	);
}
