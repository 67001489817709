import { styled } from 'styled-components';

export const BottomContainer = styled.div`
	height: 8rem;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 1.6rem 2.4rem 2.4rem 2.4rem;
	box-sizing: border-box;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 0rem 2.4rem;
	gap: 1.6rem;

	& > * {
		user-select: auto;
	}
`;

export const Description = styled.div`
	${(props) => props.theme.typography.body}
`;
