import React, { useCallback } from 'react';

import dayjs from 'dayjs';
import { v4 } from 'uuid';

import Colors from 'common/colors';
import CRSpinner from 'components/base/CRSpinner';
import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import CRTableHeader from 'components/base/CRTableHeader';
import { CheckOption } from 'components/base/Selections/type';
import { SendReservationHistoryContentDTO } from 'types/api/send';
import { PageInfo } from 'types/view/base';
import { Filter } from 'types/view/filter';

import { RESERVED_MESSAGE_HISTORY_HEADER_CONFIG, SEND_STATUS_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	isLoading?: boolean;
	items?: SendReservationHistoryContentDTO[];
	searchValue?: string;
	currentValue: {
		[key in string]: CheckOption[];
	};
	pageInfo?: PageInfo;
	filters?: Array<Filter<CheckOption>>;
	setCurrentFilter: React.Dispatch<
		React.SetStateAction<{
			[key: string]: CheckOption[];
		}>
	>;
	onChangeSearchValue?: (searchValue: string) => void;
	onClickRow?: (task: SendReservationHistoryContentDTO) => void;
	onChangePageInfo?: (pageInfo: PageInfo) => void;
	onSearch?: () => void;
	onRefresh: () => void;
}

function ReservedMessageHistoryTable({
	isLoading = true,
	items = [],
	currentValue,
	searchValue,
	pageInfo,
	filters,
	setCurrentFilter,
	onChangeSearchValue,
	onClickRow,
	onChangePageInfo,
	onSearch,
	onRefresh,
}: Props): React.ReactElement {
	const renderSendDate = useCallback(
		(value: string | number) => (
			<S.TextContainer>{value ? dayjs(value).format('YYYY.MM.DD HH:mm') : '-'}</S.TextContainer>
		),
		[],
	);

	const renderReservationCnt = useCallback(
		(value: string | number) => <S.TextContainer>{value ?? 0}건</S.TextContainer>,
		[],
	);

	const SendStatusBadge = useCallback(
		(value: string | number) => <CRStatus options={SEND_STATUS_CONFIG}>{value}</CRStatus>,
		[],
	);

	return (
		<CRTable.BackBlind>
			<S.TableContainer>
				<CRTableHeader
					showRefresh
					onRefresh={onRefresh}
					onChangePageInfo={onChangePageInfo}
					pageInfo={pageInfo}
					currentFilter={currentValue}
					stickyMode
					filters={filters}
					searchValue={searchValue}
					placeholder='제목으로 검색'
					onChangeSearchValue={onChangeSearchValue}
					setCurrentFilter={setCurrentFilter}
					onSearch={onSearch}
					showViewCount
				/>
				{isLoading ? (
					<CRSpinner />
				) : (
					<S.TableScrollContainer>
						<CRTable.Root
							style={{
								borderLeft: `0.1rem solid ${Colors.gray90}`,
								borderRight: `0.1rem solid ${Colors.gray90}`,
							}}>
							<CRTable.Head offset={-47} heads={RESERVED_MESSAGE_HISTORY_HEADER_CONFIG} />
							<CRTable.Body>
								{items.map((item) => (
									<CRTable.Row
										key={v4()}
										item={item}
										renderKeys={[
											'sendDate',
											'sendTemplateTitle',
											'sendTitle',
											'reservationCnt',
											'senderNm',
											'sendStateNm',
										]}
										customRender={{
											sendDate: renderSendDate,
											reservationCnt: renderReservationCnt,
											sendStateNm: SendStatusBadge,
										}}
										onClick={onClickRow}
									/>
								))}
							</CRTable.Body>
						</CRTable.Root>
					</S.TableScrollContainer>
				)}
			</S.TableContainer>
		</CRTable.BackBlind>
	);
}

export default ReservedMessageHistoryTable;
