import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import dayjs from 'dayjs';

import { Toast } from 'components/base/CRToast';
import EDocClientBottomFloating from 'components/ui/EDoc/EDocClientBottomFloating';
import EDocClientButton from 'components/ui/EDoc/EDocClientButton';
import EDocClientHeader, { EDocClientHeaderProps } from 'components/ui/EDoc/EDocClientHeader';
import {
	displayDayOfWeekInKorean,
	formatVisitCareRecordSheetsFromEDocParams,
	removeFalsyValues,
} from 'lib';
import useEDocClientPage from 'lib/hook/eDoc/useESignInfoPage';
import { ServiceKindCds } from 'pages/dashboard/SchedulePage/MonitoringDetailPage';
import { VisitCareScheduleRecordingInputs } from 'types/api/eDoc';

import {
	EDocRecordSheetsFormProps,
	VisitCareRecordFormFields,
	VisitCareRecordSheetParam,
} from '../types';
import { VisitCareRecordForm } from './VisitCareRecordForm';

const initValue = {
	Chk1: '0',
	Chk2: '0',
	Chk3: '0',
	Chk4: '0',
	Chk5: '0',
	Chk6: '0',
	Minute1: '',
	Minute2: '',
	Minute3: '',
	Minute4: '',
	Minute5: '',
	Minute6: '',
	Chk7: '0',
	Chk8: '0',
	Chk9_1: '0',
	Chk9_2: '1',
	Chk9_3: '0',
	Chk10_1: '0',
	Chk10_2: '1',
	Chk10_3: '0',
	Chk11_1: '0',
	Chk11_2: '1',
	Chk11_3: '0',
	Mistake1: '',
	Mistake2: '',
	SpecialContent: '',
};

const makeDefaultFormData = (defaultValue: VisitCareRecordFormFields) => {
	if (!defaultValue) return {};
	const emptyStringFiltered = removeFalsyValues(defaultValue);
	const newObj = {
		...initValue,
		...emptyStringFiltered,
	};
	return newObj;
};

function EDocVisitCareRecordForm({
	onSave,
	onSubmit,
	params,
	isSubmittable,
	disabled,
}: EDocRecordSheetsFormProps<VisitCareRecordSheetParam, VisitCareScheduleRecordingInputs>) {
	const formattedParams = formatVisitCareRecordSheetsFromEDocParams(params);
	const [sheetNumber, setSheetNumber] = useState(0);
	const isLastPage = sheetNumber >= formattedParams.length - 1;

	const currentSheetValue = formattedParams[sheetNumber];

	const methods = useForm<VisitCareRecordFormFields>({
		defaultValues: makeDefaultFormData(currentSheetValue),
	});
	const [isProcessing, setIsProcessing] = useState(false);

	const { isErp } = useEDocClientPage();

	const clientHeaderProps: EDocClientHeaderProps = useMemo(() => {
		const param = formattedParams[sheetNumber];
		if (!param) throw new Error('기록지 정보를 찾을 수 없습니다.');
		const date = `${param.year}-${param.Dt.replace('/', '-')}`;
		const startTime = dayjs(`${date} ${param.StartTime}:00`);
		let endTime = dayjs(`${date} ${param.EndTime}:00`);
		// 익일로 넘어가는 케이스 대응
		if (endTime.isBefore(startTime)) {
			endTime = endTime.add(1, 'day');
		}
		const totalMinute = endTime.diff(startTime, 'minutes');
		const getDate = `${displayDayOfWeekInKorean(dayjs(date).day())}요일`;

		return {
			recipientNm: param?.recipientNm || '',
			currentDate: dayjs(date).format('MM월 DD일 ') + getDate,
			endTime: param.EndTime,
			startTime: param.StartTime,
			totalFormCount: formattedParams.length,
			totalMinute,
			writtenFormCount: sheetNumber,
		};
	}, [formattedParams, sheetNumber]);

	// 저장전에 폼 형식 validation을 여기서 진행
	const convertValue = (
		formValues: VisitCareRecordFormFields,
	): VisitCareScheduleRecordingInputs => {
		const isY = (value: string) => value === '1';
		const getStatusCode = (improvement: string, maintain: string, worse: string) => {
			if (isY(improvement)) {
				return '1';
			}
			if (isY(maintain)) {
				return '2';
			}
			if (isY(worse)) {
				return '3';
			}
			return '2';
		};

		const date = `${currentSheetValue.year}${currentSheetValue.Dt.replace('/', '')}`;
		return {
			serviceDt: date,
			inputIndex: String(sheetNumber),
			startTime: formValues.StartTime.replace(':', ''),
			untagDesc: null,
			personalHygieneYn: isY(formValues.Chk1),
			washBodyHelpYn: isY(formValues.Chk2),
			mealHelpYn: isY(formValues.Chk3),
			positionChageYn: isY(formValues.Chk4),
			moveHelpYn: isY(formValues.Chk5),
			restroomUseYn: isY(formValues.Chk6),
			physicalActivitySupptOfferHourCnt: Number(formValues.Minute1),
			recogStimulationActivityHourCnt: Number(formValues.Minute2),
			dailylifeTogetherHourCnt: Number(formValues.Minute3),
			recogBehaviorChangeHourCnt: Number(formValues.Minute4),
			commuhelpCompanionEncourageHourCnt: Number(formValues.Minute5),
			mealCleanTidyWashYn: isY(formValues.Chk7),
			personalActivitySupptYn: isY(formValues.Chk8),
			hworkDailylifeSupptOfferHourCnt: Number(formValues.Minute6),
			physicalFunctionStateValue: getStatusCode(
				formValues.Chk9_1,
				formValues.Chk9_2,
				formValues.Chk9_3,
			),
			mealFunctionStateValue: getStatusCode(
				formValues.Chk10_1,
				formValues.Chk10_2,
				formValues.Chk10_3,
			),
			recogFunctionStateValue: getStatusCode(
				formValues.Chk11_1,
				formValues.Chk11_2,
				formValues.Chk11_3,
			),

			fecesMistakeCnt: Number(formValues.Mistake1),
			urineMistakeCnt: Number(formValues.Mistake2),
			specialDesc: formValues.SpecialContent,
			pageNo: currentSheetValue.pageNo,
			colNo: currentSheetValue.colNo,
		};
	};

	const onMoveNextPage = async (formValues: VisitCareRecordFormFields) => {
		if (isProcessing) return;
		setIsProcessing(true);

		try {
			await onSave(convertValue(formValues));
			setSheetNumber((prev) => prev + 1);
			methods.reset(makeDefaultFormData(formattedParams[sheetNumber + 1]));
		} catch {
			Toast.error('기록지 저장을 실패했습니다.');
			return;
		} finally {
			setIsProcessing(false);
		}
	};

	const onMovePrevPage = async (formValues: VisitCareRecordFormFields) => {
		if (isProcessing) return;
		setIsProcessing(true);
		try {
			await onSave(convertValue(formValues));
			setSheetNumber((prev) => prev - 1);
			methods.reset(makeDefaultFormData(formattedParams[sheetNumber - 1]));
		} catch {
			Toast.error('기록지 저장을 실패했습니다.');
			return;
		} finally {
			setIsProcessing(false);
		}
	};

	const onSubmitHandler = async (formValues: VisitCareRecordFormFields) => {
		if (isProcessing) return;
		setIsProcessing(true);
		try {
			await onSubmit(convertValue(formValues));
			// await onSaveHandler(formValues);
		} catch {
			Toast.error('기록지 제출을 실패했습니다.');
			return;
		} finally {
			setIsProcessing(false);
		}
	};

	return (
		<FormProvider {...methods}>
			<EDocClientHeader {...clientHeaderProps} />
			<VisitCareRecordForm
				disabled={disabled}
				key={sheetNumber}
				familyYn={currentSheetValue?.familyYn === 'Y'}
				serviceKindCd={currentSheetValue?.ServiceKindCd as ServiceKindCds}
			/>
			<EDocClientBottomFloating>
				<EDocClientButton.Prev
					disabled={sheetNumber === 0}
					onClick={methods.handleSubmit(onMovePrevPage)}
				/>
				{isLastPage && isSubmittable ? (
					// eslint-disable-next-line react/jsx-no-useless-fragment
					<EDocClientButton.Submit
						title={isErp ? '검수하기' : '제출하기'}
						onClick={methods.handleSubmit(onSubmitHandler)}
					/>
				) : (
					<EDocClientButton.Next onClick={methods.handleSubmit(onMoveNextPage)} />
				)}
			</EDocClientBottomFloating>
		</FormProvider>
	);
}

export default EDocVisitCareRecordForm;
