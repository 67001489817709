import React from 'react';

import { LocalNavigationSubSection } from '../../../types/view/task';
import ScrollSpySectionItem from './ScrollSpySectionItem';
import * as S from './styles';

interface IProps {
	currentKey?: string;
	subSection: LocalNavigationSubSection;
	hideProgress?: boolean;
}

function ScrollSpySubSection({ subSection, currentKey, hideProgress }: IProps): React.ReactElement {
	return (
		<S.SectionContentContainer>
			{subSection.label && (
				<S.ItemContainer>
					<S.SubSectionLabelItem>{subSection.label}</S.SubSectionLabelItem>
				</S.ItemContainer>
			)}
			<S.SectionContainer>
				{subSection.items.map((item, index) => (
					<ScrollSpySectionItem
						key={item.key}
						item={item}
						isFirst={!index}
						isLast={index === subSection.items.length - 1}
						isActive={item.key === currentKey}
						isDone={item.isDone}
						hideProgress={hideProgress}
					/>
				))}
			</S.SectionContainer>
		</S.SectionContentContainer>
	);
}

export default ScrollSpySubSection;
