import { css, keyframes, styled } from 'styled-components';

const spinner = keyframes`
    from {
        transform: rotate(0deg); 
    }
    to {
        transform: rotate(360deg);
    }
`;

export const Container = styled.div<{ $floatMode: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;

	${(props) =>
		props.$floatMode &&
		css`
			position: absolute;
			left: 0;
			top: 0;
		`};
`;

export const Spinner = styled.div<{ $size: string }>`
	box-sizing: border-box;
	width: ${(p) => p.$size};
	height: ${(p) => p.$size};
	border-radius: 50%;
	border: ${(p) => `${Number(p?.$size?.replace('rem', '')) / 10}rem`} solid #e1e3e3;
	border-top-color: #6dc993;
	animation: ${spinner} 0.8s linear infinite;
`;
