import styled, { css } from 'styled-components';

import { BannerStyleObj, BannerType, IconFilterObj } from './type';

const BANNER_STYLE: BannerStyleObj = {
	default: css`
		background: ${(props) => props.theme.colors.gray20};
		color: ${(props) => props.theme.colors.gray100};
	`,
	error: css`
		background: ${(props) => props.theme.colors.primary90};
		color: ${(props) => props.theme.colors.primary20};
	`,
	warning: css`
		background: #ffefc5;
		color: #6d5516;
	`,
	info: css`
		background: #c9deff;
		color: #1d3c6d;
	`,
	success: css`
		background: #d5efe0;
		color: #2e563f;
	`,
};

const ICON_FILTER: IconFilterObj = {
	default: css`
		filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(4%) hue-rotate(172deg)
			brightness(103%) contrast(101%);
	`,
	warning: css`
		filter: brightness(0) saturate(100%) invert(25%) sepia(85%) saturate(455%) hue-rotate(6deg)
			brightness(105%) contrast(85%);
	`,
	info: css`
		filter: brightness(0) saturate(100%) invert(16%) sepia(69%) saturate(1204%) hue-rotate(192deg)
			brightness(98%) contrast(91%);
	`,
	success: css`
		filter: brightness(0) saturate(100%) invert(26%) sepia(27%) saturate(722%) hue-rotate(93deg)
			brightness(96%) contrast(85%);
	`,
	error: css`
		filter: brightness(0) saturate(100%) invert(10%) sepia(77%) saturate(4515%) hue-rotate(325deg)
			brightness(73%) contrast(129%);
	`,
};

export const Container = styled.div<{
	$type: BannerType;
	$hasAction?: boolean;
	$hasCloseBtn?: boolean;
}>`
	position: relative;
	display: flex;
	gap: 0.8rem;
	align-items: center;
	width: auto;
	padding: 1.6rem 2rem 1.6rem 2rem;
	min-height: 6.4rem;
	box-sizing: border-box;
	border-radius: 0.8rem;
	${(props) => props.theme.typography.body}
	${(props) => BANNER_STYLE[props.$type]} 
    cursor: ${(props) => (props.$hasAction ? 'pointer' : 'auto')};
`;

export const Icon = styled.img<{ $type: BannerType }>`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
`;

export const LeftIcon = styled(Icon)`
	position: absolute;
	left: 2rem;
	top: 2rem;
	${(props) => ICON_FILTER[props.$type]};
`;

export const RightCloseIcon = styled(Icon)`
	${(props) => ICON_FILTER[props.$type]};
`;

export const ContentContainer = styled.div`
	flex-direction: column;
	align-items: center;
`;

export const Title = styled.p<{ $hasLeftButton: boolean; $hasRightButton: boolean }>`
	${(props) => props.theme.typography.body};
	flex: 1;
	display: flex;
	gap: 0.8rem;
	padding-left: ${(props) => (props.$hasLeftButton ? '3.6rem' : 0)};
	padding-right: ${(props) => (props.$hasRightButton ? '4.4rem' : 0)};
	box-sizing: border-box;
`;

export const Text = styled.span<{ $hasLeftButton: boolean; $hasRightButton: boolean }>`
	flex: 1;
	display: flex;
	gap: 0.8rem;
	padding-left: ${(props) => (props.$hasLeftButton ? '3.6rem' : 0)};
	padding-right: ${(props) => (props.$hasRightButton ? '4.4rem' : 0)};
	box-sizing: border-box;
`;

export const ButtonContainer = styled.div`
	position: absolute;
	right: 0rem;
	top: 0rem;
	padding: 2rem;
	height: 2.4rem;
	white-space: nowrap;
`;

export const HighlightText = styled.strong`
	color: ${(props) => props.theme.typography.bodyB};
`;
