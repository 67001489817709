import React, { ReactNode, useState } from 'react';

import Assets from 'assets';
import RdPopover from 'components/ui/radix/popover/RdPopover';

import { PopoverClose } from '../CRHeader/styles';
import CRIcon from '../CRIcon';
import CRToolTip from '../CRToolTip';
import * as S from './styles';

interface Props {
	items: ReactNode[];
}

function CRMorePopover({ items = [] }: Props) {
	const [isOpened, setIsOpened] = useState(false);

	const onOpen = () => {
		setIsOpened(true);
	};
	const onClose = () => {
		setIsOpened(false);
	};

	return (
		<RdPopover
			showDivider={false}
			showBorder
			openCallback={onOpen}
			closeCallback={onClose}
			sideOffset={-8}
			alignOffset={0}
			align='end'
			side='bottom'
			borderShape='small'
			content={
				<S.ItemContainer>
					{items.map((item) => (
						<PopoverClose>
							<S.DropdownItem>{item}</S.DropdownItem>
						</PopoverClose>
					))}
				</S.ItemContainer>
			}>
			<S.IconContainer>
				{isOpened ? (
					<CRIcon src={Assets.icon.moreVertical} />
				) : (
					<CRToolTip arrow title='더 보기'>
						<CRIcon src={Assets.icon.moreVertical} />
					</CRToolTip>
				)}
			</S.IconContainer>
		</RdPopover>
	);
}

export default CRMorePopover;
