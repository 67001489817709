import Colors from 'common/colors';
import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const ANNUAL_TO_USAGE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '4.8rem',
		label: '구분',
		sortKey: '구분',
	},
	{
		width: '12rem',
		label: '연차사용일',
		sortKey: '연차사용일',
	},
	{
		width: '9.6rem',
		label: '직원명',
		sortKey: '직원명',
	},
	{
		width: '12rem',
		label: '직무',
		sortKey: '직무',
	},
	{
		width: '8rem',
		label: '성별',
		sortKey: '성별',
	},
	{
		width: '9.6rem',
		label: '연차 종류',
		sortKey: '연차 종류',
	},
	{
		width: '12rem',
		label: '연차 시간',
		sortKey: '연차 시간',
	},
	{
		width: '12rem',
		label: '입사일',
		sortKey: '입사일',
	},
	{
		width: '',
		label: '',
		sortKey: '',
	},
];

export const CONTRACT_STATUS_CONFIGS: CRStatusConfig[] = [
	{
		key: 'CMN082.20',
		label: '진행중',
		color: 'yellow',
	},
	{
		key: 'CMN082.70',
		label: '보류',
		color: 'yellow',
	},
	{
		key: 'CMN082.90',
		label: '완료',
		color: 'green',
	},
	{
		key: 'CMN082.80',
		label: '취소',
		color: 'red',
	},
	{
		key: 'CMN082.00',
		label: '등록',
		color: 'gray',
		custom: {
			background: Colors.grayLighten,
			color: Colors.gray10,
		},
	},
	{
		key: 'CMN082.10',
		label: '배정대기',
		color: 'gray',
		custom: {
			background: Colors.grayLighten,
			color: Colors.gray10,
		},
	},
];
