import React, { ReactComponentElement } from 'react';

import * as S from './styles';

type Props = React.ComponentPropsWithoutRef<'img'>;

function CRIcon({ ...props }: Props) {
	return <S.Icon {...props} />;
}

export default CRIcon;
