import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - 20rem);
	border-top: 1px solid ${(props) => props.theme.colors.gray90};
	border-bottom: 1px solid ${(props) => props.theme.colors.gray90};
	overflow: hidden;
`;

export const BodyContainer = styled.div`
	flex: 1;
	display: flex;
	height: 100%;
	flex-direction: column;
	overflow: scroll;
`;

export const BodyContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2.4rem;
	gap: 1.6rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const DayContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.2rem;
	height: 100%;
`;

export const DayHeaderContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 3.6rem;
	padding: 0 0.8rem;
`;

export const Today = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	box-sizing: border-box;
	width: 2.4rem;
	height: 2.4rem;
	background: ${(props) => props.theme.colors.primary60};
	color: ${(props) => props.theme.colors.gray100};
`;

export const DayContentContainer = styled.div`
	padding: 0 0.8rem;
`;

export const Holiday = styled.div`
	width: 100%;
	height: 24px;
	${(props) => props.theme.typography.label};
	border-radius: 0.4rem;
	padding: 0 0.8rem;
	overflow: hidden;
	background-color: ${(props) => props.theme.colors.primary90};
	color: ${(props) => props.theme.colors.primary20};
`;

export const VisitContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.2rem;
`;

export const CustomHeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 2.4rem;
	margin-bottom: 0.8rem;
`;

export const CalendarContainer = styled.div<{ $height?: string }>`
	.react-datepicker {
		border: none;
		background: ${(props) => props.theme.colors.gray100};
	}

	.react-datepicker__aria-live {
		display: none;
	}

	.react-datepicker__header {
		min-width: 80rem;
		width: 100%;
		background: none;
		border-bottom: 0.2rem solid ${(props) => props.theme.colors.gray90};
	}

	.react-datepicker__day-names {
		display: flex;
		width: 100%;
		margin: 0;
		margin-bottom: 0.8rem;
	}

	.react-datepicker__day-name {
		${(props) => props.theme.typography.label};
		display: flex;
		justify-content: flex-end;
		flex: 1;
		margin: 0;
		color: ${(props) => props.theme.colors.gray60};
	}

	.react-datepicker__day-name:first-child {
		color: ${(props) => props.theme.colors.primary60};
	}

	.react-datepicker__month {
		margin: 0;
		border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};
		border-bottom: 0.2rem solid ${(props) => props.theme.colors.gray90};
		overflow: auto;
		height: ${(props) => (props.$height ? `calc(${props.$height})` : 'auto')};
		min-width: 80rem;
	}

	.react-datepicker__month-container {
		overflow: auto;
	}

	.react-datepicker__day--weekend {
		background: ${(props) => props.theme.colors.gray99};
	}

	.react-datepicker__week {
		display: flex;
	}

	.react-datepicker__week + .react-datepicker__week {
		border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	}

	.react-datepicker__day {
		width: 100%;
		min-height: 14.8rem;
		margin: 0;
		box-sizing: border-box;
		border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
		overflow: hidden;
	}

	.react-datepicker__day--outside-month ${DayContainer} {
		color: ${(props) => props.theme.colors.gray60};
	}

	.react-datepicker__day--keyboard-selected {
		background: none;
	}
`;
