import React, { useCallback } from 'react';

import CRTable from 'components/base/CRTable';
import CRTableHeader from 'components/base/CRTableHeader';
import { Billing } from 'types/view/billing';
import { FilterType } from 'types/view/filter';

import CRStatus from '../../../base/CRStatus';
import {
	BILLING_HEADER_CONFIG,
	BILLING_PROGRESS_STATUS_CONFIG,
	BILLING_TABLE_FILTER_CONFIG,
} from './constant';
import * as S from './styles';

interface Props<T extends { label: string; value: any }> {
	items: Billing[];
	searchValue?: string;
	currentFilter: {
		[key in string]: T[];
	};
	setCurrentFilter?: React.Dispatch<
		React.SetStateAction<{
			[key: string]: T[];
		}>
	>;
	onChangeSearchValue?: (searchValue: string) => void;
	onClickBilling?: (billing: Billing) => void;
}

function BillingTable<T extends { label: string; value: any }>({
	items = [],
	currentFilter,
	searchValue,
	setCurrentFilter,
	onChangeSearchValue,
	onClickBilling,
}: Props<T>): React.ReactElement {
	const BillingBadge = useCallback(
		(children: string) => <CRStatus options={BILLING_PROGRESS_STATUS_CONFIG}>{children}</CRStatus>,
		[],
	);

	return (
		<S.Container>
			<CRTableHeader
				currentFilter={currentFilter}
				filters={BILLING_TABLE_FILTER_CONFIG}
				searchValue={searchValue}
				placeholder='Place Holder'
				onChangeSearchValue={onChangeSearchValue}
				setCurrentFilter={setCurrentFilter}
				stickyMode
			/>
			<CRTable.Root>
				<CRTable.Head heads={BILLING_HEADER_CONFIG} />
				<CRTable.Body>
					{items.map((item) => (
						<CRTable.Row
							key={item.id}
							item={item}
							renderKeys={[
								'date',
								'type',
								'recipient',
								'service',
								'ownExpenseAmount',
								'totalAmount',
								'center',
								'manager',
								'status',
							]}
							customRender={{
								status: BillingBadge,
							}}
							onClick={onClickBilling}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default BillingTable;
