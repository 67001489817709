import React, { CSSProperties, useEffect, useRef, useState } from 'react';

import * as S from './styles';
import { TabProps } from './type';

interface Props {
	items: TabProps[];
	containerStyle?: CSSProperties;
	menuContainerStyle?: CSSProperties;
	contentContainerStyle?: CSSProperties;
	defaultActiveKey?: string;
	onChange?: (item: TabProps) => void;
}

function DetailTab({
	defaultActiveKey,
	onChange,
	items,
	containerStyle,
	menuContainerStyle,
	contentContainerStyle,
}: Props): React.ReactElement {
	const containerRef = useRef<HTMLUListElement>(null);
	const tabItemRef = useRef<Array<HTMLLIElement | null>>([]);
	const [currentIndex, setCurrentIndex] = useState<number>(() =>
		items?.findIndex((item) => item.key === defaultActiveKey),
	);
	const [activeStyle, setActiveStyle] = useState<CSSProperties>();

	const animateUnderLine = (targetIndex: number) => {
		const containerRect = containerRef.current?.getBoundingClientRect();
		const rect = tabItemRef.current[targetIndex]?.getBoundingClientRect();

		const underLineStyle: CSSProperties = {
			bottom: '0rem',
			left: (rect?.left ?? 0) - (containerRect?.left ?? 0),
			width: rect?.width,
		};

		setActiveStyle(underLineStyle);
	};

	const onClickTab = (selectedItem: TabProps) => {
		const targetIndex = items.findIndex((item) => item.key === selectedItem.key);

		setCurrentIndex(targetIndex);
		animateUnderLine(targetIndex);
		onChange?.(selectedItem);
	};

	useEffect(() => {
		animateUnderLine(currentIndex);
	}, [currentIndex]);

	return (
		<S.Container style={containerStyle}>
			<S.MenuContainer ref={containerRef} style={{ ...(menuContainerStyle ?? {}) }}>
				{items?.map((item, index) => (
					<S.TabItem
						ref={(el) => {
							tabItemRef.current[index] = el;
						}}
						$isActive={items[currentIndex]?.key === item.key}
						key={item.key}
						onClick={() => onClickTab(item)}>
						{item.label}
						{item.isRequired && <S.RequiredBadge>*</S.RequiredBadge>}
					</S.TabItem>
				))}
				{activeStyle && <S.TabUnderLine style={activeStyle} />}
			</S.MenuContainer>
			<S.TabContent style={contentContainerStyle ?? {}}>
				{items[currentIndex]?.children}
			</S.TabContent>
		</S.Container>
	);
}

export default DetailTab;
