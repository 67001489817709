import React from 'react';
import { Controller } from 'react-hook-form';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import useContractRenewalTask from 'lib/hook/view/contract/useContractRenewalTask';

import { CheckOption } from '../../../base/Selections/type';
import * as S from './styles';

interface Props {
	currentService: CheckOption<any>;
}

function RenewalVisitNursingContractForm({ currentService }: Props): React.ReactElement {
	const { form, disabled } = useContractRenewalTask();

	const index =
		form?.getValues('services').findIndex((item) => {
			const [serviceContractId, serviceTypeCd] = currentService.value.split('-');

			return (
				item.serviceContractId === Number(serviceContractId) && item.serviceTypeCd === serviceTypeCd
			);
		}) ?? 0;

	return (
		<S.Container key={currentService.value}>
			<S.SectionContainer>
				<S.SectionTitle>수급자</S.SectionTitle>
				<Controller
					name={`services.${index}.associates.recipientContractAssignDate`}
					control={form?.control}
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel type='left' label='계약일' isRequired showOverflow>
							<CRInput.DatePicker
								maxDate={
									form.watch(`services.${index}.use.sameDayContractYn`)
										? dayjs(form.watch(`services.${index}.use.serviceStartDate`)).toDate()
										: dayjs(form.watch(`services.${index}.use.serviceStartDate`))
												.subtract(1, 'day')
												.toDate()
								}
								placeholder='계약일'
								value={value ? dayjs(value).toDate() : undefined}
								onChangeValue={(date) => {
									onChange(dayjs(date).format('YYYY-MM-DD hh:mm:ss'));
									form.setValue(
										'services',
										form.getValues('services').map((item) => ({
											...item,
											associates: {
												...item.associates,
												recipientContractAssignDate: dayjs(date).format('YYYY-MM-DD hh:mm:ss'),
											},
										})),
									);
								}}
								disabled={disabled}
							/>
						</CRInputLabel>
					)}
				/>
			</S.SectionContainer>
			<S.SectionContainer>
				<S.SectionTitle>직원</S.SectionTitle>
				<Controller
					name={`services.${index}.associates.contractEmployees.0.employeeContractAssignDate`}
					control={form?.control}
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel type='left' label='계약일' isRequired showOverflow>
							<CRInput.DatePicker
								placeholder='계약일'
								value={value ? dayjs(value).toDate() : undefined}
								onChangeValue={(date) => onChange(dayjs(date).format('YYYY-MM-DD hh:mm:ss'))}
								disabled
							/>
						</CRInputLabel>
					)}
				/>
				<CRInputLabel type='left' label='직원 정보' isRequired showOverflow>
					<CRCardFormLayout label='직원' isRequired>
						<Controller
							name={`services.${index}.associates.contractEmployees.0.employeeNm`}
							control={form?.control}
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInputLabel type='left-sub' label='이름'>
									<CRInput.Default
										placeholder='이름 입력'
										value={value}
										onChange={onChange}
										disabled
									/>
								</CRInputLabel>
							)}
						/>
						<Controller
							name={`services.${index}.associates.contractEmployees.0.employeeRsdnNo`}
							control={form?.control}
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInputLabel type='left-sub' label='주민번호'>
									<CRInput.Default
										placeholder='주민번호 입력'
										onChange={onChange}
										value={value}
										disabled
										suffix={
											<CRButton.Default
												size='xSmall'
												palette='gray'
												type='outlined'
												buttonType='button'
												disabled>
												중복 조회
											</CRButton.Default>
										}
									/>
								</CRInputLabel>
							)}
						/>
					</CRCardFormLayout>
				</CRInputLabel>
				<Controller
					name={`services.${index}.associates.contractEmployees.0.employeeDutyCd`}
					control={form?.control}
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel type='left' label='직무' isRequired showOverflow>
							<CRCheckBoxGroup
								disabled
								type='radio'
								checkType='single'
								appearanceType='button'
								gap={0.8}
								options={[
									{ label: '간호사', value: 'CMN070.30' },
									{ label: '간호조무사', value: 'CMN070.50' },
								]}
								value={value ? [{ label: '', value }] : []}
								onChange={(item) => onChange(item[0].value)}
							/>
						</CRInputLabel>
					)}
				/>
			</S.SectionContainer>
		</S.Container>
	);
}

export default RenewalVisitNursingContractForm;
