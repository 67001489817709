import React, { useMemo } from 'react';

import * as Accordion from '@radix-ui/react-accordion';

import ChangeEmployeeVisitBathContractForm from 'components/domain/form/ChangeEmployeeVisitBathContractForm';
import ChangeEmployeeVisitBathServiceForm from 'components/domain/form/ChangeEmployeeVisitBathServiceForm';
import ChangeEmployeeVisitCareContractForm from 'components/domain/form/ChangeEmployeeVisitCareContractForm';
import ChangeEmployeeVisitCareServiceForm from 'components/domain/form/ChangeEmployeeVisitCareServiceForm';
import ChangeEmployeeVisitNursingContractForm from 'components/domain/form/ChangeEmployeeVisitNursingContractForm';
import ChangeEmployeeVisitNursingServiceForm from 'components/domain/form/ChangeEmployeeVisitNursingServiceForm';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import useContractChangeEmployeeTask from 'lib/hook/view/contract/useContractChangeEmployeeTask';

function ChangeEmployeeServiceInspectionPage(): React.ReactElement {
	const { form } = useContractChangeEmployeeTask();

	const currentService = form.getValues('service.serviceTypeCd');

	const forms = useMemo(() => {
		if (currentService === 'CMN006.10') {
			return [
				{
					id: '#1',
					label: '이용 정보',
					component: <ChangeEmployeeVisitCareServiceForm />,
				},
				{
					id: '#2',
					label: '계약 정보',
					component: <ChangeEmployeeVisitCareContractForm />,
				},
			];
		}
		if (currentService === 'CMN006.20') {
			return [
				{
					id: '#1',
					label: '이용 정보',
					component: <ChangeEmployeeVisitBathServiceForm />,
				},
				{
					id: '#2',
					label: '계약 정보',
					component: <ChangeEmployeeVisitBathContractForm />,
				},
			];
		}
		if (currentService === 'CMN006.30') {
			return [
				{
					id: '#1',
					label: '이용 정보',
					component: <ChangeEmployeeVisitNursingServiceForm />,
				},
				{
					id: '#2',
					label: '계약 정보',
					component: <ChangeEmployeeVisitNursingContractForm />,
				},
			];
		}

		return [];
	}, [currentService]);

	return forms.length ? (
		<Accordion.Root type='multiple' defaultValue={forms.map((form) => form.id)}>
			{forms.map((form) => (
				<Accordion.Item value={form.id} asChild>
					<TaskAccordion.Item>
						<Accordion.Header asChild>
							<Accordion.Trigger asChild>
								<TaskAccordion.Trigger href={form.id}>{form.label}</TaskAccordion.Trigger>
							</Accordion.Trigger>
						</Accordion.Header>
						<Accordion.Content asChild>
							<TaskAccordion.Content>{form.component}</TaskAccordion.Content>
						</Accordion.Content>
					</TaskAccordion.Item>
				</Accordion.Item>
			))}
		</Accordion.Root>
	) : (
		<div />
	);
}

export default React.memo(ChangeEmployeeServiceInspectionPage);
