import React, { ForwardedRef, MouseEvent, forwardRef } from 'react';

import Assets from 'assets';

import * as S from './styles';

type ButtonElementProps = React.ComponentPropsWithoutRef<'button'>;

interface IProps extends Omit<ButtonElementProps, 'type' | 'value' | 'onChange'> {
	type?: 'default' | 'icon';
	value?: boolean;
	disabled?: boolean;
	onChange: (arg: boolean) => void;
}

function CRSwitch(
	{ type = 'default', value = false, disabled = false, onChange, ...props }: IProps,
	ref: ForwardedRef<HTMLButtonElement>,
) {
	const onClickSwitch = (e: MouseEvent) => {
		e.preventDefault();
		if (disabled) return;
		if (onChange) onChange(!value);
	};
	return (
		<S.ToggleBtn
			{...props}
			ref={ref}
			$isActive={value}
			onClick={onClickSwitch}
			$isDisabled={disabled}>
			<S.Circle $isActive={value}>
				{type === 'icon' && value && <S.Icon src={Assets.icon.check} $isDisabled={disabled} />}
			</S.Circle>
		</S.ToggleBtn>
	);
}

export default forwardRef(CRSwitch);
