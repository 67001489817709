import useCRMutation from 'lib/hook/react-query/base/useCRMutation';
import { endpoint } from 'lib/service/Api/endpoint';

export const useRecipientDuplicateCheck = useCRMutation(
	endpoint.recipientCheckDuplicate.key,
	'recipientDuplicateCheck',
);

export const useUpdateRecipientInUse = useCRMutation(
	endpoint.updateRecipientInUse.key,
	'updateRecipientInUse',
);

export const useUpdateRecipientInWaiting = useCRMutation(
	endpoint.updateRecipientInUse.key,
	'updateRecipientWaiting',
);

export const useUpdateRecipientBase = useCRMutation(
	endpoint.updateRecipientBase.key,
	'updateRecipientBase',
);

export const useUpdateRecipientGuardians = useCRMutation(
	endpoint.updateRecipientGuardians.key,
	'updateRecipientGuardians',
);

export const useUpdateRecipientOtherServices = useCRMutation(
	endpoint.updateRecipientOtherServices.key,
	'updateRecipientOtherServices',
);

export const useUpdateRecipientBurdenAmt = useCRMutation(
	endpoint.updateRecipientBurdenAmt.key,
	'updateRecipientBurdenAmt',
);

export const useCheckRecipientRsdnNo = useCRMutation(
	endpoint.checkRecipientRsdnNo.key,
	'checkRecipientRsdnNo',
);

export const useCreateRecipientHistoryOfOwnExpense = useCRMutation(
	endpoint.createRecipientHistoryOfOwnExpenseRate.key,
	'createRecipientHistoryOfOwnExpense',
);

// create와 동일한 api를 사용.
export const useUpdateRecipientHistoryOfOwnExpense = useCRMutation(
	endpoint.updateRecipientHistoryOfOwnExpenseRate.key,
	'updateRecipientHistoryOfOwnExpense',
);

export const useDeleteRecipientHistoryOfOwnExpense = useCRMutation(
	endpoint.deleteRecipientHistoryOfOwnExpenseRate.key,
	'deleteRecipientHistoryOfOwnExpense',
);

export const useCreateRecipientHistoryOfManager = useCRMutation(
	endpoint.createRecipientHistoryOfManager.key,
	'createRecipientHistoryOfManager',
);

export const useResignRecipient = useCRMutation(endpoint.resignRecipient.key, 'resignRecipient');

export const useCheckLongTermDuplication = useCRMutation(
	endpoint.checkLongTermDuplication.key,
	'checkLongTermDuplication',
);

export const useUpdateScheduleAutomation = useCRMutation(
	endpoint.recipientUpdateScheduleAutomation.key,
	'updateRecipientScheduleAutomation',
);

export const useDeleteRecipient = useCRMutation(
	endpoint.updateRecipientAffiliation.key,
	'deleteRecipient',
);

export const useRecipientExcelInfo = useCRMutation(
	endpoint.getRecipientsExcelInfo.key,
	'getRecipientsExcelInfo',
);

export const useCreateRecipientHistoryOfHolding = useCRMutation(
	endpoint.createRecipientHoldingHistory.key,
	'createRecipientHoldingHistory',
);

export const useUpdateRecipientHistoryOfHolding = useCRMutation(
	endpoint.updateRecipientHoldingHistory.key,
	'updateRecipientHoldingHistory',
);

export const useDeleteRecipientHistoryOfHolding = useCRMutation(
	endpoint.deleteRecipientHoldingHistory.key,
	'deleteRecipientHoldingHistory',
);

export const useGetGenerateIntegratedAssessmentOpinion = useCRMutation(
	endpoint.getGenerateIntegratedAssessmentOpinion.key,
	'getGenerateIntegratedAssessmentOpinion',
);
