import styled, { css } from 'styled-components';

import { RemainTextStyleObj } from '../CRAvatarGroup/styles';
import { AvatarSize, AvatarStyleObj, ErrorContainerStyleObj } from './type';

const AVATAR_STYLE: AvatarStyleObj = {
	default: {
		container: css`
			width: 4.8rem;
			height: 4.8rem;
		`,
		icon: css`
			width: 100%;
			height: 100%;
			object-fit: cover;
		`,
	},
	small: {
		container: css`
			width: 3.2rem;
			height: 3.2rem;
			${(props) => props.theme.typography.label};
		`,
		icon: css`
			width: 100%;
			height: 100%;
			object-fit: cover;
		`,
	},
	large: {
		container: css`
			width: 8rem;
			height: 8rem;
			${(props) => props.theme.typography.body};
		`,
		icon: css`
			width: 100%;
			height: 100%;
			object-fit: cover;
		`,
	},
};

export const ERROR_PADDING: ErrorContainerStyleObj = {
	default: css`
		padding: 1.2rem;
	`,
	small: css`
		padding: 0.7rem;
	`,
	large: css`
		padding: 1.6rem;
	`,
};

type OverlapStyleObj = RemainTextStyleObj;

const OVERLAP_MARGIN: OverlapStyleObj = {
	default: css`
		margin-left: -1rem;
	`,
	small: css`
		margin-left: -0.7rem;
	`,
	large: css`
		margin-left: -1.5rem;
	`,
};

export const Wrapper = styled.div<{ $size: AvatarSize }>`
	& + & {
		${(props) => OVERLAP_MARGIN[props.$size]};
	}
`;

export const Icon = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

export const Container = styled.div<{
	$size: AvatarSize;
	$isValid: boolean;
	$borderColor?: string;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	border-radius: 50%;
	overflow: hidden;
	border: 0.2rem solid
		${(props) => (props.$borderColor ? props.$borderColor : props.theme.colors.gray100)};
	background-color: ${(props) => props.theme.colors.gray90};
	${(props) => AVATAR_STYLE[props.$size].container};

	${(props) =>
		props.$isValid
			? css``
			: css`
					${ERROR_PADDING[props.$size]};
					& > img {
						filter: brightness(0) saturate(100%) invert(63%) sepia(5%) saturate(151%)
							hue-rotate(149deg) brightness(90%) contrast(88%);
					}
				`}

	& > ${Icon} {
		${(props) => AVATAR_STYLE[props.$size].icon};
	}
`;
