import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import CRBanner from 'components/base/CRBanner';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { CheckOption } from 'components/base/Selections/type';
import ConsultingTags from 'components/ui/ConsultingTags';
import {
	useAdviceClientOptions,
	useAdviceMethods,
	useAdviceTags,
	useMyAccountInfo,
} from 'lib/hook/react-query';
import { AdviceClientOptionDTO } from 'types/dto';
import { AddAdviceFormType } from 'types/view/advice';

import * as S from './styles';

const ClientDivCdObj: { [k: string]: string } = {
	'CMN110.10': '보호자',
	'CMN110.20': '수급자',
	'CMN110.30': '직원',
};
interface Props {
	form?: AddAdviceFormType;
	onChange?: (form: AddAdviceFormType) => void;
	editMode?: boolean;
}

function ConsultingForm({
	form,
	onChange: onFormChange,
	editMode = false,
}: Props): React.ReactElement {
	const { id } = useParams();

	const { control, getValues, setValue } = useForm<AddAdviceFormType>({
		mode: 'onSubmit',
		defaultValues: form ?? {
			date: {
				date: dayjs().format('YYYYMMDD'),
				start: dayjs().format('HH:mm'),
			},
		},
	});

	const { data: accountInfo } = useMyAccountInfo();
	const { data: adviceMethods } = useAdviceMethods();
	const { data: adviceTags } = useAdviceTags();
	const { data: adviceClientOptions = [] } = useAdviceClientOptions({
		centerId: accountInfo?.centerId,
		keyword: '',
	});

	const consultingMethodOptions = useMemo(
		() =>
			(adviceMethods ?? []).map(
				(method) =>
					({
						label: method.text,
						value: method.id,
					}) as CheckOption<any>,
			),
		[adviceMethods],
	);
	const clientOptions = useMemo(
		() =>
			(adviceClientOptions ?? []).map(
				(client) =>
					({
						label: client.clientNm,
						value: {
							id: client.clientId,
							name: client.clientNm,
							duty: ClientDivCdObj[client.clientDivCd],
							birth: client.clientBirth,
						},
						data: client,
						children: client?.guardians
							?.filter((item) => !item?.selfGuardianYn)
							?.map((guardian) => ({
								label: guardian.clientNm,
								value: {
									name: guardian.clientNm,
									duty: guardian.mainGuardianYn ? '주보호자' : '보호자',
									id: guardian.telNum,
								},
								data: { ...guardian, recipientId: client.clientId },
							})),
					}) as CheckOption<AdviceClientOptionDTO>,
			),
		[adviceClientOptions],
	);

	const targetClientOptions = useMemo(() => {
		const client = clientOptions?.find((option) => option?.data?.clientId === Number(id));
		if (!client) {
			return [];
		}
		setValue('client', client);
		return [client];
	}, [clientOptions]);

	const sections = useMemo(
		() =>
			(adviceTags ?? []).map((adviceTag) => ({
				label: adviceTag.text,
				value: '',
				tags: adviceTag.dtos?.map((dto) => ({
					label: dto.text,
					value: dto.id,
				})),
			})),
		[adviceTags],
	);

	return (
		<S.FormContainer>
			<S.FormAreaContainer style={{ maxWidth: '46rem' }}>
				<Controller
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel label='상담일자' isRequired>
							<CRInput.DateTimePicker
								hide={{ end: true }}
								date={value?.date}
								startTime={value?.start}
								onChange={(date, start) => {
									onChange({ date, start });
									onFormChange?.(getValues());
								}}
								maxLength={4}
							/>
						</CRInputLabel>
					)}
					name='date'
					control={control}
				/>
				<Controller
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel label='상담 방법' isRequired>
							<CRInput.Selector
								placeholder='상담 방법 선택'
								items={consultingMethodOptions}
								onChangeValue={(value) => {
									onChange(value);
									onFormChange?.(getValues());
								}}
								currentValue={value}
							/>
						</CRInputLabel>
					)}
					name='method'
					control={control}
				/>
				<Controller
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel label='상담자' isRequired>
							<CRInput.SearchSelector
								items={targetClientOptions}
								placeholder='상담자 선택'
								onChange={(value) => {
									onChange(value);
									onFormChange?.(getValues());
								}}
								currentValue={value}
							/>
						</CRInputLabel>
					)}
					name='client'
					control={control}
				/>
				<Controller
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel label='태그' isRequired>
							<S.ConsultingTagContainer>
								<ConsultingTags
									sections={sections}
									value={value}
									onClick={(value) => {
										onChange(value);
										onFormChange?.(getValues());
									}}
								/>
							</S.ConsultingTagContainer>
						</CRInputLabel>
					)}
					name='tag'
					control={control}
				/>
			</S.FormAreaContainer>
			<S.FormAreaContainer>
				<Controller
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel label='상담자 의견' isRequired>
							<CRInput.TextArea
								value={value}
								placeholder='상담자 의견 입력 (주민등록번호, 전화번호 등 개인정보 입력 금지)'
								numberOfLines={5}
								onChange={(value) => {
									onChange(value);
									onFormChange?.(getValues());
								}}
								fixedHeight
							/>
						</CRInputLabel>
					)}
					name='advisorOpinion'
					control={control}
				/>
				<Controller
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel label='사회복지사 의견' isRequired>
							<CRInput.TextArea
								value={value}
								placeholder='사회복지사 의견 입력 (주민등록번호, 전화번호 등 개인정보 입력 금지)'
								numberOfLines={5}
								onChange={(value) => {
									onChange(value);
									onFormChange?.(getValues());
								}}
								fixedHeight
							/>
						</CRInputLabel>
					)}
					name='managerOpinion'
					control={control}
				/>
				<Controller
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel label='첨부 파일'>
							<CRInput.FileUploader
								type='multiple'
								placeholder='파일 업로드'
								files={value}
								onChange={(value) => {
									onChange(value);
									onFormChange?.(getValues());
								}}
							/>
						</CRInputLabel>
					)}
					name='files'
					control={control}
				/>
				<CRBanner content='주민등록번호, 전화번호 등 개인정보 작성 금지' type='warning' />
			</S.FormAreaContainer>
		</S.FormContainer>
	);
}

export default ConsultingForm;
