import React, { ChangeEvent, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as Accordion from '@radix-ui/react-accordion';
import dayjs from 'dayjs';

import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import CRFilterGroup from 'components/base/CRInput/CRFilterGroup';
import CRScheduleCalendar from 'components/base/CRScheduleCalendar';
import { TaskTabProps } from 'components/base/CRTab/TaskTab/type';
import { CRText } from 'components/base/CRText';
import EmployeeScheduleDetailDialog from 'components/domain/dialog/EmployeeScheduleDetailDialog';
import ScheduleSendDetailDialog from 'components/domain/dialog/ScheduleSendDetailDialog';
import InformationLayout from 'components/domain/layout/InformationLayout';
import EmployeeDetailBasicInformationTable from 'components/domain/table/EmployeeDetailBasicInformationTable';
import EmployeeListTable from 'components/domain/table/EmployeeListTable';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import { employeeScheduleAdapter } from 'lib/adapter/employee';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import { useMyAccountInfo, useSchedule } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { EmployeeDTO } from 'types/api/employee';

import * as S from './styles';

function EmployeeMonthSchedulePage(): React.ReactElement {
	const [date, setDate] = useState(new Date());
	const {
		currentTab,
		setCurrentTab,
		employees,
		currentEmployee,
		currentEmployeeBase,
		setCurrentEmployee,
		searchText,
		setSearchText,
		currentFilter,
		setCurrentFilter,
		stateFilter,
		dutyFilter,
		managerFilter,
	} = useEmployeePage();
	const { showDialog } = useDialog();
	const { data: myAccountInfo } = useMyAccountInfo();
	const navigate = useNavigate();
	const handleChangeDate = (newDate: Date) => {
		setDate(newDate);
	};

	const { data: scheduleData } = useSchedule(
		{
			type: 'employee',
			employeeId: currentEmployee?.employeeId,
			centerId: myAccountInfo?.centerId,
			yearMonth: dayjs(date).format('YYYYMM'),
		},
		employeeScheduleAdapter(myAccountInfo),
	);

	const handleClickTab = (tab: TaskTabProps) => {
		setCurrentTab(tab.key);
		navigate(
			`/${RouterPath.employee().tab.uniqueKey}/${tab.key}?id=${currentEmployee?.employeeId}`,
		);
	};

	const handleClickDetailItem = (item: EmployeeDTO) => {
		setCurrentEmployee(item);
		navigate(`${RouterPath.scheduleOperate().monthSchedule.key}/employee/${item?.employeeId}`);
	};

	const handleChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchText(event.target.value);
	};

	const handleClickDay = (date: Date) => {
		showDialog(() => (
			<EmployeeScheduleDetailDialog date={date} currentEmployee={currentEmployee} />
		));
	};

	const LeftContentComponent = useMemo(
		() => (
			<S.Container>
				<S.Header>
					<S.Title>직원 목록</S.Title>
				</S.Header>
				<CRInput.Search
					placeholder='직원명, 생년월일, 전화번호 검색'
					value={searchText}
					onChange={handleChangeSearchText}
				/>
				<CRFilterGroup
					filters={[stateFilter, dutyFilter, managerFilter]}
					currentFilter={currentFilter}
					setCurrentFilter={setCurrentFilter}
				/>
				<S.TableContainer>
					<EmployeeListTable
						items={employees}
						searchValue={searchText}
						onClickRow={handleClickDetailItem}
						currentItem={currentEmployee}
					/>
				</S.TableContainer>
				<CRText text={`총 ${employees?.length}명`} />
			</S.Container>
		),
		[currentFilter, currentEmployee, searchText, dutyFilter, stateFilter, managerFilter],
	);

	const handleClickSendSchedule = () => {
		showDialog(() => (
			<ScheduleSendDetailDialog
				type='employees'
				id={currentEmployee?.employeeId}
				params={{
					targetYm: dayjs(date).format('YYYYMM'),
				}}
			/>
		));
	};

	const renderSendButton = () => {
		const renderSyncTime = scheduleData?.lastSyncUpdate
			? `${dayjs(scheduleData.lastSyncUpdate).format('YYYY.MM.DD HH:mm')} 동기화`
			: '';
		return (
			<S.SendButtonContainer>
				{renderSyncTime}
				<CRButton.Default
					palette='gray'
					type='outlined'
					size='default'
					onClick={handleClickSendSchedule}>
					발송
				</CRButton.Default>
			</S.SendButtonContainer>
		);
	};

	const RightContentComponent = useMemo(
		() => (
			<S.Container>
				<S.RightTableContainer>
					<Accordion.Root
						type='multiple'
						style={{
							width: '100%',
							marginBottom: 0,
						}}>
						<Accordion.Item value='직원 정보' asChild>
							<TaskAccordion.Item
								style={{
									marginBottom: 0,
								}}>
								<Accordion.Header asChild>
									<Accordion.Trigger asChild>
										<TaskAccordion.Trigger
											style={{
												padding: 0,
											}}>
											직원 정보
										</TaskAccordion.Trigger>
									</Accordion.Trigger>
								</Accordion.Header>
								<Accordion.Content asChild>
									<TaskAccordion.Content
										style={{
											borderTop: 'none',
											padding: '0.8rem 0',
										}}>
										<EmployeeDetailBasicInformationTable item={currentEmployeeBase} />
									</TaskAccordion.Content>
								</Accordion.Content>
							</TaskAccordion.Item>
						</Accordion.Item>
					</Accordion.Root>
				</S.RightTableContainer>
				<S.RightTabContainer>
					<CRScheduleCalendar
						type='employee'
						date={date}
						item={scheduleData}
						renderRightButton={renderSendButton()}
						onChange={handleChangeDate}
						onClickDay={handleClickDay}
					/>
				</S.RightTabContainer>
			</S.Container>
		),
		[currentTab, handleClickTab],
	);

	useLayoutEffect(() => {
		if (
			window.location.pathname === RouterPath.monthScheduleTab().employee.key &&
			employees?.length
		) {
			navigate(
				`${RouterPath.scheduleOperate().monthSchedule.key}/employee/${employees[0].employeeId}`,
				{
					replace: true,
				},
			);
		}
	}, [currentTab, employees]);

	return (
		<InformationLayout
			LeftContentComponent={LeftContentComponent}
			RightContentComponent={RightContentComponent}
		/>
	);
}

export default EmployeeMonthSchedulePage;
