import { FileDTO } from 'types/dto';

import { BaseResponse } from './base';
import { CenterDTO } from './center';
import { CommonCodeDTO } from './common';

export type EDocRecordingSheetType = 'bath' | 'care' | 'nursing';
export interface EDocRequest {
	path: string;
	param: { [key: string]: any };
}

export interface EDocResponse {
	status: boolean;
	wait: number;
	uid: string;
	version: {
		build: string;
		day: string;
	};
}

export interface ExportEformToPdfRequest {
	eformKey: string;
}

// 직원탭, 수급자탭 -> 전자문서 탭 -> 리스트

export interface EDocListRequest {
	centerId?: number;
	employeeId?: number;
	recipientId?: number;
	paperTypeCds?: string[];
	paperStandardStartDate?: string;
	paperStandardEndDate?: string;
}
export type EmployeeEDocListRequest = Omit<EDocListRequest, 'recipientId'>;
export type RecipientEDocListRequest = Omit<EDocListRequest, 'employeeId'>;

export interface EDocDTO {
	recipientPaperId: number;
	employeePaperId: number;
	paperClassNm: string;
	paperTypeNm: string;
	paperFileNm: string;
	etcPaperNm?: string;
	paperFileCnt: number;
	paperFile: FileDTO;
	remark: string;
	edocNo: string;
	objectKey?: string;
	paperStandardDate: string;
}

export type RecipientEDocDTO = Omit<EDocDTO, 'employeePaperId'>;

export type EmployeeEDocDTO = Omit<EDocDTO, 'recipientPaperId'>;

export type EmployeeEDocListResponse = BaseResponse<EmployeeEDocDTO[]>;

export type RecipientEDocListResponse = BaseResponse<RecipientEDocDTO[]>;

export interface EDocPaperTypesDTO {
	paperTypeCds: CommonCodeDTO[];
}

// 전자문서 서류 유형
export interface EDocPaperTypesRequest {
	paperMenu: 'employee' | 'recipient';
}

export type EDocPaperTypeResponse = BaseResponse<EDocPaperTypesDTO>;

// 재직 증명서 발급 팝업
export interface EDocBeforeRequest {
	templateCode?: string;
	centerId?: number;
	employeeId?: number;
	recipientId?: number;
}

export interface EDocParamValue {
	crfeNm: string;
	params: any[];
}

export interface EDocWithoutSignEmployee {
	employeeId: number;
	employeeNm: string;
	recipientId: number;
	recipientNm: string;
	centerId: number;
	centerNm: string;
	centerAliasNm: string;
	contractId: number;
	serviceId: number;
	serviceTypeCd: string;
	serviceTypeCdNm: string;
	serviceStateCd: string;
	serviceStateCdNm: string;
	dutyCd: string;
	dutyCdNm: string;
	contractStartDate: string;
	contractPeriod: string;
	employeeJoinDate?: string;
	mobilePhoneNo: string;
}

export interface EDocWithoutSignRecipient {
	employeeId: number;
	employeeNm: string;
	recipientId: number;
	recipientNm: string;
	centerId: number;
	centerNm: string;
	centerAliasNm: string;
	contractId: number;
	serviceId: number;
	serviceTypeCd: string;
	serviceTypeCdNm: string;
	serviceStateCd: string;
	serviceStateCdNm: string;
	dutyCd: string;
	dutyCdNm: string;
	contractStartDate: string;
	contractPeriod: string;
}

export interface EDocBeforeIssueInfoDTO {
	edocTemplateId: number;
	edocTemplateCd: string;
	paperTypeCd: string;
	edocNm: string;
	eformFileNm: string;
	esignProcessDayCnt: number;
	data: {
		employee?: EDocWithoutSignEmployee[];
		recipient?: EDocWithoutSignRecipient[];
		stampImageObjectKey: string;
		center?: CenterDTO & {
			stampFile: FileDTO;
		};
		youNm?: string;
		caringStampImageData?: string;
		dutySignCds?: CommonCodeDTO[];
		insCancelCds?: CommonCodeDTO[];
	};
}

export interface EDocDeviceUnusedApplicationIssueDTO {
	edocTemplateId: number;
	edocTemplateCd: string;
	paperTypeCd: string;
	edocNm: string;
	eformFileNm: string;
	esignProcessDayCnt: number;
	data: {
		employee: {
			userId: string;
			email: string;
			userNm: string;
			password: string;
			personalMobilePhoneNo: string;
			workMobilePhoneNo: string;
			passwordChangeNeedYn: boolean;
			accountDisabledYn: boolean;
			lastLoginDate: string;
			center: null;
			positionCd: string;
			birthDt: string;
			memberAccountRoles: null;
			memberAccountId: number;
			createdAt: string;
			createdId: number;
			updatedAt: string;
			updatedId: number;
			id: number;
		};
		center: {
			centerNm: string;
			centerAliasNm: string;
			centerBasAddr: string;
			centerDetailAddr: string;
			centerZipCode: string;
			centerAddrLatitude: number;
			centerAddrLongitude: number;
			hqYn: boolean;
			centerReprPhoneNo: string;
			centerPhoneNo: string;
			centerMark: string;
			centerApiNm: string;
			centerChiefNm: string;
			centerChiefId: number;
			representativeNm: string;
			pcorpLoginId: string;
			pcorpLoginPassword: string;
			pcorpCertiFile: {
				fileDetails: Array<{
					originFileNm: string;
					transFileNm: string;
					fileDeleteYn: boolean;
					fileSize: number;
					fileSeq: number;
					filePathNm: string;
					fileDetailId: number;
					createdAt: string;
					createdId: number;
					updatedAt: string;
					updatedId: number;
				}>;
				fileId: number;
				createdAt: string;
				createdId: number;
				updatedAt: string;
				updatedId: number;
			};
			slackWebHookUrl: string;
			bizNo: string;
			stampFile: {
				fileDetails: Array<{
					originFileNm: string;
					transFileNm: string;
					fileDeleteYn: boolean;
					fileSize: number;
					fileSeq: number;
					filePathNm: string;
					fileDetailId: number;
					createdAt: string;
					createdId: number;
					updatedAt: string;
					updatedId: number;
				}>;
				fileId: number;
				createdAt: string;
				createdId: number;
				updatedAt: string;
				updatedId: number;
			};
			centerOfferServiceNm: string;
			burdenAmtPayAcctDesc: string;
			workLeaveLimitCd: string;
			timeVaryingPermitYn: boolean;
			memberAccounts: null;
			positionCenterMappings: null;
			centerAccounts: null;
			workLeavePermitIps: null;
			centerOfferServices: null;
			centerId: number;
			createdAt: string;
			createdId: number;
			updatedAt: string;
			updatedId: number;
		};
	};
}

export type EmploymentCertificationIssueInfoResponse = BaseResponse<EDocBeforeIssueInfoDTO>;

export interface IssueEDocumentRequest {
	edocTitle: string;
	edocSenderId: number;
	edocSenderNm: string;
	edocSenderPhoneNo?: string;
	edocSenderPhoneNos?: string[];
	edocReceiverPhoneNo?: string;
	edocReceiverId?: number;
	edocReceiverNm?: string;
	edocReceiverBirthDt?: string;
	centerId: number;
	paperTypeCd: string;
	edocParamValue: string;
	signatoryIds?: number[];
	signatoryNms?: string[];
	signatoryPhoneNos?: string[];
	signatoryBirthDts?: string[];
	// 하드코딩 20 수급자 30 직원
	receiverTargetDivCd?: 'CMN118.20' | 'CMN118.30';
	esignWayCd?: string;
	facingManagerId?: number;
	facingManagerPhoneUsageDivCd?: string;
}

export interface IssueEDocumentDTO {
	edocSendId: number;
	edocNo: string;
	signDueDate: string;
}

export type IssueEDocumentResponse = BaseResponse<IssueEDocumentDTO>;

export interface EDocPreviewRequest {
	centerId?: number;
	edocNo?: string;
}

interface EDocTemplate {
	edocTemplateId: number;
	edocTemplateCd: string;
	paperTypeCd: string;
	edocNm: string;
	eformFileNm: string;
	esignProcessDayCnt: number;
	issueTargetYn: boolean;
	edocSends: any;
	esignStepSetups: any;
	createdAt: string;
	createdId: number;
	updatedAt: string;
	updatedId: number;
}

interface EDocSend {
	edocSendId: number;
	edocProviderCd: string;
	edocNo: string;
	edocUuid: string;
	edocTemplate: EDocTemplate;
	edocSendDate: string;
	signDueDate: string;
	edocTitle: string;
	edocSenderId: number;
	edocSenderNm: string;
	edocSenderPhoneNo: string;
	centerId: number;
	lastEsignStateCd: string;
	edocAutoCompleteYn: boolean;
	createdAt: string;
	createdId: number;
	updatedAt: string;
	updatedId: number;
	facingSignReceiverId?: number;
	facingSignReceiverNm?: string;
	facingSignReceiverPhoneNo?: string;
}

interface EDocParam {
	edocParamId: number;
	edocSend: EDocSend;
	requestResponseDivCd: string;
	edocParamValue: string;
	createdAt: string;
	createdId: number;
	updatedAt: string;
	updatedId: number;
}

export interface Data {
	edocSend: EDocSend;
	edocParam: EDocParam;
	esignDetails: any[];
}

export interface EDocPreviewDTO {
	edocTemplateId: number;
	edocTemplateCd: string;
	paperTypeCd: string;
	edocNm: string;
	eformFileNm: string;
	esignProcessDayCnt: number;
	data: Data;
}

export type EDocPreviewResponse = BaseResponse<EDocPreviewDTO>;

// 서약서

export interface EDocPledgeEmployee {
	memberId: number;
	employeeId: number;
	centerId: number;
	korMemberNm: string;
	mobilePhoneNo: string;
	managerNm: string;
	rsdnNo: string;
	insurance: string;
	fullAddr: string;
	contractInfo: string;
	birthDt: string;
	centerNm: string;
	accidentInsuranceAcqDate: string;
}

export interface EDocPledgeRecipient {
	employeeId: number;
	employeeNm: string;
	recipientId: number;
	recipientNm: string;
	centerId: number;
	centerNm: string;
	centerAliasNm: string;
	contractId: number;
	serviceId: number;
	serviceTypeCd: string;
	serviceTypeCdNm: string;
	serviceStateCd: string;
	serviceStateCdNm: string;
	dutyCd: string;
	dutyCdNm: string;
	contractStartDate: string;
	contractPeriod: string;
}

export interface EDocPledgeDTO {
	edocTemplateId: number;
	edocTemplateCd: string;
	paperTypeCd: string;
	edocNm: string;
	eformFileNm: string;
	esignProcessDayCnt: number;
	data: {
		employee: EDocPledgeEmployee;
		recipient: EDocPledgeRecipient[];
		stampImageObjectKey: string;
	};
}

export interface EDocOtherBankDTO {
	edocTemplateId: number;
	edocTemplateCd: string;
	paperTypeCd: string;
	edocNm: string;
	eformFileNm: string;
	esignProcessDayCnt: number;
	data: {
		bankCds: CommonCodeDTO[];
		employee: EDocPledgeEmployee;
		relCds: CommonCodeDTO[];
	};
}

export interface EDocResignDTO {
	edocTemplateId: number;
	edocTemplateCd: string;
	paperTypeCd: string;
	edocNm: string;
	eformFileNm: string;
	esignProcessDayCnt: number;
	data: {
		employee: EDocPledgeEmployee;
		exitCds: CommonCodeDTO[];
		exitDetailCds: CommonCodeDTO[];
	};
}

export interface BurdenAmtPays {
	recipientAffiliationId: number;
	burdenAmtPayMethodCd: string;
}

export interface Guardian {
	recipientGuardianId: number;
	recipientGuardianNm: string;
	guardianRelCd: string;
	guardianRelNm: string;
	mainGuardianYn: boolean;
	mobilePhoneNo: string;
	birthDt: string;
	baseAddr: string;
	detailAddr: string;
	zipCode: string;
}

export interface Day {
	otherServiceUseDayCd: string;
}

export interface RecipientRecipient {
	recipientId: number;
	recipientNm: string;
	genderCd: string;
	genderNm: string;
	mobilePhoneNo: string;
	rsdnNo: string;
	birthDt: string;
	zipCode: string;
	baseAddr: string;
	detailAddr: string;
	longTermGradeCd: string;
	longTermGradeNm: string;
	longTermPeriod: string;
	longTermNo: string;
	burdenRateCd: string;
	burdenRateNm: string;
	managerId: number;
	managerNm: string;
	recipientStateCd: string;
}

export interface Time {
	otherServiceUseStartTime: string;
	otherServiceUseEndTime: string;
	days: Day[];
}

export interface OtherService {
	otherCenterNm: string;
	otherCenterPhoneNo: string;
	serviceTypeCd: string;
	times: Time[];
	remark?: string;
}

export interface EDocAdmissionUseRequestRecipient {
	recipient: RecipientRecipient;
	guardians: Guardian[];
	otherServices: OtherService[];
	burdenAmtPays: BurdenAmtPays;
}

export interface EDocAdmissionUseRequestDTO {
	edocTemplateId: number;
	edocTemplateCd: string;
	paperTypeCd: string;
	edocNm: string;
	eformFileNm: string;
	esignProcessDayCnt: number;
	data: {
		recipient: EDocAdmissionUseRequestRecipient;
		center?: CenterDTO;
		serviceTypes: string[];
		types: string[];
		youNm: string;
	};
}

export interface CreateEDocPaperRequest {
	centerId?: number;
	employeeId?: number;
	recipientId?: number;
	paperTypeCd?: string;
	paperFile?: FileDTO;
	remark?: string;
	etcPaperNm?: string;
	paperStandardDate?: string;
}

// 방문간호지시서
export interface CreateHomeCarePaperRequest {
	centerId?: number;
	employeeId?: number;
	recipientId?: number;
	paperTypeCd?: string;
	paperFile?: FileDTO;
	remark?: string;
	etcPaperNm?: string;
	paperStandardDate?: string;
	vniMedicalInstitutionNm: string;
	vniIssueDt: string;
	vniValidPeriodNm: string;
	vniValidPeriodDayCnt: number;
	vniDoctorLicenseNo: string;
	vniVisitCntStandardCd: string;
	vniVisitCnt: number;
}

export interface CreateEDocPaperDTO {
	docNo: string;
}

export type CreateEDocCreateResponse = BaseResponse<CreateEDocPaperDTO>;

export interface ResendEDocRequest {
	esignId: number;
	centerId?: number;
}

export interface ResendEDocDTO {
	sendUuid: string;
	esignStateCd: string;
}

export type ResendEDocResponse = BaseResponse<ResendEDocDTO>;

// #############################################################################

interface EDocTemplate {
	edocTemplateId: number;
	edocTemplateCd: string;
	paperTypeCd: string;
	edocNm: string;
	eformFileNm: string;
	esignProcessDayCnt: number;
	issueTargetYn: boolean;
	edocSends: any;
	esignStepSetups: any;
	createdAt: string;
	createdId: number;
	updatedAt: string;
	updatedId: number;
}

interface EDocSend {
	edocSendId: number;
	edocProviderCd: string;
	edocNo: string;
	edocUuid: string;
	edocTemplate: EDocTemplate;
	edocSendDate: string;
	signDueDate: string;
	edocTitle: string;
	edocSenderId: number;
	edocSenderNm: string;
	edocSenderPhoneNo: string;
	centerId: number;
	lastEsignStateCd: string;
	edocAutoCompleteYn: boolean;
	createdAt: string;
	createdId: number;
	updatedAt: string;
	updatedId: number;
}
export const enum ESignStateCd {
	SENDED = 'CMN031.20',
	OPENED = 'CMN031.30',
	WRITTEN = 'CMN031.35',
	CONFIRMED = 'CMN031.40',
	CHECKED = 'CMN031.50',
	DONE = 'CMN031.60',
	FAIL = 'CMN031.99',
}

export const enum ESignDivCd {
	WRITTEN = 'CMN169.05',
	SIGN = 'CMN169.10',
	INSPECTION = 'CMN169.15',
}

export interface EDocClientOpenData {
	sendUuid: string;
	signatoryReceiveDate?: string;
	esignStateCd: string;
	signatoryId: number;
	edocPreviewResponse: EDocPreviewDTO;
	signDivCd: string;
}

export interface EDocOpenData {
	sendUuid: string;
	signatoryReceiveDate?: string;
	esignStateCd: string;
	signatoryId: number;
	edocPreviewResponse: EDocPreviewDTO;
}

export interface EDocClientConfirmData {
	sendUuid: string;
	signatoryReceiveDate: string;
	esignStateCd: string;
	edocParamValue: string;
	edocNm: string;
}

export interface EDocManagerOpenData {
	sendUuid: string;
	signatoryReceiveDate?: string;
	esignStateCd: string;
	signatoryId: number;
	edocPreviewResponse: EDocPreviewDTO;
}

export interface EDocManagerConfirmData {
	sendUuid: string;
	signatoryReceiveDate: string;
	esignStateCd: string;
	edocParamValue: string;
	edocNm: string;
}

// ##################################################

export interface EDocClientOpenRequestDTO {
	sendUuid: string;
	birthDt: string;
	isEsign?: boolean;
}

export interface EDocClientOpenResponse {
	code: string;
	message: string;
	data: EDocClientOpenData;
}

export interface EDocClientConfirmRequestDTO {
	sendUuid: string;
	birthDt: string;
	isEsign?: boolean;
}

export interface EDocClientConfirmResponse {
	code: string;
	message: string;
	data: EDocClientConfirmData;
}

export interface EDocManagerOpenRequestDTO {
	sendUuid: string;
	birthDt: string;
	isEsign?: boolean;
}

export interface EDocManagerOpenResponse {
	code: string;
	message: string;
	data: EDocManagerOpenData;
}

export interface EDocManagerConfirmRequestDTO {
	sendUuid: string;
	birthDt: string;
	isEsign?: boolean;
}

export interface EDocManagerConfirmResponse {
	code: string;
	message: string;
	data: EDocManagerConfirmData;
}

export interface ConfirmEDocRequest {
	esignId: number;
	centerId: number;
}

// 문서 검수
// eslint-disable-next-line
export interface ConfirmEDocDTO {}

export type ConfirmEDocResponse = BaseResponse<ConfirmEDocDTO>;

export interface OpenEDocRequest {
	esignId: number;
	centerId: number;
}

export type OpenEDocResponse = BaseResponse<EDocOpenData>;

export interface ScheduleRecordingInputs {
	serviceDt: string;
	startTime: string;
	inputIndex: string;
	untagDesc: string | null;
	personalHygieneYn: boolean;
	washBodyHelpYn: boolean;
	mealHelpYn: boolean;
	positionChageYn: boolean;
	moveHelpYn: boolean;
	restroomUseYn: boolean;
	physicalActivitySupptOfferHourCnt: number;
	recogStimulationActivityHourCnt: number;
	dailylifeTogetherHourCnt: number;
	recogBehaviorChangeHourCnt: number;
	commuhelpCompanionEncourageHourCnt: number;
	mealCleanTidyWashYn: boolean;
	personalActivitySupptYn: boolean;
	hworkDailylifeSupptOfferHourCnt: number;
	physicalFunctionStateValue?: string;
	mealFunctionStateValue?: string;
	recogFunctionStateValue?: string;
	fecesMistakeCnt: number;
	urineMistakeCnt: number;
	bathCarNo: string;
	incarBathYn: boolean;
	inhomeBathYn: boolean;
	inhomeTubBathYn: boolean;
	longTermInstitutionBathYn: boolean;
	pbathBathYn: boolean;
	wholebodyBathOfferYn: boolean;
	bathBedOfferYn: boolean;
	bathChairOfferYn: boolean;
	beforebathUridefStateConfirmYn: boolean;
	beforebathBedsoreStateConfirmYn: boolean;
	beforebathSkinStateConfirmYn: boolean;
	afterbathSkinStateConfirmYn: boolean;
	afterbathGroomingStateConfirmYn: boolean;
	afterbathTidyStateConfirmYn: boolean;
	vniMedicalInstitutionNm: string;
	vniIssueDt: string;
	vniValidPeriodNm: string;
	vniDoctorLicenseNo: string;
	vniVisitCntStandardCd: string;
	vniVisitCnt: number;
	minBloodPressure: number;
	maxBloodPressure: number;
	pulseCnt: number;
	bodyTemperature: number;
	healthMngtHourCnt: number;
	jointBuildPreventYn: boolean;
	dosageMngtYn: boolean;
	basicHealthMngtYn: boolean;
	recogTrainingYn: boolean;
	nursingTreatHourCnt: number;
	bedsoreMngtYn: boolean;
	nutritionMngtYn: boolean;
	painMngtYn: boolean;
	excretionMngtYn: boolean;
	diabetesfootMngtYn: boolean;
	respiratoryNurboolean: boolean;
	respiratoryNursingYn: boolean;
	dialysisNursingYn: boolean;
	oralNursingYn: boolean;
	specialDesc: string;
	pageNo: string;
	colNo: string;
}

export type VisitNursingScheduleRecordingInputs = Pick<
	ScheduleRecordingInputs,
	| 'serviceDt'
	| 'startTime'
	| 'inputIndex'
	| 'untagDesc'
	| 'minBloodPressure'
	| 'maxBloodPressure'
	| 'pulseCnt'
	| 'bodyTemperature'
	| 'healthMngtHourCnt'
	| 'jointBuildPreventYn'
	| 'dosageMngtYn'
	| 'basicHealthMngtYn'
	| 'recogTrainingYn'
	| 'nursingTreatHourCnt'
	| 'bedsoreMngtYn'
	| 'nutritionMngtYn'
	| 'painMngtYn'
	| 'excretionMngtYn'
	| 'diabetesfootMngtYn'
	| 'respiratoryNursingYn'
	| 'dialysisNursingYn'
	| 'oralNursingYn'
	| 'specialDesc'
>;

export type VisitBathScheduleRecordingInputs = Pick<
	ScheduleRecordingInputs,
	| 'serviceDt'
	| 'startTime'
	| 'inputIndex'
	| 'untagDesc'
	| 'inhomeTubBathYn'
	| 'longTermInstitutionBathYn'
	| 'pbathBathYn'
	| 'bathCarNo'
	| 'incarBathYn'
	| 'inhomeBathYn'
	| 'wholebodyBathOfferYn'
	| 'bathBedOfferYn'
	| 'bathChairOfferYn'
	| 'beforebathUridefStateConfirmYn'
	| 'beforebathBedsoreStateConfirmYn'
	| 'beforebathSkinStateConfirmYn'
	| 'afterbathSkinStateConfirmYn'
	| 'afterbathGroomingStateConfirmYn'
	| 'afterbathTidyStateConfirmYn'
	| 'specialDesc'
>;

export type VisitCareScheduleRecordingInputs = Pick<
	ScheduleRecordingInputs,
	| 'serviceDt'
	| 'startTime'
	| 'inputIndex'
	| 'untagDesc'
	| 'personalHygieneYn'
	| 'washBodyHelpYn'
	| 'mealHelpYn'
	| 'positionChageYn'
	| 'restroomUseYn'
	| 'moveHelpYn'
	| 'physicalActivitySupptOfferHourCnt'
	| 'recogStimulationActivityHourCnt'
	| 'dailylifeTogetherHourCnt'
	| 'recogBehaviorChangeHourCnt'
	| 'commuhelpCompanionEncourageHourCnt'
	| 'mealCleanTidyWashYn'
	| 'personalActivitySupptYn'
	| 'hworkDailylifeSupptOfferHourCnt'
	| 'physicalFunctionStateValue'
	| 'mealFunctionStateValue'
	| 'recogFunctionStateValue'
	| 'fecesMistakeCnt'
	| 'urineMistakeCnt'
	| 'specialDesc'
	| 'pageNo'
	| 'colNo'
>;

export type VisitBathDisinfectionRecordingInputs = Partial<ScheduleRecordingInputs>;

export type ScheduleRecordingInputTypes =
	| VisitNursingScheduleRecordingInputs
	| VisitBathScheduleRecordingInputs
	| VisitCareScheduleRecordingInputs;
// | VisitBathDisinfectionRecordingInputs;

export interface SaveRecordSheetClientRequestDto {
	serviceYm: string;
	centerId: number;
	recipientId?: number;
	signatoryId?: number;
	employeeId: null;
	sendUuid: string;
	birthDt: string;
	confirmYn: boolean;
	inspectorYn: boolean;
	edocNo: number;
	scheduleRecordingInputs: ScheduleRecordingInputTypes[];
}

export interface SaveRecordSheetClientRequestParams {
	type: EDocRecordingSheetType;
	input: SaveRecordSheetClientRequestDto;
}

export interface SaveRecordSheetClientResponseData {
	recipientId: number;
}

export type SaveRecordSheetClientResponse = {
	code: string;
	message: string;
	data: SaveRecordSheetClientResponseData;
};

export interface DeletePaperRequestDTO {
	centerId: number;
	recipientId?: number;
	employeeId?: number;
	paperId: number;
}

export type DeletePaperResponse = BaseResponse<BaseResponse>;

export interface GetEFormFileNameRequest {
	paperTypeCd: string;
	targetDateTime: string;
}

export interface GetEFormFileNameDTO {
	eformFileNm: string;
}

export type GetEFormFileNameResponse = BaseResponse<GetEFormFileNameDTO>;

export interface GetRecipientEdocAdmissionUseRequest {
	centerId?: number;
	edocNo?: string;
}

export interface RecipientEdocAdmissionUseService {
	serviceTypeCd: string;
	serviceDesc: string;
	serviceCenterNm: string;
	serviceCenterMark: string;
	serviceFeeAmt?: number;
	serviceCntOfMonth?: number;
	serviceAmtOfMonth: number;
	serviceTotalAmt?: number;
}

interface RecipientPaperAdmissionUseWelfare {
	serviceTypeCd: string;
	serviceDesc: string;
	feeAmt: string;
	serviceCnt: string;
}

export interface RecipientEdocAdmissionUseDTO {
	centerId: number;
	edocNo?: string;
	centerNm: string;
	centerMark: string;
	paperTypeCd: string;
	admissionUseDivCd?: string;
	requestDt: string;
	requestManagerId: number;
	requestManagerNm: string;
	requestManagerBirthDt: string;
	requestManagerPhoneNo: string;
	requestManagerAddr: string;
	requestManagerRelNm: string;
	recipientId?: number;
	recipientNm: string;
	recipientSignNm: string;
	recipientBirthDt: string;
	recipientPhoneNo: string;
	recipientAddr: string;
	recipientRsdnNo: string;
	longTermNo: string;
	longTermGradeCd?: string;
	longTermGradeNm?: string;
	admissionUseGubun1?: boolean;
	admissionUseGubun2?: boolean;
	useStartDt: string;
	useEndDt: string;
	useServices: RecipientEdocAdmissionUseService[];
	useWelfare: RecipientPaperAdmissionUseWelfare[];
	edocSenderIds?: number[];
	lastEsignStateCds?: string[];
	edocTitle?: string;
	docNo?: string;
	edocSendId?: number;
}

export interface ConfirmRecipientEdocAdmissionUseRequest {
	centerId: number;
	signatoryId: number;
	signDivCd: string;
	edocNo: string;
	sendUuid: string;
	birthDt: string;
	confirmYn?: boolean;
	recipientEdocAdmissionUseRequest: {
		paperTypeCd: string;
		admissionUseDivCd?: string;
		requestDt: string;
		requestManagerBirthDt: string;
		recipientAddr: string;
		recipientRsdnNo: string;
		longTermNo: string;
		longTermGradeCd?: string;
		admissionUseGubun1?: boolean;
		admissionUseGubun2?: boolean;
		useStartDt: string;
		useEndDt: string;
		useServices: RecipientEdocAdmissionUseService[];
		useWelfare: RecipientPaperAdmissionUseWelfare[];
	};
}

export type GetRecipientEdocAdmissionUseResponse = BaseResponse<RecipientEdocAdmissionUseDTO>;

export type ConfirmRecipientEdocAdmissionUseResponse = BaseResponse<RecipientEdocAdmissionUseDTO>;
