import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { Toast } from 'components/base/CRToast';
import { InsuranceCancel2 } from 'lib';
import { useMyAccountInfo } from 'lib/hook/react-query';
import { useUpdateInsuranceDetailInfo } from 'lib/hook/react-query/mutation/insurance';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { InsuranceState } from 'pages/dashboard/InsuranceDetailPage';
import { ResponseCode } from 'types/api';
import { InsuranceDetailDTO, UpdateInsuranceDetailRequest } from 'types/api/insurance';

import * as S from './styles';

interface Props {
	item: InsuranceDetailDTO;
	employeeId: string;
	insuranceRequestId: string;
	onRefetch: () => void;
}

export function InsuranceCancelDialog({ item, employeeId, insuranceRequestId, onRefetch }: Props) {
	const {
		control,
		trigger,
		handleSubmit,
		formState: { isValid },
	} = useForm<{ cancelDesc: string }>({
		resolver: yupResolver(InsuranceCancel2),
	});
	const { hideDialog } = useDialog();
	const { data: myAccountInfo } = useMyAccountInfo();

	const { mutate: updateInsuranceDetailInfo, isLoading } = useUpdateInsuranceDetailInfo(
		(client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success('정상적으로 4대보험 신고 신청을 철회 하였습니다.');
				client.invalidateQueries([
					endpoint.getEmployeeBaseInfo.key,
					{
						centerId: Number(myAccountInfo?.centerId),
						employeeId,
					},
				]);
				onRefetch();
				hideDialog();
			} else {
				Toast.error('저장에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		},
	);

	const onSubmit = (data: { cancelDesc: string }) => {
		if (isLoading) return;
		const param: UpdateInsuranceDetailRequest = {
			employeeId,
			insuranceRequestId,
			insuranceRequestStateCd: InsuranceState.철회,
			cancelDesc: data.cancelDesc,
		};

		updateInsuranceDetailInfo(param);
	};

	const submitForm = () => {
		trigger().then((isValid) => {
			if (isValid) {
				handleSubmit(onSubmit)();
			}
		});
	};

	const requestType = useMemo(
		() => ({
			label: item?.insuranceRequestType.text,
			value: item?.insuranceRequestType.id,
		}),
		[item],
	);

	return (
		<CRDialog
			title='4대보험 신고 신청 철회'
			// height='60rem'
			onClickClose={hideDialog}
			body={
				<S.Container>
					<CRInputLabel isRequired label='신고 유형'>
						<CRInput.Selector disabled items={[requestType]} currentValue={requestType} />
					</CRInputLabel>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel isRequired label='철회 사유'>
								<CRInput.Default placeholder='철회 사유 입력' value={value} onChange={onChange} />
							</CRInputLabel>
						)}
						name='cancelDesc'
						control={control}
					/>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						palette='primary'
						size='default'
						disabled={!isValid}
						onClick={submitForm}>
						신청 철회
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
