import React, { useCallback } from 'react';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRFileName from 'components/base/CRFileName';
import CRTable from 'components/base/CRTable';
import { useDownload } from 'lib/hook/util/useDownload';
import { FileDTO } from 'types/dto/common';
import { FullTimeEmployeeWorkDetailDTO } from 'types/dto/full-time-employee';

import { displayPadTime } from '../../../../lib';
import { FULL_TIME_EMPLOYEE_WORK_DETAILS_TABLE_HEADER_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	items?: FullTimeEmployeeWorkDetailDTO[] | null;
	offset?: number;
}

function FullTimeEmployeeWorkDetailsTable({ items = [], offset = 0 }: Props): React.ReactElement {
	const { downloadFiles } = useDownload();

	const renderOption = (value: number, item?: FullTimeEmployeeWorkDetailDTO) => (
		<S.ButtonContainer>
			{item?.attachFile && item.attachFile?.fileDetails?.some((item) => !item.fileDeleteYn) && (
				<CRButton.Default
					size='xSmall'
					type='outlined'
					palette='gray'
					onClick={() =>
						downloadFiles(item?.attachFile?.fileDetails?.filter((item) => !item.fileDeleteYn))
					}>
					다운로드
				</CRButton.Default>
			)}
		</S.ButtonContainer>
	);

	const renderFile = useCallback((item?: FileDTO) => {
		const fileNames = item?.fileDetails
			?.filter((file) => !file.fileDeleteYn)
			?.map((data) => data.originFileNm);
		return <CRFileName maxLength={17} fileNames={fileNames} />;
	}, []);

	return (
		<S.Container>
			<CRTable.Root>
				<CRTable.Head heads={FULL_TIME_EMPLOYEE_WORK_DETAILS_TABLE_HEADER_CONFIG} offset={offset} />
				<CRTable.Body>
					{items?.map((item, index) => (
						<CRTable.Row
							key={item.workDetailRequestId}
							item={{ ...item, index: index + 1 }}
							renderKeys={[
								'index',
								'workStartDt',
								'workStartTime',
								'workRequestKindNm',
								'remark',
								'attachFile',
								'workDetailRequestId',
							]}
							customRender={{
								workStartDt: (_, item) =>
									`${dayjs(item?.workStartDt).format('YYYY.MM.DD')} ~ ${dayjs(
										item?.workEndDt,
									).format('YYYY.MM.DD')}`,
								workStartTime: (_, item) =>
									`${displayPadTime(item?.workStartTime)} ~ ${displayPadTime(item?.workEndTime)}`,
								attachFile: renderFile,
								workDetailRequestId: renderOption,
							}}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default FullTimeEmployeeWorkDetailsTable;
