/* eslint-disable no-underscore-dangle */

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as _Sentry from '@sentry/react';
import { AxiosError, AxiosResponse } from 'axios';

import { EnvConfig } from 'lib/config/env';

import ApiError from './error-class/api-error';
import ComponentBoundaryError from './error-class/component-boundary-error';
import GlobalBoundaryError from './error-class/global-boundary-error';
import ServerError from './error-class/server-error';

type SeverityLevel = 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug';
interface SentryErrorConfig {
	level?: SeverityLevel;
	tags?: { [key: string]: any };
	fingerprint?: string[];
}

export class Sentry {
	static defaultLevel: SeverityLevel = 'error';

	static defaultTags = {
		build_env: EnvConfig.BUILD_ENV,
	};

	/**
	 * seesion토큰 파싱해서 유저정보 넣어줌.
	 * @returns null | token payload
	 */
	static getUserInfoFromToken = (): any => {
		function parseJwt(token: string) {
			const base64Url = token.split('.')[1];
			const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
			const jsonPayload = decodeURIComponent(
				window
					.atob(base64)
					.split('')
					.map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
					.join(''),
			);

			return JSON.parse(jsonPayload);
		}

		try {
			const sessionToken = sessionStorage.getItem('access_token');
			if (!sessionToken) return null;

			// 토큰의 payload를 디코딩
			const payload = parseJwt(sessionToken);
			if (!payload?.sub || !payload.auth) return null;
			const detail = JSON.parse(payload.sub);
			return {
				role: payload.auth,
				...detail,
			};
		} catch (error: any) {
			console.error('Error decoding or verifying token:', error.message);
			return null;
		}
	};

	/**
	 * 해당 에러가 로깅할 에러인지 여부 확인
	 * @returns
	 */
	static checkIsTurnedOffNotification = (error: any): boolean => {
		const turnedOffNotificationErrors = [
			{
				method: 'POST',
				url: '/member-accounts/sign-in',
			},
			{
				method: 'GET',
				url: '/employees/employeeResign-info',
			},
			{
				method: 'POST',
				url: '/edoc/client/open',
				message: '생년월일이 일치하지 않습니다.',
			},
			{
				method: 'POST',
				url: '/edoc/client/open',
				message: '취소된 전자 문서입니다.',
			},
			{
				message: '중복된 데이터가 존재합니다.',
			},
		];

		const parseError = error?.message?.split('\n')?.[0]?.split(' ');
		const errorInfo = error?.message?.split('\n')?.[1];
		const [methodInfo, url] = parseError ?? ['', ''];

		const matchedTurnedOffError = turnedOffNotificationErrors.find((request) => {
			// 동일 메서드인지 확인
			if (request?.method && !methodInfo?.includes(request.method)) return false;
			// 동일 url인지 확인
			if (request?.url && url !== request.url) return false;
			// 메세지를 포함하는지 확인
			if (request?.message && !errorInfo?.includes(request.message)) return false;

			return true;
		});
		if (matchedTurnedOffError) {
			console.error('The notification for the corresponding error has been turned off.');
			return true;
		}
		return false;
	};

	/**
	 * 센트리 활성화 상태 체크
	 * @returns
	 */
	static checkSentryEnabled = (): boolean => {
		const isSentryLogging = EnvConfig.ENABLE_SENTRY || EnvConfig.BUILD_ENV !== 'local';
		if (!isSentryLogging) {
			console.error('Sentry disabled');
			return false;
		}
		return true;
	};

	/**
	 * 센트리 로깅시 고유지문 생성 유틸
	 * @returns
	 */
	static _makeApiErrorFingerprint = (error: any) => {
		// 요청메서드
		const method = error?.config?.method || 'method undefined';
		// 요청주소
		const url = error?.config?.url || 'url undefined';
		// 요청 http응답값
		const status = error?.status || error?.response?.status || 'status undefined';
		// 요청 응답 코드값
		const code = error?.data?.code || error?.response?.data?.code || 'code undefined';

		const requestParamInfo = error?.config?.data || error?.response?.config?.data;

		let parameter;
		if (requestParamInfo instanceof FormData) {
			parameter = {};
		} else if (typeof requestParamInfo === 'string') {
			parameter = JSON.parse(requestParamInfo);
		} else if (requestParamInfo) {
			parameter = requestParamInfo;
		} else {
			parameter = {};
		}

		const { recipientId, employeeId, sendUuid } = parameter;

		const fingerprint = [method, url, status, code, recipientId, employeeId, sendUuid];
		const filteredFingerprint = fingerprint.filter((val) => val !== undefined && val !== null);

		return filteredFingerprint;
	};

	/**
	 * 센트리 로깅시 사용할 기본 에러 메서드
	 * 코어 로직이기 때문에 가능한 모든 에러는 해당 메서드를 사용해주세요.
	 * @returns
	 */
	static _sentryError(error: any, config?: SentryErrorConfig): void {
		// 센트리가 활성화 되어있는지 확인
		if (!Sentry.checkSentryEnabled()) return;
		// 에러 알림이 비활성화된 에러인지 확인
		if (Sentry.checkIsTurnedOffNotification(error)) return;

		const scope = new _Sentry.Scope();

		// 에러 레벨 설정
		const level = config?.level || Sentry.defaultLevel;
		scope.setLevel(level);

		// 에러 태그 설정
		const tags = {
			...Sentry.defaultTags,
			...(config?.tags || {}),
		};
		scope.setTags(tags);

		const fingerprint = config?.fingerprint || [];
		scope.setFingerprint(fingerprint);

		scope.setUser(Sentry.getUserInfoFromToken());

		if (error?.extraInfo) {
			Object.entries(error.extraInfo).forEach(([key, value]) => {
				scope.setExtra(key, JSON.stringify(value, null, 2));
			});
		}

		_Sentry.captureException(error, scope);
	}

	/**
	 * 사용자 정보를 컨텍스트에 저장합니다.
	 * @returns
	 */
	static setContextUserInfo = (userInfo: { [key: string]: any }) =>
		_Sentry.setContext('userInfo', userInfo);

	static init() {
		if (!Sentry.checkSentryEnabled()) return;

		_Sentry.init({
			dsn: 'https://3839800050603f3335c8a8e19726ba25@o4506516689780736.ingest.sentry.io/4506516691419136',
			integrations: [
				new _Sentry.BrowserTracing({
					// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
					tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
				}),
				new _Sentry.Replay({
					maskAllText: false,
					blockAllMedia: false,
				}),
			],
			// Performance Monitoring
			tracesSampleRate: 1.0, //  Capture 100% of the transactions
			// Session Replay
			// 세션리플레이 비활성화
			// replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
			// 세션에러로깅 비활성화
			// replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		});
		Sentry.setContextUserInfo({});
	}

	/**
	 * 특정포인트에서 모니터링을 하고싶을때 사용할 로깅 메서드
	 * @returns
	 */
	static error(error: string, config?: SentryErrorConfig) {
		return this._sentryError(new Error(error), config);
	}

	/**
	 * API응답 데이터의 코드가 200이 아닌경우 사용할 로깅 메서드
	 * @returns
	 */
	static apiError(error: AxiosResponse) {
		return this._sentryError(new ApiError(error), {
			tags: {
				error_type: 'API',
			},
			fingerprint: Sentry._makeApiErrorFingerprint(error),
		});
	}

	/**
	 * 서버요청이 정상이 아닌경우 사용할 로깅 메서드
	 * @returns
	 */
	static serverError(error: AxiosError) {
		return this._sentryError(new ServerError(error), {
			tags: {
				error_type: 'SERVER',
			},
			fingerprint: Sentry._makeApiErrorFingerprint(error),
		});
	}

	/**
	 * 전역 에러바운더리에서 에러가 발생할 경우 사용할 로깅 메서드
	 * @returns
	 */
	static globalBoundaryError(error: Error) {
		return this._sentryError(new GlobalBoundaryError(error), {
			tags: {
				error_type: 'GLOBAL',
			},
		});
	}

	/**
	 * 컴포넌트 단위의 에러바운더리에서 에러가 발생할 경우 사용할 로깅 메서드
	 * @returns
	 */
	static componentBoundaryError(error: Error) {
		return this._sentryError(new ComponentBoundaryError(error), {
			tags: {
				error_type: 'COMPONENT',
			},
		});
	}
}
