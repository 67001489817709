import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import EDocViewer from 'components/base/CRFileViewer/viewer/EDocViewer';
import EDocVisitCareRecordForm from 'components/domain/form/EDocRecodSheetForm/EDocVisitCareRecordForm';
import EDocVisitNursingRecordForm from 'components/domain/form/EDocRecodSheetForm/EDocVisitNursingRecordForm';
import EDocVisitBathRecordForm from 'components/domain/form/EDocRecodSheetForm/EdocVisitBathRecordForm';
import { MOBILE_LAYOUT_ID as MOBILE_LAYOUT_CONTAINER_ID } from 'components/domain/layout/ClientMobileLayout';
import useEDocClientPage from 'lib/hook/eDoc/useESignInfoPage';
import {
	useOpenEDocClient,
	useOpenEDocManager,
	useSaveRecordSheetsClient,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { ResponseCode } from 'types/api';
import { EDocRecordingSheetType, ScheduleRecordingInputTypes } from 'types/api/eDoc';

import EDocDialogs from '../EDocDialogs';

export const RecordWriteFormList = ['CMN119.0052', 'CMN119.0053', 'CMN119.0054', 'CMN119.0066'];

type PagerTypeCd = (typeof RecordWriteFormList)[number];

interface Props {
	paperTypeCd: PagerTypeCd[number];
	crfeNm: string;
	params: any[];
	isSubmittable: boolean;
	onConfirm: () => void;
}

function EDocRecordWriteForm({ paperTypeCd, crfeNm, params, isSubmittable, onConfirm }: Props) {
	const { eDocInfo, isErp, authInfo, setEDocConfirmResult, setEDocInfo } = useEDocClientPage();
	const { hideDialog, showDialog } = useDialog();
	const { mutateAsync: saveRecordSheet, isLoading: isRecordSheetSaving } =
		useSaveRecordSheetsClient();

	const param = useParams();
	const { mutateAsync: openEdoc, isLoading: isOpeningEDoc } = isErp
		? useOpenEDocManager()
		: useOpenEDocClient();

	const recipientId = Number(params[0]?.recipientId);

	const isRecipientChecking = eDocInfo?.signatoryId === recipientId;

	const scrollToTop = () => {
		const mobileLayoutContainer = document.getElementById(MOBILE_LAYOUT_CONTAINER_ID);
		if (mobileLayoutContainer) {
			mobileLayoutContainer.scrollTop = 0;
		}
	};

	const handleOpenEDdc = async () => {
		if (!param.id || isOpeningEDoc) return;

		const authParams = { birthDt: authInfo?.birthDt || '', sendUuid: param.id, isEsign: true };

		const res = await openEdoc(authParams);

		if (res?.code === ResponseCode.SUCCESS) {
			setEDocInfo(res.data);
		}
	};

	const saveHandler =
		(type: EDocRecordingSheetType) => async (arg: ScheduleRecordingInputTypes) => {
			if (!isSubmittable) return;
			if (isRecordSheetSaving) return;
			if (!authInfo || !eDocInfo) return;
			const res = await saveRecordSheet({
				type,
				input: {
					employeeId: null,
					recipientId,
					signatoryId: eDocInfo.signatoryId,
					birthDt: authInfo.birthDt,
					sendUuid: authInfo.sendUuid,
					serviceYm: arg.serviceDt.slice(0, 6),
					centerId: eDocInfo.edocPreviewResponse.data.edocSend.centerId,
					inspectorYn: isErp,
					confirmYn: false,
					edocNo: Number(eDocInfo.edocPreviewResponse.data.edocSend.edocNo),
					scheduleRecordingInputs: [arg],
				},
			});
			if (res.code !== ResponseCode.SUCCESS) {
				throw new Error('기록지 저장에 실패했습니다.');
			} else {
				await handleOpenEDdc();
			}
			scrollToTop();
		};

	const submitHandler =
		(type: EDocRecordingSheetType) => async (arg: ScheduleRecordingInputTypes) => {
			if (!isSubmittable) return;
			if (isRecordSheetSaving) return;
			if (!authInfo || !eDocInfo) return;

			const onClickConfirm = async () => {
				const res = await saveRecordSheet({
					type,
					input: {
						employeeId: null,
						recipientId,
						signatoryId: eDocInfo.signatoryId,
						birthDt: authInfo.birthDt,
						sendUuid: authInfo.sendUuid,
						serviceYm: arg.serviceDt.slice(0, 6),
						centerId: eDocInfo.edocPreviewResponse.data.edocSend.centerId,
						inspectorYn: isErp,
						confirmYn: true,
						edocNo: Number(eDocInfo.edocPreviewResponse.data.edocSend.edocNo),
						scheduleRecordingInputs: [arg],
					},
				});
				setEDocConfirmResult({ code: res.code, message: res.message });
				onConfirm();
				hideDialog();
				if (res.code !== ResponseCode.SUCCESS) {
					throw new Error('기록지 제출실패');
				}
			};

			const SubmitModal = isErp ? EDocDialogs.EDocConfirm : EDocDialogs.EDocSubmit;

			showDialog(({ hideDialog }) => (
				<SubmitModal
					documentName={eDocInfo?.edocPreviewResponse.edocNm || '전자문서'}
					onSubmit={onClickConfirm}
					onCancel={hideDialog}
				/>
			));
		};

	const renderRecordWriteForm = () => {
		// 급여제공기록지(방문간호)
		if (paperTypeCd === 'CMN119.0052') {
			return (
				<EDocVisitNursingRecordForm
					onSave={saveHandler('nursing')}
					onSubmit={submitHandler('nursing')}
					params={params}
					isSubmittable={isSubmittable}
					disabled={isRecipientChecking}
				/>
			);
		}
		// 급여제공기록지(방문목욕)
		if (paperTypeCd === 'CMN119.0053') {
			return (
				<EDocVisitBathRecordForm
					params={params}
					onSubmit={submitHandler('bath')}
					isSubmittable={isSubmittable}
					onSave={saveHandler('bath')}
					disabled={isRecipientChecking}
				/>
			);
		}
		// 급여제공기록지(방문요양)
		if (paperTypeCd === 'CMN119.0054') {
			return (
				<EDocVisitCareRecordForm
					params={params}
					onSubmit={submitHandler('care')}
					isSubmittable={isSubmittable}
					onSave={saveHandler('care')}
					disabled={isRecipientChecking}
				/>
			);
		}
		// 방문목욕 소독 및 관찰일지
		// 1차 스펙 포함 X
		// if (paperTypeCd === 'CMN119.0066') {
		// 	return (
		// 		<EDocVisitBathDisinfectionRecordForm
		// 			onSave={saveHandler}
		// 			onSubmit={submitHandler}
		// 			params={params}
		// 			isSubmittable={isSubmittable}
		// 		/>
		// 	);
		// }
		// 최후의 예외처리
		return <EDocViewer crfeName={crfeNm} params={params} />;
	};

	return <>{renderRecordWriteForm()}</>;
}

export default EDocRecordWriteForm;
