import React, { ReactNode } from 'react';

import Assets from 'assets';

import CRIcon from '../CRIcon';
import { CRText } from '../CRText';
import * as S from './styles';

interface Props {
	items: { id: string; item: ReactNode }[];
	listStyle?: 'ol' | 'ul';
	customBullet?: ReactNode;
}

function CRTextList({ items, listStyle = 'ul', customBullet }: Props) {
	return (
		<S.ListContainer as={listStyle}>
			{items.map(({ id, item }, index) => (
				<S.StyledListItem key={id}>
					{listStyle === 'ol' && (
						<S.BulletContainer>
							<CRText text={`${index + 1}.`} padding='0.4rem 0 0 0' />
						</S.BulletContainer>
					)}
					{listStyle === 'ul' &&
						(customBullet || (
							<S.BulletContainer>
								<CRIcon src={Assets.icon.dot} />
							</S.BulletContainer>
						))}
					<span>{item}</span>
				</S.StyledListItem>
			))}
		</S.ListContainer>
	);
}

export default CRTextList;
