import React, { useMemo } from 'react';

import dayjs from 'dayjs';

import InformationTable from 'components/ui/InformationTable';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import { FullTimeEmployeeAnnualTORemainAdjustDTO } from 'types/dto/full-time-employee';

import * as S from './styles';

interface Props {
	items?: FullTimeEmployeeAnnualTORemainAdjustDTO[];
}

export default function AnnualTODeductionTab({ items = [] }: Props): React.ReactElement {
	const header = useMemo((): InformationTableItemType[] => {
		const base = [
			{
				type: 'label',
				label: '조정일',
				labelStyle: {
					width: '16rem',
				},
			},
			{
				type: 'label',
				label: '차감일수',
				labelStyle: {
					width: '10rem',
				},
			},
			{
				type: 'label',
				label: '조정 후 잔여 연차',
				labelStyle: {
					width: '16rem',
				},
			},
			{
				type: 'label',
				label: '사유',
				labelStyle: {
					width: '20rem',
				},
			},
			{
				type: 'label',
				label: '',
			},
		] as InformationTableItemType[];

		return base;
	}, []);

	return (
		<S.TabContainer>
			<InformationTable
				items={[
					header,
					...items.map(
						(item) =>
							[
								{
									type: 'value',
									value: dayjs(item.annualAdjustDate).format('YYYY.MM.DD'),
								},
								{
									type: 'value',
									value: item.adjustAnnualCntNm,
								},
								{
									type: 'value',
									value: item.afterAnnualDayCntNm,
								},
								{
									type: 'value',
									value: item.annualAdjustDesc,
								},
							] as InformationTableItemType[],
					),
				]}
			/>
		</S.TabContainer>
	);
}
