import { useMemo } from 'react';

import { QueryKey, UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { useApi } from 'lib/provider/service/Api';
import IApi from 'lib/service/Api/IApi';
import { MethodParametersAndReturnType } from 'types/view/util';

/**
 * useCRQuery에 사용자 정의된 키와, 매개변수로 전달 받은 값을 조합해 새로운
 * QueryKey를 반환한다.
 * @param keys 사용자 정의 쿼리 키를 나타낸다
 * @param param useCRQuery에서 전달 받은 파라미터의 인자 리스트
 * @returns {QueryKey} react-query에서 사용하는 QueryKey로 반환
 */
function generateQueryKeys(key: string, param: any[]): QueryKey {
	if (!param.length) return [key];

	const hasAdapter = param.length === 1 && typeof param[0] === 'function';

	if (hasAdapter) return [key];

	return [key, param[0]];
}

/**
 * useQuery가 원격 API를 호출해야하는 지 의 여부를 결정하는 조건부 함수
 * 아래의 정책 사항을 준수한다
 * 01. default는 true이다
 * 02. option에 enabledKey가 있고, 입력 인자 param[0]가 있을 때, 입력 인자 객체 안에 값이 있으면 true를 반환
 * 03. option에 enabledKey가 있고, 입력 인자 param[0]가 있을 때, 입력 인자 객체 안에 값이 없으면 false를 반환
 * 04. 위와 같은 논리를 enabledKey가 문자형 배열일 때도 준수함
 * @param param
 * @param option
 * @returns
 */
function getEnabled(
	param: any[],
	option?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'> & {
		enabledKey?: string | string[];
	},
): boolean {
	if (param[0] && option?.enabledKey) {
		if (typeof option.enabledKey === 'string') {
			return (param[0] as any)[option.enabledKey] !== undefined;
		}
		if (Array.isArray(option.enabledKey)) {
			return option.enabledKey.every((key) => (param[0] as any)[key] !== undefined);
		}
	}
	return true;
}

/**
 * 공통적으로 상용하는 react-query 로직을 추상화 한 케어링의 Query 훅
 * 사용자 정의 키를 통해서, 쿼리 키를 관리할 수 있고, adapter 기능을 통해서 사용 한느 환경에 맞춰서 원하는 뷰로 변환이 가능하다
 * @param queryKey 사용자 정의 키
 * @param action IApi를 준수하는 API 인터페이스 메서드의 메서드 명
 * @param option enabledKey를 통해 react-query를 조건부 동작 시키기
 * @returns
 */
function useCRQuery<T extends keyof IApi>(
	queryKey: string,
	action: T,
	option?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'> & {
		enabledKey?: string | string[];
	},
) {
	function query(
		...param: MethodParametersAndReturnType<IApi, T>[0] extends object
			? [MethodParametersAndReturnType<IApi, T>[0]]
			: []
	): UseQueryResult<MethodParametersAndReturnType<IApi, T>[1], unknown>;
	function query<K extends (item: MethodParametersAndReturnType<IApi, T>[1]) => unknown>(
		...param: MethodParametersAndReturnType<IApi, T>[0] extends object
			? [MethodParametersAndReturnType<IApi, T>[0], K]
			: [K]
	): UseQueryResult<ReturnType<K>, unknown>;
	function query<K extends (item: MethodParametersAndReturnType<IApi, T>[1]) => unknown>(
		...param: MethodParametersAndReturnType<IApi, T>[0] extends object
			? [MethodParametersAndReturnType<IApi, T>[0]] | [MethodParametersAndReturnType<IApi, T>[0], K]
			: [] | [K]
	): UseQueryResult<MethodParametersAndReturnType<IApi, T>[1] | ReturnType<K>, unknown> {
		const api = useApi();
		const key = generateQueryKeys(queryKey, param);

		const queryResult = useQuery(
			key,
			async () => {
				const apiAction = api[action].bind(api) as (
					param: MethodParametersAndReturnType<IApi, T>[0],
				) => Promise<MethodParametersAndReturnType<IApi, T>[1]>;
				return apiAction(param?.[0]);
			},
			option
				? ({
						...option,
						enabled: getEnabled(param, option),
					} as Omit<UseQueryOptions, 'queryKey' | 'queryFn'>)
				: undefined,
		);

		const data = useMemo(() => {
			if (!param.length) return queryResult;
			if (param.length === 1 && typeof param[0] === 'function') {
				const adapter = param[0] as K;
				return { ...queryResult, data: adapter(queryResult.data) } as UseQueryResult<ReturnType<K>>;
			}

			const adapter = param[1] as K;

			return adapter
				? ({ ...queryResult, data: adapter(queryResult.data) } as UseQueryResult<ReturnType<K>>)
				: queryResult;
		}, [queryResult.data]);

		return data;
	}

	return query;
}

export default useCRQuery;
