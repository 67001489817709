import React from 'react';

import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';

type SavePdfProps = {
	pdfTitle: string;
	pdfRef: React.RefObject<HTMLDivElement>;
	isLandscape?: boolean;
};

const usePDF = () => {
	const savePdfFile = async ({ pdfTitle, pdfRef, isLandscape = false }: SavePdfProps) => {
		const element = pdfRef.current;
		if (!element) return;
		const orientation = isLandscape ? 'l' : 'p';

		const canvas = await html2canvas(element, {
			scrollX: -window.scrollX,
			scrollY: -window.scrollY,
			scale: 3,
			...(isLandscape ? { windowWidth: 3000 } : { windowHeight: 3000 }),
		});

		const imgWidth = isLandscape ? 297 : 210;
		const pageHeight = isLandscape ? 210 : 297;
		const imgHeight = (canvas.height * imgWidth) / canvas.width;

		const imgData = canvas.toDataURL('image/png');
		const pdf = new JsPDF(orientation, 'mm', 'a4');

		let heightLeft = imgHeight;
		let position = 0;

		pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
		heightLeft -= pageHeight;

		while (heightLeft > 0) {
			position = heightLeft - imgHeight;
			pdf.addPage();
			pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
			heightLeft -= pageHeight;
		}

		pdf.save(`${pdfTitle}.pdf`);
	};

	return { savePdfFile };
};

export default usePDF;
