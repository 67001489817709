import React from 'react';
import { Controller } from 'react-hook-form';

import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import useCenterInfo from 'lib/hook/view/centerInformation/useCenterInfo';
import { WorkLeaveLimitCd } from 'types/view/centerInfo';

import * as S from './styles';

function MyCenterWorkLeavePermitIpForm(): React.ReactElement {
	const { form, commonCodes } = useCenterInfo();

	return (
		<S.Container>
			<Controller
				control={form?.control}
				name='workLeaveLimitCd'
				render={({ field: { value, onChange }, formState: { errors } }) => (
					<CRInputLabel type='left' label='출퇴근 IP 제한' isRequired>
						<CRCheckBoxGroup
							direction='row'
							onChange={onChange}
							appearanceType='button'
							checkType='single'
							type='radio'
							gap={0.8}
							value={value}
							options={commonCodes?.workLeaveOptions}
						/>
					</CRInputLabel>
				)}
			/>
			{form.watch('workLeaveLimitCd')?.[0]?.value === WorkLeaveLimitCd.PARTIALLY_ALLOWED && (
				<Controller
					control={form?.control}
					name='workLeavePermitIps'
					render={({ field: { value, onChange }, formState: { errors } }) => (
						<CRInputLabel type='left' label='허가된 IP' isRequired>
							<CRInput.IPPermitter onChange={onChange} value={value} />
						</CRInputLabel>
					)}
				/>
			)}
		</S.Container>
	);
}

export default MyCenterWorkLeavePermitIpForm;
