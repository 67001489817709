import React, { useMemo } from 'react';

import CRButton from 'components/base/CRButton';
import { CheckOption } from 'components/base/Selections/type';
import { useCommonCodes, useWageDeductionEmployees } from 'lib/hook/react-query';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import { RecipientOwnExpenseFormViewType } from 'types/view/recipient';

import { OwnExpensePayMethodType } from '../../../../types/view/common';
import * as S from './styles';

interface Props {
	items?: RecipientOwnExpenseFormViewType;
	onChangeMode: () => void;
}

function RecipientOwnExpenseAutomaticView({ items, onChangeMode }: Props): React.ReactElement {
	const { currentRecipientStatus, currentRecipient } = useRecipientPage();
	const { data: commonCodes = [] } = useCommonCodes({
		comCdGroupNms: ['CMN041', 'CMN037', 'CMN162'],
	});
	const { data: wageDeductionEmployees } = useWageDeductionEmployees({
		centerId: currentRecipient?.centerId,
		recipientId: currentRecipient?.recipientId,
	});

	const payMethodOptions = useMemo(
		() =>
			(commonCodes ?? [])
				?.filter((code) => code.comCdGroupNm === 'CMN041')
				.map(
					(code) =>
						({
							label: code.korComCdAliasNm,
							value: code.comCdId,
						}) as CheckOption,
				),
		[commonCodes],
	);

	const bankOptions = useMemo(
		() =>
			(commonCodes ?? [])
				?.filter((code) => code.comCdGroupNm === 'CMN037')
				.map(
					(code) =>
						({
							label: code.korComCdAliasNm,
							value: code.comCdId,
						}) as CheckOption,
				),
		[commonCodes],
	);

	const receiptMethodOptions = useMemo(
		() =>
			(commonCodes ?? [])
				?.filter((code) => code.comCdGroupNm === 'CMN162')
				.map(
					(code) =>
						({
							label: code.korComCdAliasNm,
							value: code.comCdId,
						}) as CheckOption,
				),
		[commonCodes],
	);

	const isResign = currentRecipientStatus?.status === 'CMN058.30';

	if (items?.payMethod.value === OwnExpensePayMethodType.Self) {
		return (
			<S.Container>
				<S.Label>
					본인부담금 납부
					{!isResign && (
						<S.ButtonContainer>
							<CRButton.Default size='xSmall' type='outlined' palette='gray' onClick={onChangeMode}>
								편집
							</CRButton.Default>
						</S.ButtonContainer>
					)}
				</S.Label>
				<S.Table $isDisabled={isResign}>
					<S.TableRow>
						<S.TableLabelColumn style={{ width: '10.4rem' }}>납부 방식</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '24rem' }}>
							{payMethodOptions.find((option) => option.value === items?.payMethod.value)?.label ??
								'-'}
						</S.TableValueColumn>
						<S.TableLabelColumn style={{ width: '10.4rem' }}>현금영수증</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '24rem' }}>
							{items?.receipt !== undefined ? (items.receipt.value ? '대상자' : '비대상자') : '-'}
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn
							style={{ width: '10.4rem', height: '16.2rem' }}
							colSpan={2}
							rowSpan={3}
						/>
						<S.TableLabelColumn
							style={{ width: '10.4rem' }}
							colSpan={items?.receipt.value ? 1 : 2}
							rowSpan={items?.receipt.value ? 1 : 3}>
							{items?.receipt.value && '이름'}
						</S.TableLabelColumn>
						{items?.receipt.value && (
							<S.TableValueColumn style={{ width: '24rem' }}>
								{items?.name ?? '-'}
							</S.TableValueColumn>
						)}
					</S.TableRow>
					<S.TableRow>
						{items?.receipt.value && (
							<>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>발급 방식</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }}>
									{receiptMethodOptions.find(
										(option) => option.value === items?.receiptMethod?.value,
									)?.label ?? '-'}
								</S.TableValueColumn>
							</>
						)}
					</S.TableRow>
					<S.TableRow>
						{items?.receipt.value && (
							<>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>
									{items?.receiptMethod?.value === 'CMN162.10' ? '휴대폰 번호' : '사업자 번호'}
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }}>
									{items?.receiptNumber ?? '-'}
								</S.TableValueColumn>
							</>
						)}
					</S.TableRow>
				</S.Table>
			</S.Container>
		);
	}

	if (items?.payMethod.value === OwnExpensePayMethodType.WageDeduction) {
		return (
			<S.Container>
				<S.Label>
					본인부담금 납부
					{!isResign && (
						<S.ButtonContainer>
							<CRButton.Default size='xSmall' type='outlined' palette='gray' onClick={onChangeMode}>
								편집
							</CRButton.Default>
						</S.ButtonContainer>
					)}
				</S.Label>
				<S.Table $isDisabled={isResign}>
					<S.TableRow>
						<S.TableLabelColumn style={{ width: '10.4rem' }}>납부 방식</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '24rem' }}>
							{payMethodOptions.find((option) => option.value === items?.payMethod.value)?.label ??
								'-'}
						</S.TableValueColumn>
						<S.TableLabelColumn style={{ width: '10.4rem' }}>현금영수증</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '24rem' }}>
							{items?.receipt !== undefined ? (items.receipt.value ? '대상자' : '비대상자') : '-'}
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn style={{ width: '10.4rem' }}>공제 대상자</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '24rem' }}>
							{wageDeductionEmployees?.find(
								(option) => option.employeeId === items?.payDeductEmployeeId,
							)?.employeeNm ?? '-'}
						</S.TableValueColumn>
						<S.TableLabelColumn
							style={{ width: '10.4rem' }}
							colSpan={items?.receipt.value ? 1 : 2}
							rowSpan={items?.receipt.value ? 1 : 3}>
							{items?.receipt.value && '이름'}
						</S.TableLabelColumn>
						{items?.receipt.value && (
							<S.TableValueColumn style={{ width: '24rem' }}>
								{items?.name ?? '-'}
							</S.TableValueColumn>
						)}
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn style={{ width: '10.4rem' }} colSpan={2} rowSpan={2} />
						{items?.receipt.value && (
							<>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>발급 방식</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }}>
									{receiptMethodOptions.find(
										(option) => option.value === items?.receiptMethod?.value,
									)?.label ?? '-'}
								</S.TableValueColumn>
							</>
						)}
					</S.TableRow>
					<S.TableRow>
						{items?.receipt.value && (
							<>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>
									{items?.receiptMethod?.value === 'CMN162.10' ? '휴대폰 번호' : '사업자 번호'}
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }}>
									{items?.receiptNumber ?? '-'}
								</S.TableValueColumn>
							</>
						)}
					</S.TableRow>
				</S.Table>
			</S.Container>
		);
	}

	return (
		<S.Container>
			<S.Label>
				본인부담금 납부
				{!isResign && (
					<S.ButtonContainer>
						<CRButton.Default size='xSmall' type='outlined' palette='gray' onClick={onChangeMode}>
							편집
						</CRButton.Default>
					</S.ButtonContainer>
				)}
			</S.Label>
			<S.Table $isDisabled={isResign}>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '10.4rem' }}>납부 방식</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '24rem' }}>
						{payMethodOptions.find((option) => option.value === items?.payMethod.value)?.label ??
							'-'}
					</S.TableValueColumn>
					<S.TableLabelColumn style={{ width: '10.4rem' }}>현금영수증</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '24rem' }}>
						{items?.receipt !== undefined ? (items.receipt.value ? '대상자' : '비대상자') : '-'}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '10.4rem' }}>은행</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '24rem' }}>
						{bankOptions.find((option) => option.value === items?.bank)?.label ?? '-'}
					</S.TableValueColumn>
					<S.TableLabelColumn
						style={{ width: '10.4rem' }}
						colSpan={items?.receipt.value ? 1 : 2}
						rowSpan={items?.receipt.value ? 1 : 3}>
						{items?.receipt.value && '이름'}
					</S.TableLabelColumn>
					{items?.receipt.value && (
						<S.TableValueColumn style={{ width: '24rem' }}>{items?.name ?? '-'}</S.TableValueColumn>
					)}
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '10.4rem' }}>계좌번호</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '24rem' }}>
						{items?.account ?? '-'}
					</S.TableValueColumn>
					{items?.receipt.value && (
						<>
							<S.TableLabelColumn style={{ width: '10.4rem' }}>발급 방식</S.TableLabelColumn>
							<S.TableValueColumn style={{ width: '24rem' }}>
								{receiptMethodOptions.find((option) => option.value === items?.receiptMethod?.value)
									?.label ?? '-'}
							</S.TableValueColumn>
						</>
					)}
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '10.4rem' }}>계좌주</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '24rem' }}>
						{items?.accountOwner ?? '-'}
					</S.TableValueColumn>
					{items?.receipt.value && (
						<>
							<S.TableLabelColumn style={{ width: '10.4rem' }}>
								{items?.receiptMethod?.value === 'CMN162.10' ? '휴대폰 번호' : '사업자 번호'}
							</S.TableLabelColumn>
							<S.TableValueColumn style={{ width: '24rem' }}>
								{items?.receiptNumber ?? '-'}
							</S.TableValueColumn>
						</>
					)}
				</S.TableRow>
			</S.Table>
		</S.Container>
	);
}

export default RecipientOwnExpenseAutomaticView;
