import React, { useCallback, useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';

import CRButton from 'components/base/CRButton';
import CRStatus from 'components/base/CRStatus';
import ApprovalLineSelectorDialog from 'components/domain/dialog/ApprovalLineSelectorDialog';
import { Employee } from 'components/domain/dialog/ApprovalLineSelectorDialog/mock';
import { APPROVAL_STATUS_CONFIG } from 'components/domain/table/ApprovalProgressTable/constant';
import RdPopover from 'components/ui/radix/popover/RdPopover';
import useAuth from 'lib/hook/auth/useAuth';
import useDialog from 'lib/hook/util/useDialog';
import useFullScreen from 'lib/hook/util/useFullScreen';

import ApprovalLine from '../ApprovalLine';
import Referrer from '../Referrer';
import ApprovalContent from './ApprovalContent';
import OpinionPopup from './OpinionPopup';
import { documentMock } from './mock';
import * as S from './styles';

export default function DocumentDetailPage() {
	const { detailId } = useParams<{ detailId: string }>();
	const { showDialog } = useDialog();

	const matchedItem = documentMock.find((e) => e.id === detailId);
	const { userInfo } = useAuth();

	const [approvalLine, setApprovalLine] = useState<Employee[]>([]);
	const [referrer, setReferrer] = useState<Employee[]>([]);

	const handleApplyApproval = (items: Employee[]) => setApprovalLine(items);

	const handleApplyReferrer = (items: Employee[]) => setReferrer(items);
	const handleDeleteReferrer = (ids: string[]) => {
		const newReferrer = referrer?.filter((item) => !ids.includes(item.id));
		setReferrer(newReferrer);
	};
	const handleDeleteApprovalItem = (id: string) => {
		const newApprovalLine = approvalLine?.filter((item) => id !== item.id);
		setApprovalLine(newApprovalLine);
	};

	const handleToggleApprovalDialog = () => {
		showDialog(() => (
			<ApprovalLineSelectorDialog
				title='결재선 설정'
				items={approvalLine}
				onApply={handleApplyApproval}
			/>
		));
	};

	const handleToggleReferrerDialog = () => {
		showDialog(() => (
			<ApprovalLineSelectorDialog
				title='참조 설정'
				items={referrer}
				onApply={handleApplyReferrer}
			/>
		));
	};

	const renderRightTopButton = useCallback(() => {
		if (!matchedItem || matchedItem.status === '승인완료') return null;
		const isApprovalLineUser = matchedItem.approvalLine.find((item) => item.id === userInfo?.id);
		if (isApprovalLineUser) {
			return (
				<S.ButtonContainer>
					<RdPopover
						sideOffset={-120}
						title='의견작성'
						showDivider={false}
						content={<OpinionPopup documentId={matchedItem.id} taskName={matchedItem.title} />}
						borderShape='large'
						openCallback={() => {}}
						closeCallback={() => {}}>
						<CRButton.Default type='outlined' palette='gray'>
							반려
						</CRButton.Default>
					</RdPopover>
					<RdPopover
						sideOffset={-60}
						title='의견작성'
						showDivider={false}
						content={<OpinionPopup documentId={matchedItem.id} taskName={matchedItem.title} />}
						borderShape='large'
						openCallback={() => {}}
						closeCallback={() => {}}>
						<CRButton.Default>승인</CRButton.Default>
					</RdPopover>
				</S.ButtonContainer>
			);
		}

		if (matchedItem.status === '반려') {
			return (
				<S.ButtonContainer>
					<CRButton.Default buttonType='submit' form='approval-form'>
						재상신하기
					</CRButton.Default>
				</S.ButtonContainer>
			);
		}
		return null;
	}, [matchedItem]);

	const handleApprovalDragEnd = (result: DropResult) => {
		if (!result.destination) return;
		const items = [...approvalLine];
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		setApprovalLine(items);
	};

	const renderApprovalStatus = () => {
		if (!matchedItem?.title || typeof matchedItem.status === 'undefined') return null;
		return (
			<S.StatusContainer>
				<h3>{matchedItem?.title}</h3>
				<CRStatus options={APPROVAL_STATUS_CONFIG}>{matchedItem.status}</CRStatus>
			</S.StatusContainer>
		);
	};

	useFullScreen();

	useEffect(() => {
		if (matchedItem) {
			setApprovalLine(matchedItem.approvalLine);
			setReferrer(matchedItem.referrer);
		}
	}, [matchedItem]);

	return (
		<S.Container>
			<S.Header>
				{renderApprovalStatus()}
				{renderRightTopButton()}
			</S.Header>
			<S.Content>
				<S.LeftSection>
					<ApprovalLine
						readOnly
						onDragEnd={handleApprovalDragEnd}
						items={approvalLine}
						onToggleDialog={handleToggleApprovalDialog}
						onDelete={handleDeleteApprovalItem}
					/>
					<S.Divider />
					<Referrer
						readOnly
						items={referrer}
						onToggleDialog={handleToggleReferrerDialog}
						onDelete={handleDeleteReferrer}
					/>
				</S.LeftSection>
				<S.RightSection>
					<ApprovalContent item={matchedItem} />
				</S.RightSection>
			</S.Content>
		</S.Container>
	);
}
