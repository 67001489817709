import { endpoint } from 'lib/service/Api/endpoint';

import useCRMutation from '../base/useCRMutation';
import useCRQuery from '../base/useCRQuery';

export const useTemplateList = useCRQuery(endpoint.getSendTemplateList.key, 'getSendTemplateList');
export const useSendTargetList = useCRQuery(endpoint.getSendTargetList.key, 'getSendTargetList', {
	enabledKey: ['templateId'],
});
export const useSendHistory = useCRQuery(endpoint.getSendHistory.key, 'getSendHistory');
export const useSendHistoryDetail = useCRQuery(
	endpoint.getSendHistoryDetail.key,
	'getSendHistoryDetail',
);
export const useSendReservationHistory = useCRQuery(
	endpoint.getReservationSendHistory.key,
	'getReservationSendHistory',
);
export const useReservationSendDetail = useCRQuery(
	endpoint.getReservationSendHistoryDetail.key,
	'getReservationSendDetail',
);

export const useESignHistoryFilter = useCRQuery(
	endpoint.getESignHistoryFilter.key,
	'getESignHistoryFilter',
);

export const useESignHistory = useCRQuery(endpoint.getESignHistory.key, 'getESignHistory');
export const useESignHistoryDetail = useCRQuery(
	endpoint.getESignHistoryDetail.key,
	'getESignHistoryDetail',
);

export const useMessageTabSendHistory = useCRQuery(
	endpoint.messageTabSendHistory.key,
	'getMessageTabSendHistory',
);

export const useMessageTabReservationHistory = useCRQuery(
	endpoint.messageTabReservationHistory.key,
	'getMessageTabReservationHistory',
);

export const useMessageTabESignHistory = useCRQuery(
	endpoint.messageTabESignHistory.key,
	'getMessageTabESignHistory',
);

export const useRecipientSendHistoryDetail = useCRQuery(
	endpoint.getRecipientSendHistoryDetail.key,
	'getRecipientSendHistoryDetail',
	{
		enabledKey: ['recipientId', 'sendDetailId'],
	},
);

export const useEmployeeSendHistoryDetail = useCRQuery(
	endpoint.getEmployeeSendHistoryDetail.key,
	'getEmployeeSendHistoryDetail',
	{
		enabledKey: ['employeeId', 'sendDetailId'],
	},
);

// mutation

// 메시지 발송
export const useSendMessage = useCRMutation(endpoint.sendMessage.key, 'sendMessage');
// 전자문서 발송 메시지 상세 기간 변경
export const useUpdateSignDueDate = useCRMutation(
	endpoint.updateSignDueDate.key,
	'updateSignDueDate',
);
// 전자문서 취소
export const useCancelEDoc = useCRMutation(endpoint.cancelEDoc.key, 'cancelEDoc');

// 메시지 실패건 재발송
export const useResendFailMessage = useCRMutation(
	endpoint.resendFailMessage.key,
	'resendFailMessage',
);
// 메시지 개별 재발송
export const useResendMessage = useCRMutation(endpoint.resendMessage.key, 'resendMessage');
export const useCancelReservationMessage = useCRMutation(
	endpoint.cancelReservationMessage.key,
	'cancelReservationMessage',
);
export const useUpdateReservationMessage = useCRMutation(
	endpoint.updateReservationMessage.key,
	'updateReservationMessage',
);
