import React from 'react';

import * as S from './styles';

interface Props {
	BannerComponent?: React.ReactNode;
	LeftContentComponent?: React.ReactNode;
	RightContentComponent?: React.ReactNode;
	containerCustomStyle?: React.CSSProperties;
	contentContainerCustomStyle?: React.CSSProperties;
	LeftContentCustomStyle?: React.CSSProperties;
	RightContentCustomStyle?: React.CSSProperties;
}

function InformationLayout({
	BannerComponent,
	LeftContentComponent = <div />,
	RightContentComponent = <div />,
	containerCustomStyle,
	contentContainerCustomStyle,
	LeftContentCustomStyle,
	RightContentCustomStyle,
}: Props): React.ReactElement {
	return (
		<S.Container style={containerCustomStyle}>
			{!!BannerComponent && <S.BannerContainer>{BannerComponent}</S.BannerContainer>}
			<S.ContentContainer style={contentContainerCustomStyle}>
				<S.ContentLeft style={LeftContentCustomStyle}>{LeftContentComponent}</S.ContentLeft>
				<S.ContentRight style={RightContentCustomStyle}>{RightContentComponent}</S.ContentRight>
			</S.ContentContainer>
		</S.Container>
	);
}

export default InformationLayout;
