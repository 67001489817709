import React, { useState } from 'react';

import Assets from 'assets';

import * as S from './styles';

interface DetailItem {
	id?: string;
}

interface Item<K> {
	details: K[];
}

type DetailKeys<T extends { details: unknown[] }> =
	T['details'] extends Array<infer U> ? keyof U : never;

interface Props<K extends DetailItem, T extends Item<K>, U extends keyof T, H extends keyof K> {
	item: T;
	renderKeys?: (U | '')[];
	customRender?: {
		[key in U]?: (value: T[U]) => React.ReactElement;
	};
	detailRenderKeys?: (DetailKeys<T> | '')[];
	detailCustomRender?: {
		[key in DetailKeys<T>]?: (value: K[H]) => React.ReactElement;
	};
	onClickDetail?: (detail: K) => void;
	onClick?: (item: T) => void;
	borderBottom?: string;
}

function CRInformationCollapsibleRow<
	K extends DetailItem,
	T extends Item<K>,
	U extends keyof T,
	H extends keyof K,
>({
	item,
	renderKeys = [],
	detailRenderKeys = [],
	customRender = {},
	detailCustomRender = {},
	onClickDetail,
	onClick,
	borderBottom,
}: Props<K, T, U, H>): React.ReactElement {
	const [isCollapsed, setIsCollapsed] = useState(false);

	const handleToggleCollapse = (): void => {
		setIsCollapsed((prev) => !prev);
	};

	return (
		<>
			<S.TableBodyRow onClick={() => onClick?.(item)}>
				{renderKeys.map((renderKey: U | '', index) => (
					<S.TableBodyColumnWithPadding
						key={renderKey.toString()}
						style={isCollapsed ? undefined : { borderBottom }}>
						<S.Content>
							{Boolean(index === 0 && !!item.details.length) && (
								<S.TableBodyIconContainer>
									<S.TableBodyToggleIcon
										src={isCollapsed ? Assets.icon.arrowDropTop : Assets.icon.arrowDropBottom}
										alt={isCollapsed ? 'arrowDropTop' : 'arrowDropBottom'}
										onClick={handleToggleCollapse}
									/>
								</S.TableBodyIconContainer>
							)}
							{
								(customRender[renderKey as U]
									? customRender[renderKey]?.(item[renderKey as U])
									: item[renderKey as U]) as React.ReactNode
							}
						</S.Content>
					</S.TableBodyColumnWithPadding>
				))}
			</S.TableBodyRow>
			{isCollapsed &&
				item.details &&
				item.details.map((detail: K, detailIndex) => (
					<S.TableBodySubRow key={detail?.id} onClick={() => onClickDetail?.(detail)}>
						{detailRenderKeys.map((renderKey: H | '', index) => (
							<S.TableBodyColumnWithPadding
								key={renderKey.toString()}
								style={detailIndex === item.details.length - 1 ? { borderBottom } : undefined}>
								<S.Content>
									{Boolean(index === 0) && (
										<S.TableBodyIconContainer>
											<S.TableBodySubLine />
										</S.TableBodyIconContainer>
									)}
									{detailCustomRender[renderKey as DetailKeys<T>]
										? detailCustomRender[renderKey]?.(detail[renderKey as H])
										: detail[renderKey as H]}
								</S.Content>
							</S.TableBodyColumnWithPadding>
						))}
					</S.TableBodySubRow>
				))}
		</>
	);
}

export default React.memo(CRInformationCollapsibleRow) as typeof CRInformationCollapsibleRow;
