import React from 'react';

import * as Accordion from '@radix-ui/react-accordion';

import ChangeEmployeeLongTermCareRecognitionForm from 'components/domain/form/ChangeEmployeeLongTermCareRecognitionForm';
import ChangeEmployeeRecipientManagerForm from 'components/domain/form/ChangeEmployeeRecipientManagerForm';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';

function ChangeEmployeeRecipientBasicPage(): React.ReactElement {
	const forms = [
		{
			id: '#1',
			label: '담당자',
			component: <ChangeEmployeeRecipientManagerForm />,
		},
		{
			id: '#2',
			label: '장기요양인정 정보',
			component: <ChangeEmployeeLongTermCareRecognitionForm />,
		},
	];

	return (
		<Accordion.Root type='multiple' defaultValue={forms.map((form) => form.id)}>
			{forms.map((form) => (
				<Accordion.Item value={form.id} asChild>
					<TaskAccordion.Item>
						<Accordion.Header asChild>
							<Accordion.Trigger asChild>
								<TaskAccordion.Trigger href={form.id}>{form.label}</TaskAccordion.Trigger>
							</Accordion.Trigger>
						</Accordion.Header>
						<Accordion.Content asChild>
							<TaskAccordion.Content>{form.component}</TaskAccordion.Content>
						</Accordion.Content>
					</TaskAccordion.Item>
				</Accordion.Item>
			))}
		</Accordion.Root>
	);
}

export default React.memo(ChangeEmployeeRecipientBasicPage);
