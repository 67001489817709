import dayjs, { Dayjs } from 'dayjs';
import * as yup from 'yup';

import { CheckOption } from 'components/base/Selections/type';
import { regex } from 'lib';

export interface ActualWorkFormType {
	workStartTime: string;
	workStartRemark: string;
	workStartPlace: CheckOption;
	workEndTime: string;
	workEndRemark: string;
	workEndPlace: CheckOption;
	defaultWorkTimeHour: number;
	defaultWorkTimeMinute: number;
	restPeriodTimeCnt: number;
	approvedOverWorkTimeHour: number;
	approvedOverWorkTimeMinute: number;
}

const calculateTotalTime = ({
	defaultWorkTimeHour = 0,
	defaultWorkTimeMinute = 0,
	approvedOverWorkTimeHour = 0,
	approvedOverWorkTimeMinute = 0,
}: Partial<ActualWorkFormType>): number =>
	defaultWorkTimeHour * 60 +
	defaultWorkTimeMinute +
	approvedOverWorkTimeHour * 60 +
	approvedOverWorkTimeMinute;

const validateHasWorkTime = (parent: Partial<ActualWorkFormType>): boolean => {
	const totalTime = calculateTotalTime(parent);
	return totalTime > 0;
};

const validateUnderTwelveHours = (parent: Partial<ActualWorkFormType>): boolean => {
	const totalTime = calculateTotalTime(parent);
	return totalTime < 721;
};

const validateRestPeriodTime = (parent: Partial<ActualWorkFormType>): boolean => {
	const totalWorkTime = calculateTotalTime(parent);
	const calculatedRestPeriodTime = Math.floor(totalWorkTime / 240) * 30;

	const { restPeriodTimeCnt = 0 } = parent;
	return restPeriodTimeCnt < calculatedRestPeriodTime;
};

const handleActualWorkTime = (workStartTime: string, workEndTime: string) => {
	const startTime = dayjs(`${dayjs().format('YYYY-MM-DD')} ${workStartTime}`);
	const endTime = dayjs(`${dayjs().format('YYYY-MM-DD')} ${workEndTime}`);
	const currentWorkEndTime = endTime.isBefore(startTime) ? endTime.add(1, 'day') : endTime;

	return {
		startTime,
		endTime: currentWorkEndTime,
	};
};

const roundUpToNearestTen = (time: Dayjs) => {
	const roundedMinutes = Math.ceil(time.minute() / 10) * 10;

	if (roundedMinutes === 60) {
		return time.add(1, 'hour').startOf('hour');
	}
	return time.minute(roundedMinutes);
};

const validateWorkEndTimeOverApprovedEndTime = (parent: Partial<ActualWorkFormType>): boolean => {
	const { workStartTime = '', workEndTime = '' } = parent;

	const totalWorkTime = calculateTotalTime(parent) + (parent?.restPeriodTimeCnt || 0);
	const hasWorkLog = workStartTime !== '' && workEndTime !== '';
	if (!hasWorkLog) return true;

	const { startTime, endTime } = handleActualWorkTime(workStartTime, workEndTime);
	const approvedEndTime = roundUpToNearestTen(startTime).add(totalWorkTime, 'minute');

	return !endTime.isBefore(approvedEndTime, 'minute');
};
const validateWorkEndTimeOverNextDay = (parent: Partial<ActualWorkFormType>): boolean => {
	const { workStartTime = '', workEndTime = '' } = parent;

	const hasWorkLog = workStartTime !== '' && workEndTime !== '';
	if (!hasWorkLog) return true;

	const { startTime, endTime } = handleActualWorkTime(workStartTime, workEndTime);
	const limitedWorkEndTime = dayjs(`${dayjs().add(1, 'day').format('YYYY-MM-DD')} 06:01`);

	return endTime.isBefore(limitedWorkEndTime, 'minute');
};

export const EmployeeDailyWorkLogSettingYupResolver = yup.object<ActualWorkFormType>({
	workStartTime: yup
		.string()
		.required('출근 시간은 필수 항목입니다.')
		.matches(regex.time, '올바른 시간을 입력해 주십시오.'),
	workStartRemark: yup
		.string()
		.max(25, '출근 기록 비고는 최대 25자까지 입력 가능합니다.')
		.when(['workStartPlace'], ([workStartPlace], schema) =>
			workStartPlace?.value === 'CMN200.90'
				? schema.required('출퇴근 장소가 기타일 경우 비고는 필수 항목입니다.')
				: schema,
		),
	workStartPlace: yup.mixed().required('출근 장소는 필수 항목입니다.'),
	workEndTime: yup
		.string()
		.matches(regex.time, '올바른 시간을 입력해 주십시오.')
		.required('퇴근 시간은 필수 항목입니다.')
		.test(
			'validateWorkEndTimeOverNextDay',
			'출퇴근 기록은 금일 06시 01분부터 익일 06시까지 설정할 수 있습니다.',
			(value, { parent }) => validateWorkEndTimeOverNextDay(parent),
		),
	workEndRemark: yup
		.string()
		.max(25, '퇴근 기록 비고는 최대 25자까지 입력 가능합니다.')
		.when(['workEndPlace'], ([workEndPlace], schema) =>
			workEndPlace?.value === 'CMN200.90'
				? schema.required('출퇴근 장소가 기타일 경우 비고는 필수 항목입니다.')
				: schema,
		),
	workEndPlace: yup.mixed().required('퇴근 장소는 필수 항목입니다.'),
	defaultWorkTimeHour: yup
		.number()
		.required()
		.default(0)
		.min(0)
		.transform((value, originalValue) => (originalValue === '' ? 0 : value))
		.test('valiedHasWorkTime', '근무시간을 입력해주세요', (value, { parent }) =>
			validateHasWorkTime(parent),
		)
		.test(
			'valiedOverTwelveHourWorkTime',
			'근무시간은 12시간을 넘을 수 없습니다.',
			(value, { parent }) => validateUnderTwelveHours(parent),
		),
	defaultWorkTimeMinute: yup
		.number()
		.required()
		.default(0)
		.min(0)
		.transform((value, originalValue) => (originalValue === '' ? 0 : value))
		.test('valiedHasWorkTime', '근무시간을 입력해주세요', (value, { parent }) =>
			validateHasWorkTime(parent),
		)
		.test(
			'valiedOverTwelveHourWorkTime',
			'근무시간은 12시간을 넘을 수 없습니다.',
			(value, { parent }) => validateUnderTwelveHours(parent),
		),
	restPeriodTimeCnt: yup.number().required('휴게 시간은 필수 항목입니다.'),
	approvedOverWorkTimeHour: yup
		.number()
		.required()
		.default(0)
		.min(0)
		.transform((value, originalValue) => (originalValue === '' ? 0 : value))
		.test('valiedHasWorkTime', '근무시간을 입력해주세요', (value, { parent }) =>
			validateHasWorkTime(parent),
		)
		.test(
			'valiedOverTwelveHourWorkTime',
			'근무시간은 12시간을 넘을 수 없습니다.',
			(value, { parent }) => validateUnderTwelveHours(parent),
		),
	approvedOverWorkTimeMinute: yup
		.number()
		.required()
		.default(0)
		.min(0)
		.transform((value, originalValue) => (originalValue === '' ? 0 : value))
		.test('valiedHasWorkTime', '근무시간을 입력해주세요', (value, { parent }) =>
			validateHasWorkTime(parent),
		)
		.test(
			'valiedOverTwelveHourWorkTime',
			'근무시간은 12시간을 넘을 수 없습니다.',
			(value, { parent }) => validateUnderTwelveHours(parent),
		),
});
