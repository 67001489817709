import React from 'react';

import dayjs, { Dayjs } from 'dayjs';

import CRDialog from 'components/base/CRDialog';
import CRInputLabel from 'components/base/CRInputLabel';
import InformationTable from 'components/ui/InformationTable';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import { displayBirthDay } from 'lib';
import { useRecipientHistoryOfHolding } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { FtimeRecipientInfoDTO } from 'types/dto/schedule';

import * as S from './styles';

interface Props {
	recipientInfo?: FtimeRecipientInfoDTO;
	date: Dayjs;
}

function RecipientHoldingStateDetailDialog({ recipientInfo, date }: Props): React.ReactElement {
	const { hideDialog } = useDialog();

	const { data: holdingHistoryData } = useRecipientHistoryOfHolding({
		recipientId: recipientInfo?.recipientId as number,
	});

	const holidingHistoryList: InformationTableItemType[][] = (holdingHistoryData ?? [])
		.map((item) => {
			const startDt = dayjs(item.recipientPendingStartDate);
			const endDt = dayjs(item.recipientPendingEndDate);

			if (
				startDt.isAfter(dayjs(date)) ||
				startDt.isSame(dayjs(date), 'month') ||
				endDt.isBefore(dayjs(date)) ||
				endDt.isSame(dayjs(date), 'month')
			)
				return [
					{
						value: `${startDt.format('YYYY.MM.DD')}~${endDt.format('YYYY.MM.DD')}`,
						type: 'value',
					},
					{
						value: item.recipientPendingDesc,
						type: 'value',
					},
				] as InformationTableItemType[];
			return [] as InformationTableItemType[];
		})
		.filter((item) => item && item.length > 0);

	return (
		<CRDialog
			type='S'
			title='보류 상세'
			onClickClose={hideDialog}
			body={
				<S.Container>
					<CRInputLabel label='기본 정보'>
						<InformationTable
							items={[
								[
									{
										label: '수급자명',
										value: recipientInfo?.korMemberNm ?? '-',
										labelStyle: { width: '10.8rem' },
										valueStyle: { width: '10.8rem' },
									},
									{
										label: '상태',
										value: recipientInfo?.recipientStateNm ?? '-',
										labelStyle: { width: '10.8rem' },
										valueStyle: { width: '10.8rem' },
									},
								],
								[
									{
										label: '생년월일',
										value: displayBirthDay(recipientInfo?.birthDt),
									},
									{
										label: '사회복지사',
										value: recipientInfo?.managerNm ?? '-',
									},
								],
							]}
						/>
					</CRInputLabel>
					{holdingHistoryData && (
						<CRInputLabel label='보류 이력'>
							<InformationTable
								items={[
									[
										{
											label: '기간',
											type: 'label',
											labelStyle: { width: '21.6rem' },
										},
										{
											label: '사유',
											type: 'label',
											labelStyle: { width: '21.6rem' },
										},
									],
									...(holidingHistoryList && holidingHistoryList),
								]}
							/>
						</CRInputLabel>
					)}
				</S.Container>
			}
		/>
	);
}

export default React.memo(RecipientHoldingStateDetailDialog);
