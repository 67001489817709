import React from 'react';

import CRButton from 'components/base/CRButton';
import AnnualTOAdjustmentDialog from 'components/domain/dialog/AnnualTOAdjustmentDialog';
import FullTimeEmployeeAnnualTODetailDialog from 'components/domain/dialog/FullTimeEmployeeAnnualTODetailDialog';
import useFullTimeEmployeePage from 'lib/hook/full-time-employee/useFullTimeEmployeePage';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import { useHasPermission } from 'lib/hook/util/useHasPermission';

import * as S from './styles';

interface Props {
	disabledButton?: boolean;
}

export default function FullTimeEmployeeTOView({ disabledButton }: Props): React.ReactElement {
	const { showDialog } = useGlobalLayout();
	const { employeeDetailData, currentEmployee } = useFullTimeEmployeePage();
	const hasPermission = useHasPermission('센터장');
	const isResign = employeeDetailData?.workStateCd === 'CMN068.30';

	const handleClickAnnualTOAdjustment = () => {
		if (!currentEmployee) return;
		if (!employeeDetailData) return;
		showDialog(({ hideDialog }) => (
			<AnnualTOAdjustmentDialog fullTimeEmployee={currentEmployee} hideDialog={hideDialog} />
		));
	};

	const handleClickAnnualTODetail = () => {
		if (!currentEmployee) return;
		showDialog(({ hideDialog }) => (
			<FullTimeEmployeeAnnualTODetailDialog
				fullTimeEmployee={currentEmployee}
				hideDialog={hideDialog}
			/>
		));
	};

	return (
		<S.Container>
			<S.Label>
				연차 정보
				{!disabledButton && (
					<S.ButtonContainer>
						{hasPermission && !isResign && (
							<CRButton.Default
								size='xSmall'
								type='outlined'
								palette='gray'
								onClick={handleClickAnnualTOAdjustment}>
								조정
							</CRButton.Default>
						)}
						<CRButton.Default
							size='xSmall'
							type='outlined'
							palette='gray'
							onClick={handleClickAnnualTODetail}>
							연차 이력
						</CRButton.Default>
					</S.ButtonContainer>
				)}
			</S.Label>
			<S.TableContainer>
				<S.Table $isDisabled={isResign}>
					<S.TableRow>
						<S.TableLabelColumn>근무년수</S.TableLabelColumn>
						<S.TableValueColumn colSpan={7}>
							{employeeDetailData?.workYearCntNm ?? '-'}
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>발생일수</S.TableLabelColumn>
						<S.TableValueColumn>
							{employeeDetailData?.annualGenerateDayCntNm ?? '-'}
						</S.TableValueColumn>
						<S.TableLabelColumn>차감일수</S.TableLabelColumn>
						<S.TableValueColumn>
							{employeeDetailData?.annualDeductDayCntNm ?? '-'}
						</S.TableValueColumn>
						<S.TableLabelColumn>사용일수</S.TableLabelColumn>
						<S.TableValueColumn>{employeeDetailData?.annualUseDayCntNm ?? '-'}</S.TableValueColumn>
						<S.TableLabelColumn>잔여일수</S.TableLabelColumn>
						<S.TableValueColumn>
							{employeeDetailData?.annualRemainDayCntNm ?? '-'}
						</S.TableValueColumn>
					</S.TableRow>
				</S.Table>
			</S.TableContainer>
		</S.Container>
	);
}
