import React from 'react';

import * as Accordion from '@radix-ui/react-accordion';

import RenewalCashReceiptForm from 'components/domain/form/RenewalCashReceiptForm';
import RenewalLongTermCareRecognitionForm from 'components/domain/form/RenewalLongTermCareRecognitionForm';
import RenewalOtherServiceUsageForm from 'components/domain/form/RenewalOtherServiceUsageForm';
import RenewalParentalControlsForm from 'components/domain/form/RenewalParentalControlsForm';
import RenewalRecipientBasicForm from 'components/domain/form/RenewalRecipientBasicForm';
import RenewalRecipientManagerForm from 'components/domain/form/RenewalRecipientManagerForm';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';

function RenewalRecipientBasicPage(): React.ReactElement {
	const forms = [
		{
			id: '#0',
			label: '담당자',
			component: <RenewalRecipientManagerForm />,
		},
		{
			id: '#1',
			label: '기본 정보',
			component: <RenewalRecipientBasicForm />,
		},
		{
			id: '#2',
			label: '장기요양인정 정보',
			component: <RenewalLongTermCareRecognitionForm />,
		},
		{
			id: '#3',
			label: '보호자 정보',
			component: <RenewalParentalControlsForm />,
		},
		{
			id: '#4',
			label: '타급여 이용 정보',
			component: <RenewalOtherServiceUsageForm />,
		},
		{
			id: '#5',
			label: '현금영수증 정보',
			component: <RenewalCashReceiptForm />,
		},
	];

	return (
		<Accordion.Root type='multiple' defaultValue={forms.map((form) => form.id)}>
			{forms.map((form) => (
				<Accordion.Item value={form.id} asChild>
					<TaskAccordion.Item>
						<Accordion.Header asChild>
							<Accordion.Trigger asChild>
								<TaskAccordion.Trigger href={form.id}>{form.label}</TaskAccordion.Trigger>
							</Accordion.Trigger>
						</Accordion.Header>
						<Accordion.Content asChild>
							<TaskAccordion.Content>{form.component}</TaskAccordion.Content>
						</Accordion.Content>
					</TaskAccordion.Item>
				</Accordion.Item>
			))}
		</Accordion.Root>
	);
}

export default RenewalRecipientBasicPage;
