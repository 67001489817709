import React from 'react';

import { CheckOption } from 'components/base/Selections/type';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import { EditableEmployeeFormTypes } from 'lib/provider/employee';
import { EmployeeDetailInfoDTO } from 'types/api/employee';

import EmployeePayInfoEdit from './EmployeePayInfoEdit';
import EmployeePayInfoView from './EmployeePayInfoView';
import * as S from './styles';

interface Props {
	item?: EmployeeDetailInfoDTO | null;
	options: {
		payType: CheckOption[];
		bank: CheckOption[];
	};
}

export default function EmployeePayInfoForm({ item, options }: Props): React.ReactElement {
	const { isFormEditable, startChangeEditSection, cancelChangeEditSection, isEditSectionChanging } =
		useEmployeePage();

	const onStartChangeSection = () => {
		startChangeEditSection(EditableEmployeeFormTypes.임금);
	};

	const onCancelChangeSection = () => {
		cancelChangeEditSection();
	};

	return (
		<S.Container>
			{isFormEditable(EditableEmployeeFormTypes.임금) ? (
				<EmployeePayInfoEdit
					item={item}
					options={options}
					onStartChangeSection={onStartChangeSection}
					onCancelChangeSection={onCancelChangeSection}
					isEditSectionChanging={isEditSectionChanging}
				/>
			) : (
				<EmployeePayInfoView item={item} options={options} onChangeMode={onStartChangeSection} />
			)}
		</S.Container>
	);
}
