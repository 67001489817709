import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	background: ${(props) => props.theme.colors.gray100};
	overflow: overlay;
	padding: 0 0.8rem 0.8rem 0;
`;

export const TableHeaderLabelContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.4rem;
`;
