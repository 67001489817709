import React, { useEffect, useState } from 'react';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { CheckOption } from 'components/base/Selections/type';
import {
	useFullTimeEmployeeDementiaEducations,
	useSaveFullTimeEmployeeDementiaEducation,
} from 'lib/hook/react-query';
import { FullTimeEmployeeDTO } from 'types/dto/full-time-employee';

import { endpoint } from '../../../../lib/service/Api/endpoint';
import * as S from './styles';

interface Props {
	fullTimeEmployee: FullTimeEmployeeDTO;
	hideDialog?: () => void;
}

export default function DementiaEducationConfirmationDialog({
	fullTimeEmployee,
	hideDialog,
}: Props): React.ReactElement {
	const [currentValue, setCurrentValue] = useState<CheckOption[]>([]);
	const { data: fullTimeEmployeeDementiaEducations } = useFullTimeEmployeeDementiaEducations({
		centerId: fullTimeEmployee.centerId,
		employeeId: fullTimeEmployee.employeeId,
		memberId: fullTimeEmployee.memberId,
	});
	const saveFullTimeEmployeeDementiaEducation = useSaveFullTimeEmployeeDementiaEducation(
		async (queryClient) => {
			await queryClient.invalidateQueries([
				endpoint.getFullTimeEmployeeDetailInfo.key,
				{
					centerId: fullTimeEmployee.centerId,
					employeeId: fullTimeEmployee.employeeId,
					memberId: fullTimeEmployee.memberId,
				},
			]);
			await queryClient.invalidateQueries([
				endpoint.getFullTimeEmployeeDementiaEducations.key,
				{
					centerId: fullTimeEmployee.centerId,
					employeeId: fullTimeEmployee.employeeId,
					memberId: fullTimeEmployee.memberId,
				},
			]);
			hideDialog?.();
		},
	);

	const options = (fullTimeEmployeeDementiaEducations ?? []).map((item) => ({
		group: item.curriculumDivNm,
		label: item.dementiaCurriculumNm,
		value: item.dementiaCurriculumCd,
		data: item,
	}));

	const handleClickSave = async () => {
		await saveFullTimeEmployeeDementiaEducation.mutateAsync(
			options.map((item) => ({
				...item.data,
				completeYn: !!currentValue.find((target) => target.value === item.value),
			})),
		);
	};

	useEffect(() => {
		if (fullTimeEmployeeDementiaEducations) {
			setCurrentValue(
				fullTimeEmployeeDementiaEducations
					.filter((item) => item.completeYn)
					.map((item) => ({
						group: item.curriculumDivNm,
						label: item.dementiaCurriculumNm,
						value: item.dementiaCurriculumCd,
						data: item,
					})),
			);
		}
	}, [fullTimeEmployeeDementiaEducations]);

	return (
		<CRDialog
			type='S'
			onClickClose={hideDialog}
			title='치매 교육 이수 확인'
			body={
				<S.Container>
					<CRInputLabel label='교육명' isRequired>
						<CRInput.MultiSelector
							fullSize
							value={currentValue}
							options={options}
							placeholder='교육 선택'
							onChange={setCurrentValue}
						/>
					</CRInputLabel>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default onClick={handleClickSave}>이수 완료</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
