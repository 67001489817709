import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import Colors from 'common/colors';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { Toast } from 'components/base/CRToast';
import InformationSheet from 'components/ui/InformationSheet';
import { FullTimeEmployeeTOAdjustment, displayTOTime } from 'lib';
import {
	useGetFullTimeEmployeeAnnualTOGenerateAdjust,
	useSaveFullTimeEmployeeAnnualTOGenerateAdjust,
} from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import {
	FullTimeEmployeeAnnualTOGenerateAdjustDTO,
	FullTimeEmployeeDTO,
} from 'types/dto/full-time-employee';

import { AnnualTOAdjustmentFormRef } from '.';

interface Props {
	fullTimeEmployee: FullTimeEmployeeDTO;
	hideDialog: () => void;
	onSubmit?: (formData: FullTimeEmployeeAnnualTOGenerateAdjustDTO) => void;
	editItem?: FullTimeEmployeeAnnualTOGenerateAdjustDTO;
	setIsValid: (isValid: boolean) => void;
}

function AnnualTOGenerateAdjustForm(
	{ fullTimeEmployee, hideDialog, onSubmit, editItem, setIsValid }: Props,
	ref,
): React.ReactElement {
	const [data, setData] = useState<FullTimeEmployeeAnnualTOGenerateAdjustDTO | undefined>(
		undefined,
	);
	const {
		control,
		watch,
		formState: { isValid },
	} = useForm<{
		adjustmentCnt: number;
		reason: string;
	}>({
		defaultValues: {
			adjustmentCnt: editItem?.adjustAnnualDayCnt ?? 0,
			reason: editItem?.annualAdjustDesc ?? '',
		},
		resolver: yupResolver(FullTimeEmployeeTOAdjustment),
	});
	const getFullTimeEmployeeAnnualTOGenerateAdjust = useGetFullTimeEmployeeAnnualTOGenerateAdjust();
	const saveFullTimeEmployeeAnnualTOGenerateAdjust = useSaveFullTimeEmployeeAnnualTOGenerateAdjust(
		(client) => {
			client.invalidateQueries([
				endpoint.getFullTimeEmployeeAnnualTOHistories.key,
				{
					centerId: fullTimeEmployee.centerId,
					employeeId: fullTimeEmployee.employeeId,
					memberId: fullTimeEmployee.memberId,
				},
			]);
			client.invalidateQueries([
				endpoint.getFullTimeEmployeeDetailInfo.key,
				{
					centerId: fullTimeEmployee.centerId,
					employeeId: fullTimeEmployee.employeeId,
					memberId: fullTimeEmployee.memberId,
				},
			]);
			Toast.success('발생 연차 조정이 성공하였습니다.');
			hideDialog();
		},
		(client, error) => {
			Toast.error(error.message);
			hideDialog();
		},
	);

	const submit = async (formData: {
		adjustmentType: string;
		adjustmentCnt: number;
		adjustmentHourCnt: number;
		reason: string;
	}) => {
		if (!data) return;
		const form = {
			...editItem,
			rowType: data.rowType ?? '',
			employeeId: fullTimeEmployee.employeeId,
			centerId: fullTimeEmployee.centerId,
			joinDate: data.joinDate,
			workAnnualItemCd: 'CMN191.20',
			workYearCntCd: data.workYearCntCd,
			beforeAnnualDayCnt: data.beforeAnnualDayCnt,
			beforeAnnualDayCntNm: data.beforeAnnualDayCntNm,
			adjustAnnualDayCnt: watch('adjustmentCnt'),
			afterAnnualDayCnt: data.afterAnnualDayCnt,
			afterAnnualDayCntNm: displayTOTime(data.beforeAnnualDayCnt + watch('adjustmentCnt')),
			annualAdjustDesc: watch('reason'),
		};
		if (onSubmit) {
			onSubmit(form);
		} else {
			await saveFullTimeEmployeeAnnualTOGenerateAdjust.mutateAsync(form);
		}
	};

	const fetchInitialData = useCallback(async () => {
		if (editItem) {
			setData(editItem);
			return;
		}
		const result = await getFullTimeEmployeeAnnualTOGenerateAdjust.mutateAsync({
			centerId: fullTimeEmployee.centerId,
			employeeId: fullTimeEmployee.employeeId,
			memberId: fullTimeEmployee.memberId,
		});
		if (result) setData(result);
	}, [editItem]);

	useEffect(() => {
		fetchInitialData();
	}, []);

	useEffect(() => {
		setIsValid(isValid);
	}, [isValid]);

	useImperativeHandle(ref, () => ({
		submit,
	}));

	if (!data) return <div />;

	return (
		<>
			<CRInputLabel label='조정' isRequired>
				<InformationSheet
					type='gray'
					items={[
						[
							{
								label: '입사일 기준 발생 연차',
								value: displayTOTime(data?.beforeAnnualDayCnt ?? 0),
								valueStyle: {
									height: '4rem',
								},
							},
						],
						[
							{
								label: '조정일수 (일)',
								value: (
									<Controller
										control={control}
										name='adjustmentCnt'
										render={({ field }) => (
											<CRInput.Counter
												value={field.value}
												onChange={field.onChange}
												suffix='일'
												countStepNumber={-1}
												max={0}
												min={-data.beforeAnnualDayCnt}
												plusColor={Colors.blue}
												minusColor={Colors.red}
											/>
										)}
									/>
								),
								valueStyle: {
									height: '4rem',
								},
							},
						],
						[
							{
								type: 'divider',
							},
						],
						[
							{
								type: 'highlight',
								label: '조정 후 발생 연차',
								value: displayTOTime(data.beforeAnnualDayCnt + watch('adjustmentCnt')),
								valueStyle: {
									height: '4rem',
								},
							},
						],
					]}
				/>
			</CRInputLabel>
			<Controller
				control={control}
				name='reason'
				render={({ field }) => (
					<CRInputLabel label='사유' isRequired renderRightAddon={`${field.value?.length ?? 0}/50`}>
						<CRInput.TextArea
							placeholder='사유 입력'
							maxLength={50}
							value={field.value}
							onChange={field.onChange}
						/>
					</CRInputLabel>
				)}
			/>
		</>
	);
}

export default React.forwardRef<AnnualTOAdjustmentFormRef, Props>(AnnualTOGenerateAdjustForm);
