import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { Toast } from 'components/base/CRToast';
import { MyCenterDocument } from 'lib';
import { useMyAccountInfo } from 'lib/hook/react-query';
import { useUpdateMyCenterDocument } from 'lib/hook/react-query/mutation/document';
import { ResponseCode } from 'types/api';
import { MyCenterDocsContentDTO, UpdateMyCenterDocsRequest } from 'types/api/document';
import { MyCenterAddDocumentForm } from 'types/view/document';

import * as S from './styles';

interface Props {
	item?: MyCenterDocsContentDTO;
	hideDialog: () => void;
	onRefresh: () => void;
}

export default function AddDocumentDialog({ item, hideDialog, onRefresh }: Props) {
	const {
		control,
		handleSubmit,
		trigger,
		formState: { isValid },
	} = useForm<MyCenterAddDocumentForm>({
		resolver: yupResolver(MyCenterDocument),
		mode: 'onChange',
		defaultValues: item
			? {
					centerFileNm: item?.centerFileNm || '',
					centerFile: item.centerFile.fileDetails,
				}
			: { centerFileNm: '', centerFile: undefined },
	});

	const { data: myAccountInfo } = useMyAccountInfo();

	const { mutate: updateMyCenterDocument } = useUpdateMyCenterDocument((client, returnData) => {
		const isEditMode = !!item;
		if (returnData?.code === ResponseCode.SUCCESS) {
			hideDialog();
			const isEditMode = !!item;

			Toast.success(`정상적으로 문서를 ${isEditMode ? '수정' : '추가'}하였습니다.`);
			onRefresh();
		} else {
			Toast.error(
				`문서 ${
					isEditMode ? '수정' : '추가'
				}에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.`,
			);
		}
	});

	const onSubmit = async (data: MyCenterAddDocumentForm) => {
		if (!myAccountInfo?.centerId) return;
		const isEditMode = !!item;
		const fileDetails = data.centerFile?.filter((file) => !file.fileDeleteYn);
		const param: UpdateMyCenterDocsRequest = {
			centerId: myAccountInfo.centerId,
			centerFileNm: data.centerFileNm,
			centerFile: {
				fileDetails,
			},
		};

		if (isEditMode) {
			param.centerFileId = item?.centerFileId;
			param.centerFile = {
				...param.centerFile,
				fileId: item.fileId,
			};
		}
		updateMyCenterDocument(param);
	};

	const submitForm = () => {
		trigger().then((isValid) => {
			if (isValid) {
				handleSubmit(onSubmit)();
			}
		});
	};

	return (
		<CRDialog
			onClickClose={hideDialog}
			type='S'
			title={`문서 ${item ? '수정' : '추가'}`}
			body={
				<S.Form onSubmit={handleSubmit(onSubmit)}>
					<S.Content>
						<Controller
							render={({ field: { ref, onBlur, onChange, value }, formState: { errors } }) => (
								<CRInputLabel isRequired label='문서명'>
									<CRInput.Default
										ref={ref}
										onBlur={onBlur}
										onChange={onChange}
										value={value}
										placeholder='문서명 입력'
									/>
								</CRInputLabel>
							)}
							name='centerFileNm'
							control={control}
						/>
						<Controller
							name='centerFile'
							control={control}
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInputLabel label='첨부 파일' isRequired>
									<CRInput.FileUploader
										// title={value?.[0].originFileNm || ''}
										onChange={onChange}
										files={value}
										type='multiple'
										onDelete={onRefresh}
									/>
								</CRInputLabel>
							)}
						/>
					</S.Content>
				</S.Form>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						disabled={!isValid}
						palette='primary'
						size='default'
						onClick={submitForm}>
						{item ? '수정' : '추가'}
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
