import React, { useCallback } from 'react';

import dayjs from 'dayjs';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRProgressBar from 'components/base/CRProgressBar';
import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import CRTableHeader, { Filter } from 'components/base/CRTableHeader';
import { useMyTaskTypes } from 'lib/hook/react-query';
import { MySubTaskContentDTO, MySubTaskRequestDTO, MyTaskContentDTO } from 'types/api/myTask';
import { PageInfo } from 'types/view/base';
import { FilterType } from 'types/view/filter';

import { MY_WORK_LIST_STATUS_CONFIG, SCHEDULE_TASK_HEADER_CONFIG } from './constant';
import * as S from './styles';

interface Props<T extends { label: string; value: any }> {
	items?: MyTaskContentDTO[];
	searchValue?: string;
	currentFilter: {
		[key in string]: T[];
	};
	pageInfo?: PageInfo;
	filters?: Array<Filter<T>>;
	setCurrentFilter?: React.Dispatch<
		React.SetStateAction<{
			[key: string]: T[];
		}>
	>;
	onChangeSearchValue?: (searchValue: string) => void;
	dataLoader?: (param: MySubTaskRequestDTO) => Promise<MySubTaskContentDTO[]>;
	onClickButton?: () => void;
	onClickSubTask?: (subTask: MySubTaskContentDTO) => void;
	onClickTask?: (task: MyTaskContentDTO) => void;
	onChangePageInfo?: (pageInfo: PageInfo) => void;
	onSearch?: () => void;
}

function ScheduleTaskTable<T extends { label: string; value: any }>({
	items = [],
	currentFilter,
	searchValue,
	pageInfo,
	filters,
	setCurrentFilter,
	onChangeSearchValue,
	onClickButton,
	onClickSubTask,
	onClickTask,
	dataLoader,
	onChangePageInfo,
	onSearch,
}: Props<T>): React.ReactElement {
	const { data: myTaskTypes } = useMyTaskTypes();

	const MyTaskProgressBar = useCallback(
		(value: string | number) => <CRProgressBar percentage={Number(value) / 100} showPercentText />,
		[],
	);

	const MyTaskStatus = useCallback(
		(value: string | number) => <CRStatus options={MY_WORK_LIST_STATUS_CONFIG}>{value}</CRStatus>,
		[],
	);

	const MyTaskDate = useCallback(
		(value: string | number) => <span>{dayjs(value).format('YY.MM.DD')}</span>,
		[],
	);

	return (
		<S.Container>
			<CRTableHeader
				onChangePageInfo={onChangePageInfo}
				pageInfo={pageInfo}
				currentFilter={currentFilter}
				renderRightButton={
					<CRButton.IconButton
						iconLeft={Assets.icon.add}
						size='xSmall'
						onClick={onClickButton}
						palette='primary'
						type='filled'>
						새 업무 생성
					</CRButton.IconButton>
				}
				filters={filters}
				searchValue={searchValue}
				placeholder='Place Holder'
				onChangeSearchValue={onChangeSearchValue}
				setCurrentFilter={setCurrentFilter}
				onSearch={onSearch}
				stickyMode
			/>
			<CRTable.Root>
				<CRTable.Head heads={SCHEDULE_TASK_HEADER_CONFIG} />
				<CRTable.Body>
					{items.map((item) => (
						<CRTable.CollapsibleRow
							key={item.myTaskId}
							item={item}
							customRender={{
								taskStartDate: MyTaskDate,
								taskEndDate: MyTaskDate,
								taskProgressRate: MyTaskProgressBar,
							}}
							detailCustomRender={{
								taskStartDate: MyTaskDate,
								taskEndDate: MyTaskDate,
								taskProgressStateCd: MyTaskStatus,
							}}
							loaderKeys={['myTaskId']}
							dataLoader={dataLoader}
							hasLoadableDetails={item.subMyTaskCount > 0}
							renderKeys={[
								'taskTypeNm',
								'taskTitle',
								'taskStartDate',
								'taskEndDate',
								'taskProgressRate',
							]}
							detailRenderKeys={[
								'',
								'taskTitle',
								'taskStartDate',
								'taskEndDate',
								'taskProgressStateCd',
							]}
							detailKey='myTaskId'
							onClick={onClickTask}
							onClickDetail={onClickSubTask}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default ScheduleTaskTable;
