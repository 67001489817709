import React, { useState } from 'react';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { Toast } from 'components/base/CRToast';
import { useCancelEDoc } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';

import * as S from './styles';

interface Props {
	defaultData: {
		edocNm: string;
		edocTitle: string;
		signDueDate: string;
	};
	edocSendId?: number;
	centerId?: number;
}

export default function ESignCancelDialog({ defaultData, edocSendId, centerId }: Props) {
	const { hideDialog } = useDialog();
	const { mutate: cancelEDoc } = useCancelEDoc((client, returnData) => {
		if (returnData?.edocSendId) {
			Toast.success('정상적으로 서명 진행 취소를 하였습니다.');
			client.invalidateQueries([endpoint.getESignHistoryDetail.key, { centerId, edocSendId }]);
			hideDialog();
		}
	});
	const [cancellationReason, setCancellationReason] = useState('');

	const handleChangeCancellationReason = (value: string) => {
		setCancellationReason(value);
	};

	const handleSubmit = () => {
		cancelEDoc({
			edocSendId,
			centerId,
			edocCancelDesc: cancellationReason,
		});
	};

	return (
		<CRDialog
			type='S'
			onClickClose={hideDialog}
			title='서명 진행 취소'
			body={
				<S.Container>
					<CRInputLabel label='문서 유형'>
						<CRInput.Default disabled value={defaultData.edocNm} />
					</CRInputLabel>
					<CRInputLabel label='제목'>
						<CRInput.Default disabled value={defaultData.edocTitle} />
					</CRInputLabel>
					<CRInputLabel label='서명 기한'>
						<CRInput.Default disabled value={defaultData.signDueDate} />
					</CRInputLabel>
					<CRInputLabel
						label='취소 사유'
						isRequired
						renderRightAddon={`${cancellationReason?.length ?? 0}/20`}>
						<CRInput.Default
							onChange={handleChangeCancellationReason}
							value={cancellationReason}
							maxLength={20}
						/>
					</CRInputLabel>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						palette='primary'
						size='default'
						onClick={handleSubmit}
						disabled={!cancellationReason}>
						저장
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
