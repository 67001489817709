import { CRTableHeadConfig } from 'types/view/base';

export const FULL_TIME_EMPLOYEE_HISTORY_OF_DUTY_LIST_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '16rem',
		label: '상태',
		sortKey: '상태',
	},
	{
		width: '24rem',
		label: '적용기간',
		sortKey: '적용기간',
	},
	{
		width: '16rem',
		label: '담당 직무',
		sortKey: '담당 직무',
	},
	{
		width: '44rem',
		label: '비고',
		sortKey: '비고',
	},
	{
		width: '12rem',
		label: '등록일',
		sortKey: '등록일',
	},
	{
		width: '100%',
		label: ' ',
		sortKey: '',
	},
];
