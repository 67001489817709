import React, { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler } from 'react';

import CRInputDefault from '../CRInputDefault';
import * as S from './styles';

interface Props {
	value?: number | string;
	id?: string;
	addOnBottom?: string;
	status?: 'default' | 'error';
	disabled?: boolean;
	maxLength?: number;
	placeholder?: string;
	toggleSecret?: boolean;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	onBlur?: FocusEventHandler<HTMLInputElement>;
	onPressEnter?: KeyboardEventHandler<HTMLInputElement>;
}

function SerialCode({
	value,
	id,
	status = 'default',
	maxLength = 524288,
	addOnBottom = '',
	disabled = false,
	placeholder = '',
	toggleSecret = false,
	onChange,
	onBlur,
	onPressEnter,
}: Props): React.ReactElement {
	return (
		<S.Container>
			<CRInputDefault />
			<S.Divider>-</S.Divider>
			<CRInputDefault />
			<S.Divider>-</S.Divider>
			<CRInputDefault />
		</S.Container>
	);
}

export default SerialCode;
