import styled from 'styled-components';

export const Container = styled.div`
	dispaly: flex;
	flex-direction: column;
	padding: 2.4rem;
	gap: 1.6rem;
	background-color: ${(props) => props.theme.colors.gray99};
	border-radius: 0.8rem;
`;

export const ChartContainer = styled.div<{ ratio: number }>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 12rem;
	height: 12rem;
	border-radius: 6rem;
	background: conic-gradient(
		${(props) => props.theme.colors.green} ${(props) => props.ratio}%,
		${(props) => props.theme.colors.gray90} ${(props) => props.ratio}%
	);
`;

export const RatioValueContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 10.4rem;
	height: 10.4rem;
	border-radius: 5.2rem;
	background-color: ${(props) => props.theme.colors.white};
`;

export const ContentContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 3.2rem;
	margin-top: 1.6rem;
`;

export const IndexContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 10.2rem;
	gap: 0.8rem;
`;

export const IndexItem = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 0.8rem;
`;

export const Index = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.4rem;
`;

export const ColorDot = styled.div<{ color: string }>`
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 1.2rem;
	background-color: ${(props) => props.color};
`;
