import { useEffect } from 'react';

import { LGNBType } from '../../provider/ui/Global';
import useGlobalLayout from './useGlobalLayout';

function useFullScreen() {
	const { setLGNBType } = useGlobalLayout();

	useEffect(() => {
		setLGNBType(LGNBType.FullScreen);
		return () => {
			setLGNBType(LGNBType.FitScreen);
		};
	}, []);
}

export default useFullScreen;
