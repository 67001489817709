import { CRTableHeadConfig } from 'types/view/base';

export const RECIPIENT_HOLDING_HISTORY_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '5.6rem',
		label: '구분',
		sortKey: '구분',
		textAlign: 'right',
	},
	{
		width: '21.6rem',
		label: '입원 기간',
		sortKey: '입원 기간',
	},
	{
		width: '',
		label: '비고',
		sortKey: '비고',
	},
	{
		label: '',
		width: '24rem',
		sortKey: '',
	},
];
