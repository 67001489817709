import React from 'react';

import { FullTimeEmployeeDetailInfoDTO } from 'types/dto/full-time-employee';

import FullTimeTrainingView from './FullTimeTrainingView';
import * as S from './styles';

interface Props {
	item?: FullTimeEmployeeDetailInfoDTO | null;
}

export default function FullTimeTrainingFormView({ item }: Props): React.ReactElement {
	return (
		<S.Container>
			<FullTimeTrainingView item={item} />
		</S.Container>
	);
}
