import { styled } from 'styled-components';

import CRIcon from 'components/base/CRIcon';

export const WorkScheduleLabel = styled.div<{ $confirmed: boolean }>`
	display: flex;
	align-items: center;
	width: 100%;
	height: 3.2rem;
	${(props) => props.theme.typography.label};
	border-radius: 0.4rem;
	padding: 0 0.8rem;
	overflow: hidden;
	white-space: nowrap;
	gap: 0.4rem;

	&.type--Holiday {
		background-color: ${(props) => props.theme.colors.primary90};
		color: ${(props) => props.theme.colors.primary20};
	}
	&.type--Success {
		${(props) =>
			props.$confirmed
				? `background-color: ${props.theme.colors.gray95}; color: ${props.theme.colors.gray20};`
				: `background-color: ${props.theme.colors.blueLighten}; color: ${props.theme.colors.gray00};`}
	}
	&.type--Error {
		background-color: ${(props) => props.theme.colors.primary90};
		color: ${(props) => props.theme.colors.primary60};
		border: 1px solid ${(props) => props.theme.colors.primary60};
	}
	&.type--Warning {
		background-color: ${(props) => props.theme.colors.yellowLighten};
		color: ${(props) => props.theme.colors.yellowDarken};
		border: 1px solid ${(props) => props.theme.colors.yellow};
	}
`;

export const DayHeaderTag = styled.div`
	display: flex;
	align-items: center;
	height: 2.5rem;
	padding: 0 0.4rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: 0.4rem;
	overflow: hidden;
	background-color: ${(props) => props.theme.colors.white};
`;

export const TagContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 0.2rem;
`;

export const StyledCheckIcon = styled(CRIcon)`
	width: 1.6rem;
	height: 1.6rem;

	&.type--Success {
		filter: brightness(0) saturate(100%) invert(59%) sepia(6%) saturate(119%) hue-rotate(149deg)
			brightness(97%) contrast(86%);
	}

	&.type--Error {
		filter: brightness(0) saturate(100%) invert(54%) sepia(43%) saturate(6391%) hue-rotate(323deg)
			brightness(102%) contrast(100%);
	}
	&.type--Warning {
		filter: invert(49%) sepia(97%) saturate(636%) hue-rotate(357deg) brightness(98%) contrast(110%);
	}
`;
