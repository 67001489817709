import { styled } from 'styled-components';

export const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - 4.8rem);
	gap: 1.6rem;
	overflow: scroll;
	padding: 0 2.4rem;
`;

export const HolidayTimeContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	gap: 0.8rem;
`;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 0.8rem;
`;

export const RemainVacaDay = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.6rem;
	background-color: ${(props) => props.theme.colors.gray99};
	box-sizing: border-box;
	border-radius: 0.8rem;
`;

export const RemainVacaDayContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.4rem;
`;

export const InfoContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.4rem;
`;

export const InfoIcon = styled.img`
	width: 1.6rem;
	height: 1.6rem;
`;
