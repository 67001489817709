import React, { useMemo } from 'react';

import RouterPath from 'common/router';
import CRTab from 'components/base/CRTab';

import AlarmListPage from './AlarmListPage';

function AlarmPage(): React.ReactElement {
	const alarmTab = useMemo(() => RouterPath.alarm(), []);

	return (
		<CRTab.Default
			defaultActiveKey={alarmTab}
			items={[
				{
					label: alarmTab,
					key: alarmTab,
					children: <AlarmListPage />,
				},
			]}
			breadCrumb='알림'
		/>
	);
}

export default AlarmPage;
