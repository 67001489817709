import React from 'react';
import { Controller } from 'react-hook-form';

import CRButton from 'components/base/CRButton';
import CRRequiredMark from 'components/base/CRRequiredMark';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import useContractTask from 'lib/hook/view/contract/useContractTask';
import { ContractDetailInspectionCheckDTO } from 'types/dto';
import { ContractStatusType } from 'types/view/common';

import * as S from './styles';

function InspectionBeforeApprovalForm(): React.ReactElement {
	const { form } = useContractTask();

	const handleClickIaDirectly = () => {
		window.open(
			`recipient/${form.getValues('recipient.base.recipientId')}/integrated-assessment`,
			'_blank',
		);
	};

	const handleClickInsuranceDirectly = () => {
		window.open(`insurance`, '_blank');
	};

	return (
		<S.Container>
			<Controller
				name='inspection.check'
				control={form?.control}
				render={({ field: { onChange, value }, formState: { errors } }) => {
					const isAllChecked = (
						[
							'recipientIaWriteYn',
							'salaryOfferPlanWriteYn',
							'pcorpScheduleRegistYn',
							'wardReportCompleteYn',
							'hopeiumFormWriteYn',
							'tagBeaconRequestYn',
							'admsnUsereqUsedescLocalgovSendYn',
							'liabilityInsuranceRegistNeedYn',
							'longTermUsePlanPcorpBrowseRequestYn',
							'pcorpSalaryContractCancelYn',
							'autotransUploadYn',
							'insuranceRequestYn',
							'wardEndReportCompleteYn',
						] as (keyof ContractDetailInspectionCheckDTO)[]
					).every((item) => value?.[item]);

					return (
						<>
							<CRCheckBox
								checked={isAllChecked}
								onChange={() => {
									onChange({
										...value,
										recipientIaWriteYn: !isAllChecked,
										salaryOfferPlanWriteYn: !isAllChecked,
										pcorpScheduleRegistYn: !isAllChecked,
										wardReportCompleteYn: !isAllChecked,
										hopeiumFormWriteYn: !isAllChecked,
										tagBeaconRequestYn: !isAllChecked,
										admsnUsereqUsedescLocalgovSendYn: !isAllChecked,
										liabilityInsuranceRegistNeedYn: !isAllChecked,
										longTermUsePlanPcorpBrowseRequestYn: !isAllChecked,
										pcorpSalaryContractCancelYn: !isAllChecked,
										autotransUploadYn: !isAllChecked,
										insuranceRequestYn: !isAllChecked,
										wardEndReportCompleteYn: !isAllChecked,
									});
								}}
								disabled={[
									ContractStatusType.Complete,
									ContractStatusType.Cancel,
									ContractStatusType.Pending,
								].includes(form.watch('contractBaseInfo.contractStateCd') as ContractStatusType)}>
								전체 점검 완료
							</CRCheckBox>
							<S.Divider />
							<CRCheckBox
								checked={value?.recipientIaWriteYn}
								onChange={() => {
									onChange({ ...value, recipientIaWriteYn: !value?.recipientIaWriteYn });
								}}
								disabled={[
									ContractStatusType.Complete,
									ContractStatusType.Cancel,
									ContractStatusType.Pending,
								].includes(form.watch('contractBaseInfo.contractStateCd') as ContractStatusType)}>
								통합사정 작성
								<CRRequiredMark />
								<S.IntegratedAssessmentDirectContainer>
									<CRButton.Default
										palette='gray'
										type='outlined'
										size='xSmall'
										onClick={handleClickIaDirectly}>
										바로가기
									</CRButton.Default>
								</S.IntegratedAssessmentDirectContainer>
							</CRCheckBox>
							<CRCheckBox
								checked={value?.salaryOfferPlanWriteYn}
								onChange={() =>
									onChange({ ...value, salaryOfferPlanWriteYn: !value?.salaryOfferPlanWriteYn })
								}
								disabled={[
									ContractStatusType.Complete,
									ContractStatusType.Cancel,
									ContractStatusType.Pending,
								].includes(form.watch('contractBaseInfo.contractStateCd') as ContractStatusType)}>
								급여제공계획서 작성
								<CRRequiredMark />
							</CRCheckBox>
							<CRCheckBox
								checked={value?.pcorpScheduleRegistYn}
								onChange={() => {
									onChange({ ...value, pcorpScheduleRegistYn: !value?.pcorpScheduleRegistYn });
								}}
								disabled={[
									ContractStatusType.Complete,
									ContractStatusType.Cancel,
									ContractStatusType.Pending,
								].includes(form.watch('contractBaseInfo.contractStateCd') as ContractStatusType)}>
								공단 (일정)
								<CRRequiredMark />
							</CRCheckBox>
							<CRCheckBox
								checked={value?.wardReportCompleteYn}
								onChange={() => {
									onChange({ ...value, wardReportCompleteYn: !value?.wardReportCompleteYn });
								}}
								disabled={[
									ContractStatusType.Complete,
									ContractStatusType.Cancel,
									ContractStatusType.Pending,
								].includes(form.watch('contractBaseInfo.contractStateCd') as ContractStatusType)}>
								시군구보고
								<CRRequiredMark />
							</CRCheckBox>
							<CRCheckBox
								checked={value?.hopeiumFormWriteYn}
								onChange={() => {
									onChange({ ...value, hopeiumFormWriteYn: !value?.hopeiumFormWriteYn });
								}}
								disabled={[
									ContractStatusType.Complete,
									ContractStatusType.Cancel,
									ContractStatusType.Pending,
								].includes(form.watch('contractBaseInfo.contractStateCd') as ContractStatusType)}>
								희망이음 공문 작성
								<CRRequiredMark />
							</CRCheckBox>
							<CRCheckBox
								checked={value?.tagBeaconRequestYn}
								onChange={() => {
									onChange({ ...value, tagBeaconRequestYn: !value?.tagBeaconRequestYn });
								}}
								disabled={[
									ContractStatusType.Complete,
									ContractStatusType.Cancel,
									ContractStatusType.Pending,
								].includes(form.watch('contractBaseInfo.contractStateCd') as ContractStatusType)}>
								태그 신청
								<CRRequiredMark />
							</CRCheckBox>
							<CRCheckBox
								checked={value?.admsnUsereqUsedescLocalgovSendYn}
								onChange={() => {
									onChange({
										...value,
										admsnUsereqUsedescLocalgovSendYn: !value?.admsnUsereqUsedescLocalgovSendYn,
									});
								}}
								disabled={[
									ContractStatusType.Complete,
									ContractStatusType.Cancel,
									ContractStatusType.Pending,
								].includes(form.watch('contractBaseInfo.contractStateCd') as ContractStatusType)}>
								입소이용신청서/내역서 지자체 발송 <CRRequiredMark />
							</CRCheckBox>
							<CRCheckBox
								checked={value?.liabilityInsuranceRegistNeedYn}
								onChange={() => {
									onChange({
										...value,
										liabilityInsuranceRegistNeedYn: !value?.liabilityInsuranceRegistNeedYn,
									});
								}}
								disabled={[
									ContractStatusType.Complete,
									ContractStatusType.Cancel,
									ContractStatusType.Pending,
								].includes(form.watch('contractBaseInfo.contractStateCd') as ContractStatusType)}>
								일반요양보호사 배상책임보험 등록
								<CRRequiredMark />
							</CRCheckBox>
							<CRCheckBox
								checked={value?.longTermUsePlanPcorpBrowseRequestYn}
								onChange={() => {
									onChange({
										...value,
										longTermUsePlanPcorpBrowseRequestYn:
											!value?.longTermUsePlanPcorpBrowseRequestYn,
									});
								}}
								disabled={[
									ContractStatusType.Complete,
									ContractStatusType.Cancel,
									ContractStatusType.Pending,
								].includes(form.watch('contractBaseInfo.contractStateCd') as ContractStatusType)}>
								개인별 장기요양이용계획서 공단 열람 신청
								<CRRequiredMark />
							</CRCheckBox>
							{form.watch('inspection.burdenAmt.burdenAmtPayMethodCd') === 'CMN041.10' && (
								<CRCheckBox
									checked={value?.autotransUploadYn}
									onChange={() => {
										onChange({
											...value,
											autotransUploadYn: !value?.autotransUploadYn,
										});
									}}
									disabled={[
										ContractStatusType.Complete,
										ContractStatusType.Cancel,
										ContractStatusType.Pending,
									].includes(form.watch('contractBaseInfo.contractStateCd') as ContractStatusType)}>
									자동이체동의서 업로드
									<CRRequiredMark />
								</CRCheckBox>
							)}
							<CRCheckBox
								checked={value?.insuranceRequestYn}
								onChange={() => {
									onChange({
										...value,
										insuranceRequestYn: !value?.insuranceRequestYn,
									});
								}}
								disabled={[
									ContractStatusType.Complete,
									ContractStatusType.Cancel,
									ContractStatusType.Pending,
								].includes(form.watch('contractBaseInfo.contractStateCd') as ContractStatusType)}>
								4대 보험 신청
								<CRRequiredMark />
								<S.IntegratedAssessmentDirectContainer>
									<CRButton.Default
										palette='gray'
										type='outlined'
										size='xSmall'
										onClick={handleClickInsuranceDirectly}>
										바로가기
									</CRButton.Default>
								</S.IntegratedAssessmentDirectContainer>
							</CRCheckBox>
						</>
					);
				}}
			/>
		</S.Container>
	);
}

export default InspectionBeforeApprovalForm;
