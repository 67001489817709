import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';

import dayjs from 'dayjs';

import Assets from 'assets';
import CRDropdown from 'components/base/CRDropdown';
import CRInput from 'components/base/CRInput';
import { UserType } from 'pages/dashboard/SendPage/SendMessageTab/mock';
import { FilterType } from 'types/view/filter';

import * as S from './styles';

const people = [
	{
		label: '강채원',
		value: {
			name: '강채원',
			birth: '621203',
			userNumber: 1038,
			userType: UserType.Recipient,
		},
	},
	{
		label: '김나라',
		value: {
			name: '김나라',
			birth: '621203',
			userNumber: 1039,
			userType: UserType.Provider,
		},
	},
	{
		label: '권나라',
		value: {
			name: '권나라',
			birth: '901203',
			userNumber: 1040,
			userType: UserType.Provider,
		},
	},
];

interface IProps<T extends { label: string; value: any }> {
	currentValue?: T[];
	placeholder?: string;
	filterKey?: string;
	onChangeValue?: (item: T, key: string, filterType: FilterType) => void;
}

function SearchChips<T extends { label: string; value: any }>({
	currentValue = [],
	placeholder = '',
	filterKey = '',
	onChangeValue,
}: IProps<T>): React.ReactElement {
	const [searchValue, setSearchValue] = useState();
	const [showOptions, setShowOptions] = useState(false);

	const filterRef = useRef<HTMLDivElement>(null);

	const isActive = useMemo(() => currentValue?.[0]?.value, [currentValue]);

	const handleToggleOptions = () => {
		setShowOptions((prev) => !prev);
	};

	const renderText = () => {
		let label = '';
		if (currentValue?.length && currentValue[0]?.value) {
			const start = dayjs(currentValue[0].value.start);
			const end = dayjs(currentValue[0].value.end);
			const isSameDate = start.diff(end, 'day') === 0;

			const result = `${placeholder} : ${
				isSameDate
					? dayjs(currentValue[0].value.start).format('YY.MM.DD')
					: `${dayjs(currentValue[0].value.start).format('YY.MM.DD')} → ${dayjs(
							currentValue[0].value.end,
						).format('YY.MM.DD')}`
			}`;
			label = result;
		} else {
			label = placeholder;
		}
		return label;
	};

	const onClickInit = (e: MouseEvent) => {
		e.stopPropagation();
		onChangeValue?.(
			{
				label: filterKey,
				value: undefined,
			} as T,
			filterKey,
			'calendar',
		);
	};

	useEffect(() => {
		const handleMouseOut = (e: MouseEvent) => {
			if (!filterRef.current?.contains(e.target as Node)) {
				setShowOptions(false);
			}
		};
		document.addEventListener('mousedown', handleMouseOut as any);

		return () => {
			document.removeEventListener('mousedown', handleMouseOut as any);
		};
	}, []);

	return (
		<S.Container ref={filterRef}>
			<S.DefaultOption
				$showOptions={showOptions}
				$isActive={isActive}
				onClick={handleToggleOptions}
				disabled={false}>
				<S.ChipLabel>{renderText()}</S.ChipLabel>
				{isActive && (
					<S.CloseIconContainer onClick={onClickInit}>
						<img src={Assets.icon.closeCircle} alt='keyboardArrowBottom' />
					</S.CloseIconContainer>
				)}
			</S.DefaultOption>

			{showOptions && (
				<CRDropdown style={{ width: 'fit-content' }}>
					<S.CustomHeader>
						<CRInput.Default
							maxLength={10}
							onChange={(value) => setSearchValue(value)}
							placeholder='이름을 검색하세요.'
							value={searchValue}
						/>
					</S.CustomHeader>
					<S.SearchResultContainer>
						{people.map((e) => (
							<S.SearchResultItem>{e.value.birth}</S.SearchResultItem>
						))}
					</S.SearchResultContainer>
				</CRDropdown>
			)}
		</S.Container>
	);
}

export default SearchChips;
