import React from 'react';

import CRChips from 'components/base/CRChips';
import { match } from 'lib';
import { Filter, FilterType } from 'types/view/filter';

import * as S from './styles';

interface Props<T extends { label: string; value: any }> {
	filters?: Filter<T>[];
	currentFilter: {
		[key: string]: T[];
	};
	setCurrentFilter: any;
}

function CRFilterGroup<T extends { label: string; value: any }>({
	filters = [],
	currentFilter,
	setCurrentFilter,
}: Props<T>): React.ReactElement {
	const onChangeFilter = (item: T, key: string, filterType: FilterType) => {
		const prevFilter = Object.keys(currentFilter).length === 0 ? {} : { ...currentFilter };

		if (filterType === 'single') {
			const newItems = { ...prevFilter };
			const exist = prevFilter[key]?.find((selectedItem) => selectedItem.value === item.value);
			if (exist) {
				delete newItems[key];
			} else {
				newItems[key] = [item];
			}
			setCurrentFilter({
				...newItems,
			});
		} else if (['multi', 'toggle'].includes(filterType)) {
			if (!prevFilter[key]) {
				setCurrentFilter({
					...prevFilter,
					[key]: [item],
				});
			} else if (item.value === undefined) {
				// 해당필터 초기화의 경우
				delete prevFilter.key;
				const removedKeyFilter: { [key: string]: T[] } = {};
				Object.entries(prevFilter).forEach(([label, value]) => {
					if (label !== key) {
						removedKeyFilter[label] = value;
					} else {
						removedKeyFilter[label] = [];
					}
				});
				setCurrentFilter(removedKeyFilter);
			} else {
				const newItems = { ...prevFilter };
				const exist = prevFilter[key].find((selectedItem) => selectedItem.value === item.value);
				if (exist) {
					newItems[key] = prevFilter[key]?.filter((prevItem) => prevItem.value !== item.value);
				} else {
					newItems[key] = [...prevFilter[key], item];
				}
				setCurrentFilter(newItems);
			}
		} else if (['calendar', 'month_calendar'].includes(filterType)) {
			setCurrentFilter({
				...prevFilter,
				[key]: [item],
			});
		}
	};
	return (
		<S.Container>
			{filters?.length > 0 &&
				filters?.map((filter) =>
					match(filter.type)
						.on(
							(type) => ['single', 'multi'].includes(type),
							() => (
								<CRChips.Selection
									maxHeight={500}
									key={filter.key}
									filterKey={filter.key}
									onChangeValue={onChangeFilter}
									currentValue={currentFilter?.[filter?.key]}
									options={filter.options}
									placeholder={filter.placeholder}
									filterType={filter.type}
								/>
							),
						)
						.on(
							(type) => type === 'calendar',
							() => (
								<CRChips.Calendar
									key={filter.key}
									filterKey={filter.key}
									onChangeValue={onChangeFilter}
									placeholder={filter.placeholder}
									currentValue={currentFilter?.[filter?.key]}
								/>
							),
						)
						.on(
							(type) => type === 'month_calendar',
							() => (
								<CRChips.MonthCalendar
									key={filter.key}
									filterKey={filter.key}
									onChangeValue={onChangeFilter}
									placeholder={filter.placeholder}
									currentValue={currentFilter?.[filter?.key]}
								/>
							),
						)
						.on(
							(type) => type === 'search',
							() => (
								<CRChips.Search
									key={filter.key}
									filterKey={filter.key}
									onChangeValue={onChangeFilter}
									placeholder={filter.placeholder}
									currentValue={currentFilter?.[filter?.key]}
								/>
							),
						)
						.otherwise(() => (
							<CRChips.Default
								key={filter.key}
								options={filter.options}
								filterKey={filter.key}
								onChangeValue={onChangeFilter}
								currentValue={currentFilter?.[filter?.key]}
							/>
						)),
				)}
		</S.Container>
	);
}

export default React.memo(CRFilterGroup);
