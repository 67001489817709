import React, { ChangeEventHandler, PropsWithChildren } from 'react';

import { ButtonPalette } from 'components/base/CRButton/type';

import { CheckBoxType } from '../type';
import * as S from './styles';

interface IProps {
	id?: string;
	name?: string;
	checked?: boolean;
	disabled?: boolean;
	onlyCheck?: boolean;
	checkBoxType?: CheckBoxType;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	errorMessage?: React.ReactNode;
	palette?: ButtonPalette;
}

function CheckBoxDefault({
	id,
	name,
	checked = false,
	disabled = false,
	onlyCheck = false,
	checkBoxType = 'checkbox',
	onChange,
	children,
	errorMessage,
	palette,
}: PropsWithChildren<IProps>) {
	return (
		<S.Container
			htmlFor={id}
			$appearanceType='default'
			$isActive={checked}
			$isDisabled={disabled}
			$onlyCheck={onlyCheck}>
			<S.InputContainer $isActive={checked} $isDisabled={disabled} $palette={palette}>
				<S.CRInput
					$palette={palette}
					id={id}
					name={name}
					type='checkbox'
					$checkBoxType={checkBoxType}
					checked={checked ?? false}
					onChange={onChange}
					disabled={disabled}
				/>
			</S.InputContainer>
			<S.Content>
				<S.Label $isDisabled={disabled}>{children}</S.Label>
				{errorMessage}
			</S.Content>
		</S.Container>
	);
}

export default CheckBoxDefault;
