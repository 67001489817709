import React, { useRef } from 'react';

import Colors from 'common/colors';
import { CRText } from 'components/base/CRText';
import { CheckOption } from 'components/base/Selections/type';

import Highlighter from '../../../ui/Highlighter';
import * as S from './styles';

interface Props {
	searchText?: string;
	searchItems?: CheckOption[];
	visibleKey?: string[];
	onClickSearchItem?: (searchItem: CheckOption) => void;
	allowSelf?: boolean;
}

function SearchDropDown({
	searchText,
	searchItems = [],
	onClickSearchItem,
	visibleKey = ['duty', 'birth', 'id', 'guardian', 'centerNm'],
	allowSelf,
}: Props): React.ReactElement {
	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const renderSearchItem = (searchItem: CheckOption) =>
		visibleKey
			.map((key) => searchItem.value?.[key])
			.filter(Boolean)
			.filter((val) => val)
			.join('・');

	const onClickSelf = () => {
		onClickSearchItem?.({
			label: searchText || '',
			value: searchText || '',
		});
	};

	return (
		<S.SearchDropDownContainer>
			<S.SearchResultTitle>검색 결과</S.SearchResultTitle>
			<S.SearchResultContainer ref={scrollContainerRef}>
				{searchItems.length > 0 ? (
					searchItems?.map((searchItem) => (
						<S.SearchResultItemOuterContainer>
							<S.SearchResultItemContainer
								key={searchItem.value?.id}
								onClick={(e) => {
									e.stopPropagation();
									onClickSearchItem?.(searchItem);
								}}>
								<S.SearchResultItemMainContainer>
									<Highlighter
										text={`${searchItem.value.name}`}
										targetText={searchText ?? ''}
										highlightStyle={{ color: Colors.primary60 }}
									/>
								</S.SearchResultItemMainContainer>
								<S.SearchResultItemSubContainer>
									<Highlighter
										text={renderSearchItem(searchItem)}
										targetText={searchText ?? ''}
										highlightStyle={{ color: Colors.primary60 }}
									/>
								</S.SearchResultItemSubContainer>
							</S.SearchResultItemContainer>
							{searchItem.children?.map((chidren) => (
								<S.SearchResultItemChildContainer
									key={chidren.value?.id}
									onClick={(e) => {
										e.stopPropagation();
										onClickSearchItem?.(chidren);
									}}>
									<S.Line />
									<S.SearchResultItemMainContainer>
										<Highlighter
											text={`${chidren.value.name}`}
											targetText={searchText ?? ''}
											highlightStyle={{ color: Colors.primary60 }}
										/>
									</S.SearchResultItemMainContainer>
									<S.SearchResultItemSubContainer>
										<Highlighter
											text={renderSearchItem(chidren)}
											targetText={searchText ?? ''}
											highlightStyle={{ color: Colors.primary60 }}
										/>
									</S.SearchResultItemSubContainer>
								</S.SearchResultItemChildContainer>
							))}
						</S.SearchResultItemOuterContainer>
					))
				) : (
					<S.SearchResultEmptyListContainer>
						<S.SearchResultEmptyListDescription>
							{searchText ? '검색결과가 없습니다.' : '검색어를 입력해주세요.'}
						</S.SearchResultEmptyListDescription>
					</S.SearchResultEmptyListContainer>
				)}
			</S.SearchResultContainer>
			{allowSelf && (
				<S.SelfSearchContainer onClick={onClickSelf}>
					<S.SelfBadge>직접 입력</S.SelfBadge>
					<CRText
						style={{
							flex: 1,
							textOverflow: 'ellipsis',
							overflow: 'hidden',
							whiteSpace: 'nowrap',
						}}
						padding='0.2rem 0 0 0 '
						text={searchText}
						color='primary60'
					/>
				</S.SelfSearchContainer>
			)}
		</S.SearchDropDownContainer>
	);
}

export default SearchDropDown;
