import React, { useState } from 'react';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRInputLabel from 'components/base/CRInputLabel';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';

import { ServiceEmployeeDTO } from '../../../../types/dto';
import { CheckOption } from '../../../base/Selections/type';
import * as S from './styles';

interface Props {
	items: ServiceEmployeeDTO[];
	onSubmit?: (item: number[]) => void;
	onClose?: () => void;
	onCancel?: () => void;
}

function RecipientEmployeeChangeDialog({
	items,
	onSubmit,
	onClose,
	onCancel,
}: Props): React.ReactElement {
	const [checkedOptions, setCheckedOptions] = useState<CheckOption[]>([]);
	return (
		<S.Container>
			<S.HeaderContainer>
				<S.HeaderTitle>담당 직원 변경</S.HeaderTitle>
				<S.CloseIcon src={Assets.icon.close} alt='close' onClick={onClose} />
			</S.HeaderContainer>
			<S.BodyContainer>
				<S.BodyContent>
					<S.Description>
						해당 급여의 기존 담당 직원의 계약을 종료하고 직원을 변경합니다.
					</S.Description>
					<CRInputLabel label='변경 직원 선택' isRequired>
						<CRCheckBoxGroup
							checkType='multiple'
							type='checkbox'
							direction='col'
							appearanceType='button'
							gap={0.4}
							value={checkedOptions}
							onChange={(item) => setCheckedOptions(item)}
							options={items.map((item) => ({
								label: item.employeeNm,
								value: item.employeeId,
							}))}
						/>
					</CRInputLabel>
				</S.BodyContent>
			</S.BodyContainer>
			<S.FooterContainer>
				<CRButton.Default palette='gray' type='text' onClick={onCancel}>
					취소
				</CRButton.Default>
				<CRButton.Default
					disabled={!checkedOptions.length}
					onClick={() => onSubmit?.(checkedOptions.map((option) => option.value))}>
					변경
				</CRButton.Default>
			</S.FooterContainer>
		</S.Container>
	);
}

export default React.memo(RecipientEmployeeChangeDialog);
