import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRIcon from 'components/base/CRIcon';
import CRInput from 'components/base/CRInput';
import CRInputMessage from 'components/base/CRInputMessage';
import CRSpinner from 'components/base/CRSpinner';
import { Toast } from 'components/base/CRToast';
import { useCheckAccountOwner } from 'lib/hook/react-query';
import useContractTask from 'lib/hook/view/contract/useContractTask';
import { ResponseCode } from 'types/api';
import { CheckAccountOwnerData, CheckAccountOwnerRequest } from 'types/api/common';

import CRRequiredMark from '../../../base/CRRequiredMark';
import * as S from './styles';

interface Props {
	index: number;
}

function EmployeeSalaryForm({ index }: Props): React.ReactElement {
	const { commonCodes, form, disabled } = useContractTask();
	const salaryAccountValidYn = form.watch(`employees.${index}.salary.salaryAccountValidYn`);
	const employee = form.watch(`employees.${index}`);
	const [isValidAccount, setIsValidAccount] = useState(false);

	const { mutate: checkAccountOwner, isLoading: checkAccountLoading } = useCheckAccountOwner(
		(client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS && returnData?.data) {
				const result: CheckAccountOwnerData = JSON.parse(returnData.data);
				if (result.data.OUTSTATCD === '0021') {
					setIsValidAccount(true);
					form?.setValue(`employees.${index}.salary.salaryAccountValidYn`, true);
					form?.clearErrors(`employees.${index}.salary.salaryAccountDepositorNm`);
				} else if (result.data.OUTSTATCD === '0031') {
					setIsValidAccount(false);
					form?.setError(`employees.${index}.salary.salaryAccountDepositorNm`, {
						message: result.data.OUTRSLTMSG,
					});
				} else {
					Toast.error('계좌 조회에 실패했습니다.');
				}
			}
		},
	);

	const isCache = form.watch(`employees.${index}.salary.salaryPayTypeCd`) === 'CMN133.20';

	const hasAccountInfo = !!(
		form.watch(`employees.${index}.salary.salaryAccountBankCd`) &&
		form.watch(`employees.${index}.salary.salaryAccountNo`) &&
		form.watch(`employees.${index}.salary.salaryAccountDepositorNm`)
	);

	const handleClickCheckAccount = () => {
		if (checkAccountLoading || !hasAccountInfo) return;
		const param: CheckAccountOwnerRequest = {
			acctNo: form.getValues(`employees.${index}.salary.salaryAccountNo`),
			bankCode: form.getValues(`employees.${index}.salary.salaryAccountBankCd`),
			custNm: form.getValues(`employees.${index}.salary.salaryAccountDepositorNm`),
		};
		checkAccountOwner(param);
	};

	useEffect(() => {
		if (index < 0) return;
		setIsValidAccount(!!salaryAccountValidYn);
	}, [salaryAccountValidYn]);

	if (!employee) return <div />;

	return (
		<S.Container>
			<S.Form>
				<S.TableContainer>
					<S.Table>
						<S.TableRow>
							<S.TableLabelColumn>
								수령방식 <CRRequiredMark />
							</S.TableLabelColumn>
							<S.TableValueColumn>
								<Controller
									key={`${index}-salaryPayTypeCd`}
									render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
										<CRInput.Selector
											items={commonCodes?.CMN133}
											ref={ref}
											type='small'
											onChangeValue={(item) => {
												onChange(item.value);
												setIsValidAccount(false);
											}}
											currentValue={
												employee.salary?.salaryPayTypeCd
													? { label: '', value: employee.salary?.salaryPayTypeCd }
													: undefined
											}
											placeholder='수령방식 선택'
											disabled={disabled}
										/>
									)}
									name={`employees.${index}.salary.salaryPayTypeCd`}
									control={form.control}
								/>
							</S.TableValueColumn>
							<S.TableLabelColumn>
								계좌번호 <CRRequiredMark />
							</S.TableLabelColumn>
							<S.TableValueColumn>
								<Controller
									key={`${index}-salaryAccountNo`}
									render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
										<CRInput.TableInput
											disabled={isCache || disabled}
											type='number'
											ref={ref}
											onBlur={onBlur}
											onChange={(item) => {
												onChange(item);
												setIsValidAccount(false);
											}}
											value={employee.salary?.salaryAccountNo ?? ''}
											placeholder='계좌번호 입력'
										/>
									)}
									name={`employees.${index}.salary.salaryAccountNo`}
									control={form.control}
								/>
							</S.TableValueColumn>
						</S.TableRow>
						<S.TableRow>
							<S.TableLabelColumn>
								은행 <CRRequiredMark />
							</S.TableLabelColumn>
							<S.TableValueColumn>
								<Controller
									key={`${index}-salaryAccountBankCd`}
									render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
										<CRInput.Selector
											disabled={isCache || disabled}
											topOffset={-210}
											items={commonCodes?.CMN037}
											ref={ref}
											type='small'
											onChangeValue={(item) => {
												onChange(item.value);
												setIsValidAccount(false);
											}}
											currentValue={
												employee.salary?.salaryAccountBankCd
													? { label: '', value: employee.salary?.salaryAccountBankCd }
													: undefined
											}
											placeholder='은행 선택'
										/>
									)}
									name={`employees.${index}.salary.salaryAccountBankCd`}
									control={form.control}
								/>
							</S.TableValueColumn>
							<S.TableLabelColumn>
								계좌주 <CRRequiredMark />
							</S.TableLabelColumn>
							<S.TableValueColumn>
								<Controller
									key={`${index}-salaryAccountDepositorNm`}
									render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
										<>
											<CRInput.TableInput
												disabled={isCache || disabled}
												ref={ref}
												onBlur={onBlur}
												onChange={(item) => {
													onChange(item);
													setIsValidAccount(false);
												}}
												value={employee.salary?.salaryAccountDepositorNm ?? ''}
												maxLength={20}
												placeholder='계좌주 입력'
												suffix={
													!isCache && (
														<CRButton.Default
															disabled={!hasAccountInfo || disabled}
															style={{
																position: 'relative',
																width: checkAccountLoading ? '4rem' : 'auto',
															}}
															size='xSmall'
															palette='gray'
															type='outlined'
															onClick={handleClickCheckAccount}>
															{checkAccountLoading ? (
																<CRSpinner />
															) : isValidAccount ? (
																<CRIcon src={Assets.icon.check} />
															) : (
																'조회'
															)}
														</CRButton.Default>
													)
												}
											/>
											{errors.employees?.[index]?.salary?.salaryAccountDepositorNm?.message && (
												<CRInputMessage type='error'>
													{errors.employees?.[index]?.salary?.salaryAccountDepositorNm?.message ??
														''}
												</CRInputMessage>
											)}
										</>
									)}
									name={`employees.${index}.salary.salaryAccountDepositorNm`}
									control={form.control}
								/>
							</S.TableValueColumn>
						</S.TableRow>
					</S.Table>
				</S.TableContainer>
			</S.Form>
		</S.Container>
	);
}

export default EmployeeSalaryForm;
