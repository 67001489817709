import React from 'react';

import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRSpinner from 'components/base/CRSpinner';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import { useDeleteRecipient } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import {
	RecipientAffiliationType,
	ResponseCode,
	UpdateRecipientAffiliationRequest,
} from 'types/api';
import { Recipient } from 'types/view/recipient';

import * as S from './styles';

interface Props {
	currentRecipient?: Recipient;
}

function RecipientDeleteDialog({ currentRecipient }: Props) {
	const { hideDialog } = useDialog();
	const haPermission = useHasPermission('센터장');

	const { mutate: deleteRecipient, isLoading } = useDeleteRecipient(async (client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			Toast.success('수급자 정보를 정상적으로 삭제하였습니다.');
			hideDialog();
			setTimeout(() => window.location.reload(), 500);
		} else {
			hideDialog();
			Toast.error('수급자 정보 삭제에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
	});

	const handleClickDelete = () => {
		if (!haPermission || !currentRecipient) return;
		const param: UpdateRecipientAffiliationRequest = {
			targetId: currentRecipient.recipientId,
			centerId: currentRecipient.centerId,
			target: RecipientAffiliationType.DELETE,
		};
		deleteRecipient(param);
	};
	return (
		<CRDialog
			onClickClose={hideDialog}
			title='수급자 정보 삭제'
			showCloseButton
			type='S'
			body={
				<S.BodyContainer>
					<CRText>
						{currentRecipient?.name}님의 수급자 정보를 모두 삭제하시겠습니까?
						<br /> <CRText color='red'>삭제 후에는 복구할 수 없습니다.</CRText>
					</CRText>
				</S.BodyContainer>
			}
			footer={
				<FlexContainer gap='0.8rem'>
					<CRButton.Default type='text' palette='gray' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						disabled={isLoading}
						onClick={handleClickDelete}
						type='outlined'
						palette={isLoading ? 'gray' : 'primary'}
						style={{
							padding: isLoading ? '0 2.4rem 0 1.6rem' : 'auto',
							background: isLoading ? Colors.gray95 : undefined,
							color: isLoading ? Colors.gray60 : undefined,
						}}>
						{isLoading && (
							<CRSpinner
								floatMode={false}
								containerStyle={{
									marginRight: '0.8rem',
								}}
							/>
						)}
						{isLoading ? '삭제 중' : '삭제'}
					</CRButton.Default>
				</FlexContainer>
			}
		/>
	);
}

export default RecipientDeleteDialog;
