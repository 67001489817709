import { styled } from 'styled-components';

export const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: scroll;
	padding: 2.4rem;
	max-height: calc(100vh - 4.8rem);
	border-top: 1px solid ${(props) => props.theme.colors.gray90};
	gap: 1.6rem;
`;
