import React from 'react';
import { get, useController, useFormContext } from 'react-hook-form';

import CRInput from 'components/base/CRInput';

interface Props extends React.ComponentProps<typeof CRInput.TextArea> {
	name: string;
}

function CRFormTableTextArea({ name, ...rest }: Props) {
	const methods = useFormContext();
	const { field, formState } = useController({ name, control: methods.control });
	const error = get(formState.errors, name);

	return (
		<CRInput.TextArea
			{...rest}
			{...field}
			status={error?.message ? 'error' : 'default'}
			addOnBottom={error?.message && `${error?.message}`}
		/>
	);
}

export default CRFormTableTextArea;
