import React from 'react';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { Toast } from 'components/base/CRToast';
import InformationTable from 'components/ui/InformationTable';
import {
	useMyAccountInfo,
	useRecipientIntegratedAssessmentSalaryOfferSend,
	useRecipientIntegratedAssessmentSalaryOfferSendPreview,
} from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';

import * as S from './styles';

interface Props {
	salaryOfferPlanId: number;
	recipientId: string;
	targetYear: string;
	hideDialog?: () => void;
}

function IntegratedAssessmentSalaryOfferDialog({
	salaryOfferPlanId,
	recipientId,
	targetYear,
	hideDialog,
}: Props): React.ReactElement {
	const { data } = useRecipientIntegratedAssessmentSalaryOfferSendPreview({
		salaryOfferPlanId: salaryOfferPlanId.toString(),
	});
	const { data: myAccountInfo } = useMyAccountInfo();

	const sendRecipientIntegratedAssessmentSalaryOffer =
		useRecipientIntegratedAssessmentSalaryOfferSend((client, returnData) => {
			if (!returnData?.isSent) {
				Toast.error('발송 실패하였습니다.');
				return;
			}
			Toast.success(`${data?.messageTitleNm}를 발송하였습니다.`);
			hideDialog?.();
			client.invalidateQueries([
				endpoint.getRecipientIntegratedAssessment.key,
				{
					recipientId,
					targetYear,
					centerId: myAccountInfo?.centerId.toString(),
				},
			]);
		});

	const handleSendScheduleMessage = () => {
		if (sendRecipientIntegratedAssessmentSalaryOffer.isLoading) return;
		sendRecipientIntegratedAssessmentSalaryOffer.mutateAsync({
			salaryOfferPlanId: salaryOfferPlanId.toString(),
		});
	};

	return (
		<CRDialog
			title='발송 상세'
			onClickClose={hideDialog}
			body={
				<S.Container>
					<S.BodyContainer>
						<CRInputLabel label='발송 정보'>
							<InformationTable
								items={[
									[
										{
											label: '템플릿명',
											value: data?.templateTitleNm ?? '',
										},
									],
									[
										{
											label: '제목',
											value: data?.messageTitleNm ?? '',
										},
									],
									[
										{
											label: '수신자',
											value: data?.receiverDesc ?? '',
										},
									],
								]}
							/>
						</CRInputLabel>
						<CRInputLabel label='발송 내용'>
							<CRInput.TextArea
								disabled
								numberOfLines={10}
								value={data?.messageDesc?.replace(/<br\/>|\\n/g, '\n')}
							/>
						</CRInputLabel>
					</S.BodyContainer>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default palette='primary' size='default' onClick={handleSendScheduleMessage}>
						발송
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}

export default React.memo(IntegratedAssessmentSalaryOfferDialog);
