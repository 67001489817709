import React from 'react';

import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import useCenterInfo from 'lib/hook/view/centerInformation/useCenterInfo';
import { WorkLeaveLimitCd } from 'types/view/centerInfo';

import * as S from './styles';

function MyCenterWorkLeavePermitIpView(): React.ReactElement {
	const { form, commonCodes } = useCenterInfo();

	return (
		<S.Container>
			<CRInputLabel type='left' label='출퇴근 IP 제한'>
				<CRCheckBoxGroup
					disabled
					direction='row'
					appearanceType='button'
					checkType='single'
					type='radio'
					gap={0.8}
					value={form.getValues('workLeaveLimitCd')}
					options={commonCodes?.workLeaveOptions}
				/>
			</CRInputLabel>
			{form.watch('workLeaveLimitCd')?.[0]?.value === WorkLeaveLimitCd.PARTIALLY_ALLOWED && (
				<CRInputLabel type='left' label='허가된 IP'>
					<CRInput.IPPermitter disabled value={form.getValues('workLeavePermitIps')} />
				</CRInputLabel>
			)}
		</S.Container>
	);
}

export default MyCenterWorkLeavePermitIpView;
