import React, { CSSProperties, useLayoutEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

import * as Pop from '@radix-ui/react-popover';
import { ko } from 'date-fns/esm/locale';
import dayjs from 'dayjs';

import Assets from 'assets';
import CRInput from 'components/base/CRInput';
import RdPopover from 'components/ui/radix/popover/RdPopover';

import Colors from '../../../../common/colors';
import * as S from './styles';

interface IProps {
	placeholder?: string;
	value?: Date;
	disabled?: boolean;
	popupPosition?: {
		x: number;
		y: number;
	};
	type?: 'M' | 'S';
	minDate?: Date;
	maxDate?: Date;
	containerStyle?: CSSProperties;
	status?: 'default' | 'error';
	onChangeValue?: (date: Date) => void;
}

function CRDatePicker({
	type = 'M',
	value,
	placeholder = '',
	disabled = false,
	popupPosition = {
		x: 0,
		y: 0,
	},
	minDate,
	maxDate,
	containerStyle = {},
	status = 'default',
	onChangeValue,
}: IProps): React.ReactElement {
	const nodeRef = useRef(null);
	const [date, setDate] = useState<Date | undefined>(undefined);
	const [dateString, setDateString] = useState(value ? dayjs(value).format('YYYY.MM.DD') : '');
	const [result, setResult] = useState<string>('');
	const closeRef = useRef<HTMLButtonElement>(null);

	const filterRef = useRef<HTMLDivElement>(null);
	const datePickerRef = useRef<HTMLDivElement>(null);

	const onSelectDate = (selectedDate: Date) => {
		if (selectedDate) {
			setDate(selectedDate);
			setDateString(selectedDate ? dayjs(selectedDate).format('YYYY.MM.DD') : '');
			const korDay = ['일', '월', '화', '수', '목', '금', '토'];
			const startText = `${dayjs(selectedDate).format('YY.MM.DD')}(${
				korDay[dayjs(selectedDate).get('day')]
			})`;
			setResult(startText);
			onChangeValue?.(selectedDate);
		}
	};

	const onChangeDate = (value: string) => {
		setDateString(value);

		const result = value.replaceAll('.', '');
		const isValid = dayjs(result).isValid() && result[result.length - 1] !== '0';
		if (isValid) {
			setDate(dayjs(result).toDate());
		}
	};

	const onChangeEnter = () => {
		onSelectDate?.(dayjs(dateString).toDate());
		closeRef.current?.click?.();
	};

	const renderCalendarPopup = () => (
		<S.PopupContainer
			ref={nodeRef}
			style={{
				left: popupPosition.x,
				top: popupPosition.y,
			}}>
			<S.CustomHeader>
				<S.Range>
					<CRInput.Default
						type='number'
						maxLength={10}
						onChange={(value) => onChangeDate(value)}
						onPressEnter={onChangeEnter}
						placeholder='예시) 20230922'
						value={dateString}
					/>
				</S.Range>
			</S.CustomHeader>
			<S.CalendarContainer ref={datePickerRef}>
				<DatePicker
					locale={ko}
					selected={date}
					onChange={() => {}}
					minDate={minDate}
					maxDate={maxDate}
					startDate={minDate}
					endDate={maxDate}
					inline
					renderCustomHeader={({ date, increaseMonth, decreaseMonth }) => (
						<>
							<S.CalendarNavigation>
								<S.Month>{dayjs(date).format('YYYY년 M월')}</S.Month>
								<S.NavButtonContainer>
									<S.Button src={Assets.icon.keyboardArrowLeft} onClick={decreaseMonth} />
									<S.Button src={Assets.icon.keyboardArrowRight} onClick={increaseMonth} />
								</S.NavButtonContainer>
							</S.CalendarNavigation>
							<S.CustomCaption>
								{['일', '월', '화', '수', '목', '금', '토'].map((dayText) => (
									<S.CaptionText key={dayText}>{dayText}</S.CaptionText>
								))}
							</S.CustomCaption>
						</>
					)}
					renderDayContents={(e, date) => {
						const isInvalidDate =
							(minDate !== undefined && dayjs(date).isBefore(minDate, 'day')) ||
							(maxDate !== undefined && dayjs(date).isAfter(maxDate, 'day'));

						const isCurrentDate = value && dayjs(date).isSame(value, 'day');

						return (
							<Pop.Close
								ref={closeRef}
								disabled={isInvalidDate}
								style={{
									background: 'transparent',
									border: 0,
									padding: 0,
									margin: 0,
									cursor: 'pointer',
								}}>
								<S.CustomDay
									style={{
										color: isCurrentDate
											? Colors.white
											: isInvalidDate
												? Colors.gray60
												: Colors.gray10,
									}}
									onClick={isInvalidDate ? undefined : () => onSelectDate(date as Date)}>
									{e}
								</S.CustomDay>
							</Pop.Close>
						);
					}}
				/>
			</S.CalendarContainer>
		</S.PopupContainer>
	);

	useLayoutEffect(() => {
		setDate(value);
		setDateString(value ? dayjs(value).format('YYYY.MM.DD') : '');
		setResult(value ? dayjs(value).format('YYYY.MM.DD') : '');
	}, [value]);

	return (
		<S.Container ref={filterRef} $disabled={disabled} style={containerStyle}>
			<RdPopover
				showDivider={false}
				sideOffset={0}
				align='end'
				borderShape='small'
				content={!disabled && renderCalendarPopup()}>
				<S.DefaultOption $error={status === 'error'} $type={type}>
					<S.ChipLabel $disabled={disabled} $isActive={!!result}>
						{result || placeholder}
					</S.ChipLabel>
					<S.CalendarIcon
						$disabled={disabled}
						src={Assets.icon.calendarToday}
						alt='calendarToday'
					/>
				</S.DefaultOption>
			</RdPopover>
		</S.Container>
	);
}

export default CRDatePicker;
