import React, { ChangeEvent, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as Accordion from '@radix-ui/react-accordion';
import dayjs from 'dayjs';

import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import CRFilterGroup from 'components/base/CRInput/CRFilterGroup';
import CRScheduleCalendar from 'components/base/CRScheduleCalendar';
import { CRText } from 'components/base/CRText';
import RecipientAdmissionToHospitalDetailDialog from 'components/domain/dialog/RecipientAdmissionToHospitalDetailDialog';
import RecipientScheduleDetailDialog from 'components/domain/dialog/RecipientScheduleDetailDialog';
import ScheduleSendDetailDialog from 'components/domain/dialog/ScheduleSendDetailDialog';
import InformationLayout from 'components/domain/layout/InformationLayout';
import RecipientListTable from 'components/domain/table/RecipientListTable';
import RecipientDetailBasicInformationTable from 'components/ui/RecipientDetailBasicInformationTable';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import { recipientScheduleAdapter } from 'lib/adapter/recipient';
import { useMyAccountInfo, useSchedule } from 'lib/hook/react-query';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import useDialog from 'lib/hook/util/useDialog';
import { RecipientPendingSchedule } from 'types/api/schedule';
import { Recipient } from 'types/view/recipient';
import { ScheduleItemType } from 'types/view/schedule';

import * as S from './styles';

function RecipientMonthSchedulePage(): React.ReactElement {
	const [date, setDate] = useState(new Date());
	const {
		currentTab,
		isLoading,
		searchText,
		setSearchText,
		recipients,
		currentRecipient,
		setCurrentRecipient,
		currentRecipientInfoSummary,
		currentFilter,
		setCurrentFilter,
		stateFilter,
		serviceFilter,
		managerFilter,
		expiredFilter,
	} = useRecipientPage();
	const { showDialog } = useDialog();
	const { data: myAccountInfo } = useMyAccountInfo();

	const navigate = useNavigate();

	const handleChangeDate = (newDate: Date) => {
		setDate(newDate);
	};

	const { data: scheduleData } = useSchedule(
		{
			type: 'recipient',
			recipientId: currentRecipient?.recipientId,
			centerId: myAccountInfo?.centerId,
			yearMonth: dayjs(date).format('YYYYMM'),
		},
		recipientScheduleAdapter(myAccountInfo),
	);

	const handleClickDetailItem = (item: Recipient) => {
		setCurrentRecipient(item);
		navigate(`${RouterPath.scheduleOperate().monthSchedule.key}/recipient/${item?.recipientId}`);
	};

	const handleChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchText(event.target.value);
	};

	const handleClickDay = (date: Date) => {
		showDialog(() => (
			<RecipientScheduleDetailDialog date={date} currentRecipient={currentRecipient} />
		));
	};

	const LeftContentComponent = useMemo(
		() => (
			<S.Container>
				<S.Header>
					<S.Title>수급자 목록</S.Title>
				</S.Header>
				<CRInput.Search
					placeholder='수급자명, 생년월일, 전화번호 검색'
					value={searchText}
					onChange={handleChangeSearchText}
				/>
				<CRFilterGroup
					filters={[stateFilter, serviceFilter, managerFilter, expiredFilter]}
					currentFilter={currentFilter}
					setCurrentFilter={setCurrentFilter}
				/>
				<S.TableContainer>
					<RecipientListTable
						items={recipients}
						currentItem={currentRecipient}
						onClickRecipient={handleClickDetailItem}
						searchValue={searchText}
					/>
				</S.TableContainer>
				<CRText text={`총 ${recipients?.length}명`} />
			</S.Container>
		),
		[
			currentFilter,
			currentRecipient,
			searchText,
			serviceFilter,
			stateFilter,
			managerFilter,
			currentTab,
			expiredFilter,
		],
	);

	const handleClickPending = (task: ScheduleItemType<RecipientPendingSchedule>) => {
		showDialog(({ hideDialog }) => (
			<RecipientAdmissionToHospitalDetailDialog
				holding={task.data}
				currentRecipient={currentRecipient}
				onClickClose={hideDialog}
			/>
		));
	};

	const handleClickSendSchedule = () => {
		showDialog(() => (
			<ScheduleSendDetailDialog
				type='recipients'
				id={currentRecipient?.recipientId}
				params={{
					targetYm: dayjs(date).format('YYYYMM'),
					centerId: myAccountInfo?.centerId,
				}}
			/>
		));
	};

	const renderSendButton = () => {
		const renderSyncTime = scheduleData?.lastSyncUpdate
			? `${dayjs(scheduleData.lastSyncUpdate).format('YYYY.MM.DD HH:mm')} 동기화`
			: '';
		return (
			<S.SendButtonContainer>
				{renderSyncTime}
				<CRButton.Default
					palette='gray'
					type='outlined'
					size='default'
					onClick={handleClickSendSchedule}>
					발송
				</CRButton.Default>
			</S.SendButtonContainer>
		);
	};

	const RightContentComponent = useMemo(
		() => (
			<S.Container>
				<S.RightTableContainer>
					<Accordion.Root
						type='multiple'
						style={{
							width: '100%',
							marginBottom: 0,
						}}>
						<Accordion.Item value='수급자 정보' asChild>
							<TaskAccordion.Item
								style={{
									marginBottom: 0,
								}}>
								<Accordion.Header asChild>
									<Accordion.Trigger asChild>
										<TaskAccordion.Trigger
											style={{
												padding: 0,
											}}>
											수급자 정보
										</TaskAccordion.Trigger>
									</Accordion.Trigger>
								</Accordion.Header>
								<Accordion.Content asChild>
									<TaskAccordion.Content
										style={{
											borderTop: 'none',
											padding: '0.8rem 0',
										}}>
										<RecipientDetailBasicInformationTable item={currentRecipientInfoSummary} />
									</TaskAccordion.Content>
								</Accordion.Content>
							</TaskAccordion.Item>
						</Accordion.Item>
					</Accordion.Root>
				</S.RightTableContainer>
				<S.RightTabContainer>
					<CRScheduleCalendar
						date={date}
						item={scheduleData}
						renderRightButton={renderSendButton()}
						onChange={handleChangeDate}
						onClickDay={handleClickDay}
						onClickPending={handleClickPending}
					/>
				</S.RightTabContainer>
			</S.Container>
		),
		[date, currentRecipientInfoSummary, scheduleData],
	);

	useLayoutEffect(() => {
		if (
			window.location.pathname === RouterPath.monthScheduleTab().recipient.key &&
			recipients?.length
		) {
			navigate(
				`${RouterPath.scheduleOperate().monthSchedule.key}/recipient/${recipients[0].recipientId}`,
				{
					replace: true,
				},
			);
		}
	}, [currentTab, recipients]);

	if (isLoading) {
		return <div />;
	}

	return (
		<InformationLayout
			LeftContentComponent={LeftContentComponent}
			RightContentComponent={RightContentComponent}
		/>
	);
}

export default RecipientMonthSchedulePage;
