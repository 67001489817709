import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import 'dayjs/locale/ko';

import Assets from 'assets';
import RouterPath from 'common/router';
import CRBanner from 'components/base/CRBanner';
import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { Toast } from 'components/base/CRToast';
import { CheckOption } from 'components/base/Selections/type';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import ReservedMessageCancelDialog from 'components/domain/dialog/ReservedMessageCancelDialog';
import SendTargetDialog from 'components/domain/dialog/SendTargetDialog';
import {
	useCommonCenters,
	useMyAccountInfo,
	useReservationSendDetail,
	useTemplateList,
	useUpdateReservationMessage,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { CommonCenterDTO } from 'types/api/common';
import { SendMessageRequest } from 'types/api/send';
import { SendFormViewType, SendTarget } from 'types/view/send';

import * as S from './styles';

function SendDetailPage(): React.ReactElement {
	const navigate = useNavigate();
	const { watch, control, setValue, reset, handleSubmit } = useForm<SendFormViewType>();
	const params = useParams<{ historyId: string }>();
	const [sendTargets, setSendTargets] = useState<SendTarget[]>([]);
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: commonCenters } = useCommonCenters();

	const { showDialog, hideDialog } = useDialog();
	const { data: templateList } = useTemplateList();
	const { data: reservationSendDetailData, refetch: refetchReservationSendDetailData } =
		useReservationSendDetail({
			historyId: params.historyId,
		});
	const { mutate: updateReservationMessage, isLoading: isUpdatingMessage } =
		useUpdateReservationMessage(async (client, returnData) => {
			if (returnData.code === '200') {
				await client.invalidateQueries({
					predicate: (query) =>
						Boolean(query.queryKey.find((key) => key === endpoint.getReservationSendHistory.key)),
				});
				navigate(RouterPath.sendHistoryTab().reserve.key, {
					replace: true,
				});
				Toast.success('정상적으로 내용을 수정하였습니다.');
			} else {
				Toast.error(returnData.message);
			}
		});

	const centerOptions = useMemo(
		() =>
			(commonCenters ?? []).map(
				(center) =>
					({
						label: center.value,
						value: center.id,
						data: center,
					}) as CheckOption<CommonCenterDTO>,
			),
		[commonCenters],
	);

	const templateListOptions = useMemo(
		() =>
			(templateList || []).map((item) => ({
				label: item.sendTemplateTitle,
				value: item.sendTemplateTypeCd,
				data: item,
			})),
		[templateList],
	);

	const renderSearchUserInfo = (target: SendTarget) => (
		<S.TargetContainer>
			<S.TargetName>{target?.name}</S.TargetName>
			<S.TargetDescription>{`${target?.birthDate}${
				target.description ? ` ・ ${target.description}` : ''
			}`}</S.TargetDescription>
		</S.TargetContainer>
	);

	const onSubmit = (data: SendFormViewType) => {
		if (isUpdatingMessage) return;
		if (!reservationSendDetailData?.sendId || !myAccountInfo?.centerId) return;
		const recipients =
			sendTargets?.filter((item) => item?.type === 'recipient').map((item) => item?.id) ?? [];
		const employees =
			sendTargets?.filter((item) => item?.type === 'employee').map((item) => item?.id) ?? [];
		const params: SendMessageRequest & { [key: string]: any } = {
			isSchedule: true,
			sendTemplateId: data.templateType.data?.sendTemplateId,
			centerId: myAccountInfo?.centerId,
			targetYm: dayjs(data.targetYm).format('YYYYMM'),
			messageTitleNm: data.messageTitleNm,
			messageDesc: data.messageDesc,
			receivers: {
				employees,
				recipients,
			},
			sendReservationDate: `${dayjs(data.reservedDate).format('YYYY-MM-DD')} ${
				data.reservedTime
			}:00`,
		};

		if (
			data.templateType.data?.sendTemplateId === 34 &&
			data.transferCenterMangerNm &&
			data?.transferCenterIdAndNm?.data?.id &&
			data?.transferCenterIdAndNm?.data?.value
		) {
			params.sendTransferInfo = {
				transferCenterIdAndNm: {
					id: Number(data.transferCenterIdAndNm.data?.id),
					name: data.transferCenterIdAndNm.data?.value,
				},
				transferCenterMangerNm: data.transferCenterMangerNm,
			};
		}

		updateReservationMessage({ sendId: reservationSendDetailData.sendId, params });
	};

	const handleApply = (targets: SendTarget[]) => {
		setSendTargets(targets);
		hideDialog();
	};

	const showSelectModal = () => {
		showDialog(() => (
			<SendTargetDialog
				sendTargets={sendTargets}
				onApply={handleApply}
				targetInfo={{
					templateId: String(watch('templateType').data?.sendTemplateId),
					targetYm: dayjs(watch('targetYm')).format('YYYYMM'),
				}}
			/>
		));
	};

	const handleDeleteTarget = (target: SendTarget) => () => {
		const newSendTargets =
			sendTargets?.filter((item) => !(item.id === target.id && item.type === target.type)) ?? [];
		setSendTargets(newSendTargets);
	};

	const handleClickMessageCancel = () => {
		const matchedTemplate = templateList?.find(
			(template) => template.sendTemplateId === reservationSendDetailData?.sendTemplateId,
		);
		showDialog(({ hideDialog }) => (
			<ReservedMessageCancelDialog
				sendId={reservationSendDetailData?.sendId}
				cancelInfo={{
					messageTitleName: reservationSendDetailData?.messageTitleNm,
					sendReservationDate: reservationSendDetailData?.sendReservationDate,
					templateName: matchedTemplate?.sendTemplateTitle,
				}}
				onSuccess={() => {
					refetchReservationSendDetailData();
					hideDialog();
				}}
			/>
		));
	};

	const handleTemplateType = (callback: any) => {
		showDialog(() => (
			<DefaultDialog
				title='문서 유형을 변경하십니까?'
				content={
					<>
						수신자가 선택된 상태에서 문서 유형을 변경할 경우 수신자의
						<br />
						리스트는 초기화 됩니다. 변경을 진행 하시겠습니까?
					</>
				}
				cancelOption={{
					text: '취소',
					callback: hideDialog,
				}}
				successOption={{
					text: '변경',
					successCallback() {
						setSendTargets([]);
						setValue('messageDesc', '');
						setValue('messageTitleNm', '');
						callback();
						hideDialog();
					},
				}}
				hideDialog={hideDialog}
			/>
		));
	};

	const isValid = useMemo(() => {
		if (watch('templateType')) {
			if (watch('templateType')?.data?.sendTemplateTypeCd === 'CMN009.999') {
				if (
					!watch('targetYm') &&
					watch('reservedTime') &&
					watch('reservedTime')?.length === 5 &&
					sendTargets?.length
				) {
					return true;
				}
			} else {
				// eslint-disable-next-line
				if (watch('templateType')?.data?.sendTemplateId === 34) {
					if (
						watch('transferCenterIdAndNm') &&
						watch('transferCenterMangerNm') &&
						watch('targetYm') &&
						watch('reservedTime') &&
						watch('reservedTime')?.length === 5 &&
						sendTargets?.length
					) {
						return true;
					}
				} else {
					// eslint-disable-next-line
					if (
						watch('targetYm') &&
						watch('reservedTime') &&
						watch('reservedTime')?.length === 5 &&
						sendTargets?.length
					) {
						return true;
					}
				}
			}
		}
		return false;
	}, [watch(), sendTargets]);

	useEffect(() => {
		if (!reservationSendDetailData || !templateList?.length) return;
		const matchedTemplate = templateList?.find(
			(template) => template.sendTemplateId === reservationSendDetailData?.sendTemplateId,
		);

		const sendTarget: SendTarget[] =
			reservationSendDetailData?.receivers.map((item) => ({
				id: item.receiverId,
				type: item?.receiverTargetDivCd === 'CMN118.20' ? 'recipient' : 'employee',
				name: item.receiverNm,
				birthDate: item.birthDt,
				description:
					item?.receiverTargetDivCd === 'CMN118.20'
						? item.mainGuardianNm || ''
						: item.receiverPhoneNo || '',
			})) ?? [];

		const formData = {
			reservedDate: dayjs(reservationSendDetailData.sendReservationDate).toDate(),
			reservedTime: dayjs(reservationSendDetailData.sendReservationDate).format('HH:mm'),
			templateType: {
				label: matchedTemplate?.sendTemplateTitle,
				value: matchedTemplate?.sendTemplateTypeCd,
				data: matchedTemplate,
			},
			targetYm: dayjs(reservationSendDetailData.targetYm).toDate(),
			messageTitleNm: reservationSendDetailData.messageTitleNm,
			messageDesc: reservationSendDetailData.messageDesc,
		};

		reset(formData);
		setSendTargets(sendTarget);
	}, [reservationSendDetailData, templateList]);

	useEffect(() => {
		// 문서유형 기타 선택시 기존 제목, 내용 초기화
		if (watch('templateType')?.data?.sendTemplateTypeCd !== 'CMN009.999') {
			const targetYm = watch('targetYm') ? dayjs(watch('targetYm')).format('YYYY년 M월') : '';
			const titleName = watch('templateType')?.data?.messageTitleNm || '';
			const replacedTitleName = titleName.replace(/{targetYm}/g, targetYm);
			const documentTitle = targetYm ? replacedTitleName : titleName;

			setValue('messageTitleNm', documentTitle);
			setValue('messageDesc', watch('templateType')?.data?.messageDesc || '');
		} else {
			setValue('targetYm', undefined);
		}
	}, [watch('templateType'), watch('targetYm')]);

	return (
		<S.Container onSubmit={handleSubmit(onSubmit)}>
			{reservationSendDetailData?.cancelYn && (
				<S.BannerContainer>
					<CRBanner
						type='error'
						title={`취소 사유: ${reservationSendDetailData.cancelDesc} (${reservationSendDetailData?.cancelDate} 취소)`}
					/>
				</S.BannerContainer>
			)}
			<S.Title>메시지 예약 발송 상세</S.Title>
			<S.ContentsContainer>
				<S.WriteFormSection>
					<S.FormSection>
						<S.FormLeftSection $isCancel={!!reservationSendDetailData?.cancelYn}>
							<CRInputLabel label='예약 발송' isRequired>
								<Controller
									render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
										<CRInput.DatePicker
											disabled={reservationSendDetailData?.cancelYn}
											minDate={new Date()}
											placeholder='예약 날짜 선택'
											onChangeValue={onChange}
											value={value}
										/>
									)}
									control={control}
									name='reservedDate'
								/>
								<Controller
									render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
										<>
											<CRInput.Time
												disabled={reservationSendDetailData?.cancelYn}
												minTime='09:00'
												maxTime='18:00'
												maxLength={5}
												placeholder='시간 입력'
												value={value}
												onChange={onChange}
											/>
											<S.InputDescription>09:00~18:00까지만 입력할 수 있습니다.</S.InputDescription>
										</>
									)}
									control={control}
									name='reservedTime'
								/>
							</CRInputLabel>
							<Controller
								render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
									<CRInputLabel label='문서유형' isRequired>
										<CRInput.Selector
											disabled={reservationSendDetailData?.cancelYn}
											placeholder='문서 유형 선택'
											currentValue={value}
											onChangeValue={(option) => {
												if (sendTargets?.length && watch('templateType')) {
													handleTemplateType(() => onChange(option));
												} else {
													onChange(option);
													setValue('messageDesc', '');
													setValue('messageTitleNm', '');
												}
											}}
											items={templateListOptions}
										/>
									</CRInputLabel>
								)}
								name='templateType'
								control={control}
							/>
							{!!watch('templateType') && watch('templateType').value !== 'CMN009.999' && (
								<Controller
									render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
										<CRInput.YearMonthPicker
											disabled={reservationSendDetailData?.cancelYn}
											currentValue={value}
											placeholder='년월 선택'
											onChangeValue={(option) => {
												if (sendTargets?.length && watch('templateType')) {
													handleTemplateType(() => onChange(option));
												} else {
													onChange(option);
												}
											}}
										/>
									)}
									control={control}
									name='targetYm'
								/>
							)}
							{watch('templateType') && watch('templateType')?.data?.sendTemplateId === 34 && (
								<>
									<Controller
										render={({
											field: { onChange, onBlur, value, ref },
											formState: { errors },
										}) => (
											<CRInputLabel label='이관 센터' isRequired showOverflow>
												<CRInput.Selector
													autoComplete
													disabled={watch('templateType')?.data?.sendTemplateId !== 34}
													placeholder='센터 선택'
													currentValue={value}
													onChangeValue={onChange}
													items={centerOptions}
												/>
											</CRInputLabel>
										)}
										name='transferCenterIdAndNm'
										control={control}
									/>
									<Controller
										render={({
											field: { onChange, onBlur, value, ref },
											formState: { errors },
										}) => (
											<CRInputLabel
												label='이관 센터 담당자'
												isRequired
												renderRightAddon={`${value?.length ?? 0}/10`}>
												<CRInput.Default
													formatter={(s) => {
														const onlyKorEngText = s.target.value?.replace(
															/[^a-zA-Z가-힣ㄱ-ㅎㅏ-ㅣ\s]/g,
															'',
														);
														return onlyKorEngText;
													}}
													disabled={watch('templateType')?.data?.sendTemplateId !== 34}
													placeholder='이름 입력'
													value={value}
													onChange={onChange}
													maxLength={10}
												/>
											</CRInputLabel>
										)}
										name='transferCenterMangerNm'
										control={control}
									/>
								</>
							)}
							<Controller
								render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
									<CRInputLabel
										label='제목'
										isRequired
										renderRightAddon={`${value?.length ?? 0}/32`}>
										<CRInput.Default
											disabled={
												!(watch('templateType')?.value === 'CMN009.999') ||
												reservationSendDetailData?.cancelYn
											}
											placeholder='제목 입력'
											value={value}
											onChange={onChange}
											maxLength={32}
										/>
									</CRInputLabel>
								)}
								name='messageTitleNm'
								control={control}
							/>
							<Controller
								render={({ field: { onChange, value, ref }, formState: { errors } }) => (
									<CRInputLabel
										label='내용'
										isRequired
										renderRightAddon={`${value?.length ?? 0}/1,000`}>
										<CRInput.TextArea
											disabled={
												!(watch('templateType')?.value === 'CMN009.999') ||
												reservationSendDetailData?.cancelYn
											}
											numberOfLines={watch('templateType')?.value === 'CMN009.999' ? 20.35 : 18}
											placeholder='내용 입력'
											maxLength={1000}
											value={value}
											onChange={onChange}
										/>
									</CRInputLabel>
								)}
								name='messageDesc'
								control={control}
							/>
						</S.FormLeftSection>
						<S.FormRightSection>
							<S.FormItem>
								<CRInputLabel label='수신자' isRequired>
									<CRInput.Search
										placeholder='수신자 선택'
										onClick={showSelectModal}
										disabled={reservationSendDetailData?.cancelYn}
									/>
								</CRInputLabel>
							</S.FormItem>
							<S.UserSelector>
								<S.UserSelectInfo>
									<span>선택</span>
									<span>총 {sendTargets?.length}명</span>
								</S.UserSelectInfo>
								<S.UserList>
									{sendTargets?.map((item) => (
										<S.UserListItem
											key={item.id}
											$isSelected={false}
											$isDisabled={!!reservationSendDetailData?.cancelYn}>
											{renderSearchUserInfo(item)}
											<S.DeleteIcon src={Assets.icon.close} onClick={handleDeleteTarget(item)} />
										</S.UserListItem>
									))}
								</S.UserList>
							</S.UserSelector>
						</S.FormRightSection>
					</S.FormSection>
				</S.WriteFormSection>
			</S.ContentsContainer>
			<S.BottomSection>
				<S.BottomMenuContainer>
					<S.BottomLeftMenuContainer>
						{!reservationSendDetailData?.cancelYn && (
							<CRButton.Default
								type='outlined'
								palette='primary'
								onClick={handleClickMessageCancel}>
								발송 취소
							</CRButton.Default>
						)}
					</S.BottomLeftMenuContainer>
					<S.BottomRightMenuContainer>
						<CRButton.Default type='text' palette='gray' onClick={() => navigate(-1)}>
							취소
						</CRButton.Default>
						{!reservationSendDetailData?.cancelYn ? (
							<CRButton.Default disabled={!isValid} buttonType='submit'>
								수정
							</CRButton.Default>
						) : (
							<CRButton.Default onClick={() => navigate(-1)}>확인</CRButton.Default>
						)}
					</S.BottomRightMenuContainer>
				</S.BottomMenuContainer>
			</S.BottomSection>
		</S.Container>
	);
}

export default SendDetailPage;
