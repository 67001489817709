import React from 'react';

import Assets from 'assets';
import CRIcon from 'components/base/CRIcon';
import { CRText } from 'components/base/CRText';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';

import { CRTableHeadConfig } from '../../../../types/view/base';
import * as S from './styles';

const commonConfig = {
	height: '3.3rem',
};

export const STAFF_WORK_LIST_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '5.8rem',
		label: '구분',
		sortKey: '구분',
		...commonConfig,
	},
	{
		width: '9.6rem',
		label: '직원명',
		sortKey: '직원명',
		...commonConfig,
	},
	{
		width: '9.6rem',
		label: '직무',
		sortKey: '직무',
		...commonConfig,
	},
	{
		width: '6.6rem',
		label: (
			<S.TableHeaderLabelContainer>
				<CRText typography='label' color='gray60'>
					공단
				</CRText>
				<RDTooltip content='공단의 월 기준 근무시간'>
					<CRIcon src={Assets.icon.help} width='1.6rem' height='1.6rem' />
				</RDTooltip>
			</S.TableHeaderLabelContainer>
		),
		sortKey: '공단',
		...commonConfig,
	},
	{
		width: '6.6rem',
		label: (
			<S.TableHeaderLabelContainer>
				<CRText typography='label' color='gray60'>
					근무
				</CRText>
				<RDTooltip content={`근무인정시간의 합계\n(연장근무 포함, 휴게시간 제외)`}>
					<CRIcon src={Assets.icon.help} width='1.6rem' height='1.6rem' />
				</RDTooltip>
			</S.TableHeaderLabelContainer>
		),
		sortKey: '근무',
		...commonConfig,
	},
	{
		width: '10rem',
		label: (
			<S.TableHeaderLabelContainer>
				<CRText typography='label' color='gray60'>
					연장
				</CRText>
				<RDTooltip content='총 연장근무시간'>
					<CRIcon src={Assets.icon.help} width='1.6rem' height='1.6rem' />
				</RDTooltip>
			</S.TableHeaderLabelContainer>
		),
		sortKey: '연장',
		...commonConfig,
	},
];
