import styled, { css } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	box-sizing: border-box;
	gap: 2.4rem;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
`;

export const Label = styled.div`
	${(props) => props.theme.typography.labelB};
	color: ${(props) => props.theme.colors.gray00};
	display: flex;
	justify-content: space-between;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const Table = styled.table<{ $isDisabled?: boolean }>`
	width: 100%;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};

	${(props) =>
		props.$isDisabled &&
		css`
			background: ${(props) => props.theme.colors.gray99};
			color: ${(props) => props.theme.colors.gray60};
		`};
`;

export const TableRow = styled.tr`
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};

	&:last-child {
		border-bottom: 0;
	}
`;

export const TableLabelColumn = styled.td`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
	height: 5.4rem;
	padding: 0.8rem;
	box-sizing: border-box;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	background: ${(props) => props.theme.colors.gray99};
	vertical-align: middle;
`;

export const TableValueColumn = styled.td`
	${(props) => props.theme.typography.label};
	position: relative;
	box-sizing: border-box;
	height: 5.4rem;
	padding: 0.8rem;
	box-sizing: border-box;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	vertical-align: middle;
`;

export const RightButtonContainer = styled.div`
	position: absolute;
	right: 1.2rem;
	top: 50%;
	transform: translateY(-50%);
`;

export const ItemHeader = styled.div`
	${(props) => props.theme.typography.label}
	display: flex;
	height: 2.6rem;
	flex-direction: row;
	align-items: center;
	gap: 0.8rem;
`;

export const DeleteButtonContainer = styled.span`
	flex: 1;
`;

export const AddButtonContainer = styled.div``;

export const TimePickerContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: calc(50% - 10.4rem);
	gap: 0.8rem;
`;

export const ServiceMaxContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.8rem;
`;

export const ServiceMaxItemContainer = styled.div`
	flex: 1;
`;

export const PeriodContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const PeriodItemContainer = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
`;

export const WelfareContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: flex-start;
	flex-direction: row;
	gap: 0.4rem;
	padding: 0.8rem;
	box-sizing: border-box;
	flex-wrap: wrap;
`;

export const TagContainer = styled.div<{ $selected?: boolean; $disabled?: boolean }>`
	height: 3.2rem;
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray10};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.2rem 1.2rem;
	border-radius: 0.8rem;
	box-sizing: border-box;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	white-space: nowrap;

	${(props) =>
		props.$disabled &&
		css`
			border: 0.1rem solid ${props.theme.colors.primary90};
			color: ${(props) => props.theme.colors.gray60};
			background: ${props.theme.colors.gray95};
		`}

	${(props) =>
		props.$selected &&
		css`
			border: 0.1rem solid ${props.theme.colors.primary90};
			color: ${(props) => props.theme.colors.primary10};
			background: ${props.theme.colors.primary95};
		`}
`;

export const LongTermNoContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.4rem;
`;

export const LongTermNo = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 3;
`;

export const LongTermNoEtc = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 2;
	gap: 0.4rem;
`;

export const LongTermNoButton = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	gap: 0.4rem;
`;
