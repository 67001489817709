import React, { useState } from 'react';

import dayjs from 'dayjs';

import CRScheduleCalendar from 'components/base/CRScheduleCalendar';
import RecipientScheduleDetailDialog from 'components/domain/dialog/RecipientScheduleDetailDialog';
import { recipientScheduleAdapter } from 'lib/adapter/recipient';
import { useMyAccountInfo, useSchedule } from 'lib/hook/react-query';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import useDialog from 'lib/hook/util/useDialog';

import RecipientAdmissionToHospitalDetailDialog from '../../../../components/domain/dialog/RecipientAdmissionToHospitalDetailDialog';
import { RecipientPendingSchedule } from '../../../../types/api/schedule';
import { ScheduleItemType } from '../../../../types/view/schedule';
import * as S from './styles';

export default function RecipientScheduleTab(): React.ReactElement {
	const [date, setDate] = useState(new Date());
	const { currentRecipient } = useRecipientPage();
	const { showDialog } = useDialog();
	const { data: myAccountInfo } = useMyAccountInfo();

	const { data: scheduleData } = useSchedule(
		{
			type: 'recipient',
			recipientId: currentRecipient?.recipientId,
			centerId: myAccountInfo?.centerId,
			yearMonth: dayjs(date).format('YYYYMM'),
		},
		recipientScheduleAdapter(myAccountInfo),
	);

	const handleChangeDate = (newDate: Date) => {
		setDate(newDate);
	};

	const handleClickDay = (date: Date) => {
		showDialog(() => (
			<RecipientScheduleDetailDialog date={date} currentRecipient={currentRecipient} />
		));
	};
	const handleClickPending = (task: ScheduleItemType<RecipientPendingSchedule>) => {
		showDialog(({ hideDialog }) => (
			<RecipientAdmissionToHospitalDetailDialog
				holding={task.data}
				currentRecipient={currentRecipient}
				onClickClose={hideDialog}
			/>
		));
	};

	return (
		<S.CalendarContainer>
			<CRScheduleCalendar
				date={date}
				item={scheduleData}
				onChange={handleChangeDate}
				onClickDay={handleClickDay}
				onClickPending={handleClickPending}
				type='recipient'
			/>
		</S.CalendarContainer>
	);
}
