import React from 'react';

import useCenterInfo from 'lib/hook/view/centerInformation/useCenterInfo';

import MyCenterBasicInformationForm from './MyCenterBasicInformationForm';
import MyCenterBasicInformationView from './MyCenterBasicInformationView';

function MyCenterBasicInformationFormView(): React.ReactElement {
	const { isEdit } = useCenterInfo();

	return isEdit ? <MyCenterBasicInformationForm /> : <MyCenterBasicInformationView />;
}

export default MyCenterBasicInformationFormView;
