import { useState } from 'react';

import axios from 'axios';

import { KakaoGetAddressByCoordinateRequestParam } from 'types/api';

export const useKaKaoMap = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const getAddress = async (params: KakaoGetAddressByCoordinateRequestParam) => {
		if (!params.x || !params.y) {
			console.error('위도와 경도를 올바르게 입력하세요.');
			return;
		}

		setLoading(true);
		setError(null);

		try {
			const response = await axios.get(`https://dapi.kakao.com/v2/local/geo/coord2address.json`, {
				headers: {
					Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_MAP_API_KEY}`,
					KA: 'sdk/1.0 os/javascript lang/en',
					'Content-Type': 'application/json;charset=UTF-8',
				},
				params: { ...params },
			});

			const result = response.data.documents[0];
			// eslint-disable-next-line consistent-return
			return result;
		} catch (err) {
			console.error(err);
			// eslint-disable-next-line consistent-return
			return null;
		} finally {
			setLoading(false);
		}
	};

	return { loading, error, getAddress };
};
