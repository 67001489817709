import React, { useCallback, useMemo, useRef, useState } from 'react';

import { VirtualItem, useVirtualizer } from '@tanstack/react-virtual';
import dayjs from 'dayjs';
import { v4 } from 'uuid';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRSpinner from 'components/base/CRSpinner';
import CRTable from 'components/base/CRTable';
import { Toast } from 'components/base/CRToast';
import { EDocFormConfirmDialog } from 'components/domain/form/EDocFormConfirmDialog';
import InformationTable from 'components/ui/InformationTable';
import {
	InformationLabelValueType,
	InformationTableItemType,
} from 'components/ui/InformationTable/type';
import { displayPhoneNumber } from 'lib';
import {
	useESignHistoryDetail,
	useMyAccountInfo,
	useOpenEDocManager,
	useResendEDoc,
	useUpdateSignDueDate,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { ResponseCode } from 'types/api';
import { EDocProgressHistResponse, Esign } from 'types/api/send';
import { EDocTemplateType, LastESignStateCd } from 'types/view/eDoc';

import DefaultDialog from '../DefaultDialog';
import EDocESignIdConfirmDialog from '../EDocConfirmDialog';
import ESignCancelDialog from '../ESignCancelDialog';
import { ESignWayCode } from '../ESignDialog';
import EDocNoDialog from '../EdocNoDialog';
import { SEND_HISTORY_DETAIL_TABLE_HEADER_CONFIG } from './constants';
import * as S from './styles';

interface Props {
	edocSendId: number;
	centerId?: number;
	navigate?: (path: string, state?: any) => void;
}

export function ESignSendHistoryDetailDialog({
	centerId,
	edocSendId,
	navigate,
}: Props): React.ReactElement {
	const [date, setDate] = useState<Date>();
	const [isEdit, setIsEdit] = useState(false);
	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const { showDialog, hideDialog } = useDialog();
	const { data: myAccountInfo } = useMyAccountInfo();
	const [, setCurrentHistory] = useState<EDocProgressHistResponse>();
	const {
		data: eSignHistoryDetail,
		isLoading: eSignHistoryDetailLoading,
		refetch: eSignHistoryRefetch,
	} = useESignHistoryDetail({
		centerId,
		edocSendId,
	});

	const { mutateAsync: openEdocManager } = useOpenEDocManager();

	const { mutate: resendEDoc } = useResendEDoc((_, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			Toast.success('정상적으로 발송 요청을 하였습니다.');
		} else {
			Toast.error('발송 요청을 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
		hideDialog();
	});
	const { mutate: updateSignDueDate } = useUpdateSignDueDate((client, returnData) => {
		if (returnData) {
			Toast.success('정상적으로 서명 기한 수정을 완료 하였습니다.');
			client.invalidateQueries([endpoint.getESignHistoryDetail.key, { centerId, edocSendId }]);
			setIsEdit(false);
		} else {
			Toast.error('서명 기한 수정에 실패했습니다. 잠시후 다시 시도해주세요.');
			setIsEdit(false);
		}
	});

	const handleUpdateDueDate = () => {
		updateSignDueDate({
			edocSendId,
			centerId,
			signDueDate: dayjs(date).format('YYYYMMDD'),
		});
	};

	const SendStatusBadge = useCallback(
		(index: number) =>
			// eslint-disable-next-line
			function (value: string) {
				const redColorTexts = ['만료', '기간 만료', '취소', '서명 취소'];
				const color = redColorTexts.includes(value) ? 'red' : 'green';
				return (
					<S.SignStatusBadge $isActive={index === 0} $color={color}>
						<S.Badge />
						{value}
					</S.SignStatusBadge>
				);
			},
		[],
	);

	const renderOption = () => (
		<S.ButtonContainer>
			<CRButton.Default size='xSmall' type='outlined' palette='gray'>
				미리보기
			</CRButton.Default>
			<CRButton.Default size='xSmall' type='outlined' palette='gray'>
				발송
			</CRButton.Default>
		</S.ButtonContainer>
	);

	const handleClickHistory = (item: EDocProgressHistResponse) => () => {
		setCurrentHistory(item);
	};

	const handleClickSignCancel = () => {
		showDialog(() => (
			<ESignCancelDialog
				defaultData={{
					edocNm: eSignHistoryDetail?.edocPreviewResponse.edocNm || '',
					edocTitle: eSignHistoryDetail?.edocTitle || '',
					signDueDate: eSignHistoryDetail?.signDueDate || '',
				}}
				edocSendId={edocSendId}
				centerId={centerId}
			/>
		));
	};

	const rowRenderer = (virtualRow: VirtualItem, index: number) => {
		const item = eSignHistoryDetail?.edocProgressHistResponse[virtualRow.index];
		if (!item) return null;

		return (
			<CRTable.Row
				// isFocused={currentHistory?.id === item.id}
				onClick={handleClickHistory(item)}
				item={{ ...item, id: v4() }}
				key={index}
				style={{
					height: `${virtualRow.size}px`,
					maxHeight: `${virtualRow.size}px`,
					transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
				}}
				customRender={{
					esignStateNm: SendStatusBadge(index),
					id: renderOption,
				}}
				renderKeys={['esignStateNm', 'actionDateTime', 'edocProgressDesc']}
			/>
		);
	};

	const rowVirtualizer = useVirtualizer({
		count: eSignHistoryDetail?.edocProgressHistResponse.length || 0,
		getScrollElement: () => scrollContainerRef.current,
		estimateSize: () => 5,
		overscan: 5,
	});

	const previewItem = useMemo(() => {
		const eDocSendData = eSignHistoryDetail?.edocPreviewResponse.data?.edocSend;
		const dueDateText = eDocSendData?.signDueDate
			? dayjs(eDocSendData.signDueDate).format('YYYY.MM.DD HH:mm')
			: '-';
		const dudeDate = isEdit ? (
			<CRInput.DatePicker value={date} type='S' onChangeValue={setDate} />
		) : (
			dueDateText
		);

		const items: InformationLabelValueType[][] = [
			[
				{
					label: '문서 유형',
					value: eDocSendData?.edocTemplate.edocNm || '-',
				},
				{
					label: '제목',
					value: eDocSendData?.edocTitle || '-',
				},
				{
					label: '문서번호',
					value: eDocSendData?.edocUuid || '-',
				},
			],
			[
				{
					label: '서명 방식',
					value:
						eDocSendData?.esignWayCd &&
						[ESignWayCode.비대면서명, ESignWayCode.대면서명].includes(
							eDocSendData.esignWayCd as ESignWayCode,
						)
							? `${eDocSendData.esignWayCd === ESignWayCode.비대면서명 ? '비대면' : '대면'} 서명`
							: '-',
				},
			].concat(
				eDocSendData?.esignWayCd === ESignWayCode.대면서명
					? [
							{
								label: '수신자',
								value: eDocSendData?.facingSignReceiverNm ?? '-',
							},
							{
								label: '수신 연락처',
								value: eDocSendData?.facingSignReceiverPhoneNo
									? displayPhoneNumber(eDocSendData.facingSignReceiverPhoneNo)
									: '-',
							},
						]
					: [
							{
								type: 'labelValueNull',
								colSpan: 4,
								style: {
									width: '46.4rem',
								},
							} as any,
						],
			),
			[
				{
					label: '서명 기한',
					value: dudeDate,
				},
				{
					label: '서명 진행 상태',
					value: eSignHistoryDetail?.lastEsignStateNm || '-',
				},
				{
					label: '발송자',
					value: eDocSendData?.edocSenderNm || '-',
				},
			],
		];

		if (eDocSendData?.lastEsignStateCd === LastESignStateCd.서명취소) {
			items.push([
				{
					label: '취소한 서명자',
					value: eSignHistoryDetail?.edocCancelerNm || '-',
				},
				{
					label: '서명 취소 사유',
					value: eSignHistoryDetail?.edocCancelDesc || '-',
				},
				{
					label: '서명 취소 일시',
					value: eSignHistoryDetail?.edocCancelDate
						? dayjs(eSignHistoryDetail.edocCancelDate).format('YYYY.MM.DD HH:mm')
						: '-',
				},
			]);
		}

		const indexWidth = ['17.2rem', '26.8rem', '13.4rem'];
		setDate(dayjs(eSignHistoryDetail?.signDueDate).toDate());
		return items.map((item) =>
			item.map((item2, index) => ({
				...item2,
				value: (
					<S.TableValue
						title={typeof item2?.value === 'string' ? item2?.value : undefined}
						style={{
							width: indexWidth[index],
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							height: '3.3rem',
							lineHeight: '3.3rem',
						}}>
						{item2.value}
					</S.TableValue>
				),
				labelStyle: {
					minWidth: '10rem',
					verticalAlign: 'middle',
					padding: '0 0.8rem',
					boxSizing: 'border-box',
				},
				valueStyle: {
					verticalAlign: 'middle',
					padding: '0 0.8rem',
				},
			})),
		);
	}, [eSignHistoryDetail, isEdit]);

	const handleChangeEdit = () => {
		setIsEdit((prev) => !prev);
	};

	const handleClickReSendESign = (item?: Esign) => () => {
		if (!item) return;
		showDialog(() => (
			<DefaultDialog
				title='발송 요청 하시겠습니까?'
				// content=''
				hideDialog={hideDialog}
				content={`${item?.signatoryNm}님에게 발송할 수 있도록 요청합니다.`}
				cancelOption={{
					text: '취소',
					callback: hideDialog,
				}}
				successOption={{
					text: '발송 요청',
					successCallback: () => {
						resendEDoc({
							esignId: item.esignId,
							centerId,
						});
					},
				}}
			/>
		));
	};

	const handleClickConfirm = (item: Esign) => async () => {
		const eDocSendData = eSignHistoryDetail?.edocPreviewResponse.data?.edocSend;
		if (
			[
				EDocTemplateType.방문간호기록지,
				EDocTemplateType.방문목욕기록지,
				EDocTemplateType.방문요양기록지,
				EDocTemplateType.방문목욕소독및관찰일지,
			].includes(item.paperTypeCd as EDocTemplateType)
		) {
			const left = (window.screen.width - 500) / 2;
			const top = (window.screen.height - 800) / 2;

			const popup = window.open(
				`${window.location.origin}/esign/${item.sendUuid}?isErp=true`,
				'문서 확인',
				`width=500, height=800, toolbar=0, location=0, directories=0, status=0, menubar=0, scrollbars=1, resizable=1, left=${left}, top=${top}`,
			);

			const timer = setInterval(() => {
				if (popup && popup.closed) {
					clearInterval(timer);
					eSignHistoryRefetch();
				}
			}, 500);
		} else if (item.paperTypeCd === EDocTemplateType.입소이용신청서) {
			if (eDocSendData?.edocNo && myAccountInfo?.centerId) {
				const authParams = { birthDt: '', sendUuid: item.sendUuid, isEsign: true };

				const res = await openEdocManager(authParams);
				if (res?.code === ResponseCode.SUCCESS) {
					showDialog(() => (
						<EDocFormConfirmDialog
							esign={item}
							menuType='recipient'
							title='입소이용신청서 검수'
							eDocTemplateCd={EDocTemplateType.입소이용신청서}
							edocNo={eDocSendData?.edocNo}
						/>
					));
				} else {
					Toast.error('전자문서 조회에 실패했습니다.');
				}
			}
		} else if (item.paperTypeCd === EDocTemplateType.업무수행일지) {
			navigate?.(`work-execute-inspection-log/${item.sendUuid}`);
		} else if (centerId && item.sendUuid) {
			showDialog(() => (
				<EDocESignIdConfirmDialog esignId={item.esignId} onSuccess={() => eSignHistoryRefetch()} />
			));
		}
	};

	const signStatusItem = useMemo(() => {
		if (!eSignHistoryDetail?.esigns?.length) {
			return [
				[
					{
						label: '',
						value: '',
					},
					{
						label: '',
						value: '',
					},
				],
			];
		}
		const items: InformationTableItemType[][] = Array.from({
			length: Math.ceil((eSignHistoryDetail?.esigns.length || 2) / 2),
		}).map(() => []);
		const renderESignStateText = (esignStateText: string) => {
			switch (esignStateText) {
				case '확인 필요': {
					return '검수 필요';
				}
				case '확인 완료': {
					return '검수 완료';
				}
				default: {
					return esignStateText || '';
				}
			}
		};
		eSignHistoryDetail?.esigns.forEach((item) => {
			const hasEmptySpaceData = items.find((item) => item && item.length < 2);
			hasEmptySpaceData?.push({
				label: item.signatoryNm,
				value: (
					<S.ButtonContainer>
						{renderESignStateText(item.esignStateText)}
						{item.esignDate && (
							<S.SignDateText>({dayjs(item.esignDate).format('YYYY.MM.DD HH:mm')})</S.SignDateText>
						)}
						{item.esignStateText === '확인 필요' && (
							<CRButton.Default
								type='outlined'
								palette='gray'
								size='xSmall'
								onClick={handleClickConfirm(item)}>
								검수
							</CRButton.Default>
						)}
						{['작성 필요', '서명 필요'].includes(item.esignStateText) && (
							<CRButton.Default
								type='outlined'
								palette='gray'
								size='xSmall'
								onClick={handleClickReSendESign(item)}>
								발송
							</CRButton.Default>
						)}
					</S.ButtonContainer>
				),
				labelStyle: {
					minWidth: '21.3rem',
					verticalAlign: 'middle',
					padding: '0 0.8rem',
					boxSizing: 'border-box',
				},
				valueStyle: {
					height: '3.3rem',
					width: '25.1rem',
					verticalAlign: 'middle',
					padding: '0 0.8rem',
					boxSizing: 'border-box',
				},
			});
		});
		if (!items[items.length - 1][1]) {
			items[items.length - 1].push({
				type: 'labelValueNull',
				colSpan: 2,
				style: {
					width: '46.4rem',
				},
			});
		}

		return items;
	}, [eSignHistoryDetail]);

	const handleClickEDocPreview = () => {
		if (eSignHistoryDetail?.edocPreviewResponse.data.edocParam.edocParamValue) {
			const { crfeNm, params } = JSON.parse(
				eSignHistoryDetail.edocPreviewResponse.data.edocParam.edocParamValue,
			);

			showDialog(() => (
				<EDocNoDialog
					dialogType='M'
					eDocParamValue={{
						crfeNm,
						params,
					}}
				/>
			));
		}
	};

	return (
		<CRDialog
			onClickClose={hideDialog}
			type='L'
			title='발송 이력 상세'
			height='65rem'
			body={
				eSignHistoryDetailLoading ? (
					<CRSpinner />
				) : (
					<S.Container>
						<S.LeftSection>
							<S.SectionContainer>
								<S.SectionTitle>
									발송 기본 정보
									{eSignHistoryDetail?.lastEsignStateNm &&
									['검수 대기', '서명 대기', '기간 만료'].includes(
										eSignHistoryDetail?.lastEsignStateNm,
									) ? (
										isEdit ? (
											<S.ButtonContainer>
												<CRButton.Default
													palette='gray'
													type='outlined'
													size='xSmall'
													onClick={handleChangeEdit}>
													취소
												</CRButton.Default>
												<CRButton.Default
													palette='gray'
													size='xSmall'
													onClick={handleUpdateDueDate}>
													저장
												</CRButton.Default>
											</S.ButtonContainer>
										) : (
											<CRButton.Default
												palette='gray'
												type='outlined'
												size='xSmall'
												onClick={handleChangeEdit}>
												편집
											</CRButton.Default>
										)
									) : null}
								</S.SectionTitle>
								<InformationTable items={previewItem as InformationTableItemType[][]} />
							</S.SectionContainer>
							<S.SectionContainer>
								<S.SectionTitle>
									서명 현황
									{eSignHistoryDetail?.lastEsignStateNm &&
										['검수 대기', '서명 대기'].includes(eSignHistoryDetail.lastEsignStateNm) && (
											<CRButton.Default
												palette='primary'
												type='outlined'
												size='xSmall'
												onClick={handleClickSignCancel}>
												취소
											</CRButton.Default>
										)}
								</S.SectionTitle>
								<InformationTable items={signStatusItem} />
							</S.SectionContainer>
							<S.SectionContainer ref={scrollContainerRef}>
								<S.SectionTitle>발송 이력</S.SectionTitle>
								<S.TableContainer>
									<CRTable.Root>
										<CRTable.Head offset={-77} heads={SEND_HISTORY_DETAIL_TABLE_HEADER_CONFIG} />
										<CRTable.Body>
											{rowVirtualizer?.getVirtualItems()?.map(rowRenderer)}
										</CRTable.Body>
									</CRTable.Root>
								</S.TableContainer>
							</S.SectionContainer>
						</S.LeftSection>
						<S.RightSection>
							<S.SectionContainer>
								<S.SectionTitle>내용</S.SectionTitle>
								<S.TextAreaContainer>
									<CRInput.TextArea
										showBorder={false}
										disabled
										numberOfLines={12}
										value={eSignHistoryDetail?.sendMessageDesc?.replace(/<br\/>|\\n/g, '\n')}
									/>
								</S.TextAreaContainer>
							</S.SectionContainer>
							<S.SectionContainer>
								<S.SectionTitle>전자 문서</S.SectionTitle>
								{eSignHistoryDetail?.edocPreviewResponse.eformFileNm && (
									<S.FileList>
										<S.FileListItem>
											<S.FileInfo>
												<S.Icon src={Assets.icon.description} />
												<S.FileName onClick={() => handleClickEDocPreview()}>
													{eSignHistoryDetail?.edocTitle}
												</S.FileName>
											</S.FileInfo>
										</S.FileListItem>
									</S.FileList>
								)}
							</S.SectionContainer>
						</S.RightSection>
					</S.Container>
				)
			}
		/>
	);
}
