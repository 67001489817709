import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { Toast } from 'components/base/CRToast';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import InformationSheet from 'components/ui/InformationSheet';
import { useMyAccountInfo, useSaveFulLTimeEmployeeWorkState } from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import { FullTimeEmployeeDTO, FullTimeEmployeeWorkStateDTO } from 'types/dto/full-time-employee';

import { FullTimeEmployeeStatus } from '../../../../lib';
import * as S from './styles';

interface Props {
	currentFullTimeEmployee?: FullTimeEmployeeDTO;
	currentWorkState?: FullTimeEmployeeWorkStateDTO;
	targetWorkState?: FullTimeEmployeeWorkStateDTO;
	hideDialog: () => void;
}

export default function AddFullTimeEmployeeHistoryOfWorkingStatusDialog({
	currentFullTimeEmployee,
	currentWorkState,
	targetWorkState,
	hideDialog,
}: Props): React.ReactElement {
	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<FullTimeEmployeeWorkStateDTO>({
		mode: 'onChange',
		defaultValues: targetWorkState,
		resolver: yupResolver(FullTimeEmployeeStatus),
	});
	const { data: myAccountInfo } = useMyAccountInfo();
	const saveFullTimeEmployeeWorkState = useSaveFulLTimeEmployeeWorkState(
		(client) => {
			client.invalidateQueries([
				endpoint.getFullTimeEmployees.key,
				{
					centerIds: [currentFullTimeEmployee?.centerId],
				},
			]);

			client.invalidateQueries([
				endpoint.getFullTimeEmployeeBaseInfo.key,
				{
					centerId: currentFullTimeEmployee?.centerId,
					employeeId: currentFullTimeEmployee?.employeeId,
				},
			]);
			client.invalidateQueries([
				endpoint.getFullTimeEmployeeDetailInfo.key,
				{
					centerId: currentFullTimeEmployee?.centerId,
					employeeId: currentFullTimeEmployee?.employeeId,
					memberId: currentFullTimeEmployee?.memberId,
				},
			]);
			client.invalidateQueries([
				endpoint.getFullTimeEmployeeWorkStates.key,
				{
					centerId: currentFullTimeEmployee?.centerId,
					employeeId: currentFullTimeEmployee?.employeeId,
					memberId: currentFullTimeEmployee?.memberId,
				},
			]);
			hideDialog();
			Toast.success('상태 변경 이력을 추가했습니다.');
		},
		(client, error) => {
			hideDialog();
			Toast.error(error.message);
		},
	);

	const stateOptions = useMemo(() => {
		if (currentWorkState?.workStateCd === 'CMN068.10') {
			return [
				{
					label: '휴직중',
					value: 'CMN068.11',
				},
				{
					label: '보류중',
					value: 'CMN068.12',
				},
			];
		}
		if (currentWorkState?.workStateCd === 'CMN068.11') {
			return [
				{
					label: '근무중',
					value: 'CMN068.10',
				},
				{
					label: '보류중',
					value: 'CMN068.12',
				},
			];
		}
		if (currentWorkState?.workStateCd === 'CMN068.12') {
			return [
				{
					label: '근무중',
					value: 'CMN068.10',
				},
				{
					label: '휴직중',
					value: 'CMN068.11',
				},
			];
		}
		return [];
	}, [currentWorkState]);

	const onSubmit = async (data: FullTimeEmployeeWorkStateDTO) => {
		if (!myAccountInfo?.centerId || !currentFullTimeEmployee?.employeeId) return;

		await saveFullTimeEmployeeWorkState.mutateAsync({
			...currentWorkState,
			ftimeEmployeeWorkStateHistId: targetWorkState?.ftimeEmployeeWorkStateHistId,
			employeeId: currentFullTimeEmployee.employeeId,
			workStateChangeDate: dayjs(data.workStateChangeDate).format('YYYY-MM-DD hh:mm:ss'),
			workStateCd: data.workStateCd,
			workStateChangeDesc: data.workStateChangeDesc,
			resignationCompleteYn: false,
			annualExhaustCompleteYn: false,
		});
	};

	return (
		<CRDialog
			onClickClose={hideDialog}
			type='S'
			title={targetWorkState ? '상태 변경 수정' : '상태 변경'}
			height={800}
			body={
				<S.Form onSubmit={handleSubmit(onSubmit)}>
					{!targetWorkState && (
						<InformationSheet
							title='현재 상태'
							align='flex-start'
							items={[
								[
									{
										label: '상태',
										value: currentWorkState?.workStateNm ?? '-',
										labelWidth: '12rem',
									},
								],
								[
									{
										label: '시작일',
										value: currentWorkState?.workStateChangeDate
											? dayjs(currentWorkState?.workStateChangeDate).format('YYYY.MM.DD')
											: '-',
										labelWidth: '12rem',
									},
								],
								[
									{
										label: '사유',
										value: currentWorkState?.workStateChangeDesc ?? '-',
										labelWidth: '12rem',
									},
								],
							]}
							type='gray'
						/>
					)}
					{!targetWorkState ? (
						<CRInputLabel label='변경할 상태' isRequired>
							<Controller
								render={({ field: { onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										type='radio'
										appearanceType='button'
										options={stateOptions}
										checkType='single'
										value={value ? [{ value, label: '' }] : []}
										onChange={(item) => onChange(item?.[0].value)}
										gap={1.6}
									/>
								)}
								name='workStateCd'
								control={control}
							/>
						</CRInputLabel>
					) : (
						<CRInputLabel label='상태' isRequired>
							<CRInput.Default value={targetWorkState?.workStateNm} disabled />
						</CRInputLabel>
					)}
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel isRequired label='시작일'>
								<CRInput.DatePicker
									placeholder='날짜 선택'
									onChangeValue={(date) => onChange(dayjs(date).format('YYYY-MM-DD hh:mm:ss'))}
									value={value ? dayjs(value).toDate() : undefined}
								/>
							</CRInputLabel>
						)}
						name='workStateChangeDate'
						control={control}
					/>
					<CRInputLabel label='사유'>
						<Controller
							render={({ field: { onChange, value, ref }, formState: { errors } }) => (
								<CRInput.TextArea
									placeholder='사유 입력'
									numberOfLines={1}
									value={value}
									onChange={onChange}
								/>
							)}
							name='workStateChangeDesc'
							control={control}
						/>
					</CRInputLabel>
				</S.Form>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						disabled={!isValid}
						palette='primary'
						size='default'
						onClick={handleSubmit(onSubmit)}>
						저장
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
