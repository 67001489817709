import React from 'react';

import { useDownload } from '../../../../lib/hook/util/useDownload';
import { FileDTO, FileDetailDTO } from '../../../../types/dto';
import CRButton from '../../CRButton';
import CRRequiredMark from '../../CRRequiredMark';
import CRFileUploaderButton from '../CRFileUploaderButton';
import * as S from './styles';

interface Props {
	uploaderList?: {
		label: string;
		isRequired?: boolean;
		disabled?: boolean;
		onClickUpload?: (files: FileDetailDTO[]) => void;
		onCustomClick?: () => void;
		file?: FileDTO | null;
	}[];
}

function CRTableFileUploader({ uploaderList = [] }: Props): React.ReactElement {
	const { downloadFiles } = useDownload();
	return (
		<S.Container>
			<S.Table>
				<S.TableHeader>
					<S.TableHeaderColumn style={{ width: '24rem' }}>문서명</S.TableHeaderColumn>
					<S.TableHeaderColumn style={{ width: '100%' }}>파일</S.TableHeaderColumn>
					<S.TableHeaderColumn style={{ width: '12rem' }}>상태</S.TableHeaderColumn>
				</S.TableHeader>
				<S.TableBody>
					{uploaderList.map((uploader) => {
						const fileDetails = uploader.file?.fileDetails.filter((item) => !item.fileDeleteYn);

						return (
							<S.TableBodyRow>
								<S.TableBodyColumn>
									{uploader.label}
									{uploader.isRequired && <CRRequiredMark />}
								</S.TableBodyColumn>
								<S.TableBodyColumn>
									<S.FileNameText
										download
										onClick={() => {
											if (!uploader.file) return;
											downloadFiles(fileDetails);
										}}>
										{fileDetails?.[0]?.originFileNm
											? `${fileDetails?.[0]?.originFileNm} ${
													(fileDetails?.length ?? 0) > 1
														? `외 ${(fileDetails.length ?? 0) - 1}건`
														: ''
												}`
											: ''}
									</S.FileNameText>
								</S.TableBodyColumn>
								<S.TableBodyColumn>
									{uploader.file ? (
										!uploader.onCustomClick ? (
											<CRFileUploaderButton
												disabled={uploader.disabled}
												label='재업로드'
												onChange={uploader?.onClickUpload}
											/>
										) : (
											<CRButton.Default
												disabled={uploader.disabled}
												type='outlined'
												palette='gray'
												size='xSmall'
												onClick={uploader.onCustomClick}>
												재업로드
											</CRButton.Default>
										)
									) : !uploader.onCustomClick ? (
										<CRFileUploaderButton
											disabled={uploader.disabled}
											label='업로드'
											onChange={uploader?.onClickUpload}
										/>
									) : (
										<CRButton.Default
											disabled={uploader.disabled}
											type='outlined'
											palette='gray'
											size='xSmall'
											onClick={uploader.onCustomClick}>
											업로드
										</CRButton.Default>
									)}
								</S.TableBodyColumn>
							</S.TableBodyRow>
						);
					})}
				</S.TableBody>
			</S.Table>
		</S.Container>
	);
}

export default CRTableFileUploader;
