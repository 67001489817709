import React, { useCallback, useMemo, useState } from 'react';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRStatus from 'components/base/CRStatus';
import CRTab from 'components/base/CRTab';
import CRTable from 'components/base/CRTable';
import { CRText } from 'components/base/CRText';
import { useMyAccountInfo } from 'lib/hook/react-query';
import { useMonitoringSyncHistory } from 'lib/hook/react-query/query/monitoring';
import useDialog from 'lib/hook/util/useDialog';
import {
	MonitoringSyncDateDTO,
	MonitoringSyncHistoryDTO,
	MonitoringUploadTypeCds,
} from 'types/api';
import { AutomationUploadDTO } from 'types/api/common';

import CRDialog from '../../../base/CRDialog';
import { ScheduleSyncResultDialog } from '../ScheduleSyncResultDialog';
import {
	DATA_SYNC_HISTORY_TABLE_HEADER_CONFIG,
	LONGTERM_SYNC_HISTORY_TABLE_HEADER_CONFIG,
	SYNC_HISTORY_STATUS_CONFIG,
} from './constants';
import * as S from './styles';

interface Props {
	serviceYm: string;
	completeSyncDate?: MonitoringSyncDateDTO[];
}

enum SyncHistoryTab {
	공단데이터불러오기 = 'longtermHistory',
	데이터갱신 = 'syncHistory',
}

enum UploadTypeCdToUploadTypeNm {
	'CMN107.SYNC_PCORP_TAG' = '태그 내역',
	'CMN107.SYNC_PCORP_SCHEDULE' = '일정 계획',
	'CMN107.SYNC_PCORP_ETC_RFID' = 'RFID 전송내용',
}

function MonitoringSyncHistoryDialog({ serviceYm, completeSyncDate }: Props): React.ReactElement {
	const [currentTab, setCurrentTab] = useState(SyncHistoryTab.공단데이터불러오기);
	const { data: myAccountInfo } = useMyAccountInfo();
	const syncTypeCds = useMemo(
		() =>
			currentTab === SyncHistoryTab.공단데이터불러오기
				? [
						MonitoringUploadTypeCds.공단_일정계획_동기화,
						MonitoringUploadTypeCds.공단_태그_동기화,
						MonitoringUploadTypeCds.공단_기타_RFID_동기화,
					]
				: [MonitoringUploadTypeCds.일정모니터링_동기화],
		[currentTab],
	);
	const { data: syncHistoryData } = useMonitoringSyncHistory({
		centerId: myAccountInfo?.centerId,
		serviceYm,
		syncTypeCds,
	});

	const { showDialog, hideDialog } = useDialog();

	const handleChangeTab = (tab: SyncHistoryTab) => setCurrentTab(tab);

	const handleClickScheduleSyncResult = (item: AutomationUploadDTO) => () => {
		showDialog(() => <ScheduleSyncResultDialog item={item} />);
	};

	const Status = useCallback(
		(status: string) => <CRStatus options={SYNC_HISTORY_STATUS_CONFIG}>{status}</CRStatus>,
		[],
	);
	// eslint-disable-next-line
	const renderCreatedNm = useCallback((name: string) => <>{name ?? '-'}</>, []);

	const Date = useCallback(
		// eslint-disable-next-line
		(name: string) => <>{name ? dayjs(name).format('YYYY.MM.DD HH:mm') : '-'}</>,
		[],
	);

	const ButtonContainer = useCallback(
		(data: number, item?: AutomationUploadDTO) => (
			<S.OptionContainer>
				{item?.uploadResultDesc && item.uploadStateCd !== 'CMN108.ERROR' && (
					<CRButton.Default
						type='outlined'
						palette='gray'
						size='xSmall'
						onClick={handleClickScheduleSyncResult(item)}>
						결과
					</CRButton.Default>
				)}
			</S.OptionContainer>
		),
		[],
	);

	const renderDivCdNm = useCallback((divCdNm: string, item?: AutomationUploadDTO) => {
		let isRecent = false;

		const PCorpRFID = completeSyncDate?.find(
			(item) => item.uploadTypeCd === MonitoringUploadTypeCds.공단_기타_RFID_동기화,
		);

		const PCorpSchedule = completeSyncDate?.find(
			(item) => item.uploadTypeCd === MonitoringUploadTypeCds.공단_일정계획_동기화,
		);

		const PCorpTag = completeSyncDate?.find(
			(item) => item.uploadTypeCd === MonitoringUploadTypeCds.공단_태그_동기화,
		);

		const syncDates = [
			{ type: MonitoringUploadTypeCds.공단_기타_RFID_동기화, date: PCorpRFID?.uploadStartDate },
			{ type: MonitoringUploadTypeCds.공단_일정계획_동기화, date: PCorpSchedule?.uploadStartDate },
			{ type: MonitoringUploadTypeCds.공단_태그_동기화, date: PCorpTag?.uploadStartDate },
		];

		syncDates.forEach((sync) => {
			if (divCdNm === sync.type && sync.date === item?.uploadStartDate) {
				isRecent = true;
			}
		});

		return (
			<S.OptionContainer>
				<CRText
					text={UploadTypeCdToUploadTypeNm[divCdNm as keyof typeof UploadTypeCdToUploadTypeNm]}
					color='gray00'
					typography='label'
				/>
				{isRecent && <S.TagText>최근</S.TagText>}
			</S.OptionContainer>
		);
	}, []);

	const renderTargetYm = useCallback(
		(uploadTargetYm: string) => (
			<S.TextBox>
				{uploadTargetYm ? `${dayjs(uploadTargetYm).startOf('M').format('YYYY년 M월')}` : '-'}
			</S.TextBox>
		),
		[],
	);

	const renderUploadResultDesc = useCallback(
		(uploadResultDesc: string, item?: MonitoringSyncHistoryDTO) => {
			const processedData = item?.uploadResultDesc?.split('\n')?.[0];
			const resulDesc = processedData?.split('/');
			const desc =
				resulDesc && resulDesc?.length > 1 ? resulDesc.slice(1).join(' /') : processedData;
			return <S.TextBox title={desc}>{desc}</S.TextBox>;
		},
		[],
	);

	const renderSyncHistoryDivNm = useCallback(
		(uploadTypeNm: string, item?: MonitoringSyncHistoryDTO) => {
			const processedData = item?.uploadResultDesc?.split('\n')?.[0]?.split('/');
			const divNm = processedData && processedData?.length > 1 ? processedData[0] : uploadTypeNm;
			return <S.TextBox>{divNm}</S.TextBox>;
		},
		[],
	);

	const renderLongtermHistory = () => (
		<div style={{ position: 'relative' }}>
			<CRTable.Root>
				<CRTable.Head heads={LONGTERM_SYNC_HISTORY_TABLE_HEADER_CONFIG} offset={1} />
				<CRTable.Body>
					{syncHistoryData?.map((item) => (
						<CRTable.Row
							key={item.uploadHistId}
							item={item}
							customRender={{
								uploadStateCd: Status,
								uploadTypeCd: renderDivCdNm,
								uploadStartDate: Date,
								uploadEndDate: Date,
								uploadTargetYm: renderTargetYm,
								createdNm: renderCreatedNm,
								uploadHistId: ButtonContainer,
							}}
							renderKeys={[
								'uploadStateCd',
								'uploadTypeCd',
								'uploadStartDate',
								'uploadEndDate',
								'uploadTargetYm',
								'createdNm',
								'uploadHistId',
							]}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</div>
	);

	const renderSyncHistory = () => (
		<div style={{ position: 'relative' }}>
			<CRTable.Root>
				<CRTable.Head heads={DATA_SYNC_HISTORY_TABLE_HEADER_CONFIG} offset={1} />
				<CRTable.Body>
					{syncHistoryData?.map((item) => (
						<CRTable.Row
							key={item.uploadHistId}
							item={item}
							customRender={{
								uploadStateCd: Status,
								createdNm: renderCreatedNm,
								uploadStartDate: Date,
								uploadEndDate: Date,
								uploadTargetYm: renderTargetYm,
								uploadHistId: ButtonContainer,
								uploadResultDesc: renderUploadResultDesc,
								uploadTypeNm: renderSyncHistoryDivNm,
							}}
							renderKeys={[
								'uploadStateCd',
								'uploadTypeNm',
								'uploadResultDesc',
								'uploadStartDate',
								'uploadEndDate',
								'uploadTargetYm',
								'createdNm',
								'uploadHistId',
							]}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</div>
	);
	return (
		<CRDialog
			type='L'
			onClickClose={hideDialog}
			title='실행 이력'
			body={
				<S.Container>
					<CRTab.Detail
						onChange={(item) => handleChangeTab(item.key as SyncHistoryTab)}
						menuContainerStyle={{ padding: '0rem 1.6rem', gap: '2.4rem' }}
						defaultActiveKey={currentTab}
						items={[
							{
								label: '공단 데이터 불러오기',
								key: 'longtermHistory',
								children: renderLongtermHistory(),
							},
							{
								label: '데이터 갱신',
								key: 'syncHistory',
								children: renderSyncHistory(),
							},
						]}
					/>
				</S.Container>
			}
		/>
	);
}

export default React.memo(MonitoringSyncHistoryDialog);
