import { css, styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	padding-bottom: 1rem;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
`;

export const Label = styled.div`
	${(props) => props.theme.typography.labelB};
	color: ${(props) => props.theme.colors.gray00};
	display: flex;
	justify-content: space-between;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const Table = styled.table<{ $isDisabled?: boolean }>`
	table-layout: fixed;
	width: 100%;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};

	${(props) =>
		props.$isDisabled &&
		css`
			background: ${(props) => props.theme.colors.gray99};
			color: ${(props) => props.theme.colors.gray60};
		`};
`;

export const TableRow = styled.tr`
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};

	&:last-child {
		border-bottom: 0;
	}
`;

export const TableLabelColumn = styled.td`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
	height: 5.4rem;
	padding: 0.8rem;
	box-sizing: border-box;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	background: ${(props) => props.theme.colors.gray99};
	vertical-align: middle;
`;

export const TableValueColumn = styled.td`
	${(props) => props.theme.typography.label};
	position: relative;
	box-sizing: border-box;
	height: 5.4rem;
	padding: 0.8rem;
	box-sizing: border-box;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	vertical-align: middle;
`;

export const RightButtonContainer = styled.div`
	position: absolute;
	right: 1.2rem;
	top: 50%;
	transform: translateY(-50%);
`;

export const ItemHeader = styled.div`
	${(props) => props.theme.typography.label}
	display: flex;
	justify-content: space-between;
	height: 2.6rem;
	flex-direction: row;
	align-items: center;
	gap: 0.8rem;
`;

export const DeleteButtonContainer = styled.span`
	flex: 1;
`;

export const AddButtonContainer = styled.div`
	padding-top: 1.5rem;
`;

export const RequiredMark = styled.span`
	color: ${(props) => props.theme.colors.primary60};
`;

export const DayContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
`;

export const SelectionNone = css`
	${(props) => props.theme.noneSelection}
`;

export const Day = styled.div<{ $isSelected?: boolean; $isDisabled?: boolean }>`
	cursor: pointer;
	${(props) => props.theme.typography.label}
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2.4rem;
	height: 2.4rem;
	border-radius: 1.2rem;
	${SelectionNone}
	${(props) =>
		props.$isSelected
			? css`
					border: 0.1rem solid
						${props.$isDisabled ? props.theme.colors.gray60 : props.theme.colors.primary60};
					color: ${props.theme.colors.gray100};
					background: ${props.$isDisabled
						? props.theme.colors.gray60
						: props.theme.colors.primary60};
				`
			: css`
					border: 0.1rem solid ${props.theme.colors.gray90};
					color: ${props.theme.colors.gray10};
					background: ${props.theme.colors.gray100};
				`}
`;

export const DaysContainer = styled.div`
	display: flex;
	position: relative;
	justify-content: space-between;
	gap: 0.4rem;
`;

export const DayToggle = styled.div`
	display: flex;
	gap: 0.4rem;
	align-items: center;
`;

export const QuestionMark = styled.img`
	width: 1.6rem;
	height: 1.6rem;
	object-fit: contain;
	margin-left: 0.4rem;
`;

export const LabelContainer = styled.div`
	${(props) => props.theme.typography.label};
	display: flex;
	align-items: center;
	color: ${(props) => props.theme.colors.gray60};
`;

export const EmptyContainer = styled.div`
	width: 100%;
	height: 18rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(p) => p.theme.colors.gray95};
`;

export const ServiceInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray10};
`;
