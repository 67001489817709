import React, { useEffect, useMemo, useRef } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import { v4 } from 'uuid';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInputMessage from 'components/base/CRInputMessage';
import { CheckOption } from 'components/base/Selections/type';
import {
	ProcessedRecipientScheduleDetail,
	RecipientScheduleDetailCenterItem,
	RecipientScheduleDetailForm,
} from 'types/view/schedule';

import * as S from './styles';

interface Props {
	item?: ProcessedRecipientScheduleDetail | null;
	options?: {
		services: CheckOption[];
		employees: CheckOption[];
		recipients: CheckOption[];
	};
	overlapIds: (string | number)[];
}

export function RecipientScheduleEdit({ item, options, overlapIds }: Props) {
	const { watch, control, setValue } = useFormContext<RecipientScheduleDetailForm>();
	const { fields, append } = useFieldArray({
		control,
		name: 'items',
	});

	const memoizedOptions = useMemo(() => options, [options]);
	const memoizedMyCenter = useMemo(() => item?.myCenter, [item?.myCenter]);

	const handleAddMyCenterItem = () => {
		append({
			scheduleId: v4(),
			service: undefined,
			employee: undefined,
			employee2: undefined,
			deleteYn: false,
			time: {
				startTime: '',
				endTime: '',
			},
		});
	};

	const handleDeleteMyCenterItem = (scheduleId: string | number) => {
		if (typeof scheduleId === 'number') {
			const newItems = watch('items').map((item) =>
				item.scheduleId === scheduleId
					? {
							...item,
							deleteYn: true,
						}
					: item,
			);
			setValue('items', newItems);
		} else {
			const newItems = watch('items').filter((item) => item.scheduleId !== scheduleId);
			setValue('items', newItems);
		}
	};

	useEffect(() => {
		if (!fields.length) {
			if (
				memoizedMyCenter?.length &&
				memoizedOptions?.employees?.length &&
				memoizedOptions.services.length
			) {
				const initFormData: RecipientScheduleDetailCenterItem[] = [];
				memoizedMyCenter?.forEach((value) => {
					const service = memoizedOptions?.services.find(
						(service) => service.value === value.serviceKindCd,
					);
					const [employee, employee2] = value.employees.map((employee) =>
						memoizedOptions?.employees?.find(
							(item) => String(item.value.id) === String(employee.employeeId),
						),
					);

					const data = {
						scheduleId: value?.employees?.find((item) => item.employeeId === employee?.value?.id)
							?.serviceSchedulePlanId,
						scheduleId2: value?.employees?.find((item) => item.employeeId === employee2?.value?.id)
							?.serviceSchedulePlanId,
						service,
						employee,
						employee2,
						deleteYn: false,
						time: {
							startTime: `${value.serviceStartTime.substring(
								0,
								2,
							)}:${value.serviceStartTime.substring(2, 4)}`,
							endTime: `${value.serviceEndTime.substring(0, 2)}:${value.serviceEndTime.substring(
								2,
								4,
							)}`,
						},
					} as RecipientScheduleDetailCenterItem;

					if (fields.findIndex((item) => item.scheduleId === data.scheduleId) === -1) {
						initFormData.push(data);
					}
				});
				setValue('items', initFormData);
			}
		}
	}, [memoizedMyCenter, memoizedOptions, fields]);

	return (
		<div>
			{watch('items')
				?.filter((item) => !item.deleteYn)
				?.map((formItem, index) => (
					<S.Container key={formItem.scheduleId}>
						<S.Title>
							내 센터
							<CRButton.Default
								type='outlined'
								size='xSmall'
								onClick={() => handleDeleteMyCenterItem(formItem.scheduleId)}>
								삭제
							</CRButton.Default>
						</S.Title>
						<S.Content key={formItem.scheduleId}>
							<Controller
								render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
									<CRInputLabel label='급여'>
										<CRInput.Selector
											disabled={typeof formItem.scheduleId === 'number'}
											items={memoizedOptions?.services}
											ref={ref}
											currentValue={value}
											onChangeValue={(item) => onChange(item)}
											placeholder='급여 선택'
										/>
									</CRInputLabel>
								)}
								name={`items.${index}.service`}
								control={control}
							/>
							<Controller
								render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
									<CRInputLabel label='직원'>
										<CRInput.SearchSelector
											currentValue={value}
											items={memoizedOptions?.employees}
											onChange={onChange}
										/>
									</CRInputLabel>
								)}
								name={`items.${index}.employee`}
								control={control}
							/>
							{watch('items')[index].service?.value === 'CMN083.20' && (
								<Controller
									render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
										<CRInputLabel label='직원2'>
											<CRInput.SearchSelector
												currentValue={value}
												items={memoizedOptions?.employees}
												onChange={onChange}
											/>
										</CRInputLabel>
									)}
									name={`items.${index}.employee2`}
									control={control}
								/>
							)}
							<Controller
								render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
									<CRInputLabel label='일정'>
										<CRInput.DayTimePicker
											showDays={false}
											currentValue={{
												id: formItem.scheduleId,
												days: [],
												startTime: value?.startTime || '',
												endTime: value?.endTime || '',
											}}
											onChange={(e) => {
												onChange(e);
											}}
										/>
										{errors?.items?.[index]?.time && (
											<CRInputMessage type='error'>
												{errors?.items?.[index]?.time?.message || ''}
											</CRInputMessage>
										)}
										{overlapIds?.includes(watch('items')?.[index]?.scheduleId) && (
											<CRInputMessage type='error'>중복된 일정이 있습니다.</CRInputMessage>
										)}
									</CRInputLabel>
								)}
								name={`items.${index}.time`}
								control={control}
							/>
						</S.Content>
					</S.Container>
				))}
			<CRButton.IconButton
				onClick={handleAddMyCenterItem}
				style={{
					width: '100%',
					marginTop: '1.6rem',
				}}
				palette='gray'
				type='tonal'
				size='default'
				iconLeft={Assets.icon.add}>
				일정 추가
			</CRButton.IconButton>
		</div>
	);
}

export default React.memo(RecipientScheduleEdit);
