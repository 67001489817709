import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRRequiredMark from 'components/base/CRRequiredMark';
import { IntegratedAssessmentDesireForms } from 'types/view/recipient';

import CRCheckBoxGroup from '../../../base/Selections/CRCheckBoxGroup';
import IntegratedAssessmentDesireHousingEnvironmentFormHeader from './IntegratedAssessmentDesireHousingEnvironmentFormHeader';
import { integratedAssessmentDesireHousingEnvironmentQuestions } from './constant';
import * as S from './styles';

interface Props {
	form: UseFormReturn<IntegratedAssessmentDesireForms>;
	showGenerateRequiredFields?: boolean;
}

function IntegratedAssessmentDesireHousingEnvironmentForm({
	form,
	showGenerateRequiredFields,
}: Props): React.ReactElement {
	return (
		<S.Container>
			<S.Table>
				<IntegratedAssessmentDesireHousingEnvironmentFormHeader />
				<S.TableBody>
					<S.TableBodyRow>
						<S.TableBodyColumn style={{ textAlign: 'center' }}>
							{integratedAssessmentDesireHousingEnvironmentQuestions[0].label}
							{showGenerateRequiredFields &&
								integratedAssessmentDesireHousingEnvironmentQuestions[0].required?.includes(
									'generate',
								) && <CRRequiredMark />}
						</S.TableBodyColumn>
						<S.TableBodyColumn>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										gap={0.8}
										type='radio'
										options={integratedAssessmentDesireHousingEnvironmentQuestions[0].answer}
										checkType='single'
										onChange={(item) => onChange(item[0]?.value)}
										value={value ? [{ label: '', value }] : []}
									/>
								)}
								name={integratedAssessmentDesireHousingEnvironmentQuestions[0].key}
								control={form.control}
							/>
						</S.TableBodyColumn>
						<S.TableBodyColumn colSpan={2} style={{ textAlign: 'center' }}>
							{integratedAssessmentDesireHousingEnvironmentQuestions[1].label}
							{showGenerateRequiredFields &&
								integratedAssessmentDesireHousingEnvironmentQuestions[1].required?.includes(
									'generate',
								) && <CRRequiredMark />}
						</S.TableBodyColumn>
						<S.TableBodyColumn>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										gap={0.8}
										type='radio'
										options={integratedAssessmentDesireHousingEnvironmentQuestions[1].answer}
										checkType='single'
										onChange={(item) => onChange(item[0]?.value)}
										value={value ? [{ label: '', value }] : []}
									/>
								)}
								name={integratedAssessmentDesireHousingEnvironmentQuestions[1].key}
								control={form.control}
							/>
						</S.TableBodyColumn>
					</S.TableBodyRow>
					<S.TableBodyRow>
						<S.TableBodyColumn style={{ textAlign: 'center' }} rowSpan={2}>
							{integratedAssessmentDesireHousingEnvironmentQuestions[2].label}
							{showGenerateRequiredFields &&
								integratedAssessmentDesireHousingEnvironmentQuestions[2].required?.includes(
									'generate',
								) && <CRRequiredMark />}
						</S.TableBodyColumn>
						<S.TableBodyColumn rowSpan={2}>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										gap={0.8}
										type='radio'
										options={integratedAssessmentDesireHousingEnvironmentQuestions[2].answer}
										checkType='single'
										onChange={(item) => onChange(item[0]?.value)}
										value={value ? [{ label: '', value }] : []}
									/>
								)}
								name={integratedAssessmentDesireHousingEnvironmentQuestions[2].key}
								control={form.control}
							/>
						</S.TableBodyColumn>
						<S.TableBodyColumn rowSpan={2} style={{ textAlign: 'center' }}>
							{integratedAssessmentDesireHousingEnvironmentQuestions[3].label}
							{showGenerateRequiredFields &&
								integratedAssessmentDesireHousingEnvironmentQuestions[3].required?.includes(
									'generate',
								) && <CRRequiredMark />}
						</S.TableBodyColumn>
						<S.TableBodyColumn style={{ textAlign: 'center' }}>
							{integratedAssessmentDesireHousingEnvironmentQuestions[3].labelDetail}
						</S.TableBodyColumn>
						<S.TableBodyColumn>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										gap={0.8}
										type='radio'
										options={integratedAssessmentDesireHousingEnvironmentQuestions[3].answer}
										checkType='single'
										onChange={(item) => onChange(item[0]?.value)}
										value={value ? [{ label: '', value }] : []}
									/>
								)}
								name={integratedAssessmentDesireHousingEnvironmentQuestions[3].key}
								control={form.control}
							/>
						</S.TableBodyColumn>
					</S.TableBodyRow>
					<S.TableBodyRow>
						<S.TableBodyColumn style={{ textAlign: 'center' }}>
							{integratedAssessmentDesireHousingEnvironmentQuestions[4].labelDetail}
						</S.TableBodyColumn>
						<S.TableBodyColumn>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										gap={0.8}
										type='radio'
										options={integratedAssessmentDesireHousingEnvironmentQuestions[4].answer}
										checkType='single'
										onChange={(item) => onChange(item[0]?.value)}
										value={value ? [{ label: '', value }] : []}
									/>
								)}
								name={integratedAssessmentDesireHousingEnvironmentQuestions[4].key}
								control={form.control}
							/>
						</S.TableBodyColumn>
					</S.TableBodyRow>

					<S.TableBodyRow>
						<S.TableBodyColumn style={{ textAlign: 'center' }}>
							{integratedAssessmentDesireHousingEnvironmentQuestions[5].label}{' '}
							{showGenerateRequiredFields &&
								integratedAssessmentDesireHousingEnvironmentQuestions[5].required?.includes(
									'generate',
								) && <CRRequiredMark />}
						</S.TableBodyColumn>
						<S.TableBodyColumn>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										gap={0.8}
										type='radio'
										options={integratedAssessmentDesireHousingEnvironmentQuestions[5].answer}
										checkType='single'
										onChange={(item) => onChange(item[0]?.value)}
										value={value ? [{ label: '', value }] : []}
									/>
								)}
								name={integratedAssessmentDesireHousingEnvironmentQuestions[5].key}
								control={form.control}
							/>
						</S.TableBodyColumn>
						<S.TableBodyColumn colSpan={2} style={{ textAlign: 'center' }}>
							{integratedAssessmentDesireHousingEnvironmentQuestions[6].label}
							{showGenerateRequiredFields &&
								integratedAssessmentDesireHousingEnvironmentQuestions[6].required?.includes(
									'generate',
								) && <CRRequiredMark />}
						</S.TableBodyColumn>
						<S.TableBodyColumn>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										gap={0.8}
										type='radio'
										options={integratedAssessmentDesireHousingEnvironmentQuestions[6].answer}
										checkType='single'
										onChange={(item) => onChange(item[0]?.value)}
										value={value ? [{ label: '', value }] : []}
									/>
								)}
								name={integratedAssessmentDesireHousingEnvironmentQuestions[6].key}
								control={form.control}
							/>
						</S.TableBodyColumn>
					</S.TableBodyRow>
					<S.TableBodyRow>
						<S.TableBodyColumn style={{ textAlign: 'center' }}>
							{integratedAssessmentDesireHousingEnvironmentQuestions[7].label}
							{showGenerateRequiredFields &&
								integratedAssessmentDesireHousingEnvironmentQuestions[7].required?.includes(
									'generate',
								) && <CRRequiredMark />}
						</S.TableBodyColumn>
						<S.TableBodyColumn>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										gap={0.8}
										type='radio'
										options={integratedAssessmentDesireHousingEnvironmentQuestions[7].answer}
										checkType='single'
										onChange={(item) => onChange(item[0]?.value)}
										value={value ? [{ label: '', value }] : []}
									/>
								)}
								name={integratedAssessmentDesireHousingEnvironmentQuestions[7].key}
								control={form.control}
							/>
						</S.TableBodyColumn>
						<S.TableBodyColumn colSpan={2} style={{ textAlign: 'center' }}>
							{integratedAssessmentDesireHousingEnvironmentQuestions[8].label}
							{showGenerateRequiredFields &&
								integratedAssessmentDesireHousingEnvironmentQuestions[8].required?.includes(
									'generate',
								) && <CRRequiredMark />}
						</S.TableBodyColumn>
						<S.TableBodyColumn>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										gap={0.8}
										type='radio'
										options={integratedAssessmentDesireHousingEnvironmentQuestions[8].answer}
										checkType='single'
										onChange={(item) => onChange(item[0]?.value)}
										value={value ? [{ label: '', value }] : []}
									/>
								)}
								name={integratedAssessmentDesireHousingEnvironmentQuestions[8].key}
								control={form.control}
							/>
						</S.TableBodyColumn>
					</S.TableBodyRow>
					<S.TableBodyRow>
						<S.TableBodyColumn style={{ textAlign: 'center' }}>
							{integratedAssessmentDesireHousingEnvironmentQuestions[9].label}
							{showGenerateRequiredFields &&
								integratedAssessmentDesireHousingEnvironmentQuestions[9].required?.includes(
									'generate',
								) && <CRRequiredMark />}
						</S.TableBodyColumn>
						<S.TableBodyColumn>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										gap={0.8}
										type='radio'
										options={integratedAssessmentDesireHousingEnvironmentQuestions[9].answer}
										checkType='single'
										onChange={(item) => onChange(item[0]?.value)}
										value={value ? [{ label: '', value }] : []}
									/>
								)}
								name={integratedAssessmentDesireHousingEnvironmentQuestions[9].key}
								control={form.control}
							/>
						</S.TableBodyColumn>
						<S.TableBodyColumn colSpan={2} style={{ textAlign: 'center' }}>
							{integratedAssessmentDesireHousingEnvironmentQuestions[10].label}
							{showGenerateRequiredFields &&
								integratedAssessmentDesireHousingEnvironmentQuestions[10].required?.includes(
									'generate',
								) && <CRRequiredMark />}
						</S.TableBodyColumn>
						<S.TableBodyColumn>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										gap={0.8}
										type='radio'
										options={integratedAssessmentDesireHousingEnvironmentQuestions[10].answer}
										checkType='single'
										onChange={(item) => onChange(item[0]?.value)}
										value={value ? [{ label: '', value }] : []}
									/>
								)}
								name={integratedAssessmentDesireHousingEnvironmentQuestions[10].key}
								control={form.control}
							/>
						</S.TableBodyColumn>
					</S.TableBodyRow>
				</S.TableBody>
			</S.Table>
			<Controller
				render={({ field: { ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='판단 근거' isRequired={showGenerateRequiredFields}>
						<CRInput.TextArea
							ref={ref}
							placeholder='판단 근거 입력'
							onChange={onChange}
							value={value}
						/>
					</CRInputLabel>
				)}
				name='주거환경상태판단근거'
				control={form.control}
			/>
		</S.Container>
	);
}

export default IntegratedAssessmentDesireHousingEnvironmentForm;
