import dayjs from 'dayjs';

import Colors from 'common/colors';
import { displayPadTime } from 'lib/util';
import { AutomationUploadDTO } from 'types/api/common';
import { GetRoundingCompletionStatusDTO, RoundingHistoryDTO } from 'types/dto';
import {
	RoundingCompletionStatusViewType,
	RoundingHistoryViewType,
	RoundingScheduleUploadHistory,
} from 'types/view/roundingHistory';

export const roundingHistoryAdapter = (
	roundingHistroyData?: RoundingHistoryDTO[] | null,
): RoundingHistoryViewType[] => {
	if (!roundingHistroyData) return [];

	const handleRoundingHistoryData = (item: RoundingHistoryDTO) => {
		if (item.visitTypeCd === 'CMN206.80')
			return {
				...item,
				totalMin: '-',
				visitDt: '-',
				visitTime: '-',
				remark: '',
				data: item,
			};
		return {
			...item,
			totalMin: item.totalMin ? `${item.totalMin}분` : '-',
			visitDt: item.visitDt ? dayjs(item.visitDt).format('YYYY.MM.DD') : '-',
			visitTime:
				item.visitStartTime && item.visitEndTime
					? `${displayPadTime(item.visitStartTime)} ~ ${displayPadTime(item.visitEndTime)}`
					: '-',
			remark: item?.remark
				? item.remark.replace(/\d{4}-\d{2}-\d{2}/g, (date) => dayjs(date).format('YYYY.MM.DD'))
				: '',
			data: item,
		};
	};

	return roundingHistroyData.map(handleRoundingHistoryData);
};

export const roundingHistoryAutomationUploadAdapter = (data: AutomationUploadDTO[] | null) =>
	(data ?? []).map(
		(item) =>
			({
				id: item.uploadHistId,
				status: item.uploadStateCd,
				type: item.uploadTypeNm,
				requestDate: item.uploadStartDate
					? dayjs(item.uploadStartDate).format('YYYY.MM.DD HH:mm')
					: '-',
				completeDate: item.uploadEndDate
					? dayjs(item.uploadEndDate).format('YYYY.MM.DD HH:mm')
					: '-',
				uploader: item.createdNm,
				uploadResultDesc: item.uploadResultDesc,
				totalCnt: item.uploadTotalCnt,
				uploadTargetYm: dayjs(item.uploadTargetYm, 'YYYYMM').format('YYYY년 MM월'),
				completeCnt: item.uploadCompleteCnt ?? 0,
				errorCnt: item.uploadRemainCnt ?? 0,
			}) as RoundingScheduleUploadHistory,
	);
export const roundingCompletionStatusAdapter = (
	data: GetRoundingCompletionStatusDTO | null,
): RoundingCompletionStatusViewType[] => {
	if (!data) return [];

	const calculateCompletionRatio = (completed: number, notVisited: number) => {
		const total = completed + notVisited;
		return total === 0 ? 0 : Math.round((completed / total) * 100);
	};

	const createStatusItem = (
		category: string,
		completedCount: number,
		notVisitedCount: number,
	): RoundingCompletionStatusViewType => ({
		category,
		completionRatio: calculateCompletionRatio(completedCount, notVisitedCount),
		legendList: [
			{
				color: Colors.green,
				legend: '완료',
				value: completedCount,
			},
			{
				color: Colors.gray90,
				legend: '미완료',
				value: notVisitedCount,
			},
		],
	});

	return [
		createStatusItem(
			'방문요양',
			data.visitCareVisitCompletedCnt || 0,
			data.visitCareNotVisitedCnt || 0,
		),
		createStatusItem(
			'가족요양',
			data.familyCareVisitCompletedCnt || 0,
			data.familyCareNotVisitedCnt || 0,
		),
		createStatusItem(
			'방문목욕',
			data.bathCareVisitCompletedCnt || 0,
			data.bathCareNotVisitedCnt || 0,
		),
	];
};
