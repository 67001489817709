import React, { useEffect, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import dayjs from 'dayjs';

import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import { useEDocIssueInfoBefore } from 'lib/hook/react-query/query/edoc';
import { EmployeeBaseInfoDTO, EmployeeDTO } from 'types/api/employee';
import { ForeignConfirmFormTypes, PledgeFormTypes } from 'types/view/eDoc';

import * as S from './styles';

interface Props {
	templateCode: string;
	currentEmployee?: EmployeeDTO;
	formContext: UseFormReturn<ForeignConfirmFormTypes, any, undefined>;
	currentEmployeeBase?: EmployeeBaseInfoDTO;
}

// 서약서
export default function centerAddrEDocForeignConfirmForm({
	templateCode,
	currentEmployee,
	currentEmployeeBase,
	formContext,
}: Props) {
	const { data: eDocBeforeIssueInfo } = useEDocIssueInfoBefore({
		employeeId: currentEmployee?.employeeId,
		centerId: currentEmployee?.centerId,
		templateCode,
	});

	useEffect(() => {
		const defaultForm: ForeignConfirmFormTypes = {
			date: dayjs().set('h', 0).set('m', 0).set('s', 0).toDate(),
		};
		if (eDocBeforeIssueInfo) {
			defaultForm.data = eDocBeforeIssueInfo;
		}
		if (currentEmployeeBase?.rsdnNo) {
			defaultForm.rsdnNo = currentEmployeeBase.rsdnNo;
			const gender = ['1', '3', '5', '7'].includes(currentEmployeeBase.rsdnNo?.[6]) ? '남' : '여';
			defaultForm.gender = [{ label: gender, value: gender }];
		}
		if (currentEmployee?.korMemberNm) {
			defaultForm.engNm = currentEmployee.korMemberNm;
		}
		if (currentEmployee?.birthDt) {
			defaultForm.birthDt = currentEmployee?.birthDt;
		}

		formContext.reset(defaultForm);
	}, [eDocBeforeIssueInfo, currentEmployeeBase, currentEmployee]);

	return (
		<S.Container>
			<S.SubFormContainer>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='가입 유형' isRequired>
							<CRCheckBoxGroup
								style={{
									width: 'auto',
								}}
								checkType='single'
								type='radio'
								onChange={onChange}
								appearanceType='button'
								gap={0.8}
								value={value}
								options={[
									{
										label: '가입',
										value: true,
									},
									{
										label: '가입탈퇴',
										value: false,
									},
								]}
							/>
						</CRInputLabel>
					)}
					name='joinType'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='신청인 영문 성명' isRequired>
							<CRInput.Default
								value={value}
								onChange={onChange}
								placeholder='신청인 영문 성명 입력'
							/>
						</CRInputLabel>
					)}
					name='engNm'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='신청인 한글 성명' isRequired>
							<CRInput.Default
								value={value}
								onChange={onChange}
								placeholder='신청인 한글 성명 입력'
							/>
						</CRInputLabel>
					)}
					name='korNm'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='성별' isRequired>
							<CRCheckBoxGroup
								style={{
									width: 'auto',
								}}
								checkType='single'
								type='radio'
								onChange={onChange}
								appearanceType='button'
								gap={0.8}
								value={value}
								options={[
									{
										label: '남',
										value: '남',
									},
									{
										label: '여',
										value: '여',
									},
								]}
							/>
						</CRInputLabel>
					)}
					name='gender'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='외국인등록번호' isRequired>
							<CRInput.Default
								maxLength={13}
								value={value}
								onChange={onChange}
								placeholder='외국인등록번호 입력'
							/>
						</CRInputLabel>
					)}
					name='rsdnNo'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='생년월일' isRequired>
							<CRInput.Default
								type='number'
								maxLength={8}
								value={value}
								onChange={onChange}
								placeholder='생년월일 입력'
								addOnBottom='8자리 입력 (예:19850101)'
							/>
						</CRInputLabel>
					)}
					name='birthDt'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='국적' isRequired>
							<CRInput.Default value={value} onChange={onChange} placeholder='국적 입력' />
						</CRInputLabel>
					)}
					name='countryNm'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='체류자격' isRequired>
							<CRInput.Default value={value} onChange={onChange} placeholder='체류자격 입력' />
						</CRInputLabel>
					)}
					name='stayQualificationNm'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='신청일' isRequired>
							<CRInput.DatePicker
								popupPosition={{
									x: 0,
									y: -250,
								}}
								value={value}
								onChangeValue={onChange}
								placeholder='신청일 입력'
							/>
						</CRInputLabel>
					)}
					name='date'
					control={formContext.control}
				/>
			</S.SubFormContainer>
		</S.Container>
	);
}
