import React from 'react';

import useCenterInfo from 'lib/hook/view/centerInformation/useCenterInfo';

import MyCenterPcorpCertForm from './MyCenterPcorpCertForm';
import MyCenterPcorpCertView from './MyCenterPcorpCertView';

function MyCenterPcorpCertFormView(): React.ReactElement {
	const { isEdit } = useCenterInfo();
	return isEdit ? <MyCenterPcorpCertForm /> : <MyCenterPcorpCertView />;
}

export default MyCenterPcorpCertFormView;
