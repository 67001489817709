import { useContext } from 'react';

import { FullTimeEmployeePageContext } from 'lib/provider/fullTimeEmployee';

export default function useFullTimeEmployeePage() {
	const context = useContext(FullTimeEmployeePageContext);
	if (!context) {
		throw new Error('useFullTimeEmployeePage must be used within a FullTimeEmployeePageContext');
	}
	return context;
}
