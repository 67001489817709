import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Dayjs } from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { Toast } from 'components/base/CRToast';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { commonCodeAdapter } from 'lib/adapter/common';
import {
	useCommonCodes,
	useDeleteEmployeeVacation,
	useSaveEmployeeVacation,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { FtimeEmployeeAnnualStateResponseDTO } from 'types/dto';
import { EmployeeVacationFormType, EmployeeWorkInfoViewType } from 'types/view/employee';
import { EmployeeVacationInfoViewType } from 'types/view/workSchedule';

import DeleteDialog from '../DeleteDialog';
import * as S from './styles';

interface Props {
	targetDate: Dayjs;
	vacationDataOfTargetDate: EmployeeVacationInfoViewType;
	annualStateInfo: FtimeEmployeeAnnualStateResponseDTO;
	currentEmployee?: EmployeeWorkInfoViewType;
}

function VacationUpdateDialog({
	targetDate,
	vacationDataOfTargetDate,
	annualStateInfo,
	currentEmployee,
}: Props): React.ReactElement {
	const { showDialog, hideDialog } = useDialog();

	const { data: commonCodes = { CMN195: [] } } = useCommonCodes(
		{ comCdGroupNms: ['CMN195'] },
		commonCodeAdapter,
	);

	const { mutate: updateVacation } = useSaveEmployeeVacation((client, data) => {
		if (!data?.centerId) {
			Toast.error('휴가 등록에 실패하였습니다. 잠시 후에 다시 시도해주세요.');
		} else {
			Toast.success('휴가 등록이 완료되었습니다.');
			client.invalidateQueries([
				endpoint.getEmployeeWorkHistory.key,
				{
					employeeId: currentEmployee?.employeeId,
					yearMonth: targetDate.format('YYYYMM'),
				},
			]);
			hideDialog();
		}
	});

	const { mutate: deleteVacation } = useDeleteEmployeeVacation((client, data) => {
		if (!data?.centerId) {
			Toast.error('휴가 삭제에 실패하였습니다. 잠시 후에 다시 시도해주세요.');
		} else {
			Toast.success('휴가 삭제가 완료되었습니다.');
			client.invalidateQueries([
				endpoint.getEmployeeWorkHistory.key,
				{
					employeeId: currentEmployee?.employeeId,
					yearMonth: targetDate.format('YYYYMM'),
				},
			]);
			hideDialog();
		}
	});

	const { control, getValues, watch, handleSubmit } = useForm<EmployeeVacationFormType>({
		mode: 'onChange',
		defaultValues: {
			vacationCategory: vacationDataOfTargetDate.categoryOfVacation,
			vacationRemark: vacationDataOfTargetDate.data.remark ?? '',
			halfDayOfSelection: [
				vacationDataOfTargetDate.data.startTime === '0900'
					? { label: '오전', value: '오전' }
					: { label: '오후', value: '오후' },
			],
		},
	});

	const vacationCategoryList = commonCodes.CMN195.filter(
		(item) =>
			item.value === 'CMN195.100' || item.value === 'CMN195.120' || item.value === 'CMN195.999',
	);

	const isRequiredRemark = useMemo(() => {
		if (!getValues('vacationCategory')) return false;
		if (getValues('vacationCategory').value === 'CMN195.999') return true;
		return false;
	}, [watch('vacationCategory')]);

	const disabled = useMemo(
		() =>
			!getValues('vacationCategory') ||
			(getValues('vacationCategory').value === 'CMN195.999' && !getValues('vacationRemark')),
		[watch()],
	);

	const handleDeleteVacationData = () => {
		if (!currentEmployee || !vacationDataOfTargetDate.data.workScheduleId) {
			Toast.error('휴가 삭제에 실패하였습니다. 잠시 후에 다시 시도해주세요.');
			return;
		}
		deleteVacation({
			centerId: currentEmployee?.centerId,
			yearMonth: targetDate.format('YYYYMM'),
			employeeId: currentEmployee?.employeeId,
			workScheduleDt: targetDate.format('YYYYMMDD'),
			workScheduleId: vacationDataOfTargetDate.data.workScheduleId,
		});
		hideDialog();
	};

	const handleClickDeleteButton = () => {
		showDialog(() => (
			<DeleteDialog
				title='휴가 일정 삭제'
				content={`${targetDate.format('M월 D일자')} 휴가 일정을 삭제하시겠습니까?`}
				hideDialog={hideDialog}
				cancelOption={{ text: '취소' }}
				successOption={{
					text: '삭제',
					successCallback: handleDeleteVacationData,
				}}
			/>
		));
	};

	const onSubmit = (data: EmployeeVacationFormType) => {
		if (!currentEmployee) {
			Toast.error('휴가 등록에 실패하였습니다. 잠시 후에 다시 시도해주세요.');
			return;
		}
		const vacationStartTime = data.halfDayOfSelection[0]?.label === '오후' ? '1330' : '0900';
		const vacationEndTime = data.halfDayOfSelection[0]?.label === '오전' ? '1330' : '1800';
		const vacaUseDayCnt =
			data.vacationCategory?.value === 'CMN195.120'
				? 0.5
				: data.vacationCategory?.value === 'CMN195.999'
					? 0
					: 1;
		const workHourCnt = data.vacationCategory?.value === 'CMN195.120' ? 240 : 480;
		const restHourCnt = data.vacationCategory?.value === 'CMN195.120' ? 30 : 60;
		const totalVacationUseDayCnt = vacaUseDayCnt - vacationDataOfTargetDate.data.vacaUseDayCnt;

		if ((annualStateInfo.annualRemainDayCnt ?? 0) - totalVacationUseDayCnt < 0) {
			Toast.error('잔여 연차가 부족하여 휴가를 등록할 수 없습니다.');
			return;
		}

		const currentRequestBody = {
			centerId: currentEmployee.centerId,
			yearMonth: targetDate.format('YYYYMM'),
			employeeId: currentEmployee.employeeId,
			workScheduleDivCd: 'CMN193.20',
			vacaKindCd: data.vacationCategory.value,
			workScheduleDt: targetDate.format('YYYYMMDD'),
			startTime: vacationStartTime,
			endTime: vacationEndTime,
			workHourCnt,
			restHourCnt,
			overWorkTimeCnt: 0,
			vacaUseDayCnt,
			remark: data.vacationRemark,
			workScheduleId: vacationDataOfTargetDate.data.workScheduleId,
		};

		updateVacation({ body: currentRequestBody });
	};

	return (
		<CRDialog
			title={`${targetDate.format('M월 D일')} 휴가 수정`}
			onClickClose={hideDialog}
			type='S'
			body={
				<S.Container>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInputLabel label='휴가 종류' isRequired>
								<CRInput.Selector
									currentValue={value}
									onChangeValue={onChange}
									placeholder='휴가 종류 선택'
									items={vacationCategoryList}
								/>
							</CRInputLabel>
						)}
						name='vacationCategory'
						control={control}
					/>
					{getValues('vacationCategory')?.value === 'CMN195.120' ? (
						<CRInputLabel label='시간' isRequired>
							<S.HolidayTimeContainer>
								<Controller
									render={({ field: { value, onChange } }) => (
										<CRCheckBoxGroup
											checkType='single'
											type='radio'
											gap={0.8}
											onChange={onChange}
											value={value}
											options={[
												{ label: '오전', value: '오전' },
												{ label: '오후', value: '오후' },
											]}
											appearanceType='button'
										/>
									)}
									name='halfDayOfSelection'
									control={control}
								/>
							</S.HolidayTimeContainer>
						</CRInputLabel>
					) : null}
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInputLabel label='비고' isRequired={isRequiredRemark}>
								<CRInput.Default
									value={value}
									onChange={onChange}
									placeholder='비고 입력'
									addOnBottom={isRequiredRemark ? '기타 휴가 시 사유 필수' : ''}
								/>
							</CRInputLabel>
						)}
						name='vacationRemark'
						control={control}
					/>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default
						type='outlined'
						palette='primary'
						size='default'
						onClick={handleClickDeleteButton}>
						삭제
					</CRButton.Default>
					<S.RightButtonContainer>
						<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
							취소
						</CRButton.Default>
						<CRButton.Default
							palette='primary'
							size='default'
							disabled={disabled}
							onClick={handleSubmit(onSubmit)}>
							등록
						</CRButton.Default>
					</S.RightButtonContainer>
				</S.ButtonContainer>
			}
		/>
	);
}

export default React.memo(VacationUpdateDialog);
