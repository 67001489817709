import React, { useEffect } from 'react';

import CRDialog from 'components/base/CRDialog';
import { CRText } from 'components/base/CRText';
import { useKaKaoMap } from 'lib/hook/map/useKakaoMap';
import useDialog from 'lib/hook/util/useDialog';

import * as S from './styles';

type LocationInfo = {
	latitude?: number;
	longitude?: number;
	ip?: string;
};

type Props = {
	workStartLocationInfo: LocationInfo;
	workEndLocationInfo: LocationInfo;
};

function WorkLogLocationDialog({
	workStartLocationInfo,
	workEndLocationInfo,
}: Props): React.ReactElement {
	const { hideDialog } = useDialog();
	const { getAddress } = useKaKaoMap();

	const [workStartLocationAddress, setWorkStartLocationAddress] = React.useState('');
	const [workEndLocationAddress, setWorkEndLocationAddress] = React.useState('');

	const getAddressByCoordinate = async (latitude: string, longitude: string) => {
		const result = await getAddress({ x: latitude, y: longitude });
		return result;
	};

	const getAddressInfo = async () => {
		const startAddress =
			!!workStartLocationInfo?.latitude && !!workStartLocationInfo?.longitude
				? await getAddressByCoordinate(
						workStartLocationInfo.latitude.toString(),
						workStartLocationInfo.longitude.toString(),
					)
				: null;
		const endAddress =
			!!workEndLocationInfo?.latitude && !!workEndLocationInfo?.longitude
				? await getAddressByCoordinate(
						workEndLocationInfo.latitude.toString(),
						workEndLocationInfo.longitude.toString(),
					)
				: null;

		setWorkStartLocationAddress(startAddress);
		setWorkEndLocationAddress(endAddress);
	};

	const handleAddressInfo = ({
		title,
		address,
		longitude,
		latitude,
		ip,
	}: {
		title: string;
		address?: string;
		longitude?: string;
		latitude?: string;
		ip?: string;
	}) => {
		if (!address && !longitude && !latitude && !ip) {
			return null;
		}
		return (
			<S.SectionContainer>
				<CRText text={title} typography='bodyB' color='gray10' />
				<S.LocationContainer>
					{address ? (
						<S.LabelContainer>
							<CRText text='주소' typography='label' color='gray60' style={{ width: '5rem' }} />
							<CRText text={address} typography='label' color='gray10' />
						</S.LabelContainer>
					) : (
						<>
							{latitude ? (
								<S.LabelContainer>
									<CRText text='위도' typography='label' color='gray60' style={{ width: '5rem' }} />
									<CRText text={latitude} typography='label' color='gray10' />
								</S.LabelContainer>
							) : null}
							{longitude ? (
								<S.LabelContainer>
									<CRText text='경도' typography='label' color='gray60' style={{ width: '5rem' }} />
									<CRText text={longitude} typography='label' color='gray10' />
								</S.LabelContainer>
							) : null}
						</>
					)}

					{ip ? (
						<S.LabelContainer>
							<CRText text='접속IP' typography='label' color='gray60' style={{ width: '5rem' }} />
							<CRText text={workStartLocationInfo.ip} typography='label' color='gray10' />
						</S.LabelContainer>
					) : null}
				</S.LocationContainer>
			</S.SectionContainer>
		);
	};

	console.log('workStartLocationAddress', workStartLocationAddress);

	useEffect(() => {
		getAddressInfo();
	}, [workStartLocationInfo, workEndLocationInfo]);

	return (
		<CRDialog
			title='위치정보'
			onClickClose={hideDialog}
			body={
				<S.Container>
					{handleAddressInfo({
						title: '출근',
						address: workStartLocationAddress,
						latitude: workStartLocationInfo?.latitude?.toString() ?? '',
						longitude: workStartLocationInfo?.longitude?.toString() ?? '',
						ip: workStartLocationInfo.ip,
					})}
					{handleAddressInfo({
						title: '퇴근',
						address: workEndLocationAddress,
						latitude: workEndLocationInfo?.latitude?.toString() ?? '',
						longitude: workEndLocationInfo?.longitude?.toString() ?? '',
						ip: workEndLocationInfo.ip,
					})}
				</S.Container>
			}
		/>
	);
}

export default React.memo(WorkLogLocationDialog);
