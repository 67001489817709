import React from 'react';
import { Controller } from 'react-hook-form';

import CRInput from 'components/base/CRInput';
import CRRsdNumber from 'components/base/CRRsdNumber';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import { DaumPostcodeData } from 'lib/hook/util/useDaumPostCode';
import useContractRenewalTask from 'lib/hook/view/contract/useContractRenewalTask';

import CRRequiredMark from '../../../base/CRRequiredMark';
import * as S from './styles';

function RenewalRecipientBasicForm(): React.ReactElement {
	const { form, commonCodes, disabled } = useContractRenewalTask();

	const handleChangeBaseAddress = (postcodeData: DaumPostcodeData) => {
		form?.setValue('recipient.base.addressInfo.basAddress', postcodeData.address);
		form?.setValue('recipient.base.addressInfo.zipCode', postcodeData.zonecode);
		form?.setValue(`recipient.base.addressInfo.roadNm`, postcodeData.roadname);
		form?.setValue(`recipient.base.addressInfo.regionNm1`, postcodeData.sido);
		form?.setValue(`recipient.base.addressInfo.regionNm2`, postcodeData.sigungu);
		form?.setValue(`recipient.base.addressInfo.regionNm3`, postcodeData.bname);
	};

	const handleChangeDetailAddress = (detailAddress: string) => {
		form?.setValue('recipient.base.addressInfo.detailAddress', detailAddress);
	};

	return (
		<S.Container>
			<S.Form>
				<S.Table>
					<S.TableRow>
						<S.TableLabelColumn style={{ width: '10.4rem' }}>
							이름 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableLabelColumn style={{ width: '24rem' }}>
							{form?.getValues('recipient.base.recipientNm')}
						</S.TableLabelColumn>
						<S.TableLabelColumn rowSpan={2} style={{ width: '10.4rem' }}>
							주소 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '24rem', verticalAlign: 'middle' }} rowSpan={2}>
							<CRInput.Address
								onChangeBaseAddress={handleChangeBaseAddress}
								onChangeDetailAddress={handleChangeDetailAddress}
								baseAddress={form?.watch('recipient.base.addressInfo.basAddress')}
								detailAddress={form?.watch('recipient.base.addressInfo.detailAddress')}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn style={{ width: '10.4rem' }}>
							주민등록번호 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableLabelColumn>
							<CRRsdNumber
								rdsNumber={form?.getValues('recipient.base.rsdnNo')}
								textColor='gray60'
							/>
						</S.TableLabelColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn style={{ width: '10.4rem' }}>
							생년월일 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '24rem' }}>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRInput.TableInput
										ref={ref}
										onBlur={onBlur}
										onChange={onChange}
										value={value}
										placeholder='예시) 19280301'
										maxLength={8}
										disabled={disabled}
									/>
								)}
								name='recipient.base.birthDt'
								control={form?.control}
							/>
						</S.TableValueColumn>
						<S.TableLabelColumn style={{ width: '9.6rem' }} rowSpan={2}>
							비고
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '24rem', verticalAlign: 'middle' }} rowSpan={2}>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRInput.TextArea
										typography='label'
										ref={ref}
										onBlur={onBlur}
										onChange={onChange}
										value={value}
										placeholder='비고 입력'
										numberOfLines={2}
										disabled={disabled}
									/>
								)}
								name='recipient.base.specialDesc'
								control={form?.control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn style={{ width: '10.4rem' }}>
							서명 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '24rem' }}>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => {
									const selectedOption = (commonCodes?.CMN143 ?? []).find(
										(option) => option.value === value,
									);
									return (
										<CRCheckBoxGroup
											ref={ref}
											checkType='single'
											type='radio'
											gap={0.8}
											value={selectedOption && [selectedOption]}
											onChange={(value: CheckOption[]) => {
												onChange(value[0].value);
											}}
											options={commonCodes?.CMN143 ?? []}
											disabled={disabled}
										/>
									);
								}}
								name='recipient.base.signMethodCd'
								control={form?.control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
				</S.Table>
			</S.Form>
		</S.Container>
	);
}

export default RenewalRecipientBasicForm;
