import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { CheckOption } from 'components/base/Selections/type';
import { PageInfo } from 'types/view/base';

export type FiltersType = {
	[key: string]: CheckOption[];
};

const usePageFilter = (
	initialFilters: FiltersType = {},
	initialPageInfo: PageInfo = {
		size: 50,
		page: 1,
		totalPages: 0,
	},
) => {
	const location = useLocation(); // 현재 경로 가져오기
	const pageKey = location.pathname; // 페이지 경로를 고유한 키로 사용

	const [filters, setFilters] = useState<FiltersType>(() => {
		// 초기에 세션 스토리지에서 필터값 불러오기
		const savedFilters = sessionStorage.getItem(`filters-${pageKey}`);
		const parsedFilters = savedFilters ? JSON.parse(savedFilters) : {};
		return parsedFilters?.filters || initialFilters;
	});

	const [pageInfo, setPageInfo] = useState<PageInfo>(() => {
		// 초기에 세션 스토리지에서 필터값 불러오기
		const savedFilters = sessionStorage.getItem(`filters-${pageKey}`);
		const parsedFilters = savedFilters ? JSON.parse(savedFilters) : {};
		return parsedFilters?.pageInfo || initialPageInfo;
	});

	// 필터값이 변경될 때마다 세션 스토리지에 저장
	useEffect(() => {
		sessionStorage.setItem(
			`filters-${pageKey}`,
			JSON.stringify({
				filters,
				pageInfo,
			}),
		);
	}, [filters, pageInfo, pageKey]);

	return { filters, setFilters, pageInfo, setPageInfo } as const;
};

export default usePageFilter;
