import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow: overlay;
`;

export const ContentContainer = styled.div`
	width: 100%;
	height: 100%;
	padding: 2.4rem;
	overflow: hidden;
`;

export const ContentBackground = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${(props) => props.theme.colors.white};
	border-radius: ${(props) => props.theme.radius.radius01};
	padding: 0 2.4rem;
`;

export const BreadCrumbContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.4rem 2.4rem;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const ContentHeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 2.4rem;
`;

export const InformationContainer = styled.div`
	height: calc(100% - 6.4rem);
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.4rem;
`;

export const DialogBodyContainer = styled.div`
	// flex: 1;
	width: 100%;
	// display: flex;
	// flex-direction: column;
	// max-height: calc(100vh - 4.8rem);
	padding: 0 2.4rem 1.6rem;
`;

export const LeftContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	height: 100%;
	box-sizing: border-box;
`;

export const TableContainer = styled.div`
	height: 100%;
	overflow: hidden;
	border-radius: 0.4rem;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const RightTableContainer = styled.div``;

export const RightTabContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`;

export const RightTabViewContainer = styled.div`
	flex: 1;
	overflow: auto;
	border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const TabView = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	overflow: hidden;
`;

export const SendButtonContainer = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray50};
	display: flex;
	align-items: center;
	gap: 1.6rem;
`;

export const CustomHeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 2.4rem;
	margin-bottom: 0.8rem;
`;

export const CalendarCustomHeaderContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const DropdownItem = styled.div`
	${(props) => props.theme.typography.body};
	display: flex;
	gap: 0.8rem;
	align-items: center;
	width: 13.1rem;
	min-height: 4.8rem;
	height: fit-content;
	user-select: none;
	padding: 0 1.6rem 0;
	box-sizing: border-box;
	cursor: pointer;

	&:hover {
		background: ${(props) => props.theme.colors.gray95};
	}
`;

export const Circle = styled.div<{ $color: string }>`
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 50%;
	background: ${(props) => props.$color};
`;

export const AddWorkHistoryButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0;
`;
