import React, { useCallback, useEffect, useMemo, useState } from 'react';

import CRPortal from 'components/base/CRPortal';
import QuickSearchDialog from 'components/domain/dialog/QuickSearchDialog';
import { getOS } from 'lib/util';

import * as S from './styles';

export interface IQuickSearchContext {
	showQuickSearch: () => void;
	hideQuickSearch: () => void;
}

export const QuickSearchContext = React.createContext<IQuickSearchContext>({
	showQuickSearch: () => {},
	hideQuickSearch: () => {},
});

function QuickSearchProvider({ children }: React.PropsWithChildren): React.ReactElement {
	const os = useMemo(() => getOS(), []);
	const [searchText, setSearchText] = useState('');
	const [searchItems, setSearchItems] = useState<any[]>([]);
	const [isShow, setIsShow] = useState(false);

	const hideQuickSearch = () => {
		setIsShow(false);
	};

	const showQuickSearch = () => {
		setIsShow(true);
	};

	const handleKeyPress = useCallback(
		(event: KeyboardEvent) => {
			if (os === 'macos' && event.metaKey && event.code === 'KeyK') {
				setIsShow((prev) => !prev);
				setSearchText('');
				setSearchItems([]);
			}
			if (os !== 'macos' && event.ctrlKey && event.code === 'KeyK') {
				setIsShow((prev) => !prev);
				setSearchText('');
				setSearchItems([]);
			}
			if (event.code === 'Escape') {
				if (searchText) {
					setSearchText('');
					setSearchItems([]);
				} else {
					setIsShow(false);
				}
			}
		},
		[searchText],
	);

	const handleChangeSearchText = (_searchText: string) => {
		setSearchText(_searchText);
		setSearchItems([1, 2, 3, 4, 5]);
	};

	useEffect(() => {
		document.addEventListener('keydown', handleKeyPress);

		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, [handleKeyPress]);

	const value = useMemo(
		() => ({ showQuickSearch, hideQuickSearch }),
		[showQuickSearch, hideQuickSearch],
	);

	return (
		<QuickSearchContext.Provider value={value}>
			{children}
			{isShow && (
				<CRPortal>
					<S.Container>
						<S.Background onClick={hideQuickSearch} />
						<S.QuickSearchContainer>
							<QuickSearchDialog
								searchText={searchText}
								onChangeSearchText={handleChangeSearchText}
								searchItems={searchItems}
							/>
						</S.QuickSearchContainer>
					</S.Container>
				</CRPortal>
			)}
		</QuickSearchContext.Provider>
	);
}

export default QuickSearchProvider;
