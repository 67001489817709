import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

import * as Pop from '@radix-ui/react-popover';
import { ko } from 'date-fns/esm/locale';
import dayjs from 'dayjs';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import RdPopover from 'components/ui/radix/popover/RdPopover';
import { FilterType } from 'types/view/filter';

import * as S from './styles';

interface IProps<T extends { label: string; value: any }> {
	currentValue?: T[];
	placeholder?: string;
	filterKey?: string;
	disabled?: boolean;
	selected?: Date;
	minDate?: Date;
	maxDate?: Date;
	monthsShown?: number;
	scrollToNowMonth?: boolean;
	singleMonth?: boolean;
	onChangeValue?: (item: T, key: string, filterType: FilterType) => void;
}

enum DateFlag {
	Start = 'start',
	End = 'end',
}
function CalendarChip<T extends { label: string; value: any }>({
	currentValue = [],
	placeholder = '',
	filterKey = '',
	disabled = false,
	minDate,
	maxDate,
	selected,
	onChangeValue,
	monthsShown = 13,
	singleMonth = false,
	scrollToNowMonth = true,
}: IProps<T>): React.ReactElement {
	const value = useMemo(
		() => ({
			start: currentValue?.[0]?.value?.start || null,
			end: currentValue?.[0]?.value?.end || null,
		}),
		[currentValue],
	);

	const [showOptions, setShowOptions] = useState(false);
	const [date, setDate] = useState<{ start: Date | null; end: Date | null }>({
		start: null,
		end: null,
	});

	const [dateString, setDateString] = useState({
		start: '',
		end: '',
	});

	const filterRef = useRef<HTMLDivElement>(null);
	const datePickerRef = useRef<HTMLDivElement>(null);
	const nowMonthRef = useRef<HTMLSpanElement>(null);

	const isActive = useMemo(() => currentValue?.[0]?.value, [currentValue]);

	const renderText = () => {
		let label = '';
		if (currentValue?.length && currentValue[0]?.value) {
			const start = dayjs(currentValue[0].value.start);
			const end = dayjs(currentValue[0].value.end);
			const isSameDate = start.diff(end, 'day') === 0;

			const result = `${placeholder} : ${
				isSameDate
					? dayjs(currentValue[0].value.start).format('YY.MM.DD')
					: `${dayjs(currentValue[0].value.start).format('YY.MM.DD')} → ${dayjs(
							currentValue[0].value.end,
						).format('YY.MM.DD')}`
			}`;
			label = result;
		} else {
			label = placeholder;
		}
		return label;
	};

	const getTotalMonth = () => {
		if (date.start && date.end) {
			const diff = dayjs(date.end).diff(dayjs(date.start), 'month') + 2;
			if (diff > 30) {
				return 30;
			}
			return diff > 9 ? diff : 10;
		}
		return 12;
	};

	const renderCustomHeader = (date: Date) => {
		const isCurrentMonth =
			date.getFullYear() === dayjs().year() && date.getMonth() === dayjs().month();
		return (
			<span ref={isCurrentMonth ? nowMonthRef : undefined}>{`${date.getFullYear()} ${
				date.getMonth() + 1
			}월`}</span>
		);
	};
	const renderCustomDay = (dayOfMonth: number) => <S.CustomDay>{dayOfMonth}</S.CustomDay>;

	const onSelectDate = (dates: any) => {
		const [start, end] = dates;
		setDate({
			start,
			end,
		});
		setDateString({
			start: start ? dayjs(start).format('YYYYMMDD') : '',
			end: end ? dayjs(end).format('YYYYMMDD') : '',
		});
	};

	const onChangeDate = (value: string, flagType: DateFlag) => {
		const result = value.replaceAll('.', '');
		setDateString({
			...dateString,
			[flagType]: value,
		});
		if (result.length === 8) {
			const isValid = dayjs(result).isValid();
			if (isValid) {
				setDate({
					...date,
					[flagType]: dayjs(result).toDate(),
				});
			}
		}
	};

	const isValidDate = useMemo(
		() =>
			(dayjs(date.start).isValid() &&
				dayjs(date.end).isValid() &&
				dayjs(date.end).diff(dayjs(date.start)) >= 0) ??
			false,
		[date],
	);

	const onClickApply = () => {
		if (isValidDate) {
			onChangeValue?.(
				{
					label: filterKey,
					value: {
						start: date.start,
						end: date.end,
					},
				} as T,
				filterKey,
				'calendar',
			);

			setShowOptions(false);
		}
	};

	const onClickInit = (e: MouseEvent) => {
		if (disabled) return;
		e.stopPropagation();
		onChangeValue?.(
			{
				label: filterKey,
				value: undefined,
			} as T,
			filterKey,
			'calendar',
		);
		setDate({
			start: null,
			end: null,
		});
		setDateString({
			start: '',
			end: '',
		});
	};

	const isValidate = useMemo(() => !!(date.start || (date.start && date.end)), [date]);

	const renderCalendarPopup = () => (
		<S.PopupContainer>
			<S.CustomHeader>
				<S.Range>
					<CRInput.Default
						type='number'
						maxLength={8}
						onChange={(value) => onChangeDate(value, DateFlag.Start)}
						placeholder='시작일'
						value={dateString.start}
					/>
					<CRInput.Default
						type='number'
						maxLength={8}
						onChange={(value) => onChangeDate(value, DateFlag.End)}
						placeholder='종료일'
						value={dateString.end}
					/>
				</S.Range>
				<S.CustomCaption>
					{['일', '월', '화', '수', '목', '금', '토'].map((dayText) => (
						<S.CaptionText key={dayText}>{dayText}</S.CaptionText>
					))}
				</S.CustomCaption>
			</S.CustomHeader>
			<S.CalendarContainer ref={datePickerRef}>
				<DatePicker
					locale={ko}
					selectsRange
					selected={selected || date.start}
					startDate={date.start}
					endDate={date.end}
					onChange={(dates) => onSelectDate(dates)}
					shouldCloseOnSelect={false}
					minDate={minDate}
					maxDate={maxDate}
					inline
					renderCustomHeader={({ monthDate }) => (
						<S.CustomName>{renderCustomHeader(monthDate)}</S.CustomName>
					)}
					renderDayContents={renderCustomDay}
					monthsShown={singleMonth ? 1 : monthsShown || getTotalMonth()}
				/>
			</S.CalendarContainer>
			<S.ButtonSection>
				<Pop.Close
					style={{
						background: 'transparent',
						border: 0,
						padding: 0,
						margin: 0,
						cursor: 'pointer',
					}}>
					<CRButton.Default type='text' palette='gray'>
						취소
					</CRButton.Default>
				</Pop.Close>
				{isValidate ? (
					<Pop.Close
						style={{
							background: 'transparent',
							border: 0,
							padding: 0,
							margin: 0,
							cursor: 'pointer',
						}}>
						<CRButton.Default
							type='filled'
							palette='primary'
							disabled={!isValidate}
							onClick={onClickApply}>
							적용
						</CRButton.Default>
					</Pop.Close>
				) : (
					<CRButton.Default
						type='filled'
						palette='primary'
						disabled={!isValidate}
						onClick={onClickApply}>
						적용
					</CRButton.Default>
				)}
			</S.ButtonSection>
		</S.PopupContainer>
	);

	useEffect(() => {
		if (nowMonthRef.current && scrollToNowMonth && showOptions) {
			nowMonthRef.current?.scrollIntoView();
		}
	}, [nowMonthRef, showOptions, scrollToNowMonth]);

	return (
		<S.Container ref={filterRef}>
			<RdPopover
				showDivider={false}
				sideOffset={0}
				align='end'
				borderShape='small'
				openCallback={() => {
					setShowOptions(true);
					if (value) {
						setDate({
							start: dayjs(value.start).toDate(),
							end: dayjs(value.end).toDate(),
						});
						setDateString({
							start: value?.start ? dayjs(value.start).format('YYYYMMDD') : '',
							end: value?.end ? dayjs(value.end).format('YYYYMMDD') : '',
						});
					}
				}}
				closeCallback={() => {
					setDate({
						start: null,
						end: null,
					});
					setDateString({
						start: '',
						end: '',
					});
					setShowOptions(false);
				}}
				content={!disabled && renderCalendarPopup()}>
				<S.DefaultOption disabled={disabled} $isActive={isActive}>
					<S.ChipLabel>{renderText()}</S.ChipLabel>
					{isActive ? (
						<S.CloseIconContainer onClick={onClickInit}>
							<img src={Assets.icon.closeCircle} alt='keyboardArrowBottom' />
						</S.CloseIconContainer>
					) : (
						<S.ArrowIconContainer src={Assets.icon.keyboardArrowBottom} alt='keyboardArrowBottom' />
					)}
				</S.DefaultOption>
			</RdPopover>
		</S.Container>
	);
}

export default CalendarChip;
