import React, { useCallback, useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import RouterPath from 'common/router';
import CRPageFooter from 'components/base/CRPageFooter';
import CRPageHeader from 'components/base/CRPageHeader';
import CRScrollSpy from 'components/base/CRScrollSpy';
import CRScrollSpyContainer from 'components/base/CRScrollSpyContainer';
import TaskLayout from 'components/domain/layout/TaskLayout';
import { useMyAccountInfo } from 'lib/hook/react-query';
import { useRecipientWorkExecuteLogNew } from 'lib/hook/react-query/query/workExecuteLog';
import useFullScreen from 'lib/hook/util/useFullScreen';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import useWorkExecuteLog from 'lib/hook/view/workExecuteLog/useWorkExecuteLog';

import { generateWorkExecuteLogScrollSpy } from './constant';
import * as S from './styles';

function WorkExecuteLogNewPage(): React.ReactElement {
	const { data: myAccountInfo } = useMyAccountInfo();
	const { setCustomBackHandler } = useGlobalLayout();
	const { saveWorkExecuteLog, form, setBaseData } = useWorkExecuteLog();
	const params = useParams<{ id?: string }>();
	const navigate = useNavigate();
	const location = useLocation();

	const { data: workExecuteLogNewData } = useRecipientWorkExecuteLogNew({
		centerId: myAccountInfo?.centerId,
		recipientId: Number(params.id),
		year: String(dayjs().get('year')),
	});

	const LeftSideComponent = useMemo(() => {
		const scrollSpySections = generateWorkExecuteLogScrollSpy(navigate, form);
		const splitPathName = location.pathname.split('/');
		const lastPath = splitPathName[splitPathName.length - 1];
		return (
			<S.LeftSideComponentContainer>
				<CRScrollSpy currentKey={lastPath + location.hash} sections={scrollSpySections} />
			</S.LeftSideComponentContainer>
		);
	}, [location, navigate, form.watch()]);

	const title = useMemo(() => {
		if (location.pathname.includes('work-execute-log')) return '업무수행일지';
		return '';
	}, [location.pathname]);

	const goBackPage = () => {
		navigate(
			`${RouterPath.recipient().tab.key}/${params.id}/${
				RouterPath.recipient().workExecuteLogTab.uniqueKey
			}`,
			{
				replace: true,
			},
		);
	};

	const handleClickCancel = useCallback(() => {
		goBackPage();
	}, []);

	useFullScreen();

	useEffect(() => {
		setCustomBackHandler(() => goBackPage);
		return () => {
			setCustomBackHandler(undefined);
		};
	}, [handleClickCancel]);

	useEffect(() => {
		if (workExecuteLogNewData) {
			setBaseData(workExecuteLogNewData);
		}
	}, [workExecuteLogNewData]);

	return (
		<TaskLayout LeftSideComponent={LeftSideComponent}>
			<S.MainComponentContainer $hasTab={false}>
				<CRScrollSpyContainer offset={0}>
					<S.ContentContainer>
						<S.HeaderComponentContainer>
							<CRPageHeader headerTitle={title} />
						</S.HeaderComponentContainer>
						<Outlet />
					</S.ContentContainer>
				</CRScrollSpyContainer>
				<S.FooterContainer>
					<S.FooterContentContainer>
						<CRPageFooter
							rightButtons={[
								{
									palette: 'gray',
									buttonType: 'button',
									type: 'text',
									onClick: handleClickCancel,
									children: '취소',
								},
								{
									palette: 'primary',
									buttonType: 'button',
									type: 'filled',
									onClick: saveWorkExecuteLog,
									children: '저장',
								},
							]}
						/>
					</S.FooterContentContainer>
				</S.FooterContainer>
			</S.MainComponentContainer>
		</TaskLayout>
	);
}

export default React.memo(WorkExecuteLogNewPage);
