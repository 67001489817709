import React from 'react';

import CRTable from 'components/base/CRTable';
import { displayShortDate } from 'lib';
import { RecipientPay } from 'types/view/recipient';

import { RECIPIENT_PAY_TABLE_HEADER_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	items: RecipientPay[];
}

function RecipientPayTable({ items = [] }: Props): React.ReactElement {
	return (
		<S.Container>
			<CRTable.Root>
				<CRTable.Head heads={RECIPIENT_PAY_TABLE_HEADER_CONFIG} offset={1} />
				<CRTable.Body>
					{items.map((item, index) => (
						<CRTable.Row
							key={item.id}
							item={{ ...item, index: index + 1 }}
							renderKeys={['date', 'amount', 'type', '']}
							customRender={{ date: displayShortDate }}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default RecipientPayTable;
