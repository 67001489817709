import React, { useMemo, useState } from 'react';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRInputLabel from 'components/base/CRInputLabel';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';

import { commonCodeAdapter } from '../../../../lib/adapter/common';
import { useCommonCodes } from '../../../../lib/hook/react-query';
import { CheckOption } from '../../../base/Selections/type';
import * as S from './styles';

interface Props {
	currentItems?: CheckOption[];
	onSubmit?: (items: CheckOption[]) => void;
	hideDialog?: () => void;
}

function ContractServiceAddDialog({
	currentItems: currentItemProps,
	onSubmit,
	hideDialog,
}: Props): React.ReactElement {
	const {
		data: commonCodes = {
			CMN006: [],
		},
	} = useCommonCodes({ comCdGroupNms: ['CMN006'] }, commonCodeAdapter);
	const [currentItems, setCurrentItems] = useState<CheckOption[]>([]);

	const options = useMemo(
		() => commonCodes.CMN006.filter((item) => item.data?.etcDesc1 === 'Y'),
		[commonCodes.CMN006],
	);

	const handleChange = (value: CheckOption[]) => {
		setCurrentItems(value);
	};

	const handleSubmit = () => {
		onSubmit?.(currentItems);
		hideDialog?.();
	};

	return (
		<S.Container>
			<S.HeaderContainer>
				<S.HeaderTitle>급여 추가</S.HeaderTitle>
				<S.CloseIcon src={Assets.icon.close} alt='close' onClick={hideDialog} />
			</S.HeaderContainer>
			<S.BodyContainer>
				<S.BodyContent>
					<CRInputLabel label='급여 종류' isRequired>
						<CRCheckBoxGroup
							checkType='multiple'
							type='checkbox'
							direction='col'
							appearanceType='button'
							gap={0.4}
							options={options.filter(
								(item) => !currentItemProps?.some((target) => target.value === item.value),
							)}
							onChange={handleChange}
							value={currentItems}
						/>
					</CRInputLabel>
				</S.BodyContent>
			</S.BodyContainer>
			<S.FooterContainer>
				<CRButton.Default palette='gray' type='text' onClick={hideDialog}>
					취소
				</CRButton.Default>
				<CRButton.Default buttonType='button' onClick={handleSubmit}>
					등록
				</CRButton.Default>
			</S.FooterContainer>
		</S.Container>
	);
}

export default React.memo(ContractServiceAddDialog);
