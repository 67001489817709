import React, { useEffect, useState } from 'react';

import * as Accordion from '@radix-ui/react-accordion';

import CRPortal from 'components/base/CRPortal';
import CRTab from 'components/base/CRTab';
import { CheckOption } from 'components/base/Selections/type';
import EmployeeBasicForm from 'components/domain/form/EmployeeBasicForm';
import EmployeeSalaryForm from 'components/domain/form/EmployeeSalaryForm';
import OtherWorkingForm from 'components/domain/form/OtherWorkingForm';
import TrainingForm from 'components/domain/form/TrainingForm';
import EmptyDescription from 'components/ui/EmptyDescription';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import useContractTask from 'lib/hook/view/contract/useContractTask';

import * as S from './styles';

function EmployeeBasicPage(): React.ReactElement {
	const { form } = useContractTask();

	const [tabs, setTabs] = useState<CheckOption[]>([]);
	const [currentTab, setCurrentTab] = useState<CheckOption | null>(null);
	const index = (form.watch('employees') ?? []).findIndex(
		(tab) => tab.base.employeeId === currentTab?.value,
	);

	const handleChangeTab = (tab: CheckOption) => {
		setCurrentTab(tab);
	};

	const handleDeleteTab = (tab: CheckOption) => {
		form.setValue(
			'employees',
			form.getValues('employees').filter((item) => item?.base?.employeeId !== tab.value),
		);

		const services = form.getValues('services') ?? [];
		const newService =
			services.map((item) => ({
				...item,
				associates: item.associates
					? {
							...item.associates,
							contractEmployees: item.associates?.contractEmployees?.filter(
								(employee) => employee.employeeId !== tab.value,
							),
						}
					: undefined,
			})) ?? [];
		form.setValue('services', newService);
	};

	const forms = [
		{
			id: '#1',
			label: '기본 정보',
			component: index >= 0 ? <EmployeeBasicForm index={index} /> : <div />,
		},
		{
			id: '#2',
			label: '자격증 및 교육 정보',
			component: index >= 0 ? <TrainingForm index={index} /> : <div />,
		},
		{
			id: '#3',
			label: '타근무 정보',
			component: index >= 0 ? <OtherWorkingForm index={index} /> : <div />,
		},
		{
			id: '#4',
			label: '임금 정보',
			component: index >= 0 ? <EmployeeSalaryForm index={index} /> : <div />,
		},
	];

	useEffect(() => {
		const tabItems = [
			...(form?.getValues('employees') ?? []).map((item, index) => ({
				label: `직원 ${index + 1}`,
				value: item.base.employeeId,
			})),
		];

		setTabs(tabItems);
		if (tabItems.length) {
			setCurrentTab(tabItems[0]);
		} else {
			setCurrentTab(null);
		}
	}, [form.getValues('employees')?.length]);

	return (
		<>
			{!!tabs.length && (
				<CRPortal id='task_layout_portal'>
					<CRTab.Contract
						items={tabs}
						defaultActiveKey={currentTab?.value}
						onChange={handleChangeTab}
						onDelete={handleDeleteTab}
						limitCount={0}
					/>
				</CRPortal>
			)}
			{tabs.length ? (
				<Accordion.Root type='multiple' defaultValue={forms.map((form) => form.id)}>
					{forms.map((form) => (
						<Accordion.Item value={form.id} asChild>
							<TaskAccordion.Item>
								<Accordion.Header asChild>
									<Accordion.Trigger asChild>
										<TaskAccordion.Trigger href={form.id}>{form.label}</TaskAccordion.Trigger>
									</Accordion.Trigger>
								</Accordion.Header>
								<Accordion.Content asChild>
									<TaskAccordion.Content>{form.component}</TaskAccordion.Content>
								</Accordion.Content>
							</TaskAccordion.Item>
						</Accordion.Item>
					))}
				</Accordion.Root>
			) : (
				<S.EmptyContainer>
					<EmptyDescription
						title=''
						description={'연결된 직원 정보가 없습니다.\n급여 정보에서 직원을 입력해주세요.'}
					/>
				</S.EmptyContainer>
			)}
		</>
	);
}

export default React.memo(EmployeeBasicPage);
