import { CRTableHeadConfig } from 'types/view/base';

export const FULL_TIME_EMPLOYEE_WORK_DETAILS_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '5.6rem',
		label: '구분',
		sortKey: '구분',
	},
	{
		width: '20rem',
		label: '일자',
		sortKey: '일자',
	},
	{
		width: '12rem',
		label: '근무 시간',
		sortKey: '근무 시간',
	},
	{
		width: '12rem',
		label: '근무 종류',
		sortKey: '근무 종류',
	},
	{
		width: '30rem',
		label: '비고',
		sortKey: '비고',
	},
	{
		width: '16rem',
		label: '근거 첨부 (파일)',
		sortKey: '근거 첨부 (파일)',
	},
	{
		width: '100%',
		label: '',
		sortKey: '',
	},
];
