import React from 'react';

import dayjs from 'dayjs';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

interface BannerType {
	id: string;
	imageUrl: string;
	link: string;
	alt: string;
}

export const DefaultBanners: BannerType[] = [
	{
		id: 'open_saha',
		imageUrl:
			'https://caring-static-public.s3.ap-northeast-2.amazonaws.com/images/banner_daycare_saha.png',
		link: 'https://blog.naver.com/cc_bs07/223593230689',
		alt: '부산 사하점 오픈 이미지',
	},
	{
		id: 'open_dunsan',
		imageUrl:
			'https://caring-static-public.s3.ap-northeast-2.amazonaws.com/images/banner_daycare_dunsan.png',
		link: 'https://blog.naver.com/cc_dg02/223595899430',
		alt: '대전 둔산점 오픈 이미지',
	},
];

interface Props {
	banners?: BannerType[];
}

function CRAdvertisingBanner({ banners = DefaultBanners }: Props) {
	const handleClickBanner = (link: string) => {
		if (link) {
			window.open(link, '_blank');
		}
	};
	return (
		<div style={{ padding: '1.6rem 2rem 1.6rem 2rem', background: 'white' }}>
			<Swiper
				modules={[Autoplay]}
				style={{ width: '100%', borderRadius: '8px', aspectRatio: 312 / 144 }}
				loop
				speed={2000}
				autoplay={{ delay: 3000, disableOnInteraction: false }}>
				{banners.map((banner) => (
					<SwiperSlide key={banner.id} onClick={() => handleClickBanner(banner.link)}>
						<img width='100%' src={banner.imageUrl} alt={banner.alt} />
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
}

export default CRAdvertisingBanner;
