import React, { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRSpinner from 'components/base/CRSpinner';
import CRTableHeader from 'components/base/CRTableHeader';
import { Toast } from 'components/base/CRToast';
import { CheckOption } from 'components/base/Selections/type';
import CreateEDocDialog from 'components/domain/dialog/CreateEDocDialog';
import { EmployeeDocumentIssueDialog } from 'components/domain/dialog/EmployeeDocumentIssueDialog';
import EmployeeDocumentTable from 'components/domain/table/EmployeeDocumentTable';
import { queryStringToObject } from 'lib';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import { useMyAccountInfo } from 'lib/hook/react-query';
import { useEmployeeEDocList, useIssuableEDocPaperTypes } from 'lib/hook/react-query/query/edoc';
import useDialog from 'lib/hook/util/useDialog';
import { EDocTemplateType } from 'types/view/eDoc';
import { Filter } from 'types/view/filter';

import * as S from './styles';

export default function EmployeeDocumentTab() {
	const dialog = useDialog();
	const { data: eDocPaperCodes } = useIssuableEDocPaperTypes({
		paperMenu: 'employee',
	});

	const [currentFilter, setCurrentFilter] = useState<{
		rangeFilter: CheckOption[];
		paperTypeCd: CheckOption[];
	}>({
		rangeFilter: [],
		paperTypeCd: [],
	});

	const { data: myAccountInfo } = useMyAccountInfo();
	const { currentEmployee, currentEmployeeBase } = useEmployeePage();

	const {
		data: employeeEDocs,
		isLoading,
		refetch,
	} = useEmployeeEDocList({
		centerId: myAccountInfo?.centerId,
		employeeId: currentEmployee?.employeeId,
		paperStandardStartDate:
			currentFilter.rangeFilter?.[0]?.value?.start &&
			dayjs(currentFilter.rangeFilter[0].value.start).format('YYYYMMDD'),
		paperStandardEndDate:
			currentFilter.rangeFilter?.[0]?.value?.end &&
			dayjs(currentFilter.rangeFilter[0].value.end).format('YYYYMMDD'),
		paperTypeCds: currentFilter.paperTypeCd?.map((item) => item.value),
	});

	const handleRefetch = () => {
		refetch();
	};

	const durationFilter = useMemo(
		() =>
			({
				key: 'rangeFilter',
				type: 'calendar',
				placeholder: '일시',
			}) as Filter<CheckOption>,
		[],
	);

	const paperTypeFilter = useMemo(
		() =>
			({
				key: 'paperTypeCd',
				type: 'multi',
				options: (eDocPaperCodes?.paperTypeCds || [])?.map((item) => ({
					label: item.korComCdAliasNm,
					value: item.comCdId,
				})),
				placeholder: '서류 유형',
			}) as Filter<CheckOption>,
		[eDocPaperCodes],
	);

	const handleClickIssueButton = (from?: EDocTemplateType) => {
		if (!currentEmployee?.mobilePhoneNo) {
			Toast.error('직원의 연락처가 존재하지않습니다.');
			return;
		}
		dialog.showDialog(() => (
			<EmployeeDocumentIssueDialog
				from={from}
				currentEmployee={currentEmployee}
				title='서류 발급'
				myAccountInfo={myAccountInfo || undefined}
			/>
		));
	};

	const handleClickDocumentEnrollButton = () => {
		dialog.showDialog(() => (
			<CreateEDocDialog
				title='서류 등록'
				paperMenu='employee'
				targetId={currentEmployee?.employeeId}
			/>
		));
	};

	useEffect(() => {
		const from = queryStringToObject(window?.location?.search)?.from as unknown as EDocTemplateType;

		if (from && currentEmployee) {
			setTimeout(() => {
				handleClickIssueButton(from);
			}, 300);
		}
	}, [currentEmployee]);

	return (
		<S.Container>
			<CRTableHeader
				hideSearchButton
				currentFilter={currentFilter}
				setCurrentFilter={setCurrentFilter}
				filters={[durationFilter, paperTypeFilter]}
				renderRightButton={
					<S.RightButtonContainer>
						<CRButton.Default
							size='xSmall'
							palette='gray'
							type='outlined'
							onClick={handleClickDocumentEnrollButton}>
							등록
						</CRButton.Default>
						<CRButton.IconButton
							size='xSmall'
							type='filled'
							iconLeft={Assets.icon.add}
							onClick={() => handleClickIssueButton()}>
							발급
						</CRButton.IconButton>
					</S.RightButtonContainer>
				}
			/>
			{isLoading ? (
				<CRSpinner />
			) : (
				<S.TableContainer>
					<EmployeeDocumentTable items={employeeEDocs} onRefetch={handleRefetch} />
				</S.TableContainer>
			)}
		</S.Container>
	);
}
