// import { SendSignStatus } from 'types/view/send';
import { StatusColor } from 'types/view/base';

import { BaseResponse } from './base';
import { CommonCodeDTO, PageResponse } from './common';

export type SignStatus =
	| '서명 대기'
	| '검수 대기'
	| '완료'
	| '서명 취소'
	| '기간 만료'
	| '상태 오류';
export type SendStatus = '발송중' | '발송완료' | '발송실패';
export type taskType = 'renewSalaryPlan' | 'recipientReceipt';

export interface SendContentDTO {
	taskId: number;
	startDate: string;
	endDate: string;
	template: string;
	title: string;
	totalCount: number;
	successCount: number;
	failureCount: number;
	pendingCount: number;
	sender: string;
	taskType: taskType;
	signStatus: SignStatus;
	sendStatus: SendStatus;
}

export interface SendSubContentDTO {
	taskId: number;
	endDate: string;
	recipient: string;
	phoneNumber: string;
	signStatus: SignStatus;
	sender: string;
	sendStatus: SendStatus;
}

export interface SendTemplateListItemDTO {
	sendTemplateId: number;
	sendTemplateTypeCd: string;
	sendTemplateTitle: string;
	messageTitleNm?: string;
	messageDesc?: string;
}
export type SendTemplateListResponse = BaseResponse<SendTemplateListItemDTO[]>;

export interface SendTargetRecipient {
	recipientId: number;
	recipientNm: string;
	birthDt: string;
	mainGuardianNm: string;
	managerId: number;
}

export interface SendTargetEmployee {
	employeeId: number;
	employeeNm: string;
	birthDt: string;
	mobilePhoneNo?: string;
	managerId: number;
}

export interface SendTargetListRequestDTO {
	templateId?: string;
	targetYm?: string;
	targetWeekSeq?: string;
}
export interface SendTargetListDTO {
	sendTargetRecipients: SendTargetRecipient[];
	sendTargetEmployee: SendTargetEmployee[];
}

export type SendTargetListResponse = BaseResponse<SendTargetListDTO>;

export enum SendStateCd {
	발송전 = 'CMN028.10',
	발송중 = 'CMN028.20',
	완료 = 'CMN028.30',
	재발송 = 'CMN028.40',
	발송불가 = 'CMN028.90',
	실패 = 'CMN028.99',
}

export enum SendStateNm {
	발송실패 = '발송실패',
	발송완료 = '발송완료',
	발송중 = '발송중',
}
export interface SendHistoryContentDTO {
	sendDate: Date;
	sendTemplateId: number;
	sendTemplateTitle: string;
	sendTitle: string;
	totalCnt: number;
	successCnt: number;
	failCnt: number;
	waitingCnt: number;
	senderId: number;
	senderNm: string;
	sendStateCd: SendStateCd;
	sendStateNm: SendStateNm;
	sendStateColor: StatusColor;
	sendId: number;
}

export interface GetSendHistoryRequest {
	page?: number;
	size?: number;
	sendStateCds?: string[];
	sendTemplateIds?: string[];
	sendStartDate?: string;
	sendEndDate?: string;
	sendTitle?: string;
	senderIds?: string[];
}

export type SendHistoryDTO = PageResponse<SendHistoryContentDTO[]>;

export type SendHistoryResponse = BaseResponse<SendHistoryDTO>;

// 발송 메시지 발송이력 상세
export interface GetSendHistoryDetailRequest {
	historyId: number;
}

export interface SendHistoryBase {
	sendTemplateId: number;
	sendTemplateTitle: string;
	sendTemplateTypeCd: string;
	sendTemplateTypeNm: string;
	sendId: number;
	sendTitle: string;
	senderId: number;
	senderNm: string;
}

export interface SendHistoryDetail {
	sendDetailId: number;
	sendDate: Date;
	receiverTargetDivCd: string;
	receiverTargetId: number;
	receiverTargetNm: string;
	receiverPhoneNo: string;
	edocNo: string;
	sendMessageDesc: string;
	sendStateCd: string;
	sendStateNm: string;
	sendStateColor: StatusColor;
	sendUUid: string;
	sendResultDesc: string;
}
export interface SendHistoryDetailDTO {
	base: SendHistoryBase;
	details: SendHistoryDetail[];
}

export type SendHistoryDetailResponse = BaseResponse<SendHistoryDetailDTO>;

export interface SendMessageRequest {
	isSchedule: boolean;
	sendReservationDate?: string;
	sendTemplateId?: number;
	centerId?: number;
	targetYm: string | null;
	targetWeekSeq?: string;
	messageTitleNm: string;
	messageDesc: string;
	receivers: {
		recipients: number[];
		employees: number[];
	};
	sendTransferInfo?: {
		transferCenterIdAndNm: {
			id: number;
			name: string;
		};
		transferCenterMangerNm: string;
	};
}

export interface SendMessageDTO {
	sendId: number;
}

export type SendMessageResponse = BaseResponse<SendMessageDTO>;

export interface SendReservationHistoryContentDTO {
	cancelYn: boolean;
	reservationCnt: number;
	sendDate: string;
	sendStateNm: string;
	sendTemplateId: number;
	sendTemplateTitle: string;
	sendTitle: string;
	senderId: number;
	senderNm: string;
	sendId: number;
}

export interface GetSendReservationHistoryRequest {
	page?: number;
	size?: number;
	sendStateCds?: string[];
	sendTemplateIds?: number[];
	sendStartDate?: string;
	sendEndDate?: string;
	sendTitle?: string;
	senderIds?: string[];
	cancelYn?: string;
}

export type SendReservationHistoryDTO = PageResponse<SendReservationHistoryContentDTO[]>;

export type SendReservationHistoryResponse = BaseResponse<SendReservationHistoryDTO>;

export interface GetReservationSendDetailRequest {
	historyId?: string;
}

export interface ReservationSendDetailReceiver {
	receiverTargetDivCd: string;
	receiverId: number;
	receiverNm: string;
	receiverPhoneNo: string;
	birthDt: string;
	mainGuardianNm: string;
}

export interface ReservationSendDetailDTO {
	sendId: number;
	sendReservationDate: string;
	sendTemplateId: number;
	messageTitleNm: string;
	messageDesc: string;
	receivers: ReservationSendDetailReceiver[];
	cancelYn: boolean;
	cancelDate?: string;
	cancelDesc?: string;
	cancelManagerNm?: string;
	targetYm?: string;
}

export type ReservationSendDetailResponse = BaseResponse<ReservationSendDetailDTO>;

// 전자서명 발송 이력

// 필터
export interface ESignHistoryFilterDTO {
	lastEsignStateCds: CommonCodeDTO[];
	paperTypeCds: CommonCodeDTO[];
}

export type ESignHistoryFilterResponse = BaseResponse<ESignHistoryFilterDTO>;

// 전자서명 발송 이력 리스트
export interface ESignHistoryRequest {
	page: number;
	size: number;
	centerId?: number;
	paperTypeCds?: number[];
	edocSenderIds?: number[];
	lastEsignStateCds?: number[];
	edocTitle?: string;
}

export interface ESignHistoryContentDTO {
	edocSendId: number;
	lastActionDate: string;
	edocTemplateId: number;
	edocNm: string;
	edocTitle: string;
	edocSenderId: number;
	edocSenderNm: string;
	signatoryIds: string;
	signatoryNms: string;
	lastEsignStateCd: string;
	lastEsignStateNm: string;
	esignWayNm: string;
	esignWayCd: string;
}

export type ESignHistoryDTO = PageResponse<ESignHistoryContentDTO[]>;

export type ESignHistoryResponse = BaseResponse<ESignHistoryDTO>;

// 전자서명 발송이력 상세

export interface ESignHistoryDetailRequest {
	centerId?: number;
	edocSendId: number;
}
export interface Esign {
	esignId: number;
	esignStateCd: string;
	esignStateNm: string;
	esignStateText: string;
	paperTypeCd: string;
	sendUuid: string;
	signatoryId: number;
	signatoryNm: string;
	signatoryPhoneNo: string;
	esignDate: string;
}

export interface EDocTemplate {
	edocTemplateId: number;
	edocTemplateCd: string;
	paperTypeCd: string;
	edocNm: string;
	eformFileNm: string;
	esignProcessDayCnt: number;
	issueTargetYn: boolean;
	edocSends: null;
	esignStepSetups: null;
	createdAt: Date;
	createdId: number;
	updatedAt: Date;
	updatedId: number;
}

export interface EDocSend {
	edocSendId: number;
	edocProviderCd: string;
	edocNo: string;
	edocUuid: string;
	edocTemplate: EDocTemplate;
	edocSendDate: Date;
	signDueDate: Date;
	edocTitle: string;
	edocSenㅕderId: number;
	edocSenderNm: string;
	edocSenderPhoneNo: string;
	centerId: number;
	lastEsignStateCd: string;
	edocAutoCompleteYn: boolean;
	edocTargetEntityNm: string;
	edocTargetEntityIdNm: string;
	edocTargetEntityIdValue: number;
	createdAt: Date;
	createdId: number;
	updatedAt: Date;
	updatedId: number;
	esignWayCd: string;
	facingSignReceiverId?: number;
	facingSignReceiverNm?: string;
	facingSignReceiverPhoneNo?: string;
}

export interface EDocParam {
	edocParamId: number;
	edocSend: EDocSend;
	requestResponseDivCd: string;
	edocParamValue: string;
	createdAt: Date;
	createdId: number;
	updatedAt: Date;
	updatedId: number;
}

export interface Data {
	edocSend: EDocSend;
	edocParam: EDocParam;
	esignDetails: any[];
}

export interface EDocProgressHistResponse {
	id?: string;
	esignStateNm: string;
	edocProgressDesc: string;
	actionDateTime: string;
}

export interface EDocPreviewResponse {
	edocTemplateId: number;
	edocTemplateCd: string;
	paperTypeCd: string;
	edocNm: string;
	eformFileNm: string;
	esignProcessDayCnt: number;
	data: Data;
}
export interface ESignHistoryDetailDTO {
	edocSendId: number;
	edocNo: string;
	signDueDate: string;
	edocTitle: string;
	edocSenderId: number;
	edocSenderNm: string;
	lastEsignStateNm: string;
	edocPreviewResponse: EDocPreviewResponse;
	esigns: Esign[];
	edocProgressHistResponse: EDocProgressHistResponse[];
	sendMessageDesc: string;
	edocCancelerNm: string;
	edocCancelDate: string;
	edocCancelDesc: string;
	esignWayNm: string;
}

export type ESignHistoryDetailResponse = BaseResponse<ESignHistoryDetailDTO>;

export interface UpdateSignDueDateRequest {
	edocSendId: number;
	centerId?: number;
	signDueDate: string;
}

export interface UpdateSignDueDateDTO {
	edocSendId: number;
	edocNo: string;
	signDueDate: string;
}

export type UpdateSignDueDateResponse = BaseResponse<UpdateSignDueDateDTO>;

export interface CancelEDocRequest {
	edocSendId?: number;
	centerId?: number;
	edocCancelDesc?: string;
}

export interface CancelEDocDTO {
	edocSendId: number;
}

export type CancelEDocResponse = BaseResponse<CancelEDocDTO>;

// 메시지 개별 재발송

export interface ResendFailMessageRequest {
	sendId: number;
}
// eslint-disable-next-line
export interface ResendFailMessageDTO {
	sendDetailId: number;
	sendId: number;
}

export type ResendFailMessageResponse = BaseResponse<ResendFailMessageDTO>;

export interface ResendMessageRequest {
	sendId: number;
	sendDetailId: number;
}

export interface ResendMessageDTO {
	sendId: number;
	sendDetailId: number;
}

export type ResendMessageResponse = BaseResponse<ResendMessageDTO>;

// 예약 메세지 발송 취소
export interface CancelReservationMessageRequest {
	cancelDesc: string;
	sendId: number;
}

export interface CancelReservationMessageDTO {
	sendId: number;
}

export type CancelReservationMessageResponse = BaseResponse<CancelReservationMessageDTO>;

// 메시지 예약 발송 상세 수정

export type UpdateReservationMessageRequest = SendMessageRequest & {
	sendId: number;
};

// eslint-disable-next-line
export interface UpdateReservationMessageDTO {
	sendId: number;
}

export type UpdateReservationMessageResponse = BaseResponse<UpdateReservationMessageDTO>;

// 수급자, 직원 탭 발송 내역 -> 메시지
export interface MessageTabSendHistoryRequest {
	sendStateCds?: string;
	sendTemplateIds?: string;
	sendStartDate?: string;
	sendEndDate?: string;
	senderIds?: string;
}

export interface MessageTabSendHistoryDTO {
	sendDate: string;
	sendTemplateId: number;
	sendTemplateTitle: string;
	sendId: number;
	sendTitle: string;
	senderId: number;
	senderNm: string;
	sendStateCd: string;
	sendStateNm: string;
	sendDetailId: number;
	sendResultDesc: string;
	sendStateColor: StatusColor;
}

export type MessageTabSendHistoryResponse = BaseResponse<MessageTabSendHistoryDTO[]>;

// 수급자, 직원 탭 발송 내역 -> 예약
export interface MessageTabReservationHistoryRequest {
	sendStateCds?: string;
	sendTemplateIds?: string;
	sendStartDate?: string;
	sendEndDate?: string;
	cancelYn?: boolean;
}

export interface MessageTabReservationHistoryDTO {
	sendDate: string;
	sendTemplateId: number;
	sendTemplateTitle: string;
	sendId: number;
	sendTitle: string;
	reservationCnt: number;
	senderId: number;
	senderNm: string;
	cancelYn: boolean;
	sendStateNm: string;
}

export type MessageTabReservationHistoryResponse = BaseResponse<MessageTabReservationHistoryDTO[]>;

// 수급자, 직원 탭 발송 내역 -> 전자문서
export interface MessageTabESignHistoryRequest {
	paperTypeCds?: string;
	lastEsignStateCds: string;
}

export interface MessageTabESignHistoryDTO {
	edocSendId: number;
	lastActionDate: string;
	edocTemplateId: number;
	edocNm: string;
	edocTitle: string;
	edocSenderId: number;
	edocSenderNm: string;
	signatoryIds: string;
	signatoryNms: string;
	lastEsignStateCd: string;
	lastEsignStateNm: string;
}

export type MessageTabESignHistoryResponse = BaseResponse<MessageTabESignHistoryDTO[]>;

export interface RecipientSendHistoryTabDetailRequest {
	recipientId?: number;
	sendDetailId?: number;
}

export interface SendHistoryTabDetailDTO {
	sendTemplateId: number;
	sendTemplateTitle: string;
	sendTitle: string;
	senderNm: string;
	centerNm: string;
	sendDate: string;
	sendStateCd: string;
	sendStateNm: string;
	messageDesc: string;
	sendStateColor: StatusColor;
	sendResultDesc: string;
}

export type RecipientSendHistoryDetailResponse = BaseResponse<SendHistoryTabDetailDTO>;

export interface EmployeeSendHistoryDetailRequest {
	employeeId?: number;
	sendDetailId?: number;
}

export type EmployeeSendHistoryDetailResponse = BaseResponse<SendHistoryTabDetailDTO>;
