import React, { CSSProperties, useMemo } from 'react';

import Assets from 'assets';

import * as S from './styles';

interface Props {
	title?: string;
	showCloseButton?: boolean;
	showHeader?: boolean;
	type?: 'L' | 'ML' | 'M' | 'S';
	height?: string | number;
	body?: React.ReactNode;
	footer?: React.ReactNode;
	bottomContainerStyle?: CSSProperties;
	showDivider?: boolean;
	onClickClose?: () => void;
}

function CRDialog({
	title = '',
	body,
	footer,
	height,
	type = 'S',
	showCloseButton = true,
	showDivider = false,
	bottomContainerStyle = {},
	showHeader = true,
	onClickClose,
}: Props) {
	const Container = useMemo(() => {
		switch (type) {
			case 'L':
				return S.LContainer;
			case 'ML':
				return S.MLContainer;
			case 'M':
				return S.MContainer;
			case 'S':
			default:
				return S.SContainer;
		}
	}, []);
	return (
		<Container $height={height}>
			{showHeader && (
				<S.HeaderContainer $showDivider={showDivider}>
					<S.Title>{title}</S.Title>
					{showCloseButton && <S.Icon src={Assets.icon.close} alt='close' onClick={onClickClose} />}
				</S.HeaderContainer>
			)}
			<S.BodyContainer>{body}</S.BodyContainer>
			{!!footer && <S.BottomContainer style={bottomContainerStyle}>{footer}</S.BottomContainer>}
		</Container>
	);
}

export default CRDialog;
