import dayjs from 'dayjs';

import { EmployeeDailyWorkHistoryViewType } from 'types/view/workSchedule';

export enum CalenderWorkStatusType {
	Warning = 'Warning',
	Error = 'Error',
	Success = 'Success',
}

export const handleWorkCalendarLabelStatus = (data: EmployeeDailyWorkHistoryViewType) => {
	const {
		date,
		maxRoundingEndTime,
		minRoundingStartTime,
		workHistoryInfo,
		actualWorkInfo,
		vacationInfo,
		holidayInfo,
		shouldRenderNoHistoryLabel,
		totalWorkTimeCntOfTargetWeek,
	} = data;
	const isBeforeToday = date.isBefore(dayjs(), 'D');
	const vacationCnt = vacationInfo.reduce((sum, item) => sum + item.data.workHourCnt, 0);

	const hasRoundingSchedule = !!minRoundingStartTime && !!maxRoundingEndTime;
	const hasWorkLog = workHistoryInfo !== undefined;
	const hasDefaultWork = hasWorkLog && workHistoryInfo.workHourCnt > 0;
	const hasOverWork = hasWorkLog && workHistoryInfo.overWorkTimeCnt > 0;
	const hasActualWork = !!actualWorkInfo?.workStartTime && !!actualWorkInfo?.workEndTime;
	const dayOffVacation = vacationCnt >= 8 * 60;
	const isWorkDay = date.day() !== 0 && date.day() !== 6 && holidayInfo.length === 0;
	const totalWorkHourCnt =
		(workHistoryInfo?.workHourCnt || 0) + (workHistoryInfo?.overWorkTimeCnt || 0);

	// 야간 근무인 경우
	const isNightWork = workHistoryInfo?.endTime
		? workHistoryInfo.endTime.isAfter(dayjs(`${date.format('YYYY.MM.DD')} 22:00`))
		: false;

	// 근무인정시간이 라운딩 범위에 포함되는 경우
	const includeRoundingSchedule = hasRoundingSchedule
		? workHistoryInfo?.startTime &&
			workHistoryInfo?.endTime &&
			(workHistoryInfo.startTime.isSame(minRoundingStartTime) ||
				workHistoryInfo.startTime.isBefore(minRoundingStartTime)) &&
			(workHistoryInfo.endTime.isSame(maxRoundingEndTime) ||
				workHistoryInfo.endTime.isAfter(maxRoundingEndTime))
		: true;

	// 주당 52시간 초과 근무
	const overFiftyTwoHourWeek = totalWorkTimeCntOfTargetWeek > 52 * 60;

	// 근무일이 아닌데 근무가 있는 경우
	const hasWorkLogOnOffDay = hasWorkLog && !isWorkDay && !hasOverWork && !hasDefaultWork;
	// 오늘 이전의 근무일에 출퇴근 기록이 없는 경우
	const nonWorkLogOnWorkdayBeforeToday = !hasWorkLog && isBeforeToday && isWorkDay;
	// 종일 휴가인데 기본 근무에 시간이 있는 경우
	const hasDefaultWorkOnDayOffVacation = dayOffVacation && hasDefaultWork;

	const error =
		!hasActualWork ||
		shouldRenderNoHistoryLabel ||
		!includeRoundingSchedule ||
		overFiftyTwoHourWeek ||
		nonWorkLogOnWorkdayBeforeToday ||
		hasWorkLogOnOffDay ||
		isNightWork ||
		hasDefaultWorkOnDayOffVacation ||
		totalWorkHourCnt === 0;

	// Warning 케이스
	// 종일 휴가 중 초과근무가 있는 경우
	const hasOverWorkOnVacation = vacationCnt > 0 && hasOverWork;
	// 근무인정시간의 퇴근보다 실제 퇴근이 빠른 경우
	const isBeforeApprovedLeaveTime =
		!!actualWorkInfo?.workEndTime &&
		!!workHistoryInfo?.endTime &&
		actualWorkInfo.workEndTime.isBefore(workHistoryInfo.endTime);

	const warning = hasOverWorkOnVacation || isBeforeApprovedLeaveTime;

	const status = () => {
		if (error) return CalenderWorkStatusType.Error;
		if (warning) return CalenderWorkStatusType.Warning;
		return CalenderWorkStatusType.Success;
	};

	return { status: status() };
};
