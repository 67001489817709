import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`;

export const Title = styled.div`
	${(props) => props.theme.typography.h3}
	color: ${(props) => props.theme.colors.gray60};
	margin-bottom: 0.8rem;
`;

export const Description = styled.div`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray60};
	margin-bottom: 1.6rem;
	white-space: pre-wrap;
	text-align: center;
`;
