import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 1000;
	z-index: 100;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	padding: 2.4rem;
	box-sizing: border-box;
	${(props) => props.theme.noneSelection}
`;

export const Background = styled.div`
	position: absolute;
	z-index: -10;
	width: 100%;
	height: 100%;
	background: ${(props) => props.theme.colors.gray00};
	opacity: 0.1;
`;

export const DialogContainer = styled.div`
	background: ${(props) => props.theme.colors.gray100};
	border-radius: ${(props) => props.theme.radius.radius02};
	box-shadow:
		0px 8px 8px 0px rgba(0, 0, 0, 0.03),
		0px 16px 16px 0px rgba(0, 0, 0, 0.05),
		0px 32px 32px 0px rgba(0, 0, 0, 0.07),
		0px 64px 64px 0px rgba(0, 0, 0, 0.08);
	overflow: hidden;
`;
