import { css, styled } from 'styled-components';

export const Container = styled.div`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray60};
	display: flex;
	gap: 0.4rem;
	width: 100%;
	align-items: center;
`;

export const Input = styled.input`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray10};
	flex: 1;
	border: none;
	width: 100%;
	height: 100%;
	outline: none;
	padding: 0;
	margin: 0;
	&:disabled {
		background: ${(props) => props.theme.colors.gray95};
		color: ${(props) => props.theme.colors.gray60};
		cursor: no-drop;
	}
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

export const SectionContainer = styled.div`
	flex: 1;
	width: 100%;
	display: flex;
	align-items: center;
`;

export const DateContainer = styled.div`
	width: 100%;
`;

export const InputContainer = styled.div<{ $isDisable?: boolean; $hasError?: boolean }>`
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0 1.2rem;
	height: 5.6rem;
	border-radius: 0.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	box-sizing: border-box;
	margin: 0;
	${(props) =>
		props.$isDisable &&
		css`
			background: ${(props) => props.theme.colors.gray95};
			cursor: no-drop;
		`}
	${(props) =>
		props.$hasError &&
		css`
			border: 0.2rem solid ${(props) => props.theme.colors.primary60};
			background: ${(props) => props.theme.colors.primary99};
		`};
	${Icon} {
		${(props) =>
			props.$hasError &&
			css`
				filter: brightness(0) saturate(100%) invert(43%) sepia(26%) saturate(2944%)
					hue-rotate(318deg) brightness(103%) contrast(101%);
			`};
	}
	${Input} {
		${(props) =>
			props.$hasError &&
			css`
				background: ${(props) => props.theme.colors.primary99};
				color: ${(props) => props.theme.colors.primary60};
			`};
	}
`;

export const ErrorMessage = styled.p`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.primary60};
	white-space: pre-wrap;
	margin-top: 0.8rem;
`;
