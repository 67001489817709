import React from 'react';

import * as gS from '../grayStyles';
import * as dS from '../styles';
import { InformationSheetDividerProperties } from '../type';

interface Props {
	item: InformationSheetDividerProperties;
	type?: 'default' | 'gray';
}

function Divider({ item, type = 'default' }: Props): React.ReactElement {
	const S = type === 'default' ? dS : gS;

	return <S.Divider />;
}

export default React.memo(Divider);
