import React from 'react';
import { FieldPath, useFormContext } from 'react-hook-form';

import CRFormTableInput from 'components/base/CRFormInput/CRFormTableInput';
import { SalaryFeeCdKorKeys } from 'components/domain/dialog/SalaryDetailDialog/types';
import { CenterFinanceDetailFormFields } from 'types/view/centerFinance';

import * as S from './styles';

interface Props {
	disabled?: boolean;
}

function MyCenterFinanceVisitNursingFeeForm({ disabled }: Props): React.ReactElement {
	const { watch } = useFormContext<CenterFinanceDetailFormFields>();
	const salaryFee = watch('salaryFee');
	const makeInputName = (field: SalaryFeeCdKorKeys): FieldPath<CenterFinanceDetailFormFields> =>
		`salaryFee.${field}.salaryFeeValue`;

	const getAmt = (field: SalaryFeeCdKorKeys): string => salaryFee?.[`${field}`]?.salaryFeeValue;

	const renderFieldValue = (field: SalaryFeeCdKorKeys) =>
		disabled ? (
			getAmt(field)
		) : (
			<CRFormTableInput
				name={makeInputName(field)}
				disabled={disabled}
				maxLength={11}
				type='comma'
				suffix='원'
			/>
		);

	return (
		<S.Container>
			<S.Table>
				<S.TableRow>
					<S.TableLabelColumn
						style={{
							paddingBlock: '0',
							height: '3.3rem',
						}}
						colSpan={2}>
						60시간 미만
					</S.TableLabelColumn>
					<S.TableLabelColumn
						style={{
							paddingBlock: '0',
							height: '3.3rem',
						}}
						colSpan={2}>
						60시간 이상
					</S.TableLabelColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '25%' }}>가족 30분 미만</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '25%' }}>
						{renderFieldValue('간호_60시간미만_가족30분미만')}
					</S.TableValueColumn>
					<S.TableLabelColumn style={{ width: '25%' }}>가족 30분 미만</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '25%' }}>
						{renderFieldValue('간호_60시간이상_가족30분미만')}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>가족 30분 이상 60분 미만</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('간호_60시간미만_가족30분이상60분미만')}
					</S.TableValueColumn>
					<S.TableLabelColumn>가족 30분 이상 60분 미만</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('간호_60시간이상_가족30분이상60분미만')}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>가족 60분 이상</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('간호_60시간미만_가족60분이상')}
					</S.TableValueColumn>
					<S.TableLabelColumn>가족 60분 이상</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('간호_60시간이상_가족60분이상')}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>일반 30분 미만 (간호사)</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('간호_60시간미만_일반30분미만_간호사')}
					</S.TableValueColumn>
					<S.TableLabelColumn>일반 30분 미만 (간호사)</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('간호_60시간이상_일반30분미만_간호사')}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>
						일반 30분 이상 60분 미만
						<br />
						(간호사)
					</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('간호_60시간미만_일반30분이상60분미만_간호사')}
					</S.TableValueColumn>
					<S.TableLabelColumn>
						일반 30분 이상 60분 미만
						<br />
						(간호사)
					</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('간호_60시간이상_일반30분이상60분미만_간호사')}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>일반 60분 이상 (간호사)</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('간호_60시간미만_일반60분이상_간호사')}
					</S.TableValueColumn>
					<S.TableLabelColumn>일반 60분 이상 (간호사)</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('간호_60시간이상_일반60분이상_간호사')}
					</S.TableValueColumn>
				</S.TableRow>

				<S.TableRow>
					<S.TableLabelColumn>일반 30분 미만 (간호조무사)</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('간호_60시간미만_일반30분미만_간호조무사')}
					</S.TableValueColumn>
					<S.TableLabelColumn>일반 30분 미만 (간호조무사)</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('간호_60시간이상_일반30분미만_간호조무사')}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>
						일반 30분 이상 60분 미만
						<br />
						(간호조무사)
					</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('간호_60시간미만_일반30분이상60분미만_간호조무사')}
					</S.TableValueColumn>
					<S.TableLabelColumn>
						일반 30분 이상 60분 미만
						<br />
						(간호조무사)
					</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('간호_60시간이상_일반30분이상60분미만_간호조무사')}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>일반 60분 이상 (간호조무사)</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('간호_60시간미만_일반60분이상_간호조무사')}
					</S.TableValueColumn>
					<S.TableLabelColumn>일반 60분 이상 (간호조무사)</S.TableLabelColumn>
					<S.TableValueColumn>
						{renderFieldValue('간호_60시간이상_일반60분이상_간호조무사')}
					</S.TableValueColumn>
				</S.TableRow>
			</S.Table>
		</S.Container>
	);
}

export default MyCenterFinanceVisitNursingFeeForm;
