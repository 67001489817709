import React from 'react';
import { Controller } from 'react-hook-form';

import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import useCenterInfo from 'lib/hook/view/centerInformation/useCenterInfo';

import * as S from './styles';

function MyCenterUsageInformationForm(): React.ReactElement {
	const { form } = useCenterInfo();
	return (
		<S.Container>
			<Controller
				control={form?.control}
				name='w4cCodeValue'
				render={({ field: { value, onChange }, formState: { errors } }) => (
					<CRInputLabel type='left' label='W4C 시설 코드'>
						<CRInput.Default placeholder='W4C 시설 코드 입력' onChange={onChange} value={value} />
					</CRInputLabel>
				)}
			/>
			<Controller
				control={form?.control}
				name='centerApiNm'
				render={({ field: { value, onChange }, formState: { errors } }) => (
					<CRInputLabel type='left' label='API Name'>
						<CRInput.Default placeholder='API Name 입력' onChange={onChange} value={value} />
					</CRInputLabel>
				)}
			/>
		</S.Container>
	);
}

export default MyCenterUsageInformationForm;
