import useCRQuery from 'lib/hook/react-query/base/useCRQuery';
import { endpoint } from 'lib/service/Api/endpoint';

export const useFullTimeEmployees = useCRQuery(
	endpoint.getFullTimeEmployees.key,
	'getFullTimeEmployees',
	{
		enabledKey: ['centerIds'],
	},
);

export const useCheckFullTimeEmployeeDeletable = useCRQuery(
	endpoint.checkFullTimeEmployeeDeletable.key,
	'checkFullTimeEmployeeDeletable',
	{
		enabledKey: ['centerId', 'employeeId', 'memberId'],
	},
);

export const useFullTimeEmployeeBaseInfo = useCRQuery(
	endpoint.getFullTimeEmployeeBaseInfo.key,
	'getFullTimeEmployeeBaseInfo',
	{
		enabledKey: ['centerId', 'employeeId'],
	},
);

export const useFullTimeEmployeeDetailInfo = useCRQuery(
	endpoint.getFullTimeEmployeeDetailInfo.key,
	'getFullTimeEmployeeDetailInfo',
	{
		enabledKey: ['centerId', 'employeeId', 'memberId'],
	},
);

export const useFullTimeEmployeeAnnualTOHistories = useCRQuery(
	endpoint.getFullTimeEmployeeAnnualTOHistories.key,
	'getFullTimeEmployeeAnnualTOHistories',
	{
		enabledKey: ['centerId', 'employeeId', 'memberId'],
	},
);

export const useFullTimeEmployeeLicenses = useCRQuery(
	endpoint.getFullTimeEmployeeLicenses.key,
	'getFullTimeEmployeeLicenses',
	{
		enabledKey: ['employeeId'],
	},
);

export const useFullTimeEmployeeEducations = useCRQuery(
	endpoint.getFullTimeEmployeeEducations.key,
	'getFullTimeEmployeeEducations',
	{
		enabledKey: ['employeeId', 'year'],
	},
);

export const useFullTimeEmployeeWorkDetails = useCRQuery(
	endpoint.getFullTimeEmployeeWorkDetails.key,
	'getFullTimeEmployeeWorkDetails',
	{
		enabledKey: ['centerId', 'employeeId'],
	},
);

export const useFullTimeEmployeeWorkStates = useCRQuery(
	endpoint.getFullTimeEmployeeWorkStates.key,
	'getFullTimeEmployeeWorkStates',
	{
		enabledKey: ['centerId', 'employeeId', 'memberId'],
	},
);

export const useFullTimeEmployeeDuties = useCRQuery(
	endpoint.getFullTimeEmployeeDuties.key,
	'getFullTimeEmployeeDuties',
	{
		enabledKey: ['centerId', 'employeeId', 'memberId'],
	},
);

export const useFullTimeEmployeeCrimeHistories = useCRQuery(
	endpoint.getFullTimeEmployeeCrimeHistories.key,
	'getFullTimeEmployeeCrimeHistories',
	{
		enabledKey: ['centerId', 'employeeId', 'memberId'],
	},
);

export const useFullTimeEmployeeLegalEducations = useCRQuery(
	endpoint.getFullTimeEmployeeLegalEducations.key,
	'getFullTimeEmployeeLegalEducations',
	{
		enabledKey: ['centerId', 'employeeId', 'memberId'],
	},
);

export const useFullTimeEmployeeDementiaEducations = useCRQuery(
	endpoint.getFullTimeEmployeeDementiaEducations.key,
	'getFullTimeEmployeeDementiaEducations',
	{
		enabledKey: ['centerId', 'employeeId', 'memberId'],
	},
);

export const useFullTimeEmployeeFinalWorkLeave = useCRQuery(
	endpoint.getFullTimeEmployeeFinalWorkLeave.key,
	'getFullTimeEmployeeFinalWorkLeave',
	{
		enabledKey: ['centerId', 'employeeId', 'memberId'],
	},
);

export const useFullTimeEmployeeResign = useCRQuery(
	endpoint.getFullTimeEmployeeResign.key,
	'getFullTimeEmployeeResign',
	{
		enabledKey: ['centerId', 'employeeId', 'memberId'],
	},
);

export const useFullTimeEmployeeHealthCheck = useCRQuery(
	endpoint.getFullTimeEmployeeHealthCheck.key,
	'getFullTimeEmployeeHealthCheck',
	{
		enabledKey: ['employeeId'],
	},
);
