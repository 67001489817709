import React from 'react';

import * as S from './styles';

interface Props {
	title?: string;
	description?: string;
	button?: React.ReactNode;
}

function ListDialogEmptyDescription({
	title = '',
	description = '',
	button,
}: Props): React.ReactElement {
	return (
		<S.Container>
			<S.Title>{title}</S.Title>
			<S.Description>{description}</S.Description>
			{button}
		</S.Container>
	);
}

export default React.memo(ListDialogEmptyDescription);
