import React, { useRef, useState } from 'react';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import { useMyAccountInfo, useSendRecordingSheet } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { ResponseCode } from 'types/api';
import {
	SendRecordingSheetRequestDTO,
	scheduleRecordingDetailRequestItemDTO,
} from 'types/api/schedule';
import { ESignRequestForm } from 'types/view/eDoc';
import { RecordingSheetItem } from 'types/view/schedule';

import { ESignWayCode } from '../ESignDialog';
import * as S from './styles';

interface Props {
	serviceStartDate: Date;
	serviceEndDate: Date;
	items: RecordingSheetItem[];
	eSignWayData: ESignRequestForm;
	initCheckIds: () => void;
	onRefresh: () => void;
}

export function SendRecordingSheetDialog({
	serviceStartDate,
	serviceEndDate,
	items,
	eSignWayData,
	initCheckIds,
	onRefresh,
}: Props) {
	const { data: myAccountInfo } = useMyAccountInfo();
	const { showDialog, hideDialog } = useDialog();
	const [progress, setProgress] = useState(0); // 진행 상황을 추적하는 상태

	const isCancellingRef = useRef(false);

	const renderBodyText = () => {
		if (!items.length) return '';
		return `${items[0].recipientNm}-${items[0].employeeNm}${
			items[0].employeeNm2 === '-' ? '' : `,${items[0].employeeNm2}`
		}${items.length > 1 ? ` 외 ${items.length - 1}건` : ''}`;
	};

	const { mutateAsync: sendRecordingSheet, isLoading } = useSendRecordingSheet();

	const processScheduleDetails = (item: RecordingSheetItem) => {
		const scheduleRecordingDetailes: scheduleRecordingDetailRequestItemDTO[] = [];
		if (item.serviceTypeCd === 'CMN006.10' || item.serviceTypeCd === 'CMN006.30') {
			Object.entries(item.recordingDetails).forEach(([key, value]) => {
				value.forEach((item) => {
					const isRfid = !!(item.rfidStart && item.rfidEnd);
					const startTime = isRfid
						? dayjs(item.rfidStart).format('HHmm')
						: dayjs(item.planStart).format('HHmm');
					const endTime = isRfid
						? dayjs(item.rfidEnd).format('HHmm')
						: dayjs(item.planEnd).format('HHmm');
					const totalHourCnt = isRfid ? item.rfidTotalMin : item.planTotalMin;
					const scheduleRecordingDetail: scheduleRecordingDetailRequestItemDTO = {
						serviceDt: item.serviceYmd,
						startTime,
						endTime,
						totalHourCnt,
						recordingSheetSendMethodCd: 'CMN131.ESIGN',
						employees: [
							{
								employeeId: item.employeeId,
								familyYn: !!(item.familyYn === 'Y'),
							},
						],
					};

					scheduleRecordingDetailes.push(scheduleRecordingDetail);
				});
			});
		}
		if (item.serviceTypeCd === 'CMN006.20') {
			Object.entries(item.recordingDetails).forEach(([key, value]) => {
				if (value.length === 2) {
					let startTime = '';
					let endTime = '';
					let isValid = false;
					let totalHourCnt = 0;
					if (value[0].rfidStart && value[1].rfidStart && value[0].rfidEnd && value[1].rfidEnd) {
						if (
							dayjs(value[0].rfidStart).format('YYYY.MM.DD HH:mm') ===
								dayjs(value[1].rfidStart).format('YYYY.MM.DD HH:mm') &&
							dayjs(value[0].rfidEnd).format('YYYY.MM.DD HH:mm') ===
								dayjs(value[1].rfidEnd).format('YYYY.MM.DD HH:mm')
						) {
							isValid = true;
							startTime = dayjs(value[0].rfidStart).format('HHmm');
							endTime = dayjs(value[0].rfidEnd).format('HHmm');
							totalHourCnt = value[0].rfidTotalMin;
						}
					} else if (
						value[0].planStart &&
						value[1].planStart &&
						value[0].planEnd &&
						value[1].planEnd
					) {
						if (
							dayjs(value[0].planStart).format('YYYY.MM.DD HH:mm') ===
								dayjs(value[1].planStart).format('YYYY.MM.DD HH:mm') &&
							dayjs(value[0].planEnd).format('YYYY.MM.DD HH:mm') ===
								dayjs(value[1].planEnd).format('YYYY.MM.DD HH:mm')
						) {
							isValid = true;
							startTime = dayjs(value[0].planStart).format('HHmm');
							endTime = dayjs(value[0].planEnd).format('HHmm');
							totalHourCnt = value[0].planTotalMin;
						}
					}
					if (isValid) {
						const scheduleRecordingDetail: scheduleRecordingDetailRequestItemDTO = {
							serviceDt: value[0].serviceYmd,
							startTime,
							endTime,
							totalHourCnt,
							recordingSheetSendMethodCd: 'CMN131.ESIGN',
							employees: value.map((employee) => ({
								employeeId: employee.employeeId,
								familyYn: !!(employee.familyYn === 'Y'),
							})),
						};

						scheduleRecordingDetailes.push(scheduleRecordingDetail);
					}
				}
			});
		}
		const isError = scheduleRecordingDetailes.length !== Object.keys(item.recordingDetails).length;
		return isError ? [] : scheduleRecordingDetailes;
	};

	const handleClickSendRecordingSheet = async () => {
		if (!myAccountInfo) return;

		const params: SendRecordingSheetRequestDTO[] = items?.map((item) => ({
			centerId: item.centerId,
			centerNm: myAccountInfo.centerNm,
			serviceStartDt: dayjs(serviceStartDate).format('YYYYMMDD'),
			serviceEndDt: dayjs(serviceEndDate).format('YYYYMMDD'),
			serviceTypeCd: item.serviceTypeCd,
			serviceTypeNm: item.serviceTypeNm,
			recipientId: item.recipientId,
			recipientNm: item.recipientNm,
			longTermNo: item.longTermNo,
			scheduleRecordingDetailes: processScheduleDetails(item),
			esignWayInfo:
				eSignWayData.eSignWayCd?.[0]?.value === ESignWayCode.대면서명
					? {
							esignWayCd: eSignWayData.eSignWayCd?.[0]?.value,
							facingManagerId: eSignWayData.receiver?.data?.memberAccountId,
							facingManagerPhoneUsageDivCd: eSignWayData?.facingManagerPhoneUsageDivCd?.[0]?.value,
						}
					: { esignWayCd: eSignWayData.eSignWayCd?.[0]?.value },
		}));

		// const errorSchedules = params.filter((item) => item.scheduleRecordingDetailes.length === 0);
		const validSchedules = params.filter((item) => item.scheduleRecordingDetailes.length !== 0);
		const errorSchedules = params.filter((item) => item.scheduleRecordingDetailes.length === 0);
		const errorSchedulesCount = params.length - validSchedules.length;

		// 방문목욕일때 일정 오류가 생겼을 경우
		if (
			errorSchedules.length > 0 &&
			!!errorSchedules.find((schedule) => schedule.serviceTypeCd === 'CMN006.20')
		) {
			hideDialog();
			showDialog(() => (
				<CRDialog
					showCloseButton={false}
					title='RFID 시간 불일치'
					body={
						<S.TextContainer>
							방문목욕 진행시 두 직원의 RFID 시간이 일치해야 합니다.
						</S.TextContainer>
					}
					footer={
						<S.ButtonContainer>
							<CRButton.Default palette='gray' type='text' onClick={hideDialog}>
								확인
							</CRButton.Default>
						</S.ButtonContainer>
					}
				/>
			));
		} else {
			let currentProgress = 0;
			let successCount = 0;
			let errCount = 0;

			// reduce를 이용해 순차적으로 API 호출을 진행하며 isCancellingRef가 true일 때 중단
			// eslint-disable-next-line
			await validSchedules.reduce(async (accPromise, param, index) => {
				await accPromise;

				// 취소가 요청되면 진행 중지
				if (isCancellingRef.current) {
					return Promise.resolve(); // reduce 종료
				}

				// AbortController 초기화
				const abortController = new AbortController();
				const { signal } = abortController;

				try {
					const response = await sendRecordingSheet({ params: param, signal });
					if (response.code === ResponseCode.SUCCESS) {
						successCount += 1;
					} else {
						errCount += 1;
					}
				} catch (error: any) {
					console.error(error);
				}

				currentProgress += 1;
				setProgress(currentProgress);
			}, Promise.resolve());

			onRefresh();
			hideDialog();

			if (errCount + errorSchedulesCount) {
				Toast.error(
					`총 ${
						errCount + errorSchedulesCount
					}건의 기록지 발송에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.`,
				);
			}

			if (successCount - errorSchedulesCount > 0) {
				Toast.success(`총 ${successCount - errorSchedulesCount}건의 기록지를 발송하였습니다.`);
			}

			setProgress(0);
			initCheckIds();
		}
	};

	const handleClickCancel = () => {
		isCancellingRef.current = true; // 취소 상태 업데이트
		hideDialog();
	};

	return (
		<CRDialog
			showCloseButton
			onClickClose={handleClickCancel}
			title='기록지 발송'
			body={
				<S.Container>
					{renderBodyText()}의 기록지를 발송합니다.
					{items?.length > 1 && (
						<>
							<br /> 진행 상태: {progress} / {items?.length}
						</>
					)}
					{items?.length > 1 && (
						<CRText
							color='gray60'
							typography='label'
							text='[취소] 버튼을 눌러 중도 취소하는 경우, 이미 발송된 기록지는 취소되지 않습니다.'
						/>
					)}
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default palette='gray' type='text' onClick={handleClickCancel}>
						취소
					</CRButton.Default>
					<CRButton.IconButton isLoading={isLoading} onClick={handleClickSendRecordingSheet}>
						발송
					</CRButton.IconButton>
				</S.ButtonContainer>
			}
		/>
	);
}
