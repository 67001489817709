import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useExportEFormToPdf } from 'lib/hook/react-query';
import { useApi } from 'lib/provider/service/Api';

function EDocDownloadPage(): React.ReactElement {
	const api = useApi();
	const param = useParams<{ key: string }>();
	const { mutateAsync: exportEFormTopdf } = useExportEFormToPdf((client, returnData) => {
		window?.open('', '_self')?.close();
	});

	// const closePage = () => {
	// 	const ua = window?.navigator.userAgent;
	// 	// alert(ua.toLocaleLowerCase().indexOf("kakaotalk"))
	// 	if (ua.toLocaleLowerCase().indexOf('kakaotalk') > -1) {
	// 		// alert("1")
	// 		window.location.href = /iPad|iPhone|iPod/.test(ua)
	// 			? 'kakaoweb://closeBrowser'
	// 			: 'kakaotalk://inappbrowser/close';
	// 	} else if (ua.toLocaleLowerCase().indexOf('android') > -1) {
	// 		// alert("2")
	// 		window.close();
	// 	} else if (
	// 		ua.toLocaleLowerCase().indexOf('iphone') > -1 ||
	// 		ua.toLocaleLowerCase().indexOf('ipad') > -1 ||
	// 		ua.toLocaleLowerCase().indexOf('ipod') > -1
	// 	) {
	// 		// alert("3")
	// 		self.close();
	// 	} else {
	// 		// alert("4")
	// 		self.close();
	// 	}
	// };
	// hajoung56.tistory.com/73 [무사뎀벨레의 블로그:티스토리]

	const downloadPdf = async (eformKey: string) => {
		try {
			const blob = await exportEFormTopdf({
				eformKey,
			});
			if (blob && blob.size > 0) {
				const url = URL.createObjectURL(blob);
				// 다운로드 링크 생성 및 클릭하여 파일 다운로드
				const a = document.createElement('a');
				a.href = url;
				a.download = `전자문서.pdf`; // 다운로드될 파일 이름 지정
				document.body.appendChild(a);
				a.click();
				// 메모리 해제
				URL.revokeObjectURL(url);
				window?.open('', '_self')?.close();
			}
		} catch (error) {
			window?.open('', '_self')?.close();
			window.location.href = '';
		}
	};

	useEffect(() => {
		if (param.key) {
			api.initEDocClient();
			downloadPdf(param.key);
		}
	}, [param]);

	return <div />;
}

export default EDocDownloadPage;
