import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FieldErrors, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRIcon from 'components/base/CRIcon';
import CRInput from 'components/base/CRInput';
import CRSpinner from 'components/base/CRSpinner';
import { Toast } from 'components/base/CRToast';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import { RecipientOwnExpenseFormResolver, removeFalsyValues } from 'lib';
import {
	useCheckAccountOwner,
	useCommonCodes,
	useUpdateRecipientBurdenAmt,
	useWageDeductionEmployees,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { ResponseCode } from 'types/api';
import {
	CheckAccountOwnerData,
	CheckAccountOwnerRequest,
	UpdateRecipientBurdenAmtRequest,
} from 'types/api/common';
import { Recipient, RecipientOwnExpenseFormViewType } from 'types/view/recipient';

import { OwnExpensePayMethodType } from '../../../../types/view/common';
import * as S from './styles';

export enum BurdenAmtPayMethodCd {
	자동이체 = 'CMN041.10',
	직접이체 = 'CMN041.20',
	임금공제 = 'CMN041.30',
}

interface Props {
	currentRecipient?: Recipient;
	items?: RecipientOwnExpenseFormViewType;
	onlyEdit?: boolean;
	hideAccount?: boolean;
	onStartChangeSection?: () => void;
	onCancelChangeSection?: () => void;
	isEditSectionChanging?: boolean;
}

function RecipientOwnExpenseAutomaticForm({
	currentRecipient,
	items,
	onlyEdit,
	hideAccount = false,
	onStartChangeSection,
	onCancelChangeSection,
	isEditSectionChanging,
}: Props): React.ReactElement {
	const { control, handleSubmit, watch, formState, clearErrors, setError, setValue, getValues } =
		useForm<RecipientOwnExpenseFormViewType>({
			mode: 'onChange',
			defaultValues: {
				...items,
				payMethod: {
					label: '',
					value: items?.payMethod.value ?? 'CMN041.10',
				},
				receipt: {
					label: '',
					value: items?.receipt.value ?? false,
				},
				receiptMethod: {
					label: '',
					value: items?.receiptMethod?.value ?? 'CMN162.10',
				},
			},
			resolver: yupResolver(RecipientOwnExpenseFormResolver),
		});

	const { showDialog } = useDialog();
	const payMethod = watch('payMethod');
	const receipt = watch('receipt');
	const updateRecipientBurdenAmt = useUpdateRecipientBurdenAmt(async (client, res, variables) => {
		if (res?.recipientId) {
			await client.invalidateQueries([
				endpoint.getRecipientBase.key,
				{ recipientId: Number(variables.recipientId) },
			]);
			onStartChangeSection?.();
			Toast.success('본인부담금 납부 정보를 수정했습니다.');
		} else {
			onCancelChangeSection?.();
		}
	});

	const { data: commonCodes = [] } = useCommonCodes({
		comCdGroupNms: ['CMN041', 'CMN037', 'CMN162'],
	});

	const { data: wageDeductionEmployees } = useWageDeductionEmployees({
		centerId: currentRecipient?.centerId,
		recipientId: currentRecipient?.recipientId,
	});

	const [isValidAccount, setIsValidAccount] = useState(
		!!(items?.account && items.accountOwner && items.bank),
	);
	const { mutate: checkAccountOwner, isLoading: checkAccountLoading } = useCheckAccountOwner(
		(client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS && returnData?.data) {
				const result: CheckAccountOwnerData = JSON.parse(returnData.data);
				if (result.data.OUTSTATCD === '0021') {
					setIsValidAccount(true);
					clearErrors('accountOwner');
				} else if (result.data.OUTSTATCD === '0031') {
					setIsValidAccount(false);
					setError('accountOwner', {
						message: result.data.OUTRSLTMSG,
					});
				} else {
					Toast.error('계좌 조회에 실패했습니다.');
				}
			}
		},
	);

	const onSubmitSuccess = async (form: RecipientOwnExpenseFormViewType) => {
		if (updateRecipientBurdenAmt.isLoading) return;
		if (form.payMethod.value === BurdenAmtPayMethodCd.자동이체 && !isValidAccount) {
			setError('accountOwner', {
				message: '계좌주를 다시 확인해주세요.',
			});
			Toast.error('계좌주를 다시 확인해주세요.');
			setIsValidAccount(false);
			onCancelChangeSection?.();
			return;
		}
		await updateRecipientBurdenAmt.mutateAsync(
			removeFalsyValues({
				recipientId: currentRecipient?.recipientId ?? 0,
				recipientAffiliationId: currentRecipient?.recipientId ?? 0,
				burdenAmtPayMethodCd: form.payMethod.value,
				burdenAmtPayDeductEmployeeId: form.payDeductEmployeeId,
				burdenAmtAutotransBankCd: form.bank,
				burdenAmtAutotransAccountNo: form.account,
				burdenAmtAutotransAccountDepositorNm: form.accountOwner,
				cashReceiptIssueYn: form.receipt.value,
				cashReceiptIssuerNm: form.name,
				cashReceiptIssueMethodCd: form.receiptMethod?.value,
				cashReceiptIssuerNo: form.receiptNumber,
			}) as UpdateRecipientBurdenAmtRequest,
		);
	};

	const onSubmitFail = (errors: FieldErrors<RecipientOwnExpenseFormViewType>) => {
		Toast.error(Object.values(errors)?.[0]?.message || '입력폼을 확인해주세요');
		onCancelChangeSection?.();
	};

	const onSubmitHandler = () => {
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='변경된 정보 저장'
				content='본임부담금 납부에서 변경된 정보를 저장합니다.'
				successOption={{
					text: '저장',
					successCallback: () => {
						hideDialog();
						handleSubmit(onSubmitSuccess, onSubmitFail)();
					},
				}}
				cancelOption={{
					text: '저장안함',
					callback: () => {
						hideDialog();
						onStartChangeSection?.();
					},
				}}
				hideDialog={() => {
					hideDialog();
					onCancelChangeSection?.();
				}}
			/>
		));
	};

	useEffect(() => {
		if (isEditSectionChanging) {
			onSubmitHandler();
		}
	}, [isEditSectionChanging]);

	const onChangePayMethodType = (value: BurdenAmtPayMethodCd) => {
		if (value !== BurdenAmtPayMethodCd.자동이체) {
			setValue('bank', '');
			setValue('account', '');
			setValue('accountOwner', '');
		}
		if (value !== BurdenAmtPayMethodCd.직접이체) {
			// 초기화 할 값 없음
		}
		if (value !== BurdenAmtPayMethodCd.임금공제) {
			setValue('payDeductEmployeeId', undefined);
		}
	};

	const onChangeReceipt = (value: boolean) => {
		if (!value) {
			setValue('name', '');
			setValue('receiptMethod', undefined);
			setValue('receiptNumber', '');
		} else {
			setValue('name', '');
			setValue('receiptMethod', {
				label: '',
				value: items?.receiptMethod?.value ?? 'CMN162.10',
			});
			setValue('receiptNumber', '');
		}
	};

	const handleClickCheckAccount = () => {
		if (checkAccountLoading) return;
		const param: CheckAccountOwnerRequest = {
			acctNo: getValues('account') || '',
			bankCode: getValues('bank') || '',
			custNm: getValues('accountOwner') || '',
		};
		checkAccountOwner(param);
	};

	const payMethodOptions = useMemo(
		() =>
			(commonCodes ?? [])
				?.filter((code) => code.comCdGroupNm === 'CMN041')
				.map(
					(code) =>
						({
							label: code.korComCdAliasNm,
							value: code.comCdId,
						}) as CheckOption,
				),
		[commonCodes],
	);

	const bankOptions = useMemo(
		() =>
			(commonCodes ?? [])
				?.filter((code) => code.comCdGroupNm === 'CMN037')
				.map(
					(code) =>
						({
							label: code.korComCdAliasNm,
							value: code.comCdId,
						}) as CheckOption,
				),
		[commonCodes],
	);

	const receiptMethodOptions = useMemo(
		() =>
			(commonCodes ?? [])
				?.filter((code) => code.comCdGroupNm === 'CMN162')
				.map(
					(code) =>
						({
							label: code.korComCdAliasNm,
							value: code.comCdId,
						}) as CheckOption,
				),
		[commonCodes],
	);

	const cashReceiptTargetOptions = useMemo(
		() => [
			{
				label: '비대상자',
				value: false,
			},
			{
				label: '대상자',
				value: true,
			},
		],
		[],
	);

	if (payMethod.value === OwnExpensePayMethodType.Self) {
		return (
			<S.Container>
				<S.Form>
					{!onlyEdit && (
						<S.Label>
							본인부담금 납부
							<S.ButtonContainer>
								<CRButton.Default
									size='xSmall'
									type='outlined'
									palette='gray'
									onClick={onSubmitHandler}>
									취소
								</CRButton.Default>
								<CRButton.Default
									buttonType='submit'
									size='xSmall'
									type='filled'
									palette='gray'
									onClick={handleSubmit(onSubmitSuccess, onSubmitFail)}>
									저장
								</CRButton.Default>
							</S.ButtonContainer>
						</S.Label>
					)}
					<S.Table>
						<S.TableRow>
							{!hideAccount && (
								<>
									<S.TableLabelColumn style={{ width: '10.4rem' }}>납부 방식</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }}>
										<Controller
											render={({ field: { ref, onChange, value }, formState: { errors } }) => (
												<CRCheckBoxGroup
													ref={ref}
													checkType='single'
													type='radio'
													gap={0.8}
													value={[value]}
													onChange={(values) => {
														onChange(values[0]);
														onChangePayMethodType(values[0]?.value);
													}}
													options={payMethodOptions}
												/>
											)}
											name='payMethod'
											control={control}
										/>
									</S.TableValueColumn>
								</>
							)}
							<S.TableLabelColumn style={{ width: '10.4rem' }}>현금영수증</S.TableLabelColumn>
							<S.TableValueColumn style={{ width: '24rem' }}>
								<Controller
									render={({ field: { ref, onChange, value }, formState: { errors } }) => (
										<CRCheckBoxGroup
											ref={ref}
											checkType='single'
											type='radio'
											gap={0.8}
											value={[value]}
											onChange={(values) => {
												onChange(values[0]);
												onChangeReceipt(values[0].value);
											}}
											options={cashReceiptTargetOptions}
										/>
									)}
									name='receipt'
									control={control}
								/>
							</S.TableValueColumn>
						</S.TableRow>
						<S.TableRow>
							{!hideAccount && (
								<S.TableLabelColumn
									style={{ width: '10.4rem', height: '16.2rem' }}
									colSpan={2}
									rowSpan={3}
								/>
							)}
							<S.TableLabelColumn
								style={{ width: '10.4rem' }}
								rowSpan={receipt.value ? 1 : 3}
								colSpan={receipt.value ? 1 : 2}>
								{receipt.value && (
									<>
										이름 <S.RequiredMark>*</S.RequiredMark>
									</>
								)}
							</S.TableLabelColumn>
							{receipt.value && (
								<S.TableValueColumn style={{ width: '24rem' }}>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TableInput
												ref={ref}
												onBlur={onBlur}
												status={errors.name?.message ? 'error' : 'default'}
												onChange={onChange}
												value={value}
												placeholder='이름 입력'
												addOnBottom={formState.errors?.name?.message}
											/>
										)}
										name='name'
										control={control}
									/>
								</S.TableValueColumn>
							)}
						</S.TableRow>
						<S.TableRow>
							{receipt.value && (
								<>
									<S.TableLabelColumn style={{ width: '10.4rem' }}>발급 방식</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }}>
										<Controller
											render={({
												field: { onBlur, ref, onChange, value },
												formState: { errors },
											}) => (
												<CRCheckBoxGroup
													ref={ref}
													checkType='single'
													type='radio'
													gap={0.8}
													value={value ? [value] : []}
													onChange={(values) => onChange(values[0])}
													options={receiptMethodOptions}
												/>
											)}
											name='receiptMethod'
											control={control}
										/>
									</S.TableValueColumn>
								</>
							)}
						</S.TableRow>
						<S.TableRow>
							{receipt.value && (
								<>
									<S.TableLabelColumn style={{ width: '10.4rem' }}>
										{watch('receiptMethod')?.value === 'CMN162.10' ? '휴대폰 번호' : '사업자 번호'}{' '}
										<S.RequiredMark>*</S.RequiredMark>
									</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }}>
										<Controller
											render={({
												field: { onBlur, ref, onChange, value },
												formState: { errors },
											}) => (
												<CRInput.TableInput
													ref={ref}
													onBlur={onBlur}
													status={errors.receiptNumber?.message ? 'error' : 'default'}
													type='number'
													onChange={onChange}
													value={value}
													addOnBottom={formState.errors?.receiptNumber?.message}
													placeholder={
														watch('receiptMethod')?.value === 'CMN162.10'
															? '휴대폰 번호 입력'
															: '사업자 번호 입력'
													}
												/>
											)}
											name='receiptNumber'
											control={control}
										/>
									</S.TableValueColumn>
								</>
							)}
						</S.TableRow>
					</S.Table>
				</S.Form>
			</S.Container>
		);
	}

	if (payMethod.value === OwnExpensePayMethodType.WageDeduction) {
		return (
			<S.Container>
				<S.Form>
					{!onlyEdit && (
						<S.Label>
							본인부담금 납부
							<S.ButtonContainer>
								<CRButton.Default
									size='xSmall'
									type='outlined'
									palette='gray'
									onClick={onSubmitHandler}>
									취소
								</CRButton.Default>
								<CRButton.Default
									buttonType='submit'
									size='xSmall'
									type='filled'
									palette='gray'
									onClick={handleSubmit(onSubmitSuccess, onSubmitFail)}>
									저장
								</CRButton.Default>
							</S.ButtonContainer>
						</S.Label>
					)}
					<S.Table>
						<S.TableRow>
							{!hideAccount && (
								<>
									<S.TableLabelColumn style={{ width: '10.4rem' }}>납부 방식</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }}>
										<Controller
											render={({ field: { ref, onChange, value }, formState: { errors } }) => (
												<CRCheckBoxGroup
													ref={ref}
													checkType='single'
													type='radio'
													gap={0.8}
													value={[value]}
													onChange={(values) => {
														onChange(values[0]);
														onChangePayMethodType(values[0]?.value);
													}}
													options={payMethodOptions}
												/>
											)}
											name='payMethod'
											control={control}
										/>
									</S.TableValueColumn>
								</>
							)}
							<S.TableLabelColumn style={{ width: '10.4rem' }}>현금영수증</S.TableLabelColumn>
							<S.TableValueColumn style={{ width: '24rem' }}>
								<Controller
									render={({ field: { ref, onChange, value }, formState: { errors } }) => (
										<CRCheckBoxGroup
											ref={ref}
											checkType='single'
											type='radio'
											gap={0.8}
											value={[value]}
											onChange={(values) => {
												onChange(values[0]);
												onChangeReceipt(values[0].value);
											}}
											options={cashReceiptTargetOptions}
										/>
									)}
									name='receipt'
									control={control}
								/>
							</S.TableValueColumn>
						</S.TableRow>
						<S.TableRow>
							{!hideAccount && (
								<>
									<S.TableLabelColumn style={{ width: '10.4rem' }}>
										공제 대상자 <S.RequiredMark>*</S.RequiredMark>
									</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }}>
										<Controller
											render={({ field: { onChange, value }, formState: { errors } }) => (
												<CRInput.Selector
													items={(wageDeductionEmployees ?? []).map((item) => ({
														label: item.employeeNm,
														value: item.employeeId,
													}))}
													disabled={!wageDeductionEmployees?.length}
													currentValue={value ? { label: '', value } : undefined}
													onChangeValue={(option) => onChange(option.value)}
													placeholder='대상자 선택'
													type='small'
												/>
											)}
											name='payDeductEmployeeId'
											control={control}
										/>
									</S.TableValueColumn>
								</>
							)}
							<S.TableLabelColumn
								style={{ width: '10.4rem' }}
								rowSpan={receipt.value ? 1 : 3}
								colSpan={receipt.value ? 1 : 2}>
								{receipt.value && (
									<>
										이름 <S.RequiredMark>*</S.RequiredMark>
									</>
								)}
							</S.TableLabelColumn>
							{receipt.value && (
								<S.TableValueColumn style={{ width: '24rem' }}>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TableInput
												ref={ref}
												onBlur={onBlur}
												status={errors.name?.message ? 'error' : 'default'}
												onChange={onChange}
												value={value}
												placeholder='이름 입력'
												addOnBottom={formState.errors?.name?.message}
											/>
										)}
										name='name'
										control={control}
									/>
								</S.TableValueColumn>
							)}
						</S.TableRow>
						<S.TableRow>
							{!hideAccount && (
								<S.TableLabelColumn
									style={{ width: '10.4rem', height: '10.8rem' }}
									colSpan={2}
									rowSpan={2}
								/>
							)}
							{receipt.value && (
								<>
									<S.TableLabelColumn style={{ width: '10.4rem' }}>발급 방식</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }}>
										<Controller
											render={({
												field: { onBlur, ref, onChange, value },
												formState: { errors },
											}) => (
												<CRCheckBoxGroup
													ref={ref}
													checkType='single'
													type='radio'
													gap={0.8}
													value={value ? [value] : []}
													onChange={(values) => onChange(values[0])}
													options={receiptMethodOptions}
												/>
											)}
											name='receiptMethod'
											control={control}
										/>
									</S.TableValueColumn>
								</>
							)}
						</S.TableRow>
						<S.TableRow>
							{receipt.value && (
								<>
									<S.TableLabelColumn style={{ width: '10.4rem' }}>
										{watch('receiptMethod')?.value === 'CMN162.10' ? '휴대폰 번호' : '사업자 번호'}{' '}
										<S.RequiredMark>*</S.RequiredMark>
									</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }}>
										<Controller
											render={({
												field: { onBlur, ref, onChange, value },
												formState: { errors },
											}) => (
												<CRInput.TableInput
													ref={ref}
													onBlur={onBlur}
													status={errors.receiptNumber?.message ? 'error' : 'default'}
													type='number'
													onChange={onChange}
													value={value}
													addOnBottom={formState.errors?.receiptNumber?.message}
													placeholder={
														watch('receiptMethod')?.value === 'CMN162.10'
															? '휴대폰 번호 입력'
															: '사업자 번호 입력'
													}
												/>
											)}
											name='receiptNumber'
											control={control}
										/>
									</S.TableValueColumn>
								</>
							)}
						</S.TableRow>
					</S.Table>
				</S.Form>
			</S.Container>
		);
	}

	return (
		<S.Container>
			<S.Form>
				{!onlyEdit && (
					<S.Label>
						본인부담금 납부
						<S.ButtonContainer>
							<CRButton.Default
								size='xSmall'
								type='outlined'
								palette='gray'
								onClick={onSubmitHandler}>
								취소
							</CRButton.Default>
							<CRButton.Default
								buttonType='submit'
								size='xSmall'
								type='filled'
								palette='gray'
								onClick={handleSubmit(onSubmitSuccess, onSubmitFail as any)}>
								저장
							</CRButton.Default>
						</S.ButtonContainer>
					</S.Label>
				)}
				<S.Table>
					<S.TableRow>
						{!hideAccount && (
							<>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>납부 방식</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }}>
									<Controller
										render={({ field: { ref, onChange, value }, formState: { errors } }) => (
											<CRCheckBoxGroup
												ref={ref}
												checkType='single'
												type='radio'
												gap={0.8}
												value={[value]}
												onChange={(values) => {
													onChange(values[0]);
													onChangePayMethodType(values[0]?.value);
												}}
												options={payMethodOptions}
											/>
										)}
										name='payMethod'
										control={control}
									/>
								</S.TableValueColumn>
							</>
						)}
						<S.TableLabelColumn style={{ width: '10.4rem' }}>현금영수증</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '24rem' }}>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										ref={ref}
										checkType='single'
										type='radio'
										gap={0.8}
										value={[value]}
										onChange={(values) => {
											onChange(values[0]);
											onChangeReceipt(values[0].value);
										}}
										options={cashReceiptTargetOptions}
									/>
								)}
								name='receipt'
								control={control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						{!hideAccount && (
							<>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>
									은행 <S.RequiredMark>*</S.RequiredMark>
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }}>
									<Controller
										render={({ field: { onChange, value }, formState: { errors } }) => (
											<CRInput.Selector
												items={bankOptions}
												currentValue={bankOptions.find((option) => option.value === value)}
												onChangeValue={(option) => {
													onChange(option.value);
													setIsValidAccount(false);
												}}
												disabled={checkAccountLoading}
												status={errors.bank?.message ? 'error' : 'default'}
												placeholder='은행 입력'
												type='small'
												addOnBottom={formState.errors?.bank?.message}
											/>
										)}
										name='bank'
										control={control}
									/>
								</S.TableValueColumn>
							</>
						)}
						<S.TableLabelColumn
							style={{ width: '10.4rem' }}
							rowSpan={receipt.value ? 1 : 3}
							colSpan={receipt.value ? 1 : 2}>
							{receipt.value && (
								<>
									이름 <S.RequiredMark>*</S.RequiredMark>
								</>
							)}
						</S.TableLabelColumn>
						{receipt.value && (
							<S.TableValueColumn style={{ width: '24rem' }}>
								<Controller
									render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
										<CRInput.TableInput
											ref={ref}
											onBlur={onBlur}
											status={errors.name?.message ? 'error' : 'default'}
											onChange={onChange}
											value={value}
											placeholder='이름 입력'
											addOnBottom={formState.errors?.name?.message}
										/>
									)}
									name='name'
									control={control}
								/>
							</S.TableValueColumn>
						)}
					</S.TableRow>
					<S.TableRow>
						{!hideAccount && (
							<>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>
									계좌번호 <S.RequiredMark>*</S.RequiredMark>
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }}>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TableInput
												type='number'
												ref={ref}
												onBlur={onBlur}
												disabled={checkAccountLoading}
												status={errors.account?.message ? 'error' : 'default'}
												onChange={(item) => {
													onChange(item);
													setIsValidAccount(false);
												}}
												value={value}
												placeholder='계좌번호 입력'
												addOnBottom={formState.errors?.account?.message}
											/>
										)}
										name='account'
										control={control}
									/>
								</S.TableValueColumn>
							</>
						)}
						{receipt.value && (
							<>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>발급 방식</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }}>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRCheckBoxGroup
												ref={ref}
												checkType='single'
												type='radio'
												gap={0.8}
												value={value ? [value] : []}
												onChange={(values) => onChange(values[0])}
												options={receiptMethodOptions}
											/>
										)}
										name='receiptMethod'
										control={control}
									/>
								</S.TableValueColumn>
							</>
						)}
					</S.TableRow>
					<S.TableRow>
						{!hideAccount && (
							<>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>
									계좌주 <S.RequiredMark>*</S.RequiredMark>
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }}>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TableInput
												ref={ref}
												onBlur={onBlur}
												status={errors.accountOwner?.message ? 'error' : 'default'}
												onChange={(item) => {
													onChange(item);
													setIsValidAccount(false);
												}}
												disabled={checkAccountLoading}
												value={value}
												placeholder='계좌주 입력'
												addOnBottom={formState.errors?.accountOwner?.message}
												suffix={
													<CRButton.Default
														disabled={isValidAccount}
														style={{
															position: 'relative',
															width: checkAccountLoading ? '4rem' : 'auto',
														}}
														size='xSmall'
														palette='gray'
														type='outlined'
														onClick={handleClickCheckAccount}>
														{checkAccountLoading ? (
															<CRSpinner />
														) : isValidAccount ? (
															<CRIcon src={Assets.icon.check} />
														) : (
															'조회'
														)}
													</CRButton.Default>
												}
											/>
										)}
										name='accountOwner'
										control={control}
									/>
								</S.TableValueColumn>
							</>
						)}
						{receipt.value && (
							<>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>
									{watch('receiptMethod')?.value === 'CMN162.10' ? '휴대폰 번호' : '사업자 번호'}{' '}
									<S.RequiredMark>*</S.RequiredMark>
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }}>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TableInput
												ref={ref}
												onBlur={onBlur}
												status={errors.receiptNumber?.message ? 'error' : 'default'}
												type='number'
												onChange={onChange}
												value={value}
												addOnBottom={formState.errors?.receiptNumber?.message}
												placeholder={
													watch('receiptMethod')?.value === 'CMN162.10'
														? '휴대폰 번호 입력'
														: '사업자 번호 입력'
												}
											/>
										)}
										name='receiptNumber'
										control={control}
									/>
								</S.TableValueColumn>
							</>
						)}
					</S.TableRow>
				</S.Table>
			</S.Form>
		</S.Container>
	);
}

export default RecipientOwnExpenseAutomaticForm;
