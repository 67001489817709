import styled from 'styled-components';

export const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 1.6rem;
	box-sizing: border-box;
	overflow: hidden;
`;

export const ScrollableContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 1.6rem;
	gap: 1.6rem;
	box-sizing: border-box;
	overflow: overlay;
`;

export const TableContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow: auto;
	border-radius: 0.4rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	box-sizing: border-box;
	background: ${(props) => props.theme.colors.gray99};
`;

export const OwnExpenseHeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1.6rem;
	overflow: hidden;
`;

export const OwnExpenseItem = styled.div`
	flex: 1;
	overflow: hidden;
	box-sizing: border-box;
`;

export const OwnExpenseContentContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	gap: 1.6rem;
	overflow: hidden;
`;

export const Table = styled.table`
	width: 100%;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const TableLabelColumn = styled.td`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
	padding: 1.2rem;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	background: ${(props) => props.theme.colors.gray99};
`;

export const TableValueColumn = styled.td`
	${(props) => props.theme.typography.label};
	position: relative;
	box-sizing: border-box;
	padding: 0 1.2rem;
	background: ${(props) => props.theme.colors.gray100};
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const RightButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
	/* position: absolute;
	right: 1.2rem;
	top: 50%;
	transform: translateY(-50%); */
`;

export const WithdrawalButtonContainer = styled.div``;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const CalendarContainer = styled.div`
	position: relative;
	padding: 1.6rem;
	overflow: auto;
	min-height: 100%;
`;

export const CalendarHeader = styled.div`
	margin-bottom: 0.8rem;
`;
