import { css, styled } from 'styled-components';

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	width: 57.2rem;
`;

export const TableContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	align-items: flex-start;
`;

export const TableItem = styled.div``;

export const Table = styled.table<{ $isDisabled?: boolean }>`
	width: 100%;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};

	${(props) =>
		props.$isDisabled &&
		css`
			background: ${(props) => props.theme.colors.gray99};
			color: ${(props) => props.theme.colors.gray60};
		`};
`;

export const TableRow = styled.tr`
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};

	&:last-child {
		border-bottom: 0;
	}
`;

export const TableLabelColumn = styled.td`
	${(props) => props.theme.typography.label};
	width: 12rem;
	height: 4.9rem;
	padding: 0.8rem;
	box-sizing: border-box;
	color: ${(props) => props.theme.colors.gray60};
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	background: ${(props) => props.theme.colors.gray99};
	vertical-align: middle;
`;

export const RequiredMark = styled.span`
	color: ${(props) => props.theme.colors.primary60};
`;

export const TableValueColumn = styled.td`
	${(props) => props.theme.typography.label};
	position: relative;
	width: 44rem;
	box-sizing: border-box;
	height: 4.9rem;
	padding: 0.8rem;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	vertical-align: middle;
`;

export const ItemHeader = styled.div`
	${(props) => props.theme.typography.label}
	display: flex;
	height: 2.6rem;
	flex-direction: row;
	align-items: center;
	gap: 0.8rem;
	margin-bottom: 0.8rem;
`;

export const DeleteButtonContainer = styled.span`
	flex: 1;
`;

export const ServiceContainer = styled.ul`
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: 0.8rem;
	padding: 1.6rem;
`;

export const ServiceItem = styled.li``;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1.6rem;
	box-sizing: border-box;
	border-radius: 0.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	margin-top: 1.6rem;
	gap: 2rem;
`;

export const Title = styled.p`
	${(props) => props.theme.typography.bodyB};
	display: flex;
	justify-content: space-between;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
`;

export const SwitchContainer = styled.div`
	display: flex;
	height: 5.8rem;
	align-items: center;
`;

export const ResultSelectContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
`;

export const ResultSelectItem = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	padding: 1.6rem;
	border-radius: 0.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const ResultLabel = styled.div`
	${(props) => props.theme.typography.body};
`;
