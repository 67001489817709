import React, { useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';

import CRSpinner from 'components/base/CRSpinner';
import CRTableMonthSelector from 'components/base/CRTableMonthSelector';
import WorkScheduleCalendar from 'components/domain/calendar/WorkScheduleCalendar';
import VacationViewDialog from 'components/domain/dialog/VacationViewDialog';
import WorkLogDetailDialog from 'components/domain/dialog/WorkLogDetailDialog';
import { employeeWorkHistoryDataAdapter } from 'lib/adapter/workSchedule';
import useFullTimeEmployeePage from 'lib/hook/full-time-employee/useFullTimeEmployeePage';
import { useFullTimeEmployeeWorkHistory } from 'lib/hook/react-query';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import {
	EmployeeDailyWorkHistoryViewType,
	EmployeeVacationInfoViewType,
} from 'types/view/workSchedule';

import * as S from './styles';

export default function FullTimeEmployeeScheduleTab(): React.ReactElement {
	const [date, setDate] = useState(new Date());
	const { currentEmployee } = useFullTimeEmployeePage();
	const { showDialog } = useGlobalLayout();

	const { data: employeeWorkScheduleData, isLoading: scheduleDataLoading } =
		useFullTimeEmployeeWorkHistory(
			{
				centerId: currentEmployee?.centerId as number,
				yearMonth: dayjs(date).format('YYYYMM') as string,
				employeeId: currentEmployee?.employeeId as number,
			},
			employeeWorkHistoryDataAdapter(dayjs(date)),
		);

	const handleChangeDate = (newDate: Date) => {
		setDate(newDate);
	};

	const handleClickWorkHistoryLabel = (
		date: Dayjs,
		workhistoryOfTargetDate?: EmployeeDailyWorkHistoryViewType,
	) =>
		workhistoryOfTargetDate?.workHistoryInfo?.data?.workScheduleId
			? showDialog(() => <WorkLogDetailDialog targetDate={date} data={workhistoryOfTargetDate} />)
			: null;

	const handleClickVacationLabel = (
		data: EmployeeVacationInfoViewType,
		confirmed: boolean,
		targetDate: Dayjs,
	) =>
		showDialog(() => (
			<VacationViewDialog targetDate={targetDate} vacationDataOfTargetDate={data} />
		));

	return (
		<S.CalendarContainer>
			{scheduleDataLoading ? (
				<CRSpinner />
			) : (
				<>
					<S.CalendarHeader>
						<CRTableMonthSelector value={date || new Date()} onChange={handleChangeDate} />
					</S.CalendarHeader>
					<WorkScheduleCalendar
						date={date}
						workCalendarData={employeeWorkScheduleData ?? undefined}
						onChange={handleChangeDate}
						handleClickVacationLabel={handleClickVacationLabel}
						handleClickWorkHistoryLabel={handleClickWorkHistoryLabel}
						renderCustomCalendarHeader={() => undefined}
					/>
				</>
			)}
		</S.CalendarContainer>
	);
}
