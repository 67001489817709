import React, { ReactNode } from 'react';

import * as S from './styles';

interface Props extends React.ComponentPropsWithoutRef<'td'> {
	children?: ReactNode;
}

function CRStyledBody({ children, ...rest }: Props) {
	return <S.TableLabelColumn {...rest}>{children}</S.TableLabelColumn>;
}

export default CRStyledBody;
