import React, { ChangeEvent, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { CRText } from 'components/base/CRText';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import { CRTableHeadConfig } from 'types/view/base';

import * as S from './styles';

interface Props {
	heads: CRTableHeadConfig[];
	offset?: number;
	disableBackBlind?: boolean;
	checkIds?: string[];
	onToggleCheck?: (e: ChangeEvent<HTMLInputElement>) => void;
}

function CRTableHead({
	heads,
	offset = 0,
	disableBackBlind = false,
	checkIds,
	onToggleCheck,
}: Props): React.ReactElement {
	const [top, setTop] = useState(0);
	const [checkAll, setCheckAll] = useState<boolean>(false);

	const ref = useRef<HTMLTableSectionElement>(null);

	useLayoutEffect(() => {
		const parentTop = !disableBackBlind
			? ref.current?.parentElement?.parentElement?.parentElement?.getBoundingClientRect().top
			: ref.current?.parentElement?.parentElement?.getBoundingClientRect().top;
		const newTop = (ref.current?.getBoundingClientRect().top ?? 0) - (parentTop ?? 0);
		setTop(newTop + offset - 1);
	}, [offset, disableBackBlind]);

	useEffect(() => {
		if (checkIds && !checkIds.length) {
			setCheckAll(false);
		}
	}, [checkIds]);

	const renderHeaderRowItem = ({
		label,
		disabledPadding,
		sortKey,
		checkbox,
		fontColor = 'gray60',
		...rest
	}: CRTableHeadConfig) => {
		const contentItem = (
			<S.TableHeaderColumnContentContainer $isCheckBox={!!checkbox}>
				{checkbox ? (
					<CRCheckBox
						palette='gray'
						checkBoxType='toggle'
						checked={checkAll}
						onChange={(e) => {
							setCheckAll(!checkAll);
							onToggleCheck?.(e);
						}}
					/>
				) : (
					<CRText typography='label' color={fontColor}>
						{label}
					</CRText>
				)}
			</S.TableHeaderColumnContentContainer>
		);

		return (
			<S.TableHeaderColumn
				key={sortKey}
				$isSortable={disabledPadding ? false : !!sortKey}
				$disabledPadding={disabledPadding}
				style={{
					...rest,
					maxWidth: rest.width,
					minWidth: rest.width,
					top,
				}}>
				{contentItem}
			</S.TableHeaderColumn>
		);
	};

	return (
		<S.TableHeader ref={ref}>
			<tr>{heads.map(renderHeaderRowItem)}</tr>
		</S.TableHeader>
	);
}

export default React.memo(CRTableHead);
