import React, { useCallback } from 'react';

import dayjs from 'dayjs';
import { v4 } from 'uuid';

import Assets from 'assets';
import CRIcon from 'components/base/CRIcon';
import CRSpinner from 'components/base/CRSpinner';
import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import { MessageTabSendHistoryDTO, SendStateCd } from 'types/api/send';

import { SEND_HISTORY_HEADER_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	isLoading?: boolean;
	items?: MessageTabSendHistoryDTO[];
	onClickRow?: (task: MessageTabSendHistoryDTO) => void;
}

function EmployeeMessageTable({ isLoading, items = [], onClickRow }: Props): React.ReactElement {
	const SendStatusBadge = useCallback((value: string, item?: MessageTabSendHistoryDTO) => {
		if (!item) return null;
		const options = [
			{ key: item.sendStateCd, label: item.sendStateNm, color: item.sendStateColor },
		];
		const content =
			item?.sendStateCd === SendStateCd.발송불가 ? (
				<>
					문서에 오류가 생겨 발송할 수 없습니다.
					<br />
					발송 탭에서 다시 발송해 주십시오.
				</>
			) : (
				`사유 : ${item?.sendResultDesc || ''}`
			);
		return (
			<S.SendStatusContainer>
				<CRStatus options={options}>{value}</CRStatus>
				{[SendStateCd.실패, SendStateCd.발송불가].includes(item.sendStateCd as SendStateCd) && (
					<RDTooltip side='top' content={content}>
						<CRIcon src={Assets.icon.help} />
					</RDTooltip>
				)}
			</S.SendStatusContainer>
		);
	}, []);

	const renderSendDate = useCallback(
		(value: string) => <S.TextContainer>{dayjs(value).format('YYYY.MM.DD HH:mm')}</S.TextContainer>,
		[],
	);

	return (
		<S.Container>
			{isLoading ? (
				<CRSpinner />
			) : (
				<CRTable.Root>
					<CRTable.Head heads={SEND_HISTORY_HEADER_CONFIG} />
					<CRTable.Body>
						{items?.map((item) => (
							<CRTable.Row
								key={v4()}
								item={item}
								renderKeys={[
									'sendDate',
									'sendTemplateTitle',
									'sendTitle',
									'senderNm',
									'sendStateCd',
								]}
								customRender={{
									sendStateCd: SendStatusBadge,
									sendDate: renderSendDate,
								}}
								onClick={onClickRow}
							/>
						))}
					</CRTable.Body>
				</CRTable.Root>
			)}
		</S.Container>
	);
}

export default EmployeeMessageTable;
