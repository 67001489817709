import React, { ReactElement } from 'react';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';

import * as S from './styles';

interface Props {
	title?: ReactElement | string;
	content?: ReactElement | string;
	successOption?: {
		text?: string;
		successCallback?: () => void;
	};
	cancelOption?: {
		text?: string;
		callback?: () => void;
	};
	hideDialog?: () => void;
	hideCloseButton?: boolean;
}

export default function DeleteDialog({
	title,
	content,
	hideDialog,
	successOption,
	cancelOption,
	hideCloseButton,
}: Props): React.ReactElement {
	return (
		<S.Container>
			<S.ContentsContainer>
				<S.HeaderContainer>
					<S.HeaderTitleContainer>
						<S.HeaderTitle>{title}</S.HeaderTitle>
						{!hideCloseButton && (
							<S.CloseIcon src={Assets.icon.close} alt='close' onClick={() => hideDialog?.()} />
						)}
					</S.HeaderTitleContainer>
				</S.HeaderContainer>
				<S.MainContainer>{content}</S.MainContainer>
			</S.ContentsContainer>
			<S.FooterContainer>
				{cancelOption && (
					<CRButton.Default
						type='text'
						palette='gray'
						onClick={() => {
							cancelOption?.callback?.();
							hideDialog?.();
						}}>
						{cancelOption?.text}
					</CRButton.Default>
				)}
				<CRButton.Default
					type='outlined'
					onClick={() => {
						successOption?.successCallback?.();
					}}>
					{successOption?.text}
				</CRButton.Default>
			</S.FooterContainer>
		</S.Container>
	);
}
