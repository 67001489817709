import React from 'react';
import { FieldPath } from 'react-hook-form';

import CRFormTableInput from 'components/base/CRFormInput/CRFormTableInput';
import { CRText } from 'components/base/CRText';
import {
	SalaryDetailDialogFormFields,
	SalaryFeeCdKorKeys,
} from 'components/domain/dialog/SalaryDetailDialog/types';

import * as S from './styles';

interface Props {
	disabled?: boolean;
}

function SalaryDetailSalaryFeeInformation({ disabled }: Props) {
	const makeInputName = (field: SalaryFeeCdKorKeys): FieldPath<SalaryDetailDialogFormFields> =>
		`salaryFee.${field}.salaryFeeValue`;

	return (
		<S.Container>
			<CRText typography='label' text='방문요양 (일반) 임금 정보' margin='0 0 0.4rem 0' />
			<S.Table style={{ marginBottom: '2rem' }}>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '25%' }}>일반 1등급</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '25%' }}>
						<CRFormTableInput
							name={makeInputName('요양_일반_일반1등급')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn style={{ width: '25%' }}>일반 2등급</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '25%' }}>
						<CRFormTableInput
							name={makeInputName('요양_일반_일반2등급')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>일반 3등급</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('요양_일반_일반3등급')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>일반 4등급</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('요양_일반_일반4등급')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>일반 5등급</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('요양_일반_일반5등급')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn colSpan={2} />
				</S.TableRow>
			</S.Table>
			<CRText typography='label' text='방문요양 (가족) 임금 정보' margin='0 0 0.4rem 0' />
			<S.Table style={{ marginBottom: '2rem' }}>
				<S.TableRow>
					<S.TableLabelColumn colSpan={2}>60시간 미만</S.TableLabelColumn>
					<S.TableLabelColumn colSpan={2}>60시간 이상</S.TableLabelColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '25%' }}>가족 30분</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '25%' }}>
						<CRFormTableInput
							name={makeInputName('요양_가족_60시간미만_가족30분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn style={{ width: '25%' }}>가족 30분</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '25%' }}>
						<CRFormTableInput
							name={makeInputName('요양_가족_60시간이상_가족30분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>가족 60분</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('요양_가족_60시간미만_가족60분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>가족 60분</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('요양_가족_60시간이상_가족60분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>가족 90분</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('요양_가족_60시간미만_가족90분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>가족 90분</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('요양_가족_60시간이상_가족90분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
			</S.Table>

			<CRText typography='label' text='방문목욕 임금 정보' margin='0 0 0.4rem 0' />
			<S.Table style={{ marginBottom: '2rem' }}>
				<S.TableRow>
					<S.TableLabelColumn colSpan={2}>60시간 미만</S.TableLabelColumn>
					<S.TableLabelColumn colSpan={2}>60시간 이상</S.TableLabelColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '25%' }}>가족 40분</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '25%' }}>
						<CRFormTableInput
							name={makeInputName('목욕_60시간미만_가족40분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn style={{ width: '25%' }}>가족 40분</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '25%' }}>
						<CRFormTableInput
							name={makeInputName('목욕_60시간이상_가족40분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>가족 60분</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('목욕_60시간미만_가족60분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>가족 60분</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('목욕_60시간이상_가족60분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>일반 40분</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('목욕_60시간미만_일반40분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>일반 40분</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('목욕_60시간이상_일반40분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>일반 60분</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('목욕_60시간미만_일반60분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>일반 60분</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('목욕_60시간이상_일반60분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>가정내목욕 40분</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('목욕_60시간미만_가정내목욕40분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>가정내목욕 40분</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('목욕_60시간이상_가정내목욕40분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>가정내목욕 60분</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('목욕_60시간미만_가정내목욕60분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>가정내목욕 60분</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('목욕_60시간이상_가정내목욕60분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>차량내목욕 40분</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('목욕_60시간미만_차량내목욕40분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>차량내목욕 40분</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('목욕_60시간이상_차량내목욕40분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>차량내목욕 60분</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('목욕_60시간미만_차량내목욕60분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>차량내목욕 60분</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('목욕_60시간이상_차량내목욕60분')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
			</S.Table>

			<CRText typography='label' text='방문간호 임금 정보' margin='0 0 0.4rem 0' />
			<S.Table style={{ marginBottom: '2rem' }}>
				<S.TableRow>
					<S.TableLabelColumn colSpan={2}>60시간 미만</S.TableLabelColumn>
					<S.TableLabelColumn colSpan={2}>60시간 이상</S.TableLabelColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '25%' }}>가족 30분 미만</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '25%' }}>
						<CRFormTableInput
							name={makeInputName('간호_60시간미만_가족30분미만')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn style={{ width: '25%' }}>가족 30분 미만</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '25%' }}>
						<CRFormTableInput
							name={makeInputName('간호_60시간이상_가족30분미만')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>가족 30분 이상 60분 미만</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('간호_60시간미만_가족30분이상60분미만')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>가족 30분 이상 60분 미만</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('간호_60시간이상_가족30분이상60분미만')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>가족 60분 이상</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('간호_60시간미만_가족60분이상')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>가족 60분 이상</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('간호_60시간이상_가족60분이상')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>일반 30분 미만 (간호사)</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('간호_60시간미만_일반30분미만_간호사')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>일반 30분 미만 (간호사)</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('간호_60시간이상_일반30분미만_간호사')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>일반 30분 이상 60분 미만 (간호사)</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('간호_60시간미만_일반30분이상60분미만_간호사')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>일반 30분 이상 60분 미만 (간호사)</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('간호_60시간이상_일반30분이상60분미만_간호사')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>일반 60분 이상 (간호사)</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('간호_60시간미만_일반60분이상_간호사')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>일반 60분 이상 (간호사)</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('간호_60시간이상_일반60분이상_간호사')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>

				<S.TableRow>
					<S.TableLabelColumn>일반 30분 미만 (간호조무사)</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('간호_60시간미만_일반30분미만_간호조무사')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>일반 30분 미만 (간호조무사)</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('간호_60시간이상_일반30분미만_간호조무사')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>일반 30분 이상 60분 미만 (간호조무사)</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('간호_60시간미만_일반30분이상60분미만_간호조무사')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>일반 30분 이상 60분 미만 (간호조무사)</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('간호_60시간이상_일반30분이상60분미만_간호조무사')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>일반 60분 이상 (간호조무사)</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('간호_60시간미만_일반60분이상_간호조무사')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>일반 60분 이상 (간호조무사)</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('간호_60시간이상_일반60분이상_간호조무사')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
			</S.Table>
			<CRText typography='label' text='기타 임금 정보' margin='0 0 0.4rem 0' />
			<S.Table style={{ marginBottom: '2rem' }}>
				<S.TableRow>
					<S.TableLabelColumn width='25%'>종일방문요양 12시간</S.TableLabelColumn>
					<S.TableValueColumn width='25%'>
						<CRFormTableInput
							name={makeInputName('기타_종일방문요양12시간')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn width='25%'>종일방문요양 24시간</S.TableLabelColumn>
					<S.TableValueColumn width='25%'>
						<CRFormTableInput
							name={makeInputName('기타_종일방문요양24시간')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>복지수당</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('기타_복지수당')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='원'
						/>
					</S.TableValueColumn>
					<S.TableLabelColumn>연차</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRFormTableInput
							name={makeInputName('기타_연차')}
							disabled={disabled}
							maxLength={11}
							type='comma'
							suffix='일'
						/>
					</S.TableValueColumn>
				</S.TableRow>
			</S.Table>
		</S.Container>
	);
}

export default SalaryDetailSalaryFeeInformation;
