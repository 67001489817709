import path from 'path';

import { EDocRecordingSheetType } from 'types/api/eDoc';

export const endpoint = {
	example() {
		return 'example';
	},
	signIn: {
		key: 'signIn',
		path: () => '/member-accounts/sign-in',
	},
	signUp: {
		key: 'signUp',
		path: () => '/member-accounts/sign-up',
	},
	validateId: {
		key: 'validateId',
		path: () => '/member-accounts/check-user-id',
	},
	newPassword: {
		key: 'newPassword',
		path: () => '/member-accounts/password',
	},
	myTaskCount: {
		key: 'myTaskCount',
		path: () => '/my-tasks/counts',
	},
	myTaskTypes: {
		key: 'myTaskTypes',
		path: () => '/my-tasks/task-types',
	},
	myTaskProcess: {
		key: 'myTaskProcess',
		path: () => '/my-tasks/process',
	},
	myTaskComplete: {
		key: 'myTaskComplete',
		path: () => '/my-tasks/complete',
	},
	myTaskWait: {
		key: 'myTaskWait',
		path: () => '/my-tasks/wait',
	},
	subMyTask: {
		key: 'subMyTask',
		path: (myTaskId: number) => `/my-tasks/${myTaskId}/sub-my-tasks`,
	},
	newTaskTypes: {
		key: 'newTaskTypes',
		path: () => '/my-tasks/new-task-types',
	},
	newSubTaskTypes: {
		key: 'newSubTaskTypes',
		path: () => '/my-tasks/new-tasks',
	},
	createTask: {
		key: 'createTask',
		path: () => `/my-tasks`,
	},
	getRecipients: {
		key: 'getRecipients',
		path: () => '/recipients',
	},
	getRecipient: {
		key: 'getRecipient',
		path: () => '/recipients',
	},
	createRecipient: {
		key: 'createRecipient',
		path: () => '/recipients',
	},
	checkRecipientRsdnNo: {
		key: 'checkRecipientRsdnNo',
		path: () => '/recipients/check-duplicate',
	},
	getRecipientHistoryOfOwnExpense: {
		key: 'getRecipientHistoryOfOwnExpenseRate',
		path: (recipientId: string | number = '') => `/recipients/${recipientId}/history/burden-rate`,
	},
	createRecipientHistoryOfOwnExpenseRate: {
		key: 'createRecipientHistoryOfOwnExpenseRate',
		path: (recipientId: string | number = '') => `/recipients/${recipientId}/burden-rate`,
	},
	updateRecipientHistoryOfOwnExpenseRate: {
		key: 'updateRecipientHistoryOfOwnExpenseRate',
		path: (recipientId: string | number = '') => `/recipients/${recipientId}/burden-rate`,
	},
	deleteRecipientHistoryOfOwnExpenseRate: {
		key: 'deleteRecipientHistoryOfOwnExpenseRate',
		path: (recipientId: string | number = '') => `/recipients/${recipientId}/burden-rate`,
	},
	getRecipientHistoryOfManager: {
		key: 'getRecipientHistoryOfManager',
		path: (recipientId: string | number = '') => `/recipients/${recipientId}/history/manager`,
	},
	createRecipientHistoryOfManager: {
		key: 'createRecipientHistoryOfManager',
		path: (recipientId: string | number = '') => `/recipients/${recipientId}/manager`,
	},
	getRecipientHistoryOfLongTermGrade: {
		key: 'getRecipientHistoryOfEmployee',
		path: (recipientId: string | number = '') =>
			`/recipients/${recipientId}/history/long-term-grade`,
	},
	getAlarms: {
		key: 'getAlarms',
		path: (memberAccountId?: string) => `/member-accounts/${memberAccountId}/allims`,
	},
	getHolidays: {
		key: 'getHolidays',
		path: (year: string, countryCode: string) => `/${year}/${countryCode}`,
	},
	createUploadUrl: {
		key: 'createUploadUrl',
		path: () => `/common/files/upload-url`,
	},
	createDownloadUrl: {
		key: 'createDownloadUrl',
		path: () => `/common/files/download-url`,
	},
	uploadFile: {
		key: 'uploadFile',
		path: () => '',
	},
	downloadFile: {
		key: 'downloadFile',
		path: () => '',
	},
	uploadBurden: {
		key: 'uploadBurden',
		path: () => 'http://localhost:8085/burdens/upload',
	},
	uploadCharge: {
		key: 'uploadCharge',
		path: () => 'http://localhost:8085/schedules/charges/upload',
	},
	recipientCheckDuplicate: {
		key: 'recipientDuplicateCheck',
		path: () => '/recipients/check-duplicate',
	},
	getEmployees: {
		key: 'getEmployees',
		path: () => `/employees`,
	},
	getEmployeeBaseInfo: {
		key: 'getEmployeeBaseInfo',
		path: () => `/employees/base-info`,
	},
	getEmployeeDetailInfo: {
		key: 'getEmployeeDetailInfo',
		path: () => `/employees/detail-info`,
	},
	// common
	geCommonCodes: {
		key: 'geCommonCodes',
		path: () => `/common/search/select-box/comCodes`,
	},
	getCommonCenters: {
		key: 'getCommonCenters',
		path: () => '/common/search/select-box/all-centers',
	},
	getGuaranteedCenters: {
		key: 'getGuaranteedCenters',
		path: () => '/common/search/select-box/centers',
	},
	saveEmployeeBaseInfo: {
		key: 'saveEmployeeBaseInfo',
		path: () => `/employees/save/base-info`,
	},
	saveEmployeeWorkState: {
		key: 'saveEmployeeWorkState',
		path: () => `/employees/save/workState`,
	},
	employeeCheckDuplicate: {
		key: 'employeeCheckDuplicate',
		path: () => '/employees/check-duplicate',
	},
	getMyAccountInfo: {
		key: 'getMyAccountInfo',
		path: () => '/member-accounts/my',
	},
	createEmployee: {
		key: 'createEmployee',
		path: () => '/employees',
	},
	saveEmployeeOtherCorpWork: {
		key: 'saveEmployeeOtherCorpWork',
		path: () => `/employees/save/otherCorpWork-info`,
	},
	saveEmployeePayInfo: {
		key: 'saveEmployeePayInfo',
		path: () => `/employees/save/pay-info`,
	},
	getEmployeeHealthCheck: {
		key: 'getEmployeeHealthCheck',
		path: () => `/employees/employeeHealthCheck`,
	},
	saveEmployeeHealthCheck: {
		key: 'saveEmployeeHealthCheck',
		path: () => `/employees/employeeHealthCheck/save`,
	},
	deleteEmployeeHealthCheck: {
		key: 'deleteEmployeeHealthCheck',
		path: () => `/employees/employeeHealthCheck/delete`,
	},
	getCenterManager: {
		key: 'getCenterManager',
		path: (centerId: number) => `/common/centers/${centerId}/managers`,
	},
	getEmployeeCareerInfo: {
		key: 'getEmployeeCareerInfo',
		path: () => `/employees/employeeCareer-info`,
	},
	saveEmployeeCareer: {
		key: 'saveEmployeeCareer',
		path: () => `/employees/employeeCareer/save`,
	},
	deleteEmployeeCareer: {
		key: 'deleteEmployeeCareer',
		path: () => `/employees/employeeCareer/delete`,
	},
	getRecipientBase: {
		key: 'getRecipientBase',
		path: (recipientId: number | string = '') => `/recipients/${recipientId}/base`,
	},
	getCenterRecipientBase: {
		key: 'getCenterRecipientBase',
		path: (recipientId: number | string = '', centerId: number | string = '') =>
			`/recipients/${recipientId}/centers/${centerId}/base`,
	},
	updateRecipientBase: {
		key: 'updateRecipientBase',
		path: (recipientId: number | string = '') => `/recipients/${recipientId}/base`,
	},
	updateRecipientBurdenAmt: {
		key: 'updateRecipientBurdenAmt',
		path: (recipientId: number | string = '') => `/recipients/${recipientId}/burden-amt`,
	},
	updateRecipientGuardians: {
		key: 'updateRecipientGuardians',
		path: (recipientId: number | string = '') => `/recipients/${recipientId}/guardians`,
	},
	updateRecipientInUse: {
		key: 'updateRecipientInUse',
		path: (recipientId: number | string = '') => `/recipients/${recipientId}/in-use`,
	},
	updateRecipientWaiting: {
		key: 'updateRecipientWaiting',
		path: (recipientId: number | string = '') => `/recipients/${recipientId}/waiting`,
	},
	updateRecipientOtherServices: {
		key: 'updateRecipientOtherServices',
		path: (recipientId: number | string = '') => `/recipients/${recipientId}/other-services`,
	},
	getEmployeeLicenseInfo: {
		key: 'getEmployeeLicenseInfo',
		path: () => `/employees/employeeLicense-info`,
	},
	saveEmployeeLicense: {
		key: 'saveEmployeeLicense',
		path: () => `/employees/employeeLicense/save`,
	},
	deleteEmployeeLicense: {
		key: 'deleteEmployeeLicense',
		path: () => `/employees/employeeLicense/delete`,
	},
	deleteFileDetail: {
		key: 'deleteFileDetail',
		path: (fileDetailId: number) => `/common/files/${fileDetailId}`,
	},
	// 직원 -> 교육
	getEmployeeEduInfo: {
		key: 'getEmployeeEduInfo',
		path: () => `/employees/employeeEdu-info`,
	},
	saveEmployeeEducation: {
		key: 'saveEmployeeEducation',
		path: () => `/employees/employeeEdu/save`,
	},
	deleteEmployeeEducation: {
		key: 'deleteEmployeeEducation',
		path: () => `/employees/employeeEdu/delete`,
	},
	getEmployeeHistoryOfManager: {
		key: 'getEmployeeHistoryOfManager',
		path: (employeeId: number) => `/employees/${employeeId}/history/manager`,
	},
	saveEmployeeHistoryOfManager: {
		key: 'saveEmployeeHistoryOfManager',
		path: (employeeId: string | number = '') => `/employees/${employeeId}/manager`,
	},
	saveEmployeeScheduleDetail: {
		key: 'saveEmployeeScheduleDetail',
		path: () => `/schedules-schedule/employee/detail`,
	},
	getSchedule: {
		key: 'getEmployeeSchedule',
		path: (type: 'employee' | 'recipient') => `/schedules-schedule/${type}`,
	},
	getMyMenuInfo: {
		key: 'getMyMenu',
		path: () => '/member-accounts/roles/mymenus',
	},
	getAdviceClientOptions: {
		key: 'getAdviceClientOptions',
		path: () => '/advices/clients',
	},
	getAdviceMethods: {
		key: 'getAdviceMethods',
		path: () => '/advices/methods',
	},
	getAdviceTags: {
		key: 'getAdviceTags',
		path: () => '/advices/tags',
	},
	updateAdvice: {
		key: 'updateAdvice',
		path: (adviceId: number) => `/advices/${adviceId}`,
	},
	createAdvice: {
		key: 'createAdvice',
		path: () => '/advices',
	},
	getAdvice: {
		key: 'getAdvice',
		path: (adviceId: number) => `/advices/${adviceId}`,
	},
	getEmployeeAdvices: {
		key: 'getEmployeeAdvices',
		path: (employeeId: number) => `/employees/${employeeId}/advices`,
	},
	getEmployeeAdvice: {
		key: 'getEmployeeAdvice',
		path: (employeeId: number, adviceId: number) => `/employees/${employeeId}/advices/${adviceId}`,
	},
	getRecipientAdvices: {
		key: 'getRecipientAdvices',
		path: (recipientId?: number) => `/recipients/${recipientId}/advices`,
	},
	getRecipientAdvice: {
		key: 'getRecipientAdvice',
		path: (recipientId: number, adviceId: number) =>
			`/recipients/${recipientId}/advices/${adviceId}`,
	},
	getRecipientGuardianAdvice: {
		key: 'getRecipientGuardianAdvice',
		path: (recipientId: number, adviceId: number) =>
			`/recipients/${recipientId}/guardians/advices/${adviceId}`,
	},
	deleteAdvice: {
		key: 'deleteAdvice',
		path: (adviceId: number) => `advices/${adviceId}`,
	},
	saveRecipientScheduleDetail: {
		key: 'saveRecipientScheduleDetail',
		path: () => `/schedules-schedule/recipient/detail`,
	},
	getScheduleDetail: {
		key: 'getScheduleDetail',
		path: (type: 'employee' | 'recipient') => `/schedules-schedule/${type}/detail`,
	},
	getCenterList: {
		key: 'getCenterList',
		path: () => 'common/centers',
	},
	searchEmployees: {
		key: 'getCenterList',
		path: () => '/common/employees',
	},
	getAutomationUploadList: {
		key: 'getAutomationUploadList',
		path: () => `/common/excel/uploads`,
	},
	updateAutomationUploadList: {
		key: 'getAutomationUploadList',
		path: (uploadHistId: number) => `/common/excel/uploads/${uploadHistId}`,
	},
	getSendTemplateList: {
		key: 'getSendTemplateList',
		path: () => '/sends/templates',
	},
	getSendTargetList: {
		key: 'getSendTargetList',
		path: (templateId: string) => `/sends/templates/${templateId}/targets`,
	},
	getMonthlyBurdenCharges: {
		key: 'getMonthlyBurdenCharges',
		path: () => '/burdens-charges',
	},
	getMonthlyBurdenChargeDetail: {
		key: 'getMonthlyBurdenChargeDetail',
		path: (burdenAmtChargeId: string | number) => `/burdens-charges/${burdenAmtChargeId}`,
	},
	getMonthlyBurdenChargesSummary: {
		key: 'getMonthlyBurdenChargesSummary',
		path: () => `/burdens-charges/summary`,
	},
	uploadMonthlyBurdenCharges: {
		key: 'uploadMonthlyBurdenCharges',
		path: () => `/burdens-charges/upload`,
	},
	getBurdenPayHistories: {
		key: 'getBurdenPayHistories',
		path: () => '/burdens-pays/hists',
	},
	getBurdenPayHistoryDetail: {
		key: 'getBurdenPayHistoryDetail',
		path: () => '/burdens-pays/hists/detail',
	},
	sendBurdenCharge: {
		key: 'sendBurdenCharge',
		path: (burdenAmtChargeId: number) => `/burdens-charges/send/${burdenAmtChargeId}`,
	},
	cancelCashReceipt: {
		key: 'cancelCashReceipt',
		path: () => '/burdens-pays/cash-receipt/cancel',
	},
	issueCashReceipt: {
		key: 'issueCashReceipt',
		path: () => '/burdens-pays/cash-receipt/issue',
	},
	// 기록지
	getRecordingSheets: {
		key: 'getRecordingSheets',
		path: () => '/schedules-record',
	},
	// 직원 입금내역 탭
	getEmployeeSalarys: {
		key: 'getEmployeeSalarys',
		path: () => '/employees/salarys',
	},
	getContracts: {
		key: 'getContracts',
		path: () => '/contracts',
	},
	getRecipientContractServices: {
		key: 'getRecipientContractServices',
		path: () => '/contracts/recipient/services',
	},
	getEmployeeContractServices: {
		key: 'getEmployeeContractServices',
		path: () => '/contracts/employee/services',
	},
	renewalEmployeeContract: {
		key: 'renewalEmployeeContract',
		path: () => '/contracts/renewal/employee',
	},
	renewalRecipientContract: {
		key: 'renewalRecipientContract',
		path: () => '/contracts/renewal/recipient',
	},
	saveRenewalRecipientContract: {
		key: 'saveRenewalRecipientContract',
		path: () => '/contracts/renewal/recipient/temp',
	},
	createRenewalRecipientContract: {
		key: 'createRenewalRecipientContract',
		path: () => '/contracts/renewal/recipient/create',
	},
	changeEmployeeContract: {
		key: 'changeEmployeeContract',
		path: () => '/contracts/change/employee',
	},
	changeTempEmployeeContract: {
		key: 'changeTempEmployeeContract',
		path: () => '/contracts/change/employee/temp',
	},
	createChangeEmployeeContract: {
		key: 'createChangeEmployeeContract',
		path: () => '/contracts/change/employee/create',
	},
	getRecipientContractDocument: {
		key: 'getRecipientContractDocument',
		path: () => '/contracts/recipient/contract-document',
	},
	getRecipientUsePlan: {
		key: 'getRecipientUsePlan',
		path: () => '/contracts/recipient/use-plan',
	},
	getRecipientGuardian: {
		key: 'getRecipientGuardian',
		path: (contractId: number | string) => `/contracts/guardian/${contractId}`,
	},
	createRecipientGuardian: {
		key: 'createRecipientGuardian',
		path: (contractId: number | string) => `/contracts/guardian/${contractId}`,
	},
	updateContractStatus: {
		key: 'updateContractStatus',
		path: () => `/contracts`,
	},
	getContractDetail: {
		key: 'getContractDetail',
		path: (serviceContractId: number | string) => `/contracts/${serviceContractId}`,
	},
	getContractRenewalDetail: {
		key: 'getContractRenewalDetail',
		path: (serviceContractGroupUuid: number | string) =>
			`/contracts/renewal/recipient/${serviceContractGroupUuid}`,
	},
	getContractChangeEmployeeDetail: {
		key: 'getContractChangeEmployeeDetail',
		path: (serviceContractId: number | string) => `/contracts/change/employee/${serviceContractId}`,
	},
	saveCompleteContract: {
		key: 'saveCompleteContract',
		path: (serviceContractId: number | string) => `/contracts/${serviceContractId}`,
	},
	saveTempContract: {
		key: 'saveTempContract',
		path: (serviceContractId: number | string) => `/contracts/${serviceContractId}/temp`,
	},
	getShouldLeaveServices: {
		key: 'getShouldLeaveServices',
		path: () => `/contracts/leave/services`,
	},
	leaveServicesAsEmployee: {
		key: 'leaveServicesAsEmployee',
		path: () => `/contracts/leave/employee`,
	},
	leaveServicesAsRecipient: {
		key: 'leaveServicesAsRecipient',
		path: () => `/contracts/leave/recipient`,
	},
	checkRecipientProceedingContract: {
		key: 'checkRecipientProceedingContract',
		path: () => `/contracts/check/proceeding/recipient`,
	},
	checkEmployeeProceedingContract: {
		key: 'checkEmployeeProceedingContract',
		path: () => `/contracts/check/proceeding/employee`,
	},
	getRecipientCurrentServiceUseTime: {
		key: 'getRecipientCurrentServiceUseTime',
		path: () => `/contracts/recipient/use-time`,
	},
	uploadVisitNursingDirection: {
		key: 'uploadVisitNursingDirection',
		path: (serviceContractId: number | string) =>
			`/contracts/upload/${serviceContractId}/visit-nursing`,
	},
	issueEmployeeContract: {
		key: 'issueEmployeeContract',
		path: () => `/contracts/send/contract-employee/retrieve`,
	},
	getEmployeeContractDocument: {
		key: 'getEmployeeContractDocument',
		path: () => `/contracts/employee/contract-document`,
	},
	openEDocClient: {
		key: 'openEDocClient',
		path: () => '/edoc/client/open',
	},
	confirmEDocClient: {
		key: 'confirmEDocClient',
		path: () => '/edoc/client/confirm',
	},
	openEDocManager: {
		key: 'openEDocManager',
		path: () => '/edoc/manager/open',
	},
	confirmEDocManager: {
		key: 'confirmEDocManager',
		path: () => '/edoc/manager/confirm',
	},
	// 직원 서류 관리
	getEmployeeEDocList: {
		key: 'getEmployeeEDocList',
		path: () => '/edoc/employee-papers',
	},
	// 서류관리 탭 서류 종류
	getEDocPaperTypes: {
		key: 'getEDocPaperTypes',
		path: () => `/edoc/tab`,
	},
	// 발급가능 서류 종류
	getIssuableEDocPaperTypes: {
		key: 'getIssuableEDocPaperTypes',
		path: () => '/edoc/before/edoc',
	},
	// 등록가능 서류 종류
	getRegisterEDocPaperTypes: {
		key: 'getRegisterEDocPaperTypes',
		path: () => '/edoc/before/paper',
	},
	// 재직증명서 발급탭 전자문서 정보
	getEmploymentCertificationInfo: {
		key: 'getEmploymentCertificationInfo',
		path: (templateCode: string) => `/edoc/before/${templateCode}`,
	},
	// 전자문서 발급
	issueEDocument: {
		key: 'issueEDocument',
		path: () => '/edoc/issue',
	},
	// 전자문서 미리보기
	getEDocPreviewInfo: {
		key: 'getEDocPreviewInfo',
		path: () => '/edoc/preview/edoc-no',
	},
	// 전자문서 서류 등록
	createEDocPaper: {
		key: 'createEDocPaper',
		path: () => '/edoc/paper',
	},
	createHomeCarePaper: {
		key: 'createEDocPaper',
		path: () => '/edoc/paper/CMN119_0081',
	},
	// 개인정보 열람이력 등록
	inquireIndividualInfo: {
		key: 'inquireIndividualInfo',
		path: () => '/common/PersonalInfo',
	},
	// 직원 서류 관리
	getRecipientEDocList: {
		key: 'getRecipientEDocList',
		path: () => '/edoc/recipient-papers',
	},
	getRecipientBurdens: {
		key: 'getRecipientBurdens',
		path: () => '/recipients/burdens',
	},

	// 발송 - 메시지 발송이력
	getSendHistory: {
		key: 'getSendHistory',
		path: () => '/sends/hists',
	},
	// 발송 이력 - 메시지 발송이력 - 상세
	getSendHistoryDetail: {
		key: 'getSendHistoryDetail',
		path: (historyId: number) => `/sends/hists/${historyId}`,
	},

	// 발송하기
	sendMessage: {
		key: 'sendMessage',
		path: () => '/sends',
	},

	// 발송 이력 - 메시지 예약 발송이력
	getReservationSendHistory: {
		key: 'getReservationSendHistory',
		path: () => '/sends/reservations',
	},

	// 발송 이력 - 메시지 예약 발송이력 - 상세
	getReservationSendHistoryDetail: {
		key: 'getReservationSendHistoryDetail',
		path: (historyId: string) => `/sends/reservations/${historyId}`,
	},
	// 발송 이력 - 전자서명 발송 필터
	getESignHistoryFilter: {
		key: 'getESignHistoryFilter',
		path: () => '/edoc/sends/tab',
	},
	getESignHistory: {
		key: 'getESignHistory',
		path: () => '/edoc/sends/hists',
	},
	getESignHistoryDetail: {
		key: 'getESignHistoryDetail',
		path: () => '/edoc',
	},
	updateSignDueDate: {
		key: 'updateSignDueDate',
		path: () => '/edoc',
	},
	cancelEDoc: {
		key: 'cancelEDoc',
		path: () => '/edoc/cancel',
	},
	resendFailMessage: {
		key: 'resendFailMessage',
		path: (sendId: number) => `/sends/${sendId}/fail-resend`,
	},
	resendMessage: {
		key: 'resendMessage',
		path: (sendId: number, sendDetailId: number) =>
			`/sends/${sendId}/sendDetails/${sendDetailId}/resend`,
	},
	// 발송 이력 - 메시지 예약 발송이력 - 상세 - 발송 취소
	cancelReservationMessage: {
		key: 'getReservationSendHistoryDetail',
		path: (sendId: number) => `/sends/reservations/${sendId}/cancel`,
	},
	confirmEDoc: {
		key: 'confirmEDoc',
		path: () => `/edoc/confirm`,
	},
	openEDoc: {
		key: 'confirmEDoc',
		path: () => `/edoc/open`,
	},
	updateReservationMessage: {
		key: 'updateReservationMessage',
		path: (sendId: number) => `/sends/reservations/${sendId}`,
	},
	// 임금관리 목록 호출
	getSalarysEmployeeSalaryList: {
		key: 'getSalarysEmployeeSalaryList',
		path: () => '/salarys/employee-salarys',
	},
	messageTabSendHistory: {
		key: 'messageTabSendHistory',
		path: (type: 'employees' | 'recipients', id: number) => `/sends/hists/${type}/${id}`,
	},
	messageTabReservationHistory: {
		key: 'messageTabReservationHistory',
		path: (type: 'employees' | 'recipients', id: number) => `/sends/reservations/${type}/${id}`,
	},
	messageTabESignHistory: {
		key: 'messageTabESignHistory',
		path: (type: 'employees' | 'recipients', id: number) => `/edoc/sends/hists/${type}/${id}`,
	},
	// 임금관리 상세 호출
	getSalarysEmployeeSalaryDetail: {
		key: 'getSalarysEmployeeSalaryDetail',
		path: (employeesSalaryId: number) => `/salarys/employee-salarys/${employeesSalaryId}`,
	},
	// 임금관리 상세 호출
	requestSalarysAutoBilling: {
		key: 'requestSalarysAutoBilling',
		path: () => `/salarys/auto/billing`,
	},
	// 임금대장 다운로드 요청
	getEmployeeSalarysPayroll: {
		key: 'getEmployeeSalarysPayroll',
		path: () => `/salarys/employee-salarys/download/payroll`,
	},
	// 대량이체 다운로드 요청
	getEmployeeSalarysTransfer: {
		key: 'getEmployeeSalarysTransfer',
		path: () => `/salarys/employee-salarys/download/transfer`,
	},

	// 임금 수정 저장 요청
	updateSalarysDetail: {
		key: 'updateSalarysDetail',
		path: (employeesSalaryId: number) => `/salarys/employee-salarys/${employeesSalaryId}`,
	},
	// 임금 개별 재계산 요청
	updateSalarysCalculate: {
		key: 'updateSalarysCalculate',
		path: (employeesSalaryId: number) => `/salarys/employee-salarys/${employeesSalaryId}`,
	},

	// 임금 현금 수령증 발송
	sendCashReceive: {
		key: 'sendCashReceive',
		path: (employeesSalaryId: number) => `/salarys/send/cash-receive/${employeesSalaryId}`,
	},
	// 임금 명세서 발송
	sendEmployeeSalary: {
		key: 'sendEmployeeSalary',
		path: (employeesSalaryId: number) => `/salarys/send/employee-salary/${employeesSalaryId}`,
	},
	// 임금 명세서 발송
	completeEmployeeSalary: {
		key: 'completeEmployeeSalary',
		path: (employeesSalaryId: number, salaryCompleteYn: boolean) =>
			`/salarys/employee-salarys/${employeesSalaryId}/salaryComplete/${salaryCompleteYn}`,
	},

	// 일정관리 - 알림톡 수급자 일정조회
	getClientScheduleRecipient: {
		key: 'getClientScheduleRecipient',
		path: (sendUuid: string) => `/customers/recipient/${sendUuid}`,
	},
	// 일정관리 - 알림톡 직원 일정조회
	getClientScheduleEmployee: {
		key: 'getClientScheduleEmployee',
		path: (sendUuid: string) => `/customers/employee/${sendUuid}`,
	},
	// 기록지 발송
	sendRecordingSheets: {
		key: 'sendRecordingSheets',
		path: () => '/schedules-record',
	},
	// 전자문서 클라이언트 기록지 저장
	saveRecordingSheetsClient: {
		key: 'saveRecordingSheetsClient',
		path: (type: EDocRecordingSheetType) => `customers/record/${type}`,
	},
	getRecipientIntegratedAssessment: {
		key: 'getRecipientIntegratedAssessment',
		path: (recipientId: number | string) => `/recipients/${recipientId}/recipient-ias`,
	},
	getRecipientIntegratedAssessmentUpdateForm: {
		key: 'getRecipientIntegratedAssessmentUpdateForm',
		path: (recipientId: number | string, recipientIaId: number | string) =>
			`/recipients/${recipientId}/recipient-ias/${recipientIaId}`,
	},
	getRecipientIntegratedAssessmentPreviousData: {
		key: 'getRecipientIntegratedAssessmentPreviousData',
		path: (recipientId: number | string, recipientIaId: number | string) =>
			`/recipients/${recipientId}/recipient-ias/${recipientIaId}/previous`,
	},
	getRecipientIntegratedAssessmentPreviousList: {
		key: 'getRecipientIntegratedAssessmentPreviousList',
		path: (recipientId: number | string) => `/recipients/${recipientId}/recipient-ias/previous`,
	},
	getRecipientIntegratedAssessmentScore: {
		key: 'getRecipientIntegratedAssessmentScore',
		path: (recipientId: number | string) => `/recipients/${recipientId}/recipient-ias/scores`,
	},
	createRecipientIntegratedAssessment: {
		key: 'createRecipientIntegratedAssessment',
		path: () => `/recipients/recipient-ias`,
	},
	updateRecipientIntegratedAssessment: {
		key: 'updateRecipientIntegratedAssessment',
		path: (recipientIaId: number | string) => `/recipients/recipient-ias/${recipientIaId}`,
	},
	confirmRecipientIntegratedAssessment: {
		key: 'confirmRecipientIntegratedAssessment',
		path: (recipientIaId: number | string) => `/recipients/recipient-ias/${recipientIaId}/confirm`,
	},
	deleteRecipientIntegratedAssessment: {
		key: 'deleteRecipientIntegratedAssessment',
		path: (recipientIaId: number | string) => `/recipients/recipient-ias/${recipientIaId}`,
	},
	// 월별 일정관리 - 일정 발송 상세
	getSendScheduleDetail: {
		key: 'sendScheduleDetail',
		path: (type: 'employees' | 'recipients', id: number) => `/sends/${type}/${id}/schedules`,
	},
	// 일정관리 - 태그 오류 관리
	getScheduleTagErrorHistory: {
		key: 'getScheduleTagErrorHistory',
		path: () => `/schedules-tag-error/tagHist`,
	},
	createContract: {
		key: 'createContract',
		path: () => `/contracts`,
	},
	// 일정 관리 - 태그 오류 관리 상세 수정
	updateScheduleTagErrorHistoryDetail: {
		key: 'updateScheduleTagErrorHistoryDetail',
		path: () => `/schedules-tag-error/tagHist/detail`,
	},
	// 일정관리 - 태그 수정 자동화 결과
	getScheduleTagAutomationHistory: {
		key: 'getScheduleTagAutomationHistory',
		path: () => `/schedules-tag-automation/tagErrorCorrection`,
	},
	// 최근 자동화 동기화 조회
	getAutomationLastSync: {
		key: 'getAutomationLastSync',
		path: () => `/common/excel/uploads/max`,
	},
	// 태그 오류 관리 태그 동기화
	tagErrorAutomation: {
		key: 'tagErrorAutomation',
		path: () => '/schedules-tag-error/tagHist',
	},
	// 일정 내역 동기화
	scheduleAutomation: {
		key: 'scheduleAutomation',
		path: () => '/employees/auto/schedule',
	},
	// 직원 퇴사 정보
	getEmployeeResignInfo: {
		key: 'getEmployeeResignInfo',
		path: () => '/employees/employeeResign-info',
	},
	// 직원 퇴사
	resignEmployee: {
		key: 'resignEmployee',
		path: () => '/employees/employeeResign-info/saveEmployeeResign',
	},
	// 예금주 조회
	checkAccountOwner: {
		key: 'checkAccountOwner',
		path: () => '/common/getAccountOwner',
	},
	// 수급자 퇴소
	resignRecipient: {
		key: 'resignRecipient',
		path: (recipientId: number) => `/recipients/${recipientId}/retirement`,
	},
	getRecipientServices: {
		key: 'getRecipientServices',
		path: () => '/contracts/leave/services',
	},
	getRecipientIntegratedAssessmentSalaryOfferSend: {
		key: 'getRecipientIntegratedAssessmentSalaryOfferSend',
		path: (salaryOfferPlanId: string) =>
			`/recipients/recipient-ia/salary-offer-plans/${salaryOfferPlanId}/send`,
	},
	getRecipientIntegratedAssessmentSalaryOfferSendPreview: {
		key: 'getRecipientIntegratedAssessmentSalaryOfferSendPreview',
		path: (salaryOfferPlanId: string) =>
			`/recipients/recipient-ia/salary-offer-plans/${salaryOfferPlanId}/send-preview`,
	},
	deleteRecipientIntegratedAssessmentSalaryOffer: {
		key: 'deleteRecipientIntegratedAssessmentSalaryOffer',
		path: (salaryOfferPlanId: string) =>
			`/recipients/recipient-ia/salary-offer-plans/${salaryOfferPlanId}`,
	},
	uploadRecipientIntegratedAssessmentSalaryOffer: {
		key: 'uploadRecipientIntegratedAssessmentSalaryOffer',
		path: () => `/recipients/recipient-ia/salary-offer-plans/upload`,
	},
	getRecipientIntegratedAssessmentSalaryOfferChangePlans: {
		key: 'getRecipientIntegratedAssessmentSalaryOfferChangePlans',
		path: (salaryOfferPlanId: string) =>
			`/recipients/recipient-ia/salary-offer-plans/${salaryOfferPlanId}/change-plans`,
	},
	getRecipientIntegratedAssessmentSalaryOfferChangePlan: {
		key: 'getRecipientIntegratedAssessmentSalaryOfferChangePlan',
		path: (salaryOfferPlanId: string, salaryOfferChangePlanId: string) =>
			`/recipients/recipient-ia/salary-offer-plans/${salaryOfferPlanId}/change-plans/${salaryOfferChangePlanId}`,
	},
	deleteRecipientIntegratedAssessmentSalaryOfferChangePlan: {
		key: 'deleteRecipientIntegratedAssessmentSalaryOfferChangePlan',
		path: (salaryOfferPlanId: string, salaryOfferChangePlanId: string) =>
			`/recipients/recipient-ia/salary-offer-plans/${salaryOfferPlanId}/change-plans/${salaryOfferChangePlanId}`,
	},
	createRecipientIntegratedAssessmentSalaryOfferChangePlan: {
		key: 'updateRecipientIntegratedAssessmentSalaryOfferChangePlan',
		path: (salaryOfferPlanId: string) =>
			`/recipients/recipient-ia/salary-offer-plans/${salaryOfferPlanId}/change-plans`,
	},
	updateRecipientIntegratedAssessmentSalaryOfferChangePlan: {
		key: 'updateRecipientIntegratedAssessmentSalaryOfferChangePlan',
		path: (salaryOfferPlanId: string, salaryOfferChangePlanId: string) =>
			`/recipients/recipient-ia/salary-offer-plans/${salaryOfferPlanId}/change-plans/${salaryOfferChangePlanId}`,
	},
	resendEDoc: {
		key: 'resendEDoc',
		path: () => `/edoc/re-send`,
	},
	sendSlack: {
		key: 'sendSlack',
		path: () => `/common/slack/send-alert`,
	},
	getInsurance: {
		key: 'getInsurance',
		path: (centerId?: number) => `/insurances/centers/${centerId}`,
	},
	updateInsuranceRequestState: {
		key: 'updateInsuranceRequestState',
		path: () => '/insurances/process',
	},
	getCenterAccounts: {
		key: 'getSenderAccount',
		path: () => '/common/search/select-box/centerAccounts',
	},
	getCenterAccountRecords: {
		key: 'getCenterAccountRecords',
		path: () => `/center-accounts/records`,
	},
	updateCenterAccountRecords: {
		key: 'updateCenterAccountRecords',
		path: (queryString = '') => `/center-accounts/records${queryString}`,
	},
	updateCenterAccountRecordDetail: {
		key: 'updateCenterAccountRecords',
		path: () => `/center-accounts/records/details`,
	},
	updateCenterAccountChildRecordDetail: {
		key: 'updateCenterAccountRecords',
		path: (centerAccountRecordDetailId: number) =>
			`/center-accounts/records/details/${centerAccountRecordDetailId}`,
	},
	getCenterAccountRecordTargets: {
		key: 'getCenterAccountRecordTargets',
		path: () => '/center-accounts/records/targets',
	},
	getCenterAccountRecordSummarys: {
		key: 'getCenterAccountRecordSummarys',
		path: () => '/center-accounts/records/summarys',
	},
	getInsuranceEtcExcelDownload: {
		key: 'getInsuranceEtcExcelDownload',
		path: ({ centerId, stateCode }: { centerId?: number; stateCode: string }) =>
			`/insurances/excelData/${centerId}/${stateCode}`,
	},
	sendRecipientContract: {
		key: 'sendRecipientContract',
		path: () => '/contracts/send/contract-recipient/retrieve',
	},
	sendRecipientRenewalContract: {
		key: 'sendRecipientRenewalContract',
		path: () => '/contracts/send/contract-recipient/renewal/retrieve',
	},
	sendEmployeeContract: {
		key: 'sendEmployeeContract',
		path: () => '/contracts/send/contract-employee/retrieve',
	},
	getContractSendStatus: {
		key: 'getContractSendStatus',
		path: () => '/contracts/send/states',
	},
	getEmployeeInsurance: {
		key: 'getEmployeeInsurance',
		path: (employeeId?: number) => `/employees/${employeeId}/insurances`,
	},
	getServiceEmployees: {
		key: 'getServiceEmployees',
		path: (serviceContractDetailId: number) =>
			`/contracts/change/employee/service/${serviceContractDetailId}`,
	},
	changeContractStatus: {
		key: 'changeContractStatus',
		path: (serviceContractId: number) => `/contracts/state/${serviceContractId}`,
	},
	createInsurance: {
		key: 'createInsurance',
		path: (employeeId: number) => `/employees/${employeeId}/insurances`,
	},
	getWageDeductionEmployees: {
		key: 'getWageDeductionEmployees',
		path: () => `/contracts/recipient/offer-employees`,
	},
	getInsuranceSalaryFeeInfo: {
		key: 'getInsuranceSalaryFeeInfo',
		path: (targetDt: string, employeeId?: number) =>
			`/employees/${employeeId}/insurances/salary/${targetDt}`,
	},
	getInsurancePriorInfo: {
		key: 'getInsurancePriorInfo',
		path: (employeeId?: number, insuranceTypeCd?: string) =>
			`/employees/${employeeId}/insurances/type/${insuranceTypeCd}/check`,
	},
	getInsuranceDetailInfo: {
		key: 'getInsurancePriorInfo',
		path: (employeeId?: string, insuranceRequestId?: string) =>
			`/employees/${employeeId}/insurances/${insuranceRequestId}`,
	},
	updateInsuranceDetail: {
		key: 'updateInsuranceDetail',
		path: (employeeId?: string, insuranceRequestId?: string) =>
			`/employees/${employeeId}/insurances/${insuranceRequestId}`,
	},
	uploadVisitNursing: {
		key: 'uploadVisitNursing',
		path: (serviceContractId?: number) => `/contracts/upload/${serviceContractId}/visit-nursing`,
	},
	checkLongTermDuplication: {
		key: 'checkLongTermDuplication',
		path: () => `/recipients/long-term-no/check-duplicate`,
	},
	updateEmployeeTagCall: {
		key: 'updateEmployeeTagCall',
		path: () => `/employees/save/tagCall-info`,
	},
	getServiceTerminationInfo: {
		key: 'getServiceTerminationInfo',
		path: (serviceContractDetailId: number) =>
			`/contracts/service-termination/${serviceContractDetailId}`,
	},
	serviceTermination: {
		key: 'serviceTermination',
		path: () => `/contracts/service-termination`,
	},
	getInsuranceAcqExcelDownload: {
		key: 'getInsuranceAcqExcelDownload',
		path: ({ centerId, stateCode }: { centerId?: number; stateCode: string }) =>
			`/insurances/excelData/${centerId}/${stateCode}/acq`,
	},
	getInsuranceLossExcelDownload: {
		key: 'getInsuranceLossExcelDownload',
		path: ({ centerId, stateCode }: { centerId?: number; stateCode: string }) =>
			`/insurances/excelData/${centerId}/${stateCode}/loss`,
	},
	createRecipientDocument: {
		key: 'createRecipientDocument',
		path: () => `/contracts/document/recipient`,
	},
	createRecipientRenewalDocument: {
		key: 'createRecipientRenewalDocument',
		path: () => `/contracts/document/recipient/renewal`,
	},
	getEmployeeWage: {
		key: 'getEmployeeWage',
		path: () => `/contracts/document/employee/wage`,
	},
	createEmployeeDocument: {
		key: 'createEmployeeDocument',
		path: () => `/contracts/document/employee`,
	},
	uploadRecipientDocument: {
		key: 'uploadRecipientDocument',
		path: () => `/contracts/document/recipient`,
	},
	uploadRecipientRenewalDocument: {
		key: 'uploadRecipientRenewalDocument',
		path: () => `/contracts/document/recipient/renewal`,
	},
	uploadEmployeeDocument: {
		key: 'uploadEmployeeDocument',
		path: () => `/contracts/document/employee`,
	},
	getDocument: {
		key: 'getDocument',
		path: () => `/contracts/document`,
	},
	getRecipientDocument: {
		key: 'getRecipientDocument',
		path: (recipientContractId: string | number) =>
			`/contracts/document/recipient/edoc-param/${recipientContractId}`,
	},
	getRecipientRenewalDocument: {
		key: 'getRecipientRenewalDocument',
		path: () => `/contracts/document/recipient/edoc-param/renewal`,
	},
	getEmployeeDocument: {
		key: 'getEmployeeDocument',
		path: (employeeContractId: string | number) =>
			`/contracts/document/employee/edoc-param/${employeeContractId}`,
	},
	getDocumentTemplate: {
		key: 'getDocumentTemplate',
		path: () => `/sends/templates/contract`,
	},
	deletePaper: {
		key: 'deletePaper',
		path: () => `/edoc/paper`,
	},
	checkRealName: {
		key: 'checkRealName',
		path: () => `/common/isRealName`,
	},
	recipientUpdateScheduleAutomation: {
		key: 'updateScheduleAutomation',
		path: (centerId: number, recipientId: number) =>
			`/recipients/${centerId}/${recipientId}/schedule-automation`,
	},
	getCenterAccountExcelDownload: {
		key: 'getCenterAccountExcelDownload',
		path: () => `/center-accounts/records/download`,
	},
	// 수급자 삭제
	updateRecipientAffiliation: {
		key: 'updateRecipientAffiliation',
		path: () => `/my-center/data-edit/recipient-affiliation`,
	},
	// 직원 삭제
	updateEmployeeAffiliation: {
		key: 'updateEmployeeAffiliation',
		path: () => `/my-center/data-edit/employee-affiliation`,
	},
	// 갱신 계약 삭제
	deleteRenewalContract: {
		key: 'deleteRenewalContract',
		path: (serviceContractGroupUuid: string) =>
			`/my-center/data-edit/service-contract/renewal/${serviceContractGroupUuid}`,
	},
	// 일반 계약 삭제
	deleteServiceContract: {
		key: 'deleteServiceContract',
		path: (serviceContractId: number) =>
			`/my-center/data-edit/service-contract/${serviceContractId}`,
	},
	// 업무수행일지 이력 조회
	getWorkExecuteLog: {
		key: 'getWorkExecuteLog',
		path: () => `/recipients/workExecuteLog`,
	},
	// 수급자 발송 이력 - 메시지 상세
	getRecipientSendHistoryDetail: {
		key: 'getRecipientSendHistoryDetail',
		path: (recipientId: number, sendDetailId: number) =>
			`/sends/hists/recipients/${recipientId}/details/${sendDetailId}`,
	},
	// 직원 발송 이력 - 메시지 상세
	getEmployeeSendHistoryDetail: {
		key: 'getEmployeeSendHistoryDetail',
		path: (employeeId: number, sendDetailId: number) =>
			`/sends/hists/employees/${employeeId}/details/${sendDetailId}`,
	},
	// 업무수행일지 신규 조회
	getRecipientWorkExecuteLogNew: {
		key: 'getRecipientWorkExecuteLogNew',
		path: () => `/recipients/workExecuteLogNew`,
	},
	// 업무수행일지 일자별 일정조회
	getWorkExecuteLogServiceSchedulePlan: {
		key: 'getWorkExecuteLogServiceSchedulePlan',
		path: () => `/recipients/workExecuteLog/serviceSchedulePlan`,
	},
	// 업무수행일지 이력 조회
	updateWorkExecuteLog: {
		key: 'updateWorkExecuteLog',
		path: () => `/recipients/workExecuteLog`,
	},
	// 업무수행일지 검수용 조회
	getWorkExecuteInspectionLog: {
		key: 'getWorkExecuteInspectionLog',
		path: (edocUuid?: string) => `/customers/workExecuteLog/${edocUuid}`,
	},
	// 업무수행일지 검수용 수정
	updateWorkExecuteInspectionLog: {
		key: 'updateWorkExecuteInspectionLog',
		path: () => `/customers/workExecuteLog`,
	},
	// 업무수행일지 수정 상세 조회
	getRecipientWorkExecuteLogDetail: {
		key: 'getRecipientWorkExecuteLogDetail',
		path: (workExecuteLogId?: string) => `/recipients/workExecuteLog/${workExecuteLogId}`,
	},
	// 수급자 엑셀 다운로드
	getRecipientsExcelInfo: {
		key: 'getRecipientsExcelInfo',
		path: () => '/external/welfare/recipients',
	},
	// 직원 엑셀 다운로드
	getEmployeesExcelInfo: {
		key: 'getEmployeesExcelInfo',
		path: () => '/external/welfare/employees',
	},
	// 업무수행일지 상담자목록
	getRecipientConsultClient: {
		key: 'getRecipientConsultClient',
		path: (recipientId?: string) => `/recipients/${recipientId}/advices/clients`,
	},
	// 내센터 계정 권한 목록 조회
	getMyCenterAccountRoles: {
		key: 'getMyCenterAccountRoles',
		path: () => `/my-center/accounts/roles`,
	},
	// 내센터 계정 목록 조회
	getMyCenterAccounts: {
		key: 'getMyCenterAccounts',
		path: () => `/my-center/accounts`,
	},
	// 내센터 로그인 허용
	activateAccount: {
		key: 'activateAccount',
		path: (userId?: string) => `/my-center/accounts/${userId}/activate`,
	},
	// 내센터 로그인 허용 해제
	deactivateAccount: {
		key: 'deactivateAccount',
		path: (userId?: string) => `/my-center/accounts/${userId}/deactivate`,
	},
	getRecipientHoldingHistories: {
		key: 'getRecipientHoldingHistories',
		path: (recipientId: number) => `/recipients/${recipientId}/history/pending`,
	},
	createRecipientHoldingHistory: {
		key: 'createRecipientHoldingHistory',
		path: (recipientId: number) => `/recipients/${recipientId}/history/pending`,
	},
	updateRecipientHoldingHistory: {
		key: 'updateRecipientHoldingHistory',
		path: (recipientId: number) => `/recipients/${recipientId}/history/pending`,
	},
	deleteRecipientHoldingHistory: {
		key: 'deleteRecipientHoldingHistory',
		path: (recipientId: number, recipientPendingHistId: number) =>
			`/recipients/${recipientId}/history/pending/${recipientPendingHistId}`,
	},
	// 내센터 문서 파일 조회
	getMyCenterDocs: {
		key: 'getMyCenterDocs',
		path: () => `/my-center/docs`,
	},
	// 내센터 문서 등록/수정
	updateMyCenterDocs: {
		key: 'updateMyCenterDocs',
		path: () => `/my-center/docs`,
	},
	// 내센터 문서 삭제
	deleteMyCenterDocs: {
		key: 'deleteMyCenterDocs',
		path: () => `/my-center/docs`,
	},
	// 센터 정보 조회
	getMyCenterInfo: {
		key: 'getMyCenterInfo',
		path: (centerId?: number) => `/my-center/${centerId}`,
	},
	// 센터 정보 저장
	saveMyCenterInfo: {
		key: 'saveMyCenterInfo',
		path: () => `/my-center`,
	},
	// 센터장 목록 조회
	getCenterChiefs: {
		key: 'getCenterChiefs',
		path: () => `/my-center/common/chiefs`,
	},
	// 센터제공 서비스 목록 조회
	getCenterOfferServices: {
		key: 'getCenterOfferServices',
		path: (centerId?: number) => `/my-center/offer-services/${centerId}`,
	},
	getEmployeeWorkInfoList: {
		key: 'getEmployeeWorkInfoList',
		path: () => `/work-schedule/hr/employees`,
	},
	getEmployeeWorkSchedule: {
		key: 'getEmployeeWorkSchedule',
		path: () => `/work-schedule`,
	},
	// 센터 계좌 관리 목록 조회
	getCenterAccountManagement: {
		key: 'getCenterAccountManagements',
		path: () => `/my-center/bank-accounts`,
	},
	saveCenterAccount: {
		key: 'createCenterAccount',
		path: () => `/my-center/bank-accounts`,
	},
	updateCenterAccount: {
		key: 'updateCenterAccount',
		path: () => `/my-center/bank-accounts`,
	},
	updateCenterAccountInfo: {
		key: 'updateCenterAccountInfo',
		path: () => `/my-center/accounts/modAccount`,
	},
	deleteCenterAccount: {
		key: 'deleteCenterAccount',
		path: (centerAccountId: number) => `/my-center/bank-accounts/${centerAccountId}`,
	},
	getCenterSalaryFeeInfo: {
		key: 'getCenterSalaryFeeInfo',
		path: () => `/my-center/salaries`,
	},
	getCurrentApplySalaryFeeInfo: {
		key: 'getCurrentApplySalaryFeeInfo',
		path: (centerId?: number) => `/my-center/salaries/currently/${centerId}`,
	},
	checkSalaryDuplicatedPeriod: {
		key: 'checkSalaryDuplicatedPeriod',
		path: () => `/my-center/salaries/duplicated-period`,
	},
	createCenterSalaryFeeInfo: {
		key: 'createCenterSalaryFeeInfo',
		path: (centerId: number) => `/my-center/salaries/${centerId}`,
	},
	getCenterSalaryFeeDetail: {
		key: 'getCenterSalaryFeeDetail',
		path: (centerId?: number) => `/my-center/salaries/${centerId}`,
	},
	updateCenterSalaryFeeInfo: {
		key: 'updateCenterSalaryFeeInfo',
		path: (centerId: number) => `/my-center/salaries/${centerId}`,
	},
	checkSalaryDuplicatedRange: {
		key: 'checkSalaryDuplicatedPeriod',
		path: () => `/my-center/salaries/duplicated-range`,
	},
	// 상근직_직원
	getFullTimeEmployees: {
		key: 'getFullTimeEmployees',
		path: () => `/ftime-employees`,
	},
	checkFullTimeEmployeeDuplication: {
		key: 'checkFullTimeEmployeeDuplication',
		path: () => `/ftime-employees/check-duplicate`,
	},
	checkFullTimeEmployeeDeletable: {
		key: 'checkFullTimeEmployeeDeletable',
		path: () => `/ftime-employees/check-delete`,
	},
	createFullTimeEmployee: {
		key: 'createFullTimeEmployee',
		path: () => `/ftime-employees`,
	},
	deleteFullTimeEmployee: {
		key: 'deleteFullTimeEmployee',
		path: () => `/ftime-employees`,
	},
	getFullTimeEmployeeBaseInfo: {
		key: 'getFullTimeEmployeeBaseInfo',
		path: () => `/ftime-employees/base-info`,
	},
	getFullTimeEmployeeDetailInfo: {
		key: 'getFullTimeEmployeeDetailInfo',
		path: () => `/ftime-employees/detail-info`,
	},
	updateFullTimeEmployeeDetailInfo: {
		key: 'updateFullTimeEmployeeDetailInfo',
		path: () => `/ftime-employees/save/base-info`,
	},
	getFullTimeEmployeeAnnualTOHistories: {
		key: 'getFullTimeEmployeeAnnualTOHistories',
		path: () => `/ftime-employees/annualHist`,
	},
	updateFullTimeEmployeeAnnualTOHistories: {
		key: 'updateFullTimeEmployeeAnnualTOHistories',
		path: () => `/ftime-employees/annualHist`,
	},
	getFullTimeEmployeeWorkSchedule: {
		key: 'getFullTimeEmployeeWorkSchedule',
		path: () => `/ftime-employees/work-schedule`,
	},
	getFullTimeEmployeeLicenses: {
		key: 'getFullTimeEmployeeLicenses',
		path: () => `/ftime-employees/employeeLicense-info`,
	},
	deleteFullTimeEmployeeLicense: {
		key: 'deleteFullTimeEmployeeLicense',
		path: () => `/ftime-employees/employeeLicense/delete`,
	},
	saveFullTimeEmployeeLicense: {
		key: 'saveFullTimeEmployeeLicense',
		path: () => `/ftime-employees/employeeLicense/save`,
	},
	getFullTimeEmployeeEducations: {
		key: 'getFullTimeEmployeeEducations',
		path: () => `/ftime-employees/employeeEdu-info`,
	},
	deleteFullTimeEmployeeEducation: {
		key: 'deleteFullTimeEmployeeEducation',
		path: () => `/ftime-employees/employeeEdu/delete`,
	},
	saveFullTimeEmployeeEducation: {
		key: 'saveFullTimeEmployeeEducation',
		path: () => `/ftime-employees/employeeEdu/save`,
	},
	getFullTimeEmployeeWorkDetails: {
		key: 'getFullTimeEmployeeWorkDetails',
		path: () => `/ftime-employees/workDetailRequests`,
	},
	getFullTimeEmployeeCrimeHistories: {
		key: 'getFullTimeEmployeeCrimeHistories',
		path: () => `/ftime-employees/crimeHist`,
	},
	saveFullTimeEmployeeCrimeHistory: {
		key: 'saveFullTimeEmployeeCrimeHistory',
		path: () => `/ftime-employees/crimeHist`,
	},
	getFullTimeEmployeeDementiaEducations: {
		key: 'getFullTimeEmployeeDementiaEducations',
		path: () => `/ftime-employees/dementiaEdus`,
	},
	saveFullTimeEmployeeDementiaEducation: {
		key: 'saveFullTimeEmployeeDementiaEducation',
		path: () => `/ftime-employees/dementiaEdus`,
	},
	getFullTimeEmployeeLegalEducations: {
		key: 'getFullTimeEmployeeLegalEducations',
		path: () => `/ftime-employees/legalEdus`,
	},
	getFullTimeEmployeeDuties: {
		key: 'getFullTimeEmployeeDuties',
		path: () => `/ftime-employees/dutys`,
	},
	saveFullTimeEmployeeDuty: {
		key: 'saveFullTimeEmployeeDuty',
		path: () => `/ftime-employees/dutys`,
	},
	deleteFullTimeEmployeeDuty: {
		key: 'deleteFullTimeEmployeeDuty',
		path: (ftimeEmployeeDutyHistId: number) => `/ftime-employees/dutys/${ftimeEmployeeDutyHistId}`,
	},
	getFullTimeEmployeeWorkStates: {
		key: 'getFullTimeEmployeeWorkStates',
		path: () => `/ftime-employees/workStates`,
	},
	saveFullTimeEmployeeWorkState: {
		key: 'saveFullTimeEmployeeWorkState',
		path: () => `/ftime-employees/workStates`,
	},
	deleteFullTimeEmployeeWorkState: {
		key: 'deleteFullTimeEmployeeWorkState',
		path: (ftimeEmployeeWorkStateHistId: number) =>
			`/ftime-employees/workStates/${ftimeEmployeeWorkStateHistId}`,
	},
	getFullTimeEmployeeFinalWorkLeave: {
		key: 'getFullTimeEmployeeFinalWorkLeave',
		path: () => `/ftime-employees/finalWorkLeave`,
	},
	getFullTimeEmployeeResign: {
		key: 'getFullTimeEmployeeResign',
		path: () => `/ftime-employees/resign`,
	},
	resignFullTimeEmployee: {
		key: 'resignFullTimeEmployee',
		path: () => `/ftime-employees/resign`,
	},
	moveFullTimeEmployeeCenter: {
		key: 'moveFullTimeEmployeeCenter',
		path: () => `/ftime-employees/center/move`,
	},
	cancelMovingFullTimeEmployeeCenter: {
		key: 'cancelMovingFullTimeEmployeeCenter',
		path: (ftimeEmployeeCenterMoveHistId: number) =>
			`/ftime-employees/center/move/${ftimeEmployeeCenterMoveHistId}`,
	},
	getFullTimeEmployeeAnnualTOGenerateAdjust: {
		key: 'getFullTimeEmployeeAnnualTOGenerateAdjust',
		path: () => `/ftime-employees/annualGenerateAdjust`,
	},
	saveFullTimeEmployeeAnnualTOGenerateAdjust: {
		key: 'saveFullTimeEmployeeAnnualTOGenerateAdjust',
		path: () => `/ftime-employees/annualGenerateAdjust`,
	},
	getFullTimeEmployeeAnnualTORemainAdjust: {
		key: 'getFullTimeEmployeeAnnualTORemainAdjust',
		path: () => `/ftime-employees/remainAnnualAdjust`,
	},
	saveFullTimeEmployeeAnnualTORemainAdjust: {
		key: 'saveFullTimeEmployeeAnnualTORemainAdjust',
		path: () => `/ftime-employees/remainAnnualAdjust`,
	},
	getFullTimeEmployeeAnnualTOWorkYearCntAdjust: {
		key: 'getFullTimeEmployeeAnnualTOWorkYearCntAdjust',
		path: () => `/ftime-employees/workYearCntAdjust`,
	},
	saveFullTimeEmployeeAnnualTOWorkYearCntAdjust: {
		key: 'saveFullTimeEmployeeAnnualTOWorkYearCntAdjust',
		path: () => `/ftime-employees/workYearCntAdjust`,
	},
	getFullTimeEmployeeHealthCheck: {
		key: 'getFullTimeEmployeeHealthCheck',
		path: () => `/ftime-employees/employeeHealthCheck`,
	},
	saveFullTimeEmployeeHealthCheck: {
		key: 'saveFullTimeEmployeeHealthCheck',
		path: () => `/ftime-employees/employeeHealthCheck/save`,
	},
	deleteFullTimeEmployeeHealthCheck: {
		key: 'deleteFullTimeEmployeeHealthCheck',
		path: () => `/ftime-employees/employeeHealthCheck/delete`,
	},
	getAnnualTOHistories: {
		key: 'getAnnualTOHistories',
		path: () => `/work-annual/annualMngtHist`,
	},
	getAnnualTOHistoriesDownload: {
		key: 'getAnnualTOHistoriesDownload',
		path: () => `/work-annual/annualMngtHist/download`,
	},
	getAnnualTOUsageHistories: {
		key: 'getAnnualTOUsageHistories',
		path: () => `/work-annual/annualUseHist`,
	},
	getAnnualTOUsageHistoriesDownload: {
		key: 'getAnnualTOUsageHistoriesDownload',
		path: () => `/work-annual/annualUseHist/download`,
	},
	getEmployeeDefaultWorkTimeList: {
		key: 'getEmployeeDefaultWorkTimeList',
		path: (employeeId: number) => `/work-schedule/basWorkTime/${employeeId}`,
	},
	saveEmployeeDefaultWorkTime: {
		key: 'saveEmployeeDefaultWorkTime',
		path: (employeeId: number) => `/work-schedule/basWorkTime/${employeeId}`,
	},
	deleteEmployeeDefaultWorkTime: {
		key: 'deleteEmployeeDefaultWorkTime',
		path: (employeeId: number, basWorkTimeId: number) =>
			`/work-schedule/basWorkTime/${employeeId}/${basWorkTimeId}`,
	},
	saveEmployeeMaxOverWorkTime: {
		key: 'saveEmployeeMaxOverWorkTime',
		path: () => `/work-schedule/hr/saveOverWorkMaxTimeCnt`,
	},
	saveEmployeeVacation: {
		key: 'saveEmployeeVacation',
		path: () => `/work-schedule/hr/saveVaca`,
	},
	deleteEmployeeVacation: {
		key: 'deleteEmployeeVacation',
		path: () => `/work-schedule/hr/deleteVaca`,
	},
	saveEmployeeWorkSchedule: {
		key: 'saveEmployeeWorkSchedule',
		path: () => `/work-schedule`,
	},
	getEmployeeWorkHistory: {
		key: 'getEmployeeWorkHistory',
		path: () => `/work-schedule/hr/workHist`,
	},
	getFullTimeEmployeeWorkHistory: {
		key: 'getEmployeeWorkHistory',
		path: () => `ftime-employees/hr/workHist`,
	},
	saveEmployeeWorkHistory: {
		key: 'saveEmployeeWorkHistory',
		path: () => `/work-schedule/hr/saveWorkHist`,
	},
	deleteEmployeeWorkHistory: {
		key: 'deleteEmployeeWorkHistory',
		path: () => `/work-schedule/hr/deleteWorkHist`,
	},
	updateWorkHistoryConfirm: {
		key: 'updateWorkHistoryConfirm',
		path: () => `/work-schedule/hr/workHistComplete`,
	},
	getEmployeeActualWorkLogDownloadList: {
		key: 'getEmployeeActualWorkLogDownloadList',
		path: () => `/work-schedule/workLeaveDownload`,
	},
	saveEmployeeActualWorkLog: {
		key: 'saveEmployeeActualWorkLog',
		path: (date: string) => `/work-schedule/workLeave/save/${date}`,
	},
	deleteEmployeeActualWorkLog: {
		key: 'deleteEmployeeActualWorkLog',
		path: (workLeaveId: number) => `/work-schedule/workLeave/delete/${workLeaveId}`,
	},
	getPcorpWorkSchedule: {
		key: 'getPcorpWorkSchedule',
		path: () => `/work-schedule/pcorpWorkSchedule`,
	},
	getCenterWorkSchedule: {
		key: 'getWorkScheduleStatus',
		path: () => `/work-schedule/centerWorkSchedule`,
	},

	getManagerTagHistory: {
		key: 'getManagerTagHistory',
		path: () => `/schedules-tag-error/tagHist/manager`,
	},

	updateManagerTagHistory: {
		key: 'updateManagerTagHistory',
		path: () => `/schedules-tag-error/tagHist/manager`,
	},

	downloadExcelManagerTagHistory: {
		key: 'getManagerTagHistory',
		path: () => `/schedules-tag-error/tagHist/manager/excel`,
	},

	getFtimeEmployeeWorkSchedule: {
		key: 'getFtimeEmployeeWorkSchedule',
		path: () => `/schedules-schedule/ftimeEmployee`,
	},
	getFtimeEmployeeMonthSchedule: {
		key: 'getFtimeEmployeeMonthSchedule',
		path: () => `/schedules-schedule/ftimeEmployee/planDownload`,
	},
	checkInsuranceRequiredEDoc: {
		key: 'checkInsuranceRequiredEDoc',
		path: (employeeId: number) => `/employees/${employeeId}/insurances/edoc`,
	},
	getLinkAccounts: {
		key: 'getLinkAccounts',
		path: () => `/my-center/accounts/links`,
	},
	linkAccounts: {
		key: 'linkAccounts',
		path: (memberAccountId: number, memberId: number) =>
			`/my-center/accounts/link/${memberAccountId}/${memberId}`,
	},
	getCenterDefaultWorkTime: {
		key: 'getCenterDefaultWorkTime',
		path: (centerId: number) => `/work-schedule/centerBasWorkTime/${centerId}`,
	},
	saveCenterDefualtWorkTime: {
		key: 'saveCenterDefualtWorkTime',
		path: (centerId: number) => `/work-schedule/centerBasWorkTime/${centerId}`,
	},
	registryDefaultWorkTime: {
		key: 'registryDefaultWorkTime',
		path: (centerId: number, yearMonth: string) =>
			`/work-schedule/saveBatchWorkScheduleByCenter/${centerId}/${yearMonth}`,
	},
	getMonitoringSchedules: {
		key: 'getMonitoringSchedules',
		path: () => `/schedules-monitoring`,
	},
	syncPCorp: {
		key: 'syncPCorp',
		path: () => `/schedules-monitoring/sync-pcorp`,
	},
	getMonitoringCompleteSynyDate: {
		key: 'getlastMonitoringSynyDate',
		path: () => `/schedules-monitoring/sync-date`,
	},
	syncMonitoringData: {
		key: 'syncMonitoringData',
		path: () => `/schedules-monitoring/sync-data`,
	},
	getRecentMonitoringSynyDate: {
		key: 'getRecentMonitoringSynyDate',
		path: () => `/schedules-monitoring/sync-date/recent`,
	},
	syncMonitoringIndividual: {
		key: 'syncMonitoringIndividual',
		path: () => `/schedules-monitoring/sync-data/individual`,
	},
	getMonitoringSyncHistory: {
		key: 'getMonitoringSyncHistory',
		path: () => `/schedules-monitoring/sync-history`,
	},
	getMonitoringDetails: {
		key: 'getMonitoringSyncHistory',
		path: () => `/schedules-monitoring/details`,
	},
	getMonitoringDetailItem: {
		key: 'getMonitoringDetailItem',
		path: () => `/schedules-monitoring/detail`,
	},
	saveScheduleMonitoringDetail: {
		key: 'saveScheduleMonitoringDetail',
		path: () => `/schedules-monitoring/detail`,
	},
	deleteEmployeeSalary: {
		key: 'deleteEmployeeSalary',
		path: (employeeSalaryId: number) => `/salarys/employee-salarys/${employeeSalaryId}`,
	},
	deleteEmployeeSalarySchedule: {
		key: 'deleteEmployeeSalarySchedule',
		path: (employeeSalaryId: number, scheduleId: number) =>
			`/salarys/employee-salarys/${employeeSalaryId}/schedules/${scheduleId}`,
	},
	updateWorkConfirmStatus: {
		key: 'updateWorkConfirmStatus',
		path: () => `/schedules-monitoring/details/work-confirm`,
	},
	updateWorkDuplicationStatus: {
		key: 'updateWorkDuplicationStatus',
		path: () => `/schedules-monitoring/details/work-duplication`,
	},
	getGenerateIntegratedAssessmentOpinion: {
		key: 'getGenerateIntegratedAssessmentOpinion',
		path: () => `/recipients/recipient-ias/generate-opinion`,
	},
	updateMonitoringDetailItemCompleteStatus: {
		key: 'updateMonitoringDetailItemCompleteStatus',
		path: () => `/schedules-monitoring/detail/complete`,
	},
	updateMonitoringDetailsCompleteStatus: {
		key: 'updateMonitoringDetailsCompleteStatus',
		path: () => `/schedules-monitoring/details/complete`,
	},
	updateMonitoringCompleteStatus: {
		key: 'updateMonitoringCompleteStatus',
		path: () => `/schedules-monitoring/complete`,
	},
	getEFormFileName: {
		key: 'getEFormFileName',
		path: () => `/edoc/edoc-template`,
	},
	exportEformToPdf: {
		key: 'exportEformToPdf',
		path: () => `/eform/export/pdf`,
	},
	issueWorkExecuteLog: {
		key: 'issueWorkExecuteLog',
		path: () => '/recipients/workExecuteLog/issueEdoc',
	},
	getRecipientEdocAdmissionUse: {
		key: 'getRecipientEdocAdmissionUse',
		path: (centerId?: number, edocNo?: string) =>
			`/recipients/doc/admission-use/${centerId}/${edocNo}`,
	},
	confirmRecipientEdocAdmissionUse: {
		key: 'confirmRecipientEdocAdmissionUse',
		path: () => `/recipients/doc/admission-use`,
	},
	getCustomerCenterManagers: {
		key: 'getCustomerCenterManagers',
		path: (centerId?: number) => `/customers/centers/${centerId}/managers`,
	},
	getCustomerChiefs: {
		key: 'getCustomerChiefs',
		path: () => `/customers/chiefs`,
	},
	getCustomerClients: {
		key: 'getCustomerClients',
		path: (recipientId?: number) => `/customers/${recipientId}/clients`,
	},
	getCustomerEmployees: {
		key: 'getCustomerEmployees',
		path: () => `/customers/employees`,
	},
	getCustomerSelectBoxCommonCodes: {
		key: 'getCustomerSelectBoxCommonCodes',
		path: () => `/customers/select-box/comCodes`,
	},
	getVisitBathRoundingHistory: {
		key: 'getVisitBathRoundingHistory',
		path: () => `/visit/hist/bath-care-result`,
	},
	getVisitCareRoundingHistory: {
		key: 'getVisitCareRoundingHistory',
		path: () => `/visit/hist/visit-care-result`,
	},
	getFamilyCareRoundingHistory: {
		key: 'getFamilyCareRoundingHistory',
		path: () => `/visit/hist/family-care-result`,
	},
	syncRoundingManagerRFIDTag: {
		key: 'syncRoundingManagerRFIDTag',
		path: () => `/visit/hist/manager-rfid`,
	},
	getCenterEmployees: {
		key: 'getCenterEmployees',
		path: () => `/recipients/member/employee`,
	},
	getCenterRecipients: {
		key: 'getCenterRecipients',
		path: () => `/recipients/member/recipient`,
	},
	getRoundingCompletionStatus: {
		key: 'getRoundingCompletionStatus',
		path: (centerId: number) => `/visit/hist/visit-rate-result/${centerId}`,
	},
	updateContractState: {
		key: 'updateContractState',
		path: (serviceContractId: number) => `/contracts/state-change/${serviceContractId}`,
	},
	updateRenewalContractState: {
		key: 'updateRenewalContractState',
		path: (serviceContractId: number) => `/contracts/renewal/state-change/${serviceContractId}`,
	},
};
