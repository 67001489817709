import React, { useMemo } from 'react';

import Colors from 'common/colors';
import { CRStatusConfig } from 'types/view/base';

import * as S from './styles';

interface Props {
	type?: 'pill' | 'badge';
	options?: CRStatusConfig[];
}

function CRStatus({
	type = 'badge',
	options = [],
	children,
}: React.PropsWithChildren<Props>): React.ReactElement {
	if (children && typeof children !== 'string') throw new Error('children should be string');

	const badgeStyle = useMemo((): React.CSSProperties => {
		if (!children) return {};

		const targetOption = options.find((option) => option.key === children);
		if (!targetOption) {
			// throw new Error('CRStatus has appropriate option');
			return {};
		}

		if (targetOption.custom) {
			return {
				background: targetOption.custom.background,
			};
		}

		switch (targetOption.color) {
			case 'gray60':
				return {
					background: Colors.gray60,
				};
			case 'gray':
				return {
					background: Colors.gray60,
				};
			case 'green':
				return {
					background: Colors.green,
				};
			case 'red':
				return {
					background: Colors.red,
				};
			case 'blue':
				return {
					background: Colors.blue,
				};
			case 'yellow':
				return {
					background: Colors.yellow,
				};
			default:
				return {};
		}
	}, [children]);

	const pillStyle = useMemo((): React.CSSProperties => {
		if (!children) return {};

		const targetOption = options.find((option) => option.key === children);
		if (!targetOption) {
			// throw new Error('CRStatus has appropriate option');
			return {};
		}

		if (targetOption.custom) {
			return {
				background: targetOption.custom.background,
			};
		}

		switch (targetOption.color) {
			case 'gray':
				return {
					background: Colors.grayLighten,
				};
			case 'green':
				return {
					background: Colors.green,
					color: Colors.greenDarken,
				};
			case 'red':
				return {
					background: Colors.red,
				};
			case 'blue':
				return {
					background: Colors.blue,
				};
			case 'yellow':
				return {
					background: Colors.yellow,
				};
			default:
				return {};
		}
	}, [children]);

	const style = useMemo((): React.CSSProperties => {
		if (!children) return {};

		const targetOption = options.find((option) => option.key === children);
		if (!targetOption) {
			// throw new Error('CRStatus has appropriate option');
			return {};
		}

		if (targetOption.custom) {
			return {
				color: targetOption.custom.color,
			};
		}

		if (type === 'badge') {
			switch (targetOption.color) {
				default:
					return {};
			}
		} else {
			const baseStyle = {
				padding: '0 0.8rem',
				borderRadius: '100rem',
			};
			switch (targetOption.color) {
				case 'gray60':
					return {
						...baseStyle,
						background: Colors.gray60,
						color: Colors.gray00,
					};
				case 'gray':
					return {
						...baseStyle,
						background: Colors.gray90,
						color: Colors.gray10,
					};
				case 'green':
					return {
						...baseStyle,
						background: Colors.greenLighten,
						color: Colors.greenDarken,
					};
				case 'blue':
					return {
						...baseStyle,
						background: Colors.blueLighten,
						color: Colors.blueDarken,
					};
				case 'yellow':
					return {
						...baseStyle,
						background: Colors.yellowLighten,
						color: Colors.yellowDarken,
					};
				case 'red':
					return {
						...baseStyle,
						background: Colors.redLighten,
						color: Colors.redDarken,
					};
				default:
					return { ...baseStyle };
			}
		}
	}, [children, type]);

	const label = useMemo((): string => {
		if (!children) return '';

		const targetOption = options.find((option) => option.key === children);
		if (!targetOption) {
			// throw new Error('CRStatus has appropriate option');
			return '';
		}

		return targetOption.label ?? children.toString();
	}, [children]);

	if (!children) return <S.Container>-</S.Container>;

	return (
		<S.Container style={style}>
			{type === 'badge' && <S.Badge style={badgeStyle} />}
			{label}
		</S.Container>
	);
}

export default React.memo(CRStatus);
