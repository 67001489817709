import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import dayjs from 'dayjs';

import Colors from 'common/colors';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import InformationSheet from 'components/ui/InformationSheet';
import { displayBirthDay } from 'lib';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes } from 'lib/hook/react-query';
import { IntegratedAssessmentKmmseForms } from 'types/view/recipient';

import * as S from './styles';

interface Props {
	form: UseFormReturn<IntegratedAssessmentKmmseForms, any, undefined>;
	disabled?: boolean;
}

function IntegratedKmmseRecipientInfoForm({ form, disabled }: Props): React.ReactElement {
	const { control, setValue } = form;
	const { data: options = {} } = useCommonCodes(
		{ comCdGroupNms: ['CMN176', 'CMN172', 'CMN013', 'CMN042', 'CMN127'] },
		commonCodeAdapter,
	);

	const handleChangEducation = (item: CheckOption) => {
		// 학력 정보가 무학일 경우 교육 년수 0으로 함
		if (item.value === 'CMN127.S2') {
			setValue('educationYear', '0');
		}
	};

	return (
		<S.Container>
			<Controller
				render={({ field: { ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='생년월일 기준' type='left' isRequired>
						<CRCheckBoxGroup
							ref={ref}
							checkType='single'
							type='radio'
							gap={0.8}
							value={value}
							onChange={onChange}
							options={options.CMN176 ?? []}
							appearanceType='button'
							disabled={disabled}
						/>
					</CRInputLabel>
				)}
				name='calendar'
				control={control}
			/>
			<CRInputLabel label='수급자 정보' type='left' isRequired>
				<InformationSheet
					align='flex-start'
					items={[
						[
							{
								label: '이름',
								value: form.getValues('recipientNm'),
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
						[
							{
								label: '성별',
								value:
									options?.CMN013?.find(
										(option) => option.value === form.getValues('recipientGenderCd'),
									)?.data?.korComCdAliasNm ?? '',
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
						[
							{
								label: '생년월일',
								value: displayBirthDay(form.getValues('recipientBirthDt') ?? '', true),
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
						[
							{
								label: '장기요양인정번호',
								value: form.getValues('longTermNo'),
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
						[
							{
								label: '장기요양인정등급',
								value:
									options?.CMN042?.find(
										(option) => option.value === form.getValues('longTermGradeCd'),
									)?.data?.korComCdAliasNm ?? '',
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
					]}
					type='gray'
				/>
			</CRInputLabel>
			<Controller
				render={({ field: { ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='조사 사유' type='left' isRequired>
						<CRCheckBoxGroup
							ref={ref}
							checkType='single'
							type='radio'
							gap={0.8}
							value={value}
							onChange={onChange}
							options={options.CMN172 ?? []}
							appearanceType='button'
							disabled={disabled}
						/>
					</CRInputLabel>
				)}
				name='type'
				control={control}
			/>
			<Controller
				render={({ field: { ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='치매여부' type='left' isRequired>
						<CRCheckBoxGroup
							ref={ref}
							checkType='single'
							type='radio'
							gap={0.8}
							value={value}
							onChange={onChange}
							options={[
								{ label: '없음', value: false },
								{ label: '있음', value: true },
							]}
							appearanceType='button'
							disabled={disabled}
						/>
					</CRInputLabel>
				)}
				name='dementiaYn'
				control={control}
			/>

			<Controller
				render={({ field: { ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='학력정보' type='left' isRequired showOverflow>
						<CRInput.Selector
							ref={ref}
							items={options.CMN127 ?? []}
							placeholder='학력 정보 선택'
							onChangeValue={(item) => {
								handleChangEducation(item);
								onChange(item);
							}}
							currentValue={value}
							disabled={disabled}
						/>
					</CRInputLabel>
				)}
				name='education'
				control={control}
			/>
			<Controller
				render={({ field: { ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='교육년수' type='left' isRequired>
						<CRInput.Default
							ref={ref}
							placeholder='교육년수 입력'
							suffix='년'
							onChange={onChange}
							value={value}
							type='number'
							maxLength={2}
							disabled={disabled}
						/>
					</CRInputLabel>
				)}
				name='educationYear'
				control={control}
			/>
			<Controller
				render={({ field: { ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='직업' type='left' isRequired>
						<CRInput.Default
							ref={ref}
							placeholder='직업 입력'
							onChange={onChange}
							value={value}
							disabled={disabled}
						/>
					</CRInputLabel>
				)}
				name='job'
				control={control}
			/>
			<Controller
				render={({ field, formState: { errors } }) => (
					<CRInputLabel label='작성자' type='left' isRequired showOverflow>
						<CRInput.Default {...field} placeholder='작성자를 입력해주세요' disabled={disabled} />
					</CRInputLabel>
				)}
				name='writerNm'
				control={form.control}
			/>
			<Controller
				render={({ field: { value, onChange, ...rest }, formState: { errors } }) => (
					<CRInputLabel label='작성일' type='left' isRequired showOverflow>
						<CRInput.DatePicker
							{...rest}
							value={dayjs(value).toDate()}
							onChangeValue={(e) => onChange(dayjs(e).format('YYYY-MM-DD HH:mm:ss'))}
							placeholder='작성일 선택'
							disabled={disabled}
						/>
					</CRInputLabel>
				)}
				name='writeDate'
				control={form.control}
			/>
		</S.Container>
	);
}

export default IntegratedKmmseRecipientInfoForm;
