import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import { DayOfTheWeek } from 'components/base/CRInput/CRTimePicker';

import useContractTask from '../../../../lib/hook/view/contract/useContractTask';
import CRRequiredMark from '../../../base/CRRequiredMark';
import * as S from './styles';

function OtherServiceUsageForm(): React.ReactElement {
	const { form, commonCodes, disabled } = useContractTask();

	const { fields, append, remove } = useFieldArray({
		control: form?.control,
		name: 'recipient.otherServiceUses',
	});

	const handleChangeTime =
		(index: number, timeIndex: number) =>
		(dayOfTheWeeks: DayOfTheWeek[], startTime = '', endTime = '') => {
			const days = dayOfTheWeeks.map((day) => ({ otherServiceUseDayCd: day }));
			form?.setValue(`recipient.otherServiceUses.${index}.times.${timeIndex}.days`, days);
			form?.setValue(
				`recipient.otherServiceUses.${index}.times.${timeIndex}.otherServiceUseStartTime`,
				startTime?.replace(':', ''),
			);
			form?.setValue(
				`recipient.otherServiceUses.${index}.times.${timeIndex}.otherServiceUseEndTime`,
				endTime?.replace(':', ''),
			);
		};

	const handleClickAddOtherService = () => {
		append({
			otherCenterNm: '',
			otherCenterPhoneNo: '',
			serviceTypeCd: 'CMN006.10',
			remark: '',
			times: [
				{
					otherServiceUseStartTime: '',
					otherServiceUseEndTime: '',
					days: [],
				},
			],
		});
	};

	const handleClickRemoveOtherService = (index: number) => () => {
		remove(index);
	};

	const handleAddTime = (index: number) => () => {
		const previousTimes = form?.getValues(`recipient.otherServiceUses.${index}.times`) ?? [];
		form?.setValue(`recipient.otherServiceUses.${index}.times`, [
			...previousTimes,
			{
				days: [],
				otherServiceUseStartTime: '',
				otherServiceUseEndTime: '',
			},
		]);
	};

	const handleDeleteTime = (index: number, timeIndex: number) => () => {
		const previous = form?.getValues(`recipient.otherServiceUses.${index}.times`);
		form?.setValue(
			`recipient.otherServiceUses.${index}.times`,
			previous.filter((_, i) => timeIndex !== i),
		);
	};

	const transformTimeString = (time = '') => {
		if (time.length <= 2) return time;
		return `${time.slice(0, 2)}:${time.slice(2, time.length)}`;
	};
	const formatIndex = (number: number) => `${number + 1}`.padStart(2, '0');
	return (
		<S.Container>
			<S.Form>
				{fields?.map((_, index) => (
					<>
						<S.ItemHeader>
							{`타급여 ${formatIndex(index)}`}
							<S.DeleteButtonContainer>
								{!disabled && (
									<CRButton.Default
										size='xSmall'
										type='outlined'
										onClick={handleClickRemoveOtherService(index)}
										disabled={disabled}>
										삭제
									</CRButton.Default>
								)}
							</S.DeleteButtonContainer>
						</S.ItemHeader>
						<S.Table>
							<S.TableRow>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>
									센터명 <CRRequiredMark />
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }}>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TableInput
												ref={ref}
												onBlur={onBlur}
												onChange={onChange}
												value={value}
												placeholder='센터명 입력'
												disabled={disabled}
											/>
										)}
										name={`recipient.otherServiceUses.${index}.otherCenterNm`}
										control={form?.control}
									/>
								</S.TableValueColumn>
								<S.TableLabelColumn style={{ width: '10.4rem' }} rowSpan={3}>
									비고
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }} rowSpan={3}>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TextArea
												typography='label'
												ref={ref}
												onBlur={onBlur}
												onChange={onChange}
												value={value}
												placeholder='비고 입력'
												numberOfLines={4}
												disabled={disabled}
											/>
										)}
										name={`recipient.otherServiceUses.${index}.remark`}
										control={form?.control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
							<S.TableRow>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>
									전화번호 <CRRequiredMark />
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }}>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TableInput
												ref={ref}
												onBlur={onBlur}
												onChange={onChange}
												value={value}
												placeholder='전화번호 입력'
												disabled={disabled}
											/>
										)}
										name={`recipient.otherServiceUses.${index}.otherCenterPhoneNo`}
										control={form?.control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
							<S.TableRow>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>
									타급여 <CRRequiredMark />
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem', verticalAlign: 'middle' }}>
									<Controller
										render={({ field: { ref, onChange, value }, formState: { errors } }) => (
											<CRInput.Selector
												ref={ref}
												items={commonCodes?.CMN006 ?? []}
												currentValue={{ label: '', value }}
												onChangeValue={(option) => onChange(option.value)}
												placeholder='타급여 종류 선택'
												type='small'
												disabled={disabled}
											/>
										)}
										name={`recipient.otherServiceUses.${index}.serviceTypeCd`}
										control={form?.control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
							<S.TableRow>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>
									이용일시 <CRRequiredMark />
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ verticalAlign: 'middle' }} colSpan={3}>
									<Controller
										render={({ formState: { errors } }) => (
											<S.TimePickerContainer>
												{form
													?.watch(`recipient.otherServiceUses.${index}.times`)
													.map((time, timeIndex) => {
														const key = `other-use-${index}-${timeIndex}`;
														return (
															<CRInput.TimePicker
																key={key}
																selectedDayOfTheWeeks={time.days.map(
																	(day) => day.otherServiceUseDayCd as DayOfTheWeek,
																)}
																start={transformTimeString(time.otherServiceUseStartTime)}
																end={transformTimeString(time.otherServiceUseEndTime)}
																onChange={handleChangeTime(index, timeIndex)}
																onDelete={handleDeleteTime(index, timeIndex)}
																disabled={disabled}
															/>
														);
													})}
												{!disabled && (
													<CRButton.IconButton
														onClick={handleAddTime(index)}
														iconLeft={Assets.icon.add}
														palette='gray'
														type='tonal'
														disabled={disabled}>
														이용일시 추가
													</CRButton.IconButton>
												)}
											</S.TimePickerContainer>
										)}
										name={`recipient.otherServiceUses.${index}.times`}
										control={form?.control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
						</S.Table>
					</>
				))}
				<S.AddButtonContainer>
					{!disabled && (
						<CRButton.IconButton
							iconLeft={Assets.icon.add}
							palette='gray'
							type='outlined'
							onClick={handleClickAddOtherService}
							disabled={disabled}>
							타급여 추가
						</CRButton.IconButton>
					)}
				</S.AddButtonContainer>
			</S.Form>
		</S.Container>
	);
}

export default OtherServiceUsageForm;
