import React from 'react';
import { useNavigate } from 'react-router-dom';

import Assets from 'assets';
import { RelatedTaskItem } from 'types/view/approval';

import * as S from './styles';

interface Props {
	item?: RelatedTaskItem;
}

export default function RelatedTask({ item }: Props) {
	const navigate = useNavigate();
	const onClickItem = () => {
		if (item?.link) {
			navigate(item.link);
		}
	};
	return (
		<S.RelatedTask $hasTask={!!item} onClick={onClickItem}>
			<S.RelatedTaskLeft>
				<S.Icon src={Assets.icon.link} alt='link' />
				<span>{item?.name}</span>
			</S.RelatedTaskLeft>
			<S.GoToRelatedTaskPageIcon src={Assets.icon.keyboardArrowRight} alt='keyboardArrowRight' />
		</S.RelatedTask>
	);
}
