import React, { useMemo } from 'react';

import dayjs from 'dayjs';

import CRDialog from 'components/base/CRDialog';
import CRTable from 'components/base/CRTable';
import useDialog from 'lib/hook/util/useDialog';
import { RecordingSheetItem } from 'types/view/schedule';

import { RECORDING_SHEET_DETAIL_HEADER_CONFIG } from './constants';
import * as S from './styles';

interface Props {
	item?: RecordingSheetItem;
}

export default function RecordingSheetDetailDialog({ item }: Props) {
	const { hideDialog } = useDialog();

	const recordingSheetDetailData = useMemo(() => {
		if (!item?.recordingDetails) return [];
		const recordingDetails = Object.values(item.recordingDetails);
		const newData = recordingDetails.map((detail, index) => ({
			order: index + 1,
			serviceYmd: detail?.[0]?.serviceYmd
				? dayjs(detail?.[0]?.serviceYmd).format('YYYY.MM.DD')
				: '',
			recipientNm: detail?.[0]?.recipientNm,
			serviceTypeNm: item.serviceTypeNm,
			employeeNm: detail?.[0]?.employeeNm,
			employeeNm2: detail?.[1]?.employeeNm,
			startEnd1:
				item.serviceTypeCd === 'CMN006.20'
					? detail?.[0]?.rfidStart &&
						detail?.[0]?.rfidEnd &&
						detail?.[1]?.rfidStart &&
						detail?.[1]?.rfidEnd
						? `${dayjs(detail?.[0]?.rfidStart).format('HH:mm')}~${dayjs(
								detail?.[0]?.rfidEnd,
							).format('HH:mm')}`
						: `${dayjs(detail?.[0]?.planStart).format('HH:mm')}~${dayjs(
								detail?.[0]?.planEnd,
							).format('HH:mm')}`
					: detail?.[0]?.rfidStart && detail?.[0]?.rfidEnd
						? `${dayjs(detail?.[0]?.rfidStart).format('HH:mm')}~${dayjs(
								detail?.[0]?.rfidEnd,
							).format('HH:mm')}`
						: `${dayjs(detail?.[0]?.planStart).format('HH:mm')}~${dayjs(
								detail?.[0]?.planEnd,
							).format('HH:mm')}`,
			startEnd2:
				item.serviceTypeCd === 'CMN006.20'
					? detail?.[0]?.rfidStart &&
						detail?.[0]?.rfidEnd &&
						detail?.[1]?.rfidStart &&
						detail?.[1]?.rfidEnd
						? `${dayjs(detail?.[1]?.rfidStart).format('HH:mm')}~${dayjs(
								detail?.[1]?.rfidEnd,
							).format('HH:mm')}`
						: `${dayjs(detail?.[1]?.planStart).format('HH:mm')}~${dayjs(
								detail?.[1]?.planEnd,
							).format('HH:mm')}`
					: '',
			serviceTypeCd: detail?.[0]?.serviceTypeCd,
		}));
		return newData;
	}, [item]);

	return (
		<CRDialog
			onClickClose={hideDialog}
			title='기록지 확인'
			type='M'
			height='88rem'
			body={
				<S.Container>
					<CRTable.Root>
						<CRTable.Head heads={RECORDING_SHEET_DETAIL_HEADER_CONFIG} />
						<CRTable.Body>
							{recordingSheetDetailData.map((item) => (
								<React.Fragment key={item.order}>
									<CRTable.Row
										item={item}
										renderKeys={[
											'order',
											'serviceYmd',
											'recipientNm',
											'serviceTypeNm',
											'employeeNm',
											'startEnd1',
											'',
										]}
										mergeConfig={
											item.serviceTypeCd === 'CMN006.20'
												? {
														order: {
															rowSpan: 2,
														},
														serviceYmd: {
															rowSpan: 2,
														},
														recipientNm: {
															rowSpan: 2,
														},
														serviceTypeNm: {
															rowSpan: 2,
														},
													}
												: {}
										}
										customStyle={{
											order: {
												verticalAlign: item.serviceTypeCd === 'CMN006.20' ? 'top' : 'middle',
												textAlign: 'right',
											},
											serviceYmd: {
												verticalAlign: item.serviceTypeCd === 'CMN006.20' ? 'top' : 'middle',
											},
											recipientNm: {
												verticalAlign: item.serviceTypeCd === 'CMN006.20' ? 'top' : 'middle',
											},
											serviceTypeNm: {
												verticalAlign: item.serviceTypeCd === 'CMN006.20' ? 'top' : 'middle',
											},
										}}
									/>
									{item.serviceTypeCd === 'CMN006.20' && (
										<CRTable.Row
											item={item}
											renderKeys={['employeeNm2', 'startEnd2', '']}
											customStyle={{
												employeeNm2: {
													borderLeft: '0.1rem solid #E1E3E3',
												},
											}}
										/>
									)}
								</React.Fragment>
							))}
						</CRTable.Body>
					</CRTable.Root>
				</S.Container>
			}
		/>
	);
}
