import { CRTableHeadConfig } from 'types/view/base';

export const ROUNDING_HISTORY_TABLE_RENDER_KEY = [
	'index',
	'visitCompleteYn',
	'visitTypeCdNm',
	'recipientNm',
	'longtermNo',
	'visitDt',
	'visitTime',
	'tagYn',
	'totalMin',
	'managerNm',
	'remark',
] as const;

export const ROUNDING_HISTORY_TABLE_RENDER_ITEM_STYLE = {
	index: {
		textAlign: 'right',
	},
	totalMin: {
		textAlign: 'right',
	},
} as const;

export const ROUNDING_HISTORY_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '5.6rem',
		label: '구분',
		sortKey: '구분',
		textAlign: 'right',
	},
	{
		width: '9.6rem',
		label: '상태',
		sortKey: '상태',
	},
	{
		width: '9.6rem',
		label: '유형',
		sortKey: '유형',
	},
	{
		width: '8.8rem',
		label: '수급자명',
		sortKey: '수급자명',
	},
	{
		width: '14.4rem',
		label: '장기요양인정번호',
		sortKey: '장기요양인정번호',
	},
	{
		width: '14.4rem',
		label: '방문일자',
		sortKey: '방문일자',
	},
	{
		width: '14.4rem',
		label: '방문시간',
		sortKey: '방문시간',
	},
	{
		width: '8rem',
		label: '태그 여부',
		sortKey: '태그 여부',
	},
	{
		width: '8.8rem',
		label: '총 방문시간',
		sortKey: '총 방문시간',
		textAlign: 'right',
	},
	{
		width: '8.8rem',
		label: '사회복지사',
		sortKey: '사회복지사',
	},
	{
		width: '100%',
		label: '비고',
		sortKey: '비고',
	},
];
