import React, { useMemo } from 'react';

import CRButton from 'components/base/CRButton/CRDefaultButton';
import { ButtonSize } from 'components/base/CRButton/type';
import { CRText } from 'components/base/CRText';

import Colors from '../../../../common/colors';
import * as S from './styles';

interface IProps {
	value: number;
	suffix?: string;
	countStepNumber?: number;
	buttonSize?: ButtonSize;
	error?: boolean;
	errorMessage?: string;
	onChange?: (value: number) => void;
	min?: number;
	max?: number;
	plusColor?: string;
	minusColor?: string;
	renderValue?: (value: number) => React.ReactNode;
}

function CRCounter({
	value,
	suffix,
	countStepNumber = 1,
	buttonSize = 'xSmall',
	error = false,
	errorMessage,
	onChange,
	min,
	max,
	plusColor = 'primary',
	minusColor = 'primary',
	renderValue,
}: IProps): React.ReactElement {
	const handleClickMinus = () => {
		if (countStepNumber > 0 && min !== undefined && value - countStepNumber < min) return;
		if (countStepNumber < 0 && max !== undefined && value - countStepNumber > max) return;
		onChange?.(value - countStepNumber);
	};
	const handleClickPlus = () => {
		if (countStepNumber > 0 && max !== undefined && value + countStepNumber > max) return;
		if (countStepNumber < 0 && min !== undefined && value + countStepNumber < min) return;
		onChange?.(value + countStepNumber);
	};

	const canPlus = useMemo(() => {
		if (countStepNumber > 0 && max !== undefined && value + countStepNumber > max) return false;
		if (countStepNumber < 0 && min !== undefined && value + countStepNumber < min) return false;
		return true;
	}, [value, plusColor, minusColor, countStepNumber, min, max]);
	const canMinus = useMemo(() => {
		if (countStepNumber > 0 && min !== undefined && value - countStepNumber < min) return false;
		if (countStepNumber < 0 && max !== undefined && value - countStepNumber > max) return false;
		return true;
	}, [value, plusColor, minusColor, countStepNumber, min, max]);
	const color = useMemo(() => {
		if (value > 0) return plusColor;
		if (value < 0) return minusColor;
		return undefined;
	}, [value, plusColor, minusColor, countStepNumber, min, max]);

	return (
		<S.Container>
			<CRButton
				type='outlined'
				style={{
					background: canMinus ? undefined : Colors.gray95,
					color: canMinus ? undefined : Colors.gray60,
				}}
				size={buttonSize}
				palette='gray'
				onClick={handleClickMinus}>
				-
			</CRButton>
			<S.ValueContainer $error={error} style={{ color }}>
				{renderValue ? (
					renderValue?.(value)
				) : (
					<>
						{value > 0 ? `+${value}` : value}
						{suffix}
					</>
				)}
			</S.ValueContainer>
			<CRButton
				type='outlined'
				style={{
					background: canPlus ? undefined : Colors.gray95,
					color: canPlus ? undefined : Colors.gray60,
				}}
				size={buttonSize}
				palette='gray'
				onClick={handleClickPlus}>
				+
			</CRButton>
			{error && errorMessage && (
				<CRText typography='label' color='primary60'>
					{errorMessage}
				</CRText>
			)}
		</S.Container>
	);
}

export default CRCounter;
