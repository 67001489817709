import { useContext } from 'react';

import { AuthContext } from 'lib/provider/auth';

export default function useAuth() {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error('useAuth must be used within a AuthProvider');
	}
	return context;
}
