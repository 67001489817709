import RouterPath from 'common/router';
import { TaskTabProps } from 'components/base/CRTab/TaskTab/type';

export const MY_TAB_CONFIG: TaskTabProps[] = [
	{
		label: '기초 정보',
		key: RouterPath.fullTimeEmployee().basic.uniqueKey,
	},
	{
		label: '자격증',
		key: RouterPath.fullTimeEmployee().license.uniqueKey,
	},
	{
		label: '교육',
		key: RouterPath.fullTimeEmployee().education.uniqueKey,
	},
	{
		label: '근무 일정',
		key: RouterPath.fullTimeEmployee().schedule.uniqueKey,
	},
];

export const TAB_CONFIG: TaskTabProps[] = [
	{
		label: '근무 일정',
		key: RouterPath.fullTimeEmployee().schedule.uniqueKey,
	},
];
