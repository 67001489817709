import { styled } from 'styled-components';

import CRIcon from 'components/base/CRIcon';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const BodyContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	padding: 0 2.4rem 2.4rem 2.4rem;
	gap: 1.6rem;
`;

export const BodyLeftContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 30.4rem;
	gap: 1.6rem;
`;

export const BodyRightContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1.6rem;
`;

export const SectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	border: 1px solid ${(props) => props.theme.colors.gray90};
	border-radius: 0.8rem;
	padding: 1.6rem;
	gap: 1.6rem;
`;

export const SectionTitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const InputHorizontalAlignContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 0.4rem;
`;

export const InputContainer = styled.div`
	width: 100%;
`;

export const IconContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
`;

export const TimeIcon = styled(CRIcon)`
	filter: brightness(0) saturate(100%) invert(62%) sepia(3%) saturate(208%) hue-rotate(148deg)
		brightness(92%) contrast(91%);
`;

export const ApprovedWorkLogContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.6rem;
	background-color: ${(props) => props.theme.colors.gray99};
	box-sizing: border-box;
	border-radius: 0.8rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	gap: 0.8rem;
`;

export const RightButtonContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: flex-end;
	gap: 0.8rem;
`;

export const CounterContainer = styled.div`
	padding: 1.2rem 0;
`;
