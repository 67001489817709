import React, { useRef } from 'react';

import dayjs, { Dayjs } from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRMonthCalendar from 'components/base/CRMonthCalendar';
import { CRText } from 'components/base/CRText';
import InformationTable from 'components/ui/InformationTable';
import { useFtimeEmployeeMonthSchedule } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import usePDF from 'lib/hook/util/usePDF';
import { FtimeEmployeeMonthScheduleInfoDTO } from 'types/dto/schedule';

import { socialWorkerInfo } from './constant';
import * as S from './styles';

type Props = {
	params: {
		managerId: number;
		centerId: number;
	};
	targetDate: Dayjs;
};

function VisitScheduleTableDownloadDialog({ params, targetDate }: Props): React.ReactElement {
	const { hideDialog } = useDialog();

	const pdfRef = useRef<HTMLDivElement>(null);
	const { savePdfFile } = usePDF();

	const handleClickDownload = () => {
		savePdfFile({ pdfTitle: '사회복지사 방문일정표', pdfRef });
		hideDialog();
	};

	const { data: ftimeEmployeeWorkScheduleData } = useFtimeEmployeeMonthSchedule({
		centerId: params.centerId as number,
		managerId: params.managerId as number,
		yearMonth: dayjs(targetDate).format('YYYYMM'),
	});

	const workerInfo: FtimeEmployeeMonthScheduleInfoDTO = {
		...ftimeEmployeeWorkScheduleData?.ftimeEmployeeInfo,
		korMemberNm: ftimeEmployeeWorkScheduleData?.ftimeEmployeeInfo.korMemberNm ?? '-',
		birthDt: dayjs(ftimeEmployeeWorkScheduleData?.ftimeEmployeeInfo.birthDt).format('YYYY.MM.DD'),
	};

	const renderCustomHeader = () => (
		<S.CustomHeaderContainer>
			<CRText typography='h4' text={targetDate.format('YYYY년 M월')} />
			<CRText color='gray10' typography='label'>
				<CRText typography='label' text='● ' color='blue' />
				표시된 시간은 업무수행일지에 요양보호사 서명이 완료된 상태를 뜻합니다.
			</CRText>
		</S.CustomHeaderContainer>
	);

	const renderCustomDayContent = (renderDay: Dayjs) => {
		const renderDateFormat = renderDay.format('YYMMDD');
		const visitSchedule =
			ftimeEmployeeWorkScheduleData?.workExecuteLogs.filter((item) =>
				dayjs(item.visitDt).isSame(renderDay, 'day'),
			) ?? [];

		const handleServiceHour = (startTime: string, endTime: string) => {
			if (!startTime || !endTime) return '';

			return dayjs(`${renderDateFormat} ${endTime}`).diff(
				dayjs(`${renderDateFormat} ${startTime}`),
				'minutes',
			);
		};

		const handleServiceTime = (startTime: string, endTime: string) => {
			if (!startTime || !endTime) return '';

			return `${dayjs(`${renderDateFormat} ${startTime}`).format('HH:mm')} ~ ${dayjs(
				`${renderDateFormat} ${endTime}`,
			).format('HH:mm')}`;
		};

		return visitSchedule.map((item) => (
			<S.VisitContainer>
				<CRText typography='label'>
					{item.workExecuteLogStateCd === 'CMN184.20' && (
						<CRText color='blue' typography='label' text='● ' />
					)}
					{handleServiceTime(item.startTime, item.endTime)}
				</CRText>
				<CRText color='gray60' typography='label'>
					{`${item.recipientNm} (${handleServiceHour(item.startTime, item.endTime)}분)`}
				</CRText>
			</S.VisitContainer>
		));
	};

	return (
		<CRDialog
			title='방문일정표 다운로드'
			onClickClose={hideDialog}
			type='ML'
			body={
				<S.Container>
					<S.BodyContainer>
						<S.BodyContentContainer ref={pdfRef}>
							<CRText typography='h3'>사회복지사 방문일정표</CRText>
							<InformationTable items={socialWorkerInfo(workerInfo)} />
							<S.CalendarContainer>
								<CRMonthCalendar
									currentDate={targetDate}
									onChange={() => {}}
									renderCustomCalendarHeader={renderCustomHeader}
									holidayList={ftimeEmployeeWorkScheduleData?.holidays ?? []}
									renderCustomDayContent={renderCustomDayContent}
								/>
							</S.CalendarContainer>
						</S.BodyContentContainer>
					</S.BodyContainer>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default palette='primary' size='default' onClick={handleClickDownload}>
						다운로드
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}

export default React.memo(VisitScheduleTableDownloadDialog);
