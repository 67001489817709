import React from 'react';

import * as S from './styles';

function CRAccordionContent(
	{ children, ...rest }: React.PropsWithChildren,
	ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
	return (
		<S.Container ref={ref} {...rest}>
			{children}
		</S.Container>
	);
}

export default React.forwardRef(CRAccordionContent);
