import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Accordion from '@radix-ui/react-accordion';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import DeleteDialog from 'components/domain/dialog/DeleteDialog';
import FullTimeEmployeeTOFormView from 'components/domain/form/FullTimeEmployeeTOFormView';
import FullTimeEmployeeDetailBasicInformationTable from 'components/domain/table/FullTimeEmployeeDetailBasicInformationTable';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import { FullTimeEmployeeResign } from 'lib';
import useFullTimeEmployeePage from 'lib/hook/full-time-employee/useFullTimeEmployeePage';
import { useFullTimeEmployeeResign, useResignFullTimeEmployee } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';

import * as S from './styles';

function FullTimeEmployeeResignPage(): React.ReactElement {
	const { currentEmployee, currentEmployeeBase } = useFullTimeEmployeePage();
	const { data: employeeResignInfo } = useFullTimeEmployeeResign({
		employeeId: currentEmployee?.employeeId,
		centerId: currentEmployee?.centerId,
		memberId: currentEmployee?.memberId,
	});
	const { showDialog, hideDialog } = useDialog();
	const {
		control,
		handleSubmit,
		formState: { isValid },
		watch,
	} = useForm<{ date: Date; resignationCompleteYn: boolean; annualExhaustCompleteYn: boolean }>({
		resolver: yupResolver(FullTimeEmployeeResign),
	});

	const params = useParams<{ id: string }>();
	const navigate = useNavigate();

	const resignFullTimeEmployee = useResignFullTimeEmployee((client) => {
		hideDialog();
		navigate(`/${RouterPath.fullTimeEmployee().tab.uniqueKey}/${params.id}/basic`);
	});

	const totalWorkDate = useMemo(() => {
		if (!employeeResignInfo?.joinDate) return '';
		if (!watch('date')) return `${dayjs(employeeResignInfo.joinDate).format('YYYY.MM.DD')} ~ `;

		const diffTotalMonth = dayjs().diff(dayjs(employeeResignInfo.joinDate), 'month');

		if (diffTotalMonth > 11) {
			return `총 ${Math.floor(diffTotalMonth / 12)}년 ${Math.floor(diffTotalMonth % 12)}개월 근무`;
		}
		return `${dayjs(employeeResignInfo.joinDate).format('YYYY.MM.DD')} ~ ${dayjs(
			watch('date'),
		).format('YYYY.MM.DD')} (총 ${Math.floor(diffTotalMonth % 12)}개월 근무)`;
	}, [employeeResignInfo, watch('date')]);

	const onSubmit = (data: {
		date: Date;
		resignationCompleteYn: boolean;
		annualExhaustCompleteYn: boolean;
	}) => {
		if (!currentEmployee) return;

		resignFullTimeEmployee.mutateAsync({
			memberId: currentEmployee?.memberId,
			employeeId: currentEmployee?.employeeId,
			centerId: currentEmployee?.centerId,
			resignDate: dayjs(data.date).format('YYYY-MM-DD hh:mm:ss'),
			resignationCompleteYn: data.resignationCompleteYn,
			annualExhaustCompleteYn: data.annualExhaustCompleteYn,
		});
	};

	const handleClickResign = () => {
		showDialog(({ hideDialog }) => (
			<DeleteDialog
				title='퇴사 신청'
				content={`${currentEmployeeBase?.korMemberNm}(${currentEmployeeBase?.employeeId}) 직원 상태를 퇴사로 변경합니다.`}
				hideDialog={hideDialog}
				cancelOption={{
					text: '취소',
				}}
				successOption={{
					text: '저장',
					successCallback: handleSubmit(onSubmit),
				}}
			/>
		));
	};

	return (
		<S.Container>
			<S.Title>{`${currentEmployeeBase?.korMemberNm}(${currentEmployeeBase?.employeeId}) 직원 퇴사`}</S.Title>
			<S.ContentsContainer>
				<Accordion.Root
					type='multiple'
					style={{
						width: '100%',
					}}
					defaultValue={['기본 정보', '연차 정보', '퇴사 신청', '점검']}>
					<Accordion.Item value='기본 정보' asChild>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger>기본 정보</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild>
								<TaskAccordion.Content>
									<FullTimeEmployeeDetailBasicInformationTable item={employeeResignInfo} isResign />
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
					<Accordion.Item value='연차 정보' asChild>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger>연차 정보</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild>
								<TaskAccordion.Content>
									<FullTimeEmployeeTOFormView disabledButton />
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
					<Accordion.Item value='퇴사 신청' asChild>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger>퇴사 신청</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild>
								<TaskAccordion.Content>
									<div
										style={{
											width: '57.2rem',
										}}>
										<CRInputLabel label='퇴사일' type='left' isRequired>
											<Controller
												render={({ field: { onChange, value } }) => (
													<CRInput.DatePicker
														value={value}
														onChangeValue={onChange}
														placeholder='2023.00.00'
														minDate={dayjs(employeeResignInfo?.joinDate).toDate()}
														popupPosition={{ x: 0, y: -500 }}
													/>
												)}
												name='date'
												control={control}
											/>
										</CRInputLabel>
										<CRInputLabel label='근무기간' type='left'>
											<S.ContentContainer>{totalWorkDate}</S.ContentContainer>
										</CRInputLabel>
									</div>
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
					<Accordion.Item value='점검' asChild>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger>점검</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild>
								<TaskAccordion.Content>
									<S.CheckBoxContainer>
										<S.CheckBoxRow>
											<Controller
												render={({ field: { onChange, value } }) => (
													<CRCheckBox checked={value} onChange={onChange}>
														사직서 및 퇴직급여 지급 연장 동의서 수령{' '}
														<S.RequiredMark>*</S.RequiredMark>
													</CRCheckBox>
												)}
												name='resignationCompleteYn'
												control={control}
											/>
										</S.CheckBoxRow>
										<S.CheckBoxRow>
											<Controller
												render={({ field: { onChange, value } }) => (
													<CRCheckBox checked={value} onChange={onChange}>
														잔여연차 확인 완료 <S.RequiredMark>*</S.RequiredMark>
													</CRCheckBox>
												)}
												name='annualExhaustCompleteYn'
												control={control}
											/>
										</S.CheckBoxRow>
									</S.CheckBoxContainer>
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
				</Accordion.Root>
			</S.ContentsContainer>
			<S.BottomSection>
				<S.BottomMenuContainer>
					<S.BottomRightMenuContainer>
						<CRButton.Default type='text' palette='gray' onClick={() => navigate(-1)}>
							취소
						</CRButton.Default>
						<CRButton.Default disabled={!isValid} type='outlined' onClick={handleClickResign}>
							퇴사
						</CRButton.Default>
					</S.BottomRightMenuContainer>
				</S.BottomMenuContainer>
			</S.BottomSection>
		</S.Container>
	);
}

export default FullTimeEmployeeResignPage;
