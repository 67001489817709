import { endpoint } from 'lib/service/Api/endpoint';

import useCRQuery from '../base/useCRQuery';

export const useEmployeeWorkScheduleInfo = useCRQuery(
	endpoint.getEmployeeWorkSchedule.key,
	'getEmployeeWorkSchedule',
	{
		enabledKey: ['centerId', 'yearMonth', 'employeeId'],
	},
);

export const useFullTimeEmployeeWorkScheduleInfo = useCRQuery(
	endpoint.getFullTimeEmployeeWorkSchedule.key,
	'getFullTimeEmployeeWorkSchedule',
	{
		enabledKey: ['centerId', 'yearMonth', 'employeeId'],
	},
);
