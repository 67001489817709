import React, { ChangeEvent, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import {
	IntegratedAssessmentKmmseForm,
	IntegratedAssessmentKmmseForms,
} from 'types/view/recipient';

import IntegratedKmmseFormHeader from './IntegratedKmmseFormHeader';
import { integratedAssessmentKmmseQuestions } from './constant';
import * as S from './styles';

interface Props {
	form: UseFormReturn<IntegratedAssessmentKmmseForms, any, undefined>;
	disabled?: boolean;
}

function IntegratedKmmseForm({ form, disabled }: Props): React.ReactElement {
	const { control, watch, getValues } = form;

	const totalPoint = useMemo(
		() =>
			(
				[
					'기억등록',
					'시간지남력',
					'장소기억력',
					'기억회상',
				] as (keyof IntegratedAssessmentKmmseForm)[]
			).reduce((accumulate, item) => {
				const value = getValues(item);
				return (
					accumulate +
					Object.keys(value ?? {}).filter((item) => (value as any)?.[item]?.checked).length
				);
			}, 0),
		[getValues()],
	);

	return (
		<S.Container>
			<S.Table>
				<IntegratedKmmseFormHeader />
				<S.TableBody>
					{integratedAssessmentKmmseQuestions.map((item) => (
						<React.Fragment key={item.key}>
							{item.questions.map((question, index) => (
								<Controller
									key={item.key + question.key}
									render={({ field: { ref, onChange, value }, formState: { errors } }) => (
										<S.TableBodyRow>
											{index === 0 && (
												<S.TableBodyColumn rowSpan={index === 0 ? item.questions.length : 1}>
													{item.label}
												</S.TableBodyColumn>
											)}
											<S.TableBodyColumn colSpan={question.question2 ? 1 : 2}>
												{question.question1}
											</S.TableBodyColumn>
											{question.question2 && (
												<S.TableBodyColumn>{question.question2}</S.TableBodyColumn>
											)}
											<S.TableBodyColumn>
												<CRInput.Default
													placeholder='반응 입력'
													value={(value as any)?.[question.key]?.value ?? ''}
													onChange={(input: string) => {
														onChange({
															...(value as any),
															[question.key]: {
																value: input,
																checked: (value as any)?.[question.key]?.checked,
															},
														});
													}}
													disabled={disabled}
												/>
											</S.TableBodyColumn>
											<S.TableBodyColumn>
												<CRCheckBox
													checkBoxType='checkbox'
													checked={(value as any)?.[question.key]?.checked ?? false}
													onChange={(event: ChangeEvent<HTMLInputElement>) => {
														onChange({
															...(value as any),
															[question.key]: {
																value: (value as any)?.[question.key]?.value,
																checked: event.target.checked,
															},
														});
													}}
													onlyCheck
													disabled={disabled}
												/>
											</S.TableBodyColumn>
											{index === 0 && (
												<S.TableBodyColumn
													style={{ textAlign: 'center' }}
													rowSpan={index === 0 ? item.questions.length : 1}>
													{
														Object.keys(value ?? {}).filter(
															(item) => (value as any)?.[item]?.checked,
														).length
													}
												</S.TableBodyColumn>
											)}
										</S.TableBodyRow>
									)}
									name={item.key}
									control={control}
								/>
							))}
						</React.Fragment>
					))}
				</S.TableBody>
				<S.TableBodyTotalRow>
					<S.TableBodyTotalColumn colSpan={5}>단축형 총점</S.TableBodyTotalColumn>
					<S.TableBodyTotalColumn style={{ textAlign: 'center' }}>
						{totalPoint}
					</S.TableBodyTotalColumn>
				</S.TableBodyTotalRow>
			</S.Table>
			<Controller
				render={({ field: { ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='비고'>
						<CRInput.TextArea
							ref={ref}
							placeholder='비고 입력'
							onChange={onChange}
							value={value ?? ''}
							disabled={disabled}
						/>
					</CRInputLabel>
				)}
				name='memo'
				control={control}
			/>
		</S.Container>
	);
}

export default IntegratedKmmseForm;
