import React from 'react';
import { Controller } from 'react-hook-form';

import Colors from 'common/colors';
import CRInputLabel from 'components/base/CRInputLabel';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import InformationSheet from 'components/ui/InformationSheet';
import useWorkExecuteLog from 'lib/hook/view/workExecuteLog/useWorkExecuteLog';
import { WorkExecuteLogNewRecipient } from 'types/api/workExecuteLog';

import * as S from './styles';

export enum WorkExecuteLogCode {
	사회복지사 = 'CMN183.10',
	프로그램관리자 = 'CMN183.20',
}

export const insuranceTypes = [
	{
		label: '사회복지사 업무수행일지',
		value: WorkExecuteLogCode.사회복지사,
	},
	{
		label: '프로그램관리자 업무수행일지',
		value: WorkExecuteLogCode.프로그램관리자,
	},
] as CheckOption<any>[];

interface Props {
	recipient?: WorkExecuteLogNewRecipient;
	disabled: boolean;
}

function WorkExecuteLogBasicInfoForm({ recipient, disabled }: Props): React.ReactElement {
	const { form } = useWorkExecuteLog();

	return (
		<S.Container>
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInputLabel isRequired label='구분' type='left'>
						<CRCheckBoxGroup
							disabled={disabled}
							direction='col'
							onChange={onChange}
							appearanceType='button'
							checkType='single'
							type='radio'
							gap={0.8}
							value={value}
							options={insuranceTypes}
						/>
					</CRInputLabel>
				)}
				name='workExecuteLogDivCd'
				control={form?.control}
			/>
			<CRInputLabel label='수급자 정보' type='left'>
				<InformationSheet
					align='flex-start'
					items={[
						[
							{
								label: '이름',
								value: recipient?.recipientNm || '',
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
						[
							{
								label: '성별',
								value: recipient?.genderNm || '',
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
						[
							{
								label: '생년월일',
								value: recipient?.birthDt || '',
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
						[
							{
								label: '장기요양인정번호',
								value: recipient?.longTermNo || '',
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
						[
							{
								label: '장기요양인정등급',
								value: recipient?.longTermGradeNm || '',
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
					]}
					type='gray'
				/>
			</CRInputLabel>
		</S.Container>
	);
}

export default React.memo(WorkExecuteLogBasicInfoForm);
