import useCRMutation from 'lib/hook/react-query/base/useCRMutation';
import { endpoint } from 'lib/service/Api/endpoint';

export const useUpdateRecipientWorkExecuteLog = useCRMutation(
	endpoint.updateWorkExecuteLog.key,
	'updateRecipientWorkExecuteLogNew',
);

export const useIssueWorkExecuteLog = useCRMutation(
	endpoint.issueWorkExecuteLog.key,
	'issueWorkExecuteLog',
);

export const useUpdateWorkExecuteInspectionLog = useCRMutation(
	endpoint.updateWorkExecuteInspectionLog.key,
	'updateWorkExecuteInspectionLog',
);
