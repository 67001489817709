import { endpoint } from 'lib/service/Api/endpoint';

import useCRQuery from '../base/useCRQuery';

export const useWorkExecuteLogList = useCRQuery(
	endpoint.getWorkExecuteLog.key,
	'getWorkExecuteLog',
	{
		enabledKey: ['recipientId', 'centerId', 'year'],
	},
);

export const useRecipientWorkExecuteLogServiceSchedulePlan = useCRQuery(
	endpoint.getWorkExecuteLogServiceSchedulePlan.key,
	'getWorkExecuteLogServiceSchedulePlan',
	{
		enabledKey: ['centerId', 'recipientId', 'year', 'searchDate'],
	},
);

export const useRecipientWorkExecuteLogNew = useCRQuery(
	endpoint.getRecipientWorkExecuteLogNew.key,
	'getRecipientWorkExecuteLogNew',
	{
		enabledKey: ['centerId', 'recipientId', 'year'],
	},
);

export const useWorkExecuteLogDetail = useCRQuery(
	endpoint.getRecipientWorkExecuteLogDetail.key,
	'getWorkExecuteLogDetail',
	{
		enabledKey: ['workExecuteLogId'],
		cacheTime: 0,
	},
);

export const useRecipientConsultClient = useCRQuery(
	endpoint.getRecipientConsultClient.key,
	'getRecipientConsultClient',
	{
		enabledKey: ['recipientId', 'centerId'],
	},
);

export const useWorkExecuteInspectionLog = useCRQuery(
	endpoint.getWorkExecuteInspectionLog.key,
	'getWorkExecuteInspectionLog',
	{
		enabledKey: ['edocUuid'],
	},
);
