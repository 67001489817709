import React, { useCallback } from 'react';

import dayjs from 'dayjs';

import CRDialog from 'components/base/CRDialog';
import CRTable from 'components/base/CRTable';
import { recipientHistoryOfLongTermGradeAdapter } from 'lib/adapter/recipient';
import { useRecipientHistoryOfLongTermGrade } from 'lib/hook/react-query';
import { Recipient } from 'types/view/recipient';

import CRStatus from '../../../base/CRStatus';
import { RECIPIENT_LONG_TERM_GRADE_HISTORY_TABLE_HEADER_CONFIG } from './constants';

interface Props {
	currentRecipient?: Recipient;
	onClickClose?: () => void;
}

function RecipientLongTermGradeHistoryDialog({
	currentRecipient,
	onClickClose,
}: Props): React.ReactElement {
	const { data: historyOfLongTermGrade = [] } = useRecipientHistoryOfLongTermGrade(
		{
			recipientId: currentRecipient?.recipientId,
		},
		recipientHistoryOfLongTermGradeAdapter,
	);

	const DateComponent = (item: string) => dayjs(item).format('YYYY.MM.DD');

	const StatusComponent = useCallback(
		(item: string) => (
			<CRStatus
				options={[
					{
						key: '현재',
						color: 'green',
					},
					{
						key: '이전 이력',
						color: 'gray',
					},
				]}>
				{item}
			</CRStatus>
		),
		[],
	);

	return (
		<CRDialog
			type='L'
			title='장기요양등급 이력'
			body={
				<CRTable.Root>
					<CRTable.Head
						disableBackBlind
						heads={RECIPIENT_LONG_TERM_GRADE_HISTORY_TABLE_HEADER_CONFIG}
					/>
					<CRTable.Body>
						{historyOfLongTermGrade.map((item) => (
							<CRTable.Row
								key={item.id}
								item={item}
								customRender={{
									status: StatusComponent,
									date: DateComponent,
									acquisitionDate: DateComponent,
									expiredDate: DateComponent,
								}}
								renderKeys={[
									'status',
									'date',
									'grade',
									'acquisitionDate',
									'expiredDate',
									'number',
									'note',
									'center',
									'manager',
								]}
							/>
						))}
					</CRTable.Body>
				</CRTable.Root>
			}
			onClickClose={onClickClose}
		/>
	);
}

export default RecipientLongTermGradeHistoryDialog;
