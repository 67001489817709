import React, { useEffect, useMemo, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { Employee } from 'components/domain/dialog/ApprovalLineSelectorDialog/mock';
import { useApi } from 'lib/provider/service/Api';
import { SignUpResponseDTO } from 'types/api';

import { endpoint } from '../../service/Api/endpoint';

export type SignInForm = {
	userId: string;
	password: string;
};

export type NewPasswordForm = {
	password: string;
	passwordConfirm: string;
};

export type SignUpForm = {
	userId: string;
	password: string;
	passwordConfirm: number;
	username: string;
	phoneNumber: string;
	workMobilePhoneNo: string;
	center: {
		label: string;
		value: any;
	};
	birthDt: string;
};

interface AuthContextType {
	isLoggedIn: boolean;
	passwordChangeNeed: boolean;
	userInfo?: Employee;
	signIn: (signInForm: SignInForm) => Promise<boolean>;
	signOut: () => void;
	signUp: (signUpForm: SignUpForm) => Promise<SignUpResponseDTO | null>;
	validateId: (id: string) => Promise<boolean>;
	setNewPassword: (newPasswordForm: NewPasswordForm) => Promise<void>;
}

export const AuthContext = React.createContext<AuthContextType | null>(null);

function AuthProvider({ user, children }: { user: boolean; children: React.ReactNode }) {
	const queryClient = useQueryClient();
	const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(user);
	const [passwordChangeNeed, setNeedPasswordChange] = useState(false);
	const [accessToken, setAccessToken] = React.useState<string>();
	const api = useApi();

	const signIn = async (signInForm: SignInForm): Promise<boolean> => {
		try {
			const data = await api.signIn({
				userId: signInForm.userId,
				password: signInForm.password,
			});
			if (data?.accessToken) {
				if (data?.passwordChangeNeedYn) {
					setNeedPasswordChange(true);
					setAccessToken(data.accessToken);
				} else {
					setIsLoggedIn(!!data.accessToken);
					sessionStorage.setItem('access_token', data.accessToken);
				}
			}
			return !!data?.passwordChangeNeedYn;
		} catch (err) {
			return false;
		}
	};

	const signUp = async (signUpForm: SignUpForm) =>
		api.signUp({
			userId: signUpForm.userId,
			password: signUpForm.password,
			centerId: signUpForm.center.value,
			userNm: signUpForm.username,
			personalMobilePhoneNo: signUpForm.phoneNumber,
			workMobilePhoneNo: signUpForm.workMobilePhoneNo,
			birthDt: Number(signUpForm.birthDt),
		});

	const signOut = () => {
		sessionStorage.removeItem('access_token');
		queryClient.invalidateQueries();
		setIsLoggedIn(false);
	};

	const validateId = async (id: string): Promise<boolean> => {
		try {
			const result = await api.validateId({ userId: id });
			return !!result;
		} catch (err) {
			// 에러처리
			return false;
		}
	};

	const setNewPassword = async (newPasswordForm: NewPasswordForm) => {
		try {
			if (accessToken) {
				sessionStorage.setItem('access_token', accessToken);
				await api.setNewPassword({
					newPassword: newPasswordForm.password,
				});
				setIsLoggedIn(true);
				setAccessToken(undefined);
				setNeedPasswordChange(false);
			}
		} catch (err) {
			sessionStorage.removeItem('access_token');
			setAccessToken(undefined);
			// 에러처리
		}
	};

	const value = useMemo(
		() => ({
			isLoggedIn,
			passwordChangeNeed,
			signIn,
			signUp,
			signOut,
			setNewPassword,

			validateId,
		}),
		[isLoggedIn, signIn, signUp, signOut, setNewPassword, validateId, passwordChangeNeed],
	);

	useEffect(() => {
		const localToken = localStorage.getItem('access_token');
		const sessionToken = sessionStorage.getItem('access_token');
		if (localToken && !sessionToken) {
			sessionStorage.setItem('access_token', localToken);
		}
	}, []);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
