import escapeRegExp from 'lodash.escaperegexp';

export const regex = {
	id: /^[a-zA-Z0-9]{2,20}$/,
	password: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/,
	phoneNumber: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/,
	hyphenBirth: /^\d{4}-\d{2}-\d{2}$/,
	korean: /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/,
	number: /^[0-9]+$/,
	name: /^(([A-Za-z]+\s?[A-Za-z]+)+|[가-힣]+)$/,
	birth: /^[0-9]{6}$/,
	time: /^([01]\d|2[0-3]):([0-5]\d)$/,
};

export const korSearchPattern = (ch: string) => {
	const offset = 44032;
	if (/[가-힣]/.test(ch)) {
		const chCode = ch.charCodeAt(0) - offset;
		if (chCode % 28 > 0) {
			return ch;
		}
		const begin = Math.floor(chCode / 28) * 28 + offset;
		const end = begin + 27;
		return `[\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
	}
	if (/[ㄱ-ㅎ]/.test(ch)) {
		const con2syl = {
			ㄱ: '가'.charCodeAt(0),
			ㄲ: '까'.charCodeAt(0),
			ㄴ: '나'.charCodeAt(0),
			ㄷ: '다'.charCodeAt(0),
			ㄸ: '따'.charCodeAt(0),
			ㄹ: '라'.charCodeAt(0),
			ㅁ: '마'.charCodeAt(0),
			ㅂ: '바'.charCodeAt(0),
			ㅃ: '빠'.charCodeAt(0),
			ㅅ: '사'.charCodeAt(0),
		} as { [key: string]: number };
		const begin = con2syl[ch] || (ch.charCodeAt(0) - 12613) /* 'ㅅ'의 코드 */ * 588 + con2syl['ㅅ'];
		const end = begin + 587;
		return `[${ch}\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
	}
	return escapeRegExp(ch);
};

export const korReg = (searchText: string) => {
	const pattern = searchText.split('').map(korSearchPattern).join('.*?');
	return new RegExp(pattern);
};
