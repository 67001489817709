import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - 4.8rem);
	overflow: hidden;
	padding: 0 2.4rem 2.4rem;
	gap: 1.6rem;
`;

export const SectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	border: 1px solid ${(props) => props.theme.colors.gray90};
	border-radius: 0.8rem;
	padding: 1.6rem;
	gap: 0.8rem;
`;

export const LocationContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 1.6rem;
	background-color: ${(props) => props.theme.colors.gray99};
	box-sizing: border-box;
	border-radius: 0.8rem;
`;

export const LabelContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1.6rem;
`;
