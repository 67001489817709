import useCRQuery from 'lib/hook/react-query/base/useCRQuery';
import { endpoint } from 'lib/service/Api/endpoint';

export const useAdviceClientOptions = useCRQuery(
	endpoint.getAdviceClientOptions.key,
	'getAdviceClientOptions',
	{ enabledKey: ['centerId'] },
);

export const useAdviceMethods = useCRQuery(endpoint.getAdviceMethods.key, 'getAdviceMethods');

export const useAdviceTags = useCRQuery(endpoint.getAdviceTags.key, 'getAdviceTags');

export const useAdvice = useCRQuery(endpoint.getAdvice.key, 'getAdvice', {
	enabledKey: 'adviceId',
});

export const useEmployeeAdvices = useCRQuery(endpoint.getEmployeeAdvices.key, 'getEmployeeAdvices');

export const useEmployeeAdvice = useCRQuery(endpoint.getEmployeeAdvice.key, 'getEmployeeAdvice');

export const useRecipientAdvices = useCRQuery(
	endpoint.getRecipientAdvices.key,
	'getRecipientAdvices',
);

export const useRecipientAdvice = useCRQuery(endpoint.getRecipientAdvice.key, 'getRecipientAdvice');

export const useRecipientGuardianAdvice = useCRQuery(
	endpoint.getRecipientGuardianAdvice.key,
	'getRecipientGuardianAdvice',
);
