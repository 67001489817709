import React, { useMemo } from 'react';

import dayjs from 'dayjs';

import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRTab from 'components/base/CRTab';
import { CRText } from 'components/base/CRText';
import RoundingRatioChart from 'components/ui/RoundingRatioChart';
import { roundingCompletionStatusAdapter } from 'lib/adapter/roundingHistory';
import { useCenterList, useMyAccountInfo } from 'lib/hook/react-query';
import { useRoundingCompletionStatus } from 'lib/hook/react-query/query/roundingHistory';

import * as S from './styles';

function RoundingRatePage() {
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: centerData } = useCenterList();
	const {
		data: roundingCompletionStatus,
		isLoading,
		error,
		refetch,
	} = useRoundingCompletionStatus(
		{
			centerId: Number(myAccountInfo?.centerId ?? 0),
		},
		roundingCompletionStatusAdapter,
	);

	const isGasanCenter = useMemo(() => centerData?.[0]?.centerTypeCd !== 'CMN011.20', [centerData]);
	const currentRoundingCompletionStatus = useMemo(
		() =>
			isGasanCenter
				? roundingCompletionStatus
				: roundingCompletionStatus?.filter((item) => item.category === '가족요양'),
		[roundingCompletionStatus],
	);

	const renderError = () => (
		<S.ErrorContainer width={isGasanCenter ? 99 : 32}>
			<CRText text='오류가 발생했습니다.' typography='h3' color='gray60' />
			<CRText text='잠시 후 다시 시도해 주십시오.' typography='body' color='gray60' />
			<CRButton.Default
				type='outlined'
				palette='gray'
				onClick={() => refetch()}
				style={{ marginTop: '0.8rem' }}>
				새로고침
			</CRButton.Default>
		</S.ErrorContainer>
	);

	const renderSkeletons = () => (
		<S.SkeletonRateContainer>
			{Array.from({ length: isGasanCenter ? 3 : 1 }).map(() => (
				<S.SkeletonContainer key={crypto.randomUUID()} />
			))}
		</S.SkeletonRateContainer>
	);

	const renderCharts = () => (
		<S.RateContainer>
			{currentRoundingCompletionStatus?.map((item) => (
				<RoundingRatioChart
					key={item.category}
					title={item.category}
					ratio={item.completionRatio}
					legends={item.legendList}
				/>
			))}
		</S.RateContainer>
	);

	const contentRenderer = useMemo(() => {
		if (error) return renderError();
		if (isLoading) return renderSkeletons();
		return renderCharts();
	}, [error, isLoading, roundingCompletionStatus]);

	return (
		<CRTab.Default
			items={[
				{
					label: RouterPath.roundingManagementTab().roundingRate.label,
					key: RouterPath.roundingManagementTab().roundingRate.key,
					children: (
						<S.Container>
							<S.ContentContainer>
								<CRText text={`${dayjs().format('M월')} 수급자 방문 현황`} typography='h4' />
								{contentRenderer}
							</S.ContentContainer>
						</S.Container>
					),
				},
			]}
			breadCrumb='방문비율'
		/>
	);
}

export default RoundingRatePage;
