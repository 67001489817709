import React, { useEffect, useState } from 'react';

import { v4 } from 'uuid';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRIcon from 'components/base/CRIcon';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import useDialog from 'lib/hook/util/useDialog';
import useIpAddress from 'lib/hook/util/useIpAddress';
import { IPPermitItem } from 'types/view/centerInfo';

import * as S from './styles';

interface Props {
	disabled?: boolean;
	value?: IPPermitItem[];
	onChange?: (items: IPPermitItem[]) => void;
}

export default function CRIpPermitter({ value, onChange, disabled = false }: Props) {
	const { getIpAddress } = useIpAddress();
	const { showDialog } = useDialog();
	const [visible, setVisible] = useState<string[]>([]);

	const handleClickVisible = (id: any) => {
		const isExist = visible.includes(id);
		if (isExist) {
			setVisible(visible.filter((prevId) => prevId !== id));
		} else {
			setVisible([...visible, id]);
		}
	};

	const handleGetIPAddress = async (id: any) => {
		try {
			const response = await getIpAddress();
			if (!response?.ip) throw Error();
			showDialog(({ hideDialog }) => (
				<DefaultDialog
					title='현재 IP 불러오기'
					content={`현재 접속한 IP(${response.ip})를 허가된 IP로 입력합니다.`}
					successOption={{
						text: '불러오기',
						successCallback: () => {
							hideDialog();
							const newItems =
								value?.map((prevItem) =>
									prevItem.id === id
										? {
												...prevItem,
												address: response.ip,
												visible: true,
											}
										: prevItem,
								) || [];
							Toast.success('정상적으로 현재 IP를 불러왔습니다.');
							onChange?.(newItems);
						},
					}}
					cancelOption={{
						text: '취소',
						callback: () => {
							hideDialog();
						},
					}}
					hideDialog={() => {
						hideDialog();
					}}
				/>
			));
		} catch {
			Toast.error('현재 IP 불러오기에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
	};

	const renderText = (item: IPPermitItem, index: number) => {
		if (item.address) {
			if (visible.includes(item.id)) {
				return item.address;
			}
			const address = item.address.split('.');
			const maskedAddress = address.map((item, index) =>
				[0, address.length - 1].includes(index) ? item : '*'.repeat(item.length),
			);
			return maskedAddress.join('.');
		}
		return `허가된 IP ${index + 1} 입력`;
	};

	const handleAddItem = () => {
		onChange?.(
			value?.length
				? value.concat({
						id: v4(),
						address: '',
					})
				: [
						{
							id: v4(),
							address: '',
						},
					],
		);
	};

	const handleDeleteItem = (id: any) => {
		const newItems = value?.filter((item) => item.id !== id) || [];
		onChange?.(newItems);
	};

	useEffect(() => {
		if (!value?.length) {
			onChange?.([
				{
					id: v4(),
					address: '',
				},
			]);
		}
	}, [value, onChange]);

	// useEffect(() => {
	// 	if (value?.length) {
	// 		setIsVisibleMode(
	// 			value.map((item) => ({
	// 				id: item.id,
	// 				visible: false,
	// 			})),
	// 		);
	// 	}
	// }, [value]);
	return (
		<S.Container>
			{value?.map((item, index) => (
				<S.ItemContainer $disabled={disabled}>
					<CRText
						text={renderText(item, index)}
						typography='body'
						color={!item.address || disabled ? 'gray60' : 'gray10'}
					/>
					<S.ButtonContainer>
						<CRIcon
							src={visible.includes(item.id) ? Assets.icon.disabledEye : Assets.icon.removeRedEye}
							onClick={() => handleClickVisible(item.id)}
						/>
						{!disabled && (
							<>
								<CRButton.Default
									size='xSmall'
									palette='gray'
									type='outlined'
									onClick={() => handleGetIPAddress(item.id)}>
									불러오기
								</CRButton.Default>
								{value?.length !== 1 && (
									<CRButton.Default
										size='xSmall'
										palette='primary'
										type='outlined'
										onClick={() => handleDeleteItem(item.id)}>
										삭제
									</CRButton.Default>
								)}
							</>
						)}
					</S.ButtonContainer>
				</S.ItemContainer>
			))}
			{!disabled && (
				<CRButton.IconButton
					style={{
						marginTop: 0,
					}}
					onClick={handleAddItem}
					palette='gray'
					type='tonal'
					size='large'
					iconLeft={Assets.icon.add}>
					허가된 IP 추가
				</CRButton.IconButton>
			)}
		</S.Container>
	);
}
