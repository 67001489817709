import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import { Toast } from 'components/base/CRToast';
import ConsultingForm from 'components/domain/form/ConsultingForm';
import { useCreateAdvice, useMyAccountInfo } from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import { ResponseCode } from 'types/api';
import { AddAdviceFormType } from 'types/view/advice';

import * as S from './styles';

function ConsultingNewPage(): React.ReactElement {
	const navigate = useNavigate();
	const params = useParams<{ id: string }>();
	const { data: myAccountInfo } = useMyAccountInfo();
	const addAdviceMutation = useCreateAdvice((client) => {
		client.invalidateQueries([endpoint.getRecipientAdvices.key]);
		client.invalidateQueries([endpoint.getEmployeeAdvices.key]);
	});

	const [form, setForm] = useState<AddAdviceFormType>({
		date: {
			date: dayjs().format('YYYYMMDD'),
			start: dayjs().format('HH:mm'),
		},
		client: {
			label: '',
			value: params.id,
		},
		method: undefined,
		tag: undefined,
		advisorOpinion: '',
		managerOpinion: '',
		files: [],
	});

	const disabled = useMemo(
		() =>
			!(
				form.date?.date &&
				form.date.start &&
				form.client &&
				form.method &&
				form.tag &&
				form.advisorOpinion &&
				form.managerOpinion
			),
		[form],
	);

	const handleChangeForm = (form: AddAdviceFormType) => {
		setForm(form);
	};

	const handleSubmitAdvice = async () => {
		if (!myAccountInfo) return;

		const date = form.date?.date ?? '00000000';
		const start = form.date?.start ?? '00:00:00';

		const res = await addAdviceMutation.mutateAsync({
			consultDate: dayjs(
				`${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)} ${start}`,
			).format('YYYY-MM-DDTHH:mm:ss'),
			writerId: Number(myAccountInfo?.memberAccountId),
			consultMethodCd: form.method?.value,
			attachFile: form.files?.length
				? {
						fileDetails: form.files,
					}
				: undefined,
			clientDivCd: form.client?.data?.clientDivCd ?? '',
			clientId: form.client?.data?.clientId ?? 0,
			recipientId: form.client?.data?.recipientId,
			centerId: myAccountInfo.centerId,
			consultDesc: form.advisorOpinion ?? '',
			consultActionDesc: form.managerOpinion ?? '',
			consultTagId: Number(form.tag?.value) ?? 0,
		});
		if (res.code !== ResponseCode.SUCCESS) {
			Toast.error('상담 등록을 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			return;
		}
		Toast.success('정상적으로 상담이 등록 되었습니다.');
		navigate(-1);
	};

	return (
		<S.Container>
			<S.BodyContainer>
				<S.ContentContainer>
					<S.ContentHeaderContainer>
						<S.Title>신규 상담</S.Title>
					</S.ContentHeaderContainer>
					<S.ContentBodyContainer>
						<ConsultingForm form={form} onChange={handleChangeForm} />
					</S.ContentBodyContainer>
				</S.ContentContainer>
			</S.BodyContainer>
			<S.FooterContainer>
				<S.ButtonContainer>
					<CRButton.Default palette='gray' type='tonal' onClick={() => navigate(-1)}>
						취소
					</CRButton.Default>
					<CRButton.Default disabled={disabled} onClick={handleSubmitAdvice}>
						저장
					</CRButton.Default>
				</S.ButtonContainer>
			</S.FooterContainer>
		</S.Container>
	);
}

export default ConsultingNewPage;
