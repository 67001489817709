import React from 'react';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRSpinner from 'components/base/CRSpinner';
import { Toast } from 'components/base/CRToast';
import CenterAccountRecordSummaryTable from 'components/domain/table/CenterAccountRecordSummaryTable';
import { useCenterAccountRecordSummarys } from 'lib/hook/react-query/query/centerAccount';
import { ResponseCode } from 'types/api';
import { GetCenterAccountRecordSummaryRequestDTO } from 'types/api/centerAccount';

interface Props extends GetCenterAccountRecordSummaryRequestDTO {
	onClose: () => void;
}

function CenterAccountRecordSummarysDialog({
	onClose,
	centerAccountIds,
	recordEndDt,
	recordStartDt,
}: Props) {
	const { data, isLoading } = useCenterAccountRecordSummarys({
		centerAccountIds,
		recordEndDt,
		recordStartDt,
	});
	if (isLoading) {
		return <CRSpinner />;
	}
	if (data?.code !== ResponseCode.SUCCESS) {
		Toast.error('계좌 요악정보를 불러올 수 없습니다.');
		onClose();
		return null;
	}

	return (
		<CRDialog
			onClickClose={onClose}
			title='센터 계좌 요약'
			showCloseButton
			type='M'
			body={<CenterAccountRecordSummaryTable items={data?.data || []} />}
			footer={
				<FlexContainer gap='0.8rem'>
					<CRButton.Default type='text' palette='gray' onClick={onClose}>
						취소
					</CRButton.Default>
					<CRButton.Default onClick={onClose}>확인</CRButton.Default>
				</FlexContainer>
			}
		/>
	);
}

export default CenterAccountRecordSummarysDialog;
