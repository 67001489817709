import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { v4 } from 'uuid';

import CRBanner from 'components/base/CRBanner';
import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRStatus from 'components/base/CRStatus';
import { Toast } from 'components/base/CRToast';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { TAG_STATUS_CONFIG } from 'components/domain/table/TagErrorTable/constant';
import InformationTable from 'components/ui/InformationTable';
import { TagDetail } from 'lib';
import { useUpdateScheduleTagErrorHistoryDetail } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import {
	ScheduleTagErrorHistoryDTO,
	UpdateScheduleTagErrorHistoryDetailRequest,
} from 'types/api/schedule';
import { TagErrorDetailFormType } from 'types/view/schedule';

import * as S from './styles';

interface Props {
	title?: string;
	item: ScheduleTagErrorHistoryDTO;
	onRefresh?: () => void;
}

export function TagDetailDialog({ title = '', item, onRefresh }: Props) {
	const {
		control,
		trigger,
		handleSubmit,
		reset,
		formState: { isValid },
	} = useForm<TagErrorDetailFormType>({
		resolver: yupResolver(TagDetail),
		mode: 'onChange',
	});

	const { hideDialog } = useDialog();

	const { mutate: updateTagErrorHistoryDetail } = useUpdateScheduleTagErrorHistoryDetail(
		(client, returnData) => {
			if (returnData === 1) {
				Toast.success('저장하였습니다.');
				onRefresh?.();
			} else {
				Toast.error('저장에 실패하였습니다.');
			}
			hideDialog();
		},
	);

	const sendStatus = item?.sendRecordStatus ?? '발송전';

	const onSubmit = (data: TagErrorDetailFormType) => {
		const params: UpdateScheduleTagErrorHistoryDetailRequest = {
			id: item.id,
			tagErrYn: data.errorYN[0].value,
			planStart: dayjs(`${item.serviceYmd} ${data.scheduleTime.startTime}:00`).format(
				'YYYY-MM-DD HH:mm:ss',
			),
			planEnd: dayjs(`${item.serviceYmd} ${data.scheduleTime.endTime}:00`).format(
				'YYYY-MM-DD HH:mm:ss',
			),
		};
		if (data.rfidTime.startTime && data.rfidTime.endTime) {
			params.rfidStart = dayjs(`${item.serviceYmd} ${data.rfidTime.startTime}:00`).format(
				'YYYY-MM-DD HH:mm:ss',
			);
			params.rfidEnd = dayjs(`${item.serviceYmd} ${data.rfidTime.endTime}:00`).format(
				'YYYY-MM-DD HH:mm:ss',
			);
		}

		updateTagErrorHistoryDetail(params);
	};

	const submitForm = () => {
		trigger().then((isValid) => {
			if (isValid) {
				handleSubmit(onSubmit)();
			}
		});
	};

	useEffect(() => {
		reset({
			errorYN: [
				{
					label: item.tagErrYn ? '오류' : '정상',
					value: item.tagErrYn,
				},
			],
			scheduleTime: {
				startTime: item.plan.length === 11 ? item.plan.split('~')[0] : undefined,
				endTime: item.plan.length === 11 ? item.plan.split('~')[1] : undefined,
			},
			rfidTime: {
				startTime: item.rfid.length === 11 ? item.rfid.split('~')[0] : undefined,
				endTime: item.rfid.length === 11 ? item.rfid.split('~')[1] : undefined,
			},
		});
	}, [item]);

	// 발송전 서명 취소 수정가능, 상태오류
	const isEditable = useMemo(
		() => !!['발송전', '서명 취소', '상태 오류', '', '완료'].includes(sendStatus),
		[sendStatus],
	);

	return (
		<CRDialog
			title='태그 상세'
			onClickClose={hideDialog}
			body={
				<S.Container>
					<CRInputLabel label='기존 태그 정보'>
						<InformationTable
							items={[
								[
									{
										label: '일자',
										value: item?.serviceYmd ? dayjs(item.serviceYmd).format('YYYY.MM.DD') : '-',
									},
								],
								[
									{
										label: '수급자',
										value: item?.recipientNm || '-',
									},
								],
								[
									{
										label: '급여종류',
										value: item?.serviceTypeNm || '-',
									},
								],
								[
									{
										label: '직원',
										value: item?.employeeNm || '-',
									},
								],
								[
									{
										label: '오류여부',
										value: (
											<CRStatus options={TAG_STATUS_CONFIG}>
												{item?.tagErrYn ? '오류' : '정상'}
											</CRStatus>
										),
									},
								],
								[
									{
										label: '일정계획 시간',
										value: item?.plan || '-',
									},
								],
								[
									{
										label: 'RFID 시간',
										value: item?.rfid || '-',
									},
								],
							]}
						/>
					</CRInputLabel>
					{!isEditable && sendStatus !== '상태 오류' && (
						<CRBanner type='warning' content='서명중인 기록지의 태그 정보는 수정할 수 없습니다.' />
					)}
					{sendStatus === '상태 오류' && <CRBanner type='error' content='상태 오류 메시지' />}
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='오류 여부'>
								<CRCheckBoxGroup
									value={value}
									onChange={onChange}
									checkType='single'
									type='radio'
									appearanceType='button'
									gap={0.8}
									options={[
										{
											label: '정상',
											value: false,
											disabled: !isEditable,
										},
										{
											label: '오류',
											value: true,
											disabled: !isEditable,
										},
									]}
								/>
							</CRInputLabel>
						)}
						name='errorYN'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='일정 계획 시간'>
								<CRInput.DayTimePicker
									showDays={false}
									disabled={!isEditable}
									currentValue={{
										id: v4(),
										days: [],
										startTime: value?.startTime || '',
										endTime: value?.endTime || '',
									}}
									onChange={onChange}
								/>
							</CRInputLabel>
						)}
						name='scheduleTime'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='RFID 시간'>
								<CRInput.DayTimePicker
									showDays={false}
									disabled={!isEditable}
									currentValue={{
										id: v4(),
										days: [],
										startTime: value?.startTime || '',
										endTime: value?.endTime || '',
									}}
									onChange={onChange}
								/>
							</CRInputLabel>
						)}
						name='rfidTime'
						control={control}
					/>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						palette='primary'
						size='default'
						disabled={!isValid || !isEditable}
						onClick={submitForm}>
						저장
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
