import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

import { ko } from 'date-fns/esm/locale';
import dayjs from 'dayjs';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import { FilterType } from 'types/view/filter';

import * as S from './styles';

interface IProps<T extends { label: string; value: any }> {
	currentValue?: T[];
	placeholder?: string;
	filterKey?: string;
	onChangeValue?: (item: T, key: string, filterType: FilterType) => void;
	minDate?: Date;
	maxDate?: Date;
}

function DayCalendarChip<T extends { label: string; value: any }>({
	currentValue = [],
	placeholder = '',
	filterKey = '',
	minDate,
	maxDate,
	onChangeValue,
}: IProps<T>): React.ReactElement {
	const nodeRef = useRef<HTMLDivElement>(null);
	const [showOptions, setShowOptions] = useState(false);
	const [date, setDate] = useState<Date | null>(currentValue?.[0]?.value ?? null);
	const [dateString, setDateString] = useState('');

	const filterRef = useRef<HTMLDivElement>(null);
	const datePickerRef = useRef<HTMLDivElement>(null);

	const isActive = useMemo(() => currentValue?.[0]?.value, [currentValue]);

	const renderText = () => {
		let label = '';
		if (currentValue?.length && currentValue[0]?.value) {
			const result = `${placeholder} : ${dayjs(currentValue[0].value).format('YYYY.MM.DD')}`;
			label = result;
		} else {
			label = placeholder;
		}
		return label;
	};

	const onClickInit = (e: MouseEvent) => {
		e.stopPropagation();
		onChangeValue?.(
			{
				label: filterKey,
				value: undefined,
			} as T,
			filterKey,
			'day_calendar',
		);
		setDate(null);
		setDateString('');
	};

	const handleToggleOptions = () => {
		setShowOptions((prev) => !prev);
	};

	const onSelectDate = (selectedDate: any) => {
		setDate(selectedDate);
		setDateString(selectedDate ? dayjs(selectedDate).format('YYYY.MM.DD') : '');
	};

	const onChangeDate = (value: string) => {
		const result = value.replaceAll('.', '');
		setDateString(value);
		if (result.length === 8) {
			const isValid = dayjs(result).isValid() && dayjs(result).isAfter('2020.12.31');
			if (isValid) {
				setDate(dayjs(result).toDate());
			}
		}
	};

	const isValidDate = useMemo(() => dayjs(date).isValid() ?? false, [date]);

	const onClickApply = () => {
		if (isValidDate) {
			onChangeValue?.(
				{
					label: filterKey,
					value: date,
				} as T,
				filterKey,
				'month_calendar',
			);
			setShowOptions(false);
		}
	};

	const renderCalendarPopup = () => (
		<S.PopupContainer ref={nodeRef}>
			<S.CustomHeader>
				<S.Range>
					<CRInput.Default
						maxLength={10}
						onChange={(value) => onChangeDate(value)}
						placeholder='예시) 20230922'
						value={dateString}
					/>
				</S.Range>
			</S.CustomHeader>
			<S.CalendarContainer ref={datePickerRef}>
				<DatePicker
					locale={ko}
					selected={date}
					onChange={(selectedDate) => onSelectDate(selectedDate)}
					shouldCloseOnSelect={false}
					minDate={minDate}
					maxDate={maxDate}
					startDate={minDate}
					endDate={maxDate}
					inline
					renderDayContents={(e) => <S.CustomDay>{e}</S.CustomDay>}
					renderCustomHeader={({ date, increaseMonth, decreaseMonth }) => (
						<>
							<S.CalendarNavigation>
								<S.Month>{dayjs(date).format('YYYY년 M월')}</S.Month>
								<S.NavButtonContainer>
									<S.Button src={Assets.icon.keyboardArrowLeft} onClick={decreaseMonth} />
									<S.Button src={Assets.icon.keyboardArrowRight} onClick={increaseMonth} />
								</S.NavButtonContainer>
							</S.CalendarNavigation>
							<S.CustomCaption>
								{['일', '월', '화', '수', '목', '금', '토'].map((dayText) => (
									<S.CaptionText key={dayText}>{dayText}</S.CaptionText>
								))}
							</S.CustomCaption>
						</>
					)}
				/>
			</S.CalendarContainer>
			<S.ButtonSection>
				<CRButton.Default type='text' palette='gray' onClick={handleToggleOptions}>
					취소
				</CRButton.Default>
				<CRButton.Default
					type='filled'
					palette='primary'
					disabled={!isValidDate}
					onClick={onClickApply}>
					적용
				</CRButton.Default>
			</S.ButtonSection>
		</S.PopupContainer>
	);

	// useLayoutEffect(() => {
	// 	if (value) {
	// 		setDate(value);
	// 		setDateString(value ? dayjs(value).format('YYYY.MM.DD') : '');
	// 		setResult(value ? dayjs(value).format('YYYY.MM.DD') : '');
	// 	}
	// }, [value]);

	useEffect(() => {
		const handleMouseOut = (e: MouseEvent) => {
			if (!filterRef.current?.contains(e.target as Node)) {
				setShowOptions(false);
				setDate(null);
				setDateString('');
			}
		};
		document.addEventListener('mousedown', handleMouseOut as any);

		return () => {
			document.removeEventListener('mousedown', handleMouseOut as any);
		};
	}, []);

	useEffect(() => {
		if (!showOptions) {
			setDate(null);
		} else if (currentValue?.[0]?.value) {
			setDate(currentValue?.[0].value);
		}
	}, [showOptions]);

	return (
		<S.Container ref={filterRef}>
			<S.DefaultOption
				$showOptions={showOptions}
				$isActive={isActive}
				onClick={handleToggleOptions}>
				<S.ChipLabel>{renderText()}</S.ChipLabel>
				{isActive ? (
					<S.CloseIconContainer onClick={onClickInit}>
						<img src={Assets.icon.closeCircle} alt='keyboardArrowBottom' />
					</S.CloseIconContainer>
				) : (
					<S.ArrowIconContainer src={Assets.icon.keyboardArrowBottom} alt='keyboardArrowBottom' />
				)}
			</S.DefaultOption>

			{showOptions && renderCalendarPopup()}
		</S.Container>
	);
}

export default DayCalendarChip;
