import React from 'react';
import { useParams } from 'react-router';

import dayjs from 'dayjs';
import { v4 } from 'uuid';

import CRAdvertisingBanner, { DefaultBanners } from 'components/base/CRAdvertisingBanner';
import CRCalendarSmall from 'components/base/CRCalendarSmall';
import CRSpinner from 'components/base/CRSpinner';
import CRTaskBadge from 'components/base/CRTaskBadge';
import CRTaskList from 'components/base/CRTaskList';
import ScheduleClientMobileHeader from 'components/ui/Schedule/ScheduleClientMobileHeader';
import { displayPadTime } from 'lib';
import { ClientScheduleAdapter } from 'lib/adapter/schedule';
import { useClientScheduleEmployee } from 'lib/hook/react-query';
import { Schedule } from 'types/api/schedule';

function ScheduleClientEmployeePage() {
	const params = useParams();
	const { data: employeeScheduleGroupByDate } = useClientScheduleEmployee(
		{ sendUuid: params.id as string },
		ClientScheduleAdapter,
	);

	if (!employeeScheduleGroupByDate) {
		return <CRSpinner />;
	}

	// 가장 일정이 많은날의 일정갯수.
	// 달력 한 칸의 사이즈를 모두 동일하게 해주기 위해서 계산
	const maxScheduleCount = Math.max(
		...Object.values(employeeScheduleGroupByDate).map((schedules) => schedules.length),
	);

	// 달력에 렌더링 시킬 데이터,
	// 일자별로 그룹핑한 데이터를 사용.
	// 날짜와 렌더링할 react노드가 item으로 들어감.

	const calendarItems = Object.entries(employeeScheduleGroupByDate).map(([date, schedules]) => {
		// 위에서 구한 가장 일정이 많은 날만큼 사이즈를 고정하기위해
		// 부족한 일정의 수는 비어있는 뱃지로 렌더링
		const item = (
			<>
				{schedules.map(({ serviceKindNm }: Schedule) => {
					const serviceType = serviceKindNm.substring(serviceKindNm.length - 2);
					return <CRTaskBadge key={v4()} type={serviceType} />;
				})}
				{Array.from({ length: maxScheduleCount - schedules.length })
					.fill(null)
					.map((_) => (
						<CRTaskBadge key={v4()} />
					))}
			</>
		);
		return {
			date,
			item,
		};
	});

	// 급여의 일정 목록
	// 일자별로 그룹핑한 데이터를 사용.
	const serviceTaskList = Object.entries(employeeScheduleGroupByDate).flatMap(([date, schedules]) =>
		schedules.map((schedule) => ({
			date,
			item: {
				startTime: displayPadTime(schedule.serviceStartTime?.substring(0, 4)),
				endTime: displayPadTime(schedule.serviceEndTime?.substring(0, 4)),
				type: schedule.serviceKindNm.substring(schedule.serviceKindNm.length - 2),
				description: `${schedule.recipientNm} 수급자`,
			},
		})),
	);

	const oneScheduleData = Object.values(employeeScheduleGroupByDate)?.[0]?.[0] || {};
	const { employeeNm, serviceStartDt } = oneScheduleData;

	const month = dayjs(calendarItems[0]?.date).toDate();

	// 10월 일정표 발송 전 까지 극동대 배너 임시 노출(9.24~ 10.25)
	const banners =
		dayjs().isAfter('2024-09-23', 'day') && dayjs().isBefore('2024-10-26', 'day')
			? [
					{
						id: 'open_kdu',
						imageUrl:
							'https://caring-static-public.s3.ap-northeast-2.amazonaws.com/images/banner_kdu.png',
						link: 'https://gdun.modoo.at/?link=9wp2iiwo',
						alt: '극동 대학교 입학안내 이미지',
					},
					...DefaultBanners,
				]
			: DefaultBanners;

	return (
		<>
			{employeeNm && serviceStartDt && (
				<ScheduleClientMobileHeader
					name={employeeNm}
					yearMonth={dayjs(serviceStartDt).format('YY년 MM월')}
				/>
			)}
			<CRCalendarSmall onChange={(e) => {}} date={month} items={calendarItems} />
			<CRAdvertisingBanner banners={banners} />
			<CRTaskList tasks={serviceTaskList} />
		</>
	);
}

export default ScheduleClientEmployeePage;
