import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { Toast } from 'components/base/CRToast';
import {
	useFullTimeEmployeeCrimeHistories,
	useSaveFullTimeEmployeeCrimeHistory,
} from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import { FullTimeEmployeeCrimeHistoryDTO, FullTimeEmployeeDTO } from 'types/dto/full-time-employee';

import * as S from './styles';

interface Props {
	hideDialog: () => void;
	currentFullTimeEmployee?: FullTimeEmployeeDTO;
}

export default function CheckFullTimeEmployeeCriminalRecord({
	currentFullTimeEmployee,
	hideDialog,
}: Props): React.ReactElement {
	const {
		control,
		handleSubmit,
		setValue,
		formState: { isValid },
	} = useForm<FullTimeEmployeeCrimeHistoryDTO>({
		mode: 'onChange',
	});

	const { data: fullTImeEmployeeCrimeHistories } = useFullTimeEmployeeCrimeHistories({
		centerId: currentFullTimeEmployee?.centerId,
		employeeId: currentFullTimeEmployee?.employeeId,
		memberId: currentFullTimeEmployee?.memberId,
	});

	const saveFullTimeEmployeeCrimeHistory = useSaveFullTimeEmployeeCrimeHistory(
		(client, returnData) => {
			if (!returnData) {
				Toast.success('저장에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
				hideDialog();
				return;
			}
			client.invalidateQueries([
				endpoint.getFullTimeEmployeeCrimeHistories.key,
				{
					centerId: currentFullTimeEmployee?.centerId,
					employeeId: currentFullTimeEmployee?.employeeId,
					memberId: currentFullTimeEmployee?.memberId,
				},
			]);
			client.invalidateQueries([
				endpoint.getFullTimeEmployeeDetailInfo.key,
				{
					centerId: currentFullTimeEmployee?.centerId,
					employeeId: currentFullTimeEmployee?.employeeId,
					memberId: currentFullTimeEmployee?.memberId,
				},
			]);

			Toast.success('정상적으로 저장하였습니다.');
			hideDialog();
		},
	);

	const onSubmit = async (data: FullTimeEmployeeCrimeHistoryDTO) => {
		if (!currentFullTimeEmployee) return;

		await saveFullTimeEmployeeCrimeHistory.mutateAsync({
			...data,
			centerId: currentFullTimeEmployee?.centerId,
			employeeId: currentFullTimeEmployee?.employeeId,
		});
	};

	useEffect(() => {
		if (fullTImeEmployeeCrimeHistories) {
			setValue('crimeHistSearchDt', fullTImeEmployeeCrimeHistories.crimeHistSearchDt);
			setValue('crimeHistReportIssueNo', fullTImeEmployeeCrimeHistories.crimeHistReportIssueNo);
		}
	}, [fullTImeEmployeeCrimeHistories]);

	return (
		<CRDialog
			onClickClose={hideDialog}
			type='S'
			title='범죄경력 조회 확인'
			body={
				<S.Form onSubmit={handleSubmit(onSubmit)}>
					<S.Description>범죄경력 회보서를 발급받고 아래 정보를 입력해주십시오.</S.Description>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel isRequired label='조회일'>
								<CRInput.DatePicker
									placeholder='날짜 선택'
									onChangeValue={(item) => onChange(dayjs(item).format('YYYYMMDD'))}
									value={value ? dayjs(value).toDate() : undefined}
								/>
							</CRInputLabel>
						)}
						name='crimeHistSearchDt'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, value, ref }, formState: { errors } }) => (
							<CRInputLabel label='회보서 발급번호' isRequired>
								<CRInput.Default
									onChange={onChange}
									value={value ?? ''}
									placeholder='회보서 발급번호 입력'
								/>
							</CRInputLabel>
						)}
						name='crimeHistReportIssueNo'
						control={control}
					/>
				</S.Form>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						disabled={!isValid}
						palette='primary'
						size='default'
						onClick={handleSubmit(onSubmit)}>
						저장
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
