import React from 'react';

import Radius from 'common/radius';
import CRButton from 'components/base/CRButton';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import { CheckOption } from 'components/base/Selections/type';
import AddCenterAccountDialog from 'components/domain/dialog/AddCenterAccountDialog';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import { useDeleteCenterAccount } from 'lib/hook/react-query/mutation/accountManagement';
import useDialog from 'lib/hook/util/useDialog';
import { ResponseCode } from 'types/api';
import { CenterAccountManagementContentDTO } from 'types/api/accountManagement';

import * as S from './styles';

interface Props {
	items: CenterAccountManagementContentDTO[];
	isCenterChief: boolean;
	refetch: () => void;
	bankOptions?: CheckOption[];
}

export default function CenterAccountManagementTable({
	items,
	bankOptions,
	refetch,
	isCenterChief,
}: Props) {
	const { showDialog, hideDialog } = useDialog();

	const { mutate: deleteCenterAccount } = useDeleteCenterAccount((client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			hideDialog();
			Toast.success(`정상적으로 계좌 삭제를 완료하였습니다.`);
			refetch();
		} else {
			Toast.error(`계좌 삭제에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.`);
		}
	});

	const handleClickUpdateAccount = (item: CenterAccountManagementContentDTO) => {
		if (!bankOptions?.length) return;
		showDialog(() => (
			<AddCenterAccountDialog
				item={item}
				bankOptions={bankOptions}
				hideDialog={hideDialog}
				onRefresh={refetch}
			/>
		));
	};

	const handleClickDelete = (item: CenterAccountManagementContentDTO) => {
		if (!item) return;
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='계좌 삭제'
				hideDialog={hideDialog}
				content={`${item.centerAccountAliasNm} (${item.centerAccountNo}) 계좌를 삭제하시겠습니까?`}
				cancelOption={{
					text: '취소',
					callback: hideDialog,
				}}
				successOption={{
					text: '삭제',
					type: 'outlined',
					successCallback: () => {
						if (item.centerAccountId) {
							deleteCenterAccount({
								centerAccountId: item.centerAccountId,
							});
						}
					},
				}}
			/>
		));
	};

	return (
		<S.Container>
			<S.TableRow
				style={{
					position: 'sticky',
					top: 0,
					zIndex: 1,
				}}>
				<S.TableHeaderColumn
					style={{
						width: '5.6rem',
					}}
				/>
				<S.TableHeaderColumn
					style={{
						width: '20rem',
					}}>
					계좌 별칭
				</S.TableHeaderColumn>
				<S.TableHeaderColumn
					style={{
						width: '24rem',
					}}>
					계좌 번호
				</S.TableHeaderColumn>
				<S.TableHeaderColumn
					style={{
						width: '12rem',
					}}>
					은행
				</S.TableHeaderColumn>
				<S.TableHeaderColumn
					style={{
						flex: 1,
					}}
				/>
				{isCenterChief && (
					<S.TableHeaderColumn
						style={{
							width: '16rem',
						}}
					/>
				)}
			</S.TableRow>
			{items?.map((item) => (
				<S.TableRow>
					<S.TableBodyColumn
						style={{
							width: '5.6rem',
						}}
					/>
					<S.TableBodyColumn
						style={{
							width: '20rem',
						}}>
						<RDTooltip content={item.centerAccountAliasNm}>
							<CRText
								style={{
									whiteSpace: 'nowrap',
								}}
								typography='labelB'
								text={item.centerAccountAliasNm || '-'}
								color='gray10'
							/>
						</RDTooltip>
					</S.TableBodyColumn>
					<S.TableBodyColumn
						style={{
							width: '24rem',
						}}>
						<RDTooltip content={item.centerAccountNo}>
							<CRText
								style={{
									whiteSpace: 'nowrap',
								}}
								text={item?.centerAccountNo || '-'}
								color='gray10'
								typography='label'
							/>
						</RDTooltip>
					</S.TableBodyColumn>
					<S.TableBodyColumn
						style={{
							width: '12rem',
						}}>
						<CRText text={item?.centerAccountBankNm || '-'} color='gray10' typography='label' />
					</S.TableBodyColumn>
					<S.TableBodyColumn
						style={{
							flex: 1,
						}}
					/>
					{isCenterChief && (
						<S.TableBodyColumn
							style={{
								width: '16rem',
								display: 'flex',
								gap: '0.8rem',
							}}>
							<CRButton.Default
								size='xSmall'
								palette='gray'
								type='outlined'
								style={{
									borderRadius: Radius.radius04,
								}}
								onClick={() => handleClickUpdateAccount(item)}>
								수정
							</CRButton.Default>
							<CRButton.Default
								size='xSmall'
								palette='primary'
								type='outlined'
								style={{
									borderRadius: Radius.radius04,
								}}
								onClick={() => handleClickDelete(item)}>
								삭제
							</CRButton.Default>
						</S.TableBodyColumn>
					)}
				</S.TableRow>
			))}
		</S.Container>
	);
}
