import React from 'react';
import { Controller } from 'react-hook-form';

import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { ServiceTypeCd } from 'components/domain/table/RecordingSheetTable/serviceEdocParam';
import useWorkExecuteLog from 'lib/hook/view/workExecuteLog/useWorkExecuteLog';

import * as S from './styles';

interface Props {
	disabled: boolean;
}

function WorkExecuteLogCheckForm({ disabled }: Props): React.ReactElement {
	const { form, commonCodes } = useWorkExecuteLog();

	const { serviceTypeCd } = form.watch();

	const isVisibleEmployee2 = serviceTypeCd
		? serviceTypeCd?.[0]?.value === ServiceTypeCd.방문목욕
		: false;

	return (
		<S.Container>
			<Controller
				render={({ field: { onChange, value, ref, onBlur } }) => (
					<CRInputLabel label='확인 내용' type='left'>
						<CRInput.TextArea
							disabled={disabled}
							fixedHeight
							ref={ref}
							height='14rem'
							maxLength={2000}
							placeholder='확인 내용 입력'
							onChange={onChange}
							onBlur={onBlur}
							value={value}
						/>
					</CRInputLabel>
				)}
				name='salaryrecogActpgOfferConfirmDesc'
				control={form?.control}
			/>
			<Controller
				render={({ field: { onChange, value, ref, onBlur } }) => (
					<CRInputLabel label='조치 사항' type='left'>
						<CRInput.TextArea
							disabled={disabled}
							fixedHeight
							ref={ref}
							height='14rem'
							maxLength={2000}
							placeholder='조치사항 입력'
							onChange={onChange}
							onBlur={onBlur}
							value={value}
						/>
					</CRInputLabel>
				)}
				name='salaryrecogActpgOfferActionDesc'
				control={form?.control}
			/>
			<CRInputLabel type='left-sub' label='급여제공자' betweenGap='3.6rem' showOverflow>
				<div
					style={{
						width: '46.4rem',
					}}>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<div
								style={{
									width: '46.4rem',
								}}>
								<CRInput.SearchSelector
									disabled={disabled}
									currentValue={value}
									items={commonCodes?.employeesOptions}
									onChange={onChange}
									placeholder='급여제공자1 선택'
								/>
							</div>
						)}
						name='employee1'
						control={form?.control}
					/>
					{isVisibleEmployee2 && (
						<Controller
							render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
								<div
									style={{
										width: '46.4rem',
									}}>
									<CRInput.SearchSelector
										disabled={disabled}
										currentValue={value}
										items={commonCodes?.employeesOptions}
										onChange={onChange}
										placeholder='급여제공자2 선택'
									/>
								</div>
							)}
							name='employee2'
							control={form?.control}
						/>
					)}
				</div>
			</CRInputLabel>
		</S.Container>
	);
}

export default React.memo(WorkExecuteLogCheckForm);
