import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';

import Assets from 'assets';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRIcon from 'components/base/CRIcon';
import CRProgressBar from 'components/base/CRProgressBar';
import { CRText } from 'components/base/CRText';
import CRSwitch from 'components/base/Selections/CRSwitch';
import { IntegratedAssessmentDesireForms } from 'types/view/recipient';

import * as S from './styles';

interface Props {
	setGenerateOpinionMode: (arg: boolean) => void;
	generateOpinionMode: boolean;
	parameterFormatter: (arg: IntegratedAssessmentDesireForms) => { [k: string]: any };
	setGenerateOpinionEmptyField: Dispatch<SetStateAction<string | undefined>>;
	form: any;
}

function GenerateOpinionSampleHelper({
	setGenerateOpinionMode,
	generateOpinionMode,
	parameterFormatter,
	setGenerateOpinionEmptyField,
	form,
}: Props) {
	const value = useWatch<IntegratedAssessmentDesireForms>({ control: form.control });
	const [percent, setPercent] = useState<number>(0.02);
	const [requiredField, setRequiredField] = useState<string>();

	const param = parameterFormatter(value as IntegratedAssessmentDesireForms);

	useEffect(() => {
		const checkRequireFields = [
			'수급자정보.키',
			'수급자정보.몸무게',
			'일반상태.영양상태',
			'일반상태.식사형태',
			'일반상태.연하상태',
			'일반상태.구강상태',
			'일반상태.소변상태',
			'일반상태.대변상태',
			'일반상태.보행',
			'일반상태.시력상태',
			'일반상태.청력상태',
			'일반상태.판단근거',
			'주요질병상태.판단근거',
			// '신체상태',
			'신체상태.판단근거',
			'재활상태.판단근거',
			'간호처치상태.판단근거',
			'인지상태.판단근거',
			'의사소통.판단근거',
			'가족및지지체계.자녀.아들',
			'가족및지지체계.자녀.딸',
			'가족및지지체계.진료병원.정기진료',
			'가족및지지체계.판단근거',
			'주거환경상태.난방',
			'주거환경상태.환기',
			'주거환경상태.문턱',
			'주거환경상태.실내계단',
			'주거환경상태.실외계단',
			'주거환경상태.화장실',
			'주거환경상태.좌변기',
			'주거환경상태.온수',
			'주거환경상태.욕조여부',
			'주거환경상태.세면대여부',
			'주거환경상태.주방',
			'주거환경상태.판단근거',
			'복지용구.판단근거',
			'수급자및보호자개별욕구.판단근거',
		];

		const fieldsNameMap = {
			수급자정보: '수급자 정보',
			일반상태: '1. 일반상태',
			주요질병상태: '2. 주요질병상태',
			신체상태: '3. 신체상태',
			재활상태: '4. 재활상태',
			간호처치상태: '5.간호처치상태',
			인지상태: '6. 인지상태',
			의사소통: '7. 의사소통',
			가족및지지체계: '8. 가족 및 지지체계',
			주거환경상태: '9. 주거환경상태',
			복지용구: '10. 복지용구',
			수급자및보호자개별욕구: '11. 수급자 및 보호자 개별 욕구',
		};
		const emptyFields = checkRequireFields.filter((field) => {
			let paramValue = param;

			const splitFields = field.split('.');
			return !splitFields.every((key, index) => {
				// console.log('key: ', key);
				paramValue = paramValue[key];

				// 마지막 참조 값이 객체인 경우 value를 확인함.
				if (
					paramValue &&
					typeof paramValue === 'object' &&
					splitFields.length - 1 === index &&
					Object.values(paramValue)?.length
				) {
					return Object.values(paramValue).some(Boolean);
				}
				// 예외케이스
				if (field === '가족및지지체계.진료병원.정기진료' && key === '정기진료') {
					return value['정기진료'] !== undefined;
				}

				if (field === '가족및지지체계.자녀.아들' && key === '아들') {
					return value['자녀수아들'] !== undefined && value['자녀수아들'] !== '';
				}
				if (field === '가족및지지체계.자녀.딸' && key === '딸') {
					return value['자녀수딸'] !== undefined && value['자녀수딸'] !== '';
				}
				return Boolean(paramValue);
			});
		});

		const currentPercent = Math.max(
			0.02,
			((checkRequireFields?.length || 0) - (emptyFields?.length || 0)) /
				(checkRequireFields?.length || 1),
		);
		setPercent(currentPercent);

		if (emptyFields?.length > 0) {
			// 파라미터의 이름을 사회복지사가 알아보기 쉬운 인덱스가 포함된 이름으로 수정하는 작업
			let fieldName = emptyFields[0].replaceAll('.', ' - ');
			Object.entries(fieldsNameMap).forEach(([key, value]) => {
				if (fieldName.includes(key)) {
					fieldName = fieldName.replace(key, value);
				}
			});

			// 스크롤 스파이의 ID를 특정하기 위해 인덱스의 번호를 파싱해서 +1을 해줌.
			const sectionId = fieldName?.split('.')?.[0];
			if (sectionId) {
				const id = Number(sectionId);
				// 수급자 정보는 인덱스가 없어서 예외처리함
				if (Number.isNaN(id)) {
					setGenerateOpinionEmptyField(`#1`);
				} else {
					setGenerateOpinionEmptyField(`#${id + 1}`);
				}
			} else {
				setGenerateOpinionEmptyField(undefined);
			}
			setRequiredField(fieldName);
		} else {
			setRequiredField(undefined);
			setGenerateOpinionEmptyField(undefined);
		}
	}, [param, value]);

	return (
		<FlexContainer direction='column' gap='1.6rem' padding='0 0 1rem 0'>
			<S.BorderContainer>
				<FlexContainer justify='space-between' align='center'>
					<CRText
						typography='label'
						text={'종합 의견 샘플 생성\n필수입력 보기'}
						style={{ textAlign: 'left' }}
					/>
					<CRSwitch onChange={setGenerateOpinionMode} value={generateOpinionMode} />
				</FlexContainer>
			</S.BorderContainer>
			{generateOpinionMode && (
				<S.BorderContainer>
					<FlexContainer direction='column' gap='0.8rem'>
						<FlexContainer align='center' gap='0.8rem'>
							<CRIcon src={Assets.icon.autoAwesome} />
							<CRText typography='labelB' text='필수입력 진행률' />
						</FlexContainer>
						<FlexContainer gap='0.8rem'>
							<CRProgressBar height='0.2rem' barColor='primary60' percentage={percent} />
							<CRText
								typography='label'
								color='gray50'
								text={`${Math.floor(percent * 100)}%`}
								style={{ width: '3.9rm', textAlign: 'right' }}
							/>
						</FlexContainer>
						{percent !== 1 ? (
							<p>
								<CRText typography='labelB' text={requiredField} />
								<CRText typography='label' text='을(를) 입력해야 합니다.' />
							</p>
						) : (
							<p>
								<CRText typography='label' text='욕구사정 필수입력을 완료했습니다.' />
							</p>
						)}
					</FlexContainer>
				</S.BorderContainer>
			)}
		</FlexContainer>
	);
}

export default GenerateOpinionSampleHelper;
