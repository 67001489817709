import { endpoint } from 'lib/service/Api/endpoint';

import useCRMutation from '../base/useCRMutation';

export const useSaveCenterInfo = useCRMutation(endpoint.saveMyCenterInfo.key, 'saveMyCenterInfo');

export const useUpdateCenterAccountInfo = useCRMutation(
	endpoint.updateCenterAccountInfo.key,
	'updateCenterAccountInfo',
);
