import React from 'react';

import { v4 } from 'uuid';

import Typography from 'common/typography';

import * as S from './styles';

interface Props {
	fileNames?: string[];
	maxLength: number;
	typography?: keyof typeof Typography;
}

export default function CRFileName({
	fileNames = [],
	typography = 'label',
	maxLength = 10,
}: Props) {
	const renderTruncatedString = () => {
		if (!fileNames?.length) return '';
		if (fileNames.length === 1) {
			return fileNames[0];
		}
		return fileNames[0].length > maxLength
			? `${fileNames[0].slice(0, maxLength)}...`
			: fileNames[0];
	};
	const remainingCount = fileNames.length - 1;
	return (
		<S.Container $typography={typography}>
			{renderTruncatedString()}
			{remainingCount > 0 && <span>{` 외 ${remainingCount}개`}</span>}
		</S.Container>
	);
}
