import React, { useEffect, useMemo } from 'react';
import { Controller, FieldErrors, useFieldArray, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRInput from 'components/base/CRInput';
import { Toast } from 'components/base/CRToast';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import { CheckOption } from 'components/base/Selections/type';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import { RecipientGuardianInfo } from 'lib';
import { commonCodeAdapter } from 'lib/adapter/common';
import {
	useCommonCodes,
	useMyAccountInfo,
	useUpdateRecipientGuardians,
} from 'lib/hook/react-query';
import { DaumPostcodeData } from 'lib/hook/util/useDaumPostCode';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import {
	Recipient,
	RecipientBasicInfoFormType,
	RecipientGuardianFormViewType,
} from 'types/view/recipient';

import * as S from './styles';

enum DocReceiveMethod {
	Kakaotalk = 'CMN180.10',
	Sms = 'CMN180.20',
}
interface Props {
	currentRecipient?: Recipient;
	currentRecipientBase?: RecipientBasicInfoFormType;
	items?: RecipientGuardianFormViewType[];
	onlyEdit?: boolean;
	onStartChangeSection?: () => void;
	onCancelChangeSection?: () => void;
	isEditSectionChanging?: boolean;
}

function RecipientGuardianForm({
	currentRecipient,
	currentRecipientBase,
	items,
	onlyEdit = false,
	onStartChangeSection,
	onCancelChangeSection,
	isEditSectionChanging,
}: Props): React.ReactElement {
	const { data: myAccountInfo } = useMyAccountInfo();
	const updateRecipientGuardiansMutation = useUpdateRecipientGuardians(
		async (client, res, variables) => {
			if (res?.recipientId) {
				await client.invalidateQueries([
					endpoint.getRecipient.key,
					{ recipientId: variables.recipientId },
				]);
				await client.invalidateQueries([
					endpoint.getRecipientBase.key,
					{ recipientId: variables.recipientId },
				]);

				client.invalidateQueries([
					endpoint.getRecipients.key,
					{ centerIds: myAccountInfo?.centerId },
				]);

				onStartChangeSection?.();
				Toast.success('보호자 정보를 수정했습니다.');
			} else {
				onCancelChangeSection?.();
			}
		},
	);
	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN038', 'CMN180'],
		},
		commonCodeAdapter,
	);

	const { showDialog } = useDialog();

	const { control, handleSubmit, watch, setValue, getValues, formState } = useForm<{
		items: RecipientGuardianFormViewType[];
	}>({
		mode: 'onChange',
		defaultValues: { items },
		resolver: yupResolver(RecipientGuardianInfo),
	});

	const { append } = useFieldArray({
		control,
		name: 'items',
	});
	const itemsList = watch('items');

	const onSubmitSuccess = async (form: { items: RecipientGuardianFormViewType[] }) => {
		if (updateRecipientGuardiansMutation.isLoading) return;
		const isScheduleReceive = !!form.items?.find((item) => item.scheduleReceiveYn);
		const isLongtermReceive = !!form.items?.find((item) => item.longTermPayFeeCertiReceiveYn);

		if (!isScheduleReceive && !isLongtermReceive) {
			Toast.error('일정표, 장기요양급여비용명세서를 수신 여부를 확인해주세요');
			return;
		}
		if (!isScheduleReceive) {
			Toast.error('최소 한명의 보호자는 일정표를 수신해야합니다');
			return;
		}
		if (!isLongtermReceive) {
			Toast.error('최소 한명의 보호자는 장기요양급여비용명세서를 수신해야합니다');
			return;
		}

		await updateRecipientGuardiansMutation.mutateAsync({
			recipientId: Number(currentRecipient?.recipientId),
			body: form.items.map((item) => ({
				recipientGuardianId: item?.id && item.id > 0 ? item.id : undefined,
				recipientGuardianNm: item.name,
				mobilePhoneNo: item.phoneNumber,
				birthDt: item.birthday,
				basAddr: item.address.basAddr,
				regionNm1: item.address.regionNm1,
				regionNm2: item.address.regionNm2,
				regionNm3: item.address.regionNm3,
				roadNm: item.address.roadNm,
				addrLatitude: item.address.addrLatitude,
				addrLongitude: item.address.addrLongitude,
				detailAddr: item.address.detailAddr,
				zipCode: item.address.zipCode,
				guardianRelCd: item.relationship,
				mainGuardianYn: item.isMain,
				deleteYn: item.deleteYn,
				scheduleReceiveYn: item.scheduleReceiveYn,
				longTermPayFeeCertiReceiveYn: item.longTermPayFeeCertiReceiveYn,
				docReceiveMethodCd: item.docReceiveMethodCd || DocReceiveMethod.Kakaotalk,
			})),
		});
	};

	const onSubmitFail = (errors: { items: FieldErrors<RecipientGuardianFormViewType>[] }) => {
		const item = errors?.items?.filter(Boolean)[0];
		onCancelChangeSection?.();
		if (!item) return;
		if (item.address?.basAddr?.message) {
			Toast.error(item.address?.basAddr?.message);
			return;
		}
		Toast.error(Object.values(item)[0]?.message || '입력폼을 확인해주세요');
	};

	const onSubmitHandler = () => {
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='변경된 정보 저장'
				content='보호자에서 변경된 정보를 저장합니다.'
				successOption={{
					text: '저장',
					successCallback: () => {
						hideDialog();
						handleSubmit(onSubmitSuccess, onSubmitFail as any)();
					},
				}}
				cancelOption={{
					text: '저장안함',
					callback: () => {
						hideDialog();
						onStartChangeSection?.();
					},
				}}
				hideDialog={() => {
					hideDialog();
					onCancelChangeSection?.();
				}}
			/>
		));
	};

	useEffect(() => {
		if (isEditSectionChanging) {
			onSubmitHandler();
		}
	}, [isEditSectionChanging]);

	const handleChangeBaseAddress = (index: number) => (postcodeData: DaumPostcodeData) => {
		setValue(`items.${index}.address.basAddr`, postcodeData.address);
		setValue(`items.${index}.address.zipCode`, postcodeData.zonecode);
		setValue(`items.${index}.address.addrLatitude`, postcodeData.latitude);
		setValue(`items.${index}.address.addrLongitude`, postcodeData.longitude);
		setValue(`items.${index}.address.regionNm1`, postcodeData.sido);
		setValue(`items.${index}.address.regionNm2`, postcodeData.sigungu);
		setValue(`items.${index}.address.regionNm3`, postcodeData.bname);
		setValue(`items.${index}.address.roadNm`, postcodeData.roadname);
	};

	const handleChangeDetailAddress = (index: number) => (detailAddress: string) => {
		setValue(`items.${index}.address.detailAddr`, detailAddress);
	};

	const relationshipOptions = useMemo(() => commonCodes?.CMN038 || [], [commonCodes]);

	const docReceiveMethodOptions = useMemo(() => commonCodes?.CMN180 || [], [commonCodes]);

	const handleClickAddGuardian = () => {
		append({
			name: '',
			phoneNumber: '',
			address: {
				basAddr: '',
				detailAddr: '',
				zipCode: '',
				regionNm1: '',
				regionNm2: '',
				regionNm3: '',
				roadNm: '',
			},
			birthday: '',
			relationship: '',
			isMain: false,
			scheduleReceiveYn: false,
			longTermPayFeeCertiReceiveYn: false,
			docReceiveMethodCd: DocReceiveMethod.Kakaotalk,
		});
	};

	const handleClickRemoveGuardian = (index: number) => () => {
		const isSaved = !!itemsList[index]?.id;
		if (isSaved) {
			setValue(
				`items`,
				itemsList.map((item, idx) => {
					if (idx !== index) return item;
					return {
						...item,
						deleteYn: true,
					};
				}),
			);
		} else {
			setValue(
				`items`,
				itemsList.filter((_, idx) => idx !== index),
			);
		}
	};

	const handleClickMain = (index: number) => () => {
		getValues('items').forEach((item, itemIndex) => {
			setValue(`items.${itemIndex}.isMain`, itemIndex === index);
		});
	};

	const onChangeRelation = (value: string, index: number) => {
		// 보호자를 본인으로 선택할 시
		if (value === 'CMN038.10') {
			const currentValue = getValues(`items.${index}`);
			setValue(`items.${index}`, {
				...currentValue,
				name: currentRecipientBase?.name || '',
				phoneNumber: currentRecipient?.phoneNumber || currentValue?.phoneNumber || '',
				address: {
					basAddr: currentRecipientBase?.address.basAddr || currentValue?.address.basAddr || '',
					detailAddr:
						currentRecipientBase?.address.detailAddr || currentValue?.address.detailAddr || '',
					zipCode: currentRecipientBase?.address.zipCode || currentValue?.address.zipCode || '',
					regionNm1:
						currentRecipientBase?.address.regionNm1 || currentValue?.address.regionNm1 || '',
					regionNm2:
						currentRecipientBase?.address.regionNm2 || currentValue?.address.regionNm2 || '',
					regionNm3:
						currentRecipientBase?.address.regionNm3 || currentValue?.address.regionNm3 || '',
					roadNm: currentRecipientBase?.address.roadNm || currentValue?.address.roadNm || '',
					addrLatitude:
						currentRecipientBase?.address.addrLatitude || currentValue?.address.addrLatitude,
					addrLongitude:
						currentRecipientBase?.address.addrLongitude || currentValue?.address.addrLongitude,
				},
				id: currentValue.id,
				birthday: currentRecipient?.birthday || '',
				relationship: 'CMN038.10',
				isMain: currentValue.isMain,
			});
		} else {
			setValue(`items.${index}.relationship`, value);
		}
	};

	const formatIndex = (number: number) => `${number + 1}`.padStart(2, '0');

	return (
		<S.Container>
			<S.Form>
				{!onlyEdit && (
					<S.Label>
						보호자
						<S.ButtonContainer>
							<CRButton.Default
								size='xSmall'
								type='outlined'
								palette='gray'
								onClick={onSubmitHandler}>
								취소
							</CRButton.Default>
							<CRButton.Default
								buttonType='button'
								size='xSmall'
								type='filled'
								palette='gray'
								onClick={handleSubmit(onSubmitSuccess, onSubmitFail as any)}>
								저장
							</CRButton.Default>
						</S.ButtonContainer>
					</S.Label>
				)}
				{itemsList?.map((field, index) => {
					if (field.deleteYn) return null;
					return (
						<React.Fragment key={field.id || index}>
							<S.ItemHeader>
								{`보호자 ${formatIndex(index)}`}
								<S.DeleteButtonContainer>
									<CRButton.Default
										disabled={watch(`items.${index}.isMain`)}
										size='xSmall'
										type='outlined'
										onClick={handleClickRemoveGuardian(index)}>
										삭제
									</CRButton.Default>
								</S.DeleteButtonContainer>
								<Controller
									render={({ field: { value }, formState: { errors } }) => (
										<CRCheckBox
											checkBoxType='radio'
											checked={value}
											onChange={handleClickMain(index)}>
											주 보호자
										</CRCheckBox>
									)}
									name={`items.${index}.isMain`}
									control={control}
								/>
							</S.ItemHeader>
							<S.Table>
								<S.TableRow>
									<S.TableLabelColumn style={{ width: '10.4rem' }}>
										이름 <S.RequiredMark>*</S.RequiredMark>
									</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }}>
										<Controller
											render={({
												field: { onBlur, ref, onChange, value },
												formState: { errors },
											}) => (
												<CRInput.TableInput
													ref={ref}
													onBlur={onBlur}
													status={errors.items?.[index]?.name?.message ? 'error' : 'default'}
													onChange={onChange}
													value={value}
													placeholder='보호자명 입력'
													addOnBottom={formState?.errors?.items?.[index]?.name?.message}
												/>
											)}
											name={`items.${index}.name`}
											control={control}
										/>
									</S.TableValueColumn>
									<S.TableLabelColumn style={{ width: '10.4rem' }}>
										연락처 <S.RequiredMark>*</S.RequiredMark>
									</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }}>
										<Controller
											render={({
												field: { onBlur, ref, onChange, value },
												formState: { errors },
											}) => (
												<CRInput.TableInput
													ref={ref}
													type='number'
													onBlur={onBlur}
													status={errors.items?.[index]?.phoneNumber?.message ? 'error' : 'default'}
													onChange={onChange}
													value={value}
													placeholder='연락처 입력'
													addOnBottom={formState?.errors?.items?.[index]?.phoneNumber?.message}
												/>
											)}
											name={`items.${index}.phoneNumber`}
											control={control}
										/>
									</S.TableValueColumn>
								</S.TableRow>
								<S.TableRow>
									<S.TableLabelColumn rowSpan={2} style={{ width: '10.4rem' }}>
										주소 {getValues(`items.${index}.isMain`) && <S.RequiredMark>*</S.RequiredMark>}
									</S.TableLabelColumn>
									<S.TableValueColumn
										style={{ width: '24rem', verticalAlign: 'middle' }}
										rowSpan={2}>
										<CRInput.Address
											onChangeBaseAddress={handleChangeBaseAddress(index)}
											onChangeDetailAddress={handleChangeDetailAddress(index)}
											baseAddress={watch(`items.${index}`).address.basAddr}
											detailAddress={watch(`items.${index}`).address.detailAddr}
											status={
												formState?.errors?.items?.[index]?.address?.basAddr?.message
													? 'error'
													: 'default'
											}
											addOnBottom={formState?.errors?.items?.[index]?.address?.basAddr?.message}
										/>
									</S.TableValueColumn>

									<S.TableLabelColumn style={{ width: '10.4rem' }}>
										생년월일
										{getValues(`items.${index}.isMain`) && <S.RequiredMark>*</S.RequiredMark>}
									</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }}>
										<Controller
											render={({
												field: { onBlur, ref, onChange, value },
												formState: { errors },
											}) => (
												<CRInput.TableInput
													type='number'
													maxLength={10}
													ref={ref}
													onBlur={onBlur}
													status={errors.items?.[index]?.birthday?.message ? 'error' : 'default'}
													onChange={onChange}
													value={value}
													placeholder='예시) 19280301'
													addOnBottom={formState?.errors?.items?.[index]?.birthday?.message}
												/>
											)}
											name={`items.${index}.birthday`}
											control={control}
										/>
									</S.TableValueColumn>
								</S.TableRow>
								<S.TableRow>
									<S.TableLabelColumn style={{ width: '9.6rem' }}>
										관계 <S.RequiredMark>*</S.RequiredMark>
									</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem', verticalAlign: 'middle' }}>
										<Controller
											render={({ field: { value, onChange }, formState: { errors } }) => (
												<CRInput.Selector
													status={
														errors.items?.[index]?.relationship?.message ? 'error' : 'default'
													}
													items={relationshipOptions}
													type='small'
													currentValue={relationshipOptions.find((item) => item.value === value)}
													onChangeValue={(option) => {
														onChange(option.value);
														onChangeRelation(option.value, index);
													}}
													placeholder='관계 입력'
													addOnBottom={formState?.errors?.items?.[index]?.relationship?.message}
												/>
											)}
											name={`items.${index}.relationship`}
											control={control}
										/>
									</S.TableValueColumn>
								</S.TableRow>
								<S.TableRow>
									<S.TableLabelColumn>
										<S.LabelContainer>
											서류 수신 여부
											<RDTooltip
												content={
													<>생년월일이 없는 보호자의 서류 비밀번호는 수급자의 생년월일 입니다</>
												}>
												<S.QuestionMark src={Assets.icon.help} alt='공휴일 근무일정' />
											</RDTooltip>
										</S.LabelContainer>
									</S.TableLabelColumn>
									<S.TableValueColumn
										style={{
											verticalAlign: 'middle',
										}}>
										<FlexContainer gap='0.8rem' align='center'>
											<FlexContainer>
												<Controller
													render={({
														field: { value, onBlur, ref, onChange },
														formState: { errors },
													}) => (
														<CRCheckBox
															name='일정표'
															onChange={onChange}
															checkBoxType='checkbox'
															checked={value}>
															일정표
														</CRCheckBox>
													)}
													name={`items.${index}.scheduleReceiveYn`}
													control={control}
												/>
											</FlexContainer>
											<FlexContainer>
												<Controller
													render={({
														field: { value, onBlur, ref, onChange },
														formState: { errors },
													}) => (
														<CRCheckBox
															name='장기요양급여비용명세서'
															onChange={onChange}
															checkBoxType='checkbox'
															checked={value}>
															장기요양급여비용명세서
														</CRCheckBox>
													)}
													name={`items.${index}.longTermPayFeeCertiReceiveYn`}
													control={control}
												/>
											</FlexContainer>
										</FlexContainer>
									</S.TableValueColumn>
									<S.TableLabelColumn style={{ width: '9.6rem' }}>
										서류 수신 방법
									</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem', verticalAlign: 'middle' }}>
										<Controller
											render={({ field: { value, onChange }, formState: { errors } }) => (
												<CRInput.Selector
													status={
														errors.items?.[index]?.relationship?.message ? 'error' : 'default'
													}
													items={docReceiveMethodOptions}
													type='small'
													currentValue={
														docReceiveMethodOptions.find((item) => item.value === value) ||
														docReceiveMethodOptions.find(
															(item) => item.value === DocReceiveMethod.Kakaotalk,
														)
													}
													onChangeValue={(option: CheckOption) => onChange(option.value)}
													placeholder='서류 수신 방법 입력'
												/>
											)}
											name={`items.${index}.docReceiveMethodCd`}
											control={control}
										/>
									</S.TableValueColumn>
								</S.TableRow>
							</S.Table>
						</React.Fragment>
					);
				})}
				<S.AddButtonContainer>
					<CRButton.IconButton
						iconLeft={Assets.icon.add}
						palette='gray'
						type='outlined'
						onClick={handleClickAddGuardian}>
						보호자 추가
					</CRButton.IconButton>
				</S.AddButtonContainer>
			</S.Form>
		</S.Container>
	);
}

export default RecipientGuardianForm;
