import React, { useMemo, useState } from 'react';

import dayjs from 'dayjs';

import CRSpinner from 'components/base/CRSpinner';
import CRTableHeader from 'components/base/CRTableHeader';
import { CheckOption } from 'components/base/Selections/type';
import FullTimeEmployeeWorkDetailsTable from 'components/domain/table/FullTimeEmployeeWorkDetailsTable';
import { commonCodeAdapter } from 'lib/adapter/common';
import useFullTimeEmployeePage from 'lib/hook/full-time-employee/useFullTimeEmployeePage';
import {
	useCommonCodes,
	useFullTimeEmployeeWorkDetails,
	useMyAccountInfo,
} from 'lib/hook/react-query';
import { Filter } from 'types/view/filter';

import * as S from './styles';

export default function FullTimeEmployeeDetailReportTab(): React.ReactElement {
	const { currentEmployee } = useFullTimeEmployeePage();
	const { data: myAccountInfo } = useMyAccountInfo();
	const [keyword, setKeyword] = useState('');
	const [currentFilter, setCurrentFilter] = useState<{
		workTypeFilter: CheckOption[];
		rangeFilter: CheckOption[];
	}>({
		workTypeFilter: [],
		rangeFilter: [
			{
				label: 'rangeFilter',
				value: {
					start: dayjs(new Date()).startOf('y').toDate(),
					end: dayjs(new Date()).endOf('y').toDate(),
				},
			},
		],
	});

	const {
		data: commonCodes = {
			CMN196: [],
		},
	} = useCommonCodes({ comCdGroupNms: ['CMN196'] }, commonCodeAdapter);

	const filters = useMemo(
		() =>
			[
				{
					key: 'rangeFilter',
					type: 'calendar',
					placeholder: '일자',
					value: [
						{
							label: 'rangeFilter',
							value: {
								start: dayjs(new Date()).startOf('y').toDate(),
								end: dayjs(new Date()).endOf('y').toDate(),
							},
						},
					],
				},
				{
					key: 'workTypeFilter',
					type: 'multi',
					placeholder: '근무 종류',
					options: commonCodes.CMN196,
				},
			] as Filter<{
				label: string;
				value: any;
			}>[],
		[commonCodes],
	);

	const { data: employeeEduInfo, isLoading } = useFullTimeEmployeeWorkDetails({
		employeeId: currentEmployee?.employeeId,
		centerId: myAccountInfo?.centerId,
		startDt: currentFilter.rangeFilter[0].value?.start
			? dayjs(currentFilter.rangeFilter[0].value?.start).format('YYYYMMDD')
			: undefined,
		endDt: currentFilter.rangeFilter[0].value?.end
			? dayjs(currentFilter.rangeFilter[0].value?.end).format('YYYYMMDD')
			: undefined,
		workRequestKindCds: currentFilter.workTypeFilter.map((item) => item.value),
		keyword,
	});

	return (
		<S.Container>
			<CRTableHeader
				currentFilter={currentFilter}
				setCurrentFilter={setCurrentFilter}
				filters={filters}
				searchValue={keyword}
				onChangeSearchValue={setKeyword}
			/>
			{isLoading ? (
				<CRSpinner />
			) : (
				<S.TableContainer>
					<FullTimeEmployeeWorkDetailsTable items={employeeEduInfo} />
				</S.TableContainer>
			)}
		</S.Container>
	);
}
