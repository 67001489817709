import styled, { css } from 'styled-components';

import { PlacementObjType, ToolTipArrow, ToolTipBoxBgColor, ToolTipPlacement } from './type';

const ARROW_COLOR_INFO: ToolTipArrow = {
	white:
		'brightness(0) saturate(100%) invert(94%) sepia(0%) saturate(16%) hue-rotate(307deg) brightness(105%) contrast(105%)',
	black:
		'brightness(0) saturate(100%) invert(5%) sepia(4%) saturate(2304%) hue-rotate(149deg) brightness(91%) contrast(87%)',
};

const PLACEMENT_INFO: PlacementObjType = {
	up: {
		container: css`
			top: 110%;
			transform: translateY(1rem);
		`,
		arrow: css`
			left: 50%;
			top: -1.5rem;
			transform: translateX(-50%);
		`,
	},
	down: {
		container: css`
			bottom: 110%;
			transition: all 0.2s ease-in-out;
			transform: translateY(-1rem);
		`,
		arrow: css`
			left: 50%;
			bottom: 0rem;
			transform: translateX(-50%);
			& > img {
				transform: rotate(180deg);
			}
		`,
	},
	left: {
		container: css`
			left: 100%;
			top: 0;
			transform: translate(2rem, -25%);
		`,
		arrow: css`
			left: -0.8rem;
			& > img {
				transform: rotate(30deg);
			}
		`,
	},
	right: {
		container: css`
			right: 100%;
			top: 0;
			transform: translate(-2rem, -25%);
		`,
		arrow: css`
			right: -0.8rem;
			& > img {
				transform: rotate(90deg);
			}
		`,
	},
};

export const Container = styled.div<{ placement: ToolTipPlacement }>`
	position: relative;
	width: fit-content;
	display: flex;
	justify-content: center;
`;

export const TooltipBody = styled.div<{ placement: ToolTipPlacement; visible: boolean }>`
	${(props) => props.theme.typography.label};
	opacity: 0;
	z-index: -1;
	position: absolute;
	padding: 1rem 1.6rem;
	border-radius: 0.8rem;
	background: ${(props) => props.theme.colors.gray10};
	color: ${(props) => props.theme.colors.gray100};
	transition: all 0.2s ease-in-out;
	white-space: nowrap;

	${(props) => PLACEMENT_INFO[props.placement].container};

	${(props) =>
		props.visible &&
		css`
			z-index: 1;
			opacity: 1;
		`};
`;

export const ArrowContainer = styled.div<{
	placement: ToolTipPlacement;
	tooltipBgColor: ToolTipBoxBgColor;
}>`
	position: absolute;
	height: 1.2rem;
	object-fit: contain;
	& > img {
		width: 100%;
		filter: ${(props) => ARROW_COLOR_INFO[props.tooltipBgColor]};
	}

	${(props) => PLACEMENT_INFO[props.placement].arrow};
`;
