import React, { useEffect, useMemo } from 'react';
import { Controller, UseFormReturn, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { Toast } from 'components/base/CRToast';
import EDocCareerForm from 'components/domain/form/EDocCareerForm';
import EDocDeviceUnusedApplicationForm from 'components/domain/form/EDocDeviceUnusedApplicationForm';
import EmploymentCertificationForm from 'components/domain/form/EDocEmploymentCertificationForm';
import EDocForeignConfirmForm from 'components/domain/form/EDocForeignConfirmForm';
import EDocForeignRequestConfirmForm from 'components/domain/form/EDocForeignRequestConfirmForm';
import EDocHealthInsuranceAcqCancelForm from 'components/domain/form/EDocHealthInsuranceAcqCancelForm';
import EDocHealthInsuranceLossCancelForm from 'components/domain/form/EDocHealthInsuranceLossCancelForm';
import EDocOtherBankBookForm from 'components/domain/form/EDocOtherBankBookForm';
import EDocPledgeForm from 'components/domain/form/EDocPledgeForm';
import EDocResignForm from 'components/domain/form/EDocResignForm';
import InformationTable from 'components/ui/InformationTable';
import {
	IssueCareerDoc,
	IssueDeviceUnusedApplicationDoc,
	IssueEmploymentCertificationDoc,
	IssueForeignConfirmDoc,
	IssueForeignRequestConfirmDoc,
	IssueHealthInsuranceAcqCancelDoc,
	IssueHealthInsuranceLossCancelDoc,
	IssueOtherBankBook,
	IssuePledge,
	IssueResignDoc,
	displayPhoneNumber,
} from 'lib';
import { commonCodeAdapter } from 'lib/adapter/common';
import {
	useCommonCodes,
	useCreateDownloadUrl,
	useEmployeePageBaseInfo,
} from 'lib/hook/react-query';
import { useIssuableEDocPaperTypes, useIssueEDocument } from 'lib/hook/react-query/query/edoc';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { downloadAndConvertToBase64 } from 'lib/util/file';
import { MyAccountInfoDTO } from 'types/api/auth';
import { EmployeeDTO } from 'types/api/employee';
import {
	CareerFormTypes,
	DeviceUnusedApplicationFormTypes,
	EDocIssueFormTypes,
	EDocTemplateType,
	EmploymentCertificationFormTypes,
	ForeignConfirmFormTypes,
	ForeignRequestConfirmFormTypes,
	HealthInsuranceAcqCancelFormTypes,
	HealthInsuranceLossCancelFormTypes,
	OtherBankBookFormTypes,
	PledgeFormTypes,
	ResignFormTypes,
} from 'types/view/eDoc';

import {
	eDocDeviceUnusedApplicationIssueFormAdapter,
	eDocEmploymentCertFormAdapter,
	eDocForeignConfirmIssueFormAdapter,
	eDocForeignRequestConfirmIssueFormAdapter,
	eDocHealthInsuranceAcqCancelIssueFormAdapter,
	eDocHealthInsuranceLossCancelIssueFormAdapter,
	eDocOtherBankBookIssueFormAdapter,
	eDocPledgeIssueFormAdapter,
	eDocResignIssueFormAdapter,
	eDocWithOutSignIssueFormAdapter,
} from './eDocIssueAdapter';
import * as S from './styles';

interface Props {
	from?: EDocTemplateType | undefined;
	menuType?: 'employee' | 'recipient';
	currentEmployee?: EmployeeDTO;
	// currentEmployeeBase?: EmployeeBaseInfoDTO;
	myAccountInfo?: MyAccountInfoDTO;
	title?: string;
}

export function EmployeeDocumentIssueDialog({
	from,
	menuType = 'employee',
	title = '',
	currentEmployee,
	myAccountInfo,
}: Props) {
	const { data: currentEmployeeBase } = useEmployeePageBaseInfo({
		centerId: Number(currentEmployee?.centerId),
		memberId: Number(currentEmployee?.memberId),
		employeeId: Number(currentEmployee?.employeeId),
	});

	const defaultForm = useForm<EDocIssueFormTypes>();
	const employmentCertificationForm = useForm<EmploymentCertificationFormTypes>({
		resolver: yupResolver(IssueEmploymentCertificationDoc),
	});

	const careerForm = useForm<CareerFormTypes>({
		resolver: yupResolver(IssueCareerDoc),
	});
	const pledgeForm = useForm<PledgeFormTypes>({
		resolver: yupResolver(IssuePledge),
	});
	const otherBankBookForm = useForm<OtherBankBookFormTypes>({
		resolver: yupResolver(IssueOtherBankBook),
	});
	const resignForm = useForm<ResignFormTypes>({
		resolver: yupResolver(IssueResignDoc),
	});
	const foreignConfirmForm = useForm<ForeignConfirmFormTypes>({
		resolver: yupResolver(IssueForeignConfirmDoc),
	});
	const healthInsuranceAcqCancelForm = useForm<HealthInsuranceAcqCancelFormTypes>({
		resolver: yupResolver(IssueHealthInsuranceAcqCancelDoc),
	});

	const healthInsuranceLossCancelForm = useForm<HealthInsuranceLossCancelFormTypes>({
		resolver: yupResolver(IssueHealthInsuranceLossCancelDoc),
	});

	const deviceUnusedApplicationForm = useForm<DeviceUnusedApplicationFormTypes>({
		resolver: yupResolver(IssueDeviceUnusedApplicationDoc),
	});

	const foreignRequestConfirmForm = useForm<ForeignRequestConfirmFormTypes>({
		resolver: yupResolver(IssueForeignRequestConfirmDoc),
	});
	const { hideDialog } = useDialog();

	const { mutateAsync: createDownloadUrl } = useCreateDownloadUrl();
	const { mutate: issueEDocument } = useIssueEDocument((client, data) => {
		hideDialog();
		Toast.success('발급 요청을 완료하였습니다.');
		client.invalidateQueries([
			endpoint.getEmployeeEDocList.key,
			{ centerId: myAccountInfo?.centerId, employeeId: currentEmployee?.employeeId },
		]);
	});

	// 재직 증명서
	const onSubmitEmploymentCertForm = async (formData: EmploymentCertificationFormTypes) => {
		if (formData.data && currentEmployee && myAccountInfo) {
			let stampImageData = '';
			const response = await createDownloadUrl({
				objectKey: formData.data.data.stampImageObjectKey,
			});
			if (response.data?.url) {
				stampImageData = await downloadAndConvertToBase64(response.data.url);
			}

			const eDocParamValue = eDocEmploymentCertFormAdapter(
				formData,
				currentEmployee,
				myAccountInfo,
				stampImageData,
			);

			if (eDocParamValue) issueEDocument(eDocParamValue);
		}
	};

	// 경력증명서
	const onSubmitCareerForm = async (formData: CareerFormTypes) => {
		if (formData.data && currentEmployee && myAccountInfo) {
			let stampImageData = '';
			const response = await createDownloadUrl({
				objectKey: formData.data.data.stampImageObjectKey,
			});
			if (response.data?.url) {
				stampImageData = await downloadAndConvertToBase64(response.data.url);
			}

			const eDocParamValue = eDocWithOutSignIssueFormAdapter(
				formData,
				currentEmployee,
				myAccountInfo,
				stampImageData,
			);

			if (eDocParamValue) issueEDocument(eDocParamValue);
		}
	};

	// 서약서
	const onSubmitPledgeForm = async (formData: PledgeFormTypes) => {
		if (formData.data && currentEmployee && myAccountInfo) {
			const eDocParamValue = eDocPledgeIssueFormAdapter(formData, currentEmployee, myAccountInfo);
			if (eDocParamValue) issueEDocument(eDocParamValue);
		}
	};

	// 외국인고용보험확인서
	const onSubmitForeignConfirmForm = async (formData: ForeignConfirmFormTypes) => {
		if (formData.data && currentEmployee && myAccountInfo) {
			const stamp = formData.data.data.center?.stampFile.fileDetails.find(
				(item) => !item.fileDeleteYn,
			);

			if (!stamp) return;
			const objectKey = `${stamp.filePathNm}${stamp.transFileNm}`;

			const response = await createDownloadUrl({ objectKey });

			if (!response.data?.url) return;

			const stampImageData = await downloadAndConvertToBase64(response.data.url);

			const eDocParamValue = eDocForeignConfirmIssueFormAdapter(
				formData,
				currentEmployee,
				myAccountInfo,
				stampImageData,
			);
			if (eDocParamValue) issueEDocument(eDocParamValue);
		}
	};

	// 건강보험취득취소확인서
	const onSubmitHealthInsuranceAcqCancelForm = async (
		formData: HealthInsuranceAcqCancelFormTypes,
	) => {
		if (formData.data && currentEmployeeBase && myAccountInfo && currentEmployeeBase?.rsdnNo) {
			const eDocParamValue = eDocHealthInsuranceAcqCancelIssueFormAdapter(
				formData,
				currentEmployeeBase,
				myAccountInfo,
				currentEmployeeBase.rsdnNo,
			);
			if (eDocParamValue) issueEDocument(eDocParamValue);
		}
	};

	// 건강보험상실취소확인서
	const onSubmitHealthInsuranceLossCancelForm = async (
		formData: HealthInsuranceLossCancelFormTypes,
	) => {
		if (formData.data && currentEmployeeBase && myAccountInfo && currentEmployeeBase?.rsdnNo) {
			const eDocParamValue = eDocHealthInsuranceLossCancelIssueFormAdapter(
				formData,
				currentEmployeeBase,
				myAccountInfo,
				currentEmployeeBase.rsdnNo,
			);
			if (eDocParamValue) issueEDocument(eDocParamValue);
		}
	};

	// 단말기미사용신청서
	const onSubmitDeviceUnusedApplicationForm = async (
		formData: DeviceUnusedApplicationFormTypes,
	) => {
		if (formData.data && currentEmployee && myAccountInfo) {
			const eDocParamValue = eDocDeviceUnusedApplicationIssueFormAdapter(
				formData,
				currentEmployee,
				myAccountInfo,
			);
			if (eDocParamValue) issueEDocument(eDocParamValue);
		}
	};

	// 외국인고용보험신청서및확인서
	const onSubmitForeignRequestConfirmForm = async (formData: ForeignRequestConfirmFormTypes) => {
		if (formData.data && currentEmployee && myAccountInfo) {
			const stamp = formData.data.data.center?.stampFile.fileDetails.find(
				(item) => !item.fileDeleteYn,
			);

			if (!stamp) return;
			const objectKey = `${stamp.filePathNm}${stamp.transFileNm}`;

			const response = await createDownloadUrl({ objectKey });

			if (!response.data?.url) return;

			const stampImageData = await downloadAndConvertToBase64(response.data.url);

			const eDocParamValue = eDocForeignRequestConfirmIssueFormAdapter(
				formData,
				currentEmployee,
				myAccountInfo,
				stampImageData,
			);
			if (eDocParamValue) issueEDocument(eDocParamValue);
		}
	};

	const onSubmitOtherBankBook = async (formData: OtherBankBookFormTypes) => {
		if (formData.data && currentEmployee && myAccountInfo) {
			const eDocParamValue = eDocOtherBankBookIssueFormAdapter(
				formData,
				currentEmployee,
				myAccountInfo,
			);
			if (eDocParamValue) issueEDocument(eDocParamValue);
		}
	};

	// 사직서
	const onSubmitResignForm = async (formData: ResignFormTypes) => {
		if (formData.data && currentEmployee && myAccountInfo) {
			const eDocParamValue = eDocResignIssueFormAdapter(formData, currentEmployee, myAccountInfo);
			if (eDocParamValue) issueEDocument(eDocParamValue);
		}
	};

	const totalContext = useMemo(
		() => [
			{
				templateCode: EDocTemplateType.재직증명서,
				formContext: employmentCertificationForm,
				onSubmit: onSubmitEmploymentCertForm,
			},
			{
				templateCode: EDocTemplateType.경력증명서,
				formContext: careerForm,
				onSubmit: onSubmitCareerForm,
			},
			{
				templateCode: EDocTemplateType.서약서,
				formContext: pledgeForm,
				onSubmit: onSubmitPledgeForm,
			},
			{
				templateCode: EDocTemplateType.타명의통장확인서,
				formContext: otherBankBookForm,
				onSubmit: onSubmitOtherBankBook,
			},
			{
				templateCode: EDocTemplateType.사직서,
				formContext: resignForm,
				onSubmit: onSubmitResignForm,
			},
			{
				templateCode: EDocTemplateType.외국인고용보험확인서,
				formContext: foreignConfirmForm,
				onSubmit: onSubmitForeignConfirmForm,
			},
			{
				templateCode: EDocTemplateType.외국인고용보험신청서및확인서,
				formContext: foreignRequestConfirmForm,
				onSubmit: onSubmitForeignRequestConfirmForm,
			},
			{
				templateCode: EDocTemplateType.건강보험취득취소확인서,
				formContext: healthInsuranceAcqCancelForm,
				onSubmit: onSubmitHealthInsuranceAcqCancelForm,
			},
			{
				templateCode: EDocTemplateType.건강보험상실취소확인서,
				formContext: healthInsuranceLossCancelForm,
				onSubmit: onSubmitHealthInsuranceLossCancelForm,
			},
			{
				templateCode: EDocTemplateType.단말기미사용신청서,
				formContext: deviceUnusedApplicationForm,
				onSubmit: onSubmitDeviceUnusedApplicationForm,
			},
		],
		[
			employmentCertificationForm,
			careerForm,
			pledgeForm,
			otherBankBookForm,
			resignForm,
			foreignConfirmForm,
			foreignRequestConfirmForm,
			healthInsuranceAcqCancelForm,
			healthInsuranceLossCancelForm,
			onSubmitEmploymentCertForm,
			onSubmitCareerForm,
			onSubmitPledgeForm,
			onSubmitOtherBankBook,
			onSubmitResignForm,
			onSubmitForeignConfirmForm,
			onSubmitForeignRequestConfirmForm,
			onSubmitHealthInsuranceAcqCancelForm,
			onSubmitHealthInsuranceLossCancelForm,
		],
	);

	const { data: eDocPaperCodes } = useIssuableEDocPaperTypes({
		paperMenu: menuType,
	});

	const { data: reasonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN142'],
		},
		commonCodeAdapter,
	);

	const matchedForm: any = totalContext.find(
		(context) => context.templateCode === defaultForm.watch('eDocType')?.value,
	);

	const submitForm = () => {
		if (matchedForm) {
			matchedForm.formContext.trigger().then((isValid: boolean) => {
				if (isValid) {
					matchedForm.formContext.handleSubmit(matchedForm.onSubmit as (data: any) => void)();
				}
			});
		}
	};

	const eDocPaperTypeCode = useMemo(
		() =>
			(eDocPaperCodes?.paperTypeCds || [])
				?.map((item) => ({
					label: item.korComCdAliasNm,
					value: item.comCdId,
				}))
				.filter((item) => {
					if (currentEmployee?.workStateNm !== '퇴사') {
						if (item?.value === EDocTemplateType.경력증명서) {
							return false;
						}
					}
					if (currentEmployee?.workStateNm !== '근무중') {
						if (item?.value === EDocTemplateType.재직증명서) {
							return false;
						}
					}

					if (currentEmployeeBase?.rsdnNo) {
						const foreignerYn = ['5', '6', '7', '8'].includes(currentEmployeeBase.rsdnNo?.[6]);
						if (
							!foreignerYn &&
							[
								EDocTemplateType.외국인고용보험신청서및확인서,
								EDocTemplateType.외국인고용보험확인서,
							].includes(item.value as EDocTemplateType)
						) {
							return false;
						}
					}
					return true;
				}),
		[eDocPaperCodes, currentEmployee, currentEmployeeBase],
	);

	const renderSubForm = () => {
		const eDocType = defaultForm.watch('eDocType');
		if (!eDocType) {
			return <S.Empty>발급 서류를 선택해주세요.</S.Empty>;
		}

		const templateCode = eDocType.value.split('.').join('_');
		switch (eDocType.value) {
			case EDocTemplateType.재직증명서: {
				return (
					<EmploymentCertificationForm
						templateCode={templateCode}
						formContext={employmentCertificationForm}
						currentEmployee={currentEmployee}
						reasonCodes={reasonCodes?.CMN142}
					/>
				);
			}
			case EDocTemplateType.경력증명서: {
				return (
					<EDocCareerForm
						templateCode={templateCode}
						formContext={careerForm}
						currentEmployee={currentEmployee}
						reasonCodes={reasonCodes?.CMN142}
					/>
				);
			}
			case EDocTemplateType.서약서: {
				return (
					<EDocPledgeForm
						templateCode={templateCode}
						formContext={pledgeForm}
						currentEmployee={currentEmployee}
					/>
				);
			}
			case EDocTemplateType.타명의통장확인서: {
				return (
					<EDocOtherBankBookForm
						templateCode={templateCode}
						formContext={otherBankBookForm}
						currentEmployee={currentEmployee}
					/>
				);
			}
			case EDocTemplateType.사직서: {
				return (
					<EDocResignForm
						templateCode={templateCode}
						formContext={resignForm}
						currentEmployee={currentEmployee}
					/>
				);
			}
			case EDocTemplateType.외국인고용보험확인서: {
				return (
					<EDocForeignConfirmForm
						templateCode={templateCode}
						formContext={foreignConfirmForm}
						currentEmployee={currentEmployee}
						currentEmployeeBase={currentEmployeeBase || undefined}
					/>
				);
			}
			case EDocTemplateType.외국인고용보험신청서및확인서: {
				return (
					<EDocForeignRequestConfirmForm
						templateCode={templateCode}
						formContext={foreignRequestConfirmForm}
						currentEmployee={currentEmployee}
						currentEmployeeBase={currentEmployeeBase || undefined}
					/>
				);
			}
			case EDocTemplateType.건강보험취득취소확인서: {
				return (
					<EDocHealthInsuranceAcqCancelForm
						templateCode={templateCode}
						formContext={healthInsuranceAcqCancelForm}
						currentEmployee={currentEmployee}
						currentEmployeeBase={currentEmployeeBase || undefined}
					/>
				);
			}
			case EDocTemplateType.건강보험상실취소확인서: {
				return (
					<EDocHealthInsuranceLossCancelForm
						templateCode={templateCode}
						formContext={healthInsuranceLossCancelForm}
						currentEmployee={currentEmployee}
						currentEmployeeBase={currentEmployeeBase || undefined}
					/>
				);
			}
			case EDocTemplateType.단말기미사용신청서: {
				return (
					<EDocDeviceUnusedApplicationForm
						templateCode={templateCode}
						formContext={deviceUnusedApplicationForm}
						currentEmployee={currentEmployee}
					/>
				);
			}
			default: {
				return null;
			}
		}
	};

	useEffect(() => {
		if (from === EDocTemplateType.사직서) {
			defaultForm.setValue('eDocType', {
				label: '사직서+퇴직급여 지급 연장 동의서',
				value: EDocTemplateType.사직서,
			});
		} else if (from === EDocTemplateType.외국인고용보험확인서) {
			defaultForm.setValue('eDocType', {
				label: '외국인 고용보험 확인서',
				value: EDocTemplateType.외국인고용보험확인서,
			});
		} else if (from === EDocTemplateType.외국인고용보험신청서및확인서) {
			defaultForm.setValue('eDocType', {
				label: '외국인 고용보험 신청서 및 확인서',
				value: EDocTemplateType.외국인고용보험신청서및확인서,
			});
		} else if (from === EDocTemplateType.건강보험취득취소확인서) {
			defaultForm.setValue('eDocType', {
				label: '건강보험 취득취소 확인서',
				value: EDocTemplateType.건강보험취득취소확인서,
			});
		} else if (from === EDocTemplateType.건강보험상실취소확인서) {
			defaultForm.setValue('eDocType', {
				label: '건강보험 상실취소 확인서',
				value: EDocTemplateType.건강보험상실취소확인서,
			});
		}
	}, [from]);

	return (
		<CRDialog
			height='60rem'
			onClickClose={hideDialog}
			title={title}
			body={
				<S.Container>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInputLabel isRequired label='발급 서류'>
								<CRInput.Selector
									placeholder='발급 서류 선택'
									currentValue={value}
									onChangeValue={onChange}
									items={eDocPaperTypeCode}
								/>
							</CRInputLabel>
						)}
						name='eDocType'
						control={defaultForm.control}
					/>
					<CRInputLabel label='발송 정보'>
						<InformationTable
							items={[
								[
									{
										label: '템플릿명',
										value: defaultForm.watch('eDocType')?.label || '-',
									},
								],
								[
									{
										label: '제목',
										value: defaultForm.watch('eDocType')
											? `${currentEmployee?.korMemberNm}님${
													defaultForm.watch('eDocType')
														? ` ${defaultForm.watch('eDocType').label}`
														: ''
												}`
											: '-',
									},
								],
								[
									{
										label: '수신자',
										value: `${currentEmployee?.korMemberNm}${
											currentEmployee?.mobilePhoneNo
												? ` (${displayPhoneNumber(currentEmployee?.mobilePhoneNo)})`
												: ''
										}`,
									},
								],
							]}
						/>
					</CRInputLabel>
					{renderSubForm()}
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						palette='primary'
						size='default'
						disabled={
							!(matchedForm?.formContext as UseFormReturn<any, any, undefined>)?.formState?.isValid
						}
						onClick={submitForm}>
						발급
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
