import React, { ReactNode, useMemo } from 'react';

import Assets from 'assets';
import { FilterType } from 'types/view/filter';

import * as S from './styles';

interface Props<T extends { label: string; value: any }> {
	options?: T[];
	currentValue?: T[];
	disabled?: boolean;
	filterKey?: string;
	onChangeValue?: (item: T, key: string, filterType: FilterType) => void;
	renderItem?: (item?: T) => ReactNode;
}

function DefaultChip<T extends { label: string; value: any }>({
	options = [],
	disabled = false,
	currentValue,
	filterKey = '',
	onChangeValue,
	renderItem,
}: Props<T>): React.ReactElement {
	if (options.length > 1) {
		throw Error('CRChips.Default: options.length should be under 1');
	}

	const isActive = useMemo(() => !!currentValue?.[0], [currentValue]);
	const onClick = () => {
		onChangeValue?.(options[0], filterKey, 'toggle');
	};

	return (
		<S.Container onClick={onClick} disabled={disabled} $isActive={isActive}>
			{isActive && <S.CheckIcon src={Assets.icon.check} alt='checkIcon' />}
			<S.ChipLabel>{renderItem?.(options?.[0]) ?? options[0]?.label ?? ''}</S.ChipLabel>
		</S.Container>
	);
}

export default DefaultChip;
