import React from 'react';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRIcon from 'components/base/CRIcon';
import CRInput from 'components/base/CRInput';
import { CRText } from 'components/base/CRText';

import EDocClientPanelBase from '../EDocClientPanelBase';
import * as S from './styles';

function EDocPageNotFound() {
	return (
		<EDocClientPanelBase>
			<S.FormContainer>
				<FlexContainer align='center' direction='column'>
					<CRIcon src={Assets.icon.report} width='3.6rem' height='3.6rem' />
					<CRText typography='h4' color='primary60' text='존재하지 않는 페이지' margin='1rem 0' />
					<FlexContainer direction='column' align='center'>
						<CRText text='올바른 접근이 아닙니다.' />
						<CRText text='주소가 올바른지 확인하거나 ' />
						<CRText text='케어링 고객센터로 문의하시기 바랍니다.' />
					</FlexContainer>
				</FlexContainer>
			</S.FormContainer>
		</EDocClientPanelBase>
	);
}

export default EDocPageNotFound;
