import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import Colors from 'common/colors';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { Toast } from 'components/base/CRToast';
import InformationSheet from 'components/ui/InformationSheet';
import { FullTimeEmployeeTOAdjustment, displayTOMonth } from 'lib';
import {
	useGetFullTimeEmployeeAnnualTOWorkYearCntAdjust,
	useSaveFullTimeEmployeeAnnualTOWorkYearCntAdjust,
} from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import {
	FullTimeEmployeeAnnualTOWorkYearAdjustDTO,
	FullTimeEmployeeDTO,
} from 'types/dto/full-time-employee';

import { AnnualTOAdjustmentFormRef } from '.';

interface Props {
	fullTimeEmployee: FullTimeEmployeeDTO;
	hideDialog: () => void;
	onSubmit?: (formData: FullTimeEmployeeAnnualTOWorkYearAdjustDTO) => void;
	editItem?: FullTimeEmployeeAnnualTOWorkYearAdjustDTO;
	setIsValid: (isValid: boolean) => void;
}

function AnnualTOWorkYearAdjustForm(
	{ fullTimeEmployee, hideDialog, onSubmit, editItem, setIsValid }: Props,
	ref,
): React.ReactElement {
	const [data, setData] = useState<FullTimeEmployeeAnnualTOWorkYearAdjustDTO | undefined>(
		undefined,
	);
	const {
		control,
		watch,
		formState: { isValid },
	} = useForm<{
		adjustmentType: string;
		adjustmentCnt: number;
		adjustmentHourCnt: number;
		reason: string;
	}>({
		defaultValues: {
			adjustmentCnt: editItem?.adjustWorkMonthCnt ?? 0,
			reason: editItem?.workYearCntAdjustDesc ?? '',
		},
		resolver: yupResolver(FullTimeEmployeeTOAdjustment),
	});

	const getFullTimeEmployeeAnnualTOWorkYearCntAdjust =
		useGetFullTimeEmployeeAnnualTOWorkYearCntAdjust();
	const saveFullTimeEmployeeAnnualTOWorkYearCntAdjust =
		useSaveFullTimeEmployeeAnnualTOWorkYearCntAdjust(
			(client) => {
				client.invalidateQueries([
					endpoint.getFullTimeEmployeeAnnualTOHistories.key,
					{
						centerId: fullTimeEmployee.centerId,
						employeeId: fullTimeEmployee.employeeId,
						memberId: fullTimeEmployee.memberId,
					},
				]);
				client.invalidateQueries([
					endpoint.getFullTimeEmployeeDetailInfo.key,
					{
						centerId: fullTimeEmployee.centerId,
						employeeId: fullTimeEmployee.employeeId,
						memberId: fullTimeEmployee.memberId,
					},
				]);
				hideDialog();
				Toast.success('근무년수 조정이 성공하였습니다.');
			},
			(client, error) => {
				Toast.error(error.message);
				hideDialog();
			},
		);

	const submit = async () => {
		if (!data) return;
		const form = {
			...editItem,
			rowType: data?.rowType ?? '',
			employeeId: fullTimeEmployee.employeeId,
			centerId: fullTimeEmployee.centerId,
			joinDate: data.joinDate,
			workAnnualItemCd: 'CMN191.10',
			beforeWorkMonthCnt: data.beforeWorkMonthCnt,
			beforeWorkYearCntNm: data.beforeWorkYearCntNm,
			adjustWorkMonthCnt: watch('adjustmentCnt'),
			afterWorkMonthCnt: data.beforeWorkMonthCnt + watch('adjustmentCnt'),
			afterWorkYearCntNm: displayTOMonth(data.beforeWorkMonthCnt + watch('adjustmentCnt')),
			workYearCntAdjustDesc: watch('reason'),
		};
		if (onSubmit) {
			onSubmit(form);
		} else {
			await saveFullTimeEmployeeAnnualTOWorkYearCntAdjust.mutateAsync(form);
		}
	};

	const fetchInitialData = useCallback(async () => {
		if (editItem) {
			setData(editItem);
			return;
		}
		const result = await getFullTimeEmployeeAnnualTOWorkYearCntAdjust.mutateAsync({
			centerId: fullTimeEmployee.centerId,
			employeeId: fullTimeEmployee.employeeId,
			memberId: fullTimeEmployee.memberId,
		});
		if (result) setData(result);
	}, [editItem]);

	useEffect(() => {
		fetchInitialData();
	}, []);

	useEffect(() => {
		setIsValid(isValid);
	}, [isValid]);

	useImperativeHandle(
		ref,
		() => ({
			submit,
		}),
		[submit],
	);

	if (!data) return <div />;

	return (
		<>
			<CRInputLabel label='조정' isRequired>
				<InformationSheet
					type='gray'
					items={[
						[
							{
								label: '입사일 기준 근무년수',
								value: displayTOMonth(data.beforeWorkMonthCnt),
								valueStyle: {
									height: '4rem',
								},
							},
						],
						[
							{
								label: '조정개월수',
								value: (
									<Controller
										control={control}
										name='adjustmentCnt'
										render={({ field }) => (
											<CRInput.Counter
												value={field.value}
												onChange={field.onChange}
												suffix='개월'
												plusColor={Colors.blue}
												minusColor={Colors.red}
												renderValue={(value) => `${value > 0 ? '+' : ''}${displayTOMonth(value)}`}
												min={0}
											/>
										)}
									/>
								),
								valueStyle: {
									height: '4rem',
								},
							},
						],
						[
							{
								type: 'divider',
							},
						],
						[
							{
								type: 'highlight',
								label: '조정 후 근무년수',
								value: displayTOMonth(data.beforeWorkMonthCnt + watch('adjustmentCnt')),
								valueStyle: {
									height: '4rem',
								},
							},
						],
					]}
				/>
			</CRInputLabel>
			<Controller
				control={control}
				name='reason'
				render={({ field }) => (
					<CRInputLabel label='사유' isRequired renderRightAddon={`${field.value?.length ?? 0}/50`}>
						<CRInput.TextArea
							placeholder='사유 입력'
							maxLength={50}
							value={field.value}
							onChange={field.onChange}
						/>
					</CRInputLabel>
				)}
			/>
		</>
	);
}

export default React.forwardRef<AnnualTOAdjustmentFormRef, Props>(AnnualTOWorkYearAdjustForm);
