import React, { useRef, useState } from 'react';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes } from 'lib/hook/react-query';
import {
	FullTimeEmployeeAnnualTOGenerateAdjustDTO,
	FullTimeEmployeeAnnualTORemainAdjustDTO,
	FullTimeEmployeeAnnualTOWorkYearAdjustDTO,
	FullTimeEmployeeDTO,
} from 'types/dto/full-time-employee';

import AnnualTOGenerateAdjustForm from './AnnualTOGenerateAdjustForm';
import AnnualTORemainAdjustForm from './AnnualTORemainAdjustForm';
import AnnualTOWorkYearAdjustForm from './AnnualTOWorkYearAdjustForm';
import * as S from './styles';

interface Props {
	fullTimeEmployee: FullTimeEmployeeDTO;
	hideDialog: () => void;
	editItem?:
		| FullTimeEmployeeAnnualTOGenerateAdjustDTO
		| FullTimeEmployeeAnnualTOWorkYearAdjustDTO
		| FullTimeEmployeeAnnualTORemainAdjustDTO;
	onSubmit?: (
		data:
			| FullTimeEmployeeAnnualTOGenerateAdjustDTO
			| FullTimeEmployeeAnnualTOWorkYearAdjustDTO
			| FullTimeEmployeeAnnualTORemainAdjustDTO,
	) => void;
	adjustType?: string;
}

export interface AnnualTOAdjustmentFormRef {
	submit: () => void;
}

function AnnualTOAdjustmentDialog({
	fullTimeEmployee,
	hideDialog,
	editItem,
	onSubmit,
	adjustType,
}: Props): React.ReactElement {
	const [adjustmentType, setAdjustmentType] = useState<string>(adjustType ?? 'CMN191.10');
	const annualTOWorkYearAdjustFormRef = useRef<AnnualTOAdjustmentFormRef>(null);
	const annualTOGenerateAdjustFormRef = useRef<AnnualTOAdjustmentFormRef>(null);
	const annualTORemainAdjustFormRef = useRef<AnnualTOAdjustmentFormRef>(null);
	const [isValid, setIsValid] = useState<boolean>(false);
	const { data: commonCodes = { CMN191: [] } } = useCommonCodes(
		{ comCdGroupNms: ['CMN191'] },
		commonCodeAdapter,
	);

	const handleSubmit = async () => {
		if (adjustmentType === 'CMN191.10') {
			await annualTOWorkYearAdjustFormRef.current?.submit();
		} else if (adjustmentType === 'CMN191.20') {
			await annualTOGenerateAdjustFormRef.current?.submit();
		} else if (adjustmentType === 'CMN191.30') {
			await annualTORemainAdjustFormRef.current?.submit();
		}
	};

	return (
		<CRDialog
			onClickClose={hideDialog}
			type='S'
			title='연차 정보 조정'
			body={
				<S.Container>
					<CRInputLabel label='항목' isRequired>
						<CRInput.Selector
							disabled={!!editItem}
							items={commonCodes.CMN191}
							currentValue={adjustmentType ? { value: adjustmentType, label: '' } : undefined}
							onChangeValue={(item) => setAdjustmentType(item.value)}
						/>
					</CRInputLabel>
					{adjustmentType === 'CMN191.10' && (
						<AnnualTOWorkYearAdjustForm
							ref={annualTOWorkYearAdjustFormRef}
							editItem={editItem as FullTimeEmployeeAnnualTOWorkYearAdjustDTO}
							fullTimeEmployee={fullTimeEmployee}
							hideDialog={hideDialog}
							onSubmit={onSubmit}
							setIsValid={setIsValid}
						/>
					)}
					{adjustmentType === 'CMN191.20' && (
						<AnnualTOGenerateAdjustForm
							ref={annualTOGenerateAdjustFormRef}
							editItem={editItem as FullTimeEmployeeAnnualTOGenerateAdjustDTO}
							fullTimeEmployee={fullTimeEmployee}
							hideDialog={hideDialog}
							onSubmit={onSubmit}
							setIsValid={setIsValid}
						/>
					)}
					{adjustmentType === 'CMN191.30' && (
						<AnnualTORemainAdjustForm
							ref={annualTORemainAdjustFormRef}
							editItem={editItem as FullTimeEmployeeAnnualTORemainAdjustDTO}
							fullTimeEmployee={fullTimeEmployee}
							hideDialog={hideDialog}
							onSubmit={onSubmit}
							setIsValid={setIsValid}
						/>
					)}
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						disabled={!isValid}
						palette='primary'
						size='default'
						onClick={handleSubmit}>
						조정
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}

export default React.memo(AnnualTOAdjustmentDialog);
