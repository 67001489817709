import React from 'react';

import { CheckOption } from 'components/base/Selections/type';
import useFullTimeEmployeePage from 'lib/hook/full-time-employee/useFullTimeEmployeePage';
import { EditableEmployeeFormTypes } from 'lib/provider/employee';
import { FullTimeEmployeeDetailInfoDTO } from 'types/dto/full-time-employee';

import {
	useMyAccountInfo,
	useUpdateFullTimeEmployeeDetailInfo,
} from '../../../../lib/hook/react-query';
import { endpoint } from '../../../../lib/service/Api/endpoint';
import FullTimeEmployeeBasicInfoForm from './FullTimeEmployeeBasicInfoForm';
import FullTimeEmployeeBasicInfoView from './FullTimeEmployeeBasicInfoView';
import * as S from './styles';

interface Props {
	item?: FullTimeEmployeeDetailInfoDTO | null;
	options: {
		workState: CheckOption[];
		workForm: CheckOption[];
		duty: CheckOption[];
		partType: CheckOption[];
		disability: CheckOption[];
	};
}

export default function FullTimeEmployeeBasicInfoFormView({
	item,
	options,
}: Props): React.ReactElement {
	const {
		isFormEditable,
		startChangeEditSection,
		isEditSectionChanging,
		cancelChangeEditSection,
		currentEmployee,
	} = useFullTimeEmployeePage();
	const { data: myAccountInfo } = useMyAccountInfo();
	const updateFullTimeEmployeeDetailInfo = useUpdateFullTimeEmployeeDetailInfo(
		async (client, returnData, variables) => {
			if (returnData?.employeeId) {
				await client.invalidateQueries([
					endpoint.getFullTimeEmployeeDetailInfo.key,
					{
						centerId: Number(myAccountInfo?.centerId),
						memberId: Number(currentEmployee?.memberId),
						employeeId: Number(currentEmployee?.employeeId),
					},
				]);
			}
		},
	);

	const onStartChangeSection = async () => {
		if (item?.rejoinUpdateTargetYn) {
			await updateFullTimeEmployeeDetailInfo.mutateAsync({ ...item, rejoinUpdateTargetYn: false });
		}
		startChangeEditSection(EditableEmployeeFormTypes.기본정보);
	};

	const onCancelChangeSection = () => {
		cancelChangeEditSection();
	};

	if (!item) return <div />;

	return (
		<S.Container>
			{isFormEditable(EditableEmployeeFormTypes.기본정보) ? (
				<FullTimeEmployeeBasicInfoForm
					options={options}
					item={item}
					onStartChangeSection={onStartChangeSection}
					onCancelChangeSection={onCancelChangeSection}
					isEditSectionChanging={isEditSectionChanging}
				/>
			) : (
				<FullTimeEmployeeBasicInfoView
					options={options}
					item={item}
					onChangeMode={onStartChangeSection}
				/>
			)}
		</S.Container>
	);
}
