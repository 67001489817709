import React, { useMemo } from 'react';

import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import { Toast } from 'components/base/CRToast';
import { CheckOption } from 'components/base/Selections/type';
import EmployeeManagerHistoryDialog from 'components/domain/dialog/EmployeeManagerHistoryDialog';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import { useCommonCodes, useMyAccountInfo, useUpdateEmployeeWorkState } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { EmployeeDTO, EmployeeDetailInfoDTO } from 'types/api/employee';
import { WorkStateCd } from 'types/view/employee';

import * as S from './styles';

export default function EmployeeBasicStatusFormView() {
	const { currentEmployee, currentEmployeeWorkState, setCurrentEmployee, employeeDetailData } =
		useEmployeePage();
	const dialog = useDialog();
	const { data: employeeStatusCode } = useCommonCodes({ comCdGroupNms: ['CMN068'] });
	const { data: myAccountInfo } = useMyAccountInfo();

	const workStateCodes = useMemo(
		() =>
			(employeeStatusCode ?? [])
				.filter((item) => item.comCdDesc === '1')
				.map(
					(item) =>
						({
							label: item.korComCdAliasNm,
							value: item.comCdId,
						}) as CheckOption,
				),
		[employeeStatusCode],
	);

	const { mutateAsync: updateWorkState } = useUpdateEmployeeWorkState(
		(client, returnData, variables) => {
			if (!currentEmployee) return;
			const label =
				workStateCodes.find((state) => state.value === variables.workStateCd)?.label ?? '';
			client.setQueryData(
				[
					endpoint.getEmployeeDetailInfo.key,
					{
						centerId: Number(currentEmployee?.centerId),
						memberId: Number(currentEmployee?.memberId),
						employeeId: Number(currentEmployee?.employeeId),
					},
				],
				(data: any) => {
					const newCache = {
						...(data as EmployeeDetailInfoDTO),
						workStateCd: variables.workStateCd,
					} as EmployeeDetailInfoDTO;

					return newCache;
				},
			);

			client.setQueryData(
				[
					endpoint.getEmployees.key,
					{
						centerIds: [myAccountInfo?.centerId],
					},
				],
				(data: EmployeeDTO[] = []) => {
					const employeesCache = data;
					const newCache = employeesCache?.map((employee) => {
						if (employee.employeeId === returnData?.employeeId) {
							return {
								...employee,
								workStateNm: label,
							};
						}
						return employee;
					});

					return newCache;
				},
			);

			setCurrentEmployee({
				...currentEmployee,
				workStateNm: label,
			});
			Toast.success(`${currentEmployee.korMemberNm} 님의 상태를 변경하였습니다.`);
		},
	);

	const handleChangeWorkState = async (item: CheckOption) => {
		if (!currentEmployee) return;
		await updateWorkState({
			centerId: Number(currentEmployee?.centerId),
			employeeId: Number(currentEmployee?.employeeId),
			workStateCd: item.value,
		});
	};

	const currentWorkState = workStateCodes.find(
		(option) => option.value === currentEmployeeWorkState.status,
	);

	const handleClickManagerHistory = () => {
		dialog.showDialog(() => <EmployeeManagerHistoryDialog currentEmployee={currentEmployee} />);
	};

	const isResign = employeeDetailData?.workStateCd === WorkStateCd.퇴사;

	return (
		<S.Table $isDisabled={isResign}>
			<S.TableLabelColumn style={{ width: '9.6rem' }}>상태</S.TableLabelColumn>
			<S.TableValueColumn style={{ width: '44rem' }}>
				<CRInput.Selector
					disabled={isResign}
					items={
						isResign
							? [
									{
										label: '퇴사',
										value: '퇴사',
									},
								]
							: workStateCodes
					}
					type='small'
					onChangeValue={handleChangeWorkState}
					currentValue={
						isResign
							? {
									label: '퇴사',
									value: '퇴사',
								}
							: currentWorkState
					}
					placeholder='상태 선택'
				/>
			</S.TableValueColumn>
			<S.TableLabelColumn style={{ width: '9.6rem' }}>사회복지사</S.TableLabelColumn>
			<S.TableValueColumn style={{ width: '44rem' }}>
				{currentEmployeeWorkState?.manager || '-'}
				<S.RightButtonContainer>
					<CRButton.Default
						disabled={isResign}
						size='xSmall'
						palette='gray'
						type='outlined'
						onClick={handleClickManagerHistory}>
						변경/이력
					</CRButton.Default>
				</S.RightButtonContainer>
			</S.TableValueColumn>
		</S.Table>
	);
}
