import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 2.4rem;
	overflow: hidden;
	gap: 2.4rem;
`;

export const TabContainer = styled.div`
	padding-top: 1.6rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.4rem;
`;
