import { endpoint } from 'lib/service/Api/endpoint';

import useCRQuery from '../base/useCRQuery';

export const useVisitCareRoundingHistory = useCRQuery(
	endpoint.getVisitCareRoundingHistory.key,
	'getVisitCareRoundingHistory',
	{
		enabledKey: ['serviceYm', 'centerId'],
	},
);
export const useFamilyCareRoundingHistory = useCRQuery(
	endpoint.getFamilyCareRoundingHistory.key,
	'getFamilyCareRoundingHistory',
	{
		enabledKey: ['serviceYm', 'centerId'],
	},
);

export const useVisitBathRoundingHistory = useCRQuery(
	endpoint.getVisitBathRoundingHistory.key,
	'getVisitBathRoundingHistory',
	{
		enabledKey: ['serviceYm', 'centerId'],
	},
);

export const useRoundingCompletionStatus = useCRQuery(
	endpoint.getRoundingCompletionStatus.key,
	'getRoundingCompletionStatus',
	{
		enabledKey: ['centerId'],
	},
);
