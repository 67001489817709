import React, { useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import dayjs from 'dayjs';

import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { displayDateToYYYYMM } from 'lib';
import { useEDocIssueInfoBefore } from 'lib/hook/react-query/query/edoc';
import { EDocPledgeDTO } from 'types/api';
import { EmployeeDTO } from 'types/api/employee';
import { DeviceUnusedApplicationFormTypes } from 'types/view/eDoc';

import * as S from './styles';

interface Props {
	templateCode: string;
	currentEmployee?: EmployeeDTO;
	formContext: UseFormReturn<DeviceUnusedApplicationFormTypes, any, undefined>;
}

// 단말기 미사용 신청서
export default function EDocDeviceUnusedApplicationForm({
	templateCode,
	currentEmployee,
	formContext,
}: Props): React.ReactElement {
	const { data: eDocBeforeIssueInfo } = useEDocIssueInfoBefore({
		employeeId: currentEmployee?.employeeId,
		centerId: currentEmployee?.centerId,
		templateCode,
	});

	useEffect(() => {
		if (!eDocBeforeIssueInfo) return;
		formContext.setValue('data', eDocBeforeIssueInfo);

		const { employee } = (eDocBeforeIssueInfo as unknown as EDocPledgeDTO).data;
		if (employee) {
			formContext.setValue('afterPhoneNo', employee?.mobilePhoneNo);
			formContext.setValue('phoneNo', employee?.mobilePhoneNo);
		}
	}, [eDocBeforeIssueInfo]);

	useEffect(() => {
		formContext.setValue('requestDate', new Date());
	}, []);

	return (
		<S.Container>
			<S.SubFormContainer>
				<CRInputLabel label='신청자' isRequired>
					<CRInput.Selector
						autoComplete
						disabled
						currentValue={{
							label: currentEmployee?.korMemberNm || '',
							value: currentEmployee?.employeeId,
						}}
						items={[
							{
								label: currentEmployee?.korMemberNm || '',
								value: currentEmployee?.employeeId,
							},
						]}
					/>
				</CRInputLabel>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='전화번호(전)'>
							<CRInput.Default
								value={value}
								onChange={onChange}
								placeholder='전화번호(전) 입력'
								type='number'
								maxLength={20}
							/>
						</CRInputLabel>
					)}
					name='beforePhoneNo'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='전화번호(후)' isRequired>
							<CRInput.Default
								value={value}
								onChange={onChange}
								placeholder='전화번호(후) 입력'
								type='number'
								maxLength={20}
							/>
						</CRInputLabel>
					)}
					name='afterPhoneNo'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='신청일자' isRequired>
							<CRInput.DatePicker
								value={value}
								onChangeValue={onChange}
								placeholder='신청일자 선택'
							/>
						</CRInputLabel>
					)}
					name='requestDate'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel label='신청 사유' isRequired>
							<CRInput.TextArea
								onChange={onChange}
								value={value}
								placeholder='신청 사유 입력'
								maxLength={100}
							/>
						</CRInputLabel>
					)}
					name='requestReason'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='연락처' isRequired>
							<CRInput.Default
								value={value}
								onChange={onChange}
								placeholder='연락처 입력'
								type='number'
								maxLength={20}
							/>
						</CRInputLabel>
					)}
					name='phoneNo'
					control={formContext.control}
				/>
			</S.SubFormContainer>
		</S.Container>
	);
}
