import React, {
	Dispatch,
	ReactNode,
	SetStateAction,
	createContext,
	useMemo,
	useState,
} from 'react';
import { useLocation } from 'react-router';

import { ResponseCode } from 'types/api';
import { EDocClientOpenData, EDocClientOpenRequestDTO } from 'types/api/eDoc';
import { Data } from 'types/api/send';

interface Props {
	children: ReactNode;
}

interface Response {
	date?: any;
	code?: string | ResponseCode;
	message?: string;
}

interface Value {
	authInfo: EDocClientOpenRequestDTO | undefined;
	eDocInfo: EDocClientOpenData | undefined;
	eDocConfirmResult: Response | undefined;
	isErp: boolean;
	setAuthInfo: Dispatch<SetStateAction<EDocClientOpenRequestDTO | undefined>>;
	setEDocInfo: Dispatch<SetStateAction<Data | undefined>>;
	setEDocConfirmResult: Dispatch<SetStateAction<Response | undefined>>;
}

export const EDocClientPageContext = createContext<Value | null>(null);

function EDocClientPageProvider({ children }: Props) {
	const [authInfo, setAuthInfo] = useState<EDocClientOpenRequestDTO>();
	const [eDocInfo, setEDocInfo] = useState<Data>();
	const [eDocConfirmResult, setEDocConfirmResult] = useState<Response>();
	const location = useLocation();
	const isErp = location.search.includes('isErp=true');

	const value = useMemo(
		() => ({
			authInfo,
			setAuthInfo,
			eDocInfo,
			setEDocInfo,
			eDocConfirmResult,
			setEDocConfirmResult,
			isErp,
		}),
		[authInfo, eDocInfo, eDocConfirmResult],
	);

	return <EDocClientPageContext.Provider value={value}>{children}</EDocClientPageContext.Provider>;
}

export default EDocClientPageProvider;
