import React, { useMemo } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';

import dayjs from 'dayjs';

import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRRequiredMark from 'components/base/CRRequiredMark';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import useContractTask from 'lib/hook/view/contract/useContractTask';
import { FileDetailDTO } from 'types/dto';

import * as S from './styles';

function LongTermCareRecognitionForm(): React.ReactElement {
	const { form, commonCodes, disabled } = useContractTask();

	const { fields, insert, remove } = useFieldArray({
		control: form.control,
		name: 'recipient.longTerm.longTermServices',
	});

	const options = useMemo(
		() => (commonCodes?.CMN006 ?? []).filter((item) => item.data?.etcDesc1 === 'Y'),
		[commonCodes?.CMN006],
	);

	const handleClickPersonalLongTermCarePlan = (files: FileDetailDTO[]) => {
		form.setValue(`recipient.longTerm.longTermUsePlanFile.fileDetails`, files);
	};

	const handleClickLongTermCareCertificatePlan = (files: FileDetailDTO[]) => {
		form.setValue(`recipient.longTerm.longTermCertiFile.fileDetails`, files);
	};

	const handleClickWelfareEquipmentConfirmation = (files: FileDetailDTO[]) => {
		form.setValue(`recipient.longTerm.welfareDeviceConfirmFile.fileDetails`, files);
	};

	const handleChangeServices = (items: CheckOption[]) => {
		options.forEach((option) => {
			const checked = items.some((item) => item.value === option.value);
			if (checked) {
				const existField = fields.find((field) => field.serviceTypeCd === option.value);
				if (!existField) {
					const sortedArray = items.slice().sort((a, b) => (a.value < b.value ? -1 : 1));
					const insertIndex = sortedArray.findIndex(
						(item) => !fields.find((field) => field.serviceTypeCd === item.value),
					);
					insert(insertIndex, {
						serviceTypeCd: option.value,
						serviceCntStandardCd: '',
						serviceCnt: 0,
						serviceOfferTimeCd: '',
						longTermPayFee: 0,
					});
					return;
				}
			} else {
				const index = fields.findIndex((field) => field.serviceTypeCd === option.value);
				if (index >= 0) remove(index);
			}
		});
	};

	return (
		<S.Container>
			<S.Form>
				<S.Table>
					<S.TableRow>
						<S.TableLabelColumn style={{ width: '12rem' }}>
							인정번호 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '40rem' }}>
							<S.LongTermNoContainer>
								L
								<Controller
									render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
										<S.LongTermNo>
											<CRInput.TableInput
												placeholder='인정번호 입력'
												onChange={(item) => {
													onChange(item);
												}}
												value={value}
												maxLength={10}
												disabled={disabled}
												type='number'
											/>
										</S.LongTermNo>
									)}
									name='recipient.longTerm.longTermNo'
									control={form?.control}
								/>
								-
								<S.LongTermNoEtc>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TableInput
												placeholder='000'
												type='number'
												onChange={onChange}
												value={value}
												maxLength={3}
												disabled={disabled}
											/>
										)}
										name='recipient.longTerm.longTermMajorChangeNo'
										control={form?.control}
									/>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TableInput
												placeholder='000'
												type='number'
												onChange={onChange}
												value={value}
												maxLength={3}
												disabled={disabled}
											/>
										)}
										name='recipient.longTerm.longTermMinorChangeNo'
										control={form?.control}
									/>
								</S.LongTermNoEtc>
							</S.LongTermNoContainer>
						</S.TableValueColumn>
						<S.TableLabelColumn style={{ width: '12rem' }} rowSpan={5}>
							복지용구
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '40rem' }} rowSpan={5}>
							<Controller
								render={({
									field: { onBlur, ref, onChange, value = [] },
									formState: { errors },
								}) => (
									<S.WelfareContainer>
										{(commonCodes?.CMN164 ?? []).map((item) => (
											<S.TagContainer
												key={item.value}
												$selected={value?.includes(item.value)}
												onClick={() => {
													if (disabled) return;
													if (value?.includes(item.value)) {
														onChange(value.filter((v) => v !== item.value));
													} else {
														onChange([...value, item.value]);
													}
												}}>
												{item.label}
											</S.TagContainer>
										))}
									</S.WelfareContainer>
								)}
								name='recipient.longTerm.welfareItemCds'
								control={form?.control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn style={{ width: '12rem' }}>
							인정등급 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '40rem' }}>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRInput.Selector
										placeholder='인정등급 선택'
										type='small'
										items={commonCodes?.CMN042 ?? []}
										onChangeValue={(item) => onChange(item.value)}
										currentValue={value ? { label: '', value } : undefined}
										disabled={disabled}
									/>
								)}
								name='recipient.longTerm.longTermGradeCd'
								control={form?.control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn style={{ width: '12rem' }}>
							인정기간 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '40rem' }}>
							<S.PeriodContainer>
								<S.PeriodItemContainer>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.DatePicker
												placeholder='시작일'
												type='S'
												value={value ? dayjs(value).toDate() : undefined}
												onChangeValue={(date) =>
													onChange(dayjs(date).format('YYYY-MM-DD hh:mm:ss'))
												}
												disabled={disabled}
											/>
										)}
										name='recipient.longTerm.longTermStartDate'
										control={form?.control}
									/>
								</S.PeriodItemContainer>
								<S.PeriodItemContainer>~</S.PeriodItemContainer>
								<S.PeriodItemContainer>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.DatePicker
												placeholder='종료일'
												type='S'
												value={value ? dayjs(value).toDate() : undefined}
												onChangeValue={(date) =>
													onChange(dayjs(date).format('YYYY-MM-DD hh:mm:ss'))
												}
												disabled={disabled}
											/>
										)}
										name='recipient.longTerm.longTermEndDate'
										control={form?.control}
									/>
								</S.PeriodItemContainer>
							</S.PeriodContainer>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn style={{ width: '12rem' }}>
							본인부담률 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '40rem' }}>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRInput.Selector
										placeholder='본인부담률 선택'
										type='small'
										items={commonCodes?.CMN043 ?? []}
										onChangeValue={(item) => onChange(item.value)}
										currentValue={value ? { label: '', value } : undefined}
										disabled={disabled}
									/>
								)}
								name='recipient.longTerm.burdenRateCd'
								control={form?.control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn style={{ width: '12rem' }}>
							이용가능급여 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '40rem' }}>
							<CRCheckBoxGroup
								checkType='multiple'
								type='checkbox'
								gap={0.8}
								options={options}
								value={fields.map((item) => ({ label: '', value: item.serviceTypeCd }))}
								onChange={handleChangeServices}
								disabled={disabled}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn style={{ width: '12rem' }}>
							급여 한도 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '40rem' }} colSpan={3}>
							{fields.map((field, index) => (
								<CRCardFormLayout
									label={options.find((option) => option.value === field.serviceTypeCd)?.label}
									key={field.id}>
									<S.ServiceMaxContainer>
										<S.ServiceMaxItemContainer>
											<Controller
												render={({
													field: { onBlur, ref, onChange, value },
													formState: { errors },
												}) => (
													<CRInputLabel label='이용 주기' isRequired showOverflow>
														<CRInput.Selector
															placeholder='주기'
															items={commonCodes?.CMN064}
															currentValue={value ? { label: '', value } : undefined}
															onChangeValue={(item) => onChange(item.value)}
															disabled={disabled}
														/>
													</CRInputLabel>
												)}
												name={`recipient.longTerm.longTermServices.${index}.serviceCntStandardCd`}
												control={form?.control}
											/>
										</S.ServiceMaxItemContainer>
										<S.ServiceMaxItemContainer>
											<Controller
												render={({
													field: { onBlur, ref, onChange, value },
													formState: { errors },
												}) => (
													<CRInputLabel label='이용 횟수' isRequired>
														<CRInput.Default
															placeholder='0'
															suffix='회'
															value={value.toString()}
															onChange={onChange}
															type='number'
															disabled={disabled}
														/>
													</CRInputLabel>
												)}
												name={`recipient.longTerm.longTermServices.${index}.serviceCnt`}
												control={form?.control}
											/>
										</S.ServiceMaxItemContainer>
										<S.ServiceMaxItemContainer>
											<Controller
												render={({
													field: { onBlur, ref, onChange, value },
													formState: { errors },
												}) => (
													<CRInputLabel label='이용 시간' isRequired showOverflow>
														<CRInput.Selector
															placeholder='이용 시간'
															items={commonCodes?.CMN084}
															currentValue={value ? { label: '', value } : undefined}
															onChangeValue={(item) => onChange(item.value)}
															disabled={disabled}
														/>
													</CRInputLabel>
												)}
												name={`recipient.longTerm.longTermServices.${index}.serviceOfferTimeCd`}
												control={form?.control}
											/>
										</S.ServiceMaxItemContainer>
										<S.ServiceMaxItemContainer>
											<Controller
												render={({
													field: { onBlur, ref, onChange, value },
													formState: { errors },
												}) => (
													<CRInputLabel label='장기요양급여 비용' isRequired>
														<CRInput.Default
															placeholder='0'
															suffix='원'
															value={value.toString()}
															onChange={onChange}
															type='number'
															disabled={disabled}
														/>
													</CRInputLabel>
												)}
												name={`recipient.longTerm.longTermServices.${index}.longTermPayFee`}
												control={form?.control}
											/>
										</S.ServiceMaxItemContainer>
									</S.ServiceMaxContainer>
								</CRCardFormLayout>
							))}
						</S.TableValueColumn>
					</S.TableRow>
				</S.Table>
			</S.Form>
			<CRInput.TableFileUploader
				uploaderList={[
					{
						label: '개인별 장기 요양 이용 계획서',
						isRequired: true,
						file: form.watch(`recipient.longTerm.longTermUsePlanFile`),
						onClickUpload: handleClickPersonalLongTermCarePlan,
						disabled,
					},
					{
						label: '장기요양 인정서',
						isRequired: true,
						file: form.watch(`recipient.longTerm.longTermCertiFile`),
						onClickUpload: handleClickLongTermCareCertificatePlan,
						disabled,
					},
					{
						label: '복지용구 확인서',
						isRequired: false,
						file: form.watch(`recipient.longTerm.welfareDeviceConfirmFile`),
						onClickUpload: handleClickWelfareEquipmentConfirmation,
						disabled,
					},
				]}
			/>
		</S.Container>
	);
}

export default LongTermCareRecognitionForm;
