import React, { useEffect, useState } from 'react';

import * as Accordion from '@radix-ui/react-accordion';

import CRPortal from 'components/base/CRPortal';
import CRTab from 'components/base/CRTab';
import { CheckOption } from 'components/base/Selections/type';
import ChangeEmployeeEmployeeBasicForm from 'components/domain/form/ChangeEmployeeEmployeeBasicForm';
import ChangeEmployeeEmployeeSalaryForm from 'components/domain/form/ChangeEmployeeEmployeeSalaryForm';
import ChangeEmployeeOtherWorkingForm from 'components/domain/form/ChangeEmployeeOtherWorkingForm';
import ChangeEmployeeTrainingForm from 'components/domain/form/ChangeEmployeeTrainingForm';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import useContractChangeEmployeeTask from 'lib/hook/view/contract/useContractChangeEmployeeTask';

import EmptyDescription from '../../../components/ui/EmptyDescription';
import * as S from './styles';

function ChangeEmployeeEmployeeBasicPage(): React.ReactElement {
	const { form } = useContractChangeEmployeeTask();

	const [tabs, setTabs] = useState<CheckOption[]>([]);
	const [currentTab, setCurrentTab] = useState<CheckOption | null>(null);
	const index = tabs.findIndex((tab) => tab.value === currentTab?.value);

	const handleChangeTab = (tab: CheckOption) => {
		setCurrentTab(tab);
	};

	const handleDeleteTab = (tab: CheckOption) => {
		form.setValue(
			'employees',
			form.watch('employees').filter((item) => item.base.employeeId !== tab.value),
		);
	};

	const forms = [
		{
			id: '#1',
			label: '기본 정보',
			component: index >= 0 ? <ChangeEmployeeEmployeeBasicForm index={index} /> : <div />,
		},
		{
			id: '#2',
			label: '자격증 및 교육 정보',
			component: index >= 0 ? <ChangeEmployeeTrainingForm index={index} /> : <div />,
		},
		{
			id: '#3',
			label: '타근무 정보',
			component: index >= 0 ? <ChangeEmployeeOtherWorkingForm index={index} /> : <div />,
		},
		{
			id: '#4',
			label: '임금 정보',
			component: index >= 0 ? <ChangeEmployeeEmployeeSalaryForm index={index} /> : <div />,
		},
	];

	useEffect(() => {
		const tabItems = [
			...(form?.watch('employees') ?? []).map((item, index) => ({
				label: `직원 ${index + 1}`,
				value: item.base.employeeId,
			})),
		];

		setTabs(tabItems);
		if (tabItems.length) {
			setCurrentTab(tabItems[0]);
		} else {
			setCurrentTab(null);
		}
	}, [form.watch('employees')?.length]);

	return (
		<>
			{!!tabs.length && (
				<CRPortal id='task_layout_portal'>
					<CRTab.Contract
						items={tabs}
						defaultActiveKey={currentTab?.value}
						onChange={handleChangeTab}
						onDelete={handleDeleteTab}
						limitCount={0}
					/>
				</CRPortal>
			)}
			{tabs.length ? (
				<Accordion.Root type='multiple' defaultValue={forms.map((form) => form.id)}>
					{forms.map((form) => (
						<Accordion.Item value={form.id} asChild>
							<TaskAccordion.Item>
								<Accordion.Header asChild>
									<Accordion.Trigger asChild>
										<TaskAccordion.Trigger href={form.id}>{form.label}</TaskAccordion.Trigger>
									</Accordion.Trigger>
								</Accordion.Header>
								<Accordion.Content asChild>
									<TaskAccordion.Content>{form.component}</TaskAccordion.Content>
								</Accordion.Content>
							</TaskAccordion.Item>
						</Accordion.Item>
					))}
				</Accordion.Root>
			) : (
				<S.EmptyContainer>
					<EmptyDescription
						title=''
						description={'연결된 직원 정보가 없습니다.\n급여 정보에서 직원을 입력해주세요.'}
					/>
				</S.EmptyContainer>
			)}
		</>
	);
}

export default ChangeEmployeeEmployeeBasicPage;
