import { endpoint } from 'lib/service/Api/endpoint';

import useCRMutation from './base/useCRMutation';
import useCRQuery from './base/useCRQuery';

// query
export const useMyTaskProcess = useCRQuery(endpoint.myTaskProcess.key, 'getMyTaskProcess');
export const useMyTaskWait = useCRQuery(endpoint.myTaskWait.key, 'getMyTaskWait');
export const useMyTaskComplete = useCRQuery(endpoint.myTaskComplete.key, 'getMyTaskComplete');
export const useMyTaskCount = useCRQuery(endpoint.myTaskCount.key, 'getMyTaskCount');
export const useMyTaskTypes = useCRQuery(endpoint.myTaskTypes.key, 'getMyTaskTypes');
export const useNewTask = useCRQuery(endpoint.newTaskTypes.key, 'getNewTaskTypes');

export const useAlarms = useCRQuery(endpoint.getAlarms.key, 'getAlarms');
export const useEmployees = useCRQuery(endpoint.getEmployees.key, 'getEmployees');
export const useEmployeePageBaseInfo = useCRQuery(
	endpoint.getEmployeeBaseInfo.key,
	'getEmployeeBaseInfo',
	{
		enabledKey: ['centerId', 'employeeId'],
	},
);
export const useEmployeePageDetailInfo = useCRQuery(
	endpoint.getEmployeeDetailInfo.key,
	'getEmployeeDetailInfo',
);

export const useEmployeePageHealthCheck = useCRQuery(
	endpoint.getEmployeeHealthCheck.key,
	'getEmployeeHealthCheck',
);
export const useCenterManagers = useCRQuery(endpoint.getCenterManager.key, 'getCenterManagers', {
	enabledKey: ['centerId'],
});

export const useEmployeePageCareerInfo = useCRQuery(
	endpoint.getEmployeeCareerInfo.key,
	'getEmployeeCareerInfo',
);

export const useEmployeePageLicenseInfo = useCRQuery(
	endpoint.getEmployeeLicenseInfo.key,
	'getEmployeeLicenseInfo',
);

export const useEmployeePageEduInfo = useCRQuery(
	endpoint.getEmployeeEduInfo.key,
	'getEmployeeEduInfo',
);

export const useEmployeePageHistoryOfManager = useCRQuery(
	endpoint.getEmployeeHistoryOfManager.key,
	'getEmployeeHistoryOfManager',
);

export const useSchedule = useCRQuery(endpoint.getSchedule.key, 'getSchedule');

export const useMyMenuInfo = useCRQuery(endpoint.getMyMenuInfo.key, 'getMyMenuInfo');

export const useEmployeePageScheduleDetail = useCRQuery(
	endpoint.getScheduleDetail.key,
	'getEmployeeScheduleDetail',
);

export const useRecipientScheduleDetail = useCRQuery(
	endpoint.getScheduleDetail.key,
	'getRecipientScheduleDetail',
);

export const useCenterList = useCRQuery(endpoint.getCenterList.key, 'getCenterList');

export const useGuaranteedCenters = useCRQuery(
	endpoint.getGuaranteedCenters.key,
	'getGuaranteedCenters',
);

export const useRecordingSheets = useCRQuery(
	endpoint.getRecordingSheets.key,
	'getRecordingSheets',
	{
		enabledKey: ['centerId', 'page', 'size', 'serviceStartDt', 'serviceEndDt'],
	},
);

export const useEmployeeSalarys = useCRQuery(endpoint.getEmployeeSalarys.key, 'getEmployeeSalarys');

// mutation
export const useCreateTask = useCRMutation(endpoint.createTask.key, 'createTask');
export const useNewSubTask = useCRMutation(endpoint.newSubTaskTypes.key, 'getNewTaskTypes');
export const useSubMyTask = useCRMutation(endpoint.subMyTask.key, 'getSubMyTask');
export const useCreateUploadUrl = useCRMutation(endpoint.createUploadUrl.key, 'createUploadUrl');
export const useCreateDownloadUrl = useCRMutation(
	endpoint.createDownloadUrl.key,
	'createDownloadUrl',
);

export const useUploadFile = useCRMutation(endpoint.uploadFile.key, 'uploadFile');
export const useUploadBurden = useCRMutation(endpoint.uploadBurden.key, 'uploadBurden');
export const useUploadCharge = useCRMutation(endpoint.uploadCharge.key, 'uploadCharge');
export const useUpdateEmployeeBaseInfo = useCRMutation(
	endpoint.saveEmployeeBaseInfo.key,
	'saveEmployeeBaseInfo',
);
export const useUpdateEmployeeWorkState = useCRMutation(
	endpoint.saveEmployeeWorkState.key,
	'saveEmployeeWorkState',
);
export const useEmployeePageDuplicateCheck = useCRMutation(
	endpoint.employeeCheckDuplicate.key,
	'employeeDuplicateCheck',
);
export const useCreateEmployee = useCRMutation(endpoint.createEmployee.key, 'createEmployee');
export const useUpdateEmployeeOtherCorpWork = useCRMutation(
	endpoint.saveEmployeeOtherCorpWork.key,
	'saveEmployeeOtherCorpWork',
);
export const useUpdateEmployeePayInfo = useCRMutation(
	endpoint.saveEmployeePayInfo.key,
	'saveEmployeePayInfo',
);
export const useCreateRecipient = useCRMutation(endpoint.createRecipient.key, 'createRecipient');

export const useUpdateEmployeeHealthCheck = useCRMutation(
	endpoint.saveEmployeeHealthCheck.key,
	'saveEmployeeHealthCheck',
);
export const useDeleteEmployeeHealthCheck = useCRMutation(
	endpoint.deleteEmployeeHealthCheck.key,
	'deleteEmployeeHealthCheck',
);

export const useUpdateEmployeeCareer = useCRMutation(
	endpoint.saveEmployeeCareer.key,
	'saveEmployeeCareer',
);

export const useDeleteEmployeeCareer = useCRMutation(
	endpoint.deleteEmployeeCareer.key,
	'deleteEmployeeCareer',
);

export const useUpdateEmployeeLicense = useCRMutation(
	endpoint.saveEmployeeLicense.key,
	'saveEmployeeLicense',
);

export const useDeleteEmployeeLicense = useCRMutation(
	endpoint.deleteEmployeeLicense.key,
	'deleteEmployeeLicense',
);

export const useDeleteFileDetail = useCRMutation(endpoint.deleteFileDetail.key, 'deleteFileDetail');

export const useUpdateEmployeeEducation = useCRMutation(
	endpoint.saveEmployeeEducation.key,
	'saveEmployeeEducation',
);

export const useDeleteEmployeeEducation = useCRMutation(
	endpoint.deleteEmployeeEducation.key,
	'deleteEmployeeEducation',
);

export const useUpdateEmployeeHistoryOfManager = useCRMutation(
	endpoint.saveEmployeeHistoryOfManager.key,
	'saveEmployeeHistoryOfManager',
);

export const useUpdateEmployeeScheduleDetail = useCRMutation(
	endpoint.saveEmployeeScheduleDetail.key,
	'updateEmployeeScheduleDetail',
);

export const useUpdateRecipientScheduleDetail = useCRMutation(
	endpoint.saveRecipientScheduleDetail.key,
	'updateRecipientScheduleDetail',
);

export const useResignEmployee = useCRMutation(endpoint.resignEmployee.key, 'resignEmployee');

export const useEmployeeResignInfo = useCRMutation(
	endpoint.getEmployeeResignInfo.key,
	'getEmployeeResignInfo',
);

export const useEmployeeWorkInfoList = useCRQuery(
	endpoint.getEmployeeWorkInfoList.key,
	'getEmployeeWorkInfoList',
	{
		enabledKey: ['centerId', 'yearMonth'],
	},
);

export * from './query';
export * from './mutation';
