import React from 'react';

import { Dayjs } from 'dayjs';

import CRDialog from 'components/base/CRDialog';
import InformationTable from 'components/ui/InformationTable';
import useDialog from 'lib/hook/util/useDialog';
import { EmployeeVacationInfoViewType } from 'types/view/workSchedule';

import * as S from './styles';

interface Props {
	targetDate: Dayjs;
	vacationDataOfTargetDate: EmployeeVacationInfoViewType;
}

function VacationViewDialog({ targetDate, vacationDataOfTargetDate }: Props): React.ReactElement {
	const { hideDialog } = useDialog();
	const handleHalfDayOffSelection =
		vacationDataOfTargetDate?.data?.vacaKindCd === 'CMN195.120'
			? `(${vacationDataOfTargetDate?.data?.startTime === '0900' ? '오전' : '오후'})`
			: '';

	const VACATION_INFO_TABLE_ITEM = [
		[
			{
				label: '일자',
				value: targetDate.format('YYYY.MM.DD'),
				labelStyle: { width: '10.8rem' },
			},
		],
		[
			{
				label: '휴가 종류',
				value: `${vacationDataOfTargetDate?.data?.vacaKindNm ?? '-'}${handleHalfDayOffSelection}`,
				labelStyle: { width: '10.8rem' },
			},
		],
		[
			{
				label: '비고',
				value: vacationDataOfTargetDate.data?.remark ?? '-',
				labelStyle: { width: '10.8rem' },
			},
		],
	];

	return (
		<CRDialog
			title={`${targetDate.format('M월 D일')} 휴가`}
			onClickClose={hideDialog}
			type='S'
			body={
				<S.Container>
					<InformationTable items={VACATION_INFO_TABLE_ITEM} />
				</S.Container>
			}
		/>
	);
}

export default React.memo(VacationViewDialog);
