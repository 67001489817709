import React, { useCallback } from 'react';

import dayjs from 'dayjs';

import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import { TagDetailDialog } from 'components/domain/dialog/TagDetailDIalog';
import { useMyAccountInfo } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { ScheduleTagErrorHistoryDTO } from 'types/api/schedule';

import { RECORD_PAPER_STATUS_CONFIG, TAG_ERROR_HEADER_CONFIG, TAG_STATUS_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	items?: ScheduleTagErrorHistoryDTO[];
	onClickRow?: (task: ScheduleTagErrorHistoryDTO) => void;
	onRefresh?: () => void;
}

function TagErrorTable({ items = [], onClickRow, onRefresh }: Props): React.ReactElement {
	const { data: myAccountInfo } = useMyAccountInfo();
	const { showDialog } = useDialog();
	const renderTagStatus = useCallback(
		(tagErrYn: boolean) => (
			<CRStatus options={TAG_STATUS_CONFIG}>{tagErrYn ? '오류' : '정상'}</CRStatus>
		),
		[],
	);

	const sendRecordStatus = useCallback(
		(value: string | number) => (
			<CRStatus options={RECORD_PAPER_STATUS_CONFIG}>{value ?? '발송전'}</CRStatus>
		),
		[],
	);

	const handleClickTagDetail = (item?: ScheduleTagErrorHistoryDTO) => () => {
		if (item?.id && myAccountInfo?.centerId) {
			showDialog(() => <TagDetailDialog item={item} onRefresh={onRefresh} />);
		}
	};

	const renderOption = (id: number, item?: ScheduleTagErrorHistoryDTO) => (
		<S.ButtonContainer>
			<CRButton.Default
				size='xSmall'
				type='outlined'
				palette='gray'
				onClick={handleClickTagDetail(item)}>
				수정
			</CRButton.Default>
		</S.ButtonContainer>
	);

	const renderDate = useCallback(
		(value: string) => (
			<S.TextContainer>{value ? dayjs(value).format('YYYY.MM.DD') : '-'}</S.TextContainer>
		),
		[],
	);

	const renderBirthDt = useCallback(
		(value: string) => (
			<S.TextContainer>{value ? dayjs(value).format('YY.MM.DD') : '-'}</S.TextContainer>
		),
		[],
	);

	return (
		<S.Container>
			<CRTable.Root
				style={{
					borderLeft: `0.1rem solid ${Colors.gray90}`,
					borderRight: `0.1rem solid ${Colors.gray90}`,
				}}>
				<CRTable.Head offset={1} heads={TAG_ERROR_HEADER_CONFIG} />
				<CRTable.Body>
					{items.map((item, index) => (
						<CRTable.Row
							key={item.id}
							item={{
								...item,
								index: index + 1,
							}}
							customStyle={{
								index: {
									textAlign: 'right',
								},
							}}
							renderKeys={[
								'index',
								'serviceYmd',
								'recipientNm',
								'recipientBirthDt',
								'serviceTypeNm',
								'employeeNm',
								'plan',
								'rfid',
								'record',
								'tagErrYn',
								'sendRecordStatus',
								'managerNm',
								'id',
							]}
							customRender={{
								serviceYmd: renderDate,
								recipientBirthDt: renderBirthDt,
								tagErrYn: renderTagStatus,
								sendRecordStatus,
								id: renderOption,
							}}
							onClick={onClickRow}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default TagErrorTable;
