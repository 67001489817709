import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInputMessage from 'components/base/CRInputMessage';
import { Toast } from 'components/base/CRToast';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import { EmployeeCareer } from 'lib';
import { useMyAccountInfo, useUpdateEmployeeCareer } from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import {
	EmployeeCareerInfoDTO,
	EmployeeDTO,
	SaveEmployeeCareerRequestDTO,
} from 'types/api/employee';
import { EmployeeCareerForm } from 'types/view/employee';

import * as S from './styles';

interface Props {
	item?: EmployeeCareerInfoDTO;
	hideDialog: () => void;
	currentEmployee?: EmployeeDTO;
}

export default function AddCareerDialog({ item, currentEmployee, hideDialog }: Props) {
	const {
		watch,
		control,
		handleSubmit,
		setValue,
		trigger,
		formState: { isValid },
	} = useForm<EmployeeCareerForm>({
		resolver: yupResolver(EmployeeCareer),
		mode: 'onChange',
	});
	const { data: myAccountInfo } = useMyAccountInfo();
	const { mutate: updateEmployeeCareer } = useUpdateEmployeeCareer((client, returnData) => {
		if (returnData?.employeeId) {
			client.invalidateQueries([
				endpoint.getEmployeeCareerInfo.key,
				{
					employeeId: currentEmployee?.employeeId,
				},
			]);
			hideDialog();
			Toast.success(`근무 경력을 ${item ? '수정' : '등록'} 했습니다.`);
		}
	});

	const onSubmit = async (data: EmployeeCareerForm) => {
		if (!myAccountInfo?.centerId || !currentEmployee?.employeeId) return;
		const param: SaveEmployeeCareerRequestDTO = {
			employeeId: currentEmployee.employeeId,
			workStartDate: dayjs(data.workDate.start).format('YYYY-MM-DDT09:00:00'),
			workEndDate: data.isWorking
				? null
				: data.workDate.end
					? dayjs(data.workDate.end).format('YYYY-MM-DDT09:00:00')
					: null,
			companyNm: data.companyNm,
			remark: data.remark,
		};
		if (item) {
			param.employeeWorkCareerId = item.employeeWorkCareerId;
		}
		updateEmployeeCareer(param);
	};

	const submitForm = () => {
		trigger().then((isValid) => {
			if (isValid) {
				handleSubmit(onSubmit)();
			}
		});
	};

	useEffect(() => {
		if (item) {
			setValue('workDate', {
				start: dayjs(item.workStartDate).toDate(),
				end: item.workEndDate ? dayjs(item.workEndDate).toDate() : null,
			});

			setValue('employeeId', item.employeeId);
			setValue('companyNm', item.companyNm);
			setValue('remark', item.remark);
			setValue('isWorking', !item.workEndDate);
		}
	}, [item]);

	return (
		<CRDialog
			onClickClose={hideDialog}
			title='근무 경력'
			height={800}
			type='S'
			body={
				<S.Form onSubmit={handleSubmit(onSubmit)}>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel
								isRequired
								label='근무기간'
								message={
									errors.workDate?.start?.message && (
										<CRInputMessage type='error'>
											{errors.workDate?.start?.message ?? ''}
										</CRInputMessage>
									)
								}>
								<CRInput.DateRangePicker
									placeholder='시작일 ~ 종료일'
									onChangeValue={(e) => {
										onChange(e);
										setValue('isWorking', false);
									}}
									value={watch('workDate')}
								/>
							</CRInputLabel>
						)}
						name='workDate'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<S.CheckBoxContainer>
								<CRCheckBox
									onChange={(e) => {
										if (e.target.checked) {
											setValue('workDate', {
												start: watch('workDate')?.start ?? null,
												end: null,
											});
										}
										onChange(e);
									}}
									checked={value}
									checkBoxType='checkbox'
								/>{' '}
								<span>현재 재직 중입니다.</span>
							</S.CheckBoxContainer>
						)}
						name='isWorking'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<CRInputLabel
								isRequired
								label='소속'
								message={
									errors.companyNm?.message && (
										<CRInputMessage type='error'>{errors.companyNm?.message ?? ''}</CRInputMessage>
									)
								}>
								<CRInput.Default
									ref={ref}
									onBlur={onBlur}
									onChange={onChange}
									value={value}
									placeholder='소속 입력'
								/>
							</CRInputLabel>
						)}
						name='companyNm'
						control={control}
					/>
					<Controller
						name='remark'
						control={control}
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='비고'>
								<CRInput.TextArea
									numberOfLines={6}
									onChange={onChange}
									value={value}
									placeholder='비고 입력'
								/>
							</CRInputLabel>
						)}
					/>
				</S.Form>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						disabled={!isValid}
						palette='primary'
						size='default'
						onClick={submitForm}>
						저장
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
