import dayjs from 'dayjs';
import * as XLSX from 'sheetjs-style';

import { PCorpWorkScheduleDTO } from 'types/dto/workSchedule';

export const pcorpWorkScheduleTranfer = (fileName: string, data: PCorpWorkScheduleDTO[]) => {
	const dummyValue = {
		성명: '',
		생년월일: '',
		근무기간: '',
		직종: '',
		일자: '',
		요일: '',
		근무시간: '',
		근무종료: '',
		주간휴식시: '',
		주간휴식분: '',
		야간휴식시: '',
		야간휴식분: '',
		근무시작2: '',
		근무종료2: '',
		주간휴식시2: '',
		주간휴식분2: '',
		야간휴식시2: '',
		야간휴식분2: '',
		휴가코드: '',
		휴가시간: '',
		휴일근무시간: '',
		근무상세구분: '',
		근무상세내용: '',
		근무휴일상세여부: '',
	};
	const excelData = data.map(
		({
			korMemberNm,
			birthDt,
			joinDate,
			resignDate,
			dutyNm,
			workScheduleDt,
			dayNm,
			startTime1,
			endTime1,
			restHourCnt1,
			restMinCnt1,
			startTime2,
			endTime2,
			restHourCnt2,
			restMinCnt2,
			vacaCd,
			vacaUseHourCnt,
			workRequestKindCd,
			workRequestDetailCd,
			workVacaDetail,
			workTime,
			overTime,
		}) => ({
			성명: korMemberNm || '',
			생년월일: dayjs(birthDt).format('YYMMDD') || '',
			근무기간: `${joinDate}~${resignDate}` || '',
			직종: dutyNm || '',
			일자: dayjs(workScheduleDt).format('DD') || '',
			요일: dayNm || '',
			근무시간: startTime1 || '',
			근무종료: endTime1 || '',
			주간휴식시: restHourCnt1 || '',
			주간휴식분: restMinCnt1 || '',
			야간휴식시: '',
			야간휴식분: '',
			근무시작2: '',
			근무종료2: '',
			주간휴식시2: '',
			주간휴식분2: '',
			야간휴식시2: '',
			야간휴식분2: '',
			휴가코드: vacaCd || '',
			휴가시간: vacaUseHourCnt ? String(Math.floor(Number(vacaUseHourCnt))) : '',
			휴일근무시간: '',
			근무상세구분: '',
			근무상세내용: '',
			근무휴일상세여부: workVacaDetail || '',
		}),
	);

	const workSheet = XLSX.utils.json_to_sheet([dummyValue, ...excelData]);

	const headerStyle = {
		fill: {
			fgColor: { rgb: 'DDDDDD' },
		},
		alignment: {
			horizontal: 'center',
		},
	};

	workSheet.A1.s = headerStyle;
	workSheet.B1.s = headerStyle;
	workSheet.C1.s = headerStyle;
	workSheet.D1.s = headerStyle;
	workSheet.E1.s = headerStyle;
	workSheet.F1.s = headerStyle;
	workSheet.G1.s = headerStyle;
	workSheet.H1.s = headerStyle;
	workSheet.I1.s = headerStyle;
	workSheet.J1.s = headerStyle;
	workSheet.K1.s = headerStyle;
	workSheet.L1.s = headerStyle;
	workSheet.M1.s = headerStyle;
	workSheet.N1.s = headerStyle;
	workSheet.O1.s = headerStyle;
	workSheet.P1.s = headerStyle;
	workSheet.Q1.s = headerStyle;
	workSheet.R1.s = headerStyle;
	workSheet.S1.s = headerStyle;
	workSheet.T1.s = headerStyle;
	workSheet.U1.s = headerStyle;
	workSheet.V1.s = headerStyle;
	workSheet.W1.s = headerStyle;
	workSheet.X1.s = headerStyle;

	const keys = Object.values(excelData[0]);

	const columnStyles = keys.map((_, idx) => {
		const getMaxLength = String(keys[idx]).length;
		const minWidth = 13;

		return {
			width: Math.max(minWidth, getMaxLength * 2),
		};
	});

	workSheet['!cols'] = columnStyles;

	const wb = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(wb, workSheet, '입력정보');
	XLSX.writeFile(wb, `${fileName}.xlsx`);
	return true;
};
