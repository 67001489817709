import React, {
	MouseEvent as ReactMouseEvent,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';

import Assets from 'assets';
import CRDropdown from 'components/base/CRDropdown';
import { FilterType } from 'types/view/filter';

import * as S from './styles';

interface IProps<T extends { label: string; value: any }> {
	options: T[];
	maxHeight?: number;
	currentValue?: T[];
	placeholder?: string;
	filterType?: FilterType;
	filterKey?: string;
	onChangeValue?: (item: T, key: string, filterType: FilterType) => void;
}

function SelectionChip<T extends { label: string; value: any }>({
	currentValue = [],
	options,
	maxHeight = 304,
	placeholder = '',
	filterType = 'single',
	filterKey = '',
	onChangeValue,
}: IProps<T>): React.ReactElement {
	const [showOptions, setShowOptions] = useState(false);
	const filterRef = useRef<HTMLDivElement>(null);

	const handleToggleOptions = () => {
		setShowOptions((prev) => !prev);
	};

	const isChecked = useCallback(
		(item: { label: string; value: any }) => !!currentValue?.find((e) => e?.value === item?.value),
		[currentValue],
	);

	const renderText = () => {
		let label = '';
		if (currentValue?.length && options?.length) {
			label =
				currentValue[0].label + (currentValue.length > 1 ? ` 외 ${currentValue.length - 1}건` : '');
		} else {
			label = placeholder;
		}
		return label;
	};

	const onClickInit = (e: ReactMouseEvent) => {
		e.stopPropagation();
		onChangeValue?.(
			{
				label: filterKey,
				value: undefined,
			} as T,
			filterKey,
			filterType,
		);
		setShowOptions(false);
	};

	useEffect(() => {
		const handleMouseOut = (e: MouseEvent) => {
			if (!filterRef.current?.contains(e.target as Node)) {
				setShowOptions(false);
			}
		};
		document.addEventListener('mousedown', handleMouseOut);

		return () => {
			document.removeEventListener('mousedown', handleMouseOut);
		};
	}, []);

	return (
		<S.Container ref={filterRef}>
			<S.DefaultOption
				$showOptions={showOptions}
				$isActive={currentValue?.length > 0}
				onClick={handleToggleOptions}
				disabled={false}>
				<S.ChipLabel>{renderText()}</S.ChipLabel>
				{filterType === 'multi' && currentValue?.length > 0 && (
					<S.CloseIconContainer onClick={onClickInit}>
						<img src={Assets.icon.closeCircle} alt='keyboardArrowBottom' />
					</S.CloseIconContainer>
				)}
				{currentValue?.length === 0 && (
					<S.ArrowIconContainer src={Assets.icon.keyboardArrowBottom} alt='keyboardArrowBottom' />
				)}
			</S.DefaultOption>

			{showOptions && ['single', 'multi'].includes(filterType) && (
				<CRDropdown
					style={{
						top: '3.1rem',
						...(maxHeight
							? {
									maxHeight,
								}
							: {}),
					}}>
					{options?.map((option) => (
						<S.TestContainer
							$isActive={isChecked(option)}
							key={option.value}
							onClick={() => {
								onChangeValue?.(option, filterKey, filterType);
								if (filterType === 'single') {
									setShowOptions(false);
								}
							}}>
							{filterType === 'multi' && (
								<S.CRInput
									$isActive={isChecked(option)}
									src={isChecked(option) ? Assets.icon.checkSquareFilled : Assets.icon.checkSquare}
								/>
							)}

							<S.ChipLabel>{option.label}</S.ChipLabel>
						</S.TestContainer>
					))}
				</CRDropdown>
			)}
		</S.Container>
	);
}

export default SelectionChip;
