import { CheckOption } from 'components/base/Selections/type';
import { WorkTime } from 'types/api/employee';
import { FileDTO, FileDetailDTO } from 'types/dto';

export enum WorkStateCd {
	근무중 = 'CMN068.10',
	휴직중 = 'CMN068.11',
	보류중 = 'CMN068.12',
	대기 = 'CMN068.20',
	퇴사 = 'CMN068.30',
}

export type EmployeeData = {
	order?: number;
	memberId: number;
	employeeId: number;
	centerId: number;
	centerNm: string;
	korMemberNm: string;
	birthDt: string;
	mobilePhoneNo: string;
	workStateNm: string;
	dutyCds: string;
	dutyNms: string;
	managerNm: string;
};

export type EmployeeBasicStatusFormType = {
	status: string;
	manager: string;
};

export type EmployeeBasicInfoFormViewType = {
	insurance?: string;
	korMemberNm: string; //
	rsdnNo: string; //
	zipCode: string; //
	basAddr: string; //
	detailAddr: string; //
	regionNm1: string;
	regionNm2: string;
	regionNm3: string;
	roadNm: string;
	birthDt: string; //
	dutyCds: CheckOption[]; //
	mobilePhoneNo: string; //
	dementiaEduCompleteYn: CheckOption[]; //
	twoPhoneYn: CheckOption[]; //
	disabilityGradeCd: string; //
	subMobilePhoneNo: string; //
	employeeSpecialDesc: string; //
	tagCallRequestYn: CheckOption[]; //
	tagCallDayCds: CheckOption[];
	healthCheckDate?: Date; //
	addrLatitude?: number;
	addrLongitude?: number;
	docReceiveMethodCd?: string;
	joinDate: string;
	monthPerWorkHourCnt: string;
	visitCareOffHourCnt: string;
	visitBathOffHourCnt: string;
	visitNursingOffHourCnt: string;
};

export type EmployeeOtherCorpWorkFormViewType = {
	otherCorpWorkId: string;
	personalBusinessOwnerYn?: CheckOption[];
	otherCorpNm: string;
	otherCorpPhoneNo: string;
	remark: string;
	times: WorkTime[];
};

export type EmployeeOtherCorpWorkForm = {
	item: EmployeeOtherCorpWorkFormViewType[];
};

export type EmployeePayInfoForm = {
	payType?: CheckOption[];
	bank?: CheckOption;
	accountNo: string;
	ownerName: string;
	accountValid?: boolean;
};

export type EmployeeHealthCheckEnrollForm = {
	healthCheckDate: Date;
	healthCheckKind: CheckOption[];
	healthCheckInstitutionNm: string;
	remark: string;
	healthCheckFile: FileDetailDTO[];
};

export type EmployeeCareerForm = {
	isWorking: boolean;
	employeeId: number;
	workDate: {
		start: Date | null;
		end: Date | null;
	};
	companyNm: string;
	remark: string;
};

export type EmployeeLicenseForm = {
	licenseTypeCd: string;
	licenseNm: string;
	licenseNo: string;
	licenseIssueDate: Date;
	licenseIssueInstitutionNm: string;
	licenseFile: FileDetailDTO[];
};

export type EmployeeEducationForm = {
	eduTypeCd: string;
	eduCompleteDate: Date;
	eduInstitutionNm: string;
	employeeEduId: number;
	eduCompleteCertiFile: FileDetailDTO[];
	eduInstitutionNmEtc: string;
};

export type EmployeeManagerHistoryType = {
	id: string;
	status: string;
	date: string;
	center: {
		id: string;
		name: string;
	};
	manager: {
		id: string;
		name: string;
	};
	note: string;
	consultantOpinion: string;
	managerChangeDesc: string;
};

export type EmployeeDetailType = {
	id: string;
	status: string;
	date: string;
	center: {
		id: string;
		name: string;
	};
	manager: {
		id: string;
		name: string;
	};
	note: string;
	consultantOpinion: string;
	managerChangeDesc: string;
};

export type EmployeeConsulting = {
	id: string | number;
	date: string;
	consultant: string;
	relation: string;
	type: string;
	tag: string;
	center: string;
	employee: string;
};

export type EmployeeAddHistoryOfManagerFormType = {
	changeDate: Date;
	center: CheckOption;
	manager: CheckOption;
	consultantOpinion?: string;
	managerChangeDesc: string;
};

export type EmployeeService = {
	id: number;
	contractServiceId: number;
	status: string;
	serviceType: string;
	service: string;
	contractedDate: string;
	contractTypeCdNm: string;
	periodOfUse: string;
	employee: string;
	center: string;
	manager: string;
	recipient: string;
};

export type EmployeeResignForm = {
	insuranceLossRequestYn: boolean;
	wardResignDischargeRequestYn: boolean;
	incompletePaperConfirmYn: boolean;
	calculateConfirmYn: boolean;
};

export type EmployeeWorkInfoViewType = {
	employeeId: number;
	centerId: number;
	centerNm: string;
	korMemberNm: string;
	birthDt: string;
	mobilePhoneNo: string;
	dutyCd: string;
	dutyNm: string;
	pcorpTimeCnt: number;
	workTimeCnt: number;
	overWorkTimeCnt: number;
	overWorkMaxTimeCnt: number;
};

export type EmployeeDefaultWorkTimeFormType = {
	days: string[];
	workStartTime: string;
	workEndTime: string;
	includeHoliday: boolean;
	restHourCnt: number;
};

export type EmployeeDailyWorkScheduleFormType = {
	date: string;
	workStartTime: string;
	workEndTime: string;
	breakTime: number;
};

export type EmployeeWorkDetailReportFormType = {
	workCategory?: CheckOption;
	workCategoryDetail?: CheckOption;
	includeHoliday: boolean;
	restTime: number;
	files?: FileDTO;
	remark: string;
	workDate: {
		start: Date;
		end: Date;
	};
	workStartTime: string;
	workEndTime: string;
};

export type EmployeeOverWorkFormType = {
	workCategory: CheckOption;
	workStartTime: string;
	workEndTime: string;
	workTime: {
		date: Date;
	};
	workRemark: string;
};

export type EmployeeVacationFormType = {
	vacationCategory: CheckOption;
	vacationDate: { start: Date; end: Date };
	halfDayOfSelection: CheckOption[];
	vacationRemark: string;
};

export type EmployeeMonthOverworkFormType = {
	overworkHour: number;
	overworkMinute: number;
};
