import React, { useEffect } from 'react';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRSpinner from 'components/base/CRSpinner';
import CRTab from 'components/base/CRTab';
import { Toast } from 'components/base/CRToast';
import SalaryDetailBasicInfo from 'components/domain/table/EmployeeSalaryDetailTable/SalaryDetailBasicInfo';
import SalaryDetailPaymentDeduction from 'components/domain/table/EmployeeSalaryDetailTable/SalaryDetailPaymentDeduction';
import SalaryDetailSalaryFeeInformation from 'components/domain/table/EmployeeSalaryDetailTable/SalaryDetailSalaryFeeInformation';
import SalaryDetailServiceSummary from 'components/domain/table/EmployeeSalaryDetailTable/SalaryDetailServiceSummary';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import { SalaryDetailValidator, displayComma } from 'lib';
import useCRForm from 'lib/hook/form/useCRForm';
import {
	useCalculateSalary,
	useCompleteEmployeeSalarys,
	useSalarysEmployeeSalaryDetail,
	useUpdateSalarysDetail,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import { endpoint } from 'lib/service/Api/endpoint';
import { ResponseCode } from 'types/api/base';

import { DeleteSalaryDetailDialog } from '../DeleteSalaryDetailDialog';
import SalaryCompleteDialog from '../SalaryCompleteDialog';
import * as S from './styles';
import { SalaryDetailDialogFormFields, salaryFeeCdKor, salaryItemCdKor } from './types';

interface Props {
	employeeSalaryId: number;
	onClose: () => void;
	refetchList?: () => void;
}

function SalaryDetailDialog({ employeeSalaryId, onClose, refetchList: refetch }: Props) {
	const { data: salaryDetailInfo, isLoading } = useSalarysEmployeeSalaryDetail({
		employeeSalaryId,
	});
	const { mutateAsync: updateSalarysDetail, isLoading: isSalaryUpdating } = useUpdateSalarysDetail(
		(client, data) => {
			if (data?.data) {
				client.setQueryData(
					[endpoint.getSalarysEmployeeSalaryDetail.key, { employeeSalaryId }],
					data?.data,
				);
				refetch?.();
			}
		},
	);

	const { mutateAsync: completeSalary, isLoading: isSalaryCompleting } = useCompleteEmployeeSalarys(
		(client, data, variables) => {
			if (!data?.data) {
				if (variables.salaryCompleteYn) {
					Toast.error('임금 내역 확정에 실패했습니다.');
				} else {
					Toast.error('임금 내역 확정 취소에 실패했습니다.');
				}
				return;
			}

			client.setQueryData(
				[endpoint.getSalarysEmployeeSalaryDetail.key, { employeeSalaryId }],
				data?.data,
			);
			if (variables.salaryCompleteYn) {
				Toast.success('임금 내역이 확정되었습니다.');
			} else {
				Toast.success('임금 내역 확정을 취소했습니다.');
			}
			refetch?.();
		},
	);

	const { mutateAsync: updateSalaryCalculate, isLoading: isCalculating } = useCalculateSalary(
		(client, data) => {
			if (data?.data) {
				client.setQueryData(
					[endpoint.getSalarysEmployeeSalaryDetail.key, { employeeSalaryId }],
					data?.data,
				);
			}
		},
	);

	const { showDialog } = useDialog();

	const { setValue, watch, CRHandleSubmit, CRFormProvider } =
		useCRForm<SalaryDetailDialogFormFields>({ yupValidator: SalaryDetailValidator });
	const hasPermission = useHasPermission('센터장');
	const salaryDetail = watch('salaryDetail');

	useEffect(() => {
		// 간혹 임금정보를 찾을 수 없는 케이스가 나타남. 예외처리 추가.
		if (!isLoading && !salaryDetailInfo) {
			Toast.error('임금정보가 존재하지 않습니다.');
			onClose();
		}

		if (salaryDetailInfo) {
			const burdenAmtDeducts = salaryDetailInfo.burdenAmtDeducts.map((item) => ({
				...item,
				visitCareBurdenAmt: displayComma(item.visitCareBurdenAmt),
				visitBathBurdenAmt: displayComma(item.visitBathBurdenAmt),
				visitNursingBurdenAmt: displayComma(item.visitNursingBurdenAmt),
			}));

			setValue('salaryDetail', {
				...salaryDetailInfo,
				burdenAmtDeducts,
			});

			const SalaryInfoObj: any = {};
			salaryDetailInfo.salaryItems.forEach((item) => {
				const itemKor = Object.entries(salaryItemCdKor).find(
					([name, value]) => item.salaryItemCd === value,
				);
				if (itemKor) {
					const convertedItem = { ...item, salaryItemAmt: displayComma(item.salaryItemAmt) };
					const korKey = itemKor[0];
					SalaryInfoObj[korKey] = convertedItem;
				}
			});

			setValue('salaryItem', SalaryInfoObj);

			const SalaryFeeObj: any = {};
			salaryDetailInfo.employeeSalaryFeeStandards.forEach((item) => {
				const itemKor = Object.entries(salaryFeeCdKor).find(
					([name, value]) => item.salaryFeeCd === value,
				);
				if (itemKor) {
					const convertedItem = { ...item, salaryFeeValue: displayComma(item.salaryFeeValue) };
					const korKey = itemKor[0];
					SalaryFeeObj[korKey] = convertedItem;
				}
			});
			setValue('salaryFee', SalaryFeeObj);
		}
	}, [salaryDetailInfo, isLoading]);

	const currentMonth = dayjs(salaryDetail?.salaryYm).format('YYYY년 M월');

	const onClickSave = async (formData: SalaryDetailDialogFormFields) => {
		if (isSalaryUpdating || isCalculating) return;
		const { salaryDetail, salaryFee, salaryItem } = formData;

		const isUnderTaxFreeValue = salaryDetail.salaryItems.every((item) => {
			const changedItem = Object.values(salaryItem).find(
				(changed) => changed.salaryItemCd === item.salaryItemCd,
			);
			if (!changedItem) return true;
			const salaryItemAmt = Number(changedItem.salaryItemAmt.replace(/,/g, ''));
			if (changedItem.taxFreeLimitAmt !== 0 && changedItem.taxFreeLimitAmt < salaryItemAmt) {
				Toast.error(
					`${
						changedItem.salaryCertiItemNm
					}는(은) ${changedItem.taxFreeLimitAmt.toLocaleString()}원을 초과할 수 없습니다.`,
				);
				return false;
			}
			return true;
		});

		if (!isUnderTaxFreeValue) return;

		const saveSalaryDetail = {
			...salaryDetail,
			burdenAmtDeducts: salaryDetail.burdenAmtDeducts.map((item) => ({
				...item,
				visitBathBurdenAmt: Number(String(item.visitBathBurdenAmt).replace(/,/g, '')),
				visitCareBurdenAmt: Number(String(item.visitCareBurdenAmt).replace(/,/g, '')),
				visitNursingBurdenAmt: Number(String(item.visitNursingBurdenAmt).replace(/,/g, '')),
			})),
			salaryItems: salaryDetail.salaryItems.map((item) => {
				const changedItem = Object.values(salaryItem).find(
					(changed) => changed.salaryItemCd === item.salaryItemCd,
				);
				if (!changedItem) return item;

				const salaryItemAmt = Number(changedItem.salaryItemAmt.replace(/,/g, ''));

				return { ...changedItem, salaryItemAmt };
			}),
			employeeSalaryFeeStandards: salaryDetail.employeeSalaryFeeStandards.map((item) => {
				const changedItem = Object.values(salaryFee).find(
					(changed) => changed.salaryFeeCd === item.salaryFeeCd,
				);
				if (!changedItem) return item;

				const salaryFeeValue = changedItem.salaryFeeValue.replace(/,/g, '');

				return { ...changedItem, salaryFeeValue };
			}),
		};

		const res = await updateSalarysDetail(saveSalaryDetail);
		if (res?.code !== ResponseCode.SUCCESS) {
			Toast.error('저장에 실패했습니다. 잠시 후 다시 시도해주시길 바랍니다.');
			return;
		}

		const calRes = await updateSalaryCalculate({ employeeSalaryId, inputValueKeepYn: true });
		if (calRes?.code !== ResponseCode.SUCCESS) {
			Toast.error('임금 저장은 성공했으나 재계산에 실패했습니다.');
			return;
		}

		Toast.success('임금 상세 저장 및 재계산을 완료했습니다.');
		onClose();
	};

	/**
	 * 확정기능.
	 */
	const onClickConfirm = () => {
		showDialog(({ hideDialog }) => (
			<SalaryCompleteDialog
				employeeSalaryId={employeeSalaryId}
				onClose={() => {
					hideDialog();
					hideDialog();
				}}
				refetch={refetch || (() => {})}
			/>
		));
	};

	/**
	 * 확정취소 기능. 센터장만 가능합니다.
	 */
	const onClickRevertConfirm = () => {
		if (isSalaryCompleting) return;
		completeSalary({ employeeSalaryId, salaryCompleteYn: false });
	};

	// 임금 삭제 기능. 센터장만 가능
	const onClickDeleteSalary = () => {
		if (!salaryDetailInfo) return;
		showDialog(() => <DeleteSalaryDetailDialog salaryData={salaryDetailInfo} />);
	};

	const isFormDisabled = salaryDetail?.salaryCompleteYn || !hasPermission;
	const isCompleted = salaryDetail?.salaryCompleteYn; // || isCompletedFixed;

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{!salaryDetail ? (
				<CRSpinner />
			) : (
				<CRDialog
					type='M'
					title={`${currentMonth} 임금 상세`}
					onClickClose={onClose}
					body={
						<CRFormProvider>
							<S.Container>
								<SalaryDetailBasicInfo disabled={isCompleted} />
								<CRTab.Sub
									containerStyle={{ minHeight: '39.1rem' }}
									defaultActiveKey='근로 내역'
									items={[
										{
											label: '근로 내역',
											key: '근로 내역',
											children: <SalaryDetailServiceSummary disabled={isFormDisabled} />,
										},
										{
											label: '임금 내역',
											key: '임금 내역',
											children: <SalaryDetailPaymentDeduction disabled={isFormDisabled} />,
										},
										{
											label: '재무 정보',
											key: '재무 정보',
											children: <SalaryDetailSalaryFeeInformation disabled={isFormDisabled} />,
										},
									]}
								/>
							</S.Container>
						</CRFormProvider>
					}
					footer={
						<S.ButtonContainer justify='space-between'>
							{!isFormDisabled ? (
								<CRButton.Default palette='primary' type='outlined' onClick={onClickDeleteSalary}>
									삭제
								</CRButton.Default>
							) : (
								<RDTooltip content={<>센터장만 임금 정보를 삭제할 수 있습니다.</>} side='right'>
									<CRButton.Default disabled>삭제</CRButton.Default>
								</RDTooltip>
							)}
							<FlexContainer gap='0.8rem'>
								<CRButton.Default palette='gray' type='text' onClick={onClose}>
									취소
								</CRButton.Default>
								<CRButton.Default
									disabled={isCompleted}
									onClick={CRHandleSubmit(onClickSave)}
									palette='gray'
									type='tonal'>
									저장
								</CRButton.Default>

								{!isCompleted && <CRButton.Default onClick={onClickConfirm}>확정</CRButton.Default>}
								{isCompleted && (
									// eslint-disable-next-line react/jsx-no-useless-fragment
									<>
										{hasPermission ? (
											<CRButton.Default onClick={onClickRevertConfirm}>확정 취소</CRButton.Default>
										) : (
											<CRButton.Default disabled>확정 완료</CRButton.Default>
										)}
									</>
								)}
							</FlexContainer>
						</S.ButtonContainer>
					}
				/>
			)}
		</>
	);
}

export default SalaryDetailDialog;
