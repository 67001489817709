import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';

import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { ErrorMessage } from 'components/base/CRInputMessage/styles';
import { CenterFinanceDetailFormFields } from 'types/view/centerFinance';

import * as S from './styles';

interface Props {
	disabled?: boolean;
	periodDuplicate?: boolean;
}

function MyCenterFinanceDurationForm({ disabled, periodDuplicate }: Props): React.ReactElement {
	const { control } = useFormContext<CenterFinanceDetailFormFields>();

	return (
		<S.Container>
			<Controller
				control={control}
				name='salaryFeeStandardStartDate'
				render={({ field: { value, onChange }, formState: { errors } }) => (
					<CRInputLabel type='left' label='적용 시작 연월' isRequired>
						<CRInput.YearMonthPicker
							// minYearMonth={dayjs('2024-05-05').toDate()}
							status={periodDuplicate === true ? 'error' : 'default'}
							disabled={disabled}
							placeholder='시작 연월 선택'
							onChangeValue={onChange}
							currentValue={value}
						/>
						{periodDuplicate && (
							<ErrorMessage>
								현재 적용중인 재무 정보의 시작 연월 이후로 설정해 주십시오.
							</ErrorMessage>
						)}
					</CRInputLabel>
				)}
			/>
		</S.Container>
	);
}

export default MyCenterFinanceDurationForm;
