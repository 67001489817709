import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Assets from 'assets';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { CRText } from 'components/base/CRText';
import { CheckOption } from 'components/base/Selections/type';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes } from 'lib/hook/react-query';
import { MonitoringDetailItemDTO } from 'types/api';
import { CommonCodeDTO } from 'types/api/common';
import { MonitoringDetailForm } from 'types/view/monitoring';

import * as S from './styles';

type SpecificDescKey =
	| 'recogPlanTime'
	| 'carePlanTimeOver'
	| 'careMultipleUse'
	| 'visitCareFiveGrade'
	| 'bathOverOnceWeek';

interface Props {
	item?: MonitoringDetailItemDTO | null;
	isConfirmed: boolean;
}

function SpecificDescForm({ item, isConfirmed }: Props) {
	const { data: commonCodes } = useCommonCodes({ comCdGroupNms: ['CMN203'] }, commonCodeAdapter);

	const { watch, setValue, control } = useFormContext<MonitoringDetailForm>();

	const {
		recogPlanTimeUnderOfferDescCd,
		carePlanTimeOverOfferDescCd,
		careMultipleUseOfferDescCd,
		visitCareFiveGradeOfferDescCd,
		bathOverOnceWeekOfferDescCd,
	} = watch();

	const specificDescCode = commonCodes?.CMN203?.reduce(
		(acc: { [key in SpecificDescKey]?: CheckOption<CommonCodeDTO>[] }, item) => {
			const { value } = item; // 예시: 'CMN203.101'
			const number = parseInt(value.split('.')[1], 10); // '101' -> 101
			const hundreds = Math.floor(number / 100) * 100; // 101 -> 100, 201 -> 200

			const mappedObj: { [key: number]: SpecificDescKey } = {
				100: 'recogPlanTime',
				200: 'carePlanTimeOver',
				300: 'careMultipleUse',
				400: 'visitCareFiveGrade',
				500: 'bathOverOnceWeek',
			};

			const codeName = mappedObj[hundreds];

			// 해당 백의 자리에 해당하는 배열이 없으면 생성
			if (!acc[codeName]) {
				acc[codeName] = [];
			}

			// 해당 배열에 현재 아이템을 추가
			// eslint-disable-next-line
			acc[codeName]!.push(item);

			return acc;
		},
		{},
	);

	const renderSpecialDescConfirm = (confirmYn?: boolean, descCd?: string) => {
		if (confirmYn === undefined) return '-';
		return (
			<FlexContainer direction='row' justify='space-between' width='100%'>
				<CRText
					text={confirmYn ? '작성 완료' : '미작성'}
					typography='label'
					color={confirmYn ? 'gray00' : 'primary60'}
				/>
				{descCd && !confirmYn && (
					<RDTooltip
						side='top'
						content={
							<>
								행정마스터 검수 후<br />
								작성 완료로 변경됩니다.
							</>
						}>
						<S.QuestionMark src={Assets.icon.help} alt='공휴일 근무일정' />
					</RDTooltip>
				)}
			</FlexContainer>
		);
	};

	return (
		<S.CheckGroupContainer>
			<S.ContentTitle>
				<CRText text='특정내용 작성' color='gray10' typography='bodyB' />
			</S.ContentTitle>
			{(item?.recogPlanTimeUnderYn ||
				item?.carePlanTimeOverYn ||
				item?.careMultipleUseYn ||
				item?.visitCareFiveGradeYn ||
				item?.bathOverOnceWeekYn) && (
				<S.Table>
					{item?.recogPlanTimeUnderYn && (
						<S.TableRow>
							<S.TableLabelColumn>120분 미만 제공 사유</S.TableLabelColumn>
							<S.TableValueColumn
								style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								{renderSpecialDescConfirm(
									item?.recogPlanTimeUnderConfirmYn,
									item?.recogPlanTimeUnderOfferDescCd,
								)}
							</S.TableValueColumn>
						</S.TableRow>
					)}
					{item?.carePlanTimeOverYn && item?.carePlanTimeOverNm === '210' && (
						<S.TableRow>
							<S.TableLabelColumn>210분이상 연속 제공사유</S.TableLabelColumn>
							<S.TableValueColumn
								style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								{renderSpecialDescConfirm(
									item?.carePlanTimeOverConfirmYn,
									item?.carePlanTimeOverOfferDescCd,
								)}
							</S.TableValueColumn>
						</S.TableRow>
					)}
					{item?.carePlanTimeOverYn && item?.carePlanTimeOverNm === '270' && (
						<S.TableRow>
							<S.TableLabelColumn>270분 이상 연속 제공사유</S.TableLabelColumn>
							<S.TableValueColumn
								style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								{renderSpecialDescConfirm(
									item?.carePlanTimeOverConfirmYn,
									item?.carePlanTimeOverOfferDescCd,
								)}
							</S.TableValueColumn>
						</S.TableRow>
					)}
					{item?.careMultipleUseYn && (
						<S.TableRow>
							<S.TableLabelColumn style={{ padding: '0 0.8rem' }}>
								1일 다횟수 방문요양 제공사유
							</S.TableLabelColumn>
							<S.TableValueColumn
								style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								{renderSpecialDescConfirm(
									item?.careMultipleUseConfirmYn,
									item?.careMultipleUseOfferDescCd,
								)}
							</S.TableValueColumn>
						</S.TableRow>
					)}
					{item?.visitCareFiveGradeYn && (
						<S.TableRow>
							<S.TableLabelColumn>5등급 일반요양 제공사유</S.TableLabelColumn>
							<S.TableValueColumn
								style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								{renderSpecialDescConfirm(
									item?.visitCareFiveGradeConfirmYn,
									item?.visitCareFiveGradeOfferDescCd,
								)}
							</S.TableValueColumn>
						</S.TableRow>
					)}
					{item?.bathOverOnceWeekYn && (
						<S.TableRow>
							<S.TableLabelColumn>주1회 초과사유</S.TableLabelColumn>
							<S.TableValueColumn
								style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								{renderSpecialDescConfirm(
									item?.bathOverOnceWeekConfirmYn,
									item?.bathOverOnceWeekOfferDescCd,
								)}
							</S.TableValueColumn>
						</S.TableRow>
					)}
				</S.Table>
			)}
			{item?.recogPlanTimeUnderYn && (
				<S.InputContainer>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='120분 미만 제공 사유' isRequired>
								<CRInput.Selector
									onChangeValue={(data) => {
										if (data.value === 'CMN203.0199') setValue('recogPlanTimeUnderOfferDesc', '');
										onChange(data);
									}}
									currentValue={value}
									items={specificDescCode?.recogPlanTime}
									placeholder='특정내용 선택'
									disabled={isConfirmed}
								/>
							</CRInputLabel>
						)}
						name='recogPlanTimeUnderOfferDescCd'
						control={control}
					/>
					{recogPlanTimeUnderOfferDescCd?.value === 'CMN203.0199' && (
						<Controller
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInput.Default
									onChange={onChange}
									value={value}
									placeholder='직접 입력'
									disabled={isConfirmed}
								/>
							)}
							name='recogPlanTimeUnderOfferDesc'
							control={control}
						/>
					)}
				</S.InputContainer>
			)}
			{item?.carePlanTimeOverYn && item?.carePlanTimeOverNm === '210' && (
				<S.InputContainer>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='210분이상 연속 제공사유' isRequired>
								<CRInput.Selector
									onChangeValue={(data) => {
										if (data.value === 'CMN203.0299') setValue('carePlanTimeOverOfferDesc', '');
										onChange(data);
									}}
									currentValue={value}
									items={specificDescCode?.carePlanTimeOver}
									placeholder='특정내용 선택'
									disabled={isConfirmed}
								/>
							</CRInputLabel>
						)}
						name='carePlanTimeOverOfferDescCd'
						control={control}
					/>
					{carePlanTimeOverOfferDescCd?.value === 'CMN203.0299' && (
						<Controller
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInput.Default
									onChange={onChange}
									value={value}
									placeholder='직접 입력'
									disabled={isConfirmed}
								/>
							)}
							name='carePlanTimeOverOfferDesc'
							control={control}
						/>
					)}
				</S.InputContainer>
			)}
			{item?.carePlanTimeOverYn && item?.carePlanTimeOverNm === '270' && (
				<S.InputContainer>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='270분이상 연속 제공사유' isRequired>
								<CRInput.Selector
									onChangeValue={(data) => {
										if (data.value === 'CMN203.0299') setValue('carePlanTimeOverOfferDesc', '');
										onChange(data);
									}}
									currentValue={value}
									items={specificDescCode?.carePlanTimeOver}
									placeholder='특정내용 선택'
									disabled={isConfirmed}
								/>
							</CRInputLabel>
						)}
						name='carePlanTimeOverOfferDescCd'
						control={control}
					/>
					{carePlanTimeOverOfferDescCd?.value === 'CMN203.0299' && (
						<Controller
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInput.Default
									onChange={onChange}
									value={value}
									placeholder='직접 입력'
									disabled={isConfirmed}
								/>
							)}
							name='carePlanTimeOverOfferDesc'
							control={control}
						/>
					)}
				</S.InputContainer>
			)}
			{item?.careMultipleUseYn && (
				<S.InputContainer>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='1일 다횟수 방문요양 제공사유' isRequired>
								<CRInput.Selector
									onChangeValue={(data) => {
										if (data.value === 'CMN203.0399') setValue('careMultipleUseOfferDesc', '');
										onChange(data);
									}}
									currentValue={value}
									items={specificDescCode?.careMultipleUse}
									placeholder='특정내용 선택'
									disabled={isConfirmed}
								/>
							</CRInputLabel>
						)}
						name='careMultipleUseOfferDescCd'
						control={control}
					/>
					{careMultipleUseOfferDescCd?.value === 'CMN203.0399' && (
						<Controller
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInput.Default
									onChange={onChange}
									value={value}
									placeholder='직접 입력'
									disabled={isConfirmed}
								/>
							)}
							name='careMultipleUseOfferDesc'
							control={control}
						/>
					)}
				</S.InputContainer>
			)}
			{item?.visitCareFiveGradeYn && (
				<S.InputContainer>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='5등급 일반요양 제공사유' isRequired>
								<CRInput.Selector
									onChangeValue={(data) => {
										if (data.value === 'CMN203.0499') setValue('visitCareFiveGradeOfferDesc', '');
										onChange(data);
									}}
									currentValue={value}
									items={specificDescCode?.visitCareFiveGrade}
									placeholder='특정내용 선택'
									disabled={isConfirmed}
								/>
							</CRInputLabel>
						)}
						name='visitCareFiveGradeOfferDescCd'
						control={control}
					/>
					{visitCareFiveGradeOfferDescCd?.value === 'CMN203.0499' && (
						<Controller
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInput.Default
									onChange={onChange}
									value={value}
									placeholder='직접 입력'
									disabled={isConfirmed}
								/>
							)}
							name='visitCareFiveGradeOfferDesc'
							control={control}
						/>
					)}
				</S.InputContainer>
			)}
			{item?.bathOverOnceWeekYn && (
				<S.InputContainer>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='주1회 초과사유' isRequired>
								<CRInput.Selector
									onChangeValue={(data) => {
										if (data.value === 'CMN203.0599') setValue('bathOverOnceWeekOfferDesc', '');
										onChange(data);
									}}
									disabled={isConfirmed}
									currentValue={value}
									items={specificDescCode?.bathOverOnceWeek}
									placeholder='특정내용 선택'
								/>
							</CRInputLabel>
						)}
						name='bathOverOnceWeekOfferDescCd'
						control={control}
					/>
					{bathOverOnceWeekOfferDescCd?.value === 'CMN203.0599' && (
						<Controller
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInput.Default
									onChange={onChange}
									value={value}
									placeholder='직접 입력'
									disabled={isConfirmed}
								/>
							)}
							name='bathOverOnceWeekOfferDesc'
							control={control}
						/>
					)}
				</S.InputContainer>
			)}
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='특정내용 (기타내용)'>
						<CRInput.TextArea
							disabled={isConfirmed}
							onChange={onChange}
							value={value}
							height='10.2rem'
							numberOfLines={2.5}
							placeholder='특정내용 (기타내용) 입력'
							fixedHeight
						/>
					</CRInputLabel>
				)}
				name='etcSpecificDesc'
				control={control}
			/>
		</S.CheckGroupContainer>
	);
}

export default SpecificDescForm;
