import { Dayjs } from 'dayjs';

import { CheckOption } from 'components/base/Selections/type';
import { WorkScheduleInfoDTO } from 'types/dto';
import { EmployeeWorkInfoViewType } from 'types/view/employee';
import {
	EmployeeActualWorkViewType,
	EmployeeDailyWorkHistoryViewType,
} from 'types/view/workSchedule';

import { ActualWorkFormType } from './inputSchema';

export const employeeDailyWorkLogDefaultValue = (
	data: EmployeeDailyWorkHistoryViewType | undefined,
	targetDate: Dayjs,
) => {
	const targetActualWorkData = data?.actualWorkInfo ?? undefined;
	const targetWorkHistoryData = data?.workHistoryInfo?.data ?? undefined;
	const isWorkDay =
		targetDate.day() !== 0 && targetDate.day() !== 6 && data?.holidayInfo.length === 0;
	const vacationCnt = data?.vacationInfo.reduce((acc, cur) => acc + cur.data.workHourCnt, 0);
	const workHourCnt = targetWorkHistoryData?.workHourCnt || 0;
	const defaultWorkHourCnt = workHourCnt || 0;

	return {
		workStartTime: targetActualWorkData?.workStartTime
			? targetActualWorkData?.workStartTime.format('HH:mm')
			: undefined,
		workStartRemark: targetActualWorkData?.workStartRemark || undefined,
		workStartPlace:
			targetActualWorkData?.data.workPlaceCd && targetActualWorkData?.data.workPlaceNm
				? ({
						label: targetActualWorkData?.data.workPlaceNm,
						value: targetActualWorkData?.data.workPlaceCd,
					} as CheckOption)
				: undefined,
		workEndTime: targetActualWorkData?.workEndTime
			? targetActualWorkData?.workEndTime.format('HH:mm')
			: undefined,
		workEndRemark: targetActualWorkData?.workEndRemark || undefined,
		workEndPlace:
			targetActualWorkData?.data.leavePlaceCd && targetActualWorkData?.data.leavePlaceNm
				? ({
						label: targetActualWorkData?.data.leavePlaceNm,
						value: targetActualWorkData?.data.leavePlaceCd,
					} as CheckOption)
				: undefined,
		defaultWorkTimeHour: Math.floor(defaultWorkHourCnt / 60),
		defaultWorkTimeMinute: defaultWorkHourCnt % 60,
		restPeriodTimeCnt: targetWorkHistoryData?.restHourCnt || 0,
		approvedOverWorkTimeHour: Math.floor((targetWorkHistoryData?.overWorkTimeCnt || 0) / 60),
		approvedOverWorkTimeMinute: (targetWorkHistoryData?.overWorkTimeCnt || 0) % 60,
	};
};

export const handleRequestBody = ({
	currentEmployee,
	currentData,
	targetWorkHistoryData,
	targetActualWorkData,
	targetDate,
}: {
	currentEmployee: EmployeeWorkInfoViewType;
	currentData: ActualWorkFormType;
	targetWorkHistoryData: WorkScheduleInfoDTO | undefined;
	targetActualWorkData: EmployeeActualWorkViewType | undefined;
	targetDate: Dayjs;
}) => ({
	centerId: currentEmployee.centerId,
	yearMonth: targetDate.format('YYYYMM'),
	employeeId: currentEmployee.employeeId,
	workScheduleDivCd: 'CMN193.10',
	workScheduleDt: targetDate.format('YYYYMMDD'),
	workHourCnt: currentData.defaultWorkTimeHour * 60 + currentData.defaultWorkTimeMinute,
	restHourCnt: currentData.restPeriodTimeCnt,
	overWorkTimeCnt:
		currentData.approvedOverWorkTimeHour * 60 + currentData.approvedOverWorkTimeMinute,
	workRemark: currentData.workStartRemark,
	leaveRemark: currentData.workEndRemark,
	workScheduleId: targetWorkHistoryData?.workScheduleId,
	workTime: currentData.workStartTime.split(':').join(''),
	workPlaceCd: currentData.workStartPlace.value,
	leaveTime: currentData.workEndTime.split(':').join(''),
	leavePlaceCd: currentData.workEndPlace.value,
	workLeaveId: targetActualWorkData?.data.workLeaveId,
});
