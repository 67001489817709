import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';

import { VirtualItem, useVirtualizer } from '@tanstack/react-virtual';
import { Dayjs } from 'dayjs';

import Colors from 'common/colors';
import CRTable from 'components/base/CRTable';
import { CRText } from 'components/base/CRText';
import RecipientHoldingStateDetailDialog from 'components/domain/dialog/RecipientHoldingStateDetailDialog';
import { displayShortDate } from 'lib';
import useDialog from 'lib/hook/util/useDialog';
import { FtimeRecipientInfoDTO } from 'types/dto/schedule';

import { RECIPIENT_LIST_TABLE_HEADER_CONFIG, RECIPIENT_LIST_TABLE_RENDER_KEY } from './constant';
import * as S from './styles';

interface Props {
	items: FtimeRecipientInfoDTO[];
	searchValue?: string;
	date: Dayjs;
}

function RecipientScheduleListTable({ items = [], searchValue, date }: Props): React.ReactElement {
	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const { showDialog } = useDialog();
	const params = useParams<{ id: string }>();

	const rowRenderer = (virtualRow: VirtualItem<Element>, index: number) => {
		const item = items?.[virtualRow.index];
		if (!item) return null;

		const showRecipientHoldingDetail = (item: FtimeRecipientInfoDTO) => {
			showDialog(() => <RecipientHoldingStateDetailDialog recipientInfo={item} date={date} />);
		};

		const holdStateRenderer = (
			value?: string,
			item?: FtimeRecipientInfoDTO & { index: number },
		) => {
			if (!item) return null;
			const textColor =
				item.recipientStateCd === 'CMN058.30'
					? 'gray50'
					: item.scheduleCnt === 0 &&
						  item.pending !== '보류' &&
						  item.recipientStateCd !== 'CMN058.20'
						? 'primary60'
						: 'gray00';

			return (
				<S.HoldStateContainer>
					<CRText text={value ? '보류' : '-'} color={textColor} typography='label' />
					<S.DetailButton
						size='xSmall'
						palette='gray'
						type='outlined'
						$holdState={value === '보류'}
						onClick={() => showRecipientHoldingDetail(item)}>
						상세
					</S.DetailButton>
				</S.HoldStateContainer>
			);
		};

		const recipientRowCustomStyle = (recipientInfo: FtimeRecipientInfoDTO) => {
			const currentStyle: { [key in string]: React.CSSProperties } = {
				index: { textAlign: 'right', width: RECIPIENT_LIST_TABLE_HEADER_CONFIG[0].width },
			};
			RECIPIENT_LIST_TABLE_RENDER_KEY.forEach((item, index) => {
				if (!currentStyle[item]) currentStyle[item] = {};
				if (recipientInfo.scheduleCnt === 0 && recipientInfo.pending !== '보류')
					currentStyle[item].color = Colors.primary60;
				if (recipientInfo.recipientStateCd === 'CMN058.20')
					currentStyle[item].color = Colors.gray00;
				if (recipientInfo.recipientStateCd === 'CMN058.30')
					currentStyle[item].color = Colors.gray50;

				currentStyle[item].maxWidth = RECIPIENT_LIST_TABLE_HEADER_CONFIG[index].width;
				currentStyle[item].width = RECIPIENT_LIST_TABLE_HEADER_CONFIG[index].width;
			});

			return currentStyle;
		};

		return (
			<CRTable.Row
				key={item.recipientId}
				item={{ index: virtualRow.index + 1, ...item }}
				searchValue={searchValue}
				searchKey={['korMemberNm', 'birthDt']}
				customRender={{ pending: holdStateRenderer, birthDt: displayShortDate }}
				style={{
					width: '100%',
					position: 'absolute',
					transform: `translateY(${virtualRow.start}px)`,
				}}
				customStyle={recipientRowCustomStyle(item)}
				renderKeys={[
					'index',
					'korMemberNm',
					'birthDt',
					'recipientStateNm',
					'scheduleCnt',
					'pending',
					'managerNm',
				]}
			/>
		);
	};

	const rowVirtualizer = useVirtualizer({
		count: items.length,
		getScrollElement: () => scrollContainerRef.current,
		estimateSize: () => 33,
		overscan: 30,
	});

	return (
		<S.Container ref={scrollContainerRef}>
			<CRTable.Root>
				<CRTable.Head offset={-1} heads={RECIPIENT_LIST_TABLE_HEADER_CONFIG} />
				<CRTable.Body
					style={{
						height: `${rowVirtualizer.getTotalSize()}px`,
						position: 'relative',
					}}>
					{rowVirtualizer?.getVirtualItems()?.map(rowRenderer)}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default React.memo(RecipientScheduleListTable);
