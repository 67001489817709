import { endpoint } from 'lib/service/Api/endpoint';

import useCRQuery from '../base/useCRQuery';

export const useEmployeeDefaultWorkTimeList = useCRQuery(
	endpoint.getEmployeeDefaultWorkTimeList.key,
	'getEmployeeDefaultWorkTimeList',
	{
		enabledKey: ['employeeId'],
	},
);

export const useEmployeeActualWorkLogDownloadList = useCRQuery(
	endpoint.getEmployeeActualWorkLogDownloadList.key,
	'getEmployeeActualWorkLogDownloadList',
	{
		enabledKey: ['centerId', 'employeeIds', 'yearMonth'],
	},
);

export const usePcorpWorkSchedule = useCRQuery(
	endpoint.getPcorpWorkSchedule.key,
	'getPcorpWorkSchedule',
	{
		enabledKey: ['centerId', 'yearMonth'],
	},
);

export const useCenterWorkSchedule = useCRQuery(
	endpoint.getCenterWorkSchedule.key,
	'getCenterWorkSchedule',
	{
		enabledKey: ['centerId', 'yearMonth'],
	},
);

export const useCenterDefaultWorkTime = useCRQuery(
	endpoint.getCenterDefaultWorkTime.key,
	'getCenterDefaultWorkTime',
	{
		enabledKey: ['centerId'],
	},
);

export const useEmployeeWorkHistory = useCRQuery(
	endpoint.getEmployeeWorkHistory.key,
	'getEmployeeWorkHistory',
	{
		enabledKey: ['centerId', 'employeeId', 'yearMonth'],
	},
);

export const useFullTimeEmployeeWorkHistory = useCRQuery(
	endpoint.getFullTimeEmployeeWorkHistory.key,
	'getFullTimeEmployeeWorkHistory',
	{
		enabledKey: ['centerId', 'employeeId', 'yearMonth'],
	},
);
