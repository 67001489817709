import styled, { css } from 'styled-components';

import { CRText } from 'components/base/CRText';

export const Container = styled.div<{
	$isDisabled?: boolean;
	$error?: boolean;
	$focus: boolean;
}>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: relative;
	cursor: pointer;
	box-sizing: border-box;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: ${(props) => props.theme.radius.radius01};
	padding: 1.4rem 1.2rem 1.4rem 1.6rem;

	${({ $focus, theme }) =>
		$focus &&
		css`
			border: 0.2rem solid ${theme.colors.gray10};
			margin: -0.1rem;
		`}

	${({ $error, theme }) =>
		$error &&
		css`
			border: 0.2rem solid ${theme.colors.primary60};
			background-color: ${theme.colors.primary99};
			margin: -0.1rem;
		`}

	${({ $isDisabled, theme }) =>
		$isDisabled &&
		css`
			background-color: ${theme.colors.gray95};
			cursor: no-drop;
		`}
`;

export const ContentContainer = styled.input<{
	$hasValue?: boolean;
	$isDisabled?: boolean;
	$error?: boolean;
}>`
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: left;
	width: 100%;
	border: none;
	outline: none;
	caret-color: transparent;
	cursor: pointer;
	color: ${(props) => props.theme.colors.gray10};
	background: ${(props) => props.theme.colors.gray100};
	${(props) => props.theme.typography.body};

	&::placeholder {
		color: ${(props) => props.theme.colors.gray60};
		${({ $error, theme }) =>
			$error &&
			css`
				background-color: ${theme.colors.primary99};
			`}
	}

	${({ $error, theme }) =>
		$error &&
		css`
			background-color: ${theme.colors.primary99};
		`}

	${({ $isDisabled, theme }) =>
		$isDisabled &&
		css`
			background: ${theme.colors.gray95};
			border: none;
			cursor: not-allowed;
			color: ${theme.colors.gray60};
		`}
`;

export const Icon = styled.img<{ $disabled?: boolean }>`
	width: 2.4rem;
	height: 2.4rem;

	${(props) => props.$disabled && props.theme.filters.gray60};
`;

export const SelectorContainer = styled.div`
	display: flex;
	position: absolute;
	margin-top: 0.2rem;
	max-height: 27rem;
	overflow: hidden;
	background: white;
	z-index: 2;

	box-shadow: ${(props) => props.theme.elevation.elevation01};
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: ${(props) => props.theme.radius.radius01};
`;

export const ItemListContainer = styled.ul<{
	$type?: 'default' | 'small' | 'tableHeader';
	$isEmpty: boolean;
	$rightBorder?: boolean;
}>`
	flex: 1;
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 5rem;
	max-height: 26rem;

	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;
	${({ $rightBorder, theme }) =>
		$rightBorder && `border-right: 0.1rem solid ${theme.colors.gray90}`}

	${(props) =>
		props.$type === 'tableHeader' &&
		css`
			width: 16rem;
		`};
	${(props) =>
		props.$type === 'small' &&
		css`
			max-height: 20rem;
		`};
	${(props) =>
		props.$isEmpty &&
		css`
			border: none;
		`};
`;

export const TimeOption = styled.li<{ $selected?: boolean }>`
	${(props) => props.theme.typography.body};
	padding: 1rem 1.6rem;
	cursor: pointer;
	background: ${({ theme, $selected }) => ($selected ? theme.colors.primary95 : 'white')};
	display: flex;
	flex-direction: flex-start;

	&:hover {
		background: #f1f1f1;
	}
`;

export const InputDesciription = styled(CRText)``;
