import { css, styled } from 'styled-components';

export const LContainer = styled.div<{ $height?: string | number }>`
	display: flex;
	flex-direction: column;
	max-width: 160rem;
	height: ${(props) => props.$height ?? 'calc(100vh - 20rem)'};
	max-height: calc(100vh - 20rem);
	min-height: calc(100vh - 20rem);
	overflow: hidden;
	max-width: 100%;

	${(props) =>
		props.$height &&
		css`
			min-height: auto;
			height: ${props.$height};
		`};
`;

export const MLContainer = styled.div<{ $height?: string | number }>`
	display: flex;
	flex-direction: column;
	width: 108rem;
	max-height: calc(100vh - 20rem);
	overflow: hidden;
	${(props) =>
		props.$height &&
		css`
			min-height: auto;
			height: ${props.$height};
		`};
`;

export const MContainer = styled.div<{ $height?: string | number }>`
	display: flex;
	flex-direction: column;
	min-width: 80rem;
	max-width: 80rem;
	max-height: calc(100vh - 20rem);
	overflow: hidden;
	${(props) =>
		props.$height &&
		css`
			min-height: auto;
			height: ${props.$height};
		`};
`;

export const SContainer = styled.div<{ $height?: string | number }>`
	display: flex;
	flex-direction: column;
	width: 48rem;
	overflow: hidden;
	max-height: calc(100vh - 10rem);

	${(props) =>
		props.$height &&
		css`
			min-height: auto;
			height: ${props.$height};
		`};
`;

export const Title = styled.h3`
	${(props) => props.theme.typography.h3};
	color: ${(props) => props.theme.colors.gray00};
	flex: 1;
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
`;

export const HeaderContainer = styled.div<{ $showDivider: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 2.4rem 2.4rem 1.6rem 2.4rem;
	box-sizing: border-box;

	${(props) =>
		props.$showDivider &&
		css`
			border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
		`};
`;

export const BodyContainer = styled.div`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray10};
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow-y: overlay;
	overflow-x: hidden;
`;

export const FooterContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 1.6rem 2.4rem 2.4rem 2.4rem;
	box-sizing: border-box;
	gap: 0.8rem;
`;
export const SubTitle = styled.p`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray00};
`;
export const BottomContainer = styled.div`
	height: 8rem;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 1.6rem 2.4rem 2.4rem 2.4rem;
	box-sizing: border-box;
`;
