import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import dayjs from 'dayjs';

import Colors from 'common/colors';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import InformationSheet from 'components/ui/InformationSheet';
import { displayBirthDay } from 'lib';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes } from 'lib/hook/react-query';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import { IntegratedAssessmentFallForms } from 'types/view/recipient';

import * as S from './styles';

interface Props {
	form: UseFormReturn<IntegratedAssessmentFallForms, any, undefined>;
}

function IntegratedFallRecipientInfoForm({ form }: Props): React.ReactElement {
	const { control } = form;
	const { data: options = {} } = useCommonCodes(
		{ comCdGroupNms: ['CMN172', 'CMN042', 'CMN013'] },
		commonCodeAdapter,
	);

	return (
		<S.Container>
			<CRInputLabel label='수급자 정보' type='left' isRequired>
				<InformationSheet
					align='flex-start'
					items={[
						[
							{
								label: '이름',
								value: form.getValues('recipientNm'),
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
						[
							{
								label: '성별',
								value:
									options?.CMN013?.find(
										(option) => option.value === form.getValues('recipientGenderCd'),
									)?.data?.korComCdAliasNm ?? '',
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
						[
							{
								label: '생년월일',
								value: displayBirthDay(form.getValues('recipientBirthDt') ?? '', true),
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
						[
							{
								label: '장기요양인정번호',
								value: form.getValues('longTermNo'),
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
						[
							{
								label: '장기요양인정등급',
								value:
									options?.CMN042?.find(
										(option) => option.value === form.getValues('longTermGradeCd'),
									)?.data?.korComCdAliasNm ?? '',
								labelWidth: '12rem',
								valueStyle: {
									color: Colors.gray10,
								},
							},
						],
					]}
					type='gray'
				/>
			</CRInputLabel>
			<Controller
				render={({ field: { ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='조사 사유' type='left' isRequired>
						<CRCheckBoxGroup
							ref={ref}
							checkType='single'
							type='radio'
							gap={0.8}
							value={value}
							onChange={onChange}
							options={options.CMN172 ?? []}
							appearanceType='button'
						/>
					</CRInputLabel>
				)}
				name='type'
				control={control}
			/>
			<Controller
				render={({ field, formState: { errors } }) => (
					<CRInputLabel label='작성자' type='left' isRequired showOverflow>
						<CRInput.Default {...field} placeholder='작성자를 입력해주세요' />
					</CRInputLabel>
				)}
				name='writerNm'
				control={form.control}
			/>
			<Controller
				render={({ field: { value, onChange, ...rest }, formState: { errors } }) => (
					<CRInputLabel label='작성일' type='left' isRequired showOverflow>
						<CRInput.DatePicker
							{...rest}
							value={dayjs(value).toDate()}
							onChangeValue={(e) => onChange(dayjs(e).format('YYYY-MM-DD HH:mm:ss'))}
							placeholder='작성일 선택'
						/>
					</CRInputLabel>
				)}
				name='writeDate'
				control={form.control}
			/>
		</S.Container>
	);
}

export default IntegratedFallRecipientInfoForm;
