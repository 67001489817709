import ContractTab from './ContractTab';
import DetailTab from './DetailTab';
import MainTab from './MainTab';
import SubTab from './SubTab';
import TaskTab from './TaskTab';

export default {
	Default: MainTab,
	Sub: SubTab,
	Detail: DetailTab,
	Task: TaskTab,
	Contract: ContractTab,
};
