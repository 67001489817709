import Colors from 'common/colors';
import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const CONTRACT_MANAGEMENT_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '4.8rem',
		label: '구분',
		sortKey: '구분',
	},
	{
		width: '9.6rem',
		label: '상태',
		sortKey: '상태',
	},
	{
		width: '8rem',
		label: '계약유형',
		sortKey: '계약유형',
	},
	{
		width: '8rem',
		label: '수급자명',
		sortKey: '수급자명',
	},
	{
		width: '12rem',
		label: '생년월일',
		sortKey: '생년월일',
	},
	{
		width: '20rem',
		label: '급여 종류',
		sortKey: '급여 종류',
	},
	{
		width: '12rem',
		label: '계약일',
		sortKey: '계약일',
	},
	{
		width: '24rem',
		label: '이용기간',
		sortKey: '이용기간',
	},
	{
		width: '16rem',
		label: '담당 직원',
		sortKey: '담당 직원',
	},
	{
		width: '16rem',
		label: '계약 완료일',
		sortKey: '계약 완료일',
	},
	{
		width: '10rem',
		label: '사회복지사',
		sortKey: '사회복지사',
	},
	{
		width: '15rem',
		label: '',
		sortKey: '',
	},
];

export const CONTRACT_STATUS_CONFIGS: CRStatusConfig[] = [
	{
		key: 'CMN082.20',
		label: '진행중',
		color: 'yellow',
	},
	{
		key: 'CMN082.70',
		label: '보류',
		color: 'yellow',
	},
	{
		key: 'CMN082.90',
		label: '완료',
		color: 'green',
	},
	{
		key: 'CMN082.80',
		label: '취소',
		color: 'red',
	},
	{
		key: 'CMN082.00',
		label: '등록',
		color: 'gray',
		custom: {
			background: Colors.grayLighten,
			color: Colors.gray10,
		},
	},
	{
		key: 'CMN082.10',
		label: '배정대기',
		color: 'gray',
		custom: {
			background: Colors.grayLighten,
			color: Colors.gray10,
		},
	},
];
