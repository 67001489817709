import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import Assets from '../../../../assets';
import CRChips from '../../../base/CRChips';
import Highlighter from '../../../ui/Highlighter';
import * as S from './styles';

interface Props<T> {
	searchText?: string;
	onChangeSearchText?: (searchText: string) => void;
	searchItems?: T[];
}

function QuickSearchDialog<T>({
	searchText,
	onChangeSearchText,
	searchItems = [],
}: Props<T>): React.ReactElement {
	const inputRef = useRef<HTMLInputElement>(null);
	const [filters, setFilters] = useState<{
		recipient?: { label: string; value: string }[];
		employee?: { label: string; value: string }[];
	}>({});

	const handleChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
		onChangeSearchText?.(event.target.value);
	};

	const handleChangeChips = (item: any, key: string) => {
		setFilters({
			...filters,
			[key]: filters[key as keyof typeof filters] ? undefined : [item],
		});
	};

	useEffect(() => {
		inputRef.current?.focus();
	}, []);

	return (
		<S.Container>
			<S.SearchContainer>
				<S.SearchIcon src={Assets.icon.search} />
				<S.SearchInput
					ref={inputRef}
					placeholder='이름 또는 연락처로 검색'
					value={searchText}
					onChange={handleChangeSearchText}
				/>
				{!!searchText && <S.SearchIcon src={Assets.icon.closeCircle} />}
			</S.SearchContainer>
			<S.FilterContainer>
				<CRChips.Default
					filterKey='recipient'
					currentValue={filters?.recipient}
					options={[{ label: '수급자', value: '수급자' }]}
					onChangeValue={handleChangeChips}
				/>
				<CRChips.Default
					filterKey='employee'
					currentValue={filters?.employee}
					options={[{ label: '직원', value: '직원' }]}
					onChangeValue={handleChangeChips}
				/>
			</S.FilterContainer>
			<S.SearchResultContainer>
				<S.SearchResultTitle>검색 결과</S.SearchResultTitle>
				{searchItems.length ? (
					searchItems.map((searchItem) => (
						<S.SearchResultItemContainer>
							<S.SearchResultItemMainContainer>
								<Highlighter
									text='김요보(560231)'
									targetText={searchText ?? ''}
									highlightStyle={{ color: 'red' }}
								/>
							</S.SearchResultItemMainContainer>
							<S.SearchResultItemSubContainer>
								<Highlighter
									text='직원・010-2345-6789・서초센터(홍길동)'
									targetText={searchText ?? ''}
									highlightStyle={{ color: 'red' }}
								/>
							</S.SearchResultItemSubContainer>
						</S.SearchResultItemContainer>
					))
				) : (
					<S.SearchResultEmptyListContainer>
						<S.SearchResultEmptyListDescription>
							검색어로 입력해주세요
						</S.SearchResultEmptyListDescription>
					</S.SearchResultEmptyListContainer>
				)}
			</S.SearchResultContainer>
		</S.Container>
	);
}

export default QuickSearchDialog;
