import React from 'react';

import dayjs from 'dayjs';

import InformationTable from 'components/ui/InformationTable';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import { FullTimeEmployeeAnnualHistoryUseDTO } from 'types/dto/full-time-employee';

import * as S from './styles';

interface Props {
	items?: FullTimeEmployeeAnnualHistoryUseDTO[];
}

export default function AnnualTOUsageTab({ items = [] }: Props): React.ReactElement {
	return (
		<S.TabContainer>
			<InformationTable
				items={[
					[
						{
							type: 'label',
							label: '사용일',
							labelStyle: {
								width: '16rem',
							},
						},
						{
							type: 'label',
							label: '유형',
							labelStyle: {
								width: '10rem',
							},
						},
						{
							type: 'label',
							label: '사용',
							labelStyle: {
								width: '16rem',
							},
						},
						{
							type: 'label',
							label: '',
						},
					],
					...items.map(
						(item) =>
							[
								{
									type: 'value',
									value: dayjs(item.workScheduleDt).format('YYYY.MM.DD'),
								},
								{
									type: 'value',
									value: item.vacaKindNm,
								},
								{
									type: 'value',
									value: item.vacaUseDayCntNm,
								},
							] as InformationTableItemType[],
					),
				]}
			/>
		</S.TabContainer>
	);
}
