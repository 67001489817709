import React from 'react';
import { Controller } from 'react-hook-form';

import Colors from 'common/colors';
import CRInput from 'components/base/CRInput';
import { CRText } from 'components/base/CRText';
import useWorkExecuteLog from 'lib/hook/view/workExecuteLog/useWorkExecuteLog';

import * as S from './styles';

interface Props {
	disabled: boolean;
}

function RecognitiveActivityProgramForm({ disabled }: Props): React.ReactElement {
	const { form } = useWorkExecuteLog();

	return (
		<S.Container>
			<S.TableContainer>
				<S.Table>
					<S.TableHeader>
						<S.TableHeaderColumn colSpan={2} style={{ width: '19rem' }}>
							항목
						</S.TableHeaderColumn>
						<S.TableHeaderColumn style={{ width: '100%' }}>내용</S.TableHeaderColumn>
						<S.TableHeaderColumn />
					</S.TableHeader>
					<S.TableBody>
						<S.TableBodyRow>
							<S.TableBodyColumn rowSpan={2}>
								<CRText text='인지자극' typography='body' color='gray00' />
							</S.TableBodyColumn>
							<S.TableBodyColumn>
								<CRText text='필요내용' typography='body' color='gray00' />
							</S.TableBodyColumn>
							<S.TableBodyColumn style={{ width: '100%' }}>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInput.Default
											disabled={disabled}
											onChange={onChange}
											value={value}
											placeholder='필요내용 입력'
											maxLength={100}
										/>
									)}
									name='recogStimulationNeedDesc'
									control={form?.control}
								/>
							</S.TableBodyColumn>
						</S.TableBodyRow>
						<S.TableBodyRow>
							<S.TableBodyColumn
								style={{
									borderLeft: `0.1rem solid ${Colors.gray90}`,
								}}>
								<CRText text='제공방법' typography='body' color='gray00' />
							</S.TableBodyColumn>
							<S.TableBodyColumn style={{ width: '100%' }}>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInput.Default
											disabled={disabled}
											onChange={onChange}
											value={value}
											placeholder='제공방법 입력'
											maxLength={100}
										/>
									)}
									name='recogStimulationProvisionMethodDesc'
									control={form?.control}
								/>
							</S.TableBodyColumn>
						</S.TableBodyRow>
						<S.TableBodyRow>
							<S.TableBodyColumn rowSpan={2}>
								<CRText text={'신체능력\n잔존유지'} typography='body' color='gray00' />
							</S.TableBodyColumn>
							<S.TableBodyColumn>
								<CRText text='필요내용' typography='body' color='gray00' />
							</S.TableBodyColumn>
							<S.TableBodyColumn style={{ width: '100%' }}>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInput.Default
											disabled={disabled}
											onChange={onChange}
											value={value}
											placeholder='필요내용 입력'
											maxLength={100}
										/>
									)}
									name='physicalAbilityNeedDesc'
									control={form?.control}
								/>
							</S.TableBodyColumn>
						</S.TableBodyRow>
						<S.TableBodyRow>
							<S.TableBodyColumn
								style={{
									borderLeft: `0.1rem solid ${Colors.gray90}`,
								}}>
								<CRText text='제공방법' typography='body' color='gray00' />
							</S.TableBodyColumn>
							<S.TableBodyColumn style={{ width: '100%' }}>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInput.Default
											disabled={disabled}
											onChange={onChange}
											value={value}
											placeholder='제공방법 입력'
											maxLength={100}
										/>
									)}
									name='physicalAbilityProvisionMethodDesc'
									control={form?.control}
								/>
							</S.TableBodyColumn>
						</S.TableBodyRow>
					</S.TableBody>
				</S.Table>
			</S.TableContainer>
		</S.Container>
	);
}

export default React.memo(RecognitiveActivityProgramForm);
