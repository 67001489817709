import React from 'react';

import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import { EditableRecipientFormTypes } from 'lib/provider/recipient';

import RecipientGuardianForm from './RecipientGuardianForm';
import RecipientGuardianView from './RecipientGuardianView';
import * as S from './styles';

function RecipientGuardianFormView(): React.ReactElement {
	const {
		currentRecipient,
		currentRecipientGuardians,
		currentRecipientBase,
		isFormEditable,
		startChangeEditSection,
		cancelChangeEditSection,
		isEditSectionChanging,
	} = useRecipientPage();

	const onStartChangeSection = () => {
		startChangeEditSection(EditableRecipientFormTypes.보호자);
	};

	const onCancelChangeSection = () => {
		cancelChangeEditSection();
	};

	return (
		<S.Container>
			{isFormEditable(EditableRecipientFormTypes.보호자) ? (
				<RecipientGuardianForm
					currentRecipient={currentRecipient}
					currentRecipientBase={currentRecipientBase}
					items={currentRecipientGuardians}
					onStartChangeSection={onStartChangeSection}
					onCancelChangeSection={onCancelChangeSection}
					isEditSectionChanging={isEditSectionChanging}
				/>
			) : (
				<RecipientGuardianView
					items={currentRecipientGuardians}
					onChangeMode={onStartChangeSection}
				/>
			)}
		</S.Container>
	);
}

export default RecipientGuardianFormView;
