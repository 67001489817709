import { styled } from 'styled-components';

import CRTable from 'components/base/CRTable';

export const DescriptionContainer = styled.div`
	padding: 0 2.4rem 2.4rem 2.4rem;
`;

export const FooterRightSideContainer = styled.div`
	flex: 1;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 160rem;
	overflow: hidden;
`;

export const ButtonContainer = styled.div`
	align-items: center;
	gap: 0.4rem;

	tr:has(&) td:last-child > div {
		display: none;
	}

	tr:has(&):hover td:last-child > div {
		display: flex;
	}
`;
