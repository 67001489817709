import { InsuranceState } from 'pages/dashboard/InsuranceDetailPage';
import { FileDTO, FileDetailDTO } from 'types/dto';
import { StatusColor } from 'types/view/base';

import { BaseResponse } from './base';
import { PageResponse } from './common';

export enum InsuranceAcqStateCd {
	심사중 = 'CMN071.00',
	가입 = 'CMN071.10',
	상실 = 'CMN071.20',
	불능 = 'CMN071.99',
}
export interface InsuranceRequest {
	centerId?: number;
	insuranceRequestStateCds?: string[];
	insuranceRequestTypeCds?: string[];
	requesterIds?: number[];
	page: number;
	size: number;
	keyword?: string;
}

export interface InsuranceDTO {
	employeeId: number;
	employeeNm: string;
	employeeBirth: string;
	requesterNm: string;
	insuranceRequestState: {
		id: string;
		text: string;
		color: StatusColor;
	};
	insuranceRequestType: string;
	certSeveranceYn: false;
	requestDate: string;
	accidentInsuranceRequestAmt: number;
	employInsuranceRequestAmt: number;
	healthInsuranceRequestAmt: number;
	pensionInsuranceRequestAmt: number;
	accidentInsuranceRequestDate: string;
	employInsuranceRequestDate: string;
	healthInsuranceRequestDate: string;
	pensionInsuranceRequestDate: string;
	insuranceRequestId: number;
	accidentInsuranceApproveYn?: boolean;
	employInsuranceApproveYn?: boolean;
	healthInsuranceApproveYn?: boolean;
	pensionInsuranceApproveYn?: boolean;
}

export type InsuranceResponse = BaseResponse<PageResponse<InsuranceDTO[]>>;

export interface UpdateInsuranceRequestStateRequest {
	centerId?: number;
	currentState: InsuranceState;
	insuranceRequestIds: number[];
}

export type UpdateInsuranceRequestStateResponse = BaseResponse<string>;

export interface InsuranceEtcExcelDownloadRequest {
	centerId?: number;
	stateCode: string;
}

export interface InsuranceEtcExcelDownloadDTO {
	insuranceState: string;
	createdDate: string;
	name: string;
	insuranceType: string;
	pensionInsuranceRequestAmt?: number;
	pensionInsuranceRequestDate?: string;
	healthInsuranceRequestAmt?: number;
	healthInsuranceRequestDate?: string;
	employInsuranceRequestAmt?: number;
	employInsuranceRequestDate?: string;
	accidentInsuranceRequestAmt?: number;
	accidentInsuranceRequestDate?: string;
	paySumAmtCurrentYear?: number; // 당해년도 총급여액
	paySumAmtLastYear?: number; // 전년도 총금액
	paySumAmtLastYear1stHalf?: number; // 전년도 상반기 총급여액
	paySumAmtLastYear2ndHalf?: number; // 전년도 하반기 총급여액
}

export type InsuranceEtcExcelDownloadResponse = BaseResponse<InsuranceEtcExcelDownloadDTO[]>;

export interface InsuranceAcqExcelDownloadRequest {
	centerId?: number;
	stateCode: string;
}

export interface InsuranceAcqExcelDownloadDTO {
	rsdnNo: string; // 주민등록번호
	korNm: string; // 성명
	representativeYnNm: string; // 대표자 여부. 고정값 : N
	countryNm: string; // 외국인인 경우 국적
	stayQualificationNm: string; // 외국인인경우 체류자격
	pensionInsuranceAmt: number; // 소득월액(연금보험료)
	pensionInsuranceAcqDate: string; // 국민연금자격취득일시
	acqMonthPayYn: string; // 취득월납부여부. 국민연금자격취득일시가 1일인 경우 1, 그 외는 2.(단, null인 경우 empty string)
	pensionInsuranceAcqSign: string; // 국민연금자격취득부호. null이 아닌 경우, 고정값:1
	dependentYn: string; // 피부양자신청여부
	healthInsuranceAmt: number; // 보수월액(건강보험료)
	healthInsuranceAcqDate: string; // 건강보험자격취득일시
	healthInsuranceAcqSign: string; // 건강보험자격취득부호. null이 아닌 경우, 고정값:13
	employInsuranceAmt: number; // 월평균보수(고용보험료)
	employInsuranceAcqDate: string; // 고용보험자격취득일시
	dutySign: string; // 직종부호
	weekPerWorkHourCnt: number; // 주소정근로시간수
	workFormYn: string; // 계약직여부. null이 아닌 경우, 고정값:2
	workEndYearMonth: string; // 계약종료년월
	accidentInsuranceAmt: number; // 월평균보수(산재보험료)
	accidentInsuranceAcqDate: string; // 산재보험자격취득일시
	enNm: string;
}

export type InsuranceAcqExcelDownloadResponse = BaseResponse<InsuranceAcqExcelDownloadDTO[]>;

export interface InsuranceLossExcelDownloadRequest {
	centerId?: number;
	stateCode: string;
}

export interface InsuranceLossExcelDownloadDTO {
	name: string; // 성명
	rsdnNo: string; // 주민등록번호
	pensionInsuranceLossDt: string; // 국민연금상실일
	pensionInsuranceLossSign: string; // 국민연금상실부호
	pensionInsurancePayYn: string; // 국민연금초일취득당월상실자납부여부
	healthInsuranceLossDt: string; // 건강보험상실일자
	healthInsuranceLossSign: string; // 건강보험상실부호
	healthInsurancePaySumAmtCurrentYear: string; // 건강보험당해년도보수총액
	healthInsurancePaySumAmtLastYear: string; // 건강보험전년도보수총액
	healthInsuranceWorkMonthCurrentYear: string; // 건강보험당해년도근무개월수
	healthInsuranceWorkMonthLastYear: string; // 건강보험전년도근무개월수
	employInsuranceLossDt: string; // 고용보험상실일자
	employInsuranceLossReasonDivCd: string; // 고용보험상실사유구분코드
	employInsuranceLossReasonDetailCd: string; // 고용보험상실구체적사유
	employInsurancePaySumAmtCurrentYear: string; // 고용보험당해년도보수총액
	employInsurancePaySumAmtLastYear: string; // 고용보험전년도보수총액
	accidentInsuranceLossDt: string; // 산재보험상실일자
	accidentInsurancePaySumAmtCurrentYear: string; // 산재보험당해년도보수총액
	accidentInsurancePaySumAmtLastYear: string; // 산재보험전년도보수총액
}

export type InsuranceLossExcelDownloadResponse = BaseResponse<InsuranceLossExcelDownloadDTO[]>;

export interface EmployeeInsuranceRequest {
	centerId?: number;
	employeeId?: number;
}

export interface EmployeeInsuranceDTO {
	insuranceRequestState: {
		id: string;
		text: string;
		color: StatusColor;
	};
	insuranceRequestType: string;
	certSeveranceYn: number;
	requestDate: string;
	accidentInsuranceRequestAmt: number;
	employInsuranceRequestAmt: number;
	healthInsuranceRequestAmt: number;
	pensionInsuranceRequestAmt: number;
	accidentInsuranceRequestDate: string;
	employInsuranceRequestDate: string;
	healthInsuranceRequestDate: string;
	pensionInsuranceRequestDate: string;
	insuranceRequestId: number;
	accidentInsuranceApproveYn?: boolean;
	employInsuranceApproveYn?: boolean;
	healthInsuranceApproveYn?: boolean;
	pensionInsuranceApproveYn?: boolean;
}

export type EmployeeInsuranceResponse = BaseResponse<EmployeeInsuranceDTO[]>;

export interface InsuranceDependent {
	dependentRsdnNo: string;
	dependentNm: string;
	dependentRelCd: string;
	dependentRegistDt: string;
	dependentCountryCd?: string | null;
	dependentStayStartDt?: string;
	dependentStayEndDt?: string;
}

export interface InsuranceRequestDetail {
	accidentInsuranceRequestAmt?: number; // 산재보험
	accidentInsuranceRequestDate?: string;
	pensionInsuranceRequestAmt?: number; // 국민연금
	pensionInsuranceRequestDate?: string;
	employInsuranceRequestAmt?: number; // 고용보험
	employInsuranceRequestDate?: string;
	healthInsuranceRequestAmt?: number; // 건강보험
	healthInsuranceRequestDate?: string;
	accidentInsuranceApproveYn?: boolean; // 산재보험승인여부
	employInsuranceApproveYn?: boolean; // 고용보험승인여부
	healthInsuranceApproveYn?: boolean; // 건강보험승인여부
	pensionInsuranceApproveYn?: boolean; // 국민연금승인여부
}

export interface EmployeeInsuranceDetails {
	accidentInsuranceDate?: string;
	employInsuranceDate?: string;
	healthInsuranceDate?: string;
	pensionInsuranceDate?: string;
	accidentInsuranceAmt?: number;
	employInsuranceAmt?: number;
	healthInsuranceAmt?: number;
	pensionInsuranceAmt?: number;
}

export type EmployeeInsuranceDetailDate = Pick<
	EmployeeInsuranceDetails,
	'accidentInsuranceDate' | 'employInsuranceDate' | 'healthInsuranceDate' | 'pensionInsuranceDate'
>;

export interface CreateAcquirementInsuranceRequest {
	centerId: number;
	insuranceRequestTypeCd: string;
	weekPerWorkHourCnt: number;
	requestDesc: string;
	attachFile?: {
		fileDetails: FileDetailDTO[];
	};
	dependents: InsuranceDependent[];
	insuranceRequestDetails?: InsuranceRequestDetail;
	unemploymentBenefitPreconditionFullYn?: boolean;
	edocNo?: string;
}

// 취득일장 정정, 상실일자 정정
export interface CreateDateChangeInsuranceRequest {
	centerId: number;
	insuranceRequestTypeCd: string;
	requestDesc: string;
	attachFile?: {
		fileDetails: FileDetailDTO[];
	};
	insuranceRequestDetails?: InsuranceRequestDetail;
	employeeInsuranceDetails?: EmployeeInsuranceDetails;
	unemploymentBenefitPreconditionFullYn?: boolean;
}

export interface CreateCancelInsuranceRequest {
	centerId: number;
	insuranceRequestTypeCd: string;
	requestDesc: string;
	attachFile?: {
		fileDetails: FileDetailDTO[];
	};
	insuranceRequestDetails?: InsuranceRequestDetail;
	employeeInsuranceDetails?: EmployeeInsuranceDetails;
	edocNo?: string;
}

// 신고금액변경
export interface CreateChangeSalaryInsuranceRequest {
	centerId: number;
	insuranceRequestTypeCd: string;
	insuranceRequestDetails?: InsuranceRequestDetail;
	salaryChangeYm: string;
	salaryChangeDescCd: string;
	requestDesc: string;
	attachFile?: {
		fileDetails: FileDetailDTO[];
	};
	employeeInsuranceDetails?: EmployeeInsuranceDetails;
}

// 입퇴사자일자 정정 신고
export interface CreateResignInsuranceRequest {
	centerId: number;
	insuranceRequestTypeCd: string;
	requestDesc: string;
	attachFile?: {
		fileDetails: FileDetailDTO[];
	};
}

// 상실 신고
export interface CreateLossInsuranceRequest {
	centerId: number;
	insuranceRequestTypeCd: string;
	insuranceRequestDetails?: InsuranceRequestDetail;
	lossDescCd: string;
	lossDetailDescCd?: string;
	currentMonthSalaryAmt: number;
	currentMonthWorkHourCnt: number;
	requestDesc?: string;
	attachFileRequestDesc?: string; // 이직확인서 요청
	attachFile?: {
		fileDetails: FileDetailDTO[];
	};
	edocNo?: string;
}

export type CreateInsuranceRequest =
	| CreateAcquirementInsuranceRequest
	| CreateCancelInsuranceRequest
	| CreateLossInsuranceRequest
	| CreateResignInsuranceRequest;

export interface CreateInsuranceDTO {
	insuranceRequestId: number;
	requestDate: string;
	insuranceRequestState: {
		id: string;
		text: string;
	};
}

export type CreateInsuranceResponse = BaseResponse<CreateInsuranceDTO>;

export interface InsurancePriorInfoRequest {
	centerId?: number;
	insuranceTypeCd?: string;
	employeeId: number;
}

export interface InsurancePriorInfoDTO {
	accidentInsurancePosbYn: boolean;
	employInsurancePosbYn: boolean;
	healthInsurancePosbYn: boolean;
	pensionInsurancePosbYn: boolean;
	accidentInsuranceDate: string;
	employInsuranceDate: string;
	healthInsuranceDate: string;
	pensionInsuranceDate: string;
	accidentInsuranceAmt: number;
	employInsuranceAmt: number;
	healthInsuranceAmt: number;
	pensionInsuranceAmt: number;
}

export type InsurancePriorInfoResponse = BaseResponse<InsurancePriorInfoDTO>;

export interface InsuranceSalaryFeeInfoRequest {
	centerId?: number;
	employeeId?: number;
	targetDt: string;
}

export interface SalaryFee {
	salaryFeeNm: string;
	workHourCnt: number;
	salaryFeeValue: number;
	generalCareYn: boolean;
}

export interface TSalaryFee {
	serviceType: string;
	salaryFees: SalaryFee[];
}

export interface InsuranceSalaryFeeInfoDTO {
	targetSalaryInfo?: {
		totalWorkHourCnt: number;
		paySumAmt: number;
	};
	targetSalaryFees: TSalaryFee[];
}

export type InsuranceSalaryFeeInfoResponse = BaseResponse<InsuranceSalaryFeeInfoDTO>;

export interface InsuranceDetailRequest {
	employeeId?: string;
	insuranceRequestId?: string;
}

export interface EDocSimpResponse {
	edocNo: string;
	title: string;
	signState: {
		id: string;
		text: string;
		color: StatusColor;
	};
}

export interface InsuranceDetailDTO {
	insuranceRequestType: {
		id: string;
		text: string;
	};
	currentMonthSalaryAmt?: number; // 당월급여
	lastMonthSalaryAmt?: number; // 전월급여
	weekPerWorkHourCnt?: number; // 주소정근로시간수
	currentMonthWorkHourCnt?: number; // 당월근로시간수
	lastMonthWorkHourCnt?: number; // 전월근로시간수
	salaryChangeYm?: string; // 보수액변경년월
	lossDesc?: string; // 상실사유
	lossDetailDesc?: string; // 상실상세사유
	requestDesc?: string; // 요청사항
	responseDesc?: string; // 답변내용
	attachFileResponseDesc?: string; // 첨부파일답변내용, 신고요청 타입이 상실이거나 이직확인서 요청인 경우, 이직확인서답변 입니다
	attachFile?: FileDTO;
	attachFileRequestDesc?: string;
	requestDate?: string; // 요청일시
	approverNm?: string; // 결재자명
	approvalDate?: string; // 결재일시
	cancelDesc?: string; // 취소 사유
	insuranceRequestId: number; // 4대보험 신고 id
	insuranceRequestState: {
		id: string;
		text: string;
	};
	insuranceRequestDetails: InsuranceRequestDetail;
	dependents: InsuranceDependent[];
	unemploymentBenefitPreconditionFullYn: boolean;
	salaryChangeDesc: string;
	employeeInsuranceDetails: EmployeeInsuranceDetails;
	edocInfo: EDocSimpResponse;
}

export type InsuranceDetailResponse = BaseResponse<InsuranceDetailDTO>;

export interface InsuranceDetailEditRequest {
	insuranceRequestStateCd: string;
	responseDesc?: string;
	attachFileResponseDesc: string;
	cancelDesc: string;
}

export interface UpdateInsuranceDetailRequest {
	employeeId?: string;
	insuranceRequestId?: string;
	insuranceRequestStateCd: string;
	responseDesc?: string;
	attachFileResponseDesc?: string;
	cancelDesc?: string;
	accidentInsuranceApproveYn?: boolean;
	employInsuranceApproveYn?: boolean;
	healthInsuranceApproveYn?: boolean;
	pensionInsuranceApproveYn?: boolean;
}

export type UpdateInsuranceLoss = Pick<
	UpdateInsuranceDetailRequest,
	| 'employeeId'
	| 'insuranceRequestId'
	| 'responseDesc'
	| 'attachFileResponseDesc'
	| 'insuranceRequestStateCd'
	| 'cancelDesc'
	| 'employInsuranceApproveYn'
	| 'healthInsuranceApproveYn'
	| 'pensionInsuranceApproveYn'
	| 'accidentInsuranceApproveYn'
>;

export type UpdateInsuranceAcquirement = Pick<
	UpdateInsuranceDetailRequest,
	| 'employeeId'
	| 'insuranceRequestId'
	| 'responseDesc'
	| 'insuranceRequestStateCd'
	| 'cancelDesc'
	| 'employInsuranceApproveYn'
	| 'healthInsuranceApproveYn'
	| 'pensionInsuranceApproveYn'
	| 'accidentInsuranceApproveYn'
>;

export type UpdateInsuranceChangeSalary = Pick<
	UpdateInsuranceDetailRequest,
	'employeeId' | 'insuranceRequestId' | 'responseDesc' | 'insuranceRequestStateCd' | 'cancelDesc'
>;

export type UpdateInsuranceChangeJob = Pick<
	UpdateInsuranceDetailRequest,
	| 'employeeId'
	| 'insuranceRequestId'
	| 'attachFileResponseDesc'
	| 'insuranceRequestStateCd'
	| 'cancelDesc'
>;

// eslint-disable-next-line
export interface UpdateInsuranceDetailDTO {}

export type UpdateInsuranceDetailResponse = BaseResponse<UpdateInsuranceDetailDTO>;

export interface CheckInsuranceRequiredEDocRequest {
	employeeId: number;
	centerId: number;
	insuranceRequestType: string;
	weekPerWorkHourCnt?: number;
}

export interface CheckInsuranceRequiredEDocDTO {
	edocInfo: EDocSimpResponse;
	requiredEdoc: {
		title: string;
		paperTypeCd: string;
	};
	edocNeedYn: boolean;
}

export type CheckInsuranceRequiredEDocResponse = BaseResponse<CheckInsuranceRequiredEDocDTO>;
