import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import dayjs from 'dayjs';

import CRTable from 'components/base/CRTable';
import CRTableHeader from 'components/base/CRTableHeader';
import RdPopover from 'components/ui/radix/popover/RdPopover';
import { match } from 'lib';
import { Alarm } from 'types/view/alarm';
import { FilterType } from 'types/view/filter';

import TargetListPopup from './TargetListPopup';
import { ALARM_TABLE_HEADER_CONFIG } from './constant';
import * as S from './styles';

interface Props<T extends { label: string; value: any }> {
	items: Alarm[];
	searchValue?: string;
	currentFilter: {
		[key in string]: T[];
	};
	setCurrentFilter?: React.Dispatch<
		React.SetStateAction<{
			[key: string]: T[];
		}>
	>;
	renderCustomFilter?: React.ReactElement;
	onChangeSearchValue?: (searchValue: string) => void;
}

function AlarmTable<T extends { label: string; value: any }>({
	items = [],
	currentFilter,
	setCurrentFilter,
	searchValue,
	renderCustomFilter,
	onChangeSearchValue,
}: Props<T>): React.ReactElement {
	const targetLinkRef = useRef<HTMLDivElement>(null);

	const dateCustomRender = (value: string) => {
		const targetDate = dayjs(value);
		const date = match(targetDate)
			.on(
				(target) => target.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD'),
				() => '오늘',
			)
			.on(
				(target) => target.diff(dayjs(), 'day') === -1,
				() => '어제',
			)
			.otherwise(() => targetDate.format('YYYY년 M월 DD일'));
		return <S.TargetListContainer>{date}</S.TargetListContainer>;
	};

	const targetCustomRender = (value: string, item?: Alarm | undefined) => {
		if (item?.allimTargetLinkList && item?.allimTargetLinkList?.length > 1) {
			return (
				<S.TargetListContainer ref={targetLinkRef}>
					<RdPopover
						borderShape='small'
						showDivider={false}
						align='start'
						side='left'
						alignOffset={50}
						content={<TargetListPopup items={item.allimTargetLinkList} />}>
						<S.TargetText $isMultiple>
							김케어 (500505) 외 {item.allimTargetLinkList.length - 1} 명
						</S.TargetText>
					</RdPopover>
				</S.TargetListContainer>
			);
		}
		return <S.TargetListContainer>김케어 (500505)</S.TargetListContainer>;
	};

	return (
		<S.Container>
			<CRTableHeader
				renderCustomFilter={renderCustomFilter}
				currentFilter={currentFilter}
				filters={[]}
				searchValue={searchValue}
				placeholder='Place Holder'
				onChangeSearchValue={onChangeSearchValue}
				setCurrentFilter={setCurrentFilter}
				stickyMode
			/>
			<CRTable.Root>
				<CRTable.Head heads={ALARM_TABLE_HEADER_CONFIG} />
				<CRTable.Body>
					{items.map((item) => (
						<CRTable.Row
							key={item.allimId}
							item={item}
							renderKeys={[
								'allimDesc',
								'allimTargetLinkList',
								'allimConfirmDate',
								'mainEmployee',
								'subEmployee',
							]}
							customRender={{
								allimConfirmDate: dateCustomRender,
							}}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default AlarmTable;
