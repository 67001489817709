import React, { useMemo } from 'react';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRSpinner from 'components/base/CRSpinner';
import CRTableHeader from 'components/base/CRTableHeader';
import AddFullTimeEmployeeLicenseDialog from 'components/domain/dialog/AddFullTimeEmployeeLicenseDialog';
import FullTimeEmployeeLicenseTable from 'components/domain/table/FullTimeEmployeeLicenseTable';
import useFullTimeEmployeePage from 'lib/hook/full-time-employee/useFullTimeEmployeePage';
import { useCommonCodes, useFullTimeEmployeeLicenses } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';

import * as S from './styles';

export default function FullTimeEmployeeLicenseTab(): React.ReactElement {
	const { data: commonCodes } = useCommonCodes({
		comCdGroupNms: ['CMN067'],
	});

	const licenseTypes = useMemo(
		() =>
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN067')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [],
		[commonCodes],
	);
	const { showDialog } = useDialog();
	const { currentEmployee } = useFullTimeEmployeePage();

	const {
		data: fullTimeEmployeeLicenses,
		isLoading,
		refetch,
	} = useFullTimeEmployeeLicenses({
		employeeId: currentEmployee?.employeeId,
	});

	const handleRefetch = () => {
		refetch();
	};

	const handleClickAddCareer = () => {
		showDialog(({ hideDialog }) => (
			<AddFullTimeEmployeeLicenseDialog
				onDelete={handleRefetch}
				currentEmployee={currentEmployee}
				licenseTypes={licenseTypes}
				hideDialog={hideDialog}
			/>
		));
	};

	return (
		<S.Container>
			<CRTableHeader
				currentFilter={{}}
				filters={[]}
				hideSearchButton
				renderRightButton={
					<CRButton.IconButton
						size='xSmall'
						type='filled'
						iconLeft={Assets.icon.add}
						onClick={handleClickAddCareer}>
						자격증 추가
					</CRButton.IconButton>
				}
			/>
			{isLoading ? (
				<CRSpinner />
			) : (
				<S.TableContainer>
					<FullTimeEmployeeLicenseTable
						onRefetch={handleRefetch}
						currentEmployee={currentEmployee}
						items={fullTimeEmployeeLicenses}
						licenseTypes={licenseTypes}
					/>
				</S.TableContainer>
			)}
		</S.Container>
	);
}
