import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { CheckOption } from 'components/base/Selections/type';

export type FiltersType = {
	[key: string]: CheckOption[];
};

const useDateFilter = (initialMonth?: Date) => {
	const location = useLocation(); // 현재 경로 가져오기
	const pageKey = location.pathname; // 페이지 경로를 고유한 키로 사용

	const [date, setDate] = useState<Date>(() => {
		// 초기에 세션 스토리지에서 필터값 불러오기
		const savedDate = sessionStorage.getItem(`date-filter-${pageKey}`);
		return savedDate ? new Date(JSON.parse(savedDate)) : initialMonth || new Date();
	});

	// 필터값이 변경될 때마다 세션 스토리지에 저장
	useEffect(() => {
		if (date) {
			sessionStorage.setItem(`date-filter-${pageKey}`, JSON.stringify(date));
		} else {
			sessionStorage.removeItem(`date-filter-${pageKey}`);
		}
	}, [date, pageKey]);

	return [date, setDate] as const; // 튜플 타입 반환
};

export default useDateFilter;
