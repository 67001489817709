import React, { useCallback } from 'react';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRFileName from 'components/base/CRFileName';
import CRTable from 'components/base/CRTable';
import { Toast } from 'components/base/CRToast';
import { CheckOption } from 'components/base/Selections/type';
import AddLicenseDialog from 'components/domain/dialog/AddLicenseDialog';
import DeleteDialog from 'components/domain/dialog/DeleteDialog';
import { useDeleteEmployeeLicense } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { EmployeeDTO, EmployeeLicenseInfoDTO } from 'types/api/employee';
import { FileDTO } from 'types/dto/common';

import { useDownload } from '../../../../lib/hook/util/useDownload';
import { STAFF_LICENSE_LIST_TABLE_HEADER_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	items?: EmployeeLicenseInfoDTO[] | null;
	offset?: number;
	licenseTypes: CheckOption[];
	currentEmployee?: Partial<EmployeeDTO>;
	onRefetch?: () => void;
}

function ContractEmployeeLicenseTable({
	items = [],
	licenseTypes,
	offset = 0,
	currentEmployee,
	onRefetch,
}: Props): React.ReactElement {
	const { showDialog, hideDialog } = useDialog();
	const { mutate: deleteEmployeeLicense } = useDeleteEmployeeLicense((client, returnData) => {
		if (returnData?.employeeId) {
			client.invalidateQueries([
				endpoint.getEmployeeLicenseInfo.key,
				{ employeeId: currentEmployee?.employeeId },
			]);
			Toast.success(`자격증이 삭제되었습니다.`);
			hideDialog();
		}
	});
	const { downloadFiles } = useDownload();

	const handleDeleteLicenseItem = async (item?: EmployeeLicenseInfoDTO) => {
		if (!item) return;

		const deleteLicense = async (item?: EmployeeLicenseInfoDTO) => {
			if (!item) return;
			deleteEmployeeLicense({
				employeeLicenseId: item.employeeLicenseId,
			});
		};

		showDialog(({ hideDialog }) => (
			<DeleteDialog
				title='자격증 삭제'
				content={`${currentEmployee?.korMemberNm} 님의 자격증을 삭제하겠습니다.`}
				hideDialog={hideDialog}
				cancelOption={{
					text: '취소',
				}}
				successOption={{
					text: '삭제',
					successCallback: () => deleteLicense(item),
				}}
			/>
		));
	};

	const handleClickEditLicense = (item?: EmployeeLicenseInfoDTO) => {
		showDialog(({ hideDialog }) => (
			<AddLicenseDialog
				onDelete={onRefetch}
				item={item}
				currentEmployee={currentEmployee}
				licenseTypes={licenseTypes}
				hideDialog={hideDialog}
			/>
		));
	};

	const renderOption = (value: number, item?: EmployeeLicenseInfoDTO) => (
		<S.ButtonContainer>
			{item && item.licenseFile?.fileDetails?.some((item) => !item.fileDeleteYn) && (
				<CRButton.Default
					size='xSmall'
					type='outlined'
					palette='gray'
					onClick={() =>
						downloadFiles(item.licenseFile?.fileDetails.filter((item) => !item.fileDeleteYn))
					}>
					다운로드
				</CRButton.Default>
			)}
			<CRButton.Default
				size='xSmall'
				type='outlined'
				palette='gray'
				onClick={() => handleClickEditLicense(item)}>
				수정
			</CRButton.Default>
			<CRButton.Default
				size='xSmall'
				type='outlined'
				palette='primary'
				onClick={() => handleDeleteLicenseItem(item)}>
				삭제
			</CRButton.Default>
		</S.ButtonContainer>
	);

	const renderLicenseIssueDate = (value: string, item?: EmployeeLicenseInfoDTO) => (
		<span>{dayjs(item?.licenseIssueDate).format('YYYY.MM.DD')}</span>
	);

	const renderLicenseIssueInstitutionNm = (value: string, item?: EmployeeLicenseInfoDTO) => (
		<span>{value || '-'}</span>
	);

	const renderLicenseFile = useCallback((item?: FileDTO) => {
		const fileNames = item?.fileDetails
			?.filter((file) => !file.fileDeleteYn)
			?.map((data) => data.originFileNm);
		return <CRFileName maxLength={12} fileNames={fileNames} />;
	}, []);

	return (
		<S.Container>
			<CRTable.Root>
				<CRTable.Head heads={STAFF_LICENSE_LIST_TABLE_HEADER_CONFIG} offset={offset} />
				<CRTable.Body>
					{items?.map((item) => (
						<CRTable.Row
							key={item.employeeLicenseId}
							item={item}
							renderKeys={[
								'licenseNm',
								'licenseNo',
								'licenseIssueDate',
								'licenseIssueInstitutionNm',
								'employeeLicenseId',
							]}
							toolTipKey={['licenseIssueInstitutionNm']}
							customRender={{
								licenseIssueDate: renderLicenseIssueDate,
								licenseIssueInstitutionNm: renderLicenseIssueInstitutionNm,
								employeeLicenseId: renderOption,
								licenseFile: renderLicenseFile,
							}}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default ContractEmployeeLicenseTable;
