import React, { ReactNode } from 'react';

import dayjs, { Dayjs } from 'dayjs';

import Assets from 'assets';
import CRMonthCalendar from 'components/base/CRMonthCalendar';
import { CRText } from 'components/base/CRText';
import {
	EmployeeDailyWorkHistoryViewType,
	EmployeeVacationInfoViewType,
	EmployeeWorkHistoryDataViewType,
	EmployeeWorkHistoryViewType,
} from 'types/view/workSchedule';

import { CalenderWorkStatusType, handleWorkCalendarLabelStatus } from './config';
import * as S from './styles';

export interface Props {
	date?: Date;
	height?: string;
	workCalendarData?: EmployeeWorkHistoryDataViewType;
	hoverBackgroundColor?: string;
	handleClickWorkHistoryLabel?: (date: Dayjs, data?: EmployeeDailyWorkHistoryViewType) => void;
	handleClickVacationLabel?: (
		data: EmployeeVacationInfoViewType,
		confirmed: boolean,
		targetDate: Dayjs,
	) => void;
	renderCustomCalendarHeader?: () => ReactNode;
	renderDayHoverContent?: (date: Dayjs) => ReactNode;
	onChange: (date: Date) => void;
}

export default function WorkScheduleCalendar({
	date,
	height,
	workCalendarData,
	hoverBackgroundColor,
	handleClickWorkHistoryLabel,
	handleClickVacationLabel,
	onChange,
	renderCustomCalendarHeader,
	renderDayHoverContent,
}: Props) {
	const currentDate = dayjs(date);

	const renderCustomDayHeaderTag = (
		tagList: {
			data: EmployeeVacationInfoViewType | EmployeeWorkHistoryViewType;
			label: string;
			onClick?: () => void;
		}[],
	) =>
		tagList.map((item) => (
			<S.DayHeaderTag onClick={item.onClick}>
				<CRText typography='label'>{item.label}</CRText>
			</S.DayHeaderTag>
		));

	const isSameDay = (targetDate: Dayjs, refDate: Dayjs) =>
		refDate.isSame(targetDate, 'D') && refDate.isSame(targetDate, 'M');

	const renderCustomDayHeader = (renderDate: Dayjs) => {
		if (!renderDate.isSame(currentDate, 'M')) return null;
		const workHistOfTargetDate = workCalendarData?.dailyWorkHistory.filter((item) =>
			isSameDay(renderDate, dayjs(item.date)),
		)[0];

		const currentTagList = [
			workHistOfTargetDate && workHistOfTargetDate?.workHistoryInfo?.tagText
				? {
						data: workHistOfTargetDate.workHistoryInfo,
						label: workHistOfTargetDate.workHistoryInfo.tagText,
						onClick: handleClickWorkHistoryLabel
							? () => handleClickWorkHistoryLabel(renderDate, workHistOfTargetDate)
							: undefined,
					}
				: undefined,

			...(workHistOfTargetDate?.vacationInfo?.filter((item) => item.tagText) ?? []).map((item) => ({
				data: item,
				label: item.tagText,
				onClick: handleClickVacationLabel
					? () =>
							handleClickVacationLabel(item, workHistOfTargetDate?.confirmed ?? false, renderDate)
					: undefined,
			})),
		].filter((item) => !!item);

		if (!currentTagList.length) return null;
		return renderCustomDayHeaderTag(currentTagList);
	};

	const renderCustomDayFooter = (renderDate: Dayjs) => {
		if (!renderDate.isSame(currentDate, 'M')) return [];
		if (renderDate.isAfter(dayjs(), 'D')) return [];

		const workHistOfTargetDate = workCalendarData?.dailyWorkHistory.filter((item) =>
			isSameDay(renderDate, dayjs(item.date)),
		)[0];
		const calendarLabelStatus = workHistOfTargetDate
			? handleWorkCalendarLabelStatus(workHistOfTargetDate)
			: { status: CalenderWorkStatusType.Success };

		const handleClick = handleClickWorkHistoryLabel
			? () => handleClickWorkHistoryLabel(renderDate, workHistOfTargetDate)
			: undefined;

		const footerLabelClassName = `type--${calendarLabelStatus.status}`;

		return workHistOfTargetDate?.shouldRenderNoHistoryLabel ? (
			<S.WorkScheduleLabel
				className={footerLabelClassName}
				onClick={handleClick}
				$confirmed={false}>
				기록 없음
			</S.WorkScheduleLabel>
		) : workHistOfTargetDate?.workHistoryInfo?.labelText ? (
			<S.WorkScheduleLabel
				className={footerLabelClassName}
				onClick={handleClick}
				$confirmed={workHistOfTargetDate ? workHistOfTargetDate.confirmed : false}>
				{workHistOfTargetDate.confirmed ? (
					<S.StyledCheckIcon className={footerLabelClassName} src={Assets.icon.check} />
				) : null}
				{workHistOfTargetDate.workHistoryInfo.labelText}
			</S.WorkScheduleLabel>
		) : null;
	};

	return (
		<CRMonthCalendar
			height={height}
			currentDate={currentDate}
			onChange={onChange}
			holidayList={workCalendarData?.data.holidays ?? []}
			hoverBackgroundColor={hoverBackgroundColor}
			renderCustomCalendarHeader={renderCustomCalendarHeader}
			renderCustomDayFooter={renderCustomDayFooter}
			renderCustomDayHeader={renderCustomDayHeader}
			renderDayHoverContent={renderDayHoverContent}
		/>
	);
}
