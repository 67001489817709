import React from 'react';

import Assets from 'assets';
import Filters from 'common/filter';
import CRButton from 'components/base/CRButton';
import { CRText } from 'components/base/CRText';

import * as S from './styles';

interface Props {
	onClick: () => void;
}

function EDocClientButtonNext({ onClick }: Props) {
	return (
		<CRButton.Default onClick={onClick} style={{ width: '100%', height: '5.6rem' }}>
			<CRText typography='h4' color='white'>
				다음
			</CRText>
			<S.Icon src={Assets.icon.keyboardArrowRight} $filter={Filters.gray100} />
		</CRButton.Default>
	);
}

export default EDocClientButtonNext;
