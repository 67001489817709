import React, { useCallback } from 'react';

import dayjs from 'dayjs';

import CRTable from 'components/base/CRTable';
import { RecipientConsulting } from 'types/view/recipient';

import CRBadge from '../../../base/CRBadge';
import { RECIPIENT_CONSULTING_LIST_TABLE_HEADER_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	items: RecipientConsulting[];
	onClickRecipientConsulting?: (item: RecipientConsulting) => void;
}

function RecipientConsultingTable({
	items = [],
	onClickRecipientConsulting,
}: Props): React.ReactElement {
	const DateComponent = useCallback((item: string) => dayjs(item).format('YYYY.MM.DD HH:mm'), []);
	const TagComponent = useCallback((item: string) => <CRBadge>{item}</CRBadge>, []);
	return (
		<S.Container>
			<CRTable.Root>
				<CRTable.Head heads={RECIPIENT_CONSULTING_LIST_TABLE_HEADER_CONFIG} />
				<CRTable.Body>
					{items.map((item) => (
						<CRTable.Row
							onClick={() => onClickRecipientConsulting?.(item)}
							key={item.id}
							item={item}
							customRender={{
								date: DateComponent,
								tag: TagComponent,
							}}
							renderKeys={['date', 'consultant', 'relation', 'type', 'tag', 'center', 'employee']}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default RecipientConsultingTable;
