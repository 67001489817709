import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import Assets from 'assets';
import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import CRInputLabel from 'components/base/CRInputLabel';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import InformationSheet from 'components/ui/InformationSheet';
import { displayBirthDay } from 'lib';
import useWorkExecuteInspectionLog from 'lib/hook/view/workExecuteInspectionLog/useWorkExecuteInspectionLog';
import { WorkExecuteLogNewRecipient } from 'types/api/workExecuteLog';

import * as S from './styles';

export enum WorkExecuteLogCode {
	사회복지사 = 'CMN183.10',
	프로그램관리자 = 'CMN183.20',
}

export const insuranceTypes = [
	{
		label: '사회복지사 업무수행일지',
		value: WorkExecuteLogCode.사회복지사,
	},
	{
		label: '프로그램관리자 업무수행일지',
		value: WorkExecuteLogCode.프로그램관리자,
	},
] as CheckOption<any>[];

interface Props {
	recipient?: WorkExecuteLogNewRecipient;
	disabled: boolean;
}

function EDocWorkExecuteLogBasicInfoForm({ recipient, disabled }: Props): React.ReactElement {
	const { form } = useWorkExecuteInspectionLog();
	const [isOpenRecipientInfo, setIsOpenRecipientInfo] = useState(false);

	const handleOpenRecipientInfo = () => {
		setIsOpenRecipientInfo((prev) => !prev);
	};

	return (
		<S.Container>
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInputLabel isRequired label='구분' type='top'>
						<CRCheckBoxGroup
							disabled={disabled}
							direction='col'
							onChange={onChange}
							appearanceType='button'
							checkType='single'
							type='radio'
							gap={0.8}
							value={value}
							options={insuranceTypes}
						/>
					</CRInputLabel>
				)}
				name='workExecuteLogDivCd'
				control={form?.control}
			/>
			<div>
				<CRButton.IconButton
					onClick={handleOpenRecipientInfo}
					iconRight={
						isOpenRecipientInfo ? Assets.icon.keyboardArrowTop : Assets.icon.keyboardArrowBottom
					}
					palette='gray'
					type='outlined'
					size='large'
					fullSize>
					수급자 정보
				</CRButton.IconButton>
				{isOpenRecipientInfo && (
					<InformationSheet
						align='flex-start'
						alignItems='flex-start'
						direction='column'
						items={[
							[
								{
									label: '이름',
									value: recipient?.recipientNm || '',
									labelWidth: '12rem',
									valueStyle: {
										color: Colors.gray10,
									},
								},
							],
							[
								{
									label: '성별',
									value: recipient?.genderNm || '',
									labelWidth: '12rem',
									valueStyle: {
										color: Colors.gray10,
									},
								},
							],
							[
								{
									label: '생년월일',
									value: displayBirthDay(recipient?.birthDt ?? '', true),
									labelWidth: '12rem',
									valueStyle: {
										color: Colors.gray10,
									},
								},
							],
							[
								{
									label: '장기요양인정번호',
									value: `${recipient?.longTermNo || ''}-${
										recipient?.longTermMajorChangeNo || ''
									}(${recipient?.longTermMinorChangeNo || ''})`,
									labelWidth: '12rem',
									valueStyle: {
										color: Colors.gray10,
									},
								},
							],
							[
								{
									label: '장기요양인정등급',
									value: recipient?.longTermGradeNm || '',
									labelWidth: '12rem',
									valueStyle: {
										color: Colors.gray10,
									},
								},
							],
						]}
						type='gray'
					/>
				)}
			</div>
		</S.Container>
	);
}

export default React.memo(EDocWorkExecuteLogBasicInfoForm);
