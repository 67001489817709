import React, { useMemo } from 'react';

import dayjs from 'dayjs';

import { CheckOption } from 'components/base/Selections/type';
import { commonCodeAdapter } from 'lib/adapter/common';
import { roundingHistoryAdapter } from 'lib/adapter/roundingHistory';
import { useCenterManagers, useCommonCodes, useMyAccountInfo } from 'lib/hook/react-query';
import { useVisitCareRoundingHistory } from 'lib/hook/react-query/query/roundingHistory';
import { ROUNDING_FILTER_CONFIG } from 'pages/dashboard/RoundingHistoryTOPage/config';
import { Filter } from 'types/view/filter';

import RoundingHistoryTabPage from './RoundingHistoryTabPage';

interface Props {
	targetDate: Date;
	handleTargetDate: (date: Date) => void;
	lastSyncDate: string;
}

function RoundingHistoryTOVisitCareTab({
	targetDate,
	handleTargetDate,
	lastSyncDate,
}: Props): React.ReactElement {
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: commonCodes = { CMN206: [] } } = useCommonCodes(
		{ comCdGroupNms: ['CMN206'] },
		commonCodeAdapter,
	);
	const {
		data: roundingHistoryData,
		isLoading,
		refetch,
	} = useVisitCareRoundingHistory(
		{
			serviceYm: dayjs(targetDate).format('YYYYMM'),
			centerId: myAccountInfo?.centerId ?? 0,
		},
		roundingHistoryAdapter,
	);
	const { data: centerManagers } = useCenterManagers({
		centerId: myAccountInfo?.centerId,
	});

	const filters = useMemo(
		() =>
			[
				ROUNDING_FILTER_CONFIG[0],
				{
					key: 'visitCategoryFilter',
					type: 'multi',
					placeholder: '유형',
					options: (commonCodes.CMN206 ?? []).filter((option) => option.value !== 'CMN206.50'),
				},
				ROUNDING_FILTER_CONFIG[1],
				{
					key: 'managerFilter',
					type: 'multi',
					placeholder: '사회복지사',
					options: (centerManagers ?? []).map((item) => ({
						label: item.userNm,
						value: item.memberAccountId,
					})),
				},
			] as Filter<CheckOption>[],
		[centerManagers],
	);

	return (
		<RoundingHistoryTabPage
			targetDate={targetDate}
			handleTargetDate={handleTargetDate}
			roundingHistoryData={roundingHistoryData}
			refetch={refetch}
			lastSyncDate={lastSyncDate}
			filters={filters}
			isLoading={isLoading}
			myAccountInfo={myAccountInfo}
		/>
	);
}

export default RoundingHistoryTOVisitCareTab;
