import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import dayjs from 'dayjs';

import { Toast } from 'components/base/CRToast';
import EDocClientBottomFloating from 'components/ui/EDoc/EDocClientBottomFloating';
import EDocClientButton from 'components/ui/EDoc/EDocClientButton';
import EDocClientHeader, { EDocClientHeaderProps } from 'components/ui/EDoc/EDocClientHeader';
import { displayDayOfWeekInKorean, removeFalsyValues } from 'lib';
import useEDocClientPage from 'lib/hook/eDoc/useESignInfoPage';
import { VisitNursingScheduleRecordingInputs } from 'types/api/eDoc';

import {
	EDocRecordSheetsFormProps,
	VisitNursingRecordFormFields,
	VisitNursingRecordSheetParam,
} from '../types';
import { VisitNursingRecordForm } from './VisitNursingRecordForm';

const initValue = {
	bloodPressureStart: '',
	bloodPressureEnd: '',
	pulse: '',
	temperature: '',
	healthcareMinute: '',
	healthcareChk1: '0',
	healthcareChk2: '0',
	healthcareChk3: '0',
	healthcareChk4: '0',
	nursingcareMinute: '',
	nursingcareChk1: '0',
	nursingcareChk2: '0',
	nursingcareChk3: '0',
	nursingcareChk4: '0',
	nursingcareChk5: '0',
	nursingcareChk6: '0',
	nursingcareChk7: '0',
	nursingcareChk8: '0',
	specialContent: '',
};

const makeDefaultFormData = (defaultValue: VisitNursingRecordFormFields) => {
	const emptyStringFiltered = removeFalsyValues(defaultValue);
	const newObj = {
		...initValue,
		...emptyStringFiltered,
	};
	return newObj;
};

function EDocVisitNursingRecordForm({
	onSave,
	onSubmit,
	params,
	isSubmittable,
	disabled,
}: EDocRecordSheetsFormProps<VisitNursingRecordSheetParam, VisitNursingScheduleRecordingInputs>) {
	const [sheetNumber, setSheetNumber] = useState(0);
	const currentSheetValue = useMemo(
		() => makeDefaultFormData(params[sheetNumber]),
		[sheetNumber],
	) as VisitNursingRecordFormFields;
	const methods = useForm<VisitNursingRecordFormFields>({ defaultValues: currentSheetValue });
	const isLastPage = sheetNumber >= params.length - 1;
	const param = params[sheetNumber];
	const [isProcessing, setIsProcessing] = useState(false);

	const { isErp } = useEDocClientPage();

	const healthcareMinute = methods.watch('healthcareMinute');
	const nursingcareMinute = methods.watch('nursingcareMinute');

	const clientHeaderProps: EDocClientHeaderProps = useMemo(() => {
		const date = dayjs(`${param.year}-${param.month}-${param.day}`);
		const getDate = `${displayDayOfWeekInKorean(date.day())}요일`;
		const startTime = dayjs(`${param.year}-${param.month}-${param.day} ${param.startTime}:00`);
		let endTime = dayjs(`${param.year}-${param.month}-${param.day} ${param.endTime}:00`);
		// 익일로 넘어가는 케이스 대응
		if (endTime.isBefore(startTime)) {
			endTime = endTime.add(1, 'day');
		}
		const totalLeftMinute =
			endTime.diff(startTime, 'minutes') - (Number(healthcareMinute) + Number(nursingcareMinute));

		return {
			recipientNm: param.recipientNm,
			currentDate: dayjs(date).format('MM월 DD일 ') + getDate,
			endTime: param.endTime,
			startTime: param.startTime,
			totalFormCount: params.length,
			totalMinute: totalLeftMinute,
			writtenFormCount: sheetNumber,
		};
	}, [params, sheetNumber, healthcareMinute, nursingcareMinute]);

	const convertValue = (formValues: VisitNursingRecordFormFields) => {
		const startTime = dayjs(`${param.year}-${param.month}-${param.day} ${param.startTime}:00`);
		const endTime = dayjs(`${param.year}-${param.month}-${param.day} ${param.endTime}:00`);
		const totalMinute =
			endTime.diff(startTime, 'minutes') - (Number(healthcareMinute) + Number(nursingcareMinute));

		if (totalMinute < 0) {
			Toast.error('제공시간을 확인해주세요');
			throw new Error('제공시간을 확인해주세요');
		}

		const isY = (value: string) => value === '1';
		const date = `${currentSheetValue.year}${currentSheetValue.month}${currentSheetValue.day}`;
		const saveValues: VisitNursingScheduleRecordingInputs = {
			serviceDt: date,
			inputIndex: String(sheetNumber),
			startTime: formValues.startTime.replace(':', ''),
			untagDesc: '',
			minBloodPressure: Number(formValues.bloodPressureStart),
			maxBloodPressure: Number(formValues.bloodPressureEnd),
			pulseCnt: Number(formValues.pulse),
			bodyTemperature: Number(formValues.temperature),
			healthMngtHourCnt: Number(formValues.healthcareMinute),
			jointBuildPreventYn: isY(formValues.healthcareChk1),
			dosageMngtYn: isY(formValues.healthcareChk2),
			basicHealthMngtYn: isY(formValues.healthcareChk3),
			recogTrainingYn: isY(formValues.healthcareChk4),
			nursingTreatHourCnt: Number(formValues.nursingcareMinute),
			bedsoreMngtYn: isY(formValues.nursingcareChk1),
			nutritionMngtYn: isY(formValues.nursingcareChk2),
			painMngtYn: isY(formValues.nursingcareChk3),
			excretionMngtYn: isY(formValues.nursingcareChk4),
			diabetesfootMngtYn: isY(formValues.nursingcareChk5),
			respiratoryNursingYn: isY(formValues.nursingcareChk6),
			dialysisNursingYn: isY(formValues.nursingcareChk7),
			oralNursingYn: isY(formValues.nursingcareChk8),
			specialDesc: formValues.specialContent,
		};
		return saveValues;
	};

	const onMoveNextPage = async (saveValue: VisitNursingRecordFormFields) => {
		if (isProcessing) return;
		setIsProcessing(true);
		try {
			await onSave(convertValue(saveValue));
			setSheetNumber((prev) => prev + 1);
			methods.reset(makeDefaultFormData(params[sheetNumber + 1]));
		} catch {
			return;
		} finally {
			setIsProcessing(false);
		}
	};

	const onMovePrevPage = async (formValues: VisitNursingRecordFormFields) => {
		if (isProcessing) return;
		setIsProcessing(true);
		try {
			await onSave(convertValue(formValues));
			setSheetNumber((prev) => prev - 1);
			methods.reset(makeDefaultFormData(params[sheetNumber - 1]));
		} catch {
			Toast.error('기록지 저장을 실패했습니다.');
			return;
		} finally {
			setIsProcessing(false);
		}
	};

	const onSubmitHandler = async (saveValue: VisitNursingRecordFormFields) => {
		if (isProcessing) return;
		setIsProcessing(true);
		try {
			await onSubmit(convertValue(saveValue));
		} catch {
			return;
		} finally {
			setIsProcessing(false);
		}
	};

	return (
		<FormProvider {...methods}>
			<EDocClientHeader {...clientHeaderProps} />
			<VisitNursingRecordForm disabled={disabled} key={sheetNumber} />
			<EDocClientBottomFloating>
				<EDocClientButton.Prev
					disabled={sheetNumber === 0}
					onClick={methods.handleSubmit(onMovePrevPage)}
				/>
				{isLastPage && isSubmittable ? (
					// eslint-disable-next-line react/jsx-no-useless-fragment
					<EDocClientButton.Submit
						title={isErp ? '검수하기' : '제출하기'}
						onClick={methods.handleSubmit(onSubmitHandler)}
					/>
				) : (
					<EDocClientButton.Next onClick={methods.handleSubmit(onMoveNextPage)} />
				)}
			</EDocClientBottomFloating>
		</FormProvider>
	);
}

export default EDocVisitNursingRecordForm;
