import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs, { Dayjs } from 'dayjs';

import Assets from 'assets';
import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRFilterGroup from 'components/base/CRInput/CRFilterGroup';
import CRInputLabel from 'components/base/CRInputLabel';
import CRMonthCalendar from 'components/base/CRMonthCalendar';
import { CRText } from 'components/base/CRText';
import { CheckOption } from 'components/base/Selections/type';
import VisitScheduleTableDownloadDialog from 'components/domain/dialog/VisitScheduleTableDownloadDialog';
import InformationLayout from 'components/domain/layout/InformationLayout';
import RecipientScheduleListTable from 'components/domain/table/RecipientScheduleListTable';
import InformationTable from 'components/ui/InformationTable';
import { centerMangersOptionsAdapter } from 'lib/adapter/common';
import { ftimeEmployeeWorkScheduleAdapter } from 'lib/adapter/schedule';
import {
	useCenterManagers,
	useFtimeEmployeeWorkSchedule,
	useMyAccountInfo,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { FtimeEmployeeWorkExecutePlanDTO } from 'types/dto/schedule';

import { FILTER_GROUP, recipientScheduleInfo } from './constants';
import * as S from './styles';

function GasanSocialWorkerMonthSchedulePage(): React.ReactElement {
	const navigate = useNavigate();

	const [date, setDate] = useState<Date>(new Date());

	const [currentWorker, setCurrentWorker] = useState<CheckOption>({} as CheckOption);
	const [searchText, setSearchText] = useState<string>('');
	const [filter, setFilter] = useState<{
		recipientStateFilter: { label: string; value: string }[];
		holdStateFilter: { label: string; value: string }[];
		hasScheduleFilter: { label: string; value: string }[];
	}>({
		recipientStateFilter: [],
		holdStateFilter: [],
		hasScheduleFilter: [],
	});

	const { showDialog, hideDialog } = useDialog();

	const { data: accountInfo } = useMyAccountInfo();
	const { data: centerManagers } = useCenterManagers(
		{
			centerId: accountInfo?.centerId as number,
		},
		centerMangersOptionsAdapter,
	);

	const { data: ftimeEmployeeWorkScheduleData } = useFtimeEmployeeWorkSchedule(
		{
			managerId: currentWorker.value as number,
			centerId: accountInfo?.centerId as number,
			yearMonth: dayjs(date).format('YYYYMM'),
			recipientStateCds: ['CMN058.10', 'CMN058.20', 'CMN058.30'],
		},
		ftimeEmployeeWorkScheduleAdapter,
	);

	const handleClickWorkerItem = (value: CheckOption) => {
		setCurrentWorker(value);
		navigate(
			`${RouterPath.scheduleOperate().monthSchedule.key}/gasan/${value?.data?.memberAccountId}`,
		);
	};

	const recipientList = useMemo(() => {
		if (!ftimeEmployeeWorkScheduleData) return [];
		const { holdStateFilter, hasScheduleFilter, recipientStateFilter } = filter;

		const holdStateFiltedList = !holdStateFilter.length
			? ftimeEmployeeWorkScheduleData.ftimeRecipients
			: ftimeEmployeeWorkScheduleData.ftimeRecipients.filter((item) => item.pending === '보류');

		const hasScheduleFiltedList = !hasScheduleFilter.length
			? holdStateFiltedList
			: holdStateFiltedList.filter((item) => item.scheduleCnt === 0);

		const stateFiledList =
			recipientStateFilter.length > 0
				? hasScheduleFiltedList.filter((item) =>
						filter.recipientStateFilter.some((state) => state.value === item.recipientStateCd),
					)
				: hasScheduleFiltedList;

		const searchFilterList = stateFiledList.filter(
			(recipient) =>
				recipient.korMemberNm.includes(searchText) || recipient.birthDt?.includes(searchText),
		);

		return searchFilterList.filter((item) => item.managerId === currentWorker.value);
	}, [filter, ftimeEmployeeWorkScheduleData, searchText]);

	const showVisitScheduleDetailDialog = (item: FtimeEmployeeWorkExecutePlanDTO) => {
		const workDate = dayjs(item.visitDt).format('YYYYMMDD');

		const timeFormatter = (time?: string) =>
			!time || time.includes(':') ? (time ?? '-') : `${time.slice(0, 2)}:${time.slice(2)}`;
		const timeHour = (start: string, end: string) =>
			dayjs(`${workDate} ${end}`).diff(`${workDate} ${start}`, 'minutes');

		const handleServiceStatusColor = (workLogStateCd: string) => {
			switch (workLogStateCd) {
				case 'CMN184.10':
					return 'yellow';
				case 'CMN184.11':
					return 'red';
				case 'CMN184.20':
					return 'green';
				default:
					return 'green';
			}
		};

		const info = {
			state: item.workExecuteLogStateNm ? (
				<CRText typography='label'>
					<CRText
						color={handleServiceStatusColor(item.workExecuteLogStateCd)}
						typography='label'
						text='● '
					/>
					{item.workExecuteLogStateNm}
				</CRText>
			) : (
				'-'
			),
			recipientName: item.recipientNm ?? '-',
			employeeNm: item.employeeNm1 ?? '-',
			visitDt: item.visitDt ? dayjs(item.visitDt).format('YYYY.MM.DD') : '-',
			visitTime: `${timeFormatter(item.startTime)} ~ ${timeFormatter(item.endTime)} (${timeHour(
				item.startTime,
				item.endTime,
			)}분)`,
			serviceTypeNm: item.serviceTypeNm ?? '-',
			serviceTime:
				item.serviceStartTime && item.serviceEndTime
					? `${timeFormatter(item.serviceStartTime)} ~ ${timeFormatter(item.serviceEndTime)}`
					: '-',
			visitorNm: item.visitorNm ?? '-',
		};

		showDialog(() => (
			<CRDialog
				type='S'
				title='복지사 가정방문 일정 상세'
				onClickClose={hideDialog}
				body={
					<S.HoldingDialogContainer>
						<CRInputLabel label='기본 정보'>
							<InformationTable items={recipientScheduleInfo(info)} />
						</CRInputLabel>
					</S.HoldingDialogContainer>
				}
			/>
		));
	};

	const showVisitScheduleTableDownloadDialog = () => {
		showDialog(() => (
			<VisitScheduleTableDownloadDialog
				params={{
					managerId: currentWorker.value,
					centerId: accountInfo?.centerId ?? 0,
				}}
				targetDate={dayjs(date)}
			/>
		));
	};

	const BannerFilterComponent = useMemo(
		() => (
			<S.BannerContainer>
				<CRInputLabel label='연월' isRequired>
					<S.InputContainer>
						<CRInput.YearMonthPicker currentValue={date} onChangeValue={setDate} />
					</S.InputContainer>
				</CRInputLabel>
				<CRInputLabel label='사회복지사' isRequired>
					<S.InputWrapper>
						<S.InputContainer>
							<CRInput.Selector
								currentValue={currentWorker}
								onChangeValue={handleClickWorkerItem}
								items={centerManagers}
								activeColor
								autoComplete
							/>
						</S.InputContainer>
					</S.InputWrapper>
				</CRInputLabel>
			</S.BannerContainer>
		),
		[date, currentWorker],
	);

	const recipientListNumberStatus = [
		{ key: 'total', label: `전체 ${recipientList.length.toLocaleString()}명` },
		{
			key: 'recipient',
			label: `수급중 ${recipientList
				.filter((item) => item.recipientStateCd === 'CMN058.10')
				.length.toLocaleString()}명`,
		},
		{
			key: 'hold',
			label: `보류 ${recipientList
				.filter((item) => item.pending === '보류')
				.length.toLocaleString()}명`,
		},
	];

	const LeftContentComponent = useMemo(
		() => (
			<S.LeftContentContainer>
				<CRText typography='h4'>수급자 목록</CRText>
				<CRInput.Search
					placeholder='수급자명, 생년월일 검색'
					value={searchText}
					onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value)}
				/>
				<CRFilterGroup
					filters={[FILTER_GROUP.recipientState, FILTER_GROUP.holdState, FILTER_GROUP.hasSchedule]}
					currentFilter={filter}
					setCurrentFilter={setFilter}
				/>

				<S.TableContainer>
					<RecipientScheduleListTable items={recipientList} date={dayjs(date)} />
					<S.RecipientTextContainer>
						{recipientListNumberStatus.map((item) => (
							<CRText key={item.key} text={item.label} color='gray50' typography='label' />
						))}
					</S.RecipientTextContainer>
				</S.TableContainer>
			</S.LeftContentContainer>
		),
		[searchText, filter, recipientList],
	);

	const renderCustomDayContent = (renderDate: Dayjs) => {
		const visitSchedule = ftimeEmployeeWorkScheduleData?.workExecuteLogs.filter((item) =>
			dayjs(item.visitDt).isSame(renderDate, 'day'),
		);
		if (!visitSchedule) return null;

		const renderDateFormat = renderDate.format('YYMMDD');

		const handleServiceTime = (startTime: string, endTime: string) => {
			if (!startTime || !endTime) return '';

			return `${dayjs(`${renderDateFormat} ${startTime}`).format('HH:mm')} ~ ${dayjs(
				`${renderDateFormat} ${endTime}`,
			).format('HH:mm')}`;
		};

		const handleServiceHour = (startTime: string, endTime: string) => {
			if (!startTime || !endTime) return '';

			return dayjs(`${renderDateFormat} ${endTime}`).diff(
				dayjs(`${renderDateFormat} ${startTime}`),
				'minutes',
			);
		};

		const handleServiceStatusColor = (workLogStateCd: string) => {
			switch (workLogStateCd) {
				case 'CMN184.10':
					return 'yellow';
				case 'CMN184.11':
					return 'red';
				case 'CMN184.20':
					return 'green';
				default:
					return 'green';
			}
		};

		return visitSchedule.map((item) => (
			<S.VisitContainer onClick={() => showVisitScheduleDetailDialog(item)}>
				<CRText typography='label'>
					<CRText
						color={handleServiceStatusColor(item.workExecuteLogStateCd)}
						typography='label'
						text='● '
					/>
					{handleServiceTime(item.startTime, item.endTime)}
				</CRText>
				<CRText color='gray60' typography='label'>
					{`${item.recipientNm} (${handleServiceHour(item.startTime, item.endTime)}분)`}
				</CRText>
			</S.VisitContainer>
		));
	};

	const RightContentComponent = useMemo(
		() => (
			<S.RightContentContainer>
				<S.CalendarHeader>
					<CRText text={`${currentWorker?.label}님 방문일정`} typography='h4' />
					<CRButton.IconButton
						iconLeft={Assets.icon.download}
						type='outlined'
						palette='gray'
						style={{ paddingLeft: '2rem' }}
						onClick={showVisitScheduleTableDownloadDialog}>
						방문일정표
					</CRButton.IconButton>
				</S.CalendarHeader>
				<S.Calendar>
					<CRMonthCalendar
						currentDate={dayjs(date)}
						onChange={() => {}}
						renderCustomCalendarHeader={() => null}
						holidayList={ftimeEmployeeWorkScheduleData?.holidays ?? []}
						renderCustomDayContent={renderCustomDayContent}
					/>
				</S.Calendar>
			</S.RightContentContainer>
		),
		[recipientList, currentWorker, date],
	);

	useEffect(() => {
		const currentCenterManager =
			centerManagers?.find((item) => item.value === accountInfo?.memberAccountId) ??
			({} as CheckOption);
		setCurrentWorker(currentCenterManager);

		if (currentCenterManager?.data?.memberAccountId)
			navigate(
				`${RouterPath.scheduleOperate().monthSchedule.key}/gasan/${
					currentWorker?.data?.memberAccountId ?? 0
				}`,
				{
					replace: true,
				},
			);
	}, [centerManagers]);

	return (
		<InformationLayout
			BannerComponent={BannerFilterComponent}
			LeftContentComponent={LeftContentComponent}
			RightContentComponent={RightContentComponent}
			contentContainerCustomStyle={{ height: 'calc(100% - 10rem)' }}
		/>
	);
}

export default GasanSocialWorkerMonthSchedulePage;
