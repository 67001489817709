import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import CRButton from 'components/base/CRButton';
import CRRequiredMark from 'components/base/CRRequiredMark';
import CRStatus from 'components/base/CRStatus';
import { Toast } from 'components/base/CRToast';
import CheckHourlyWageDialog from 'components/domain/dialog/CheckHourlyWageDialog';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import { ESignWayCode, ESignWayDialog } from 'components/domain/dialog/ESignDialog';
import EDocNoDialog from 'components/domain/dialog/EdocNoDialog';
import UploadContractDocument from 'components/domain/dialog/UploadContractDocument';
import InformationTable from 'components/ui/InformationTable';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import { displayBirthDayId } from 'lib';
import {
	useContractDocument,
	useContractSendStatus,
	useCreateEmployeeDocument,
	useCreateRecipientDocument,
	useGetEmployeeDocument,
	useGetRecipientDocument,
	useSendEmployeeContract,
	useSendRecipientContract,
} from 'lib/hook/react-query';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import useContractTask from 'lib/hook/view/contract/useContractTask';
import { SendEmployeeContractRequest, SendRecipientContractRequest } from 'types/api/contract';
import { ContractDetailServiceDTO } from 'types/dto';
import { ESignRequestForm, LastESignStateCd } from 'types/view/eDoc';

import { CONTRACT_LIST_TABLE_HEADER } from './constant';
import * as S from './styles';

function ContractDocumentForm(): React.ReactElement {
	const params = useParams<{ contractId: string }>();
	const { showDialog, hideDialog } = useGlobalLayout();
	const { form, disabled, validateCreateDocument } = useContractTask();
	const { data: contractSendStatus, refetch: refetchContractSendStatus } = useContractSendStatus({
		serviceContractId: Number(params.contractId),
	});
	const { data: contractDocument, refetch: refetchContractDocument } = useContractDocument({
		serviceContractIds: [Number(params.contractId)],
	});
	const sendRecipientContract = useSendRecipientContract();
	const sendEmployeeContract = useSendEmployeeContract();
	const createRecipientDocument = useCreateRecipientDocument();
	const createEmployeeDocument = useCreateEmployeeDocument();
	const getRecipientDocument = useGetRecipientDocument();
	const getEmployeeDocument = useGetEmployeeDocument();

	const sendList = useMemo(() => {
		// 수급자 표준약관 추출
		const contractEdocSendResponse = (contractSendStatus ?? []).find(
			(item) => item.paperTypeCd === 'CMN119.0058',
		)?.contractEdocSendResponse;

		const recipientSend = {
			title: `수급자 ${form.getValues('recipient.base.recipientNm')}(${displayBirthDayId(
				form.getValues('recipient.base.birthDt'),
			)})`,

			items: [
				{
					type: 'recipient',
					title: '수급자 계약서',
					isRequired: true,
					fileName: '수급자_계약서.eform',
					status: contractEdocSendResponse?.lastEsignStateCd ?? 'CMN031.10',
					data: {
						serviceContractId: Number(params.contractId),
						receiverName: form.getValues('recipient.base.recipientNm'),
					},

					document: contractDocument?.find((item) => item.paperTypeCd === 'CMN119.0058'),
				},
			] as {
				type: 'employee' | 'recipient';
				title: string;
				isRequired: boolean;
				fileName: string;
				status: string;
				data: any;
				document: any;
			}[],
		};

		const employeeSend = (form.getValues('employees') ?? []).map((item) => ({
			title: `직원 ${item.base.employeeNm}(${displayBirthDayId(item.base.employeeBirthDt)})`,
			items: form.getValues('services').reduce(
				(accumulate: any[], service: ContractDetailServiceDTO) => {
					const exist = service.associates?.contractEmployees?.find(
						(employee) => employee?.employeeId === item.base.employeeId,
					);
					if (exist) {
						const contractEdocSendResponse = (contractSendStatus ?? []).find(
							(status) =>
								status.paperTypeCd === 'CMN119.0051' &&
								status?.employeeId === item.base.employeeId &&
								status.serviceTypeCd === service.serviceTypeCd,
						)?.contractEdocSendResponse;

						return [
							...accumulate,
							{
								type: 'employee',
								title: '직원 계약서',
								isRequired: true,
								fileName: '직원_계약서.eform',
								status: contractEdocSendResponse?.lastEsignStateCd ?? 'CMN031.10',
								data: {
									serviceContractId: Number(params.contractId),
									employeeId: item.base.employeeId,
									serviceTypeCd: service.serviceTypeCd,
									monthPerWorkHourCnt: Number(service?.use?.monthPerWorkHourCnt ?? '0'),
									receiverName: item.base.employeeNm,
								},
								document: contractDocument?.find(
									(target) =>
										target.paperTypeCd === 'CMN119.0051' &&
										target?.employeeId === item.base.employeeId &&
										target.serviceTypeCd === service.serviceTypeCd,
								),
							},
						];
					}

					return accumulate;
				},
				[] as {
					type: 'employee' | 'recipient';
					title: string;
					isRequired: boolean;
					fileName: string;
					status: string;
					data: any;
					document: any;
				}[],
			),
		}));

		return [recipientSend, ...employeeSend];
	}, [
		form.getValues('employees'),
		form.getValues('recipient'),
		form.getValues('services'),
		contractSendStatus,
		contractDocument,
	]);

	const handleClickRecipientSend = async (data: any) => {
		const onSubmitRecipient = async (eSignWayData: ESignRequestForm, item: any) => {
			try {
				if (sendRecipientContract.isLoading) return false;
				const param: SendRecipientContractRequest =
					eSignWayData.eSignWayCd?.[0]?.value === ESignWayCode.대면서명
						? {
								recipientContractId: item.document.contractDocumentId,
								esignWayCd: eSignWayData.eSignWayCd?.[0]?.value,
								facingManagerId: eSignWayData.receiver?.data?.memberAccountId,
								facingManagerPhoneUsageDivCd:
									eSignWayData?.facingManagerPhoneUsageDivCd?.[0]?.value,
							}
						: {
								recipientContractId: item.document.contractDocumentId,
								esignWayCd: eSignWayData.eSignWayCd?.[0]?.value,
							};
				await sendRecipientContract.mutateAsync(param);
				refetchContractSendStatus();
				hideDialog();
				Toast.success('계약서를 성공적으로 발송했습니다.');
			} catch {
				Toast.error('계약서 발송에 실패하였습니다. 잠시 후 다시 시도해주세요.');
			}
			return undefined;
		};

		showDialog(() => <ESignWayDialog onSubmit={onSubmitRecipient} item={data} />);
	};

	const handleClickEmployeeSend = async (data: any) => {
		const onSubmitEmployee = async (eSignWayData: ESignRequestForm, item: any) => {
			try {
				if (sendEmployeeContract.isLoading) return false;

				const param: SendEmployeeContractRequest =
					eSignWayData.eSignWayCd?.[0]?.value === ESignWayCode.대면서명
						? {
								employeeContractId: item.document.contractDocumentId,
								esignWayCd: eSignWayData.eSignWayCd?.[0]?.value,
								facingManagerId: eSignWayData.receiver?.data?.memberAccountId,
								facingManagerPhoneUsageDivCd:
									eSignWayData?.facingManagerPhoneUsageDivCd?.[0]?.value,
							}
						: {
								employeeContractId: item.document.contractDocumentId,
								esignWayCd: eSignWayData.eSignWayCd?.[0]?.value,
							};

				await sendEmployeeContract.mutateAsync(param);
				refetchContractSendStatus();
				hideDialog();
				Toast.success('계약서를 성공적으로 발송했습니다.');
			} catch (error) {
				Toast.error('계약서 발송에 실패하였습니다. 잠시 후 다시 시도해주세요.');
			}
			return undefined;
		};
		showDialog(() => <ESignWayDialog onSubmit={onSubmitEmployee} item={data} />);
	};

	const handleClickPreview = async (item: any) => {
		if (item.type === 'recipient') {
			const data = await getRecipientDocument.mutateAsync({
				recipientContractId: item.document.contractDocumentId,
			});

			if (!data) return;
			showDialog(() => <EDocNoDialog viewerType='report' eDocParamValue={JSON.parse(data)} />);
			return;
		}

		const data = await getEmployeeDocument.mutateAsync({
			employeeContractId: item.document.contractDocumentId,
		});

		if (!data) return;
		showDialog(() => <EDocNoDialog viewerType='report' eDocParamValue={JSON.parse(data)} />);
	};

	const handleClickCreateDocument = async (item: any) => {
		if (!validateCreateDocument?.(item.type, item?.data?.employeeId)) return;
		try {
			if (item.type === 'recipient') {
				if (createRecipientDocument.isLoading) return;
				await createRecipientDocument.mutateAsync({
					serviceContractId: item.data.serviceContractId,
				});
				Toast.success('계약서를 성공적으로 발급했습니다.');
				refetchContractDocument();
				return;
			}
			if (item.type === 'employee') {
				if (createEmployeeDocument.isLoading) return;
				showDialog(({ hideDialog }) => (
					<CheckHourlyWageDialog
						employeeName={item.data.receiverName}
						config={{
							serviceContractId: item.data.serviceContractId,
							employeeId: item.data.employeeId,
							serviceTypeCd: item.data.serviceTypeCd,
							monthPerWorkHourCnt: item.data.monthPerWorkHourCnt,
						}}
						onClose={hideDialog}
						onConfirm={async (serviceEmployeeWage: number | null) => {
							await createEmployeeDocument.mutateAsync({
								serviceContractId: item.data.serviceContractId,
								employeeId: item.data.employeeId,
								serviceTypeCd: item.data.serviceTypeCd,
								monthPerWorkHourCnt: item.data.monthPerWorkHourCnt,
								serviceEmployeeWage,
							});
							Toast.success('계약서를 성공적으로 발급했습니다.');
							refetchContractDocument();
							hideDialog();
						}}
					/>
				));

				return;
			}
		} catch (error: any) {
			Toast.error(error.message ?? '계약서 발급에 실패하였습니다. 잠시 후 다시 시도해주세요.');
		}
	};

	const handleClickReCreateEmployeeDocument = (item: any) => {
		if (!validateCreateDocument?.(item.type, item?.data?.employeeId)) return;
		showDialog(({ hideDialog }) => (
			<CheckHourlyWageDialog
				employeeName={item.data.receiverName}
				config={{
					serviceContractId: item.data.serviceContractId,
					employeeId: item.data.employeeId,
					serviceTypeCd: item.data.serviceTypeCd,
					monthPerWorkHourCnt: item.data.monthPerWorkHourCnt,
				}}
				onClose={hideDialog}
				onConfirm={async (serviceEmployeeWage: number | null) => {
					await createEmployeeDocument.mutateAsync({
						serviceContractId: item.data.serviceContractId,
						employeeId: item.data.employeeId,
						serviceTypeCd: item.data.serviceTypeCd,
						monthPerWorkHourCnt: item.data.monthPerWorkHourCnt,
						serviceEmployeeWage,
					});
					refetchContractSendStatus();
					refetchContractDocument();
					hideDialog();
					Toast.success('계약서를 성공적으로 재발급했습니다.');
				}}
			/>
		));
	};

	const handleClickReCreateDocument = async (item: any) => {
		if (!validateCreateDocument?.(item.type, item?.data?.employeeId)) return;
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='계약서 재발급'
				content='기존 문서를 취소하고 재발급 받겠습니까?'
				hideDialog={hideDialog}
				successOption={{
					text: '재발급',
					successCallback: async () => {
						try {
							if (item.type === 'recipient') {
								if (sendEmployeeContract.isLoading) return;
								await createRecipientDocument.mutateAsync({
									serviceContractId: item.data.serviceContractId,
								});
								refetchContractSendStatus();
								refetchContractDocument();
								hideDialog();
								Toast.success('계약서를 성공적으로 재발급했습니다.');
								return;
							}
							if (item.type === 'employee') {
								if (sendEmployeeContract.isLoading) return;
								hideDialog();
								handleClickReCreateEmployeeDocument(item);
							}
						} catch {
							Toast.error('계약서 재발급에 실패하였습니다. 잠시 후 다시 시도해주세요.');
						}
					},
				}}
			/>
		));
	};

	const handleClickSend = (item: any) => {
		if (item.type === 'recipient') {
			handleClickRecipientSend(item);
			return;
		}
		if (item.type === 'employee') {
			handleClickEmployeeSend(item);
			return;
		}
	};

	const handleClickUpload = (item: any) => {
		if (item.type === 'recipient') {
			showDialog(() => (
				<UploadContractDocument
					contractDocumentId={item.document.contractDocumentId}
					type='recipient'
				/>
			));
			return;
		}
		if (item.type === 'employee') {
			showDialog(() => (
				<UploadContractDocument
					contractDocumentId={item.document.contractDocumentId}
					type='employee'
				/>
			));
			return;
		}
	};

	return (
		<S.Container>
			{sendList.map((item) => (
				<S.SectionContainer>
					<S.SectionTitle>{item.title}</S.SectionTitle>
					<InformationTable
						items={[
							CONTRACT_LIST_TABLE_HEADER,
							...item.items.map(
								(send) =>
									[
										{
											type: 'value',
											value: (
												<div>
													{send.title} {send.isRequired && <CRRequiredMark />}
												</div>
											),
										},
										{
											type: 'value',
											value: send.fileName,
										},
										{
											type: 'value',
											value: (
												<CRStatus
													options={[
														{
															key: 'CMN031.10',
															label: '발송전',
															color: 'gray',
														},
														{
															key: LastESignStateCd.서명대기,
															label: '서명 대기',
															color: 'yellow',
														},
														{
															key: LastESignStateCd.검수대기,
															label: '검수 대기',
															color: 'blue',
														},
														{
															key: LastESignStateCd.완료,
															label: '완료',
															color: 'green',
														},
														{
															key: LastESignStateCd.서명취소,
															label: '서명 취소',
															color: 'red',
														},
														{
															key: LastESignStateCd.기간만료,
															label: '기간 만료',
															color: 'red',
														},
													]}>
													{send.status}
												</CRStatus>
											),
										},
										{
											type: 'value',
											value: (
												<S.ButtonContainer>
													{!send.document ? (
														<CRButton.Default
															size='xSmall'
															type='outlined'
															palette='gray'
															disabled={disabled}
															onClick={() => handleClickCreateDocument(send)}>
															발급
														</CRButton.Default>
													) : (
														<>
															<CRButton.Default
																size='xSmall'
																type='outlined'
																palette='gray'
																onClick={() => handleClickReCreateDocument(send)}
																disabled={disabled}>
																재발급
															</CRButton.Default>
															<CRButton.Default
																size='xSmall'
																type='outlined'
																palette='gray'
																onClick={() => handleClickPreview(send)}>
																미리보기
															</CRButton.Default>
															<CRButton.Default
																size='xSmall'
																type='outlined'
																palette='gray'
																onClick={() => handleClickSend(send)}>
																{send.status === 'CMN031.10' ? '발송' : '재발송'}
															</CRButton.Default>
															<CRButton.Default
																size='xSmall'
																type='outlined'
																palette='gray'
																disabled={disabled}
																onClick={() => handleClickUpload(send)}>
																업로드
															</CRButton.Default>
														</>
													)}
												</S.ButtonContainer>
											),
										},
									] as InformationTableItemType[],
							),
						]}
					/>
				</S.SectionContainer>
			))}
		</S.Container>
	);
}

export default ContractDocumentForm;
