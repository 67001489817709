import React from 'react';

import Assets from 'assets';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRIcon from 'components/base/CRIcon';
import { CRText } from 'components/base/CRText';

import * as S from './styles';

interface Props {
	name: string;
	yearMonth: string;
}

function ScheduleClientMobileHeader({ name, yearMonth }: Props) {
	return (
		<S.Container>
			<FlexContainer justify='space-between'>
				<FlexContainer gap='1rem'>
					<S.ProfileBackground>
						<CRIcon src={Assets.icon.person} />
					</S.ProfileBackground>
					<CRText color='white' typography='h4' text={name} />
				</FlexContainer>
				<S.DateWrapper>
					<CRText color='white' text={`${yearMonth} 일정표`} />
				</S.DateWrapper>
			</FlexContainer>
		</S.Container>
	);
}

export default ScheduleClientMobileHeader;
