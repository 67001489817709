import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { Toast } from 'components/base/CRToast';
import EDocAdmissionUseRequestForm from 'components/domain/form/EDocAdmissionUseRequestForm';
import InformationTable from 'components/ui/InformationTable';
import { admissionUseRequest, displayPhoneNumber } from 'lib';
import { useCenterList } from 'lib/hook/react-query';
import { useIssuableEDocPaperTypes, useIssueEDocument } from 'lib/hook/react-query/query/edoc';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { IssueEDocumentRequest, ResponseCode } from 'types/api';
import { MyAccountInfoDTO } from 'types/api/auth';
import {
	AdmissionUseRequestFormTypes,
	EDocIssueFormTypes,
	EDocTemplateType,
	ESignRequestForm,
} from 'types/view/eDoc';
import { Recipient } from 'types/view/recipient';

import { ESignWayCode, ESignWayDialog } from '../ESignDialog';
import { eDocAdmissionUseRequestFormAdapter } from './eDocIssueAdapter';
import * as S from './styles';

interface Props {
	menuType?: 'employee' | 'recipient';
	currentRecipient?: Recipient;
	myAccountInfo?: MyAccountInfoDTO | null;
	title?: string;
}

export function RecipientDocumentIssueDialog({
	menuType = 'employee',
	title = '',
	currentRecipient,
	myAccountInfo,
}: Props) {
	const client = useQueryClient();
	const { showDialog, hideDialog } = useDialog();
	const defaultForm = useForm<EDocIssueFormTypes>();
	const admissionUseRequestForm = useForm<AdmissionUseRequestFormTypes>({
		mode: 'onChange',
		resolver: yupResolver(admissionUseRequest),
	});
	const { data: centerListData } = useCenterList();

	const myCenter = useMemo(
		() => (centerListData || []).find((item) => item.centerId === myAccountInfo?.centerId),
		[centerListData],
	);
	const { mutateAsync: issueEDocumentMutateAsync } = useIssueEDocument();

	const onSubmit = async (data: ESignRequestForm, item: IssueEDocumentRequest) => {
		const param: IssueEDocumentRequest = {
			...item,
			esignWayCd: data.eSignWayCd?.[0].value,
		};
		if (data.eSignWayCd?.[0].value === ESignWayCode.대면서명) {
			param.facingManagerId = data.receiver.data?.memberAccountId;
			param.facingManagerPhoneUsageDivCd = data.facingManagerPhoneUsageDivCd?.[0]?.value;
		}

		const response = await issueEDocumentMutateAsync(param);

		hideDialog();
		client.invalidateQueries([
			endpoint.getRecipientEDocList.key,
			{ centerId: myAccountInfo?.centerId, recipientId: currentRecipient?.recipientId },
		]);
		if (response?.code === ResponseCode.SUCCESS) {
			Toast.success('발급 요청을 완료하였습니다.');
		} else {
			Toast.error('발급 요청에 실패했습니다. 잠시 후 다시 시도해주세요.');
		}
	};

	// 입소이용 신청서
	const onSubmitAdmissionUseRequestForm = async (formData: AdmissionUseRequestFormTypes) => {
		if (formData.data && currentRecipient && myAccountInfo) {
			const eDocParamValue = eDocAdmissionUseRequestFormAdapter(
				formData,
				currentRecipient,
				myAccountInfo,
				myCenter,
			);

			hideDialog();

			showDialog(() => <ESignWayDialog onSubmit={onSubmit} item={eDocParamValue} />);
		}
	};

	const totalContext = useMemo(
		() => [
			{
				templateCode: EDocTemplateType.입소이용신청서,
				formContext: admissionUseRequestForm,
				onSubmit: onSubmitAdmissionUseRequestForm,
			},
		],
		[admissionUseRequestForm, onSubmitAdmissionUseRequestForm],
	);

	const currentForm = totalContext.find(
		(context) => context.templateCode === defaultForm.watch('eDocType')?.value,
	);

	const { data: eDocPaperCodes } = useIssuableEDocPaperTypes({
		paperMenu: menuType,
	});

	const submitForm = () => {
		const matchedForm: any = totalContext.find(
			(context) => context.templateCode === defaultForm.watch('eDocType')?.value,
		);
		if (matchedForm) {
			matchedForm.formContext.trigger().then((isValid: boolean) => {
				if (isValid) {
					matchedForm.formContext.handleSubmit(matchedForm.onSubmit as (data: any) => void)();
				}
			});
		}
	};

	const eDocPaperTypeCode = useMemo(
		() =>
			(eDocPaperCodes?.paperTypeCds || [])?.map((item) => ({
				label: item.korComCdAliasNm,
				value: item.comCdId,
			})),
		[eDocPaperCodes],
	);

	const renderSubForm = () => {
		const eDocType = defaultForm.watch('eDocType');
		if (!eDocType) {
			return <S.Empty>발급 서류를 선택해주세요.</S.Empty>;
		}

		const templateCode = eDocType.value.split('.').join('_');
		switch (eDocType.value) {
			case EDocTemplateType.입소이용신청서: {
				return (
					<EDocAdmissionUseRequestForm
						templateCode={templateCode}
						formContext={admissionUseRequestForm}
						currentRecipientId={currentRecipient?.recipientId}
					/>
				);
			}
			default: {
				return null;
			}
		}
	};

	return (
		<CRDialog
			onClickClose={hideDialog}
			title={title}
			body={
				<S.Container>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel isRequired label='발급 서류'>
								<CRInput.Selector
									placeholder='발급 서류 선택'
									currentValue={value}
									onChangeValue={onChange}
									items={eDocPaperTypeCode}
								/>
							</CRInputLabel>
						)}
						name='eDocType'
						control={defaultForm.control}
					/>
					<CRInputLabel label='발송 정보'>
						<InformationTable
							items={[
								[
									{
										label: '템플릿명',
										value: defaultForm.watch('eDocType')?.label || '-',
									},
								],
								[
									{
										label: '제목',
										value: defaultForm.watch('eDocType')
											? `${currentRecipient?.name}님${
													defaultForm.watch('eDocType')
														? ` ${defaultForm.watch('eDocType').label}`
														: ''
												}`
											: '-',
									},
								],
								[
									{
										label: '수신자',
										value: `${currentRecipient?.name}${
											currentRecipient?.phoneNumber
												? ` (${displayPhoneNumber(currentRecipient?.phoneNumber)})`
												: ''
										}`,
									},
								],
							]}
						/>
					</CRInputLabel>
					{renderSubForm()}
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						palette='primary'
						size='default'
						disabled={!currentForm?.formContext.formState.isValid}
						onClick={submitForm}>
						{defaultForm.watch('eDocType')?.value === EDocTemplateType.입소이용신청서
							? '다음'
							: '발급'}
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
