import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import * as XLSX from 'xlsx';

import CRButton from 'components/base/CRButton';
import CRInputLabel from 'components/base/CRInputLabel';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import { useUploadBurden, useUploadCharge } from 'lib/hook/react-query';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import { BurdenUpload } from 'types/view/excel';

import CRSelector from '../CRSelector';
import { centerMock } from './mock';
import * as S from './styles';

function CRExcelUploader() {
	const inputRef = useRef<HTMLInputElement>(null);
	const burdenRef = useRef<HTMLInputElement>(null);
	const chargeRef = useRef<HTMLInputElement>(null);
	const { mutateAsync: uploadBurden } = useUploadBurden();
	const { mutateAsync: uploadCharge } = useUploadCharge();
	const [centerList, setCenterList] = useState<{ label: string; value: number }[]>([]);
	const [selectedFilter, setSelectedFilter] = useState<{ label: string; value: number }>();
	const [header, setHeader] = useState<any>();
	const [list, setList] = useState<any[]>();
	const [listDetail, setListDetail] = useState<any[]>();

	const { showDialog } = useGlobalLayout();

	const handleChangeFilter = (item: { label: string; value: number }) => {
		setSelectedFilter(item);
	};

	/* eslint-disable */

	const replaceCommaWonNumber = (string: string) => {
		if (!string) return 0;
		return Number(string.toString().replace('원', '').replace(/,/g, ''));
	};

	const addTimes = (t1: string, t2: string, minus = false) => {
		function toMin(t: string) {
			return Number(t.split(':')[0]) * 60 + Number(t.split(':')[1]);
		}
		const min = minus ? toMin(t1) - toMin(t2) : toMin(t1) + toMin(t2);

		return `${Math.floor(min / 60)}:${min % 60}`;
	};

	const makeJoin = () => {
		if (!list || !listDetail) return;
		const result = [header];
		list.forEach((it) => {
			/*
            * '청구내역'과 '청구내역상세'에서 아래의 값들이 모두 같은 행을 서로 찾음
                청구내역 5번째 열 = 청구내역상세 2번째 열 (수급자 인정번호)
                청구내역 1번째 열 = 청구내역상세 3번재 열 (일자)
                청구내역 12번째 열 = 청구내역상세 4번째 열 (근무시작시간)
                청구내역 16번째 열 = 청구내역상세 8번째 열 (수가명) */
			const detail = listDetail.find(
				(d) => it[4] === d[1] && it[0] === d[2] && it[11] === d[3] && it[15] === d[7],
			);
			if (!detail) return;
			// 2번의 조건을 만족하는 '청구내역상세' 행의 9번째 열 (수가)을 '청구내역' 행의 17번째 열로 추가함
			it[16] = detail[8];

			// '청구내역' 엑셀파일의 13번째 열 (근무 종료시간) 값을 (12번째 열 + 3번째 열 - 2번째 열) 값으로 변경함
			it[12] = addTimes(addTimes(it[11], it[2]), it[1], true);

			// 청구내역 2번째, 3번째 열의 값을 각각 12, 13번째 열의 값으로 대체하고, 12, 13번째 열을 삭제함
			it[1] = it[11];
			it[2] = it[12];
			it.splice(11, 2);
			result.push(it);
		});

		const wsName = '일정';
		const wb = XLSX.utils.book_new();

		/* make worksheet */
		const ws = XLSX.utils.aoa_to_sheet(result);
		/* Add the worksheet to the workbook */
		XLSX.utils.book_append_sheet(wb, ws, wsName);
		XLSX.writeFile(wb, '일정.xlsx');
	};

	const handleChangeBurden = async (event: ChangeEvent<HTMLInputElement>) => {
		const target = event.target;
		if (target.files?.length !== 1) {
			throw new Error('Cannot use multiple files');
		}

		if (!selectedFilter) {
			showDialog(({ hideDialog }) => (
				<DefaultDialog
					content='센터를 먼저 선택해주세요'
					hideDialog={hideDialog}
					successOption={{
						text: '확인',
						successCallback: () => hideDialog(),
					}}
				/>
			));
			return;
		}

		const formData = new FormData();
		formData.append('file', target.files[0]);

		await uploadBurden({
			burdenUploads: formData,
			centerId: selectedFilter.value,
		});
	};

	const handleChangeCharge = async (event: ChangeEvent<HTMLInputElement>) => {
		const target = event.target;
		if (target.files?.length !== 1) {
			throw new Error('Cannot use multiple files');
		}

		if (!selectedFilter) {
			showDialog(({ hideDialog }) => (
				<DefaultDialog
					content='센터를 먼저 선택해주세요'
					hideDialog={hideDialog}
					successOption={{
						text: '확인',
						successCallback: () => hideDialog(),
					}}
				/>
			));
			return;
		}

		const formData = new FormData();
		formData.append('file', target.files[0]);

		await uploadCharge({
			chargeUploads: formData,
			centerId: selectedFilter.value,
		}).then((v) => {
			if (!v) return;
			alert(v.data);
			target.value = '';
		});
	};

	const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) return;

		if (!selectedFilter) {
			showDialog(({ hideDialog }) => (
				<DefaultDialog
					content='센터를 먼저 선택해주세요'
					hideDialog={hideDialog}
					successOption={{
						text: '확인',
						successCallback: () => hideDialog(),
					}}
				/>
			));
			return;
		}

		if (event.target.files.length !== 2) {
			showDialog(({ hideDialog }) => (
				<DefaultDialog
					content='청구내역상세 / 청구내역 파일을 선택해주세요.'
					hideDialog={hideDialog}
					successOption={{
						text: '확인',
						successCallback: () => hideDialog(),
					}}
				/>
			));
			return;
		}

		for (const it of Array.from(event.target.files)) {
			const reader: FileReader = new FileReader();

			reader.onload = (e: any) => {
				const bstr: string = e.target.result;
				const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
				const wsname: string = wb.SheetNames[0];
				const ws: XLSX.WorkSheet = wb.Sheets[wsname];
				const data: any[][] = XLSX.utils.sheet_to_json(ws, {
					header: 1,
					raw: false,
					dateNF: 'yyyy-MM-DD',
				});

				if (data[0][0] == '일자') {
					setList(data.slice(1));
					setHeader(data[0]);
				}
				if (data[0][0] == '수급자성명') {
					setListDetail(data.slice(1));
				}
			};
			reader.readAsBinaryString(it);
		}

		console.log('files', event.target.files);
	};

	const handleClickExcelUpload = () => {
		inputRef.current?.click();
	};

	const handleClickBurdenUpload = () => {
		inputRef.current?.click();
	};

	useEffect(() => {
		const centerOptions = centerMock.map((e) => ({
			label: e.name,
			value: e.id,
		}));
		setCenterList(centerOptions);
	}, [centerMock]);

	useEffect(() => {
		if (header && list && listDetail) {
			makeJoin();
		}
		makeJoin;
	}, [header, list, listDetail]);

	return (
		<S.Container>
			<S.inputContainer>
				<S.CenterSelectorContainer>
					<CRSelector
						currentValue={selectedFilter}
						placeholder='센터를 선택해주세요'
						items={centerList}
						onChangeValue={handleChangeFilter}
					/>
				</S.CenterSelectorContainer>
				<CRButton.Default
					size='default'
					type='filled'
					palette='gray'
					onClick={handleClickExcelUpload}>
					청구내역/상세파일 변환
				</CRButton.Default>
				<input ref={inputRef} hidden type='file' onChange={handleChangeFile} multiple max={2} />
			</S.inputContainer>
			{selectedFilter && (
				<S.inputContainer>
					<CRInputLabel label='본인부담금'>
						<input
							ref={burdenRef}
							type='file'
							onChange={handleChangeBurden}
							accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
						/>
					</CRInputLabel>
				</S.inputContainer>
			)}
			{selectedFilter && (
				<S.inputContainer>
					<CRInputLabel label='청구일정'>
						<input
							ref={chargeRef}
							type='file'
							onChange={handleChangeCharge}
							accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
						/>
					</CRInputLabel>
				</S.inputContainer>
			)}
		</S.Container>
	);
}

export default CRExcelUploader;
