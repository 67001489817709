import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import { CRText } from 'components/base/CRText';
import { DeleteSalaryScheduleDialog } from 'components/domain/dialog/DeleteSalaryScheduleDialog';
import { SalaryDetailDialogFormFields } from 'components/domain/dialog/SalaryDetailDialog/types';
import { displayComma } from 'lib';
import useDialog from 'lib/hook/util/useDialog';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import { SalaryServiceSchedule } from 'types/api/salary';

import * as S from './styles';

interface Props {
	disabled: boolean;
}

function SalaryDetailServiceSummary({ disabled }: Props) {
	const { watch } = useFormContext<SalaryDetailDialogFormFields>();
	const { showDialog } = useDialog();

	const hasPermission = useHasPermission('센터장');
	const salaryServiceScheduleSummarys = watch('salaryDetail.salaryServiceScheduleSummarys');
	const salaryServiceSchedules = useMemo(
		() =>
			watch('salaryDetail.salaryServiceSchedules').filter(
				(schedule) => schedule.automationDivNm === 'UPDATE_BILLING',
			),
		[watch('salaryDetail.salaryServiceSchedules')],
	);

	const formatTime = (time?: string) => {
		if (!time) return '';
		const hours = time.substring(0, 2);
		const minutes = time.slice(2, 4);
		return `${hours}:${minutes}`;
	};

	// 임금 삭제 기능. 센터장만 가능
	const onClickServiceDetail = (schedule: SalaryServiceSchedule) => {
		showDialog(() => <DeleteSalaryScheduleDialog scheduleData={schedule} />);
	};

	return (
		<S.Container>
			<CRText typography='label' text='근로 정보' margin='0 0 0.4rem 0' />
			<S.Table style={{ marginBottom: '2rem' }}>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '25%' }}>급여 종류</S.TableLabelColumn>
					<S.TableLabelColumn style={{ width: '25%' }}>수급자</S.TableLabelColumn>
					<S.TableLabelColumn style={{ width: '25%' }}>총 근무 시간</S.TableLabelColumn>
					<S.TableLabelColumn style={{ width: '25%' }}>임금</S.TableLabelColumn>
				</S.TableRow>
				{salaryServiceScheduleSummarys.map((summary, index) => {
					const isLastRow = salaryServiceScheduleSummarys.length - 1 === index;
					if (!isLastRow) {
						return (
							<S.TableRow>
								<S.TableValueColumn $textAlign='left'>{summary.serviceNm}</S.TableValueColumn>
								<S.TableValueColumn $textAlign='left'>{summary.recipientNm}</S.TableValueColumn>
								<S.TableValueColumn $textAlign='left'>
									{summary.totalWorkHourInfo}
								</S.TableValueColumn>
								<S.TableValueColumn $textAlign='left'>
									{displayComma(summary.totalSalaryAmt)}
								</S.TableValueColumn>
							</S.TableRow>
						);
					}
					return (
						<S.TableRow>
							<S.TableValueColumnBase $textAlign='left'>
								<CRText typography='labelB' text={summary.serviceNm} />
							</S.TableValueColumnBase>
							<S.TableValueColumnBase $textAlign='left'>
								<CRText typography='labelB' text={`${summary.recipientNm}명`} />
							</S.TableValueColumnBase>
							<S.TableValueColumnBase $textAlign='left'>
								<CRText typography='labelB' text={summary.totalWorkHourInfo} />
							</S.TableValueColumnBase>
							<S.TableValueColumnBase $textAlign='left'>
								<CRText typography='labelB' text={displayComma(summary.totalSalaryAmt)} />
							</S.TableValueColumnBase>
						</S.TableRow>
					);
				})}
			</S.Table>
			<CRText typography='label' text='근로 상세 내역' margin='0 0 0.4rem 0' />
			<S.Table>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '25%' }}>급여 종류</S.TableLabelColumn>
					<S.TableLabelColumn style={{ width: '16%' }}>수급자</S.TableLabelColumn>
					<S.TableLabelColumn style={{ width: '16%' }}>근무 일자</S.TableLabelColumn>
					<S.TableLabelColumn style={{ width: '35%' }}>근무 시간</S.TableLabelColumn>
					{hasPermission && !disabled && <S.TableLabelColumn style={{ width: '9%' }} />}
				</S.TableRow>
				{salaryServiceSchedules.map((schedule) => {
					const serviceOfferDisplay =
						schedule.restHourCnt > 0
							? `(${schedule.workHourCnt}분 / 휴게 ${schedule.restHourCnt}분)`
							: `(${schedule.serviceOfferHourCnt}분)`;
					return (
						<S.TableRow>
							<S.TableValueColumn $textAlign='left'>{schedule.serviceNm}</S.TableValueColumn>
							<S.TableValueColumn $textAlign='left'>{schedule.recipientNm}</S.TableValueColumn>
							<S.TableValueColumn $textAlign='left'>
								{dayjs(schedule.serviceStartDt).format('YYYY.MM.DD')}
							</S.TableValueColumn>
							<S.TableValueColumn $textAlign='left'>{`${formatTime(
								schedule.serviceStartTime,
							)}~${formatTime(
								schedule.serviceEndTime,
							)} ${serviceOfferDisplay}`}</S.TableValueColumn>
							{hasPermission && !disabled && (
								<S.TableValueColumn $textAlign='left'>
									<S.OptionContainer>
										<CRButton.Default
											size='xSmall'
											type='outlined'
											onClick={() => onClickServiceDetail(schedule)}>
											삭제
										</CRButton.Default>
									</S.OptionContainer>
								</S.TableValueColumn>
							)}
						</S.TableRow>
					);
				})}
			</S.Table>
		</S.Container>
	);
}

export default SalaryDetailServiceSummary;
