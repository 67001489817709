import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInputMessage from 'components/base/CRInputMessage';
import { Toast } from 'components/base/CRToast';
import { CreateEDoc } from 'lib';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes, useMyAccountInfo } from 'lib/hook/react-query';
import {
	useCreateEDocPaper,
	useCreateHomeCarePaper,
	useRegisterEDocPaperTypes,
} from 'lib/hook/react-query/query/edoc';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { CreateEDocPaperRequest, CreateHomeCarePaperRequest } from 'types/api/eDoc';
import { DocumentEnrollFormTypes } from 'types/view/eDoc';

import * as S from './styles';

interface Props {
	title?: string;
	paperMenu?: 'employee' | 'recipient';
	targetId?: number;
}

// 직원, 수급자 서류 등록
export default function CreateEDocDialog({ title = '', paperMenu = 'employee', targetId }: Props) {
	const { data: myAccountInfo } = useMyAccountInfo();
	const {
		watch,
		setValue,
		control,
		trigger,
		handleSubmit,
		formState: { isValid },
	} = useForm<DocumentEnrollFormTypes>({
		resolver: yupResolver(CreateEDoc),
	});

	const { hideDialog } = useDialog();

	const { data: paperTypeCds } = useRegisterEDocPaperTypes({
		paperMenu,
	});

	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN064'],
		},
		commonCodeAdapter,
	);

	const expiredDate =
		watch('vniIssueDt') && watch('vniValidPeriodDayCnt')
			? dayjs(watch('vniIssueDt'))
					.add(Number(watch('vniValidPeriodDayCnt')), 'day')
					.toDate()
			: undefined;
	const visitCntStandardCode = commonCodes?.CMN064;
	const paperTypeOptions = (paperTypeCds?.paperTypeCds || [])?.map((item) => ({
		label: item.korComCdAliasNm,
		value: item.comCdId,
	}));

	const { mutate: createEDocPaper } = useCreateEDocPaper((client, returnData) => {
		if (returnData?.docNo) {
			hideDialog();
			Toast.success('등록 요청을 완료하였습니다.');
			client.invalidateQueries([
				paperMenu === 'employee'
					? endpoint.getEmployeeEDocList.key
					: endpoint.getRecipientEDocList.key,
				paperMenu === 'employee'
					? { centerId: myAccountInfo?.centerId, employeeId: targetId }
					: { centerId: myAccountInfo?.centerId, recipientId: targetId },
			]);
		}
	});

	const { mutate: createHomeCarePaper } = useCreateHomeCarePaper((client, returnData) => {
		if (returnData?.docNo) {
			hideDialog();
			Toast.success('등록 요청을 완료하였습니다.');
			client.invalidateQueries([
				paperMenu === 'employee'
					? endpoint.getEmployeeEDocList.key
					: endpoint.getRecipientEDocList.key,
				paperMenu === 'employee'
					? { centerId: myAccountInfo?.centerId, employeeId: targetId }
					: { centerId: myAccountInfo?.centerId, recipientId: targetId },
			]);
		}
	});

	const onSubmit = async (data: DocumentEnrollFormTypes) => {
		if (!myAccountInfo?.centerId || !targetId) return;
		const param: CreateEDocPaperRequest = {
			centerId: myAccountInfo.centerId,
			paperTypeCd: data.paperType.value,
			remark: data.remark,
			paperStandardDate: dayjs(data.enrollDate).format('YYYYMMDD'),
		};

		if (paperMenu === 'employee') {
			param.employeeId = targetId;
		} else {
			param.recipientId = targetId;
		}

		if (data.paperFile?.length) {
			param.paperFile = {
				fileDetails: data.paperFile,
			};
		}

		if (data.paperTypeEtc) {
			param.etcPaperNm = data.paperTypeEtc;
		}
		if (data.paperType.value === 'CMN119.0081') {
			const homeCareParam: CreateHomeCarePaperRequest = {
				...param,
				vniMedicalInstitutionNm: data.vniMedicalInstitutionNm,
				vniIssueDt: dayjs(data.vniIssueDt).format('YYYYMMDD'),
				vniValidPeriodDayCnt: Number(data.vniValidPeriodDayCnt),
				vniValidPeriodNm: `발급일로부터 ${data.vniValidPeriodDayCnt}일`,
				vniDoctorLicenseNo: data.vniDoctorLicenseNo,
				vniVisitCntStandardCd: data.vniVisitCntStandardCd.value,
				vniVisitCnt: Number(data.vniVisitCnt),
			};

			createHomeCarePaper(homeCareParam);
		} else {
			createEDocPaper(param);
		}
	};

	const renderSubForm = () => {
		if (watch('paperType')?.value === 'CMN119.0081') {
			return (
				<>
					{watch('paperType.value') === 'CMN119.0081' && (
						<Controller
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<CRInputLabel label='의료기관 명칭' isRequired>
									<CRInput.Default
										ref={ref}
										onBlur={onBlur}
										placeholder='의료기관 명칭 입력'
										onChange={onChange}
										value={value}
									/>
								</CRInputLabel>
							)}
							name='vniMedicalInstitutionNm'
							control={control}
						/>
					)}
					{watch('paperType.value') === 'CMN119.0081' && (
						<Controller
							render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
								<CRInputLabel label='발급일' isRequired>
									<S.ResignDateContainer>
										<CRInput.DatePicker
											value={value}
											onChangeValue={onChange}
											placeholder='발급일 선택'
										/>
									</S.ResignDateContainer>
								</CRInputLabel>
							)}
							name='vniIssueDt'
							control={control}
						/>
					)}
					{watch('paperType.value') === 'CMN119.0081' && (
						<Controller
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<CRInputLabel
									label='유효기간'
									isRequired
									message={<CRInputMessage>발급일로부터의 유효기간을 입력하세요.</CRInputMessage>}>
									<CRInput.Default
										type='number'
										ref={ref}
										onBlur={onBlur}
										placeholder='유효기간 일수 입력'
										suffix='일'
										onChange={onChange}
										value={value}
									/>
								</CRInputLabel>
							)}
							name='vniValidPeriodDayCnt'
							control={control}
						/>
					)}
					{watch('paperType.value') === 'CMN119.0081' && (
						<CRInputLabel
							label='만료일'
							message={<CRInputMessage>유효기간에 따라 자동계산됩니다.</CRInputMessage>}>
							<S.ResignDateContainer>
								<CRInput.DatePicker value={expiredDate} disabled />
							</S.ResignDateContainer>
						</CRInputLabel>
					)}
					{watch('paperType.value') === 'CMN119.0081' && (
						<Controller
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<CRInputLabel label='의사면허번호' isRequired>
									<CRInput.Default
										maxLength={10}
										type='number'
										ref={ref}
										onBlur={onBlur}
										placeholder='의사면허번호 입력'
										onChange={onChange}
										value={value}
									/>
								</CRInputLabel>
							)}
							name='vniDoctorLicenseNo'
							control={control}
						/>
					)}
					{watch('paperType.value') === 'CMN119.0081' && (
						<CRInputLabel label='방문횟수' isRequired>
							<S.CountContainer>
								<Controller
									render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
										<CRInput.Selector
											currentValue={value}
											items={visitCntStandardCode}
											onChangeValue={onChange}
											placeholder='서류 유형 선택'
										/>
									)}
									name='vniVisitCntStandardCd'
									control={control}
								/>
								<Controller
									render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
										<CRInput.Default
											type='number'
											ref={ref}
											onBlur={onBlur}
											suffix='회'
											onChange={onChange}
											value={value}
										/>
									)}
									name='vniVisitCnt'
									control={control}
								/>
							</S.CountContainer>
						</CRInputLabel>
					)}
				</>
			);
		}
		return null;
	};

	const submitForm = () => {
		trigger().then((isValid) => {
			if (isValid) {
				handleSubmit(onSubmit)();
			}
		});
	};

	useEffect(() => {
		setValue('enrollDate', new Date());
	}, []);

	useEffect(() => {
		if (watch('paperType')?.value !== 'CMN119.9999') {
			setValue('paperTypeEtc', '');
		}
	}, [watch('paperType')]);

	useEffect(() => {
		if (!watch('vniVisitCntStandardCd') && visitCntStandardCode?.length) {
			setValue('vniVisitCntStandardCd', visitCntStandardCode[0]);
		}
	}, [visitCntStandardCode, watch('vniVisitCntStandardCd')]);

	return (
		<CRDialog
			onClickClose={hideDialog}
			type='S'
			title={title}
			body={
				<S.Container onSubmit={handleSubmit(onSubmit)}>
					<CRInputLabel label='서류 유형' isRequired>
						<Controller
							render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
								<CRInput.Selector
									currentValue={value}
									items={paperTypeOptions}
									onChangeValue={onChange}
									placeholder='서류 유형 선택'
								/>
							)}
							name='paperType'
							control={control}
						/>
						{watch('paperType.value') === 'CMN119.9999' && (
							<Controller
								render={({ field: { onChange, value }, formState: { errors } }) => (
									<CRInput.Default onChange={onChange} value={value} placeholder='서류 유형 입력' />
								)}
								name='paperTypeEtc'
								control={control}
							/>
						)}
					</CRInputLabel>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<CRInputLabel label='등록일자' isRequired>
								<S.ResignDateContainer>
									<CRInput.DatePicker
										value={value}
										onChangeValue={onChange}
										placeholder='등록일자 선택'
									/>
								</S.ResignDateContainer>
							</CRInputLabel>
						)}
						name='enrollDate'
						control={control}
					/>
					{renderSubForm()}
					<Controller
						control={control}
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='첨부 파일' isRequired>
								<CRInput.FileUploader onChange={onChange} files={value} type='multiple' />
							</CRInputLabel>
						)}
						name='paperFile'
					/>
					{watch('paperType')?.value !== 'CMN119.0081' && (
						<CRInputLabel label='비고'>
							<Controller
								render={({ field: { onChange, value }, formState: { errors } }) => (
									<CRInput.TextArea onChange={onChange} value={value} placeholder='비고 입력' />
								)}
								name='remark'
								control={control}
							/>
						</CRInputLabel>
					)}
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						disabled={!isValid}
						palette='primary'
						size='default'
						onClick={submitForm}>
						등록
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
