import { GetContractData } from 'types/api/contract';
import { ContractRecord } from 'types/view/contract';

export const contractsAdapter = (items: GetContractData | null) => ({
	totalPages: items?.totalPages,
	content: (items?.content ?? []).map(
		(item) =>
			({
				id: item.serviceContractId,
				status: item.contractStateCd,
				contractType: item.contractTypeCd,
				serviceContractGroupUuid: item.serviceContractGroupUuid,
				recipient: item.recipientNm,
				birth: item.recipientBirthDt,
				services: item.serviceTypeNms,
				contractDate: item.contractStartDate,
				contractEndDate: item.contractCompleteDate,
				servicePeriod: item.servicePeriod,
				centerName: item.affiliationCenterNm,
				employees: item.offerEmployee,
				manager: item.contractManagerNm,
			}) as ContractRecord,
	),
});
