import React, { useMemo } from 'react';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import InformationTable from 'components/ui/InformationTable';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import { FullTimeEmployeeAnnualTOWorkYearAdjustDTO } from 'types/dto/full-time-employee';

import * as S from './styles';

interface Props {
	items?: FullTimeEmployeeAnnualTOWorkYearAdjustDTO[];
}

export default function YearsOfServiceTab({ items = [] }: Props): React.ReactElement {
	const header = useMemo((): InformationTableItemType[] => {
		const base = [
			{
				type: 'label',
				label: '조정일',
				labelStyle: {
					width: '16rem',
				},
			},
			{
				type: 'label',
				label: '조정개월수',
				labelStyle: {
					width: '10rem',
				},
			},
			{
				type: 'label',
				label: '조정 후 근무년수',
				labelStyle: {
					width: '16rem',
				},
			},
			{
				type: 'label',
				label: '사유',
				labelStyle: {
					width: '20rem',
				},
			},
			{
				type: 'label',
				label: '',
			},
		] as InformationTableItemType[];

		return base;
	}, []);

	return (
		<S.TabContainer>
			<InformationTable
				items={[
					header,
					...items.map(
						(item) =>
							[
								{
									type: 'value',
									value: dayjs(item.workYearCntAdjustDate).format('YYYY.MM.DD'),
								},
								{
									type: 'value',
									value: item.adjustWorkMonthCntNm,
								},
								{
									type: 'value',
									value: item.afterWorkYearCntNm,
								},
								{
									type: 'value',
									value: item.workYearCntAdjustDesc,
								},
							] as InformationTableItemType[],
					),
				]}
			/>
		</S.TabContainer>
	);
}
