import React, { ChangeEvent } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { IntegratedAssessmentDesireForms } from 'types/view/recipient';

import CRCheckBox from '../../../base/Selections/CRCheckBox';
import IntegratedAssessmentMajorIllnessStatusFormHeader from './IntegratedAssessmentMajorIllnessStatusFormHeader';
import { integratedAssessmentMajorIllnessStatusQuestions } from './constant';
import * as S from './styles';

interface Props {
	form: UseFormReturn<IntegratedAssessmentDesireForms>;
	showGenerateRequiredFields?: boolean;
}

function IntegratedAssessmentMajorIllnessStatusForm({
	form,
	showGenerateRequiredFields,
}: Props): React.ReactElement {
	return (
		<S.Container>
			<S.Table>
				<IntegratedAssessmentMajorIllnessStatusFormHeader />
				<S.TableBody>
					{integratedAssessmentMajorIllnessStatusQuestions.map((question) => (
						<Controller
							render={({ field: { ref, onChange, value }, formState: { errors } }) => (
								<S.TableBodyRow>
									<S.TableBodyColumn>{question.label}</S.TableBodyColumn>
									<S.TableBodyColumn>
										<S.TableContentVerticalContainer>
											<S.TableContentContainer>
												{(question.answer ?? []).map((answer) => (
													<>
														<CRCheckBox
															checkBoxType='checkbox'
															checked={form.watch(question.key)?.includes(answer.value)}
															onChange={(event: ChangeEvent<HTMLInputElement>) => {
																const targetValue = value as string[];

																if (event.target.checked) {
																	onChange([...(targetValue ?? []), answer.value]);
																} else {
																	onChange(
																		(targetValue ?? []).filter((item) => item !== answer.value),
																	);
																}
															}}>
															{answer.label}
														</CRCheckBox>
														{answer.options && (
															<>
																(
																{answer.options?.map((option) => (
																	<CRCheckBox
																		checkBoxType='checkbox'
																		checked={form.watch(question.key)?.includes(option.value)}
																		onChange={(event: ChangeEvent<HTMLInputElement>) => {
																			const targetValue = value as string[];

																			if (event.target.checked) {
																				onChange([...(targetValue ?? []), option.value]);
																			} else {
																				onChange(
																					(targetValue ?? []).filter(
																						(item) => item !== option.value,
																					),
																				);
																			}
																		}}>
																		{option.label}
																	</CRCheckBox>
																))}
																)
															</>
														)}
													</>
												))}
											</S.TableContentContainer>
											{form.watch(question.key)?.includes(question.etcValue ?? '기타') && (
												<Controller
													render={({ field: { ref, onChange, value }, formState: { errors } }) => (
														<CRInput.TextArea
															ref={ref}
															placeholder={question.etcPlaceholder ?? '기타 사항 입력'}
															onChange={onChange}
															value={value as string}
														/>
													)}
													name={
														(question.etcKey ??
															`${question.key}기타`) as keyof IntegratedAssessmentDesireForms
													}
													control={form.control}
												/>
											)}
										</S.TableContentVerticalContainer>
									</S.TableBodyColumn>
								</S.TableBodyRow>
							)}
							name={question.key}
							control={form.control}
						/>
					))}
				</S.TableBody>
			</S.Table>
			<Controller
				render={({ field: { ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='판단 근거' isRequired={showGenerateRequiredFields}>
						<CRInput.TextArea
							ref={ref}
							placeholder='판단 근거 입력'
							onChange={onChange}
							value={value}
						/>
					</CRInputLabel>
				)}
				name='주요질병상태판단근거'
				control={form.control}
			/>
		</S.Container>
	);
}

export default IntegratedAssessmentMajorIllnessStatusForm;
