import * as Pop from '@radix-ui/react-popover';
import { css, keyframes, styled } from 'styled-components';

export const Container = styled.div`
	height: fit-content;

	& > button {
		height: 100%;
	}
`;

const slideDown = keyframes`
	from {
	  opacity: 0;
	  transform: translateY(-10px);
	}
	to {
	  opacity: 1;
	  transform: translateY(0);
	};
  `;

const slideUp = keyframes`
	from {
	  opacity: 0;
	  transform: translateY(10px);
	}
	to {
	  opacity: 1;
	  transform: translateY(0);
	}
  `;

export const PopoverRoot = styled(Pop.Root)``;

export const PopoverTrigger = styled(Pop.Trigger)`
	border: none;
	background-color: transparent;
	outline: none;
	padding: 0;
	width: 100%;
`;

export const PopoverContent = styled(Pop.Content)<{
	$borderShape?: 'none' | 'small' | 'large';
	$showBorder?: boolean;
}>`
	margin-top: 100px;
	background: ${(props) => props.theme.colors.gray100};
	overflow: auto;
	animation-duration: 0.2s;
	outline: none;
	margin-top: 0.8rem;

	&[data-side='top'] {
		animation-name: ${slideUp};
	}

	&[data-side='bottom'] {
		animation-name: ${slideDown};
	}

	${(props) =>
		props.$borderShape === 'small' &&
		css`
			border-radius: 0.8rem;
			box-shadow: ${(props) => props.theme.elevation.elevation01};
		`}

	${(props) =>
		props.$borderShape === 'large' &&
		css`
			border-radius: 1.6rem;
			box-shadow: ${(props) => props.theme.elevation.elevation04};
		`}

		${(props) =>
		props.$showBorder &&
		css`
			border: 0.1rem solid ${props.theme.colors.gray90};
		`}
`;

export const PopoverClose = styled(Pop.Close)`
	background: none;
	border: transparent;
	outline: none;
`;

export const Header = styled.div<{ $showDivider?: boolean }>`
	position: sticky;
	top: 0;
	display: flex;
	justify-content: space-between;
	padding: 2.4rem 2.4rem 1.6rem;
	box-sizing: border-box;
	background: ${(props) => props.theme.colors.gray100};

	${(props) =>
		props.$showDivider &&
		css`
			border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
		`}
`;

export const CloseIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
`;

export const HeaderTitle = styled.h4``;
