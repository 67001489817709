import React, { useCallback, useMemo, useRef, useState } from 'react';

import { VirtualItem, useVirtualizer } from '@tanstack/react-virtual';
import dayjs from 'dayjs';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRIcon from 'components/base/CRIcon';
import CRInput from 'components/base/CRInput';
import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import CRTableHeader from 'components/base/CRTableHeader';
import { Toast } from 'components/base/CRToast';
import { CheckOption } from 'components/base/Selections/type';
import InformationTable from 'components/ui/InformationTable';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import { displayPhoneNumber } from 'lib';
import {
	useCommonCodes,
	useResendFailMessage,
	useResendMessage,
	useSendHistoryDetail,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { SendHistoryDetail, SendStateCd } from 'types/api/send';
import { Filter } from 'types/view/filter';

import DefaultDialog from '../DefaultDialog';
import EDocNoDialog from '../EdocNoDialog';
import { SEND_HISTORY_DETAIL_TABLE_HEADER_CONFIG } from './constants';
import * as S from './styles';

interface Props {
	historyId: number;
}

export function SendHistoryDetailDialog({ historyId }: Props) {
	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const { showDialog, hideDialog } = useDialog();
	const [searchValue, setSearValue] = useState('');
	const [currentHistory, setCurrentHistory] = useState<SendHistoryDetail>();
	const [currentFilter, setCurrentFilter] = useState<{
		[key in string]: CheckOption[];
	}>({
		sendStatus: [],
	});
	const { data: sendStatusCodes } = useCommonCodes({
		comCdGroupNms: ['CMN028'],
	});

	const { data: sendHistoryDetail, refetch: sendHistoryDetailRefetch } = useSendHistoryDetail({
		historyId,
	});
	const { mutate: resendFailMessage } = useResendFailMessage((client, returnData) => {
		if (JSON.stringify(returnData) === '{}') {
			Toast.success('정상적으로 발송 요청을 하였습니다.');
			client.invalidateQueries([endpoint.getSendHistoryDetail.key, { historyId }]);
		} else {
			Toast.error('발송 요청을 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}

		hideDialog();
	});

	const { mutate: resendMessage } = useResendMessage((client, returnData) => {
		if (returnData?.sendDetailId && returnData?.sendId) {
			Toast.success('정상적으로 발송 요청을 하였습니다.');
			client.invalidateQueries([endpoint.getSendHistoryDetail.key, { historyId }]);
		} else {
			Toast.error('발송 요청을 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
		hideDialog();
	});

	const base = sendHistoryDetail?.base;
	const details =
		sendHistoryDetail?.details
			.filter(
				(item) =>
					!currentFilter.sendStatus?.length ||
					currentFilter.sendStatus?.some((filter) => filter.label === item.sendStateNm),
			)
			.filter(
				(item) =>
					item.receiverTargetNm.toLowerCase().includes(searchValue.toLowerCase()) ||
					item.receiverPhoneNo?.includes(searchValue),
			) ?? [];

	const sendStatusFilter = useMemo(
		() =>
			({
				key: 'sendStatus',
				type: 'multi',
				options: (sendStatusCodes || [])?.map((item) => ({
					label: item.korComCdAliasNm,
					value: item.comCdId,
				})),
				placeholder: '발송 상태',
			}) as Filter<CheckOption>,
		[sendStatusCodes],
	);

	const SendStatusBadge = useCallback((value: string, item?: SendHistoryDetail) => {
		if (!item) return null;
		const options = [
			{ key: item.sendStateCd, label: item.sendStateNm, color: item.sendStateColor },
		];
		const content =
			item?.sendStateCd === SendStateCd.발송불가 ? (
				<>
					문서에 오류가 생겨 발송할 수 없습니다.
					<br />
					발송 탭에서 다시 발송해 주십시오.
				</>
			) : (
				`사유 : ${item?.sendResultDesc || ''}`
			);
		return (
			<S.SendStatusContainer>
				<CRStatus options={options}>{value}</CRStatus>
				{[SendStateCd.실패, SendStateCd.발송불가].includes(item.sendStateCd as SendStateCd) && (
					<RDTooltip side='top' content={content}>
						<CRIcon src={Assets.icon.help} />
					</RDTooltip>
				)}
			</S.SendStatusContainer>
		);
	}, []);

	const handleClickSend = (item?: SendHistoryDetail) => () => {
		if (!item) return;
		showDialog(() => (
			<DefaultDialog
				title='발송 요청 하시겠습니까?'
				hideDialog={hideDialog}
				content={`${item?.receiverTargetNm}(${item?.receiverPhoneNo})님에게 ${sendHistoryDetail?.base.sendTemplateTitle}를 발송할 수 있도록 요청합니다.`}
				cancelOption={{
					text: '취소',
					callback: hideDialog,
				}}
				successOption={{
					text: '발송 요청',
					successCallback: () => {
						if (sendHistoryDetail) {
							resendMessage({
								sendId: sendHistoryDetail.base.sendId,
								sendDetailId: item.sendDetailId,
							});
						}
					},
				}}
			/>
		));
	};

	const handleClickAllFailMessage = () => {
		const totalFailCount = details.filter((item) => item.sendStateCd === 'CMN028.99')?.length ?? 0;
		showDialog(() => (
			<DefaultDialog
				title='발송 요청 하시겠습니까?'
				hideDialog={hideDialog}
				content={`총 ${totalFailCount}명에게 ${sendHistoryDetail?.base.sendTemplateTitle}를 발송할 수 있도록 요청합니다.`}
				cancelOption={{
					text: '취소',
					callback: hideDialog,
				}}
				successOption={{
					text: '발송 요청',
					successCallback: () => {
						if (sendHistoryDetail) {
							resendFailMessage({
								sendId: sendHistoryDetail?.base.sendId,
							});
						}
					},
				}}
			/>
		));
	};

	const handleSearchValue = (value: string) => setSearValue(value);
	const isSchedule = base?.sendTemplateTypeCd === 'CMN009.004';

	const handleClickPreview = (item?: SendHistoryDetail) => () => {
		if (item?.edocNo) {
			showDialog(() => <EDocNoDialog edocNo={item.edocNo} />);
		} else if (isSchedule) {
			const left = (window.screen.width - 500) / 2;
			const top = (window.screen.height - 800) / 2;

			const target = item?.receiverTargetDivCd === 'CMN118.30' ? 'employee' : 'recipient';

			window.open(
				`${window.location.origin}/schedule/client-mobile/${target}/${item?.sendUUid}`,
				'일정표 확인',
				`width=500, height=800, toolbar=0, location=0, directories=0, status=0, menubar=0, scrollbars=1, resizable=1, left=${left}, top=${top}`,
			);
		}
	};

	const renderOption = (value: number, item?: SendHistoryDetail) => (
		<S.ButtonContainer>
			{/* 전자문서이거나 일정표일때만 미리보기 노출 */}
			{(item?.edocNo || isSchedule) && (
				<CRButton.Default
					size='xSmall'
					type='outlined'
					palette='gray'
					onClick={handleClickPreview(item)}>
					미리보기
				</CRButton.Default>
			)}
			{/* 하드코딩 */}
			{item?.sendStateCd !== 'CMN028.90' && (
				<CRButton.Default
					size='xSmall'
					type='outlined'
					palette='gray'
					onClick={handleClickSend(item)}>
					발송
				</CRButton.Default>
			)}
			{}
		</S.ButtonContainer>
	);

	const renderSendDate = (sendDate: Date) => (
		<S.TextBox>{sendDate ? dayjs(sendDate).format('YYYY.MM.DD HH:mm') : '-'}</S.TextBox>
	);

	const renderReceiverPhoneNo = (renderReceiverPhoneNo: string) => (
		<S.TextBox>{renderReceiverPhoneNo ? displayPhoneNumber(renderReceiverPhoneNo) : '-'}</S.TextBox>
	);

	const onClickHistory = (item: SendHistoryDetail) => () => {
		setCurrentHistory(item);
	};

	const rowRenderer = (virtualRow: VirtualItem, index: number) => {
		const item = details?.[virtualRow.index];
		if (!item) return null;

		return (
			<CRTable.Row
				isFocused={currentHistory?.sendDetailId === item.sendDetailId}
				onClick={onClickHistory(item)}
				item={item}
				key={index}
				style={{
					width: '100%',
					display: 'flex',
					position: 'absolute',
					transform: `translateY(${virtualRow.start}px)`,
				}}
				customRender={{
					sendDetailId: renderOption,
					sendDate: renderSendDate,
					receiverPhoneNo: renderReceiverPhoneNo,
					sendStateCd: SendStatusBadge,
				}}
				customStyle={{
					sendDate: {
						width: SEND_HISTORY_DETAIL_TABLE_HEADER_CONFIG[0].width,
						flexShrink: 0,
						textAlign: 'right',
						lineHeight: '3.3rem',
					},
					receiverTargetNm: {
						width: SEND_HISTORY_DETAIL_TABLE_HEADER_CONFIG[1].width,
						flexShrink: 0,
						lineHeight: '3.3rem',
					},
					receiverPhoneNo: {
						width: SEND_HISTORY_DETAIL_TABLE_HEADER_CONFIG[2].width,
						flexShrink: 0,
						lineHeight: '3.3rem',
					},
					edocNo: {
						width: SEND_HISTORY_DETAIL_TABLE_HEADER_CONFIG[3].width,
						flexShrink: 0,
						lineHeight: '3.3rem',
					},
					sendStateCd: {
						width: SEND_HISTORY_DETAIL_TABLE_HEADER_CONFIG[4].width,
						flexShrink: 0,
						lineHeight: '3.3rem',
					},
					sendDetailId: {
						width: SEND_HISTORY_DETAIL_TABLE_HEADER_CONFIG[5].width,
						lineHeight: '3.3rem',
					},
				}}
				renderKeys={[
					'sendDate',
					'receiverTargetNm',
					'receiverPhoneNo',
					'edocNo',
					'sendStateCd',
					'sendDetailId',
				]}
			/>
		);
	};

	const rowVirtualizer = useVirtualizer({
		count: details.length,
		getScrollElement: () => scrollContainerRef.current,
		estimateSize: () => 33,
		overscan: 30,
	});

	return (
		<CRDialog
			onClickClose={hideDialog}
			type='L'
			height='70rem'
			title='발송 이력 상세'
			body={
				<S.Container>
					<S.LeftSection>
						<S.SectionContainer>
							<S.SectionTitle>발송 기본 정보</S.SectionTitle>
							<InformationTable
								items={[
									[
										{
											label: '문서 유형',
											value: base?.sendTemplateTitle || '-',
										},
										{
											label: '제목',
											value: base?.sendTitle || '-',
										},
										{
											label: '발송자',
											value: base?.senderNm || '-',
										},
									].map((column, index) => {
										const indexWidth = ['22.1rem', '36.2rem', '6rem'];
										return {
											...column,
											value: (
												<S.TableValue
													title={typeof column.value === 'string' ? column.value : undefined}
													style={{
														width: indexWidth[index],
														overflow: 'hidden',
														textOverflow: 'ellipsis',
														whiteSpace: 'nowrap',
														height: '3.3rem',
														lineHeight: '3.3rem',
													}}>
													{column.value}
												</S.TableValue>
											),
											labelStyle: {
												minWidth: '7.9rem',
												verticalAlign: 'middle',
												padding: '0 0.8rem',
												boxSizing: 'border-box',
											},
											valueStyle: {
												verticalAlign: 'middle',
												padding: '0 0.8rem',
											},
										};
									}),
								]}
							/>
						</S.SectionContainer>
						<S.SectionContainer>
							<S.SectionTitle>발송 이력</S.SectionTitle>
							<CRTableHeader
								bottomPadding={false}
								filters={[sendStatusFilter]}
								currentFilter={currentFilter}
								renderRightButton={
									details?.find((item) => item.sendStateCd === 'CMN028.99') && (
										<CRButton.Default
											size='xSmall'
											type='outlined'
											palette='gray'
											onClick={handleClickAllFailMessage}>
											발송 실패 재발송
										</CRButton.Default>
									)
								}
								onChangeSearchValue={handleSearchValue}
								placeholder='이름, 전화번호 검색'
								setCurrentFilter={setCurrentFilter}
								stickyMode
								searchValue={searchValue}
							/>
							<S.TableContainer ref={scrollContainerRef}>
								<CRTable.Root>
									<CRTable.Head offset={-69} heads={SEND_HISTORY_DETAIL_TABLE_HEADER_CONFIG} />
									<CRTable.Body
										style={{
											height: `${rowVirtualizer.getTotalSize()}px`,
											position: 'relative',
										}}>
										{rowVirtualizer?.getVirtualItems()?.map(rowRenderer)}
									</CRTable.Body>
								</CRTable.Root>
							</S.TableContainer>
						</S.SectionContainer>
					</S.LeftSection>
					<S.RightSection>
						<S.SectionContainer>
							<S.SectionTitle>내용</S.SectionTitle>
							<CRInput.TextArea
								disabled
								style={{
									height: '41.6rem',
								}}
								numberOfLines={14}
								value={
									currentHistory?.sendMessageDesc
										? currentHistory?.sendMessageDesc?.replace(/<br\/>|\\n/g, '\n')
										: currentHistory?.sendStateCd === 'CMN028.90'
											? `문서에 오류가 생겨 발송할 수 없습니다.\n발송 탭에서 다시 발송해 주십시오.`
											: ''
								}
							/>
						</S.SectionContainer>
					</S.RightSection>
				</S.Container>
			}
		/>
	);
}
