import React, { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import Assets from 'assets';
import CRBanner from 'components/base/CRBanner';
import CRButton from 'components/base/CRButton';
import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRIcon from 'components/base/CRIcon';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInputMessage from 'components/base/CRInputMessage';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { FullTimeEmployeeCreateValidator } from 'lib';
import { commonCodeAdapter } from 'lib/adapter/common';
import {
	useCheckFullTimeEmployeeDuplication,
	useCheckRealName,
	useCommonCodes,
	useCreateFullTimeEmployee,
	useMyAccountInfo,
} from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import { ResponseCode } from 'types/api';

import * as S from './styles';

interface Props {
	hideDialog: () => void;
	onSuccess: (employeeId?: number) => void;
}

export type FullTimeEmployeeEnrollForm = {
	name: string;
	rsdnNo: string;
	center: {
		label: string;
		value: any;
	};
	part: {
		label: string;
		value: any;
	};
	wageType: {
		label: string;
		value: any;
	};
	joinDate: Date;
};

export default function FullTimeEmployeeEnrollDialog({ hideDialog, onSuccess }: Props) {
	const {
		control,
		handleSubmit,
		watch,
		setError,
		formState: { errors, isValid },
	} = useForm<FullTimeEmployeeEnrollForm>({
		resolver: yupResolver(FullTimeEmployeeCreateValidator),
		mode: 'onChange',
		defaultValues: {
			part: {
				label: '통합요양',
				value: 'CMN187.10',
			},
			wageType: {
				label: '월급제',
				value: 'CMN188.10',
			},
		},
	});
	const { data: myAccountInfo } = useMyAccountInfo();
	const [banner, setBanner] = useState(
		<CRBanner type='info' title='중복 조회를 진행해야 직원을 생성할 수 있습니다.' />,
	);
	const [isDuplicateValid, setIsDuplicateValid] = useState<boolean | null>(null);
	const [isRealName, setIsRealName] = useState(false);
	const [didCheckRealName, setDidCheckRealName] = useState<boolean>(false);

	const {
		data: commonCodes = {
			CMN187: [],
			CMN188: [],
		},
	} = useCommonCodes({ comCdGroupNms: ['CMN187', 'CMN188'] }, commonCodeAdapter);
	const { data: checkDuplicateResult, mutateAsync: checkDuplicate } =
		useCheckFullTimeEmployeeDuplication();
	const { mutateAsync: checkRealNameMutation } = useCheckRealName();

	const { mutate: createEmployee } = useCreateFullTimeEmployee(async (client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS && myAccountInfo) {
			const queryKey = [
				endpoint.getFullTimeEmployees.key,
				{
					centerIds: [myAccountInfo.centerId],
				},
			];

			await client.invalidateQueries(queryKey);
			onSuccess(returnData.data?.employeeId);
			hideDialog();
			Toast.success('직원을 추가했습니다.');
		} else {
			Toast.error('직원 등록에 실패했습니다. 잠시후 다시 시도해주세요.');
		}
	});

	const checkKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
		if (e.key === 'Enter') e.preventDefault();
	};

	const { name, rsdnNo } = watch();

	const handleClickCheckRealName = async () => {
		const foreignerYn = ['5', '6', '7', '8'].includes(rsdnNo?.[6]);
		const result = await checkRealNameMutation({
			name,
			rsdnNo,
			foreignerYn,
		});
		setDidCheckRealName(true);
		if (result?.data?.result) {
			setBanner(
				<CRBanner type='success' title='실명 확인이 완료되어 직원을 생성할 수 있습니다.' />,
			);
			setIsRealName(true);
		} else {
			setBanner(
				<>
					<CRBanner
						type='warning'
						title='실명 확인에 실패했습니다. 이름과 주민등록번호를 확인 후 진행해 주십시오.'
					/>
					<S.HelperTextContainer>
						<S.HelperTextTitle>
							<CRIcon
								style={{
									width: '1.6rem',
									height: '1.6rem',
								}}
								src={Assets.icon.help}
							/>
							<CRText
								color='gray50'
								typography='labelB'
								text='왜 실명확인에 실패해도 추가/등록이 가능한가요?'
							/>
						</S.HelperTextTitle>
						<CRText
							color='gray50'
							typography='label'
							text={`실명확인 시스템은 모든 국민의 정보를 확인할 수 없습니다. 
							정확한 정보를 기입해도 실패가 될 수 있습니다.
							이름과 주민등록번호가 정확하다면 업무를 진행하시면 됩니다.
							*시군구(W4C)에서 한 번 더 실명확인하는 것을 추천합니다.
							`}
						/>
					</S.HelperTextContainer>
				</>,
			);
			setIsRealName(true);
		}
	};

	const handleClickDuplicateCheck = async () => {
		if (!rsdnNo) {
			setError('rsdnNo', {
				message: '입력값을 확인해주세요',
			});
		}
		if (!name) {
			setError('name', {
				message: '입력값을 확인해주세요',
			});
		}

		if (!myAccountInfo?.centerId || Object.keys(errors)?.length) return;

		const checkResult = await checkDuplicate({
			rsdnNo,
			name,
			centerId: myAccountInfo.centerId,
		});

		if (checkResult?.code === ResponseCode.SUCCESS) {
			if (checkResult?.data?.recipientDuplicateYn === true) {
				setBanner(
					<CRBanner type='error' title='동일한 주민번호로 이미 수급자에 등록되어 있습니다.' />,
				);
				setIsDuplicateValid(false);
			} else if (checkResult.data?.resignYn) {
				setBanner(
					<CRBanner
						type='warning'
						title={`${dayjs(checkResult.data.resignDt).format('YYYY.MM.DD')}에 ${
							checkResult.data.centerNm
						}에서 퇴사한 직원입니다.\n(재입사 등록)`}
					/>,
				);
				setIsDuplicateValid(true);
				setIsRealName(true);
				setDidCheckRealName(true);
			} else if (
				!checkResult?.data?.otherCenterDuplicateYn &&
				!checkResult?.data?.sameCenterDuplicateYn
			) {
				setBanner(
					<CRBanner type='success' title='새로운 직원입니다. 실명 확인을 진행해 주십시오.' />,
				);
				setIsDuplicateValid(true);
			} else if (checkResult?.data?.otherCenterDuplicateYn) {
				setBanner(
					<CRBanner
						type='error'
						title={`${checkResult.data.centerNm}에 이미 존재하는 직원입니다.`}
					/>,
				);
				setIsDuplicateValid(false);
			} else if (checkResult?.data?.sameCenterDuplicateYn) {
				setBanner(<CRBanner type='error' title='센터내에 이미 존재하는 직원입니다.' />);
				setIsDuplicateValid(false);
			} else {
				setBanner(
					<CRBanner type='error' title='중복 조회에 실패 했습니다. 나중에 다시 시도해주세요.' />,
				);
				setIsDuplicateValid(false);
			}
		} else {
			setBanner(
				<CRBanner
					type='error'
					title={checkResult?.message || '중복 조회에 실패 했습니다. 나중에 다시 시도해주세요.'}
				/>,
			);
			Toast.error(checkResult?.message || '중복 조회에 실패 했습니다. 나중에 다시 시도해주세요.');
			setIsDuplicateValid(false);
		}

		setDidCheckRealName(false);
	};

	const isNewUser = useMemo(
		() =>
			!checkDuplicateResult?.data?.sameCenterDuplicateYn &&
			!checkDuplicateResult?.data?.otherCenterDuplicateYn &&
			!checkDuplicateResult?.data?.recipientDuplicateYn,
		[checkDuplicateResult],
	);

	const isOtherCenterDuplicated = useMemo(
		() => checkDuplicateResult?.data?.otherCenterDuplicateYn,
		[checkDuplicateResult],
	);

	const onSubmit = async (data: FullTimeEmployeeEnrollForm) => {
		if (!myAccountInfo?.centerId) return;
		try {
			createEmployee({
				rsdnNo: data.rsdnNo,
				name: data.name,
				centerId: myAccountInfo?.centerId,
				partDivCd: data.part.value,
				wageDivCd: data.wageType.value,
				joinDt: dayjs(data.joinDate).format('YYYYMMDD'),
			});
		} catch (e) {
			console.log('error', e);
		}
	};

	const isAllValid = !!(
		Object.keys(errors)?.length === 0 &&
		isDuplicateValid &&
		(isOtherCenterDuplicated || isRealName) &&
		watch('joinDate')
	);

	useEffect(() => {
		setIsDuplicateValid(false);
		setIsRealName(false);
		setDidCheckRealName(false);
	}, [name, rsdnNo]);

	return (
		<S.Container>
			<S.Form onSubmit={handleSubmit(onSubmit)} onKeyDown={checkKeyDown}>
				<S.Header>
					<S.Title>직원 등록</S.Title>
					<S.Icon src={Assets.icon.close} alt='close' onClick={hideDialog} />
				</S.Header>
				<S.Content>
					<S.SubTitle>중복 조회를 하고 새로운 직원을 생성하세요.</S.SubTitle>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => {
							if (errors.name?.message) {
								setIsDuplicateValid(false);
							}
							return (
								<CRInputLabel
									label='이름'
									isRequired
									message={
										errors.name?.message && (
											<CRInputMessage type='error'>{errors.name?.message ?? ''}</CRInputMessage>
										)
									}>
									<CRInput.Default
										status={errors.name?.message ? 'error' : 'default'}
										ref={ref}
										onBlur={onBlur}
										onChange={onChange}
										value={value}
										placeholder='이름 입력'
									/>
								</CRInputLabel>
							);
						}}
						name='name'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => {
							if (errors.name?.message) {
								setIsDuplicateValid(false);
							}
							return (
								<CRInputLabel
									label='주민등록번호'
									isRequired
									message={
										errors.rsdnNo?.message && (
											<CRInputMessage type='error'>{errors.rsdnNo?.message ?? ''}</CRInputMessage>
										)
									}>
									<div
										style={{
											position: 'relative',
										}}>
										<CRInput.Default
											type='number'
											maxLength={13}
											status={errors.rsdnNo?.message ? 'error' : 'default'}
											ref={ref}
											onBlur={onBlur}
											onChange={onChange}
											value={value}
											placeholder='주민등록번호 입력'
										/>
										<S.RightButtonContainer>
											<CRButton.Default
												onClick={handleClickDuplicateCheck}
												disabled={
													!(watch('name') && !errors.name && watch('rsdnNo') && !errors.rsdnNo)
												}
												size='xSmall'
												palette='gray'
												type='outlined'
												buttonType='button'>
												중복 조회
											</CRButton.Default>
											<CRButton.Default
												size='xSmall'
												disabled={!isDuplicateValid || !isNewUser || didCheckRealName}
												palette='gray'
												type='filled'
												onClick={handleClickCheckRealName}
												buttonType='button'>
												실명 확인
											</CRButton.Default>
										</S.RightButtonContainer>
									</div>
								</CRInputLabel>
							);
						}}
						name='rsdnNo'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<CRInputLabel label='소속센터'>
								<CRInput.Selector
									disabled
									items={[
										{
											label: myAccountInfo?.centerNm || '',
											value: myAccountInfo?.centerNm || '',
										},
									]}
									autoComplete
									onChangeValue={onChange}
									currentValue={{
										label: myAccountInfo?.centerNm || '',
										value: myAccountInfo?.centerNm || '',
									}}
									placeholder='센터 선택'
								/>
							</CRInputLabel>
						)}
						name='center'
						control={control}
					/>
					{banner}
					<CRCardFormLayout label='입사 정보' isRequired>
						<CRInputLabel label='파트 구분' isRequired>
							<Controller
								render={({ field: { onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										type='radio'
										appearanceType='button'
										options={commonCodes.CMN187}
										value={[value]}
										onChange={(option) => onChange(option[0])}
										gap={1.6}
										disabled
									/>
								)}
								name='part'
								control={control}
							/>
						</CRInputLabel>
						<CRInputLabel label='임금 구분' isRequired>
							<Controller
								render={({ field: { onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										type='radio'
										appearanceType='button'
										options={commonCodes.CMN188}
										value={[value]}
										onChange={(option) => onChange(option[0])}
										gap={1.6}
										disabled
									/>
								)}
								name='wageType'
								control={control}
							/>
						</CRInputLabel>
						<Controller
							render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
								<CRInputLabel
									label='입사일'
									message={
										errors.name?.message && (
											<CRInputMessage type='error'>{errors.joinDate?.message ?? ''}</CRInputMessage>
										)
									}
									isRequired>
									<CRInput.DatePicker
										placeholder='입사일 선택'
										value={value}
										onChangeValue={onChange}
										popupPosition={{ y: -580, x: 0 }}
									/>
								</CRInputLabel>
							)}
							name='joinDate'
							control={control}
						/>
					</CRCardFormLayout>
				</S.Content>
				<S.BottomContainer>
					<S.ButtonContainer>
						<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
							취소
						</CRButton.Default>
						<CRButton.Default
							disabled={!isAllValid}
							palette='primary'
							size='default'
							buttonType='submit'>
							등록
						</CRButton.Default>
					</S.ButtonContainer>
				</S.BottomContainer>
			</S.Form>
		</S.Container>
	);
}
