import { CRTableHeadConfig } from '../../../../types/view/base';

export const RECIPIENT_LIST_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '5.2rem',
		label: '구분',
		sortKey: '구분',
		textAlign: 'right',
	},
	{
		width: '7.2rem',
		label: '수급자명',
		sortKey: '수급자명',
	},
	{
		width: '9.6rem',
		label: '생년월일',
		sortKey: '생년월일',
	},
	{
		width: '6.4rem',
		label: '상태',
		sortKey: '상태',
	},
	{
		width: '4.8rem',
		label: '일정',
		sortKey: '일정',
	},
	{
		width: '8.8rem',
		label: '보류 여부',
		sortKey: '보류 여부',
	},
	{
		width: '9.6rem',
		label: '사회복지사',
		sortKey: '사회복지사',
	},
];

export const RECIPIENT_LIST_TABLE_RENDER_KEY = [
	'index',
	'korMemberNm',
	'birthDt',
	'recipientStateNm',
	'scheduleCnt',
	'pending',
	'managerNm',
] as const;
