import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import CRInput from 'components/base/CRInput';

interface Props extends React.ComponentProps<typeof CRInput.Default> {
	name: string;
}

function CRFormInputDefault({ name, ...rest }: Props) {
	const methods = useFormContext();
	const { field } = useController({ name, control: methods.control });

	return (
		<CRInput.Default
			{...rest}
			{...field}
			status={methods.formState.errors[name]?.message ? 'error' : 'default'}
			addOnBottom={`${methods.formState.errors[name]?.message}`}
		/>
	);
}

export default CRFormInputDefault;
