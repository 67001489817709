import { endpoint } from 'lib/service/Api/endpoint';

import useCRQuery from '../base/useCRQuery';

export const useMyCenterInfo = useCRQuery(endpoint.getMyCenterInfo.key, 'getMyCenterInfo', {
	enabledKey: ['centerId'],
});

export const useCenterChiefs = useCRQuery(endpoint.getCenterChiefs.key, 'getCenterChiefs');

export const useCenterOfferServices = useCRQuery(
	endpoint.getCenterOfferServices.key,
	'getCenterOfferServices',
	{
		enabledKey: ['centerId'],
	},
);
