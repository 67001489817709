import React, { CSSProperties } from 'react';

import DefaultSubTab from './DefaultSubTab';
import FullSizeSubTab from './FullSizeSubTab';
import { TabProps } from './type';

interface Props {
	items: TabProps[];
	containerStyle?: CSSProperties;
	menuContainerStyle?: CSSProperties;
	contentContainerStyle?: CSSProperties;
	defaultActiveKey?: string;
	onChange?: (item: TabProps) => void;
	fullSize?: boolean;
}

function SubTab({ fullSize = false, ...rest }: Props): React.ReactElement {
	if (fullSize) return <FullSizeSubTab {...rest} />;
	return <DefaultSubTab {...rest} />;
}

export default SubTab;
