import * as XLSX from 'sheetjs-style';

import { GetRecipientsExcelDTO } from 'types/api';

export const exportRecipientsInfoTransfer = (fileName: string, data: GetRecipientsExcelDTO[]) => {
	const excelData = data.map(
		({
			recipientId,
			recipientStateNm,
			korMemberNm,
			birthDt,
			genderNm,
			centerNm,
			managerNm,
			longTermGradeNm,
			longTermNo,
			recipientBasAddr,
			recipientDetailAddr,
			recipientZipCode,
			guardianNm,
			guardianPhoneNo,
			serviceTypeNms,
			burdenRateNm,
			longTermDate,
			recipientRegionNm1,
			recipientRegionNm2,
			recipientRegionNm3,
			recipientRoadNm,
			recipientAddrLatitude,
			recipientAddrLongitude,
		}) => ({
			수급자ID: recipientId || '',
			상태: recipientStateNm || '',
			수급자성명: korMemberNm || '',
			생년월일: birthDt || '',
			성별: genderNm || '',
			센터명: centerNm || '',
			담당자성명: managerNm || '',
			장기요양인정등급: longTermGradeNm || '',
			장기요양인정번호: longTermNo || '',
			기본주소: recipientBasAddr || '',
			상세주소: recipientDetailAddr || '',
			우편번호: recipientZipCode || '',
			보호자성명: guardianNm || '',
			보호자전화번호: guardianPhoneNo || '',
			급여종류: serviceTypeNms || '',
			본인부담율: burdenRateNm || '',
			장기요양인정기간: longTermDate || '',
			지역명1: recipientRegionNm1 || '',
			지역명2: recipientRegionNm2 || '',
			지역명3: recipientRegionNm3 || '',
			도로명: recipientRoadNm || '',
			위도: recipientAddrLatitude || '',
			경도: recipientAddrLongitude || '',
		}),
	);

	const workSheet = XLSX.utils.json_to_sheet(excelData);

	const headerStyle = {
		fill: {
			fgColor: { rgb: 'DDDDDD' },
		},
		alignment: {
			horizontal: 'center',
		},
	};

	workSheet.A1.s = headerStyle;
	workSheet.B1.s = headerStyle;
	workSheet.C1.s = headerStyle;
	workSheet.D1.s = headerStyle;
	workSheet.E1.s = headerStyle;
	workSheet.F1.s = headerStyle;
	workSheet.G1.s = headerStyle;
	workSheet.H1.s = headerStyle;
	workSheet.I1.s = headerStyle;
	workSheet.J1.s = headerStyle;
	workSheet.K1.s = headerStyle;
	workSheet.L1.s = headerStyle;
	workSheet.M1.s = headerStyle;
	workSheet.N1.s = headerStyle;
	workSheet.O1.s = headerStyle;
	workSheet.P1.s = headerStyle;
	workSheet.Q1.s = headerStyle;
	workSheet.R1.s = headerStyle;
	workSheet.S1.s = headerStyle;
	workSheet.T1.s = headerStyle;
	workSheet.U1.s = headerStyle;
	workSheet.V1.s = headerStyle;
	workSheet.W1.s = headerStyle;

	const keys = Object.values(excelData[0]);

	const columnStyles = keys.map((_, idx) => {
		const getMaxLength = String(keys[idx]).length;
		const minWidth = 13;

		return {
			width: Math.max(minWidth, getMaxLength * 2),
		};
	});

	workSheet['!cols'] = columnStyles;

	const wb = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(wb, workSheet, '입력정보');
	XLSX.writeFile(wb, `${fileName}.xlsx`);
	return true;
};
