import { CheckOption } from 'components/base/Selections/type';

import { ServiceTime } from '../api/common';
import { KAKAOAddress } from './base';

export type Recipient = {
	id: string;
	recipientId: number;
	name: string;
	/**
	 * https://m.blog.naver.com/jsvr/220886531103
	 * resident number의 약어, 주민등록번호
	 */
	rdsn: string;
	birthday: string;
	phoneNumber: string;
	centerId: number;
	centerName: string;
	state: string;
	service: string;
	managerId: number;
	managerName: string;
	longTermEndDate?: string;
	mainGuardianNm?: string;
};

export type RecipientInfoSummary = {
	name: string;
	guardian: string;
	manager: string;
	gender: string;
	genderCd: string;
	phoneNumber: string;
	ownExpense: string;
	rsdn: string;
	otherService: string;
	longTermNo: string;
	address: string;
	service: string;
	longTermGrade: string;
	longTermGradeCd: string;
};

export type RecipientDigitalReport = {
	id: string;
	week: string;
	employee: string;
	service: string;
	targetDate: string;
	signingDate: string;
	status: string;
};

export type RecipientHandworkReport = {
	id: string;
	week: string;
	employee: string;
	service: string;
	targetDate: string;
	signingDate: string;
	status: string;
	file?: any;
};

export type RecipientDocumentDetail = {
	id: string;
	title: string;
	no: string;
	updatedAt: string;
	status: string;
};

export type RecipientDocument = {
	id: string;
	title: string;
	note: string;
	file: {
		url: string;
		name: string;
	};
	createAt: string;
};

export type RecipientConsulting = {
	id: string | number;
	date: string;
	consultant: string;
	relation: string;
	type: string;
	tag: string;
	center: string;
	employee: string;
};

export type RecipientService = {
	id: number;
	status: string;
	serviceType: string;
	service: string;
	contractedDate: string;
	contractTypeCdNm: string;
	periodOfUse: string;
	employee: string;
	center: string;
	manager: string;
	recipient: string;
};

export type RecipientMessageHistory = {
	id: string;
	date: string;
	template: string;
	title: string;
	status: string;
	signStatus: string;
	center: string;
	sender: string;
	content?: string;
};

export type RecipientReservedMessage = {
	id: string;
	date: string;
	template: string;
	title: string;
	status: string;
	center: string;
	sender: string;
	content?: string;
};

export type RecipientPay = {
	id: string;
	date: string;
	amount: string;
	type: string;
	memo: string;
};

export type RecipientClaim = {
	id: string;
	date: string;
	service: string;
	amount: string;
};

export type RecipientBasicStatusFormType = {
	status: string;
	manager: string;
	holding: string;
};

export type RecipientBasicInfoFormType = {
	name: string;
	address: KAKAOAddress;
	korMemberNm: string;
	birthday: string;
	memo: string;
	signType: string;
	regionNm1: string;
	regionNm2: string;
	regionNm3: string;
	roadNm: string;
	addrLatitude: number;
	addrLongitude: number;
};

export type RecipientGuardianFormViewType = {
	id?: number;
	name: string;
	phoneNumber: string;
	address: KAKAOAddress;
	birthday: string;
	relationship: string;
	isMain: boolean;
	deleteYn?: boolean;
	scheduleReceiveYn: boolean;
	longTermPayFeeCertiReceiveYn?: boolean;
	docReceiveMethodCd?: string;
};

export type RecipientOtherServiceFormViewType = {
	id?: number;
	centerName: string;
	phoneNumber: string;
	memo: string;
	otherService: string;
	times: ServiceTime[];
};

export type RecipientLongTermFormViewType = {
	longTermNo: string;
	longTermMajorChangeNo: string;
	longTermMinorChangeNo: string;
	longTermGrade: string;
	longTermGradeCd: string;
	longTermGradePeriod: string;
	ownExpenseRate: string;
	welfareEquipment: string[];
};

export type RecipientLongTermGradeHistoryType = {
	id: string;
	status: string;
	date: string;
	grade: string;
	acquisitionDate: string;
	expiredDate: string;
	number: string;
	note: string;
	center: string;
	manager: string;
};

export type RecipientOwnExpenseHistoryType = {
	id: string;
	status: string;
	date: string;
	ownExpenseRate: string;
	ownExpenseRateCd: string;
	note: string;
	center: {
		id: string;
		name: string;
	};
	manager: {
		id: string;
		name: string;
	};
};

export type RecipientManagerHistoryType = {
	id: string;
	status: string;
	date: string;
	center: {
		id: string;
		name: string;
	};
	manager: {
		id: string;
		name: string;
	};
	note: string;
	consultantOpinion: string;
	changeReason: string;
};

export type RecipientHoldingHistoryType = {
	id: number;
	period: string;
	description: string;
};

export type RecipientOwnExpenseFormViewType = {
	payMethod: CheckOption;
	bank?: string;
	account?: string;
	accountOwner?: string;
	name?: string;
	receipt: CheckOption;
	receiptMethod?: CheckOption;
	receiptNumber?: string;
	payDeductEmployeeId?: number;
};

export type RecipientIntegratedAssessment = {
	id: string;
	week: string;
	employee: string;
	service: string;
	targetDate: string;
	signingDate: string;
	status: string;
};

export enum IntegratedAssessmentType {
	DesireInfo = 'CMN119.0004',
	FallInfo = 'CMN119.0005',
	BedsoreInfo = 'CMN119.0006',
	KmmseInfo = 'CMN119.0008',
}

export type IntegratedAssessmentBedsoreRecipientForm = {
	type: CheckOption[];
	writerNm: string;
	writeDate: string;
	recipientNm: string;
	recipientBirthDt: string;
	recipientGenderCd: string;
	longTermStartDate: string;
	longTermEndDate: string;
	longTermGradeCd: string;
	longTermNo: string;
};

export type IntegratedAssessmentBedsoreForm = {
	감각인지여부: number;
	습기여부: number;
	활동상태: number;
	움직임: number;
	영양상태: number;
	마찰력과응전력: number;
	욕창비고: string;
};

export type IntegratedAssessmentBedsoreForms = IntegratedAssessmentBedsoreRecipientForm &
	IntegratedAssessmentBedsoreForm;

export type IntegratedAssessmentBedsoreQuestion = {
	key: keyof IntegratedAssessmentBedsoreForm;
	label: string;
	answer: {
		label: string;
		description: string;
		highlightText?: string[];
		point: number;
	}[];
};

export type IntegratedAssessmentFallRecipientForm = {
	type: CheckOption[];
	writerNm: string;
	writeDate: string;
	recipientNm: string;
	recipientBirthDt: string;
	recipientGenderCd: string;
	longTermStartDate: string;
	longTermEndDate: string;
	longTermGradeCd: string;
	longTermNo: string;
};

export type IntegratedAssessmentFallForm = {
	연령: number;
	정신상태: number;
	배변: number;
	낙상경험: number;
	활동상태: number;
	걸음걸이및균형: number;
	약물: number;
	낙상비고: string;
};

export type IntegratedAssessmentFallForms = IntegratedAssessmentFallRecipientForm &
	IntegratedAssessmentFallForm;

export type IntegratedAssessmentFallQuestion = {
	key: keyof IntegratedAssessmentFallForm;
	label: string;
	answer: {
		label: string;
		description: string;
		highlightText?: string[];
		point: number;
	}[];
};

export type IntegratedAssessmentKmmseRecipientForm = {
	type: CheckOption[];
	education: CheckOption;
	dementiaYn: CheckOption[];
	educationYear: string;
	job: string;
	calendar: CheckOption[];
	writerNm: string;
	writeDate: string;
	recipientNm: string;
	recipientBirthDt: string;
	recipientGenderCd: string;
	longTermStartDate: string;
	longTermEndDate: string;
	longTermGradeCd: string;
	longTermNo: string;
};

export type IntegratedAssessmentKmmseForm = {
	기억등록: {
		기차: {
			value?: string;
			checked?: boolean;
		};
		사과: {
			value?: string;
			checked?: boolean;
		};
		은행나무: {
			value?: string;
			checked?: boolean;
		};
	};
	시간지남력: {
		년: {
			value?: string;
			checked?: boolean;
		};
		월: {
			value?: string;
			checked?: boolean;
		};
		일: {
			value?: string;
			checked?: boolean;
		};
		요일: {
			value?: string;
			checked?: boolean;
		};
		계절: {
			value?: string;
			checked?: boolean;
		};
	};
	장소기억력: {
		국가: {
			value?: string;
			checked?: boolean;
		};
		시: {
			value?: string;
			checked?: boolean;
		};
		장소: {
			value?: string;
			checked?: boolean;
		};
		currentPlace: {
			value?: string;
			checked?: boolean;
		};
		floor: {
			value?: string;
			checked?: boolean;
		};
	};
	기억회상: {
		기차: {
			value?: string;
			checked?: boolean;
		};
		사과: {
			value?: string;
			checked?: boolean;
		};
		은행나무: {
			value?: string;
			checked?: boolean;
		};
	};
	memo: string;
};

export type IntegratedAssessmentKmmseForms = IntegratedAssessmentKmmseRecipientForm &
	IntegratedAssessmentKmmseForm;

export type IntegratedAssessmentKmmseQuestion = {
	key: keyof IntegratedAssessmentKmmseForm;
	label: string;
	questions: {
		key: string;
		question1?: string;
		question2?: string;
	}[];
};

export type IntegratedAssessmentDesireRecipientForm = {
	type: CheckOption[];
	height: string;
	weight: string;
	writerNm: string;
	writeDate: string;
	recipientNm: string;
	recipientBirthDt: string;
	recipientGenderCd: string;
	longTermStartDate: string;
	longTermEndDate: string;
	longTermGradeCd: string;
	longTermNo: string;
};

export type IntegratedAssessmentDesireBasicStatusForm = {
	영양상태?: string;
	식사형태?: string[];
	식사형태기타?: string;
	소화상태?: string[];
	소화상태기타?: string;
	연하상태?: string;
	구강상태?: string;
	소변상태?: string[];
	대변상태?: string[];
	보행?: string;
	보행상태?: string[];
	보행기타?: string;
	치매?: string;
	치매기타?: string;
	치매증상?: string[];
	치매증상기타?: string;
	시력상태?: string;
	청력상태?: string;
	일반상태판단근거?: string;
};

export type IntegratedAssessmentDesireBasicStatusQuestion = {
	key: keyof IntegratedAssessmentDesireBasicStatusForm;
	label: string;
	type: 'radio' | 'checkbox';
	required?: ['generate'];
	answer: {
		label: string;
		value: string;
		options?: {
			label: string;
			value: string;
		}[];
	}[];
};

// 주요 질병 상태
export type IntegratedAssessmentMajorIllnessStatusForm = {
	만성질환: string[];
	만성질환암?: string;
	순환기계: string[];
	순환기계기타?: string;
	신경계: string[];
	신경계기타?: string;
	근골격계: string[];
	근골격계기타?: string;
	정신행동장애: string[];
	정신행동장애기타?: string;
	호흡기계: string[];
	호흡기계기타?: string;
	만성신장질환: string[];
	만성신장질환기타?: string;
	기타질환: string[];
	기타질환기타?: string;
	주요질병상태판단근거: string;
};

export type IntegratedAssessmentMajorIllnessStatusQuestion = {
	key: keyof IntegratedAssessmentMajorIllnessStatusForm;
	label: string;
	etcValue?: string;
	etcKey?: string;
	etcPlaceholder?: string;
	answer: {
		label: string;
		value: string;
		options?: {
			label: string;
			value: string;
		}[];
	}[];
};

export type IntegratedAssessmentDesireRehabilitationStatus = {
	// 운동장애
	운동장애: string[];
	// 관절구축
	관절구축: string[];
	// 보행장애: 지난 3개월 간 낙상 경험
	보행장애낙상경험: string[];
	// 보행장애: 걸음걸이및균형
	보행장애걸음걸이: string[];
	// 재활상태 판단 근거
	재활상태판단근거: string;
};

export type IntegratedAssessmentDesireRehabilitationStatusQuestion = {
	key: keyof IntegratedAssessmentDesireRehabilitationStatus;
	label: string;
	answer: {
		label: string;
		value: string;
		options?: {
			label: string;
			value: string;
		}[];
	}[];
};

// 간호 처치 상태
export type IntegratedAssessmentDesireNursingCareStatusForm = {
	// 호흡
	호흡: string[];
	호흡기타: string;
	// 영양
	영양: string[];
	영양기타: string;
	// 배설
	배설: string[];
	// 상처
	상처: string[];
	상처기타: string;
	// 욕창 단계
	욕창단계: string[];
	// 욕창 부위
	욕창부위: string[];
	욕창부위기타: string;
	// 암 발생 부위
	암발생부위: string[];
	암발생부위기타: string;
	// 일반 통증 부위
	일반통증부위: string[];
	일반통증부위기타: string;
	// 기타 내용 및 판단 근거
	간호처치상태판단근거: string;
};

export type IntegratedAssessmentDesireNursingCareStatusQuestion = {
	key: keyof IntegratedAssessmentDesireNursingCareStatusForm;
	label: string;
	answer: {
		label: string;
		value: string;
		disabled?: boolean;
		options?: {
			label: string;
			value: string;
		}[];
	}[];
};

export type IntegratedAssessmentDesireCommunicationStatusForm = {
	// 의사소통 능력
	의사소통능력: string;
	// 발음 능력
	발음능력: string;
	// 의사소통 판단 근거
	의사소통판단근거: string;
};

export interface IntegratedAssessmentDesireCognitiveStateForm {
	// 기억력 저하
	기억력저하: string[];

	// 지능 및 인지 기능 저하
	지능및인지기능저하: string[];

	// 주의 집중력 저하
	주의집중력저하: string[];

	// 계산력 저하
	계산력저하: string[];

	// 판단력 저하
	판단력저하: string[];

	// 부적절한 옷 입기
	부적절한옷입기: string[];

	// 망상
	망상: string[];

	// 배회
	배회: string[];

	// 환각
	환각: string[];
	환각기타: string;

	// 반복적인 행동
	반복적인행동: string[];

	// 부적절한 행동
	부적절한행동: string[];

	// 폭력적인 행동
	폭력적인행동: string[];

	// 야간 수면장애
	야간수면장애: string[];

	// 불결한 행동
	불결한행동: string[];

	// 식습관 변화
	식습관변화: string[];
	식습관변화기타: string;

	// 음식이 아닌 물건을 섭취
	음식물아닌물건섭취: string[];

	// 불안
	불안: string[];
	불안기타: string;

	// 우울
	우울: string[];

	// 판단 근거
	인지상태판단근거: string;
}

export type IntegratedAssessmentDesireCognitiveStateQuestion = {
	key: keyof IntegratedAssessmentDesireCognitiveStateForm;
	label: string;
	value: string;
	answer: {
		label: string;
		value: string;
	}[];
};

export type IntegratedAssessmentDesireCommunicationStatusQuestion = {
	key: keyof IntegratedAssessmentDesireCommunicationStatusForm;
	label: string;
	required?: ['generate'];
	answer: {
		label: string;
		value: string;
	}[];
};

export type IntegratedAssessmentDesireFamilySupportSystemForm = {
	// 동거 가족
	동거가족: string[];
	동거가족기타: string;
	// 자녀 수
	자녀수: boolean;
	// 자녀 수(아들)
	자녀수아들: string;
	// 자녀 수(딸)
	자녀수딸: string;
	// 주수발자 관계
	주수발자관계: string[];
	주수발자관계기타: string;
	// 주수발자 경제상태
	주수발자경제상태: string[];
	// 주수발자 부양부담
	주수발자부양부담: string[];
	// 진료 병원
	진료병원: string;
	// 진료 병원 전화번호
	진료병원번호: string;
	// 정기진료
	정기진료: boolean;
	// 약 복용(일간)
	약복용일간: string;
	// 약 복용(주간)
	약복용주간: string;
	// 약 복용(갯수)
	약복용갯수: string;
	// 종교 활동
	종교활동: string[];
	종교활동기타: string;
	// 지역 사회 자원
	지역사회자원: string[];
	// 가족 및 지지 체계 기타 내용 및 판단 근거
	가족및지지체계판단근거: string;
};

export type IntegratedAssessmentDesireFamilySupportSystemQuestion = {
	key: keyof IntegratedAssessmentDesireFamilySupportSystemForm;
	label: string;
	answer: {
		label: string;
		value: string;
		disabled?: boolean;
		options?: {
			label: string;
			value: string;
		}[];
	}[];
};

export type IntegratedAssessmentDesireHousingEnvironmentForm = {
	// 난방
	난방: string;
	// 문턱
	문턱: string;
	// 화장실
	화장실: string;
	// 온수 여부
	온수: string;
	// 세면대 여부
	세면대여부: string;
	// 환기
	환기: string;
	// 실내 계단
	실내계단: string;
	// 실외 계단
	실외계단: string;
	// 좌변기
	좌변기: string;
	// 욕조 여부
	욕조여부: string;
	// 주방
	주방: string;
	주거환경상태판단근거: string;
};

export type IntegratedAssessmentDesireHousingEnvironmentQuestion = {
	key: keyof IntegratedAssessmentDesireHousingEnvironmentForm;
	label: string;
	labelDetail?: string;
	required?: ['generate'];
	answer: {
		label: string;
		value: string;
	}[];
};

export type IntegratedAssessmentDesireAssistiveDevicesForm = {
	// 이동변기
	이동변기: boolean;
	// 성인용 보행기
	성인용보행기: boolean;
	// 안전 손잡이
	안전손잡이: boolean;
	// 미끄럼방지용품
	미끄럼방지용품: boolean;
	// 수동 휠체어
	수동휠체어: boolean;
	// 전동 침대
	전동침대: boolean;
	// 수동 침대
	수동침대: boolean;
	// 배회 감지기
	배회감지기: boolean;
	// 실내 경사로
	실내경사로: boolean;
	// 실외 경사로
	실외경사로: boolean;
	// 복지용구 메모
	복지용구판단근거: string;
};

export type IntegratedAssessmentDesireRecipientCaregiverNeedsForm = {
	// 일상생활
	일상생활: string[];
	// 기능회복훈련
	기능회복훈련: string[];
	// 정서지원
	정서지원: string[];
	// 가족수발경감
	가족수발경감: string[];
	// 수급자 및 보호자 개별 욕구 판단 근거
	수급자및보호자개별욕구판단근거: string;
};

export type IntegratedAssessmentDesireRecipientCaregiverNeedsQuestion = {
	key: keyof IntegratedAssessmentDesireRecipientCaregiverNeedsForm;
	label: string;
	answer: {
		label: string;
		value: string;
	}[];
};

// 신체상태
export type IntegratedAssessmentDesirePhysicalConditionForm = {
	// 체위 변경하기
	체위변경하기: boolean;

	// 일어나앉기
	일어나앉기: boolean;

	// 일어서기
	일어서기: boolean;

	// 이동
	이동: boolean;

	// 실내 보행
	실내보행: boolean;

	// 휠체어 이동
	휠체어이동: boolean;

	// 근거리 외출
	근거리외출: boolean;

	// 식사하기
	식사하기: boolean;

	// 세수하기
	세수하기: boolean;

	// 양치질
	양치질: boolean;

	// 옷 벗고 입기
	옷벗고입기: boolean;

	// 화장실 사용
	화장실사용: boolean;

	// 기저귀 갈기
	기저귀갈기: boolean;

	// 목욕하기
	목욕하기: boolean;

	// 몸단장하기
	몸단장하기: boolean;

	// 식사 준비
	식사준비: boolean;

	// 청소하기
	청소하기: boolean;

	// 빨래하기
	빨래하기: boolean;

	// 약 챙겨먹기
	약챙겨먹기: boolean;

	// 전화 사용하기
	전화사용: boolean;

	// 교통수단 이용
	교통수단이용: boolean;

	// 신체상태 판단 근거
	신체상태판단근거?: string;
};

export type IntegratedAssessmentDesirePhysicalConditionQuestion = {
	key: keyof IntegratedAssessmentDesirePhysicalConditionForm;
	label: string;
};

export type IntegratedAssessmentDesireRehabilitationForm = {
	memo?: string;
};

export type IntegratedAssessmentDesireTotalOpinionForm = {
	memo?: string;
};

export type IntegratedAssessmentDesireForms = IntegratedAssessmentDesireRecipientForm &
	IntegratedAssessmentDesireBasicStatusForm &
	IntegratedAssessmentDesirePhysicalConditionForm &
	IntegratedAssessmentDesireRehabilitationStatus &
	IntegratedAssessmentDesireNursingCareStatusForm &
	IntegratedAssessmentDesireCommunicationStatusForm &
	IntegratedAssessmentMajorIllnessStatusForm &
	IntegratedAssessmentDesireTotalOpinionForm &
	IntegratedAssessmentDesireFamilySupportSystemForm &
	IntegratedAssessmentDesireAssistiveDevicesForm &
	IntegratedAssessmentDesireHousingEnvironmentForm &
	IntegratedAssessmentDesireRecipientCaregiverNeedsForm &
	IntegratedAssessmentDesireCognitiveStateForm;

export type RecipientResignForm = {
	retirementDate: Date;
	resignType: CheckOption;
	resignTypeEtc: string;
	serviceChangeDesc: string;
	pcorpSalaryContractCancelYn: boolean;
	wardResignDischargeRequestYn: boolean;
};

export type RecipientScheduleAutomationFormViewType = {
	id?: number;
	serviceKindCd: string;
	automationFeeCd?: string;
	employeeId1?: CheckOption;
	employeeId2?: CheckOption;
	days: string[];
	monthMethod?: 'count' | 'amount' | '';
	monthUseCnt?: string;
	monthAmt?: string;
	time: {
		serviceStartTime: string;
		serviceEndTime: string;
	};
	holidayExceptYn?: boolean;
	monthAmtOverYn?: boolean;
	managerId?: number;
	totalDayYn: CheckOption[];
};
