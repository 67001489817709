import React, { useEffect, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import * as Accordion from '@radix-ui/react-accordion';
import dayjs from 'dayjs';
import { v4 } from 'uuid';

import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRStatus from 'components/base/CRStatus';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import CRSwitch from 'components/base/Selections/CRSwitch';
import EDocNoDialog from 'components/domain/dialog/EdocNoDialog';
import InformationTable from 'components/ui/InformationTable';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import { calculateKoreanAge, rsdnNumberToBirthDay } from 'lib';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { InsuranceDetailDTO } from 'types/api/insurance';
import { InsuranceEditDetailFormType } from 'types/view/insurance';

import { InsuranceState } from '..';
import * as S from './styles';

interface Props {
	item: InsuranceDetailDTO;
	disabled?: boolean;
	rsdnNumber?: string;
	context: UseFormReturn<InsuranceEditDetailFormType, any, undefined>;
}

export default function InsuranceAcquirementView({ item, context, rsdnNumber, disabled }: Props) {
	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN161'],
		},
		commonCodeAdapter,
	);

	const { showDialog } = useDialog();

	const isOver65 = useMemo(
		() =>
			calculateKoreanAge(
				rsdnNumberToBirthDay(rsdnNumber),
				item?.insuranceRequestDetails?.employInsuranceRequestDate,
			) > 64,
		[rsdnNumber, item.insuranceRequestDetails],
	);

	const {
		isEachSelect,
		employInsuranceApproveYn,
		accidentInsuranceApproveYn,
		pensionInsuranceApproveYn,
		healthInsuranceApproveYn,
	} = context.watch();

	const informationTableItem = useMemo(() => {
		const items: InformationTableItemType[][] = [
			[
				{
					type: 'label',
					label: '구분',
					labelStyle: {
						width: '14rem',
						height: '4.1rem',
						verticalAlign: 'middle',
					},
				},
				{
					type: 'label',
					label: '신고 금액',
					labelStyle: {
						width: '14rem',
						textAlign: 'right',
						height: '4.1rem',
						verticalAlign: 'middle',
					},
				},
				{
					type: 'label',
					label: '취득 일자',
					labelStyle: {
						width: '14rem',
						height: '4.1rem',
						verticalAlign: 'middle',
					},
				},
			],
		];
		if (
			item.insuranceRequestDetails?.employInsuranceRequestAmt ||
			item.insuranceRequestDetails?.employInsuranceRequestDate
		) {
			items.push([
				{
					type: 'value',
					value: '고용보험',
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<CRInput.TableInput
							disabled
							prefix=' '
							type='comma'
							value={
								item?.insuranceRequestDetails?.employInsuranceRequestAmt
									? item.insuranceRequestDetails?.employInsuranceRequestAmt?.toLocaleString()
									: '0'
							}
							placeholder='신고 금액 입력'
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
				{
					type: 'value',
					value: (
						<CRInput.DatePicker
							disabled
							type='S'
							value={
								item.insuranceRequestDetails?.employInsuranceRequestDate
									? dayjs(item.insuranceRequestDetails?.employInsuranceRequestDate).toDate()
									: undefined
							}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
			]);
		}

		if (
			item.insuranceRequestDetails?.accidentInsuranceRequestAmt ||
			item.insuranceRequestDetails?.accidentInsuranceRequestDate
		) {
			items.push([
				{
					type: 'value',
					value: '산재보험',
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<CRInput.TableInput
							disabled
							prefix=' '
							type='comma'
							value={
								item?.insuranceRequestDetails?.accidentInsuranceRequestAmt
									? item.insuranceRequestDetails?.accidentInsuranceRequestAmt?.toLocaleString()
									: '0'
							}
							placeholder='신고 금액 입력'
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
				{
					type: 'value',
					value: (
						<CRInput.DatePicker
							disabled
							type='S'
							value={
								item.insuranceRequestDetails?.accidentInsuranceRequestDate
									? dayjs(item.insuranceRequestDetails?.accidentInsuranceRequestDate).toDate()
									: undefined
							}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
			]);
		}
		if (
			item.insuranceRequestDetails?.pensionInsuranceRequestAmt ||
			item.insuranceRequestDetails?.pensionInsuranceRequestDate
		) {
			items.push([
				{
					type: 'value',
					value: '국민연금',
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<CRInput.TableInput
							disabled
							prefix=' '
							type='comma'
							value={
								item?.insuranceRequestDetails?.pensionInsuranceRequestAmt
									? item.insuranceRequestDetails?.pensionInsuranceRequestAmt?.toLocaleString()
									: '0'
							}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
				{
					type: 'value',
					value: (
						<CRInput.DatePicker
							disabled
							type='S'
							value={
								item.insuranceRequestDetails?.pensionInsuranceRequestDate
									? dayjs(item.insuranceRequestDetails?.pensionInsuranceRequestDate).toDate()
									: undefined
							}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
			]);
		}

		if (
			item.insuranceRequestDetails?.healthInsuranceRequestAmt ||
			item.insuranceRequestDetails?.healthInsuranceRequestDate
		) {
			items.push([
				{
					type: 'value',
					value: '건강보험',
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<CRInput.TableInput
							disabled
							prefix=' '
							type='comma'
							value={
								item?.insuranceRequestDetails?.healthInsuranceRequestAmt
									? item.insuranceRequestDetails?.healthInsuranceRequestAmt?.toLocaleString()
									: '0'
							}
							placeholder='신고 금액 입력'
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
				{
					type: 'value',
					value: (
						<CRInput.DatePicker
							disabled
							type='S'
							value={
								item.insuranceRequestDetails?.healthInsuranceRequestDate
									? dayjs(item.insuranceRequestDetails?.healthInsuranceRequestDate).toDate()
									: undefined
							}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
					},
				},
			]);
		}

		return items;
	}, [item]);

	const renderResultSelect = () => {
		const isEmployInsuranceExist =
			item.insuranceRequestDetails?.employInsuranceRequestAmt ||
			item.insuranceRequestDetails?.employInsuranceRequestDate;
		const isAccidentInsuranceExist =
			item.insuranceRequestDetails?.accidentInsuranceRequestAmt ||
			item.insuranceRequestDetails?.accidentInsuranceRequestDate;
		const isPensionInsuranceExist =
			item.insuranceRequestDetails?.pensionInsuranceRequestAmt ||
			item.insuranceRequestDetails?.pensionInsuranceRequestDate;
		const isHealthInsuranceExist =
			item.insuranceRequestDetails?.healthInsuranceRequestAmt ||
			item.insuranceRequestDetails?.healthInsuranceRequestDate;

		const commonOptions = [
			{
				label: '완료',
				value: true,
			},
			{
				label: '반려',
				value: false,
			},
		];

		if (isEachSelect) {
			return (
				<S.ResultSelectContainer>
					{isEmployInsuranceExist && (
						<S.ResultSelectItem>
							<S.ResultLabel>
								고용보험 <S.RequiredMark>*</S.RequiredMark>
							</S.ResultLabel>
							<Controller
								render={({ field: { onChange, value } }) => (
									<CRCheckBoxGroup
										style={{
											width: 'auto',
										}}
										disabled={disabled}
										checkType='single'
										type='radio'
										onChange={onChange}
										appearanceType='button'
										gap={0.8}
										value={value}
										options={commonOptions}
									/>
								)}
								name='employInsuranceApproveYn'
								control={context.control}
							/>
						</S.ResultSelectItem>
					)}
					{isAccidentInsuranceExist && (
						<S.ResultSelectItem>
							<S.ResultLabel>
								산재보험 <S.RequiredMark>*</S.RequiredMark>
							</S.ResultLabel>
							<Controller
								render={({ field: { onChange, value } }) => (
									<CRCheckBoxGroup
										disabled={disabled}
										style={{
											width: 'auto',
										}}
										checkType='single'
										type='radio'
										onChange={onChange}
										appearanceType='button'
										gap={0.8}
										value={value}
										options={commonOptions}
									/>
								)}
								name='accidentInsuranceApproveYn'
								control={context.control}
							/>
						</S.ResultSelectItem>
					)}
					{isPensionInsuranceExist && (
						<S.ResultSelectItem>
							<S.ResultLabel>
								국민연금 <S.RequiredMark>*</S.RequiredMark>
							</S.ResultLabel>
							<Controller
								render={({ field: { onChange, value } }) => (
									<CRCheckBoxGroup
										disabled={disabled}
										style={{
											width: 'auto',
										}}
										checkType='single'
										type='radio'
										onChange={onChange}
										appearanceType='button'
										gap={0.8}
										value={value}
										options={commonOptions}
									/>
								)}
								name='pensionInsuranceApproveYn'
								control={context.control}
							/>
						</S.ResultSelectItem>
					)}
					{isHealthInsuranceExist && (
						<S.ResultSelectItem>
							<S.ResultLabel>
								건강보험 <S.RequiredMark>*</S.RequiredMark>
							</S.ResultLabel>
							<Controller
								render={({ field: { onChange, value } }) => (
									<CRCheckBoxGroup
										disabled={disabled}
										style={{
											width: 'auto',
										}}
										checkType='single'
										type='radio'
										onChange={onChange}
										appearanceType='button'
										gap={0.8}
										value={value}
										options={commonOptions}
									/>
								)}
								name='healthInsuranceApproveYn'
								control={context.control}
							/>
						</S.ResultSelectItem>
					)}
				</S.ResultSelectContainer>
			);
		}
		return (
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInput.Selector
						topOffset={-285}
						disabled={disabled}
						items={commonCodes?.CMN161.filter((item) => item?.value !== InsuranceState.부분완료)}
						currentValue={value}
						onChangeValue={onChange}
						placeholder='결과 선택'
					/>
				)}
				name='insuranceRequestStateCd'
				control={context.control}
			/>
		);
	};

	const renderUnemploymentBenefit = () =>
		isOver65 &&
		item?.insuranceRequestDetails?.employInsuranceRequestAmt &&
		item?.insuranceRequestDetails?.employInsuranceRequestDate ? (
			<CRInputLabel label='실업급여 납부' isRequired type='left-sub' betweenGap='2.4rem'>
				<CRCheckBoxGroup
					style={{
						width: 'auto',
					}}
					disabled
					checkType='single'
					type='radio'
					appearanceType='button'
					gap={0.8}
					value={[
						{
							label: '',
							value: !!item.unemploymentBenefitPreconditionFullYn,
						},
					]}
					options={[
						{
							label: '납부하지 않음',
							value: false,
						},
						{
							label: '납부함',
							value: true,
						},
					]}
				/>
			</CRInputLabel>
		) : null;

	const renderCancelDesc = () => {
		if (!item || item.insuranceRequestState.id === InsuranceState.완료) return null;
		const insuranceState = context.watch('insuranceRequestStateCd')?.value as InsuranceState;

		const isExistDenyInsurance = [
			employInsuranceApproveYn?.[0]?.value,
			accidentInsuranceApproveYn?.[0]?.value,
			pensionInsuranceApproveYn?.[0]?.value,
			healthInsuranceApproveYn?.[0]?.value,
		]
			.filter((item) => item !== undefined)
			.some((item) => !item);

		return (
			((isEachSelect && isExistDenyInsurance) ||
				[InsuranceState.반려됨, InsuranceState.철회].includes(insuranceState)) && (
				<CRInputLabel label='반려 사유' type='left-sub' isRequired showOverflow betweenGap='2.4rem'>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.Default
								disabled={disabled}
								value={item?.cancelDesc || value}
								onChange={onChange}
								placeholder='반려 사유 입력'
							/>
						)}
						name='cancelDesc'
						control={context.control}
					/>
				</CRInputLabel>
			)
		);
	};

	const renderEDoc = () => {
		if (!item?.edocInfo?.edocNo) return null;

		const handleClickEDocPreview = (edocNo: string) => {
			if (!edocNo) return;
			showDialog(() => <EDocNoDialog dialogType='M' edocNo={edocNo} />);
		};

		const eDocInformationTableItems = useMemo(() => {
			const options = [
				{
					key: item.edocInfo.signState?.id || '',
					label: item.edocInfo.signState?.text || '',
					color: item.edocInfo.signState?.color || '',
				},
			];
			const items: InformationTableItemType[][] = [
				[
					{
						type: 'label',
						label: '서명상태',
						labelStyle: {
							height: '3.3rem',
							verticalAlign: 'middle',
						},
					},
					{
						type: 'label',
						label: '파일',
						labelStyle: {
							height: '3.3rem',
							verticalAlign: 'middle',
						},
					},
					{
						type: 'label',
						label: '',
						labelStyle: {
							height: '3.3rem',
							verticalAlign: 'middle',
						},
					},
				],
				[
					{
						type: 'value',
						value: <CRStatus options={options}>{item.edocInfo?.signState.id}</CRStatus>,
						valueStyle: {
							verticalAlign: 'middle',
						},
					},
					{
						type: 'value',
						value: item.edocInfo.title,
						valueStyle: {
							verticalAlign: 'middle',
							height: '3.3rem',
						},
					},
					{
						type: 'value',
						value: (
							<CRButton.Default
								onClick={() => handleClickEDocPreview(item.edocInfo.edocNo)}
								size='xSmall'
								type='outlined'
								palette='gray'>
								미리보기
							</CRButton.Default>
						),
						valueStyle: {
							verticalAlign: 'middle',
							height: '3.3rem',
						},
					},
				],
			];

			return items;
		}, [item]);

		return (
			<CRInputLabel label='발급 서류' type='left-sub' showOverflow betweenGap='2.4rem'>
				<InformationTable roundBorder items={eDocInformationTableItems} />
			</CRInputLabel>
		);
	};

	useEffect(() => {
		const defaultForm: InsuranceEditDetailFormType = {
			insuranceRequestStateCd: item.insuranceRequestState
				? {
						label: item.insuranceRequestState.text,
						value: item.insuranceRequestState.id,
					}
				: undefined,
			responseDesc: item?.responseDesc || '',
			insuranceRequestDetails: item.insuranceRequestDetails || undefined,
		};

		if (item.insuranceRequestState?.id === InsuranceState.부분완료) {
			defaultForm.employInsuranceApproveYn = [
				{
					label: '',
					value: item.insuranceRequestDetails.employInsuranceApproveYn,
				},
			];
			defaultForm.accidentInsuranceApproveYn = [
				{
					label: '',
					value: item.insuranceRequestDetails.accidentInsuranceApproveYn,
				},
			];
			defaultForm.healthInsuranceApproveYn = [
				{
					label: '',
					value: item.insuranceRequestDetails.healthInsuranceApproveYn,
				},
			];
			defaultForm.pensionInsuranceApproveYn = [
				{
					label: '',
					value: item.insuranceRequestDetails.pensionInsuranceApproveYn,
				},
			];
			defaultForm.isEachSelect = true;
		}
		context.reset(defaultForm);
	}, [item]);

	return (
		<>
			<Accordion.Item value='신고 내용' asChild>
				<TaskAccordion.Item>
					<Accordion.Header asChild>
						<Accordion.Trigger asChild>
							<TaskAccordion.Trigger>신고 내용</TaskAccordion.Trigger>
						</Accordion.Trigger>
					</Accordion.Header>
					<Accordion.Content asChild>
						<TaskAccordion.Content>
							<S.FormContainer>
								<CRInputLabel
									label='신고 선택'
									type='left-sub'
									isRequired
									showOverflow
									betweenGap='2.4rem'>
									<InformationTable roundBorder items={informationTableItem} />
								</CRInputLabel>
								{renderUnemploymentBenefit()}
								<CRInputLabel
									label='주 소정근로시간'
									type='left-sub'
									isRequired
									showOverflow
									betweenGap='2.4rem'>
									<CRInput.Default
										disabled
										suffix='시간'
										type='number'
										value={item.weekPerWorkHourCnt ? String(item.weekPerWorkHourCnt) : ''}
									/>
								</CRInputLabel>
								<CRInputLabel label='요청 사항' type='left-sub' showOverflow betweenGap='2.4rem'>
									<CRInput.TextArea
										disabled
										numberOfLines={4}
										value={item.requestDesc || ''}
										placeholder='요청 사항 입력'
										fixedHeight
									/>
								</CRInputLabel>
								{renderEDoc()}
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInputLabel
											label='업무 담당자 답변'
											type='left-sub'
											showOverflow
											betweenGap='2.4rem'>
											<CRInput.TextArea
												disabled={disabled}
												numberOfLines={4}
												onChange={onChange}
												value={disabled ? item?.responseDesc : value}
												placeholder='업무 담당자 답변 입력'
												fixedHeight
											/>
										</CRInputLabel>
									)}
									name='responseDesc'
									control={context.control}
								/>
								<div
									style={{
										width: '57.2rem',
									}}>
									{item.attachFile?.fileDetails &&
										item.attachFile?.fileDetails?.some((item) => !item.fileDeleteYn) && (
											<CRInputLabel
												label='파일 첨부'
												type='left-sub'
												showOverflow
												betweenGap='2.4rem'>
												<CRInput.FileUploader
													disabled
													files={item.attachFile?.fileDetails}
													placeholder='파일 업로드'
													type='multiple'
												/>
											</CRInputLabel>
										)}
								</div>
							</S.FormContainer>
						</TaskAccordion.Content>
					</Accordion.Content>
				</TaskAccordion.Item>
			</Accordion.Item>
			<Accordion.Item value='피부양자' asChild>
				<TaskAccordion.Item>
					<Accordion.Header asChild>
						<Accordion.Trigger asChild>
							<TaskAccordion.Trigger>피부양자</TaskAccordion.Trigger>
						</Accordion.Trigger>
					</Accordion.Header>
					<Accordion.Content asChild>
						<TaskAccordion.Content>
							<S.TableContainer>
								{item?.dependents?.map((dependent, index) => (
									<S.TableItem key={v4()}>
										<S.ItemHeader>
											{`피부양자 정보 ${index + 1 < 10 ? `0${index + 1}` : index + 1}`}
										</S.ItemHeader>
										<S.Table>
											<S.TableRow>
												<S.TableLabelColumn>
													이름 <S.RequiredMark>*</S.RequiredMark>
												</S.TableLabelColumn>
												<S.TableValueColumn>
													<CRInput.TableInput
														disabled
														value={dependent.dependentNm}
														placeholder='이름 입력'
													/>
												</S.TableValueColumn>
												<S.TableLabelColumn>
													등록일 <S.RequiredMark>*</S.RequiredMark>
												</S.TableLabelColumn>
												<S.TableValueColumn>
													<CRInput.DatePicker
														disabled
														placeholder='등록일 선택'
														type='S'
														containerStyle={{ width: '100%' }}
														value={
															dependent.dependentRegistDt
																? dayjs(dependent.dependentRegistDt).toDate()
																: undefined
														}
													/>
												</S.TableValueColumn>
											</S.TableRow>
											<S.TableRow>
												<S.TableLabelColumn>
													관계 <S.RequiredMark>*</S.RequiredMark>
												</S.TableLabelColumn>
												<S.TableValueColumn>
													<CRInput.Selector
														disabled
														type='small'
														items={[
															{
																label: dependent.dependentRelCd,
																value: dependent.dependentRelCd,
															},
														]}
														currentValue={{
															label: dependent.dependentRelCd,
															value: dependent.dependentRelCd,
														}}
													/>
												</S.TableValueColumn>
												<S.TableLabelColumn>
													국적 <S.RequiredMark>*</S.RequiredMark>
												</S.TableLabelColumn>
												<S.TableValueColumn>
													<CRCheckBoxGroup
														disabled
														checkType='single'
														type='radio'
														gap={0.8}
														value={[
															{
																label: dependent.dependentCountryCd ? '해외' : '국내',
																value: !!dependent.dependentCountryCd,
															},
														]}
														options={[
															{
																label: '국내',
																value: false,
															},
															{
																label: '해외',
																value: true,
															},
														]}
													/>
												</S.TableValueColumn>
											</S.TableRow>
											<S.TableRow>
												<S.TableLabelColumn>
													주민등록번호 <S.RequiredMark>*</S.RequiredMark>
												</S.TableLabelColumn>
												<S.TableValueColumn>
													<CRInput.TableInput
														disabled
														maxLength={13}
														type='number'
														value={dependent.dependentRsdnNo}
													/>
												</S.TableValueColumn>
												{dependent?.dependentCountryCd ? (
													<>
														<S.TableLabelColumn>
															국가 <S.RequiredMark>*</S.RequiredMark>
														</S.TableLabelColumn>
														<S.TableValueColumn>
															<CRInput.Selector
																disabled
																type='small'
																items={[
																	{
																		label: dependent.dependentCountryCd,
																		value: dependent.dependentCountryCd,
																	},
																]}
																currentValue={{
																	label: dependent.dependentCountryCd,
																	value: dependent.dependentCountryCd,
																}}
															/>
														</S.TableValueColumn>
													</>
												) : (
													<S.TableLabelColumn colSpan={2} />
												)}
											</S.TableRow>
											{dependent?.dependentCountryCd && (
												<S.TableRow>
													<S.TableLabelColumn colSpan={2} />
													<S.TableLabelColumn>
														체류 기간 <S.RequiredMark>*</S.RequiredMark>
													</S.TableLabelColumn>
													<S.TableValueColumn
														style={{
															display: 'flex',
															alignItems: 'center',
															gap: '0.4rem',
														}}>
														<CRInput.DatePicker
															disabled
															type='S'
															containerStyle={{ flex: 1 }}
															value={
																dependent.dependentStayStartDt
																	? dayjs(dependent.dependentStayStartDt).toDate()
																	: undefined
															}
														/>
														<div style={{}}>-</div>
														<CRInput.DatePicker
															disabled
															type='S'
															containerStyle={{ flex: 1 }}
															value={
																dependent.dependentStayEndDt
																	? dayjs(dependent.dependentStayEndDt).toDate()
																	: undefined
															}
														/>
													</S.TableValueColumn>
												</S.TableRow>
											)}
										</S.Table>
									</S.TableItem>
								))}
							</S.TableContainer>
						</TaskAccordion.Content>
					</Accordion.Content>
				</TaskAccordion.Item>
			</Accordion.Item>
			<Accordion.Item value='4대보험 신고 결과' asChild>
				<TaskAccordion.Item>
					<Accordion.Header asChild>
						<Accordion.Trigger asChild>
							<TaskAccordion.Trigger>4대보험 신고 결과</TaskAccordion.Trigger>
						</Accordion.Trigger>
					</Accordion.Header>
					<Accordion.Content asChild>
						<TaskAccordion.Content>
							<S.FormContainer>
								<CRInputLabel
									label='보험별 선택 여부'
									type='left-sub'
									isRequired
									showOverflow
									betweenGap='2.4rem'>
									<Controller
										render={({ field: { onChange, value } }) => (
											<S.SwitchContainer>
												<CRSwitch
													disabled={disabled}
													onChange={onChange}
													value={value}
													type='icon'
												/>
											</S.SwitchContainer>
										)}
										name='isEachSelect'
										control={context.control}
									/>
								</CRInputLabel>
								<CRInputLabel
									label='결과 선택'
									type='left-sub'
									isRequired
									showOverflow
									betweenGap='2.4rem'>
									{renderResultSelect()}
								</CRInputLabel>
								{renderCancelDesc()}
							</S.FormContainer>
						</TaskAccordion.Content>
					</Accordion.Content>
				</TaskAccordion.Item>
			</Accordion.Item>
		</>
	);
}
