import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { CRText } from 'components/base/CRText';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { ServiceTypeCd } from 'components/domain/table/RecordingSheetTable/serviceEdocParam';
import { ServiceKindCds } from 'pages/dashboard/SchedulePage/MonitoringDetailPage';
import { MonitoringDetailItemDTO } from 'types/api';
import { MonitoringDetailForm } from 'types/view/monitoring';

import * as S from './styles';

interface Props {
	isNotTaggedRecordingSheet: boolean;
	monitoringDetailItem?: MonitoringDetailItemDTO | null;
	isConfirmed: boolean;
	control: Control<MonitoringDetailForm, any>;
}

export function SpecialDescCheck({
	isNotTaggedRecordingSheet,
	monitoringDetailItem,
	isConfirmed,
	control,
}: Props) {
	const renderSpecialDescConfirm = (value?: boolean) => {
		if (value === undefined) return '-';
		return (
			<CRText
				text={value ? '작성 완료' : '미작성'}
				typography='label'
				color={value ? 'gray00' : 'primary60'}
			/>
		);
	};
	return (
		<S.CheckGroupContainer>
			<S.ContentTitle>
				<CRText text='특이사항 확인' color='gray10' typography='bodyB' />
			</S.ContentTitle>
			<S.Table>
				<S.TableRow>
					<S.TableLabelColumn>주1회 상태변화기록</S.TableLabelColumn>
					<S.TableValueColumn
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}>
						{renderSpecialDescConfirm(monitoringDetailItem?.stateChangeRecordWriteYn)}
						{monitoringDetailItem?.stateChangeRecordingSheetExpectNeedYn && (
							<Controller
								render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
									<CRCheckBoxGroup
										disabled={isConfirmed}
										checkType='multiple'
										onChange={onChange}
										value={value}
										type='checkbox'
										gap={0.8}
										options={[
											{
												label: '기록지 반영 예정',
												value: true,
											},
										]}
									/>
								)}
								name='stateChangeRecordingSheetExpectYn'
								control={control}
							/>
						)}
					</S.TableValueColumn>
				</S.TableRow>
				{monitoringDetailItem?.serviceKindCd === ServiceKindCds.방문요양 && (
					<S.TableRow>
						<S.TableLabelColumn>조조/야간/공휴일 이용 사유</S.TableLabelColumn>
						<S.TableValueColumn
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}>
							{renderSpecialDescConfirm(monitoringDetailItem?.specialHoursWriteYn)}
							{isNotTaggedRecordingSheet && monitoringDetailItem?.specialHoursWriteYn === false && (
								<Controller
									render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
										<CRCheckBoxGroup
											disabled={isConfirmed}
											checkType='multiple'
											onChange={onChange}
											value={value}
											type='checkbox'
											gap={0.8}
											options={[
												{
													label: '기록지 반영 예정',
													value: true,
												},
											]}
										/>
									)}
									name='specialHoursRecordingSheetExpectYn'
									control={control}
								/>
							)}
						</S.TableValueColumn>
					</S.TableRow>
				)}
				{monitoringDetailItem?.serviceKindCd === ServiceKindCds.인지요양 && (
					<S.TableRow>
						<S.TableLabelColumn>인지활동 특이사항</S.TableLabelColumn>
						<S.TableValueColumn
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}>
							{renderSpecialDescConfirm(monitoringDetailItem?.cognitiveProgramWriteYn)}
							{isNotTaggedRecordingSheet &&
								monitoringDetailItem?.cognitiveProgramWriteYn === false && (
									<Controller
										render={({
											field: { onChange, onBlur, value, ref },
											formState: { errors },
										}) => (
											<CRCheckBoxGroup
												disabled={isConfirmed}
												checkType='multiple'
												onChange={onChange}
												value={value}
												type='checkbox'
												gap={0.8}
												options={[
													{
														label: '기록지 반영 예정',
														value: true,
													},
												]}
											/>
										)}
										name='cognitiveProgramRecordingSheetExpectYn'
										control={control}
									/>
								)}
						</S.TableValueColumn>
					</S.TableRow>
				)}
				{monitoringDetailItem?.serviceTypeCd === ServiceTypeCd.방문목욕 && (
					<S.TableRow>
						<S.TableLabelColumn>목욕 주1회 초과 이용 사유</S.TableLabelColumn>
						<S.TableValueColumn
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}>
							{renderSpecialDescConfirm(monitoringDetailItem?.biweeklyBathWriteYn)}
							{monitoringDetailItem?.biweeklyBathRecordingSheetExpectNeedYn && (
								<Controller
									render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
										<CRCheckBoxGroup
											disabled={isConfirmed}
											checkType='multiple'
											onChange={onChange}
											value={value}
											type='checkbox'
											gap={0.8}
											options={[
												{
													label: '기록지 반영 예정',
													value: true,
												},
											]}
										/>
									)}
									name='biweeklyBathRecordingSheetExpectYn'
									control={control}
								/>
							)}
						</S.TableValueColumn>
					</S.TableRow>
				)}
			</S.Table>
		</S.CheckGroupContainer>
	);
}
