import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import CRBanner from 'components/base/CRBanner';
import CRButton from 'components/base/CRButton';
import CRTable from 'components/base/CRTable';
import MonitoringSideModal from 'components/domain/sideModal/MonitoringSideModal';
import { monitoringArgs } from 'components/domain/table/DeprecatedMonitoringTable/mock';
import MonitoringDetailTable from 'components/domain/table/MonitoringDetailTable';
import useFullScreen from 'lib/hook/util/useFullScreen';
import useSideModal from 'lib/hook/util/useSideModal';
import { MonitoringDetailItem, MonitoringItem } from 'types/view/monitoring';

import { bannerMessage } from './mock';
import * as S from './styles';

function MonitoringDetailPage(): React.ReactElement {
	const param = useParams<{ id: string; detailId: string }>();
	const { showSideModal } = useSideModal();
	const [detailItem, setDetailItem] = useState<MonitoringItem>();
	const [checkStatus, setCheckStatus] = useState<string[]>([]);
	const renderTitle = () => {
		if (!detailItem) return null;
		const title = `${detailItem.recipient} / ${detailItem.EmployeeName}`;
		const diff = dayjs(detailItem.endDate).diff(dayjs(detailItem.startDate), 'day');
		const duration = `시작일: ${detailItem.startDate} ~ 마감일: ${detailItem.endDate} (D-${diff})`;
		return (
			<S.HeaderLeft>
				<S.Title>{title}</S.Title>
				<S.Duration>{duration}</S.Duration>
			</S.HeaderLeft>
		);
	};

	const handleClickCheck = (scheduleId: string) => {
		const exist = checkStatus.find((id) => id === scheduleId);
		if (exist) {
			setCheckStatus(checkStatus.filter((id) => id !== scheduleId));
		} else {
			setCheckStatus(checkStatus.concat(scheduleId));
		}
	};

	useFullScreen();

	const handleClickRow = (item: MonitoringDetailItem) =>
		showSideModal(<MonitoringSideModal item={item} />);

	useEffect(() => {
		const currentItem = monitoringArgs.items.find(
			(item) => item.id === param.detailId,
		) as MonitoringItem;
		if (currentItem) {
			setDetailItem(currentItem);
		}
	}, [param]);

	return (
		<S.Container>
			<CRTable.BackBlind
				stickyHeader={
					<S.Header>
						<S.HeaderTop>
							{renderTitle()}
							<S.HeaderRight>
								<CRButton.Default disabled={false}>처리 완료</CRButton.Default>
							</S.HeaderRight>
						</S.HeaderTop>
						<CRBanner title={bannerMessage[0]} type='warning' />
					</S.Header>
				}>
				<MonitoringDetailTable
					items={detailItem?.items ?? []}
					checkStatus={checkStatus}
					onClickRow={handleClickRow}
					onClickCheck={handleClickCheck}
					checkCount={checkStatus?.length}
				/>
			</CRTable.BackBlind>
		</S.Container>
	);
}

export default MonitoringDetailPage;
