import React, { useMemo } from 'react';

import RouterPath from 'common/router';
import CRTab from 'components/base/CRTab';

import { CenterFinanceInformationTab } from './CenterFinanceInformationTab';

function CenterFinanceInformationPage(): React.ReactElement {
	const tab = useMemo(() => RouterPath.myCenterOperate().document, []);

	return (
		<CRTab.Default
			defaultActiveKey={tab.key}
			items={[
				{
					label: tab.label,
					key: tab.key,
					children: <CenterFinanceInformationTab />,
				},
			]}
			breadCrumb='재무 정보'
		/>
	);
}

export default CenterFinanceInformationPage;
