import React from 'react';

import dayjs from 'dayjs';

import CRDialog from 'components/base/CRDialog';
import CRInputLabel from 'components/base/CRInputLabel';
import CRTab from 'components/base/CRTab';
import InformationTable from 'components/ui/InformationTable';
import { useFullTimeEmployeeAnnualTOHistories } from 'lib/hook/react-query';
import { AnnualTOHistoryDTO } from 'types/dto/schedule';

import { displayTOTime } from '../../../../lib';
import AnnualTODeductionTab from './AnnualTODeductionTab';
import AnnualTOOccurrenceTab from './AnnualTOOccurrenceTab';
import AnnualTOUsageTab from './AnnualTOUsageTab';
import YearsOfServiceTab from './YearsOfServiceTab';
import * as S from './styles';

interface Props {
	item: AnnualTOHistoryDTO;
	hideDialog: () => void;
}

export default function AnnualTODetailDialog({ item, hideDialog }: Props): React.ReactElement {
	const { data: annualTOHistories } = useFullTimeEmployeeAnnualTOHistories({
		centerId: item?.centerId,
		employeeId: item?.employeeId,
		memberId: item?.memberId,
	});
	return (
		<CRDialog
			onClickClose={hideDialog}
			type='M'
			title='연차 이력'
			body={
				<S.Container>
					<CRInputLabel label='기본 정보'>
						<InformationTable
							items={[
								[
									{
										label: '이름',
										value: item.korMemberNm,
									},
									{
										label: '직무',
										value: item.dutyNm,
									},
									{
										label: '입사일',
										value: dayjs(item.joinDate).format('YYYY.MM.DD'),
									},
								],
								[
									{
										label: '근무년수',
										value: item.workYearCntNm,
									},
									{
										label: '연차 발생일',
										value: dayjs(item.annualStates[0].annualStartDate).format('YYYY.MM.DD'),
									},
									{
										label: '소멸 예정일',
										value: dayjs(item.annualStates[0].annualEndDate).format('YYYY.MM.DD'),
									},
								],
								[
									{
										label: '사용 연차',
										value: displayTOTime(item.annualStates[0].annualUseDayCnt),
									},
									{
										label: '잔여 연차',
										value: displayTOTime(item.annualStates[0].annualRemainDayCnt),
									},
									{
										type: 'labelValueNull',
									},
								],
							]}
						/>
					</CRInputLabel>
					<CRTab.Sub
						defaultActiveKey='연차 사용'
						items={[
							{
								label: '연차 사용',
								key: '연차 사용',
								children: <AnnualTOUsageTab items={annualTOHistories?.annualUses} />,
							},
							{
								label: '연차 차감',
								key: '연차 차감',
								children: <AnnualTODeductionTab items={annualTOHistories?.remainAnnualAdjusts} />,
							},
							{
								label: '발생 연차',
								key: '발생 연차',
								children: (
									<AnnualTOOccurrenceTab items={annualTOHistories?.annualGenerateAdjusts} />
								),
							},
							{
								label: '근무년수',
								key: '근무년수',
								children: <YearsOfServiceTab items={annualTOHistories?.workYearCntAdjusts} />,
							},
						]}
					/>
				</S.Container>
			}
		/>
	);
}
