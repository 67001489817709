import React, { useMemo } from 'react';

import CRButton from 'components/base/CRButton';
import { CRText } from 'components/base/CRText';
import RecipientAddHistoryOfOwnExpenseDialog from 'components/domain/dialog/RecipientAddHistoryOfOwnExpenseDialog';
import RecipientLongTermGradeHistoryDialog from 'components/domain/dialog/RecipientLongTermGradeHistoryDialog';
import RecipientOwnExpenseHistoryDialog from 'components/domain/dialog/RecipientOwnExpenseHistoryDialog';
import { calculateLongTermGradeExpireLeftDate } from 'lib';
import { useCommonCodes } from 'lib/hook/react-query';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import useDialog from 'lib/hook/util/useDialog';

import { commonCodeAdapter } from '../../../../lib/adapter/common';
import * as S from './styles';

function RecipientLongTermFormView(): React.ReactElement {
	const dialog = useDialog();
	const { data = {} } = useCommonCodes({ comCdGroupNms: ['CMN164'] }, commonCodeAdapter);
	const { currentRecipient, currentRecipientStatus, currentRecipientLongTermCertification } =
		useRecipientPage();

	const handleClickHistoryOfLongTermGrade = () => {
		dialog.showDialog(({ hideDialog }) => (
			<RecipientLongTermGradeHistoryDialog
				currentRecipient={currentRecipient}
				onClickClose={hideDialog}
			/>
		));
	};

	const handleClickAddHistoryOfOwnExpense = () => {
		dialog.showDialog(({ hideDialog }) => (
			<RecipientAddHistoryOfOwnExpenseDialog
				currentRecipient={currentRecipient}
				onClickClose={hideDialog}
			/>
		));
	};

	const handleClickHistoryOfOwnExpense = () => {
		dialog.showDialog(({ hideDialog }) => (
			<RecipientOwnExpenseHistoryDialog
				currentRecipient={currentRecipient}
				onClickClose={hideDialog}
				onClickAdd={handleClickAddHistoryOfOwnExpense}
			/>
		));
	};

	const isResign = currentRecipientStatus?.status === 'CMN058.30';
	const [longtermPeriodStart, longtermPeriodEnd] =
		currentRecipientLongTermCertification?.longTermGradePeriod?.split(' ~ ') || [
			undefined,
			undefined,
		];

	const longtermLeftDays = longtermPeriodEnd
		? `(${calculateLongTermGradeExpireLeftDate(longtermPeriodEnd)}일 남음)`
		: '-';

	const longTermNo = useMemo(
		() =>
			currentRecipientLongTermCertification?.longTermNo
				? `${currentRecipientLongTermCertification.longTermNo}${
						currentRecipientLongTermCertification?.longTermMajorChangeNo
							? `-${currentRecipientLongTermCertification?.longTermMajorChangeNo}`
							: ''
					}${
						currentRecipientLongTermCertification?.longTermMinorChangeNo
							? `-${currentRecipientLongTermCertification?.longTermMinorChangeNo}`
							: ''
					}`
				: '-',
		[currentRecipientLongTermCertification],
	);

	return (
		<S.Container>
			<S.Label>장기요양 인정</S.Label>
			<S.Table $isDisabled={isResign}>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '10.4rem' }}>인정번호</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '24rem' }}>{longTermNo}</S.TableValueColumn>
					<S.TableLabelColumn style={{ width: '10.4rem', verticalAlign: 'top' }} rowSpan={3}>
						복지용구
					</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '24rem' }} rowSpan={3}>
						<S.WelfareContainer>
							{(data.CMN164 ?? []).map((item) => (
								<S.TagContainer
									$selected={currentRecipientLongTermCertification.welfareEquipment.includes(
										item.value,
									)}
									$disabled={isResign}
									key={item.value}>
									{item.label}
								</S.TagContainer>
							))}
						</S.WelfareContainer>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '10.4rem', verticalAlign: 'top' }}>
						장기요양 등급
					</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '24rem' }}>
						<S.ContentContainer>
							<S.GradeContentContainer>
								<S.GradeText>
									{currentRecipientLongTermCertification?.longTermGrade || '-'}
								</S.GradeText>
								<CRText typography='label' color='primary60'>
									{longtermLeftDays}
								</CRText>
								<S.GradeText>
									{currentRecipientLongTermCertification?.longTermGradePeriod || '-'}
								</S.GradeText>
							</S.GradeContentContainer>
							<CRButton.Default
								disabled={isResign}
								type='outlined'
								palette='gray'
								size='small'
								onClick={handleClickHistoryOfLongTermGrade}>
								변경/이력
							</CRButton.Default>
						</S.ContentContainer>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '10.4rem' }}>본인부담율</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '24rem' }}>
						<S.OwnExpenseContentContainer>
							<S.GradeContentContainer>
								{currentRecipientLongTermCertification?.ownExpenseRate || '-'}
							</S.GradeContentContainer>
							<CRButton.Default
								disabled={isResign}
								type='outlined'
								palette='gray'
								size='small'
								onClick={handleClickHistoryOfOwnExpense}>
								변경/이력
							</CRButton.Default>
						</S.OwnExpenseContentContainer>
					</S.TableValueColumn>
				</S.TableRow>
			</S.Table>
		</S.Container>
	);
}

export default RecipientLongTermFormView;
