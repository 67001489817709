import styled, { css } from 'styled-components';

import Assets from 'assets';
import { ButtonPalette } from 'components/base/CRButton/type';

import { CheckBoxAppearance, CheckBoxType } from '../type';

const defaultButton = css`
	height: 5.6rem;
	min-height: 5.6rem;
	display: flex;
	align-items: center;
	padding: 0 2.4rem 0 1.6rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: 0.8rem;
	cursor: pointer;
`;

export const Container = styled.label<{
	$appearanceType: CheckBoxAppearance;
	$isActive?: boolean;
	$isDisabled?: boolean;
	$onlyCheck?: boolean;
}>`
	${(props) => props.theme.typography.body};
	display: flex;
	align-items: center;
	height: fit-content;
	user-select: none;
	gap: 0.4rem;
	white-space: nowrap;
	${(props) =>
		props.$appearanceType === 'button' &&
		css`
			${defaultButton}
			background: ${(props) => props.theme.colors.gray100};
			flex: 1;
		`};

	${(props) =>
		props.$appearanceType === 'button' &&
		props.$isActive &&
		css`
			border: 0.1rem solid ${(props) => props.theme.colors.primary90};
			background: ${(props) => props.theme.colors.primary95};
		`};

	${(props) =>
		props.$appearanceType === 'button' &&
		props.$isDisabled &&
		css`
			${defaultButton}
			background: ${(props) => props.theme.colors.gray95};
			color: ${(props) => props.theme.colors.gray60};
			cursor: no-drop;
		`};

	${(props) =>
		props.$appearanceType === 'default' &&
		props.$isDisabled &&
		css`
			color: ${(props) => props.theme.colors.gray60};
		`};

	${(props) =>
		props.$onlyCheck &&
		css`
			justify-content: center;
		`};
`;

export const InputContainer = styled.div<{
	$isActive?: boolean;
	$isDisabled?: boolean;
	$palette?: ButtonPalette;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 2.5rem;
	border-radius: 50%;

	&:hover {
		background: ${(props) =>
			/* eslint-disable */
			props.$isDisabled
				? 'transparent'
				: props.$isActive
					? props.$palette === 'primary'
						? props.theme.colors.primary90
						: props.theme.colors.gray95
					: props.theme.colors.gray95};
	}

	&:disabled {
		color: ${(props) => props.theme.colors.primary60};
	}
`;

export const Label = styled.div<{ $isDisabled?: boolean }>`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray10};
	display: flex;
	align-items: center;
	width: 100%;

	${(props) =>
		props.$isDisabled &&
		css`
			color: ${(props) => props.theme.colors.gray60};
		`};
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
`;

export const CRInput = styled.input<{ $checkBoxType: CheckBoxType; $palette?: ButtonPalette }>`
	appearance: none;
	width: 2.4rem;
	height: 2.4rem;
	margin: 0;
	padding: 0;
	white-space: nowrap;
	${(props) => props.theme.typography.body};

	border-color: transparent;

	background-image: ${(props) =>
		`url(${props.$checkBoxType === 'radio' ? Assets.icon.checkRadio : Assets.icon.checkSquare})`};
	background-size: 100% 100%;
	background-repeat: no-repeat;

	cursor: pointer;

	&:checked {
		background-image: ${(props) =>
			`url(${
				props.$checkBoxType === 'checkbox'
					? Assets.icon.checkSquareFilled
					: props.$checkBoxType === 'radio'
						? Assets.icon.checkRadioFilled
						: Assets.icon.indeterminateCheckBox
			})`};
		${(props) =>
			props.$palette === 'primary' ? props.theme.filters.primary60 : props.theme.filters.gray10};
		/* filter: ${(props) =>
			props.$palette === 'primary' ? props.theme.filters.primary60 : props.theme.filters.gray10}; */
	}

	&:disabled {
		filter: brightness(0) saturate(100%) invert(61%) sepia(4%) saturate(167%) hue-rotate(149deg)
			brightness(93%) contrast(96%);
		cursor: no-drop;
	}
`;
