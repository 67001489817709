import React from 'react';

import dayjs from 'dayjs';

import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import CRRsdNumber from 'components/base/CRRsdNumber';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import CheckFullTimeEmployeeCriminalRecord from 'components/domain/dialog/CheckFullTimeEmployeeCriminalRecord';
import FullTimeEmployeeHistoryOfDutyDialog from 'components/domain/dialog/FullTimeEmployeeHistoryOfDutyDialog';
import { displayBirthDay, displayPhoneNumber } from 'lib';
import useFullTimeEmployeePage from 'lib/hook/full-time-employee/useFullTimeEmployeePage';
import useDialog from 'lib/hook/util/useDialog';
import { FullTimeEmployeeDetailInfoDTO } from 'types/dto/full-time-employee';

import { useHasPermission } from '../../../../lib/hook/util/useHasPermission';
import { CRText } from '../../../base/CRText';
import FullTimeEmployeeHealthCheckupHistoryDialog from '../../dialog/FullTimeEmployeeHealthCheckupHistoryDialog';
import * as S from './styles';

interface Props {
	item?: FullTimeEmployeeDetailInfoDTO | null;
	options: {
		workState: CheckOption[];
		workForm: CheckOption[];
		duty: CheckOption[];
		partType: CheckOption[];
		disability: CheckOption[];
	};
	onChangeMode: () => void;
}

export default function FullTimeEmployeeBasicInfoView({
	item,
	options,
	onChangeMode,
}: Props): React.ReactElement {
	const { currentEmployee, employeeDetailData } = useFullTimeEmployeePage();
	const { showDialog } = useDialog();
	const hasPermission = useHasPermission('센터장');
	const isResign = employeeDetailData?.workStateCd === 'CMN068.30';

	const handleClickFullTimeEmployeeCriminalRecord = () => {
		showDialog(({ hideDialog }) => (
			<CheckFullTimeEmployeeCriminalRecord
				currentFullTimeEmployee={currentEmployee}
				hideDialog={hideDialog}
			/>
		));
	};

	const handleClickFullTimeEmployeeDutyHistory = () => {
		showDialog(({ hideDialog }) => (
			<FullTimeEmployeeHistoryOfDutyDialog
				currentFullTimeEmployee={currentEmployee}
				hideDialog={hideDialog}
				isResign={isResign}
			/>
		));
	};

	const handleClickHealthCheckupHistory = () => {
		showDialog(({ hideDialog }) => (
			<FullTimeEmployeeHealthCheckupHistoryDialog
				hideDialog={hideDialog}
				currentEmployee={currentEmployee}
			/>
		));
	};

	const renderAddress = () => {
		const address = [
			item?.basAddr ?? '',
			item?.detailAddr ?? '',
			item?.zipCode ? `(${item?.zipCode})` : '',
		].join(' ');
		return address.trim() ? address : '-';
	};

	return (
		<S.Container>
			<S.Label>
				기본 정보
				{!isResign && (
					<S.ButtonContainer>
						{item?.rejoinUpdateTargetYn && (
							<CRText typography='label' color='primary60'>
								재입사 직원 정보를 최신 정보로 편집해주세요.
							</CRText>
						)}
						<CRButton.Default size='xSmall' type='outlined' palette='gray' onClick={onChangeMode}>
							편집
						</CRButton.Default>
					</S.ButtonContainer>
				)}
			</S.Label>
			<S.Table $isDisabled={isResign}>
				<S.TableRow>
					<S.TableLabelColumn>이름</S.TableLabelColumn>
					<S.TableValueColumn>{item?.korMemberNm || '-'}</S.TableValueColumn>
					<S.TableLabelColumn
						rowSpan={2}
						style={{
							verticalAlign: 'top',
						}}>
						주소
					</S.TableLabelColumn>
					<S.TableValueColumn
						rowSpan={2}
						style={{
							verticalAlign: 'top',
						}}>
						{renderAddress()}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>주민등록번호</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRRsdNumber disabled={isResign} rdsNumber={item?.rsdnNo} key={item?.rsdnNo} />
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>생년월일</S.TableLabelColumn>
					<S.TableValueColumn>
						{item?.birthDt ? displayBirthDay(item?.birthDt) : '-'}
					</S.TableValueColumn>
					<S.TableLabelColumn>직무</S.TableLabelColumn>
					<S.TableValueColumn
						style={{
							verticalAlign: 'middle',
						}}>
						{item?.dutyNm ?? '-'}
						<S.RightButtonContainer>
							<CRButton.Default
								size='xSmall'
								palette='gray'
								type='outlined'
								onClick={handleClickFullTimeEmployeeDutyHistory}>
								변경/이력
							</CRButton.Default>
						</S.RightButtonContainer>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>입사일</S.TableLabelColumn>
					<S.TableValueColumn>
						{item?.joinDate ? dayjs(item.joinDate).format('YYYY.MM.DD') : '-'}
					</S.TableValueColumn>
					<S.TableLabelColumn>파트 구분</S.TableLabelColumn>
					<S.TableValueColumn>
						<CRCheckBoxGroup
							checkType='single'
							type='radio'
							disabled
							gap={0.8}
							value={[{ value: item?.partDivCd, label: '' }]}
							options={options.partType}
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>퇴사일</S.TableLabelColumn>
					<S.TableValueColumn>
						{item?.resignDate ? dayjs(item.resignDate).format('YYYY.MM.DD') : '-'}
					</S.TableValueColumn>
					<S.TableLabelColumn>근무 형태</S.TableLabelColumn>
					<S.TableValueColumn>{item?.workFormNm ?? '-'}</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>전화번호</S.TableLabelColumn>
					<S.TableValueColumn>
						{item?.mobilePhoneNo ? displayPhoneNumber(item?.mobilePhoneNo) : '-'}
					</S.TableValueColumn>
					<S.TableLabelColumn>업무 대행자</S.TableLabelColumn>
					<S.TableValueColumn>{item?.workExecuteActingEmployeeNm ?? '-'}</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>범죄경력 조회</S.TableLabelColumn>
					<S.TableValueColumn
						style={!item?.crimeHistSearchDt && !isResign ? { color: Colors.red } : undefined}>
						{item?.crimeHistSearchDt
							? dayjs(item.crimeHistSearchDt).format('YYYY.MM.DD')
							: '미조회'}
						<S.RightButtonContainer>
							{hasPermission && !isResign && (
								<CRButton.Default
									size='xSmall'
									palette='gray'
									type='outlined'
									onClick={handleClickFullTimeEmployeeCriminalRecord}>
									조회 확인
								</CRButton.Default>
							)}
						</S.RightButtonContainer>
					</S.TableValueColumn>
					<S.TableLabelColumn>장애여부</S.TableLabelColumn>
					<S.TableValueColumn>
						{options.disability.find((target) => target.value === item?.disabilityGradeCd)?.label ??
							'없음'}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>건강 검진일</S.TableLabelColumn>
					<S.TableValueColumn>
						{item?.healthCheckDate ? dayjs(item.healthCheckDate).format('YYYY.MM.DD') : '-'}
						<S.RightButtonContainer>
							<CRButton.Default
								size='xSmall'
								palette='gray'
								type='outlined'
								onClick={handleClickHealthCheckupHistory}>
								변경/이력
							</CRButton.Default>
						</S.RightButtonContainer>
					</S.TableValueColumn>
					<S.TableLabelColumn
						rowSpan={3}
						style={{
							verticalAlign: 'top',
						}}>
						비고
					</S.TableLabelColumn>
					<S.TableValueColumn
						rowSpan={3}
						style={{
							verticalAlign: 'top',
						}}>
						{item?.employeeSpecialDesc || '-'}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>투폰/투번호</S.TableLabelColumn>
					<S.TableValueColumn
						style={{
							verticalAlign: 'middle',
						}}>
						<CRCheckBoxGroup
							checkType='single'
							type='radio'
							gap={0.8}
							value={[{ value: item?.twoPhoneYn, label: '' }]}
							options={[
								{
									label: '없음',
									value: false,
									disabled: true,
								},
								{
									label: '있음',
									value: true,
									disabled: true,
								},
							]}
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>투폰 전화번호</S.TableLabelColumn>
					<S.TableValueColumn>
						{item?.subMobilePhoneNo ? displayPhoneNumber(item?.subMobilePhoneNo) : '-'}
					</S.TableValueColumn>
				</S.TableRow>
			</S.Table>
		</S.Container>
	);
}
