import React, { useCallback } from 'react';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRTable from 'components/base/CRTable';
import { recipientHistoryOfHoldingAdapter } from 'lib/adapter/recipient';
import { useRecipientHistoryOfHolding } from 'lib/hook/react-query';
import { Recipient, RecipientHoldingHistoryType } from 'types/view/recipient';

import { RECIPIENT_HOLDING_HISTORY_TABLE_HEADER_CONFIG } from './constants';
import * as S from './styles';

interface Props {
	currentRecipient?: Recipient;
	onClickClose?: () => void;
	onClickAdd?: () => void;
	onClickEdit?: (item: RecipientHoldingHistoryType) => void;
	onClickDelete?: (item: RecipientHoldingHistoryType) => void;
}

function RecipientHoldingHistoryDialog({
	currentRecipient,
	onClickClose,
	onClickAdd,
	onClickEdit,
	onClickDelete,
}: Props): React.ReactElement {
	const { data: historyOfHolding = [] } = useRecipientHistoryOfHolding(
		{
			recipientId: currentRecipient?.recipientId ?? 0,
		},
		recipientHistoryOfHoldingAdapter,
	);

	const ButtonContainer = useCallback(
		(id: number, item?: RecipientHoldingHistoryType) => (
			<S.ButtonContainer>
				<CRButton.Default
					type='outlined'
					palette='gray'
					size='xSmall'
					onClick={() => item && onClickEdit?.(item)}>
					수정
				</CRButton.Default>
				<CRButton.Default
					type='outlined'
					palette='primary'
					size='xSmall'
					onClick={() => item && onClickDelete?.(item)}>
					삭제
				</CRButton.Default>
			</S.ButtonContainer>
		),
		[onClickEdit, onClickDelete],
	);

	return (
		<CRDialog
			type='L'
			title='입원 이력'
			body={
				<S.Container>
					<S.DescriptionContainer>
						수급자 입원으로 사회복지사 가정 방문이 불가능한 경우, 입원 이력을 관리할 수 있습니다.
					</S.DescriptionContainer>
					<CRTable.Root>
						<CRTable.Head heads={RECIPIENT_HOLDING_HISTORY_TABLE_HEADER_CONFIG} disableBackBlind />
						<CRTable.Body>
							{historyOfHolding.map((item, index) => (
								<CRTable.Row
									key={item.id}
									item={{ ...item, index: index + 1 }}
									customRender={{
										description: (description: string) => description || '-',
										id: ButtonContainer,
									}}
									customStyle={{
										index: {
											textAlign: 'right',
										},
									}}
									renderKeys={['index', 'period', 'description', 'id']}
								/>
							))}
						</CRTable.Body>
					</CRTable.Root>
				</S.Container>
			}
			footer={
				<S.FooterRightSideContainer>
					<CRButton.IconButton
						iconLeft={Assets.icon.add}
						type='outlined'
						palette='gray'
						onClick={onClickAdd}>
						입원 이력 등록
					</CRButton.IconButton>
				</S.FooterRightSideContainer>
			}
			onClickClose={onClickClose}
		/>
	);
}

export default React.memo(RecipientHoldingHistoryDialog);
