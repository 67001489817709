import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import Assets from 'assets';
import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import CRTextArea from 'components/base/CRInput/CRTextArea';
import CRInputLabel from 'components/base/CRInputLabel';
import { Toast } from 'components/base/CRToast';
import InformationSheet from 'components/ui/InformationSheet';
import { InformationSheetProperties } from 'components/ui/InformationSheet/type';
import { Termination, displayBirthDay } from 'lib';
import { commonCodeAdapter } from 'lib/adapter/common';
import {
	useCommonCodes,
	useServiceTermination,
	useServiceTerminationInfo,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { ResponseCode } from 'types/api';
import { ServiceTerminationRequest } from 'types/api/contract';
import { TerminationForm } from 'types/view/contract';

import * as S from './styles';

interface Props {
	detailId: number;
	onRefetch: () => void;
}

function ContractWithdrawalDialog({ detailId, onRefetch }: Props): React.ReactElement {
	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<TerminationForm>({
		resolver: yupResolver(Termination),
	});

	const { hideDialog } = useDialog();
	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN178'],
		},
		commonCodeAdapter,
	);
	const { data: terminationInfo } = useServiceTerminationInfo({
		serviceContractDetailId: detailId,
	});

	const { mutate: serviceTermination } = useServiceTermination((client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			Toast.success('정상적으로 해지되었습니다.');
			onRefetch();
			hideDialog();
		} else {
			Toast.error('해지에 실패했습니다. 잠시후 다시 시도해주세요.');
		}
	});

	const onSubmit = (data: TerminationForm) => {
		if (!terminationInfo) return;
		const param: ServiceTerminationRequest = {
			serviceContractDetailId: terminationInfo.serviceContractDetailId,
			serviceEndDate: dayjs(data.serviceEndDate).format('YYYY-MM-DD'),
			contractEndDescCd: data.contractEndDescCd.value,
			serviceEndDesc: data.serviceEndDesc,
		};
		serviceTermination(param);
	};

	return (
		<S.Container onSubmit={handleSubmit(onSubmit)}>
			<S.HeaderContainer>
				<S.HeaderTitle>급여 해지</S.HeaderTitle>
				<S.CloseIcon src={Assets.icon.close} alt='close' onClick={hideDialog} />
			</S.HeaderContainer>
			<S.BodyContainer>
				<S.BodyContent>
					<InformationSheet
						title='급여 계약 정보'
						items={
							[
								[
									{
										label: '급여 종류',
										value: terminationInfo?.serviceTypeCdNm || '-',
										labelWidth: '6.4rem',
										valueStyle: {
											width: '100%',
											color: Colors.gray10,
											textAlign: 'left',
											fontSize: '1.4rem',
											lineHeight: '2.4rem',
										},
									},
								],
								[
									{
										label: '계약일',
										value: terminationInfo?.serviceContractCompleteDate
											? dayjs(terminationInfo?.serviceContractCompleteDate).format('YYYY.MM.DD')
											: '-',
										labelWidth: '6.4rem',
									},
								],
								[
									{
										label: '수급자',
										value: `${terminationInfo?.recipient.memberNm}(${displayBirthDay(
											terminationInfo?.recipient.memberBirthDt,
										)})`,
										labelWidth: '6.4rem',
									},
								],
							]
								.concat(
									terminationInfo?.employees?.map((item) => [
										{
											label: '직원',
											value: `${item?.memberNm}(${displayBirthDay(item?.memberBirthDt)}) `,
											labelWidth: '6.4rem',
										},
									]) || [],
								)
								.map((item) =>
									item.map((item) => ({
										...item,
										valueStyle: {
											width: '100%',
											color: Colors.gray10,
											textAlign: 'left',
											fontSize: '1.4rem',
											lineHeight: '2.4rem',
										},
									})),
								) as InformationSheetProperties[][]
						}
						type='gray'
					/>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='종료일' isRequired>
								<CRInput.DatePicker
									value={value}
									onChangeValue={onChange}
									placeholder='종료일 선택'
								/>
							</CRInputLabel>
						)}
						name='serviceEndDate'
						control={control}
					/>

					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='급여 종료 유형' isRequired>
								<CRInput.Selector
									placeholder='급여 종료 유형 선택'
									items={commonCodes?.CMN178}
									currentValue={value}
									onChangeValue={onChange}
								/>
							</CRInputLabel>
						)}
						name='contractEndDescCd'
						control={control}
					/>

					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='급여 종료 사유' isRequired>
								<CRTextArea
									placeholder='급여 종료 사유를 자세히 작성해주세요.'
									onChange={onChange}
									value={value}
								/>
							</CRInputLabel>
						)}
						name='serviceEndDesc'
						control={control}
					/>
				</S.BodyContent>
			</S.BodyContainer>
			<S.FooterContainer>
				<CRButton.Default palette='gray' type='text' onClick={hideDialog}>
					취소
				</CRButton.Default>
				<CRButton.Default
					palette='primary'
					type='outlined'
					size='default'
					disabled={!isValid}
					buttonType='submit'>
					해지
				</CRButton.Default>
			</S.FooterContainer>
		</S.Container>
	);
}

export default React.memo(ContractWithdrawalDialog);
