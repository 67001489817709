import { UseFormReturn } from 'react-hook-form';
import { NavigateFunction } from 'react-router-dom';

import RouterPath from 'common/router';
import { ContractDetailDTO } from 'types/dto';
import { LocalNavigationSection } from 'types/view/task';

export function generateContractScrollSpy(
	navigate: NavigateFunction,
	form: UseFormReturn<ContractDetailDTO>,
	onChangeRouter?: () => void,
) {
	const contractTaskScrollSpyMock: LocalNavigationSection[] = [
		{
			label: RouterPath.myTaskOperate().recipientBasic.label,
			onClick: () => {
				navigate(`${RouterPath.myTaskOperate().recipientBasic.key}`);
			},
			subSections: [
				{
					label: '',
					items: [
						{
							label: '기본 정보',
							key: `${RouterPath.myTaskOperate().recipientBasic.key}#1`,
							onClick: () => navigate(`${RouterPath.myTaskOperate().recipientBasic.key}#1`),
							isDone: !!(
								form.watch('recipient.base.recipientNm') &&
								form.watch('recipient.base.addressInfo.basAddress') &&
								form.watch('recipient.base.birthDt') &&
								form.watch('recipient.base.signMethodCd')
							),
						},
						{
							label: '장기요양인정 정보',
							key: `${RouterPath.myTaskOperate().recipientBasic.key}#2`,
							onClick: () => navigate(`${RouterPath.myTaskOperate().recipientBasic.key}#2`),
							isDone: !!(
								form.watch('recipient.longTerm.longTermNo') &&
								form.watch('recipient.longTerm.longTermStartDate') &&
								form.watch('recipient.longTerm.longTermEndDate') &&
								form.watch('recipient.longTerm.burdenRateCd') &&
								form.watch('recipient.longTerm.longTermGradeCd') &&
								form.watch('recipient.longTerm.longTermServices')?.length &&
								form.watch('recipient.longTerm.longTermCertiFile') &&
								form.watch('recipient.longTerm.longTermUsePlanFile')
							),
						},
						{
							label: '보호자 정보',
							key: `${RouterPath.myTaskOperate().recipientBasic.key}#3`,
							onClick: () => navigate(`${RouterPath.myTaskOperate().recipientBasic.key}#3`),
							isDone: !!form
								.watch('recipient.guardians')
								?.find(
									(item) =>
										item.guardianNm &&
										item.guardianPhoneNo &&
										item.guardianRelCd &&
										item.addressInfo,
								),
						},
						{
							label: '타급여 이용 정보',
							key: `${RouterPath.myTaskOperate().recipientBasic.key}#4`,
							onClick: () => navigate(`${RouterPath.myTaskOperate().recipientBasic.key}#4`),
							isDone: !!(
								!form.watch('recipient.otherServiceUses')?.length ||
								(form.watch('recipient.otherServiceUses')?.length &&
									form
										.watch('recipient.otherServiceUses')
										?.every(
											(item) =>
												item.otherCenterNm &&
												item.otherCenterPhoneNo &&
												item.serviceTypeCd &&
												item.times?.length,
										))
							),
						},
						{
							label: '현금영수증 정보',
							key: `${RouterPath.myTaskOperate().recipientBasic.key}#5`,
							onClick: () => navigate(`${RouterPath.myTaskOperate().recipientBasic.key}#5`),
							isDone: !!(
								(form.watch('recipient.recipientReceipt.cashReceiptIssueYn') !== undefined &&
									!form.watch('recipient.recipientReceipt.cashReceiptIssueYn')) ||
								(form.watch('recipient.recipientReceipt.cashReceiptIssueYn') &&
									form.watch('recipient.recipientReceipt.cashReceiptIssuerNm') &&
									form.watch('recipient.recipientReceipt.cashReceiptIssueMethodCd') &&
									form.watch('recipient.recipientReceipt.cashReceiptIssuerMobilePhoneNo'))
							),
						},
					],
				},
			],
		},
		{
			label: RouterPath.myTaskOperate().contractService.label,
			onClick: () => {
				navigate(`${RouterPath.myTaskOperate().contractService.key}`);
			},
			subSections: [
				{
					label: '',
					items: [
						{
							key: `${RouterPath.myTaskOperate().contractService.key}#1`,
							label: '이용 정보',
							onClick: () => {
								navigate(`${RouterPath.myTaskOperate().contractService.key}#1`);
							},
							isDone: !!form
								.watch('services')
								?.every(
									(item) =>
										item.use?.serviceStartDate &&
										item.use?.serviceEndDate &&
										item.use?.serviceTimes?.length &&
										item.use?.monthPerWorkHourCnt,
								),
						},
						{
							key: `${RouterPath.myTaskOperate().contractService.key}#2`,
							onClick: () => {
								navigate(`${RouterPath.myTaskOperate().contractService.key}#2`);
							},
							label: '계약 정보',
							isDone: !!form
								.watch('services')
								?.every(
									(item) =>
										item.associates?.recipientContractAssignDate &&
										item.associates.contractEmployees?.every(
											(employee) => employee?.employeeId && employee.employeeContractAssignDate,
										),
								),
						},
					],
				},
			],
		},
		{
			label: RouterPath.myTaskOperate().manager.label,
			onClick: () => {
				navigate(`${RouterPath.myTaskOperate().manager.key}`);
			},
			subSections: [
				{
					label: '',
					items: [
						{
							key: `${RouterPath.myTaskOperate().manager.key}#1`,
							label: '기본 정보',
							onClick: () => {
								navigate(`${RouterPath.myTaskOperate().manager.key}#1`);
							},
							isDone: !!(
								form.watch('employees')?.length &&
								form
									.watch('employees')
									?.every(
										(item) =>
											item.base.employeeNm &&
											item.base.employeeRsdnNo &&
											item.base.employeeBirthDt &&
											item.base.tagMobilePhoneNo &&
											(!item.base.twoPhoneYn || item.base.subMobilePhoneNo) &&
											item.base.employeeBasAddr &&
											item.base.dementiaEduCompleteYn !== undefined &&
											item.base.disabilityGradeCd,
									)
							),
						},
						{
							key: `${RouterPath.myTaskOperate().manager.key}#2`,
							label: '자격증 및 교육 정보',
							onClick: () => {
								navigate(`${RouterPath.myTaskOperate().manager.key}#2`);
							},
							isDone: !!form.watch('employees')?.length,
						},
						{
							key: `${RouterPath.myTaskOperate().manager.key}#3`,
							label: '타근무 정보',
							onClick: () => {
								navigate(`${RouterPath.myTaskOperate().manager.key}#3`);
							},
							isDone: !!(
								form.watch('employees')?.length &&
								form
									.watch('employees')
									?.every(
										(item) =>
											!item.otherCorpWorks?.length ||
											item.otherCorpWorks?.every(
												(otherCorpWork) =>
													otherCorpWork.otherCorpNm &&
													otherCorpWork.personalBusinessOwnerYn !== undefined &&
													otherCorpWork.otherCorpPhoneNo &&
													otherCorpWork.otherCorpWorkStartEndTimes?.length,
											),
									)
							),
						},
						{
							key: `${RouterPath.myTaskOperate().manager.key}#4`,
							label: '임금 정보',
							onClick: () => {
								navigate(`${RouterPath.myTaskOperate().manager.key}#4`);
							},
							isDone: !!(
								form.watch('employees')?.length &&
								form
									.watch('employees')
									?.every(
										(item) =>
											(item.salary?.salaryPayTypeCd &&
												item.salary?.salaryPayTypeCd !== 'CMN133.10') ||
											(item.salary?.salaryAccountDepositorNm &&
												item.salary.salaryAccountNo &&
												item.salary.salaryAccountBankCd &&
												item.salary.salaryAccountValidYn),
									)
							),
						},
					],
				},
			],
		},
		{
			label: RouterPath.myTaskOperate().contractInspection.label,
			onClick: () => {
				onChangeRouter?.();
				navigate(`${RouterPath.myTaskOperate().contractInspection.key}`);
			},
			subSections: [
				{
					label: '',
					items: [
						{
							key: `${RouterPath.myTaskOperate().contractInspection.key}#1`,
							label: '본인부담금 정보',
							onClick: () => {
								onChangeRouter?.();
								navigate(`${RouterPath.myTaskOperate().contractInspection.key}#1`);
							},
							isDone: !!(
								form.watch('inspection.burdenAmt.burdenAmtPayMethodCd') &&
								(!(form.watch('inspection.burdenAmt.burdenAmtPayMethodCd') === 'CMN041.10') ||
									(form.watch('inspection.burdenAmt.burdenAmtAutotransAccountNo') &&
										form.watch('inspection.burdenAmt.burdenAmtAutotransBankCd') &&
										form.watch('inspection.burdenAmt.burdenAmtAutotransAccountDepositorNm') &&
										form.watch('inspection.burdenAmt.burdenAmtAutotransAccountValidYn'))) &&
								(!(form.watch('inspection.burdenAmt.burdenAmtPayMethodCd') === 'CMN041.30') ||
									form.watch('inspection.burdenAmt.burdenAmtPayDeductEmployeeId'))
							),
						},
						{
							key: `${RouterPath.myTaskOperate().contractInspection.key}#2`,
							label: '발송',
							onClick: () => {
								onChangeRouter?.();
								navigate(`${RouterPath.myTaskOperate().contractInspection.key}#2`);
							},
							isDone: true,
						},
						{
							key: `${RouterPath.myTaskOperate().contractInspection.key}#3`,
							label: '점검',
							onClick: () => {
								onChangeRouter?.();
								navigate(`${RouterPath.myTaskOperate().contractInspection.key}#3`);
							},
							isDone: !!(
								form.watch('inspection.check.recipientIaWriteYn') &&
								form.watch('inspection.check.salaryOfferPlanWriteYn') &&
								form.watch('inspection.check.pcorpScheduleRegistYn') &&
								form.watch('inspection.check.wardReportCompleteYn') &&
								form.watch('inspection.check.hopeiumFormWriteYn') &&
								form.watch('inspection.check.tagBeaconRequestYn') &&
								form.watch('inspection.check.admsnUsereqUsedescLocalgovSendYn') &&
								form.watch('inspection.check.liabilityInsuranceRegistNeedYn') &&
								form.watch('inspection.check.longTermUsePlanPcorpBrowseRequestYn') &&
								form.watch('inspection.check.pcorpSalaryContractCancelYn') &&
								(form.watch('inspection.burdenAmt.burdenAmtPayMethodCd') !== 'CMN041.10' ||
									form.watch('inspection.check.autotransUploadYn')) &&
								form.watch('inspection.check.insuranceRequestYn') &&
								form.watch('inspection.check.wardEndReportCompleteYn')
							),
						},
					],
				},
			],
		},
	];
	return contractTaskScrollSpyMock;
}
