import React, { useEffect, useMemo, useState } from 'react';
import { Control, Controller, useFieldArray, useWatch } from 'react-hook-form';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRInputLabel from 'components/base/CRInputLabel';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import { NewTaskInput } from 'components/domain/dialog/NewTaskDialog';
import { useRecipients } from 'lib/hook/react-query';
import useDebounceValue from 'lib/hook/util/useDebounce';
import { NewSubTaskDTO, NewTaskTypesDTO } from 'types/api/myTask';

import CRInput from '../../../base/CRInput';
import { TaskTypeCode } from './constant';
import * as S from './styles';

interface Props {
	control: Control<NewTaskInput, any>;
	onSubmit: any;
	newTaskItems?: NewTaskTypesDTO[];
	subTaskItems?: NewSubTaskDTO;
	onClickClose: () => void;
}

function NewMyTaskForm({
	control,
	onSubmit,
	newTaskItems = [],
	subTaskItems,
	onClickClose,
}: Props): React.ReactElement {
	const [keyword, setKeyword] = useState('');
	const debouncedKeyword = useDebounceValue(keyword, 500);
	const { data: searchResults } = useRecipients({
		centerId: 1,
		keyword: debouncedKeyword,
	});

	const onRecipientsSearch = (keyword: string) => {
		setKeyword(keyword);
	};

	const normalizedRecipient = useMemo(
		() =>
			searchResults?.data?.map((recipient) => ({
				label: recipient.korMemberNm,
				value: recipient,
			})) ?? [],
		[searchResults],
	);

	const { fields: serviceTypeField, append: appendServiceType } = useFieldArray({
		control,
		name: 'serviceType',
	});

	const { fields: resignField, append: appendResign } = useFieldArray({
		control,
		name: 'resign',
	});

	const { fields: documentTypeField, append: appendDocumentType } = useFieldArray({
		control,
		name: 'documentType',
	});

	const { newTaskType } = useWatch({
		control,
	});

	const renderSubTask = () => {
		if (!subTaskItems) return null;

		if (subTaskItems.services?.length) {
			return (
				<CRInputLabel label='서비스 종류' isRequired>
					<S.CheckboxContainer $direction='column'>
						{serviceTypeField?.map((item, index) => (
							<Controller
								key={item.id}
								control={control}
								name={`serviceType.${index}.checked`}
								defaultValue={false}
								render={({ field: { value, onChange } }) => (
									<CRCheckBox
										checkBoxType='checkbox'
										appearanceType='button'
										checked={value}
										onChange={onChange}>
										{item.label}
									</CRCheckBox>
								)}
							/>
						))}
					</S.CheckboxContainer>
				</CRInputLabel>
			);
		}
		if (subTaskItems.tasks?.length) {
			if (newTaskType?.value === TaskTypeCode.RENEWAL_PAPER) {
				return (
					<>
						<Controller
							render={({ field: { onChange, value, ref }, formState: { errors } }) => (
								<CRInputLabel label='서류 종류' isRequired>
									<CRInput.Selector
										placeholder='서류 유형을 선택해주세요'
										onChangeValue={onChange}
										items={subTaskItems.tasks?.map((item) => ({
											value: item.taskId,
											label: item.taskNm,
										}))}
										currentValue={value}
									/>
								</CRInputLabel>
							)}
							name='documentType'
							control={control}
						/>
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInputLabel label='수급자명' isRequired>
									<CRInput.Selector
										placeholder='수급자 검색'
										autoComplete
										onSearch={onRecipientsSearch}
										currentValue={value}
										items={normalizedRecipient}
										renderOptionItem={(item) => (
											<span>{`${item.value.korMemberNm}(${item.value.birthDt})`}</span>
										)}
										onChangeValue={onChange}
									/>
								</CRInputLabel>
							)}
							name='recipient'
							control={control}
						/>
					</>
				);
			}
			if (newTaskType?.value === TaskTypeCode.RESIGN) {
				return (
					<CRInputLabel label='구분' isRequired>
						<S.CheckboxContainer $direction='column'>
							{resignField?.map((item, index) => (
								<Controller
									key={item.id}
									control={control}
									name={`resign.${index}.checked`}
									defaultValue={false}
									render={({ field: { value, onChange } }) => (
										<CRCheckBox
											checkBoxType='checkbox'
											appearanceType='button'
											checked={value}
											onChange={onChange}>
											{item.label?.includes('수급자') ? '퇴소' : '퇴사'}
										</CRCheckBox>
									)}
								/>
							))}
						</S.CheckboxContainer>
					</CRInputLabel>
				);
			}
		}

		if (newTaskType?.value === TaskTypeCode.CHANGE_STAFF) {
			return (
				<>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInputLabel label='수급자명' isRequired>
								<CRInput.Selector
									placeholder='수급자 검색'
									autoComplete
									onSearch={onRecipientsSearch}
									currentValue={value}
									items={normalizedRecipient}
									renderOptionItem={(item) => (
										<span>{`${item.value.korMemberNm}(${item.value.birthDt})`}</span>
									)}
									onChangeValue={onChange}
								/>
							</CRInputLabel>
						)}
						name='recipient'
						control={control}
					/>
					<CRInputLabel label='서비스 종류' isRequired>
						<S.CheckboxContainer $direction='column'>
							{serviceTypeField?.map((item, index) => (
								<Controller
									key={item.id}
									control={control}
									name={`serviceType.${index}.checked`}
									defaultValue={false}
									render={({ field: { value, onChange } }) => (
										<CRCheckBox
											checkBoxType='checkbox'
											appearanceType='button'
											checked={value}
											onChange={onChange}>
											{item.label}
										</CRCheckBox>
									)}
								/>
							))}
						</S.CheckboxContainer>
					</CRInputLabel>
				</>
			);
		}

		return null;
	};

	useEffect(() => {
		if (!newTaskType) return;
		setKeyword('');
		if (newTaskType.value === TaskTypeCode.CONTRACT) {
			appendServiceType(
				subTaskItems?.services?.map((item) => ({
					label: item.serviceNm,
					value: item.serviceCd,
					checked: false,
				})) ?? [],
			);
		}

		if (newTaskType.value === TaskTypeCode.RESIGN) {
			appendResign(
				subTaskItems?.tasks?.map((item) => ({
					label: item.taskNm,
					value: item.taskId,
					checked: false,
				})) ?? [],
			);
		}

		if (newTaskType.value === TaskTypeCode.RENEWAL_PAPER) {
			appendDocumentType(
				subTaskItems?.tasks?.map((item) => ({
					label: item.taskNm,
					value: item.taskId,
					checked: false,
				})) ?? [],
			);
		}

		if (newTaskType.value === TaskTypeCode.CHANGE_STAFF) {
			appendServiceType(
				subTaskItems?.tasks?.map((item) => ({
					label: item.taskNm,
					value: item.taskId,
					checked: false,
				})) ?? [],
			);
		}
	}, [subTaskItems]);

	return (
		<S.Container onSubmit={onSubmit}>
			<S.ContentsContainer>
				<S.HeaderContainer>
					<S.HeaderTitleContainer>
						<S.HeaderTitle>새 업무 생성하기</S.HeaderTitle>
						<S.CloseIcon src={Assets.icon.close} alt='close' onClick={onClickClose} />
					</S.HeaderTitleContainer>
					<S.HeaderDescription>유형에 맞춰 업무를 생성하세요</S.HeaderDescription>
				</S.HeaderContainer>
				<S.MainContainer>
					<Controller
						render={({ field: { onChange, value, ref }, formState: { errors } }) => (
							<CRInputLabel label='업무유형' isRequired>
								<CRInput.Selector
									placeholder='유형을 선택해주세요'
									onChangeValue={onChange}
									items={newTaskItems.map((item) => ({
										value: item.taskTypeCd,
										label: item.taskTypeNm,
									}))}
									currentValue={value}
								/>
							</CRInputLabel>
						)}
						name='newTaskType'
						control={control}
					/>
					{renderSubTask()}
					<Controller
						render={({ field: { onChange, value, ref }, formState: { errors } }) => (
							<CRInputLabel label='진행기간' isRequired>
								<CRInput.DateRangePicker
									title='진행기간'
									onChangeValue={onChange}
									placeholder='시작일자 ~ 종료일자'
								/>
							</CRInputLabel>
						)}
						name='dateRange'
						control={control}
					/>
				</S.MainContainer>
			</S.ContentsContainer>
			<S.FooterContainer>
				<CRButton.Default type='text' palette='gray' onClick={onClickClose}>
					취소
				</CRButton.Default>
				<CRButton.Default buttonType='submit'>생성</CRButton.Default>
			</S.FooterContainer>
		</S.Container>
	);
}

export default NewMyTaskForm;
