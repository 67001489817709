import React from 'react';

import * as Accordion from '@radix-ui/react-accordion';
import dayjs from 'dayjs';

import ContractDocumentForm from 'components/domain/form/ContractDocumentForm';
import ContractOwnExpenseForm from 'components/domain/form/ContractOwnExpenseForm';
import InspectionBeforeApprovalForm from 'components/domain/form/InspectionBeforeApprovalForm';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';

import { useCenterManagers, useMyAccountInfo } from '../../../lib/hook/react-query';
import useContractTask from '../../../lib/hook/view/contract/useContractTask';
import * as S from './styles';

function ContractInspectionPage(): React.ReactElement {
	const { form } = useContractTask();
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: centerManagers } = useCenterManagers({ centerId: myAccountInfo?.centerId });

	const forms = [
		{
			id: '#1',
			label: '본인부담금 정보',
			component: <ContractOwnExpenseForm />,
		},
		{
			id: '#2',
			label: '발송',
			component: <ContractDocumentForm />,
		},
		{
			id: '#3',
			label: '점검',
			component: <InspectionBeforeApprovalForm />,
		},
	];

	return (
		<Accordion.Root type='multiple' defaultValue={forms.map((form) => form.id)}>
			{forms.map((form) => (
				<Accordion.Item value={form.id} asChild>
					<TaskAccordion.Item>
						<Accordion.Header asChild>
							<Accordion.Trigger asChild>
								<TaskAccordion.Trigger href={form.id}>{form.label}</TaskAccordion.Trigger>
							</Accordion.Trigger>
						</Accordion.Header>
						<Accordion.Content asChild>
							<TaskAccordion.Content>{form.component}</TaskAccordion.Content>
						</Accordion.Content>
					</TaskAccordion.Item>
				</Accordion.Item>
			))}
			{!!form.watch('recipient.socialWorkerId') && (
				<S.LastCheckContainer>
					{`${
						(centerManagers ?? []).find(
							(item) => item.memberAccountId === form.watch('recipient.socialWorkerId'),
						)?.centerNm
					} ${
						(centerManagers ?? []).find(
							(item) => item.memberAccountId === form.watch('recipient.socialWorkerId'),
						)?.userNm
					} 사회복지사가 최종 확인하였습니다.\n${dayjs(
						form.watch('inspection.check.lastConfirmDate'),
					).format('YYYY.MM.DD')}`}
				</S.LastCheckContainer>
			)}
		</Accordion.Root>
	);
}

export default React.memo(ContractInspectionPage);
