import React, { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import { CRText } from 'components/base/CRText';
import { CheckOption } from 'components/base/Selections/type';
import RecipientClaimTable from 'components/domain/table/RecipientClaimTable';
import RecipientPayTable from 'components/domain/table/RecipientPayTable';
import InformationSheet from 'components/ui/InformationSheet';
import { InformationSheetProperties } from 'components/ui/InformationSheet/type';
import { displayPhoneNumber } from 'lib';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes, useRecipientBurdens } from 'lib/hook/react-query';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import { CommonCodeDTO } from 'types/api/common';
import { RecipientBurdenDTO } from 'types/dto';
import { OwnExpensePayMethodType } from 'types/view/common';
import { RecipientClaim, RecipientPay } from 'types/view/recipient';

import * as S from './styles';

export default function RecipientOwnExpenseTab(): React.ReactElement {
	const { currentRecipient } = useRecipientPage();
	const [filter, setFilter] = useState<CheckOption<CommonCodeDTO>[]>([]);
	const { data } = useRecipientBurdens({
		recipientId: currentRecipient?.recipientId,
		centerId: currentRecipient?.centerId,
		serviceTypeCds: filter?.map((e) => e.value),
	});

	const {
		data: commonCodes = {
			CMN006: [],
		},
	} = useCommonCodes({ comCdGroupNms: ['CMN006'] }, commonCodeAdapter);

	const serviceOptions = commonCodes.CMN006.filter((item) => item.data?.etcDesc1 === 'Y');

	const cashReceiptInfo = data?.cashReceiptIssueYn
		? `${displayPhoneNumber(data.cashReceiptIssuerNo)}, ${data.cashReceiptIssuerNm}`
		: '-';

	const burdenAmtPayStateRenderer = (data: RecipientBurdenDTO | undefined) => {
		if (!data) return '납부금액';
		// 완납
		if (data.burdenAmtPayStateCd === 'CMN135.30') {
			return (
				<FlexContainer justify='space-between'>
					<CRText typography='bodyB' text='납부금액' />
					<FlexContainer align='center'>
						<S.Circle color='green' />
						<CRText typography='label' text={data.burdenAmtPayStateNm} />
					</FlexContainer>
				</FlexContainer>
			);
		}
		// 과납
		if (data.burdenAmtPayStateCd === 'CMN135.40') {
			return (
				<FlexContainer justify='space-between'>
					<CRText typography='bodyB' text='납부금액' />
					<FlexContainer align='center'>
						<S.Circle color='blue' />
						<CRText typography='label' text={data.burdenAmtPayStateNm} />
					</FlexContainer>
				</FlexContainer>
			);
		}
		// 미납
		if (data.burdenAmtPayStateCd === 'CMN135.10') {
			const unpaidDays =
				data.burdenAmtUnpaidDayCnt === undefined ? '' : ` (${data.burdenAmtUnpaidDayCnt}일 차)`;
			return (
				<FlexContainer justify='space-between'>
					<CRText typography='bodyB' text='납부금액' />
					<FlexContainer align='center'>
						<S.Circle color='red' />
						<CRText typography='label' text={`${data.burdenAmtPayStateNm}${unpaidDays}`} />
					</FlexContainer>
				</FlexContainer>
			);
		}

		return '납부금액';
	};

	const payInformationItems: InformationSheetProperties[][] = useMemo(() => {
		if (data?.burdenAmtPayMethodCd === OwnExpensePayMethodType.Automatic) {
			return [
				[{ label: '은행명', value: data.burdenAmtAutotransBankNm ?? '-' }],
				[{ label: '계좌', value: data.burdenAmtAutotransAccountNo ?? '-' }],
				[{ label: '입금자명', value: data.burdenAmtAutotransAccountDepositorNm ?? '-' }],
			];
		}
		if (data?.burdenAmtPayMethodCd === OwnExpensePayMethodType.Self) {
			return [
				[{ label: '은행명', value: '센터장에게 문의해주세요.' }],
				[{ label: '계좌', value: '센터장에게 문의해주세요.' }],
				[{ label: '입금자명', value: '센터장에게 문의해주세요.' }],
			];
		}
		if (data?.burdenAmtPayMethodCd === OwnExpensePayMethodType.WageDeduction) {
			const phoneNumber = data?.burdenAmtPayDeductEmployeeMobilePhoneNo;
			const employeeNm = data?.burdenAmtPayDeductEmployeeNm;
			const employeeBirthDt = data?.burdenAmtPayDeductEmployeeBirthDt;
			const employeeInfo = [
				employeeNm ?? '',
				employeeBirthDt ? `(${dayjs(employeeBirthDt).format('YY.MM.DD')})` : '',
			]
				.filter(Boolean)
				.join(' ');

			return [
				[
					{
						label: '소속센터',
						value: data.burdenAmtPayDeductCenterNm ?? '-',
					},
				],
				[{ label: '직원', value: employeeInfo ?? '-' }],
				[
					{
						label: '전화번호',
						value: phoneNumber ? displayPhoneNumber(phoneNumber) : '-',
					},
				],
			];
		}
		return [
			[{ label: '은행명', value: '-' }],
			[{ label: '계좌', value: '-' }],
			[{ label: '입금자명', value: '-' }],
		];
	}, [data?.burdenAmtPayMethodCd]);

	return (
		<S.Container>
			<FlexContainer align='center' gap='0.8rem'>
				<CRButton.Default
					palette='gray'
					type={filter?.length === 0 ? 'filled' : 'outlined'}
					size='small'
					onClick={() => setFilter([])}>
					전체
				</CRButton.Default>
				<S.Line />
				{serviceOptions?.length > 0 &&
					serviceOptions.map((option) => (
						<CRButton.Default
							palette='gray'
							type={filter?.[0]?.value === option?.value ? 'filled' : 'outlined'}
							size='small'
							onClick={() => setFilter([option])}>
							{option.label}
						</CRButton.Default>
					))}
			</FlexContainer>
			{data && (
				<>
					<S.OwnExpenseHeaderContainer>
						<S.OwnExpenseItem>
							<InformationSheet
								title='납부 정보'
								items={[
									[{ label: '급여 종류', value: data?.serviceTypeNm || '-' }],
									[{ label: '납부 방식', value: data?.burdenAmtPayMethodNm || '-' }],
									[
										{
											label: '현금영수증',
											value: cashReceiptInfo,
										},
									],
								]}
							/>
						</S.OwnExpenseItem>
						<S.OwnExpenseItem>
							<InformationSheet title='계좌 정보' items={[...payInformationItems]} />
						</S.OwnExpenseItem>
						<S.OwnExpensePayItem>
							<InformationSheet
								align='space-between'
								title={burdenAmtPayStateRenderer(data)}
								items={[
									[
										{
											label: '총 청구액',
											value: `${data.burdenAmtChargeSumAmt.toLocaleString()}원`,
										},
									],
									[{ label: '납입금', value: `${data.burdenAmtPaySumAmt.toLocaleString()}원` }],
									[{ label: '미납금', value: `${data.burdenAmtUnPaidSumAmt.toLocaleString()}원` }],
								]}
							/>
						</S.OwnExpensePayItem>
					</S.OwnExpenseHeaderContainer>
					<S.OwnExpenseContentContainer>
						<S.OwnExpenseItem>
							<S.TableContainer>
								<RecipientClaimTable
									items={data.burdenAmtChargeServices.map(
										(item) =>
											({
												id: item.burdenAmtChargeYm,
												date: item.burdenAmtChargeYm,
												service: item.serviceTypeNm,
												amount: `${item.burdenAmt.toLocaleString()}원`,
											}) as RecipientClaim,
									)}
								/>
							</S.TableContainer>
						</S.OwnExpenseItem>
						<S.OwnExpenseItem>
							<S.TableContainer>
								<RecipientPayTable
									items={data.burdenAmtPayDetails.map(
										(item) =>
											({
												id: item.id,
												date: item.burdenAmtDt,
												amount: `${item.burdenAmt.toLocaleString()}원`,
												type: item.burdenAmtPayMethodNm,
												memo: item.burdenAmtDesc,
											}) as RecipientPay,
									)}
								/>
							</S.TableContainer>
						</S.OwnExpenseItem>
					</S.OwnExpenseContentContainer>
				</>
			)}
		</S.Container>
	);
}
