import { endpoint } from 'lib/service/Api/endpoint';

import useCRMutation from '../base/useCRMutation';
import useCRQuery from '../base/useCRQuery';

export const useClientScheduleRecipient = useCRQuery(
	endpoint.getClientScheduleRecipient.key,
	'getClientScheduleRecipient',
);

export const useClientScheduleEmployee = useCRQuery(
	endpoint.getClientScheduleEmployee.key,
	'getClientScheduleEmployee',
);

export const useSendRecordingSheet = useCRMutation(
	endpoint.sendRecordingSheets.key,
	'sendRecordingSheet',
);

export const useSendScheduleDetail = useCRQuery(
	endpoint.getSendScheduleDetail.key,
	'getSendScheduleDetail',
);

export const useScheduleTagErrorHistory = useCRQuery(
	endpoint.getScheduleTagErrorHistory.key,
	'getScheduleTagErrorHistory',
	{
		enabledKey: ['page', 'size', 'startServiceYmd', 'endServiceYmd', 'centerId'],
	},
);

export const useScheduleTagAutomationHistory = useCRQuery(
	endpoint.getScheduleTagAutomationHistory.key,
	'getScheduleTagAutomationHistory',
	{
		enabledKey: ['page', 'size', 'startServiceYmd', 'endServiceYmd', 'centerId'],
	},
);

export const useUpdateScheduleTagErrorHistoryDetail = useCRMutation(
	endpoint.updateScheduleTagErrorHistoryDetail.key,
	'updateScheduleTagErrorHistoryDetail',
);

export const useTagErrorAutomation = useCRMutation(
	endpoint.tagErrorAutomation.key,
	'tagErrorAutomation',
);

export const useScheduleAutomation = useCRMutation(
	endpoint.scheduleAutomation.key,
	'scheduleAutomation',
);

export const useAnnualTOHistories = useCRQuery(
	endpoint.getAnnualTOHistories.key,
	'getAnnualTOHistories',
	{
		enabledKey: ['centerId', 'basDt', 'dutyCds', 'page', 'size'],
	},
);

export const useAnnualTOHistoriesDownload = useCRQuery(
	endpoint.getAnnualTOHistoriesDownload.key,
	'getAnnualTOHistoriesDownload',
	{
		enabledKey: ['centerId', 'basDt', 'dutyCds'],
	},
);

export const useAnnualTOUsageHistories = useCRQuery(
	endpoint.getAnnualTOUsageHistories.key,
	'getAnnualTOUsageHistories',
	{
		enabledKey: ['centerId', 'vacaStartDt', 'vacaEndDt', 'vacaKindCds', 'dutyCds', 'page', 'size'],
	},
);

export const useAnnualTOUsageHistoriesDownload = useCRQuery(
	endpoint.getAnnualTOUsageHistoriesDownload.key,
	'getAnnualTOUsageHistoriesDownload',
	{
		enabledKey: ['centerId', 'vacaStartDt', 'vacaEndDt', 'vacaKindCds', 'dutyCds'],
	},
);

export const useManagerTagHistory = useCRQuery(
	endpoint.getManagerTagHistory.key,
	'getManagerTagHistory',
	{
		enabledKey: ['page', 'size', 'centerId', 'serviceYm'],
	},
);

export const useDownloadExcelManagerTagHistory = useCRQuery(
	endpoint.downloadExcelManagerTagHistory.key,
	'downloadExcelManagerTagHistory',
);

export const useFtimeEmployeeWorkSchedule = useCRQuery(
	endpoint.getFtimeEmployeeWorkSchedule.key,
	'getFtimeEmployeeWorkSchedule',
	{
		enabledKey: ['centerId', 'yearMonth', 'managerId', 'recipientStateCds'],
	},
);

export const useFtimeEmployeeMonthSchedule = useCRQuery(
	endpoint.getFtimeEmployeeMonthSchedule.key,
	'getFtimeEmployeeMonthSchedule',
	{
		enabledKey: ['centerId', 'yearMonth', 'managerId'],
	},
);
