import RouterPath from 'common/router';
import { TaskTabProps } from 'components/base/CRTab/TaskTab/type';

export const TAB_CONFIG: TaskTabProps[] = [
	{
		label: '기초 정보',
		key: RouterPath.recipient().basicInformation.uniqueKey,
	},
	{
		label: '급여 관리',
		key: RouterPath.recipient().service.uniqueKey,
	},
	{
		label: '통합사정',
		key: RouterPath.recipient().integratedAssessment.uniqueKey,
	},
	{
		label: '업무수행일지',
		key: RouterPath.recipient().workExecuteLogTab.uniqueKey,
	},
	{
		label: '일정 관리',
		key: RouterPath.recipient().schedule.uniqueKey,
	},
	{
		label: '본인부담금',
		key: RouterPath.recipient().ownExpense.uniqueKey,
	},
	{
		label: '상담 기록',
		key: RouterPath.recipient().consulting.uniqueKey,
	},
	{
		label: '발송 이력',
		key: RouterPath.recipient().message.uniqueKey,
	},
	{ label: '서류 관리', key: RouterPath.recipient().documents.uniqueKey },
];
