import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRSpinner from 'components/base/CRSpinner';
import { CRText } from 'components/base/CRText';
import { CRTextBox } from 'components/base/CRTextBox';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import { ESignRequest, displayBirthDay, displayPhoneNumber } from 'lib';
import { useCenterManagers, useMyAccountInfo } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { CenterManager } from 'types/api/common';
import { ESignRequestForm } from 'types/view/eDoc';

import * as S from './styles';

export enum ESignWayCode {
	대면서명 = 'CMN204.FACE',
	비대면서명 = 'CMN204.NON_FACE',
}

export enum FacingManagerPhoneUsageDivCd {
	업무용 = 'CMN205.WORK',
	개인용 = 'CMN205.PERSONAL',
}

interface EsignWayParam {
	esignWayCd: ESignWayCode;
	facingManagerId?: number;
	facingManagerPhoneUsageDivCd?: FacingManagerPhoneUsageDivCd;
}

interface Props {
	onSubmit: (data: ESignRequestForm, item: any) => void;
	item: any;
	defaultESignWay?: EsignWayParam;
	disabled?: boolean;
}

export const ESignWayOptions = [
	{
		label: '대면 서명',
		value: ESignWayCode.대면서명,
	},
	{
		label: '비대면 서명',
		value: ESignWayCode.비대면서명,
	},
] as CheckOption<any>[];

export function ESignWayDialog({ onSubmit, item, defaultESignWay, disabled = false }: Props) {
	const { hideDialog } = useDialog();
	const { data: myAccountInfo } = useMyAccountInfo();

	const {
		watch,
		setValue,
		control,
		handleSubmit,
		trigger,
		reset,
		formState: { isValid },
	} = useForm<ESignRequestForm>({
		resolver: yupResolver(ESignRequest),
		defaultValues: {
			eSignWayCd: [ESignWayOptions[0]],
		},
	});

	const eSignWayCd = watch('eSignWayCd')?.[0].value;
	const receiver = watch('receiver');
	const facingManagerPhoneUsageDivCd = watch('facingManagerPhoneUsageDivCd');
	const [fakeLoading, setFakeLoading] = useState(false);

	const { data: centerManagers } = useCenterManagers({
		centerId: myAccountInfo?.centerId,
	});

	const managerOptions = useMemo(
		() =>
			(centerManagers ?? []).map(
				(item) =>
					({
						label: item.userNm,
						value: {
							id: item.memberAccountId,
							name: item.userNm,
							duty: item.positionNm,
							birth: displayBirthDay(item.birthDt),
							workMobilePhoneNo: item.workMobilePhoneNo,
							personalMobilePhoneNo: item.personalMobilePhoneNo,
						},
						data: item,
					}) as CheckOption,
			),
		[centerManagers],
	);

	const receivePhoneOptions = useMemo(
		() => [
			{
				label: `업무용${
					receiver?.value?.workMobilePhoneNo
						? `(${displayPhoneNumber(receiver?.value?.workMobilePhoneNo)})`
						: ''
				}`,
				value: FacingManagerPhoneUsageDivCd.업무용,
				disabled: !receiver?.value?.workMobilePhoneNo,
			},
			{
				label: `개인용${
					receiver?.value?.personalMobilePhoneNo
						? `(${displayPhoneNumber(receiver?.value?.personalMobilePhoneNo)})`
						: ''
				}`,
				value: FacingManagerPhoneUsageDivCd.개인용,
				disabled: !receiver?.value?.personalMobilePhoneNo,
			},
		],
		[receiver],
	);

	const onsubmit = async (data: ESignRequestForm) => onSubmit?.(data, item);

	const submitForm = () => {
		if (fakeLoading) return;
		trigger().then((isValid) => {
			if (isValid) {
				// 로딩 상태 활성화
				setFakeLoading(true);

				// 3초 후 로딩 상태 비활성화
				setTimeout(() => {
					setFakeLoading(false);
				}, 3000);

				handleSubmit(onsubmit)();
			}
		});
	};

	useEffect(() => {
		if (!facingManagerPhoneUsageDivCd && receiver) {
			setValue(
				'facingManagerPhoneUsageDivCd',
				receiver
					? [
							{
								label: ``,
								value: receiver?.value?.workMobilePhoneNo
									? FacingManagerPhoneUsageDivCd.업무용
									: FacingManagerPhoneUsageDivCd.개인용,
							},
						]
					: [],
			);
		}
	}, [receiver, facingManagerPhoneUsageDivCd]);

	useEffect(() => {
		if (defaultESignWay && ESignWayOptions && managerOptions?.length) {
			if (defaultESignWay.esignWayCd === ESignWayCode.대면서명) {
				reset({
					eSignWayCd: ESignWayOptions.filter((item) => item?.value === defaultESignWay?.esignWayCd),
					facingManagerPhoneUsageDivCd: receivePhoneOptions?.filter(
						(item) => item.value === defaultESignWay?.facingManagerPhoneUsageDivCd,
					),
					receiver: managerOptions?.find(
						(item) => item?.data.memberAccountId === defaultESignWay?.facingManagerId,
					),
				});
			} else {
				reset({
					eSignWayCd: ESignWayOptions.filter((item) => item?.value === defaultESignWay?.esignWayCd),
				});
			}
		}
	}, [defaultESignWay, ESignWayOptions, receivePhoneOptions, managerOptions]);

	useEffect(() => {
		// 초기 진입시 로그인한 사용자와 일치하는 유저 찾아줌
		if (managerOptions?.length && myAccountInfo) {
			const receiver = managerOptions?.find(
				(item) => item?.data.memberAccountId === myAccountInfo?.memberAccountId,
			) as CheckOption<CenterManager>;
			if (receiver) {
				setValue('receiver', receiver);
				setValue('facingManagerPhoneUsageDivCd', [
					{
						label: '업무용',
						value: FacingManagerPhoneUsageDivCd.업무용,
						disabled: false,
					},
				]);
			}
		}
	}, [managerOptions, myAccountInfo]);

	return (
		<CRDialog
			onClickClose={hideDialog}
			title='서명 요청'
			body={
				<S.Container>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInputLabel isRequired label='서명 방식'>
								<CRCheckBoxGroup
									disabled={disabled}
									direction='col'
									onChange={onChange}
									appearanceType='button'
									checkType='single'
									type='radio'
									gap={0.8}
									value={value}
									options={ESignWayOptions}
								/>
							</CRInputLabel>
						)}
						name='eSignWayCd'
						control={control}
					/>
					{eSignWayCd === ESignWayCode.대면서명 && (
						<S.ReceiverContainer>
							<CRText text='수신 정보 입력' typography='bodyB' color='gray10' />
							<Controller
								render={({ field: { onChange, value }, formState: { errors } }) => (
									<CRInputLabel label='수신자' isRequired>
										<CRInput.SearchSelector
											disabled={disabled}
											placeholder='사회복지사 선택'
											currentValue={value}
											items={managerOptions}
											onChange={onChange}
											visibleKey={['birth', 'duty']}
										/>
									</CRInputLabel>
								)}
								name='receiver'
								control={control}
							/>
							<Controller
								render={({ field: { onChange, value }, formState: { errors } }) => (
									<CRInputLabel label='수신 연락처 유형' isRequired>
										<CRCheckBoxGroup
											disabled={disabled}
											direction='col'
											onChange={onChange}
											checkType='single'
											type='radio'
											gap={0.8}
											value={value}
											options={receivePhoneOptions}
										/>
									</CRInputLabel>
								)}
								name='facingManagerPhoneUsageDivCd'
								control={control}
							/>
						</S.ReceiverContainer>
					)}
					<CRTextBox
						items={['• 각 문서의 서명 방식은 대면 또는 비대면 중 하나만 선택할 수 있습니다.']}
					/>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						disabled={!isValid || fakeLoading}
						palette='primary'
						size='default'
						onClick={submitForm}>
						{fakeLoading ? <CRSpinner floatMode={false} /> : '발송'}
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
