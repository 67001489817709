import React from 'react';
import { Controller, FieldErrors, SubmitErrorHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRIcon from 'components/base/CRIcon';
import CRInput from 'components/base/CRInput';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import { regex } from 'lib';

import EDocClientPanelBase from '../EDocClientPanelBase';
import * as S from './styles';

interface FormFields {
	birthDt: string;
}

interface Props {
	onSubmit: (data: FormFields) => void;
}

export const resolver = yup.object<FormFields>({
	birthDt: yup
		.string()
		.required('생년월일을 입력해주세요.')
		.matches(regex.birth, '생년월일 형식이 올바르지 않습니다.'),
});

function EDocClientAuthForm({ onSubmit }: Props) {
	const { register, handleSubmit, control, formState } = useForm<FormFields>({
		resolver: yupResolver(resolver),
	});

	const onFormError = (errors: FieldErrors<FormFields>) => {
		const oneErrorMessage = Object.values(errors)[0].message;
		Toast.error(oneErrorMessage);
	};

	return (
		<EDocClientPanelBase>
			<S.FormContainer>
				<FlexContainer align='center' direction='column'>
					<CRIcon src={Assets.icon.report} width='3.6rem' height='3.6rem' />
					<CRText typography='h4' color='primary60' text='본인확인' margin='1rem 0' />
					<FlexContainer direction='column' align='center'>
						<CRText text='문서 열람을 위해' />
						<div>
							<CRText typography='bodyB' text='생년월일 6자리' />
							<CRText text='를 입력해주세요.' />
						</div>
					</FlexContainer>
				</FlexContainer>
				<Controller
					name='birthDt'
					control={control}
					render={({ field }) => (
						<CRInput.Default
							{...field}
							placeholder='생년월일(예:950101)'
							maxLength={6}
							type='number'
							status={formState.errors.birthDt?.message ? 'error' : 'default'}
						/>
					)}
				/>
				<CRButton.Default
					onClick={handleSubmit(onSubmit, onFormError)}
					style={{ width: '100%' }}
					size='default'>
					<CRText typography='bodyB' color='white' text='확인' margin='0.3rem 0' />
				</CRButton.Default>
			</S.FormContainer>
		</EDocClientPanelBase>
	);
}

export default EDocClientAuthForm;
