import React, { useMemo } from 'react';

import RouterPath from 'common/router';
import CRTab from 'components/base/CRTab';

import RecordingSheetTab from './RecordingSheetTab';

export default function RecordingSheetPage() {
	const recordTab = useMemo(() => RouterPath.scheduleOperate().recordingSheet, []);

	return (
		<CRTab.Default
			defaultActiveKey={recordTab.key}
			breadCrumb='기록지'
			items={[
				{
					label: recordTab.label,
					key: recordTab.key,
					children: <RecordingSheetTab />,
				},
			]}
		/>
	);
}
