import React from 'react';

import dayjs from 'dayjs';
import { v4 } from 'uuid';

import CRButton from 'components/base/CRButton';
import CRTable from 'components/base/CRTable';
import { Toast } from 'components/base/CRToast';
import AddCareerDialog from 'components/domain/dialog/AddCareerDialog';
import DeleteDialog from 'components/domain/dialog/DeleteDialog';
import { useDeleteEmployeeCareer } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { EmployeeCareerInfoDTO, EmployeeDTO } from 'types/api/employee';

import { STAFF_CAREER_LIST_TABLE_HEADER_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	items?: EmployeeCareerInfoDTO[] | null;
	offset?: number;
	currentEmployee?: EmployeeDTO;
}

function EmployeeCareerTable({
	items = [],
	offset = 0,
	currentEmployee,
}: Props): React.ReactElement {
	const { showDialog, hideDialog } = useDialog();
	const { mutate: deleteCareer } = useDeleteEmployeeCareer((client, returnData) => {
		if (returnData?.employeeId) {
			client.invalidateQueries([
				endpoint.getEmployeeCareerInfo.key,
				{
					employeeId: currentEmployee?.employeeId,
				},
			]);
			Toast.success('근무 경력이 삭제되었습니다.');
			hideDialog();
		}
	});
	const handleDeleteHealthCheckItem = async (item?: EmployeeCareerInfoDTO) => {
		if (!item) return;
		const deleteHealthCheck = async () => {
			if (!item) return;
			deleteCareer({
				employeeWorkCareerId: item.employeeWorkCareerId,
			});
		};
		showDialog(({ hideDialog }) => (
			<DeleteDialog
				title='근무 경력 삭제'
				content={`${currentEmployee?.korMemberNm} 님의 근무 경력을 삭제하겠습니다.`}
				hideDialog={hideDialog}
				cancelOption={{
					text: '취소',
				}}
				successOption={{
					text: '삭제',
					successCallback: deleteHealthCheck,
				}}
			/>
		));
	};

	const handleClickAddHealCheck = (item?: EmployeeCareerInfoDTO) => {
		showDialog(({ hideDialog }) => (
			<AddCareerDialog item={item} hideDialog={hideDialog} currentEmployee={currentEmployee} />
		));
	};

	const renderOption = (value: number, item?: EmployeeCareerInfoDTO) => (
		<S.ButtonContainer>
			<CRButton.Default
				size='xSmall'
				type='outlined'
				palette='gray'
				onClick={() => handleClickAddHealCheck(item)}>
				수정
			</CRButton.Default>
			<CRButton.Default
				size='xSmall'
				type='outlined'
				palette='primary'
				onClick={() => handleDeleteHealthCheckItem(item)}>
				삭제
			</CRButton.Default>
		</S.ButtonContainer>
	);

	const renderWorkDuration = (value: string, item?: EmployeeCareerInfoDTO) => (
		<span>
			{dayjs(item?.workStartDate).format('YYYY.MM.DD')} ~
			{item?.workEndDate ? dayjs(item?.workEndDate).format('YYYY.MM.DD') : ' 현재'}
		</span>
	);

	const renderRemark = (value: string) => (
		<S.TextContainer>
			{value?.split('\n').map((item) => <div key={v4()}>{item}</div>)}
		</S.TextContainer>
	);
	return (
		<S.Container>
			<CRTable.Root>
				<CRTable.Head heads={STAFF_CAREER_LIST_TABLE_HEADER_CONFIG} offset={offset} />
				<CRTable.Body>
					{items?.map((item) => (
						<CRTable.Row
							key={item.employeeWorkCareerId}
							item={item}
							renderKeys={['workStartDate', 'companyNm', 'remark', 'employeeWorkCareerId']}
							customRender={{
								workStartDate: renderWorkDuration,
								employeeWorkCareerId: renderOption,
								remark: renderRemark,
							}}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default EmployeeCareerTable;
