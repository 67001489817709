import dayjs from 'dayjs';
import * as XLSX from 'sheetjs-style';

import { GetEmployeesExcelDTO } from 'types/api';

import { isForeigner } from '../util';

export const exportEmployeesInfoTransfer = (fileName: string, data: GetEmployeesExcelDTO[]) => {
	const excelData = data.map(
		({
			workStateNm,
			dutyNm,
			employeeNm,
			birthDt,
			genderNm,
			mobilePhoneNo,
			rsdnNo,
			basAddr,
			detailAddr,
			regionNm1,
			regionNm2,
			regionNm3,
			addrLatitude,
			addrLongitude,
			managerNm,
			workMobilePhoneNo,
			centerNm,
			employeeId,
			centerId,
			zipCode,
			joinDate,
			resignDate,
			monthPerWorkHourCnt,
			dementiaEduCompleteYn,
			disabilityGradeCd,
		}) => ({
			근무상태: workStateNm || '',
			직무: dutyNm || '',
			직원성명: employeeNm || '',
			생년월일: birthDt || '',
			성별: genderNm || '',
			전화번호: mobilePhoneNo || '',
			'내/외국인': isForeigner(rsdnNo) ? '외국인' : '내국인',
			기본주소: basAddr || '',
			상세주소: detailAddr || '',
			지역명1: regionNm1 || '',
			지역명2: regionNm2 || '',
			지역명3: regionNm3 || '',
			입사일: joinDate ? dayjs(joinDate).format('YYYY-MM-DD') : '',
			퇴사일: resignDate ? dayjs(resignDate).format('YYYY-MM-DD') : '',
			월소정근로시간: monthPerWorkHourCnt || '',
			치매교육: dementiaEduCompleteYn || '',
			장애여부: disabilityGradeCd || '',
			위도: addrLatitude || '',
			경도: addrLongitude || '',
			담당자성명: managerNm || '',
			업무용휴대폰번호: workMobilePhoneNo || '',
			센터명: centerNm || '',
			직원ID: employeeId || '',
			센터ID: centerId || '',
			우편번호: zipCode || '',
		}),
	);

	const workSheet = XLSX.utils.json_to_sheet(excelData);

	const headerStyle = {
		fill: {
			fgColor: { rgb: 'DDDDDD' },
		},
		alignment: {
			horizontal: 'center',
		},
	};

	workSheet.A1.s = headerStyle;
	workSheet.B1.s = headerStyle;
	workSheet.C1.s = headerStyle;
	workSheet.D1.s = headerStyle;
	workSheet.E1.s = headerStyle;
	workSheet.F1.s = headerStyle;
	workSheet.G1.s = headerStyle;
	workSheet.H1.s = headerStyle;
	workSheet.I1.s = headerStyle;
	workSheet.J1.s = headerStyle;
	workSheet.K1.s = headerStyle;
	workSheet.L1.s = headerStyle;
	workSheet.M1.s = headerStyle;
	workSheet.N1.s = headerStyle;
	workSheet.O1.s = headerStyle;
	workSheet.P1.s = headerStyle;
	workSheet.Q1.s = headerStyle;
	workSheet.R1.s = headerStyle;
	workSheet.S1.s = headerStyle;
	workSheet.T1.s = headerStyle;
	workSheet.U1.s = headerStyle;
	workSheet.V1.s = headerStyle;
	workSheet.W1.s = headerStyle;
	workSheet.X1.s = headerStyle;
	workSheet.Y1.s = headerStyle;

	const keys = Object.values(excelData[0]);

	const columnStyles = keys.map((_, idx) => {
		const getMaxLength = String(keys[idx]).length;
		const minWidth = 13;

		if (idx === 7) {
			return {
				width: 30,
			};
		}

		return {
			width: Math.max(minWidth, getMaxLength * 2),
		};
	});

	workSheet['!cols'] = columnStyles;

	const wb = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(wb, workSheet, '입력정보');
	XLSX.writeFile(wb, `${fileName}.xlsx`);
	return true;
};
