import React, { useState } from 'react';

import CRTable from 'components/base/CRTable';
import AlarmTable from 'components/domain/table/AlarmTable';

import { AlarmMock } from './mock';
import * as S from './styles';

export default function AlarmListPage() {
	const [currentFilter, setCurrentFilter] = useState('all');

	const renderCustomFilter = (
		<S.CustomFilterContainer>
			<S.CustomFilterChip
				onClick={() => setCurrentFilter('all')}
				$isActive={currentFilter === 'all'}>
				전체
			</S.CustomFilterChip>
			<S.Divider />
			<S.CustomFilterRight>
				<S.CustomFilterChip
					onClick={() => setCurrentFilter('unread')}
					$isActive={currentFilter === 'unread'}>
					미확인
				</S.CustomFilterChip>
				<S.CustomFilterChip
					onClick={() => setCurrentFilter('read')}
					$isActive={currentFilter === 'read'}>
					확인
				</S.CustomFilterChip>
			</S.CustomFilterRight>
		</S.CustomFilterContainer>
	);

	return (
		<CRTable.BackBlind>
			<AlarmTable currentFilter={{}} items={AlarmMock} renderCustomFilter={renderCustomFilter} />
		</CRTable.BackBlind>
	);
}
