import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import RouterPath from 'common/router';
import { CheckOption } from 'components/base/Selections/type';
import ReservedMessageHistoryTable from 'components/domain/table/ReservedMessageHistoryTable';
import {
	useCenterManagers,
	useMyAccountInfo,
	useSendReservationHistory,
	useTemplateList,
} from 'lib/hook/react-query';
import usePageFilter from 'lib/hook/util/usePageFilter';
import { SendReservationHistoryContentDTO } from 'types/api/send';
import { PageInfo } from 'types/view/base';
import { Filter } from 'types/view/filter';

export default function ReservedMessageHistoryTab() {
	const {
		filters: currentFilter,
		setFilters: setCurrentFilter,
		pageInfo,
		setPageInfo,
	} = usePageFilter({});
	const [keyword, setKeyword] = useState('');
	const [searchValue, setSearchValue] = useState('');

	const {
		data: sendHistory,
		isLoading: reservedMEssageHistoryLoading,
		refetch: sendReservationHistoryRefetch,
	} = useSendReservationHistory({
		page: pageInfo.page,
		size: pageInfo.size,
		sendStartDate:
			currentFilter?.rangeFilter?.[0]?.value?.start &&
			dayjs(currentFilter?.rangeFilter[0].value.start).format('YYYY-MM-DDT00:00:00'),
		sendEndDate:
			currentFilter?.rangeFilter?.[0]?.value?.end &&
			dayjs(currentFilter?.rangeFilter[0].value.end).format('YYYY-MM-DDT23:59:59'),
		sendTitle: keyword,
		senderIds: currentFilter?.managerFilter?.map((item) => item.value),
		sendStateCds: currentFilter?.sendStatusFilter?.map((item) => item.value),
		sendTemplateIds: currentFilter?.templateTypeFilter?.map((item) => item.value),
		cancelYn: currentFilter?.sendStatusFilter?.[0]?.value,
	});

	const { data: templateList } = useTemplateList();

	const { data: myAccountInfo } = useMyAccountInfo();

	const { data: centerManagers } = useCenterManagers({
		centerId: myAccountInfo?.centerId,
	});

	const navigate = useNavigate();

	const handleSearchKeyword = (value: string) => setSearchValue(value);

	const handleSearch = () => {
		setPageInfo((prev) => ({
			...prev,
			page: 1,
		}));
		setKeyword(searchValue);
	};

	const handleChangePageInfo = (pageInfo: PageInfo) => {
		setPageInfo(pageInfo);
	};

	const dateFilter = useMemo(
		() =>
			({
				key: 'rangeFilter',
				type: 'calendar',
				placeholder: '기간',
			}) as Filter<CheckOption>,
		[],
	);

	const templateTypeFilter = useMemo(
		() =>
			({
				key: 'templateTypeFilter',
				type: 'multi',
				options: (templateList ?? []).map((item) => ({
					label: item.sendTemplateTitle,
					value: item.sendTemplateTypeCd,
				})),
				placeholder: '문서유형',
			}) as Filter<CheckOption>,
		[templateList],
	);

	const managerFilter = useMemo(
		() =>
			({
				key: 'managerFilter',
				type: 'multi',
				options:
					centerManagers?.map((item) => ({
						label: item.userNm,
						value: item.memberAccountId,
					})) ?? [],
				placeholder: '발송자',
			}) as Filter<CheckOption>,
		[centerManagers],
	);

	const sendStatusFilter = useMemo(
		() =>
			({
				key: 'sendStatusFilter',
				type: 'single',
				options: [
					{
						label: '대기',
						value: false,
					},
					{
						label: '취소',
						value: true,
					},
				],
				placeholder: '발송상태',
			}) as Filter<CheckOption>,
		[],
	);

	const handleClickRow = (item: SendReservationHistoryContentDTO) => {
		navigate(`${RouterPath.send().history.key}/${item.sendId}/detail`);
	};

	useEffect(() => {
		setPageInfo((prev) => ({
			...prev,
			totalPages: sendHistory?.totalPages || 0,
		}));
	}, [sendHistory?.totalPages]);

	// 사회복지사 기본필터를 자신으로 설정
	useLayoutEffect(() => {
		if (myAccountInfo && myAccountInfo?.positionCd === 'CMN103.10') {
			setCurrentFilter((prev) => {
				const alreadyManagerExist = Boolean(
					prev?.managerFilter?.find((manager) => manager.value === myAccountInfo.memberAccountId),
				);
				if (alreadyManagerExist) {
					return prev;
				}
				return {
					...prev,
					managerFilter: [
						...(prev?.managerFilter || []),
						{ value: myAccountInfo.memberAccountId, label: myAccountInfo.userNm },
					],
				};
			});
		}
	}, [myAccountInfo?.memberAccountId]);

	return (
		<ReservedMessageHistoryTable
			onRefresh={() => sendReservationHistoryRefetch()}
			isLoading={reservedMEssageHistoryLoading}
			filters={[dateFilter, templateTypeFilter, managerFilter, sendStatusFilter]}
			setCurrentFilter={setCurrentFilter}
			currentValue={currentFilter}
			items={sendHistory?.content ?? []}
			pageInfo={pageInfo}
			onChangePageInfo={handleChangePageInfo}
			searchValue={searchValue}
			onChangeSearchValue={handleSearchKeyword}
			onSearch={handleSearch}
			onClickRow={handleClickRow}
		/>
	);
}
