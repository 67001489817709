import Colors from 'common/colors';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import { FtimeEmployeeMonthScheduleInfoDTO } from 'types/dto/schedule';

export const socialWorkerInfo = (
	item: FtimeEmployeeMonthScheduleInfoDTO,
): InformationTableItemType[][] => [
	[
		{
			label: '직원명',
			value: item.korMemberNm,
			labelStyle: { width: '12rem', height: '3.3rem' },
			valueStyle: { width: '13.8rem' },
		},
		{
			label: '생년월일',
			value: item.birthDt ?? '-',
			labelStyle: { width: '12rem', height: '3.3rem' },
			valueStyle: { width: '13.8rem' },
		},
		{
			label: '성별',
			value: item.genderNm ?? '-',
			labelStyle: { width: '12rem', height: '3.3rem' },
			valueStyle: { width: '13.8rem' },
		},
		{
			label: '직무',
			value: item.dutyNm ?? '-',
			labelStyle: { width: '12rem', height: '3.3rem' },
			valueStyle: { width: '13.8rem' },
		},
	],
	[
		{
			label: '소속',
			value: item.centerNm ?? '-',
			labelStyle: {
				width: '12rem',
				borderTop: `0.01rem solid ${Colors.gray90}`,
			},
			valueStyle: {
				borderTop: `0.01rem solid ${Colors.gray90}`,
			},
			valueColSpan: 7,
		},
	],
];
