import React from 'react';

import CRCheckBox from 'components/base/Selections/CRCheckBox';

import * as S from './styles';

export interface Props {
	disabled?: boolean;
	value: string[];
	onChange?: (item: string[]) => void;
	dayStyle?: React.CSSProperties;
	showSelectAllButton?: boolean;
}

export enum DayOfTheWeek {
	Sun = 'CMN020.0',
	Mon = 'CMN020.1',
	Tue = 'CMN020.2',
	Wed = 'CMN020.3',
	Thu = 'CMN020.4',
	Fri = 'CMN020.5',
	Sat = 'CMN020.6',
}

export default function CRDayPicker({
	value,
	disabled = false,
	onChange,
	dayStyle,
	showSelectAllButton = false,
}: Props) {
	const selectedAll = value.length === 7;
	const displayDayOfWeek = (dayOfWeek: DayOfTheWeek) => {
		switch (dayOfWeek) {
			case DayOfTheWeek.Mon:
				return '월';
			case DayOfTheWeek.Tue:
				return '화';
			case DayOfTheWeek.Wed:
				return '수';
			case DayOfTheWeek.Thu:
				return '목';
			case DayOfTheWeek.Fri:
				return '금';
			case DayOfTheWeek.Sat:
				return '토';
			case DayOfTheWeek.Sun:
				return '일';
			default:
				return '';
		}
	};

	const handleClickDay = (item: DayOfTheWeek) => {
		if (!value || disabled) return;
		const newDays = value?.length ? [...value] : [];
		const matchedItemIndex = value.findIndex((day) => day === item);
		if (matchedItemIndex > -1) {
			newDays.splice(matchedItemIndex, 1);
		} else {
			newDays.push(item);
		}
		onChange?.(newDays);
	};

	return (
		<S.DayContainer>
			<S.DayOptionContainer>
				{[
					DayOfTheWeek.Mon,
					DayOfTheWeek.Tue,
					DayOfTheWeek.Wed,
					DayOfTheWeek.Thu,
					DayOfTheWeek.Fri,
					DayOfTheWeek.Sat,
					DayOfTheWeek.Sun,
				].map((item) => {
					const isSelected = value?.includes(item);
					return (
						<S.Day
							$isDisabled={disabled}
							$isSelected={isSelected}
							key={item}
							style={dayStyle}
							onClick={() => handleClickDay(item)}>
							{displayDayOfWeek(item)}
						</S.Day>
					);
				})}
			</S.DayOptionContainer>
			{showSelectAllButton ? (
				<CRCheckBox
					checked={selectedAll}
					onChange={() => (selectedAll ? onChange?.([]) : onChange?.(Object.values(DayOfTheWeek)))}>
					전체 선택
				</CRCheckBox>
			) : null}
		</S.DayContainer>
	);
}
