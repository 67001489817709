import React from 'react';

import dayjs from 'dayjs';

import { CRText } from 'components/base/CRText';
import { displayPhoneNumber } from 'lib';
import { GetLinkAccountsDTO } from 'types/api/userAccount';

import * as S from './styles';

interface Props {
	item: GetLinkAccountsDTO;
}

export default function AccountInfoCard({ item }: Props) {
	const name = `${item?.userNm || ''}${item?.dutyNm ? ` (${item.dutyNm})` : ''}`;
	const mobilePhoneNo = item?.mobilePhoneNo ? displayPhoneNumber(item.mobilePhoneNo) : '-';
	const birthDt = `생년월일: ${
		item?.birthDt ? `${dayjs(item.birthDt).format('YYYY.MM.DD')}` : '-'
	}`;
	return (
		<S.Container>
			<CRText text={name} color='gray10' typography='body' />
			<CRText text={mobilePhoneNo} color='gray60' typography='label' />
			<CRText text={birthDt} color='gray60' typography='label' />
		</S.Container>
	);
}
