import React from 'react';

import { v4 } from 'uuid';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRIcon from 'components/base/CRIcon';
import { CRText } from 'components/base/CRText';
import CRTextList from 'components/base/CRTextList';

import * as S from './styles';

interface Props {
	documentName: string;
	onSubmit: () => void;
	onCancel: () => void;
	listStyle?: 'ul' | 'ol';
}

function EDocConfirmDialog({ documentName, onCancel, onSubmit, listStyle = 'ul' }: Props) {
	return (
		<FlexContainer direction='column' gap='2rem' align='center' padding='2rem 1rem'>
			<FlexContainer direction='column' gap='1rem' align='center'>
				<CRIcon src={Assets.icon.checkCircle} width='3.6rem' height='3.6rem' />
				<FlexContainer wrap='wrap' justify='center'>
					<CRText typography='bodyB' text={documentName} margin=' 0 0.2rem 0 0' />
					<CRText text=' 을(를)' />
					<CRText color='red' text=' 검수완료' margin=' 0 0.4rem 0 0.4rem' />
					<CRText text='하시겠습니까?' />
				</FlexContainer>
			</FlexContainer>

			<FlexContainer direction='column' gap='1rem' align='center'>
				<S.TitleWrapper>
					<CRText typography='bodyB' text='최종 확인사항' />
				</S.TitleWrapper>
				<CRTextList
					listStyle={listStyle}
					items={[
						{
							id: v4(),
							item: (
								<CRText text="문서의 내용을 허위로 작성하게 되어 적발되는 경우 ‘공문서 조작'으로 인사위원회에 회부될 수 있습니다." />
							),
						},
						{
							id: v4(),
							item: (
								<CRText text='검수완료로 상태가 변경되면 이후 내용 수정이 불가합니다. 그래도 검수완료 처리 하시겠습니까?' />
							),
						},
					]}
				/>
			</FlexContainer>

			<FlexContainer direction='column' width='100%' gap='0.5rem'>
				<CRButton.Default onClick={onSubmit}>
					<CRText typography='bodyB' color='white' text='검수 완료' />
				</CRButton.Default>
				<CRButton.Default onClick={onCancel} palette='gray' type='tonal'>
					<CRText typography='bodyB' text='취소' />
				</CRButton.Default>
			</FlexContainer>
		</FlexContainer>
	);
}
export default EDocConfirmDialog;
