import React from 'react';

import { useEmployees, useMyAccountInfo } from 'lib/hook/react-query';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import { EditableRecipientFormTypes } from 'lib/provider/recipient';

import RecipientScheduleAutomationForm from './RecipientScheduleAutomationForm';
import RecipientScheduleAutomationView from './RecipientScheduleAutomationView';
import * as S from './styles';

function RecipientScheduleAutomationFormView(): React.ReactElement {
	const {
		currentRecipientScheduleAutomations,
		isFormEditable,
		startChangeEditSection,
		cancelChangeEditSection,
		isEditSectionChanging,
		managerFilter,
	} = useRecipientPage();
	const { data: myAccountInfo } = useMyAccountInfo();

	const { data: employees } = useEmployees({
		centerIds: myAccountInfo?.centerId ? [myAccountInfo.centerId] : [],
	});

	const onStartChangeSection = () => {
		startChangeEditSection(EditableRecipientFormTypes.일정등록자동화);
	};

	const onCancelChangeSection = () => {
		cancelChangeEditSection();
	};

	return (
		<S.Container>
			{isFormEditable(EditableRecipientFormTypes.일정등록자동화) ? (
				<RecipientScheduleAutomationForm
					employees={employees || []}
					items={currentRecipientScheduleAutomations}
					centerManagers={managerFilter?.options || []}
					onStartChangeSection={onStartChangeSection}
					onCancelChangeSection={onCancelChangeSection}
					isEditSectionChanging={isEditSectionChanging}
				/>
			) : (
				<RecipientScheduleAutomationView
					employees={employees || []}
					centerManagers={managerFilter?.options || []}
					items={currentRecipientScheduleAutomations}
					onChangeMode={onStartChangeSection}
				/>
			)}
		</S.Container>
	);
}

export default RecipientScheduleAutomationFormView;
