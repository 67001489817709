import { styled } from 'styled-components';

export const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - 4.8rem);
	gap: 1.6rem;
	overflow: scroll;
	padding: 0 2.4rem;
`;

export const HolidayTimeContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	gap: 0.8rem;
`;

export const RightButtonContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: flex-end;
	gap: 0.8rem;
`;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 0.8rem;
`;
