import React, { ReactNode, useEffect } from 'react';

import Assets from 'assets';
import useDaumPostcode, { DaumPostcodeData } from 'lib/hook/util/useDaumPostCode';
import useKakaoLocation from 'lib/hook/util/useKakaoLocation';

import * as S from './styles';

interface Props {
	disabled?: boolean;
	baseAddress?: string;
	detailAddress?: string;
	onChangeBaseAddress?: (value: DaumPostcodeData) => void;
	onChangeDetailAddress?: (detail: string) => void;
	addOnBottom?: ReactNode;
	status?: 'default' | 'error';
	type?: 'default' | 'table';
}

function CRAddressSearch({
	disabled = false,
	baseAddress,
	detailAddress,
	onChangeBaseAddress,
	onChangeDetailAddress,
	addOnBottom,
	status,
	type = 'table',
}: Props): React.ReactElement {
	const { openPostcodeModal, resPostCode, reset } = useDaumPostcode();
	const { getGeolocation } = useKakaoLocation();
	useEffect(() => {
		if (resPostCode?.address) {
			getGeolocation(resPostCode.address)
				.then((result) => {
					onChangeBaseAddress?.({
						...resPostCode,
						latitude: Number(result.latitude),
						longitude: Number(result.longitude),
					});
				})
				.catch(() => {
					onChangeBaseAddress?.(resPostCode);
				});
		}
	}, [resPostCode]);

	useEffect(() => {
		reset();
	}, [baseAddress]);

	return (
		<S.Container>
			<S.AddressContainer
				$type={type}
				$disabled={disabled}
				$error={status === 'error'}
				onClick={disabled ? undefined : openPostcodeModal}>
				<input
					style={{
						caretColor: 'transparent',
					}}
					placeholder='주소 검색'
					disabled={disabled}
					// defaultValue={baseAddress}
					value={baseAddress ?? ''}
				/>
				{!disabled && <img src={Assets.icon.search} alt='주소 검색' role='presentation' />}
			</S.AddressContainer>
			{onChangeDetailAddress && (
				<S.AddressContainer $type={type} $disabled={disabled} $error={status === 'error'}>
					<input
						disabled={disabled}
						placeholder='상세주소 입력'
						onChange={(e) => onChangeDetailAddress?.(e.target.value)}
						value={detailAddress ?? ''}
					/>
				</S.AddressContainer>
			)}

			<S.HelperText status={status}>{addOnBottom}</S.HelperText>
		</S.Container>
	);
}

export default CRAddressSearch;
