import React from 'react';

import Assets from 'assets';

import * as S from './styles';

function RdAccordionTrigger(
	{ children, ...rest }: React.PropsWithChildren<React.AnchorHTMLAttributes<HTMLAnchorElement>>,
	ref: React.ForwardedRef<HTMLAnchorElement>,
): React.ReactElement<HTMLAnchorElement> {
	const isOpen = (rest as { 'data-state': string })['data-state'] === 'open';
	return (
		<S.Container ref={ref} {...rest}>
			<S.Title>{children}</S.Title>
			<S.Icon
				src={isOpen ? Assets.icon.keyboardArrowTop : Assets.icon.keyboardArrowBottom}
				alt={isOpen ? 'keyboardArrowTop' : 'keyboardArrowBottom'}
			/>
		</S.Container>
	);
}

export default React.forwardRef(RdAccordionTrigger);
