import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import RouterPath from 'common/router';
import CRHeader from 'components/base/CRHeader';
import CRLeftSideNavigation from 'components/base/CRLeftSideNavigation';
import { myTabMenuAdapter } from 'lib/adapter/common';
import { useMyMenuInfo } from 'lib/hook/react-query';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import SideModalProvider from 'lib/provider/ui/SideModalProvider';

import * as S from './styles';

interface IProps {
	HelperComponent?: React.ReactElement;
}

function CRLayout({
	children,
	HelperComponent,
}: React.PropsWithChildren<IProps>): React.ReactElement {
	const {
		lGNBType,
		breadCrumbs,
		isRightSideFolded,
		setIsRightSideFolded,
		isLeftSideFolded,
		setIsLeftSideFolded,
		isHeld,
		setIsHeld,
		blockLeftHoverEvent,
		showQuickSearch,
		newConsultingMode,
		customBackHandler,
		hideDialog,
	} = useGlobalLayout();
	const location = useLocation();
	const navigate = useNavigate();
	const leftSideNavigationRef = useRef<HTMLDivElement>(null);
	const [shouldRenderRightContents, setShouldRenderRight] = useState(false);

	const { data: myTabMenu } = useMyMenuInfo(myTabMenuAdapter);
	// TODO: 디바운싱 적용 필요
	const handleMouseOverLeftSide = (event: MouseEvent<HTMLDivElement>) => {
		if (blockLeftHoverEvent) return;
		const boundaryX = 10;
		const boundaryY = 56;
		const isActiveArea = event.clientY >= boundaryY && event.clientX <= boundaryX;

		if (!isActiveArea) return;
		if (!isLeftSideFolded) return;

		setIsLeftSideFolded(false);
	};

	const toggleHelper = () => {
		setIsRightSideFolded((prev) => !prev);
	};

	const toggleLeftSideNavigation = useCallback(() => {
		if (isHeld) {
			setIsHeld((prev) => !prev);
			setIsLeftSideFolded((prev) => !prev);
		} else {
			setIsHeld(true);
			setIsLeftSideFolded(false);
		}
	}, [isHeld]);

	const handleMouseOverMenu = useCallback(() => {
		setIsLeftSideFolded(false);
	}, []);

	const handleMouseOutMenu = useCallback(
		(event?: MouseEvent<HTMLDivElement>) => {
			if (leftSideNavigationRef.current?.contains(event?.relatedTarget as Node) || isHeld) return;
			setIsLeftSideFolded(true);
		},
		[isHeld],
	);

	const handleMouseLeaveLeftSideNavigation = () => {
		if (isHeld) return;
		setIsLeftSideFolded(true);
	};

	const handleClickNavigation = (path: string) => {
		if (path === RouterPath.search()) {
			showQuickSearch();
			return;
		}
	};

	const handleClickBack = () => {
		if (customBackHandler) {
			customBackHandler?.();
		} else {
			navigate(-1);
		}
	};

	// 내부의 helper모달이 접혔을때 unmount 시켜주는 코드.
	// timeout 200ms는 transition의 duration 으로 하드코딩함 (일부러 단순하게 처리).
	useEffect(() => {
		if (isRightSideFolded === false) {
			setShouldRenderRight(true);
			return;
		}
		const timer = setTimeout(() => setShouldRenderRight(false), 200);

		// eslint-disable-next-line consistent-return
		return () => clearTimeout(timer);
	}, [isRightSideFolded]);

	useEffect(
		() => () => {
			hideDialog();
		},
		[location],
	);

	return (
		<S.Container onMouseMove={handleMouseOverLeftSide}>
			<CRHeader
				breadCrumbs={breadCrumbs}
				onClickMenu={toggleLeftSideNavigation}
				onClickBack={handleClickBack}
				onClickHelper={toggleHelper}
				onMouseOverMenu={handleMouseOverMenu}
				onMouseOutMenu={handleMouseOutMenu}
			/>
			<S.ContentContainer>
				<S.LeftContainer
					ref={leftSideNavigationRef}
					$isFolded={isLeftSideFolded}
					$navMode={lGNBType}
					onMouseLeave={handleMouseLeaveLeftSideNavigation}>
					<CRLeftSideNavigation items={myTabMenu?.finalResult} onClick={handleClickNavigation} />
				</S.LeftContainer>
				<S.MainContentContainer $isLeftFolded={isLeftSideFolded} $isRightFolded={isRightSideFolded}>
					<S.MainContentView>
						<SideModalProvider>{children}</SideModalProvider>
					</S.MainContentView>
				</S.MainContentContainer>
				<S.RightContainer $newConsultingMode={newConsultingMode} $isFolded={isRightSideFolded}>
					{shouldRenderRightContents && HelperComponent}
				</S.RightContainer>
			</S.ContentContainer>
		</S.Container>
	);
}

export default React.memo(CRLayout);
