import React from 'react';

import CRButton from '../CRButton';
import { IButtonProps } from '../CRButton/CRDefaultButton';
import * as S from './styles';

interface IProps {
	headerTitle?: string;
	buttons?: IButtonProps[];
}

function CRPageHeader({ headerTitle = '', buttons = [] }: IProps): React.ReactElement {
	return (
		<S.Container>
			<S.HeaderTitle>{headerTitle}</S.HeaderTitle>
			<S.ButtonContainer>
				{buttons.map((button) => (
					<CRButton.Default {...button} />
				))}
			</S.ButtonContainer>
		</S.Container>
	);
}

export default React.memo(CRPageHeader);
