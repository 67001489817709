import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import dayjs from 'dayjs';

import CRBanner from 'components/base/CRBanner';
import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { CRText } from 'components/base/CRText';
import { useCommonCenters, useMoveFullTimeEmployeeCenter } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { FullTimeEmployeeDTO } from 'types/dto/full-time-employee';

import DefaultDialog from '../DefaultDialog';
import * as S from './styles';

interface Props {
	currentFullTimeEmployee?: FullTimeEmployeeDTO;
	hideDialog?: () => void;
}

function FullTimeEmployeeCenterChangeDialog({
	currentFullTimeEmployee,
	hideDialog,
}: Props): React.ReactElement {
	const { showDialog } = useDialog();
	const { data: centerList = [] } = useCommonCenters();
	const moveFullTimeEmployeeCenter = useMoveFullTimeEmployeeCenter((client) => {
		client.invalidateQueries([
			endpoint.getFullTimeEmployeeDetailInfo.key,
			{
				centerId: currentFullTimeEmployee?.centerId,
				employeeId: currentFullTimeEmployee?.employeeId,
				memberId: currentFullTimeEmployee?.memberId,
			},
		]);
		hideDialog?.();
	});
	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<{ center: string; date: Date }>({
		mode: 'onChange',
	});

	const centerOptions = useMemo(
		() =>
			(centerList ?? []).map((center) => ({
				label: center.value,
				value: center.id,
			})),
		[centerList],
	);

	const onSubmit = async (data: { center: string; date: Date }) => {
		if (!currentFullTimeEmployee) return;

		showDialog(() => (
			<DefaultDialog
				hideDialog={hideDialog}
				title={`${currentFullTimeEmployee.korMemberNm} 님의 정보를 ${currentFullTimeEmployee.centerNm}로\n이동하시겠습니까?`}
				content={
					<CRText highlightText={['더 이상 해당 직원의 정보를 조회할 수 없습니다.']}>
						{
							'센터 이동 후 더 이상 해당 직원의 정보를 조회할 수 없습니다.\n또한 해당 직원은 기존 센터 ERP에 접근할 수 없습니다.'
						}
					</CRText>
				}
				cancelOption={{
					text: '닫기',
				}}
				successOption={{
					text: '센터 이동',
					type: 'outlined',
					successCallback: async () => {
						moveFullTimeEmployeeCenter.mutateAsync({
							memberId: currentFullTimeEmployee?.memberId,
							employeeId: currentFullTimeEmployee?.employeeId,
							beforeCenterId: currentFullTimeEmployee?.centerId,
							centerMoveDate: dayjs(data.date).format('YYYY-MM-DD hh:mm:ss'),
							afterCenterId: Number(data.center),
						});
						hideDialog?.();
					},
				}}
			/>
		));
	};

	return (
		<CRDialog
			type='S'
			title='센터 이동'
			body={
				<S.Form onSubmit={handleSubmit(onSubmit)}>
					<CRBanner
						type='warning'
						content='센터 이동 시 현재 센터에서 더 이상 해당 직원을 조회할 수 없습니다. (이동 센터에서 조회 가능)'
						highlightText={['현재 센터에서 더 이상 해당 직원을 조회할 수 없습니다.']}
					/>
					<CRInputLabel label='소속 센터' isRequired>
						<CRInput.Selector
							autoComplete
							currentValue={{
								value: currentFullTimeEmployee?.centerId,
								label: currentFullTimeEmployee?.centerNm,
							}}
							items={[
								{
									value: currentFullTimeEmployee?.centerId,
									label: currentFullTimeEmployee?.centerNm,
								},
							]}
							disabled
							placeholder='소속 센터'
						/>
					</CRInputLabel>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel isRequired label='이동일'>
								<CRInput.DatePicker
									placeholder='이동일 입력'
									onChangeValue={onChange}
									minDate={dayjs().add(1, 'day').toDate()}
									popupPosition={{ y: -100 }}
									value={value}
								/>
							</CRInputLabel>
						)}
						name='date'
						control={control}
					/>
					<CRInputLabel label='이동할 센터' isRequired>
						<Controller
							render={({ field: { onChange, value, ref }, formState: { errors } }) => (
								<CRInput.Selector
									onChangeValue={(item) => onChange(item.value)}
									currentValue={centerOptions.find((center) => center.value === value)}
									items={centerOptions}
									placeholder='이동할 센터 선택'
								/>
							)}
							name='center'
							control={control}
						/>
					</CRInputLabel>
				</S.Form>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						disabled={!isValid}
						palette='primary'
						size='default'
						onClick={handleSubmit(onSubmit)}>
						센터 이동
					</CRButton.Default>
				</S.ButtonContainer>
			}
			onClickClose={hideDialog}
		/>
	);
}

export default React.memo(FullTimeEmployeeCenterChangeDialog);
