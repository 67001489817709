import React, { ChangeEvent, useEffect, useState } from 'react';

import { Dayjs } from 'dayjs';

import CRInput from 'components/base/CRInput';
import CRFilterGroup from 'components/base/CRInput/CRFilterGroup';
import { CRText } from 'components/base/CRText';
import EmployeeWorkListTable from 'components/domain/table/EmployeeWorkListTable';
import { commonCodeAdapter } from 'lib/adapter/common';
import { employeeWorkInfoDataAdapter } from 'lib/adapter/workSchedule';
import { useCommonCodes, useEmployeeWorkInfoList } from 'lib/hook/react-query';
import { EmployeeWorkInfoViewType } from 'types/view/employee';
import { Filter } from 'types/view/filter';

import * as S from './styles';

interface Props {
	date: Dayjs;
	centerId: number;
	handleClickEmployeeItem: (item: EmployeeWorkInfoViewType) => void;
	currentEmployee?: EmployeeWorkInfoViewType;
}

function LeftLayoutContent({ date, centerId, currentEmployee, handleClickEmployeeItem }: Props) {
	const [searchText, setSearchText] = useState<string>('');
	const [currentFilter, setCurrentFilter] = useState<{
		dutyFilter: { label: string; value: any }[];
	}>({ dutyFilter: [] });

	const { data: commonCodes = { CMN070: [] } } = useCommonCodes(
		{
			comCdGroupNms: ['CMN070'],
		},
		commonCodeAdapter,
	);

	const { data: employeeWorkInfoList } = useEmployeeWorkInfoList(
		{
			centerId,
			yearMonth: date.format('YYYYMM') as string,
			dutyCds: currentFilter?.dutyFilter?.map((item) => item.value),
		},
		employeeWorkInfoDataAdapter,
	);

	const filteredEmployeeList: EmployeeWorkInfoViewType[] | undefined = employeeWorkInfoList?.filter(
		(item) =>
			item.korMemberNm.toLowerCase().includes(searchText.toLowerCase()) ||
			item.mobilePhoneNo?.includes(searchText) ||
			item.birthDt?.includes(searchText.replace(/\./g, '')),
	);

	const handleChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchText(event.target.value);
	};

	const dutyFilterOptions = {
		key: 'dutyFilter',
		type: 'multi',
		options: commonCodes.CMN070.filter((option) => option.data?.etcDesc1 === '1'),
		placeholder: '직무',
	} as Filter<{ label: string; value: string }>;

	useEffect(() => {
		if (!filteredEmployeeList) return;
		const indexOfMatchEmployee = filteredEmployeeList.findIndex(
			(item) => item.employeeId === currentEmployee?.employeeId,
		);
		const currentIndex = indexOfMatchEmployee < 0 ? 0 : indexOfMatchEmployee;

		handleClickEmployeeItem(filteredEmployeeList[currentIndex]);
	}, [filteredEmployeeList]);

	return (
		<S.LeftContentContainer>
			<CRText typography='h4' text='직원 목록' />
			<CRInput.Search
				placeholder='직원명, 생년월일, 전화번호 검색'
				value={searchText}
				onChange={handleChangeSearchText}
			/>
			<CRFilterGroup
				filters={[dutyFilterOptions]}
				currentFilter={currentFilter}
				setCurrentFilter={setCurrentFilter}
			/>
			<S.TableContainer>
				<EmployeeWorkListTable
					items={filteredEmployeeList ?? []}
					searchValue={searchText}
					onClickRow={handleClickEmployeeItem}
					currentItem={currentEmployee}
				/>
			</S.TableContainer>
		</S.LeftContentContainer>
	);
}

export default LeftLayoutContent;
