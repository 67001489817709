import React, { useMemo } from 'react';

import CRButton from 'components/base/CRButton';
import { CRText } from 'components/base/CRText';
import { displayPhoneNumber } from 'lib';
import { useCommonCodes } from 'lib/hook/react-query';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import { ServiceTime } from 'types/api/common';
import { RecipientOtherServiceFormViewType } from 'types/view/recipient';

import * as S from './styles';

interface Props {
	items?: RecipientOtherServiceFormViewType[];
	onChangeMode: () => void;
}

function RecipientOtherServiceView({ items, onChangeMode }: Props): React.ReactElement {
	const { currentRecipientStatus } = useRecipientPage();
	const { data: commonCodes = [] } = useCommonCodes({ comCdGroupNms: ['CMN006', 'CMN020'] });

	const serviceCommonCodes = useMemo(
		() => (commonCodes ?? [])?.filter((item) => item.comCdGroupNm === 'CMN006'),
		[commonCodes],
	);

	const dayCommonCodes = useMemo(
		() => (commonCodes ?? [])?.filter((item) => item.comCdGroupNm === 'CMN020'),
		[commonCodes],
	);

	const renderServiceTime = (serviceTime: ServiceTime) => {
		const dateString = serviceTime.days
			.map(
				(item) =>
					dayCommonCodes.find((code) => code.comCdId === item.otherServiceUseDayCd)
						?.korComCdAliasNm,
			)
			.join(', ');
		const startTimeString = `${serviceTime.otherServiceUseStartTime.slice(
			0,
			2,
		)}:${serviceTime.otherServiceUseStartTime.slice(2, 4)}`;
		const endTimeString = `${serviceTime.otherServiceUseEndTime.slice(
			0,
			2,
		)}:${serviceTime.otherServiceUseEndTime.slice(2, 4)}`;

		return `${dateString} ${startTimeString}~${endTimeString}`;
	};

	const isResign = currentRecipientStatus?.status === 'CMN058.30';
	const formatIndex = (number: number) => `${number + 1}`.padStart(2, '0');

	return (
		<S.Container>
			<S.Label>
				타급여
				{!isResign && (
					<S.ButtonContainer>
						<CRButton.Default size='xSmall' type='outlined' palette='gray' onClick={onChangeMode}>
							편집
						</CRButton.Default>
					</S.ButtonContainer>
				)}
			</S.Label>
			{items && items?.length > 0 ? (
				// eslint-disable-next-line react/jsx-no-useless-fragment
				<>
					{items?.map((item, index) => (
						<>
							<S.ItemHeader>{`타급여 ${formatIndex(index)}`}</S.ItemHeader>
							<S.Table $isDisabled={isResign}>
								<S.TableRow>
									<S.TableLabelColumn style={{ width: '10.4rem' }}>센터명</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }}>
										{item?.centerName ?? '-'}
									</S.TableValueColumn>
									<S.TableLabelColumn style={{ width: '10.4rem' }} rowSpan={3}>
										비고
									</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }} rowSpan={3}>
										{item?.memo ?? '-'}
									</S.TableValueColumn>
								</S.TableRow>
								<S.TableRow>
									<S.TableLabelColumn style={{ width: '10.4rem' }}>전화번호</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }}>
										{item?.phoneNumber ? displayPhoneNumber(item?.phoneNumber) : '-'}
									</S.TableValueColumn>
								</S.TableRow>
								<S.TableRow>
									<S.TableLabelColumn style={{ width: '10.4rem' }}>타급여 종류</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }}>
										{serviceCommonCodes?.find((code) => code.comCdId === item?.otherService)
											?.korComCdAliasNm || '-'}
									</S.TableValueColumn>
								</S.TableRow>
								<S.TableRow>
									<S.TableLabelColumn style={{ width: '10.4rem' }}>이용일시</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }} colSpan={3}>
										{item?.times.map((item) => (
											<S.ServiceTimeText>{renderServiceTime(item)} </S.ServiceTimeText>
										)) || '-'}
									</S.TableValueColumn>
								</S.TableRow>
							</S.Table>
						</>
					))}
				</>
			) : (
				<S.EmptyContainer>
					<CRText typography='label' color='gray60' text='이용중인 타급여가 없습니다.' />
				</S.EmptyContainer>
			)}
		</S.Container>
	);
}

export default RecipientOtherServiceView;
