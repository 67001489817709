import React from 'react';

import dayjs from 'dayjs';

import CRDialog from 'components/base/CRDialog';
import CRInputLabel from 'components/base/CRInputLabel';
import InformationTable from 'components/ui/InformationTable';
import { displayBirthDay } from 'lib';
import { RecipientPendingSchedule } from 'types/api/schedule';
import { Recipient } from 'types/view/recipient';

import * as S from './styles';

interface Props {
	currentRecipient?: Recipient;
	holding?: RecipientPendingSchedule;
	onClickClose?: () => void;
}

function RecipientAdmissionToHospitalDetailDialog({
	currentRecipient,
	holding,
	onClickClose,
}: Props): React.ReactElement {
	return (
		<CRDialog
			type='S'
			title='입원 상세'
			body={
				<S.Container>
					<CRInputLabel label='기본 정보'>
						<InformationTable
							items={[
								[
									{
										label: '수급자명',
										value: currentRecipient?.name ?? '',
									},
									{
										label: '상태',
										value: currentRecipient?.state ?? '',
									},
								],
								[
									{
										label: '생년월일',
										value: displayBirthDay(currentRecipient?.birthday) ?? '',
									},
									{
										label: '사회복지사',
										value: currentRecipient?.managerName ?? '',
									},
								],
							]}
						/>
					</CRInputLabel>
					<CRInputLabel label='입원 이력'>
						<InformationTable
							items={[
								[
									{
										label: '입원 기간',
										type: 'label',
										labelStyle: { width: '21.6rem' },
									},
									{
										label: '비고',
										type: 'label',
										labelStyle: { width: '21.6rem' },
									},
								],
								[
									{
										value: `${dayjs(holding?.recipientPendingStartDate).format(
											'YYYY.MM.DD',
										)}~${dayjs(holding?.recipientPendingEndDate).format('YYYY.MM.DD')}`,
										type: 'value',
									},
									{
										value: holding?.recipientPendingDesc || '-',
										type: 'value',
									},
								],
							]}
						/>
					</CRInputLabel>
				</S.Container>
			}
			onClickClose={onClickClose}
		/>
	);
}

export default React.memo(RecipientAdmissionToHospitalDetailDialog);
