import { CheckOption } from 'components/base/Selections/type';
import {
	ScheduleDetailCenterItem,
	ScheduleDetailItem,
	ScheduleDetailOtherCorpWork,
	ScheduleDetailOtherServicesUses,
	ScheduleRecording,
} from 'types/api/schedule';

export enum ScheduleCategory {
	HOLIDAYS = 'holidays',
	HOLDING = 'holding',
	MY_CENTER = 'myCenter',
	CARING_CENTER = 'caringCenter',
	OTHER = 'otherCorpWorks',
}

export type ProvideServiceType = '요양' | '목욕' | '간호';

export type ScheduleItemType<T = any> = {
	kind: ScheduleCategory;
	title: string;
	name?: string;
	startTime?: string;
	endTime?: string;
	centerId?: number;
	data?: T;
};

export type ScheduleType = {
	date: string;
	hasError: boolean;
	items: ScheduleItemType[];
};

export type ProcessedSchedule = {
	schedules: ScheduleType[];
	lastSyncUpdate?: string;
};

export type ProcessedEmployeeScheduleDetail = {
	employee?: ScheduleDetailItem[];
	recipients?: ScheduleDetailItem[];
	caringCenter: ScheduleDetailCenterItem[];
	myCenter: ScheduleDetailCenterItem[];
	otherCorpWorks: ScheduleDetailOtherCorpWork[];
};

export type ProcessedRecipientScheduleDetail = {
	employees?: ScheduleDetailItem[];
	recipient?: ScheduleDetailItem[];
	caringCenter: ScheduleDetailCenterItem[];
	myCenter: ScheduleDetailCenterItem[];
	otherServiceUses: ScheduleDetailOtherServicesUses[];
};

export type EmployeeScheduleDetailCenterItem = {
	scheduleId: string | number;
	service?: CheckOption;
	employee?: CheckOption;
	recipient?: CheckOption;
	deleteYn: boolean;
	time: {
		startTime: string;
		endTime: string;
	};
};

export type RecipientScheduleDetailCenterItem = {
	scheduleId: number | string;
	scheduleId2?: number | string;
	service?: CheckOption;
	employee?: CheckOption;
	employee2?: CheckOption;
	deleteYn: boolean;
	time: {
		startTime: string;
		endTime: string;
	};
};

export type EmployeeScheduleDetailForm = {
	items: EmployeeScheduleDetailCenterItem[];
};

export type RecipientScheduleDetailForm = {
	items: RecipientScheduleDetailCenterItem[];
};

export type ScheduleSynchronizationFormType = {
	yearMonth: Date;
};

// 태그 오류 관리

export type TagErrorDetailFormType = {
	errorYN: CheckOption[];
	scheduleTime: {
		startTime: string;
		endTime: string;
	};
	rfidTime: {
		startTime: string;
		endTime: string;
	};
};

// 기록지 데이터
export type RecordingSheetItem = ScheduleRecording & {
	id: string;
	employeeNm2: string;
	employeeMobilePhoneNo2: string;
	sendStatus: string;
	sendUseYn: boolean;
};
