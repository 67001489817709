import React from 'react';
import { Controller } from 'react-hook-form';

import CRInput from 'components/base/CRInput';
import CRRequiredMark from 'components/base/CRRequiredMark';
import { CRText } from 'components/base/CRText';
import useWorkExecuteInspectionLog from 'lib/hook/view/workExecuteInspectionLog/useWorkExecuteInspectionLog';

import * as S from './styles';

interface Props {
	disabled: boolean;
}

function IASalaryOfferPlanForm({ disabled }: Props): React.ReactElement {
	const { form } = useWorkExecuteInspectionLog();

	return (
		<S.Container>
			<S.TableTitle>
				<CRText text='욕구사정' color='gray10' typography='label' />
				<CRRequiredMark />
			</S.TableTitle>
			<S.TableContainer>
				<S.Table>
					<S.TableHeader>
						<S.TableHeaderColumn style={{ width: '13rem' }}>항목</S.TableHeaderColumn>
						<S.TableHeaderColumn style={{ width: '100%' }}>욕구사정</S.TableHeaderColumn>
					</S.TableHeader>
					<S.TableBody>
						<S.TableBodyRow>
							<S.TableBodyColumn>
								<CRText text='① 신체상태' typography='body' color='gray60' />
							</S.TableBodyColumn>
							<S.TableBodyColumn>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInput.TextArea
											disabled={disabled}
											onChange={onChange}
											value={value}
											fixedHeight
											height='14.4rem'
											maxLength={1000}
											placeholder='신체상태 입력'
										/>
									)}
									name='physicalConditionDesc'
									control={form?.control}
								/>
							</S.TableBodyColumn>
						</S.TableBodyRow>
						<S.TableBodyRow>
							<S.TableBodyColumn>
								<CRText text='② 질병' typography='body' color='gray60' />
							</S.TableBodyColumn>
							<S.TableBodyColumn>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInput.TextArea
											disabled={disabled}
											onChange={onChange}
											value={value}
											fixedHeight
											height='14.4rem'
											maxLength={1000}
											placeholder='질병 입력'
										/>
									)}
									name='diseaseDesc'
									control={form?.control}
								/>
							</S.TableBodyColumn>
						</S.TableBodyRow>
						<S.TableBodyRow>
							<S.TableBodyColumn>
								<CRText text='③ 인지상태' typography='body' color='gray60' />
							</S.TableBodyColumn>
							<S.TableBodyColumn>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInput.TextArea
											disabled={disabled}
											onChange={onChange}
											value={value}
											fixedHeight
											height='14.4rem'
											maxLength={1000}
											placeholder='인지상태 입력'
										/>
									)}
									name='recogStateDesc'
									control={form?.control}
								/>
							</S.TableBodyColumn>
						</S.TableBodyRow>
						<S.TableBodyRow>
							<S.TableBodyColumn>
								<CRText text='④ 의사소통' typography='body' color='gray60' />
							</S.TableBodyColumn>
							<S.TableBodyColumn>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInput.TextArea
											disabled={disabled}
											onChange={onChange}
											value={value}
											fixedHeight
											height='14.4rem'
											maxLength={1000}
											placeholder='의사소통 입력'
										/>
									)}
									name='communicationDesc'
									control={form?.control}
								/>
							</S.TableBodyColumn>
						</S.TableBodyRow>
						<S.TableBodyRow>
							<S.TableBodyColumn>
								<CRText text='⑤ 영양상태' typography='body' color='gray60' />
							</S.TableBodyColumn>
							<S.TableBodyColumn>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInput.TextArea
											disabled={disabled}
											onChange={onChange}
											value={value}
											fixedHeight
											height='14.4rem'
											maxLength={1000}
											placeholder='영양상태 입력'
										/>
									)}
									name='nutritionalStateDesc'
									control={form?.control}
								/>
							</S.TableBodyColumn>
						</S.TableBodyRow>
						<S.TableBodyRow>
							<S.TableBodyColumn>
								<CRText text='⑥ 가족 및 환경' typography='body' color='gray60' />
							</S.TableBodyColumn>
							<S.TableBodyColumn>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInput.TextArea
											disabled={disabled}
											onChange={onChange}
											value={value}
											fixedHeight
											height='14.4rem'
											maxLength={1000}
											placeholder='가족 및 환경 입력'
										/>
									)}
									name='familyEnvironmentDesc'
									control={form?.control}
								/>
							</S.TableBodyColumn>
						</S.TableBodyRow>
						<S.TableBodyRow>
							<S.TableBodyColumn>
								<CRText text='종합 의견' typography='body' color='gray60' />
							</S.TableBodyColumn>
							<S.TableBodyColumn>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInput.TextArea
											disabled={disabled}
											onChange={onChange}
											value={value}
											fixedHeight
											height='14.4rem'
											maxLength={2000}
											placeholder='종합 의견 입력'
										/>
									)}
									name='totalOpinionDesc'
									control={form?.control}
								/>
							</S.TableBodyColumn>
						</S.TableBodyRow>
					</S.TableBody>
				</S.Table>
			</S.TableContainer>
			<S.TableTitle>
				<CRText text='급여제공계획' color='gray10' typography='label' />
				<CRRequiredMark />
			</S.TableTitle>
			<S.TableContainer>
				<S.Table>
					<S.TableHeader>
						<S.TableHeaderColumn style={{ width: '13rem' }}>항목</S.TableHeaderColumn>
						<S.TableHeaderColumn style={{ width: '100%' }}>급여제공계획</S.TableHeaderColumn>
					</S.TableHeader>
					<S.TableBody>
						<S.TableBodyRow>
							<S.TableBodyColumn>
								<CRText text='급여목표' typography='body' color='gray60' />
							</S.TableBodyColumn>
							<S.TableBodyColumn>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInput.TextArea
											disabled={disabled}
											onChange={onChange}
											value={value}
											fixedHeight
											height='14.4rem'
											maxLength={2000}
											placeholder='급여목표 입력'
										/>
									)}
									name='salaryGoalDesc'
									control={form?.control}
								/>
							</S.TableBodyColumn>
						</S.TableBodyRow>
						<S.TableBodyRow>
							<S.TableBodyColumn>
								<CRText text='필요 급여내용' typography='body' color='gray60' />
							</S.TableBodyColumn>
							<S.TableBodyColumn>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInput.TextArea
											disabled={disabled}
											onChange={onChange}
											value={value}
											fixedHeight
											height='14.4rem'
											maxLength={2000}
											placeholder='필요 급여내용 입력'
										/>
									)}
									name='needSalaryDesc'
									control={form?.control}
								/>
							</S.TableBodyColumn>
						</S.TableBodyRow>
						<S.TableBodyRow>
							<S.TableBodyColumn>
								<CRText text='제공방법' typography='body' color='gray60' />
							</S.TableBodyColumn>
							<S.TableBodyColumn>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInput.TextArea
											disabled={disabled}
											onChange={onChange}
											value={value}
											fixedHeight
											height='14.4rem'
											maxLength={2000}
											placeholder='제공방법 입력'
										/>
									)}
									name='provisionMethodDesc'
									control={form?.control}
								/>
							</S.TableBodyColumn>
						</S.TableBodyRow>
					</S.TableBody>
				</S.Table>
			</S.TableContainer>
		</S.Container>
	);
}

export default React.memo(IASalaryOfferPlanForm);
