import styled, { css } from 'styled-components';

import Assets from 'assets';
import { FilterType } from 'types/view/filter';

export const Container = styled.div<{ $disabled: boolean }>`
	position: relative;
	user-select: none;
	background: ${(props) => props.theme.colors.gray100};
	cursor: pointer;
	height: 2.4rem;

	${(props) =>
		props.$disabled &&
		css`
			background: ${(props) => props.theme.colors.gray95};
			cursor: no-drop;
		`}
`;

export const CustomDay = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 4rem;
	height: 4rem;
`;

export const MonthYearWrapper = styled.div`
	/* padding: 1.6rem 2.4rem; */
`;

export const MonthYearWeek = styled.div`
	display: flex;
	justify-content: center;
	gap: 4rem;
	white-space: nowrap;

	& + & {
		margin-top: 1.6rem;
	}
`;

export const MonthYearIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
`;

export const YearText = styled.span`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray10};
`;

export const MonthYearContainer = styled.div`
	padding: 1.6rem 0;
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const MonthYearHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.8rem 2.4rem 1.6rem;
`;

export const MonthMonthYearItem = styled.div<{ $isActive: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray10};
	cursor: pointer;

	&:hover {
		background: ${(props) => props.theme.colors.gray95};
	}

	${(props) =>
		props.$isActive &&
		css`
			background: ${props.theme.colors.primary60};
			color: ${(props) => props.theme.colors.gray100};
		`}
`;

export const CalendarContainer = styled.div`
	width: 37.6rem;
	overflow: auto;
	/* padding: 0 2.4rem; */
	box-sizing: border-box;

	.react-datepicker__aria-live {
		/* display: none; */
	}

	.react-datepicker__navigation {
		top: 1rem;
	}

	.react-datepicker__monthPicker {
		display: none;
	}

	.react-datepicker__month-wrapper {
		display: flex;
	}

	.react-datepicker__month-text {
		flex: 1;
	}

	/* .react-datepicker__navigation-icon {
		width: 0.74rem;
		height: 1.2rem;
	} */

	.react-datepicker {
		width: 100%;
		border: none;
	}

	.react-datepicker__month-container {
		width: 100%;
		margin-top: 0.8rem;
	}

	.react-datepicker__month {
		margin: 0;
	}

	.react-datepicker__week {
		display: flex;
		justify-content: center;
		margin-top: 0.8rem;
	}

	.react-datepicker__day {
		cursor: pointer;
	}

	.react-datepicker__day--keyboard-selected {
		background-color: ${(props) => props.theme.colors.gray100};
	}

	.react-datepicker__header {
		background-color: transparent;
		border: none;
		padding: 0;
	}

	.react-datepicker__day-names {
		background-color: none;
		display: flex;
		justify-content: center;
		gap: 0.8rem;
		display: none;
	}

	.react-datepicker__day {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 4rem;
		height: 4rem;
		padding: 0 0.4rem;
		margin: 0;
		outline: none;
		${(props) => props.theme.typography.label};
		color: ${(props) => props.theme.colors.primary10};
	}

	.react-datepicker__day--selected.react-datepicker__day--range-start ${CustomDay} {
		background: ${(props) => props.theme.colors.primary90};
		border-radius: 50%;
		color: ${(props) => props.theme.colors.primary10};
	}

	.react-datepicker__day--range-end ${CustomDay} {
		background: ${(props) => props.theme.colors.primary60};
		border-radius: 50%;
		color: ${(props) => props.theme.colors.gray100};
	}

	.react-datepicker__day--today ${CustomDay} {
		background-color: #fff;
		border: 0.1rem solid ${(props) => props.theme.colors.gray10};
		${(props) => props.theme.typography.label};
		color: ${(props) => props.theme.colors.gray10};
		border-radius: 50%;
		box-sizing: border-box;
	}

	.react-datepicker__day--selected {
		background: none;
	}

	.react-datepicker__day--today.react-datepicker__day--selected.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start
		${CustomDay} {
		background-color: #fff;
		border: 0.1rem solid ${(props) => props.theme.colors.gray10};
		${(props) => props.theme.typography.label};
		color: ${(props) => props.theme.colors.gray10};
		border-radius: 50%;
		box-sizing: border-box;
	}

	.react-datepicker__day--today.react-datepicker__day--selected ${CustomDay} {
		border-radius: 50%;
		border: none;
		color: ${(props) => props.theme.colors.gray100};
	}

	.react-datepicker__day--selecting-range-start,
	.react-datepicker__day--selecting-range-end {
		background: ${(props) => props.theme.colors.primary95};
	}

	.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end.react-datepicker__day--in-selecting-range {
		border-radius: 50%;
	}

	.react-datepicker__day--selecting-range-start.react-datepicker__day--selected {
		padding-left: 0;
		margin-left: 0.4rem;
		border-radius: 4rem 0 0 4rem;
	}

	.react-datepicker__day--selecting-range-end.react-datepicker__day--selected {
		padding-right: 0;
		margin-right: 0.4rem;
		border-radius: 0 4rem 4rem 0;
	}

	.react-datepicker__day--in-range {
		background: ${(props) => props.theme.colors.primary95};
		border-radius: 0;
	}

	.react-datepicker__day--range-start {
		padding-left: 0;
		margin-left: 0.4rem;
		border-radius: 4rem 0 0 4rem;
	}

	.react-datepicker__day--range-start ${CustomDay} {
		background: ${(props) => props.theme.colors.primary90}!;
	}

	.react-datepicker__day--range-end.react-datepicker__day--in-range {
		padding-right: 0;
		margin-right: 0.4rem;
		border-radius: 0 4rem 4rem 0;
	}

	.react-datepicker__day--range-start.react-datepicker__day--range-end.react-datepicker__day--in-range {
		border-radius: 50%;
	}

	.react-datepicker__day--range-start.react-datepicker__day--range-end ${CustomDay} {
		background: ${(props) => props.theme.colors.primary60};
		border-radius: 50%;
		color: ${(props) => props.theme.colors.gray100};
		border: none;
	}

	.react-datepicker__day--range-end ${CustomDay} {
		background: ${(props) => props.theme.colors.primary60};
		border-radius: 50%;
		color: ${(props) => props.theme.colors.gray100};
		border: 0.1rem solid ${(props) => props.theme.colors.primary10};
		box-sizing: border-box;
	}

	.react-datepicker__day--outside-month {
		visibility: hidden;
		background: ${(props) => props.theme.colors.gray100};
	}

	.react-datepicker__day--in-selecting-range {
		background: ${(props) => props.theme.colors.primary95};
		border-radius: 0;
	}

	.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end {
		padding-right: 0;
		margin-right: 0.4rem;
		border-radius: 0 4rem 4rem 0;
	}
`;

export const ButtonSection = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 0.8rem;
	height: 7.2rem;
	padding: 0 2.4rem;
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	box-sizing: border-box;
`;

export const CustomHeader = styled.div`
	padding-top: 1.6rem;
`;

export const Range = styled.div`
	display: flex;
	gap: 0.8rem;
	padding: 0 2.4rem;
	margin-top: 0.8rem;
	margin-bottom: 1.2rem;
`;

export const CustomCaption = styled.div`
	${(props) => props.theme.typography.label};
	display: flex;
	justify-content: center;
	gap: 0.8rem;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	color: ${(props) => props.theme.colors.gray60};
	background-color: none;
`;

export const CustomName = styled.div`
	${(props) => props.theme.typography.label};
	display: flex;
	justify-content: flex-start;
	color: ${(props) => props.theme.colors.gray60};
	background-color: none;
	padding: 0 2.4rem;
`;

export const CaptionText = styled.div`
	${(props) => props.theme.typography.label};
	display: flex;
	justify-content: center;
	align-items: center;
	width: 4rem;
	height: 2.5rem;
	padding: 0.8rem 0;
	color: ${(props) => props.theme.colors.gray60};
`;

export const Title = styled.p`
	${(props) => props.theme.typography.labelB};
	white-space: nowrap;
	width: 100%;
	padding: 0 2.4rem;
	box-sizing: border-box;
	/* padding-top: 2.4rem; */
`;

const option = css`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.4rem;
	min-width: 9.8rem;
	height: 3.2rem;
	padding: 0.2rem 0.8rem 0.2rem;
	border-radius: 0.8rem;
	box-sizing: border-box;
	user-select: none;
	cursor: pointer;
`;

export const ChipLabel = styled.span<{ $isActive?: boolean; $isDisabled: boolean }>`
	${(props) => props.theme.typography.body}
	color: ${(props) => (props.$isActive ? props.theme.colors.gray10 : props.theme.colors.gray60)};
	${(props) =>
		props.$isDisabled &&
		css`
			color: ${props.theme.colors.gray60};
		`};
`;

export const CalendarIcon = styled.img<{ $isDisabled?: boolean }>`
	width: 2.4rem;
	height: 2.4rem;

	${(props) =>
		props.$isDisabled &&
		css`
			${(props) => props.theme.filters.gray60};
		`}
`;

export const CloseIconContainer = styled.div<{ $showOptions?: boolean }>`
	display: block;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.8rem;
	height: 1.8rem;
	margin: 0.7rem 0 0.7rem 0.4rem;
	& > img {
		width: 100%;
		height: 100%;
	}
`;

export const DefaultOption = styled.div<{
	$isActive?: boolean;
	disabled?: boolean;
	$showOptions?: boolean;
}>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 5.6rem;
	border-radius: 0.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	padding: 0 1.2rem 0 1.6rem;
	box-sizing: border-box;

	/* display: flex;
	align-items: center;
	justify-content: space-between;
	width: fit-content;
	height: 3.2rem;
	padding: 0.4rem 1.2rem 0.4rem 0.8rem;
	border-radius: 0.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	box-sizing: border-box;
	background: ${(props) => props.theme.colors.gray100};
	user-select: none;
	cursor: pointer;
	${(props) =>
		!props.$isActive &&
		!props.disabled &&
		css`
			&:hover {
				background: ${(props) => props.theme.colors.gray99};
			}

			&:active {
				background: ${(props) => props.theme.colors.gray95};
			}
		`};

	${(props) =>
		props.$isActive &&
		css`
			background: ${(props) => props.theme.colors.primary95};
			border: 0.1rem solid ${(props) => props.theme.colors.primary90};
			& > ${ChipLabel} {
				color: ${(props) => props.theme.colors.primary10};
			}
		`};

	${(props) =>
		props.$showOptions &&
		css`
			& > ${CloseIconContainer} {
				transform: rotate(180deg);
			}
		`};

	${(props) =>
		props.disabled &&
		css`
			background: ${(props) => props.theme.colors.gray90};
			border: 0.1rem solid transparent;
			& > ${ChipLabel} {
				color: ${(props) => props.theme.colors.gray60};
			}
		`};

	width: fit-content;
	min-width: 9.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90}; */
`;

export const OptionsContainer = styled.div`
	position: absolute;
	top: 4rem;
	left: 0;
	/* min-width: 10rem; */
	/* width: fit-content; */
	min-width: 16rem;
	/* border: 0.1rem solid ${(props) => props.theme.colors.gray90}; */
	background-color: ${(props) => props.theme.colors.gray100};
	border-radius: 0.8rem;
	z-index: 100;
	overflow: hidden;

	box-shadow: ${(props) => props.theme.elevation.elevation01};

	& > li {
		list-style: none;
		&:hover {
			/* background: ${(props) => props.theme.colors.gray90}; */
		}
	}

	border: 1px solid #e1e3e3;
`;

// const defaultButton = css`
// 	height: 5.6rem;
// 	display: flex;
// 	align-items: center;
// 	padding: 0 2.4rem 0 0.8rem;
// 	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
// 	border-radius: 0.8rem;
// 	cursor: pointer;
// `;

export const InputContainer = styled.div<{
	$isActive?: boolean;
	isDisabled?: boolean;
	type: FilterType;
}>`
	display: none;
	align-items: center;
	justify-content: center;
	/* width: 100%; */
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	/* padding: 0 1.6rem; */
	white-space: nowrap;

	&:disabled {
		color: ${(props) => props.theme.colors.primary60};
	}

	${(props) =>
		props.type === 'multi' &&
		css`
			display: flex;
		`};
`;

export const TestContainer = styled.label<{
	$isActive?: boolean;
	isDisabled?: boolean;
}>`
	${(props) => props.theme.typography.body};
	display: flex;
	align-items: center;
	min-height: 4.8rem;
	height: fit-content;
	user-select: none;
	padding: 0 1.6rem 0 0.8rem;
	cursor: pointer;

	&:hover ${InputContainer} {
		background: ${(props) =>
			/* eslint-disable */
			props.isDisabled
				? 'transparent'
				: props.$isActive
					? props.theme.colors.primary90
					: props.theme.colors.gray95};
	}
`;

export const CRInput = styled.img<{ $isActive?: boolean }>`
	appearance: none;
	width: 2.4rem;
	height: 2.4rem;
	margin: 0;
	padding: 0;
	white-space: nowrap;
	${(props) => props.theme.typography.body};

	border-color: transparent;
	background-image: url(${Assets.icon.checkSquare});
	background-size: 100% 100%;
	background-repeat: no-repeat;
	filter: brightness(0) saturate(100%) invert(70%) sepia(0%) saturate(4470%) hue-rotate(286deg)
		brightness(86%) contrast(64%);
	cursor: pointer;

	${(props) =>
		props.$isActive &&
		css`
			filter: brightness(0) saturate(100%) invert(41%) sepia(48%) saturate(2377%) hue-rotate(325deg)
				brightness(106%) contrast(101%);
		`};

	/* &:checked {
		background-image: url(${Assets.icon.checkSquareFilled});
		filter: brightness(0) saturate(100%) invert(41%) sepia(48%) saturate(2377%) hue-rotate(325deg)
			brightness(106%) contrast(101%);
	} */
`;
