import React, { useRef } from 'react';

import { VirtualItem, useVirtualizer } from '@tanstack/react-virtual';

import Colors from 'common/colors';
import CRTable from 'components/base/CRTable';
import { CRText } from 'components/base/CRText';
import { EmployeeWorkInfoViewType } from 'types/view/employee';

import { STAFF_WORK_LIST_TABLE_HEADER_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	items: EmployeeWorkInfoViewType[];
	searchValue: string;
	currentItem?: EmployeeWorkInfoViewType;
	onClickRow?: (recipient: EmployeeWorkInfoViewType) => void;
}

function EmployeeWorkListTable({
	items = [],
	currentItem,
	searchValue = '',
	onClickRow,
}: Props): React.ReactElement {
	const scrollContainerRef = useRef<HTMLDivElement>(null);

	const overworkTimeRenderer = (value: number, item?: EmployeeWorkInfoViewType) => (
		<CRText typography='label'>
			{value}
			<CRText text={` / ${item?.overWorkMaxTimeCnt}`} typography='label' color='gray60' />
		</CRText>
	);

	const rowRenderer = (virtualRow: VirtualItem) => {
		const item = items?.[virtualRow.index];
		if (!item) return null;

		const isFocused = currentItem?.employeeId === item?.employeeId;
		const renderKeys = [
			'index',
			'korMemberNm',
			'dutyNm',
			'pcorpTimeCnt',
			'workTimeCnt',
			'overWorkTimeCnt',
		] as const;
		const customStyle: { [key in string]: React.CSSProperties } = {};

		const handleScheduleTimeCntTextColor = {
			color: item.pcorpTimeCnt > item.workTimeCnt ? Colors.primary60 : Colors.gray00,
		};

		renderKeys.forEach((item: string, index: number) => {
			customStyle[`${item}`] = {
				width: STAFF_WORK_LIST_TABLE_HEADER_CONFIG[index].width,
				lineHeight: '3.3rem',
				flexShrink: 0,
				textAlign: index === 0 ? 'right' : 'left',
				...(item === 'workTimeCnt' && handleScheduleTimeCntTextColor),
			};
		});

		return (
			<CRTable.Row
				isFocused={isFocused}
				item={{
					index: virtualRow.index + 1,
					...item,
				}}
				searchValue={searchValue}
				searchKey={['korMemberNm', 'mobilePhoneNo', 'birthDt']}
				toolTipKey={['dutyNm']}
				key={item.employeeId}
				style={{
					width: '100%',
					display: 'flex',
					position: 'absolute',
					transform: `translateY(${virtualRow.start}px)`,
				}}
				customStyle={customStyle}
				renderKeys={[...renderKeys]}
				customRender={{
					overWorkTimeCnt: overworkTimeRenderer,
				}}
				onClick={onClickRow}
			/>
		);
	};

	const rowVirtualizer = useVirtualizer({
		count: items.length,
		getScrollElement: () => scrollContainerRef.current,
		estimateSize: () => 33,
		overscan: 30,
	});

	return (
		<S.Container ref={scrollContainerRef}>
			<CRTable.Root>
				<CRTable.Head offset={1} heads={STAFF_WORK_LIST_TABLE_HEADER_CONFIG} />
				<CRTable.Body
					style={{
						height: `${rowVirtualizer.getTotalSize()}px`,
						position: 'relative',
					}}>
					{rowVirtualizer?.getVirtualItems()?.map(rowRenderer)}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default React.memo(EmployeeWorkListTable);
