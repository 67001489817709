import React from 'react';

import Assets from 'assets';
import Colors from 'common/colors';
import CRAvatar from 'components/base/CRAvatar';
import CRButton from 'components/base/CRButton';
import { Employee } from 'components/domain/dialog/ApprovalLineSelectorDialog/mock';

import * as S from './styles';

interface Props {
	readOnly?: boolean;
	items: Employee[];
	onDelete: (ids: string[]) => void;
	onToggleDialog: () => void;
}

export default function Referrer({ readOnly = false, items, onDelete, onToggleDialog }: Props) {
	const renderReferrerText = (item: Employee[]) => {
		if (!item?.length) return '참조 대상을 선택해주세요';

		let text = '';
		if (item[0]) {
			text = item[0].name;
		}

		if (item.length > 1) {
			text = `${text} 외 ${item.length - 1}`;
		}
		return text;
	};

	const renderEmptyView = () => (
		<S.ApprovalLineItem onClick={onToggleDialog}>
			<S.ItemLeft>
				<CRAvatar.Default borderColor={Colors.gray90} size='small' src={Assets.icon.avatarNone} />
				<S.MessageContainer>참조 대상을 선택해주세요</S.MessageContainer>
			</S.ItemLeft>
			<S.ItemRight>
				<CRButton.Default size='xSmall' type='outlined' palette='gray' onClick={onToggleDialog}>
					선택
				</CRButton.Default>
			</S.ItemRight>
		</S.ApprovalLineItem>
	);

	const renderReferrerItem = (items: Employee[]) => (
		<S.ApprovalLineItem $hasItem $readOnly={readOnly} onClick={onToggleDialog}>
			<S.ItemLeft>
				<CRAvatar.Group size='small' maxCount={3}>
					{items?.map((item) => (
						<CRAvatar.Default
							showTooltip
							content={`${item.name} - ${item.center}`}
							key={item.id}
							src={item.imgUrl}
							alt={item.name}
						/>
					))}
				</CRAvatar.Group>
				<S.MessageContainer $hasItem>{renderReferrerText(items)}</S.MessageContainer>
			</S.ItemLeft>
			<S.ItemRight>
				<S.DeleteIcon
					$readOnly={readOnly}
					src={Assets.icon.closeCircle}
					alt='closeCircle'
					onClick={(event) => {
						event.stopPropagation();
						onDelete(items.map((e) => e.id));
					}}
				/>
			</S.ItemRight>
		</S.ApprovalLineItem>
	);

	return (
		<S.Container>
			<S.Title>참조</S.Title>
			<S.ApprovalLineList>
				{items.length > 0 ? renderReferrerItem(items) : renderEmptyView()}
			</S.ApprovalLineList>
		</S.Container>
	);
}
