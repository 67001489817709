import React from 'react';

import { Dayjs } from 'dayjs';

import Assets from 'assets';
import CRDialog from 'components/base/CRDialog';
import CRIcon from 'components/base/CRIcon';
import CRInputLabel from 'components/base/CRInputLabel';
import { CRText } from 'components/base/CRText';
import InformationTable from 'components/ui/InformationTable';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import { displayPadTime } from 'lib';
import useDialog from 'lib/hook/util/useDialog';
import {
	EmployeeActualWorkViewType,
	EmployeeDailyWorkHistoryViewType,
} from 'types/view/workSchedule';

import * as S from './styles';

type Props = {
	targetDate: Dayjs;
	data: EmployeeDailyWorkHistoryViewType;
};

function WorkLogDetailDialog({ targetDate, data }: Props): React.ReactElement {
	const { hideDialog } = useDialog();

	const handleHourMinuteText = (value: number) => {
		const hour = Math.floor(value / 60);
		const minute = value % 60;
		return hour ? `${hour}시간${minute ? ` ${minute}분` : ''}` : `${minute}분`;
	};

	const actualWorkLog: EmployeeActualWorkViewType =
		data.actualWorkInfo ?? ({} as EmployeeActualWorkViewType);

	const approvedWorkTimeText =
		data?.workHistoryInfo?.startTime && data?.workHistoryInfo?.endTime
			? `${data.workHistoryInfo.startTime.format('HH:mm')} ~ ${data.workHistoryInfo.endTime.format(
					'HH:mm',
				)}`
			: '-';

	const roundingScheduleText =
		data?.minRoundingStartTime && data?.maxRoundingEndTime
			? `${data?.minRoundingStartTime.format('HH:mm')} ~ ${data?.maxRoundingEndTime.format(
					'HH:mm',
				)}`
			: '-';

	const defaultWorkTime = data?.workHistoryInfo.data.workHourCnt ?? 0;
	const restHourCnt = data?.workHistoryInfo.data.restHourCnt ?? 0;
	const approvedOverWorkTimeCnt = data?.workHistoryInfo.data.overWorkTimeCnt ?? 0;
	const actualWorkTimeCnt = data?.initialEmployeeActualWorkHourCnt
		? data.initialEmployeeActualWorkHourCnt - defaultWorkTime - restHourCnt
		: 0;

	const defaultWorkTimeText = handleHourMinuteText(defaultWorkTime);
	const restHourText = handleHourMinuteText(restHourCnt);
	const approvedOverWorkTimeText = handleHourMinuteText(approvedOverWorkTimeCnt);
	const actualWorkTimeText = handleHourMinuteText(actualWorkTimeCnt < 0 ? 0 : actualWorkTimeCnt);

	const approvedWorkTimeTableItem = [
		[
			{
				label: (
					<CRText
						typography='label'
						color='gray60'
						style={{ display: 'flex', alignItems: 'center', gap: '0.2rem', whiteSpace: 'nowrap' }}>
						근무인정시간
						<RDTooltip
							content={`공단 포털에 입력될 근무시간\n(연장근무, 휴게시간 포함)`}
							side='top'>
							<CRIcon
								src={Assets.icon.help}
								width='1.6rem'
								height='1.6rem'
								style={{ marginBottom: '0.2rem' }}
							/>
						</RDTooltip>
					</CRText>
				),
				value: approvedWorkTimeText,
				labelStyle: { width: '13.2rem' },
			},
		],
	];

	const actualWorkLogTableItem = [
		[
			{
				label: '출근 시간',
				value: actualWorkLog?.data?.workTime ? displayPadTime(actualWorkLog?.data?.workTime) : '-',
				labelStyle: { width: '13.2rem' },
			},
		],
		[
			{
				label: '출근 장소',
				value: data?.actualWorkInfo?.data?.workPlaceNm?.split('(')[0] ?? '-',
				labelStyle: { width: '13.2rem' },
			},
		],
		[
			{
				label: '출근 기록 비고',
				value: data?.actualWorkInfo?.data?.workRemark ?? '-',
				labelStyle: { width: '13.2rem' },
				valueColSpan: 3,
			},
		],
		[
			{
				label: '퇴근 시간',
				value: actualWorkLog?.data?.leaveTime
					? displayPadTime(actualWorkLog?.data?.leaveTime)
					: '-',
				labelStyle: { width: '13.2rem' },
			},
		],
		[
			{
				label: '퇴근 장소',
				value: data?.actualWorkInfo?.data?.leavePlaceNm?.split('(')[0] ?? '-',
				labelStyle: { width: '13.2rem' },
			},
		],
		[
			{
				label: '퇴근 기록 비고',
				value: data?.actualWorkInfo?.data?.leaveRemark ?? '-',
				labelStyle: { width: '13.2rem' },
				valueColSpan: 3,
			},
		],
	];

	const roundingScheduleTableItem = [
		[
			{
				label: (
					<CRText
						typography='label'
						color='gray60'
						style={{ display: 'flex', alignItems: 'center', gap: '0.2rem', whiteSpace: 'nowrap' }}>
						라운딩 방문시간
						<RDTooltip content='최초 방문시작시간 ~ 마지막 방문종료시간' side='top'>
							<CRIcon
								src={Assets.icon.help}
								width='1.6rem'
								height='1.6rem'
								style={{ marginBottom: '0.2rem' }}
							/>
						</RDTooltip>
					</CRText>
				),
				value: roundingScheduleText,
				labelStyle: { width: '13.2rem' },
			},
		],
	];

	const defaultWorkTableItem = [
		[
			{
				label: '기본근무시간',
				value: defaultWorkTimeText,
				labelStyle: { width: '13.2rem' },
			},
		],
		[
			{
				label: '휴게시간',
				value: restHourText,
				labelStyle: { width: '13.2rem' },
			},
		],
	];

	const overWorkTableItem = [
		[
			{
				label: (
					<CRText
						typography='label'
						color='gray60'
						style={{ display: 'flex', alignItems: 'center', gap: '0.2rem', whiteSpace: 'nowrap' }}>
						근무자 기록시간
						<RDTooltip content={`근무자 최초 출퇴근 기록의\n연장근무시간`} side='top'>
							<CRIcon
								src={Assets.icon.help}
								width='1.6rem'
								height='1.6rem'
								style={{ marginBottom: '0.2rem' }}
							/>
						</RDTooltip>
					</CRText>
				),
				value: actualWorkTimeText,
				labelStyle: { width: '13.2rem' },
			},
		],
		[
			{
				label: '인정시간',
				value: approvedOverWorkTimeText,
				labelStyle: { width: '13.2rem' },
			},
		],
	];

	return (
		<CRDialog
			title={`${targetDate.format('YYYY년 M월 D일')} 근무내용`}
			onClickClose={hideDialog}
			body={
				<S.Container>
					<S.BodyContainer>
						<InformationTable items={approvedWorkTimeTableItem} />
						<S.TableContainer>
							<CRInputLabel label='출퇴근 기록'>
								<InformationTable items={actualWorkLogTableItem} />
							</CRInputLabel>
							<InformationTable items={roundingScheduleTableItem} />
						</S.TableContainer>
						<CRInputLabel label='기본근무'>
							<InformationTable items={defaultWorkTableItem} />
						</CRInputLabel>
						<CRInputLabel label='연장근무'>
							<InformationTable items={overWorkTableItem} />
						</CRInputLabel>
					</S.BodyContainer>
				</S.Container>
			}
		/>
	);
}

export default React.memo(WorkLogDetailDialog);
