import { styled } from 'styled-components';

import Filters from 'common/filter';

export const Container = styled.div``;

export const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	padding: 2.4rem 2.4rem 0;
	margin-bottom: -0.8rem;
	min-height: 9.6rem;
`;
export const Title = styled.h3`
	display: flex;
	justify-content: space-between;
	${(props) => props.theme.typography.h3};
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 1rem;
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	${Filters.gray100}
`;

export const Circle = styled.div<{ $color: string }>`
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 50%;
	background: ${(props) => props.$color};
`;

export const DropdownItem = styled.div`
	${(props) => props.theme.typography.body};
	display: flex;
	gap: 0.8rem;
	align-items: center;
	width: 13.1rem;
	min-height: 4.8rem;
	height: fit-content;
	user-select: none;
	padding: 0 1.6rem 0;
	box-sizing: border-box;
	cursor: pointer;

	&:hover {
		background: ${(props) => props.theme.colors.gray95};
	}
`;

export const SubTitle = styled.p`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray50};
`;

export const TableContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 96%;
	width: 100%;
	border-radius: 1.6rem;
	background: ${(props) => props.theme.colors.gray100};
`;

export const TableScrollContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow: auto;
	/* height: calc(100% - 10.1rem); */
	background: ${(props) => props.theme.colors.gray100};
`;

export const SendButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
`;

export const SendButtonText = styled.span`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray60};
`;

export const Divider = styled.div`
	width: 0.1rem;
	height: 1.6rem;
	background: ${(props) => props.theme.colors.gray90};
`;

export const EmptyContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.8rem;
	flex: 1;
`;

export const EmptyTitle = styled.h3`
	${(props) => props.theme.typography.h3};
	color: ${(props) => props.theme.colors.gray60};
`;

export const EmptyDescription = styled.p`
	${(props) => props.theme.typography.body};
	text-align: center;
	color: ${(props) => props.theme.colors.gray60};
`;

export const TotalCount = styled.div`
	padding: 0.8rem 0 1.6rem 0;
`;
