import React from 'react';

import dayjs from 'dayjs';

import Assets from 'assets';

import { FlexContainer } from '../CRFlexLayout/styles';
import CRIcon from '../CRIcon';
import { CRText } from '../CRText';
import MonthPickerButton from './MonthPickerButton';

interface Props {
	onChange: (date: Date) => void;
	value: Date;
}
function CRTableMonthSelector({ onChange, value }: Props): React.ReactElement {
	const date = dayjs(value);

	const onClickPrevMonth = () => {
		onChange(dayjs(value).subtract(1, 'month').toDate());
	};

	const onClickNextPrevMonth = () => {
		onChange(dayjs(value).add(1, 'month').toDate());
	};

	const onClickMonth = (date: Date) => {
		onChange(dayjs(date).toDate());
	};

	return (
		<FlexContainer align='center' gap='0.8rem'>
			<CRIcon
				onClick={onClickPrevMonth}
				src={Assets.icon.keyboardArrowLeft}
				style={{ cursor: 'pointer' }}
				alt='keyboardArrowLeft'
			/>
			<FlexContainer gap='0.8rem' align='center'>
				<CRText typography='h4' text={date.format('YYYY년 M월')} />
				<MonthPickerButton onChangeValue={onClickMonth} initDate={value} />
			</FlexContainer>
			<CRIcon
				onClick={onClickNextPrevMonth}
				src={Assets.icon.keyboardArrowRight}
				style={{ cursor: 'pointer' }}
				alt='keyboardArrowRight'
			/>
		</FlexContainer>
	);
}

export default CRTableMonthSelector;
