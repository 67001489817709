import CRCollapsibleRow from './CRCollapsibleRow';
import CRInformationCollapsibleRow from './CRInformationCollapsibleRow';
import CRInformationRow from './CRInformationRow';
import CRInformationTableContainer from './CRInformationTableContainer';
import CRInformationTableHead from './CRInformationTableHead';
import CRRow from './CRRow';
import CRRowTitle from './CRRowTitle';
import CRStyledBody from './CRStyledBody';
import CRTableBlind from './CRTableBlind';
import CRTableBody from './CRTableBody';
import CRTableContainer from './CRTableContainer';
import CRTableEmpty from './CRTableEmpty';
import CRTableHead from './CRTableHead';

export default {
	Root: CRTableContainer,
	InformationRoot: CRInformationTableContainer,
	Head: CRTableHead,
	InformationHead: CRInformationTableHead,
	Body: CRTableBody,
	CollapsibleRow: CRCollapsibleRow,
	InformationCollapsibleRow: CRInformationCollapsibleRow,
	Row: CRRow,
	BodyStyled: CRStyledBody,
	RowTitle: CRRowTitle,
	InformationRow: CRInformationRow,
	BackBlind: CRTableBlind,
	Empty: CRTableEmpty,
};
