import React, { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import Assets from 'assets';
import RouterPath from 'common/router';
import RdPopover from 'components/ui/radix/popover/RdPopover';
import { EnvConfig } from 'lib/config/env';
import useAuth from 'lib/hook/auth/useAuth';
import { useMyAccountInfo } from 'lib/hook/react-query';

import CRBreadCrumbs from '../CRBreadCrumbs';
import CRIcon from '../CRIcon';
import CRMorePopover from '../CRMorePopover';
import { CRText } from '../CRText';
import * as S from './styles';

interface IProps {
	breadCrumbs?: { link: string; label: string }[];
	onClickMenu?: () => void;
	onClickBack?: () => void;
	onClickHelper?: () => void;
	onMouseOverMenu?: (event?: MouseEvent<HTMLDivElement>) => void;
	onMouseOutMenu?: (event?: MouseEvent<HTMLDivElement>) => void;
}

function CRHeader({
	breadCrumbs,
	onClickMenu,
	onClickBack,
	onClickHelper,
	onMouseOverMenu,
	onMouseOutMenu,
}: IProps): React.ReactElement {
	const { signOut } = useAuth();
	const navigate = useNavigate();

	const { data: myAccountInfo } = useMyAccountInfo();

	const onSignOut = () => {
		signOut?.();
		navigate(RouterPath.signIn());
	};

	const onClickCenter = () => {
		navigate(RouterPath.myCenterOperate().user.key);
	};

	const isDev = ['development'].includes(EnvConfig.BUILD_ENV);

	const extLongtermAttrs = {
		id: 'extLongtermOpen',
		'data-centerid': myAccountInfo?.centerId,
		'data-token': sessionStorage.getItem('access_token'),
	};

	return (
		<S.Container>
			<S.MenuLeaderContainer>
				{breadCrumbs?.length ? (
					<>
						<S.MenuContainer>
							<S.ButtonContainer aria-label='arrowLeft' onClick={onClickBack}>
								<S.MenuIcon alt='menu' src={Assets.icon.arrowLeft} />
							</S.ButtonContainer>
						</S.MenuContainer>
						<CRBreadCrumbs breadCrumbs={breadCrumbs} />
					</>
				) : (
					<>
						<S.MenuContainer
							onClick={onClickMenu}
							onMouseOver={onMouseOverMenu}
							onMouseOut={onMouseOutMenu}>
							<S.ButtonContainer aria-label='menu'>
								<S.MenuIcon alt='menu' src={Assets.icon.menu} />
							</S.ButtonContainer>
						</S.MenuContainer>
						<S.Logo alt='logo' src={isDev ? Assets.img.logoTest : Assets.img.logo} />
						<S.ERPText>ERP</S.ERPText>
					</>
				)}
			</S.MenuLeaderContainer>
			<S.MenuTailContainer>
				{myAccountInfo && (
					<>
						<S.CenterText>{myAccountInfo?.centerNm}</S.CenterText>
						<RdPopover
							showDivider={false}
							sideOffset={-80}
							align='end'
							alignOffset={-100}
							borderShape='small'
							content={
								<div>
									<S.DropdownItem onClick={onClickCenter}>내 센터</S.DropdownItem>
									<S.DropdownItem onClick={onSignOut}>로그아웃</S.DropdownItem>
								</div>
							}>
							<S.MyInformationButtonContainer>
								<S.MyInformationButtonText>{myAccountInfo?.userNm}</S.MyInformationButtonText>
								<S.Icon alt='keyboardArrowBottom' src={Assets.icon.keyboardArrowBottom} />
							</S.MyInformationButtonContainer>
						</RdPopover>
					</>
				)}
				<CRMorePopover
					items={[
						<S.PopoverItemContainer onClick={onClickHelper}>
							<CRText text='신규 상담' />
							<CRIcon src={Assets.icon.supportAgent} />
						</S.PopoverItemContainer>,
						<S.PopoverItemContainer {...extLongtermAttrs}>
							<span {...extLongtermAttrs}>장기요양정보시스템</span>
							<CRIcon src={Assets.icon.openInNew} {...extLongtermAttrs} />
						</S.PopoverItemContainer>,
					]}
				/>
			</S.MenuTailContainer>
		</S.Container>
	);
}

export default React.memo(CRHeader);
