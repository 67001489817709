import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { Toast } from 'components/base/CRToast';
import { useUpdateCenterAccountInfo } from 'lib/hook/react-query/mutation/centerInfo';
import { useUserAccountRoles } from 'lib/hook/react-query/query/userAccount';
import useDialog from 'lib/hook/util/useDialog';
import { ResponseCode } from 'types/api';
import { MyCenterAccountContentDTO } from 'types/api/userAccount';
import { ChnageCenterAccountForm, LinkAccountForm } from 'types/view/userAccount';

import * as S from './styles';

interface Props {
	data: MyCenterAccountContentDTO;
	refetch: () => void;
}

export function ChangeAccountInfoDialog({ data, refetch }: Props): React.ReactElement {
	const { hideDialog } = useDialog();
	const { data: accountRoles } = useUserAccountRoles();
	const updateCenterAccountInfoMutation = useUpdateCenterAccountInfo((client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			refetch();
			Toast.success('직원 정보가 수정되었습니다.');
		} else {
			Toast.error('직원 정보 수정에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
		hideDialog();
	});

	const {
		control,
		handleSubmit,
		trigger,
		formState: { isValid },
	} = useForm<ChnageCenterAccountForm>({
		defaultValues: {
			roleId: data.roleId,
			workMobilePhoneNo: data.workMobilePhoneNo,
			personalMobilePhoneNo: data.mobilePhoneNo,
		},
	});

	const onSubmit = (formData: ChnageCenterAccountForm) => {
		updateCenterAccountInfoMutation.mutate({ memberAccountId: data.memberAccountId, ...formData });
	};

	const submitForm = () => {
		trigger().then((isValid) => {
			if (isValid) {
				handleSubmit(onSubmit)();
			}
		});
	};

	return (
		<CRDialog
			onClickClose={hideDialog}
			type='S'
			title='직원 정보 수정'
			body={
				<S.Container>
					<CRInputLabel label='직책'>
						<Controller
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInput.Selector
									items={(accountRoles ?? [])
										.filter((item) => [4, 5].includes(item.roleId))
										.map((item) => ({ value: item.roleId, label: item.roleDesc }))}
									onChangeValue={(item) => onChange(item.value)}
									currentValue={{ value, label: '' }}
								/>
							)}
							name='roleId'
							control={control}
						/>
					</CRInputLabel>
					<CRInputLabel label='전화번호(개인용)'>
						<Controller
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInput.Default onChange={onChange} value={value} type='number' />
							)}
							name='workMobilePhoneNo'
							control={control}
						/>
					</CRInputLabel>
					<CRInputLabel label='전화번호(업무용)'>
						<Controller
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInput.Default onChange={onChange} value={value} type='number' />
							)}
							name='personalMobilePhoneNo'
							control={control}
						/>
					</CRInputLabel>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						palette='primary'
						size='default'
						disabled={!isValid}
						onClick={submitForm}>
						저장
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
