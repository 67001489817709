import { v4 } from 'uuid';

import { MyAccountInfoDTO } from 'types/api/auth';
import {
	GetFtimeEmployeeWorkScheduleData,
	RecordingDetail,
	RecordingSheetDTO,
	Schedule,
	ScheduleDTO,
} from 'types/api/schedule';
import { RecordingSheetItem } from 'types/view/schedule';

export const RecordingSheetAdapter =
	(myAccountInfo?: MyAccountInfoDTO | null) => (items: RecordingSheetDTO | null) => {
		if (!myAccountInfo || !items?.scheduleRecordings.length)
			return {
				scheduleRecordings: [],
				totCount: 0,
			};
		const scheduleRecordings =
			(items?.scheduleRecordings || [])?.map(
				(item) =>
					({
						...item,
						id: v4(),
						employeeNm: item.employeeNm?.split(',')?.[0] || '-',
						employeeNm2: item.employeeNm?.split(',')?.[1] || '-',
						employeeMobilePhoneNo: item.employeeMobilePhoneNo?.split(',')?.[0] || '-',
						employeeMobilePhoneNo2: item.employeeMobilePhoneNo?.split(',')?.[1] || '-',
						sendStatus: item.recordingSheetSendYn ? '발송' : '미발송',
						recordingDetails: item.recordingDetails,
					}) as RecordingSheetItem,
			) ?? [];
		return {
			scheduleRecordings,
			totCount: items?.totCount,
		};
	};

export function ClientScheduleAdapter(data: ScheduleDTO | null) {
	const schedules = data?.schedules;
	if (!schedules) return {};

	// 일자별로 동일한 일정을 묶어줌
	// 요양보호사가 2명인 경우 같은날에 2개의 일정으로 데이터가 들어오기 때문
	const groupByServiceStartTime = schedules.reduce(
		(acc, cur) => {
			const targetDt = cur.serviceStartDt;
			acc[targetDt] = acc[targetDt] ? [...acc[targetDt], cur] : [cur];
			return acc;
		},
		{} as Record<string, Schedule[]>,
	);

	return groupByServiceStartTime;
}

export const ftimeEmployeeWorkScheduleAdapter = (
	data: GetFtimeEmployeeWorkScheduleData | null,
): GetFtimeEmployeeWorkScheduleData | null => {
	if (!data) return null;

	const ftimeRecipients = data.ftimeRecipients.map((item) => ({
		...item,
	}));

	return {
		ftimeRecipients,
		workExecuteLogs: data.workExecuteLogs,
		holidays: data.holidays ?? [],
	};
};
