import React, {
	ChangeEventHandler,
	ForwardedRef,
	KeyboardEvent,
	forwardRef,
	useState,
} from 'react';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';

import * as S from './styles';

interface IProps {
	value?: number | string;
	id?: string;
	addOnBottom?: string;
	status?: 'default' | 'error';
	disabled?: boolean;
	maxLength?: number;
	placeholder?: string;
	showSearchButton?: boolean;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	onClickDeleteButton?: () => void;
	onSearch?: () => void;
	onClick?: () => void;
}

function CRInputSearch(
	{
		value,
		id,
		status = 'default',
		maxLength = 524288,
		addOnBottom = '',
		disabled = false,
		placeholder = '',
		showSearchButton = false,
		onChange,
		onClickDeleteButton,
		onSearch,
		onClick,
	}: IProps,
	ref: ForwardedRef<HTMLInputElement>,
) {
	const [isFocused, setIsFocused] = useState(false);
	const onFocus = () => setIsFocused(true);
	const onBlur = () => setIsFocused(false);
	const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			onSearch?.();
		}
	};

	return (
		<S.Container onClick={disabled ? () => {} : onClick}>
			<S.InputContainer $isFocused={isFocused} $error={status === 'error'} disabled={disabled}>
				<S.Icon src={Assets.icon.search} onClick={onClickDeleteButton} $disabled={disabled} />
				<input
					readOnly={!!onClick}
					ref={ref}
					id={id}
					value={value}
					type='text'
					disabled={disabled}
					maxLength={maxLength}
					placeholder={placeholder}
					onBlur={onBlur}
					onFocus={onFocus}
					onChange={onChange}
					onKeyUp={onKeyUp}
				/>
				{showSearchButton && (
					<CRButton.Default size='xSmall' type='outlined' palette='gray' onClick={onSearch}>
						검색
					</CRButton.Default>
				)}
			</S.InputContainer>
			<S.HelperText status={status}>{addOnBottom}</S.HelperText>
		</S.Container>
	);
}

export default forwardRef(CRInputSearch);
