import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import * as Accordion from '@radix-ui/react-accordion';
import dayjs from 'dayjs';

import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRPageFooter from 'components/base/CRPageFooter';
import CRPageHeader from 'components/base/CRPageHeader';
import CRScrollSpy from 'components/base/CRScrollSpy';
import CRScrollSpyContainer from 'components/base/CRScrollSpyContainer';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import IntegratedAssessmentGenerateOpinionDialog from 'components/domain/dialog/IntegratedAssessmentGenerateOpinionDialog';
import IntegratedAssessmentPreviousDataDialog from 'components/domain/dialog/IntegratedAssessmentPreviousDataDialog';
import IntegratedAssessmentDesireAssistiveDevicesForm from 'components/domain/form/IntegratedAssessmentDesireAssistiveDevicesForm';
import IntegratedAssessmentDesireBasicStatusForm from 'components/domain/form/IntegratedAssessmentDesireBasicStatusForm';
import IntegratedAssessmentDesireCognitiveStateForm from 'components/domain/form/IntegratedAssessmentDesireCognitiveStateForm';
import IntegratedAssessmentDesireCommunicationStatusForm from 'components/domain/form/IntegratedAssessmentDesireCommunicationStatusForm';
import IntegratedAssessmentDesireFamilySupportSystemForm from 'components/domain/form/IntegratedAssessmentDesireFamilySupportSystemForm';
import IntegratedAssessmentDesireHousingEnvironmentForm from 'components/domain/form/IntegratedAssessmentDesireHousingEnvironmentForm';
import IntegratedAssessmentDesireNursingCareStatusForm from 'components/domain/form/IntegratedAssessmentDesireNursingCareStatusForm';
import IntegratedAssessmentDesirePhysicalConditionForm from 'components/domain/form/IntegratedAssessmentDesirePhysicalConditionForm';
import IntegratedAssessmentDesireRecipientCaregiverNeedsForm from 'components/domain/form/IntegratedAssessmentDesireRecipientCaregiverNeedsForm';
import IntegratedAssessmentDesireRehabilitationStatusForm from 'components/domain/form/IntegratedAssessmentDesireRehabilitationStatusForm';
import IntegratedAssessmentMajorIllnessStatusForm from 'components/domain/form/IntegratedAssessmentMajorIllnessStatusForm';
import IntegratedDesireRecipientInfoForm from 'components/domain/form/IntegratedDesireRecipientInfoForm';
import IntegratedDesireTotalOpinionForm from 'components/domain/form/IntegratedDesireTotalOpinionForm';
import TaskLayout from 'components/domain/layout/TaskLayout';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import {
	useDeleteRecipientIntegratedAssessment,
	useMyAccountInfo,
	useRecipientIntegratedAssessmentScore,
	useRecipientIntegratedAssessmentUpdateForm,
	useUpdateRecipientIntegratedAssessment,
} from 'lib/hook/react-query';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import useDialog from 'lib/hook/util/useDialog';
import useFullScreen from 'lib/hook/util/useFullScreen';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import { endpoint } from 'lib/service/Api/endpoint';
import {
	flattenIntegratedAssessment,
	groupIntegratedAssessment,
	structuredIntegratedAssessment,
} from 'lib/util/form';
import { ResponseCode } from 'types/api';
import { IntegratedAssessmentDesireForms, IntegratedAssessmentType } from 'types/view/recipient';

import { IntegratedAssessmentFormDTO } from '../../../types/dto';
import GenerateOpinionSampleHelper from './GenerateOpinionSampleHelper';
import { generateIntegratedAssessmentDesireInfoScrollSpy } from './constant';
import * as S from './styles';

function IntegratedAssessmentDesireInfoPage(): React.ReactElement {
	const { showDialog, setCustomBackHandler } = useGlobalLayout();
	const location = useLocation();
	const navigate = useNavigate();
	const params = useParams<{
		id: string;
		recipientIaId: string;
	}>();
	const form = useForm<IntegratedAssessmentDesireForms>();
	const { currentRecipient, currentRecipientInfoSummary } = useRecipientPage();
	const { data: previousData } = useRecipientIntegratedAssessmentUpdateForm({
		recipientIaId: params.recipientIaId ?? '',
		recipientId: params.id ?? '',
	});

	const { data: myAccountInfo } = useMyAccountInfo();
	const { hideDialog } = useDialog();

	const [generateOpinionMode, setGenerateOpinionMode] = useState(false);
	const [generateOpinionEmptyField, setGenerateOpinionEmptyField] = useState<string | undefined>();
	const [isOpinionGenerated, setIsOpinionGenerated] = useState(false);

	const { data: recipientIntegratedAssessmentScore } = useRecipientIntegratedAssessmentScore({
		recipientId: params.id ?? '',
		centerId: myAccountInfo?.centerId.toString() ?? undefined,
		targetYear: previousData?.targetYear ?? undefined,
		targetSeq: previousData?.targetSeq.toString() ?? undefined,
	});

	const deleteRecipientIntegratedAssessment = useDeleteRecipientIntegratedAssessment(
		(client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success('정상적으로 삭제을 완료하였습니다.');
				client.invalidateQueries([
					endpoint.getRecipientIntegratedAssessment.key,
					{
						recipientId: params.id,
						centerId: myAccountInfo?.centerId.toString(),
						targetYear: previousData?.targetYear,
					},
				]);
				hideDialog();
			} else {
				Toast.error('삭제에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		},
	);

	const updateRecipientIntegratedAssessment = useUpdateRecipientIntegratedAssessment(
		(client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success('정상적으로 저장을 완료하였습니다.');
				client.invalidateQueries([
					endpoint.getRecipientIntegratedAssessment.key,
					{
						recipientId: params.id,
						centerId: myAccountInfo?.centerId.toString(),
						targetYear: previousData?.targetYear,
					},
				]);
				client.invalidateQueries([
					endpoint.getRecipientIntegratedAssessmentUpdateForm.key,
					{
						recipientIaId: params.recipientIaId ?? '',
						recipientId: params.id ?? '',
					},
				]);
			} else {
				Toast.error('저장에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		},
	);

	const goBack = useCallback(() => {
		navigate(
			`/${RouterPath.recipient().tab.uniqueKey}/${params.id}/${
				RouterPath.recipient().integratedAssessment.uniqueKey
			}`,
		);
	}, [params.id]);

	const handleClickDelete = async () => {
		if (!params.recipientIaId) return;

		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='문서 삭제'
				hideDialog={hideDialog}
				content='욕구사정 문서를 삭제하시겠습니까?'
				cancelOption={{
					text: '취소',
					callback: hideDialog,
				}}
				successOption={{
					text: '삭제',
					type: 'outlined',
					successCallback: async () => {
						await deleteRecipientIntegratedAssessment.mutateAsync({
							recipientIaId: Number(params.recipientIaId),
						});
						goBack();
					},
				}}
			/>
		));
	};

	const getContent = (
		formItem: IntegratedAssessmentDesireForms,
		previousData?: IntegratedAssessmentFormDTO | null,
	) =>
		flattenIntegratedAssessment(formItem, groupIntegratedAssessment(previousData?.content ?? {}), [
			'type',
			'longTermEndDate',
			'longTermGradeCd',
			'longTermStartDate',
			'longTermNo',
			'recipientBirthDt',
			'recipientGenderCd',
			'recipientNm',
			'writeDate',
			'writerNm',
			'memo',
			'weight',
			'height',
		]);

	const validateForm = (formItem: IntegratedAssessmentDesireForms) => {
		if (!formItem.height?.length) {
			Toast.error('신장을 입력해주세요.');
			return false;
		}
		if (!formItem.weight?.length) {
			Toast.error('체중을 입력해주세요.');
			return false;
		}
		if (!formItem.writerNm?.length) {
			Toast.error('작성자를 입력해주세요.');
			return false;
		}
		if (!formItem.writeDate?.length) {
			Toast.error('작성일을 입력해주세요.');
			return false;
		}

		return true;
	};

	const handleClickCancel = async () => {
		if (!myAccountInfo) return;
		if (!currentRecipient) return;
		if (!currentRecipientInfoSummary) return;
		const formItem = form.getValues();

		const content = getContent(formItem, previousData);

		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='변경된 정보 저장'
				content='욕구사정 문서에 변경된 정보를 저장합니다.'
				hideDialog={hideDialog}
				successOption={{
					text: '저장',
					successCallback: async () => {
						hideDialog();
						if (form.getValues('writerNm')?.trim().length < 2) {
							Toast.error('작성자를 올바르게 입력해주세요.');
							return;
						}
						if (!validateForm(formItem)) return;
						await updateRecipientIntegratedAssessment.mutateAsync({
							recipientIaId: Number(params.recipientIaId),
							evalDescTypeCd: form.getValues('type')?.[0].value,
							height: Number(form.getValues('height') || '0'),
							weight: Number(form.getValues('weight') || '0'),
							specialDesc: form.getValues('memo') ?? '',
							writerNm: form.getValues('writerNm'),
							writeDate: form.getValues('writeDate'),
							content,
						});

						goBack();
					},
				}}
				cancelOption={{
					text: '저장안함',
					callback: () => {
						hideDialog();
						goBack();
					},
				}}
			/>
		));
	};

	const handleClickConfirm = async () => {
		if (!myAccountInfo) return;
		if (!currentRecipient) return;
		if (!currentRecipientInfoSummary) return;
		if (form.getValues('writerNm')?.trim().length < 2) {
			Toast.error('작성자를 올바르게 입력해주세요.');
			return;
		}
		const formItem = form.getValues();
		if (!validateForm(formItem)) return;

		const content = getContent(formItem, previousData);

		await updateRecipientIntegratedAssessment.mutateAsync({
			recipientIaId: Number(params.recipientIaId),
			evalDescTypeCd: form.getValues('type')?.[0].value,
			height: Number(form.getValues('height') || '0'),
			weight: Number(form.getValues('weight') || '0'),
			specialDesc: form.getValues('memo') ?? '',
			writerNm: form.getValues('writerNm'),
			writeDate: form.getValues('writeDate'),
			content,
		});

		goBack();
	};

	const loadFormFromData = (item: IntegratedAssessmentFormDTO) => {
		if (item?.content) {
			form.reset(groupIntegratedAssessment(item?.content));
		}
		if (item?.weight) {
			form.setValue('weight', item.weight?.toString());
		}
		if (item?.height) {
			form.setValue('height', item.height?.toString());
		}
		if (item?.specialDesc) {
			form.setValue('memo', item.specialDesc);
		}

		if (previousData?.evalDescTypeCd) {
			form.setValue('type', [{ value: previousData.evalDescTypeCd, label: '' }]);
		}
		if (previousData?.writerNm) {
			form.setValue('writerNm', previousData.writerNm);
		}
		if (previousData?.writeDate) {
			form.setValue('writeDate', previousData.writeDate);
		}
		if (previousData?.recipientNm) {
			form.setValue('recipientNm', previousData.recipientNm);
		}
		if (previousData?.recipientBirthDt) {
			form.setValue('recipientBirthDt', previousData.recipientBirthDt);
		}
		if (previousData?.recipientGenderCd) {
			form.setValue('recipientGenderCd', previousData.recipientGenderCd);
		}
		if (previousData?.longTermStartDate) {
			form.setValue('longTermStartDate', previousData.longTermStartDate);
		}
		if (previousData?.longTermEndDate) {
			form.setValue('longTermEndDate', previousData.longTermEndDate);
		}
		if (previousData?.longTermGradeCd) {
			form.setValue('longTermGradeCd', previousData.longTermGradeCd);
		}
		if (previousData?.longTermNo) {
			form.setValue('longTermNo', previousData.longTermNo);
		}
	};

	const handleLoadPreviousData = (item: IntegratedAssessmentFormDTO) => {
		loadFormFromData(item);
	};

	const handleClickLoadPreviousData = async () => {
		showDialog(({ hideDialog }) => (
			<IntegratedAssessmentPreviousDataDialog
				title='욕구사정 이력'
				hideDialog={hideDialog}
				recipientId={params.id}
				paperTypeCd={IntegratedAssessmentType.DesireInfo}
				onClickLoad={handleLoadPreviousData}
			/>
		));
	};

	const getGenerateOpinionParameter = (formItem: IntegratedAssessmentDesireForms) => {
		const content = getContent(formItem, previousData);
		const structuredContent = structuredIntegratedAssessment(content);
		const params = {
			수급자정보: {
				성별: currentRecipientInfoSummary.gender,
				나이: currentRecipient?.birthday
					? dayjs().diff(dayjs(currentRecipient.birthday), 'year')
					: undefined,
				등급: currentRecipientInfoSummary.longTermGrade
					? Number(currentRecipientInfoSummary.longTermGrade.slice(0, 1))
					: 0,
				몸무게: formItem.weight ? Number(formItem.weight) : undefined,
				키: formItem.height ? Number(formItem.height) : undefined,
			},
			낙상위험도_점수: recipientIntegratedAssessmentScore?.fallScore,
			욕창위험도_점수: recipientIntegratedAssessmentScore?.bedsoreScore,
			인지기능검사_점수: recipientIntegratedAssessmentScore?.kmmseScore,
			...structuredContent,
		};
		return params;
	};

	const LeftSideComponent = useMemo(() => {
		const scrollSpySections = generateIntegratedAssessmentDesireInfoScrollSpy(navigate);

		return (
			<S.LeftSideComponentContainer>
				<CRScrollSpy
					currentKey={location.pathname + location.hash}
					sections={scrollSpySections}
					hideCollapse
					hideDivider
				/>
				<GenerateOpinionSampleHelper
					generateOpinionMode={generateOpinionMode}
					setGenerateOpinionMode={setGenerateOpinionMode}
					parameterFormatter={getGenerateOpinionParameter}
					setGenerateOpinionEmptyField={setGenerateOpinionEmptyField}
					form={form}
				/>
			</S.LeftSideComponentContainer>
		);
	}, [location, navigate, generateOpinionMode]);

	const openGenerateOpinionDialog = () => {
		const formItem = form.getValues();
		const params = getGenerateOpinionParameter(formItem);
		const onSuccess = (data: string) => {
			if (data) {
				form.setValue('memo', data);
				setIsOpinionGenerated(true);
			}
		};

		showDialog(({ hideDialog }) => (
			<IntegratedAssessmentGenerateOpinionDialog
				hideDialog={hideDialog}
				params={params}
				onSuccess={onSuccess}
			/>
		));
	};

	const handleClickGenerateOpinion = () => {
		if (generateOpinionEmptyField) {
			const onClickClose = () => {
				hideDialog();
				setGenerateOpinionMode(true);
				navigate(generateOpinionEmptyField);
			};
			showDialog(() => (
				<CRDialog
					title='필수입력 누락'
					body={
						<CRText
							padding='0 2.4rem'
							text={`샘플 생성을 위한 필수입력을 모두 입력해야 합니다.\n화면 좌측에 [필수입력 보기]에서 확인할 수 있습니다.`}
						/>
					}
					showCloseButton={false}
					footer={<CRButton.Default onClick={onClickClose}>확인</CRButton.Default>}
				/>
			));
			return;
		}

		const checkScoreList = ['낙상위험도_점수', '욕창위험도_점수', '인지기능검사_점수'] as const;
		const requireScore = checkScoreList.filter((checkField) => {
			if (
				checkField === '낙상위험도_점수' &&
				recipientIntegratedAssessmentScore?.fallScore === undefined
			) {
				return true;
			}
			if (
				checkField === '욕창위험도_점수' &&
				recipientIntegratedAssessmentScore?.bedsoreScore === undefined
			) {
				return true;
			}
			if (
				checkField === '인지기능검사_점수' &&
				recipientIntegratedAssessmentScore?.kmmseScore === undefined
			) {
				return true;
			}
			return false;
		});

		if (requireScore.length) {
			showDialog(({ hideDialog }) => (
				<CRDialog
					title='통합사정 누락'
					showCloseButton={false}
					body={
						<CRText
							typography='body'
							padding='0 2.4rem'
							text={`샘플 생성을 위해 ${requireScore
								.map((item) => item.split('_')[0].replace('검사', ''))
								.join(', ')} 평가가 필요합니다.`}
						/>
					}
					footer={<CRButton.Default onClick={hideDialog}>확인</CRButton.Default>}
				/>
			));
			return;
		}

		if (form.getValues('memo')?.trim()) {
			showDialog(({ hideDialog }) => (
				<CRDialog
					title='불러오기 시 기존 내용 삭제'
					body={
						<CRText
							padding='0 2.4rem'
							text={`새로운 샘플을 불러오면 기존에 입력한 내용이 사라집니다.\n계속하시겠습니까?`}
						/>
					}
					showCloseButton={false}
					footer={
						<FlexContainer gap='0.8rem'>
							<CRButton.Default palette='gray' type='text' onClick={hideDialog}>
								취소
							</CRButton.Default>
							<CRButton.Default
								onClick={() => {
									hideDialog();
									openGenerateOpinionDialog();
								}}>
								불러오기
							</CRButton.Default>
						</FlexContainer>
					}
				/>
			));
			return;
		}

		openGenerateOpinionDialog();
	};

	useFullScreen();

	useEffect(() => {
		if (!previousData) return;

		loadFormFromData(previousData);
	}, [previousData]);

	const forms = [
		{
			id: '#1',
			label: '수급자 정보',
			component: <IntegratedDesireRecipientInfoForm form={form} />,
		},
		{
			id: '#2',
			label: '1. 일반상태',
			component: (
				<IntegratedAssessmentDesireBasicStatusForm
					form={form}
					showGenerateRequiredFields={generateOpinionMode}
				/>
			),
		},
		{
			id: '#3',
			label: '2. 주요질병상태 (재가-시설 공용)',
			component: (
				<IntegratedAssessmentMajorIllnessStatusForm
					form={form}
					showGenerateRequiredFields={generateOpinionMode}
				/>
			),
		},
		{
			id: '#4',
			label: '3. 신체상태 (일상생활 수행능력)',
			component: (
				<IntegratedAssessmentDesirePhysicalConditionForm
					form={form}
					showGenerateRequiredFields={generateOpinionMode}
				/>
			),
		},
		{
			id: '#5',
			label: '4. 재활상태',
			component: (
				<IntegratedAssessmentDesireRehabilitationStatusForm
					form={form}
					showGenerateRequiredFields={generateOpinionMode}
				/>
			),
		},
		{
			id: '#6',
			label: '5. 간호처치상태',
			component: (
				<IntegratedAssessmentDesireNursingCareStatusForm
					form={form}
					showGenerateRequiredFields={generateOpinionMode}
				/>
			),
		},
		{
			id: '#7',
			label: '6. 인지상태',
			component: (
				<IntegratedAssessmentDesireCognitiveStateForm
					form={form}
					showGenerateRequiredFields={generateOpinionMode}
				/>
			),
		},
		{
			id: '#8',
			label: '7. 의사소통',
			component: (
				<IntegratedAssessmentDesireCommunicationStatusForm
					form={form}
					showGenerateRequiredFields={generateOpinionMode}
				/>
			),
		},
		{
			id: '#9',
			label: '8. 가족 및 지지 체계',
			component: (
				<IntegratedAssessmentDesireFamilySupportSystemForm
					form={form}
					showGenerateRequiredFields={generateOpinionMode}
				/>
			),
		},
		{
			id: '#10',
			label: '9. 주거환경상태',
			component: (
				<IntegratedAssessmentDesireHousingEnvironmentForm
					form={form}
					showGenerateRequiredFields={generateOpinionMode}
				/>
			),
		},
		{
			id: '#11',
			label: '10. 복지용구 이용 희망',
			component: (
				<IntegratedAssessmentDesireAssistiveDevicesForm
					form={form}
					showGenerateRequiredFields={generateOpinionMode}
				/>
			),
		},
		{
			id: '#12',
			label: '11. 수급자 및 보호자 개별 욕구',
			component: (
				<IntegratedAssessmentDesireRecipientCaregiverNeedsForm
					form={form}
					showGenerateRequiredFields={generateOpinionMode}
				/>
			),
		},
		{
			id: '#13',
			label: '12. 종합 의견',
			component: (
				<IntegratedDesireTotalOpinionForm
					form={form}
					isOpinionGenerated={isOpinionGenerated}
					recipientIntegratedAssessmentScore={recipientIntegratedAssessmentScore}
					onClickGenerateOpinion={handleClickGenerateOpinion}
				/>
			),
		},
	];

	useFullScreen();

	useEffect(() => {
		setCustomBackHandler(() => handleClickCancel);
		return () => {
			setCustomBackHandler(undefined);
		};
	}, []);

	return (
		<TaskLayout LeftSideComponent={LeftSideComponent}>
			<S.MainComponentContainer>
				<CRScrollSpyContainer>
					<S.ContentContainer>
						<S.HeaderComponentContainer>
							<CRPageHeader
								headerTitle='욕구사정'
								buttons={
									myAccountInfo?.centerId !== 9
										? [
												{
													palette: 'primary',
													buttonType: 'button',
													type: 'outlined',
													onClick: handleClickDelete,
													children: '삭제',
												},
											]
										: []
								}
							/>
						</S.HeaderComponentContainer>
						<Accordion.Root type='multiple' defaultValue={forms.map((form) => form.id)}>
							{forms.map((form) => (
								<Accordion.Item value={form.id} asChild>
									<TaskAccordion.Item>
										<Accordion.Header asChild>
											<Accordion.Trigger asChild>
												<TaskAccordion.Trigger href={form.id}>{form.label}</TaskAccordion.Trigger>
											</Accordion.Trigger>
										</Accordion.Header>
										<Accordion.Content asChild>
											<TaskAccordion.Content>{form.component}</TaskAccordion.Content>
										</Accordion.Content>
									</TaskAccordion.Item>
								</Accordion.Item>
							))}
						</Accordion.Root>
					</S.ContentContainer>
				</CRScrollSpyContainer>
				<S.FooterContainer>
					<S.FooterContentContainer>
						{myAccountInfo?.centerId !== 9 && (
							<CRPageFooter
								leftButtons={[
									{
										palette: 'gray',
										buttonType: 'button',
										type: 'outlined',
										onClick: handleClickLoadPreviousData,
										children: '불러오기',
									},
								]}
								rightButtons={[
									{
										palette: 'gray',
										buttonType: 'button',
										type: 'text',
										onClick: handleClickCancel,
										children: '취소',
									},
									{
										palette: 'primary',
										buttonType: 'button',
										type: 'filled',
										onClick: handleClickConfirm,
										children: '저장',
									},
								]}
							/>
						)}
					</S.FooterContentContainer>
				</S.FooterContainer>
			</S.MainComponentContainer>
		</TaskLayout>
	);
}

export default IntegratedAssessmentDesireInfoPage;
