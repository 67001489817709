import React from 'react';
import { useLocation } from 'react-router-dom';

import { SideNavigationMenuItem } from 'types/view/navigation';

import * as S from './styles';

interface IProps {
	item: SideNavigationMenuItem;
}

function MyCenterLeftSideNavigationItem({ item }: IProps): React.ReactElement {
	const location = useLocation();
	const fullPath = location.pathname + location.search;
	const isCurrent = !!fullPath.startsWith(item.path);

	return (
		<S.MenuContainer to={isCurrent ? fullPath : item.path} replace>
			<S.MenuLabel>{item.label}</S.MenuLabel>
		</S.MenuContainer>
	);
}

export default MyCenterLeftSideNavigationItem;
