import React, { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';

import RouterPath from 'common/router';
import CRSyncMenu from 'components/base/CRSyncMenu';
import CRTab from 'components/base/CRTab';
import { TabProps } from 'components/base/CRTab/DetailTab/type';
import { Toast } from 'components/base/CRToast';
import { SyncDialog } from 'components/domain/dialog/SyncDialog';
import TagErrorSyncHistoryDialog from 'components/domain/dialog/TagErrorSyncHistoryDialog';
import { displayDateToYYYYMM } from 'lib';
import {
	useAutomationLastSync,
	useMyAccountInfo,
	useSendSlack,
	useTagErrorAutomation,
	useUpdateAutomationUploadList,
} from 'lib/hook/react-query';
import useDateFilter from 'lib/hook/util/useDateFilter';
import useDialog from 'lib/hook/util/useDialog';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import { endpoint } from 'lib/service/Api/endpoint';
import { ResponseCode } from 'types/api';

import TagErrorTab from './TagErrorTab';
import * as S from './styles';

export default function TagErrorPage() {
	const tagErrorTab = RouterPath.tagErrorTab() as {
		recipient: { key: string; label: string };
		[key: string]: { key: string; label: string };
	};

	const dialog = useDialog();
	const [date, setDate] = useDateFilter(dayjs().subtract(1, 'month').toDate());
	const [isTempSync, setIsTempSync] = useState(true);
	const [currentTab, setCurrentTab] = useState<TabProps>();
	const handleChangeTab = (item: TabProps) => {
		setCurrentTab(item);
	};

	const { data: myAccountInfo } = useMyAccountInfo();

	const centerId = myAccountInfo?.centerId;

	const { data: lastSyncData } = useAutomationLastSync({
		centerId,
		uploadTypeCds: ['CMN107.RFID'],
	});

	const { mutate: sendSlack } = useSendSlack();

	const { mutate: tagErrorAutomation, isLoading: tagErrorAutomationLoading } =
		useTagErrorAutomation((client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				setIsTempSync(true);
				Toast.success(
					'태그 내역 동기화 요청을 하였습니다. 잠시 뒤 자동화 업데이트 후 반영될 예정입니다.',
				);
			} else {
				Toast.error('태그 내역 동기화 요청에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		});

	const { mutate: updateAutomationUploadHist } = useUpdateAutomationUploadList(
		(client, returnData) => {
			client.invalidateQueries([endpoint.getAutomationLastSync.key]);
			client.invalidateQueries([endpoint.getAutomationUploadList.key]);
		},
	);

	const hasPermission = useHasPermission('센터장');

	const handleScheduleSync = async (data: { yearMonth: Date }) => {
		if (!centerId) {
			return;
		}
		const YYYYMM = displayDateToYYYYMM(data.yearMonth);
		tagErrorAutomation({ centerId: `${centerId}`, yyyymm: YYYYMM });

		dialog.hideDialog();
	};

	const handleChangeDate = (newDate: Date) => {
		setDate(newDate);
	};

	const handleClickSyncButton = () => {
		dialog.showDialog(() => <SyncDialog title='태그 오류' onSync={handleScheduleSync} />);
	};

	const handleClickSyncHistory = () => {
		dialog.showDialog(() => <TagErrorSyncHistoryDialog uploadTypeCds='CMN107.RFID' />);
	};
	const isProgress = useMemo(() => isTempSync, [isTempSync]);

	const tabRightButtonRenderer = () => (
		<S.TabRightButtonContainer>
			<CRSyncMenu
				showSyncButton={hasPermission}
				title={isProgress ? '태그 동기화 중' : '태그 동기화'}
				isProgress={isProgress}
				onClickSync={handleClickSyncButton}
				onClickSyncHistory={handleClickSyncHistory}
			/>
		</S.TabRightButtonContainer>
	);

	useEffect(() => {
		if (
			lastSyncData &&
			lastSyncData.uploadHistId &&
			(lastSyncData.uploadStateCd === 'CMN108.REQUEST' ||
				lastSyncData.uploadStateCd === 'CMN108.PROGRESS')
		) {
			setIsTempSync(true);
			const minuteDiff = dayjs().diff(dayjs(lastSyncData.uploadStartDate), 'minute');

			if (minuteDiff >= 10) {
				updateAutomationUploadHist({ uploadHistId: lastSyncData.uploadHistId });
				sendSlack({
					message: '수급자 태그 오류 동기화 자동화 이슈 발생! 확인 필요합니다.',
				});
				setIsTempSync(false);
			}
		} else {
			setIsTempSync(false);
		}
	}, [lastSyncData]);

	return (
		<CRTab.Default
			defaultActiveKey={currentTab?.key}
			breadCrumb='태그 오류 관리'
			onChange={handleChangeTab}
			renderRightButton={tabRightButtonRenderer()}
			items={[
				{
					label: tagErrorTab.recipient.label,
					key: tagErrorTab.recipient.key,
					children: <TagErrorTab date={date} onChangeDate={handleChangeDate} />,
				},
			]}
		/>
	);
}
