import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';

import Assets from 'assets';
import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInputMessage from 'components/base/CRInputMessage';
import useAuth from 'lib/hook/auth/useAuth';
import { SignInForm } from 'lib/provider/auth';
import { signIn } from 'lib/util/validator';

import * as S from './styles';

function SignInPage(): React.ReactElement {
	const auth = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const { control, handleSubmit, watch } = useForm<SignInForm>({
		resolver: yupResolver(signIn),
		mode: 'onSubmit',
	});
	const from = location.state?.from?.pathname || '/recipient';

	const onSubmit = async (signInForm: SignInForm) => {
		const shouldNewPassword = await auth.signIn?.(signInForm);

		if (shouldNewPassword) {
			navigate(RouterPath.newPassword(), {
				replace: true,
				state: {
					from,
				},
			});
		} else {
			navigate(from, { replace: true });
		}
	};

	const handleClickSignUp = () => {
		navigate(RouterPath.signUp());
	};

	return (
		<S.Container>
			<S.Contents>
				<S.Logo src={Assets.img.logo} alt='caring' />
				<S.Form onSubmit={handleSubmit(onSubmit)}>
					<S.InputContainer>
						<Controller
							render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
								<CRInputLabel label='아이디'>
									<CRInput.Default
										ref={ref}
										onBlur={onBlur}
										status={errors.userId?.message ? 'error' : 'default'}
										onChange={onChange}
										value={value}
										placeholder='아이디 입력'
									/>
								</CRInputLabel>
							)}
							name='userId'
							control={control}
						/>
						<Controller
							render={({ field: { onBlur, onChange, value }, formState: { errors } }) => (
								<CRInputLabel
									label='비밀번호'
									message={
										errors.password?.message ? (
											<CRInputMessage type='error'>{errors.password?.message ?? ''}</CRInputMessage>
										) : (
											<CRInputMessage>8~16자 영문 대・소문자,숫자,특수문자 조합</CRInputMessage>
										)
									}>
									<CRInput.Password
										autoComplete
										onBlur={onBlur}
										status={errors.password ? 'error' : 'default'}
										onChange={onChange}
										value={value}
										placeholder='비밀번호 입력'
										toggleSecret
									/>
								</CRInputLabel>
							)}
							name='password'
							control={control}
						/>
					</S.InputContainer>
					<S.ButtonContainer>
						<CRButton.Default
							buttonType='submit'
							size='large'
							fullSize
							disabled={!(watch('password') && watch('userId'))}>
							로그인
						</CRButton.Default>
						<CRButton.Default
							buttonType='button'
							type='outlined'
							palette='gray'
							size='large'
							onClick={handleClickSignUp}
							fullSize>
							회원가입
						</CRButton.Default>
					</S.ButtonContainer>
				</S.Form>
			</S.Contents>
		</S.Container>
	);
}

export default SignInPage;
