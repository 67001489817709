import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import CRInput from 'components/base/CRInput';
import { DaumPostcodeData } from 'lib/hook/util/useDaumPostCode';

interface Props extends React.ComponentProps<typeof CRInput.Address> {
	name: string;
}

function CRFormAddressSearch({ name }: Props): React.ReactElement {
	const methods = useFormContext();
	const { field } = useController({ name, control: methods.control });

	const handleChangeBaseAddress = (postcodeData: DaumPostcodeData) => {
		methods?.setValue(`${name}.baseAddr`, postcodeData.address);
		methods?.setValue(`${name}.zipCode`, postcodeData.zonecode);
		methods?.setValue(`${name}.addrLatitude`, postcodeData.latitude ?? 0);
		methods?.setValue(`${name}.addrLongitude`, postcodeData.longitude ?? 0);
	};

	const handleChangeDetailAddress = (detailAddress: string) => {
		methods?.setValue(`${name}.detailAddr`, detailAddress);
	};

	return (
		<CRInput.Address
			{...field}
			onChangeBaseAddress={handleChangeBaseAddress}
			onChangeDetailAddress={handleChangeDetailAddress}
			baseAddress={field.value.baseAddr}
			detailAddress={field.value.detailAddr}
		/>
	);
}

export default CRFormAddressSearch;
