import React, { useMemo } from 'react';

import RouterPath from 'common/router';
import CRTab from 'components/base/CRTab';

import { CenterUserTab } from './CenterUserTab';

function CenterUserPage(): React.ReactElement {
	const userTab = useMemo(() => RouterPath.myCenterOperate().user, []);

	return (
		<CRTab.Default
			defaultActiveKey={userTab.key}
			items={[
				{
					label: userTab.label,
					key: userTab.key,
					children: <CenterUserTab />,
				},
			]}
			breadCrumb='계정'
		/>
	);
}

export default CenterUserPage;
