import React, { useEffect, useMemo } from 'react';
import { Controller, FieldErrors, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import CRRsdNumber from 'components/base/CRRsdNumber';
import { Toast } from 'components/base/CRToast';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import { RecipientBasicInfo } from 'lib';
import { useCommonCodes, useMyAccountInfo, useUpdateRecipientBase } from 'lib/hook/react-query';
import { DaumPostcodeData } from 'lib/hook/util/useDaumPostCode';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { GetRecipientsData } from 'types/api/common';
import { Recipient, RecipientBasicInfoFormType } from 'types/view/recipient';

import * as S from './styles';

interface Props {
	currentRecipient?: Recipient;
	item?: RecipientBasicInfoFormType;
	onlyEdit?: boolean;
	onStartChangeSection?: () => void;
	onCancelChangeSection?: () => void;
	isEditSectionChanging?: boolean;
}

function RecipientBasicInfoForm({
	item,
	onlyEdit = false,
	currentRecipient,
	onStartChangeSection,
	onCancelChangeSection,
	isEditSectionChanging,
}: Props): React.ReactElement {
	const { control, handleSubmit, watch, setValue, getValues } = useForm<RecipientBasicInfoFormType>(
		{
			resolver: yupResolver(RecipientBasicInfo),
			mode: 'onSubmit',
			defaultValues: item,
		},
	);
	const { data: signTypeCommonCodes } = useCommonCodes({ comCdGroupNms: ['CMN143'] });
	const { data: myAccountInfo } = useMyAccountInfo();
	const updateRecipientBaseMutation = useUpdateRecipientBase(async (client, res, variables) => {
		if (res?.recipientId) {
			await client.invalidateQueries([
				endpoint.getRecipient.key,
				{ recipientId: variables.recipientId },
			]);
			await client.invalidateQueries([
				endpoint.getRecipientBase.key,
				{ recipientId: variables.recipientId },
			]);

			client.setQueryData<GetRecipientsData | undefined>(
				[endpoint.getRecipients.key, { centerIds: myAccountInfo?.centerId }],
				(data) => {
					if (!data) return data;
					const newData: GetRecipientsData = {
						count: data.count,
						recipients: [...data.recipients],
					};
					const recipients = newData?.recipients ?? [];
					const index = recipients?.findIndex(
						(item) => Number(item.recipientId) === variables.recipientId,
					);
					if (index === -1) return { ...data };
					recipients[index] = {
						...recipients[index],
						korMemberNm: variables.recipientNm,
						birthDt: variables.birthDt,
					};

					return { count: data.count, recipients: [...recipients] };
				},
			);

			onStartChangeSection?.();
			Toast.success('기본 정보를 수정했습니다.');
		} else {
			onCancelChangeSection?.();
		}
	});
	const { showDialog } = useDialog();

	const signTypeOptions = useMemo(
		() =>
			(signTypeCommonCodes ?? []).map(
				(item) =>
					({
						label: item.korComCdAliasNm,
						value: item.comCdId,
					}) as CheckOption,
			),
		[signTypeCommonCodes],
	);

	const onSubmitSuccess = async (form: RecipientBasicInfoFormType) => {
		if (updateRecipientBaseMutation.isLoading) return;
		try {
			await updateRecipientBaseMutation.mutateAsync({
				recipientId: Number(currentRecipient?.recipientId),
				recipientNm: form.name,
				rsdnNo: form.korMemberNm,
				birthDt: form.birthday,
				basAddr: form.address.basAddr,
				detailAddr: form.address.detailAddr,
				regionNm1: form.address.regionNm1,
				regionNm2: form.address.regionNm2,
				regionNm3: form.address.regionNm3,
				roadNm: form.address.roadNm,
				addrLatitude: form.address.addrLatitude,
				addrLongitude: form.address.addrLongitude,
				zipCode: form.address.zipCode,
				signMethodCd: form.signType,
				recipientSpecialDesc: form.memo,
			});
		} catch (error) {
			console.log(error);
		}
	};

	const onSubmitFail = (errors: FieldErrors<RecipientBasicInfoFormType>) => {
		Toast.error(Object.values(errors)?.[0]?.message || '입력폼을 확인해주세요');
		onCancelChangeSection?.();
	};

	const onSubmitHandler = () => {
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='변경된 정보 저장'
				content='기본 정보에서 변경된 정보를 저장합니다.'
				successOption={{
					text: '저장',
					successCallback: () => {
						hideDialog();
						handleSubmit(onSubmitSuccess, onSubmitFail)();
					},
				}}
				cancelOption={{
					text: '저장안함',
					callback: () => {
						hideDialog();
						onStartChangeSection?.();
					},
				}}
				hideDialog={() => {
					hideDialog();
					onCancelChangeSection?.();
				}}
			/>
		));
	};

	useEffect(() => {
		if (isEditSectionChanging) {
			onSubmitHandler();
		}
	}, [isEditSectionChanging]);

	const handleChangeBaseAddress = (postcodeData: DaumPostcodeData) => {
		setValue('address', {
			...getValues('address'),
			basAddr: postcodeData.address,
			zipCode: postcodeData.zonecode,
			addrLatitude: postcodeData.latitude,
			addrLongitude: postcodeData.longitude,
			regionNm1: postcodeData.sido,
			regionNm2: postcodeData.sigungu,
			regionNm3: postcodeData.bname,
			roadNm: postcodeData.roadname,
		});
	};
	const handleChangeDetailAddress = (detailAddress: string) => {
		setValue('address', {
			...getValues('address'),
			detailAddr: detailAddress,
		});
	};

	return (
		<S.Container>
			<S.Form>
				{!onlyEdit && (
					<S.Label>
						기본 정보
						<S.ButtonContainer>
							<CRButton.Default
								size='xSmall'
								type='outlined'
								palette='gray'
								onClick={onSubmitHandler}>
								취소
							</CRButton.Default>
							<CRButton.Default
								buttonType='button'
								size='xSmall'
								type='filled'
								palette='gray'
								onClick={handleSubmit(onSubmitSuccess, onSubmitFail)}>
								저장
							</CRButton.Default>
						</S.ButtonContainer>
					</S.Label>
				)}
				<S.Table>
					<S.TableRow>
						<S.TableLabelColumn style={{ width: '10.4rem' }}>
							이름 <S.RequiredMark>*</S.RequiredMark>
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '24rem' }}>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRInput.TableInput
										ref={ref}
										onBlur={onBlur}
										status={errors.name?.message ? 'error' : 'default'}
										onChange={onChange}
										value={value}
										placeholder='수급자명 입력'
										addOnBottom={errors.name?.message}
									/>
								)}
								name='name'
								control={control}
							/>
						</S.TableValueColumn>
						<S.TableLabelColumn rowSpan={2} style={{ width: '10.4rem' }}>
							주소
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '24rem', verticalAlign: 'middle' }} rowSpan={2}>
							<CRInput.Address
								onChangeBaseAddress={handleChangeBaseAddress}
								onChangeDetailAddress={handleChangeDetailAddress}
								baseAddress={watch('address').basAddr}
								detailAddress={watch('address').detailAddr}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn style={{ width: '10.4rem' }}>주민등록번호</S.TableLabelColumn>
						<S.TableLabelColumn>
							<CRRsdNumber rdsNumber={item?.korMemberNm} textColor='gray60' />
						</S.TableLabelColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn style={{ width: '10.4rem' }}>
							생년월일 <S.RequiredMark>*</S.RequiredMark>
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '24rem' }}>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRInput.TableInput
										type='number'
										ref={ref}
										onBlur={onBlur}
										status={errors.birthday?.message ? 'error' : 'default'}
										onChange={onChange}
										value={value}
										placeholder='예시) 19280301'
										maxLength={8}
										addOnBottom={errors.birthday?.message}
									/>
								)}
								name='birthday'
								control={control}
							/>
						</S.TableValueColumn>
						<S.TableLabelColumn style={{ width: '9.6rem' }} rowSpan={2}>
							비고
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '24rem', verticalAlign: 'middle' }} rowSpan={2}>
							<Controller
								render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
									<CRInput.TextArea
										typography='label'
										ref={ref}
										onBlur={onBlur}
										status={errors.memo?.message ? 'error' : 'default'}
										onChange={onChange}
										value={value}
										placeholder='비고 입력'
										numberOfLines={2}
									/>
								)}
								name='memo'
								control={control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn style={{ width: '10.4rem' }}>서명</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '24rem' }}>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => {
									const selectedOption = signTypeOptions.find((option) => option.value === value);
									return (
										<CRCheckBoxGroup
											ref={ref}
											checkType='single'
											type='radio'
											gap={0.8}
											value={selectedOption && [selectedOption]}
											onChange={(value: CheckOption[]) => {
												onChange(value[0].value);
											}}
											options={signTypeOptions}
										/>
									);
								}}
								name='signType'
								control={control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
				</S.Table>
			</S.Form>
		</S.Container>
	);
}

export default RecipientBasicInfoForm;
