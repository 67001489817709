import React, { useEffect, useMemo, useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';

import { Toast } from 'components/base/CRToast';
import { CheckOption } from 'components/base/Selections/type';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes, useMyAccountInfo } from 'lib/hook/react-query';
import { useSaveCenterInfo } from 'lib/hook/react-query/mutation/centerInfo';
import { useCenterChiefs, useMyCenterInfo } from 'lib/hook/react-query/query/centerInfo';
import { getFileExtension } from 'lib/util/file';
import { ResponseCode } from 'types/api';
import { GetCenterInfoDTO, SaveCenterInfoRequest } from 'types/api/centerInfo';
import { CommonCodeDTO } from 'types/api/common';
import { FileDetailDTO } from 'types/dto';
import { CenterInfoFormType, WorkLeaveLimitCd } from 'types/view/centerInfo';

interface CenterInformationContextType {
	form: UseFormReturn<CenterInfoFormType>;
	commonCodes: {
		serviceOfferOptions: CheckOption<CommonCodeDTO>[];
		workLeaveOptions: CheckOption<CommonCodeDTO>[];
		centerChiefOptions: CheckOption<CommonCodeDTO>[];
		[key: string]: CheckOption<CommonCodeDTO>[];
	};
	centerInfoData?: GetCenterInfoDTO;
	saveCenterInformation?: () => void;
	validate?: (config?: { ignoreCheck?: boolean; isCreateDocument?: boolean }) => void;
	isLoaded?: boolean;
	isEdit: boolean;
	toggleEdit: () => void;
}

export const CenterInformationContext = React.createContext<CenterInformationContextType | null>(
	null,
);

function CenterInformationProvider({ children }: { children: React.ReactNode }) {
	const [isEdit, setIsEdit] = useState(false);
	const [isLoaded] = useState(false);
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: centerInfoData, refetch: centerInfoRefetch } = useMyCenterInfo({
		centerId: myAccountInfo?.centerId,
	});

	const { data: centerChiefs } = useCenterChiefs();

	const centerChiefOptions = useMemo(
		() =>
			centerChiefs
				?.filter((item) => item?.centerId === myAccountInfo?.centerId)
				?.map((item) => ({
					label: item.userNm,
					value: item.memberAccountId,
				})) || [],
		[centerChiefs, myAccountInfo],
	);

	const saveCenterInformationMutation = useSaveCenterInfo();

	const form = useForm<CenterInfoFormType>({
		defaultValues: {},
	});

	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN006', 'CMN185'],
		},
		commonCodeAdapter,
	);

	const serviceOfferOptions = useMemo(() => commonCodes?.CMN006 || [], [commonCodes]);
	const workLeaveOptions = useMemo(
		() =>
			commonCodes?.CMN185?.map((item) => {
				if (item.value === 'CMN185.10') {
					return {
						...item,
						label: '제한 없음',
					};
				}
				if (item.value === 'CMN185.20') {
					return {
						...item,
						label: '허가된 IP만 허용',
					};
				}
				return item;
			}) || [],
		[commonCodes],
	);

	const validate = () => {
		if (!form.getValues('centerNm')?.length) {
			Toast.error('기본정보 - 센터명을 입력해주세요.');
			// initHash('#1');
			return false;
		}

		if (!form.getValues('centerOfferServices')?.length) {
			Toast.error('기본정보 - 서비스를 선택해주세요.');
			return false;
		}
		if (!form.getValues('centerPhoneNo')) {
			Toast.error('기본정보 - 전화번호를 입력해주세요.');
			// initHash('#2');
			return false;
		}

		if (!form.getValues('centerBasAddr')) {
			Toast.error('기본정보 - 기본 주소를 입력해주세요.');
			// initHash('#2');
			return false;
		}
		if (!form.getValues('centerDetailAddr')) {
			Toast.error('기본정보 - 상세 주소를 입력해주세요.');
			// initHash('#2');
			return false;
		}
		if (!form.getValues('centerChief')) {
			Toast.error('기본정보 - 센터장을 선택해주세요.');
			// initHash('#2');
			return false;
		}
		if (!form.getValues('bizNo')) {
			Toast.error('기본정보 - 사업자등록번호를 입력해주세요.');
			// initHash('#2');
			return false;
		}

		if (!form.getValues('centerMark')) {
			Toast.error('기본정보 - 센터기호를 입력해주세요.');
			// initHash('#2');
			return false;
		}

		if (!form.getValues('stampFile')?.find((item) => !item.fileDeleteYn)) {
			Toast.error('기본정보 - 센터 직인을 등록해주세요.');
			// initHash('#2');
			return false;
		}

		if (
			form.getValues('workLeaveLimitCd')?.[0]?.value === WorkLeaveLimitCd.PARTIALLY_ALLOWED &&
			!form.getValues('workLeavePermitIps')?.[0]?.address
		) {
			Toast.error('모바일 출퇴근 IP 제한 설정 - 허가된 IP를 입력해주세요.');
			// initHash('#2');
			return false;
		}

		if (!form.getValues('pcorpCertiFile')?.find((item) => !item.fileDeleteYn)) {
			Toast.error('센터 공동인증서 - 공동인증서 (파일)를 등록해주세요.');
			// initHash('#2');
			return false;
		}

		if (!form.getValues('pcorpLoginPassword')) {
			Toast.error('센터 공동인증서 - 인증서 비밀번호를 입력해주세요');
			return false;
		}

		return true;
	};
	const saveCenterInformation = async () => {
		if (saveCenterInformationMutation.isLoading) return;

		if (!validate() || !myAccountInfo?.centerId || !centerInfoData) return;

		const param: SaveCenterInfoRequest = {
			...centerInfoData,
			centerId: myAccountInfo.centerId,
			centerNm: form.getValues('centerNm'),
			centerAliasNm: centerInfoData.centerAliasNm,
			centerBasAddr: form.getValues('centerBasAddr'),
			centerDetailAddr: form.getValues('centerDetailAddr'),
			centerZipCode: form.getValues('centerZipCode'),
			centerAddrLatitude: form.getValues('centerAddrLatitude'),
			centerAddrLongitude: form.getValues('centerAddrLongitude'),
			centerPhoneNo: form.getValues('centerPhoneNo'),
			centerChiefId: form.getValues('centerChief').value,
			centerChiefNm: form.getValues('centerChief').label,
			bizNo: form.getValues('bizNo'),
			centerMark: form.getValues('centerMark'),
			workLeaveLimitCd: form.getValues('workLeaveLimitCd')?.[0].value,
			centerOfferServices: form.getValues('centerOfferServices')?.map((item) => ({
				serviceTypeCd: item.value,
				laborCostDisplayYn: false,
			})),
			centerOfferServiceNm: form
				.getValues('centerOfferServices')
				?.map((item) => item.label)
				?.join(', '),
			pcorpLoginId: form.getValues('centerMark'),
			pcorpLoginPassword: form.getValues('pcorpLoginPassword'),
		};

		if (form.getValues('workLeaveLimitCd')?.[0].value === WorkLeaveLimitCd.PARTIALLY_ALLOWED) {
			param.workLeavePermitIps = form.getValues('workLeavePermitIps').map((item) => ({
				workLeavePermitIp: item.address,
			}));
		}

		if (form.getValues('w4cCodeValue')) {
			param.w4cCodeValue = form.getValues('w4cCodeValue');
		}

		if (form.getValues('centerApiNm')) {
			param.centerApiNm = form.getValues('centerApiNm');
		}

		if (form.getValues('pcorpCertiFile')?.length) {
			const fileDetails = form
				.getValues('pcorpCertiFile')
				?.filter((item) => !item.fileDeleteYn) as FileDetailDTO[];
			if (fileDetails) {
				param.pcorpCertiFile = {
					fileDetails,
				};
				if (
					centerInfoData?.pcorpCertiFile?.fileId &&
					centerInfoData?.pcorpCertiFile?.fileDetails?.[0]?.fileSize ===
						fileDetails?.[0]?.fileSize &&
					centerInfoData?.pcorpCertiFile?.fileDetails?.[0]?.originFileNm ===
						fileDetails?.[0]?.originFileNm
				) {
					param.pcorpCertiFile = {
						...param.pcorpCertiFile,
						fileId: centerInfoData.pcorpCertiFile.fileId,
					};
				}
				const file = fileDetails.find((item) => !item.fileDeleteYn);
				const extension = getFileExtension(file?.originFileNm);

				param.pcorpCertiFileNm = `${param.centerMark}.${extension}`;
			}
		}
		if (form.getValues('stampFile')?.length) {
			const fileDetails = form
				.getValues('stampFile')
				?.filter((item) => !item.fileDeleteYn) as FileDetailDTO[];
			if (fileDetails) {
				param.stampFile = {
					fileDetails,
				};
				if (centerInfoData?.stampFile?.fileId) {
					param.stampFile = {
						...param.stampFile,
						fileId: centerInfoData.stampFile.fileId,
					};
				}
			}
		}

		const result = await saveCenterInformationMutation.mutateAsync(param);
		if (result?.code === ResponseCode.SUCCESS) {
			Toast.success('센터정보를 저장했습니다.');
			await centerInfoRefetch();
			setIsEdit(false);
		}
	};

	const initForm = () => {
		const centerOfferServices =
			centerInfoData?.centerOfferServices?.length && serviceOfferOptions
				? centerInfoData.centerOfferServices.map((item) =>
						serviceOfferOptions?.find((option) => option?.value === item.serviceTypeCd),
					)
				: [];

		const workLeaveLimitCd = workLeaveOptions?.find(
			(option) => option?.value === centerInfoData?.workLeaveLimitCd,
		)
			? [workLeaveOptions.find((option) => option?.value === centerInfoData?.workLeaveLimitCd)]
			: [];

		const workLeavePermitIps =
			centerInfoData?.workLeavePermitIps?.map((item) => ({
				id: item.workLeavePermitIpId,
				address: item.workLeavePermitIp,
				visible: false,
			})) || [];

		const centerChief = centerChiefOptions?.find(
			(item) => item.value === centerInfoData?.centerChiefId,
		);

		form.reset({
			centerNm: centerInfoData?.centerNm || '',
			centerAliasNm: centerInfoData?.centerAliasNm || '',
			centerOfferServices,
			workLeaveLimitCd,
			centerBasAddr: centerInfoData?.centerBasAddr || '',
			centerDetailAddr: centerInfoData?.centerDetailAddr || '',
			centerZipCode: centerInfoData?.centerZipCode || '',
			centerAddrLatitude: centerInfoData?.centerAddrLatitude || null,
			centerAddrLongitude: centerInfoData?.centerAddrLongitude || null,
			centerPhoneNo: centerInfoData?.centerPhoneNo
				? centerInfoData?.centerPhoneNo.replaceAll('-', '')
				: '',
			bizNo: centerInfoData?.bizNo || '',
			centerMark: centerInfoData?.centerMark || '',
			stampFile: centerInfoData?.stampFile?.fileDetails,
			centerApiNm: centerInfoData?.centerApiNm || '',
			w4cCodeValue: centerInfoData?.w4cCodeValue || '',
			workLeavePermitIps,
			centerChief,
			pcorpCertiFile: centerInfoData?.pcorpCertiFile?.fileDetails,
			pcorpLoginPassword: centerInfoData?.pcorpLoginPassword || '',
		} as CenterInfoFormType);
	};

	const handleToggleEdit = () => {
		if (isEdit) {
			initForm();
		}
		setIsEdit((prev) => !prev);
	};

	useEffect(() => {
		initForm();
	}, [centerInfoData, serviceOfferOptions, workLeaveOptions, centerChiefOptions]);

	const value = useMemo(
		() => ({
			form,
			commonCodes: { ...commonCodes, serviceOfferOptions, workLeaveOptions, centerChiefOptions },
			saveCenterInformation,
			centerInfoData: centerInfoData || undefined,
			validate,
			isLoaded,
			isEdit,
			toggleEdit: handleToggleEdit,
		}),
		[
			form,
			commonCodes,
			isLoaded,
			handleToggleEdit,
			isEdit,
			serviceOfferOptions,
			workLeaveOptions,
			centerInfoData,
			centerChiefOptions,
		],
	);

	return (
		<CenterInformationContext.Provider value={value}>{children}</CenterInformationContext.Provider>
	);
}

export default React.memo(CenterInformationProvider);
