import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import Assets from 'assets';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { approval } from 'lib';
import useAuth from 'lib/hook/auth/useAuth';

import * as S from './styles';

export type ApprovalInput = {
	type: { label: string; value: any };
	title: string;
	content: string;
	relatedWork: string;
	attachment: File[];
};

export interface Props {
	defaultValues?: {
		type: string;
		title: string;
		content: string;
	};
}

export default function ApprovalForm({ defaultValues }: Props) {
	const { control, handleSubmit, setValue } = useForm<ApprovalInput>({
		resolver: yupResolver(approval),
		mode: 'onChange',
	});

	const onSubmit = (data: ApprovalInput) => {
		console.log('submitData', data);
		alert('상신 성공!');
	};

	const initForm = () => {
		if (defaultValues?.type) {
			setValue('type', { label: defaultValues.type, value: defaultValues.type });
		}
		if (defaultValues?.content) {
			setValue('content', defaultValues.content);
		}
		if (defaultValues?.title) {
			setValue('title', defaultValues.title);
		}
	};

	useEffect(() => {
		initForm();
	}, [defaultValues]);

	return (
		<S.Container>
			<S.Title>결재 내용</S.Title>
			<S.FormContainer
				id='approval-form'
				onSubmit={handleSubmit(onSubmit, (err) => console.log('err', err))}>
				<S.FormItem>
					<CRInputLabel label='종류' isRequired>
						<Controller
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInput.Selector
									items={[
										{
											label: '기본결제',
											value: 'approval_template1',
										},
										{
											label: '업무검토',
											value: 'approval_template2',
										},
									]}
									onChangeValue={onChange}
									placeholder='유형을 선택해주세요'
									currentValue={value}
								/>
							)}
							name='type'
							control={control}
						/>
					</CRInputLabel>
				</S.FormItem>
				<S.FormItem>
					<CRInputLabel label='제목' isRequired>
						<Controller
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInput.Default onChange={onChange} value={value} />
							)}
							name='title'
							control={control}
						/>
					</CRInputLabel>
				</S.FormItem>
				<S.FormItem>
					<CRInputLabel label='내용' isRequired>
						<Controller
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInput.TextArea numberOfLines={9} onChange={onChange} value={value} />
							)}
							name='content'
							control={control}
						/>
					</CRInputLabel>
				</S.FormItem>
				<S.FormItem>
					<CRInputLabel label='연결업무'>
						<S.RelatedTask $hasTask={!!defaultValues?.title}>
							<S.RelatedTaskLeft>
								<S.Icon src={Assets.icon.link} alt='link' />
								<span>{defaultValues?.title}</span>
							</S.RelatedTaskLeft>
							<S.GoToRelatedTaskPageIcon
								src={Assets.icon.keyboardArrowRight}
								alt='keyboardArrowRight'
							/>
						</S.RelatedTask>
					</CRInputLabel>
				</S.FormItem>
				<S.FormItem>
					<CRInputLabel label='첨부파일'>
						<Controller
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInput.FileUploader type='multiple' onChange={onChange} files={value} />
							)}
							name='attachment'
							control={control}
						/>
					</CRInputLabel>
				</S.FormItem>
			</S.FormContainer>
		</S.Container>
	);
}
