import React, { useMemo } from 'react';

import EDocViewer from 'components/base/CRFileViewer/viewer/EDocViewer';
import EDocClientBottomFloating from 'components/ui/EDoc/EDocClientBottomFloating';
import EDocClientButton from 'components/ui/EDoc/EDocClientButton';
import EDocDialogs from 'components/ui/EDoc/EDocDialogs';
import EDocRecordWriteForm, { RecordWriteFormList } from 'components/ui/EDoc/EDocRecordWriteForm';
import EDocWorkExecuteForm, { WorkExecuteFormList } from 'components/ui/EDoc/EDocWorkExecuteForm';
import useEDocClientPage from 'lib/hook/eDoc/useESignInfoPage';
import { useConfirmEDocClient, useConfirmEDocManager } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import WorkExecuteInspectionLogProvider from 'lib/provider/workExecuteInspectionLog';
import { ESignDivCd, ESignStateCd } from 'types/api/eDoc';

interface Props {
	onConfirm: () => void;
}

function ESignInfoConfirmStep({ onConfirm }: Props): React.ReactElement {
	const { showDialog, hideDialog } = useDialog();
	const { eDocInfo, authInfo, setEDocConfirmResult, isErp } = useEDocClientPage();
	const { mutateAsync: confirmEDocClient } = isErp
		? useConfirmEDocManager()
		: useConfirmEDocClient();

	const parsedEDocParamValue = useMemo(
		() => JSON.parse(eDocInfo?.edocPreviewResponse.data.edocParam.edocParamValue || '""'),
		[eDocInfo],
	);

	const isSubmittable = [ESignStateCd.OPENED, ESignStateCd.WRITTEN].includes(
		eDocInfo?.esignStateCd as ESignStateCd,
	);

	const eDocTitle = eDocInfo?.edocPreviewResponse?.edocNm || '';

	const openConfirmModal = () => {
		const onClickConfirm = async () => {
			// 내부적으로 로직 이슈가 있을때
			if (!authInfo) {
				hideDialog();
				onConfirm();
				return;
			}
			// 응답을 저장하고 다음단계로 이동.
			const res = await confirmEDocClient({
				sendUuid: authInfo.sendUuid,
				birthDt: authInfo.birthDt,
			});
			if (res) {
				setEDocConfirmResult({ message: res.message, code: res.code });
			}
			hideDialog();
			onConfirm();
		};

		const SubmitModal = isErp ? EDocDialogs.EDocConfirm : EDocDialogs.EDocSubmit;

		showDialog(({ hideDialog }) => (
			<SubmitModal
				documentName={eDocInfo?.edocPreviewResponse.edocNm || '전자문서'}
				onSubmit={onClickConfirm}
				onCancel={hideDialog}
			/>
		));
	};

	// TODO 유효하지 않은 형식 예외처리
	if (!parsedEDocParamValue || !eDocInfo?.edocPreviewResponse.paperTypeCd) {
		return <div>유효하지 않은 형식입니다.</div>;
	}

	const { crfeNm, params } = parsedEDocParamValue;

	if (RecordWriteFormList.includes(eDocInfo.edocPreviewResponse.paperTypeCd)) {
		return (
			<EDocRecordWriteForm
				paperTypeCd={eDocInfo.edocPreviewResponse.paperTypeCd}
				crfeNm={crfeNm}
				params={params}
				onConfirm={onConfirm}
				isSubmittable={isSubmittable}
			/>
		);
	}

	// 업무수행일지 작성 -> 서명 완료의 경우 결과화면으로 보내는 케이스(추후 추가 가능성있음)
	// if (
	// 	WorkExecuteFormList.includes(eDocInfo.edocPreviewResponse.paperTypeCd) &&
	// 	eDocInfo.signDivCd === ESignDivCd.WRITTEN &&
	// 	eDocInfo.esignStateCd === ESignStateCd.CONFIRMED
	// ) {
	// 	setEDocConfirmResult({ code: '200', message: '작성 완료' });
	// 	onConfirm();
	// 	return <div />;
	// }

	if (
		WorkExecuteFormList.includes(eDocInfo.edocPreviewResponse.paperTypeCd) &&
		eDocInfo.signDivCd === ESignDivCd.WRITTEN &&
		eDocInfo.esignStateCd === ESignStateCd.WRITTEN
	) {
		setEDocConfirmResult({ code: '200', message: '작성 완료' });
		onConfirm();
		return <div />;
	}

	if (
		WorkExecuteFormList.includes(eDocInfo.edocPreviewResponse.paperTypeCd) &&
		eDocInfo.signDivCd === ESignDivCd.WRITTEN &&
		eDocInfo.esignStateCd === ESignStateCd.OPENED
	) {
		return (
			<WorkExecuteInspectionLogProvider>
				<EDocWorkExecuteForm onConfirm={onConfirm} />
			</WorkExecuteInspectionLogProvider>
		);
	}

	return (
		<>
			<EDocViewer
				eDocTitle={eDocTitle}
				showImgDownloader={false}
				showCustomHeader
				viewerMode='fullPage'
				style={{ height: `${window.innerHeight - 128}px` }}
				crfeName={crfeNm}
				params={params}
			/>
			{isSubmittable && (
				<EDocClientBottomFloating>
					<EDocClientButton.Submit onClick={openConfirmModal} />
				</EDocClientBottomFloating>
			)}
		</>
	);
}
export default ESignInfoConfirmStep;
