import { useContext } from 'react';

import { CenterInformationContext } from 'lib/provider/centerInformation';

export default function useCenterInfo() {
	const context = useContext(CenterInformationContext);
	if (!context) {
		throw new Error('useCenterInfo must be used within a CenterInformationContext');
	}
	return context;
}
