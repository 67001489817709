import React, { useEffect, useMemo, useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import RouterPath from 'common/router';
import { Toast } from 'components/base/CRToast';
import { CheckOption } from 'components/base/Selections/type';
import { WorkExecuteLogCode } from 'components/domain/form/WorkExecuteLogForm/WorkExecuteLogBasicInfoForm';
import { ServiceTypeCd } from 'components/domain/table/RecordingSheetTable/serviceEdocParam';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useUpdateWorkExecuteInspectionLog } from 'lib/hook/react-query/mutation/workExecuteLog';
import {
	useGetCustomerCenterManagers,
	useGetCustomerChiefs,
	useGetCustomerClients,
	useGetCustomerEmployees,
	useGetCustomerSelectBoxCommonCodes,
} from 'lib/hook/react-query/query/openApi';
import { useWorkExecuteInspectionLog } from 'lib/hook/react-query/query/workExecuteLog';
import { endpoint } from 'lib/service/Api/endpoint';
import { displayPadTime } from 'lib/util';
import {
	BaseResponse,
	ConsultClientGuardianInfo,
	EDocClientOpenData,
	ResponseCode,
} from 'types/api';
import { CommonCodeDTO } from 'types/api/common';
import { ESignHistoryDetailDTO } from 'types/api/send';
import {
	UpdateRecipientWorkExecuteLogRequest,
	WorkExecuteLogNewRecipient,
} from 'types/api/workExecuteLog';
import { WorkExecuteLogFormType } from 'types/view/workExecuteLog';

interface WorkExecuteInspectionLogContextType {
	form: UseFormReturn<WorkExecuteLogFormType>;
	commonCodes?: {
		[key: string]: CheckOption<CommonCodeDTO>[];
	};
	advisorOptions?: ConsultClientGuardianInfo[];
	updateWorkExecuteInspectionLog?: () => void;
	validate: (config?: { ignoreMessage?: boolean }) => boolean;
	isLoaded?: boolean;
	setBaseData: React.Dispatch<
		React.SetStateAction<
			| {
					edocNo: string;
					sendUuid: string;
					birthDt: string;
					docNo: string;
			  }
			| undefined
		>
	>;
	recipient: WorkExecuteLogNewRecipient;
	setType: React.Dispatch<React.SetStateAction<'wirte' | 'inspection'>>;
	setBirthDt: React.Dispatch<React.SetStateAction<string>>;
	setEdocData: React.Dispatch<React.SetStateAction<ESignHistoryDetailDTO | EDocClientOpenData>>;
	setUpdateEDocConfirmResultHandler: React.Dispatch<
		React.SetStateAction<(response: BaseResponse) => void>
	>;
}

export const WorkExecuteInspectionLogContext =
	React.createContext<WorkExecuteInspectionLogContextType | null>(null);

function WorkExecuteInspectionLogProvider({ children }: { children: React.ReactNode }) {
	const [isInitForm, setIsInitForm] = useState(false);
	const location = useLocation();
	const params = useParams();
	const navigate = useNavigate();
	const [isLoaded] = useState(false);
	const [type, setType] = useState<'wirte' | 'inspection'>();
	const [birthDt, setBirthDt] = useState<string>('');
	const [edocData, setEdocData] = useState<EDocClientOpenData>();
	const [updateEDocConfirmResultHandler, setUpdateEDocConfirmResultHandler] = useState<
		(response: BaseResponse) => void
	>(() => () => {});
	const { data: consultClient } = useWorkExecuteInspectionLog({
		edocUuid: (edocData as EDocClientOpenData)?.edocPreviewResponse.data.edocSend.edocUuid,
	});
	const { data: centerManager } = useGetCustomerCenterManagers({
		centerId: consultClient?.centerId,
	});
	const { data: clients } = useGetCustomerClients({
		centerId: consultClient?.centerId,
		recipientId: consultClient?.recipientId,
	});
	const { data: centerChiefs } = useGetCustomerChiefs();
	const { data: employeesData } = useGetCustomerEmployees({
		centerIds: consultClient?.centerId ? [consultClient.centerId] : undefined,
	});

	const employeesOptions = useMemo(
		() =>
			(employeesData ?? []).map(
				(item) =>
					({
						label: item.korMemberNm,
						value: {
							id: item.employeeId,
							name: item.korMemberNm,
							duty: item.dutyNms,
							birth: item.birthDt,
						},
					}) as CheckOption,
			),
		[employeesData],
	);

	const centerChiefOptions = useMemo(
		() =>
			centerChiefs
				?.filter((item) => item?.centerId === consultClient?.centerId)
				?.map((item) => ({
					label: item.userNm,
					value: item.memberAccountId,
				})) || [],
		[centerChiefs, consultClient],
	);

	const advisorOptions = clients?.[0]
		? [
				...(clients?.[0].guardians ?? []).filter((item) => !item.selfGuardianYn),
				{
					clientDivCd: clients?.[0].clientDivCd,
					clientId: clients?.[0].clientId,
					clientNm: clients?.[0].clientNm,
					clientBirth: clients?.[0].clientBirth,
				},
			]
		: [];

	const centerManagerOption = (centerManager ?? []).map(
		(item) => ({ label: item.userNm, value: item.memberAccountId }) as CheckOption,
	);

	const updateWorkExecuteInspectionLogMutation = useUpdateWorkExecuteInspectionLog((client) => {
		client.invalidateQueries([endpoint.getWorkExecuteInspectionLog.key]);
	});

	const form = useForm<WorkExecuteLogFormType>({
		defaultValues: {
			workExecuteLogDivCd: [],
			workExecuteLogStateCd: undefined,
			visitDt: undefined,
			visitor:
				centerManagerOption.find((item) => item.value === consultClient?.visitorId) || undefined,
			visitTime: undefined,
			facilityManager: undefined,
			visitSchedule: undefined,
			visitPlaceNm: '수급자 가정',
			salaryOfferVisitYn: undefined,
			consultDesc: '',
		},
	});

	const { data: commonCodes } = useGetCustomerSelectBoxCommonCodes(
		{
			comCdGroupNms: ['CMN006', 'CMN183', 'CMN184'],
		},
		commonCodeAdapter,
	);

	const recipient = useMemo(
		() =>
			({
				recipientId: consultClient?.recipientId,
				recipientNm: consultClient?.recipientNm,
				genderCd: consultClient?.recipientGenderCd,
				genderNm: consultClient?.recipientGenderNm,
				longTermGradeCd: consultClient?.longTermGradeCd,
				longTermGradeNm: consultClient?.longTermGradeNm,
				longTermNo: consultClient?.longTermNo,
				longTermMajorChangeNo: consultClient?.longTermMajorChangeNo,
				longTermMinorChangeNo: consultClient?.longTermMinorChangeNo,
				birthDt: consultClient?.recipientBirthDt,
			}) as WorkExecuteLogNewRecipient,
		[consultClient],
	);

	const serviceTypeOption =
		commonCodes?.CMN006?.filter((item) => item.data?.etcDesc1 === 'Y') || [];

	const validateInspectionForm = () => {
		if (!form.getValues('workExecuteLogDivCd')?.length) {
			return {
				message: '기본정보 - 구분을 입력해주세요.',
				hash: '#1',
			};
		}

		if (!form.getValues('workExecuteLogDivCd')?.length) {
			return {
				message: '기본정보 - 구분을 입력해주세요.',
				hash: '#1',
			};
		}

		if (!form.getValues('workExecuteLogStateCd')) {
			return {
				message: '방문정보 - 상태를 입력해주세요.',
				hash: '#2',
			};
		}

		if (!form.getValues('visitDt')) {
			return {
				message: '방문정보 - 방문(상담) 일시 - 날짜를 입력해주세요.',
				hash: '#2',
			};
		}

		if (form.formState.errors?.visitTime?.message) {
			return {
				message: '방문정보 - 방문(상담) 시간을 확인해주세요',
				hash: '#2',
			};
		}

		if (!form.getValues('visitTime')?.startTime) {
			return {
				message: '방문 정보 - 방문(상담) 시작 시간을 입력해주세요.',
				hash: '#2',
			};
		}

		if (form.getValues('visitTime')?.startTime?.length !== 5) {
			return {
				message: '방문 정보 - 방문(상담) 시작 시간을 확인해주세요.',
				hash: '#2',
			};
		}

		if (!form.getValues('visitTime')?.endTime) {
			return {
				message: '방문 정보 - 방문(상담) 종료 시간을 입력해주세요.',
				hash: '#2',
			};
		}

		if (form.getValues('visitTime')?.endTime?.length !== 5) {
			return {
				message: '방문 정보 - 방문(상담) 종료 시간을 확인해주세요.',
				hash: '#2',
			};
		}

		if (!form.getValues('serviceTypeCd')?.length) {
			return {
				message: '방문정보 - 급여 종류를 선택해주세요.',
				hash: '#2',
			};
		}

		if (form.getValues('salaryOfferVisitYn')?.[0]?.value === true) {
			if (!form.getValues('serviceDt') || !form.getValues('serviceTime')) {
				return {
					message: '방문정보 - 요양보호사 방문 일정을 입력해주세요',
					hash: '#2',
				};
			}

			if (form.getValues('serviceDt')?.length !== 8) {
				return {
					message: '방문정보 - 요양보호사 방문 일정(날짜)을 입력해주세요',
					hash: '#2',
				};
			}

			if (form.getValues('serviceTime')?.startTime?.length !== 5) {
				return {
					message: '방문정보 - 요양보호사 방문 일정(시간)을 입력해주세요',
					hash: '#2',
				};
			}

			if (form.getValues('serviceTime')?.endTime?.length !== 5) {
				return {
					message: '방문정보 - 요양보호사 방문 일정(시간)을 입력해주세요',
					hash: '#2',
				};
			}
		}

		if (!form.getValues('visitor')) {
			return {
				message: '방문정보 - 방문자를 입력해주세요',
				hash: '#2',
			};
		}

		if (!form.getValues('facilityManager')) {
			return {
				message: '방문정보 - 시설장(관리책임자)을 입력해주세요',
				hash: '#2',
			};
		}

		if (form.getValues('salaryOfferVisitYn')?.[0]?.value === false) {
			if (!form.getValues('visitDenyDesc')) {
				return {
					message: '방문정보 - 방문불가 사유를 입력해주세요.',
					hash: '#2',
				};
			}
		}

		if (form.getValues('workExecuteLogDivCd')?.[0]?.value === WorkExecuteLogCode.프로그램관리자) {
			if (!form.getValues('recogStimulationNeedDesc')) {
				return {
					message: '인지활동 프로그램 제공 계획 - 인지자극 필요내용을 입력해주세요.',
					hash: '#3',
				};
			}

			if (!form.getValues('recogStimulationProvisionMethodDesc')) {
				return {
					message: '인지활동 프로그램 제공 계획 - 인지자극 제공방법을 입력해주세요.',
					hash: '#3',
				};
			}

			if (!form.getValues('physicalAbilityNeedDesc')) {
				return {
					message: '인지활동 프로그램 제공 계획 - 신체능력 잔존유지 필요내용을 입력해주세요.',
					hash: '#3',
				};
			}

			if (!form.getValues('physicalAbilityProvisionMethodDesc')) {
				return {
					message: '인지활동 프로그램 제공 계획 - 신체능력 잔존유지 제공방법을 입력해주세요.',
					hash: '#3',
				};
			}
		}

		if (!form.getValues('client')?.length) {
			return {
				message: '상담 - 상담자를 입력해주세요.',
				hash: '#4',
			};
		}

		if (type === 'inspection' && !form.getValues('consultDesc')) {
			return {
				message: '상담 - 상담 내용을 입력해주세요.',
				hash: '#4',
			};
		}

		if (!form.getValues('salaryrecogActpgOfferConfirmDesc')) {
			return {
				message: '급여 및 인지활동 프로그램 제공 확인 - 확인 내용을 입력해주세요.',
				hash: '#5',
			};
		}

		if (!form.getValues('salaryrecogActpgOfferActionDesc')) {
			return {
				message: '급여 및 인지활동 프로그램 제공 확인 - 조치 사항을 입력해주세요.',
				hash: '#5',
			};
		}

		if (!form.getValues('employee1')) {
			return {
				message: '급여 및 인지활동 프로그램 제공 확인 - 급여제공자1을 입력해주세요.',
				hash: '#5',
			};
		}

		if (
			form.getValues('serviceTypeCd')?.[0]?.value === ServiceTypeCd.방문목욕 &&
			!form.getValues('employee2')
		) {
			return {
				message: '급여 및 인지활동 프로그램 제공 확인 - 급여제공자2를 입력해주세요.',
				hash: '#5',
			};
		}

		if (
			form.getValues('serviceTypeCd')?.[0]?.value === ServiceTypeCd.방문목욕 &&
			form.getValues('employee1')?.value?.id === form.getValues('employee2')?.value?.id
		) {
			return {
				message:
					'급여 및 인지활동 프로그램 제공 확인 - 급여제공자1 과 급여제공자2가 같을 수 없습니다.',
				hash: '#5',
			};
		}

		if (!form.getValues('physicalConditionDesc')) {
			return {
				message: '욕구사정 및 급여제공계획 - 신체상태를 입력해주세요.',
				hash: '#6',
			};
		}

		if (!form.getValues('diseaseDesc')) {
			return {
				message: '욕구사정 및 급여제공계획 - 질병을 입력해주세요.',
				hash: '#6',
			};
		}

		if (!form.getValues('recogStateDesc')) {
			return {
				message: '욕구사정 및 급여제공계획 - 인지상태를 입력해주세요.',
				hash: '#6',
			};
		}

		if (!form.getValues('communicationDesc')) {
			return {
				message: '욕구사정 및 급여제공계획 - 의사소통를 입력해주세요.',
				hash: '#6',
			};
		}

		if (!form.getValues('nutritionalStateDesc')) {
			return {
				message: '욕구사정 및 급여제공계획 - 영양상태를 입력해주세요.',
				hash: '#6',
			};
		}

		if (!form.getValues('familyEnvironmentDesc')) {
			return {
				message: '욕구사정 및 급여제공계획 - 가족 및 환경을 입력해주세요.',
				hash: '#6',
			};
		}

		if (!form.getValues('totalOpinionDesc')) {
			return {
				message: '욕구사정 및 급여제공계획 - 종합 의견을 입력해주세요.',
				hash: '#6',
			};
		}

		if (!form.getValues('salaryGoalDesc')) {
			return {
				message: '욕구사정 및 급여제공계획 - 급여목표를 입력해주세요.',
				hash: '#6',
			};
		}

		if (!form.getValues('needSalaryDesc')) {
			return {
				message: '욕구사정 및 급여제공계획 - 필요 급여내용을 입력해주세요.',
				hash: '#6',
			};
		}

		if (!form.getValues('provisionMethodDesc')) {
			return {
				message: '욕구사정 및 급여제공계획 - 제공방법을 입력해주세요.',
				hash: '#6',
			};
		}

		return undefined;
	};

	const validateWriteForm = () => {
		if (!form.getValues('workExecuteLogDivCd')?.length) {
			return {
				message: '기본정보 - 구분을 입력해주세요.',
				hash: '#1',
			};
		}

		if (!form.getValues('workExecuteLogDivCd')?.length) {
			return {
				message: '기본정보 - 구분을 입력해주세요.',
				hash: '#1',
			};
		}

		if (!form.getValues('workExecuteLogStateCd')) {
			return {
				message: '방문정보 - 상태를 입력해주세요.',
				hash: '#2',
			};
		}

		if (!form.getValues('visitDt')) {
			return {
				message: '방문정보 - 방문(상담) 일시 - 날짜를 입력해주세요.',
				hash: '#2',
			};
		}

		if (form.formState.errors?.visitTime?.message) {
			return {
				message: '방문정보 - 방문(상담) 시간을 확인해주세요',
				hash: '#2',
			};
		}

		if (!form.getValues('visitTime')?.startTime) {
			return {
				message: '방문 정보 - 방문(상담) 시작 시간을 입력해주세요.',
				hash: '#2',
			};
		}

		if (form.getValues('visitTime')?.startTime?.length !== 5) {
			return {
				message: '방문 정보 - 방문(상담) 시작 시간을 확인해주세요.',
				hash: '#2',
			};
		}

		if (!form.getValues('visitTime')?.endTime) {
			return {
				message: '방문 정보 - 방문(상담) 종료 시간을 입력해주세요.',
				hash: '#2',
			};
		}

		if (form.getValues('visitTime')?.endTime?.length !== 5) {
			return {
				message: '방문 정보 - 방문(상담) 종료 시간을 확인해주세요.',
				hash: '#2',
			};
		}

		if (!form.getValues('serviceTypeCd')?.length) {
			return {
				message: '방문정보 - 급여 종류를 선택해주세요.',
				hash: '#2',
			};
		}

		if (!form.getValues('visitor')) {
			return {
				message: '방문정보 - 방문자를 입력해주세요',
				hash: '#2',
			};
		}

		if (!form.getValues('facilityManager')) {
			return {
				message: '방문정보 - 시설장(관리책임자)을 입력해주세요',
				hash: '#2',
			};
		}

		if (!form.getValues('visitPlaceNm')) {
			return {
				message: '방문정보 - 방문 장소를 입력해주세요',
				hash: '#2',
			};
		}

		if (!form.getValues('salaryOfferVisitYn')?.length) {
			return {
				message: '방문정보 - 급여제공 중 방문 여부 사유를 입력해주세요.',
				hash: '#2',
			};
		}

		if (!form.getValues('client')?.length) {
			return {
				message: '상담 - 상담자를 입력해주세요.',
				hash: '#4',
			};
		}

		if (!form.getValues('employee1')) {
			return {
				message: '급여 및 인지활동 프로그램 제공 확인 - 급여제공자1을 입력해주세요.',
				hash: '#5',
			};
		}

		if (
			form.getValues('serviceTypeCd')?.[0]?.value === ServiceTypeCd.방문목욕 &&
			!form.getValues('employee2')
		) {
			return {
				message: '급여 및 인지활동 프로그램 제공 확인 - 급여제공자2를 입력해주세요.',
				hash: '#5',
			};
		}

		if (
			form.getValues('serviceTypeCd')?.[0]?.value === ServiceTypeCd.방문목욕 &&
			form.getValues('employee1')?.value?.id === form.getValues('employee2')?.value?.id
		) {
			return {
				message:
					'급여 및 인지활동 프로그램 제공 확인 - 급여제공자1 과 급여제공자2가 같을 수 없습니다.',
				hash: '#5',
			};
		}

		return undefined;
	};

	const validate = (config?: { ignoreMessage?: boolean }) => {
		const initHash = (target: string) => {
			navigate(target);
			setTimeout(() => {
				navigate(location.pathname, {
					replace: true,
				});
			});
		};

		const validateForm = type === 'inspection' ? validateInspectionForm : validateWriteForm;

		const result = validateForm();

		if (!result) return true;
		if (!config?.ignoreMessage) {
			Toast.error(result.message);
			initHash(result.hash);
		}

		return false;
	};

	const goBackPage = () => {
		if (location.pathname.includes('recipient')) {
			navigate(
				`${RouterPath.recipient().tab.key}/${params.id}/${
					RouterPath.recipient().message.uniqueKey
				}`,
				{
					replace: true,
				},
			);
		} else if (location.pathname.includes(RouterPath.sendHistoryTab().esign.key)) {
			navigate(`${RouterPath.sendHistoryTab().esign.key}`, {
				replace: true,
			});
		} else if (location.pathname.includes('esign/')) {
			navigate(location.pathname, {
				replace: true,
			});
		}
	};

	const isSameDate = (startTime: string, endTime: string) => {
		// 시간을 숫자로 변환
		const parseTime = (time: string) => {
			const hours = parseInt(time.slice(0, -2), 10); // 앞 두자리 (시간)
			const minutes = parseInt(time.slice(-2), 10); // 뒤 두자리 (분)
			return hours * 60 + minutes; // 분 단위로 변환
		};

		const start = parseTime(startTime);
		const end = parseTime(endTime);

		return start <= end;
	};

	const updateWorkExecuteInspectionLog = async () => {
		if (updateWorkExecuteInspectionLogMutation.isLoading) return;

		if (!validate() || !consultClient?.centerId) return;

		const param: UpdateRecipientWorkExecuteLogRequest = {
			workExecuteLogDivCd: form.getValues('workExecuteLogDivCd')?.[0]?.value,
			workExecuteLogStateCd: form.getValues('workExecuteLogStateCd')?.value,
			centerId: consultClient.centerId,
			recipientId: consultClient.recipientId,
			recipientNm: consultClient.recipientNm,
			recipientBirthDt: consultClient.recipientBirthDt,
			recipientGenderCd: consultClient.recipientGenderCd,
			longTermNo: consultClient.longTermNo || '',
			longTermMajorChangeNo: consultClient.longTermMajorChangeNo || '',
			longTermMinorChangeNo: consultClient.longTermMinorChangeNo || '',
			longTermGradeCd: consultClient.longTermGradeCd || '',
			visitorId: form.getValues('visitor')?.value,
			facilityManagerNm: form.getValues('facilityManager')?.label || '',
			facilityManagerId: form.getValues('facilityManager')?.value,
			visitDt:
				type === 'inspection'
					? dayjs(form.getValues('visitDt')).format('YYYYMMDD')
					: (form.getValues('visitDt') as string),
			startTime: form.getValues('visitTime.startTime')?.replace(':', ''),
			endTime: form.getValues('visitTime.endTime')?.replace(':', ''),
			serviceStartDt: form.getValues('serviceDt') || undefined,
			serviceStartTime: form.getValues('serviceTime.startTime')?.replace(':', '') || undefined,
			serviceEndDt:
				form.getValues('serviceTime.startTime') && form.getValues('serviceTime.endTime')
					? isSameDate(
							form.getValues('serviceTime.startTime'),
							form.getValues('serviceTime.endTime'),
						)
						? form.getValues('serviceDt')
						: dayjs(form.getValues('serviceDt')).add(1, 'day').format('YYYYMMDD')
					: form.getValues('serviceDt') || undefined,
			serviceEndTime: form.getValues('serviceTime.endTime')?.replace(':', '') || undefined,
			visitPlaceNm: form.getValues('visitPlaceNm') || '',
			salaryOfferVisitYn: form.getValues('salaryOfferVisitYn')?.[0]?.value,
			serviceTypeCd: form.getValues('serviceTypeCd')?.[0]?.value,
			clientDivCd: form.getValues('client')?.[0]?.data?.clientDivCd,
			clientId: form.getValues('client')?.[0]?.data?.clientId,
			clientPhoneNo: form.getValues('client')?.[0]?.data?.telNum,
		};

		if (form.getValues('employee1')?.value?.id) {
			param.employeeId1 = form.getValues('employee1')?.value?.id;
			if (
				form.getValues('serviceTypeCd')?.[0]?.value === ServiceTypeCd.방문목욕 &&
				form.getValues('employee2')?.value?.id
			) {
				param.employeeId2 = form.getValues('employee2')?.value?.id;
			}
		}

		if (consultClient?.workExecuteLogId) {
			param.workExecuteLogId = consultClient.workExecuteLogId;
		}

		if (form.getValues('visitDenyDesc')) {
			param.visitDenyDesc = form.getValues('visitDenyDesc');
		}

		if (form.getValues('client')?.[0] && form.getValues('client')?.[0]?.data?.mainGuardianYn) {
			param.clientMainGuardianYn = true;
		}

		if (form.getValues('consultDesc')) {
			param.consultDesc = form.getValues('consultDesc');
		}

		if (form.getValues('workExecuteLogDivCd')?.[0].value === WorkExecuteLogCode.프로그램관리자) {
			if (form.getValues('recogStimulationNeedDesc')) {
				param.recogStimulationNeedDesc = form.getValues('recogStimulationNeedDesc');
			}
			if (form.getValues('recogStimulationProvisionMethodDesc')) {
				param.recogStimulationProvisionMethodDesc = form.getValues(
					'recogStimulationProvisionMethodDesc',
				);
			}
			if (form.getValues('physicalAbilityNeedDesc')) {
				param.physicalAbilityNeedDesc = form.getValues('physicalAbilityNeedDesc');
			}
			if (form.getValues('physicalAbilityProvisionMethodDesc')) {
				param.physicalAbilityProvisionMethodDesc = form.getValues(
					'physicalAbilityProvisionMethodDesc',
				);
			}
		}
		if (form.getValues('salaryrecogActpgOfferConfirmDesc')) {
			param.salaryrecogActpgOfferConfirmDesc = form.getValues('salaryrecogActpgOfferConfirmDesc');
		}
		if (form.getValues('salaryrecogActpgOfferActionDesc')) {
			param.salaryrecogActpgOfferActionDesc = form.getValues('salaryrecogActpgOfferActionDesc');
		}

		if (form.getValues('physicalConditionDesc')) {
			param.physicalConditionDesc = form.getValues('physicalConditionDesc');
		}

		if (form.getValues('diseaseDesc')) {
			param.diseaseDesc = form.getValues('diseaseDesc');
		}

		if (form.getValues('recogStateDesc')) {
			param.recogStateDesc = form.getValues('recogStateDesc');
		}

		if (form.getValues('communicationDesc')) {
			param.communicationDesc = form.getValues('communicationDesc');
		}

		if (form.getValues('nutritionalStateDesc')) {
			param.nutritionalStateDesc = form.getValues('nutritionalStateDesc');
		}

		if (form.getValues('familyEnvironmentDesc')) {
			param.familyEnvironmentDesc = form.getValues('familyEnvironmentDesc');
		}

		if (form.getValues('totalOpinionDesc')) {
			param.totalOpinionDesc = form.getValues('totalOpinionDesc');
		}

		if (form.getValues('salaryGoalDesc')) {
			param.salaryGoalDesc = form.getValues('salaryGoalDesc');
		}

		if (form.getValues('needSalaryDesc')) {
			param.needSalaryDesc = form.getValues('needSalaryDesc');
		}

		if (form.getValues('provisionMethodDesc')) {
			param.provisionMethodDesc = form.getValues('provisionMethodDesc');
		}

		const edocNo = (edocData as EDocClientOpenData)?.edocPreviewResponse.data.edocSend.edocNo;

		const result = await updateWorkExecuteInspectionLogMutation.mutateAsync({
			centerId: consultClient?.centerId,
			signatoryId: (edocData as EDocClientOpenData)?.signatoryId,
			edocNo,
			signDivCd: type === 'wirte' ? 'CMN169.05' : 'CMN169.20',
			sendUuid: (edocData as EDocClientOpenData)?.sendUuid,
			birthDt: type === 'wirte' ? birthDt : '',
			confirmYn: true,
			workExecuteLogRequest: {
				...param,
				writeCompleteYn: true,
				visitDenyDesc: param.visitDenyDesc || '',
				docNo: edocNo,
				edocNo,
			},
		});
		if (result?.code === ResponseCode.SUCCESS) {
			setIsInitForm(false);
			form.reset();
			Toast.success('업무수행일지를 저장했습니다.');
			goBackPage();
			updateEDocConfirmResultHandler(result as BaseResponse);
		}
	};

	useEffect(() => {
		if (form.watch('salaryOfferVisitYn')?.[0]?.value === true) {
			form.setValue('visitDenyDesc', '');
		}
	}, [form.watch('salaryOfferVisitYn')]);

	useEffect(() => {
		if (
			consultClient &&
			!isInitForm &&
			commonCodes &&
			centerChiefOptions?.length &&
			serviceTypeOption?.length &&
			employeesOptions?.length
		) {
			const defaultForm: WorkExecuteLogFormType = {
				physicalConditionDesc: consultClient?.physicalConditionDesc || '',
				client: [],
				diseaseDesc: consultClient?.diseaseDesc || '',
				recogStateDesc: consultClient?.recogStateDesc || '',
				communicationDesc: consultClient?.communicationDesc || '',
				nutritionalStateDesc: consultClient?.nutritionalStateDesc || '',
				familyEnvironmentDesc: consultClient?.familyEnvironmentDesc || '',
				totalOpinionDesc: consultClient?.totalOpinionDesc || '',
				salaryGoalDesc: consultClient?.salaryGoalDesc || '',
				needSalaryDesc: consultClient?.needSalaryDesc || '',
				provisionMethodDesc: consultClient?.provisionMethodDesc || '',
			};
			if (consultClient) {
				const workExecuteLogDivCd = commonCodes.CMN183.find(
					(item) => item.value === consultClient?.workExecuteLogDivCd,
				);
				const workExecuteLogStateCd = commonCodes.CMN184.find(
					(item) => item.value === consultClient?.workExecuteLogStateCd,
				);
				const visitDt =
					type === 'inspection' ? dayjs(consultClient.visitDt).toDate() : consultClient.visitDt;
				const time = {
					startTime: displayPadTime(consultClient.startTime),
					endTime: displayPadTime(consultClient.endTime),
				};
				const facilityManager = centerChiefOptions.find(
					(item) => item.value === consultClient?.facilityManagerId,
				);
				const employeeId = [consultClient?.employeeId1, consultClient?.employeeId2]
					.filter(Boolean)
					.join(',');
				const employee1 = employeesOptions?.find(
					(item) => item.value?.id === consultClient?.employeeId1,
				);
				const employee2 = employeesOptions?.find(
					(item) => item.value?.id === consultClient?.employeeId2,
				);

				const serviceTypeCd = serviceTypeOption?.find(
					(item) => item.value === consultClient?.serviceTypeCd,
				);

				const visitSchedule =
					consultClient.serviceStartDt &&
					consultClient.serviceTypeCd &&
					consultClient.serviceStartTime &&
					consultClient.serviceEndTime
						? {
								label: '',
								value:
									employeeId +
									consultClient.serviceStartDt +
									consultClient.serviceTypeCd +
									consultClient.serviceStartTime +
									consultClient.serviceEndTime,
								data: {
									employeeId,
									serviceStartDt: consultClient.serviceStartDt,
									serviceTypeCd: consultClient.serviceTypeCd,
									serviceStartTime: consultClient.serviceStartTime,
									serviceEndTime: consultClient.serviceEndTime,
								},
							}
						: undefined;

				const client = [
					{
						label: '',
						value: consultClient.clientId,
						data: {
							clientDivCd: consultClient.clientDivCd,
							clientId: consultClient.clientId,
							telNum: consultClient.clientPhoneNo,
						},
					},
				];

				const salaryOfferVisitYn =
					consultClient?.salaryOfferVisitYn !== undefined
						? [
								{
									label: '',
									value: consultClient.salaryOfferVisitYn,
								},
							]
						: undefined;

				const serviceTime = {
					startTime: consultClient.serviceStartTime
						? displayPadTime(consultClient.serviceStartTime)
						: '',
					endTime: consultClient.serviceEndTime ? displayPadTime(consultClient.serviceEndTime) : '',
				};

				defaultForm.workExecuteLogDivCd = workExecuteLogDivCd ? [workExecuteLogDivCd] : [];
				defaultForm.workExecuteLogStateCd = workExecuteLogStateCd;
				defaultForm.visitDt = visitDt;
				defaultForm.visitor =
					centerManagerOption.find((item) => item.value === consultClient?.visitorId) || undefined;
				defaultForm.visitTime = time;
				defaultForm.facilityManager = facilityManager;
				defaultForm.visitSchedule = visitSchedule ? [visitSchedule] : [];
				defaultForm.visitPlaceNm = consultClient?.visitPlaceNm || '';
				defaultForm.salaryOfferVisitYn = salaryOfferVisitYn;
				defaultForm.visitDenyDesc = consultClient.visitDenyDesc;
				defaultForm.client = consultClient.clientId ? client : [];
				defaultForm.consultDesc = consultClient.consultDesc;

				defaultForm.salaryrecogActpgOfferConfirmDesc =
					consultClient.salaryrecogActpgOfferConfirmDesc || '';
				defaultForm.salaryrecogActpgOfferActionDesc =
					consultClient.salaryrecogActpgOfferActionDesc || '';

				defaultForm.physicalConditionDesc = consultClient.physicalConditionDesc;
				defaultForm.diseaseDesc = consultClient.diseaseDesc;
				defaultForm.recogStateDesc = consultClient.recogStateDesc;
				defaultForm.communicationDesc = consultClient.communicationDesc;
				defaultForm.nutritionalStateDesc = consultClient.nutritionalStateDesc;
				defaultForm.familyEnvironmentDesc = consultClient.familyEnvironmentDesc;
				defaultForm.totalOpinionDesc = consultClient.totalOpinionDesc;
				defaultForm.salaryGoalDesc = consultClient.salaryGoalDesc;
				defaultForm.needSalaryDesc = consultClient.needSalaryDesc;
				defaultForm.provisionMethodDesc = consultClient.provisionMethodDesc;
				defaultForm.recogStimulationNeedDesc = consultClient.recogStimulationNeedDesc;
				defaultForm.recogStimulationProvisionMethodDesc =
					consultClient.recogStimulationProvisionMethodDesc;
				defaultForm.physicalAbilityNeedDesc = consultClient.physicalAbilityNeedDesc;
				defaultForm.physicalAbilityProvisionMethodDesc =
					consultClient.physicalAbilityProvisionMethodDesc;
				defaultForm.employee1 = employee1;
				defaultForm.employee2 = employee2;
				defaultForm.serviceTypeCd = serviceTypeCd ? [serviceTypeCd] : undefined;
				defaultForm.serviceDt = consultClient.serviceStartDt || '';
				defaultForm.serviceTime = serviceTime;
			}

			form.reset(defaultForm);
			setIsInitForm(true);
		}
	}, [commonCodes, isInitForm, advisorOptions, centerChiefOptions, employeesOptions]);

	const value = useMemo(
		() => ({
			form,
			commonCodes: {
				...commonCodes,
				centerManagerOption,
				centerChiefOptions,
				serviceTypeOption,
				employeesOptions,
			},
			updateWorkExecuteInspectionLog,
			validate,
			isLoaded,
			setEdocData,
			setType,
			setBirthDt,
			setUpdateEDocConfirmResultHandler,
			recipient,
			advisorOptions: advisorOptions || undefined,
		}),
		[
			form,
			commonCodes,
			centerManagerOption,
			updateWorkExecuteInspectionLog,
			isLoaded,
			setEdocData,
			setType,
			setBirthDt,
			setUpdateEDocConfirmResultHandler,
			recipient,
			advisorOptions,
			serviceTypeOption,
		],
	);

	return (
		<WorkExecuteInspectionLogContext.Provider value={value}>
			{children}
		</WorkExecuteInspectionLogContext.Provider>
	);
}
export default React.memo(WorkExecuteInspectionLogProvider);
