import React, { useEffect, useMemo, useState } from 'react';

import Assets from 'assets';
import CRBanner from 'components/base/CRBanner';
import CRButton from 'components/base/CRButton';
import CRIcon from 'components/base/CRIcon';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInputMessage from 'components/base/CRInputMessage';
import { CRText } from 'components/base/CRText';
import { CheckOption } from 'components/base/Selections/type';
import { regex } from 'lib';
import {
	useCenterEmployees,
	useCheckRealName,
	useEmployeePageDuplicateCheck,
	useMyAccountInfo,
} from 'lib/hook/react-query';
import { CenterMemberSearchDTO } from 'types/api/common';

import { EmployeeCheckDuplicateDTO } from '../../../../types/api';
import * as S from './styles';

interface Props {
	data: {
		name: string;
		rsdn: string;
	};
	onChange?: (data: { name: string; rsdn: string }) => void;
	disabled?: boolean;
	onConfirm?: (data: { name: string; rsdn: string; result: EmployeeCheckDuplicateDTO }) => void;
}

function EmployeeDuplicationCheckForm({
	data,
	disabled,
	onChange,
	onConfirm,
}: Props): React.ReactElement {
	const { data: myAccountInfo } = useMyAccountInfo();
	const [isDuplicateValid, setIsDuplicateValid] = useState(false);
	const [isRealName, setIsRealName] = useState(false);
	const [selectedEmployee, setSelectedEmployee] = useState<CheckOption<CenterMemberSearchDTO>>();
	const [banner, setBanner] = useState(
		<CRBanner type='info' title='중복 조회를 진행해야 수급자를 생성할 수 있습니다.' />,
	);
	const [nameError, setNameError] = useState<string>();
	const [rsdnError, setRsdnError] = useState<string>();

	const { data: members } = useCenterEmployees({
		name: '',
	});

	const checkDuplicate = useEmployeePageDuplicateCheck();
	const checkRealNameMutation = useCheckRealName();

	const memberOptions = useMemo(
		() =>
			(members ?? [])?.map(
				(item) =>
					({
						label: item?.korMemberNm,
						value: {
							name: item?.korMemberNm,
							rsdnNo: item?.rsdnNo ? item.rsdnNo.slice(0, 6) : '',
							centerNm: item?.centerAliasNm ? `${item.centerAliasNm}센터` : '',
						},
						data: item,
					}) as CheckOption<CenterMemberSearchDTO>,
			),
		[members],
	);

	const onClickDuplicate = async () => {
		if (!myAccountInfo) return;
		if (!data.name) return;
		if (!data.rsdn) return;
		if (data?.rsdn?.length !== 13) {
			setBanner(
				<CRBanner
					type='error'
					title='주민등록번호 13자리를 모두 입력해야 중복 조회가 가능합니다.'
				/>,
			);
			return;
		}

		const regex = /[ㄱ-ㅎ]/g;

		const hasForbiddenConsonant = regex.test(data.name);

		if (hasForbiddenConsonant) {
			setBanner(
				<CRBanner type='error' title='직원 이름을 모두 입력해야 중복 조회가 가능합니다.' />,
			);
			return;
		}

		const result = await checkDuplicate.mutateAsync({
			name: data.name,
			rsdnNo: data.rsdn,
			centerId: myAccountInfo.centerId,
		});

		if (!result?.data) {
			setBanner(<CRBanner type='error' title={result?.message ?? '중복 조회에 실패하였습니다.'} />);
			return;
		}

		if (result?.data?.recipientDuplicated) {
			setBanner(
				<CRBanner type='error' title='동일한 주민번호로 이미 수급자에 등록되어있습니다.' />,
			);
			setIsDuplicateValid(false);
			setIsRealName(false);
			return;
		}

		if (!result?.data?.isCenterDuplicated && result?.data?.isEmployeeDuplicated) {
			let centerName = result.data.centers.length > 0 ? result.data.centers[0] : '타';
			if (result?.data.centers.length > 1) {
				centerName += ` 외 ${result.data.centers.length - 1}개 센터`;
			}
			setBanner(
				<CRBanner
					type='warning'
					title={`${centerName}에 이미 존재하는 직원입니다.(센터 추가 등록)`}
				/>,
			);
			setIsDuplicateValid(true);
			setIsRealName(true);

			return;
		}

		if (result?.data?.isCenterDuplicated && result.data.isEmployeeDuplicated) {
			setBanner(<CRBanner type='success' title='센터내에 존재하는 직원입니다.' />);
			setIsDuplicateValid(true);
			setIsRealName(true);
		} else {
			setBanner(<CRBanner type='success' title='새로운 직원입니다 실명 확인을 진행해 주십시오.' />);
			setIsDuplicateValid(true);
			setIsRealName(false);
		}
	};

	const handleClickCheckRealName = async () => {
		const foreignerYn = ['5', '6', '7', '8'].includes(data.rsdn?.[6]);
		const result = await checkRealNameMutation.mutateAsync({
			name: data.name,
			rsdnNo: data.rsdn,
			foreignerYn,
		});
		if (result?.data?.result) {
			setBanner(
				<CRBanner type='success' title='실명 확인이 완료되어 직원을 생성할 수 있습니다.' />,
			);
		} else {
			setBanner(
				<>
					<CRBanner
						type='warning'
						title='실명 확인에 실패했습니다. 이름과 주민등록번호를 확인 후 진행해 주십시오.'
					/>
					<S.HelperTextContainer>
						<S.HelperTextTitle>
							<CRIcon
								style={{
									width: '1.6rem',
									height: '1.6rem',
								}}
								src={Assets.icon.help}
							/>
							<CRText
								color='gray50'
								typography='labelB'
								text='왜 실명확인에 실패해도 추가/등록이 가능한가요?'
							/>
						</S.HelperTextTitle>
						<CRText
							color='gray50'
							typography='label'
							text={`실명확인 시스템은 모든 국민의 정보를 확인할 수 없습니다. 
							정확한 정보를 기입해도 실패가 될 수 있습니다.
							이름과 주민등록번호가 정확하다면 업무를 진행하시면 됩니다.
							*시군구(W4C)에서 한 번 더 실명확인하는 것을 추천합니다.
							`}
						/>
					</S.HelperTextContainer>
				</>,
			);
		}
		setIsRealName(true);
		setIsDuplicateValid(true);
	};

	const handleConfirm = () => {
		if (!checkDuplicate?.data?.data) return;
		onConfirm?.({ ...data, result: checkDuplicate.data.data });
	};

	const isNewUser = useMemo(
		() =>
			checkDuplicate?.data?.data?.isCenterDuplicated === false &&
			checkDuplicate?.data?.data?.isEmployeeDuplicated === false &&
			checkDuplicate?.data?.data?.recipientDuplicated === false,
		[checkDuplicate],
	);

	useEffect(() => {
		setIsDuplicateValid(false);
		setIsRealName(false);
	}, []);

	useEffect(() => {
		if (memberOptions?.length) {
			const matchedEmployee = memberOptions.find(
				(item) => item.data?.rsdnNo === data.rsdn && item.data?.korMemberNm === data.name,
			);
			setSelectedEmployee(
				matchedEmployee ?? {
					label: data.name ?? '',
					value: '',
				},
			);
		}
	}, [data, memberOptions]);

	useEffect(() => {
		let errorMessage = '';
		if (data.name === undefined) return;
		if (data.name === '') {
			errorMessage = '필수 정보입니다.';
		} else if (data.name?.length < 2) {
			errorMessage = '2글자 이상 입력해야 합니다.';
		} else if (data.name?.length > 20) {
			errorMessage = '20자까지 입력할 수 있습니다.';
		} else if (!regex.name?.test(data.name)) {
			// 정규식 통과하지 못하면 오류
			errorMessage = '한글이나 영문만 입력할 수 있습니다.';
		} else {
			errorMessage = ''; // 조건을 모두 통과하면 에러 메시지 없음
		}
		if (errorMessage) setNameError(errorMessage);
	}, [data.name]);

	useEffect(() => {
		let errorMessage = '';
		if (data.rsdn === undefined) return;
		if (data.rsdn === '') {
			errorMessage = '필수 정보입니다.';
		} else if (data.rsdn.length < 13) {
			errorMessage = '주민등록번호를 정확히 입력해야 합니다.';
		} else if (!regex.number?.test(data.rsdn)) {
			errorMessage = '숫자만 입력할 수 있습니다.';
		} else {
			errorMessage = ''; // 조건을 모두 통과하면 에러 메시지 없음
		}
		if (errorMessage) setRsdnError(errorMessage);
	}, [data.rsdn]);

	const isValid =
		isDuplicateValid &&
		isRealName &&
		!checkDuplicate?.data?.data?.recipientDuplicated &&
		(isNewUser ||
			checkDuplicate?.data?.data?.isCenterDuplicated ||
			checkDuplicate?.data?.data?.isEmployeeDuplicated);

	return (
		<>
			<CRInputLabel
				type='left-sub'
				label='이름'
				showOverflow
				message={
					nameError && <CRInputMessage type='error'>{(nameError as string) ?? ''}</CRInputMessage>
				}>
				<CRInput.SearchSelector
					disabled={disabled}
					currentValue={selectedEmployee}
					items={memberOptions}
					onChange={(value) => {
						const param = { ...data };
						if (value?.data?.rsdnNo) {
							setSelectedEmployee(value);
							param.rsdn = value.data.rsdnNo;
							setNameError(undefined);
							setRsdnError(undefined);
						} else {
							param.rsdn = '';
							if (value?.label === value?.value) {
								setNameError(undefined);
							}
						}
						setIsDuplicateValid(false);
						setIsRealName(false);
						onChange?.({ ...param, name: value.label });
					}}
					visibleKey={['centerNm', 'rsdnNo']}
					placeholder='이름 입력'
					allowSelf
				/>
			</CRInputLabel>
			<CRInputLabel
				type='left-sub'
				label='주민번호'
				message={rsdnError && <CRInputMessage type='error'>{rsdnError ?? ''}</CRInputMessage>}>
				<CRInput.Default
					placeholder='주민번호 입력'
					onChange={(rsdn) => {
						setRsdnError(undefined);
						setIsDuplicateValid(false);
						setIsRealName(false);
						onChange?.({ ...data, rsdn });
					}}
					value={data.rsdn}
					disabled={disabled}
					type='number'
					maxLength={13}
					suffix={
						<S.ValidateButtonContainer>
							<CRButton.Default
								onClick={onClickDuplicate}
								size='xSmall'
								palette='gray'
								type='outlined'
								buttonType='button'
								disabled={disabled || !!rsdnError || !(data.name && data.name)}>
								중복 조회
							</CRButton.Default>
							<CRButton.Default
								size='xSmall'
								disabled={!isDuplicateValid || !isNewUser || isRealName}
								palette='gray'
								type='filled'
								onClick={handleClickCheckRealName}
								buttonType='button'>
								실명 확인
							</CRButton.Default>
						</S.ValidateButtonContainer>
					}
				/>
			</CRInputLabel>
			{!disabled && banner}
			{isValid && (
				<S.ButtonContainer>
					<CRButton.Default
						onClick={handleConfirm}
						type='filled'
						buttonType='button'
						disabled={disabled || !isValid}>
						확정
					</CRButton.Default>
				</S.ButtonContainer>
			)}
		</>
	);
}

export default React.memo(EmployeeDuplicationCheckForm);
