import React from 'react';

import { Filter } from 'types/view/filter';

export const FILTER_GROUP: { [key in string]: Filter<{ label: string; value: any }> } = {
	recipientState: {
		key: 'recipientStateFilter',
		options: [
			{ label: '수급중', value: 'CMN058.10' },
			{ label: '대기중', value: 'CMN058.20' },
			{ label: '퇴소', value: 'CMN058.30' },
		],
		type: 'multi',
		placeholder: '상태',
	},
	holdState: {
		key: 'holdStateFilter',
		options: [{ label: '보류', value: '보류' }],
		type: 'toggle',
	},
	hasSchedule: {
		key: 'hasScheduleFilter',
		options: [{ label: '일정 없음', value: '일정 없음' }],
		type: 'toggle',
	},
};

export type RecipientInfoType = {
	id: string;
	name: string;
	birth: string;
	status: string;
	schedule: number;
	holdState: boolean;
};

export const recipientScheduleInfo = (info: {
	state: React.ReactNode;
	recipientName: string;
	employeeNm: string;
	visitDt: string;
	visitTime: string;
	serviceTypeNm: string;
	serviceTime: string;
	visitorNm: string;
}) => [
	[
		{
			label: '상태',
			value: info.state,
			labelStyle: { width: '12rem' },
		},
	],
	[
		{
			label: '수급자',
			value: info.recipientName,
			labelStyle: { width: '12rem' },
		},
	],
	[
		{
			label: '직원',
			value: info.employeeNm,
			labelStyle: { width: '12rem' },
		},
	],
	[
		{
			label: '방문일',
			value: info.visitDt,
		},
	],
	[
		{
			label: '방문 시간',
			value: info.visitTime,
		},
	],
	[
		{
			label: '급여 종류',
			value: info.serviceTypeNm,
		},
	],
	[
		{
			label: '급여 시간',
			value: info.serviceTime,
		},
	],
	[
		{
			label: '방문자',
			value: info.visitorNm,
		},
	],
];
