import styled from 'styled-components';

import CRButton from 'components/base/CRButton';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	background: ${(props) => props.theme.colors.gray100};
	overflow: overlay;
`;

export const HoldStateContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const DetailButton = styled(CRButton.Default)<{ $holdState: boolean }>`
	display: ${(props) => (props.$holdState ? `var(--child-display, none)` : `none`)};
`;

export const DayContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.2rem;
	height: 100%;
	overflow: auto;
`;
