import { UseFormReturn } from 'react-hook-form';
import { NavigateFunction } from 'react-router-dom';

import RouterPath from 'common/router';
import { LocalNavigationSection } from 'types/view/task';

import { ContractChangeEmployeeDetailDTO } from '../../../types/dto';

export function generateContractScrollSpy(
	navigate: NavigateFunction,
	form: UseFormReturn<ContractChangeEmployeeDetailDTO>,
	onChangeRouter?: () => void,
) {
	const contractTaskScrollSpyMock: LocalNavigationSection[] = [
		{
			label: RouterPath.myTaskOperate().recipientBasic.label,
			onClick: () => {
				navigate(`${RouterPath.myTaskOperate().recipientBasic.key}`);
			},
			subSections: [
				{
					label: '',
					items: [
						{
							label: '기본 정보',
							key: `${RouterPath.myTaskOperate().recipientBasic.key}#1`,
							onClick: () => navigate(`${RouterPath.myTaskOperate().recipientBasic.key}#1`),
							isDone: !!(
								form.watch('recipient.socialWorkerCenterId') &&
								form.watch('recipient.socialWorkerId')
							),
						},
						{
							label: '장기요양인정 정보',
							key: `${RouterPath.myTaskOperate().recipientBasic.key}#2`,
							onClick: () => navigate(`${RouterPath.myTaskOperate().recipientBasic.key}#2`),
							isDone: !!(
								form.watch('recipient.longTerm.longTermNo') &&
								form.watch('recipient.longTerm.longTermStartDate') &&
								form.watch('recipient.longTerm.longTermEndDate') &&
								form.watch('recipient.longTerm.burdenRateCd') &&
								form.watch('recipient.longTerm.longTermGradeCd') &&
								form.watch('recipient.longTerm.longTermServices')?.length &&
								form.watch('recipient.longTerm.longTermCertiFile') &&
								form.watch('recipient.longTerm.longTermUsePlanFile')
							),
						},
					],
				},
			],
		},
		{
			label: RouterPath.myTaskOperate().contractService.label,
			onClick: () => navigate(`${RouterPath.myTaskOperate().contractService.key}`),
			subSections: [
				{
					label: '',
					items: [
						{
							key: `${RouterPath.myTaskOperate().contractService.key}#1`,
							label: '이용 정보',
							onClick: () => navigate(`${RouterPath.myTaskOperate().contractService.key}#1`),
							isDone: !!(
								form.watch('service')?.use?.serviceStartDate &&
								form.watch('service')?.use?.serviceEndDate &&
								form.watch('service')?.use?.serviceTimes?.length &&
								form.watch('service')?.use?.monthPerWorkHourCnt
							),
						},
						{
							key: `${RouterPath.myTaskOperate().contractService.key}#2`,
							onClick: () => navigate(`${RouterPath.myTaskOperate().contractService.key}#2`),
							label: '계약 정보',
							isDone: !!(
								form.watch('service')?.associates?.recipientContractAssignDate &&
								form
									.watch('service')
									.associates?.contractEmployees?.every(
										(employee) => employee?.employeeId && employee.employeeContractAssignDate,
									)
							),
						},
					],
				},
			],
		},
		{
			label: RouterPath.myTaskOperate().manager.label,
			onClick: () => navigate(`${RouterPath.myTaskOperate().manager.key}`),
			subSections: [
				{
					label: '',
					items: [
						{
							key: `${RouterPath.myTaskOperate().manager.key}#1`,
							label: '기본 정보',
							onClick: () => navigate(`${RouterPath.myTaskOperate().manager.key}#1`),
							isDone: !!(
								form.watch('employees')?.length &&
								form
									.watch('employees')
									?.every(
										(item) =>
											item.base.employeeNm &&
											item.base.employeeRsdnNo &&
											item.base.employeeBirthDt &&
											item.base.tagMobilePhoneNo &&
											(!item.base.twoPhoneYn || item.base.subMobilePhoneNo) &&
											item.base.employeeBasAddr &&
											item.base.dementiaEduCompleteYn !== undefined &&
											item.base.disabilityGradeCd,
									)
							),
						},
						{
							key: `${RouterPath.myTaskOperate().manager.key}#2`,
							label: '자격증 및 교육 정보',
							onClick: () => navigate(`${RouterPath.myTaskOperate().manager.key}#2`),
							isDone: !!form.watch('employees')?.length,
						},
						{
							key: `${RouterPath.myTaskOperate().manager.key}#3`,
							label: '타근무 정보',
							onClick: () => navigate(`${RouterPath.myTaskOperate().manager.key}#3`),
							isDone: !!(
								form.watch('employees')?.length &&
								form
									.watch('employees')
									?.every(
										(item) =>
											!item.otherCorpWorks?.length ||
											item.otherCorpWorks?.every(
												(otherCorpWork) =>
													otherCorpWork.otherCorpNm &&
													otherCorpWork.personalBusinessOwnerYn !== undefined &&
													otherCorpWork.otherCorpPhoneNo &&
													otherCorpWork.otherCorpWorkStartEndTimes?.length,
											),
									)
							),
						},
						{
							key: `${RouterPath.myTaskOperate().manager.key}#4`,
							label: '임금 정보',
							onClick: () => navigate(`${RouterPath.myTaskOperate().manager.key}#4`),
							isDone: !!(
								form.watch('employees')?.length &&
								form
									.watch('employees')
									?.every(
										(item) =>
											(item.salary?.salaryPayTypeCd &&
												item.salary?.salaryPayTypeCd !== 'CMN133.10') ||
											(item.salary?.salaryAccountDepositorNm &&
												item.salary.salaryAccountNo &&
												item.salary.salaryAccountBankCd &&
												item.salary.salaryAccountValidYn),
									)
							),
						},
					],
				},
			],
		},
		{
			label: RouterPath.myTaskOperate().contractInspection.label,
			onClick: () => {
				onChangeRouter?.();
				navigate(`${RouterPath.myTaskOperate().contractInspection.key}`);
			},
			subSections: [
				{
					label: '',
					items: [
						{
							key: `${RouterPath.myTaskOperate().contractInspection.key}#1`,
							label: '본인부담금 정보',
							onClick: () => {
								onChangeRouter?.();
								navigate(`${RouterPath.myTaskOperate().contractInspection.key}#1`);
							},
							isDone: !!(
								form.watch('inspection.burdenAmt.burdenAmtPayMethodCd') &&
								(!(form.watch('inspection.burdenAmt.burdenAmtPayMethodCd') === 'CMN041.10') ||
									(form.watch('inspection.burdenAmt.burdenAmtAutotransAccountNo') &&
										form.watch('inspection.burdenAmt.burdenAmtAutotransBankCd') &&
										form.watch('inspection.burdenAmt.burdenAmtAutotransAccountDepositorNm') &&
										form.watch('inspection.burdenAmt.burdenAmtAutotransAccountValidYn'))) &&
								(!(form.watch('inspection.burdenAmt.burdenAmtPayMethodCd') === 'CMN041.30') ||
									form.watch('inspection.burdenAmt.burdenAmtPayDeductEmployeeId'))
							),
						},
						{
							key: `${RouterPath.myTaskOperate().contractInspection.key}#2`,
							label: '발송',
							onClick: () => {
								onChangeRouter?.();
								navigate(`${RouterPath.myTaskOperate().contractInspection.key}#2`);
							},
							isDone: true,
						},
						{
							key: `${RouterPath.myTaskOperate().contractInspection.key}#3`,
							label: '점검',
							onClick: () => {
								onChangeRouter?.();
								navigate(`${RouterPath.myTaskOperate().contractInspection.key}#3`);
							},
							isDone: !!(
								form.watch('inspection.check.salaryOfferPlanWriteYn') &&
								form.watch('inspection.check.pcorpScheduleRegistYn') &&
								form.watch('inspection.check.wardReportCompleteYn') &&
								form.watch('inspection.check.hopeiumFormWriteYn') &&
								form.watch('inspection.check.liabilityInsuranceRegistNeedYn') &&
								(form.watch('inspection.burdenAmt.burdenAmtPayMethodCd') !== 'CMN041.10' ||
									form.watch('inspection.check.autotransUploadYn')) &&
								form.watch('inspection.check.insuranceRequestYn')
							),
						},
					],
				},
			],
		},
	];
	return contractTaskScrollSpyMock;
}
