import styled from 'styled-components';

import { CheckGroupAlign } from '../type';

export const Container = styled.div<{ direction: CheckGroupAlign; gap: number }>`
	display: flex;
	flex-direction: ${(props) => (props.direction === 'col' ? 'column' : 'row')};
	gap: ${(props) => `${props.gap}rem`};
	flex-wrap: wrap;
`;
