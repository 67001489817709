import React, { useCallback } from 'react';

import { QueryObserverResult } from '@tanstack/react-query';
import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRFileName from 'components/base/CRFileName';
import CRTable from 'components/base/CRTable';
import { Toast } from 'components/base/CRToast';
import { CheckOption } from 'components/base/Selections/type';
import AddEducationDialog from 'components/domain/dialog/AddEducationDialog';
import DeleteDialog from 'components/domain/dialog/DeleteDialog';
import useFullTimeEmployeePage from 'lib/hook/full-time-employee/useFullTimeEmployeePage';
import { useDeleteFullTimeEmployeeEducation } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { useDownload } from 'lib/hook/util/useDownload';
import { EmployeeEduInfoDTO } from 'types/api/employee';
import { FileDTO } from 'types/dto/common';

import { STAFF_EDUCATION_LIST_TABLE_HEADER_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	items?: EmployeeEduInfoDTO[] | null;
	offset?: number;
	eduTypes: CheckOption[];
	onRefresh: () => Promise<QueryObserverResult<EmployeeEduInfoDTO[] | null, unknown>>;
}

function FullTimeEmployeeEducationTable({
	items = [],
	eduTypes,
	onRefresh,
	offset = 0,
}: Props): React.ReactElement {
	const { showDialog, hideDialog } = useDialog();
	const { currentEmployee } = useFullTimeEmployeePage();
	const { mutate: deleteEmployeeEducation } = useDeleteFullTimeEmployeeEducation(
		(client, returnData) => {
			if (returnData?.employeeId) {
				onRefresh();
				Toast.success(`교육이 삭제되었습니다.`);
				hideDialog();
			}
		},
	);
	const { downloadFiles } = useDownload();

	const handleDeleteLicenseItem = async (item?: EmployeeEduInfoDTO) => {
		if (!item) return;

		const deleteLicense = async (item?: EmployeeEduInfoDTO) => {
			if (!item) return;
			deleteEmployeeEducation({
				employeeEduId: item.employeeEduId,
			});
		};

		showDialog(({ hideDialog }) => (
			<DeleteDialog
				title='교육 삭제'
				content={`${currentEmployee?.korMemberNm} (${currentEmployee?.employeeId})의 교육 이력을 삭제하겠습니다.`}
				hideDialog={hideDialog}
				cancelOption={{
					text: '취소',
				}}
				successOption={{
					text: '삭제',
					successCallback: () => deleteLicense(item),
				}}
			/>
		));
	};

	const handleClickEditEducation = (item?: EmployeeEduInfoDTO) => {
		showDialog(({ hideDialog }) => (
			<AddEducationDialog
				item={item}
				currentEmployee={currentEmployee}
				eduTypes={eduTypes}
				hideDialog={hideDialog}
				onRefresh={onRefresh}
			/>
		));
	};

	const renderOption = (value: number, item?: EmployeeEduInfoDTO) => (
		<S.ButtonContainer>
			{item?.eduCompleteCertiFile &&
				item.eduCompleteCertiFile?.fileDetails?.some((item) => !item.fileDeleteYn) && (
					<CRButton.Default
						size='xSmall'
						type='outlined'
						palette='gray'
						onClick={() =>
							downloadFiles(
								item?.eduCompleteCertiFile?.fileDetails?.filter((item) => !item.fileDeleteYn),
							)
						}>
						다운로드
					</CRButton.Default>
				)}
			<CRButton.Default
				size='xSmall'
				type='outlined'
				palette='gray'
				onClick={() => handleClickEditEducation(item)}>
				수정
			</CRButton.Default>
			<CRButton.Default
				size='xSmall'
				type='outlined'
				palette='primary'
				onClick={() => handleDeleteLicenseItem(item)}>
				삭제
			</CRButton.Default>
		</S.ButtonContainer>
	);

	const renderKindCode = (value: string) => (
		<S.TextBox>{eduTypes?.find((code) => code.value === value)?.label || ''}</S.TextBox>
	);

	const renderEduCompleteDate = (value: string, item?: EmployeeEduInfoDTO) => (
		<span>{dayjs(item?.eduCompleteDate).format('YYYY.MM.DD')}</span>
	);

	const renderEduInstitutionNm = (value: string, item?: EmployeeEduInfoDTO) => (
		<span>{value || '-'}</span>
	);

	const renderEduCompleteCertiFile = useCallback((item?: FileDTO) => {
		const fileNames = item?.fileDetails
			?.filter((file) => !file.fileDeleteYn)
			?.map((data) => data.originFileNm);
		return <CRFileName maxLength={17} fileNames={fileNames} />;
	}, []);

	return (
		<S.Container>
			<CRTable.Root>
				<CRTable.Head heads={STAFF_EDUCATION_LIST_TABLE_HEADER_CONFIG} offset={offset} />
				<CRTable.Body>
					{items?.map((item) => (
						<CRTable.Row
							key={item.employeeEduId}
							item={item}
							renderKeys={[
								'eduTypeCd',
								'eduCompleteDate',
								'eduInstitutionNm',
								'eduCompleteCertiFile',
								'employeeEduId',
							]}
							toolTipKey={['eduInstitutionNm', 'eduCompleteCertiFile']}
							customRender={{
								eduTypeCd: renderKindCode,
								eduInstitutionNm: renderEduInstitutionNm,
								eduCompleteDate: renderEduCompleteDate,
								employeeEduId: renderOption,
								eduCompleteCertiFile: renderEduCompleteCertiFile,
							}}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default FullTimeEmployeeEducationTable;
