import React from 'react';

import * as Accordion from '@radix-ui/react-accordion';

import Colors from 'common/colors';
import CRBanner from 'components/base/CRBanner';
import CRInputLabel from 'components/base/CRInputLabel';
import { CRText } from 'components/base/CRText';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';

import * as S from './styles';

export default function AcquirementHelp() {
	return (
		<S.FormItemContainer>
			<CRInputLabel label='도움말' type='left-sub' showOverflow betweenGap='2.4rem'>
				<S.HelpText>
					<S.HelpTextTitle>신고 기준 금액</S.HelpTextTitle>
					<Accordion.Item value='가족요양' asChild style={{ marginBottom: '0.8rem' }}>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger
										style={{
											height: '4rem',
											borderRadius: '1rem',
											background: Colors.gray95,
											padding: '0 1.6rem',
											display: 'flex',
											alignItems: 'center',
										}}>
										<CRText typography='bodyB'>1. 가족요양</CRText>
									</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild style={{}}>
								<TaskAccordion.Content
									style={{
										borderTop: 0,
										background: Colors.gray99,
									}}>
									<S.TableContainer>
										<CRText>(해당연도 서비스별 단위임금) X (월 최대 근무일수)</CRText>
										<S.Table>
											<S.TableRow>
												<S.TableLabelColumn />
												<S.TableLabelColumn>월 60시간 미만</S.TableLabelColumn>
												<S.TableLabelColumn>월 60시간 이상</S.TableLabelColumn>
											</S.TableRow>
											<S.TableRow>
												<S.TableLabelColumn>60분(20일)</S.TableLabelColumn>
												<S.TableValueColumn>400,000원</S.TableValueColumn>
												<S.TableValueColumn>352,000원</S.TableValueColumn>
											</S.TableRow>
											<S.TableRow>
												<S.TableLabelColumn style={{ width: '25%' }}>90분(31일)</S.TableLabelColumn>
												<S.TableValueColumn style={{ width: '25%' }}>905,200원</S.TableValueColumn>
												<S.TableValueColumn style={{ width: '25%' }}>796,700원</S.TableValueColumn>
											</S.TableRow>
										</S.Table>
									</S.TableContainer>
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
					<Accordion.Item
						value='일반요양/방문목욕/방문간호'
						asChild
						style={{ marginBottom: '0.8rem' }}>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger
										style={{
											height: '4rem',
											borderRadius: '1rem',
											background: Colors.gray95,
											padding: '0 1.6rem',
											display: 'flex',
											alignItems: 'center',
										}}>
										<CRText typography='bodyB'>2. 일반요양 / 방문목욕 / 방문간호</CRText>
									</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild>
								<TaskAccordion.Content
									style={{
										borderTop: 0,
										background: Colors.gray99,
									}}>
									<S.TableContainer>
										<S.TableItemContainer>
											<CRText>(해당연도 서비스별 단위임금) X (월 최대 근무일수)</CRText>
											<CRText>1) 일반요양 등급별 단위임금(60분 기준)</CRText>
											<S.Table>
												<S.TableRow>
													<S.TableLabelColumn />
													<S.TableLabelColumn style={{ width: '25%' }}>1등급</S.TableLabelColumn>
													<S.TableLabelColumn style={{ width: '25%' }}>
														2등급~5등급
													</S.TableLabelColumn>
													<S.TableLabelColumn style={{ width: '25%' }}>
														케어링 가족요양 보호사
													</S.TableLabelColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableLabelColumn>2025년 기준</S.TableLabelColumn>
													<S.TableValueColumn>12,700원 ~ 13,500원</S.TableValueColumn>
													<S.TableValueColumn>12,700원</S.TableValueColumn>
													<S.TableValueColumn>13,000원</S.TableValueColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableLabelColumn>2025년 이전 근로자</S.TableLabelColumn>
													<S.TableValueColumn colSpan={3}>
														2025년도 이전에 지급하던 시급으로 반영
													</S.TableValueColumn>
												</S.TableRow>
											</S.Table>
										</S.TableItemContainer>
										<S.TableItemContainer>
											<CRText>2) 방문목욕 / 방문간호 단위임금(60분 기준)</CRText>
											<S.Table>
												<S.TableRow>
													<S.TableLabelColumn colSpan={2} />
													<S.TableLabelColumn>월 60시간 미만</S.TableLabelColumn>
													<S.TableLabelColumn>월 60시간 이상</S.TableLabelColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableLabelColumn rowSpan={2}>방문목욕</S.TableLabelColumn>
													<S.TableLabelColumn>가족O 근로자</S.TableLabelColumn>
													<S.TableValueColumn>19,700원</S.TableValueColumn>
													<S.TableValueColumn>17,200원</S.TableValueColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableLabelColumn>가족X 근로자</S.TableLabelColumn>
													<S.TableValueColumn>20,200원</S.TableValueColumn>
													<S.TableValueColumn>17,200원</S.TableValueColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableLabelColumn rowSpan={2}>방문간호</S.TableLabelColumn>
													<S.TableLabelColumn>가족O 근로자</S.TableLabelColumn>
													<S.TableValueColumn>41,000원</S.TableValueColumn>
													<S.TableValueColumn>39,000원</S.TableValueColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableLabelColumn>가족X 근로자</S.TableLabelColumn>
													<S.TableValueColumn>44,000원</S.TableValueColumn>
													<S.TableValueColumn>42,000원</S.TableValueColumn>
												</S.TableRow>
											</S.Table>
										</S.TableItemContainer>
									</S.TableContainer>
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
					<Accordion.Item value='주소정근로시간' asChild style={{ marginBottom: '0rem' }}>
						<TaskAccordion.Item>
							<Accordion.Header asChild>
								<Accordion.Trigger asChild>
									<TaskAccordion.Trigger
										style={{
											height: '4rem',
											borderRadius: '1rem',
											background: Colors.gray95,
											padding: '0 1.6rem',
											display: 'flex',
											alignItems: 'center',
										}}>
										<CRText typography='bodyB'>주 소정 근로시간</CRText>
									</TaskAccordion.Trigger>
								</Accordion.Trigger>
							</Accordion.Header>
							<Accordion.Content asChild>
								<TaskAccordion.Content
									style={{
										borderTop: 0,
										background: Colors.gray99,
									}}>
									<S.TableContainer>
										<S.TableItemContainer>
											<S.Table>
												<S.TableRow>
													<S.TableLabelColumn
														style={{
															textAlign: 'center',
														}}>
														서비스 종류
													</S.TableLabelColumn>
													<S.TableLabelColumn
														style={{
															textAlign: 'center',
														}}>
														실제 월 근무 시간
													</S.TableLabelColumn>
													<S.TableLabelColumn
														style={{
															textAlign: 'center',
														}}>
														근로계약서 상<br />월 소정 근로시간
													</S.TableLabelColumn>
													<S.TableLabelColumn
														style={{
															textAlign: 'center',
														}}>
														4대보험 취득 시<br />주 소정 근록시간
													</S.TableLabelColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														가족요양(60분)
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														-
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														20
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														5
													</S.TableValueColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														가족요양(90분)
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														-
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														47
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														11
													</S.TableValueColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}
														rowSpan={6}>
														일반요양
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														13시간 미만
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														12
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														3
													</S.TableValueColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														13시간 이상 40시간 미만
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														39
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														9
													</S.TableValueColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														40시간 이상 60시간 미만
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														59
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														12
													</S.TableValueColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														60시간 이상 90시간 미만
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														89
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														18
													</S.TableValueColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														90시간 이상 120시간 미만
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														119
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														24
													</S.TableValueColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														120시간 이상
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														159
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														39
													</S.TableValueColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}
														rowSpan={4}>
														방문목욕
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														4시간 이하
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														4
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														1
													</S.TableValueColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														4시간 초과 ~ 8시간 이하
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														8
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														2
													</S.TableValueColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														8시간 초과 ~ 12시간 이하
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														12
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														3
													</S.TableValueColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														12시간 초과
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														16
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														4
													</S.TableValueColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}
														rowSpan={5}>
														방문간호
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														4시간 이하
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														4
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														1
													</S.TableValueColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														4시간 초과 ~ 8시간 이하
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														8
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														2
													</S.TableValueColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														8시간 초과 ~ 12시간 이하
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														12
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														3
													</S.TableValueColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														12시간 초과 ~ 16시간 이하
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														16
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														4
													</S.TableValueColumn>
												</S.TableRow>
												<S.TableRow>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														16시간 초과
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														20
													</S.TableValueColumn>
													<S.TableValueColumn
														style={{
															textAlign: 'center',
														}}>
														5
													</S.TableValueColumn>
												</S.TableRow>
											</S.Table>
										</S.TableItemContainer>
									</S.TableContainer>
								</TaskAccordion.Content>
							</Accordion.Content>
						</TaskAccordion.Item>
					</Accordion.Item>
					<S.Divider />
					<S.HelpTextLabel>• 취득일 : 고용일(=근로계약서 상 근무시작일)</S.HelpTextLabel>
					<S.HelpTextDesc>• 근무 중 취득 시, 60시간 이상 근무가 발생한 월의 1일</S.HelpTextDesc>
					<S.HelpTextDesc>
						• 근로계약서 상 근무시작일보다 실제 근무시작일이 늦어질 경우, 실제 근무일을 취득일로 함
					</S.HelpTextDesc>
					<S.HelpTextLabel>
						• 이중취득 가능 : 국민연금, 건강보험, 산재보험(필수 취득)
					</S.HelpTextLabel>
					<S.HelpTextLabel>
						• 이중취득 불가 : 고용보험 (단, 신규입사 시 무조건 취득시키고 추후 공단 안내에 따라
						처리)
					</S.HelpTextLabel>
					<S.BannerContainer>
						<CRBanner
							type='warning'
							content='국민연금/고용/산재보험은 취득일 기준 익월 15일까지 신고, 건강보험은 취득일 기준 14일 이내 신고'
						/>
					</S.BannerContainer>
				</S.HelpText>
			</CRInputLabel>
		</S.FormItemContainer>
	);
}
