import React from 'react';
import { Controller } from 'react-hook-form';

import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import useContractRenewalTask from 'lib/hook/view/contract/useContractRenewalTask';

import * as S from './styles';

function RenewalRecipientManagerForm(): React.ReactElement {
	const { commonCodes, form, disabled } = useContractRenewalTask();

	return (
		<S.Container>
			<CRInputLabel type='left' label='소속' isRequired>
				<CRInput.Selector
					autoComplete
					placeholder='소속 선택'
					currentValue={{
						label: '',
						value: form?.watch('recipient.socialWorkerCenterId') ?? '',
					}}
					items={commonCodes?.centerOption}
					disabled
				/>
			</CRInputLabel>
			<Controller
				name='recipient.socialWorkerId'
				control={form?.control}
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel type='left' label='사회복지사' showOverflow>
						<CRInput.Selector
							disabled={disabled}
							placeholder='사회복지사 선택'
							currentValue={
								value !== undefined
									? {
											label: '',
											value,
										}
									: undefined
							}
							items={commonCodes?.centerManagerOption}
							onChangeValue={(item) => onChange(item.value)}
						/>
					</CRInputLabel>
				)}
			/>
		</S.Container>
	);
}

export default RenewalRecipientManagerForm;
