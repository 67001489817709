import React, { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';

import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import CRTableHeader from 'components/base/CRTableHeader';
import { CheckOption } from 'components/base/Selections/type';
import { commonCodeAdapter } from 'lib/adapter/common';
import {
	useAnnualTOUsageHistories,
	useCommonCodes,
	useFullTimeEmployees,
	useMyAccountInfo,
} from 'lib/hook/react-query';
import { PageInfo } from 'types/view/base';
import { Filter } from 'types/view/filter';

import { ANNUAL_TO_USAGE_HEADER_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	onChangeFilter?: (data: {
		keyword: string;
		dutyFilter: CheckOption[];
		annualTOFilter: CheckOption[];
		rangeFilter: CheckOption[];
		managerFilter: CheckOption[];
	}) => void;
}

function AnnualTOUsageTable({ onChangeFilter }: Props): React.ReactElement {
	const [keyword, setKeyword] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const [pageInfo, setPageInfo] = useState<PageInfo>({
		page: 1,
		size: 50,
		totalPages: 0,
	});
	const [currentFilter, setCurrentFilter] = useState<{
		dutyFilter: CheckOption[];
		annualTOFilter: CheckOption[];
		rangeFilter: CheckOption[];
		managerFilter: CheckOption[];
	}>({
		dutyFilter: [],
		annualTOFilter: [],
		rangeFilter: [
			{
				label: 'rangeFilter',
				value: {
					start: dayjs(new Date()).startOf('y').toDate(),
					end: dayjs(new Date()).endOf('y').toDate(),
				},
			},
		],
		managerFilter: [],
	});

	const { data: myAccountInfo } = useMyAccountInfo();
	const {
		data = {
			totCnt: 0,
			annualUseHists: [],
		},
		refetch,
	} = useAnnualTOUsageHistories({
		page: pageInfo.page,
		size: pageInfo.size,
		keyword,
		centerId: myAccountInfo?.centerId ?? 0,
		vacaStartDt: currentFilter.rangeFilter[0]?.value?.start
			? dayjs(currentFilter.rangeFilter[0]?.value.start).format('YYYYMMDD')
			: undefined,
		vacaEndDt: currentFilter.rangeFilter[0]?.value?.end
			? dayjs(currentFilter.rangeFilter[0]?.value.end).format('YYYYMMDD')
			: undefined,
		employeeId: currentFilter.managerFilter.map((item) => item.value),
		vacaKindCds: currentFilter.annualTOFilter.map((item) => item.value),
		dutyCds: currentFilter.dutyFilter.map((item) => item.value),
	});

	const { data: fullTimeEmployees } = useFullTimeEmployees({
		centerIds: [myAccountInfo?.centerId ?? 0],
	});

	const {
		data: commonCodes = {
			CMN070: [],
			CMN195: [],
		},
	} = useCommonCodes({ comCdGroupNms: ['CMN070', 'CMN195'] }, commonCodeAdapter);

	const filters = useMemo(
		() =>
			[
				{
					key: 'dutyFilter',
					type: 'multi',
					placeholder: '직무',
					options: commonCodes.CMN070?.filter(
						(e) => e.data?.comCdGroupNm === 'CMN070' && e?.data?.etcDesc1 === '1',
					),
				},
				{
					key: 'annualTOFilter',
					type: 'multi',
					placeholder: '연차 종류',
					options: commonCodes.CMN195,
				},
				{
					key: 'rangeFilter',
					type: 'calendar',
					placeholder: '연차사용일',
					value: [
						{
							label: 'rangeFilter',
							value: {
								start: dayjs(new Date()).startOf('y').toDate(),
								end: dayjs(new Date()).endOf('y').toDate(),
							},
						},
					],
				},
				{
					key: 'managerFilter',
					type: 'multi',
					placeholder: '직원명',
					options: (fullTimeEmployees ?? []).map((item) => ({
						label: item.korMemberNm,
						value: item.employeeId,
					})),
				},
			] as Filter<{
				label: string;
				value: any;
			}>[],
		[commonCodes, fullTimeEmployees],
	);

	const renderDate = (val: string) => (val ? dayjs(val).format('YYYY.MM.DD') : '-');

	const renderUseDateCount = (val: number) => {
		if (val >= 1) return `${val}일`;
		return `${val * 8}시간`;
	};

	const handleSearchValue = (value: string) => setSearchValue(value);

	const handleSearch = () => {
		setKeyword(searchValue);
		setPageInfo({ ...pageInfo, page: 1 });
	};

	useEffect(() => {
		onChangeFilter?.({
			keyword,
			...currentFilter,
		});
	}, [keyword, currentFilter]);

	useEffect(() => {
		setPageInfo((prev) => ({
			...prev,
			totalPages: Math.ceil((data?.totCnt ?? 0) / prev.size),
		}));
	}, []);

	return (
		<S.Container>
			<CRTableHeader
				currentFilter={currentFilter}
				setCurrentFilter={setCurrentFilter}
				stickyMode
				filters={filters}
				searchValue={searchValue}
				pageInfo={pageInfo}
				onChangeSearchValue={handleSearchValue}
				onSearch={handleSearch}
				onChangePageInfo={(value) => setPageInfo(value)}
				showRefresh
				placeholder='직원명'
				showViewCount
				onRefresh={refetch}
			/>
			<S.TableScrollContainer>
				<CRTable.Root>
					<CRTable.Head heads={ANNUAL_TO_USAGE_HEADER_CONFIG} offset={-47} />
					<CRTable.Body>
						{data?.annualUseHists.map((item, index) => (
							<CRTable.Row
								key={item.workScheduleId}
								item={{ ...item, index: index + 1 }}
								renderKeys={[
									'index',
									'workScheduleDt',
									'employeeNm',
									'dutyNm',
									'genderNm',
									'vacaKindNm',
									'vacaUseDayCnt',
									'joinDate',
									'',
								]}
								customStyle={{
									index: {
										textAlign: 'right',
									},
								}}
								customRender={{
									workScheduleDt: renderDate,
									joinDate: renderDate,
									vacaUseDayCnt: renderUseDateCount,
								}}
							/>
						))}
					</CRTable.Body>
				</CRTable.Root>
			</S.TableScrollContainer>
		</S.Container>
	);
}

export default AnnualTOUsageTable;
