import React, { useMemo } from 'react';

import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import CRRsdNumber from 'components/base/CRRsdNumber';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import { displayBirthDay } from 'lib';
import { useCommonCodes } from 'lib/hook/react-query';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import { RecipientBasicInfoFormType } from 'types/view/recipient';

import * as S from './styles';

interface Props {
	item?: RecipientBasicInfoFormType;
	onChangeMode: () => void;
}

function RecipientBasicInfoView({ item, onChangeMode }: Props): React.ReactElement {
	const { currentRecipientStatus } = useRecipientPage();
	const { data: signTypeCommonCodes } = useCommonCodes({ comCdGroupNms: ['CMN143'] });

	const signTypeOptions = useMemo(
		() =>
			(signTypeCommonCodes ?? []).map(
				(item) =>
					({
						label: item.korComCdAliasNm,
						value: item.comCdId,
					}) as CheckOption,
			),
		[signTypeCommonCodes],
	);

	const renderAddress = () => {
		const address = [
			item?.address?.basAddr ?? '',
			item?.address?.detailAddr ?? '',
			item?.address?.zipCode ? `(${item?.address.zipCode})` : '',
		].join(' ');
		return address.trim() ? address : '-';
	};

	const isResign = currentRecipientStatus?.status === 'CMN058.30';

	return (
		<S.Container>
			<S.Label>
				기본 정보
				{!isResign && (
					<S.ButtonContainer>
						<CRButton.Default size='xSmall' type='outlined' palette='gray' onClick={onChangeMode}>
							편집
						</CRButton.Default>
					</S.ButtonContainer>
				)}
			</S.Label>
			<S.Table $isDisabled={isResign}>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '10.4rem' }}>이름</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '24rem' }}>{item?.name || '-'}</S.TableValueColumn>
					<S.TableLabelColumn rowSpan={2} style={{ width: '10.4rem' }}>
						주소
					</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '24rem' }} rowSpan={2}>
						{renderAddress()}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '10.4rem' }}>주민등록번호</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '24rem' }}>
						<CRRsdNumber
							disabled={isResign}
							rdsNumber={item?.korMemberNm}
							key={item?.korMemberNm}
						/>
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '10.4rem' }}>생년월일</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '24rem' }}>
						{item?.birthday ? displayBirthDay(item?.birthday) : '-'}
					</S.TableValueColumn>
					<S.TableLabelColumn rowSpan={2} style={{ width: '10.4rem' }}>
						비고
					</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '24rem' }} rowSpan={2}>
						{item?.memo || '-'}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '10.4rem' }}>서명</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '24rem' }}>
						<CRCheckBoxGroup
							checkType='single'
							type='radio'
							gap={0.8}
							value={[{ label: item?.signType || '', value: item?.signType }]}
							disabled
							options={signTypeOptions.map((item) => ({
								...item,
								disabled: !!isResign,
							}))}
						/>
					</S.TableValueColumn>
				</S.TableRow>
			</S.Table>
		</S.Container>
	);
}

export default RecipientBasicInfoView;
