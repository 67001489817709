import React, { useEffect, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import * as Accordion from '@radix-ui/react-accordion';

import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes } from 'lib/hook/react-query';
import { InsuranceDetailDTO } from 'types/api/insurance';
import { InsuranceEditDetailFormType } from 'types/view/insurance';

import { InsuranceState } from '..';
import * as S from './styles';

interface Props {
	item: InsuranceDetailDTO;
	disabled?: boolean;
	context: UseFormReturn<InsuranceEditDetailFormType, any, undefined>;
}

export default function InsuranceChangeJobView({ item, disabled, context }: Props) {
	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN161'],
		},
		commonCodeAdapter,
	);

	useEffect(() => {
		if (item.insuranceRequestState) {
			context.setValue('insuranceRequestStateCd', {
				label: item.insuranceRequestState.text,
				value: item.insuranceRequestState.id,
			});
		}
		if (item.responseDesc) {
			context.setValue('responseDesc', item.responseDesc);
		}
	}, [item]);

	return (
		<>
			<Accordion.Item value='신고 내용' asChild>
				<TaskAccordion.Item>
					<Accordion.Header asChild>
						<Accordion.Trigger asChild>
							<TaskAccordion.Trigger>신고 내용</TaskAccordion.Trigger>
						</Accordion.Trigger>
					</Accordion.Header>
					<Accordion.Content asChild>
						<TaskAccordion.Content>
							<S.FormContainer>
								<CRInputLabel label='요청 사항' type='left-sub' showOverflow betweenGap='2.4rem'>
									<CRInput.TextArea
										disabled
										numberOfLines={4}
										value={item.requestDesc || ''}
										placeholder=''
										fixedHeight
									/>
								</CRInputLabel>
								<div
									style={{
										width: '57.2rem',
									}}>
									{item.attachFile?.fileDetails &&
										item.attachFile?.fileDetails?.some((item) => !item.fileDeleteYn) && (
											<CRInputLabel
												label='파일 첨부'
												type='left-sub'
												showOverflow
												betweenGap='2.4rem'>
												<CRInput.FileUploader
													disabled
													files={item.attachFile?.fileDetails}
													placeholder='파일 업로드'
													type='multiple'
												/>
											</CRInputLabel>
										)}
								</div>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInputLabel
											label='업무 담당자 답변'
											type='left-sub'
											showOverflow
											betweenGap='2.4rem'>
											<CRInput.TextArea
												disabled={disabled}
												numberOfLines={4}
												onChange={onChange}
												value={disabled ? item?.attachFileResponseDesc : value}
												placeholder='업무 담당자 답변 입력'
												fixedHeight
											/>
										</CRInputLabel>
									)}
									name='attachFileResponseDesc'
									control={context.control}
								/>
							</S.FormContainer>
						</TaskAccordion.Content>
					</Accordion.Content>
				</TaskAccordion.Item>
			</Accordion.Item>
			<Accordion.Item value='4대보험 신고 결과' asChild>
				<TaskAccordion.Item>
					<Accordion.Header asChild>
						<Accordion.Trigger asChild>
							<TaskAccordion.Trigger>4대보험 신고 결과</TaskAccordion.Trigger>
						</Accordion.Trigger>
					</Accordion.Header>
					<Accordion.Content asChild>
						<TaskAccordion.Content>
							<S.FormContainer>
								<CRInputLabel
									label='결과 선택'
									type='left-sub'
									isRequired
									showOverflow
									betweenGap='2.4rem'>
									<Controller
										render={({ field: { onChange, value } }) => (
											<CRInput.Selector
												topOffset={-285}
												disabled={disabled}
												items={commonCodes?.CMN161}
												currentValue={value}
												onChangeValue={onChange}
												placeholder='결과 선택'
											/>
										)}
										name='insuranceRequestStateCd'
										control={context.control}
									/>
								</CRInputLabel>
								{item.insuranceRequestState.id !== InsuranceState.완료 &&
									[InsuranceState.반려됨, InsuranceState.철회].includes(
										context.watch('insuranceRequestStateCd')?.value as InsuranceState,
									) && (
										<CRInputLabel
											label='반려 사유'
											type='left-sub'
											isRequired
											showOverflow
											betweenGap='2.4rem'>
											<Controller
												render={({ field: { onChange, value } }) => (
													<CRInput.Default
														disabled={disabled}
														value={item?.cancelDesc || value}
														onChange={onChange}
														placeholder='반려 사유 입력'
													/>
												)}
												name='cancelDesc'
												control={context.control}
											/>
										</CRInputLabel>
									)}
							</S.FormContainer>
						</TaskAccordion.Content>
					</Accordion.Content>
				</TaskAccordion.Item>
			</Accordion.Item>
		</>
	);
}
