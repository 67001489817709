import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInputMessage from 'components/base/CRInputMessage';
import { Toast } from 'components/base/CRToast';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import { EmployeeHealthCheck } from 'lib';
import { useMyAccountInfo, useSaveFullTimeEmployeeHealthCheck } from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import { EmployeeDetailInfoDTO, SaveEmployeeHealthCheckRequestDTO } from 'types/api/employee';
import { FullTimeEmployeeDTO } from 'types/dto/full-time-employee';
import { EmployeeHealthCheckEnrollForm } from 'types/view/employee';

import * as S from './styles';

interface Props {
	hideDialog: () => void;
	currentEmployee?: FullTimeEmployeeDTO;
	options?: {
		healthCheckKind: CheckOption[];
	};
}

function AddFullTimeEmployeeHealthCheckDialog({
	currentEmployee,
	hideDialog,
	options,
}: Props): React.ReactElement {
	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<EmployeeHealthCheckEnrollForm>({
		resolver: yupResolver(EmployeeHealthCheck),
		mode: 'onChange',
	});
	const [fileLoading, setFileLoading] = useState<boolean>(false);
	const { data: myAccountInfo } = useMyAccountInfo();
	const { mutate: updateEmployeeHealthCheck } = useSaveFullTimeEmployeeHealthCheck(
		(client, returnData, variables) => {
			if (returnData?.employeeId) {
				client.invalidateQueries([
					endpoint.getFullTimeEmployeeHealthCheck.key,
					{ employeeId: currentEmployee?.employeeId },
				]);
				client.setQueryData(
					[
						endpoint.getFullTimeEmployeeDetailInfo.key,
						{
							centerId: Number(currentEmployee?.centerId),
							memberId: Number(currentEmployee?.memberId),
							employeeId: Number(currentEmployee?.employeeId),
						},
					],
					(data: any) => {
						const healthCheckDate = dayjs(variables.healthCheckDate).isAfter(
							dayjs(data?.healthCheckDate),
						)
							? variables.healthCheckDate
							: data?.healthCheckDate;
						return {
							...data,
							healthCheckDate,
						} as EmployeeDetailInfoDTO;
					},
				);
				hideDialog();
				Toast.success('검진이력을 추가했습니다.');
			}
		},
	);

	const onSubmit = async (data: EmployeeHealthCheckEnrollForm) => {
		if (!myAccountInfo?.centerId || !currentEmployee?.employeeId) return;

		const param: SaveEmployeeHealthCheckRequestDTO = {
			employeeId: currentEmployee.employeeId,
			healthCheckDate: dayjs(data.healthCheckDate).format('YYYY-MM-DDT09:00:00'),
			healthCheckKindCd: data.healthCheckKind[0].value,
			healthCheckInstitutionNm: data.healthCheckInstitutionNm,
			remark: data.remark,
			healthCheckFile: {
				fileDetails: data.healthCheckFile,
			},
		};

		updateEmployeeHealthCheck(param);
	};

	return (
		<S.Container>
			<S.Form onSubmit={handleSubmit(onSubmit)}>
				<S.Header>
					<S.Title>검진 이력 추가</S.Title>
					<S.Icon src={Assets.icon.close} alt='close' onClick={hideDialog} />
				</S.Header>
				<S.Content>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel
								isRequired
								label='검진일'
								message={
									errors.healthCheckDate?.message && (
										<CRInputMessage type='error'>
											{errors.healthCheckDate?.message ?? ''}
										</CRInputMessage>
									)
								}>
								<CRInput.DatePicker
									placeholder='날짜 선택'
									onChangeValue={onChange}
									value={value}
								/>
							</CRInputLabel>
						)}
						name='healthCheckDate'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel
								isRequired
								label='종류'
								message={
									errors.healthCheckKind?.message && (
										<CRInputMessage type='error'>
											{errors.healthCheckKind?.message ?? ''}
										</CRInputMessage>
									)
								}>
								<CRCheckBoxGroup
									onChange={onChange}
									appearanceType='button'
									checkType='single'
									type='radio'
									gap={0.8}
									value={value}
									options={
										options?.healthCheckKind?.map((option) => ({
											label: option.label,
											value: option.value,
										})) ?? []
									}
								/>
							</CRInputLabel>
						)}
						name='healthCheckKind'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<CRInputLabel
								isRequired
								label='검진기관'
								message={
									errors.healthCheckInstitutionNm?.message && (
										<CRInputMessage type='error'>
											{errors.healthCheckInstitutionNm?.message ?? ''}
										</CRInputMessage>
									)
								}>
								<CRInput.Default
									ref={ref}
									onBlur={onBlur}
									onChange={onChange}
									value={value}
									placeholder='검진기관명 입력'
								/>
							</CRInputLabel>
						)}
						name='healthCheckInstitutionNm'
						control={control}
					/>
					<Controller
						name='remark'
						control={control}
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='비고'>
								<CRInput.TextArea numberOfLines={6} onChange={onChange} value={value} />
							</CRInputLabel>
						)}
					/>
					<Controller
						name='healthCheckFile'
						control={control}
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='검진결과'>
								<CRInput.FileUploader
									setIsLoading={setFileLoading}
									title='검진결과'
									type='multiple'
									onChange={onChange}
									files={value}
								/>
							</CRInputLabel>
						)}
					/>
				</S.Content>
				<S.BottomContainer>
					<S.ButtonContainer>
						<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
							취소
						</CRButton.Default>
						<CRButton.Default
							disabled={!isValid || fileLoading}
							palette='primary'
							size='default'
							buttonType='submit'>
							등록
						</CRButton.Default>
					</S.ButtonContainer>
				</S.BottomContainer>
			</S.Form>
		</S.Container>
	);
}

export default AddFullTimeEmployeeHealthCheckDialog;
