/* eslint-disable react/destructuring-assignment */
import React, { Component, ErrorInfo, ReactNode } from 'react';

import { EnvConfig } from 'lib/config/env';
import { Sentry } from 'lib/third-party/sentry';
import DashBoardErrorPage from 'pages/exception/ErrorPage/DashBoardErrorPage';

type BoundaryLevel = 'global' | 'component';

interface ErrorBoundaryProps {
	children: ReactNode;
	fallback?: ReactNode;
	level?: BoundaryLevel;
}

interface ErrorBoundaryState {
	hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
	static level = 'component';

	static isProduction = EnvConfig.BUILD_ENV === 'production';

	constructor(props: ErrorBoundaryProps) {
		super(props);
		if (props.level) {
			ErrorBoundary.level = props.level;
		}
		this.state = {
			hasError: false,
		};
	}

	static getDerivedStateFromError(error: Error): ErrorBoundaryState {
		return { hasError: true };
	}

	override componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
		if (ErrorBoundary.level === 'global') {
			Sentry.globalBoundaryError(error);
			return;
		}
		if (ErrorBoundary.level === 'component') {
			Sentry.componentBoundaryError(error);
			return;
		}
	}

	override render(): ReactNode {
		if (this.state.hasError && ErrorBoundary.isProduction) {
			return this.props.fallback || <DashBoardErrorPage />;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
