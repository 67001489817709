import React from 'react';

import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import { CRText } from 'components/base/CRText';

function SalaryEmpty() {
	return (
		<FlexContainer width='100%' height='80%' align='center' justify='center'>
			<FlexContainer direction='column'>
				<CRText typography='h3' text='임금이 생성되지 않았습니다.' color='gray60' />
				<FlexContainer direction='column'>
					<CRText
						typography='body'
						text='해당 월 임금이 아직 생성되지 않았습니다.'
						color='gray60'
					/>
					<CRText typography='body' text='센터장에게 요청 바랍니다.' color='gray60' />
				</FlexContainer>
			</FlexContainer>
		</FlexContainer>
	);
}

export default SalaryEmpty;
