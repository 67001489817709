import React, { useMemo } from 'react';

import CRButton from 'components/base/CRButton';
import { CheckOption } from 'components/base/Selections/type';
import FullTimeEmployeeWorkingStatusHistoryDialog from 'components/domain/dialog/FullTimeEmployeeWorkingStatusHistoryDialog';
import useFullTimeEmployeePage from 'lib/hook/full-time-employee/useFullTimeEmployeePage';
import { useCommonCodes } from 'lib/hook/react-query';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';

import * as S from './styles';

export default function FullTimeEmployeeBasicStatusFormView(): React.ReactElement {
	const { currentEmployeeWorkState, employeeDetailData, currentEmployee } =
		useFullTimeEmployeePage();
	const { showDialog } = useGlobalLayout();
	const { data: employeeStatusCode } = useCommonCodes({ comCdGroupNms: ['CMN068'] });

	const workStateCodes = useMemo(
		() =>
			(employeeStatusCode ?? [])
				.filter((item) => item.etcDesc1 === '1')
				.map(
					(item) =>
						({
							label: item.korComCdAliasNm,
							value: item.comCdId,
						}) as CheckOption,
				),
		[employeeStatusCode],
	);

	const currentWorkState = workStateCodes.find(
		(option) => option.value === currentEmployeeWorkState.status,
	);

	const isResign = employeeDetailData?.workStateCd === 'CMN068.30';

	const handleClickWorkingStatusHistory = () => {
		showDialog(({ hideDialog }) => (
			<FullTimeEmployeeWorkingStatusHistoryDialog
				currentFullTimeEmployee={currentEmployee}
				onClickClose={hideDialog}
				isResign={isResign}
			/>
		));
	};

	return (
		<S.Table $isDisabled={isResign}>
			<S.TableLabelColumn style={{ width: '9.6rem' }}>상태</S.TableLabelColumn>
			<S.TableValueColumn style={{ width: '44rem' }}>
				{currentWorkState?.label || '-'}
				<S.RightButtonContainer>
					<CRButton.Default
						size='xSmall'
						palette='gray'
						type='outlined'
						onClick={handleClickWorkingStatusHistory}>
						변경/이력
					</CRButton.Default>
				</S.RightButtonContainer>
			</S.TableValueColumn>
			<S.TableLabelColumn style={{ width: '9.6rem' }}>ERP 아이디</S.TableLabelColumn>
			<S.TableValueColumn style={{ width: '44rem' }}>
				{employeeDetailData?.userId || '-'}
			</S.TableValueColumn>
		</S.Table>
	);
}
