import React, { ChangeEvent, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRSpinner from 'components/base/CRSpinner';
import CRTable from 'components/base/CRTable';
import CRTableHeader from 'components/base/CRTableHeader';
import CRTableMonthSelector from 'components/base/CRTableMonthSelector';
import { CheckOption } from 'components/base/Selections/type';
import { ESignWayDialog } from 'components/domain/dialog/ESignDialog';
import { SendRecordingSheetDialog } from 'components/domain/dialog/SendRecordingSheetDialog';
import RecordingSheetTable from 'components/domain/table/RecordingSheetTable';
import { commonCodeAdapter } from 'lib/adapter/common';
import { RecordingSheetAdapter } from 'lib/adapter/schedule';
import {
	useAutomationLastSync,
	useCenterManagers,
	useCommonCodes,
	useMyAccountInfo,
	useRecordingSheets,
} from 'lib/hook/react-query';
import useDateFilter from 'lib/hook/util/useDateFilter';
import useDialog from 'lib/hook/util/useDialog';
import usePageFilter from 'lib/hook/util/usePageFilter';
import { PageInfo } from 'types/view/base';
import { ESignRequestForm } from 'types/view/eDoc';
import { Filter } from 'types/view/filter';

import * as S from './styles';

export default function RecordingSheetTab() {
	const [date, setDate] = useDateFilter(dayjs().subtract(1, 'month').toDate());
	const [keyword, setKeyword] = useState('');
	const [checkIds, setCheckIds] = useState<string[]>([]);
	const {
		filters: currentFilter,
		setFilters: setCurrentFilter,
		pageInfo,
		setPageInfo,
	} = usePageFilter({
		rangeFilter: [
			{
				label: 'rangeFilter',
				value: {
					start: dayjs(date).startOf('M').toDate(),
					end: dayjs(date).endOf('M').toDate(),
				},
			},
		],
	});

	const serviceStartDate = currentFilter?.rangeFilter?.[0].value?.start;
	const serviceEndDate = currentFilter?.rangeFilter?.[0].value?.end;

	const { showDialog, hideDialog } = useDialog();

	const [searchValue, setSearchValue] = useState('');
	const {
		data: commonCodes = {
			CMN006: [],
		},
	} = useCommonCodes({ comCdGroupNms: ['CMN006'] }, commonCodeAdapter);
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: centerManagers } = useCenterManagers({
		centerId: myAccountInfo?.centerId,
	});

	const { data: lastSyncData } = useAutomationLastSync({
		centerId: myAccountInfo?.centerId,
		uploadTypeCds: ['CMN107.RFID'],
		uploadTargetYm: dayjs(date).format('YYYYMM'),
	});

	const serviceFilter = useMemo(
		() =>
			({
				key: 'serviceFilter',
				type: 'multi',
				placeholder: '급여 종류',
				options: (commonCodes?.CMN006 || [])?.filter((item) => item?.data?.etcDesc1 === 'Y'),
			}) as Filter<CheckOption>,
		[commonCodes?.CMN006],
	);

	const {
		data: recordingSheetsData,
		isLoading: recordingSheetLoading,
		refetch,
	} = useRecordingSheets(
		{
			centerId: myAccountInfo?.centerId,
			page: pageInfo.page,
			size: pageInfo.size,
			serviceStartDt: serviceStartDate && dayjs(serviceStartDate).format('YYYYMMDD'),
			serviceEndDt: serviceEndDate && dayjs(serviceEndDate).format('YYYYMMDD'),
			managerId: currentFilter?.managerFilter?.map((item) => item?.value).join(','),
			serviceTypeCd: currentFilter?.serviceFilter?.length
				? currentFilter?.serviceFilter?.map((item) => item?.value).join(',')
				: serviceFilter?.options?.map((item) => item.value).join(','),
			recordingSheetSendYn:
				currentFilter?.sendStatusFilter?.length === 1
					? currentFilter?.sendStatusFilter?.[0].value
					: undefined,
			keyword,
		},
		RecordingSheetAdapter(myAccountInfo),
	);

	const scheduleRecordingData = useMemo(
		() => recordingSheetsData?.scheduleRecordings ?? [],
		[recordingSheetsData],
	);

	const handleSearchValue = (value: string) => setSearchValue(value);
	const handleSearch = () => {
		setPageInfo((prev) => ({
			...prev,
			page: 1,
		}));
		setKeyword(searchValue);
	};
	const handleChangePageInfo = (pageInfo: PageInfo) => {
		setPageInfo(pageInfo);
	};

	const handleInitCheckIds = () => {
		setCheckIds([]);
	};

	const handleRefresh = () => {
		refetch();
	};

	const onSubmit = (data: ESignRequestForm, items: any) => {
		if (items?.length && serviceStartDate && serviceEndDate) {
			hideDialog();
			showDialog(() => (
				<SendRecordingSheetDialog
					serviceStartDate={serviceStartDate}
					serviceEndDate={serviceEndDate}
					items={items}
					initCheckIds={handleInitCheckIds}
					onRefresh={handleRefresh}
					eSignWayData={data}
				/>
			));
		}
	};

	const handleClickSendRecordingSheet = () => {
		const items = scheduleRecordingData.filter((item) => checkIds.includes(item.id));

		if (items.length && serviceStartDate && serviceEndDate) {
			showDialog(() => <ESignWayDialog onSubmit={onSubmit} item={items} />);
		}
	};

	const rangeFilter = useMemo(
		() =>
			({
				key: 'rangeFilter',
				type: 'single_range_calendar',
				placeholder: '기록지 일자',
				// disabled: true,
				value: [
					{
						label: 'rangeFilter',
						value: {
							start: dayjs(date).startOf('M').toDate(),
							end: dayjs(date).endOf('M').toDate(),
						},
					},
				],
			}) as Filter<CheckOption>,
		[date],
	);

	const sendStatusFilter = useMemo(
		() =>
			({
				key: 'sendStatusFilter',
				type: 'multi',
				placeholder: '발송상태',
				options: [
					{
						label: '미발송',
						value: false,
					},
					{
						label: '발송',
						value: true,
					},
				],
			}) as Filter<CheckOption>,
		[commonCodes],
	);

	const managerFilter = useMemo(
		() =>
			({
				key: 'managerFilter',
				type: 'multi',
				options:
					centerManagers?.map((item) => ({
						label: item.userNm,
						value: item.memberAccountId,
					})) ?? [],
				placeholder: '사회복지사',
			}) as Filter<CheckOption>,
		[centerManagers],
	);

	const renderEmpty = useMemo(
		() => (
			<S.EmptyContainer>
				<S.EmptyTitle>태그 정보가 없습니다.</S.EmptyTitle>
				<S.EmptyDescription>
					해당 월 태그가 아직 동기화되지 않았습니다.
					<br />
					태그 오류 관리에서 동기화 버튼을 눌러주세요.
				</S.EmptyDescription>
			</S.EmptyContainer>
		),
		[],
	);

	const handleToggleCheckAll = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			setCheckIds(scheduleRecordingData?.filter((item) => item.sendUseYn)?.map((item) => item.id));
		} else {
			setCheckIds([]);
		}
	};

	const handleChangCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
		const matchedId = checkIds.includes(e.target.id);
		if (matchedId) {
			setCheckIds(checkIds.filter((checkedId) => checkedId !== e.target.id));
		} else {
			setCheckIds([...checkIds, e.target.id]);
		}
	};

	const renderSendButton = () => {
		if (!checkIds.length) return undefined;
		return (
			<S.SendButtonContainer>
				<CRButton.Default
					onClick={handleClickSendRecordingSheet}
					palette='gray'
					size='small'
					style={{
						padding: '0 1.2rem',
					}}>
					발송
				</CRButton.Default>
				<S.SendButtonText>({checkIds.length}건 선택됨)</S.SendButtonText>
				<S.Divider />
			</S.SendButtonContainer>
		);
	};

	const handleChangeYearMonth = (date: Date) => {
		setDate(date);
		setPageInfo((prev) => ({
			...prev,
			page: 1,
		}));
		setCurrentFilter({
			...currentFilter,
			rangeFilter: [
				{
					label: 'rangeFilter',
					value: {
						start: dayjs(date).startOf('M').toDate(),
						end: dayjs(date).endOf('M').toDate(),
					},
				},
			],
		});
	};

	useEffect(() => {
		if (recordingSheetsData?.totCount !== undefined) {
			setPageInfo((prev) => ({
				...prev,
				totalPages: recordingSheetsData?.totCount
					? Math.ceil(recordingSheetsData.totCount / prev.size)
					: 0,
			}));
		}
	}, [recordingSheetsData?.totCount]);

	// 월 변경시 체크항목 초기화 및 필터 변경시 기록지 일자 항상 적용되도록 default값 설정
	useLayoutEffect(() => {
		setCheckIds([]);
		if (!currentFilter.rangeFilter?.[0].value) {
			setCurrentFilter({
				...currentFilter,
				rangeFilter: [
					{
						label: 'rangeFilter',
						value: {
							start: dayjs(date).startOf('M').toDate(),
							end: dayjs(date).endOf('M').toDate(),
						},
					},
				],
			});
		}
	}, [currentFilter, date]);

	// 사회복지사 기본필터를 자신으로 설정
	useLayoutEffect(() => {
		if (myAccountInfo && myAccountInfo?.positionCd === 'CMN103.10') {
			setCurrentFilter((prev) => {
				const alreadyManagerExist = Boolean(
					prev?.managerFilter?.find((manager) => manager.value === myAccountInfo.memberAccountId),
				);
				if (alreadyManagerExist) {
					return prev;
				}
				return {
					...prev,
					managerFilter: [
						...(prev?.managerFilter || []),
						{ value: myAccountInfo.memberAccountId, label: myAccountInfo.userNm },
					],
				};
			});
		}
	}, [myAccountInfo?.memberAccountId]);

	return (
		<CRTable.BackBlind>
			<S.HeaderContainer>
				<CRTableMonthSelector value={date} onChange={handleChangeYearMonth} />
				<S.SyncTime>
					{lastSyncData?.uploadStartDate
						? `${dayjs(lastSyncData?.uploadStartDate).format('YYYY.MM.DD HH:mm')} 동기화`
						: ''}
					{}
				</S.SyncTime>
			</S.HeaderContainer>

			<S.TableContainer>
				<CRTableHeader
					onChangePageInfo={handleChangePageInfo}
					pageInfo={pageInfo}
					currentFilter={currentFilter}
					stickyMode
					filters={[rangeFilter, serviceFilter, sendStatusFilter, managerFilter]}
					searchValue={searchValue}
					placeholder='수급자명으로 검색'
					onChangeSearchValue={handleSearchValue}
					setCurrentFilter={setCurrentFilter}
					onSearch={handleSearch}
					showRefresh
					showViewCount
					onRefresh={handleRefresh}
					renderCustomFilter={renderSendButton()}
				/>
				<S.TableScrollContainer>
					{recordingSheetLoading ? (
						<CRSpinner />
					) : recordingSheetsData && scheduleRecordingData.length > 0 ? (
						<RecordingSheetTable
							initCheckIds={handleInitCheckIds}
							serviceStartDate={serviceStartDate}
							serviceEndDate={serviceEndDate}
							checkIds={checkIds}
							handleToggleCheckAll={handleToggleCheckAll}
							handleChangCheckBox={handleChangCheckBox}
							items={scheduleRecordingData}
							onRefresh={handleRefresh}
						/>
					) : (
						renderEmpty
					)}
				</S.TableScrollContainer>
			</S.TableContainer>
		</CRTable.BackBlind>
	);
}
