import { endpoint } from 'lib/service/Api/endpoint';

import useCRQuery from '../base/useCRQuery';

export const useUserAccountRoles = useCRQuery(
	endpoint.getMyCenterAccountRoles.key,
	'getMyCenterAccountRoles',
);

export const useUserAccounts = useCRQuery(endpoint.getMyCenterAccounts.key, 'getMyCenterAccounts', {
	enabledKey: ['centerId'],
});
