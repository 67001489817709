import React, { CSSProperties, ReactElement, useCallback } from 'react';

import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';

import * as S from './styles';

interface Props<T extends object, U extends keyof T> {
	searchValue?: string;
	searchKey?: (U | '')[];
	toolTipKey?: (U | '')[];
	item: T;
	renderKeys?: (U | '')[];
	stopPropagationKeys?: (U | '')[];
	isFocused?: boolean;
	customRender?: {
		[k in keyof T]?: (value: T[k], item?: T) => React.ReactNode;
	};
	customStyle?: {
		[k in keyof T]?: React.CSSProperties;
	};
	mergeConfig?: {
		[k in keyof T]?: {
			colSpan?: number;
			rowSpan?: number;
		};
	};
	onClick?: (item: T) => void;
	style?: CSSProperties;
	borderBottom?: string;
}

function CRRow<T extends object, U extends keyof T>({
	searchValue = '',
	searchKey = [],
	toolTipKey = [],
	item,
	renderKeys = [],
	stopPropagationKeys = [],
	isFocused = false,
	customRender = {},
	customStyle = {},
	borderBottom,
	style,
	mergeConfig = {},
	onClick,
}: Props<T, U>): React.ReactElement {
	const renderContent = useCallback(
		(renderKey: U | '') => {
			const commonStyles = { borderBottom, ...customStyle?.[renderKey] } as React.CSSProperties;
			const text = item[renderKey as U] as unknown as ReactElement;

			const searchHighlighter = (content?: any) => {
				if (!content) {
					return content;
				}
				if (typeof content !== 'string' && typeof content !== 'number') {
					return content;
				}

				if (searchValue && searchKey?.find((key) => key === renderKey)) {
					const startIndex = String(content)?.toLowerCase().indexOf(String(searchValue));
					if (startIndex !== -1) {
						const endIndex = startIndex + String(searchValue).length;
						return (
							<>
								{String(content).substring(0, startIndex)}
								<S.HighlightText style={{ color: 'red' }}>
									{String(content).substring(startIndex, endIndex)}
								</S.HighlightText>
								{String(content).substring(endIndex)}
							</>
						);
					}
				}
				return content;
			};

			const showToolTip = toolTipKey?.find((key) => key === renderKey);
			const isStopPropagation = stopPropagationKeys.find((key) => key === renderKey);

			if (customRender[renderKey as U]) {
				return (
					<S.TableBodyColumnWithPadding
						onClick={(e) => {
							if (isStopPropagation) e.stopPropagation();
						}}
						key={renderKey.toString()}
						style={commonStyles}
						colSpan={mergeConfig[renderKey]?.colSpan}
						rowSpan={mergeConfig[renderKey]?.rowSpan}>
						{showToolTip ? (
							<RDTooltip
								content={searchHighlighter(
									customRender[renderKey as U]?.(item[renderKey as U], item),
								)}>
								{searchHighlighter(customRender[renderKey as U]?.(item[renderKey as U], item))}
							</RDTooltip>
						) : (
							searchHighlighter(customRender[renderKey as U]?.(item[renderKey as U], item))
						)}
					</S.TableBodyColumnWithPadding>
				);
			}

			return (
				<S.TableBodyColumnWithPadding
					onClick={(e) => {
						if (isStopPropagation) e.stopPropagation();
					}}
					key={renderKey.toString()}
					style={commonStyles}
					colSpan={mergeConfig[renderKey]?.colSpan}
					rowSpan={mergeConfig[renderKey]?.rowSpan}>
					{showToolTip ? (
						<RDTooltip content={searchHighlighter(text)}>{searchHighlighter(text)}</RDTooltip>
					) : (
						searchHighlighter(text)
					)}
				</S.TableBodyColumnWithPadding>
			);
		},
		[searchValue, customRender],
	);

	return (
		<S.TableBodyRow style={style} $isFocused={isFocused} onClick={() => onClick?.(item)}>
			{renderKeys.map(renderContent)}
		</S.TableBodyRow>
	);
}

export default React.memo(CRRow) as typeof CRRow;
