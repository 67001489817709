import React, { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRTable from 'components/base/CRTable';
import CRTableHeader from 'components/base/CRTableHeader';
import { CheckOption } from 'components/base/Selections/type';
import AnnualTODetailDialog from 'components/domain/dialog/AnnualTODetailDialog';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useAnnualTOHistories, useCommonCodes, useMyAccountInfo } from 'lib/hook/react-query';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import { AnnualTOHistoryDTO } from 'types/dto/schedule';
import { PageInfo } from 'types/view/base';
import { Filter } from 'types/view/filter';

import { displayTOTime } from '../../../../lib';
import { ANNUAL_TO_MANAGE_HEADER_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	date: Date;
	onChangeFilter?: (data: { keyword: string; dutyFilter: CheckOption[] }) => void;
}

function AnnualTOManageTable({ date, onChangeFilter }: Props): React.ReactElement {
	const [keyword, setKeyword] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const [pageInfo, setPageInfo] = useState<PageInfo>({
		page: 1,
		size: 50,
		totalPages: 0,
	});
	const [currentFilter, setCurrentFilter] = useState<{
		dutyFilter: CheckOption[];
	}>({
		dutyFilter: [],
	});
	const { showDialog } = useGlobalLayout();

	const { data: myAccountInfo } = useMyAccountInfo();

	const {
		data = {
			totCnt: 0,
			annualMngtHists: [],
		},
		refetch,
	} = useAnnualTOHistories({
		centerId: myAccountInfo?.centerId ?? 0,
		basDt: dayjs(date).format('YYYYMMDD'),
		page: pageInfo.page,
		size: pageInfo.size,
		dutyCds: currentFilter.dutyFilter.map((e) => e.value),
		keyword,
	});

	const {
		data: commonCodes = {
			CMN070: [],
		},
	} = useCommonCodes({ comCdGroupNms: ['CMN070'] }, commonCodeAdapter);

	const filters = useMemo(
		() =>
			[
				{
					key: 'dutyFilter',
					type: 'multi',
					placeholder: '직무',
					options: commonCodes.CMN070?.filter(
						(e) => e.data?.comCdGroupNm === 'CMN070' && e?.data?.etcDesc1 === '1',
					),
				},
			] as Filter<{
				label: string;
				value: any;
			}>[],
		[commonCodes.CMN070],
	);

	const handleClickAnnualTODetail = (item?: AnnualTOHistoryDTO) => () => {
		if (!item) return;
		showDialog(({ hideDialog }) => <AnnualTODetailDialog item={item} hideDialog={hideDialog} />);
	};

	const ButtonComponent = useCallback(
		(employeeId: number, item?: AnnualTOHistoryDTO) => (
			<S.ButtonContainer>
				<CRButton.Default
					size='xSmall'
					palette='gray'
					type='outlined'
					onClick={handleClickAnnualTODetail(item)}>
					연차 이력
				</CRButton.Default>
			</S.ButtonContainer>
		),
		[],
	);

	const renderDate = (val: string) => (val ? dayjs(val).format('YYYY.MM.DD') : '-');

	const handleSearchValue = (value: string) => setSearchValue(value);

	const handleSearch = () => {
		setKeyword(searchValue);
		setPageInfo({ ...pageInfo, page: 1 });
	};

	// useEffect(() => {
	// 	onChangeFilter?.({ dutyFilter: currentFilter.dutyFilter, keyword });
	// }, [currentFilter.dutyFilter, keyword]);

	useEffect(() => {
		setPageInfo((prev) => ({
			...prev,
			totalPages: Math.ceil((data?.totCnt ?? 0) / prev.size),
		}));
	}, [data]);

	return (
		<S.Container>
			<CRTableHeader
				currentFilter={currentFilter}
				setCurrentFilter={setCurrentFilter}
				stickyMode
				filters={filters}
				searchValue={searchValue}
				pageInfo={pageInfo}
				onChangeSearchValue={handleSearchValue}
				onSearch={handleSearch}
				onChangePageInfo={(value) => setPageInfo(value)}
				showRefresh
				placeholder='직원명'
				showViewCount
				onRefresh={refetch}
			/>
			<S.TableScrollContainer>
				<CRTable.Root>
					<CRTable.Head heads={ANNUAL_TO_MANAGE_HEADER_CONFIG} offset={-47} />
					<CRTable.Body>
						{data?.annualMngtHists.map((item, index) => (
							<CRTable.Row
								key={item.employeeId}
								item={{ ...item, index: index + 1, ...item.annualStates[0] }}
								renderKeys={[
									'index',
									'korMemberNm',
									'birthDt',
									'dutyNm',
									'genderNm',
									'joinDate',
									'resignDate',
									'workYearCntNm',
									'annualAdjustDayCnt',
									'annualUseDayCnt',
									'annualDeductDayCnt',
									'annualRemainDayCnt',
									'employeeId',
								]}
								customStyle={{
									index: {
										textAlign: 'right',
									},
								}}
								customRender={{
									joinDate: renderDate,
									resignDate: renderDate,
									birthDt: renderDate,
									employeeId: ButtonComponent,
									annualAdjustDayCnt: (val) => (val ? displayTOTime(val) : '-'),
									annualUseDayCnt: (val) => (val ? displayTOTime(val) : '-'),
									annualDeductDayCnt: (val) => (val ? displayTOTime(-val) : '-'),
									annualRemainDayCnt: (val) => (val ? displayTOTime(val) : '-'),
								}}
							/>
						))}
					</CRTable.Body>
				</CRTable.Root>
			</S.TableScrollContainer>
		</S.Container>
	);
}

export default AnnualTOManageTable;
