import React from 'react';
import { Controller } from 'react-hook-form';

import Colors from 'common/colors';
import CRInput from 'components/base/CRInput';
import { CRText } from 'components/base/CRText';
import useWorkExecuteInspectionLog from 'lib/hook/view/workExecuteInspectionLog/useWorkExecuteInspectionLog';

import * as S from './styles';

interface Props {
	disabled: boolean;
}

function EDocWorkExecuteLogRecognitiveActivityProgramForm({ disabled }: Props): React.ReactElement {
	const { form } = useWorkExecuteInspectionLog();

	return (
		<S.Container>
			<S.TableTitle>
				<CRText text='인지자극' color='gray00' typography='bodyB' />
			</S.TableTitle>
			<CRText text='필요내용' color='gray60' typography='label' />
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInput.TextArea
						disabled={disabled}
						onChange={onChange}
						value={value}
						fixedHeight
						height='14.4rem'
						maxLength={1000}
						placeholder='필요내용 입력'
					/>
				)}
				name='recogStimulationNeedDesc'
				control={form?.control}
			/>
			<CRText text='제공방법' color='gray60' typography='label' />
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInput.TextArea
						disabled={disabled}
						onChange={onChange}
						value={value}
						fixedHeight
						height='14.4rem'
						maxLength={1000}
						placeholder='제공방법 입력'
					/>
				)}
				name='recogStimulationProvisionMethodDesc'
				control={form?.control}
			/>
			<S.TableTitle>
				<CRText text='신체능력 잔존유지' color='gray00' typography='bodyB' />
			</S.TableTitle>
			<CRText text='필요내용' color='gray60' typography='label' />
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInput.TextArea
						disabled={disabled}
						onChange={onChange}
						value={value}
						fixedHeight
						height='14.4rem'
						maxLength={1000}
						placeholder='필요내용 입력'
					/>
				)}
				name='physicalAbilityNeedDesc'
				control={form?.control}
			/>
			<CRText text='제공방법' color='gray60' typography='label' />
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInput.TextArea
						disabled={disabled}
						onChange={onChange}
						value={value}
						fixedHeight
						height='14.4rem'
						maxLength={1000}
						placeholder='제공방법 입력'
					/>
				)}
				name='physicalAbilityProvisionMethodDesc'
				control={form?.control}
			/>
		</S.Container>
	);
}

export default React.memo(EDocWorkExecuteLogRecognitiveActivityProgramForm);
