import React from 'react';

import dayjs from 'dayjs';

import Assets from 'assets';
import Colors from 'common/colors';
import Radius from 'common/radius';
import CRAvatar from 'components/base/CRAvatar';
import CRButton from 'components/base/CRButton';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import CRSwitch from 'components/base/Selections/CRSwitch';
import { ChangeAccountInfoDialog } from 'components/domain/dialog/ChangeAccountInfoDialog';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import { LinkAccountDialog } from 'components/domain/dialog/LinkAccountDialog';
import { displayPhoneNumber } from 'lib';
import { useMyAccountInfo } from 'lib/hook/react-query';
import {
	useActivateAccount,
	useDeActivateAccount,
	useGetLinkAccounts,
	useLinkAccounts,
} from 'lib/hook/react-query/mutation/userAccount';
import useDialog from 'lib/hook/util/useDialog';
import { ResponseCode } from 'types/api';
import { MyCenterAccountContentDTO } from 'types/api/userAccount';

import * as S from './styles';

interface Props {
	items: MyCenterAccountContentDTO[];
	isCenterChief: boolean;
	refetch: () => void;
}

export default function UserAccountTable({ items, refetch, isCenterChief }: Props) {
	const { showDialog, hideDialog } = useDialog();
	const getkAccountsListMutation = useGetLinkAccounts();
	const linkAccountsMutation = useLinkAccounts((client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			refetch();
			Toast.success('직원 정보가 연동되었습니다.');
			hideDialog();
		} else {
			Toast.error('직원 정보 연동에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
	});
	const { data: myAccountInfo } = useMyAccountInfo();
	const { mutate: activateAccount } = useActivateAccount((client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			Toast.success('로그인 허용을 완료하였습니다.');
			refetch();
			hideDialog();
		} else {
			Toast.error('로그인 허용에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
	});
	const { mutate: deactivateAccount } = useDeActivateAccount((client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			Toast.success('로그인 제한을 완료하였습니다.');
			refetch();
			hideDialog();
		} else {
			Toast.error('로그인 제한에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
	});

	const handleChangeLoginPermission = (nextValue: boolean, item: MyCenterAccountContentDTO) => {
		const title = `로그인 ${nextValue ? '허용' : '제한'}`;
		const content = `${item.userNm}(${item.userId})님의 ERP 로그인을 ${
			nextValue ? '허용' : '제한'
		}합니다.`;
		showDialog(() => (
			<DefaultDialog
				hideCloseButton
				title={title}
				content={content}
				cancelOption={{
					text: '취소',
					callback: hideDialog,
				}}
				successOption={{
					text: nextValue ? '허용' : '제한',
					type: nextValue ? 'filled' : 'outlined',
					successCallback: () => {
						if (nextValue) {
							activateAccount({
								userId: item.userId,
							});
						} else {
							deactivateAccount({
								userId: item.userId,
							});
						}
					},
				}}
				hideDialog={hideDialog}
			/>
		));
	};

	const handleClickLinkAccount = async (item?: MyCenterAccountContentDTO) => {
		if (!item || !myAccountInfo?.centerId) return;
		if (!item.birthDt) {
			Toast.error('직원의 생일정보가 존재하지 않습니다.');
			return;
		}
		const accounts = await getkAccountsListMutation.mutateAsync({
			centerId: myAccountInfo.centerId,
			birthDt: item.birthDt,
			userNm: item.userNm,
		});
		if (!accounts?.length) {
			Toast.error('아직 해당 정보가 생성되지 않았습니다.');
		} else if (accounts?.length === 1) {
			showDialog(() => (
				<DefaultDialog
					hideCloseButton
					title='직원 정보와 ERP 아이디를 연동하시겠습니까?'
					content={`${accounts[0].userNm}(${accounts[0].birthDt})님의 직원 정보와 ERP 아이디를 연동합니다.`}
					hideDialog={hideDialog}
					cancelOption={{
						text: '취소',
						callback: () => {
							hideDialog();
						},
					}}
					successOption={{
						text: '연동',
						successCallback: () => {
							linkAccountsMutation.mutate({
								memberId: accounts[0].memberId,
								memberAccountId: item.memberAccountId,
							});
						},
					}}
				/>
			));
		} else {
			showDialog(() => (
				<LinkAccountDialog
					memberAccountId={item.memberAccountId}
					data={accounts}
					refetch={refetch}
				/>
			));
		}
	};

	const handleClickUpdateAccountInfo = async (item?: MyCenterAccountContentDTO) => {
		if (!item) return;
		showDialog(() => <ChangeAccountInfoDialog data={item} refetch={refetch} />);
	};

	const renderAccountLink = (item?: MyCenterAccountContentDTO) =>
		item?.memberId ? (
			<S.Icon src={Assets.icon.check} />
		) : (
			<CRButton.Default
				style={{
					borderRadius: Radius.radius02,
				}}
				size='xSmall'
				palette='gray'
				type='outlined'
				onClick={() => handleClickLinkAccount(item)}>
				연동하기
			</CRButton.Default>
		);

	const renderUpdateAccountInfo = (item?: MyCenterAccountContentDTO) => (
		<CRButton.Default
			style={{
				borderRadius: Radius.radius02,
			}}
			size='xSmall'
			palette='gray'
			type='outlined'
			onClick={() => handleClickUpdateAccountInfo(item)}>
			수정하기
		</CRButton.Default>
	);

	return (
		<S.Container>
			<S.TableRow
				style={{
					position: 'sticky',
					top: 0,
					zIndex: 1,
				}}>
				<S.TableHeaderColumn
					style={{
						width: '7.2rem',
					}}
				/>
				<S.TableHeaderColumn
					style={{
						width: '16rem',
					}}>
					이름
				</S.TableHeaderColumn>
				{isCenterChief && (
					<S.TableHeaderColumn
						style={{
							width: '14.4rem',
						}}>
						생년월일
					</S.TableHeaderColumn>
				)}

				<S.TableHeaderColumn
					style={{
						width: '14.4rem',
					}}>
					직책
				</S.TableHeaderColumn>
				<S.TableHeaderColumn
					style={{
						width: '16rem',
					}}>
					전화번호 (업무용)
				</S.TableHeaderColumn>
				<S.TableHeaderColumn
					style={{
						width: '16rem',
					}}>
					전화번호 (개인용)
				</S.TableHeaderColumn>
				{isCenterChief && (
					<>
						<S.TableHeaderColumn
							style={{
								width: '16rem',
							}}>
							직원 정보 연동
						</S.TableHeaderColumn>
						<S.TableHeaderColumn
							style={{
								width: '16rem',
							}}>
							직원 정보 수정
						</S.TableHeaderColumn>
					</>
				)}

				<S.TableHeaderColumn
					style={{
						flex: 1,
					}}
				/>
				{isCenterChief && (
					<>
						<S.TableHeaderColumn
							style={{
								justifyContent: 'flex-end',
								width: '20rem',
							}}>
							ERP 아이디
						</S.TableHeaderColumn>
						<S.TableHeaderColumn
							style={{
								justifyContent: 'flex-end',
								width: '20rem',
							}}>
							가입일시
						</S.TableHeaderColumn>
						<S.TableHeaderColumn
							style={{
								justifyContent: 'flex-end',
								width: '12rem',
							}}>
							로그인 허용
						</S.TableHeaderColumn>
					</>
				)}
			</S.TableRow>
			{items?.map((item) => (
				<S.TableRow>
					<S.TableBodyColumn
						style={{
							width: '7.2rem',
							justifyContent: 'center',
						}}>
						<CRAvatar.Default size='small' />
					</S.TableBodyColumn>
					<S.TableBodyColumn
						style={{
							width: '16rem',
							color: item.accountDisabledYn ? Colors.gray60 : Colors.gray10,
						}}>
						<CRText
							typography='labelB'
							text={item?.userNm || '-'}
							color={item.accountDisabledYn ? 'gray60' : 'gray10'}
						/>
					</S.TableBodyColumn>
					{isCenterChief && (
						<S.TableBodyColumn
							style={{
								width: '14.4rem',
								color: item.accountDisabledYn ? Colors.gray60 : Colors.gray10,
							}}>
							{item?.birthDt ? dayjs(item.birthDt).format('YYYY.MM.DD') : '-'}
						</S.TableBodyColumn>
					)}
					<S.TableBodyColumn
						style={{
							width: '14.4rem',
							color: item.accountDisabledYn ? Colors.gray60 : Colors.gray10,
						}}>
						{item.roleDesc || '-'}
					</S.TableBodyColumn>
					<S.TableBodyColumn
						style={{
							width: '16rem',
							color: item.accountDisabledYn ? Colors.gray60 : Colors.gray10,
						}}>
						{item?.workMobilePhoneNo ? displayPhoneNumber(item.workMobilePhoneNo) : '-'}
					</S.TableBodyColumn>
					<S.TableBodyColumn
						style={{
							width: '16rem',
							color: item.accountDisabledYn ? Colors.gray60 : Colors.gray10,
						}}>
						{item?.mobilePhoneNo ? displayPhoneNumber(item.mobilePhoneNo) : '-'}
					</S.TableBodyColumn>
					{isCenterChief && (
						<>
							<S.TableBodyColumn
								style={{
									width: '16rem',
									color: item.accountDisabledYn ? Colors.gray60 : Colors.gray10,
								}}>
								{renderAccountLink(item)}
							</S.TableBodyColumn>
							<S.TableBodyColumn
								style={{
									width: '16rem',
									color: item.accountDisabledYn ? Colors.gray60 : Colors.gray10,
								}}>
								{renderUpdateAccountInfo(item)}
							</S.TableBodyColumn>
						</>
					)}
					<S.TableBodyColumn
						style={{
							flex: 1,
						}}
					/>
					{isCenterChief && (
						<>
							<S.TableBodyColumn
								style={{
									justifyContent: 'flex-end',
									width: '20rem',
									color: item.accountDisabledYn ? Colors.gray60 : Colors.gray10,
								}}>
								{item?.userId || '-'}
							</S.TableBodyColumn>
							<S.TableBodyColumn
								style={{
									justifyContent: 'flex-end',
									width: '20rem',
									color: Colors.gray60,
								}}>
								{item?.createdAt ? dayjs(item.createdAt).format('YYYY.MM.DD HH:mm') : '-'}
							</S.TableBodyColumn>
							<S.TableBodyColumn
								style={{
									width: '12rem',
									justifyContent: 'flex-end',
								}}>
								<CRSwitch
									onChange={(nextValue) => handleChangeLoginPermission(nextValue, item)}
									value={!item.accountDisabledYn}
									type='icon'
								/>
							</S.TableBodyColumn>
						</>
					)}
				</S.TableRow>
			))}
		</S.Container>
	);
}
