import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 2.4rem;
`;

export const ValueContainer = styled.div<{ $error: boolean }>`
	display: flex;
	align-items: center;

	color: ${(props) => (props.$error ? props.theme.colors.primary60 : props.theme.colors.gray10)};
`;
