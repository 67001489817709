import React, { ChangeEvent, useCallback } from 'react';

import dayjs from 'dayjs';

import Colors from 'common/colors';
import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import { CRText } from 'components/base/CRText';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import { CheckOption } from 'components/base/Selections/type';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import { displayPadTime } from 'lib';
import { MonitoringCheckMode } from 'pages/dashboard/SchedulePage/MonitoringDetailPage';
import { MonitoringDetailsDTO } from 'types/api';

import { ServiceTypeCd } from '../RecordingSheetTable/serviceEdocParam';
import {
	MONITORING_BATH_HEADER_CONFIG,
	MONITORING_COMPLETE_STATUS_CONFIG,
	MONITORING_HEADER_CONFIG,
	PLAN_COMPLETE_STATUS_CONFIG,
} from './constant';
import * as S from './styles';

export enum SpecialDescType {
	상태변화기록지 = 'STATE_CHANGE_RECORD',
	휴일 = 'SPECIAL_HOURS',
	인지활동 = 'COGNITIVE_PROGRAM',
	목욕2회이상 = 'BIWEEKLY_BATH',
}

export enum WorkDuplicateType {
	타센터근무중복 = 'OTHER_CENTER_DUPLICATION',
	타직장근무중복 = 'OTHER_WORK_DUPLICATION',
	확인완료 = 'COMPLETE',
}

export enum TimeErrorCheckType {
	수가일치여부 = 'SERVICE_FEE_MATCH',
	일정계획오류여부 = 'SERVICE_SCHEDULE_PLAN_TIME',
	일반요양2시간간격 = 'CARE_INTERVAL_WORK_2HR',
	태그시간분배 = 'TAG_TIME_DISTRIBUTION',
	태그미겹침 = 'BATH_TAG_NON_OVERLAP',
}

export enum SpecificDesc {
	'120분미만제공사유' = 'RECOG_PLAN_TIME_UNDER_120MIN',
	'270분이상연속제공사유' = 'CARE_PLAN_TIME_OVER_270MIN',
	'210분이상연속제공사유' = 'CARE_PLAN_TIME_OVER_210MIN',
	'1일다횟수방문요양제공사유' = 'CARE_MULTIPLE_USE',
	'5등급일반요양제공사유' = 'VISIT_CARE_FIVE_GRADE',
	'주1회초과사유' = 'BATH_OVER_ONCE_WEEK',
	'작성완료' = 'COMPLETE',
}

interface Props {
	currentRow?: MonitoringDetailsDTO;
	items?: MonitoringDetailsDTO[];
	checkIds: string[];
	checkMode?: MonitoringCheckMode;
	onClickRow?: (task: MonitoringDetailsDTO) => void;
	handleToggleCheckAll: (e: ChangeEvent<HTMLInputElement>) => void;
	handleChangCheckBox: (e: ChangeEvent<HTMLInputElement>) => void;
}

function MonitoringDetailTable({
	currentRow,
	items = [],
	checkIds = [],
	checkMode,
	onClickRow,
	handleToggleCheckAll,
	handleChangCheckBox,
}: Props): React.ReactElement {
	const renderPlanCompleteStatus = useCallback((compleyeYn?: boolean) => {
		if (compleyeYn === undefined) return '';
		return (
			<CRStatus options={PLAN_COMPLETE_STATUS_CONFIG}>
				{compleyeYn === undefined ? '-' : compleyeYn ? '완료' : '미완료'}
			</CRStatus>
		);
	}, []);

	const renderMonitoringCompleteStatus = useCallback((scheduleMonitoringCompleteYn?: boolean) => {
		if (scheduleMonitoringCompleteYn === undefined) return '';
		return (
			<CRStatus options={MONITORING_COMPLETE_STATUS_CONFIG}>
				{scheduleMonitoringCompleteYn === undefined
					? '-'
					: scheduleMonitoringCompleteYn
						? '완료'
						: '대기'}
			</CRStatus>
		);
	}, []);

	const renderDate = useCallback(
		(value: string) => (
			<S.TextContainer>{value ? dayjs(value).format('YYYY.MM.DD') : '-'}</S.TextContainer>
		),
		[],
	);

	const renderPlanTime = useCallback((startTime: string, item?: MonitoringDetailsDTO) => {
		if (!item?.schedulePlanStartTime) return '-';
		return (
			<S.TextContainer>{`${displayPadTime(item.schedulePlanStartTime)}~${
				item?.schedulePlanEndTime ? displayPadTime(item.schedulePlanEndTime) : ''
			}`}</S.TextContainer>
		);
	}, []);

	const renderWorkTime = useCallback((startTime: string, item?: MonitoringDetailsDTO) => {
		if (!item?.workStartTime) return '-';
		return (
			<S.TextContainer>{`${displayPadTime(item.workStartTime)}~${
				item?.workEndTime ? displayPadTime(item.workEndTime) : ''
			}`}</S.TextContainer>
		);
	}, []);

	const renderRfidTime = useCallback((startTime: string, item?: MonitoringDetailsDTO) => {
		if (!item?.rfidStartTime) return '-';
		return (
			<S.TextContainer>{`${displayPadTime(item.rfidStartTime)}~${
				item?.rfidEndTime ? displayPadTime(item.rfidEndTime) : ''
			}`}</S.TextContainer>
		);
	}, []);

	const renderCheckBox = useCallback(
		(id: number, item?: MonitoringDetailsDTO) => {
			if (!checkMode || !item) return null;

			if (checkMode === MonitoringCheckMode.근무확인변경 && item.rfidStartTime) {
				return (
					item?.workConfirmYn === false && (
						<div
							role='presentation'
							onClick={(e) => e.stopPropagation()}
							style={{
								display: 'flex',
								alignItems: 'center',
								height: '3.3rem',
							}}>
							<CRCheckBox
								palette='gray'
								id={String(id)}
								checked={checkIds?.includes(String(id))}
								onChange={handleChangCheckBox}
							/>
						</div>
					)
				);
			}

			if (
				checkMode === MonitoringCheckMode.근무중복확인변경 &&
				item?.workDuplicationCheckNeeds &&
				item?.workDuplicationCheckNeeds?.length > 0 &&
				!item?.workDuplicationCheckNeeds.includes(WorkDuplicateType.확인완료)
			) {
				return (
					<div
						role='presentation'
						onClick={(e) => e.stopPropagation()}
						style={{
							display: 'flex',
							alignItems: 'center',
							height: '3.3rem',
						}}>
						<CRCheckBox
							palette='gray'
							id={String(id)}
							checked={checkIds?.includes(String(id))}
							onChange={handleChangCheckBox}
						/>
					</div>
				);
			}

			return null;
		},
		[checkIds, checkMode],
	);

	const renderWorkConfirmYn = useCallback(
		(value?: boolean) => (
			<CRText
				text={value === undefined ? '-' : value ? '확인 완료' : '미확인'}
				typography='label'
				color={value === undefined ? 'gray00' : value ? 'gray00' : 'primary60'}
			/>
		),
		[],
	);

	const renderChargeTypeNm = useCallback((value?: string) => value || '-', []);

	const rendeSpecialDesc = useCallback((value?: string[]) => {
		if (!value || value?.length < 1) return '-';
		const mappedText: CheckOption[] = [];
		value?.forEach((item) => {
			if (item === SpecialDescType.상태변화기록지) {
				mappedText.push({
					label: '상',
					value: '주1회 상태변화기록지 작성 여부',
				});
			} else if (item === SpecialDescType.인지활동) {
				mappedText.push({
					label: '인',
					value: '인지활동 특이사항 작성 여부 ',
				});
			} else if (item === SpecialDescType.휴일) {
				mappedText.push({
					label: '휴',
					value: '조조/야간/공휴일 이용 사유 작성 여부',
				});
			} else if (item === SpecialDescType.목욕2회이상) {
				mappedText.push({
					label: '목',
					value: '목욕주2회작성여부',
				});
			}
		});

		return (
			<S.TagTextContainer>
				{mappedText?.length > 0
					? mappedText.map((item) => (
							<RDTooltip side='top' content={item.value}>
								<S.TagText key={item.label}>{item.label}</S.TagText>
							</RDTooltip>
						))
					: '-'}
			</S.TagTextContainer>
		);
	}, []);

	const renderTimeErrCheckNeeds = useCallback((value?: string[], item?: MonitoringDetailsDTO) => {
		// if (!value || value?.length < 1) return '-';
		const mappedText: CheckOption[] = [];
		value?.forEach((item) => {
			if (item === TimeErrorCheckType.수가일치여부) {
				mappedText.push({
					label: '수',
					value: '수가 일치 여부',
				});
			} else if (item === TimeErrorCheckType.일정계획오류여부) {
				mappedText.push({
					label: '계',
					value: '일정 계획 오류',
				});
			} else if (item === TimeErrorCheckType.일반요양2시간간격) {
				mappedText.push({
					label: '일',
					value: '일반요양 2시간 간격',
				});
			} else if (item === TimeErrorCheckType.태그시간분배) {
				mappedText.push({
					label: '태',
					value: '태그 시간 분배',
				});
			} else if (item === TimeErrorCheckType.태그미겹침) {
				mappedText.push({
					label: '태',
					value: '태그 미겹침',
				});
			}
		});

		return (
			<S.TagTextContainer>
				{mappedText?.length > 0
					? mappedText.map((item) => (
							<RDTooltip side='top' content={item.value}>
								<S.TagText key={item.label}>{item.label}</S.TagText>
							</RDTooltip>
						))
					: '-'}
			</S.TagTextContainer>
		);
	}, []);

	const renderWorkDuplication = useCallback((value?: string[]) => {
		if (!value?.length) return '-';
		const mappedText: CheckOption[] = [];
		value?.forEach((item) => {
			if (item === WorkDuplicateType.타센터근무중복) {
				mappedText.push({
					label: '센',
					value: '타센터  중복',
				});
			} else if (item === WorkDuplicateType.타직장근무중복) {
				mappedText.push({
					label: '직',
					value: '타직장 중복',
				});
			}
		});

		return (
			<S.TagTextContainer>
				{mappedText?.length > 0
					? mappedText.map((item) => (
							<RDTooltip side='top' content={item.value}>
								<S.TagText key={item.label}>{item.label}</S.TagText>
							</RDTooltip>
						))
					: '확인 완료'}
			</S.TagTextContainer>
		);
	}, []);

	const renderSpecificDesc = useCallback((value?: string[]) => {
		if (!value?.length) return '-';
		const mappedText: CheckOption[] = [];
		value?.forEach((item) => {
			if (item === SpecificDesc['120분미만제공사유']) {
				mappedText.push({
					label: '120분',
					value: '120분 미만 제공 사유',
				});
			} else if (item === SpecificDesc['210분이상연속제공사유']) {
				mappedText.push({
					label: '210분',
					value: '210분이상 연속 제공사유',
				});
			} else if (item === SpecificDesc['270분이상연속제공사유']) {
				mappedText.push({
					label: '270분',
					value: '270분이상 연속 제공사유',
				});
			} else if (item === SpecificDesc['1일다횟수방문요양제공사유']) {
				mappedText.push({
					label: '다횟수',
					value: '1일 다횟수 방문요양 제공사유',
				});
			} else if (item === SpecificDesc['5등급일반요양제공사유']) {
				mappedText.push({
					label: '5등급',
					value: '5등급 일반요양 제공사유',
				});
			} else if (item === SpecificDesc.주1회초과사유) {
				mappedText.push({
					label: '주1회',
					value: '주1회 초과사유',
				});
			}
		});
		const isComplete = value.includes(SpecificDesc.작성완료);

		return (
			<S.TagTextContainer>
				{isComplete
					? '작성 완료'
					: mappedText?.length > 0
						? mappedText.map((item) => (
								<RDTooltip side='top' content={item.value}>
									<S.TagText key={item.label}>{item.label}</S.TagText>
								</RDTooltip>
							))
						: '-'}
			</S.TagTextContainer>
		);
	}, []);

	const renderAmountMatchYn = useCallback(
		(value?: boolean) => (
			<CRText
				text={value === undefined ? '-' : value ? '일치' : '불일치'}
				typography='label'
				color={value === undefined ? 'gray00' : value ? 'gray00' : 'primary60'}
			/>
		),
		[],
	);

	const renderTagTimeDivisionErrorYn = useCallback(
		(value?: boolean) => (
			<CRText
				text={value === undefined ? '-' : value ? '정상' : '오류'}
				typography='label'
				color={value === undefined ? 'gray00' : value ? 'gray00' : 'primary60'}
			/>
		),
		[],
	);

	const renderRfidProcessState = useCallback(
		(value?: string) => (
			<CRText
				text={value ?? '-'}
				typography='label'
				color={value === '미생성' ? 'primary60' : 'gray00'}
			/>
		),
		[],
	);

	const renderMemo = useCallback(
		(value?: string) =>
			value ? (
				<RDTooltip fitContent side='left' content={value}>
					<S.TextContainer
						style={{
							width: '26rem',
							overflow: 'hidden',
						}}>
						{value}
					</S.TextContainer>
				</RDTooltip>
			) : (
				'-'
			),
		[],
	);

	const serviceTypeCd = items?.[0].serviceTypeCd;

	const HEADER_CONFIG =
		serviceTypeCd === ServiceTypeCd.방문목욕
			? MONITORING_BATH_HEADER_CONFIG
			: MONITORING_HEADER_CONFIG;

	const checkActiveRow = (item: MonitoringDetailsDTO) => {
		if (!currentRow) return false;

		return item.scheduleMonitoringId === currentRow.scheduleMonitoringId;
	};

	return (
		<S.Container>
			<CRTable.Root
				style={{
					borderLeft: `0.1rem solid ${Colors.gray90}`,
					borderRight: `0.1rem solid ${Colors.gray90}`,
				}}>
				<CRTable.Head
					offset={1}
					checkIds={checkIds}
					onToggleCheck={handleToggleCheckAll}
					heads={[{ ...HEADER_CONFIG[0], checkbox: !!checkMode }, ...HEADER_CONFIG.slice(1)]}
				/>
				<CRTable.Body>
					{items.map((item, index) => (
						<CRTable.Row
							key={item.scheduleMonitoringId}
							item={{ ...item, index: index + 1 }}
							style={
								checkActiveRow(item)
									? {
											background: Colors.primary95,
										}
									: {}
							}
							renderKeys={
								serviceTypeCd === ServiceTypeCd.방문목욕
									? [
											'scheduleMonitoringId',
											'index',
											'schedulePlanCompleteYn',
											'scheduleMonitoringCompleteYn',
											'serviceDt',
											'schedulePlanStartTime',
											'employeeNm',
											'chargeTypeNm',
											'rfidStartTime',
											'workStartTime',
											'workConfirmYn',
											'specificDescCheckNeeds',
											'specialDescCheckNeeds',
											'timeErrCheckNeeds',
											'workDuplicationCheckNeeds',
											'rfidProcessState',
											'scheduleMonitoringMemo',
											'',
										]
									: [
											'scheduleMonitoringId',
											'index',
											'schedulePlanCompleteYn',
											'scheduleMonitoringCompleteYn',
											'serviceDt',
											'schedulePlanStartTime',
											'chargeTypeNm',
											'rfidStartTime',
											'workStartTime',
											'workConfirmYn',
											'specificDescCheckNeeds',
											'specialDescCheckNeeds',
											'timeErrCheckNeeds',
											'workDuplicationCheckNeeds',
											'rfidProcessState',
											'scheduleMonitoringMemo',
											'',
										]
							}
							customRender={{
								scheduleMonitoringId: renderCheckBox,
								schedulePlanCompleteYn: renderPlanCompleteStatus,
								scheduleMonitoringCompleteYn: renderMonitoringCompleteStatus,
								serviceDt: renderDate,
								schedulePlanStartTime: renderPlanTime,
								workStartTime: renderWorkTime,
								chargeTypeNm: renderChargeTypeNm,
								rfidStartTime: renderRfidTime,
								workConfirmYn: renderWorkConfirmYn,
								specialDescCheckNeeds: rendeSpecialDesc,
								workDuplicationCheckNeeds: renderWorkDuplication,
								serviceFeeMatchYn: renderAmountMatchYn,
								tagTimeDistributionYn: renderTagTimeDivisionErrorYn,
								scheduleMonitoringMemo: renderMemo,
								timeErrCheckNeeds: renderTimeErrCheckNeeds,
								rfidProcessState: renderRfidProcessState,
								specificDescCheckNeeds: renderSpecificDesc,
							}}
							customStyle={{
								scheduleMonitoringId: {
									display: 'flex',
									textAlign: 'center',
									justifyContent: 'center',
								},
								index: {
									textAlign: 'right',
									padding: '0.4rem 0.8rem',
								},
							}}
							onClick={onClickRow}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default React.memo(MonitoringDetailTable);
