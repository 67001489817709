import styled, { css } from 'styled-components';

import { ColorObjType, TagPalette } from './type';

const COLOR_PALETTE: ColorObjType = {
	primary: css`
		background: ${(props) => props.theme.colors.primary60};
		color: ${(props) => props.theme.colors.gray100};
		& > img {
			filter: brightness(0) saturate(100%) invert(90%) sepia(100%) saturate(0%) hue-rotate(229deg)
				brightness(106%) contrast(101%);
		}
	`,
	secondary: css`
		background: ${(props) => props.theme.colors.primary90};
		color: ${(props) => props.theme.colors.primary10};
		& > img {
			filter: brightness(0) saturate(100%) invert(8%) sepia(65%) saturate(3666%) hue-rotate(332deg)
				brightness(70%) contrast(110%);
		}
	`,
	black: css`
		background: ${(props) => props.theme.colors.gray10};
		color: ${(props) => props.theme.colors.gray100};
		& > img {
			filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg)
				brightness(109%) contrast(104%);
		}
	`,
	gray: css`
		background: ${(props) => props.theme.colors.gray90};
		color: ${(props) => props.theme.colors.gray10};
		& > img {
			filter: brightness(0) saturate(100%) invert(7%) sepia(7%) saturate(929%) hue-rotate(149deg)
				brightness(94%) contrast(90%);
		}
	`,
};

export const Container = styled.div<{ $palette: TagPalette }>`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.4rem;
	padding: 0 0.8rem;
	min-width: 2.4rem;
	width: fit-content;
	height: 2.4rem;
	border-radius: 100rem;
	user-select: none;
	cursor: pointer;

	${(props) => props.theme.typography.label};
	${(props) => COLOR_PALETTE[props.$palette]};
`;

export const CloseIcon = styled.img`
	width: 1.8rem;
	height: 1.8rem;
`;
