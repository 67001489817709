import styled from 'styled-components';

export const Container = styled.form`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;

export const Title = styled.h3`
	${(props) => props.theme.typography.h3};
	color: ${(props) => props.theme.colors.gray00};
	margin: 0 auto;
	padding-top: 4rem;
	padding-bottom: 1.6rem;
	width: 103.2rem;
	height: 9.2rem;
`;

export const ContentsContainer = styled.div`
	display: flex;
	gap: 1.6rem;
	overflow-y: auto;
	width: 103.2rem;
	margin: 0 auto;
	flex: 1;
`;

export const CheckBoxContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 2.4rem;
`;

export const CheckBoxRow = styled.div`
	display: flex;
	flex-direction: column;
`;

export const RequiredMark = styled.span`
	color: ${(props) => props.theme.colors.primary60};
`;

export const BottomSection = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	box-sizing: border-box;
	margin-top: 2.4rem;
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const BottomMenuContainer = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: flex-end;
	height: 7.2rem;
	padding: 0 2.4rem;
	width: 103.2rem;
	margin: 0 auto;
`;

export const BottomRightMenuContainer = styled.div`
	display: flex;
	gap: 0.8rem;
	align-items: center;
`;

export const ContentContainer = styled.div`
	display: flex;
	align-items: center;
	height: 5.8rem;
`;
