import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { Toast } from 'components/base/CRToast';
import { EmployeeEnroll } from 'lib';
import {
	useCenterManagers,
	useCreateRecipientHistoryOfManager,
	useMyAccountInfo,
} from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import { Recipient, RecipientManagerHistoryType } from 'types/view/recipient';

import CRDialog from '../../../base/CRDialog';
import * as S from './styles';

interface Props {
	currentRecipient?: Recipient;
	editItem?: RecipientManagerHistoryType;
	onClickClose?: () => void;
}

export type RecipientAddHistoryOfManagerForm = {
	changeDate: Date;
	center: {
		label: string;
		value: string;
	};
	manager: {
		label: string;
		value: string;
	};
	consultantOpinion?: string;
	managerChangeReason: string;
};

function RecipientAddHistoryOfManagerDialog({ editItem, currentRecipient, onClickClose }: Props) {
	const { control, getValues, setValue, watch } = useForm<RecipientAddHistoryOfManagerForm>({
		resolver: yupResolver(EmployeeEnroll),
	});

	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: centerManagers } = useCenterManagers({
		centerId: myAccountInfo?.centerId,
	});
	const client = useQueryClient();
	const createRecipientHistoryOfManagerMutation = useCreateRecipientHistoryOfManager();

	const handleClickAdd = async () => {
		if (createRecipientHistoryOfManagerMutation.isLoading) return;

		const res = await createRecipientHistoryOfManagerMutation.mutateAsync({
			recipientManagerHistId: editItem ? Number.parseInt(editItem.id, 10) : undefined,
			recipientId: String(currentRecipient?.recipientId),
			managerChangeDate: dayjs(getValues('changeDate').toString()).format('YYYYMMDD'),
			centerId: Number.parseInt(getValues('center.value').toString(), 10),
			managerId: Number.parseInt(getValues('manager.value'), 10),
			consultOpinion: getValues('consultantOpinion') ?? '',
			managerChangeReason: getValues('managerChangeReason'),
		});

		if (res?.recipientId) {
			await client.invalidateQueries([
				endpoint.getRecipientBase.key,
				{ recipientId: currentRecipient?.recipientId },
			]);
			await client.invalidateQueries([
				endpoint.getRecipientHistoryOfManager.key,
				{
					recipientId: (currentRecipient?.recipientId ?? 0).toString(),
				},
			]);
			Toast.success('사회복지사 이력을 수정했습니다.');
			onClickClose?.();
		}
	};

	useEffect(() => {
		if (myAccountInfo) {
			setValue('center.label', myAccountInfo.centerNm);
			setValue('center.value', myAccountInfo.centerId.toString());
		}
	}, [myAccountInfo]);

	useEffect(() => {
		if (editItem) {
			setValue('changeDate', dayjs(editItem.date).toDate());
			setValue('center', { label: editItem.center.name, value: editItem.center.id });
			setValue('manager.label', editItem.manager.name);
			setValue('manager.value', editItem.manager.id);
			setValue('consultantOpinion', editItem.consultantOpinion);
			setValue('managerChangeReason', editItem.changeReason);
		} else {
			setValue('center', {
				label: myAccountInfo?.centerNm || '',
				value: myAccountInfo?.centerId ? String(myAccountInfo?.centerId) : '',
			});
		}
	}, [editItem]);

	return (
		<CRDialog
			title='사회복지사 변경'
			body={
				<S.Content>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel label='변경일' isRequired>
								<CRInput.DatePicker
									onChangeValue={onChange}
									value={value}
									placeholder='변경일자 선택'
								/>
							</CRInputLabel>
						)}
						name='changeDate'
						control={control}
					/>
					<Controller
						render={({ field: { onChange }, formState: { errors } }) => (
							<CRInputLabel label='소속' isRequired>
								<CRInput.Selector
									disabled
									items={[
										{
											label: myAccountInfo?.centerNm ?? '',
											value: `${myAccountInfo?.centerId}`,
										},
									]}
									autoComplete
									onChangeValue={onChange}
									currentValue={getValues('center')}
									placeholder='소속 선택'
								/>
							</CRInputLabel>
						)}
						name='center'
						control={control}
					/>
					<Controller
						render={({ field: { onChange }, formState: { errors } }) => (
							<CRInputLabel label='사회복지사' isRequired>
								<CRInput.Selector
									items={centerManagers?.map((item) => ({
										label: item.userNm,
										value: item.memberAccountId,
									}))}
									autoComplete
									onChangeValue={onChange}
									currentValue={getValues('manager')}
									placeholder='사회복지사 선택'
								/>
							</CRInputLabel>
						)}
						name='manager'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<CRInputLabel label='상담자 의견'>
								<CRInput.TextArea
									ref={ref}
									placeholder='상담자 의견 입력'
									onChange={onChange}
									onBlur={onBlur}
									value={value}
								/>
							</CRInputLabel>
						)}
						name='consultantOpinion'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<CRInputLabel label='변경 사유' isRequired>
								<CRInput.TextArea
									ref={ref}
									placeholder='변경 사유 입력'
									onChange={onChange}
									onBlur={onBlur}
									value={value}
								/>
							</CRInputLabel>
						)}
						name='managerChangeReason'
						control={control}
					/>
				</S.Content>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={onClickClose}>
						취소
					</CRButton.Default>
					<CRButton.Default
						palette='primary'
						size='default'
						disabled={
							!watch('manager') ||
							!watch('center') ||
							!watch('changeDate') ||
							!watch('managerChangeReason')
						}
						onClick={handleClickAdd}>
						등록
					</CRButton.Default>
				</S.ButtonContainer>
			}
			onClickClose={onClickClose}
		/>
	);
}

export default RecipientAddHistoryOfManagerDialog;
