import React, { useCallback } from 'react';

import dayjs from 'dayjs';

import Colors from 'common/colors';
import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import { ScheduleTagAutomationHistoryDTO } from 'types/api/schedule';

import {
	TAG_AUTOMATION_HEADER_CONFIG,
	TAG_AUTOMATION_RESULT_CONFIG,
	TAG_ERROR_TYPE_STATUS_CONFIG,
	TAG_PLAN_EDIT_YN_STATUS_CONFIG,
} from './constant';
import * as S from './styles';

interface Props {
	items?: ScheduleTagAutomationHistoryDTO[];
	onRefresh?: () => void;
}

function TagAutomationTable({ items = [], onRefresh }: Props): React.ReactElement {
	const renderTagErrorTypeNm = useCallback(
		(value: string | number) => <CRStatus options={TAG_ERROR_TYPE_STATUS_CONFIG}>{value}</CRStatus>,
		[],
	);

	const renderPlanEditYn = useCallback(
		(planEditYn: boolean) => (
			<CRStatus options={TAG_PLAN_EDIT_YN_STATUS_CONFIG}>{planEditYn ? '수정' : '정상'}</CRStatus>
		),
		[],
	);

	const renderAutomationResult = useCallback(
		(automationResult: string) => (
			<CRStatus options={TAG_AUTOMATION_RESULT_CONFIG}>
				{automationResult === '자동화 완료' ? automationResult : '실패'}
			</CRStatus>
		),
		[],
	);

	const renderRfid = useCallback(
		(rfid: string) => (
			<S.TextContainer>
				{rfid}
				{rfid.endsWith('~') && <S.ErrorText>(-)</S.ErrorText>}
			</S.TextContainer>
		),
		[],
	);

	const renderDate = useCallback(
		(date: string) => (
			<S.TextContainer>
				{date && dayjs(date).isValid() ? dayjs(date).format('YYYY.MM.DD') : '-'}
			</S.TextContainer>
		),
		[],
	);

	return (
		<S.Container>
			<CRTable.Root
				style={{
					borderLeft: `0.1rem solid ${Colors.gray90}`,
					borderRight: `0.1rem solid ${Colors.gray90}`,
				}}>
				<CRTable.Head offset={1} heads={TAG_AUTOMATION_HEADER_CONFIG} />
				<CRTable.Body>
					{items.map((item, index) => (
						<CRTable.Row
							key={item.id}
							item={{
								...item,
								index: index + 1,
							}}
							renderKeys={[
								'index',
								'recipientNm',
								'longTermNo',
								'serviceTypeNm',
								'employeeNm',
								'serviceYmd',
								'planEditYn',
								'plan',
								'editPlan',
								'tagErrTypeNm',
								'tagErrReason',
								'automationResult',
								'rfid',
								'editRfid',
								'managetNm',
								'id',
							]}
							customRender={{
								planEditYn: renderPlanEditYn,
								tagErrTypeNm: renderTagErrorTypeNm,
								automationResult: renderAutomationResult,
								rfid: renderRfid,
								serviceYmd: renderDate,
								id: () => null,
							}}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default TagAutomationTable;
