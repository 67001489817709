import React, { CSSProperties, ReactNode, useMemo } from 'react';

import Colors from 'common/colors';
import Typography from 'common/typography';

import * as S from './styles';

type DisplayPropertyValue =
	| 'block'
	| 'inline'
	| 'inline-block'
	| 'none'
	| 'flex'
	| 'grid'
	| 'table'
	| 'inline-table'
	| 'table-row'
	| 'table-cell';

interface Prop {
	children?: ReactNode;
	text?: ReactNode;
	typography?: keyof typeof Typography;
	color?: keyof typeof Colors;
	margin?: string;
	padding?: string;
	display?: DisplayPropertyValue;
	style?: CSSProperties;
	highlightText?: string[];
}

export function CRText({
	children,
	text,
	typography = 'body',
	color = 'gray20',
	margin,
	padding,
	display,
	style = {},
	highlightText,
}: Prop) {
	const highlightedTexts = useMemo(() => {
		if (!highlightText || highlightText.length === 0) {
			return text ?? children;
		}

		const content = text ?? children;
		if (typeof content !== 'string') {
			return content;
		}

		const parts = highlightText.reduce(
			(acc, highlight) => {
				const regex = new RegExp(`(${highlight})`, 'gi');
				return acc.flatMap((part) => (typeof part === 'string' ? part.split(regex) : part));
			},
			[content],
		);

		return parts.map((part, index) => {
			const key = `${part}`;
			return highlightText.some((highlight) => highlight.toLowerCase() === part.toLowerCase()) ? (
				<S.Typography
					key={key}
					$color='primary60'
					style={style}
					$typography={typography}
					$margin={margin}
					$display={display}
					$padding={padding}>
					{part}
				</S.Typography>
			) : (
				part
			);
		});
	}, [text, children, highlightText]);

	return (
		<S.Typography
			style={style}
			$typography={typography}
			$color={color}
			$margin={margin}
			$display={display}
			$padding={padding}>
			{highlightedTexts}
		</S.Typography>
	);
}
