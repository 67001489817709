import useCRMutation from 'lib/hook/react-query/base/useCRMutation';
import { endpoint } from 'lib/service/Api/endpoint';

export const useSaveEmployeeDefaultWorkTime = useCRMutation(
	endpoint.saveEmployeeDefaultWorkTime.key,
	'saveEmployeeDefaultWorkTime',
);

export const useDeleteEmployeeDefaultWorkTime = useCRMutation(
	endpoint.deleteEmployeeDefaultWorkTime.key,
	'deleteEmployeeDefaultWorkTime',
);

export const useSaveEmployeeVacation = useCRMutation(
	endpoint.saveEmployeeVacation.key,
	'saveEmployeeVacation',
);

export const useDeleteEmployeeVacation = useCRMutation(
	endpoint.deleteEmployeeVacation.key,
	'deleteEmployeeVacation',
);

export const useSaveEmployeeWorkSchedule = useCRMutation(
	endpoint.saveEmployeeWorkSchedule.key,
	'saveEmployeeWorkSchedule',
);

export const useSaveEmployeeActualWorkLog = useCRMutation(
	endpoint.saveEmployeeActualWorkLog.key,
	'saveEmployeeActualWorkLog',
);

export const useDeleteEmployeeActualWorkLog = useCRMutation(
	endpoint.deleteEmployeeActualWorkLog.key,
	'deleteEmployeeActualWorkLog',
);

export const useSaveCenterDefaultWorkTime = useCRMutation(
	endpoint.saveCenterDefualtWorkTime.key,
	'saveCenterDefualtWorkTime',
);

export const useRegistryDefaultWorkTime = useCRMutation(
	endpoint.registryDefaultWorkTime.key,
	'registryDefaultWorkTime',
);

export const useSaveEmployeeMonthMaxOverworkTime = useCRMutation(
	endpoint.saveEmployeeMaxOverWorkTime.key,
	'saveEmployeeMaxOverWorkTime',
);

export const useSaveEmployeeWorkHistory = useCRMutation(
	endpoint.saveEmployeeWorkHistory.key,
	'saveEmployeeWorkHistory',
);

export const useDeleteEmployeeWorkHistory = useCRMutation(
	endpoint.deleteEmployeeWorkHistory.key,
	'deleteEmployeeWorkHistory',
);

export const useUpdateWorkHistoryConfirm = useCRMutation(
	endpoint.updateWorkHistoryConfirm.key,
	'updateWorkHistoryConfirm',
);
