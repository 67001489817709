import { styled } from 'styled-components';

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	width: 100%;
`;

export const FormItemContainer = styled.div`
	width: 100%;
`;

export const HelpText = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 0.8rem;
	background: ${(props) => props.theme.colors.gray99};
	padding: 2.4rem;
`;

export const HelpTextTitle = styled.p`
	${(props) => props.theme.typography.bodyB};
	color: ${(props) => props.theme.colors.gray00};
`;

export const HelpTextLabel = styled.span`
	${(props) => props.theme.typography.body};
	display: block;
	color: ${(props) => props.theme.colors.gray00};
	padding-left: 0.5rem;
`;

export const HelpTextDesc = styled.span`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray00};
	display: block;
	padding-left: 2.5rem;
`;

export const Divider = styled.div`
	width: 100%;
	height: 0.1rem;
	background: ${(props) => props.theme.colors.gray90};
	margin: 0.8rem 0;
`;

export const BannerContainer = styled.div`
	margin-top: 0.8rem;
`;

export const TableContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1.6rem;
`;

export const TableItemContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.8rem;
`;

export const Table = styled.table`
	width: 100%;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	table-layout: fixed;
`;

export const TableRow = styled.tr`
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	width: 100%;
	&:last-child {
		border-bottom: 0;
	}
`;

export const TableLabelColumn = styled.td`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray00};
	height: 3.3rem;
	padding: 0.4rem 0.8rem;
	box-sizing: border-box;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	background: ${(props) => props.theme.colors.gray95};
	vertical-align: middle;
	word-break: keep-all;
`;

export const TableValueColumnBase = styled.td<{ $textAlign?: 'left' | 'right' }>`
	${(props) => props.theme.typography.label};
	text-align: ${(p) => p.$textAlign || 'right'};
	position: relative;
	box-sizing: border-box;
	height: 3.3rem;
	padding: 0.4rem 0.8rem;
	box-sizing: border-box;
	background: ${(props) => props.theme.colors.gray100};
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	vertical-align: middle;
`;

export const TableValueColumn = styled(TableValueColumnBase)<{ $textAlign?: 'left' | 'right' }>`
	text-align: left;
	* {
		${(props) => props.theme.typography.label};
	}
`;
