import React, { useMemo, useRef, useState } from 'react';

import Assets from 'assets';
import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRTab from 'components/base/CRTab';

import AnnualTOManageTab, { AnnualTOManageTabForwardRef } from './AnnualTOManageTab';
import AnnualTOUsageTab, { AnnualTOUsageTabForwardRef } from './AnnualTOUsageTab';

function AnnualTOPage(): React.ReactElement {
	const annualToTab = useMemo(() => RouterPath.annualToTab(), []);
	const annualTOManageTabRef = useRef<AnnualTOManageTabForwardRef>(null);
	const annualTOUsageTabRef = useRef<AnnualTOUsageTabForwardRef>(null);
	const [tab, setCurrentTab] = useState<string>(annualToTab.annualManage.key);

	const handleClickAnnualTODownload = () => {
		annualTOManageTabRef.current?.downloadAnnualTOHistories();
	};

	const handleClickAnnualTOUsageDownload = () => {
		annualTOUsageTabRef.current?.downloadAnnualTOUsageHistories();
	};

	const handleClickDownload = () => {
		if (tab === annualToTab.annualManage.key) {
			handleClickAnnualTODownload();
		}
		if (tab === annualToTab.annualUsage.key) {
			handleClickAnnualTOUsageDownload();
		}
	};

	return (
		<CRTab.Default
			defaultActiveKey={tab}
			items={[
				{
					label: annualToTab.annualManage.label,
					key: annualToTab.annualManage.key,
					children: <AnnualTOManageTab ref={annualTOManageTabRef} />,
				},
				{
					label: annualToTab.annualUsage.label,
					key: annualToTab.annualUsage.key,
					children: <AnnualTOUsageTab ref={annualTOUsageTabRef} />,
				},
			]}
			onChange={(tab) => setCurrentTab(tab.key)}
			breadCrumb='연차, 유급 휴일 대장'
			renderRightButton={
				<CRButton.IconButton
					iconLeft={Assets.icon.download}
					type='outlined'
					palette='gray'
					onClick={handleClickDownload}>
					{tab === annualToTab.annualManage.key ? '연차관리대장 다운로드' : '연차사용대장 다운로드'}
				</CRButton.IconButton>
			}
		/>
	);
}

export default AnnualTOPage;
