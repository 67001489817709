import React, { ReactNode, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Accordion from '@radix-ui/react-accordion';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import { CheckOption } from 'components/base/Selections/type';
import {
	InsuranceAcquirement,
	InsuranceBase,
	InsuranceCancel,
	InsuranceChangeSalary,
	InsuranceDateChange,
	InsuranceLoss,
	displayCommaToNumber,
} from 'lib';
import { useEmployeePageBaseInfo, useMyAccountInfo } from 'lib/hook/react-query';
import {
	useCheckInsuranceRequiredEDoc,
	useCreateInsurance,
} from 'lib/hook/react-query/mutation/insurance';
import useDialog from 'lib/hook/util/useDialog';
import { ResponseCode } from 'types/api';
import {
	CheckInsuranceRequiredEDocRequest,
	CreateAcquirementInsuranceRequest,
	CreateCancelInsuranceRequest,
	CreateChangeSalaryInsuranceRequest,
	CreateDateChangeInsuranceRequest,
	CreateInsuranceResponse,
	CreateLossInsuranceRequest,
	CreateResignInsuranceRequest,
	EmployeeInsuranceDetails,
	InsuranceDependent,
	InsuranceRequestDetail,
	SalaryFee,
} from 'types/api/insurance';
import { EDocTemplateType, LastESignStateCd } from 'types/view/eDoc';
import {
	InsuranceAcquirementFormType,
	InsuranceBaseFormType,
	InsuranceCancelFormType,
	InsuranceChangeSalaryFormType,
	InsuranceDateChangeFormType,
	InsuranceLossFormType,
	InsuranceRequestTypeFormType,
} from 'types/view/insurance';

import BasicInfo from './BasicInfoForm';
import InsuranceAcquireDateChangeForm from './InsuranceAcquireDateChangeForm';
import InsuranceAcquirementCancelForm from './InsuranceAcquirementCancelForm';
import InsuranceAcquirementForm from './InsuranceAcquirementForm';
import InsuranceBaseForm from './InsuranceBaseForm';
import InsuranceChangeSalaryForm from './InsuranceChangeSalaryForm';
import InsuranceLossCancelForm from './InsuranceLossCancelForm';
import InsuranceLossDateChangeForm from './InsuranceLossDateChangeForm';
import InsuranceLossForm from './InsuranceLossForm';
import RequestTypeForm, { InsuranceReqType } from './RequestTypeForm';
import * as S from './styles';

export enum InsuranceType {
	ACCIDENT = '산재보험',
	EMPLOY = '고용보험',
	HEALTH = '건강보험',
	PENSION = '국민연금',
}

function InsuranceRequestPage(): React.ReactElement {
	const param = useParams<{ id: string }>();
	const navigate = useNavigate();
	const { data: myAccountInfo } = useMyAccountInfo();
	const { showDialog, hideDialog } = useDialog();
	const requestTypeContext = useForm<InsuranceRequestTypeFormType>();
	const acquirementContext = useForm<InsuranceAcquirementFormType>({
		resolver: yupResolver(InsuranceAcquirement),
		mode: 'onChange',
	});
	const lossContext = useForm<InsuranceLossFormType>({
		resolver: yupResolver(InsuranceLoss),
	});

	const acquirementCancelContext = useForm<InsuranceCancelFormType>({
		resolver: yupResolver(InsuranceCancel),
	});

	const lossCancelContext = useForm<InsuranceCancelFormType>({
		resolver: yupResolver(InsuranceCancel),
	});

	const baseContext = useForm<InsuranceBaseFormType>({
		resolver: yupResolver(InsuranceBase),
	});

	const changeSalaryContext = useForm<InsuranceChangeSalaryFormType>({
		resolver: yupResolver(InsuranceChangeSalary),
	});

	const acquireDateChangeContext = useForm<InsuranceDateChangeFormType>({
		resolver: yupResolver(InsuranceDateChange),
	});

	const lossDateChangeContext = useForm<InsuranceDateChangeFormType>({
		resolver: yupResolver(InsuranceDateChange),
	});

	const onCreateInsuranceHandler = (returnData: CreateInsuranceResponse | null) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			Toast.success('정상적으로 4대보험 신고 신청하였습니다.');
			navigate(-1);
		} else {
			Toast.error('4대보험 신고 신청을 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
	};

	const { mutateAsync: createInsurance, isLoading } = useCreateInsurance();

	const { data: currentEmployeeBase } = useEmployeePageBaseInfo({
		centerId: myAccountInfo?.centerId,
		employeeId: Number(param.id),
	});

	const requestType = requestTypeContext.watch('requestType');

	const checkInsuranceRequestEDoc = useCheckInsuranceRequiredEDoc();

	const totalAmtSum = (
		formData: {
			serviceType?: CheckOption<any>;
			salaryFee?: CheckOption<any>;
			count: string;
			hour: string;
		}[],
	) => {
		const result = formData.reduce((acc, item) => {
			if (!item.salaryFee) return 0;
			const generalCareYn = (item?.salaryFee as unknown as CheckOption<SalaryFee>).data
				?.generalCareYn;
			const salaryFeeValue = Number(item.salaryFee.data.salaryFeeValue);
			const count = Number(item.count);

			if (!item.count || !salaryFeeValue) return acc;

			if (generalCareYn) {
				return acc + count * salaryFeeValue * Number(item.hour);
			}

			return acc + count * salaryFeeValue;
		}, 0);
		return result;
	};

	const totalMinuteSum = (
		formData: {
			serviceType?: CheckOption<any>;
			salaryFee?: CheckOption<any>;
			count: string;
			hour: string;
		}[],
	) => {
		const result = formData.reduce((acc, item) => {
			if (!item.salaryFee) return 0;
			const generalCareYn = (item?.salaryFee as unknown as CheckOption<SalaryFee>).data
				?.generalCareYn;
			const workHourCnt = Number(item.salaryFee.data.workHourCnt);
			const count = Number(item.count);

			if (!item.count || !workHourCnt) return acc;

			if (generalCareYn) {
				return acc + count * workHourCnt * Number(item.hour);
			}

			return acc + count * workHourCnt;
		}, 0);
		return result;
	};

	// 상실 신고
	const onSubmitLossForm = async (data: InsuranceLossFormType) => {
		if (!myAccountInfo?.centerId || !requestType?.value || !param.id) return;

		const insuranceRequestDetails: InsuranceRequestDetail = {};
		if (data.insuranceType?.find((item) => item.value === InsuranceType.ACCIDENT)) {
			// eslint-disable-next-line
			insuranceRequestDetails.accidentInsuranceRequestDate = dayjs(
				data.accidentInsuranceRequestDate,
			).format('YYYY-MM-DD HH:mm:ss');
			insuranceRequestDetails.accidentInsuranceApproveYn = true;
		}
		if (data.insuranceType?.find((item) => item.value === InsuranceType.PENSION)) {
			// eslint-disable-next-line
			insuranceRequestDetails.pensionInsuranceRequestDate = dayjs(
				data?.pensionInsuranceRequestDate,
			).format('YYYY-MM-DD HH:mm:ss');
			insuranceRequestDetails.pensionInsuranceApproveYn = true;
		}

		if (data.insuranceType?.find((item) => item.value === InsuranceType.EMPLOY)) {
			// eslint-disable-next-line
			insuranceRequestDetails.employInsuranceRequestDate = dayjs(
				data.employInsuranceRequestDate,
			).format('YYYY-MM-DD HH:mm:ss');
			insuranceRequestDetails.employInsuranceApproveYn = true;
		}
		if (data.insuranceType?.find((item) => item.value === InsuranceType.HEALTH)) {
			// eslint-disable-next-line
			insuranceRequestDetails.healthInsuranceRequestDate = dayjs(
				data.healthInsuranceRequestDate,
			).format('YYYY-MM-DD HH:mm:ss');
			insuranceRequestDetails.healthInsuranceApproveYn = true;
		}

		const currentMonthSalaryAmt = data.thisSalaryInfo.paySumAmt || totalAmtSum(data.currentMonth);

		const currentMonthWorkHourCnt =
			data.thisSalaryInfo.totalWorkHourCnt || totalMinuteSum(data.currentMonth);

		const params: CreateLossInsuranceRequest = {
			centerId: myAccountInfo.centerId,
			insuranceRequestTypeCd: requestType.value,
			lossDescCd: data.lossDescCd?.value,
			currentMonthSalaryAmt,
			currentMonthWorkHourCnt,
			requestDesc: data.requestDesc || '',
			insuranceRequestDetails,
		};

		// 상실사유 고용보험 이중취득 일때를 제외하고 상실 상세 사유를 담음
		if (data.lossDescCd?.value !== 'CMN114.30') {
			params.lossDetailDescCd = data.lossDetailDescCd?.value;
		}

		if (data.edocNo) {
			params.edocNo = data.edocNo;
		}

		if (data.attachFile?.length) {
			params.attachFile = {
				fileDetails: data.attachFile.filter((item) => !item.fileDeleteYn),
			};
		}

		const requestItems = [
			createInsurance({
				employeeId: Number(param.id),
				params,
			}),
		];
		// 이직확인서 값 입력시 이직확인서도 함꼐 신청
		const isIssueCareerChangeCert = data.issueCareerChangeCert?.[0]?.value || false;
		if (isIssueCareerChangeCert && data.attachFileRequestDesc) {
			const changeCareerParams: CreateResignInsuranceRequest = {
				centerId: myAccountInfo.centerId,
				insuranceRequestTypeCd: 'CMN073.50',
				requestDesc: data.attachFileRequestDesc || '',
			};

			if (data.careerChangeAttachFile?.length) {
				changeCareerParams.attachFile = {
					fileDetails: data.careerChangeAttachFile.filter((item) => !item.fileDeleteYn),
				};
			}

			requestItems.push(
				createInsurance({
					employeeId: Number(param.id),
					params: changeCareerParams,
				}),
			);
		}
		const response = await Promise.all(requestItems);

		onCreateInsuranceHandler(response[0]);
	};

	const onSubmitBaseForm = async (data: InsuranceBaseFormType) => {
		if (!myAccountInfo?.centerId || !requestType?.value || !param.id) return;

		const params: CreateResignInsuranceRequest = {
			centerId: myAccountInfo.centerId,
			insuranceRequestTypeCd: requestType.value,
			requestDesc: data.requestDesc || '',
		};

		if (data.attachFile?.length) {
			params.attachFile = {
				fileDetails: data.attachFile.filter((item) => !item.fileDeleteYn),
			};
		}

		const response = await createInsurance({
			employeeId: Number(param.id),
			params,
		});

		onCreateInsuranceHandler(response);
	};

	const onSubmitCancelForm = async (data: InsuranceCancelFormType) => {
		if (!myAccountInfo?.centerId || !requestType?.value || !param.id) return;

		const insuranceRequestDetails: InsuranceRequestDetail = {};
		const employeeInsuranceDetails: EmployeeInsuranceDetails = {};
		if (data.insuranceType?.find((item) => item.value === InsuranceType.ACCIDENT)) {
			insuranceRequestDetails.accidentInsuranceApproveYn = true;
			employeeInsuranceDetails.accidentInsuranceDate = dayjs(
				data.data?.accidentInsuranceDate,
			).format('YYYY-MM-DD HH:mm:ss');
		}

		if (data.insuranceType?.find((item) => item.value === InsuranceType.PENSION)) {
			insuranceRequestDetails.pensionInsuranceApproveYn = true;
			employeeInsuranceDetails.pensionInsuranceDate = dayjs(data.data?.pensionInsuranceDate).format(
				'YYYY-MM-DD HH:mm:ss',
			);
		}

		if (data.insuranceType?.find((item) => item.value === InsuranceType.EMPLOY)) {
			insuranceRequestDetails.employInsuranceApproveYn = true;
			employeeInsuranceDetails.employInsuranceDate = dayjs(data.data?.employInsuranceDate).format(
				'YYYY-MM-DD HH:mm:ss',
			);
		}

		if (data.insuranceType?.find((item) => item.value === InsuranceType.HEALTH)) {
			insuranceRequestDetails.healthInsuranceApproveYn = true;
			employeeInsuranceDetails.healthInsuranceDate = dayjs(data.data?.healthInsuranceDate).format(
				'YYYY-MM-DD HH:mm:ss',
			);
		}

		const params: CreateCancelInsuranceRequest = {
			centerId: myAccountInfo.centerId,
			insuranceRequestTypeCd: requestType.value,
			requestDesc: data.requestDesc || '',
		};

		if (data.edocNo) {
			params.edocNo = data.edocNo;
		}

		if (data.attachFile?.length) {
			params.attachFile = {
				fileDetails: data.attachFile.filter((item) => !item.fileDeleteYn),
			};
		}

		if (Object.keys(insuranceRequestDetails)?.length) {
			params.insuranceRequestDetails = insuranceRequestDetails;
		}
		if (Object.keys(employeeInsuranceDetails)?.length) {
			params.employeeInsuranceDetails = employeeInsuranceDetails;
		}

		const response = await createInsurance({
			employeeId: Number(param.id),
			params,
		});
		onCreateInsuranceHandler(response);
	};

	const onSubmitChangeSalaryForm = async (data: InsuranceChangeSalaryFormType) => {
		if (!myAccountInfo?.centerId || !requestType?.value || !param.id) return;

		const insuranceRequestDetails: InsuranceRequestDetail = {};
		const employeeInsuranceDetails: EmployeeInsuranceDetails = {};
		if (data.insuranceType?.find((item) => item.value === InsuranceType.ACCIDENT)) {
			insuranceRequestDetails.accidentInsuranceRequestAmt = displayCommaToNumber(
				data.accidentInsuranceRequestAmt,
			);
			insuranceRequestDetails.accidentInsuranceApproveYn = true;
			employeeInsuranceDetails.accidentInsuranceAmt = data?.data?.accidentInsuranceAmt;
		}

		if (data.insuranceType?.find((item) => item.value === InsuranceType.PENSION)) {
			insuranceRequestDetails.pensionInsuranceRequestAmt = displayCommaToNumber(
				data.pensionInsuranceRequestAmt,
			);
			insuranceRequestDetails.pensionInsuranceApproveYn = true;
			employeeInsuranceDetails.pensionInsuranceAmt = data?.data?.pensionInsuranceAmt;
		}

		if (data.insuranceType?.find((item) => item.value === InsuranceType.EMPLOY)) {
			insuranceRequestDetails.employInsuranceRequestAmt = displayCommaToNumber(
				data.employInsuranceRequestAmt,
			);
			insuranceRequestDetails.employInsuranceApproveYn = true;
			employeeInsuranceDetails.employInsuranceAmt = data?.data?.employInsuranceAmt;
		}

		if (data.insuranceType?.find((item) => item.value === InsuranceType.HEALTH)) {
			insuranceRequestDetails.healthInsuranceRequestAmt = displayCommaToNumber(
				data.healthInsuranceRequestAmt,
			);
			insuranceRequestDetails.healthInsuranceApproveYn = true;
			employeeInsuranceDetails.healthInsuranceAmt = data?.data?.healthInsuranceAmt;
		}

		const params: CreateChangeSalaryInsuranceRequest = {
			centerId: myAccountInfo.centerId,
			insuranceRequestTypeCd: requestType.value,
			salaryChangeYm: dayjs(data.salaryChangeYm).format('YYYYMM'),
			requestDesc: data.requestDesc || '',
			salaryChangeDescCd: data.salaryChangeDescCd?.value,
		};

		if (data.attachFile?.length) {
			params.attachFile = {
				fileDetails: data.attachFile.filter((item) => !item.fileDeleteYn),
			};
		}

		if (Object.keys(insuranceRequestDetails)?.length) {
			params.insuranceRequestDetails = insuranceRequestDetails;
		}

		if (Object.keys(employeeInsuranceDetails)?.length) {
			params.employeeInsuranceDetails = employeeInsuranceDetails;
		}

		const response = await createInsurance({
			employeeId: Number(param.id),
			params,
		});

		onCreateInsuranceHandler(response);
	};

	// 취득
	const onSubmitAcquirementForm = async (data: InsuranceAcquirementFormType) => {
		if (!myAccountInfo?.centerId || !requestType?.value || !param.id) return;

		const insuranceRequestDetails: InsuranceRequestDetail = {};
		if (
			!!data.insuranceType.find((item) => item.value === InsuranceType.ACCIDENT) &&
			data.accidentInsuranceRequestAmt &&
			data.accidentInsuranceRequestDate
		) {
			insuranceRequestDetails.accidentInsuranceRequestAmt = Number(
				data.accidentInsuranceRequestAmt.replace(/,/g, ''),
			);
			insuranceRequestDetails.accidentInsuranceRequestDate = data.accidentInsuranceRequestDate;
			insuranceRequestDetails.accidentInsuranceApproveYn = true;
		}

		if (
			!!data.insuranceType.find((item) => item.value === InsuranceType.PENSION) &&
			data.pensionInsuranceRequestAmt &&
			data.pensionInsuranceRequestDate
		) {
			insuranceRequestDetails.pensionInsuranceRequestAmt = Number(
				data.pensionInsuranceRequestAmt.replace(/,/g, ''),
			);
			insuranceRequestDetails.pensionInsuranceRequestDate = data.pensionInsuranceRequestDate;
			insuranceRequestDetails.pensionInsuranceApproveYn = true;
		}

		if (
			!!data.insuranceType.find((item) => item.value === InsuranceType.EMPLOY) &&
			data.employInsuranceRequestAmt &&
			data.employInsuranceRequestDate
		) {
			insuranceRequestDetails.employInsuranceRequestAmt = Number(
				data.employInsuranceRequestAmt.replace(/,/g, ''),
			);
			insuranceRequestDetails.employInsuranceRequestDate = data.employInsuranceRequestDate;
			insuranceRequestDetails.employInsuranceApproveYn = true;
		}

		if (
			!!data.insuranceType.find((item) => item.value === InsuranceType.HEALTH) &&
			data.healthInsuranceRequestAmt &&
			data.healthInsuranceRequestDate
		) {
			insuranceRequestDetails.healthInsuranceRequestAmt = Number(
				data.healthInsuranceRequestAmt.replace(/,/g, ''),
			);
			insuranceRequestDetails.healthInsuranceRequestDate = data.healthInsuranceRequestDate;
			insuranceRequestDetails.healthInsuranceApproveYn = true;
		}

		const params: CreateAcquirementInsuranceRequest = {
			centerId: myAccountInfo.centerId,
			insuranceRequestTypeCd: requestType.value,
			weekPerWorkHourCnt: data.weekPerWorkHourCnt,
			requestDesc: data.requestDesc || '',
			dependents: data.dependents?.map((item) => {
				const dependent: InsuranceDependent = {
					dependentRsdnNo: item.dependentRsdnNo,
					dependentNm: item.dependentNm,
					dependentCountryCd: item.isForeign[0].value ? item.dependentCountryCd?.value : null,
					dependentRelCd: item.dependentRelCd?.value,
					dependentRegistDt: dayjs(item.dependentRegistDt).format('YYYYMMDD'),
				};
				if (item.isForeign[0].value) {
					dependent.dependentStayStartDt = dayjs(item.dependentStayStartDt).format('YYYYMMDD');
					dependent.dependentStayEndDt = dayjs(item.dependentStayEndDt).format('YYYYMMDD');
				}
				return dependent;
			}),
		};

		if (data.edocNo) {
			params.edocNo = data.edocNo;
		}

		if (data.attachFile?.length) {
			params.attachFile = {
				fileDetails: data.attachFile.filter((item) => !item.fileDeleteYn),
			};
		}

		if (Object.keys(insuranceRequestDetails)?.length) {
			params.insuranceRequestDetails = insuranceRequestDetails;
		}

		if (data.insuranceType?.find((item) => item.value === InsuranceType.EMPLOY)) {
			params.unemploymentBenefitPreconditionFullYn =
				data.unemploymentBenefitPreconditionFullYn?.[0].value;
		}

		const response = await createInsurance({
			employeeId: Number(param.id),
			params,
		});

		onCreateInsuranceHandler(response);
	};

	// 취득일자정정, 상실일자정정
	const onSubmitChangeDateForm = async (data: InsuranceDateChangeFormType) => {
		if (!myAccountInfo?.centerId || !requestType?.value || !param.id) return;

		const insuranceRequestDetails: InsuranceRequestDetail = {};
		const employeeInsuranceDetails: EmployeeInsuranceDetails = {};
		if (
			!!data.insuranceType.find((item) => item.value === InsuranceType.ACCIDENT) &&
			data.accidentInsuranceRequestDate
		) {
			insuranceRequestDetails.accidentInsuranceRequestDate = data.accidentInsuranceRequestDate;
			insuranceRequestDetails.accidentInsuranceApproveYn = true;
			employeeInsuranceDetails.accidentInsuranceDate = dayjs(
				data.data?.accidentInsuranceDate,
			).format('YYYY-MM-DD HH:mm:ss');
		}

		if (
			!!data.insuranceType.find((item) => item.value === InsuranceType.PENSION) &&
			data.pensionInsuranceRequestDate
		) {
			insuranceRequestDetails.pensionInsuranceRequestDate = data.pensionInsuranceRequestDate;
			insuranceRequestDetails.pensionInsuranceApproveYn = true;
			employeeInsuranceDetails.pensionInsuranceDate = dayjs(data.data?.pensionInsuranceDate).format(
				'YYYY-MM-DD HH:mm:ss',
			);
		}

		if (
			!!data.insuranceType.find((item) => item.value === InsuranceType.EMPLOY) &&
			data.employInsuranceRequestDate
		) {
			insuranceRequestDetails.employInsuranceRequestDate = data.employInsuranceRequestDate;
			insuranceRequestDetails.employInsuranceApproveYn = true;
			employeeInsuranceDetails.employInsuranceDate = dayjs(data.data?.employInsuranceDate).format(
				'YYYY-MM-DD HH:mm:ss',
			);
		}

		if (
			!!data.insuranceType.find((item) => item.value === InsuranceType.HEALTH) &&
			data.healthInsuranceRequestDate
		) {
			insuranceRequestDetails.healthInsuranceRequestDate = data.healthInsuranceRequestDate;
			insuranceRequestDetails.healthInsuranceApproveYn = true;
			employeeInsuranceDetails.healthInsuranceDate = dayjs(data.data?.healthInsuranceDate).format(
				'YYYY-MM-DD HH:mm:ss',
			);
		}

		const params: CreateDateChangeInsuranceRequest = {
			centerId: myAccountInfo.centerId,
			insuranceRequestTypeCd: requestType.value,
			requestDesc: data.requestDesc || '',
		};

		if (data.attachFile?.length) {
			params.attachFile = {
				fileDetails: data.attachFile.filter((item) => !item.fileDeleteYn),
			};
		}

		if (Object.keys(insuranceRequestDetails)?.length) {
			params.insuranceRequestDetails = insuranceRequestDetails;
		}

		if (Object.keys(employeeInsuranceDetails)?.length) {
			params.employeeInsuranceDetails = employeeInsuranceDetails;
		}

		if (
			data.insuranceType?.find((item) => item.value === InsuranceType.EMPLOY) &&
			requestType.value === InsuranceReqType.취득일자정정
		) {
			params.unemploymentBenefitPreconditionFullYn =
				data.unemploymentBenefitPreconditionFullYn?.[0].value;
		}

		const response = await createInsurance({
			employeeId: Number(param.id),
			params,
		});

		onCreateInsuranceHandler(response);
	};

	const totalContext = useMemo(
		() => [
			{
				requestType: InsuranceReqType.취득,
				formContext: acquirementContext,
				onSubmit: onSubmitAcquirementForm,
			},
			{
				requestType: InsuranceReqType.상실,
				formContext: lossContext,
				onSubmit: onSubmitLossForm,
			},
			{
				requestType: InsuranceReqType.취득취소,
				formContext: acquirementCancelContext,
				onSubmit: onSubmitCancelForm,
			},
			{
				requestType: InsuranceReqType.상실취소,
				formContext: lossCancelContext,
				onSubmit: onSubmitCancelForm,
			},
			{
				requestType: InsuranceReqType.세무문서요청,
				formContext: baseContext,
				onSubmit: onSubmitBaseForm,
			},
			{
				requestType: InsuranceReqType.이직확인서,
				formContext: baseContext,
				onSubmit: onSubmitBaseForm,
			},
			{
				requestType: InsuranceReqType.기타,
				formContext: baseContext,
				onSubmit: onSubmitBaseForm,
			},
			{
				requestType: InsuranceReqType.신고금액변경,
				formContext: changeSalaryContext,
				onSubmit: onSubmitChangeSalaryForm,
			},
			{
				requestType: InsuranceReqType.취득일자정정,
				formContext: acquireDateChangeContext,
				onSubmit: onSubmitChangeDateForm,
			},
			{
				requestType: InsuranceReqType.상실일자정정,
				formContext: lossDateChangeContext,
				onSubmit: onSubmitChangeDateForm,
			},
		],

		[
			acquirementContext,
			lossContext,
			acquirementCancelContext,
			lossCancelContext,
			baseContext,
			changeSalaryContext,
			acquireDateChangeContext,
			lossDateChangeContext,
			onSubmitAcquirementForm,
			onSubmitCancelForm,
			onSubmitLossForm,
			onSubmitChangeSalaryForm,
			onSubmitChangeDateForm,
		],
	);

	const matchedForm: any = totalContext.find(
		(context) => context.requestType === requestTypeContext?.watch('requestType')?.value,
	);

	const openEDocPage = (from: EDocTemplateType) => {
		const popup = window.open(
			`/employee/${currentEmployeeBase?.employeeId}/documents?from=${from}`,
		);
		const timer = setInterval(() => {
			if (popup && popup.closed) {
				clearInterval(timer);
				// queryClient.invalidateQueries([
				// 	endpoint.getEmployeeInsurance.key,
				// 	{
				// 		centerId: myAccountInfo?.centerId,
				// 		employeeId: currentEmployee?.employeeId,
				// 	},
				// ]);
			}
		}, 500);
	};

	const checkForeignerEmployAcquirement = () => {
		if (!currentEmployeeBase) return false;
		const isForeigner = ['5', '6', '7', '8'].includes(currentEmployeeBase.rsdnNo[6]);
		const isAcquirement = requestType?.value === InsuranceReqType.취득;
		const hasEmployInsurance = !!matchedForm.formContext
			.getValues()
			?.insuranceType?.find((item: any) => item?.value === InsuranceType.EMPLOY);
		return isForeigner && isAcquirement && hasEmployInsurance;
	};

	const checkForeignerEmployLoss = () => {
		if (!currentEmployeeBase) return false;
		const isForeigner = ['5', '6', '7', '8'].includes(currentEmployeeBase.rsdnNo[6]);
		const isLoss = requestType?.value === InsuranceReqType.상실;
		const hasEmployInsurance = !!matchedForm.formContext
			.getValues()
			?.insuranceType?.find((item: any) => item?.value === InsuranceType.EMPLOY);
		return isForeigner && isLoss && hasEmployInsurance;
	};

	const checkIsOver14DaysFromHealthInsuranceAcqDate = () => {
		if (!currentEmployeeBase || !currentEmployeeBase?.healthInsuranceAcqDate) return false;
		const diffDate = dayjs().diff(dayjs(currentEmployeeBase.healthInsuranceAcqDate), 'day');

		const isOver14 = diffDate >= 14;
		const isAcquirementCancel = requestType?.value === InsuranceReqType.취득취소;
		const hasHealthInsurance = !!matchedForm.formContext
			.getValues()
			?.insuranceType?.find((item: any) => item?.value === InsuranceType.HEALTH);
		return isOver14 && isAcquirementCancel && hasHealthInsurance;
	};

	const checkIsOver14DaysFromHealthInsuranceLossDate = () => {
		if (!currentEmployeeBase || !currentEmployeeBase?.healthInsuranceLossDate) return false;
		const diffDate = dayjs().diff(dayjs(currentEmployeeBase.healthInsuranceLossDate), 'day');

		const isOver14 = diffDate >= 14;
		const isLossCancel = requestType?.value === InsuranceReqType.상실취소;
		const hasHealthInsurance = !!matchedForm.formContext
			.getValues()
			?.insuranceType?.find((item: any) => item?.value === InsuranceType.HEALTH);
		return isOver14 && isLossCancel && hasHealthInsurance;
	};

	const isRequiredDocumentSubmitted = async (title?: ReactNode) => {
		let isSubmit = false;
		const weekPerWorkHourCnt = matchedForm.formContext.getValues()?.weekPerWorkHourCnt;

		if (!currentEmployeeBase?.employeeId || !myAccountInfo?.centerId || !requestType.value)
			return isSubmit;

		const requestParam: CheckInsuranceRequiredEDocRequest = {
			employeeId: currentEmployeeBase.employeeId,
			centerId: myAccountInfo.centerId,
			insuranceRequestType: requestType.value,
		};
		if (weekPerWorkHourCnt) {
			requestParam.weekPerWorkHourCnt = weekPerWorkHourCnt;
		}
		const res = await checkInsuranceRequestEDoc.mutateAsync(requestParam);

		// 이미 발급되었다면 서명대기 상태가 아니어야 통과
		if (res?.edocNeedYn && res.edocInfo.edocNo) {
			matchedForm.formContext.setValue('edocNo', res.edocInfo.edocNo);
			if (res.edocInfo.signState.id && res.edocInfo.signState.id !== LastESignStateCd.서명대기) {
				isSubmit = true;
				return isSubmit;
			}
		}

		const body = (
			<S.RequiredEDocContainer>
				{title}
				<S.EDocInfo>
					<CRText text='필요 서류' typography='body' color='gray60' />
					<CRText
						text={
							res?.edocInfo?.signState?.id === LastESignStateCd.서명대기
								? res?.edocInfo?.title
								: res?.requiredEdoc?.title
						}
						typography='bodyB'
						color='gray00'
					/>
				</S.EDocInfo>
			</S.RequiredEDocContainer>
		);

		const footer = (
			<S.RequiredEDocFooter>
				<S.LeftButtonContainer>
					<CRButton.Default
						type='outlined'
						onClick={() => {
							matchedForm.formContext.handleSubmit(matchedForm.onSubmit as (data: any) => void)();
							hideDialog();
						}}>
						무시하고 신청
					</CRButton.Default>
				</S.LeftButtonContainer>
				<S.RightButtonContainer>
					<CRButton.Default type='text' palette='gray' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						onClick={() => {
							if (res?.requiredEdoc.paperTypeCd) {
								openEDocPage(res.requiredEdoc.paperTypeCd as EDocTemplateType);
							}
							hideDialog();
						}}>
						발급
					</CRButton.Default>
				</S.RightButtonContainer>
			</S.RequiredEDocFooter>
		);

		if (requestType?.value !== InsuranceReqType.상실) {
			showDialog(() => (
				<CRDialog title='서류 발급 필요' body={body} onClickClose={hideDialog} footer={footer} />
			));
		} else {
			// 신고유형이 상실일경우에는 서류발급창 띄우지 않음
			isSubmit = true;
		}

		return isSubmit;
	};

	const submitForm = () => {
		if (matchedForm && !isLoading) {
			matchedForm.formContext.trigger().then(async (isValid: boolean) => {
				if (isValid) {
					const isForeignerEmployAcquirement = checkForeignerEmployAcquirement();
					const isForeignerEmployLoss = checkForeignerEmployLoss();
					const isHealthInsuranceAcqCancelOver14Date =
						checkIsOver14DaysFromHealthInsuranceAcqDate();
					const isHealthInsuranceLossCancelOver14Date =
						checkIsOver14DaysFromHealthInsuranceLossDate();

					if (isForeignerEmployAcquirement) {
						const isSubmit = await isRequiredDocumentSubmitted(
							<CRText
								text={
									<>
										고용보험을 취득하려면 아래 서류를 발급받고 서명을 받아야합니다.
										<br />
										서류를 발급하시겠습니까?
									</>
								}
								color='gray00'
								typography='body'
							/>,
						);
						if (!isSubmit) return;
					}
					if (isForeignerEmployLoss) {
						const isSubmit = await isRequiredDocumentSubmitted();
						if (!isSubmit) return;
					}
					if (isHealthInsuranceAcqCancelOver14Date) {
						const isSubmit = await isRequiredDocumentSubmitted(
							<CRText
								text={
									<>
										건강보험 취득일 기준 14일이 경과한 경우, 취소 시 아래 서류를 발급
										<br />
										받고 서명을 받아야합니다. 서류를 발급하시겠습니까?
									</>
								}
								color='gray00'
								typography='body'
							/>,
						);
						if (!isSubmit) return;
					}

					if (isHealthInsuranceLossCancelOver14Date) {
						const isSubmit = await isRequiredDocumentSubmitted(
							<CRText
								text={
									<>
										건강보험 상실일 기준 14일이 경과한 경우, 취소 시 아래 서류를 발급
										<br />
										받고 서명을 받아야합니다. 서류를 발급하시겠습니까?
									</>
								}
								color='gray00'
								typography='body'
							/>,
						);
						if (!isSubmit) return;
					}
					matchedForm.formContext.handleSubmit(matchedForm.onSubmit as (data: any) => void)();
				}
			});
		}
	};

	const renderSubForm = () => {
		const requestTypeCd = requestTypeContext?.watch('requestType')?.value;
		switch (requestTypeCd) {
			case InsuranceReqType.취득: {
				return (
					<InsuranceAcquirementForm
						requestTypeCd={requestTypeCd}
						context={acquirementContext}
						rsdnNumber={currentEmployeeBase?.rsdnNo}
					/>
				);
			}
			case InsuranceReqType.상실: {
				return <InsuranceLossForm requestTypeCd={requestTypeCd} context={lossContext} />;
			}
			case InsuranceReqType.취득취소: {
				return (
					<InsuranceAcquirementCancelForm
						requestTypeCd={requestTypeCd}
						context={acquirementCancelContext}
					/>
				);
			}
			case InsuranceReqType.상실취소: {
				return (
					<InsuranceLossCancelForm requestTypeCd={requestTypeCd} context={lossCancelContext} />
				);
			}
			case InsuranceReqType.신고금액변경: {
				return (
					<InsuranceChangeSalaryForm requestTypeCd={requestTypeCd} context={changeSalaryContext} />
				);
			}
			case InsuranceReqType.취득일자정정: {
				return (
					<InsuranceAcquireDateChangeForm
						requestTypeCd={requestTypeCd}
						context={acquireDateChangeContext}
						rsdnNumber={currentEmployeeBase?.rsdnNo}
					/>
				);
			}
			case InsuranceReqType.상실일자정정: {
				return (
					<InsuranceLossDateChangeForm
						requestTypeCd={requestTypeCd}
						context={lossDateChangeContext}
					/>
				);
			}
			case InsuranceReqType.이직확인서:
			case InsuranceReqType.세무문서요청:
			case InsuranceReqType.기타: {
				return <InsuranceBaseForm context={baseContext} />;
			}
			default: {
				return null;
			}
		}
	};

	useEffect(
		() => () => {
			matchedForm?.formContext?.reset();
		},
		[requestType],
	);

	return (
		<S.Container>
			<S.Title>4대보험 신고</S.Title>
			<S.ContentsContainer>
				<Accordion.Root
					type='multiple'
					style={{
						width: '100%',
					}}
					defaultValue={[
						'기본 정보',
						'신고 유형',
						'신고 내용',
						'피부양자',
						'이직확인서 요청',
						'파일 첨부',
					]}>
					<BasicInfo />
					<RequestTypeForm context={requestTypeContext} />
					{renderSubForm()}
				</Accordion.Root>
			</S.ContentsContainer>
			<S.BottomSection>
				<S.BottomMenuContainer>
					<S.BottomRightMenuContainer>
						<CRButton.Default type='text' palette='gray' onClick={() => navigate(-1)}>
							취소
						</CRButton.Default>
						<CRButton.Default
							disabled={!matchedForm?.formContext?.formState?.isValid}
							onClick={submitForm}
							type='outlined'>
							신청
						</CRButton.Default>
					</S.BottomRightMenuContainer>
				</S.BottomMenuContainer>
			</S.BottomSection>
		</S.Container>
	);
}

export default InsuranceRequestPage;
