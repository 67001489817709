import React, { useMemo } from 'react';

import EDocViewer from 'components/base/CRFileViewer/viewer/EDocViewer';
import useEDocClientPage from 'lib/hook/eDoc/useESignInfoPage';

function EDocInfoViewStep() {
	const { eDocInfo } = useEDocClientPage();
	const parsedEdocPraramValue = useMemo(
		() => JSON.parse(eDocInfo?.edocPreviewResponse.data.edocParam.edocParamValue || '""'),
		[eDocInfo],
	);

	// TODO 유효하지 않은 형식 예외처리
	if (!parsedEdocPraramValue) {
		return null;
	}
	const { crfeNm, params } = parsedEdocPraramValue;

	const eDocTitle = eDocInfo?.edocPreviewResponse?.edocNm || '';

	return (
		<EDocViewer
			eDocTitle={eDocTitle}
			showImgDownloader={false}
			showCustomHeader
			viewerMode='fullPage'
			dataType='json'
			crfeName={crfeNm}
			params={params}
		/>
	);
}
export default EDocInfoViewStep;
