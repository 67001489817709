import React from 'react';

import FullTimeEmployeeTOView from './FullTimeEmployeeTOView';
import * as S from './styles';

interface Props {
	disabledButton?: boolean;
}

function FullTimeEmployeeTOFormView({ disabledButton }: Props): React.ReactElement {
	return (
		<S.Container>
			<FullTimeEmployeeTOView disabledButton={disabledButton} />
		</S.Container>
	);
}

export default React.memo(FullTimeEmployeeTOFormView);
