import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';

import dayjs from 'dayjs';

import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { useCreateEmployee, useMyAccountInfo } from 'lib/hook/react-query';
import useContractChangeEmployeeTask from 'lib/hook/view/contract/useContractChangeEmployeeTask';

import { useApi } from '../../../../lib/provider/service/Api';
import { EmployeeCheckDuplicateDTO } from '../../../../types/api';
import { ContractDetailEmployeeDutyDTO } from '../../../../types/dto';
import EmployeeDuplicationCheckForm from '../EmployeeDuplicationCheckForm';
import * as S from './styles';

function ChangeEmployeeVisitNursingContractForm(): React.ReactElement {
	const api = useApi();
	const { form, disabled } = useContractChangeEmployeeTask();
	const { data: myAccountInfo } = useMyAccountInfo();
	const createEmployee = useCreateEmployee();

	const { append } = useFieldArray({
		control: form.control,
		name: 'employees',
	});

	const getEmployeeData = async (targetIndex: number, employeeId: number, memberId: number) => {
		if (!myAccountInfo) return;
		const result = await api.getEmployeeDetailInfo({
			centerId: myAccountInfo.centerId,
			employeeId,
			memberId,
		});

		form.setValue(
			`service.associates.contractEmployees.${targetIndex}.centerId`,
			myAccountInfo.centerId,
		);
		form.setValue(
			`service.associates.contractEmployees.${targetIndex}.employeeId`,
			employeeId ?? 0,
		);

		if (!form.getValues('employees')?.some((item) => item?.base.employeeId === employeeId)) {
			append({
				base: {
					employeeId: employeeId ?? 0,
					centerId: myAccountInfo.centerId,
					employeeNm: result?.korMemberNm,
					employeeRsdnNo: result?.rsdnNo,
					tagMobilePhoneNo: result?.mobilePhoneNo,
					employeeSpecialDesc: result?.employeeSpecialDesc,
					employeeBirthDt: result?.birthDt,
					employeeBasAddr: result?.basAddr ?? '',
					employeeDetailAddr: result?.detailAddr ?? '',
					roadNm: result?.roadNm ?? '',
					regionNm1: result?.regionNm1 ?? '',
					regionNm2: result?.regionNm2 ?? '',
					regionNm3: result?.regionNm3 ?? '',
					disabilityGradeCd: result?.disabilityGradeCd,
					dementiaEduCompleteYn: result?.dementiaEduCompleteYn,
					dutyCds: (result?.dutyCds.length
						? result.dutyCds
						: [
								{
									dutyCd: form.getValues(
										`service.associates.contractEmployees.${targetIndex}.employeeDutyCd`,
									),
								} as ContractDetailEmployeeDutyDTO,
							]
					).map(
						(item) =>
							({
								dutyCd: item.dutyCd,
								employeeDutyId: item.employeeDutyId
									? Number(item.employeeDutyId ?? '0')
									: undefined,
							}) as ContractDetailEmployeeDutyDTO,
					),
					tagCallRequestYn: result?.twoPhoneYn,
					twoPhoneYn: result?.twoPhoneYn,
					subMobilePhoneNo: result?.subMobilePhoneNo,
					joinDate: result?.joinDate,
					docReceiveMethodCd: result?.docReceiveMethodCd,
				},
				salary: {
					salaryPayTypeCd: result?.salaryPayTypeCd,
					salaryAccountBankCd: result?.salaryAccountBankCd,
					salaryAccountDepositorNm: result?.salaryAccountDepositorNm,
					salaryAccountNo: result?.salaryAccountNo,
					salaryAccountValidYn: result?.salaryAccountValidYn,
				},
			});
		}
	};

	const handleClickDuplication =
		(targetIndex: number) =>
		async (data: { name: string; rsdn: string; result: EmployeeCheckDuplicateDTO }) => {
			if (!myAccountInfo) return;
			if (data.result?.isCenterDuplicated && data.result.isEmployeeDuplicated) {
				if (!data.result.employeeId) return;
				if (!data.result.memberId) return;
				await getEmployeeData(targetIndex, data.result.employeeId, data.result.memberId);
				return;
			}
			const result = await createEmployee.mutateAsync({
				centerId: myAccountInfo.centerId,
				name: data.name,
				rsdnNo: data.rsdn,
			});
			if (!result?.data?.employeeId) return;
			if (!result?.data?.memberId) return;
			await getEmployeeData(targetIndex, result.data.employeeId, result.data.memberId);
			return;
		};

	return (
		<S.Container>
			<S.SectionContainer>
				<S.SectionTitle>수급자</S.SectionTitle>
				<Controller
					name='service.associates.recipientContractAssignDate'
					control={form?.control}
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel type='left' label='계약일' isRequired showOverflow>
							<CRInput.DatePicker
								placeholder='계약일'
								value={value ? dayjs(value).toDate() : undefined}
								maxDate={
									form.watch(`service.use.sameDayContractYn`)
										? dayjs(form.watch(`service.use.serviceStartDate`)).toDate()
										: dayjs(form.watch(`service.use.serviceStartDate`)).subtract(1, 'day').toDate()
								}
								onChangeValue={(date) => {
									onChange(dayjs(date).format('YYYY-MM-DD hh:mm:ss'));
								}}
								disabled={disabled}
							/>
						</CRInputLabel>
					)}
				/>
			</S.SectionContainer>
			<S.SectionContainer>
				<S.SectionTitle>직원</S.SectionTitle>
				<Controller
					name='service.associates.contractEmployees.0'
					control={form?.control}
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<>
							<CRInputLabel type='left' label='계약일' isRequired showOverflow>
								<CRInput.DatePicker
									placeholder='계약일'
									value={
										value?.employeeContractAssignDate
											? dayjs(value?.employeeContractAssignDate).toDate()
											: undefined
									}
									maxDate={
										form.watch(`service.use.sameDayContractYn`)
											? dayjs(form.watch(`service.use.serviceStartDate`)).toDate()
											: dayjs(form.watch(`service.use.serviceStartDate`))
													.subtract(1, 'day')
													.toDate()
									}
									onChangeValue={(date) =>
										onChange({
											...value,
											employeeContractAssignDate: dayjs(date).format('YYYY-MM-DD hh:mm:ss'),
										})
									}
									disabled={disabled}
								/>
							</CRInputLabel>
							<CRInputLabel type='left' label='직원 정보' isRequired showOverflow>
								<CRCardFormLayout label='직원' isRequired>
									<EmployeeDuplicationCheckForm
										data={{ rsdn: value?.employeeRsdnNo, name: value?.employeeNm }}
										onChange={(data) =>
											onChange({ ...value, employeeNm: data.name, employeeRsdnNo: data.rsdn })
										}
										disabled={
											form
												.watch('employees')
												?.some(
													(item) =>
														item?.base.employeeId &&
														item?.base.employeeId ===
															form.watch(`service.associates.contractEmployees.0.employeeId`),
												) || disabled
										}
										onConfirm={handleClickDuplication(0)}
									/>
								</CRCardFormLayout>
							</CRInputLabel>
							<CRInputLabel type='left' label='직무' isRequired showOverflow>
								<CRCheckBoxGroup
									type='radio'
									checkType='single'
									appearanceType='button'
									gap={0.8}
									options={[
										{ label: '간호사', value: 'CMN070.30' },
										{ label: '간호조무사', value: 'CMN070.50' },
									]}
									value={value?.employeeDutyCd ? [{ label: '', value: value.employeeDutyCd }] : []}
									onChange={(item) => onChange({ ...value, employeeDutyCd: item[0].value })}
									disabled={disabled}
								/>
							</CRInputLabel>
						</>
					)}
				/>
			</S.SectionContainer>
		</S.Container>
	);
}

export default ChangeEmployeeVisitNursingContractForm;
