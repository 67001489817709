import React from 'react';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRIcon from 'components/base/CRIcon';
import { CRText } from 'components/base/CRText';

function ClientMobileErrorPage(): React.ReactElement {
	return (
		<FlexContainer
			width='100vw'
			height='100vh'
			align='center'
			justify='center'
			style={{ backgroundColor: '#FAFAFA' }}
			direction='column'
			gap='1.6rem'>
			<CRIcon src={Assets.icon.errorPage} alt='error' width='10rem' height='10rem' />
			<FlexContainer direction='column' gap='0.8rem'>
				<CRText typography='h3' color='gray60' text='오류가 발생했습니다.' />
				<CRText color='gray60' text='오류가 계속된다면 관리자에게 문의해주세요.' />
			</FlexContainer>
			<CRButton.Default onClick={() => window.location.reload()} type='outlined' palette='gray'>
				새로고침
			</CRButton.Default>
		</FlexContainer>
	);
}

export default ClientMobileErrorPage;
