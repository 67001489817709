import React, { ForwardedRef, forwardRef, useImperativeHandle, useState } from 'react';

import dayjs from 'dayjs';

import CRTable from 'components/base/CRTable';
import CRTableDatePicker from 'components/base/CRTableDateSelector';
import { CheckOption } from 'components/base/Selections/type';
import EDocNoDialog from 'components/domain/dialog/EdocNoDialog';
import AnnualTOManageTable from 'components/domain/table/AnnualTOManageTable';
import { useAnnualTOHistoriesDownload, useMyAccountInfo } from 'lib/hook/react-query';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';

import { displayTOTime } from '../../../lib';
import * as S from './styles';

export interface AnnualTOManageTabForwardRef {
	downloadAnnualTOHistories: () => void;
}

function AnnualTOManageTab(_, ref: ForwardedRef<AnnualTOManageTabForwardRef>): React.ReactElement {
	const { showDialog } = useGlobalLayout();
	const [date, setDate] = useState(new Date());
	const [filter, setFilter] = useState<{ keyword: string; dutyFilter: CheckOption[] }>({
		keyword: '',
		dutyFilter: [],
	});
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: annualTOHistoriesDownload = [] } = useAnnualTOHistoriesDownload({
		centerId: myAccountInfo?.centerId ?? 0,
		keyword: filter.keyword,
		dutyCds: filter.dutyFilter.map((item) => item.value),
		basDt: dayjs(date).format('YYYYMMDD'),
	});

	const downloadAnnualTOHistories = () => {
		showDialog(() => (
			<EDocNoDialog
				viewerType='report'
				eDocParamValue={{
					crfeNm: 'template/CMN119_0083_0001.crfe',
					params: (annualTOHistoriesDownload ?? []).map((item, index) => ({
						date: dayjs(date).format('YYYY년 MM월 DD일'),
						centerNm: myAccountInfo?.centerNm ?? '',
						num: `${index + 1}`,
						managerNm: item.korMemberNm,
						assignTaskNm: item.dutyNm,
						birthDt: dayjs(item.birthDt).format('YYYY.MM.DD'),
						joinDate: dayjs(item.joinDate).format('YYYY.MM.DD'),
						workYear: item.workYearCntNm,
						annualGenerateDayCnt: item.annualStates[0]?.annualGenerateDayCnt
							? displayTOTime(item.annualStates[0]?.annualGenerateDayCnt)
							: '-',
						annualUseDayCnt: item.annualStates[0]?.annualUseDayCnt
							? displayTOTime(item.annualStates[0]?.annualUseDayCnt)
							: '-',
						annualDeductDayCnt: item.annualStates[0]?.annualDeductDayCnt
							? displayTOTime(Math.abs(item.annualStates[0]?.annualDeductDayCnt))
							: '-',
						annualRemainDayCnt: item.annualStates[0]?.annualRemainDayCnt
							? displayTOTime(item.annualStates[0]?.annualRemainDayCnt)
							: '-',
					})),
				}}
			/>
		));
	};

	useImperativeHandle(ref, () => ({ downloadAnnualTOHistories }));

	return (
		<CRTable.BackBlind>
			<S.CalendarHeaderContainer>
				<CRTableDatePicker value={date} onChange={setDate} />
			</S.CalendarHeaderContainer>
			<AnnualTOManageTable date={date} onChangeFilter={setFilter} />
		</CRTable.BackBlind>
	);
}

export default forwardRef(AnnualTOManageTab);
