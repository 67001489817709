import React, { useEffect, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { CheckOption } from 'components/base/Selections/type';
import { useEDocIssueInfoBefore } from 'lib/hook/react-query/query/edoc';
import { EmployeeDTO } from 'types/api/employee';
import { PledgeFormTypes } from 'types/view/eDoc';

import * as S from './styles';

interface Props {
	templateCode: string;
	currentEmployee?: EmployeeDTO;
	formContext: UseFormReturn<PledgeFormTypes, any, undefined>;
}

// 서약서
export default function EDocPledgeForm({ templateCode, currentEmployee, formContext }: Props) {
	const { data: eDocBeforeIssueInfo } = useEDocIssueInfoBefore({
		employeeId: currentEmployee?.employeeId,
		centerId: currentEmployee?.centerId,
		templateCode,
	});

	const recipientsOptions = useMemo(
		() =>
			(eDocBeforeIssueInfo?.data.recipient ?? []).map(
				(item) =>
					({
						label: item.recipientNm,
						value: item.recipientId,
					}) as CheckOption,
			),
		[eDocBeforeIssueInfo],
	);

	useEffect(() => {
		if (eDocBeforeIssueInfo) {
			formContext.setValue('data', eDocBeforeIssueInfo);
		}
	}, [eDocBeforeIssueInfo]);

	return (
		<S.Container>
			<S.SubFormContainer>
				<CRInputLabel label='서약자' isRequired>
					<CRInput.Selector
						autoComplete
						disabled
						currentValue={{
							label: currentEmployee?.korMemberNm || '',
							value: currentEmployee?.employeeId,
						}}
						items={[
							{
								label: currentEmployee?.korMemberNm || '',
								value: currentEmployee?.employeeId,
							},
						]}
					/>
				</CRInputLabel>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='수급자' isRequired>
							<CRInput.Selector
								currentValue={value}
								items={recipientsOptions}
								onChangeValue={onChange}
							/>
						</CRInputLabel>
					)}
					name='recipient'
					control={formContext.control}
				/>
				<CRInputLabel label='서약 내용' isRequired>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInput.TextArea onChange={onChange} value={value} placeholder='서약 내용 입력' />
						)}
						name='pledgeContent'
						control={formContext.control}
					/>
				</CRInputLabel>
			</S.SubFormContainer>
		</S.Container>
	);
}
