import { GetBurdenPayHistoriesData, GetMonthlyBurdenChargesData } from 'types/api/ownExpense';
import {
	OwnExpenseMonthlyPay,
	OwnExpensePayHistoryAdapterReturn,
	OwnExpenseUploadHistory,
} from 'types/view/ownExpense';

import { AutomationUploadDTO } from '../../types/api/common';

interface OwnExpenseMonthlyPayAdapterReturn {
	content: OwnExpenseMonthlyPay[];
	totalPages?: number;
}

export const ownExpenseMonthlyPayAdapter = (
	data: GetMonthlyBurdenChargesData | null,
): OwnExpenseMonthlyPayAdapterReturn => {
	const content = (data?.content ?? []).map((item) => ({
		id: item.burdenAmtChargeId,
		date: item.burdenAmtChargeYm,
		recipient: item.recipientNm,
		birth: item.birthDt,
		mainGuardian: item?.recipientMainGuardian?.guardianNm,
		services: item.serviceTypeNm,
		feeAmount: `${(item.feeSumAmt ?? 0).toLocaleString()}`,
		ownExpenseAmount: `${(item.burdenAmt ?? 0).toLocaleString()}`,
		claimAmount: `${(item.pcorpChargeAmt ?? 0).toLocaleString()}`,
		totalAmountAfterAddition: `${(item.additionAfterSumAmt ?? 0).toLocaleString()}`,
		sendStatus: item.sendStateNm,
		manager: item.managerNm,
	}));
	return {
		content,
		totalPages: data?.totalPages,
	};
};

export const ownExpenseHistoriesAdapter = (
	data: GetBurdenPayHistoriesData | null,
): OwnExpensePayHistoryAdapterReturn | null => {
	if (!data) return null;
	const content =
		(data?.content ?? []).map((item) => ({
			id: item.recipientId,
			recipient: item.recipientNm,
			birth: item.birthDt,
			amount: (item.burdenAmtChargeSumAmt ?? 0).toLocaleString(),
			paidAmount: (item.burdenAmtPaySumAmt ?? 0).toLocaleString(),
			paymentMethod: item.burdenAmtPayMethodNm || '-',
			status: item.burdenAmtPayStateCd,
			receipt: item.cashReceiptIssueYn
				? item.cashReceiptIssueYn === true
					? '대상자'
					: '비대상자'
				: '-',
			manager: item.managerNm,
			centerId: item.centerId,
			recipientId: item.recipientId,
			edocNo: item.edocNo,
		})) ?? [];
	return {
		...data,
		content,
	};
};

export const automationUploadAdapter = (data: AutomationUploadDTO[] | null) =>
	(data ?? []).map(
		(item) =>
			({
				id: item.uploadHistId,
				status: item.uploadStateCd,
				type: item.uploadTypeNm,
				requestDate: item.uploadStartDate,
				completeDate: item.uploadEndDate,
				uploader: item.createdNm,
				uploadResultDesc: item.uploadResultDesc,
				totalCnt: item.uploadTotalCnt,
				completeCnt: item.uploadCompleteCnt ?? 0,
				errorCnt: item.uploadRemainCnt ?? 0,
			}) as OwnExpenseUploadHistory,
	);
