import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import Colors from 'common/colors';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { Toast } from 'components/base/CRToast';
import InformationSheet from 'components/ui/InformationSheet';
import { FullTimeEmployeeTOAdjustment, displayTOTime } from 'lib';
import { commonCodeAdapter } from 'lib/adapter/common';
import {
	useCommonCodes,
	useGetFullTimeEmployeeAnnualTORemainAdjust,
	useSaveFullTimeEmployeeAnnualTORemainAdjust,
} from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import {
	FullTimeEmployeeAnnualTORemainAdjustDTO,
	FullTimeEmployeeDTO,
} from 'types/dto/full-time-employee';

import { AnnualTOAdjustmentFormRef } from '.';

interface Props {
	fullTimeEmployee: FullTimeEmployeeDTO;
	hideDialog: () => void;
	onSubmit?: (formData: FullTimeEmployeeAnnualTORemainAdjustDTO) => void;
	editItem?: FullTimeEmployeeAnnualTORemainAdjustDTO;
	setIsValid: (isValid: boolean) => void;
}

function AnnualTORemainAdjustForm(
	{ fullTimeEmployee, hideDialog, onSubmit, editItem, setIsValid }: Props,
	ref,
): React.ReactElement {
	const [data, setData] = useState<FullTimeEmployeeAnnualTORemainAdjustDTO | undefined>(undefined);
	const {
		control,
		watch,
		formState: { isValid },
	} = useForm<{
		adjustmentCnt: number;
		adjustmentHourCnt: number;
		reason: string;
	}>({
		defaultValues: {
			adjustmentCnt: editItem?.adjustAnnualDayCnt ?? 0,
			adjustmentHourCnt: editItem?.adjustAnnualTimeCnt ?? 0,
			reason: editItem?.annualAdjustDesc ?? '',
		},
		resolver: yupResolver(FullTimeEmployeeTOAdjustment),
	});
	const { data: commonCodes = { CMN191: [] } } = useCommonCodes(
		{ comCdGroupNms: ['CMN191'] },
		commonCodeAdapter,
	);

	const getFullTimeEmployeeAnnualTORemainAdjust = useGetFullTimeEmployeeAnnualTORemainAdjust();
	const saveFullTimeEmployeeAnnualTORemainAdjust = useSaveFullTimeEmployeeAnnualTORemainAdjust(
		(client) => {
			client.invalidateQueries([
				endpoint.getFullTimeEmployeeAnnualTOHistories.key,
				{
					centerId: fullTimeEmployee.centerId,
					employeeId: fullTimeEmployee.employeeId,
					memberId: fullTimeEmployee.memberId,
				},
			]);
			client.invalidateQueries([
				endpoint.getFullTimeEmployeeDetailInfo.key,
				{
					centerId: fullTimeEmployee.centerId,
					employeeId: fullTimeEmployee.employeeId,
					memberId: fullTimeEmployee.memberId,
				},
			]);
			Toast.success('연차 차감 조정이 성공하였습니다.');
			hideDialog();
		},
		(client, error) => {
			Toast.error(error.message);
			hideDialog();
		},
	);

	const submit = async () => {
		if (!data) return;
		const form = {
			...editItem,
			rowType: data.rowType ?? '',
			employeeId: fullTimeEmployee.employeeId,
			centerId: fullTimeEmployee.centerId,
			joinDate: data.joinDate,
			workAnnualItemCd: 'CMN191.30',
			workYearCntCd: data.workYearCntCd ?? 'CMN190.0002',
			beforeAnnualDayCnt: data.beforeAnnualDayCnt,
			beforeAnnualDayCntNm: data.beforeAnnualDayCntNm,
			adjustAnnualDayCnt: watch('adjustmentCnt'),
			adjustAnnualTimeCnt: watch('adjustmentHourCnt') * 0.125,
			adjustAnnualCnt: watch('adjustmentCnt') + watch('adjustmentHourCnt') * 0.125,
			afterAnnualDayCnt:
				data.beforeAnnualDayCnt + watch('adjustmentCnt') + watch('adjustmentHourCnt') * 0.125,
			afterAnnualDayCntNm: `${
				data.beforeAnnualDayCnt + watch('adjustmentCnt') + watch('adjustmentHourCnt') * 0.125
			}일`,
			annualAdjustDesc: watch('reason'),
		};
		if (onSubmit) {
			onSubmit(form);
		} else {
			await saveFullTimeEmployeeAnnualTORemainAdjust.mutateAsync(form);
		}
	};

	const fetchInitialData = useCallback(async () => {
		if (editItem) {
			setData(editItem);
			return;
		}
		const result = await getFullTimeEmployeeAnnualTORemainAdjust.mutateAsync({
			centerId: fullTimeEmployee.centerId,
			employeeId: fullTimeEmployee.employeeId,
			memberId: fullTimeEmployee.memberId,
		});
		if (result) setData(result);
	}, [editItem]);

	const checkRemainDay = (count: number): number => {
		const noTimeRemain = !(count - Math.floor(count)) && Math.floor(count) === 1;
		return count > 1 || noTimeRemain ? watch('adjustmentCnt') - 1 : watch('adjustmentCnt');
	};

	const checkRemainTime = (count: number): number =>
		count > 0 ? -8 : -(count - Math.floor(count)) * 8;

	useEffect(() => {
		fetchInitialData();
	}, []);

	useEffect(() => {
		setIsValid(isValid);
	}, [isValid]);

	useImperativeHandle(ref, () => ({
		submit,
	}));

	if (!data) return <div />;

	return (
		<>
			<CRInputLabel label='조정' isRequired>
				<InformationSheet
					type='gray'
					items={[
						[
							{
								label: '잔여 연차',
								value: displayTOTime(data?.beforeAnnualDayCnt ?? 0),
								valueStyle: {
									height: '4rem',
								},
							},
						],
						[
							{
								label: '차감일수 (일)',
								value: (
									<Controller
										control={control}
										name='adjustmentCnt'
										render={({ field }) => (
											<CRInput.Counter
												value={field.value}
												onChange={field.onChange}
												suffix='일'
												countStepNumber={-1}
												max={0}
												min={checkRemainDay(
													data.beforeAnnualDayCnt +
														watch('adjustmentCnt') +
														watch('adjustmentHourCnt') * 0.125,
												)}
												plusColor={Colors.blue}
												minusColor={Colors.red}
											/>
										)}
									/>
								),
								valueStyle: {
									height: '4rem',
								},
							},
						],
						[
							{
								label: '차감일수 (시간)',
								value: (
									<Controller
										control={control}
										name='adjustmentHourCnt'
										render={({ field }) => (
											<CRInput.Counter
												value={field.value}
												onChange={field.onChange}
												suffix='시간'
												countStepNumber={-1}
												min={checkRemainTime(
													data.beforeAnnualDayCnt +
														watch('adjustmentCnt') +
														watch('adjustmentHourCnt') * 0.125,
												)}
												max={0}
												plusColor={Colors.blue}
												minusColor={Colors.red}
											/>
										)}
									/>
								),
								valueStyle: {
									height: '4rem',
								},
							},
						],
						[
							{
								type: 'divider',
							},
						],
						[
							{
								type: 'highlight',
								label: '조정 후 잔여 연차',
								value: displayTOTime(
									data.beforeAnnualDayCnt +
										watch('adjustmentCnt') +
										watch('adjustmentHourCnt') * 0.125,
								),
								valueStyle: {
									height: '4rem',
								},
							},
						],
					]}
				/>
			</CRInputLabel>
			<Controller
				control={control}
				name='reason'
				render={({ field }) => (
					<CRInputLabel label='사유' isRequired renderRightAddon={`${field.value?.length ?? 0}/50`}>
						<CRInput.TextArea
							placeholder='사유 입력'
							maxLength={50}
							value={field.value}
							onChange={field.onChange}
						/>
					</CRInputLabel>
				)}
			/>
		</>
	);
}

export default React.forwardRef<AnnualTOAdjustmentFormRef, Props>(AnnualTORemainAdjustForm);
