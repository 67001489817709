import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import useDialog from 'lib/hook/util/useDialog';

import * as S from './styles';

interface Props {
	onSync: (data: { yearMonth: Date }) => void;
	title?: string;
}

export function SyncDialog({ title = '', onSync }: Props) {
	const { watch, control, trigger, handleSubmit } = useForm<{ yearMonth: Date }>();
	const { hideDialog } = useDialog();

	const onSubmit = (data: { yearMonth: Date }) => {
		onSync(data);
	};

	const submitForm = () => {
		trigger().then((isValid) => {
			if (isValid) {
				handleSubmit(onSubmit)();
			}
		});
	};

	return (
		<CRDialog
			title={`${title} 동기화`}
			onClickClose={hideDialog}
			body={
				<S.Container>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel isRequired label='연월'>
								<CRInput.YearMonthPicker
									placeholder='연월 선택'
									currentValue={value}
									onChangeValue={onChange}
								/>
							</CRInputLabel>
						)}
						name='yearMonth'
						control={control}
					/>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						palette='primary'
						size='default'
						disabled={!watch('yearMonth')}
						onClick={submitForm}>
						동기화
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
