import styled from 'styled-components';

import CRButton from 'components/base/CRButton';

export const HoldingDialogContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2.4rem;
	gap: 1.6rem;
	overflow: hidden;
	border-top: 1px solid ${(props) => props.theme.colors.gray90};
`;

export const LeftContentContainer = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	height: 100%;
	gap: 0.8rem;
`;

export const TableContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	width: 100%;
	height: 100%;
	box-sizing: content-box;
	border-radius: 0.4rem;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const RecipientTextContainer = styled.div`
	display: flex;
	flex-direction: row;
	bottom: 0;
	width: 100%;
	gap: 1rem;
	padding: 0.4rem 0.8rem;
	background-color: ${(props) => props.theme.colors.white};

	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-bottom-left-radius: 0.4rem;
	border-bottom-right-radius: 0.4rem;
`;

export const DayContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.2rem;
	height: 100%;
	overflow: auto;
`;

export const DayHeaderContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 3.6rem;
	padding: 0 0.8rem;
`;

export const DayHeaderContent = styled.div`
	display: flex;
	max-width: calc(100% - 4rem);
	align-items: center;
	gap: 0.4rem;
	margin-right: 1.6rem;
`;

export const DayHeaderTag = styled.div`
	display: flex;
	align-items: center;
	height: 2.5rem;
	padding: 0 0.4rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: 0.4rem;
	overflow: hidden;
`;

export const Today = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	box-sizing: border-box;
	width: 2.4rem;
	height: 2.4rem;
	background: ${(props) => props.theme.colors.primary60};
	color: ${(props) => props.theme.colors.gray100};
`;

export const DayContentContainer = styled.div`
	padding: 0 0.8rem;
`;

export const Holiday = styled.div`
	width: 100%;
	height: 24px;
	${(props) => props.theme.typography.label};
	border-radius: 0.4rem;
	padding: 0 0.8rem;
	overflow: hidden;
	background-color: ${(props) => props.theme.colors.primary90};
	color: ${(props) => props.theme.colors.primary20};
`;

export const VisitContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.2rem;
	cursor: pointer;
`;

export const VisitStatus = styled.div`
	width: 1.4rem;
	height: 1.4rem;
	border-radius: 1.4rem;
	background-color: ${(props) => props.theme.colors.green};
`;

export const BannerContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1rem;
`;

export const InputContainer = styled.div`
	width: 27rem;
`;

export const InputWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1rem;
`;

export const RightContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
	overflow: hidden;
	gap: 0.8rem;
`;

export const CalendarHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const Calendar = styled.div`
	overflow: scroll;
	.react-datepicker {
		border: none;
		background: ${(props) => props.theme.colors.gray100};
	}

	.react-datepicker__aria-live {
		display: none;
	}

	.react-datepicker__header {
		min-width: 80rem;
		width: 100%;
		background: none;
		border-bottom: 0.2rem solid ${(props) => props.theme.colors.gray90};
	}

	.react-datepicker__day-names {
		display: flex;
		width: 100%;
		margin: 0;
		margin-bottom: 0.8rem;
	}

	.react-datepicker__day-name {
		${(props) => props.theme.typography.label};
		display: flex;
		justify-content: flex-end;
		flex: 1;
		margin: 0;
		color: ${(props) => props.theme.colors.gray60};
	}

	.react-datepicker__day-name:first-child {
		color: ${(props) => props.theme.colors.primary60};
	}

	.react-datepicker__month {
		margin: 0;
		border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};
		border-bottom: 0.2rem solid ${(props) => props.theme.colors.gray90};
		overflow: auto;
		min-width: 80rem;
	}

	.react-datepicker__month-container {
		overflow: auto;
	}

	.react-datepicker__day--weekend {
		background: ${(props) => props.theme.colors.gray99};
	}

	.react-datepicker__week {
		display: flex;
	}

	.react-datepicker__week + .react-datepicker__week {
		border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	}

	.react-datepicker__day {
		width: 100%;
		min-height: 14.8rem;
		margin: 0;
		box-sizing: border-box;
		border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	}

	.react-datepicker__day--outside-month ${DayContainer} {
		color: ${(props) => props.theme.colors.gray60};
	}

	.react-datepicker__day--keyboard-selected {
		background: none;
	}
`;
