export const ROUNDING_FILTER_CONFIG = [
	{
		key: 'statusFilter',
		type: 'multi',
		placeholder: '상태',
		options: [
			{
				label: '완료',
				value: true,
			},
			{
				label: '미완료',
				value: false,
			},
		],
	},
	{
		key: 'tagFilter',
		type: 'multi',
		placeholder: '태그여부',
		options: [
			{
				label: '태그',
				value: true,
			},
			{
				label: '미태그',
				value: false,
			},
		],
	},
];
