import React from 'react';

import dayjs from 'dayjs';

import CRRsdNumber from 'components/base/CRRsdNumber';
import { displayPhoneNumber } from 'lib';
import { FullTimeEmployeeBaseInfoDTO } from 'types/dto/full-time-employee';

import * as S from './styles';

interface Props {
	item?: FullTimeEmployeeBaseInfoDTO | null;
	isResign?: boolean;
}

function FullTimeEmployeeDetailBasicInformationTable({
	item,
	isResign,
}: Props): React.ReactElement {
	if (isResign) {
		return (
			<S.Table>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '10.4rem' }}>이름</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '24rem' }}>{item?.korMemberNm}</S.TableValueColumn>
					<S.TableLabelColumn style={{ width: '12rem' }}>전화번호</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '24rem' }}>
						{item?.mobilePhoneNo ? displayPhoneNumber(item?.mobilePhoneNo) : '-'}
					</S.TableValueColumn>
					<S.TableLabelColumn style={{ width: '16rem' }}>입사일</S.TableLabelColumn>
					<S.TableValueColumn style={{ width: '24rem', paddingRight: '5.3rem' }}>
						{item?.joinDate ? dayjs(item?.joinDate).format('YYYY-MM-DD') : '-'}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '12rem' }}>주민등록번호</S.TableLabelColumn>
					<S.TableValueColumn
						style={{
							width: '24rem',
							paddingRight: '5.3rem',
						}}>
						<CRRsdNumber rdsNumber={item?.rsdnNo} key={item?.rsdnNo} />
					</S.TableValueColumn>
					<S.TableLabelColumn style={{ width: '16rem' }}>직무</S.TableLabelColumn>
					<S.TableValueColumn
						style={{
							width: '24rem',
							paddingRight: '5.3rem',
						}}>
						{item?.dutyNm ?? '-'}
					</S.TableValueColumn>
					<S.TableLabelColumn style={{ width: '16rem' }}>퇴사일</S.TableLabelColumn>
					<S.TableValueColumn
						style={{
							width: '24rem',
							paddingRight: '5.3rem',
						}}>
						{item?.resignDate ? dayjs(item?.resignDate).format('YYYY-MM-DD') : '-'}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '10.4rem' }}>주소</S.TableLabelColumn>
					<S.TableValueColumn colSpan={5}>{item?.fullAddr || '-'}</S.TableValueColumn>
				</S.TableRow>
			</S.Table>
		);
	}
	return (
		<S.Table>
			<S.TableRow>
				<S.TableLabelColumn style={{ width: '10.4rem' }}>이름</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>{item?.korMemberNm}</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '12rem' }}>전화번호</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>
					{item?.mobilePhoneNo ? displayPhoneNumber(item?.mobilePhoneNo) : '-'}
				</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '16rem' }}>입사일</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem', paddingRight: '5.3rem' }}>
					{item?.joinDate ? dayjs(item?.joinDate).format('YYYY-MM-DD') : '-'}
				</S.TableValueColumn>
			</S.TableRow>
			<S.TableRow>
				<S.TableLabelColumn style={{ width: '12rem' }}>직무</S.TableLabelColumn>
				<S.TableValueColumn
					style={{
						width: '24rem',
						paddingRight: '5.3rem',
					}}>
					{item?.dutyNm ?? '-'}
				</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '16rem' }}>업무대행자</S.TableLabelColumn>
				<S.TableValueColumn
					style={{
						width: '24rem',
						paddingRight: '5.3rem',
					}}>
					{item?.workExecuteActingEmployeeNm ?? '-'}
				</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '16rem' }}>퇴사일</S.TableLabelColumn>
				<S.TableValueColumn
					style={{
						width: '24rem',
						paddingRight: '5.3rem',
					}}>
					{item?.resignDate ? dayjs(item?.resignDate).format('YYYY-MM-DD') : '-'}
				</S.TableValueColumn>
			</S.TableRow>
			<S.TableRow>
				<S.TableLabelColumn style={{ width: '10.4rem' }}>비고</S.TableLabelColumn>
				<S.TableValueColumn colSpan={5}>{item?.employeeSpecialDesc || '-'}</S.TableValueColumn>
			</S.TableRow>
		</S.Table>
	);
}

export default FullTimeEmployeeDetailBasicInformationTable;
