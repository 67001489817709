import React, { useCallback } from 'react';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import { roundingHistoryAutomationUploadAdapter } from 'lib/adapter/roundingHistory';
import { useAutomationUploadList } from 'lib/hook/react-query';
import { RoundingScheduleUploadHistory } from 'types/view/roundingHistory';

import {
	UPLOAD_HISTORY_STATUS_OPTIONS,
	VISIT_SCHEDULE_UPLOAD_HISTORY_TABLE_HEADER_CONFIG,
} from './constants';
import * as S from './styles';

interface Props {
	centerId: number;
	onClickClose?: () => void;
	onClickItem?: (item: RoundingScheduleUploadHistory) => void;
}

function VisitScheduleUploadHistoryDialog({
	centerId,
	onClickClose,
	onClickItem,
}: Props): React.ReactElement {
	const Status = useCallback(
		(status: string) => <CRStatus options={UPLOAD_HISTORY_STATUS_OPTIONS}>{status}</CRStatus>,
		[onClickItem],
	);

	const { data: uploadHistories = [] } = useAutomationUploadList(
		{
			centerId,
			uploadTypeCds: ['CMN107.MANAGER_RECIPIENT_M, CMN107.PCORP_MANAGER_RFID'],
		},
		roundingHistoryAutomationUploadAdapter,
	);

	const Button = useCallback(
		(id: number, item?: RoundingScheduleUploadHistory) => {
			if (!item || !item.uploadResultDesc) return <div />;
			return (
				<S.ButtonContainer>
					<CRButton.Default
						type='outlined'
						size='xSmall'
						palette='gray'
						onClick={() => onClickItem?.(item)}>
						결과
					</CRButton.Default>
				</S.ButtonContainer>
			);
		},
		[onClickItem],
	);

	return (
		<CRDialog
			type='L'
			title='방문일정 동기화 실행 이력'
			body={
				<S.Container>
					<CRTable.Root>
						<CRTable.Head
							heads={VISIT_SCHEDULE_UPLOAD_HISTORY_TABLE_HEADER_CONFIG}
							disableBackBlind
							offset={1}
						/>
						<CRTable.Body>
							{uploadHistories.map((item) => (
								<CRTable.Row
									key={item.id}
									item={item}
									customRender={{
										status: Status,
										id: Button,
									}}
									renderKeys={[
										'status',
										'type',
										'requestDate',
										'completeDate',
										'uploadTargetYm',
										'uploader',
										'id',
									]}
									customStyle={{
										status: {
											paddingLeft: '2.4rem',
										},
									}}
								/>
							))}
						</CRTable.Body>
					</CRTable.Root>
				</S.Container>
			}
			onClickClose={onClickClose}
		/>
	);
}

export default VisitScheduleUploadHistoryDialog;
