import { useContext } from 'react';

import { ContractRenewalTaskContext } from 'lib/provider/contractRenewal';

export default function useContractRenewalTask() {
	const context = useContext(ContractRenewalTaskContext);
	if (!context) {
		throw new Error('useContractRenewalTask must be used within a ContractRenewalTaskContext');
	}
	return context;
}
