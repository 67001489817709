import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';

import dayjs from 'dayjs';

import Assets from 'assets';
import CRDropdown from 'components/base/CRDropdown';

import * as S from './styles';

interface Props {
	disabled?: boolean;
	initDate?: Date;
	onChangeValue?: (date: Date) => void;
}

function MonthPickerButton({
	disabled = false,
	onChangeValue,
	initDate,
}: Props): React.ReactElement {
	const nodeRef = useRef(null);
	const [showOptions, setShowOptions] = useState(false);
	const [date, setDate] = useState<Date | null>(initDate || new Date());
	const [rangeDate, setRangeDate] = useState(initDate || new Date());

	const filterRef = useRef<HTMLDivElement>(null);
	const datePickerRef = useRef<HTMLDivElement>(null);

	const handleToggleOptions = () => {
		setShowOptions((prev) => !prev);
	};

	const renderMonthCalendar = () => {
		const year = dayjs(rangeDate).get('year');
		const months = Array.from({ length: 12 })
			.fill(null)
			.map((e, i) => dayjs(rangeDate).set('month', i).toDate());
		const caringStartDate = dayjs('2019.01.01').toDate();

		const onClickItem = (currentItemDate: Date) => {
			const isExist = date && dayjs(date).isSame(dayjs(currentItemDate));
			setDate(isExist ? null : currentItemDate);
			onChangeValue?.(currentItemDate);
			setShowOptions(false);
		};

		const isBeforeCaringStartDate = dayjs(rangeDate).subtract(1, 'year').isBefore(caringStartDate);

		return (
			<S.MonthYearWrapper>
				<S.MonthYearHeader>
					<S.MonthYearIcon
						src={Assets.icon.keyboardArrowLeft}
						alt='이전달'
						onClick={() =>
							!isBeforeCaringStartDate &&
							setRangeDate(dayjs(rangeDate).subtract(1, 'year').toDate())
						}
					/>
					<S.YearText>{year}</S.YearText>

					<S.MonthYearIcon
						src={Assets.icon.keyboardArrowRight}
						alt='다음달'
						onClick={() => setRangeDate(dayjs(rangeDate).add(1, 'year').toDate())}
					/>
				</S.MonthYearHeader>
				<S.MonthYearContainer>
					{[0, 1, 2].map((index) => (
						<S.MonthYearWeek key={index}>
							{months.slice(index * 4, index * 4 + 4).map((month) => {
								const isActive = !!(dayjs(month).format('YYYYMM') === dayjs(date).format('YYYYMM'));
								return (
									<S.MonthMonthYearItem
										key={String(month)}
										$isActive={isActive}
										onClick={() => onClickItem(month)}>
										{`${dayjs(month).get('month') + 1}월`}
									</S.MonthMonthYearItem>
								);
							})}
						</S.MonthYearWeek>
					))}
				</S.MonthYearContainer>
			</S.MonthYearWrapper>
		);
	};

	const renderCalendarPopup = () => (
		<Draggable defaultPosition={{ x: 0, y: 0 }} bounds='body' nodeRef={nodeRef}>
			<CRDropdown style={{ width: 'fit-content' }}>
				<S.CalendarContainer ref={datePickerRef}>{renderMonthCalendar()}</S.CalendarContainer>
			</CRDropdown>
		</Draggable>
	);

	useEffect(() => {
		const handleMouseOut = (e: MouseEvent) => {
			if (!filterRef.current?.contains(e.target as Node)) {
				setShowOptions(false);
			}
		};
		document.addEventListener('mousedown', handleMouseOut as any);

		return () => {
			document.removeEventListener('mousedown', handleMouseOut as any);
		};
	}, []);

	return (
		<S.Container ref={filterRef} $disabled={disabled}>
			<S.CalendarIcon
				$isDisabled={disabled}
				src={Assets.icon.calendarToday}
				alt='calendarToday'
				onClick={handleToggleOptions}
			/>
			{!disabled && showOptions && renderCalendarPopup()}
		</S.Container>
	);
}

export default MonthPickerButton;
