import React, { useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import { IntegratedAssessmentBedsoreForms } from 'types/view/recipient';

import IntegratedBedsoreFormHeader from './IntegratedBedsoreFormHeader';
import IntegratedBedsoreFormScoreInfo from './IntegratedBedsoreFormScoreInfo';
import { integratedAssessmentBedsoreQuestions } from './constant';
import * as S from './styles';

interface Props {
	form: UseFormReturn<IntegratedAssessmentBedsoreForms, any, undefined>;
}

function IntegratedBedsoreForm({ form }: Props): React.ReactElement {
	const { watch, control } = form;
	const totalPoint = useMemo(
		() =>
			watch('활동상태') +
			watch('마찰력과응전력') +
			watch('습기여부') +
			watch('움직임') +
			watch('영양상태') +
			watch('감각인지여부'),
		[watch()],
	);
	return (
		<S.Container>
			<S.Table>
				<IntegratedBedsoreFormHeader />
				<S.TableBody>
					{integratedAssessmentBedsoreQuestions.map((question) => (
						<Controller
							render={({ field: { ref, onChange, value }, formState: { errors } }) => (
								<>
									{question.answer.map((answer, index) => (
										<S.TableBodyRow>
											{index === 0 && (
												<S.TableBodyColumn rowSpan={index === 0 ? question.answer.length : 1}>
													{question.label}
												</S.TableBodyColumn>
											)}
											<S.TableBodyColumn>
												<CRCheckBox
													checkBoxType='radio'
													onChange={() => {
														onChange(answer.point);
													}}
													checked={value === answer.point}>
													{answer.label}
												</CRCheckBox>
											</S.TableBodyColumn>
											<S.TableBodyColumn>{answer.description}</S.TableBodyColumn>
											{index === 0 && (
												<S.TableBodyColumn rowSpan={index === 0 ? question.answer.length : 1}>
													{watch(question.key)}
												</S.TableBodyColumn>
											)}
										</S.TableBodyRow>
									))}
								</>
							)}
							name={question.key}
							control={control}
						/>
					))}
					<S.TableBodyTotalRow>
						<S.TableBodyTotalColumn colSpan={3}>총점</S.TableBodyTotalColumn>
						<S.TableBodyColumn>{totalPoint}</S.TableBodyColumn>
					</S.TableBodyTotalRow>
				</S.TableBody>
			</S.Table>
			<IntegratedBedsoreFormScoreInfo />
			<Controller
				render={({ field: { ref, onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='비고'>
						<CRInput.TextArea ref={ref} placeholder='비고 입력' onChange={onChange} value={value} />
					</CRInputLabel>
				)}
				name='욕창비고'
				control={control}
			/>
		</S.Container>
	);
}

export default IntegratedBedsoreForm;
