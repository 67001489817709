import styled from 'styled-components';

export const Container = styled.div`
	padding: 0 2.4rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 0.8rem;
`;
