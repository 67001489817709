import React from 'react';

import { displayNumber } from 'lib';
import { LocalNavigationSection } from 'types/view/task';

import ScrollSpySection from './ScrollSpySection';
import * as S from './styles';

interface IProps {
	currentKey?: string;
	sections?: LocalNavigationSection[];
	hideHeader?: boolean;
	hideDivider?: boolean;
	hideProgress?: boolean;
	hideCollapse?: boolean;
	customHeader?: (props?: { index?: string; label?: string; open?: boolean }) => React.ReactElement;
	customDivider?: React.ReactElement;
}

function CRScrollSpy({
	currentKey,
	sections = [],
	customHeader,
	customDivider,
	hideHeader,
	hideDivider,
	hideProgress,
	hideCollapse,
}: IProps): React.ReactElement {
	return (
		<S.Container>
			{sections.map((section, index) => (
				<React.Fragment key={section.label}>
					<ScrollSpySection
						key={section.label}
						section={section}
						indexLabel={displayNumber(index + 1)}
						currentKey={currentKey}
						customHeader={customHeader}
						hideHeader={hideHeader}
						hideProgress={hideProgress}
						hideCollapse={hideCollapse}
					/>
					{!hideDivider &&
						(index !== sections.length && customDivider ? customDivider : <S.Divider />)}
				</React.Fragment>
			))}
		</S.Container>
	);
}

export default CRScrollSpy;
