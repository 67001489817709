import React from 'react';
import { useParams } from 'react-router-dom';

import * as Accordion from '@radix-ui/react-accordion';

import EmployeeDetailBasicInformationTable from 'components/domain/table/EmployeeDetailBasicInformationTable';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import { useEmployeePageBaseInfo, useMyAccountInfo } from 'lib/hook/react-query';

import * as S from './styles';

export default function BasicInfo() {
	const { data: myAccountInfo } = useMyAccountInfo();
	const params = useParams<{ id: string }>();
	const { data: currentEmployeeBase } = useEmployeePageBaseInfo({
		centerId: myAccountInfo?.centerId,
		employeeId: Number(params.id),
	});

	return (
		<Accordion.Item value='기본 정보' asChild>
			<TaskAccordion.Item>
				<Accordion.Header asChild>
					<Accordion.Trigger asChild>
						<TaskAccordion.Trigger>기본 정보</TaskAccordion.Trigger>
					</Accordion.Trigger>
				</Accordion.Header>
				<Accordion.Content asChild>
					<TaskAccordion.Content>
						<S.FormContainer>
							<EmployeeDetailBasicInformationTable item={currentEmployeeBase} />
						</S.FormContainer>
					</TaskAccordion.Content>
				</Accordion.Content>
			</TaskAccordion.Item>
		</Accordion.Item>
	);
}
