import React, { useState } from 'react';

import { v4 } from 'uuid';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRIcon from 'components/base/CRIcon';
import { CRText } from 'components/base/CRText';
import CRTextList from 'components/base/CRTextList';

import * as S from './styles';

interface Props {
	documentName: string;
	onSubmit: () => void;
	onCancel: () => void;
	listStyle?: 'ul' | 'ol';
}

function EDocSubmitDialog({ documentName, onCancel, onSubmit, listStyle = 'ul' }: Props) {
	const [fakeLoading, setFakeLoading] = useState(false);

	const handleSubmit = () => {
		if (fakeLoading) return;
		onSubmit?.();
		setFakeLoading(true);
		setTimeout(() => {
			setFakeLoading(false);
		}, 3000);
	};

	return (
		<FlexContainer direction='column' gap='2rem' align='center' padding='2rem 1rem'>
			<FlexContainer direction='column' gap='1rem' align='center'>
				<CRIcon src={Assets.icon.checkCircle} width='3.6rem' height='3.6rem' />
				<FlexContainer wrap='wrap' justify='center'>
					<CRText typography='bodyB' text={documentName} margin=' 0 0.2rem 0 0' />
					<CRText text=' 을(를)' />
					<CRText color='red' text=' 제출' margin=' 0 0 0 0.4rem' />
					<CRText text='하시겠습니까?' />
				</FlexContainer>
			</FlexContainer>

			<FlexContainer direction='column' gap='1rem' align='center'>
				<S.TitleWrapper>
					<CRText typography='bodyB' text='제출 전 최종 확인사항' />
				</S.TitleWrapper>
				<CRTextList
					listStyle={listStyle}
					items={[
						{
							id: v4(),
							item: <CRText text='담당 사회복지사가 최종 확인 후 서명이 완료됩니다.' />,
						},
						{
							id: v4(),
							item: (
								<CRText text='전자서명법, 전자문서 및 전자거래 기본법에 따라 서명을 전자서명으로 하는것에 대해 동의합니다.' />
							),
						},
						{ id: v4(), item: <CRText text='모든 서명자가 서명한 문서를 원본으로 인정합니다.' /> },
					]}
				/>
			</FlexContainer>
			<FlexContainer direction='column' width='100%' gap='0.5rem'>
				<CRButton.Default onClick={handleSubmit}>
					<CRText typography='bodyB' color='white' text='동의 및 제출' />
				</CRButton.Default>
				<CRButton.Default onClick={onCancel} palette='gray' type='tonal'>
					<CRText typography='bodyB' text='취소' />
				</CRButton.Default>
			</FlexContainer>
		</FlexContainer>
	);
}
export default EDocSubmitDialog;
