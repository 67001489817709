import { styled } from 'styled-components';

export const Container = styled.div`
	position: relative;
	padding: 1.6rem;
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	gap: 2.4rem;
	overflow: auto;
	min-height: 100%;
`;

export const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	gap: 0.8rem;
`;

export const LeftButtonContainer = styled.div`
	flex: 1;
	display: flex;
	gap: 0.8rem;
`;

export const WithdrawalButtonContainer = styled.div``;
