import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import * as Accordion from '@radix-ui/react-accordion';

import IASalaryOfferPlanForm from 'components/domain/form/WorkExecuteInspectionLogForm/IASalaryOfferPlanForm';
import RecognitiveActivityProgramForm from 'components/domain/form/WorkExecuteInspectionLogForm/RecognitiveActivityProgramForm';
import WorkExecuteLogBasicInfoForm, {
	WorkExecuteLogCode,
} from 'components/domain/form/WorkExecuteInspectionLogForm/WorkExecuteLogBasicInfoForm';
import WorkExecuteLogCheckForm from 'components/domain/form/WorkExecuteInspectionLogForm/WorkExecuteLogCheckForm';
import WorkExecuteLogGuardianConsultForm from 'components/domain/form/WorkExecuteInspectionLogForm/WorkExecuteLogGuardianConsultForm';
import WorkExecuteLogVisitForm from 'components/domain/form/WorkExecuteInspectionLogForm/WorkExecuteLogVisitForm';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import { useOpenEDocManager } from 'lib/hook/react-query';
import useWorkExecuteInspectionLog from 'lib/hook/view/workExecuteInspectionLog/useWorkExecuteInspectionLog';

function WorkExecuteInspectionLogBasicPage(): React.ReactElement {
	const { recipient, form, setEdocData, setType } = useWorkExecuteInspectionLog();
	const { mutateAsync: openEDocManager } = useOpenEDocManager();
	const params = useParams();
	const [forms, setForms] = useState<
		{
			id: string;
			label: string;
			component: JSX.Element;
		}[]
	>();

	const workExecuteLogDivCd = form.watch('workExecuteLogDivCd');

	useEffect(() => {
		const newForms = [
			{
				id: '#1',
				label: '기본 정보',
				component: <WorkExecuteLogBasicInfoForm disabled={false} recipient={recipient} />,
			},
			{
				id: '#2',
				label: '방문 정보',
				component: <WorkExecuteLogVisitForm disabled={false} />,
			},
			{
				id: '#4',
				label: '상담',
				component: <WorkExecuteLogGuardianConsultForm disabled={false} />,
			},
			{
				id: '#5',
				label: '급여 및 인지활동 프로그램 제공 확인',
				component: <WorkExecuteLogCheckForm disabled={false} />,
			},
			{
				id: '#6',
				label: '욕구사정 및 급여제공계획',
				component: <IASalaryOfferPlanForm disabled={false} />,
			},
		];

		if (workExecuteLogDivCd?.[0]?.value === WorkExecuteLogCode.프로그램관리자) {
			newForms.splice(2, 0, {
				id: '#3',
				label: '인지활동 프로그램 제공 계획',
				component: <RecognitiveActivityProgramForm disabled={false} />,
			});
		}

		setForms(newForms);
	}, [workExecuteLogDivCd]);

	useEffect(() => {
		setType('inspection');
		if (params.sendUuid) {
			openEDocManager({
				sendUuid: params.sendUuid,
				birthDt: '',
				isEsign: true,
			}).then((result) => {
				if (result?.data) {
					setEdocData(result?.data);
				}
			});
		}
	}, [params.sendUuid]);

	if (!forms?.length) return <div />;

	return (
		<Accordion.Root
			type='multiple'
			defaultValue={Array.from({ length: 6 }).map((_, index) => `#${index + 1}`)}>
			{forms?.map((form) => (
				<Accordion.Item value={form.id} key={form.id} asChild>
					<TaskAccordion.Item>
						<Accordion.Header asChild>
							<Accordion.Trigger asChild>
								<TaskAccordion.Trigger href={form.id}>{form.label}</TaskAccordion.Trigger>
							</Accordion.Trigger>
						</Accordion.Header>
						<Accordion.Content asChild>
							<TaskAccordion.Content>{form.component}</TaskAccordion.Content>
						</Accordion.Content>
					</TaskAccordion.Item>
				</Accordion.Item>
			))}
		</Accordion.Root>
	);
}

export default WorkExecuteInspectionLogBasicPage;
