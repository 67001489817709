import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInputMessage from 'components/base/CRInputMessage';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import { recipientHistoryOfHoldingAdapter } from 'lib/adapter/recipient';
import {
	useCreateRecipientHistoryOfHolding,
	useRecipientHistoryOfHolding,
	useUpdateRecipientHistoryOfHolding,
} from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import { Recipient, RecipientHoldingHistoryType } from 'types/view/recipient';

import * as S from './styles';

interface Props {
	currentRecipient?: Recipient;
	editItem?: RecipientHoldingHistoryType;
	onClickClose?: () => void;
}

export type RecipientAddHistoryOfManagerForm = {
	holdingDate: {
		start: Date | null;
		end: Date | null;
	};
	reason: string;
};

function RecipientAddHistoryOfRecipientHoldingDialog({
	editItem,
	currentRecipient,
	onClickClose,
}: Props): React.ReactElement {
	const { data: historyOfHolding = [] } = useRecipientHistoryOfHolding(
		{
			recipientId: currentRecipient?.recipientId ?? 0,
		},
		recipientHistoryOfHoldingAdapter,
	);
	const {
		control,
		getValues,
		setValue,
		watch,
		setError,
		clearErrors,
		formState: { errors },
	} = useForm<RecipientAddHistoryOfManagerForm>({});

	const createRecipientHistoryOfHolding = useCreateRecipientHistoryOfHolding((client) => {
		client.invalidateQueries([
			endpoint.getRecipientHoldingHistories.key,
			{ recipientId: currentRecipient?.recipientId },
		]);
	});
	const updateRecipientHistoryOfHolding = useUpdateRecipientHistoryOfHolding((client) => {
		client.invalidateQueries([
			endpoint.getRecipientHoldingHistories.key,
			{ recipientId: currentRecipient?.recipientId },
		]);
	});

	const isOverlapping = useCallback(
		(start: Date, end: Date) => {
			const newStart = dayjs(start);
			const newEnd = dayjs(end);

			return historyOfHolding.some((history) => {
				const historyStart = dayjs(history.period.split('~')[0].trim());
				const historyEnd = dayjs(history.period.split('~')[1].trim());

				const isStartOverlapping =
					(newStart.isAfter(historyStart) || newStart.isSame(historyStart)) &&
					(newStart.isBefore(historyEnd) || newStart.isSame(historyEnd));
				const isEndOverlapping =
					(newEnd.isAfter(historyStart) || newEnd.isSame(historyStart)) &&
					(newEnd.isBefore(historyEnd) || newEnd.isSame(historyEnd));
				const isFullyOverlapping = newStart.isBefore(historyStart) && newEnd.isAfter(historyEnd);

				return isStartOverlapping || isEndOverlapping || isFullyOverlapping;
			});
		},
		[historyOfHolding],
	);

	const handleConfirmAdd = async () => {
		if (editItem) {
			try {
				await updateRecipientHistoryOfHolding.mutateAsync({
					recipientPendingHistId: editItem.id,
					recipientId: currentRecipient?.recipientId ?? 0,
					recipientPendingDesc: getValues('reason'),
					recipientPendingStartDate: dayjs(getValues('holdingDate.start')).format('YYYYMMDD'),
					recipientPendingEndDate: dayjs(getValues('holdingDate.end')).format('YYYYMMDD'),
				});
				Toast.success('정상적으로 입원 이력을 수정했습니다.');
			} catch (error) {
				Toast.error('수정에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		} else {
			try {
				await createRecipientHistoryOfHolding.mutateAsync({
					recipientId: currentRecipient?.recipientId ?? 0,
					recipientPendingDesc: getValues('reason'),
					recipientPendingStartDate: dayjs(getValues('holdingDate.start')).format('YYYYMMDD'),
					recipientPendingEndDate: dayjs(getValues('holdingDate.end')).format('YYYYMMDD'),
				});
				Toast.success('정상적으로 입원 이력을 등록했습니다.');
			} catch (error) {
				Toast.error('등록에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		}
		onClickClose?.();
	};

	useEffect(() => {
		if (editItem) {
			const [start, end] = editItem.period.split('~').map((i) => i.trim());
			setValue('holdingDate.start', dayjs(start).toDate());
			setValue('holdingDate.end', dayjs(end).toDate());
			setValue('reason', editItem.description);
		}
	}, [editItem]);

	return (
		<CRDialog
			title={editItem ? '입원 이력 수정' : '입원 이력 등록'}
			type='S'
			body={
				<S.Content>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel
								isRequired
								label='입원 기간'
								message={
									errors.holdingDate?.message && (
										<CRInputMessage type='error'>
											{errors.holdingDate?.message ?? ''}
										</CRInputMessage>
									)
								}>
								<CRInput.DateRangePicker
									placeholder='시작일 ~ 종료일'
									onChangeValue={(value) => {
										if (
											!editItem &&
											value.start &&
											value.end &&
											isOverlapping(value.start, value.end)
										) {
											setError('holdingDate', {
												message: '이미 등록된 기간입니다.',
											});
										} else {
											clearErrors('holdingDate');
										}
										onChange(value);
									}}
									value={watch('holdingDate')}
									selected={dayjs().subtract(5, 'month').toDate()}
									maxDate={dayjs().add(12, 'month').toDate()}
									status={errors.holdingDate?.message ? 'error' : 'default'}
								/>
							</CRInputLabel>
						)}
						name='holdingDate'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel
								label='비고'
								renderRightAddon={
									<CRText typography='label' color='gray60'>
										{value?.length ?? 0} / 50
									</CRText>
								}>
								<CRInput.TextArea
									onChange={onChange}
									value={value}
									placeholder='사유 입력'
									numberOfLines={1}
									maxLength={50}
								/>
							</CRInputLabel>
						)}
						name='reason'
						control={control}
					/>
				</S.Content>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={onClickClose}>
						취소
					</CRButton.Default>
					<CRButton.Default
						palette='primary'
						size='default'
						disabled={
							!watch('holdingDate.start') ||
							!watch('holdingDate.end') ||
							!!errors.holdingDate?.message
						}
						onClick={handleConfirmAdd}>
						{editItem ? '저장' : '등록'}
					</CRButton.Default>
				</S.ButtonContainer>
			}
			onClickClose={onClickClose}
		/>
	);
}

export default RecipientAddHistoryOfRecipientHoldingDialog;
