import React, { memo } from 'react';

import { CRText } from 'components/base/CRText';

import * as S from './styles';

interface ChartLegend {
	color: string;
	legend: string;
	value: number;
}

interface RoundingRatioChartProps {
	title: string;
	ratio: number;
	legends: ChartLegend[];
}

function RoundingRatioChart({ title, ratio, legends }: RoundingRatioChartProps) {
	return (
		<S.Container>
			<CRText text={title} typography='bodyB' />
			<S.ContentContainer>
				<S.ChartContainer ratio={ratio}>
					<S.RatioValueContainer>
						<CRText text={`${ratio}%`} typography='h3' style={{ marginLeft: '0.4rem' }} />
					</S.RatioValueContainer>
				</S.ChartContainer>
				<S.IndexContainer>
					{legends.map((index) => (
						<S.IndexItem key={index.legend}>
							<S.Index>
								<S.ColorDot color={index.color} />
								<CRText text={index.legend} typography='label' color='gray60' />
							</S.Index>
							<CRText text={index.value.toLocaleString()} typography='label' color='gray20' />
						</S.IndexItem>
					))}
				</S.IndexContainer>
			</S.ContentContainer>
		</S.Container>
	);
}

export default memo(RoundingRatioChart);
