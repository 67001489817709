import React, { ChangeEvent, ForwardedRef, KeyboardEvent, ReactNode, forwardRef } from 'react';

import Colors from 'common/colors';
import Typography from 'common/typography';

import * as S from './styles';

type InputElementType = React.ComponentPropsWithoutRef<'input'>;

interface Prop extends Omit<InputElementType, 'type' | 'onChange' | 'prefix'> {
	textAlign?: 'left' | 'center' | 'right';
	type?: 'text' | 'number';
	formatter?: (e: ChangeEvent<HTMLInputElement>) => string;
	onChange?: (value: string | number) => void;
	prefix?: ReactNode;
	suffix?: ReactNode;
	typography?: keyof typeof Typography;
	color?: keyof typeof Colors;
}

function CRInputNum(
	{
		min = 0,
		max,
		maxLength,
		width = '61px',
		type = 'text',
		textAlign = 'left',
		onChange,
		formatter,
		prefix,
		suffix,
		typography,
		color,
		...props
	}: Prop,
	ref: ForwardedRef<HTMLInputElement>,
) {
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (maxLength && e.target.value.length > maxLength) return;
		if (max && Number(max) && Number(max) < Number(e.target.value)) {
			onChange?.(max);
			return;
		}

		onChange?.(formatter ? formatter(e) : e.target.value);
	};

	const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (type === 'number' && ['e', 'E', '+', '-'].includes(e.key)) {
			e.preventDefault();
		}
	};

	const onBlur = () => {
		if (!min || !props.value) return;
		if (min && Number(min) && Number(min) > Number(props.value)) {
			onChange?.(min);
		}
	};

	const numberInputOnWheelPreventChange = (e) => {
		// Prevent the input value change
		e.target.blur();
		// Prevent the page/container scrolling
		e.stopPropagation();
	};

	return (
		<S.InputContainer width={width}>
			{prefix}
			<S.InputElement
				onBlurCapture={onBlur}
				{...props}
				{...ref}
				$color={color}
				$typography={typography}
				onChange={handleChange}
				onKeyDown={onKeyDown}
				min={min}
				width={width}
				type={type}
				$textAlign={textAlign}
				onWheel={numberInputOnWheelPreventChange}
			/>
			{suffix}
		</S.InputContainer>
	);
}

export default forwardRef(CRInputNum);
