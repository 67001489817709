import * as XLSX from 'sheetjs-style';

import { GetCenterAccountRecordsDownloadDTO } from 'types/api/centerAccount';

export const centerAccountTransfer = (
	fileName: string,
	data: GetCenterAccountRecordsDownloadDTO[],
) => {
	const excelData = data.map(
		({
			recordKindNm,
			recordTypeNm,
			recordDesc,
			record,
			depositAmt,
			withdrawAmt,
			balanceAmt,
			centerAccountAliasNm,
			recordDate,
			transferMediaNm,
			recipientNm,
			recipientBirthDt,
			burdenYm,
			burdenServiceNm,
			longTermCareServiceNm,
		}) => ({
			종류: recordKindNm || '',
			유형: recordTypeNm || '',
			메모: recordDesc || '',
			'입금처/출금처': record,
			입금액: depositAmt ? Number(depositAmt).toLocaleString() : '',
			출금액: withdrawAmt ? Number(withdrawAmt).toLocaleString() : '',
			잔액: balanceAmt ? Number(balanceAmt).toLocaleString() : '',
			계좌명: centerAccountAliasNm || '',
			'이체 일시': recordDate || '',
			적요: transferMediaNm || '',
			수급자명: recipientNm || '',
			'수급자 생년월일': recipientBirthDt || '',
			'본부금 년월': burdenYm || '',
			'본부금 급여종류': burdenServiceNm || '',
			'장기요양 급여종류': longTermCareServiceNm || '',
		}),
	);

	const workSheet = XLSX.utils.json_to_sheet(excelData);

	const headerStyle = {
		fill: {
			fgColor: { rgb: 'DDDDDD' },
		},
		alignment: {
			horizontal: 'center',
		},
	};

	workSheet.A1.s = headerStyle;
	workSheet.B1.s = headerStyle;
	workSheet.C1.s = headerStyle;
	workSheet.D1.s = headerStyle;
	workSheet.E1.s = headerStyle;
	workSheet.F1.s = headerStyle;
	workSheet.G1.s = headerStyle;
	workSheet.H1.s = headerStyle;
	workSheet.I1.s = headerStyle;
	workSheet.J1.s = headerStyle;
	workSheet.K1.s = headerStyle;
	workSheet.L1.s = headerStyle;
	workSheet.M1.s = headerStyle;
	workSheet.N1.s = headerStyle;
	workSheet.O1.s = headerStyle;

	const columnStyles = [
		{ width: 8 },
		{ width: 15 },
		{ width: 20 },
		{ width: 20 },
		{ width: 20 },
		{ width: 20 },
		{ width: 20 },
		{ width: 20 },
		{ width: 20 },
		{ width: 20 },
		{ width: 20 },
		{ width: 20 },
		{ width: 20 },
		{ width: 20 },
		{ width: 20 },
	];
	workSheet['!cols'] = columnStyles;

	const wb = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(wb, workSheet, '입력정보');
	XLSX.writeFile(wb, `${fileName}.xlsx`);
	return true;
};
