import { endpoint } from 'lib/service/Api/endpoint';

import useCRMutation from '../base/useCRMutation';

export const useUpdateCenterAccountRecords = useCRMutation(
	endpoint.updateCenterAccountRecords.key,
	'updateCenterAccountRecords',
);
export const useUpdateCenterAccountRecordDetail = useCRMutation(
	endpoint.updateCenterAccountRecordDetail.key,
	'updateCenterAccountRecordDetail',
);
export const useUpdateCenterAccountChildRecordDetail = useCRMutation(
	endpoint.updateCenterAccountChildRecordDetail.key,
	'updateCenterAccountChildRecordDetail',
);

export const useDownloadCenterAccountRecordsExcel = useCRMutation(
	endpoint.getCenterAccountExcelDownload.key,
	'getCenterAccountRecordsDownload',
);
