import styled from 'styled-components';

export const Container = styled.div`
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	overflow-y: hidden;
`;

export const Header = styled.div`
	${({ theme }) => theme.typography.bodyB};
	height: 6.4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	background-color: ${(props) => props.theme.colors.gray100};
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const Footer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	padding: 0.8rem 1rem;
	width: 100%;
	background-color: ${(props) => props.theme.colors.gray100};
`;

export const FormContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	gap: 1.6rem;
	padding: 2.4rem 1.6rem;
	background: ${(props) => props.theme.colors.gray95};
	overflow-y: auto;
`;
