import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import { Toast } from 'components/base/CRToast';
import { useUpdateMonitoringDetailItemCompleteStatus } from 'lib/hook/react-query/mutation/monitoring';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { MonitoringDetailItemDTO, ResponseCode } from 'types/api';

import * as S from './styles';

interface Props {
	item: MonitoringDetailItemDTO;
	onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
}

export function MonitoringDetailItemsCompleteStatusDialog({ item, onSubmit }: Props) {
	const { hideDialog } = useDialog();
	const { mutateAsync: confirmMonitoringSchedule, isLoading } =
		useUpdateMonitoringDetailItemCompleteStatus();
	const queryClient = useQueryClient();

	const renderContent = `${item?.recipientNm}님의 ${dayjs(item?.serviceDt).format(
		'YYYY년 M월 D일',
	)} 일정 모니터링을 확정합니다.`;

	const handleClickComplete = async () => {
		if (isLoading || !item) return;
		try {
			await onSubmit();
			const result = await confirmMonitoringSchedule({
				centerId: item.centerId,
				serviceYm: item.serviceYm,
				recipientId: item.recipientId,
				employeeId: item.employeeId,
				serviceTypeCd: item.serviceTypeCd,
				schedulePlanStartDate: item.schedulePlanStartDate,
				schedulePlanEndDate: item.schedulePlanEndDate,
				completeYn: true,
			});

			if (result?.code === ResponseCode.SUCCESS) {
				await queryClient.invalidateQueries([endpoint.getMonitoringDetailItem.key]);
				await queryClient.invalidateQueries([endpoint.getMonitoringDetails.key]);
				Toast.success('일정을 확정하였습니다.');
			} else {
				Toast.error('일정 확정에 실패했습니다. 잠시 후 다시 시도해 주시기 바랍니다.');
			}
			hideDialog();
		} catch (err) {
			Toast.error('일정 확정에 실패했습니다. 잠시 후 다시 시도해 주시기 바랍니다.');
			hideDialog();
		}
	};

	return (
		<CRDialog
			onClickClose={hideDialog}
			type='S'
			title='일정 확정'
			body={<S.Container>{renderContent}</S.Container>}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default palette='primary' size='default' onClick={handleClickComplete}>
						확정
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
