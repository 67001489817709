import React, { ChangeEventHandler, PropsWithChildren, useMemo } from 'react';

import { ButtonPalette } from 'components/base/CRButton/type';

import { CheckBoxType } from '../type';
import * as S from './styles';

interface IProps {
	id?: string;
	name?: string;
	palette?: ButtonPalette;
	checked?: boolean;
	disabled?: boolean;
	checkBoxType?: CheckBoxType;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	errorMessage?: React.ReactNode;
}

function CheckBoxButton({
	id,
	name,
	palette = 'primary',
	checked = false,
	disabled = false,
	checkBoxType = 'checkbox',
	onChange,
	children,
	errorMessage,
}: PropsWithChildren<IProps>) {
	return (
		<>
			<S.Container htmlFor={id} $appearanceType='button' $isActive={checked} $isDisabled={disabled}>
				<S.InputContainer $isActive={checked} $isDisabled={disabled} $palette={palette}>
					<S.CRInput
						$palette={palette}
						id={id}
						name={name}
						type='checkbox'
						$checkBoxType={checkBoxType}
						checked={checked ?? false}
						onChange={onChange}
						disabled={disabled}
					/>
				</S.InputContainer>
				<S.Content>
					<S.Label $isDisabled={disabled}>{children}</S.Label>
				</S.Content>
			</S.Container>
			{errorMessage}
		</>
	);
}

export default CheckBoxButton;
