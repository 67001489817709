import React from 'react';

import useFunnel from 'lib/hook/util/useFunnel';
import EDocClientPageProvider from 'lib/provider/eDoc/EDocClient';

import EDocInfoAuthStep from './EDocInfoAuthStep';
import EDocInfoViewStep from './EDocInfoViewStep';

type Steps = ['auth', 'view'];

function ESignInfoPage(): React.ReactElement {
	const [Funnel, setStep] = useFunnel<Steps>('auth');

	const onAuthSuccess = () => {
		setStep('view');
	};

	return (
		<EDocClientPageProvider>
			<Funnel>
				<Funnel.Step name='auth'>
					<EDocInfoAuthStep onSuccess={onAuthSuccess} />
				</Funnel.Step>
				<Funnel.Step name='view'>
					<EDocInfoViewStep />
				</Funnel.Step>
			</Funnel>
		</EDocClientPageProvider>
	);
}

export default ESignInfoPage;
