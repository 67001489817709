import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2.4rem;
	gap: 1.6rem;
	overflow: hidden;
	border-top: 1px solid ${(props) => props.theme.colors.gray90};
`;
