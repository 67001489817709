import React, { useCallback } from 'react';

import dayjs from 'dayjs';

import Assets from 'assets';
import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRFileName from 'components/base/CRFileName';
import CRTable from 'components/base/CRTable';
import { Toast } from 'components/base/CRToast';
import { CheckOption } from 'components/base/Selections/type';
import { commonCodeAdapter } from 'lib/adapter/common';
import {
	useCommonCodes,
	useDeleteEmployeeHealthCheck,
	useEmployeePageHealthCheck,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { useDownload } from 'lib/hook/util/useDownload';
import { EmployeeDTO, EmployeeHealthCheckDTO } from 'types/api/employee';
import { FileDTO } from 'types/dto/common';

import AddHealthCheckDialog from '../AddHealthCheckDialog';
import DeleteDialog from '../DeleteDialog';
import { HEALTH_SCREENING_HISTORY_LIST_TABLE_HEADER_CONFIG } from './constants';
import * as S from './styles';

interface Props {
	hideDialog: () => void;
	currentEmployee?: EmployeeDTO;
}

function HealthCheckupHistoryDialog({ currentEmployee, hideDialog }: Props): React.ReactElement {
	const { data: employeeHealthCheck = [], refetch } = useEmployeePageHealthCheck({
		employeeId: currentEmployee?.employeeId,
	});
	const { data: commonCodes } = useCommonCodes({ comCdGroupNms: ['CMN163'] }, commonCodeAdapter);

	const options = {
		healthCheckKind: commonCodes?.CMN163 ?? [],
	};

	const { downloadFiles } = useDownload();

	const { mutate: deleteEmployeeHealthCheck } = useDeleteEmployeeHealthCheck(
		(client, returnData) => {
			if (returnData?.employeeId) {
				refetch();
				Toast.success('검진기록이 삭제되었습니다.');
				hideDialog();
			}
		},
	);
	const { showDialog } = useDialog();

	const handleDeleteHealthCheckItem = async (item?: EmployeeHealthCheckDTO) => {
		if (!item) return;
		const deleteHealthCheck = async () => {
			deleteEmployeeHealthCheck({
				employeeHealthCheckId: item?.employeeHealthCheckId,
			});
		};
		showDialog(({ hideDialog }) => (
			<DeleteDialog
				title='검진이력 삭제'
				content={`${currentEmployee?.korMemberNm} 님의 검진 이력을 삭제하겠습니다.`}
				hideDialog={hideDialog}
				cancelOption={{
					text: '취소',
				}}
				successOption={{
					text: '삭제',
					successCallback: deleteHealthCheck,
				}}
			/>
		));
	};

	const handleClickAddHealCheck = () => {
		showDialog(({ hideDialog }) => (
			<AddHealthCheckDialog
				hideDialog={hideDialog}
				options={options}
				currentEmployee={currentEmployee}
			/>
		));
	};

	const renderCheckDate = (value: string) => (
		<S.TextBox>{dayjs(value).format('YYYY.MM.DD')}</S.TextBox>
	);

	const renderKindCode = (value: string) => (
		<S.TextBox>
			{options?.healthCheckKind.find((code) => code.value === value)?.label || ''}
		</S.TextBox>
	);

	const renderOption = (value: number, item?: EmployeeHealthCheckDTO) => (
		<S.OptionContainer>
			{item && item.healthCheckFile?.fileDetails?.some((item) => !item.fileDeleteYn) && (
				<CRButton.Default
					size='xSmall'
					type='outlined'
					palette='gray'
					onClick={() => {
						const files = item?.healthCheckFile?.fileDetails.filter((file) => !file.fileDeleteYn);
						downloadFiles(files);
					}}>
					다운로드
				</CRButton.Default>
			)}

			<CRButton.Default
				size='xSmall'
				type='outlined'
				palette='primary'
				onClick={() => handleDeleteHealthCheckItem(item)}>
				삭제
			</CRButton.Default>
		</S.OptionContainer>
	);

	const renderHealthCheckFile = useCallback((item?: FileDTO) => {
		const fileNames = item?.fileDetails
			?.filter((file) => !file.fileDeleteYn)
			?.map((data) => data.originFileNm);
		return <CRFileName maxLength={25} fileNames={fileNames} />;
	}, []);

	return (
		<CRDialog
			onClickClose={hideDialog}
			type='L'
			title='건강검진 이력'
			bottomContainerStyle={{ borderTop: `0.1rem solid ${Colors.gray90}` }}
			body={
				<S.Container>
					<CRTable.Root>
						<CRTable.Head offset={-77} heads={HEALTH_SCREENING_HISTORY_LIST_TABLE_HEADER_CONFIG} />
						<CRTable.Body>
							{employeeHealthCheck?.map((item) => (
								<CRTable.Row
									key={item.employeeHealthCheckId}
									item={item}
									toolTipKey={['healthCheckFile']}
									customRender={{
										healthCheckDate: renderCheckDate,
										healthCheckKindCd: renderKindCode,
										employeeHealthCheckId: renderOption,
										healthCheckFile: renderHealthCheckFile,
									}}
									renderKeys={[
										'healthCheckDate',
										'healthCheckKindCd',
										'healthCheckInstitutionNm',
										'healthCheckFile',
										'centerNm',
										'managerNm',
										'remark',
										'employeeHealthCheckId',
									]}
								/>
							))}
						</CRTable.Body>
					</CRTable.Root>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<S.LeftButtonContainer>
						<CRButton.IconButton
							palette='gray'
							type='outlined'
							iconLeft={Assets.icon.add}
							onClick={handleClickAddHealCheck}>
							검진 이력 추가
						</CRButton.IconButton>
					</S.LeftButtonContainer>
				</S.ButtonContainer>
			}
		/>
	);
}

export default HealthCheckupHistoryDialog;
