import { endpoint } from 'lib/service/Api/endpoint';

import useCRMutation from '../base/useCRMutation';

export const useCheckFullTimeEmployeeDuplication = useCRMutation(
	endpoint.checkFullTimeEmployeeDuplication.key,
	'checkFullTimeEmployeeDuplication',
);

export const useCreateFullTimeEmployee = useCRMutation(
	endpoint.createFullTimeEmployee.key,
	'createFullTimeEmployee',
);

export const useDeleteFullTimeEmployee = useCRMutation(
	endpoint.deleteFullTimeEmployee.key,
	'deleteFullTimeEmployee',
);

export const useUpdateFullTimeEmployeeDetailInfo = useCRMutation(
	endpoint.updateFullTimeEmployeeDetailInfo.key,
	'updateFullTimeEmployeeDetailInfo',
);

export const useUpdateFullTimeEmployeeAnnualTOHistories = useCRMutation(
	endpoint.updateFullTimeEmployeeAnnualTOHistories.key,
	'updateFullTimeEmployeeAnnualTOHistories',
);

export const useDeleteFullTimeEmployeeLicense = useCRMutation(
	endpoint.deleteFullTimeEmployeeLicense.key,
	'deleteFullTimeEmployeeLicense',
);

export const useSaveFullTimeEmployeeLicense = useCRMutation(
	endpoint.saveFullTimeEmployeeLicense.key,
	'saveFullTimeEmployeeLicense',
);

export const useDeleteFullTimeEmployeeEducation = useCRMutation(
	endpoint.deleteFullTimeEmployeeEducation.key,
	'deleteFullTimeEmployeeEducation',
);

export const useSaveFullTimeEmployeeEducation = useCRMutation(
	endpoint.saveFullTimeEmployeeEducation.key,
	'saveFullTimeEmployeeEducation',
);

export const useDeleteFulLTimeEmployeeWorkState = useCRMutation(
	endpoint.deleteFullTimeEmployeeWorkState.key,
	'deleteFullTimeEmployeeWorkState',
);

export const useSaveFulLTimeEmployeeWorkState = useCRMutation(
	endpoint.saveFullTimeEmployeeWorkState.key,
	'saveFullTimeEmployeeWorkState',
);

export const useDeleteFullTimeEmployeeDuty = useCRMutation(
	endpoint.deleteFullTimeEmployeeDuty.key,
	'deleteFullTimeEmployeeDuty',
);

export const useSaveFullTimeEmployeeDuty = useCRMutation(
	endpoint.saveFullTimeEmployeeDuty.key,
	'saveFullTimeEmployeeDuty',
);

export const useSaveFullTimeEmployeeCrimeHistory = useCRMutation(
	endpoint.saveFullTimeEmployeeCrimeHistory.key,
	'saveFullTimeEmployeeCrimeHistory',
);

export const useSaveFullTimeEmployeeDementiaEducation = useCRMutation(
	endpoint.saveFullTimeEmployeeDementiaEducation.key,
	'saveFullTimeEmployeeDementiaEducation',
);

export const useSaveFullTimeEmployeeAnnualTOGenerateAdjust = useCRMutation(
	endpoint.saveFullTimeEmployeeAnnualTOGenerateAdjust.key,
	'saveFullTimeEmployeeAnnualTOGenerateAdjust',
);

export const useSaveFullTimeEmployeeAnnualTORemainAdjust = useCRMutation(
	endpoint.saveFullTimeEmployeeAnnualTORemainAdjust.key,
	'saveFullTimeEmployeeAnnualTORemainAdjust',
);

export const useSaveFullTimeEmployeeAnnualTOWorkYearCntAdjust = useCRMutation(
	endpoint.saveFullTimeEmployeeAnnualTOWorkYearCntAdjust.key,
	'saveFullTimeEmployeeAnnualTOWorkYearCntAdjust',
);

export const useMoveFullTimeEmployeeCenter = useCRMutation(
	endpoint.moveFullTimeEmployeeCenter.key,
	'moveFullTimeEmployeeCenter',
);

export const useCancelMovingFullTimeEmployeeCenter = useCRMutation(
	endpoint.cancelMovingFullTimeEmployeeCenter.key,
	'cancelMovingFullTimeEmployeeCenter',
);

export const useResignFullTimeEmployee = useCRMutation(
	endpoint.resignFullTimeEmployee.key,
	'resignFullTimeEmployee',
);

export const useGetFullTimeEmployeeAnnualTOGenerateAdjust = useCRMutation(
	endpoint.getFullTimeEmployeeAnnualTOGenerateAdjust.key,
	'getFullTimeEmployeeAnnualTOGenerateAdjust',
);

export const useGetFullTimeEmployeeAnnualTORemainAdjust = useCRMutation(
	endpoint.getFullTimeEmployeeAnnualTORemainAdjust.key,
	'getFullTimeEmployeeAnnualTORemainAdjust',
);

export const useGetFullTimeEmployeeAnnualTOWorkYearCntAdjust = useCRMutation(
	endpoint.getFullTimeEmployeeAnnualTOWorkYearCntAdjust.key,
	'getFullTimeEmployeeAnnualTOWorkYearCntAdjust',
);

export const useDeleteFullTimeEmployeeHealthCheck = useCRMutation(
	endpoint.deleteFullTimeEmployeeHealthCheck.key,
	'deleteFullTimeEmployeeHealthCheck',
);

export const useSaveFullTimeEmployeeHealthCheck = useCRMutation(
	endpoint.saveFullTimeEmployeeHealthCheck.key,
	'saveFullTimeEmployeeHealthCheck',
);
