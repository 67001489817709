import { CRTableHeadConfig } from 'types/view/base';

export const FULL_TIME_EMPLOYEE_WORKING_STATUS_HISTORY_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '12rem',
		label: '구분',
		sortKey: '구분',
	},
	{
		width: '24rem',
		label: '시작일',
		sortKey: '시작일',
	},
	{
		width: '16rem',
		label: '상태',
		sortKey: '상태',
	},
	{
		width: '44rem',
		label: '사유',
		sortKey: '사유',
	},
	{
		width: '',
		label: '',
		sortKey: '',
	},
];
