import React, { ChangeEvent, Dispatch, useEffect, useRef } from 'react';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import { Toast } from 'components/base/CRToast';
import { useCreateUploadUrl, useUploadFile } from 'lib/hook/react-query';
import { ResponseCode } from 'types/api/base';
import { FileDetailDTO } from 'types/dto';

import * as S from './styles';

const FILE_PATH_NAME = dayjs().format('YYYYMMDD');
interface Props {
	label?: string;
	file?: FileDetailDTO;
	disabled?: boolean;
	setIsLoading?: Dispatch<React.SetStateAction<boolean>>;
	onChange?: (file: FileDetailDTO[]) => void;
}

function CRFileUploaderButton({
	label = '',
	file,
	disabled = false,
	onChange,
	setIsLoading,
}: Props): React.ReactElement {
	const inputRef = useRef<HTMLInputElement>(null);
	const { mutateAsync: createUploadUrl, isLoading: createUploadUrlLoading } = useCreateUploadUrl();
	const { mutateAsync: uploadFile, isLoading: uploadFileLoading } = useUploadFile();

	const fileToS3 = async (e: File) => {
		const {
			data: createResponse,
			code,
			message,
		} = await createUploadUrl({
			size: e.size,
			objectKey: `${FILE_PATH_NAME}/${e.name}`,
		});

		if (code === ResponseCode.SUCCESS) {
			if (createResponse?.objectKey) {
				await uploadFile({
					presignedUrl: createResponse?.url,
					type: e.type,
					file: e,
				});
				const [filePathNm, transFileNm] = createResponse.objectKey.split('/');
				const fileInfo: Pick<
					FileDetailDTO,
					'originFileNm' | 'fileDeleteYn' | 'fileSize' | 'filePathNm' | 'transFileNm'
				> = {
					originFileNm: e.name,
					fileDeleteYn: false,
					fileSize: e.size,
					filePathNm: `${filePathNm}/`,
					transFileNm,
				};
				return fileInfo;
			}
			return null;
		}
		Toast.error(message);

		return null;
	};

	const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) return;

		const s3UploadResult = await Promise.all(
			Array.from(event.target.files).map((file) => fileToS3(file)),
		);
		onChange?.(s3UploadResult.filter(Boolean) as FileDetailDTO[]);
	};

	const onClickInput = () => {
		inputRef.current?.click();
	};

	useEffect(() => {
		setIsLoading?.(uploadFileLoading || createUploadUrlLoading);
	}, [uploadFileLoading, createUploadUrlLoading]);

	return (
		<S.Container>
			<CRButton.Default
				disabled={disabled}
				type='outlined'
				palette='gray'
				size='xSmall'
				onClick={onClickInput}>
				{label}
			</CRButton.Default>
			<input ref={inputRef} hidden multiple type='file' onChange={handleChangeFile} />
		</S.Container>
	);
}

export default CRFileUploaderButton;
