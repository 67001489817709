import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';

import Assets from 'assets';
import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInputMessage from 'components/base/CRInputMessage';
import { CheckOption } from 'components/base/Selections/type';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import useAuth from 'lib/hook/auth/useAuth';
import { useCommonCenters } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { SignUpForm } from 'lib/provider/auth';
import { signUp } from 'lib/util/validator';

import { Toast } from '../../../components/base/CRToast';
import * as S from './styles';

function SignUpPage(): React.ReactElement {
	const auth = useAuth();
	const { data: commonCenters } = useCommonCenters();
	const { control, handleSubmit, watch, getValues, setError, clearErrors } = useForm<SignUpForm>({
		resolver: yupResolver(signUp),
		mode: 'onChange',
	});
	const { showDialog } = useDialog();
	const navigate = useNavigate();
	const [isValidateId, setIsValidateId] = useState(false);

	const centerOptions = useMemo(
		() =>
			(commonCenters ?? []).map(
				(center) =>
					({
						label: center.value,
						value: center.id,
					}) as CheckOption,
			),
		[commonCenters],
	);

	const handleClickValidateId = async () => {
		const isValidateIdFromRemote = await auth.validateId(getValues('userId'));
		setIsValidateId(isValidateIdFromRemote);
		if (!isValidateIdFromRemote) {
			setError('userId', {
				message: '이미 사용한 아이디입니다.',
			});
		} else {
			clearErrors('userId');
		}
	};

	const handleGoBack = () => {
		navigate(-1);
	};

	const onSubmit = async (data: SignUpForm) => {
		try {
			if (isValidateId) {
				const res = await auth.signUp(data);
				if (res?.memberAccountId) {
					showDialog(
						({ hideDialog }) => (
							<DefaultDialog
								title='가입 완료'
								content='회원 가입이 완료되었습니다. 센터장에게 가입 허가를 요청 하십시오.'
								hideDialog={hideDialog}
								successOption={{
									text: '로그인',
									successCallback: () => {
										navigate(RouterPath.signIn());
										hideDialog();
									},
								}}
							/>
						),
						{
							onClickBackground: () => {},
						},
					);
				}
			} else {
				setError('userId', {
					message: '아이디 중복확인을 해주시길 바랍니다.',
				});
			}
		} catch (error: any) {
			const errorMessage = error.response.data?.fieldErrors?.[0]?.defaultMessage;
			if (errorMessage) {
				Toast.error(errorMessage);
			}
		}
	};

	useEffect(() => {
		setIsValidateId(false);
	}, [watch('userId')]);

	return (
		<S.Container>
			<S.Contents>
				<S.Logo src={Assets.img.logo} alt='caring' />
				<S.Header>회원가입</S.Header>
				<S.Description>소속 정보를 입력해주십시오.</S.Description>
				<S.Form onSubmit={handleSubmit(onSubmit, (err) => console.log('에러', err))}>
					<S.InputContainer>
						<Controller
							render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
								<CRInputLabel label='이름' isRequired>
									<CRInput.Default
										ref={ref}
										onBlur={onBlur}
										addOnBottom={errors.username?.message}
										status={errors.username ? 'error' : 'default'}
										onChange={onChange}
										value={value}
										placeholder='이름 입력'
									/>
								</CRInputLabel>
							)}
							name='username'
							control={control}
						/>
						<Controller
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInputLabel
									label='생년월일'
									isRequired
									message={
										errors.birthDt?.message ? (
											<CRInputMessage type='error'>{errors.birthDt?.message ?? ''}</CRInputMessage>
										) : (
											<CRInputMessage>8자리 입력 (예: 19850101)</CRInputMessage>
										)
									}>
									<CRInput.Default
										status={errors.birthDt ? 'error' : 'default'}
										onChange={onChange}
										value={value}
										placeholder='생년월일 8자리 입력'
										type='number'
										maxLength={8}
									/>
								</CRInputLabel>
							)}
							name='birthDt'
							control={control}
						/>
						<Controller
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInputLabel label='소속센터' isRequired>
									<CRInput.Selector
										label='소속 센터'
										autoComplete
										onChangeValue={onChange}
										currentValue={value}
										items={centerOptions}
										placeholder='소속 센터 선택'
										addOnBottom={errors.center?.message}
										status={errors.center ? 'error' : 'default'}
									/>
								</CRInputLabel>
							)}
							name='center'
							control={control}
						/>
						<Controller
							render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
								<CRInputLabel label='개인 휴대폰 번호' isRequired>
									<CRInput.Default
										ref={ref}
										onBlur={onBlur}
										addOnBottom={errors.phoneNumber?.message}
										status={errors.phoneNumber ? 'error' : 'default'}
										onChange={onChange}
										value={value}
										type='number'
										placeholder='01012341234'
										maxLength={11}
									/>
								</CRInputLabel>
							)}
							name='phoneNumber'
							control={control}
						/>
						<Controller
							render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
								<CRInputLabel label='업무처리 휴대폰 번호' isRequired>
									<CRInput.Default
										ref={ref}
										onBlur={onBlur}
										addOnBottom={errors.workMobilePhoneNo?.message}
										status={errors.workMobilePhoneNo ? 'error' : 'default'}
										onChange={onChange}
										value={value}
										type='number'
										placeholder='01012341234'
										maxLength={11}
									/>
								</CRInputLabel>
							)}
							name='workMobilePhoneNo'
							control={control}
						/>
						<Controller
							render={({
								field: { onChange, onBlur, value, ref },
								fieldState: { invalid },
								formState: { errors },
							}) => (
								<CRInputLabel
									label='아이디'
									message={
										errors.userId?.message ? (
											<CRInputMessage type='error'>{errors.userId?.message}</CRInputMessage>
										) : isValidateId ? (
											<CRInputMessage>사용 가능한 아이디입니다.</CRInputMessage>
										) : null
									}
									isRequired>
									<CRInput.Default
										ref={ref}
										onBlur={onBlur}
										status={errors.userId ? 'error' : 'default'}
										onChange={onChange}
										value={value}
										placeholder='아이디 입력'
										suffix={
											<CRButton.Default
												palette='gray'
												size='small'
												type='outlined'
												disabled={
													!value ||
													(invalid &&
														errors.userId?.message !== '아이디 중복확인을 해주시길 바랍니다.')
												}
												onClick={handleClickValidateId}>
												중복확인
											</CRButton.Default>
										}
									/>
								</CRInputLabel>
							)}
							name='userId'
							control={control}
						/>
						<Controller
							render={({ field: { onChange, value, onBlur }, formState: { errors } }) => (
								<CRInputLabel
									label='비밀번호'
									message={
										errors.password?.message ? (
											<CRInputMessage type='error'>{errors.password?.message ?? ''}</CRInputMessage>
										) : (
											<CRInputMessage>8~16자 영문 대・소문자,숫자,특수문자 조합</CRInputMessage>
										)
									}
									isRequired>
									<CRInput.Password
										status={errors.password ? 'error' : 'default'}
										onChange={onChange}
										onBlur={onBlur}
										value={value}
										placeholder='비밀번호 입력'
										toggleSecret
									/>
								</CRInputLabel>
							)}
							name='password'
							control={control}
						/>
						<Controller
							render={({ field: { onChange, value, onBlur }, formState: { errors } }) => (
								<CRInputLabel label='비밀번호 확인' isRequired>
									<CRInput.Password
										addOnBottom={errors.passwordConfirm?.message}
										status={errors.passwordConfirm ? 'error' : 'default'}
										onChange={onChange}
										onBlur={onBlur}
										value={value}
										placeholder='위와 동일한 비밀번호를 입력해주세요'
										toggleSecret
									/>
								</CRInputLabel>
							)}
							name='passwordConfirm'
							control={control}
						/>
					</S.InputContainer>
					<S.ButtonContainer>
						<S.CancelButtonContainer>
							<CRButton.Default
								onClick={handleGoBack}
								buttonType='button'
								size='large'
								palette='gray'
								type='outlined'
								fullSize>
								취소
							</CRButton.Default>
						</S.CancelButtonContainer>
						<S.SignUpButtonContainer>
							<CRButton.Default
								buttonType='submit'
								size='large'
								disabled={
									!(
										watch('userId') &&
										watch('username') &&
										watch('center') &&
										watch('phoneNumber') &&
										watch('workMobilePhoneNo') &&
										watch('password') &&
										watch('passwordConfirm')
									)
								}
								fullSize>
								가입하기
							</CRButton.Default>
						</S.SignUpButtonContainer>
					</S.ButtonContainer>
				</S.Form>
			</S.Contents>
		</S.Container>
	);
}

export default SignUpPage;
