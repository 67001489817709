import React, { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import Assets from 'assets';
import CRBanner from 'components/base/CRBanner';
import CRButton from 'components/base/CRButton';
import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRIcon from 'components/base/CRIcon';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInputMessage from 'components/base/CRInputMessage';
import CRSpinner from 'components/base/CRSpinner';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import { contractCreateValidator } from 'lib';
import { commonCodeAdapter } from 'lib/adapter/common';
import {
	useCenterRecipients,
	useCheckRealName,
	useCheckRecipientRsdnNo,
	useCommonCodes,
	useCreateContracts,
	useCreateRecipient,
	useGuaranteedCenters,
	useMyAccountInfo,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import { endpoint } from 'lib/service/Api/endpoint';
import { CreateContractForm } from 'types/view/contract';

import { CheckRecipientRsdnNData } from '../../../../types/api/common';
import * as S from './styles';

interface Props {
	onSubmit?: (contractId: number) => void;
}

function ContractCreateDialog({ onSubmit }: Props): React.ReactElement {
	const {
		control,
		watch,
		setValue,
		formState: { errors, isValid },
		clearErrors,
	} = useForm<CreateContractForm>({
		mode: 'onChange',
		resolver: yupResolver(contractCreateValidator),
	});

	const { data: members } = useCenterRecipients({
		name: '',
	});

	const hasContactManager = useHasPermission('컨택매니저');
	const { data: guaranteedCenters = [] } = useGuaranteedCenters();
	const [banner, setBanner] = useState(
		<CRBanner type='info' title='중복 조회를 진행해야 수급자를 생성할 수 있습니다.' />,
	);
	const { data: myAccountInfo } = useMyAccountInfo();
	const { hideDialog } = useDialog();
	const {
		data: commonCodes = {
			CMN006: [],
		},
	} = useCommonCodes({ comCdGroupNms: ['CMN006'] }, commonCodeAdapter);
	const options = useMemo(
		() => commonCodes.CMN006.filter((item) => item.data?.etcDesc1 === 'Y'),
		[commonCodes.CMN006],
	);

	const createRecipientMutation = useCreateRecipient((client) => {
		client.invalidateQueries([endpoint.getRecipients.key]);
	});
	const checkRealNameMutation = useCheckRealName();

	const [isDuplicateValid, setIsDuplicateValid] = useState(false);
	const [isRealName, setIsRealName] = useState(false);
	const [didCheckRealName, setDidCheckRealName] = useState(false);

	const checkRecipientRsdnNoMutation = useCheckRecipientRsdnNo();

	const createContractMutation = useCreateContracts((client, returnData) => {
		if (returnData.code === '200') {
			Toast.success('계약이 추가되었습니다.');
			client.invalidateQueries([endpoint.getEmployeeContractServices.key]);
		} else {
			Toast.error(returnData?.message);
		}
		hideDialog();
	});

	const { name, rsdnNo, serviceType, center, recipientId } = watch();

	const isNewUser = useMemo(
		() =>
			checkRecipientRsdnNoMutation.data?.isCenterDuplicated === false &&
			checkRecipientRsdnNoMutation.data?.isRecipientDuplicated === false &&
			checkRecipientRsdnNoMutation.data?.isEmployeeDuplicated === false &&
			checkRecipientRsdnNoMutation.data?.isMemberDuplicated === false,
		[checkRecipientRsdnNoMutation],
	);

	const isOtherCenterDuplicated = useMemo(
		() =>
			checkRecipientRsdnNoMutation.data?.isCenterDuplicated === false &&
			checkRecipientRsdnNoMutation.data?.isEmployeeDuplicated === false &&
			checkRecipientRsdnNoMutation.data?.isRecipientDuplicated === true &&
			checkRecipientRsdnNoMutation.data?.isMemberDuplicated === true,
		[checkRecipientRsdnNoMutation],
	);

	const isAllValid = !!(
		Object.keys(errors)?.length === 0 &&
		serviceType?.length > 0 &&
		isDuplicateValid &&
		(isOtherCenterDuplicated || isRealName)
	);

	const handleFormSubmit = async () => {
		if (createContractMutation.isLoading) return;
		if (!myAccountInfo?.centerId || !isAllValid) return;

		// 기존 센터에 존재하는 수급자의 경우
		if (
			isDuplicateValid &&
			recipientId &&
			checkRecipientRsdnNoMutation.data?.isCenterDuplicated &&
			checkRecipientRsdnNoMutation.data?.isMemberDuplicated &&
			checkRecipientRsdnNoMutation.data?.isRecipientDuplicated
		) {
			const result = await createContractMutation.mutateAsync({
				contractTypeCd: 'CMN136.10',
				centerId: hasContactManager ? center.value : myAccountInfo.centerId,
				recipientId,
				serviceTypeCds: serviceType.map((item) => item.value),
			});

			if (!result.data?.serviceContract.serviceContractId) return;
			onSubmit?.(result.data?.serviceContract.serviceContractId);
		} else {
			const createRecipientResult = await createRecipientMutation.mutateAsync({
				name: name?.label,
				rsdnNo,
				centerId: hasContactManager ? center?.value : myAccountInfo.centerId,
			});

			if (createRecipientResult?.recipientId) {
				const result = await createContractMutation.mutateAsync({
					contractTypeCd: 'CMN136.10',
					centerId: hasContactManager ? center?.value : myAccountInfo.centerId,
					recipientId: createRecipientResult?.recipientId,
					serviceTypeCds: serviceType.map((item) => item.value),
				});

				if (!result.data?.serviceContract.serviceContractId) return;
				onSubmit?.(result.data?.serviceContract.serviceContractId);
			}
		}
	};

	const handleClickDuplicateCheck = async () => {
		if (!myAccountInfo?.centerId || errors.name?.message || errors.rsdnNo?.message || !name) return;
		if (rsdnNo?.length !== 13) {
			setBanner(
				<CRBanner
					type='error'
					title='주민등록번호 13자리를 모두 입력해야 중복 조회가 가능합니다.'
				/>,
			);
			return;
		}
		setValue('recipientId', undefined);

		const result = await checkRecipientRsdnNoMutation.mutateAsync({
			name: name?.label,
			rsdnNo,
			centerId: hasContactManager ? center?.value : myAccountInfo.centerId,
		});

		if (result?.isEmployeeDuplicated && result?.isMemberDuplicated) {
			setBanner(<CRBanner type='error' title='동일한 주민번호로 이미 직원에 등록되어있습니다.' />);
			setIsDuplicateValid(false);
		} else if (!result?.isCenterDuplicated && !result?.isRecipientDuplicated) {
			setBanner(
				<CRBanner type='success' title='새로운 수급자입니다. 실명 확인을 진행해 주십시오.' />,
			);
			setIsDuplicateValid(true);
			setDidCheckRealName(false);
			setIsRealName(false);
		} else if (!result?.isCenterDuplicated && result?.isRecipientDuplicated) {
			setBanner(
				<CRBanner
					type='warning'
					title={`${result.centerNm}에 이미 존재하는 수급자입니다. (센터 추가 등록)`}
				/>,
			);
			setIsDuplicateValid(true);
			if (result?.recipientId) {
				setValue('recipientId', result.recipientId);
			}
		} else if (result?.isCenterDuplicated && result?.isRecipientDuplicated) {
			setBanner(<CRBanner type='success' title='센터내에 존재하는 수급자입니다.' />);
			setIsDuplicateValid(true);
			setIsRealName(true);
			if (result?.recipientId) {
				setValue('recipientId', result.recipientId);
			}
		}
		// 최후의 예외처리
		else {
			setBanner(<CRBanner type='error' title='잠시 후 다시 시도해주세요.' />);
			setIsDuplicateValid(false);
		}

		setDidCheckRealName(false);
	};

	const handleClickCheckRealName = async () => {
		const foreignerYn = ['5', '6', '7', '8'].includes(rsdnNo?.[6]);
		const result = await checkRealNameMutation.mutateAsync({
			name: name?.label,
			rsdnNo,
			foreignerYn,
		});
		setDidCheckRealName(true);

		if (result?.data?.result) {
			setBanner(
				<CRBanner type='success' title='실명 확인이 완료되어 직원을 생성할 수 있습니다.' />,
			);
			setIsRealName(true);
		} else {
			setBanner(
				<>
					<CRBanner
						type='warning'
						title='실명 확인에 실패했습니다. 이름과 주민등록번호를 확인 후 진행해 주십시오.'
					/>
					<S.HelperTextContainer>
						<S.HelperTextTitle>
							<CRIcon
								style={{
									width: '1.6rem',
									height: '1.6rem',
								}}
								src={Assets.icon.help}
							/>
							<CRText
								color='gray50'
								typography='labelB'
								text='왜 실명확인에 실패해도 추가/등록이 가능한가요?'
							/>
						</S.HelperTextTitle>
						<CRText
							color='gray50'
							typography='label'
							text={`실명확인 시스템은 모든 국민의 정보를 확인할 수 없습니다. 
							정확한 정보를 기입해도 실패가 될 수 있습니다.
							이름과 주민등록번호가 정확하다면 업무를 진행하시면 됩니다.
							*시군구(W4C)에서 한 번 더 실명확인하는 것을 추천합니다.
							`}
						/>
					</S.HelperTextContainer>
				</>,
			);
			setIsRealName(true);
		}
	};

	const memberOptions = useMemo(
		() =>
			(members ?? [])?.map(
				(item) =>
					({
						label: item?.korMemberNm,
						value: {
							name: item?.korMemberNm,
							rsdnNo: item?.rsdnNo ? item.rsdnNo.slice(0, 6) : '',
							centerNm: item?.centerAliasNm ? `${item.centerAliasNm}센터` : '',
						},
						data: item,
					}) as CheckOption,
			),
		[members],
	);

	const checkKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
		if (e.key === 'Enter') e.preventDefault();
	};

	useEffect(() => {
		setIsDuplicateValid(false);
		setIsRealName(false);
		setDidCheckRealName(false);
	}, [name, rsdnNo]);

	return (
		<S.Container>
			<S.Form onSubmit={handleFormSubmit} onKeyDown={checkKeyDown}>
				<S.HeaderContainer>
					<S.HeaderTitle>계약 추가</S.HeaderTitle>
					<S.CloseIcon src={Assets.icon.close} alt='close' onClick={hideDialog} />
				</S.HeaderContainer>
				<S.BodyContainer>
					<S.BodyContent>
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInputLabel label='서비스 종류' isRequired>
									<CRCheckBoxGroup
										checkType='multiple'
										type='checkbox'
										direction='col'
										appearanceType='button'
										gap={0.4}
										options={options}
										onChange={onChange}
										value={value}
									/>
								</CRInputLabel>
							)}
							name='serviceType'
							control={control}
						/>

						<CRCardFormLayout label='수급자' isRequired>
							<Controller
								render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => {
									if (errors.name?.message) {
										setIsDuplicateValid(false);
									}
									return (
										<CRInputLabel
											label='이름'
											message={
												errors.name?.label?.message && (
													<CRInputMessage type='error'>
														{(errors.name?.label?.message as string) ?? ''}
													</CRInputMessage>
												)
											}>
											<CRInput.SearchSelector
												status={errors.name?.label ? 'error' : 'default'}
												currentValue={value}
												items={memberOptions}
												onChange={(value) => {
													if (value?.data?.rsdnNo) {
														setDidCheckRealName(true);
														setIsDuplicateValid(true);
														setIsRealName(true);
														setValue('rsdnNo', value.data.rsdnNo);
														clearErrors();
													} else {
														setValue('rsdnNo', '');
													}
													onChange(value);
												}}
												visibleKey={['centerNm', 'rsdnNo']}
												placeholder='이름 입력'
												allowSelf
											/>
										</CRInputLabel>
									);
								}}
								name='name'
								control={control}
							/>
							<Controller
								render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => {
									if (errors.name?.message) {
										setIsDuplicateValid(false);
									}
									return (
										<CRInputLabel
											label='주민등록번호'
											message={
												errors.rsdnNo?.message && (
													<CRInputMessage type='error'>
														{errors.rsdnNo?.message ?? ''}
													</CRInputMessage>
												)
											}>
											<div
												style={{
													position: 'relative',
												}}>
												<CRInput.Default
													type='number'
													status={errors.rsdnNo?.message ? 'error' : 'default'}
													ref={ref}
													onBlur={onBlur}
													onChange={onChange}
													value={value}
													placeholder='주민등록번호 입력'
													maxLength={13}
												/>
												<S.RightButtonContainer>
													<CRButton.Default
														size='xSmall'
														disabled={!isValid}
														palette='gray'
														type='outlined'
														onClick={handleClickDuplicateCheck}
														buttonType='button'>
														중복 조회
													</CRButton.Default>
													<CRButton.Default
														size='xSmall'
														disabled={!isDuplicateValid || !isNewUser || didCheckRealName}
														palette='gray'
														type='filled'
														onClick={handleClickCheckRealName}
														buttonType='button'>
														실명 확인
													</CRButton.Default>
												</S.RightButtonContainer>
											</div>
										</CRInputLabel>
									);
								}}
								name='rsdnNo'
								control={control}
							/>
							<Controller
								render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
									<CRInputLabel label='소속센터'>
										<CRInput.Selector
											disabled={!hasContactManager}
											items={
												hasContactManager
													? (guaranteedCenters ?? []).map((item) => ({
															label: item.value,
															value: item.id,
														}))
													: [
															{
																label: myAccountInfo?.centerNm || '',
																value: myAccountInfo?.centerId || '',
															},
														]
											}
											autoComplete
											onChangeValue={onChange}
											currentValue={
												hasContactManager
													? value !== undefined
														? value
														: undefined
													: {
															label: myAccountInfo?.centerNm || '',
															value: myAccountInfo?.centerId || '',
														}
											}
											placeholder='센터 선택'
										/>
									</CRInputLabel>
								)}
								name='center'
								control={control}
							/>
							{banner}
						</CRCardFormLayout>
					</S.BodyContent>
				</S.BodyContainer>
				<S.FooterContainer>
					<CRButton.Default palette='gray' type='text' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						style={{
							position: 'relative',
						}}
						disabled={
							!isAllValid || createRecipientMutation.isLoading || createContractMutation.isLoading
						}
						onClick={handleFormSubmit}>
						{createRecipientMutation.isLoading || createContractMutation.isLoading ? (
							<CRSpinner />
						) : (
							'등록'
						)}
					</CRButton.Default>
				</S.FooterContainer>
			</S.Form>
		</S.Container>
	);
}

export default React.memo(ContractCreateDialog);
