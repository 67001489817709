import React from 'react';

import EDocSignResult from 'components/ui/EDoc/EDocSignResult';
import useEDocClientPage from 'lib/hook/eDoc/useESignInfoPage';

function ESignInfoAlreadyConfirmedStep() {
	const { eDocInfo, isErp } = useEDocClientPage();

	const documentName = eDocInfo?.edocPreviewResponse.edocNm || '전자문서';
	const managerName = eDocInfo?.edocPreviewResponse.data.edocSend.edocSenderNm || '담당';

	const renderResult = () => {
		const ResultComponent = isErp
			? EDocSignResult.EDocConfirmResult
			: EDocSignResult.EDocSubmitResult;

		return (
			<ResultComponent
				onClickBack={() => {}}
				documentName={documentName}
				managerName={managerName}
				result='ALREADY_CONFIRMED'
			/>
		);
	};

	return renderResult();
}

export default ESignInfoAlreadyConfirmedStep;
