import React, { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import Assets from 'assets';
import CRBanner from 'components/base/CRBanner';
import CRButton from 'components/base/CRButton';
import CRIcon from 'components/base/CRIcon';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInputMessage from 'components/base/CRInputMessage';
import CRSpinner from 'components/base/CRSpinner';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import { CheckOption } from 'components/base/Selections/type';
import { EmployeeEnroll } from 'lib';
import {
	useCenterEmployees,
	useCheckRealName,
	useCreateEmployee,
	useEmployeePageDuplicateCheck,
	useMyAccountInfo,
} from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import { ResponseCode } from 'types/api';
import { CenterMemberSearchDTO } from 'types/api/common';

import * as S from './styles';

interface Props {
	hideDialog: () => void;
	onSuccess: (employeeId?: number) => void;
}

export type EmployeeEnrollForm = {
	name: CheckOption<CenterMemberSearchDTO>;
	rsdnNo: string;
	center: {
		label: string;
		value: any;
	};
};

export default function EmployeeEnrollDialog({ hideDialog, onSuccess }: Props) {
	const {
		control,
		handleSubmit,
		watch,
		setError,
		clearErrors,
		setValue,
		formState: { errors, isValid },
	} = useForm<EmployeeEnrollForm>({
		resolver: yupResolver(EmployeeEnroll),
		mode: 'onChange',
	});

	const { data: members } = useCenterEmployees({
		name: '',
	});

	const { data: myAccountInfo } = useMyAccountInfo();
	const [banner, setBanner] = useState(
		<CRBanner type='info' title='중복 조회를 진행해야 직원을 생성할 수 있습니다.' />,
	);
	const [isDuplicateValid, setIsDuplicateValid] = useState<boolean | null>(null);
	const [isRealName, setIsRealName] = useState(false);
	const [didCheckRealName, setDidCheckRealName] = useState<boolean>(false);

	const { data: checkDuplicateResult, mutateAsync: checkDuplicate } =
		useEmployeePageDuplicateCheck();
	const { mutateAsync: checkRealNameMutation } = useCheckRealName();

	const { mutate: createEmployee, isLoading: createEmployeeLoading } = useCreateEmployee(
		async (client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS && myAccountInfo) {
				const queryKey = [
					endpoint.getEmployees.key,
					{
						centerIds: [myAccountInfo.centerId],
					},
				];

				await client.invalidateQueries(queryKey);
				onSuccess(returnData.data?.employeeId);
				hideDialog();
				Toast.success('직원을 추가했습니다.');
			} else {
				Toast.error('직원 등록에 실패했습니다. 잠시후 다시 시도해주세요.');
			}
		},
	);

	const checkKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
		if (e.key === 'Enter') e.preventDefault();
	};

	const { name, rsdnNo } = watch();

	const handleClickCheckRealName = async () => {
		const foreignerYn = ['5', '6', '7', '8'].includes(rsdnNo?.[6]);
		const result = await checkRealNameMutation({
			name: name?.label,
			rsdnNo,
			foreignerYn,
		});
		setDidCheckRealName(true);
		if (result?.data?.result) {
			setBanner(
				<CRBanner type='success' title='실명 확인이 완료되어 직원을 생성할 수 있습니다.' />,
			);
			setIsRealName(true);
		} else {
			setBanner(
				<>
					<CRBanner
						type='warning'
						title='실명 확인에 실패했습니다. 이름과 주민등록번호를 확인 후 진행해 주십시오.'
					/>
					<S.HelperTextContainer>
						<S.HelperTextTitle>
							<CRIcon
								style={{
									width: '1.6rem',
									height: '1.6rem',
								}}
								src={Assets.icon.help}
							/>
							<CRText
								color='gray50'
								typography='labelB'
								text='왜 실명확인에 실패해도 추가/등록이 가능한가요?'
							/>
						</S.HelperTextTitle>
						<CRText
							color='gray50'
							typography='label'
							text={`실명확인 시스템은 모든 국민의 정보를 확인할 수 없습니다. 
							정확한 정보를 기입해도 실패가 될 수 있습니다.
							이름과 주민등록번호가 정확하다면 업무를 진행하시면 됩니다.
							*시군구(W4C)에서 한 번 더 실명확인하는 것을 추천합니다.
							`}
						/>
					</S.HelperTextContainer>
				</>,
			);
			setIsRealName(true);
		}
	};

	const handleClickDuplicateCheck = async () => {
		if (!rsdnNo) {
			setError('rsdnNo', {
				message: '입력값을 확인해주세요',
			});
		}
		if (!name) {
			setError('name', {
				message: '입력값을 확인해주세요',
			});
		}

		if (!myAccountInfo?.centerId || Object.keys(errors)?.length) return;

		const checkResult = await checkDuplicate({
			rsdnNo,
			name: name?.label,
			centerId: myAccountInfo.centerId,
		});

		if (checkResult?.code === ResponseCode.SUCCESS) {
			if (checkResult?.data?.recipientDuplicated === true) {
				setBanner(
					<CRBanner type='error' title='동일한 주민번호로 이미 수급자에 등록되어 있습니다.' />,
				);
				setIsDuplicateValid(false);
			} else if (
				checkResult?.data?.isEmployeeDuplicated === false &&
				checkResult?.data?.isCenterDuplicated === false
			) {
				setBanner(
					<CRBanner type='success' title='새로운 직원입니다. 실명 확인을 진행해 주십시오.' />,
				);
				setIsDuplicateValid(true);
			} else if (
				checkResult?.data?.isEmployeeDuplicated === true &&
				checkResult?.data?.isCenterDuplicated === false
			) {
				let centerName = checkResult.data.centers.length > 0 ? checkResult.data.centers[0] : '타';
				if (checkResult.data.centers.length > 1)
					centerName += ` 외 ${checkResult.data.centers.length - 1}개 센터`;
				setBanner(
					<CRBanner
						type='warning'
						title={`${centerName}에 이미 존재하는 직원입니다.(센터 추가 등록)`}
					/>,
				);
				setIsDuplicateValid(true);
			} else if (
				checkResult?.data?.isEmployeeDuplicated === true &&
				checkResult?.data?.isCenterDuplicated === true
			) {
				setBanner(<CRBanner type='error' title='센터내에 이미 존재하는 직원입니다.' />);
				setIsDuplicateValid(false);
			} else {
				setBanner(
					<CRBanner type='error' title='중복 조회에 실패 했습니다. 나중에 다시 시도해주세요.' />,
				);
				setIsDuplicateValid(false);
			}
		} else {
			setBanner(
				<CRBanner
					type='error'
					title={checkResult?.message || '중복 조회에 실패 했습니다. 나중에 다시 시도해주세요.'}
				/>,
			);
			Toast.error(checkResult?.message || '중복 조회에 실패 했습니다. 나중에 다시 시도해주세요.');
			setIsDuplicateValid(false);
		}

		setDidCheckRealName(false);
	};

	const isNewUser = useMemo(
		() =>
			checkDuplicateResult?.data?.isCenterDuplicated === false &&
			checkDuplicateResult?.data?.isEmployeeDuplicated === false &&
			checkDuplicateResult?.data?.recipientDuplicated === false,
		[checkDuplicateResult],
	);

	const isOtherCenterDuplicated = useMemo(
		() =>
			checkDuplicateResult?.data?.isCenterDuplicated === false &&
			checkDuplicateResult?.data?.recipientDuplicated === false &&
			checkDuplicateResult?.data?.isEmployeeDuplicated === true,
		[checkDuplicateResult],
	);

	const onSubmit = async (data: EmployeeEnrollForm) => {
		if (!myAccountInfo?.centerId) return;
		try {
			createEmployee({
				rsdnNo: data.rsdnNo,
				name: data.name.label,
				centerId: myAccountInfo?.centerId,
			});
		} catch (e) {
			console.log('error', e);
		}
	};

	const memberOptions = useMemo(
		() =>
			(members ?? [])?.map(
				(item) =>
					({
						label: item?.korMemberNm,
						value: {
							name: item?.korMemberNm,
							rsdnNo: item?.rsdnNo ? item.rsdnNo.slice(0, 6) : '',
							centerNm: item?.centerAliasNm ? `${item.centerAliasNm}센터` : '',
						},
						data: item,
					}) as CheckOption,
			),
		[members],
	);

	const isAllValid = !!(
		Object.keys(errors)?.length === 0 &&
		isDuplicateValid &&
		(isOtherCenterDuplicated || isRealName)
	);

	useEffect(() => {
		setIsDuplicateValid(false);
		setIsRealName(false);
		setDidCheckRealName(false);
	}, [name, rsdnNo]);

	return (
		<S.Container>
			<S.Form onSubmit={handleSubmit(onSubmit)} onKeyDown={checkKeyDown}>
				<S.Header>
					<S.Title>직원 등록</S.Title>
					<S.Icon src={Assets.icon.close} alt='close' onClick={hideDialog} />
				</S.Header>
				<S.Content>
					<S.SubTitle>중복 조회를 하고 새로운 직원을 생성하세요.</S.SubTitle>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => {
							if (errors.name?.message) {
								setIsDuplicateValid(false);
							}
							return (
								<CRInputLabel
									label='이름'
									message={
										errors.name?.label?.message && (
											<CRInputMessage type='error'>
												{(errors.name?.label?.message as string) ?? ''}
											</CRInputMessage>
										)
									}>
									<CRInput.SearchSelector
										status={errors.name?.label ? 'error' : 'default'}
										currentValue={value}
										items={memberOptions}
										onChange={(value) => {
											if (value?.data?.rsdnNo) {
												setDidCheckRealName(true);
												setIsDuplicateValid(true);
												setIsRealName(true);
												setValue('rsdnNo', value.data.rsdnNo);
												clearErrors();
											} else {
												setValue('rsdnNo', '');
											}
											onChange(value);
										}}
										visibleKey={['centerNm', 'rsdnNo']}
										placeholder='이름 입력'
										allowSelf
									/>
								</CRInputLabel>
							);
						}}
						name='name'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => {
							if (errors.name?.message) {
								setIsDuplicateValid(false);
							}
							return (
								<CRInputLabel
									label='주민등록번호'
									message={
										errors.rsdnNo?.message && (
											<CRInputMessage type='error'>{errors.rsdnNo?.message ?? ''}</CRInputMessage>
										)
									}>
									<div
										style={{
											position: 'relative',
										}}>
										<CRInput.Default
											type='number'
											maxLength={13}
											status={errors.rsdnNo?.message ? 'error' : 'default'}
											ref={ref}
											onBlur={onBlur}
											onChange={onChange}
											value={value}
											placeholder='주민등록번호 입력'
										/>
										<S.RightButtonContainer>
											<CRButton.Default
												onClick={handleClickDuplicateCheck}
												disabled={!isValid}
												size='xSmall'
												palette='gray'
												type='outlined'
												buttonType='button'>
												중복 조회
											</CRButton.Default>
											<CRButton.Default
												size='xSmall'
												disabled={!isDuplicateValid || !isNewUser || didCheckRealName}
												palette='gray'
												type='filled'
												onClick={handleClickCheckRealName}
												buttonType='button'>
												실명 확인
											</CRButton.Default>
										</S.RightButtonContainer>
									</div>
								</CRInputLabel>
							);
						}}
						name='rsdnNo'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<CRInputLabel label='소속센터'>
								<CRInput.Selector
									disabled
									items={[
										{
											label: myAccountInfo?.centerNm || '',
											value: myAccountInfo?.centerNm || '',
										},
									]}
									autoComplete
									onChangeValue={onChange}
									currentValue={{
										label: myAccountInfo?.centerNm || '',
										value: myAccountInfo?.centerNm || '',
									}}
									placeholder='센터 선택'
								/>
							</CRInputLabel>
						)}
						name='center'
						control={control}
					/>

					{banner}
				</S.Content>
				<S.BottomContainer>
					<S.ButtonContainer>
						<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
							취소
						</CRButton.Default>
						<CRButton.Default
							style={{
								position: 'relative',
							}}
							disabled={!isAllValid || createEmployeeLoading}
							palette='primary'
							size='default'
							buttonType='submit'>
							{createEmployeeLoading ? <CRSpinner /> : '등록'}
						</CRButton.Default>
					</S.ButtonContainer>
				</S.BottomContainer>
			</S.Form>
		</S.Container>
	);
}
