import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import Assets from 'assets';
import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRTableHeader from 'components/base/CRTableHeader';
import EDocPreviewDialog from 'components/domain/dialog/EDocPreviewDialog';
import IntegratedAssessmentSalaryOfferDialog from 'components/domain/dialog/IntegratedAssessmentSalaryOfferDialog';
import RecipientIntegratedAssessmentTable from 'components/domain/table/RecipientIntegratedAssessmentTable';
import { getYearRange } from 'lib';
import {
	useCreateRecipientIntegratedAssessment,
	useDeleteRecipientIntegratedAssessmentSalaryOffer,
	useMyAccountInfo,
	useUploadRecipientIntegratedAssessmentSalaryOffer,
} from 'lib/hook/react-query';
import { useRecipientIntegratedAssessment } from 'lib/hook/react-query/query/integratedAssessment';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import { endpoint } from 'lib/service/Api/endpoint';
import { IntegratedAssessmentDTO, IntegratedAssessmentSalaryOfferPlanInfoDTO } from 'types/dto';
import { IntegratedAssessmentType } from 'types/view/recipient';

import { Toast } from '../../../../components/base/CRToast';
import * as S from './styles';

const YEAR_RANGE = getYearRange();

export default function RecipientIntegratedAssessmentTab(): React.ReactElement {
	const { showDialog } = useGlobalLayout();
	const navigate = useNavigate();
	const [currentYear, setCurrentYear] = useState<number>(YEAR_RANGE[0]);
	const [localIntegratedAssessment, setLocalIntegratedAssessment] = useState<
		IntegratedAssessmentDTO[]
	>([]);

	const { currentRecipient, currentRecipientLongTermCertification, currentRecipientInfoSummary } =
		useRecipientPage();
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: integratedAssessment } = useRecipientIntegratedAssessment({
		recipientId: currentRecipient?.recipientId.toString(),
		centerId: myAccountInfo?.centerId.toString(),
		targetYear: currentYear.toString(),
	});

	const createRecipientIntegratedAssessment = useCreateRecipientIntegratedAssessment((client) => {
		client.invalidateQueries([
			endpoint.getRecipientIntegratedAssessment.key,
			{
				recipientId: currentRecipient?.recipientId.toString(),
				centerId: myAccountInfo?.centerId.toString(),
				targetYear: currentYear.toString(),
			},
		]);
	});

	const uploadRecipientIntegratedAssessmentSalaryOffer =
		useUploadRecipientIntegratedAssessmentSalaryOffer((client) => {
			client.invalidateQueries([
				endpoint.getRecipientIntegratedAssessment.key,
				{
					recipientId: currentRecipient?.recipientId.toString(),
					centerId: myAccountInfo?.centerId.toString(),
					targetYear: currentYear.toString(),
				},
			]);
		});

	const deleteRecipientIntegratedAssessmentSalaryOffer =
		useDeleteRecipientIntegratedAssessmentSalaryOffer((client) => {
			client.invalidateQueries([
				endpoint.getRecipientIntegratedAssessment.key,
				{
					recipientId: currentRecipient?.recipientId.toString(),
					centerId: myAccountInfo?.centerId.toString(),
					targetYear: currentYear.toString(),
				},
			]);
		});

	const onClickYearFilter = (year: number) => {
		setCurrentYear(year);
	};

	const renderYearFilter = () => (
		<S.ButtonContainer>
			{YEAR_RANGE.map((year) => (
				<CRButton.Default
					size='small'
					palette='gray'
					type={year === currentYear ? 'filled' : 'outlined'}
					onClick={() => onClickYearFilter(year)}>
					{`${year}년`}
				</CRButton.Default>
			))}
		</S.ButtonContainer>
	);

	const getIntegratedAssessmentName = (type: IntegratedAssessmentType) => {
		switch (type) {
			case IntegratedAssessmentType.BedsoreInfo:
				return '욕창위험도';
			case IntegratedAssessmentType.FallInfo:
				return '낙상위험도';
			case IntegratedAssessmentType.KmmseInfo:
				return '인지기능';
			case IntegratedAssessmentType.DesireInfo:
				return '욕구사정';
			default:
				return '';
		}
	};

	const validate = (type: IntegratedAssessmentType) => {
		const typeName = getIntegratedAssessmentName(type);
		if (!currentRecipient?.name) {
			Toast.error(`수급자명이 존재하지 않아 ${typeName}을 생성할 수 없습니다.`);
			return false;
		}
		if (!currentRecipientInfoSummary?.genderCd) {
			Toast.error(`수급자 성별이 존재하지 않아 ${typeName}을 생성할 수 없습니다.`);
			return false;
		}
		if (!currentRecipient?.birthday) {
			Toast.error(`수급자 생년월일이 존재하지 않아 ${typeName}을 생성할 수 없습니다.`);
			return false;
		}
		if (!currentRecipientLongTermCertification?.longTermNo) {
			Toast.error(`수급자 장기요양인정번호이 존재하지 않아 ${typeName}을 생성할 수 없습니다.`);
			return false;
		}
		if (!currentRecipientLongTermCertification?.longTermGradeCd) {
			Toast.error(`수급자 장기요양인정등급이 존재하지 않아 ${typeName}을 생성할 수 없습니다.`);
			return false;
		}

		return true;
	};

	const handleClickAddRow = async () => {
		setLocalIntegratedAssessment([...localIntegratedAssessment, {}]);
	};

	const handleClickIntegratedAssessment =
		(recipientIaId: number, type: IntegratedAssessmentType) => async () => {
			switch (type) {
				case IntegratedAssessmentType.FallInfo:
					navigate(
						`${RouterPath.recipient().integratedAssessmentFallInfo.uniqueKey}/${recipientIaId}`,
					);
					return;
				case IntegratedAssessmentType.BedsoreInfo:
					navigate(
						`${RouterPath.recipient().integratedAssessmentBedsoreInfo.uniqueKey}/${recipientIaId}`,
					);
					return;
				case IntegratedAssessmentType.KmmseInfo:
					navigate(
						`${RouterPath.recipient().integratedAssessmentKmmseInfo.uniqueKey}/${recipientIaId}`,
					);
					return;
				case IntegratedAssessmentType.DesireInfo:
					navigate(
						`${RouterPath.recipient().integratedAssessmentDesireInfo.uniqueKey}/${recipientIaId}`,
					);
					return;
				default:
					return;
			}
		};

	const handleClickChangeSalaryOfferPlan = (salaryOfferPlanId: number) => async () => {
		navigate(
			`${
				RouterPath.recipient().integratedAssessmentSalaryOfferPlanInfo.uniqueKey
			}/${salaryOfferPlanId}`,
		);
	};

	const handleClickUploadSalaryOfferPlan = async (targetSeq: number, file: File) => {
		if (!currentRecipient?.recipientId) return;
		await uploadRecipientIntegratedAssessmentSalaryOffer.mutateAsync({
			file,
			targetYear: currentYear.toString(),
			targetSeq,
			recipientId: currentRecipient.recipientId,
		});
	};

	const handleClickSalaryOfferPreview = async (
		data: IntegratedAssessmentSalaryOfferPlanInfoDTO,
	) => {
		showDialog(() => <EDocPreviewDialog esignId={data.esignSimpResponse.esignId} />);
	};

	const handleClickDeleteSalaryOfferPlan = async (salaryOfferPlanId: number) => {
		await deleteRecipientIntegratedAssessmentSalaryOffer.mutateAsync({
			salaryOfferPlanId: salaryOfferPlanId.toString(),
		});
	};

	const handleClickSendSalaryOfferPlan = async (salaryOfferPlanId: number) => {
		showDialog(({ hideDialog }) => (
			<IntegratedAssessmentSalaryOfferDialog
				salaryOfferPlanId={salaryOfferPlanId}
				targetYear={String(currentYear)}
				recipientId={String(currentRecipient?.recipientId)}
				hideDialog={hideDialog}
			/>
		));
	};

	const handleClickAddIntegratedAssessment =
		(index: number, type: IntegratedAssessmentType) => async () => {
			if (!myAccountInfo) return;
			if (!currentRecipient) return;
			if (!currentRecipientLongTermCertification) return;
			if (!validate(type)) return;

			const periods = currentRecipientLongTermCertification.longTermGradePeriod?.split(' ~ ');
			const longTermStartDate = dayjs(periods?.[0]).format('YYYY-MM-DD HH:mm:ss');
			const longTermEndDate = dayjs(periods?.[1]).format('YYYY-MM-DD HH:mm:ss');

			const result = await createRecipientIntegratedAssessment.mutateAsync({
				centerId: myAccountInfo.centerId,
				recipientId: currentRecipient.recipientId,
				paperTypeCd: type,
				evalDescTypeCd: 'CMN172.10',
				targetYear: currentYear.toString(),
				targetSeq: index,
				writeDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
				writeStateCd: 'CMN085.10',
				writerId: Number(myAccountInfo.memberAccountId ?? '0'),
				recipientNm: currentRecipient.name,
				recipientBirthDt: currentRecipient?.birthday,
				recipientGenderCd: currentRecipientInfoSummary.genderCd,
				longTermNo: currentRecipientLongTermCertification.longTermNo,
				longTermGradeCd: currentRecipientLongTermCertification.longTermGradeCd,
				longTermEndDate,
				longTermStartDate,
				totalPoint: 0,
				content: {},
			});

			if (!result?.recipientIaId) return;

			switch (type) {
				case IntegratedAssessmentType.FallInfo:
					navigate(
						`${RouterPath.recipient().integratedAssessmentFallInfo.uniqueKey}/${
							result?.recipientIaId
						}`,
					);
					return;
				case IntegratedAssessmentType.BedsoreInfo:
					navigate(
						`${RouterPath.recipient().integratedAssessmentBedsoreInfo.uniqueKey}/${
							result?.recipientIaId
						}`,
					);
					return;
				case IntegratedAssessmentType.KmmseInfo:
					navigate(
						`${RouterPath.recipient().integratedAssessmentKmmseInfo.uniqueKey}/${
							result?.recipientIaId
						}`,
					);
					return;
				case IntegratedAssessmentType.DesireInfo:
					navigate(
						`${RouterPath.recipient().integratedAssessmentDesireInfo.uniqueKey}/${
							result?.recipientIaId
						}`,
					);
					return;
				default:
					return;
			}
		};

	useEffect(() => {
		if (integratedAssessment) {
			setLocalIntegratedAssessment(integratedAssessment);
		}
	}, [integratedAssessment]);

	return (
		<S.Container>
			<CRTableHeader
				currentFilter={{}}
				filters={[]}
				hideSearchButton
				renderCustomFilter={renderYearFilter()}
				renderRightButton={
					<CRButton.IconButton
						size='xSmall'
						type='filled'
						iconLeft={Assets.icon.add}
						onClick={handleClickAddRow}>
						회차 추가
					</CRButton.IconButton>
				}
			/>
			<S.TableContainer>
				<RecipientIntegratedAssessmentTable
					items={localIntegratedAssessment}
					onClickAddIntegratedAssessmentType={handleClickAddIntegratedAssessment}
					onClickIntegratedAssessmentType={handleClickIntegratedAssessment}
					onClickChangeSalaryOfferPlan={handleClickChangeSalaryOfferPlan}
					onClickUploadSalaryOfferPlan={handleClickUploadSalaryOfferPlan}
					onClickDeleteSalaryOfferPlan={handleClickDeleteSalaryOfferPlan}
					onClickSendSalaryOfferPlan={handleClickSendSalaryOfferPlan}
					onClickSalaryOfferPreview={handleClickSalaryOfferPreview}
				/>
			</S.TableContainer>
		</S.Container>
	);
}
