import React, { createContext, useContext } from 'react';

import IApi from 'lib/service/Api/IApi';

interface IProps {
	api: IApi;
}

const ApiContext = createContext<IApi | null>(null);

export function useApi(): IApi {
	const context = useContext(ApiContext);

	if (!context) {
		throw new Error('useApi must be used by children of the ApiProvider.');
	}

	return context;
}

function ApiProvider({ children, api }: React.PropsWithChildren<IProps>): React.ReactElement {
	return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
}

export default ApiProvider;
