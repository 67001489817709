import useCRQuery from 'lib/hook/react-query/base/useCRQuery';
import { endpoint } from 'lib/service/Api/endpoint';

export const useGetCustomerCenterManagers = useCRQuery(
	endpoint.getCustomerCenterManagers.key,
	'getCustomerCenterManagers',
	{
		enabledKey: ['centerId'],
	},
);

export const useGetCustomerChiefs = useCRQuery(endpoint.getCustomerChiefs.key, 'getCustomerChiefs');

export const useGetCustomerClients = useCRQuery(
	endpoint.getCustomerClients.key,
	'getCustomerClients',
	{
		enabledKey: ['centerId'],
	},
);

export const useGetCustomerEmployees = useCRQuery(
	endpoint.getCustomerEmployees.key,
	'getCustomerEmployees',
	{
		enabledKey: ['centerIds'],
	},
);

export const useGetCustomerSelectBoxCommonCodes = useCRQuery(
	endpoint.getCustomerSelectBoxCommonCodes.key,
	'getCustomerSelectBoxCommonCodes',
);
