import React from 'react';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import { useDeleteRecipientHistoryOfHolding } from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import { Recipient, RecipientHoldingHistoryType } from 'types/view/recipient';

import { Toast } from '../../../base/CRToast';
import * as S from './styles';

interface Props {
	currentRecipient?: Recipient;
	deleteItem?: RecipientHoldingHistoryType;
	onClickClose?: () => void;
}

function RecipientDeleteHistoryOfRecipientHoldingDialog({
	currentRecipient,
	deleteItem,
	onClickClose,
}: Props): React.ReactElement {
	const deleteRecipientHistoryOfHolding = useDeleteRecipientHistoryOfHolding((client) => {
		client.invalidateQueries([
			endpoint.getRecipientHoldingHistories.key,
			{ recipientId: currentRecipient?.recipientId },
		]);
	});

	const handleClickDelete = async () => {
		if (!currentRecipient) return;
		if (!deleteItem) return;

		try {
			await deleteRecipientHistoryOfHolding.mutateAsync({
				recipientId: currentRecipient?.recipientId ?? 0,
				recipientPendingHistId: deleteItem.id,
			});
			Toast.success('정상적으로 입원 이력을 삭제했습니다.');
			onClickClose?.();
		} catch (error) {
			Toast.error('삭제에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
	};
	return (
		<CRDialog
			title='입원 이력 삭제'
			type='S'
			body={
				<S.Content>{`입원 이력을 삭제하시겠습니까?\n${
					deleteItem?.period ? `(${deleteItem.period})` : ''
				}`}</S.Content>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={onClickClose}>
						취소
					</CRButton.Default>
					<CRButton.Default
						palette='primary'
						type='outlined'
						size='default'
						onClick={handleClickDelete}>
						삭제
					</CRButton.Default>
				</S.ButtonContainer>
			}
			onClickClose={onClickClose}
		/>
	);
}

export default RecipientDeleteHistoryOfRecipientHoldingDialog;
