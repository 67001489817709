import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { Toast } from 'components/base/CRToast';
import EDocAdmissionUseRequestForm from 'components/domain/form/EDocAdmissionUseRequestForm';
import InformationTable from 'components/ui/InformationTable';
import { admissionUseRequest } from 'lib';
import { useConfirmRecipientEdocAdmissionUse } from 'lib/hook/react-query';
import { useIssuableEDocPaperTypes } from 'lib/hook/react-query/query/edoc';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { loadApplication } from 'lib/service/util/loader';
import { ResponseCode } from 'types/api';
import { Esign } from 'types/api/send';
import {
	AdmissionUseRequestFormTypes,
	EDocIssueFormTypes,
	EDocTemplateType,
} from 'types/view/eDoc';

import { eDocAdmissionUseConfirmRequestAdapter } from './eDocConfirmAdapter';
import * as S from './styles';

interface Props {
	menuType?: 'employee' | 'recipient';
	title?: string;
	eDocTemplateCd: EDocTemplateType;
	edocNo: string;
	esign: Esign;
}

export function EDocFormConfirmDialog({
	menuType = 'employee',
	title = '',
	eDocTemplateCd,
	edocNo,
	esign,
}: Props) {
	const { hideDialog } = useDialog();
	const { queryClient } = loadApplication();
	const { data: eDocPaperCodes } = useIssuableEDocPaperTypes({
		paperMenu: menuType,
	});

	const { mutate: confirmRecipientEdocAdmissionUse } = useConfirmRecipientEdocAdmissionUse(
		(client, returnData) => {
			hideDialog();
			client.invalidateQueries([endpoint.getESignHistoryDetail.key]);
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success('정상적으로 검수를 완료하였습니다.');
			} else {
				Toast.error('검수를 완료할 수 없습니다. 입력된 내용을 다시 확인해주세요.');
			}
		},
	);

	const currentEDocType = eDocPaperCodes?.paperTypeCds?.find(
		(item) => item?.comCdId === eDocTemplateCd,
	);

	const defaultContext = useForm<EDocIssueFormTypes>();

	const admissionUseRequestForm = useForm<AdmissionUseRequestFormTypes>({
		mode: 'onSubmit',
		resolver: yupResolver(admissionUseRequest),
	});

	// 입소이용 신청서
	const onSubmitAdmissionUseRequestForm = async (formData: AdmissionUseRequestFormTypes) => {
		if (formData?.admissionUseEdocData) {
			const param = eDocAdmissionUseConfirmRequestAdapter(formData, esign);
			if (param) {
				confirmRecipientEdocAdmissionUse(param);
			}
		}
	};

	const totalContext = useMemo(
		() => [
			{
				templateCode: EDocTemplateType.입소이용신청서,
				formContext: admissionUseRequestForm,
				onSubmit: onSubmitAdmissionUseRequestForm,
			},
		],
		[admissionUseRequestForm, onSubmitAdmissionUseRequestForm],
	);

	const currentForm = totalContext.find(
		(context) => context.templateCode === defaultContext.watch('eDocType')?.value,
	);

	const submitForm = () => {
		const matchedForm: any = totalContext.find(
			(context) => context.templateCode === defaultContext.watch('eDocType')?.value,
		);
		if (matchedForm) {
			matchedForm.formContext.trigger().then((isValid: boolean) => {
				if (isValid) {
					matchedForm.formContext.handleSubmit(matchedForm.onSubmit as (data: any) => void)();
				}
			});
		}
	};

	const eDocPaperTypeCode = useMemo(
		() =>
			(eDocPaperCodes?.paperTypeCds || [])?.map((item) => ({
				label: item.korComCdAliasNm,
				value: item.comCdId,
			})),
		[eDocPaperCodes],
	);

	const renderSubForm = () => {
		const eDocType = defaultContext.watch('eDocType');

		const templateCode = eDocType?.value?.split('.')?.join('_');
		switch (currentEDocType?.comCdId) {
			case EDocTemplateType.입소이용신청서: {
				return (
					<EDocAdmissionUseRequestForm
						confirmMode
						templateCode={templateCode}
						formContext={admissionUseRequestForm}
						defaultContext={defaultContext}
						edocNo={edocNo}
					/>
				);
			}
			default: {
				return null;
			}
		}
	};

	const onClose = () => {
		queryClient.invalidateQueries([endpoint.getESignHistoryDetail.key]);
		hideDialog();
	};

	useEffect(() => {
		defaultContext.setValue('eDocType', {
			label: currentEDocType?.korComCdNm,
			value: currentEDocType?.comCdId,
		});
	}, [currentEDocType]);

	const recipientNm = defaultContext.getValues('recipientNm');
	const recipientPhoneNo = defaultContext.getValues('recipientPhoneNo');

	return (
		<CRDialog
			onClickClose={onClose}
			title={title}
			body={
				<S.Container>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInputLabel isRequired label='발급 서류'>
								<CRInput.Selector
									disabled
									placeholder='발급 서류 선택'
									currentValue={value}
									onChangeValue={onChange}
									items={eDocPaperTypeCode}
								/>
							</CRInputLabel>
						)}
						name='eDocType'
						control={defaultContext.control}
					/>
					<CRInputLabel label='발송 정보'>
						<InformationTable
							items={[
								[
									{
										label: '템플릿명',
										value: defaultContext.watch('eDocType')?.label || '-',
									},
								],
								[
									{
										label: '제목',
										value: defaultContext.watch('eDocType')
											? `${recipientNm}님${
													defaultContext.watch('eDocType')
														? ` ${defaultContext.watch('eDocType').label}`
														: ''
												}`
											: '-',
									},
								],
								[
									{
										label: '수신자',
										value: `${recipientNm}${recipientPhoneNo ? ` (${recipientPhoneNo})` : ''}`,
									},
								],
							]}
						/>
					</CRInputLabel>
					{renderSubForm()}
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={onClose}>
						취소
					</CRButton.Default>
					<CRButton.Default
						palette='primary'
						size='default'
						disabled={!currentForm?.formContext.formState.isValid}
						onClick={submitForm}>
						검수 완료
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
