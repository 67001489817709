import styled, { RuleSet, css } from 'styled-components';

import { ButtonPalette, ButtonSize, ButtonState, ButtonType } from '../type';

type ColorObjType = {
	[key in ButtonPalette]: {
		[key in ButtonType]: {
			[key in ButtonState]: RuleSet<object>;
		};
	};
};

type SizeObjType = {
	[key in ButtonSize]: RuleSet<object>;
};

const COLOR_ATTRIBUTE: ColorObjType = {
	primary: {
		filled: {
			default: css`
				background: ${(props) => props.theme.colors.primary60};
				color: ${(props) => props.theme.colors.gray100};
			`,
			hover: css`
				background: ${(props) => props.theme.colors.primary50};
				color: ${(props) => props.theme.colors.gray100};
			`,
			active: css`
				background: ${(props) => props.theme.colors.primary40};
				color: ${(props) => props.theme.colors.gray100};
			`,
			disabled: css`
				background: ${(props) => props.theme.colors.gray90};
				color: ${(props) => props.theme.colors.gray60};
			`,
		},
		tonal: {
			default: css`
				background: ${(props) => props.theme.colors.primary90};
				color: ${(props) => props.theme.colors.gray10};
			`,
			hover: css`
				background: ${(props) => props.theme.colors.primary80};
				color: ${(props) => props.theme.colors.gray10};
			`,
			active: css`
				background: ${(props) => props.theme.colors.primary80};
				color: ${(props) => props.theme.colors.gray10};
			`,
			disabled: css`
				background: ${(props) => props.theme.colors.gray90};
				color: ${(props) => props.theme.colors.gray60};
			`,
		},
		outlined: {
			default: css`
				background: ${(props) => props.theme.colors.gray100};
				box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.primary60} inset;
				color: ${(props) => props.theme.colors.primary60};
			`,
			hover: css`
				background: ${(props) => props.theme.colors.primary99};
				box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.primary60} inset;
				color: ${(props) => props.theme.colors.primary60};
			`,
			active: css`
				background: ${(props) => props.theme.colors.primary95};
				box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.primary60} inset;
				color: ${(props) => props.theme.colors.primary60};
			`,
			disabled: css`
				background: transparent;
				box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.gray90} inset;
				color: ${(props) => props.theme.colors.gray60};
			`,
		},
		text: {
			default: css`
				background: transparent;
				color: ${(props) => props.theme.colors.primary60};
			`,
			hover: css`
				background: ${(props) => props.theme.colors.gray100};
				color: ${(props) => props.theme.colors.primary60};
			`,
			active: css`
				background: ${(props) => props.theme.colors.primary95};
				color: ${(props) => props.theme.colors.primary60};
			`,
			disabled: css`
				background: transparent;
				color: ${(props) => props.theme.colors.gray60};
			`,
		},
	},
	gray: {
		filled: {
			default: css`
				background: ${(props) => props.theme.colors.gray10};
				color: ${(props) => props.theme.colors.gray100};
			`,
			hover: css`
				background: ${(props) => props.theme.colors.gray00};
				color: ${(props) => props.theme.colors.gray100};
			`,
			active: css`
				background: ${(props) => props.theme.colors.primary00};
				color: ${(props) => props.theme.colors.gray100};
			`,
			disabled: css`
				background: ${(props) => props.theme.colors.gray90};
				color: ${(props) => props.theme.colors.gray60};
			`,
		},
		tonal: {
			default: css`
				background: ${(props) => props.theme.colors.gray95};
				color: ${(props) => props.theme.colors.gray10};
			`,
			hover: css`
				background: ${(props) => props.theme.colors.gray90};
				color: ${(props) => props.theme.colors.gray10};
			`,
			active: css`
				background: ${(props) => props.theme.colors.gray80};
				color: ${(props) => props.theme.colors.gray10};
			`,
			disabled: css`
				background: ${(props) => props.theme.colors.gray90};
				color: ${(props) => props.theme.colors.gray60};
			`,
		},
		outlined: {
			default: css`
				background: ${(props) => props.theme.colors.gray100};
				box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.gray90} inset;
				color: ${(props) => props.theme.colors.gray10};
			`,
			hover: css`
				background: ${(props) => props.theme.colors.gray99};
				box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.gray90} inset;
				color: ${(props) => props.theme.colors.gray10};
			`,
			active: css`
				background: ${(props) => props.theme.colors.gray95};
				box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.gray90} inset;
				color: ${(props) => props.theme.colors.gray10};
			`,
			disabled: css`
				background: transparent;
				box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.gray90} inset;
				color: ${(props) => props.theme.colors.gray60};
			`,
		},
		text: {
			default: css`
				background: transparent;
				color: ${(props) => props.theme.colors.gray10};
			`,
			hover: css`
				background: ${(props) => props.theme.colors.gray99};
				color: ${(props) => props.theme.colors.gray10};
			`,
			active: css`
				background: ${(props) => props.theme.colors.gray95};
				color: ${(props) => props.theme.colors.gray10};
			`,
			disabled: css`
				background: transparent;
				color: ${(props) => props.theme.colors.gray60};
			`,
		},
	},
};

const SIZE_ATTRIBUTE: SizeObjType = {
	default: css`
		padding: 0.6rem 2.4rem;
		min-height: 4rem;
		${(props) => props.theme.typography.label};
	`,
	large: css`
		padding: 1.4rem 3.2rem;
		min-height: 5.6rem;
		${(props) => props.theme.typography.body};
	`,
	small: css`
		padding: 0 0.8rem;
		min-height: 3.2rem;
		border-radius: 0.8rem;
		${(props) => props.theme.typography.label};
	`,
	xSmall: css`
		padding: 0 0.8rem;
		min-height: 2.5rem;
		border-radius: 0.4rem;
		${(props) => props.theme.typography.label};
	`,
};

export const Container = styled.button<{
	$palette: ButtonPalette;
	$buttonType: ButtonType;
	$size: ButtonSize;
	$fullSize: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${(props) => (props.$fullSize ? '100%' : 'auto')};
	border: none;
	outline: none;
	border-radius: 0.8rem;
	box-sizing: border-box;
	cursor: pointer;
	white-space: nowrap;
	padding: 0;
	${(props) => SIZE_ATTRIBUTE[props.$size]};
	${(props) => COLOR_ATTRIBUTE[props.$palette][props.$buttonType].default};
	&:hover {
		${(props) => COLOR_ATTRIBUTE[props.$palette][props.$buttonType].hover};
	}
	&:active {
		${(props) => COLOR_ATTRIBUTE[props.$palette][props.$buttonType].active};
	}
	&:disabled {
		${(props) => COLOR_ATTRIBUTE[props.$palette][props.$buttonType].disabled};
		cursor: no-drop;
	}
`;
