import React from 'react';

import CRTable from 'components/base/CRTable';
import { CRText } from 'components/base/CRText';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import { RoundingHistoryViewType } from 'types/view/roundingHistory';

import {
	ROUNDING_HISTORY_TABLE_HEADER_CONFIG,
	ROUNDING_HISTORY_TABLE_RENDER_ITEM_STYLE,
	ROUNDING_HISTORY_TABLE_RENDER_KEY,
} from './constant';

type Props = {
	roundingHistoryData: RoundingHistoryViewType[];
};

function RoundingHistoryTable({ roundingHistoryData }: Props) {
	const visitCompleteStatusRenderer = (value: boolean) => (
		<>
			<CRText text='● ' color={value ? 'green' : 'primary60'} typography='label' />
			<CRText text={value ? '완료' : '미완료'} color='gray20' typography='label' />
		</>
	);
	const hasTagStautusRenderer = (value: boolean) => (
		<CRText text={value ? '태그' : '미태그'} typography='label' />
	);
	const remarkRenderer = (value: string) => {
		const tooltipValue = value.split(' 입원\n').join(',\n');
		const tableValue = value.split(' 입원\n').join(', ');
		return (
			<RDTooltip content={tooltipValue} side='left'>
				<CRText text={tableValue} typography='label' color='gray60' />
			</RDTooltip>
		);
	};
	return (
		<CRTable.Root>
			<CRTable.Head offset={-63} heads={ROUNDING_HISTORY_TABLE_HEADER_CONFIG} />
			<CRTable.Body>
				{roundingHistoryData.map((item, index) => (
					<CRTable.Row
						key={item.longtermNo}
						item={{ ...item, index: index + 1, id: 0 }}
						customStyle={{
							...ROUNDING_HISTORY_TABLE_RENDER_ITEM_STYLE,
						}}
						renderKeys={[...ROUNDING_HISTORY_TABLE_RENDER_KEY]}
						customRender={{
							tagYn: hasTagStautusRenderer,
							visitCompleteYn: visitCompleteStatusRenderer,
							remark: remarkRenderer,
						}}
					/>
				))}
			</CRTable.Body>
		</CRTable.Root>
	);
}

export default RoundingHistoryTable;
