import React, { useEffect } from 'react';
import { Controller, FieldErrors, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRIcon from 'components/base/CRIcon';
import CRInput from 'components/base/CRInput';
import CRInputMessage from 'components/base/CRInputMessage';
import CRSpinner from 'components/base/CRSpinner';
import { Toast } from 'components/base/CRToast';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import { ErrorMessage } from 'components/domain/sideModal/MonitoringSideModal/styles';
import { EmployeePayInfo } from 'lib';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import { useCheckAccountOwner, useUpdateEmployeePayInfo } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { ResponseCode } from 'types/api';
import { CheckAccountOwnerData, CheckAccountOwnerRequest } from 'types/api/common';
import { EmployeeDetailInfoDTO, SaveEmployeePayInfoRequestDTO } from 'types/api/employee';
import { EmployeePayInfoForm } from 'types/view/employee';

import * as S from './styles';

interface Props {
	item?: EmployeeDetailInfoDTO | null;
	options: {
		payType: CheckOption[];
		bank: CheckOption[];
	};
	onStartChangeSection?: () => void;
	onCancelChangeSection?: () => void;
	isEditSectionChanging?: boolean;
}

export default function EmployeePayInfoEdit({
	item,
	options,
	onStartChangeSection,
	onCancelChangeSection,
	isEditSectionChanging,
}: Props): React.ReactElement {
	const { showDialog, hideDialog } = useDialog();
	const { control, handleSubmit, watch, setValue, reset } = useForm<EmployeePayInfoForm>({
		resolver: yupResolver(EmployeePayInfo),
	});

	const { currentEmployee } = useEmployeePage();
	const { mutate: updateEmployeePayInfo } = useUpdateEmployeePayInfo((client, returnData) => {
		if (returnData?.employeeId) {
			hideDialog();

			client.invalidateQueries([
				endpoint.getEmployeeDetailInfo.key,
				{
					centerId: currentEmployee?.centerId,
					employeeId: currentEmployee?.employeeId,
				},
			]);
			onStartChangeSection?.();
			Toast.success('임금 정보를 수정했습니다.');
		} else {
			onCancelChangeSection?.();
		}
	});

	const { mutate: checkAccountOwner, isLoading: checkAccountLoading } = useCheckAccountOwner(
		(client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS && returnData?.data) {
				const result: CheckAccountOwnerData = JSON.parse(returnData.data);
				if (result.data.OUTSTATCD === '0021') {
					setValue('accountValid', true);
				} else if (result.data.OUTSTATCD === '0031') {
					setValue('accountValid', false);
					Toast.error(result?.data.OUTRSLTMSG);
				} else {
					Toast.error('계좌 조회에 실패했습니다.');
				}
			}
		},
	);

	const formData = watch();

	const isCache = watch('payType')?.[0]?.value === 'CMN133.20';

	const hasAccountInfo = !!(watch('bank') && watch('accountNo') && watch('ownerName'));

	const isValidAccount = watch('accountValid');

	// console.log('watch', watch());

	const isAccountPayment = watch('payType')?.[0]?.value === 'CMN133.10';

	const onSubmit = async (data: EmployeePayInfoForm) => {
		if (!currentEmployee || !item) return;
		// if (isAccountPayment && !hasAccountInfo) {
		// 	Toast.error('계좌지급의 경우 계좌번호, 은행, 계좌주는 필수값입니다.');
		// 	onCancelChangeSection?.();
		// 	return;
		// }

		if (isAccountPayment && !isValidAccount) {
			Toast.error('계좌주를 조회 해주세요.');
			onCancelChangeSection?.();
			return;
		}

		const param: SaveEmployeePayInfoRequestDTO = {
			employeeId: item.employeeId,
			centerId: item.centerId,
			salaryPayTypeCd: data.payType?.[0]?.value,
			salaryAccountNo: data.accountNo,
			salaryAccountBankCd: data.bank?.value,
			salaryAccountDepositorNm: data.ownerName,
			salaryAccountValidYn: !isCache,
		};
		updateEmployeePayInfo(param);
	};

	const onSubmitFail = (errors: FieldErrors<EmployeePayInfoForm>) => {
		onCancelChangeSection?.();
		Toast.error(Object.values(errors)?.[0]?.message || '입력폼을 확인해주세요.');
	};

	const handleClickCheckAccount = () => {
		if (checkAccountLoading || !hasAccountInfo) return;
		const param: CheckAccountOwnerRequest = {
			acctNo: formData.accountNo,
			bankCode: formData.bank?.value,
			custNm: formData.ownerName,
		};
		checkAccountOwner(param);
	};

	const onSubmitHandler = () => {
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='변경된 정보 저장'
				content='임금에서 변경된 정보를 저장합니다.'
				successOption={{
					text: '저장',
					successCallback: () => {
						hideDialog();
						handleSubmit(onSubmit, onSubmitFail as any)();
					},
				}}
				cancelOption={{
					text: '저장안함',
					callback: () => {
						hideDialog();
						onStartChangeSection?.();
					},
				}}
				hideDialog={() => {
					hideDialog();
					onCancelChangeSection?.();
				}}
			/>
		));
	};

	useEffect(() => {
		if (item) {
			const matchedPayType = options.payType.find(
				(option) => option.value === item?.salaryPayTypeCd,
			);
			const matchedBank = options.bank.find((option) => option.value === item?.salaryAccountBankCd);
			setValue('accountValid', !!item.salaryAccountValidYn);
			reset({
				payType: matchedPayType ? [matchedPayType] : undefined,
				bank: matchedBank,
				accountNo: item?.salaryAccountNo || '',
				ownerName: item?.salaryAccountDepositorNm || '',
			});
		}
	}, [item, options]);

	useEffect(() => {
		if (isCache) {
			setValue('bank', undefined);
			setValue('ownerName', '');
			setValue('accountNo', '');
			setValue('accountValid', true);
		}
	}, [isCache]);

	useEffect(() => {
		if (isEditSectionChanging) {
			onSubmitHandler();
		}
	}, [isEditSectionChanging]);

	return (
		<S.Container>
			<S.Form>
				<S.Label>
					임금
					<S.ButtonContainer>
						<CRButton.Default
							size='xSmall'
							type='outlined'
							palette='gray'
							onClick={onSubmitHandler}>
							취소
						</CRButton.Default>
						<CRButton.Default
							size='xSmall'
							type='filled'
							palette='gray'
							onClick={handleSubmit(onSubmit, onSubmitFail as any)}>
							저장
						</CRButton.Default>
					</S.ButtonContainer>
				</S.Label>
				<S.TableContainer>
					<S.Table>
						<S.TableRow>
							<S.TableLabelColumn>
								수령방식 <S.RequiredMark>*</S.RequiredMark>
							</S.TableLabelColumn>
							<S.TableValueColumn>
								<Controller
									render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
										<>
											<CRCheckBoxGroup
												checkType='single'
												type='radio'
												gap={0.8}
												options={options.payType}
												ref={ref}
												value={value}
												onChange={(item) => {
													onChange(item);
													setValue('accountValid', false);
												}}
											/>
											{errors.payType?.message && (
												<ErrorMessage>{errors.payType?.message}</ErrorMessage>
											)}
										</>
									)}
									name='payType'
									control={control}
								/>
							</S.TableValueColumn>
							<S.TableLabelColumn>
								계좌번호 {!isCache && <S.RequiredMark>*</S.RequiredMark>}
							</S.TableLabelColumn>
							<S.TableValueColumn>
								<Controller
									render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
										<CRInput.TableInput
											disabled={isCache || checkAccountLoading}
											type='number'
											ref={ref}
											onBlur={onBlur}
											onChange={(item) => {
												onChange(item);
												setValue('accountValid', false);
											}}
											addOnBottom={errors.accountNo?.message}
											value={value}
											status={errors.accountNo?.message ? 'error' : 'default'}
											placeholder='계좌번호 입력'
										/>
									)}
									name='accountNo'
									control={control}
								/>
							</S.TableValueColumn>
						</S.TableRow>
						<S.TableRow>
							<S.TableLabelColumn>
								은행 {!isCache && <S.RequiredMark>*</S.RequiredMark>}
							</S.TableLabelColumn>
							<S.TableValueColumn>
								<Controller
									render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
										<CRInput.Selector
											topOffset={-210}
											disabled={isCache || checkAccountLoading}
											items={options.bank}
											ref={ref}
											type='small'
											onChangeValue={(item) => {
												onChange(item);
												setValue('accountValid', false);
											}}
											currentValue={value}
											placeholder='은행 선택'
											status={errors.bank ? 'error' : 'default'}
											addOnBottom={errors.bank?.message}
										/>
									)}
									name='bank'
									control={control}
								/>
							</S.TableValueColumn>
							<S.TableLabelColumn>
								계좌주 {!isCache && <S.RequiredMark>*</S.RequiredMark>}
							</S.TableLabelColumn>
							<S.TableValueColumn>
								<Controller
									render={({ field: { onBlur, ref, onChange, value }, formState: { errors } }) => (
										<>
											<CRInput.TableInput
												disabled={isCache || checkAccountLoading}
												ref={ref}
												onBlur={onBlur}
												onChange={(item) => {
													onChange(item);
													setValue('accountValid', false);
												}}
												value={value}
												maxLength={20}
												placeholder='계좌주 입력'
												status={errors.ownerName ? 'error' : 'default'}
												suffix={
													!isCache && (
														<CRButton.Default
															disabled={!hasAccountInfo}
															style={{
																position: 'relative',
																width: checkAccountLoading ? '4rem' : 'auto',
															}}
															size='xSmall'
															palette='gray'
															type='outlined'
															onClick={handleClickCheckAccount}>
															{checkAccountLoading ? (
																<CRSpinner />
															) : isValidAccount ? (
																<CRIcon src={Assets.icon.check} />
															) : (
																'조회'
															)}
														</CRButton.Default>
													)
												}
											/>
											{errors.ownerName?.message && (
												<CRInputMessage type='error'>
													{errors.ownerName?.message || ''}
												</CRInputMessage>
											)}
										</>
									)}
									name='ownerName'
									control={control}
								/>
							</S.TableValueColumn>
						</S.TableRow>
					</S.Table>
				</S.TableContainer>
			</S.Form>
		</S.Container>
	);
}
