import React from 'react';
import { Controller } from 'react-hook-form';

import CRInput from 'components/base/CRInput';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import useContractRenewalTask from 'lib/hook/view/contract/useContractRenewalTask';

import CRRequiredMark from '../../../base/CRRequiredMark';
import * as S from './styles';

function RenewalCashReceiptForm(): React.ReactElement {
	const { form, commonCodes, disabled } = useContractRenewalTask();

	return (
		<S.Container>
			<S.Form>
				<S.Table>
					<S.TableRow>
						<S.TableLabelColumn style={{ width: '10.4rem' }}>
							현금영수증 <CRRequiredMark />
						</S.TableLabelColumn>
						<S.TableValueColumn style={{ width: '24rem' }}>
							<Controller
								render={({ field: { ref, onChange, value }, formState: { errors } }) => (
									<CRCheckBoxGroup
										ref={ref}
										checkType='single'
										type='radio'
										gap={0.8}
										value={[{ label: '', value }]}
										onChange={(values) => {
											if (values[0].value) {
												form.setValue(
													'recipient.recipientReceipt.cashReceiptIssueMethodCd',
													'CMN162.10',
												);
											}
											onChange(values[0].value);
										}}
										options={[
											{
												label: '대상자',
												value: true,
											},
											{
												label: '비대상자',
												value: false,
											},
										]}
										disabled={disabled}
									/>
								)}
								name='recipient.recipientReceipt.cashReceiptIssueYn'
								control={form?.control}
							/>
						</S.TableValueColumn>
					</S.TableRow>
					{form?.watch('recipient.recipientReceipt.cashReceiptIssueYn') && (
						<>
							<S.TableRow>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>
									이름 <CRRequiredMark />
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }}>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TableInput
												ref={ref}
												onBlur={onBlur}
												onChange={onChange}
												value={value}
												placeholder='이름 입력'
												disabled={disabled}
											/>
										)}
										name='recipient.recipientReceipt.cashReceiptIssuerNm'
										control={form?.control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
							<S.TableRow>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>
									발급 방식 <CRRequiredMark />
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }}>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRCheckBoxGroup
												ref={ref}
												checkType='single'
												type='radio'
												gap={0.8}
												value={value ? [{ label: '', value }] : undefined}
												onChange={(values) => onChange(values[0].value)}
												options={commonCodes?.CMN162 ?? []}
												disabled={disabled}
											/>
										)}
										name='recipient.recipientReceipt.cashReceiptIssueMethodCd'
										control={form?.control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
							<S.TableRow>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>
									{form?.watch('recipient.recipientReceipt.cashReceiptIssueMethodCd') ===
									'CMN162.10'
										? '휴대폰 번호'
										: '사업자 번호'}
									<CRRequiredMark />
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }}>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TableInput
												ref={ref}
												onBlur={onBlur}
												onChange={onChange}
												value={value}
												disabled={disabled}
												placeholder={
													form?.watch('recipient.recipientReceipt.cashReceiptIssueMethodCd') ===
													'CMN162.10'
														? '휴대폰 번호 입력'
														: '사업자 번호 입력'
												}
											/>
										)}
										name='recipient.recipientReceipt.cashReceiptIssuerMobilePhoneNo'
										control={form?.control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
						</>
					)}
				</S.Table>
			</S.Form>
		</S.Container>
	);
}

export default RenewalCashReceiptForm;
