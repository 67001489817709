import React from 'react';

import dayjs from 'dayjs';

import Assets from 'assets';
import Colors from 'common/colors';
import Radius from 'common/radius';
import CRButton from 'components/base/CRButton';
import CRIconButton from 'components/base/CRButton/CRIconButton';
import { Toast } from 'components/base/CRToast';
import AddDocumentDialog from 'components/domain/dialog/AddDocumentDialog';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import { useMyAccountInfo } from 'lib/hook/react-query';
import { useDeleteMyCenterDocument } from 'lib/hook/react-query/mutation/document';
import useDialog from 'lib/hook/util/useDialog';
import { useDownload } from 'lib/hook/util/useDownload';
import { ResponseCode } from 'types/api';
import { MyCenterDocsContentDTO } from 'types/api/document';

import * as S from './styles';

interface Props {
	items: MyCenterDocsContentDTO[];
	isCenterChief: boolean;
	onRefresh: () => void;
}

export default function CenterDocumentTable({ items, onRefresh, isCenterChief }: Props) {
	const { showDialog, hideDialog } = useDialog();
	const { data: myAccountInfo } = useMyAccountInfo();
	// 본부일경우 공용문서 수정,삭제가능 그 외는 불가능
	const isHeadquarterUser = myAccountInfo?.centerId === 9;
	const { downloadFiles } = useDownload();
	const { mutate: deleteMyCenterDocument } = useDeleteMyCenterDocument((client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			Toast.success('정상적으로 문서를 삭제하였습니다.');
			onRefresh();
			hideDialog();
		} else {
			Toast.error('문서 삭제에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
	});

	const handleDownload = (item: MyCenterDocsContentDTO) => {
		const fileDetails = item.centerFile?.fileDetails?.filter((item) => !item.fileDeleteYn);
		if (fileDetails.length) {
			downloadFiles(fileDetails);
		}
	};

	const handleClickDelete = (item: MyCenterDocsContentDTO) => {
		if (!myAccountInfo?.centerId) return;
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='문서 삭제'
				hideDialog={hideDialog}
				content={`[${item?.centerFileNm}] 문서를 삭제하시겠습니까?`}
				cancelOption={{
					text: '취소',
					callback: hideDialog,
				}}
				successOption={{
					text: '삭제',
					type: 'outlined',
					successCallback: () => {
						deleteMyCenterDocument({
							centerFileId: item.centerFileId,
						});
					},
				}}
			/>
		));
	};

	const handleClickEditDocument = (item: MyCenterDocsContentDTO) => {
		showDialog(({ hideDialog }) => (
			<AddDocumentDialog onRefresh={onRefresh} item={item} hideDialog={hideDialog} />
		));
	};

	return (
		<S.Container>
			<S.TableRow
				style={{
					position: 'sticky',
					top: 0,
					zIndex: 1,
				}}>
				<S.TableHeaderColumn
					style={{
						width: '5.6rem',
					}}
				/>
				<S.TableHeaderColumn
					style={{
						width: '20rem',
					}}>
					문서명
				</S.TableHeaderColumn>
				<S.TableHeaderColumn
					style={{
						flex: 1,
					}}>
					파일
				</S.TableHeaderColumn>
				<S.TableHeaderColumn
					style={{
						width: '16rem',
						justifyContent: 'flex-end',
					}}>
					등록일
				</S.TableHeaderColumn>
				<S.TableHeaderColumn
					style={{
						width: '10rem',
						justifyContent: 'flex-end',
					}}>
					등록자명
				</S.TableHeaderColumn>
				{isCenterChief && (
					<S.TableHeaderColumn
						style={{
							width: '16rem',
						}}
					/>
				)}
				<S.TableHeaderColumn
					style={{
						width: '5.6rem',
					}}
				/>
			</S.TableRow>
			{items?.map((item) => (
				<S.TableRow key={item.centerFileId}>
					<S.TableBodyColumn
						style={{
							width: '5.6rem',
						}}
					/>
					<S.TableBodyColumn
						style={{
							width: '20rem',
						}}>
						{item.centerFileNm}
					</S.TableBodyColumn>
					<S.TableBodyColumn
						style={{
							flex: 1,
							gap: '0.8rem',
						}}>
						{item.centerFile?.fileDetails?.some((item) => !item.fileDeleteYn) && (
							<CRIconButton
								size='small'
								palette='gray'
								type='outlined'
								iconLeft={Assets.icon.download}
								onClick={() => handleDownload(item)}>
								다운로드
							</CRIconButton>
						)}
					</S.TableBodyColumn>
					<S.TableBodyColumn
						style={{
							width: '16rem',
							justifyContent: 'flex-end',
							color: Colors.gray60,
						}}>
						{item.centerFile.createdAt
							? dayjs(item.centerFile.createdAt).format('YYYY.MM.DD')
							: '-'}
					</S.TableBodyColumn>
					<S.TableBodyColumn
						style={{
							width: '10rem',
							justifyContent: 'flex-end',
							color: Colors.gray60,
						}}>
						{item?.userNm || '-'}
					</S.TableBodyColumn>
					{isCenterChief && (
						<S.TableBodyColumn
							style={{
								width: '16rem',
								display: 'flex',
								justifyContent: 'center',
								gap: '0.8rem',
							}}>
							{/* 본사에서 올린 공용문서는 본사센터장만 수정,삭제 가능 */}
							{((isHeadquarterUser && item.centerId === 9) ||
								(!isHeadquarterUser && item.centerId !== 9)) && (
								<>
									<CRButton.Default
										size='xSmall'
										palette='gray'
										type='outlined'
										style={{
											borderRadius: Radius.radius04,
										}}
										onClick={() => handleClickEditDocument(item)}>
										수정
									</CRButton.Default>
									<CRButton.Default
										size='xSmall'
										palette='primary'
										type='outlined'
										style={{
											borderRadius: Radius.radius04,
										}}
										onClick={() => handleClickDelete(item)}>
										삭제
									</CRButton.Default>
								</>
							)}
						</S.TableBodyColumn>
					)}
					<S.TableBodyColumn
						style={{
							width: '5.6rem',
						}}
					/>
				</S.TableRow>
			))}
		</S.Container>
	);
}
