import React, { useEffect } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import { DaumPostcodeData } from 'lib/hook/util/useDaumPostCode';

import useContractTask from '../../../../lib/hook/view/contract/useContractTask';
import CRRequiredMark from '../../../base/CRRequiredMark';
import * as S from './styles';

function ParentalControlsForm(): React.ReactElement {
	const { form, commonCodes, disabled } = useContractTask();
	const { fields, append, remove } = useFieldArray({
		control: form?.control,
		name: 'recipient.guardians',
	});

	const handleChangeBaseAddress = (index: number) => (postcodeData: DaumPostcodeData) => {
		form?.setValue(`recipient.guardians.${index}.addressInfo.basAddress`, postcodeData.address);
		form?.setValue(`recipient.guardians.${index}.addressInfo.zipCode`, postcodeData.zonecode);
	};

	const handleChangeDetailAddress = (index: number) => (detailAddress: string) => {
		form?.setValue(`recipient.guardians.${index}.addressInfo.detailAddress`, detailAddress);
	};

	const handleClickAddGuardian = () => {
		append({
			mainGuardianYn: !fields.length,
			guardianNm: '',
			addressInfo: {
				basAddress: '',
				detailAddress: '',
				zipCode: '',
				latitude: 0,
				longitude: 0,
			},
			guardianPhoneNo: '',
			guardianBirthDt: '',
			guardianRelCd: '',
			scheduleReceiveYn: false,
			longTermPayFeeCertiReceiveYn: false,
			docReceiveMethodCd: 'CMN180.10',
		});
	};

	const handleClickRemoveGuardian = (index: number) => () => {
		remove(index);
	};

	const handleClickMain = (index: number) => () => {
		form?.getValues('recipient.guardians').forEach((item, itemIndex) => {
			form?.setValue(`recipient.guardians.${itemIndex}.mainGuardianYn`, itemIndex === index);
			form?.setValue(`recipient.guardians.${itemIndex}.longTermPayFeeCertiReceiveYn`, true);
			form?.setValue(`recipient.guardians.${itemIndex}.scheduleReceiveYn`, true);
		});
	};

	const onChangeRelation = (value: string, index: number) => {
		// 보호자를 본인으로 선택할 시
		if (value === 'CMN038.10') {
			const currentValue = form.getValues(`recipient.guardians.${index}`);
			const currentRecipientBase = form.getValues('recipient.base');
			form.setValue(`recipient.guardians.${index}`, {
				...currentValue,
				guardianNm: currentRecipientBase?.recipientNm || '',
				guardianPhoneNo: currentRecipientBase?.mobilePhoneNo || currentValue?.guardianPhoneNo || '',
				addressInfo: {
					basAddress:
						currentRecipientBase?.addressInfo.basAddress ||
						currentValue?.addressInfo.basAddress ||
						'',
					detailAddress:
						currentRecipientBase?.addressInfo.detailAddress ||
						currentValue?.addressInfo.detailAddress ||
						'',
					zipCode:
						currentRecipientBase?.addressInfo.zipCode || currentValue?.addressInfo.zipCode || '',
					regionNm1:
						currentRecipientBase?.addressInfo.regionNm1 ||
						currentValue?.addressInfo.regionNm1 ||
						'',
					regionNm2:
						currentRecipientBase?.addressInfo.regionNm2 ||
						currentValue?.addressInfo.regionNm2 ||
						'',
					regionNm3:
						currentRecipientBase?.addressInfo.regionNm3 ||
						currentValue?.addressInfo.regionNm3 ||
						'',
					roadNm:
						currentRecipientBase?.addressInfo.roadNm || currentValue?.addressInfo.roadNm || '',
					latitude:
						currentRecipientBase?.addressInfo.latitude || currentValue?.addressInfo.latitude,

					longitude:
						currentRecipientBase?.addressInfo.longitude || currentValue?.addressInfo.longitude,
				},
				guardianId: currentValue.guardianId,
				guardianBirthDt: currentRecipientBase?.birthDt || '',
				guardianRelCd: 'CMN038.10',
				mainGuardianYn: currentValue.mainGuardianYn,
			});
		} else {
			form.setValue(`recipient.guardians.${index}.relationship` as any, value);
		}
	};

	useEffect(() => {
		if (!form.getValues('recipient.guardians').length) {
			append({
				mainGuardianYn: !fields.length,
				guardianNm: '',
				addressInfo: {
					basAddress: '',
					detailAddress: '',
					zipCode: '',
					latitude: 0,
					longitude: 0,
				},
				guardianPhoneNo: '',
				guardianBirthDt: '',
				guardianRelCd: '',
				scheduleReceiveYn: !fields.length,
				longTermPayFeeCertiReceiveYn: !fields.length,
				docReceiveMethodCd: 'CMN180.10',
			});
		}
	}, [form.getValues('recipient.guardians')?.length]);

	return (
		<S.Container>
			<S.Form>
				{fields?.map((field, index) => (
					<React.Fragment key={field.id}>
						<S.ItemHeader>
							{`보호자 ${index + 1}`}
							<S.DeleteButtonContainer>
								{!disabled && (
									<CRButton.Default
										disabled={form?.watch(`recipient.guardians.${index}.mainGuardianYn`)}
										size='xSmall'
										type='outlined'
										onClick={handleClickRemoveGuardian(index)}>
										삭제
									</CRButton.Default>
								)}
							</S.DeleteButtonContainer>
							<Controller
								render={({ field: { value }, formState: { errors } }) => (
									<CRCheckBox
										checkBoxType='radio'
										checked={value ?? ''}
										onChange={handleClickMain(index)}
										disabled={disabled}>
										주 보호자
									</CRCheckBox>
								)}
								name={`recipient.guardians.${index}.mainGuardianYn`}
								control={form?.control}
							/>
						</S.ItemHeader>
						<S.Table>
							<S.TableRow>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>
									이름 <CRRequiredMark />
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }}>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TableInput
												ref={ref}
												onBlur={onBlur}
												onChange={onChange}
												value={value ?? ''}
												placeholder='보호자명 입력'
												disabled={disabled}
											/>
										)}
										name={`recipient.guardians.${index}.guardianNm`}
										control={form?.control}
									/>
								</S.TableValueColumn>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>
									연락처 <CRRequiredMark />
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }}>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TableInput
												ref={ref}
												onBlur={onBlur}
												onChange={onChange}
												value={value ?? ''}
												placeholder='연락처 입력'
												type='number'
												disabled={disabled}
											/>
										)}
										name={`recipient.guardians.${index}.guardianPhoneNo`}
										control={form?.control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
							<S.TableRow>
								<S.TableLabelColumn rowSpan={2} style={{ width: '10.4rem' }}>
									주소{' '}
									{form?.watch(`recipient.guardians.${index}.mainGuardianYn`) && <CRRequiredMark />}
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem', verticalAlign: 'middle' }} rowSpan={2}>
									<CRInput.Address
										onChangeBaseAddress={handleChangeBaseAddress(index)}
										onChangeDetailAddress={handleChangeDetailAddress(index)}
										baseAddress={form?.watch(`recipient.guardians.${index}.addressInfo.basAddress`)}
										detailAddress={form?.watch(
											`recipient.guardians.${index}.addressInfo.detailAddress`,
										)}
										disabled={disabled}
									/>
								</S.TableValueColumn>
								<S.TableLabelColumn style={{ width: '10.4rem' }}>
									생년월일{' '}
									{form?.watch(`recipient.guardians.${index}.mainGuardianYn`) && <CRRequiredMark />}
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem' }}>
									<Controller
										render={({
											field: { onBlur, ref, onChange, value },
											formState: { errors },
										}) => (
											<CRInput.TableInput
												ref={ref}
												onBlur={onBlur}
												onChange={onChange}
												value={value ?? ''}
												placeholder='예시) 19280301'
												disabled={disabled}
											/>
										)}
										name={`recipient.guardians.${index}.guardianBirthDt`}
										control={form?.control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
							<S.TableRow>
								<S.TableLabelColumn style={{ width: '9.6rem' }}>
									관계 <CRRequiredMark />
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem', verticalAlign: 'middle' }}>
									<Controller
										render={({ field: { value, onChange }, formState: { errors } }) => (
											<CRInput.Selector
												items={commonCodes?.CMN038 ?? []}
												type='small'
												currentValue={value ? { label: '', value } : undefined}
												onChangeValue={(option) => {
													onChange(option.value);
													onChangeRelation(option.value, index);
												}}
												placeholder='관계 입력'
												disabled={disabled}
											/>
										)}
										name={`recipient.guardians.${index}.guardianRelCd`}
										control={form?.control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
							<S.TableRow>
								<S.TableLabelColumn style={{ width: '9.6rem' }}>
									서류 수신 여부 <CRRequiredMark />
								</S.TableLabelColumn>
								<S.TableValueColumn
									style={{
										verticalAlign: 'middle',
										alignItems: 'center',
										flexDirection: 'row',
										display: 'flex',
										gap: '0.4rem',
									}}>
									<Controller
										render={({ field: { value, onChange }, formState: { errors } }) => (
											<CRCheckBox checked={value} onChange={onChange} disabled={disabled}>
												일정표
											</CRCheckBox>
										)}
										name={`recipient.guardians.${index}.scheduleReceiveYn`}
										control={form?.control}
									/>
									<Controller
										render={({ field: { value, onChange }, formState: { errors } }) => (
											<CRCheckBox checked={value} onChange={onChange} disabled={disabled}>
												장기요양급여비용명세서
											</CRCheckBox>
										)}
										name={`recipient.guardians.${index}.longTermPayFeeCertiReceiveYn`}
										control={form?.control}
									/>
								</S.TableValueColumn>
								<S.TableLabelColumn style={{ width: '9.6rem' }}>
									서류 수신 방법 <CRRequiredMark />
								</S.TableLabelColumn>
								<S.TableValueColumn style={{ width: '24rem', verticalAlign: 'middle' }}>
									<Controller
										render={({ field: { value, onChange }, formState: { errors } }) => (
											<CRInput.Selector
												items={commonCodes?.CMN180 ?? []}
												type='small'
												currentValue={{ label: '', value }}
												onChangeValue={(option) => onChange(option.value)}
												placeholder='서류 수신 방법 선택'
												disabled={disabled}
											/>
										)}
										name={`recipient.guardians.${index}.docReceiveMethodCd`}
										control={form?.control}
									/>
								</S.TableValueColumn>
							</S.TableRow>
						</S.Table>
					</React.Fragment>
				))}
				<S.AddButtonContainer>
					{!disabled && (
						<CRButton.IconButton
							iconLeft={Assets.icon.add}
							palette='gray'
							type='outlined'
							disabled={disabled}
							onClick={handleClickAddGuardian}>
							보호자 추가
						</CRButton.IconButton>
					)}
				</S.AddButtonContainer>
			</S.Form>
		</S.Container>
	);
}

export default ParentalControlsForm;
