import React from 'react';

import Assets from '../../../../assets';
import CRButton from '../../CRButton';
import { CheckOption } from '../../Selections/type';
import * as S from './styles';

interface Props {
	items: CheckOption[];
	defaultActiveKey?: string;
	canAdd?: boolean;
	onChange?: (item: CheckOption) => void;
	onDelete?: (item: CheckOption) => void;
	onAdd?: () => void;
	hideDelete?: boolean;
	addDisabled?: boolean;
	limitCount?: number;
}

function ContractTab({
	defaultActiveKey,
	onChange,
	onDelete,
	items,
	canAdd,
	onAdd,
	hideDelete = false,
	addDisabled = false,
	limitCount = 1,
}: Props): React.ReactElement {
	const onClickTab = (selectedItem: CheckOption) => {
		onChange?.(selectedItem);
	};

	return (
		<S.Container>
			<S.MenuContainer>
				{items?.map((item) => (
					<S.TabItem
						key={item.value}
						$isActive={item.value === defaultActiveKey}
						onClick={() => onClickTab(item)}>
						{item.label}
						{!hideDelete && items.length > limitCount && item.value === defaultActiveKey && (
							<S.Icon src={Assets.icon.close} onClick={() => onDelete?.(item)} />
						)}
					</S.TabItem>
				))}
				{canAdd && (
					<CRButton.IconButton
						iconLeft={Assets.icon.add}
						type='text'
						size='small'
						palette='gray'
						onClick={onAdd}
						disabled={addDisabled}>
						급여추가
					</CRButton.IconButton>
				)}
			</S.MenuContainer>
			<S.Divider />
		</S.Container>
	);
}

export default React.memo(ContractTab);
