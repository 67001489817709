import React from 'react';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import { CRText } from 'components/base/CRText';

interface Props {
	onClick: () => void;
	title?: string;
}

function EDocClientButtonSubmit({ onClick, title = '제출하기' }: Props) {
	return (
		<CRButton.IconButton
			style={{ width: '100%', height: '5.6rem' }}
			iconLeft={Assets.icon.check}
			onClick={onClick}>
			<CRText typography='h4' color='white'>
				{title}
			</CRText>
		</CRButton.IconButton>
	);
}

export default EDocClientButtonSubmit;
