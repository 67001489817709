import React from 'react';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';

import { v4 } from 'uuid';

import Assets from 'assets';
import Colors from 'common/colors';
import CRAvatar from 'components/base/CRAvatar';
import CRBanner from 'components/base/CRBanner';
import CRButton from 'components/base/CRButton';
import CRStatus from 'components/base/CRStatus';
import { StrictModeDroppable } from 'components/domain/dialog/ApprovalLineSelectorDialog/StrictModeDroppable';
import { Employee } from 'components/domain/dialog/ApprovalLineSelectorDialog/mock';
import { APPROVAL_STATUS_CONFIG } from 'components/domain/table/ApprovalProgressTable/constant';
import { match } from 'lib';
import { ApprovalStatus } from 'types/view/approval';

import * as S from './styles';

interface Props {
	readOnly?: boolean;
	items: Employee[];
	onToggleDialog: () => void;
	onDelete: (id: string) => void;
	onDragEnd: (result: DropResult) => void;
}

export default function ApprovalLine({
	readOnly = false,
	items,
	onToggleDialog,
	onDelete,
	onDragEnd,
}: Props) {
	const renderEmptyView = () => (
		<S.ApprovalLineItem onClick={onToggleDialog}>
			<S.ItemLeft>
				<S.OrderCount>01</S.OrderCount>
				<CRAvatar.Default borderColor={Colors.gray90} size='small' src={Assets.icon.avatarNone} />
				<S.EmptyMessage>승인 대상을 선택해주세요</S.EmptyMessage>
			</S.ItemLeft>
			<S.ItemRight>
				<CRButton.Default size='xSmall' type='outlined' palette='gray'>
					선택
				</CRButton.Default>
			</S.ItemRight>
		</S.ApprovalLineItem>
	);

	const renderApprovalStatusInfo = ({
		status,
		reason,
	}: {
		status: ApprovalStatus;
		reason: string;
	}) => {
		const type = match(status)
			.on(
				(status) => status === '반려',
				() => 'error',
			)
			.on(
				(status) => status === '승인완료',
				() => 'success',
			)
			.on(
				(status) => status === '승인필요',
				() => 'warning',
			)
			.otherwise(() => 'default');

		return (
			<S.ApprovalStatusInfo>
				<CRBanner title={`${status} 사유`} content={reason} type={type} />
			</S.ApprovalStatusInfo>
		);
	};

	const renderApprovalItem = (item: Employee, index: number) => (
		<S.ApprovalLineItem $hasItem key={item.id} $readOnly={readOnly}>
			<S.ApprovalLineItemTop>
				<S.ItemLeft>
					<S.Info>
						<S.DraggableIcon src={Assets.icon.dragIndicator} alt='dragIndicator' />
						<S.OrderCount $hasItem>{index < 9 ? `0${index + 1}` : index + 1}</S.OrderCount>
					</S.Info>
					<CRAvatar.Default
						showTooltip
						content={`${item.name} - ${item.center}`}
						borderColor={Colors.gray100}
						size='small'
						src={item.imgUrl ? item.imgUrl : Assets.icon.avatarNone}
					/>
					<S.EmployeeInfo>
						<S.EmployeeName>{item.name}</S.EmployeeName>
						<S.CenterName>{item.center}</S.CenterName>
					</S.EmployeeInfo>
				</S.ItemLeft>
				<S.ItemRight>
					{item.statusInfo?.status && readOnly && (
						<S.Status options={APPROVAL_STATUS_CONFIG}>{item.statusInfo?.status}</S.Status>
					)}
					<S.DeleteIcon
						src={Assets.icon.closeCircle}
						alt='closeCircle'
						onClick={(e) => {
							e.stopPropagation();
							onDelete(item.id);
						}}
					/>
				</S.ItemRight>
			</S.ApprovalLineItemTop>
			{item.statusInfo?.reason && readOnly && renderApprovalStatusInfo(item.statusInfo)}
		</S.ApprovalLineItem>
	);

	const renderApprovalItemList = (items: Employee[]) => {
		if (items.length > 1 && !readOnly) {
			return (
				<DragDropContext onDragEnd={onDragEnd}>
					<StrictModeDroppable droppableId={v4()}>
						{(provided) => (
							<div ref={provided.innerRef} {...provided.droppableProps}>
								{items.map((item, index) => (
									<Draggable key={item.id} draggableId={item.id} index={index}>
										{(provided) => (
											<S.DragItemContainer
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}>
												{renderApprovalItem(item, index)}
											</S.DragItemContainer>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</StrictModeDroppable>
				</DragDropContext>
			);
		}
		return items.map((item, index) => renderApprovalItem(item, index));
	};

	return (
		<S.Container>
			<S.Title>결재선</S.Title>
			<S.ApprovalLineList>
				{items.length > 0 ? renderApprovalItemList(items) : renderEmptyView()}
				{!readOnly && (
					<CRButton.IconButton
						onClick={onToggleDialog}
						palette='gray'
						type='tonal'
						size='large'
						iconLeft={Assets.icon.add}
						style={{
							width: '100%',
						}}>
						결재선 추가
					</CRButton.IconButton>
				)}
			</S.ApprovalLineList>
		</S.Container>
	);
}
