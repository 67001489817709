/* eslint-disable guard-for-in */

/* eslint-disable no-restricted-syntax */

/* eslint-disable no-underscore-dangle */
import React, { useCallback, useMemo } from 'react';
import {
	FieldErrors,
	FieldValues,
	FormProvider,
	SubmitErrorHandler,
	SubmitHandler,
	UseFormProps,
	useForm,
} from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { Toast } from 'components/base/CRToast';

interface UseCRFormProps<TFieldValues extends FieldValues = FieldValues, TContext = any>
	extends UseFormProps<TFieldValues, TContext> {
	yupValidator?: any;
}

function useCRForm<TFieldValues extends FieldValues = FieldValues, TContext = any>({
	yupValidator,
	...formProps
}: UseCRFormProps<TFieldValues, TContext> = {}) {
	const methods = useForm<TFieldValues>({
		...formProps,
		...((yupValidator ? { resolver: yupResolver(yupValidator) } : {}) as any),
	});

	const CRFormProvider = useCallback(
		({ children }: { children: React.ReactNode }) => (
			<FormProvider {...methods}>{children}</FormProvider>
		),
		[methods],
	);

	function _getFirstErrorMessage(errorObject: FieldErrors<FieldValues>): string {
		for (const key in errorObject) {
			const currentErrorObject = errorObject[key] as any;
			if (!currentErrorObject) return '';

			if (Array.isArray(currentErrorObject)) {
				for (const item of currentErrorObject) {
					const arrayItemMessage = _getFirstErrorMessage(item);
					if (arrayItemMessage) {
						return arrayItemMessage;
					}
				}
			} else if (currentErrorObject && currentErrorObject?.message) {
				return currentErrorObject.message;
			} else if (currentErrorObject && typeof currentErrorObject === 'object') {
				const nestedErrorMessage = _getFirstErrorMessage(currentErrorObject);
				if (nestedErrorMessage) {
					return nestedErrorMessage;
				}
			}
		}
		return '';
	}

	const CRHandleSubmit = useCallback((onSubmit: SubmitHandler<TFieldValues>) => {
		const onSubmitFail: SubmitErrorHandler<TFieldValues> = (errors) => {
			Toast.error(_getFirstErrorMessage(errors) || '입력폼을 확인해주세요');
		};

		return methods.handleSubmit(onSubmit, onSubmitFail);
	}, []);

	const returnMethods = useMemo(
		() => ({
			...methods,
			CRHandleSubmit,
			CRFormProvider,
		}),
		[],
	);

	return returnMethods;
}

export default useCRForm;
